import axios from 'axios';
import baseRequest, {
  baseRequestWithHeaders,
  baseRequestWithOptions,
  baseRequestWithHeadersFileResponse,
} from './baseRequest';
import config from '../../config';
import  { store } from  '../../store';

const redirectForLogin = () => {
  window.location.href = '/';
 }
 
 axios.interceptors.response.use((response) => response,  (error) => {
   const statusCode = error.response.data.code;
   console.log(statusCode);
   return statusCode === 401 ?  redirectForLogin() : Promise.reject(error);
 });

export const getClaimsRequests = (filters, sorting, searchInput,filteredValue) => {
  if (filters.receiptUrl && filters.receiptUrl === 'all') {
    filters.receiptUrl = null;
  }
  console.log(sorting);
  if (searchInput) {
    const url = `/claims-support/reimbursements`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method, {
      ...filters,
      q: searchInput,
      status: filteredValue,
    });
  }
  const urlQuery = new URLSearchParams(filters).toString();
  const url = `/claims-support/reimbursements?${urlQuery}`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, {
    sortBy: sorting ? sorting.sortBy : undefined,
    sortOrder: sorting ? sorting.sortOrder : undefined,
  });
};

export const getConsultationReimbursementRequests = (
  filters,
  appliedOrderBy
) => {
  if (filters.status && filters.status === 'all') {
    filters.status = null;
  }
  if (filters.receiptUrl && filters.receiptUrl === 'all') {
    filters.receiptUrl = null;
  }
  const urlQuery = new URLSearchParams(filters).toString();

  const url = `/claims-support/v2/consultation-requests?${urlQuery}`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method);
};

export const approveClaim = (
  reimbursementId,
  requestedAmount,
  approvedAmount,
  reason
) => {
  const body = {
    reimbursementId,
    requestedAmount,
    approvedAmount,
    reason,
  };
  const url = '/claims-support/reimbursements';
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, body);
};

export const rejectClaim = (reimbursementId, reason) => {
  const body = {
    reimbursementId,
    requestedAmount: 0,
    approvedAmount: 0,
    reason,
  };
  const url = '/claims-support/reimbursements';
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, body);
};

export const updateClaim = (body) => {
  const url = '/claims-support/v2/reimbursements';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const getClaim = (reimbursementId) => {
  const url = `/claims-support/reimbursement/${reimbursementId}`;
  const method = 'GET';
  return baseRequest(url, method);
};

export const placeCall = (reimbursementId) => {
  const url = `/voice-call/initiate`;
  const method = 'POST';
  const body = {
    eventId: reimbursementId,
    eventType: 'claim-reimburse',
  };
  return baseRequest(url, method, null, body);
};

export const getUserByPhone = (phone) => {
  const url = '/claims-support/reimbursements/user-by-phone';
  const method = 'GET';
  const body = {
    phone,
  };
  return baseRequest(url, method, body);
};

export const getReimbursementProcedures = (userId, reimbursementId) => {
  const url = '/claims-support/reimbursements/get-procedures';
  const method = 'GET';
  const body = {
    userId,
    reimbursementId,
  };
  return baseRequest(url, method, body);
};

export const getDependentsForUser = (userId) => {
  const url = '/claims-support/reimbursements/get-dependents-for-user';
  const method = 'GET';
  const body = {
    userId,
  };
  return baseRequest(url, method, body);
};

export const addReimbursementRequest = (body) => {
  const url = '/claims-support/add-reimbursement-for-user';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const copyClaim = (claimId) => {
  const url = '/claims-support/copy-claim';
  const method = 'POST';
  const body = {
    claimId,
  };
  return baseRequest(url, method, null, body);
};

export const deleteClaim = (claimId, reason) => {
  const url = `/claims-support/reimbursement/${claimId}`;
  const method = 'DELETE';
  const body = {
    reason,
  };
  return baseRequest(url, method, null, body);
};

export const deleteInvoiceItem = (claimId, itemId) => {
  const url = `/claims-support/reimbursement/${claimId}/invoice/${itemId}`;
  const method = 'DELETE';
  return baseRequest(url, method, null);
};

export const deleteInvoiceUrl = (claimId, invoiceUrl) => {
  const url = `/claims-support/reimbursement/${claimId}/invoice`;
  const method = 'DELETE';
  const body = {
    invoiceUrl,
  };
  return baseRequest(url, method, null, body);
};

export const deletePrescriptionUrl = (claimId, prescriptionUrl) => {
  const url = `/claims-support/reimbursement/${claimId}/prescription`;
  const method = 'DELETE';
  const body = {
    prescriptionUrl,
  };
  return baseRequest(url, method, null, body);
};

export const reopenClaim = (claimId, reopenReason) => {
  const url = `/claims-support/reimbursement/${claimId}/reopen`;
  const method = 'POST';
  const body = {
    reopenReason,
  };
  return baseRequest(url, method, null, body);
};

export const submitPrescriptionsForDigitization = (claimId, prescriptions) => {
  const url = `/claims-support/reimbursement/${claimId}/submit-prescription-for-digitization`;
  const method = 'POST';
  const body = prescriptions;
  return baseRequest(url, method, null, body);
};

export const getPreviousClaimedAmountByUser = (userId, invoiceDate) => {
  const url = `/cortis/reimbursement/fetch-amount-claimed-by-user`;
  const method = 'POST';
  const body = {
    userId,
    invoiceDate,
  };
  return baseRequest(url, method, null, body);
};

export const uploadInvoiceFile = (reimbursementId, file, onUploadProgress) => {
  const url = `/claims-support/reimbursement/${reimbursementId}/add-additional-invoices`;
  const method = 'PUT';
  const fileData = new FormData();
  fileData.append('invoiceFile', file);
  return baseRequestWithOptions(url, method, fileData, {
    onUploadProgress,
  });
};

export const uploadPrescriptionFile = (
  reimbursementId,
  file,
  onUploadProgress
) => {
  const url = `/claims-support/reimbursement/${reimbursementId}/add-additional-prescriptions`;
  const method = 'PUT';
  const fileData = new FormData();
  fileData.append('prescriptionFile', file);
  return baseRequestWithOptions(url, method, fileData, {
    onUploadProgress,
  });
};

export const uploadMerFile = (reimbursementId, file, onUploadProgress) => {
  const url = `/claims-support/reimbursement/${reimbursementId}/add-additional-mers`;
  const method = 'PUT';
  const fileData = new FormData();
  fileData.append('merFile', file);
  return baseRequestWithOptions(url, method, fileData, {
    onUploadProgress,
  });
};

export const updateClaimToDocumentsDeficient = (body) => {
  const url = `/claims-support/v2/reimbursements/documents-deficient`;
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const viewCommentRequest = (reimbursementId) => {
  const url = `/claims-support/reimbursements-orders/comments/${reimbursementId}/requests`;
  const method = 'GET';
  const data = {};
  return baseRequest(url, method, null, data);
};

export const storeCommentRequest = (reimbursementId, comment, type) => {
  const url = `/claims-support/reimbursements-orders/comments/${reimbursementId}/requests`;
  const method = 'POST';
  const data = {
    comment,
    type,
  };
  return baseRequest(url, method, null, data);
};

export const fetchRequestHistory = (requestId) => {
  const url = `/claims-support/request/${requestId}/history`;
  const method = 'GET';
  const params = {
    requestId,
  };
  return baseRequest(url, method, params, null);
};

export const processClaimRequest = (reimbursementId) => {
  const url = `/claims-support/reimbursements-orders/claim/${reimbursementId}/request`;
  const method = 'POST';
  return baseRequest(url, method, null, null);
};
 export async function getReimbursementInfo(page,pageSize,sponsor,searchString,reason,status,claimedBy,startDate,endDate,
  sortBy,
  sortOrder,remark) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return await axios
    .get(
      `${config.baseUrl}/claims-support/get-reimbursement-info?page=${page}&pageSize=${pageSize}&sponsor=${sponsor}&searchString=${searchString}&reason=${reason}&status=${status}&claimedBy=${claimedBy}&sd=${startDate}&ed=${endDate}&sortBy=${sortBy}&sortOrder=${sortOrder}&remark=${remark}`,
      { headers }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });

}
export async function getSponsorList() {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.get(`${config.baseUrl}/claims-support/get-sponsor-list`,{headers}).then((res)=>{
    const responseBody= res.data;
  if(responseBody.message === 'success'){
      return responseBody.result
  }
  else{
  const err = new Error('Invalid response')
   err.data = responseBody;
   throw err;
    }
  })
  .catch((err) => {
    throw err;
  });

}
export async function getReimbursementReasonList() {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.get(`${config.baseUrl}/claims-support/get-reimbursement-reason-list`,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody.result

    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });

}

export async function getReimbursementDoctorList() {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.get(`${config.baseUrl}/claims-support/get-reimbursement-doctor-list`,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody.result

    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });

}

export async function getReimbursementSponsorDoctorList() {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.get(`${config.baseUrl}/claims-support/reimbursements/doctor-sponsors`,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody.data

    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });

}
export async function addDoctorSponsors(body) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.post(`${config.baseUrl}/claims-support/reimbursements/doctor-sponsors `,body,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody.responseMessage

    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });

}
export async function removeDoctorSponsors(doctorId) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.delete(`${config.baseUrl}/claims-support/reimbursements/doctor-sponsors?doctorId=${doctorId}`,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody.responseMessage

    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });

}

export async function updateDoctorSponsors(body) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.put(`${config.baseUrl}/claims-support/reimbursements/doctor-sponsors`,body,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody.responseMessage
    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });

}
export async function transferReimbursementCase(body) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.post(`${config.baseUrl}/claims-support/v2/reimbursements/reassign-reimbursements-doctor`,body,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody.responseMessage
    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });

}
export const  downloadReimbursementCsvFile =  (params)=> {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  const urlQuery = new URLSearchParams(params).toString();

  const url = `/claims-support/download-reimbursements-csv?${urlQuery}`;
  const method = 'GET';
  return baseRequestWithHeadersFileResponse(url, method, null);
    
}

export const getWalletBalanceByReimbursementType = (reimbursementId , procedureId) => {
  const url = `/claims-support/reimbursement/${reimbursementId}/wallet-balance/${procedureId}`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
}

export const addRemarkForDoc = (opsUserId,remark,userId,reimbursementId,selectedReason) => {
  const url = '/claims-support/reimbursement-doc-remark'
  const method = 'POST';
  const body = {opsUserId,remark,userId,reimbursementId,selectedReason}
  return baseRequest(url, method, null,body)
}

export const getRemarkForDoc = (appliedFilters,searchInput) => {
 if (searchInput) {
  const url = `/claims-support/get-Qc-Remarks`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, {
    q: searchInput
  });
}
const urlQuery = new URLSearchParams(appliedFilters).toString()
console.log(urlQuery,'[urlQuery]')
const url = `/claims-support/get-Qc-Remarks?${urlQuery}`;
const method = 'GET';
return baseRequestWithHeaders(url,method,null,null)
}

export const getNotificationRemark = (limit) => {
  const url = `/claims-support/remarks-notification?${limit}`;
  const method = 'GET';
  return baseRequest(url, method)
}

export const getRemarks = (reimbursementId) => {
  const url = `/claims-support/get-qc-remark/${reimbursementId}`
  const method = 'GET';
  return baseRequest(url,method)
}


export async function getReimbursementDeficiencyRemarks () {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.get(`${config.baseUrl}/claims-support/reimbursements/deficiency-remarks`,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody

    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });
    
}

export async function verifyDoctorRegistration (body) {

  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.post(`${config.baseUrl}/claims-support/reimbursement/verify-prescription-doctor`,body,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody
    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });
}


  
export async function getReimbursementPrePostReq (claimId) {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.get(`${config.baseUrl}/claims-support/get/reimbursement/pre-post-request/${claimId}`,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody
    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });
}

export async function updateDoctorRegistration (body) {
const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return  await axios.post(`${config.baseUrl}/claims-support/reimbursement/verify-doctor-registration`,body,{headers}).then((res)=>{
    const responseBody= res.data;
    if(responseBody.message === 'success'){
      return responseBody
    }
    else{
      const err = new Error('Invalid response')
      err.data = responseBody;
      throw err;
    }
  })
  .catch((err) => {
    throw err;
  });
}

export const getDoctorNameSuggestion = (searchInput) => {
  const url = `/claims-support/doctor-name-suggestion`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, {
    doctorName: searchInput,
  });
};


export const addClarificationReason = (body) => {
  const url = `/claims-support/reimbursement-clarification`;
  const method = 'PUT';
  return baseRequest(url, method, null, body);
};

export const addClarificationSolution = (body) => {
  const url = `/claims-support/reimbursement-clarification-solution`;
  const method = 'PUT';
  return baseRequest(url, method, null, body);
};

export const pastClaimData = (reimbursementId,pastClaimStdate,pastClaimEdDate) => {
  const url = `/claims-support/user-past-claims-details?reimbursementId=${reimbursementId}&claimSd=${pastClaimStdate}&claimEd=${pastClaimEdDate}`;
  const method = 'GET';
  return baseRequest(url, method, null, );
};

export async function getClaimsRemarkList() {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  return await axios
    .get(
      `${config.baseUrl}/claims-support/get-deficiency-and-rejection-remarks`,
      {
        headers,
      }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.result;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}


export const downloadReimbursementDoctorsCountFile = (params) => {
  const headers = {};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  const urlQuery = new URLSearchParams(params).toString();

  const url = `/claims-support/download-mis-report-csv?${urlQuery}`;
  const method = 'GET';
  return baseRequestWithHeadersFileResponse(url, method, null);
};
