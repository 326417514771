import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Select from 'react-select';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';
import { FaArrowLeft,FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import { SingleDatePicker } from 'react-dates';
import PastClaims from './PastClaims';
// import '../../styling/table.css';
import NavBar from '../layout/NavBar';
import ReactTable from 'react-table';

// import '../../styling/table.css';
import {
  placeCall,
  updateClaim,
  updateClaimToDocumentsDeficient,
  getClaim,
  getReimbursementProcedures,
  viewCommentRequest,
  copyClaim,
  deleteClaim,
  deleteInvoiceItem,
  deleteInvoiceUrl,
  deletePrescriptionUrl,
  submitPrescriptionsForDigitization,
  getPreviousClaimedAmountByUser,
  uploadInvoiceFile,
  uploadPrescriptionFile,
  uploadMerFile,
  getWalletBalanceByReimbursementType,
  getReimbursementDeficiencyRemarks,

  getRemarks,
  verifyDoctorRegistration,
  updateDoctorRegistration,
  getDoctorNameSuggestion,
  getReimbursementPrePostReq,
  addClarificationReason,
  addClarificationSolution,
  pastClaimData
} from '../../services/api/reimburse-claims';
import appConfig from '../../config/app-config';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
} from 'reactstrap';
import ConfirmModal from '../offline-consultations/ConfirmModal';
import ReimbursementPrePost from './ReimbursementPrePost';
import CommentList from './comments/commentList';
import CommentForm from './comments/commentForm';
import { connect } from 'react-redux';
import RemarksConfirmModal from './RemarksConfirmModal';


const ApprovedText = styled.span`
  color: ${(props) => props.color};
`;
const CrossIcon = styled(FaTimes)`
  font-size: 1rem;
  font-weight: normal;
  color: #8752cb;
`;
const ButtonsComponent = styled.div``;
const customStylesModal = {
  content: {
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '40%',
    height: '45%',
    transform: 'translate(-40%, -10%)',
    position: 'absolute',
    top: '30%',
    backgroundColor: 'white',
  },
};
const clarificationOptions =[
  {
    label: 'Maternity Related Claim',
    value: 'Maternity Related Claim',
  },
  {
    label: 'Doctor details not found on Google',
    value: 'Doctor details not found on Google',
  },  {
    label: 'Diagnosis is mandatory',
    value: 'Diagnosis is mandatory',
  },  {
    label: 'Other',
    value: 'Other',
  },
  
]

const customStylesModal2 = {
  content: {
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '80%',
    transform: 'translate(-40%, -10%)',
    position: 'absolute',
    top: '20%',
    backgroundColor: 'white',
  },
};



class ReimburseClaimRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clarificationstatus:'',
      pastClaimModal:false,
      pastClaimStdate:null,
      pastClaimEdDate:null,
      pastClaimArr:[],
      procedureId: null,
      doctorRegVerified: 0,
      doctorRegId: '',
      doctorName: '',
      doctrorRegModal: false,
      registrationDoctorArr: [],
      doctorTableModal: false,
      reimbursementPrePostRequests: [],
      prePostModal: false,
      deficiencyRemarksArray: [],
      reimburseRequest: null,
      validationChecksErrored: false,
      validationChecksError: '',
      prescriptionInvalid: false,
      invoiceDateDifferent: false,
      detailsMismatch: false,
      invalidStamp: false,
      oldInvoice: false,
      invalidDoctorName: false,
      invalidPatientName: false,
      blurredPicture: false,
      genuinePrescription: false,
      signatureMissing: false,
      ahcDetails: [],
      inappropriatePicture: false,
      invalidReimbursement: false,
      patientNotCovered: false,
      invoiceBeforePolicyDate: false,
      invoiceInAnotherClaim: false,
      itemsNotPrescribed: false,
      documentsMissing: false,
      invoiceNotItemized: false,
      sumInsuredExhausted: false,
      policyNotActive: false,
      outOfPolicy: false,
      registrationCheckFailed: null,
      invoiceBeforePrescriptionDate: null,
      incompleteDocumentAfterReminder: false,
      policyDetails: null,
      bankDetails: null,
      policyDependents: [],
      reimbursementFor: -1,
      reimbursementForDependent: false,
      invoiceId: null,
      invoiceName: '',
      invoiceDate: null,
      invoiceDateInputFocused: false,
      invoiceNumber: '',
      invoiceItems: [],
      finalApprovalStatus: 'pending',
      updating: false,
      procedureDetails: null,
      amountClaimedForProcedure: 0,
      confirmModal: {
        isOpen: false,
        header: '',
        body: '',
        onSubmit: null,
        requestId: null,
        onClose: null,
        buttonLoading: false,
      },
      qcRemarkModal: {
        isOpen: false,
        onSubmit: null,
        requestId: null,
        onClose: false,
        buttonLoading: false,
      },
      availableProcedures: [],
      editingFinalApprovedPrice: false,
      consultationDetails: null,
      prescriptions: [],
      selectedPrescriptionFiles: [],
      digitizing: false,
      loading: false,
      isCriticalIllness: false,
      comments: [],
      oracleDentalCheck: false,
      oracleCiScan: false,
      documentsDeficientOption: [
        {
          label: 'Prescription',
          value: 'prescription',
        },
        {
          label: 'Invoice',
          value: 'invoice',
        },
        {
          label: 'Labs Report',
          value: 'lab-report',
        },
      ],
      documentsDeficientRemark: null,
      selectedDocumentsDeficient: [],
      walletBalanceAmount: null,
      ciWalletBalanceAmount: null,
      remark: '',
      remarkData: [],
      selectedReason: '',
      selectedDoctor: null,
      doctorNameSuggestions: [],
      doctorVerifyByGoogleSearch:false,
    };
    this.procedureSelectionRef = React.createRef();
    const { state } = props.location;
    if (state && state.reimburseRequest) {
      console.log({ reimburseRequest: state.reimburseRequest });
      this.state.reimburseRequest = state.reimburseRequest;
    } else {
      // props.history.replace('/reimburse-claims');
    }
    this.getDeficiencyRemarks();
    this.getReimbursementPrePost();
    this.pastClaim();
  }

  componentDidMount() {
    this.getClaim();
  }

  columns = [
    {
      Headers: '',
      accessor: 'doctorId',
      Cell: (data, record) => {
        return (
          <input
            type={'radio'}
            checked={
              this.state.selectedDoctor &&
              this.state.selectedDoctor.doctorId === data.original.doctorId
            }
            onChange={() => {
              this.setState({
                selectedDoctor: data.original,
              });
            }}
          />
        );
      },
      filterable: false,
    },
    {
      Header: 'Registration No',
      style: { whiteSpace: 'unset' },
      accessor: 'registrationNo',
      render: (text) => {
        console.log(text);
        return <p>{text.registrationNo}</p>;
      },
      filterable: true,
    },
    {
      Header: 'Doctor Name',
      style: { whiteSpace: 'unset' },
      accessor: 'firstName',
      render: (text) => {
        console.log(text);
        return <p>{text.firstName}</p>;
      },
      filterable: true,
      filterMethod: (filter, row) => {
        return row[filter.id].includes(filter.value);
      },
    },
    {
      Header: 'Registration Date',
      style: { whiteSpace: 'unset' },
      accessor: 'regDate',
      render: (text) => {
        return <p>{text.regDate}</p>;
      },
      filterable: false,
    },
  ];

  getReimbursementPrePost = () => {
    const { params } = this.props.match;
    getReimbursementPrePostReq(params.claimId)
      .then((resposne) => {
        this.setState({ reimbursementPrePostRequests: resposne.result });
      })
      .catch((err) => {});
  };

  componentDidUpdate(_, prevState) {
    const { reimburseRequest } = this.state;
    const { reimburseRequest: prevReimburseRequest } = prevState;
    if (
      reimburseRequest &&
      reimburseRequest.reimbursementId &&
      prevReimburseRequest !== reimburseRequest
    ) {
      this.fetchRemark();
    }
  }

  getClaim() {
    const { reimburseRequest } = this.state;
    const { params } = this.props.match;
    getClaim((reimburseRequest || {}).reimbursementId || params.claimId)
      .then((result) => {
        let invoiceDate;
        if ((result.result.reimbursementDetails || {}).invoiceDate) {
          invoiceDate = moment(
            (result.result.reimbursementDetails || {}).invoiceDate,
            'DD/MM/YYYY'
          );
        }
        const rejectionFlags = result.result.rejectionFlags;
        const prescriptionFiles =
          (result.result.reimbursementDetails.prescriptionLink || '').split(
            ','
          ) || [];
        this.setState({
          isJnJClaim: result.result.isJnJClaim,
          doctorRegVerified:
            result.result.reimbursementDetails.doctorRegVerified,
            doctorVerifyByGoogleSearch:result.result.reimbursementDetails.doctorVerifyByGoogleSearch,
          invoiceItems: result.result.reimbursementInvoiceItems,
          reimburseRequest: result.result.reimbursementDetails,
          isCriticalIllness:
            result.result.reimbursementDetails &&
            result.result.reimbursementDetails.isCriticalIllness
              ? true
              : false,
          invoiceId:
            (result.result.reimbursementInvoice || {}).invoiceId || null,
          invoiceName:
            (result.result.reimbursementInvoice || {}).invoiceName || '',
          invoiceDate,
          invoiceNumber:
            (result.result.reimbursementInvoice || {}).invoiceNumber || '',
          finalApprovalStatus:
            (result.result.reimbursementDetails || {}).requestStatus ||
            'pending',
          policyDetails: result.result.policyDetails,
          bankDetails: result.result.bankDetails,
          ahcDetails: result.result.previousAhc,
          policyDependents: result.result.policyDependents,
          reimbursementForDependent: result.result.reimbursementDetails.relative
            ? true
            : false,
          reimbursementFor: result.result.reimbursementDetails.relative
            ? result.result.reimbursementDetails.dependentId
            : (result.result.policyDetails || {}).holderId,
          procedureDetails: result.result.procedureDetails,
          amountClaimedForProcedure: result.result.amountClaimedForProcedure,
          ...rejectionFlags,
          consultationDetails: result.result.consultationDetails,
          prescriptions: result.result.prescriptionDetails.map((ele) => ({
            files: ele.files.split(',').map((link, idx) => {
              const findIndex = prescriptionFiles.findIndex(
                (file) => file === link
              );
              if (findIndex !== -1) {
                return {
                  label: `Prescription File ${findIndex + 1}`,
                  value: link,
                };
              } else {
                return {
                  label: 'Prescription File',
                  value: link,
                };
              }
            }),
            status: ele.digitizationStatus,
          })),
          selectedPrescriptionFiles: result.result.prescriptionDetails.reduce(
            (acc, ele) =>
              acc.concat(
                ele.files.split(',').map((file) => ({
                  label: 'Prescription',
                  value: file,
                }))
              ),
            []
          ),
        });
        if (result.result.previousClaimAmountFetch) {
          this.getPreviousClaimedAmount(
            result.result.reimbursementDetails.userId,
            result.result.reimbursementDetails.invoiceDate
          );
        }
        console.log(
          result.result.prescriptionDetails.map((ele) => ({
            files: ele.files.split(',').map((link, idx) => {
              const findIndex = prescriptionFiles.findIndex(
                (file) => file === link
              );
              if (findIndex !== -1) {
                return {
                  label: `Prescription File ${findIndex + 1}`,
                  value: link,
                };
              } else {
                return {
                  label: 'Prescription File',
                  value: link,
                };
              }
            }),
            status: ele.digitizationStatus,
          }))
        );
        this.handleSelectReimbursementType({
          value: this.procedureSelectionRef.current.state.value.value,
        });
        return getReimbursementProcedures(
          result.result.reimbursementDetails.userId,
          result.result.reimbursementDetails.reimbursementId
        );
      })
      .then((result) => {
        this.setState({
          availableProcedures: result.result.map((ele) => {
            return {
              value: ele.procedureId,
              label: ele.title,
              metaData: ele,
            };
          }),
        });
        this.fetchCommentByOrderId();
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error fetching claim details');
      });
  }

  closePrePostModal = () => {
    this.setState({ prePostModal: false });
  };

  openPrePostModal = () => {
    this.setState({ prePostModal: true });
  };

  getPreviousClaimedAmount(userId, invoiceDate) {
    getPreviousClaimedAmountByUser(userId, invoiceDate)
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            amountClaimedForProcedure: res.result,
          });
        } else {
          this.setState({
            amountClaimedForProcedure: 'UNAVAILABLE',
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          amountClaimedForProcedure: 'UNAVAILABLE',
        });
      });
  }

  addInvoiceItem = () => {
    const { invoiceItems } = this.state;
    const updatedInvoiceItems = Array.from(invoiceItems);
    updatedInvoiceItems.push({
      itemName: '',
      itemPrice: 0,
      approvedAmount: 0,
      remarks: '',
    });
    this.setState({
      invoiceItems: updatedInvoiceItems,
    });
  };

  verifyDoctorReg = () => {
    const { doctorRegId } = this.state;
    if (doctorRegId) {
      const body = {
        registrationNo: doctorRegId,
      };
      verifyDoctorRegistration(body)
        .then((result) => {
          if (result.result.length > 0) {
            this.setState({
              registrationDoctorArr: result.result,
              doctorTableModal: true,
              doctrorRegModal: false,
              doctorNameSuggestions: [],
            });
          } else {
            // this.setState({doctrorRegModal:false});
            window.alert('Doctor is not found');
          }
        })
        .catch(() => {});
    } else {
      window.alert('RegistrationId is required');
    }
  };
  OnChangeDoctorName = (e) => {
    console.log(e);
    this.setState({ doctorName: e });
  };
  OnRegistrationNumber = (e) => {
    this.setState({ doctorRegId: e.target.value });
  };
  selectedDate = (date ,dateString)=>{
    this.setState({pastClaimStdate:dateString[0],pastClaimEdDate:dateString[1]},()=>{
      this.pastClaim();
    })
  
  }

  updateFinalStatus() {
    const {
      invoiceItems,
      reimburseRequest,
      policyDetails,
      isJnJClaim,

      invoiceDate,
      prescriptionInvalid,
      invoiceDateDifferent,
      detailsMismatch,
      invalidStamp,
      oldInvoice,
      invalidDoctorName,
      invalidPatientName,
      blurredPicture,
      genuinePrescription,
      signatureMissing,
      inappropriatePicture,
      invalidReimbursement,
      patientNotCovered,
      invoiceBeforePolicyDate,
      invoiceInAnotherClaim,
      itemsNotPrescribed,
      documentsMissing,
      invoiceNotItemized,
      outOfPolicy,
      sumInsuredExhausted,
      policyNotActive,
      registrationCheckFailed,
      invoiceBeforePrescriptionDate,
      incompleteDocumentAfterReminder,
    } = this.state;
    if (
      prescriptionInvalid ||
      invoiceDateDifferent ||
      detailsMismatch ||
      invalidStamp ||
      oldInvoice ||
      invalidDoctorName ||
      invalidPatientName ||
      blurredPicture ||
      genuinePrescription ||
      signatureMissing ||
      inappropriatePicture ||
      invalidReimbursement ||
      patientNotCovered ||
      invoiceBeforePolicyDate ||
      invoiceInAnotherClaim ||
      documentsMissing ||
      itemsNotPrescribed ||
      invoiceNotItemized ||
      outOfPolicy ||
      sumInsuredExhausted ||
      policyNotActive ||
      registrationCheckFailed ||
      invoiceBeforePrescriptionDate ||
      incompleteDocumentAfterReminder
    ) {
      this.setState({
        finalApprovalStatus: 'rejected',
      });
      return;
    }
    // if (
    //   invoiceItems.length > 0 &&
    //   invoiceItems.every((ele) => Number.parseFloat(ele.approvedAmount) === 0)
    // ) {
    //   this.setState({
    //     finalApprovalStatus: invoiceItems.length === 0 ? 'pending' : 'rejected',
    //   });
    //   return;
    // }
    if (policyDetails.policyStartDate && invoiceDate) {
      const policyStartDate = moment(
        policyDetails.policyStartDate,
        'DD/MM/YYYY'
      );
      if (invoiceDate.isBefore(policyStartDate) && !isJnJClaim) {
        this.setState({
          finalApprovalStatus: 'rejected',
          invoiceBeforePolicyDate: true,
        });
        return;
      }
    }
    if (invoiceItems.length === 0) {
      this.setState({
        finalApprovalStatus: 'pending',
      });
      return;
    }
    // Compute the total approved amount
    const totalApprovedAmount = invoiceItems.reduce(
      (acc, ele) => acc + Number.parseFloat(ele.approvedAmount),
      0
    );
    if (reimburseRequest.reimbursementAmount) {
      if (reimburseRequest.reimbursementAmount === totalApprovedAmount) {
        this.setState({
          finalApprovalStatus: 'approved',
        });
      } else {
        this.setState({
          finalApprovalStatus: 'partially-approved',
        });
      }
    } else {
      const totalInvoiceAmount = invoiceItems.reduce(
        (acc, ele) => acc + Number.parseFloat(ele.itemPrice),
        0
      );
      console.log(totalInvoiceAmount, totalApprovedAmount);
      if (totalInvoiceAmount === totalApprovedAmount) {
        this.setState({
          finalApprovalStatus: 'approved',
        });
      } else {
        this.setState({
          finalApprovalStatus: 'partially-approved',
        });
      }
    }
  }

  onInvoiceItemChanged = (idx, key) => (e) => {
    const { invoiceItems } = this.state;
    if (idx === -1) {
      if (key === 'invoiceName') {
        const value = e.target.value;
        const formattedValue = value
          .split(' ')
          .reduce(
            (acc, ele, idx) =>
              `${acc}${idx === 0 ? '' : ' '}${ele
                .substring(0, 1)
                .toUpperCase()}${ele.substring(1)}`,
            ''
          );
        this.setState({
          invoiceName: formattedValue,
        });
        return;
      }
      this.setState({
        [key]: e.target.value,
      });
      return;
    }
    const updatedInvoiceItems = Array.from(invoiceItems);
    if (key === 'itemPrice') {
      // Update both item price and approvedAmount
      updatedInvoiceItems[idx] = Object.assign(updatedInvoiceItems[idx], {
        itemPrice: e.target.value,
        approvedAmount: e.target.value,
      });
    } else {
      updatedInvoiceItems[idx] = Object.assign(updatedInvoiceItems[idx], {
        [key]: e.target.value,
      });
    }
    this.setState({
      invoiceItems: updatedInvoiceItems,
    });
    this.updateFinalStatus();
  };

  toggleIsCriticalIllness = () => {
    let OB = {
      ...this.state.reimburseRequest,
    };
    OB.isCriticalIllness = !this.state.reimburseRequest.isCriticalIllness;
    this.setState({
      reimburseRequest: OB,
      isCriticalIllness: !this.state.isCriticalIllness,
    });
  };
  //     isCriticalIllness: !this.state.isCriticalIllness,
  //   });
  // };
  updateClaim = () => {
    const {
      doctorRegVerified,
      reimburseRequest,
      procedureId,
      availableProcedures,
    } = this.state;

    const {
      invoiceId,
      invoiceName,
      invoiceDate,
      invoiceNumber,
      invoiceItems,
      prescriptionInvalid,
      invoiceDateDifferent,
      detailsMismatch,
      invalidStamp,
      oldInvoice,
      invalidDoctorName,
      invalidPatientName,
      blurredPicture,
      genuinePrescription,
      signatureMissing,
      inappropriatePicture,
      invalidReimbursement,
      patientNotCovered,
      invoiceBeforePolicyDate,
      invoiceInAnotherClaim,
      itemsNotPrescribed,
      documentsMissing,
      invoiceNotItemized,
      outOfPolicy,
      sumInsuredExhausted,
      reimbursementForDependent,
      reimbursementFor,
      policyNotActive,
      isCriticalIllness,
      oracleCiScan,
      oracleDentalCheck,
      registrationCheckFailed,
      invoiceBeforePrescriptionDate,
      doctorVerifyByGoogleSearch,
      incompleteDocumentAfterReminder,
    } = this.state;
    if (
      prescriptionInvalid ||
      invoiceDateDifferent ||
      detailsMismatch ||
      invalidStamp ||
      oldInvoice ||
      invalidDoctorName ||
      invalidPatientName ||
      blurredPicture ||
      genuinePrescription ||
      signatureMissing ||
      inappropriatePicture ||
      invalidReimbursement ||
      patientNotCovered ||
      invoiceBeforePolicyDate ||
      invoiceInAnotherClaim ||
      itemsNotPrescribed ||
      documentsMissing ||
      invoiceNotItemized ||
      outOfPolicy ||
      sumInsuredExhausted ||
      policyNotActive ||
      registrationCheckFailed ||
      invoiceBeforePrescriptionDate ||
      incompleteDocumentAfterReminder
    ) {
      // Basic validation checks failed
      this.setState({ updating: true });
      console.log(reimbursementForDependent, reimbursementFor, '[yoyi]');
      updateClaim({
        reimbursementId: reimburseRequest.reimbursementId,
        dependentId: reimbursementForDependent ? reimbursementFor : null,
        prescriptionInvalid,
        invoiceDateDifferent,
        detailsMismatch,
        invalidStamp,
        oldInvoice,
        invalidDoctorName,
        invalidPatientName,
        blurredPicture,
        genuinePrescription,
        signatureMissing,
        inappropriatePicture,
        invalidReimbursement,
        patientNotCovered,
        invoiceBeforePolicyDate,
        invoiceInAnotherClaim,
        itemsNotPrescribed,
        documentsMissing,
        invoiceNotItemized,
        outOfPolicy,
        sumInsuredExhausted,
        policyNotActive,
        isCriticalIllness,
        doctorVerifyByGoogleSearch,
        oracleCiScan,
        oracleDentalCheck,
        reimburseRequestStatus: reimburseRequest.requestStatus
          ? reimburseRequest.requestStatus
          : null,
        registrationCheckFailed,
        invoiceBeforePrescriptionDate,
        incompleteDocumentAfterReminder,
        procedureId: this.procedureSelectionRef.current
          ? this.procedureSelectionRef.current.state.value.value
          : null,
      })
        .then((result) => {
          if (result.message === 'success') {
            toast.success('Claim updated!');
          } else {
            toast.error(
              `Failed to update claim- ${
                result.errorMessage || 'Something went wrong'
              }`
            );
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            `Failed to update claim- ${
              err.errorMessage || 'Something went wrong'
            }`
          );
        })
        .finally(() => {
          this.setState({ updating: false });
          this.getClaim();
        });
      return;
    }
    let verificationRequired = true;
    if (procedureId) {
      const procedureDetails = availableProcedures.find(
        (e) => e.value === procedureId
      );
      if (
        procedureDetails &&
        ['vaccine', 'health-checkups'].includes(
          procedureDetails.metaData.procedureName
        )
      ) {
        verificationRequired = false;
      }
    }
    if (
      doctorRegVerified === 0 &&
      reimburseRequest.prescriptionLink &&
      reimburseRequest.sponsorId === 731 &&
      verificationRequired &&
      false
    ) {
    } else {
      const requestedAmount = invoiceItems.reduce(
        (acc, ele) => acc + Number.parseFloat(ele.itemPrice),
        0
      );
      const approvedAmount = invoiceItems.reduce(
        (acc, ele) => acc + Number.parseFloat(ele.approvedAmount),
        0
      );
      this.setState({ updating: true });
      updateClaim({
        reimbursementId: reimburseRequest.reimbursementId,
        dependentId: reimbursementForDependent ? reimbursementFor : null,
        invoiceId,
        doctorVerifyByGoogleSearch,
        invoiceNumber,
        invoiceName,
        invoiceDate: invoiceDate.format('DD/MM/YYYY'),
        invoiceItems,
        requestedAmount,
        approvedAmount,
        isCriticalIllness,
        procedureId: this.procedureSelectionRef.current
          ? this.procedureSelectionRef.current.state.value.value
          : null,
        oracleCiScan,
        oracleDentalCheck,
        reimburseRequestStatus: reimburseRequest.requestStatus
          ? reimburseRequest.requestStatus
          : null,
      })
        .then((result) => {
          if (result.message === 'success') {
            toast.success('Claim updated!');
            this.getClaim();
          } else {
            toast.error(
              `Failed to update claim- ${
                result.errorMessage || 'Something went wrong'
              }`
            );
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(
            `Failed to update claim- ${
              err.errorMessage || 'Something went wrong'
            }`
          );
        })
        .finally(() => {
          this.setState({ updating: false });
        });
    }
  };

  onValidationCheckChange = (index) => (event) => {
    this.setState(
      {
        [index]: event.target.checked,
      },
      () => {
        this.updateFinalStatus();
      }
    );
  };
  onDoctorVerifyCheck = ()=>(event)=>{
    this.setState({
      doctorVerifyByGoogleSearch:event.target.checked
    })
  }

  selectReimbursementForDependent = (dependentId) => () => {
    this.setState({
      reimbursementForDependent: true,
      reimbursementFor: dependentId,
    });
  };

  selectReimbursementForHolder = () => {
    const { policyDetails } = this.state;
    this.setState({
      reimbursementForDependent: false,
      reimbursementFor: policyDetails.holderId,
    });
  };

  requestCall = () => {
    const { reimburseRequest } = this.state;
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Call ${reimburseRequest.userName}`,
        body: `Are you sure you want to place call to ${
          reimburseRequest.userName
        } regarding claim made for ${
          reimburseRequest.relative || reimburseRequest.userName
        } ${
          reimburseRequest.relative ? '(' + reimburseRequest.relation + ')' : ''
        }`,
        onSubmit: this.placeCall(reimburseRequest.reimbursementId),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  placeCall = (reimbursementId) => () => {
    const { confirmModal } = this.state;
    console.log('Placing');
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    return placeCall(reimbursementId)
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Call placed successfully!');
        } else {
          toast.error('Error placing call');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error placing call');
      })
      .finally(() => {
        this.setState({
          confirmModal: {
            isOpen: false,
          },
        });
      });
  };

  getDeficiencyRemarks() {
    return getReimbursementDeficiencyRemarks()
      .then((res) => {
        const deficiencyRemarksArray = res.data.map((data) => {
          return {
            value: data.id,
            label: data.remark,
          };
        });
        this.setState({
          deficiencyRemarksArray,
        });
      })
      .catch((err) => {
        toast.error('Error');
        console.log(err);
      });
  }
  requestCopyClaim = () => {
    const { reimburseRequest } = this.state;
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Copy ${reimburseRequest.reimbursementId}`,
        body: `Are you sure you want to copy this claim? This will create a new claim for the user.`,
        onSubmit: this.copyClaim(reimburseRequest.reimbursementId),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  requestDocumentsDeficient = () => {
    const { reimburseRequest, deficiencyRemarksArray } = this.state;
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Documents deficient ${reimburseRequest.reimbursementId}`,
        body: (
          <div className="container">
            <div className="col-12">
              <FormGroup check>
                <Label for="DocumentsDeficient" className="mr-2">
                  Remark:
                </Label>
                {/* <input
                  type="text"
                  name="DocumentsDeficient"
                  id="DocumentsDeficient"
                  onChange={this.onHandleRemarkChanged}
                /> */}
                <Select
                  isMulti
                  placeholder="Select Remark"
                  onChange={this.onHandleRemarkChanged}
                  options={deficiencyRemarksArray}
                />
              </FormGroup>
              <FormGroup check>
                <Label for="DocumentsDeficient">
                  Select documents deficient:
                </Label>
                <Select
                  isMulti
                  options={this.state.documentsDeficientOption}
                  onChange={this.onHandleDocumentsDeficientChanged}
                />
              </FormGroup>
            </div>
          </div>
        ),
        onSubmit: () =>
          this.documentsDeficient(reimburseRequest.reimbursementId),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };
  onHandleRemarkChanged = (e) => {
    this.setState(
      {
        documentsDeficientRemark: e,
      },
      () => {
        console.log('data-- ', this.state.documentsDeficientRemark);
      }
    );
  };
  onHandleDocumentsDeficientChanged = (e) => {
    this.setState({
      selectedDocumentsDeficient: e,
    });
  };
  documentsDeficient = (reimbursementId) => {
    let documentsDeficient = '';
    if (this.state.selectedDocumentsDeficient.length > 0) {
      this.state.selectedDocumentsDeficient.forEach((element) => {
        documentsDeficient =
          documentsDeficient.length > 0
            ? `${documentsDeficient},${element.value}`
            : element.value;
      });
    }
    if (!documentsDeficient.length) {
      return toast.error('Please select the deficient document');
    }
    const { documentsDeficientRemark } = this.state;
    const remarksData = documentsDeficientRemark
      .slice(',')
      .map((e) => {
        return e.label;
      })
      .join(',');
    const body = {
      reimbursementId,
      remark: remarksData,
      documentsDeficient,
      procedureId: this.state.procedureId,
    };
    console.log({ body });
    updateClaimToDocumentsDeficient(body)
      .then((result) => {
        if (result.message === 'success') {
          toast.success('Claim updated!');
          this.setState({ confirmModal: { isOpen: false } });
        } else {
          toast.error(
            `Failed to update claim- ${
              result.errorMessage || 'Something went wrong'
            }`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          `Failed to update claim- ${
            err.errorMessage || 'Something went wrong'
          }`
        );
      })
      .finally(() => {
        this.setState({ updating: false });
        this.getClaim();
      });
  };
  requestDeleteClaim = () => {
    const { reimburseRequest } = this.state;
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Delete ${reimburseRequest.reimbursementId}`,
        body: (
          <div className="container">
            <div className="col-12">
              <FormGroup check>
                <Label for="deleteReason">
                  Specify a reason to delete this claim
                </Label>
                <input type="text" name="deleteReason" id="deleteReason" />
              </FormGroup>
            </div>
          </div>
        ),
        onSubmit: this.deleteClaim(reimburseRequest.reimbursementId),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  deleteClaim = (reimbursementId) => () => {
    const { confirmModal } = this.state;
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    const reason = document.querySelector('#deleteReason').value;
    if (!reason) {
      toast.error('Please specify a valid reason to delete this claim');
      this.setState({
        confirmModal: {
          isOpen: false,
        },
      });
      return;
    }
    return deleteClaim(reimbursementId, reason)
      .then((res) => {
        if (res.message === 'success') {
          this.getClaim();
        } else {
          toast.error(res.errorMessage || 'Error deleting claim');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.errorMessage || 'Error deleting claim');
      })
      .finally(() => {
        this.setState({
          confirmModal: {
            isOpen: false,
          },
        });
      });
  };

  
  copyClaim = (reimbursementId) => () => {
    const { confirmModal } = this.state;
    console.log('Placing');
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    return copyClaim(reimbursementId)
      .then((res) => {
        if (res.message === 'success') {
          const claimId = res.result.claimId;
          const anchorElement = document.createElement('a');
          anchorElement.setAttribute('href', `/reimburse-claim/${claimId}`);
          anchorElement.setAttribute('target', '_blank');
          anchorElement.click();
        } else {
          toast.error(res.errorMessage || 'Error creating claim copy');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.errorMessage || 'Error creating claim copy');
      })
      .finally(() => {
        this.setState({
          confirmModal: {
            isOpen: false,
          },
        });
      });
  };

  editTotalApprovedAmount = () => {
    this.setState({
      editingFinalApprovedPrice: true,
    });
  };

  saveApprovedAmount = () => {
    const { invoiceItems } = this.state;
    const finalAmount = document.querySelector('#finalApprovedPrice').value;
    const totalAmount = invoiceItems.reduce(
      (acc, ele) => acc + Number.parseFloat(ele.approvedAmount),
      0
    );
    const roundOffInvoiceItem = invoiceItems.findIndex((ele) => ele.roundOff);
    const discountInvoiceItem = invoiceItems.findIndex((ele) => ele.discount);
    // Check if the final amount is above the total computed amount
    const updatedInvoiceItems = Array.from(invoiceItems);
    if (finalAmount > totalAmount) {
      // It's a round up.
      // Check if another round off item already exists, and if so
      // modify that
      // Also check if a discount item exists, remove that and add the
      // discounted price to the round off
      if (roundOffInvoiceItem !== -1) {
        const item = updatedInvoiceItems[roundOffInvoiceItem];
        // Increase the price of the round off item by the difference
        item.itemPrice += finalAmount - totalAmount;
        item.approvedAmount += finalAmount - totalAmount;
      } else if (discountInvoiceItem !== -1) {
        const discountedPrice =
          updatedInvoiceItems[discountInvoiceItem].itemPrice;
        const roundOffItem = {
          itemName: 'Round Off',
          itemPrice: finalAmount - totalAmount + discountedPrice,
          approvedAmount: finalAmount - totalAmount + discountedPrice,
          roundOff: true,
          remarks: '',
        };
        if (roundOffItem.itemPrice === 0) {
          // Just remove the discount item
          updatedInvoiceItems.splice(discountInvoiceItem, 1);
        } else {
          updatedInvoiceItems.splice(discountInvoiceItem, 1, roundOffItem);
        }
      } else {
        updatedInvoiceItems.push({
          itemName: 'Round Off',
          itemPrice: finalAmount - totalAmount,
          approvedAmount: finalAmount - totalAmount,
          roundOff: true,
          remarks: '',
        });
      }
    } else {
      // It's a discount
      // Check if another discount item already exists, and if so modify that
      // Also check if a round off item exists, remove that and subtract the
      // round off price to the discount
      if (discountInvoiceItem !== -1) {
        const item = updatedInvoiceItems[discountInvoiceItem];
        item.itemPrice -= totalAmount - finalAmount;
        item.approvedAmount -= totalAmount - finalAmount;
      } else if (roundOffInvoiceItem !== -1) {
        const roundOffItemPrice =
          updatedInvoiceItems[roundOffInvoiceItem].itemPrice;
        const discountItem = {
          itemName: 'Discount',
          itemPrice: finalAmount - totalAmount + roundOffItemPrice,
          approvedAmount: finalAmount - totalAmount + roundOffItemPrice,
          discount: true,
          remarks: '',
        };
        if (discountItem.itemPrice === 0) {
          // Just remove the round off item
          updatedInvoiceItems.splice(roundOffInvoiceItem, 1);
        } else {
          updatedInvoiceItems.splice(roundOffInvoiceItem, 1, discountItem);
        }
      } else {
        updatedInvoiceItems.push({
          itemName: 'Discount',
          itemPrice: finalAmount - totalAmount,
          approvedAmount: finalAmount - totalAmount,
          discount: true,
          remarks: '',
        });
      }
    }
    console.log(updatedInvoiceItems);
    this.setState({
      invoiceItems: updatedInvoiceItems,
      editingFinalApprovedPrice: false,
    });
  };

  requestDeleteInvoiceItem = (itemId, index) => () => {
    const { reimburseRequest, invoiceItems } = this.state;
    if (!itemId) {
      // Newly added item, just delete
      const updatedInvoiceItems = Array.from(invoiceItems);
      updatedInvoiceItems.splice(index, 1);
      this.setState({
        invoiceItems: updatedInvoiceItems,
      });
      return;
    }
    console.log('Show deletion modal');
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Delete ${reimburseRequest.reimbursementId}`,
        body: 'Are you sure you want to delete this invoice item?',
        onSubmit: this.deleteInvoiceItem(itemId),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  deleteInvoiceItem = (itemId) => () => {
    const { confirmModal, reimburseRequest } = this.state;
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    return deleteInvoiceItem(reimburseRequest.reimbursementId, itemId)
      .then((res) => {
        if (res.message === 'success') {
          this.getClaim();
        } else {
          toast.error(res.errorMessage || 'Error deleting claim');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.errorMessage || 'Error deleting claim');
      })
      .finally(() => {
        this.setState({
          confirmModal: {
            isOpen: false,
          },
        });
      });
  };

  confirmDeleteFile = (fileName, type, url) => () => {
    const { reimburseRequest } = this.state;
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Delete ${fileName}`,
        body: 'Are you sure you want to delete this file?',
        onSubmit: this.deleteFile(type, url),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  deleteFile = (type, invoiceUrl) => () => {
    const { confirmModal, reimburseRequest } = this.state;
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    if (type === 'invoice') {
      return deleteInvoiceUrl(reimburseRequest.reimbursementId, invoiceUrl)
        .then((res) => {
          if (res.message === 'success') {
            this.getClaim();
          } else {
            toast.error(res.errorMessage || 'Error deleting claim');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.errorMessage || 'Error deleting claim');
        })
        .finally(() => {
          this.setState({
            confirmModal: {
              isOpen: false,
            },
          });
        });
    } else {
      return deletePrescriptionUrl(reimburseRequest.reimbursementId, invoiceUrl)
        .then((res) => {
          if (res.message === 'success') {
            this.getClaim();
          } else {
            toast.error(res.errorMessage || 'Error deleting claim');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.errorMessage || 'Error deleting claim');
        })
        .finally(() => {
          this.setState({
            confirmModal: {
              isOpen: false,
            },
          });
        });
    }
  };

  onPrescriptionSelectedChanged = (idx) => (items, action) => {
    const { prescriptions, selectedPrescriptionFiles } = this.state;
    console.log(items, action);
    const isFileAlreadySelected = (file) => {
      console.log(selectedPrescriptionFiles, file);
      return (
        selectedPrescriptionFiles.findIndex(
          (ele) => file.value === ele.value
        ) !== -1
      );
    };
    switch (action.action) {
      case 'select-option':
      case 'set-value': {
        if (isFileAlreadySelected(action.option)) {
          window.alert(
            'You cannot select a file already added to another prescription'
          );
          return;
        }
        const updatedPrescriptions = Array.from(prescriptions);
        const updatedFileList = Array.from(updatedPrescriptions[idx].files);
        updatedFileList.push(action.option);
        updatedPrescriptions[idx] = Object.assign(
          {},
          updatedPrescriptions[idx],
          {
            files: updatedFileList,
          }
        );
        this.setState({
          prescriptions: updatedPrescriptions,
          selectedPrescriptionFiles: updatedPrescriptions.reduce(
            (acc, ele) => acc.concat(ele.files),
            []
          ),
        });
        break;
      }
      case 'remove-value':
      case 'pop-value': {
        const updatedPrescriptions = Array.from(prescriptions);
        const fileList = Array.from(updatedPrescriptions[idx].files);
        const findIndex = fileList.findIndex(
          (ele) => ele.value === action.removedValue.value
        );
        if (findIndex !== -1) {
          fileList.splice(findIndex, 1);
          updatedPrescriptions[idx] = Object.assign(
            {},
            updatedPrescriptions[idx],
            {
              files: fileList,
            }
          );
          this.setState({
            prescriptions: updatedPrescriptions,
            selectedPrescriptionFiles: updatedPrescriptions.reduce(
              (acc, ele) => acc.concat(ele.files),
              []
            ),
          });
        }
        break;
      }
      case 'clear': {
        const updatedPrescriptions = Array.from(prescriptions);
        updatedPrescriptions[idx] = Object.assign(
          {},
          updatedPrescriptions[idx],
          {
            files: [],
          }
        );
        this.setState({
          prescriptions: updatedPrescriptions,
          selectedPrescriptionFiles: updatedPrescriptions.reduce(
            (acc, ele) => acc.concat(ele.files),
            []
          ),
        });
        break;
      }
      default:
        break;
    }
  };

  addPrescription = () => {
    const { prescriptions } = this.state;
    const updatedPrescriptions = Array.from(prescriptions);
    updatedPrescriptions.push({
      files: [],
      status: 'pending',
    });
    this.setState({
      prescriptions: updatedPrescriptions,
    });
  };

  submitForDigitization = () => {
    const { prescriptions, reimburseRequest } = this.state;
    const formattedBody = prescriptions.map((ele) => {
      const array = ele.files.reduce((acc, file) => {
        acc.push(file.value);
        return acc;
      }, []);
      return array;
    });
    this.setState({
      digitizing: true,
    });
    submitPrescriptionsForDigitization(
      reimburseRequest.reimbursementId,
      formattedBody
    )
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Submitted for digitization');
          return;
        }
        toast.error(
          res.errorMessage || 'Something went wrong. Please try again later'
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err.errorMessage || 'Something went wrong. Please try again later'
        );
      })
      .finally(() => {
        this.setState({ digitizing: false });
      });
  };

  addInvoiceFile = () => {
    document.querySelector('#add-invoice-input').click();
  };

  onInvoiceFileAdded = (ev) => {
    const { reimburseRequest } = this.state;
    const reimbursementId = reimburseRequest.reimbursementId;
    const file = document.querySelector('#add-invoice-input').files[0];
    const toastId = toast('Uploading', {
      position: 'top-center',
      closeButton: false,
      closeOnClick: false,
      hideProgressBar: false,
      type: toast.TYPE.INFO,
      progress: 0,
    });
    const onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const { loaded, total } = progressEvent;
        toast.update(toastId, {
          progress: loaded / total,
        });
      }
    };
    if (file) {
      uploadInvoiceFile(reimbursementId, file, onUploadProgress)
        .then((res) => {
          if (res.message === 'success') {
            toast.done(toastId);
            toast.dismiss(toastId);
            toast.success('Upload complete!');
            return;
          }
          toast.done(toastId);
          toast.dismiss(toastId);
          toast.error(
            res.errorMessage || 'Something went wrong. Please try again later'
          );
        })
        .catch((err) => {
          toast.done(toastId);
          toast.dismiss(toastId);
          toast.error(
            err.errorMessage || 'Something went wrong. Please try again later'
          );
        })
        .finally(() => {
          this.getClaim();
        });
    }
  };

  addPrescriptionFile = () => {
    document.querySelector('#add-prescription-input').click();
  };

  onPrescriptionFileAdded = (ev) => {
    const { reimburseRequest } = this.state;
    const reimbursementId = reimburseRequest.reimbursementId;
    const file = document.querySelector('#add-prescription-input').files[0];
    const toastId = toast('Uploading', {
      position: 'top-center',
      closeButton: false,
      closeOnClick: false,
      hideProgressBar: false,
      type: toast.TYPE.INFO,
      progress: 0,
    });
    const onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const { loaded, total } = progressEvent;
        toast.update(toastId, {
          progress: loaded / total,
        });
      }
    };
    if (file) {
      uploadPrescriptionFile(reimbursementId, file, onUploadProgress)
        .then((res) => {
          if (res.message === 'success') {
            toast.done(toastId);
            toast.dismiss(toastId);
            toast.success('Upload complete!');
            return;
          }
          toast.done(toastId);
          toast.dismiss(toastId);
          toast.error(
            res.errorMessage || 'Something went wrong. Please try again later'
          );
        })
        .catch((err) => {
          toast.done(toastId);
          toast.dismiss(toastId);
          toast.error(
            err.errorMessage || 'Something went wrong. Please try again later'
          );
        })
        .finally(() => {
          this.getClaim();
        });
    }
  };

  addMerFile = () => {
    document.querySelector('#add-mer-input').click();
  };

  onMerFileAdded = (ev) => {
    const { reimburseRequest } = this.state;
    const reimbursementId = reimburseRequest.reimbursementId;
    const file = document.querySelector('#add-mer-input').files[0];
    const toastId = toast('Uploading', {
      position: 'top-center',
      closeButton: false,
      closeOnClick: false,
      hideProgressBar: false,
      type: toast.TYPE.INFO,
      progress: 0,
    });
    const onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const { loaded, total } = progressEvent;
        toast.update(toastId, {
          progress: loaded / total,
        });
      }
    };
    if (file) {
      uploadMerFile(reimbursementId, file, onUploadProgress)
        .then((res) => {
          if (res.message === 'success') {
            toast.done(toastId);
            toast.dismiss(toastId);
            toast.success('Upload complete!');
            return;
          }
          toast.done(toastId);
          toast.dismiss(toastId);
          toast.error(
            res.errorMessage || 'Something went wrong. Please try again later'
          );
        })
        .catch((err) => {
          toast.done(toastId);
          toast.dismiss(toastId);
          toast.error(
            err.errorMessage || 'Something went wrong. Please try again later'
          );
        })
        .finally(() => {
          this.getClaim();
        });
    }
  };

  fetchCommentByOrderId = () => {
    const { reimburseRequest } = this.state;
    const reimbursementId = reimburseRequest.reimbursementId;
    viewCommentRequest(reimbursementId, appConfig.REQUEST_CHAT.INTERNAL)
      .then((response) => this.handleComments(response))
      .then((comments) => {
        // setting the state
        this.setState({
          comments,
          requestChat: {
            reimbursementId,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleComments = (response) => {
    const comments = [];
    if (response.message === 'success' && response.results.length) {
      // build comments
      response.results.forEach((c) => {
        comments.push({
          name: c.userName,
          message: c.text,
          time: c.createdAt,
        });
      });
    }
    return comments;
  };

  handleSelectReimbursementType = (e) => {
    const { reimburseRequest } = this.state;
    const procedureId = e.value;
    this.setState({ procedureId: e.value });
    const reimbursementId = reimburseRequest.reimbursementId;
    getWalletBalanceByReimbursementType(reimbursementId, procedureId)
      .then((response) => {
        this.setState({
          walletBalanceAmount: response.result,
          ciWalletBalanceAmount: response.ciWalletBalance,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          walletBalanceAmount: null,
          ciWalletBalanceAmount: null,
        });
      });
  };

  addComment = (comment) => {
    console.log({ comment });
    this.setState({
      loading: false,
      comments: [...this.state.comments, comment],
    });
  };

  fetchRemark = () => {
    const { reimburseRequest } = this.state;
    getRemarks(reimburseRequest.reimbursementId)
      .then((response) => {
        console.log(response.data, '[123Response]');
        if (response.message === 'success') {
          this.setState({
            remark: response.data[0].comments,
            selectedReason: response.data[0].selectedRemarks,
          });
        }
      })
      .catch((err) => {
        console.log(err, '[remarkErrr]');
        toast.error(err || `Error fetching claim Remarks`);
      });
  };
  updateDoctorRegistrationInfo = () => {
    const { reimburseRequest, selectedDoctor, doctorName, doctorRegId } =
      this.state;
    const { registrationNo, firstName, doctorId } = selectedDoctor;
    const body = {
      reimbursementId: reimburseRequest.reimbursementId,
      doctorRegVerifyKey: 1,
      registrationNo,
      firstName,
      doctorId,
      enteredDoctorName: doctorName,
      enteredRegistrationId: doctorRegId,
    };
    updateDoctorRegistration(body)
      .then((res) => {
        this.setState({
          doctorTableModal: false,
          doctorRegVerified: 1,
          selectedDoctor: null,
          doctorRegId: null,
          doctorName: null,
        });
        toast.success('Doctor verified.');
      })
      .catch((err) => {
        toast.error('Error.');
      });
  };
  closeDocotrRegMoadl = () => {
    this.setState({ doctrorRegModal: false });
  };
  closeDoctorTableModal = () => {
    this.setState({ doctorTableModal: false });
  };
  opneDoctorVerifyModal = () => {
    this.setState({ doctrorRegModal: true, doctorNameSuggestions: [] });
  };
  verifyAgain = () => {
    this.setState({
      doctrorRegModal: true,
      doctorTableModal: false,
      doctorNameSuggestions: [],
    });
  };

  onDoctorNameSearch = async (searchText) => {
    console.log(searchText);
    this.setState({
      doctorName: searchText,
    });
    await getDoctorNameSuggestion(searchText)
      .then((res) => {
        console.log(res, '[getDoctorNameSuggestion]');
        const arr = [
          { value: searchText },
          ...res.body.result.map((e) => {
            return { value: e.name };
          }),
        ];
        console.log(arr);
        this.setState({
          doctorNameSuggestions: arr,
        });
        return null;
      })
      .catch(() => {});
  };

  clarificationNeeded = () => {
    const { reimburseRequest } = this.state;
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Clarification Needed ${reimburseRequest.reimbursementId}`,
        body: (
          <div className="container">
            <div className="col-12">
              <Select
              placeholder='Select Clarificatoin Status'
              options={clarificationOptions}
              onChange={(e)=>this.setState({clarificationstatus:e.value})}
              >

              </Select>
              <FormGroup check>
                <Label for="clarificationReason">
                  Please update what clairification is needed
                </Label>
                <input
                  type="text"
                  name="clarificationReason"
                  id="clarificationReason"
                />
              </FormGroup>
            </div>
          </div>
        ),
        onSubmit: this.addClarificationReason(reimburseRequest.reimbursementId),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  addClarificationReason = (reimbursementId) => () => {
    const { confirmModal,clarificationstatus } = this.state;
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    const remark = document.querySelector('#clarificationReason').value;
    if (clarificationstatus==='Other' ) {
      if(!remark){
      toast.error('Please update a clarification valid remark this claim');
      this.setState({
        confirmModal: {
          isOpen: false,
        },
      });
      return;
    }
    }
    const body = {
      reimbursementId,
      remark,
      clarificationstatus
    };
    return addClarificationReason(body)
      .then((res) => {
        if (res.message === 'success') {
          this.getClaim();
          toast.success('Claim sent for clarification');
        } else {
          toast.error(res.errorMessage || 'Error deleting claim');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.errorMessage || 'Error deleting claim');
      })
      .finally(() => {
        this.setState({
          confirmModal: {
            isOpen: false,
          },
        });
      });
  };

  clarificationResolution = () => {
    const { reimburseRequest } = this.state;
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Resolve Clarification ${reimburseRequest.reimbursementId}`,
        body: (
          <div className="container">
            <div className="col-12">
              <h5>
                <strong>Remark by doctor: </strong>
                <span>{reimburseRequest.clarificationRemarks}</span>
              </h5>
            </div>
            <br />
            <div className="col-12">
              <FormGroup check>
                <Label for="clarificationResolution">
                  Please update clairification for above remark
                </Label>
                <input
                  type="text"
                  name="clarificationResolution"
                  id="clarificationResolution"
                />
              </FormGroup>
            </div>
          </div>
        ),
        onSubmit: this.addClarificationResolution(
          reimburseRequest.reimbursementId
        ),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  addClarificationResolution = (reimbursementId) => () => {
    const { confirmModal } = this.state;
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    const remark = document.querySelector('#clarificationResolution').value;
    if (!remark) {
      toast.error('Please update a clarification valid remark this claim');
      this.setState({
        confirmModal: {
          isOpen: false,
        },
      });
      return;
    }
    const body = {
      reimbursementId,
      solution: remark,
    };
    return addClarificationSolution(body)
      .then((res) => {
        if (res.message === 'success') {
          this.getClaim();
          toast.success('Clarification Added');
        } else {
          toast.error(res.errorMessage || 'Error deleting claim');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.errorMessage || 'Error deleting claim');
      })
      .finally(() => {
        this.setState({
          confirmModal: {
            isOpen: false,
          },
        });
      });
  };
   pastClaim= async () =>{
    const { params } = this.props.match;
    const reimbursementId =  params.claimId;
    const {pastClaimStdate,pastClaimEdDate}= this.state;
   await  pastClaimData(
      reimbursementId,
      pastClaimStdate?pastClaimStdate:'' ,
      pastClaimEdDate?pastClaimEdDate:'' ,
      ).then((res)=>{
      
    if(res.message ==='success'){
      this.setState({
        pastClaimArr:res.result
      })
    }

    }).catch((err)=>{

    })

    
   } 

  render() {
    console.log(this.state.isCriticalIllness, '[isCriticalIllness]');
    const {
      finalApprovalStatus,
      invoiceItems,
      invoiceName,
      invoiceDate,
      invoiceDateInputFocused,
      invoiceNumber,
      reimburseRequest,
      updating,
      reimbursementFor,
      reimbursementForDependent,
      invalidReimbursement,
      patientNotCovered,
      invoiceBeforePolicyDate,
      invoiceInAnotherClaim,
      outOfPolicy,
      sumInsuredExhausted,
      policyNotActive,
      policyDependents,
      policyDetails,
      bankDetails,
      procedureDetails,
      amountClaimedForProcedure,
      confirmModal,
      availableProcedures,
      editingFinalApprovedPrice,
      consultationDetails,
      prescriptions,
      selectedPrescriptionFiles,
      digitizing,
      ahcDetails,
      oracleDentalCheck,
      oracleCiScan,

      doctorTableModal,
      registrationDoctorArr,
      registrationCheckFailed,
      invoiceBeforePrescriptionDate,
      doctorVerifyByGoogleSearch,
      incompleteDocumentAfterReminder,
    } = this.state;

    const { history } = this.props;
    const totalAmount = invoiceItems.reduce(
      (acc, ele) => acc + Number.parseFloat(ele.approvedAmount),
      0
    );

    const buttonDisable =
      reimburseRequest &&
      this.props.user &&
      reimburseRequest.sentForQcCheck === 1 &&
      this.props.user.showQcTab !== 1;
    const renderInvoiceItems = (ele, idx) => (
      <Row form key={idx} className="align-items-end">
        <Col xs={6} md={3}>
          <FormGroup check>
            <Label for={`itemName-${idx}`}>Item Name</Label>
            <Input
              type="text"
              name={`itemName-${idx}`}
              id={`itemName-${idx}`}
              value={invoiceItems[idx].itemName}
              onChange={this.onInvoiceItemChanged(idx, 'itemName')}
              // disabled={(reimburseRequest.requestStatus === 'qc-check') &&
              // (this.props.user.showQcTab !== 1) || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')}
              disabled={buttonDisable}
            />
          </FormGroup>
        </Col>
        <Col xs={5} md={2}>
          <FormGroup check>
            <Label for={`itemPrice-${idx}`}>Item Price</Label>
            <Input
              type="text"
              name={`itemPrice-${idx}`}
              id={`itemPrice-${idx}`}
              value={invoiceItems[idx].itemPrice}
              onChange={this.onInvoiceItemChanged(idx, 'itemPrice')}
              // disabled={(reimburseRequest.requestStatus === 'qc-check') &&
              // (this.props.user.showQcTab !== 1)|| (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')}
              disabled={buttonDisable}
            />
          </FormGroup>
        </Col>
        <Col xs={5} md={2}>
          <FormGroup check>
            <Label for={`itemApprovedAmount-${idx}`}>Approved Amount</Label>
            <Input
              type="text"
              name={`itemApprovedAmount-${idx}`}
              id={`itemApprovedAmount-${idx}`}
              value={invoiceItems[idx].approvedAmount}
              // onChange={(reimburseRequest.requestStatus === 'qc-check') &&
              // (this.props.user.showQcTab !== 1) }
              onChange={this.onInvoiceItemChanged(idx, 'approvedAmount')}
              // disabled={(reimburseRequest.requestStatus === 'qc-check') &&
              // (this.props.user.showQcTab !== 1) || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified') }
              disabled={buttonDisable}
            />
          </FormGroup>
        </Col>
        <Col xs={6} md={3}>
          <FormGroup check>
            <Label for={`itemRemarks-${idx}`}>Remarks</Label>
            <Input
              type="text"
              name={`itemRemarks-${idx}`}
              id={`itemRemarks-${idx}`}
              value={invoiceItems[idx].remarks || ''}
              onChange={this.onInvoiceItemChanged(idx, 'remarks')}
              // disabled={(reimburseRequest.requestStatus === 'qc-check') &&
              // (this.props.user.showQcTab !== 1) || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified') }
              disabled={buttonDisable}
            />
          </FormGroup>
        </Col>
        <Col xs={2} md={2} className="text-center">
          <Button
            color="danger"
            outline
            onClick={this.requestDeleteInvoiceItem(ele.itemId, idx)}
            // disabled={(reimburseRequest.requestStatus === 'qc-check') &&
            // (this.props.user.showQcTab !== 1) || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')}
            disabled={buttonDisable}
          >
            <MdDelete />
          </Button>
        </Col>
      </Row>
    );
    const renderApprovalStatus = (approvalStatus) => {
      switch (approvalStatus.toLowerCase()) {
        case 'pending':
          return (
            <ApprovedText className="h4" color="#ffc53d">
              PENDING
            </ApprovedText>
          );
        case 'qc-check':
          return (
            <ApprovedText className="h4" color="">
              QC-Check
            </ApprovedText>
          );
        case 'documents-verified':
          return (
            <ApprovedText className="h4" color="#fa8c16">
              DOCUMENTS VERIFIED
            </ApprovedText>
          );
        case 'partially-approved':
          return (
            <ApprovedText className="h4" color="#95de64">
              PARTIALLY APPROVED
            </ApprovedText>
          );
        case 'approved':
          return (
            <ApprovedText className="h4" color="#237804">
              APPROVED
            </ApprovedText>
          );
        case 'rejected':
          return (
            <ApprovedText className="h4" color="#cf1322">
              REJECTED
            </ApprovedText>
          );
        default:
          return (
            <ApprovedText className="h4" color="#ffc53d">
              PENDING
            </ApprovedText>
          );
      }
    };
    const renderDependent = (ele, idx) => (
      <tr key={idx}>
        <td>{ele.name}</td>
        <td>{ele.dob}</td>
        <td>{ele.policyStartDate}</td>
        <td>{ele.policyEndDate}</td>
        <td>
          <Input
            type="checkbox"
            checked={
              reimbursementForDependent && reimbursementFor === ele.dependentId
            }
            onChange={this.selectReimbursementForDependent(ele.dependentId)}
          />
        </td>
      </tr>
    );
    if (!reimburseRequest) {
      return false;
    }
    const prettyPrintPayoutStatus = (claimStatus) => {
      switch (claimStatus) {
        case 'pending':
          return (
            <h4>
              <span className="badge badge-secondary">Pending</span>
            </h4>
          );
        case 'approved':
          return (
            <h4>
              <span className="badge badge-success">Approved</span>
            </h4>
          );
        case 'settled':
          return (
            <h4>
              <span className="badge badge-success">Settled</span>
            </h4>
          );
        case 'rejected':
        case 'failure':
        case 'reversed':
          return (
            <h4>
              <span className="badge badge-danger">{claimStatus}</span>
            </h4>
          );
        case 'reopened':
          return (
            <h4>
              <span className="badge badge-warning">Reopened</span>
            </h4>
          );
        default:
          return (
            <h4>
              <span className="badge badge-secondary">Pending</span>
            </h4>
          );
      }
    };
    const renderPrescription = (ele, idx) => (
      <div className="col-12" key={idx}>
        <div className="row">
          <div className="col-4">{`Prescription ${idx + 1} (Status: ${
            ele.status
          })`}</div>
          <div className="col-8">
            <Select
              isMulti
              value={ele.files}
              options={reimburseRequest.prescriptionLink
                .split(',')
                .map((link, idx) => ({
                  label: `Prescription File ${idx + 1}`,
                  value: link,
                }))}
              onChange={this.onPrescriptionSelectedChanged(idx)}
            />
          </div>
        </div>
      </div>
    );
    return (
      <div className="container-fluid">
        <div className="row">
          <NavBar />
        </div>
        <div className="row mt-5 pt-1">
          <div className="col-9 mt-3">
            {reimburseRequest.deletionDate && (
              <div className="col-12">
                <div className="alert alert-danger" role="alert">
                  Please note: This claim request has been deleted.
                </div>
              </div>
            )}
            {reimburseRequest.claimStatus === 'reopened' && (
              <div className="col-12">
                <div className="alert alert-primary" role="alert">
                  Please note: This claim request has been reopened. Any amount
                  changes you make will be sent for approval.
                </div>
              </div>
            )}
            <div className="col-1">
              <button
                className="btn btn-link"
                onClick={() => {
                  history.goBack();
                }}
              >
                <FaArrowLeft />
              </button>
            </div>
            <div className="col-11">
              <h2 variant="h2">
                {`Reimbursement Claim for ${
                  reimburseRequest.relative || reimburseRequest.userName
                }(${reimburseRequest.reimbursementId})`}
                {/* {' '}
                {reimburseRequest.emergencyCase ? (
                  <span className="badge badge-danger">Emergency case</span>
                ) : null} */}
              </h2>
            </div>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Patient Name</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p>
                    {reimburseRequest.relative || reimburseRequest.userName}
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Sponsor Name</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p>{reimburseRequest.sponsorName}</p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Reimbursement Type</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <Select
                    isSearchable={false}
                    defaultValue={{
                      value: reimburseRequest.procedureId,
                      label: reimburseRequest.reimbursementType,
                    }}
                    options={availableProcedures}
                    ref={this.procedureSelectionRef}
                    onChange={this.handleSelectReimbursementType}
                    // isDisabled={
                    //   reimburseRequest.requestStatus === 'qc-check' &&
                    //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                    // }
                    isDisabled={buttonDisable}
                  />
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Invoice Date</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p>
                    {invoiceDate
                      ? invoiceDate.format('Do MMM YYYY')
                      : 'Not Available'}
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Request Date</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p>{reimburseRequest.requestDate}</p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Claim Amount</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p>
                    {reimburseRequest.reimbursementAmount
                      ? `₹ ${reimburseRequest.reimbursementAmount}`
                      : 'Not Available'}
                  </p>
                </div>
                {procedureDetails && (
                  <>
                    <div className="col-6 col-md-3 col-lg-2">
                      <p className="text-right">
                        <strong>Procedure limits</strong>
                      </p>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2">
                      <p>
                        {`${procedureDetails.maxCap}(${procedureDetails.capFrequency})`}
                      </p>
                    </div>
                  </>
                )}
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Amount already claimed for this category</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p>{amountClaimedForProcedure}</p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Insurer Status</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  {prettyPrintPayoutStatus(reimburseRequest.claimStatus)}
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Policy Name</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p>
                    {policyDetails && policyDetails.policyName
                      ? `${policyDetails.policyName}`
                      : 'Not Available'}
                  </p>
                </div>
                <div className="col-3 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>UTR number</strong>
                  </p>
                </div>
                <div className="col-3 col-md-3 col-lg-2">
                  <span>
                    {bankDetails.claimUtr
                      ? bankDetails.claimUtr
                      : 'Not Available'}
                  </span>
                </div>
                <div className="col-3 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Cashfree Transaction Id</strong>
                  </p>
                </div>
                <div className="col-3 col-md-3 col-lg-2">
                  <span>
                    {bankDetails.claimTransactionId
                      ? bankDetails.claimTransactionId
                      : 'Not Available'}
                  </span>
                </div>
                <div className="col-3 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Bank Account</strong>
                  </p>
                </div>
                <div className="col-3 col-md-3 col-lg-2">
                  <span>
                    {bankDetails.bankAccount
                      ? bankDetails.bankAccount
                      : 'Not Available'}
                  </span>
                </div>
                {/* {reimburseRequest.invoiceLink && (
                  <>
                    <div className="col-3 col-md-3 col-lg-2">
                      <p className="text-right">
                        <strong>Cashfree Payment Screenshot</strong>
                      </p>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2">
                      {reimburseRequest.invoiceLink
                        .split(',')
                        .map((link, idx) => (
                          <div key={idx} className="mt-2">
                            <a
                              rel="noopener noreferrer"
                              target="_blank"
                              href={link}
                            >{`Screenshot ${idx + 1}`}</a>
                          </div>
                        ))}
                    </div>
                  </>
                )} */}
                <div className="col-3 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>IFSC</strong>
                  </p>
                </div>
                <div className="col-3 col-md-3 col-lg-2">
                  <span>
                    {bankDetails.ifsc ? bankDetails.ifsc : 'Not Available'}
                  </span>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Wallet Balance</strong>
                  </p>
                </div>
  
                <div className="col-6 col-md-3 col-lg-2">
                  <p>
                    {this.state.walletBalanceAmount >= 0 &&
                    this.state.walletBalanceAmount !== null
                      ? `₹ ${this.state.walletBalanceAmount}`
                      : 'Not Available'}
                  </p>
                </div>
                {this.state.pastClaimArr.length>0?(                
                <div className="col-6 col-md-6 col-lg-6">
                  <div className='flex'>
                  <p className="text-right">
                    <strong>Total claim raised- {` ${this.state.pastClaimArr.length}`}</strong>
                  </p>
                  <buttton className='btn btn-primary ml-2'
                  onClick={()=>this.setState({pastClaimModal:true})}
                  >Past Claims

                  </buttton>
                  </div>
                </div>
                ):('')}
                {this.state.ciWalletBalanceAmount >= 0 &&
                this.state.ciWalletBalanceAmount !== null ? (
                  <>
                    {' '}
                    <div className="col-6 col-md-3 col-lg-2">
                      <p className="text-right">
                        <strong>CI Wallet Balance</strong>
                      </p>
                    </div>
                    <div className="col-6 col-md-3 col-lg-2">
                      <p>
                        {this.state.ciWalletBalanceAmount >= 0
                          ? `₹ ${this.state.ciWalletBalanceAmount}`
                          : 'Not Available'}
                      </p>
                    </div>{' '}
                  </>
                ) : (
                  ''
                )}
                {ahcDetails && ahcDetails.length !== 0 && (
                  <>
                    <div className="col-3 col-md-3 col-lg-2">
                      <p className="text-right">
                        <strong>Health checkups booked by Dependents</strong>
                      </p>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2">
                      <span>
                        {ahcDetails.filter((item) => item.relativeId != null)
                          .length || 0}
                      </span>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2">
                      <p className="text-right">
                        <strong>Health checkups booked by employee</strong>
                      </p>
                    </div>
                    <div className="col-3 col-md-3 col-lg-2">
                      <span>
                        {ahcDetails.filter((item) => item.relativeId == null)
                          .length || 0}
                      </span>
                    </div>
                  </>
                )}
                <div className="col-6 col-md-3 col-lg-2">
                  <p className="text-right">
                    <strong>Deficiency Submission Count</strong>
                  </p>
                </div>
                <div className="col-6 col-md-3 col-lg-2">
                  <p>
                    {reimburseRequest
                      ? reimburseRequest.documentDeficientSubmittedCount
                      : ''}
                  </p>
                </div>
              </div>
              {reimburseRequest.reimbursementReason && (
                <div className="row mt-3">
                  <div className="col-12">
                    <strong>Rejection Reason</strong>
                    <span>{reimburseRequest.reimbursementReason}</span>
                  </div>
                </div>
              )}
              {reimburseRequest.clarificationRemarks && (
                <div className="row mt-3">
                  <div className="col-12">
                    <h5>
                      <strong>Clarification Remark: </strong>
                      <span>{reimburseRequest.clarificationRemarks}</span>
                    </h5>
                  </div>
                </div>
              )}
               {reimburseRequest.clarificationStatus && (
                <div className="row mt-3">
                  <div className="col-12">
                    <h5>
                      <strong>Clarification Status: </strong>
                      <span>{reimburseRequest.clarificationStatus}</span>
                    </h5>
                  </div>
                </div>
              )}
              {reimburseRequest.clarificationSolution && (
                <div className="row mt-3">
                  <div className="col-12">
                    <h5>
                      <strong>Clarification solution: </strong>
                      <span>{reimburseRequest.clarificationSolution}</span>
                    </h5>
                  </div>
                </div>
              )}
              <div className="row mt-3">
                <div className="col-12">
                  <h4>Your Available Actions</h4>
                </div>
                <div className="col-4 col-md-3 mt-2">
                  <Button
                    outline
                    color="primary"
                    onClick={this.requestCall}
                    // disabled={
                    //   reimburseRequest.requestStatus === 'qc-check' &&
                    //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                    // }
                    disabled={buttonDisable}
                  >
                    Call user
                  </Button>
                </div>
                <div className="col-4 col-md-3 mt-2">
                  <Button
                    outline
                    color="primary"
                    onClick={this.requestCopyClaim}
                    // disabled={
                    //   reimburseRequest.requestStatus === 'qc-check' &&
                    //   (this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1&&reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1&&reimburseRequest.requestStatus === 'documents-verified'))
                    // }
                    // disabled={buttonDisable}
                  >
                    Copy Claim
                  </Button>
                </div>
                <div className="col-4 col-md-3 mt-2">
                  <Button
                    outline
                    color="primary"
                    onClick={this.requestDeleteClaim}
                    // disabled={
                    //   reimburseRequest.requestStatus === 'qc-check' &&
                    //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                    // }
                    disabled={buttonDisable}
                  >
                    Delete Claim
                  </Button>
                </div>
                <div className="col-4 col-md-3 mt-2">
                  <input
                    type="file"
                    id="add-invoice-input"
                    accept=".png,.jpg,.jpeg,.pdf"
                    style={{ display: 'none' }}
                    onChange={this.onInvoiceFileAdded}
                  />
                  <Button
                    outline
                    color="primary"
                    onClick={this.addInvoiceFile}
                    // disabled={
                    //   reimburseRequest.requestStatus === 'qc-check' &&
                    //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                    // }
                    disabled={buttonDisable}
                  >
                    Add Invoice
                  </Button>
                </div>
                <div className="col-4 col-md-3 mt-2">
                  <input
                    type="file"
                    id="add-prescription-input"
                    accept=".png,.jpg,.jpeg,.pdf"
                    style={{ display: 'none' }}
                    onChange={this.onPrescriptionFileAdded}
                  />
                  <Button
                    outline
                    color="primary"
                    onClick={this.addPrescriptionFile}
                    // disabled={
                    //   reimburseRequest.requestStatus === 'qc-check' &&
                    //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                    // }
                    disabled={buttonDisable}
                  >
                    Add Prescription
                  </Button>
                </div>
                <div className="col-4 col-md-3 mt-2">
                  <input
                    type="file"
                    id="add-mer-input"
                    accept=".png,.jpg,.jpeg,.pdf"
                    style={{ display: 'none' }}
                    onChange={this.onMerFileAdded}
                  />
                  <Button
                    outline
                    color="primary"
                    onClick={this.addMerFile}
                    // disabled={
                    //   reimburseRequest.requestStatus === 'qc-check' &&
                    //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                    // }
                    disabled={buttonDisable}
                  >
                    Add MER
                  </Button>
                </div>
                <div className="col-4 col-md-3 mt-2">
                  <Button
                    outline
                    color="primary"
                    onClick={this.requestDocumentsDeficient}
                    // disabled={
                    //   reimburseRequest.requestStatus === 'qc-check' &&
                    //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                    // }
                    disabled={buttonDisable}
                  >
                    Documents deficient
                  </Button>
                </div>
                {this.state.reimbursementPrePostRequests.length ? (
                  <div className="col-4 col-md-3 mt-2">
                    <Button
                      outline
                      color="danger"
                      onClick={this.openPrePostModal}
                    >
                      Flagged Claims
                    </Button>
                  </div>
                ) : null}
                {reimburseRequest.subStatus !== 'clarification-need' ? (
                  <div className="col-4 col-md-3 mt-2">
                    <Button
                      outline
                      color="primary"
                      onClick={this.clarificationNeeded}
                      // disabled={
                      //   reimburseRequest.requestStatus === 'qc-check' &&
                      //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                      // }
                      disabled={buttonDisable}
                    >
                      Clarification Needed
                    </Button>
                  </div>
                ) : (
                  ''
                )}
                {reimburseRequest.subStatus === 'clarification-needed' ? (
                  <div className="col-4 col-md-3 mt-2">
                    <Button
                      outline
                      color="primary"
                      onClick={this.clarificationResolution}
                      // disabled={
                      //   reimburseRequest.requestStatus === 'qc-check' &&
                      //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                      // }
                      disabled={buttonDisable}
                    >
                      Resolve Clarification Query
                    </Button>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {consultationDetails.doctorName && (
                <div className="row mt-3">
                  <div className="col-12">
                    <h4>Consultation Details</h4>
                  </div>
                  <div className="col-6 col-md-3 col-lg-2">
                    <p className="text-right">
                      <strong>Doctor Name</strong>
                    </p>
                  </div>
                  <div className="col-6 col-md-3 col-lg-2">
                    <p>{consultationDetails.doctorName}</p>
                  </div>
                  <div className="col-6 col-md-3 col-lg-2">
                    <p className="text-right">
                      <strong>Center Name</strong>
                    </p>
                  </div>
                  <div className="col-6 col-md-3 col-lg-2">
                    <p>{consultationDetails.centerName}</p>
                  </div>
                </div>
              )}
              <div className="row mt-3">
                <div className="col-12">
                  <h4>Uploaded Prescriptions and Invoices</h4>
                </div>
                <div className="col-6 col-md-3">
                  {reimburseRequest.prescriptionLink &&
                    reimburseRequest.prescriptionLink
                      .split(',')
                      .map((link, idx) => (
                        <div key={idx} className="mt-2">
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={link}
                          >{`Prescription file ${idx + 1}`}</a>
                          <Button
                            as="span"
                            color="danger"
                            size="sm"
                            style={{ marginLeft: '8px ' }}
                            disabled={
                              reimburseRequest.requestStatus === 'qc-check' &&
                              this.props.user.showQcTab !== 1
                            }
                            outline
                            onClick={this.confirmDeleteFile(
                              `Prescription file ${idx + 1}`,
                              'prescription',
                              link
                            )}
                          >
                            <MdDelete />
                          </Button>
                        </div>
                      ))}
                </div>
                <div className="col-6 col-md-3">
                  {reimburseRequest.invoiceLink &&
                    reimburseRequest.invoiceLink.split(',').map((link, idx) => (
                      <div key={idx} className="mt-2">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={link}
                        >{`Invoice ${idx + 1}`}</a>
                        <Button
                          as="span"
                          color="danger"
                          size="sm"
                          style={{ marginLeft: '8px ' }}
                          outline
                          onClick={this.confirmDeleteFile(
                            `Invoice ${idx + 1}`,
                            'invoice',
                            link
                          )}
                        >
                          <MdDelete />
                        </Button>
                      </div>
                    ))}
                </div>
                <div className="col-6 col-md-3">
                  {reimburseRequest.labReportLink &&
                    reimburseRequest.labReportLink
                      .split(',')
                      .map((link, idx) => (
                        <div key={idx} className="mt-2">
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href={link}
                          >{`Lab Report ${idx + 1}`}</a>
                          {/* <Button
                          as="span"
                          color="danger"
                          size="sm"
                          style={{ marginLeft: '8px ' }}
                          outline
                          onClick={this.confirmDeleteFile(
                            `Lab Report ${idx + 1}`,
                            'Lab Report',
                            link
                          )}
                        >
                          <MdDelete />
                        </Button> */}
                        </div>
                      ))}
                </div>
                <div className="col-6 col-md-3">
                  {reimburseRequest.merLink &&
                    reimburseRequest.merLink.split(',').map((link, idx) => (
                      <div key={idx} className="mt-2">
                        <a
                          rel="noopener noreferrer"
                          target="_blank"
                          href={link}
                        >{`MER ${idx + 1}`}</a>
                        {/* <Button
                        as="span"
                        color="danger"
                        size="sm"
                        style={{ marginLeft: '8px ' }}
                        outline
                        onClick={this.confirmDeleteFile(
                          `MER ${idx + 1}`,
                          'mer',
                          link
                        )}
                      >
                        <MdDelete />
                      </Button> */}
                      </div>
                    ))}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <h4>Policy Holders and Dependents</h4>
                </div>
                <div className="col-12">
                  {policyDetails ? (
                    <Table>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Date of Birth</th>
                          <th>Policy Start Date</th>
                          <th>Policy End Date</th>
                          <th>Reimbursement for</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{policyDetails.name}</td>
                          <td>{policyDetails.dob}</td>
                          <td>{policyDetails.policyStartDate}</td>
                          <td>{policyDetails.policyEndDate}</td>
                          <td>
                            <Input
                              type="checkbox"
                              checked={
                                !reimbursementForDependent &&
                                reimbursementFor === policyDetails.holderId
                              }
                              onChange={this.selectReimbursementForHolder}
                            />
                          </td>
                        </tr>
                        {policyDependents.map(renderDependent)}
                      </tbody>
                    </Table>
                  ) : (
                    <h5 className="text-secondary">No policy details found</h5>
                  )}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <h4>Do any of these validation checks fail?</h4>
                </div>
                <div className="col-12">
                  <Form>
                    {reimburseRequest.documentDeficientSubmittedCount >= 2 ? (
                      <Row form>
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={incompleteDocumentAfterReminder}
                                onChange={this.onValidationCheckChange(
                                  'incompleteDocumentAfterReminder'
                                )}
                              />
                              Incomplete documents submitted even after multiple
                              reminders
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    ) : (
                      <Row form>
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={prescriptionInvalid}
                              onChange={this.onValidationCheckChange(
                                'prescriptionInvalid'
                              )}
                            />
                            Prescription is invalid
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={invoiceDateDifferent}
                              onChange={this.onValidationCheckChange(
                                'invoiceDateDifferent'
                              )}
                            />
                            Invoice date is different
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={detailsMismatch}
                              onChange={this.onValidationCheckChange(
                                'detailsMismatch'
                              )}
                            />
                            Patient details do not match the prescription and
                            the invoice
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={invalidStamp}
                              onChange={this.onValidationCheckChange(
                                'invalidStamp'
                              )}
                            />
                            Invoice does not contain a valid stamp
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={oldInvoice}
                              onChange={this.onValidationCheckChange(
                                'oldInvoice'
                              )}
                            />
                            Invoice date is more than 15 days old
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={invalidDoctorName}
                              onChange={this.onValidationCheckChange(
                                'invalidDoctorName'
                              )}
                            />
                            Invalid Doctor Name
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={invalidPatientName}
                              onChange={this.onValidationCheckChange(
                                'invalidPatientName'
                              )}
                            />
                            Invalid Patient Name
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={blurredPicture}
                              onChange={this.onValidationCheckChange(
                                'blurredPicture'
                              )}
                            />
                            Blurred Picture
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={genuinePrescription}
                              onChange={this.onValidationCheckChange(
                                'genuinePrescription'
                              )}
                            />
                            Prescription is not on a valid letter head
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={signatureMissing}
                              onChange={this.onValidationCheckChange(
                                'signatureMissing'
                              )}
                            />
                            Doctor signature is missing
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={inappropriatePicture}
                              onChange={this.onValidationCheckChange(
                                'inappropriatePicture'
                              )}
                            />
                            Inappropriate Picture
                          </Label>
                        </FormGroup>
                      </Col> */}
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={invalidReimbursement}
                                onChange={this.onValidationCheckChange(
                                  'invalidReimbursement'
                                )}
                              />
                              Reimbursement type is invalid
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={patientNotCovered}
                                onChange={this.onValidationCheckChange(
                                  'patientNotCovered'
                                )}
                              />
                              Patient is not covered under policy
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={invoiceBeforePolicyDate}
                                onChange={this.onValidationCheckChange(
                                  'invoiceBeforePolicyDate'
                                )}
                              />
                              Invoice date is before policy start date
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={invoiceInAnotherClaim}
                                onChange={this.onValidationCheckChange(
                                  'invoiceInAnotherClaim'
                                )}
                              />
                              Invoice already submitted in another claim
                            </Label>
                          </FormGroup>
                        </Col>
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={itemsNotPrescribed}
                              onChange={this.onValidationCheckChange(
                                'itemsNotPrescribed'
                              )}
                            />
                            Items are not prescribed
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={documentsMissing}
                              onChange={this.onValidationCheckChange(
                                'documentsMissing'
                              )}
                            />
                            Documents are missing
                          </Label>
                        </FormGroup>
                      </Col> */}
                        {/* <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="checkbox"
                              checked={invoiceNotItemized}
                              onChange={this.onValidationCheckChange(
                                'invoiceNotItemized'
                              )}
                            />
                            Invoice is not itemized
                          </Label>
                        </FormGroup>
                      </Col> */}
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={outOfPolicy}
                                onChange={this.onValidationCheckChange(
                                  'outOfPolicy'
                                )}
                              />
                              Claim not covered under policy
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={policyNotActive}
                                onChange={this.onValidationCheckChange(
                                  'policyNotActive'
                                )}
                              />
                              Policy is not active
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={sumInsuredExhausted}
                                onChange={this.onValidationCheckChange(
                                  'sumInsuredExhausted'
                                )}
                              />
                              Sum Insured Exhausted
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={registrationCheckFailed}
                                onChange={this.onValidationCheckChange(
                                  'registrationCheckFailed'
                                )}
                              />
                              Doctor's name and the registration number
                              mentioned in the prescription are not matching
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col xs={6} md={4}>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={invoiceBeforePrescriptionDate}
                                onChange={this.onValidationCheckChange(
                                  'invoiceBeforePrescriptionDate'
                                )}
                              />
                              Invoice date is before prescription date, Kindly
                              attach the appropriate prescription.
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                  </Form>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <h4>How many unique prescriptions in the claim?</h4>
                </div>
                {prescriptions.map(renderPrescription)}
                <div className="col-12">
                  <Button
                    outline
                    color="primary"
                    onClick={this.addPrescription}
                    // disabled={(reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')}
                    // disabled={buttonDisable}
                  >
                    + Add Prescription
                  </Button>
                </div>
                <div className="col-12 mt-2 text-center">
                  <Button
                    outline
                    color="primary"
                    onClick={this.submitForDigitization}
                    disabled={
                      selectedPrescriptionFiles.length === 0 || digitizing
                    }
                  >
                    Submit Prescriptions for digitization
                  </Button>
                </div>
              </div>

              <div className="h4 ml-5 mt-5 mb-5">
                <Input
                  type="checkbox"
                  checked={
                    reimburseRequest.isCriticalIllness === 1
                      ? Boolean(reimburseRequest.isCriticalIllness)
                      : this.state.isCriticalIllness
                  }
                  onChange={this.toggleIsCriticalIllness}
                  disabled={
                    (reimburseRequest.sentForQcCheck === 1 &&
                      reimburseRequest.requestStatus ===
                        'documents-verified') ||
                    (this.props.user.showQcTab !== 1 &&
                      reimburseRequest.requestStatus === 'documents-verified')
                  }
                />
                Critical illness
              </div>

              <div className="row mt-3">
                <div className="col-6">
                  <h4>Invoice Details</h4>
                </div>
                <div className="col-6 text-right">
                  <h4>
                    Final Status: {renderApprovalStatus(finalApprovalStatus)}
                  </h4>
                </div>
                <div className="col-12">
                  <Form>
                    <Row form>
                      <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label for="invoiceName">Merchant Name</Label>
                          <Input
                            type="text"
                            name="invoiceName"
                            id="invoiceName"
                            value={invoiceName}
                            onChange={this.onInvoiceItemChanged(
                              -1,
                              'invoiceName'
                            )}
                            // disabled={
                            //   reimburseRequest.requestStatus === 'qc-check' &&
                            //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                            // }
                            disabled={buttonDisable}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label for="invoiceDate">Invoice Date</Label>
                          <SingleDatePicker
                            date={invoiceDate}
                            id="invoiceDate"
                            block
                            placeholder="Invoice Date"
                            focused={invoiceDateInputFocused}
                            onFocusChange={({ focused }) =>
                              this.setState({
                                invoiceDateInputFocused: focused,
                              })
                            }
                            onDateChange={(date) =>
                              this.setState({ invoiceDate: date }, () => {
                                this.updateFinalStatus();
                              })
                            }
                            openDirection="up"
                            isOutsideRange={() => false}
                            displayFormat="DD/MM/YYYY"
                            // disabled={
                            //   reimburseRequest.requestStatus === 'qc-check' &&
                            //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                            // }
                            disabled={buttonDisable}
                          />
                        </FormGroup>
                      </Col>
                      <Col xs={6} md={4}>
                        <FormGroup check>
                          <Label for="invoiceNumber">Invoice Number</Label>
                          <Input
                            type="text"
                            name="invoiceNumber"
                            id="invoiceNumber"
                            value={invoiceNumber}
                            onChange={this.onInvoiceItemChanged(
                              -1,
                              'invoiceNumber'
                            )}
                            // disabled={
                            //   reimburseRequest.requestStatus === 'qc-check' &&
                            //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                            // }
                            disabled={buttonDisable}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <h5>Invoice Items</h5>
                </div>
                <div className="col-12">
                  <Form>
                    {invoiceItems.length > 0 ? (
                      invoiceItems.map(renderInvoiceItems)
                    ) : (
                      <span className="text-secondary text-center">
                        No items added yet
                      </span>
                    )}
                  </Form>
                </div>
                <div className="col-12 mt-3">
                  <Button
                    outline
                    color="primary"
                    onClick={this.addInvoiceItem}
                    // disabled={
                    //   reimburseRequest.requestStatus === 'qc-check' &&
                    //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                    // }
                    disabled={buttonDisable}
                  >
                    + Add item
                  </Button>
                </div>
                <div className="col-12 mb-2 text-center">
                  {editingFinalApprovedPrice ? (
                    <Form inline className="justify-content-center">
                      <FormGroup check>
                        <Label for="finalApprovedPrice">Approved Amount</Label>
                        <Input
                          type="text"
                          className="ml-2"
                          name="finalApprovedPrice"
                          id="finalApprovedPrice"
                        />
                      </FormGroup>
                      <Button
                        className="ml-2"
                        outline
                        color="primary"
                        onClick={this.saveApprovedAmount}
                      >
                        Save
                      </Button>
                    </Form>
                  ) : (
                    <>
                      <span className="h4">{`Total Approved Amount: ${totalAmount}`}</span>
                      <Button
                        outline
                        color="primary"
                        onClick={this.editTotalApprovedAmount}
                        // disabled={
                        //   reimburseRequest.requestStatus === 'qc-check' &&
                        //   this.props.user.showQcTab !== 1 || (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                        // }
                        disabled={buttonDisable}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </div>
                {reimburseRequest.sponsorId === 731 ? (
                  <div className="col-12 mt-2 mb-2 text-center">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={oracleDentalCheck}
                        onChange={this.onValidationCheckChange(
                          'oracleDentalCheck'
                        )}
                      />
                      Reimbursement Claim is not of these type
                      Dental/Vision/Ayurvedic/Day care treatment
                    </Label>
                  </div>
                ) : (
                  ''
                )}
                {reimburseRequest.sponsorId === 731 ? (
                  <div className="col-12 mt-1 mb-2 text-center">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={oracleCiScan}
                        onChange={this.onValidationCheckChange('oracleCiScan')}
                      />
                      CT Scan, MRI, Biopsy, Bone Densitometry are not present in
                      the prescription
                    </Label>
                  </div>
                ) : (
                  ''
                )}
                {policyDetails &&  policyDetails.vendorId===8 ?
                <div className="col-12 mt-1 mb-2 text-center">
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={doctorVerifyByGoogleSearch}
                        onChange={this.onDoctorVerifyCheck()}
                      />
                   Verified doctor on the basis of Google search
                    </Label>
                  </div>
                 :('') }
                <div className="col-12 mt-3 mb-2 text-center">
                  <Button className="mr-2" onClick={this.opneDoctorVerifyModal}>
                    Verify Doctor
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => {
                      // if (reimburseRequest.emergencyCase) {
                      //   this.setState({
                      //     confirmModal: {
                      //       isOpen: true,
                      //       header: `Update ${reimburseRequest.userName}`,
                      //       body: `Are you sure you want to update this claim? This claim is an emergency claim.`,
                      //       onSubmit: this.updateClaim(),
                      //       requestId: reimburseRequest.reimbursementId,
                      //       onClose: () => {
                      //         this.setState({
                      //           confirmModal: { isOpen: false },
                      //         });
                      //       },
                      //       buttonLoading: false,
                      //     },
                      //   });
                      // }
                      if (
                        this.props.user.showQcTab === 1 &&
                        reimburseRequest.requestStatus === 'qc-check'
                      ) {
                        this.setState({ qcRemarkModal: { isOpen: true } });
                      } else {
                        this.updateClaim();
                      }
                    }}
                    disabled={
                      updating ||
                          // (! doctorVerifyByGoogleSearch  && policyDetails.vendorId ===8 &&
                          // !doctorRegVerified && finalApprovalStatus !=='rejected') || 
                      finalApprovalStatus === 'pending' ||
                      (finalApprovalStatus === 'rejected'
                        ? false
                        : reimburseRequest.sponsorId === 731 &&
                          !oracleDentalCheck) ||
                      (finalApprovalStatus === 'rejected'
                        ? false
                        : reimburseRequest.sponsorId === 731 &&
                          !oracleCiScan) ||
                      buttonDisable
                      // (reimburseRequest.sentForQcCheck === 1)&&(reimburseRequest.requestStatus === 'documents-verified') || (this.props.user.showQcTab !== 1)&&(reimburseRequest.requestStatus === 'documents-verified')
                    }
                  >
                    {updating ? (
                      <>
                        <span>Updating...</span>
                      </>
                    ) : (
                      'Update Claim'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col mt-3">
            <CommentList
              loading={this.state.loading}
              comments={this.state.comments}
            />
            <div className="">
              <CommentForm
                addComment={this.addComment}
                reimbursementId={reimburseRequest.reimbursementId}
                commentType={appConfig.REIMBURSEMENT.reimbursement}
              />
            </div>
            {reimburseRequest.requestStatus === 'documents-verified' &&
            this.state.remark !== '' ? (
              <div>
                <div className="row col mt-3">
                  <div>Selected Reason:-</div>
                  <div>{this.state.selectedReason}</div>
                </div>
                <div className="row col mt-3">
                  <div>Remarks:-</div>
                  <div>{this.state.remark}</div>
                </div>
              </div>
            ) : (
              ''
            )}
            {/* { this.props.user.showQcTab === 1 ?<div className="row col mt-3">
              <div className='mb-2 bold'>Remark For Doctor:- </div>
              <div className="form-group mt-1">
                <textarea
                  onChange={(e)=>this.setState({remark:e.target.value})}
                  // value={this.state.comment.message}
                  className="form-control"
                  placeholder="Add a remark..."
                  name="message"
                  rows="3"
                />
              </div>
              <div className="form-group">
                <button
                  disabled={this.state.loading}
                  className="btn btn-primary"
                  onClick={this.addRemark}
                >
                  Add Remark
                </button>
              </div>
            </div> : ''} */}
          </div>
        </div>
        <Modal
          isOpen={this.state.doctrorRegModal}
          style={customStylesModal}
          onRequestClose={this.closeDocotrRegMoadl}
        >
          <div className="">
            <Row className="float-right">
              <Col xs={12} md={12}>
                <ButtonsComponent>
                  <button onClick={this.closeDocotrRegMoadl}>
                    <CrossIcon />
                  </button>
                </ButtonsComponent>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={12}>
                <h3 className="text-center m-3" style={{ color: '#8752CB' }}>
                  Verify Doctor Registration
                </h3>
              </Col>
            </Row>

            <Row>
              <Col xs={6} md={4}>
                <lable>Registration No :</lable>
              </Col>
              <Col xs={6} md={4}>
                <input
                  type="text"
                  placeholder="Enter Registration No"
                  onChange={this.OnRegistrationNumber}
                ></input>
              </Col>
            </Row>
            <div class="text-center m-3">
              <button
                className="btn btn-primary"
                onClick={this.verifyDoctorReg}
              >
                Find Doctor
              </button>
            </div>
          </div>
          <div className="col-6 col-md-3">
            {reimburseRequest.prescriptionLink &&
              reimburseRequest.prescriptionLink.split(',').map((link, idx) => (
                <div key={idx} className="mt-2">
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={link}
                  >{`Prescription file ${idx + 1}`}</a>
                </div>
              ))}
          </div>
        </Modal>
        <Modal
          isOpen={doctorTableModal}
          style={customStylesModal2}
          onRequestClose={this.closeDoctorTableModal}
        >
          <div
            style={{
              height: '85%',
              overflow: 'scroll',
              boxShadow: '0px 1px 2px 0px #b4adad',
            }}
          >
            <div className="float-right">
              <Col>
                <Row className="float-right">
                  <ButtonsComponent>
                    <button onClick={this.closeDoctorTableModal}>
                      <CrossIcon />
                    </button>
                  </ButtonsComponent>
                </Row>
              </Col>
            </div>

            <Row className="m-2">
              <Col xs={12} md={12}>
                <h3
                  className="mb-2 text-bold text-center"
                  style={{ color: '#8752CB' }}
                >
                  Doctors Detail
                </h3>
              </Col>
            </Row>
            <Row className="m-2">
              <Col xs={12} md={12}>
                <ReactTable
                  filterable
                  sortable={false}
                  style={{ flex: 1 }}
                  data={registrationDoctorArr}
                  collapseOnDataChange={false}
                  columns={this.columns}
                  manual={false}
                  className="-striped -highlight"
                />
              </Col>
            </Row>
          </div>
          <div
            style={{
              height: '14%',
              marginTop: '12px',
            }}
          >
            <div className="mt-2 text-center">
              <button
                className="btn btn-success"
                onClick={this.updateDoctorRegistrationInfo}
                disabled={!this.state.selectedDoctor}
              >
                Verify Doctor
              </button>
            </div>
            <div className="mt-2 text-center">
              <button className="btn btn-primary" onClick={this.verifyAgain}>
                Find Doctor again
              </button>
            </div>
          </div>
        </Modal>
        < PastClaims
        isOpen={this.state.pastClaimModal}
        dataSource={this.state.pastClaimArr}
        onClosePastClaimsModal =
        {()=>this.setState({pastClaimModal:false})}
        selectedDate={this.selectedDate}
        />
        

        <ReimbursementPrePost
          isOpen={this.state.prePostModal}
          prepostData={this.state.reimbursementPrePostRequests}
          onClose={() => this.setState({ prePostModal: false })}
        ></ReimbursementPrePost>
        <ConfirmModal
          modalHeader={confirmModal.header}
          onClose={confirmModal.onClose}
          isOpen={confirmModal.isOpen}
          onSubmit={confirmModal.onSubmit}
          loading={confirmModal.buttonLoading}
        >
          {confirmModal.body}
        </ConfirmModal>
        {this.props.user.showQcTab === 1 &&
        reimburseRequest.requestStatus === 'qc-check' ? (
          <RemarksConfirmModal
            onClose={() => {
              this.setState({ qcRemarkModal: { isOpen: false } });
            }}
            isOpen={this.state.qcRemarkModal.isOpen}
            onSubmit={this.updateClaim}
            loading={this.state.qcRemarkModal.buttonLoading}
            opsUserId={this.props.user.userId}
            reimbursementDetails={reimburseRequest}
            get
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)( ReimburseClaimRequest);
