import React, { Component } from 'react';
import ResultModal from '../common/modal/ResultModal';
import { TableContainer, Container, ContainerItem } from './StyledComponent';
import ReactTable from 'react-table';
import {
  getProductsPoolSponsor,
  changeProductPoolActivity,
} from '../../services/api/redemptions';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import { toast } from 'react-toastify';
import ModalConfirm from '../common/ModalConfirm';

class ProductPoolTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showModal: false,
      showProductField: false,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      productOptions: [],
      productSelected: '',
      corporateCost: '',
      nonServiceableMessage: '',
      amount: '',
      priority: '',
      maxBuy: '',
      maxUsersBuy: '',
      confirmModal: {
        isOpen: false,
        message: '',
        onConfirm: () => {},
        onClose: () => {},
      },
      locations: '',
      layout: '',
      layoutOptions: [
        { value: 'grid', label: 'Grid' },
        { value: 'fullwidth', label: 'Full Width' },
      ],
      invites: '',
      web: '',
      webOptions: [
        { value: '1', label: 'Yes' },
        { value: '0', label: 'No' },
      ],
      poolData: [],
      sponsorData: [],
    };
  }

  sponsorTableColumns = [
    {
      Header: 'Sponsor',
      columns: [
        {
          accessor: 'id',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'Id',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'name',
          sortable: true,
          numeric: false,
          disablePadding: false,
          Header: 'Title',
          style: { whiteSpace: 'unset' },
          filterable: true,
          Filter: ReactTableInputFilter,
        },
      ],
    },
  ];
  poolTableColumns = [
    {
      Header: 'Pools',
      columns: [
        {
          accessor: 'id',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'Id',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'pool_name',
          sortable: true,
          numeric: false,
          disablePadding: false,
          Header: 'Pool Name',
          style: { whiteSpace: 'unset' },
          filterable: true,
          Filter: ReactTableInputFilter,
        },
        {
          disablePadding: false,
          sortable: true,
          Header: 'Action',
          style: { whiteSpace: 'unset' },
          Cell: (row) => {
            const { isActive } = row.original;
            return (
              <button
                className="btn btn-primary"
                onClick={() => {
                  return this.toggleProductActiveInactive(row);
                }}
              >
                {isActive ? 'Inactive' : 'Active'}
              </button>
            );
          },
        },
      ],
    },
  ];
  toggleProductActiveInactive = (row) => {
    console.log({ row });
    const isActive = row.original.isActive;
    this.setState({
      confirmModal: {
        isOpen: true,
        message: `Are you sure you want to mark this ${
          isActive ? 'inactive' : 'active'
        }?`,
        onConfirm: () => {
          const poolId = row.original.id;
          const productId = this.props.productId;
          return changeProductPoolActivity(poolId, productId, !isActive).then(
            () => {
              toast.success(
                `Successfully marked ${isActive ? 'inactive' : 'active'}`
              );
              this.setState({
                confirmModal: {
                  isOpen: false,
                  onConfirm: () => {},
                  onClose: () => {},
                },
              });
              return this.props.fetchTableData();
            }
          );
        },
        onClose: () => {
          this.setState({
            confirmModal: {
              isOpen: false,
              onConfirm: () => {},
              onClose: () => {},
            },
          });
        },
      },
    });
  };

  componentDidMount() {
    this.fetchProductTableData();
  }

  // Fetch Table Data
  fetchProductTableData = () => {
    this.setState({
      loading: true,
    });

    const params = {
      productId: this.props.productId,
    };

    if (!params.productId) {
      return;
    }

    return getProductsPoolSponsor(params)
      .then((responseBody) => {
        this.setState({
          poolData: responseBody.data.poolData || [],
          sponsorData: responseBody.data.sponsorData || [],
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };

  render() {
    return (
      <TableContainer>
        <Container>
          <ContainerItem style={{ width: '500px' }}>
            <ReactTable
              data={this.state.poolData}
              columns={this.poolTableColumns}
              loading={this.state.loading}
              showPaginationTop={true}
              showPaginationBottom={false}
              collapseOnDataChange={false}
              defaultPageSize={5}
              className="-striped -highlight text-center"
            />
          </ContainerItem>
          <ContainerItem style={{ width: '500px' }}>
            <ReactTable
              data={this.state.sponsorData}
              columns={this.sponsorTableColumns}
              loading={this.state.loading}
              showPaginationTop={true}
              showPaginationBottom={false}
              collapseOnDataChange={false}
              defaultPageSize={5}
              className="-striped -highlight text-center"
            />
          </ContainerItem>
        </Container>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        <ModalConfirm
          message={this.state.confirmModal.message}
          onConfirm={this.state.confirmModal.onConfirm}
          onClose={this.state.confirmModal.onClose}
          isOpen={this.state.confirmModal.isOpen}
        />
      </TableContainer>
    );
  }
}

export default ProductPoolTable;
