export const defaultState = {
    userId: null
};

export default function labsDashboard(state = defaultState, action) {
    switch (action.type) {
        case "SET_LABS_USER_ID": {
            const userId = action.payload.userId;
            return Object.assign({}, state, {
                userId
            });
        }

        case 'SET_CART_INFO': {
            const cartItemsResult = action.payload.cartItemsResult;
            return Object.assign({}, state, {
                cartItemsResult,
            });
        }

        case 'SET_SELECTED_PARTNER_NEW_TEST': {
            const selectedPartnerId = action.payload.selectedPartnerId;
            return Object.assign({}, state, {
                selectedPartnerId,
            });
        }

        default:
            return state;
    }
}
