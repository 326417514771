import React from "react";

const allOptions = () => {
    return (
        <React.Fragment>
            <option value="">All</option>
            <option value="request-received">Request Received</option>
            <option value="request-processed">Request Processed</option>
            <option value="request-cancelled">Cancelled</option>
            <option value="request-declined">Declined</option>
            <option value="booking-created">Booking Created</option>
            <option value="booking-confirmed">Booking Confirmed</option>
            <option value="booking-payout-complete">Booking Payout Complete</option>
        </React.Fragment>
    );
};


export default class StatusFilter extends React.Component {

    changeFilterType = (event) => {
        const value = event.target.value === "" ? null : event.target.value;
        console.log({ value }, 'Filter Inner Component');
        this.props.onChangeValue(value);
    };

    getOptions = () => {
        return allOptions();
    };

    render() {
        const { value } = this.props;
        return (
            <select
                onChange={this.changeFilterType}
                style={{
                    width: "100%",
                    height: "40px",
                    fontSize: "12px"
                }}
                value={value || ''}
            >
                {this.getOptions()}
            </select>
        );
    }
}
