import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactTable from 'react-table';
import { renewCartAPI, changeCartReimbursementStatus, getReimbursementCarts, saveCartRemark,  } from '../../services/api/get-labs-orders';
import { debounce } from '../../utils/debounce';
import { toast } from 'react-toastify';
import ReactTableInputFilter from './ReactTableInputFilter';
import {
    Modal,
    ModalHeader,
} from 'reactstrap'
import { FaTimes } from 'react-icons/fa';

class LabsReimbursementTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            editReimbursementModalOpen: false,
            renewCartModalOpen: false,
            pages: -1,
            requests: [],
            filtered: [],
            sorted: [],
            pageSize: 50,
            page: 0,
            delTrModalOpen: false,
            delTrRequestId: null,
            concernedCart: {},
            concernedCartItems: [],
            showMoreTests: [],
        };


        this.onFilteredChange = this.onFilteredChange.bind(this);
        this.fetchStrategy = this.fetchStrategy.bind(this);

        this.fetchData = this.fetchData.bind(this);
        this.fetchDataWithDebounce = debounce(this.fetchData, 300);
        console.log('Lab PartnerId: ', this.props.labPartnerId);
    }

    componentDidUpdate(prevProps, prevState) {
    }

  
    saveRemark = (row, remark) => {
        console.log(row, remark);
        saveCartRemark(row.original.cartId, remark)
            .then(result => {
                if (result.body.message === 'success') {
                    toast.success(`Comment Saved`);
                    const newState = Array.from(this.state.requests);
                    newState[row.index].remarks = remark;
                    // Update that order
                    this.setState({
                        requests: newState,
                    });
                } else {
                    toast.error(`Error in Saving comment`);
                }
            })
            .catch(err => {
                console.log('Error in API', err);
                toast.error(`Error in Saving comment`);
            });
    };

    onFilteredChange = (e) => {
        // console.log('Start filtering. After this fetch strategy should be called');
        this.setState({filtered: e});
    };

    fetchStrategy = state => this.fetchDataWithDebounce(state);

    prepareApiFilters = () => {
        const appliedFilters = {};
        // let appliedOrderBy = {};
        // Convert this list of filterables into object properties
        this.state.filtered.forEach(
            filter => (appliedFilters[filter.id] = filter.value),
        );
        // state.sorted.forEach(sort => appliedOrderBy[sort.id] = sort.desc?'desc':'asc');

        appliedFilters.rowsPerPage = this.state.pageSize;
        appliedFilters.page = this.state.page + 1;
        return appliedFilters;
    };


    fetchData = (state, instance) => {
        // show the loading overlay
        this.setState({ loading: true });
        const appliedFilters = this.prepareApiFilters();

        console.log('fetchData with Applied filters', appliedFilters);
        // state.filtered =
        // fetch your data
        getReimbursementCarts(appliedFilters, this.state.sorted)
        .then(response => {
            // console.log('Api response', response.body);
            let pages = 1;
            if (response.body.count[0].totalCount) {
                pages = Math.ceil(response.body.count[0].totalCount / this.state.pageSize)
            }
            console.log(pages, response,response.body.count[0].totalCount,this.state.pageSize, 'pages')
            this.setState({
                requests: response.body.result,
                loading: false,
                pages,
            });
        })
        .catch(err => {
            console.error(err, 'Error in get labs requests API');
            toast.error(`Error in fetching requests`);
        });
    };

    updateTrDeleteRequestId = (requestId) => {
        const { delTrRequestId } = this.state;
        this.setState({
            delTrRequestId: requestId,
        });
    }

    openTrModal = () => {
        this.setState({
            delTrModalOpen: true,
        })
    }

    closeTrModal = () => {
        this.setState({
            delTrModalOpen: !this.state.delTrModalOpen,
        })
    }

    editReimbursableFlag = cartData => () => {
    console.log(cartData);
    this.setState(
      {
        editReimbursementModalOpen: true,
        concernedCart: cartData
      }
    );
  };

  renewCart = cartData => () => {
      this.setState({
            renewCartModalOpen: true,
            concernedCart: cartData

      })
  };

  renewCartApiCall = () => {
      renewCartAPI(this.state.concernedCart.cartId)
      .then (() => {
        window.location.reload();
      })
  }

  changeReimbursementStatus = () => {
    changeCartReimbursementStatus(this.state.concernedCart.cartId, (this.state.concernedCart.reimbursable === 'yes' ? 0 : 1))
    .then(() => {
        window.location.reload();
    })

  }
    getColumns = () => [
        {
            Header: 'Cart Details',
            columns: [
                {
                    Header: 'Cart Id',
                    accessor: 'cartId',
                    filterable: true,
                    width: 75,
                    Filter: ReactTableInputFilter,
                    Cell: row => (
                        <span>
                          {row.original.cartId} 
                        </span>
                    ),
                },
                {
                    Header: 'User Id',
                    accessor: 'userId',
                    width: 120,
                    filterable: true,
                    Filter: ReactTableInputFilter,
                    Cell: row => (
                        <span>
                          {row.original.userId}
                        </span>
                    ),
                },
                {
                    Header: 'Sponsor Id',
                    accessor: 'sponsorId',
                    width: 120,
                    Cell: row => {
                        return (
                            <span>
                                {row.original.sponsorId}
                            </span>
                        )                       
                    },
                },
                {
                    Header: 'reimbursable',
                    accessor: 'reimbursable',
                    filterable: true,
                    width: 100,
                    Cell: row => {
                        return (
                            <button
                                type="button"
                                className="btn btn-primary"
                                onClick={this.editReimbursableFlag(row.original)}
                            >
                                {row.original.reimbursable}
                            </button>
                        );
                    },
                },
            ],
        },
        {
            Header: 'User Details',
            columns: [
                {
                    Header: 'User Name',
                    accessor: 'userName',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'User phone',
                    accessor: 'userPhone',
                    width: 140,
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'Sponsor Name',
                    accessor: 'sponsorName',
                    width: 100,
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
            ],
        },
        {
            Header: 'Patient Details',
            columns: [
                {
                    Header: 'Patient Name',
                    accessor: 'patientName',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'Patient Phone',
                    accessor: 'patientPhone',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
            ],
        },
        {
            Header: 'Test Details',
            columns: [
                {
                    Header: 'Tests',
                    accessor: 'tests',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                    Cell: row => {
                        return (
                            <div  onClick={() => {
                                this.setState({
                                    cartItemsModalOpen: true,
                                    concernedCart: row.original
                                })
                            }}>
                                {!this.state.showMoreTests.includes(row.original.cartId) ? (
                                    row.original.tests && row.original.tests.length > 100 ? (
                                    <div>
                                        <p>
                                            {row.original.tests ? row.original.tests.slice(0,100): null}
                                            <p className= "text-primary" onClick={()=> {this.setState({showMoreTests: [...this.state.showMoreTests, row.original.cartId]})}}>...show more</p>
                                        </p>
                                    </div>
                                    ) : row.original.tests
                                ): (
                                    <p>
                                        {row.original.tests}
                                            <p className= "text-primary" onClick={()=> {
                                                for( let i = 0; i < this.state.showMoreTests.length; i++){ 
                                                        if ( this.state.showMoreTests[i] === row.original.cartId) { 
                                                            this.state.showMoreTests.splice(i, 1); 
                                                        }
                                                    }
                                                return this.setState({showMoreTests: this.state.showMoreTests})
                                            }}
                                            >...show less</p>
                                    </p>
                                )}
                            </div>
                            
                        );
                    },
                },
                {
                    Header: 'Tests Type',
                    accessor: 'testType',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'Cart type',
                    accessor: 'cartType',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'Created At',
                    accessor: 'createdAt',
                    filterable: true,
                    width: 100,
                    Cell: row => {
                        return (
                            <div
                                type="button"
                                onClick={this.renewCart(row.original)}
                            >
                                {row.original.createdAt}
                            </div>
                        );
                    },
                },
                
            ],
        },
    ];

    getSubComponent = row => {
        return (
            <div className="row">
                <div className="col-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Remarks</span>
                        </div>
                        <textarea
                            className="form-control"
                            aria-label="Remarks"
                            placeholder="Comment here..."
                            onChange={event => (row.updatedRemark = event.target.value)}
                            defaultValue={
                                row.original.remarks === null ? '' : row.original.remarks
                            }
                        />
                    </div>
                </div>
                <div className="col-1 d-flex">
                    <button
                        className="btn btn btn-success"
                        onClick={() => this.saveRemark(row, row.updatedRemark)}
                    >
                        Save
                    </button>
                </div>
                <div className="col-1 d-flex">
                    <button
                        className="btn btn btn-danger"
                        onClick={() => {
                            this.updateTrDeleteRequestId(row.original.requestId);
                            this.openTrModal();
                        }}
                    >
                        Delete
                    </button>
                </div>


            </div>
        );
    };

    render() {
        // console.log(this.props.startDate, this.props.endDate);
        return (
            <>
            <ReactTable
                keyField="cartId"
                data={this.state.requests}
                columns={this.getColumns()}
                defaultPageSize={50}
                className="-striped -highlight"

                pages={this.state.pages}
                pageSize={this.state.pageSize}
                page={this.state.page}

                loading={this.state.loading}
                filtered={this.state.filtered}
                sorted={this.state.sorted}

                manual

                onFetchData={this.fetchStrategy}
                onFilteredChange={this.onFilteredChange}

                onPageSizeChange={pageSize => this.setState({ pageSize })}
                onPageChange={page => this.setState({ page })}
                onSortedChange={sorted => this.setState({ sorted })}

                showPaginationTop
                showPaginationBottom
            />

            <Modal isOpen={this.state.editReimbursementModalOpen}>
                <ModalHeader className="text-body">
                    <FaTimes className="float-right" onClick={() => this.setState({
                        editReimbursementModalOpen: false
                        })}></FaTimes>
                        <br/>
                        <div className='d-flex justify-content-center'>
                            {this.state.concernedCart.reimbursable === 'yes' ? 'Do you want to mark this cart as \n non-reimbursable' : 'Do you want to mark this cart as reimbursable?'}
                    
                    <br></br>
                        <button 
                        className="btn btn-primary"
                        onClick = {() => this.changeReimbursementStatus()}
                        >yes</button>
                        <button
                        className="btn btn-primary ml-2"
                        onClick={() => this.setState({
                            editReimbursementModalOpen: false,
                        })}
                        >cancel</button>
                    </div>
                </ModalHeader>
            </Modal>
            
            <Modal isOpen={this.state.renewCartModalOpen}>
                <ModalHeader className="text-body">
                    <FaTimes className="float-right" onClick={() => this.setState({
                        renewCartModalOpen: false
                        })}></FaTimes>
                        <br/>
                        <div className='d-flex justify-content-center'>
                            Do you want to renew this cart to today
                    
                    <br></br>
                        <button 
                        className="btn btn-primary"
                        onClick = {() => this.renewCartApiCall()}
                        >yes</button>
                        <button
                        className="btn btn-primary ml-2"
                        onClick={() => this.setState({
                            renewCartModalOpen: false,
                        })}
                        >cancel</button>
                    </div>
                </ModalHeader>
            </Modal>

            <Modal isOpen={this.state.cartItemsModalOpen}>
                <ModalHeader className="text-body">
                    <FaTimes className="float-right" onClick={() => this.setState({
                        cartItemsModalOpen: false
                        })}></FaTimes>
                        <br/>
                        <div className='d-flex justify-content-center'>
                            Items In Cart Are:

                    
                    <br></br>
                    </div>
                </ModalHeader>
            </Modal>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {  };
}

export default connect(mapStateToProps)(LabsReimbursementTable);
