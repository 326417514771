import React from 'react';

import Modal from './Modal';

import LoadingComponent from "./LoadingComponent";

class ModalConfirm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    onConfirm = () => {
        this.setState({
            loading: true,
        });
        return this.props.onConfirm()
            .then(() => {
                this.props.onClose();
            })
            .catch((err) => {
                console.error(err, 'Error while confirming');
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {   
        const { message } = this.props;
        const { loading } = this.state;
        return (
            <Modal {...this.props} header={message}>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            {(loading) ? (
                                <LoadingComponent />
                            ) : (
                                <button className="btn btn-block btn-success" onClick={this.onConfirm}>Confirm</button>
                            )}
                        </div>
                        <div className="col">
                            <button className="btn btn-block btn-danger" onClick={this.props.onClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ModalConfirm;
