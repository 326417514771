import React, { Component } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

class ValidationModal extends Component {
    constructor(props) {
      console.log(props,'[validProp]')
        super(props);
        this.state = {};
    }

  render() {
    console.log(Object.keys(this.props.errors[0]))
    return (
      <Modal isOpen toggle={this.props.closeStatusModal}>
        <ModalHeader>
            Validation Error
        </ModalHeader>
        <ModalBody>
            <div>
            <table className='table'>
                <thead>
                <tr>
                {Object.keys(this.props.errors[0]).map((el,i)=>{
                    return <th key={i} className='col'>{el}</th>
                })}
                </tr>
                </thead>
                <tbody>
                    {this.props.errors.map((ele,inx)=>{
                        return (
                          <tr key={inx}>
                            <th>{ele.rowIndex}</th>
                            <th>{ele.columnIndex}</th>
                            <th>{ele.message}</th>
                          </tr>
                        );
                    })}
                </tbody>
            </table>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button onClick={this.props.closeStatusModal}>Close</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ValidationModal