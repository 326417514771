import React from 'react';

import Modal from '../common/Modal';

import Link from 'valuelink';
import { Input } from 'valuelink/tags';

import { sendPartnerComms } from '../../services/api/lab-order-details';

import { toast } from 'react-toastify';

class LabOrderPartnerComms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            rescheduleDate: '',
            rescheduleTime: '',
        };
    }

    handleSubmit = () => {
        const { orderId } = this.props;
        const { rescheduleDate, rescheduleTime } = this.state;
        return sendPartnerComms(orderId, 'reschedule', { rescheduleDate, rescheduleTime })
            .then(() => {
                toast.success('Successfully Sent');
            })
            .catch((err) => {
                console.error(err, 'Error');
            });
    }

    render() {
        const rescheduleDateLink = Link.state(this, 'rescheduleDate');
        const rescheduleTimeLink = Link.state(this, 'rescheduleTime');
        return (
            <React.Fragment>
                <div className="btn btn-primary" onClick={() => (this.setState({ isOpen: true }))}>Send Partner Reschedule Mailer</div>
                <Modal
                    isOpen={this.state.isOpen}
                    header={'Send partner reschedule mailer'}
                >
                    <div className="row">
                        <div className="col">
                            Reschedule Date
                        </div>
                        <div className="col">
                            <Input valueLink={rescheduleDateLink} type="date" />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            Reschedule Time
                        </div>
                        <div className="col">
                            <Input valueLink={rescheduleTimeLink} type="time" />
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            <div className="btn btn-primary" onClick={this.handleSubmit}>Submit</div>
                        </div>
                        <div className="col">
                            <div className="btn btn-danger" onClick={() => (this.setState({ isOpen: false }))}>Close</div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default LabOrderPartnerComms;
