import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Row,
  Col,
  Label,
} from 'reactstrap';
import { TimePicker } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';
import LoadingComponent from '../../components/common/LoadingComponent';
import { markFollowUp } from '../../services/api/offline-consult';

const FOLLOWUP_DROPDOWN_OPTIONS = ['User', 'Centre', 'Network Team'];
const FollowUpModal = (props) => {
  const {
    isOpen,
    closeFollowUpModal,
    fetchTableData,
    followUpDetails,
    onComplete,
  } = props;
  const [requestId, setRequestId] = useState('');
  const [appDate, setAppDate] = useState('');
  const [followUpTime, setFollowUpTime] = useState('');
  const [followUpRequiredFrom, setFollowUpRequiredFrom] = useState('User');
  const [loading, setLoading] = useState(false);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;

    if (!selectedDate) {
      return;
    }

    const currentDate = moment().format('YYYY-MM-DD');

    if (selectedDate >= currentDate) {
      setAppDate(selectedDate);
    } else {
      toast.warning('Please select a date equal to or after the current date.');
    }
  };

  const disabledTime = (current, type) => {
    const currentMoment = moment();

    if (type === 'hour' && currentMoment) {
      // Disable hours before the current hour
      return {
        disabledHours: () => [...Array(currentMoment.hours()).keys()],
      };
    }

    if (type === 'minute' && currentMoment) {
      // Disable minutes before the current minute
      return {
        disabledMinutes: () => [...Array(currentMoment.minutes()).keys()],
      };
    }

    return {};
  };

  const handleFollowUp = () => {
    setLoading(true);
    const { requestId } = followUpDetails;
    try {
      return markFollowUp(
        requestId,
        followUpRequiredFrom,
        appDate,
        followUpTime
      )
        .then((res) => {
          if (res.message === 'success') {
            toast.success(`Success`);
            setLoading(false);
            closeFollowUpModal();
            onComplete();
          } else {
            const err = new Error('Invalid response');
            err.data = res;
            throw err;
          }
        })
        .catch((err) => {
          console.log({ err, response: err.data });
          setLoading(false);
          toast.error(`Failure! ${err.data.errorMessage}`);
        });
    } catch (e) {
      toast.error(`Failure!`);
      console.log({ e });
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log('Follow Up Details are... before setting' + followUpDetails);
    if (followUpDetails) {
      console.log(followUpDetails);
      setRequestId(followUpDetails.requestId);
    }

    if (followUpDetails) {
      const date = followUpDetails.uAppointmentDate.split(' ')[0];
      setAppDate(date);
    }
  }, [followUpDetails]);
  return (
    <Modal
      centered
      isOpen={isOpen}
      onCancel={closeFollowUpModal}
      toggle={closeFollowUpModal}
    >
      <ModalHeader className="revamp-modal-header" toggle={closeFollowUpModal}>
        Follow Up ({requestId})
      </ModalHeader>
      <ModalBody>
        <form>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label>FollowUp Date:</Label>
              </Col>
              <Col>
                <input
                  className="form-control"
                  type="date"
                  value={appDate}
                  onChange={handleDateChange}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label>FollowUp Time:</Label>
              </Col>
              <Col>
                <TimePicker
                  className="w-full"
                  //   disabledTime=(current) =>
                  //     disabledTime(current, 'hour')
                  disabledTime={(current) => disabledTime(current, 'hour')}
                  onChange={(_, date) => {
                    setFollowUpTime(date);
                  }}
                  format="hh:mm:00 A"
                  defaultValue={
                    followUpTime
                      ? moment(followUpTime, 'hh:mm:00 A')
                      : undefined
                  }
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label>Follow Up required from:</Label>
              </Col>
              <Col>
                <select
                  className="form-control"
                  value={followUpRequiredFrom}
                  onChange={(e) => {
                    setFollowUpRequiredFrom(e.target.value);
                  }}
                >
                  {FOLLOWUP_DROPDOWN_OPTIONS.map((dropdownOption) => (
                    <option>{dropdownOption}</option>
                  ))}
                </select>{' '}
              </Col>
            </Row>
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
        }}
      >
        {loading ? <LoadingComponent color={'black'} /> : <></>}
        <Button
          style={{
            backgroundColor: 'rgb(113, 79, 255)',
            width: '40%',
            borderColor: 'rgb(113, 79, 255)',
            height: '44px',
          }}
          disabled={loading || !followUpTime || !appDate}
          onClick={() => handleFollowUp()}
        >
          Confirm
        </Button>
        <Button
          style={{
            backgroundColor: 'rgb(255, 255, 255)',
            width: '40%',
            borderColor: 'rgb(113, 79, 255)',
            color: 'rgb(113, 79, 255)',
            height: '44px',
          }}
          onClick={() => closeFollowUpModal()}
        >
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FollowUpModal;
