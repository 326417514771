import React, { useEffect, useState } from 'react';
import { getWalletDetailsFromOrderId } from '../../services/api/get-pharmacy-orders';

function UserWallet({ propOrderId , isStarHealthUser, starPolicyNo}) {
  const [orderId, setOrderId] = useState(null);
  const [PharmacyTotalBalance, setPharmacyTotalBalance] = useState(null);
  const [PharmacyTotalRemainingBalance, setPharmacyTotalRemainingBalance] =
    useState(null);
  const [PharmacyCiTotalBalance, setPharmacyCiTotalBalance] = useState(null);
  const [PharmacyCiTotalRemainingBalance, setPharmacyCiTotalRemainingBalance] =
    useState(null);

  const getOrderWallet = (orderId) => {
    console.log(orderId, 'Recieved Order ID');
    getWalletDetailsFromOrderId(orderId).then((response) => {
      const details = response.body;
      console.log({ details }, '[getWalletDetailsFromOrderID]');
      setPharmacyTotalBalance(details.PharmacyTotalBalance);
      setPharmacyTotalRemainingBalance(details.PharmacyTotalBalanceRemaining);
      setPharmacyCiTotalBalance(details.PharmacyCiTotalBalance);
      setPharmacyCiTotalRemainingBalance(
        details.PharmacyCiTotalBalanceRemaining
      );
    });
  };

  useEffect(() => {
    setOrderId(propOrderId);
  }, [propOrderId]);

  useEffect(() => {
    if (orderId !== null) {
      getOrderWallet(orderId);
      console.log(orderId);
    }
  }, [orderId]);

  useEffect(()=>{
    console.log(PharmacyCiTotalBalance, PharmacyTotalBalance, PharmacyCiTotalRemainingBalance, PharmacyTotalRemainingBalance);
  },[PharmacyCiTotalBalance, PharmacyTotalBalance, PharmacyCiTotalRemainingBalance, PharmacyTotalRemainingBalance]);

  const renderCartItems = () => {
    return <tr><th>{PharmacyTotalBalance}</th><th>{PharmacyTotalRemainingBalance}</th><th>{PharmacyCiTotalBalance}</th><th>{PharmacyCiTotalRemainingBalance}</th><th>{isStarHealthUser && starPolicyNo}</th></tr>;
  };

  return <div className="container-fluid">
    <h4 className="font-weight-bold">User's Wallet Detail</h4>
    <table className="table table-bordered thead-dark">
      <thead>
        <tr>
          <th scope="col">Pharmacy Total Wallet Balance</th>
          <th scope="col">Pharmacy Remaining Wallet Balance</th>
          <th scope="col">Pharmacy CI Total Wallet Balance</th>
          {/* <th scope="col">Cost</th> */}
          <th scope="col">Pharmacy CI Remaining Wallet Balance</th>
          {isStarHealthUser &&  <th scope='col'>StarHealth Policy No.</th>}
        </tr>
      </thead>
      <tbody>{renderCartItems()}</tbody>
    </table>
    {/* <br></br>
  <h4 className='font-weight-bold'>Payment Detail</h4>
  <table class="table table-bordered thead-dark">
<thead>
<tr>
<th scope="col">User Paid</th>
<th scope="col">Wallet deduction</th>
<th scope="col">Total amount</th>
<th scope="col">Order Type</th>
</tr>
</thead>
<tbody>
<tr>
<td>{ orderAmount.amount}</td>
<td>{ orderAmount.walletDeduction}</td>
<td>{ orderAmount.totalAmount}</td>
<td>{ orderAmount.orderType}</td>
</tr>
</tbody>
</table> */}
  </div>;
}

export default UserWallet;