import React, { Component } from 'react';
import  { Fragment }  from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import baseRequest from '../../services/api/baseRequest';
import { toast } from 'react-toastify';


import NavBar from '../layout/NavBar';
const PaddedContainer = styled.div`
  margin-top: 5rem;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
`;

class AddDoctors extends Component {

    constructor(props) {
        super(props);
        this.state = {
          doctorId : null
        };
      }
     
     onSubmit = (e) =>{
        e.preventDefault();
        const url = '/new-ops/onlinePayment/doctor/add';
        const method = 'POST';
        const body = {doctorId:parseInt(this.state.doctorId)}
        return baseRequest(url, method, null, body)
        .then((res)=>{
            if (res.message === 'Success') {
              toast.success('Added doctor successfully');
          }
            else {
                const err = new Error('Invalid response');
                err.data = res;
                throw err;
              }
           })
           .catch(err => {
            console.log({ err, response: err.data });
            toast.error('failed to to add doctor ');
          })
     }

     handleUserInput = (e) =>{
         this.setState({
             doctorId : e.target.value
         })
     }

    render() {
        return (
     <Fragment>  
        <NavBar />
           <PaddedContainer>
          <h3 className="">Add Doctor</h3>
          <Form className="mt-4" onSubmit={(e)=>this.onSubmit(e)}>
            <FormGroup row>
              <Label  md={1}>
               Doctor Id
              </Label>
              <Col md={3}>
                <Input
                  type="text"
                  id="name"
                  placeholder="Enter  Doctor Id"
                  md={4}
                  value={this.state.doctorId}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </Col>
              <Button type="submit">Submit</Button>
            </FormGroup>
           
            <Col md={0}>
            
            </Col>
            </Form>
            </PaddedContainer>

          </Fragment>
        );
      }
    }
function mapStateToProps() {
        return {};
  }

export default connect(mapStateToProps)(AddDoctors);