import React from 'react';

import { fetchOrderActivities } from '../../services/api/lab-order-details';

import LoadingComponent from "../common/LoadingComponent";

import { HistoryItem, TimeContainer, StyledHistoryContainerForPharmacy } from '../common/Activities';

import { FaChild } from 'react-icons/fa';
import { MdOpenInNew } from "react-icons/md";

class LabOrderActivities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activities: [],
            loading: false,
        }
    }

    componentDidMount() {
        const { orderId } = this.props;
        if (!orderId) {
            return;
        }
        this.setState({
            loading: true,
        });
        return fetchOrderActivities(orderId)
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        activities: response.result,
                    });
                }
                throw new Error(response.errorMessage || 'Error while fetching order activities');
            })
            .catch((err) => {
                console.error(err, { orderId },
                    'Error while fetching order activities');
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        const { loading, activities } = this.state;
        return (
            (loading ? (
                <LoadingComponent />
            ) : (
                <StyledHistoryContainerForPharmacy>
                    {(activities && activities.length > 0) ? (activities.map(item => {
                        if (item.url !== null) {
                            return (<HistoryItem key={item.activityId}>{item.description} <a target="_blank" href={item.url}><MdOpenInNew/></a><TimeContainer>{item.dateTime}</TimeContainer></HistoryItem>);
                        } else {
                            return (<HistoryItem key={item.activityId}>{item.description}<TimeContainer>{item.dateTime}</TimeContainer></HistoryItem>);
                        }
                    })) : (
                        <HistoryItem>No Activities Here. <FaChild /></HistoryItem>
                    )}
                </StyledHistoryContainerForPharmacy>
            ))
        );
    }
}

export default LabOrderActivities;
