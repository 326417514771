import React from 'react';
import ReactTable from "react-table";

import { fetchProductVoucherDetails,deleteDupesProductCoupons } from '../../services/api/redemptions';
import { toast } from 'react-toastify';
import Modal from '../common/Modal';
import { Button } from 'reactstrap';
import moment from 'moment';

class ProductVoucherDetailsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            voucherDetails: [],
            loading: false,
        };
    }

    handleOnOpen = () => {
        const { productId } = this.props.productDetails;
        this.setState({
            loading: true,
        });
        return fetchProductVoucherDetails(productId)
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        voucherDetails: response.data,
                    });
                }
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            });
    };

    deleteDupesCoupens = (randomString,productId) => {
        console.log({randomString,productId},'[randomString]');
        return deleteDupesProductCoupons(randomString,productId)
            .then((response) => {
                console.log(response,'[response]');
                if(response.message === 'success') {
                    this.handleOnOpen();
                    toast.success('Successfully Deleted Dupes vouchers');
                }
            }).catch((err) => {
                console.log(err,'[err]');
            })

    }

    getColumns = () => {
        return [{
            accessor: 'couponType',
            Header: 'Coupon Type',
            width: 200,
            className: 'text-center',
        }, {
            accessor: 'expiryDate',
            Header: 'Expiry Date',
            width: 200,
            className: 'text-center',
            Cell: (row) => ((row.original.expiryDate) ? (moment.unix(row.original.expiryDate).format('LL')) : ('N/A'))
        }, {
            accessor: 'createdAt',
            Header: 'Uploaded On',
            width: 200,
            className: 'text-center',
            Cell: (row) => (moment.unix(row.original.createdAt).format('LLL'))
        }, {
            accessor: 'couponCount',
            Header: 'Coupons',
            width: 200,
            className: 'text-center',
        }, {
            accessor: 'unusedCoupons',
            Header: 'Unused Coupons',
            width: 200,
            className: 'text-center',
        },{
            accessor: 'Action',
            Headers:'Actions',
            width: 200,
            className: 'text-center',
            Cell: (row) => {
                console.log(row.original);
                return (
                    <div>
                        <Button
                            color="danger"
                            onClick={() => this.deleteDupesCoupens(row.original.randomString, row.original.productId)}
                        >
                            Delete
                        </Button>
                    </div>
                );
            }
        }];
    };

    render() {
        const { isOpen, onClose, productDetails } = this.props;
        const { voucherDetails, loading } = this.state;
        return (
            <Modal
                header={`Voucher Details for ${productDetails.productName}`}
                isOpen={isOpen}
                onOpen={this.handleOnOpen}
                onClose={onClose}
            >
                <ReactTable
                    data={voucherDetails}
                    columns={this.getColumns()}
                    loading={loading}
                    showPaginationTop={false}
                    showPaginationBottom={false}
                    defaultPageSize={10}
                />
            </Modal>
        );
    }
}

export default ProductVoucherDetailsModal;
