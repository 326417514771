import React from 'react';
import Pubnub from 'pubnub-react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
// import './custom-toast.css'; // Custom CSS file
import '../revamp-offline-ops/revamp-react-table.css'; // Custom CSS file
import alertConfig from './config';
import config from '../../config';

class PubnubAlertComponent extends React.Component {
  constructor(props) {
    super(props);
    const { publishKey, subscribeKey } = config.pubnub;
    this.pubnub = new Pubnub({
      publishKey,
      subscribeKey,
    });
    this.pubnub.init(this);
  }

  unsubscribeToPubnub() {
    const commentsConfig = alertConfig.comments;

    this.pubnub.unsubscribe({
      channels: [
        commentsConfig.offline.channel,
        commentsConfig.network.channel,
      ],
    });
  }

  subscribeToPubnub() {
    const { user } = this.props;

    if (!user.token || !user.showOps) {
      this.unsubscribeToPubnub();
      return;
    }
    const commentsConfig = alertConfig.comments;
    const { userId } = this.props.user;
    if (user.networkCommentsActive) {
      // Subscribe to network comments only if active
      console.log('Network Comments active subscribing to pubnub...');
      this.pubnub.subscribe({
        channels: [
          commentsConfig.offline.channel,
          commentsConfig.network.channel,
        ],
        withPresence: true,
      });

      this.pubnub.getMessage(commentsConfig.network.channel, (msg) => {
        const messagePayload = msg.message;
        if (
          messagePayload &&
          `${messagePayload.senderId}${messagePayload.userType}` !==
            `${userId}ops`
        ) {
          toast.info(
            `Network - ${messagePayload.name}: ${messagePayload.text}`,
            {
              className: 'custom-toast',
              bodyClassName: 'custom-toast-body',
              closeButtonClassName: 'custom-toast-close',
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      });

      this.pubnub.getMessage(commentsConfig.offline.channel, (msg) => {
        const messagePayload = msg.message;
        if (
          messagePayload &&
          `${messagePayload.senderId}${messagePayload.userType}` !==
            `${userId}ops`
        ) {
          toast.info(`Ops - ${messagePayload.name}: ${messagePayload.text}`, {
            className: 'custom-toast',
            bodyClassName: 'custom-toast-body',
            closeButtonClassName: 'custom-toast-close',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      });
    }
  }

  simulateMessages() {
    const messages = [
      //   {
      //     senderId: 'user1',
      //     userType: 'ops',
      //     name: 'User One',
      //     text: 'This is a test message 1',
      //   },
      //   {
      //     senderId: 'user2',
      //     userType: 'ops',
      //     name: 'User Two',
      //     text: 'This is a test message 2',
      //   },
      //   {
      //     senderId: 'user3',
      //     userType: 'ops',
      //     name: 'User Three',
      //     text: 'This is a test message 3',
      //   },
    ];

    messages.forEach((messagePayload, index) => {
      setTimeout(() => {
        toast.info(`Ops - ${messagePayload.name}: ${messagePayload.text}`, {
          className: 'custom-toast',
          bodyClassName: 'custom-toast-body',
          closeButtonClassName: 'custom-toast-close',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }, index * 3000); // Show a message every 3 seconds
    });
  }

  componentDidUpdate() {
    this.subscribeToPubnub();
  }

  componentDidMount() {
    this.subscribeToPubnub();
    this.simulateMessages(); // Call the simulateMessages function to test locally
  }

  componentWillUnmount() {
    this.unsubscribeToPubnub();
  }

  render() {
    return (
      <div>
        <ToastContainer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PubnubAlertComponent);
