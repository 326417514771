export const defaultState = {
  verticalsDocOnCall: null,
  verticalsSpecialistConsults: null,
  verticalsOfflineSp: null,
  verticalsScheduledConsults: null,

  doctorsDocOnCall: null,
  doctorsSpecialistConsults: null,
  doctorsOfflineSp: null,
  doctorsScheduledConsults: null,

  sponsorsDocOnCall: null,
  sponsorsSpecialistConsults: null,
  sponsorsOfflineSp: null,
  sponsorsScheduledConsults: null,

  groupOfDocOnCall: null,
  groupOfSpecialistConsults: null,
  groupOfOfflineSp: null,
  groupOfScheduledConsults: null,

  startDateDocOnCall: null,
  startDateSpecialistConsults: null,
  startDateOfflineSp: null,
  startDateScheduledConsults: null,

  endDateDocOnCall: null,
  endDateSpecialistConsults: null,
  endDateOfflineSp: null,
  endDateScheduledConsults: null,

  internalDocOnCall: null,
  internalSpecialistConsults: null,
  internalOfflineSp: null,
  internalScheduledConsults: null,
};

export default function filterHeader(state = defaultState, action) {
  if (action.type.includes('SET_VERTICALS_FILTER')) {
    const verticals = action.payload;
    if (action.type.includes('doc-on-call')) {
      return Object.assign({}, state, {
        verticalsDocOnCall: verticals,
      });
    } else if (action.type.includes('specialist-consults')) {
      return Object.assign({}, state, {
        verticalsSpecialistConsults: verticals,
      });
    } else if (action.type.includes('offline-sp')) {
      return Object.assign({}, state, {
        verticalsOfflineSp: verticals,
      });
    } else if (action.type.includes('scheduled-consults')) {
      return Object.assign({}, state, {
        verticalsScheduledConsults: verticals,
      });
    }
  } else if (action.type.includes('SET_DOCTORS_FILTER')) {
    const doctors = action.payload;
    if (action.type.includes('doc-on-call')) {
      return Object.assign({}, state, {
        doctorsDocOnCall: doctors,
      });
    } else if (action.type.includes('specialist-consults')) {
      return Object.assign({}, state, {
        doctorsSpecialistConsults: doctors,
      });
    } else if (action.type.includes('offline-sp')) {
      return Object.assign({}, state, {
        doctorsOfflineSp: doctors,
      });
    } else if (action.type.includes('scheduled-consults')) {
      return Object.assign({}, state, {
        doctorsScheduledConsults: doctors,
      });
    }
  } else if (action.type.includes('SET_SPONSORS_FILTER')) {
    const sponsors = action.payload;
    if (action.type.includes('doc-on-call')) {
      return Object.assign({}, state, {
        sponsorsDocOnCall: sponsors,
      });
    } else if (action.type.includes('specialist-consults')) {
      return Object.assign({}, state, {
        sponsorsSpecialistConsults: sponsors,
      });
    } else if (action.type.includes('offline-sp')) {
      return Object.assign({}, state, {
        sponsorsOfflineSp: sponsors,
      });
    } else if (action.type.includes('scheduled-consults')) {
      return Object.assign({}, state, {
        sponsorsScheduledConsults: sponsors,
      });
    }
  }

  else if (action.type.includes('SET_GROUP_OF_FILTER')) {
    const payload = action.payload;
    if (action.type.includes('doc-on-call')) {
      return Object.assign({}, state, {
        groupOfDocOnCall: payload,
      });
    } else if (action.type.includes('specialist-consults')) {
      return Object.assign({}, state, {
        groupOfSpecialistConsults: payload,
      });
    } else if (action.type.includes('offline-sp')) {
      return Object.assign({}, state, {
        groupOfOfflineSp: payload,
      });
    } else if (action.type.includes('scheduled-consults')) {
      return Object.assign({}, state, {
        groupOfScheduledConsults: payload,
      });
    }
  } else if (action.type.includes('SET_START_DATE_FILTER')) {
    const payload = action.payload;
    if (action.type.includes('doc-on-call')) {
      return Object.assign({}, state, {
        startDateDocOnCall: payload,
      });
    } else if (action.type.includes('specialist-consults')) {
      return Object.assign({}, state, {
        startDateSpecialistConsults: payload,
      });
    } else if (action.type.includes('offline-sp')) {
      return Object.assign({}, state, {
        startDateOfflineSp: payload,
      });
    } else if (action.type.includes('scheduled-consults')) {
      return Object.assign({}, state, {
        startDateScheduledConsults: payload,
      });
    }
  } else if (action.type.includes('SET_END_DATE_FILTER')) {
    const payload = action.payload;
    if (action.type.includes('doc-on-call')) {
      return Object.assign({}, state, {
        endDateDocOnCall: payload,
      });
    } else if (action.type.includes('specialist-consults')) {
      return Object.assign({}, state, {
        endDateSpecialistConsults: payload,
      });
    } else if (action.type.includes('offline-sp')) {
      return Object.assign({}, state, {
        endDateOfflineSp: payload,
      });
    } else if (action.type.includes('scheduled-consults')) {
      return Object.assign({}, state, {
        endDateScheduledConsults: payload,
      });
    }
  }

  else if (action.type.includes('SET_INTERNAL_FILTER')) {
    const payload = action.payload;
    if (action.type.includes('doc-on-call')) {
      return Object.assign({}, state, {
        internalDocOnCall: payload,
      });
    } else if (action.type.includes('specialist-consults')) {
      return Object.assign({}, state, {
        internalSpecialistConsults: payload,
      });
    } else if (action.type.includes('offline-sp')) {
      return Object.assign({}, state, {
        internalOfflineSp: payload,
      });
    } else if (action.type.includes('scheduled-consults')) {
      return Object.assign({}, state, {
        internalScheduledConsults: payload,
      });
    }
  }


  else if (action.type.includes('SET_PAGE_INDEX_FILTER')) {
    const payload = action.payload;
    if (action.type.includes('doc-on-call')) {
      return Object.assign({}, state, {
        pageIndexDocOnCall: payload,
      });
    } else if (action.type.includes('specialist-consults')) {
      return Object.assign({}, state, {
        pageIndexSpecialistConsults: payload,
      });
    } else if (action.type.includes('offline-sp')) {
      return Object.assign({}, state, {
        pageIndexOfflineSp: payload,
      });
    } else if (action.type.includes('scheduled-consults')) {
      return Object.assign({}, state, {
        pageIndexScheduledConsults: payload,
      });
    }
  }

  else if (action.type.includes('SET_PAGE_SIZE_FILTER')) {
    const payload = action.payload;
    if (action.type.includes('doc-on-call')) {
      return Object.assign({}, state, {
        pageSizeDocOnCall: payload,
      });
    } else if (action.type.includes('specialist-consults')) {
      return Object.assign({}, state, {
        pageSizeSpecialistConsults: payload,
      });
    } else if (action.type.includes('offline-sp')) {
      return Object.assign({}, state, {
        pageSizeOfflineSp: payload,
      });
    } else if (action.type.includes('scheduled-consults')) {
      return Object.assign({}, state, {
        pageSizeScheduledConsults: payload,
      });
    }
  }


  else return state;
}
