import React from 'react';

const AllOptions = () => (
    <React.Fragment>
        <option value=''>All</option>
        <option value='pending'>Request Received</option>
        <option value='confirmed'>Request Confirmed</option>
        <option value='cancelled'>Request Cancelled</option>
        <option value='rx-uploaded'>Rx uploaded</option>
        <option value='pre-auth-requested'>Pre auth requested</option>
        <option value='pre-auth-approved'>Pre auth Approved</option>
        <option value='rx-approved'>Rx approved</option>
        <option value='rx-rejected'>Rx Rejected</option>
        <option value='rx-pending'>Rx pending</option>
    </React.Fragment>
)

const StatusFilter = ({ value, onChange }) => {

    const changeFilterType = (event) => {
        onChange(event.target.value === '' ? null : event.target.value)
    }

    return <select
        onChange={changeFilterType}
        style={{
            width: "100%",
            height: "40px",
            fontSize: "12px"
        }}
        value={value || ''}
    >
        <AllOptions />
    </select>
}

export default StatusFilter