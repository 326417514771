import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

class ResultModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = () => {
    switch (this.props.confirmRequestFrom) {
      case 'initiate_call': {
        this.props.initiateConfirmCall();
        break;
      }
      case 'initiate_ops_call': {
        this.props.initiateOpsCall();
        break;
      }
      case 'initiate_ops_call_schedule_consult': {
        this.props.initiateOpsCallScheduleConsult();
        break;
      }
      case 'shift_consult': {
        this.props.shiftConfirmConsultation();
        break;
      }
      case 'add_consult_note': {
        this.props.addConfConsultationNotes();
        break;
      }
      case 'end_consultations': {
        this.props.endConfConsultation();
        break;
      }
      default: {
        break;
      }
    }
    this.props.closeModal();
  };

  render() {
    return (
      <Modal isOpen toggle={this.props.closeModal}>
        <ModalBody>
          <h5>Are you sure!</h5>
        </ModalBody>
        <ModalFooter>
          <button className="bg-info" onClick={this.handleSubmit}>
            Ok
          </button>
          <button className="bg-secondary" onClick={this.props.closeModal}>
            Close
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ResultModal;
