import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';

const MarkDentalAppointmentComplete = ({requestId,
    submitModal,
    closeModal,
    onComplete,
    modalIsOpen
}) => {

    const handleSubmit = () => {
        return submitModal()
        .then(() => {
            closeModal()
            onComplete()
        })
    }
    const handleCancel = () => {
        closeModal()
        onComplete()
    }


    return <Modal isOpen={modalIsOpen}
    toggle={closeModal}>
        <ModalHeader className="bg-primary" toggle={closeModal}>
            Sure you want to mark as completed? (Request Id: {requestId})
        </ModalHeader>
        <ModalFooter>
            <Button color="info" onClick={handleCancel}>
                Cancel
            </Button>
            <Button color="info" onClick={handleSubmit}>
                Submit
            </Button>
        </ModalFooter>
    </Modal>
};

export default MarkDentalAppointmentComplete;