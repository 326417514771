import React from 'react';

const OnboardingStatusFilter = ({ value, onChange }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <select
      style={{
        width: '100%',
        height: '40px',
        fontSize: '12px',
        borderRadius: '10px',
      }}
      onChange={handleChange}
      value={value}
    >
      <option value="">All</option>
      <option value="pending">Pending</option>
      <option value="live">Live</option>
      <option value="not-live">Not Live</option>
    </select>
  );
};

export default OnboardingStatusFilter;
