export const searchFilterMethod = (filter, row) => {
    // console.log(filter, row, 'Inside Filter');
    if (!row[filter.id]) {
        return false;
    }
    return row[filter.id].toString().toLowerCase().startsWith(filter.value.toString().toLowerCase()) || row[filter.id].toString().toLowerCase().endsWith(filter.value.toString().toLowerCase());
};

export const activeDropDownFilterMethod = (filter, row) => {
    // if(!row[filter.id]) return false;
    
    if(filter.value==="Yes") return row.active===1
    
    if (filter.value==="No") return row.active==0
    
    return false;
}
