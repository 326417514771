import { baseRequestWithHeaders } from './baseRequest';



export const getOrders = (filters, appliedOrderBy) => {
    console.log('filters', filters);


    if (filters.status && filters.status === 'all') {
        filters.status = null;
    }
    if (filters.receiptUrl && filters.receiptUrl === 'all') {
        filters.receiptUrl = null;
    }
    const urlQuery = new URLSearchParams(filters).toString();

    const url = `/new-ops/claims-recon/reconcile-partner-transactions/1?vendorId=1&${urlQuery}`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, { orderBy: appliedOrderBy });
};

export const uploadPartnerTnx = (file, tableName, partnerId) => {

    const data = new FormData();
    data.append('file', file);
    data.append('tableName', tableName);
    data.append('partnerId', partnerId);

    const url = `/new-ops/claims-recon/upload-partner-tnx`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, data);
};

export const getConflictingOrders = () => {
    const url = `/new-ops/claims-recon/conflicting-orders`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method, null, null);
};

export const getConflictingItems = (referenceId) => {
    const url = `/new-ops/claims-recon/conflicting-orders/${referenceId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method, null, null);
};

export const reconcileOrder = (referenceId) => {
    const url = `/new-ops/claims-recon/conflicting-orders/${referenceId}`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, null);
};

export const loadCostUpdates = () => {
    const url = `/new-ops/claims-recon/updates-tnx-costs`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const updateCostDiff = () => {
    const url = `/new-ops/claims-recon/updates-tnx-costs`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, null);
};

export const getMissingTransactions = () => {
    const url = `/new-ops/claims-recon/missing-tnx`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const updatePartnerOrderId = (orderId, partnerTestCode, partnerOrderId) => {
    const url = `/new-ops/claims-recon/update-partner-order`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, { partnerTestCode, partnerOrderId, orderId });
};
