import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  Container,
  Label,
} from 'reactstrap';

import { toast } from 'react-toastify';

class IntimationModalCancel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remarks: '',
    };
  }

  handleChange = e => {
    const { value } = e.target;
    this.setState({
      remarks: value,
    });
  };

  handleSubmit = () => {
    const { remarks } = this.state;
    console.log({ remarks},'[handleSubmit]');
    if (!remarks.length) {
      toast.error('No Remark added');
      return;
    }
    return this.props.submitModal(remarks)
        .then(() => {
          this.props.closeModal();
          this.props.onComplete();
        });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        toggle={() => this.props.closeModal()}
        className={this.props.className}
      >
        <ModalHeader className="bg-primary" toggle={this.props.closeModal}>
          Sure you want to cancel?
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <Row>
                  <label>Reason for cancellation:</label>
                </Row>
                <br />
                <Row>
                  <div>
                    {/* <Input
                      type = "input"
                      name = "reason"
                      onChange = {this.handleChange}
                      placeholder = "Reason for cancellation"
                    /> */}
                     <select onChange={this.handleChange}>
                      <option value="">Select Reason</option>
                      <option value="User requested">User requested</option>
                      <option value=" Insufficient Documents/Request"> Insufficient Documents/Request</option>
                    </select>
                  </div>
                </Row>
                <br />
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={this.handleSubmit}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default IntimationModalCancel;
