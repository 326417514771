import React from 'react';

import Modal from '../common/Modal';

import LoadingComponent from '../common/LoadingComponent'
import { raiseCashgramReimbursementForAHC } from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';


class CashgramModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            amount : 0,
            ahcId: 0
        };
    }

    handleCloseCashgramModal = () => {
        this.setState({}, this.props.onClose);
    }

    handleSubmit = () => {
        this.setState({loading: true});
        const payload = {
            amount: this.state.amount,
            ahcId: this.state.ahcId,
        }
        return raiseCashgramReimbursementForAHC(payload)
        .then((response) => {
            toast.success('Successfully Refund!');
            this.props.onClose(true);
        })
        .catch((err) => {
            console.log('Error raiseCashgramReimbursementForAHC', err);
            toast.error(err.response.data.errorMessage ? err.response.data.errorMessage : 'Error in raising cashgram Link!');
        })
        .finally(() => {
            this.handleCloseCashgramModal();
            this.setState({
                loading: false, 
            });
        })
    }

    amountChangeHandler = (e) => {
        const { value } = e.target;
        this.setState({
            amount: value,
        });
    }

    ahcChangeHandler = (e) => {
        const { value } = e.target;
        this.setState({
            ahcId: value,
        });
    }

    render() {
        const {isOpen } = this.props;
        return (
            <Modal
                isOpen={isOpen}
                header={`Enter Amount For AHC`}
                onOpen={this.onModalOpen}
            >
            <div style={{display: 'flex', marginTop: "10px"}}>
            <input style={{width: '100%', padding: '5px', marginRight:'5px'}}  type="number" placeholder="AHC ID" onChange={this.ahcChangeHandler}/>
            <input style={{width: '100%', padding: '5px'}}  type="number" placeholder="Reimbursement Amount" onChange={this.amountChangeHandler}/>
            </div>
            <div style={{display: 'flex', marginTop: "10px"}}>
                <button className="btn btn-primary btn-primary" disabled={!this.state.amount || !this.state.amount > 0 ||!this.state.ahcId || !this.state.ahcId > 0 || this.state.loading} onClick={this.handleSubmit}>Raise Cashgram Reimbursement</button>
                <button className="btn btn-danger btn-block" disabled={this.state.loading} style={{width:"100px", marginLeft:"5px"}} onClick={()=>{this.handleCloseCashgramModal();}}>
                    Close
                </button>
            </div>
            </Modal>
        );
    }
}


export default CashgramModal;
