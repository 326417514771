
export default (jsonArray) => {
    if (!jsonArray || !Array.isArray(jsonArray)) {
        return null;
    }
    const headers = [];
    Object.keys(jsonArray[0]).forEach((key) => {
        headers.push(key);
    });
    let body = '';
    for (let i = 0; i < jsonArray.length; i++) {
        const json = jsonArray[i];
        const arr = [];
        for (let j = 0; j < headers.length; j++) {
            const val = json[headers[j]];
            arr.push(`"${val}"`);
        }
        body += arr.join(',');
        body += '\n';
        // console.log({ headers, arr, i, body });
    }
    return headers.join(',').concat('\n').concat(body);
};
