import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loginCheck from "../login/LoginCheck";
import NavBar from "../layout/NavBar";

import RedemptionOpsHome from './RedemptionOpsHome';

class RedemptionOpsNavigator extends React.Component {

    render() {
        return (
            <React.Fragment>
                <NavBar />
                <div className="container-fluid" style={{ marginTop: '70px' }}>
                    <Switch>
                        <Route
                            exact
                            path="/redemption-ops"
                            name="Redemption OPS"
                            component={RedemptionOpsHome}
                        />
                    </Switch>
                </div>
            </React.Fragment>
        );
    }
}

export default loginCheck(RedemptionOpsNavigator);
