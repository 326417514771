import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { IoIosCall, IoMdSwap } from 'react-icons/io';
import {
  initiateCarrierCall,
  initiateOpsCall,
  shiftConsult,
  addNoteConsult,
  endConsult,
  cancelConsultation,
  cancelScheduledConsultation,
  initiateOpsCallScheduleConsult,
  updateConsultationNotes
} from '../../../services/api/doc-on-call';
import ResultModal from './ResultModal';
import ConfirmModal from './ConfirmModal';

import { Select } from 'valuelink/tags';
import Link from 'valuelink';

import LoadingComponent from '../LoadingComponent';

import { toast } from 'react-toastify';
import ReactSelect from 'react-select'

export default class SuperAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctorId: '',
      addNote: '',
      showModal: false,
      confirmModal: false,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      confirmReqFrom: '',
      cancelReason: '',
      shiftReason: '',
      cancelConsultationLoading: false,
      isScheduledConsult: this.props.consultationType === 'scheduled-consults',
      showConsultationNoteInput : false
    };
  }  
 
  // Initiate Carrier Call
  initiateCall = () => {
    this.setState({
      confirmModal: true,
      confirmReqFrom: 'initiate_call',
    });
  };
  requestOpsCallScheduleConsult = () => {
    this.setState({
      confirmModal: true,
      confirmReqFrom: 'initiate_ops_call_schedule_consult',
    });
  }
  requestOpsCall = () => {
    this.setState({
      confirmModal: true,
      confirmReqFrom: 'initiate_ops_call',
    });
  };
  initiateConfirmCall = () => {
    const consultId = this.props.consultationId;
    initiateCarrierCall(consultId)
      .then(() => {
        this.setState({
          showModal: true,
          modalHeader: 'Success',
          modalBody: 'Call is Connected Properly',
          bgColor: 'bg-success',
        });
      })
      .catch((err) => {
        this.setState({
          showModal: true,
          modalHeader: 'Danger',
          modalBody: 'Call is not Connected Properly. Please Try again.',
          bgColor: 'bg-danger',
        });
      });
  };

  initiateConfirmOpsCallScheduleConsult = () => {
    const scheduledConsultationId = this.props.consultationId;
    initiateOpsCallScheduleConsult(scheduledConsultationId)
      .then(() => {
        this.setState({
          showModal: true,
          modalHeader: 'Success',
          modalBody: 'Call is Connected Properly',
          bgColor: 'bg-success',
        });
      })
      .catch((err) => {
        this.setState({
          showModal: true,
          modalHeader: 'Danger',
          modalBody: 'Call is not Connected Properly. Please Try again.',
          bgColor: 'bg-danger',
        });
      });
  };

  initiateConfirmOpsCall = () => {
    const consultId = this.props.consultationId;
    initiateOpsCall(consultId)
      .then(() => {
        this.setState({
          showModal: true,
          modalHeader: 'Success',
          modalBody: 'Call is Connected Properly',
          bgColor: 'bg-success',
        });
      })
      .catch((err) => {
        this.setState({
          showModal: true,
          modalHeader: 'Danger',
          modalBody: 'Call is not Connected Properly. Please Try again.',
          bgColor: 'bg-danger',
        });
      });
  };
  // Shift Consultation
  shiftConsultation = () => {
    const doctorId = this.state.doctorId;
    const { shiftReason } = this.state;

    console.log(shiftReason,"shift reason");
    if (!shiftReason && shiftReason.trim().length) {
      toast.error('Please select a shift reason');
      return;
    } 

    if (doctorId) {
      this.setState({
        confirmModal: true,
        confirmReqFrom: 'shift_consult',
      });
    }
  };

  shiftConfirmConsultation = () => {
    const consultId = this.props.consultationId;
    const doctorId = this.state.doctorId;
    const { shiftReason } = this.state;
    // console.log(consultId, doctorId, "Shift Consultation");
    this.setState({ confirmModal: true });

    shiftConsult(consultId, doctorId, shiftReason)
      .then((resp) => {
        this.setState({
          showModal: true,
          modalHeader: 'Success',
          modalBody: 'Consultation is Shifted Properly',
          bgColor: 'bg-success',
        });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          showModal: true,
          modalHeader: 'Danger',
          modalBody: 'Consultation is not Shifted Properly',
          bgColor: 'bg-danger',
        });
      });
  };
  HandleChangeDocId = (e) => {
    this.setState({ doctorId: e.target.value });
  };

  // Add Consultation Notes
  addConsultationNotes = () => {
    const addNote = this.state.addNote;
    if (addNote) {
      if(this.state.showConsultationNoteInput){
        updateConsultationNotes(addNote);
      }
      this.setState({
        confirmModal: true,
        confirmReqFrom: 'add_consult_note',
      });
    }
  };
  addConfConsultationNotes = () => {
    const consultId = this.props.consultationId;
    const addNote = this.state.addNote;
    console.log(consultId, addNote, 'Add Consultation Notes');
    this.setState({ confirmModal: true });
    addNoteConsult(consultId, addNote, this.state.isScheduledConsult)
      .then((resp) => {
        // console.log(resp, "Shift Consult");
        this.setState({
          showModal: true,
          modalHeader: 'Success',
          modalBody: 'Consultation Note is added successfully.',
          bgColor: 'bg-success',
        });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          showModal: true,
          modalHeader: 'Danger',
          modalBody: 'Consultation Note is not added properly.',
          bgColor: 'bg-danger',
        });
      });
  };
  HandleChangeDocNote = (option) => {
    if(this.state.showConsultationNoteInput){
      this.setState({ addNote: option.target.value });
    }
    else if(option.value === 'Other'){
      this.setState({ showConsultationNoteInput: true });
    }
    else{
      this.setState({ addNote: option.value });
    }
  };
  // End Consultations
  endConsultation = () => {
    this.setState({
      confirmModal: true,
      confirmReqFrom: 'end_consultations',
    });
  };
  endConfConsultation = () => {
    const consultId = this.props.consultationId;
    this.setState({ confirmModal: true });
    endConsult(consultId)
      .then((resp) => {
        // console.log(resp, "end Consult");
        this.setState({
          showModal: true,
          modalHeader: 'Success',
          modalBody: 'Consultation is ended properly',
          bgColor: 'bg-success',
        });
      })
      .catch((err) => {
        // console.log(err);
        this.setState({
          showModal: true,
          modalHeader: 'Danger',
          modalBody: 'Consultation is not ended. Please try again.',
          bgColor: 'bg-danger',
        });
      });
  };

  cancelConsultation = () => {
    const { cancelReason } = this.state;
    const consultationId = this.props.consultationId;
    console.log(cancelReason, consultationId, 'Cancel Button clicked');
    if (!cancelReason) {
      toast.error('Please select a reason');
      return;
    }
    this.setState({
      cancelConsultationLoading: true,
    });
    const cancelConsultPromise = !this.state.isScheduledConsult
      ? cancelConsultation(consultationId, cancelReason)
      : cancelScheduledConsultation(consultationId, cancelReason)
    return cancelConsultPromise
      .then((response) => {
        if (response && response.message === 'success') {
          toast.success('Successfully cancelled the consultation');
        } else {
          throw new Error(
            response.errorMessage || "Couldn't cancel the consultation"
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        this.setState({
          cancelConsultationLoading: false,
        });
      });
  };

  render() {
    const cancelReasonLink = Link.state(this, 'cancelReason');
    const shiftReasonLink = Link.state(this, 'shiftReason');

    if(this.props.consultationNotesList){
      this.props.consultationNotesList.push({id : this.props.consultationNotesList.length + 1, label : "Other", value : "Other"})
    }

        return (
      <Form>
        {!this.state.isScheduledConsult ? (
          <React.Fragment>
            <FormGroup>
              <Label>
                Initiate Carrier
                Call:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Label>
              <Button className="bg-info" onClick={this.initiateCall}>
                <IoIosCall />
              </Button>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label>
                Initiate Ops
                Call:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Label>
              <Button className="bg-info" onClick={this.requestOpsCall}>
                <IoIosCall />
              </Button>
            </FormGroup>
            <hr />
            <FormGroup>
              <div style={{display:'flex', justifyContent: 'space-between'}}>
              <Label for="examplePassword">
                Shift
                Consultation:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Label>
              <div>
              <Select className="form-control" valueLink={shiftReasonLink}>
              <option value="">Select Reason</option>
                <option value="User wants immediate consultation, Current doctor is Busy/Not Reachable">User wants immediate consultation, Current doctor is Busy/Not Reachable</option>
                <option value="User wants immediate consultation, Current doctor is Not available">User wants immediate consultation, Current doctor is Not available</option>
                <option value="Booked with wrong Doctor/Vertical/Language prefrence">Booked with wrong Doctor/Vertical/Language prefrence</option>
                <option value="Ticket/ Slack support request">Ticket/ Slack support request</option>
                <option value="User / Doctor both are not reachable - TAT breached">User / Doctor both are not reachable - TAT breached</option>
              </Select>
            </div>
              </div>
              <div style={{display:'flex', justifyContent: 'space-between'}}>
              <Input
                type="text"
                placeholder="New Doctor's Id"
                onChange={this.HandleChangeDocId}
              />
              <Button className="bg-danger" onClick={this.shiftConsultation}>
                <IoMdSwap />
              </Button>
              </div>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label for="exampleText">Consultation Note:</Label>
             { !this.state.showConsultationNoteInput ? <ReactSelect options = { !this.props.consultationNotesList ? [{id : 1, label : "Other", value : "Other"}] : this.props.consultationNotesList } onChange = {this.HandleChangeDocNote} styles={{
                  option: (base) => ({
                    ...base,
                    cursor: 'pointer'
                  }),
                }}/> : <input type='text' onChange = {this.HandleChangeDocNote} className='consultation-note-input'/>}
              <br />
              <Button
                className="bg-success"
                onClick={this.addConsultationNotes}
              >
                Save
              </Button>
            </FormGroup>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FormGroup>
              <Label>
                Initiate Ops
                Call:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Label>
              <Button className="bg-info" onClick={this.requestOpsCallScheduleConsult}>
                <IoIosCall /> 
              </Button>
            </FormGroup>
            <hr />
            <FormGroup>
              <Label for="exampleText">Consultation Note:</Label>
              { !this.state.showConsultationNoteInput ? <ReactSelect options = { !this.props.consultationNotesList ? [{id : 1, label : "Other", value : "Other"}] : this.props.consultationNotesList } onChange = {this.HandleChangeDocNote} styles={{
                  option: (base) => ({
                    ...base,
                    cursor: 'pointer'
                  }),
                }}/> : <input type='text' onChange = {this.HandleChangeDocNote} className='consultation-note-input'/>}
              <br />
              <Button
                className="bg-success"
                onClick={this.addConsultationNotes}
              >
                Save
              </Button>
            </FormGroup>
          </React.Fragment>
        )}
        <hr />
        <div className="container-fluid">
          {!this.state.isScheduledConsult ? (
            <React.Fragment>
              <div className="row">
                <div className="col">
                  <label>End Consultation:</label>
                </div>
                <div className="col text-right">
                  <div
                    className="btn btn-danger"
                    onClick={this.endConsultation}
                  >
                    End
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            ''
          )}
          <div className="row mt-2">
            <label className="col">Cancel Consultation:</label>
            <div className="col">
              <Select className="form-control" valueLink={cancelReasonLink}>
                <option value="">Select Reason</option>
                <option value="User Requested">User Requested</option>
                <option value="Demo Appointment">Demo Appointment</option>
              </Select>
            </div>
            <div className="col text-right">
              {this.state.cancelConsultationLoading ? (
                <LoadingComponent />
              ) : (
                <div
                  className="btn btn-danger"
                  onClick={this.cancelConsultation}
                >
                  Cancel
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        {this.state.confirmModal && (
          <ConfirmModal
            closeModal={() => this.setState({ confirmModal: false })}
            confirmRequestFrom={this.state.confirmReqFrom}
            initiateConfirmCall={this.initiateConfirmCall}
            initiateOpsCallScheduleConsult={this.initiateConfirmOpsCallScheduleConsult}
            initiateOpsCall={this.initiateConfirmOpsCall}
            shiftConfirmConsultation={this.shiftConfirmConsultation}
            addConfConsultationNotes={this.addConfConsultationNotes}
            endConfConsultation={this.endConfConsultation}
          />
        )}
      </Form>
    );
  }
}
