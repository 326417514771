import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { callCustomRequest } from '../../../services/api/offline-consult';
import { connect } from 'react-redux';
import { FaSpinner, FaPhone } from 'react-icons/fa';
import ConfirmationModal from '../../common/ConfirmationModal';
import { RegularText, Text } from '../../common/Text';

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const DetailName = styled(Text)`
  font-size: 1rem;
  width: 8rem;
  color: #4c4c4c;
`;

const DetailText = styled(RegularText)`
  font-size: 1rem;
  color: #787778;
  margin-left: 0.25rem;
`;

const SelectElement = styled(Select)`
  /* border: 0.1rem solid #8852cc; */
  flex: 1 0;
  width: 16rem;
  margin-bottom: 0.625rem;
`;

class CustomCallingSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userType: '',
      phone: '',
      loading: false,
      error: null,
      showConfirmationModal: false,
      callReasonOptions: [
        "a pending action on the rescheduled request from you",
        "a change in the price",
        "the required reconfirmation from you", 
        "the doctor’s unavailability and available alternatives", 
        "the slot’s unavailability and available alternatives", 
        "a pending confirmation from the centre",
        "an update",
        "a pending prescription upload"
      ],
      callReasonOptionsForCenter: [
        "Seeking appointment confirmation",
        "Seeking alternative for the appt requested for the centre",
        "Seeking appointment reconfirmation for prepayment",
        "Seeking appointment reconfirmation for the appointment",
        "Verification of payment details",
        "Verification of settled payment",
        "Verification of consultation fees",
        "Clarification on tie-up issue",
        "Cashless letter acknowledgement issue"
    ],
      isCallModalOpen : false,
      selectedCallReason: null
    };

    this.callStakeholdersOptions = [
      { value: 'User', label: 'User' },
      { value: 'Relative', label: 'Relative' },
      { value: 'Doctor', label: 'Doctor' },
      { value: 'Assistant', label: 'Assistant' },
      { value: 'Health Center', label: 'Health Center' },
    ];
  }

  setUserType = event => {
    const value = event.target.value;
    this.setState({
      userType: value,
    });
  };

  setPhone = event => {
    const value = event.target.value;
    this.setState({
      phone: value,
    });
  };

  toggleConfirmationModal = () => {
    if(['User','Doctor','Assistant','Health Center'].includes(this.state.userType))
      {
        this.state.isCallModalOpen=true
      }
    this.setState({
      showConfirmationModal: !this.state.showConfirmationModal,
    });
  };

  initiateCall = event => {
    const { phone, userType } = this.state;
    const { requestId } = this.props.offlineConsult;
    this.setState({
      showConfirmationModal: false,
      isCallModalOpen:false,
    });
    if (!phone || !userType) {
      this.setState({
        loading: true,
      });
      return;
    }
    callCustomRequest(requestId, phone, userType,this.state.selectedCallReason)
      .catch(err => {
        console.error({ err }, 'Error while initiating the call');
        const error = 'Couldnt initiate call';
        this.setState({ error });
      })
      .finally(() => {
        this.setState({
          loading: false,
          error: null,
        });
      });
  };

  onClickCall = event => {
    const error = this.validateSubmit();
    if (error) {
      this.setState({ error });
      return;
    }
    this.setState(
      {
        loading: true,
      },
      this.toggleConfirmationModal()
    );
  };

  validateSubmit = () => {
    const { phone, userType } = this.state;
    let error = null;
    if (!phone || phone.length !== 10) {
      error = 'Please enter a valid phone number';
    } else if (!userType) {
      error = 'Please select a usert type';
    }
    if (error) {
      return error;
    }
    return null;
  };

  onOptionSelect = option => {
    console.log({ option });
    this.setState({
      userType: option.value,
    });
    if(['User','Doctor','Assistant','Health Center'].includes(option.value))
    { this.setState({
        isCallModalOpen: true,
      });
    }else{
      this.setState({
        isCallModalOpen: false,
      });
    }
    

  };

  handleChangeForCallReason = (event) => {
    const { value } = event.target;
    this.setState({ selectedCallReason: value, colorGreading: true });
  };

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '0.1rem solid #8852cc',
      // // color: '#8852cc',
    }),
  };

  render() {
    const { error, loading, phone, showConfirmationModal } = this.state;
    if(!showConfirmationModal)
    {
      this.state.selectedCallReason=null; 
    }
    return (
      <SectionBody>
        <DetailRow>
          <DetailName>User Type:</DetailName>
          <SelectElement
            styles={this.customStyles}
            options={this.callStakeholdersOptions}
            theme={theme => ({
              ...theme,
              // borderRadius: '0.25rem',
              colors: {
                ...theme.colors,
                primary50: '#d5d5d5',
                primary25: '#f5f5f5',
                primary: '#8852cc',
              },
            })}
            onChange={option => this.onOptionSelect(option)}
          />
        </DetailRow>
        <DetailRow>
          <DetailName>Phone:</DetailName>
          <input
            type="number"
            style={{ width: '16rem' }}
            className="form-control"
            onChange={this.setPhone}
          />
          <button
            className="btn btn-primary btn-sm"
            style={{ marginLeft: 5 }}
            onClick={this.onClickCall}
          >
            {loading ? <FaSpinner className="icon-spin" /> : <FaPhone />}
          </button>
        </DetailRow>
        <div>
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
        </div>
        <ConfirmationModal
          isOpen={showConfirmationModal}
          confirmationMessage={`Are you sure you want to call this no: ${phone}`}
          callback={this.initiateCall}
          toggle={this.toggleConfirmationModal}
          callReasonOptions = {['Doctor','Assistant','Health Center'].includes(this.state.userType)? this.state.callReasonOptionsForCenter:this.state.callReasonOptions}
          isCallModalOpen = {this.state.isCallModalOpen}
          handleChange={this.handleChangeForCallReason}
          selectedValue = {this.state.selectedCallReason}
        />
      </SectionBody>
    );
  }
}

const mapStateToProps = state => ({
  offlineConsult: state.offlineConsult,
});

export default connect(mapStateToProps)(CustomCallingSection);
