import React, { Component } from 'react';

export default class PendingFollowUpFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    onChange = (event) => {
        const value = event.target.value === "" ? null : event.target.value;
        this.setState({ value });
        this.props.onChangeValue(value);
    }


    render() {
        const { value } = this.state;
        return (
            <select
                onChange={this.onChange}
                style={{
                    width: "100%",
                    height: "40px",
                    fontSize: "12px"
                }}
                value={value}
            >
                <option value="">Choose</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="none">None</option>
            </select>
        )
    }
}
