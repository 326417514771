import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Toast,
  ToastBody,
  ToastHeader,
  ModalHeader,
} from 'reactstrap';
import { shlReimbursementStatus } from '../../services/api/customer-support';
import csvFileValidator from 'csv-file-validator';
import moment from 'moment';
import ValidationModal from '../opd-management/validationModal';

function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes(),
    second = d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-') + ' ' + [hour, minute, second].join(':');
}

const Element = (props) => {
  const [sheetAt, setSheetAt] = useState(() => new Date());

  const [uploading, setUploading] = useState(() => false);
  const [confirmUpload, setConfirmUpload] = useState(() => false);
  const [inValidData, setInValidData] = useState([]);
  const [response, setResponse] = useState(() => {
    return { show: false, color: 'success', message: '' };
  });
  const [showValidationModal, setshowValidationModal] = useState(true);
  const requiredError = (headerName, rowNumber, columnNumber) => {
    return `${headerName} is required in the ${rowNumber} row ${columnNumber} column`;
  };

  const dateError = (DOB) => {
    const dob = moment(DOB, 'YYYY-MM-DD', true).isValid() ? true : false;
    return dob;
  };
  const tpaClaimCheck = (data) => {
    const regex = /r-[0-9]+/;
    const result = regex.test(data);
    return result;
  };

  const config = {
    headers: [
      {
        name: 'Claim ID',
        inputName: 'claimId',
        required: true,
        requiredError,
        validateError: tpaClaimCheck,
      },
      {
        name: 'TPA Claim ID',
        inputName: 'tpaClaimId',
        required: true,
        requiredError,
        validate: tpaClaimCheck,
      },
      {
        name: 'Payment Document No',
        inputName: 'paymentDocumentNo',
        required: true,
        requiredError,
      },
      {
        name: 'Payment Date',
        inputName: 'paymentDate',
        required: true,
        requiredError,
        validate: dateError,
      },
      {
        name: 'Credit Amount',
        inputName: 'creditAmount',
        required: true,
        requiredError,
      },
      {
        name: 'TDS Amount',
        inputName: 'tdsAmount',
        required: true,
        requiredError,
      },
      {
        name: 'Net Claim Paid',
        inputName: 'netClaimPaid',
        required: true,
        requiredError,
      },
      {
        name: 'Authorized Flag',
        inputName: 'authorizedFlag',
        required: true,
        requiredError,
      },
      {
        name: 'Authorized Date',
        inputName: 'authorizedDate',
        required: true,
        requiredError,
        validate: dateError,
      },
      {
        name: 'STATUS',
        inputName: 'status',
        required: true,
        requiredError,
      },
      {
        name: 'UTR Number',
        inputName: 'utrNumber',
        required: true,
        requiredError,
      },
      {
        name: 'Other Remarks',
        inputName: 'otherRemarks',
        required: false,
      },
    ],
  };

  const submitFormData = () => {
    const file = document.querySelector('#shlRbsStatus').files[0];
    const formData = new FormData();
    formData.append('sheetAt', formatDate());
    formData.append('file', file);

    csvFileValidator(file, config)
      .then((csvData) => {
        console.log(csvData, '[csvData]');
        if (csvData.inValidData.length !== 0) {
          setshowValidationModal(true);
          setInValidData(csvData.inValidData);
        } else {
          setUploading(true);
          shlReimbursementStatus(formData)
            .then((res) => {
              if (res.message === 'success') {
                setResponse(() => {
                  return {
                    show: true,
                    message:
                      'SHL Reimbursement Status File Successfully Uploaded',
                    color: 'success',
                  };
                });

                // props.getUpdatedDetails();
              } else {
                setResponse(() => {
                  return {
                    show: true,
                    message:
                      res.errorMessage ||
                      'Failed to upload SHL Reimbursement Status File',
                    color: 'danger',
                  };
                });
              }
            })
            .catch((err) => {
              console.log(err, '');
              setResponse(() => {
                return {
                  show: true,
                  message:
                    err.errorMessage ||
                    'Failed to upload Akzo Reimbursement Status File',
                  color: 'danger',
                };
              });
            })
            .finally(() => {
              setUploading(() => false);
              setConfirmUpload(() => false);
            });
        }
      })
      .catch((err) => {
        console.log(err, '[err]');
      });
  };
  console.log(inValidData, '[setinvalid]');
  return (
    <div>
      <div
        className={'row'}
        style={{ border: '1px solid rgb(230,230,230)', 'border-radius': '4px' }}
      >
        <div className={'col-md-12'}>
          <Toast isOpen={response.show}>
            <ToastHeader icon={response.color}>Response</ToastHeader>
            <ToastBody>{response.message}</ToastBody>
          </Toast>
          <Form>
            <FormGroup inline>
              <Label for="" sm={5}>
                <b>1) Sheet Gmail Thread Timing</b>
              </Label>
              <br />
              <div style={{ 'margin-left': '24px' }}>
                <DateTimePicker
                  format={'yyyy-MM-dd HH:mm:ss'}
                  onChange={setSheetAt}
                  value={sheetAt}
                />{' '}
                &nbsp;&nbsp;(YYYY-MM-DD)(HH:MM:SS) (24 Hr Clock) (Select Sheet
                Timings to Minute Precision)
              </div>
            </FormGroup>

            {/* <br /> */}
            <FormGroup inline>
              <Label for="shlRbsStatus" sm={5}>
                <b>2) SHL Reimbursement Status File (Get Visit) (CSV Format)</b>
              </Label>
              <Col sm={7}>
                <Input
                  type="file"
                  accept="image/*,.pdf,.csv"
                  name="shlRbsStatus"
                  id="shlRbsStatus"
                />
              </Col>
            </FormGroup>
            <Button
              color="info"
              onClick={() => setConfirmUpload(() => true)}
              style={{ marginBottom: '1rem' }}
            >
              Upload
            </Button>
          </Form>
        </div>
      </div>

      {/* <Modal isOpen={showValidationModal}>
        <ModalHeader>
            Validation Error
        </ModalHeader>
        </Modal> */}
      <Modal isOpen={confirmUpload}>
        <ModalBody>Are you sure about uploading this file ?</ModalBody>
        <ModalFooter>
          <Button
            disabled={uploading}
            color="primary"
            onClick={() => submitFormData()}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
          <Button
            color="secondary"
            disabled={uploading}
            onClick={() => {
              setConfirmUpload(() => false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {inValidData.length !== 0 && showValidationModal && (
        <ValidationModal
          closeStatusModal={() => setshowValidationModal(false)}
          errors={inValidData}
        />
      )}
    </div>
  );
};

export default Element;
