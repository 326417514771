import React from 'react';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { sendLogoutRequest } from "../../services/api/logout";
import { unsetUserInfo, unsetAuthToken, toggleSidebar } from "../../actions";

class LogOut extends React.Component {

    logout = () => {
        // clear user data
        this.props.dispatch(unsetUserInfo());
        this.props.dispatch(unsetAuthToken());
        this.props.dispatch(toggleSidebar(false));
        // redirect the user after clearing user info
        this.props.history.push('/');
    };

    handleLogOut = () => {
        sendLogoutRequest()
            .finally(this.logout);
    };

    componentDidMount() {
        this.handleLogOut();
    }
    render() {
        return (<div />);
    }
}

const mapStateToProps = state => ({
    user: state.user
});

export default connect(mapStateToProps)(withRouter(LogOut));