import { toast } from "react-toastify"
import baseRequest from "../../services/api/baseRequest"

export const fetchFileRequests = (params) => {
    const url = '/new-ops/get-execp-reim-file-request'
    const method = 'GET'
    return baseRequest(url, method, params, null)
}

export const fetchCSVFileRequests = () => {
    const url = '/new-ops/get-excep-reim-file-csv';
    const method = 'GET';
    return baseRequest(url, method, null, null);
}

export const getSharableLinks = (body) => {
    const url ='/new-ops/get-excep-reim-file-url'
    const method = 'POST'
    return baseRequest(url, method, null, body)
}

export const getLabOrderItems = (body) => {
    const url = '/new-ops/get-lab-order-items'
    return baseRequest(url, 'POST', null, body)
}

export const mapLabOrderItems = (body) => {
    const url = '/new-ops/map-test-with-reimbursement-price'
    return baseRequest(url, 'POST', null, body)
}

export const sendCashgramLink = (body) => {
    let url = ''
    if (body.orderId[0] === 'l') {
        url = '/new-ops/raise-labs-cashgram-request'
    } else if (body.orderId[0] === 'c') {
        url = '/new-ops/raise-cashgram-request'
    }
    if (!url) return

    const method = 'POST'
    return baseRequest(url, method, null, body)
}

export const resendLink = (params) => {
    const url = '/new-ops/resend-link'
    const method = 'GET'
    return baseRequest(url, method, params, null)
}

export const markInvalid = (body) => {
    const url = '/new-ops/mark-invalid'
    const method = 'POST'
    return baseRequest(url, method, null, body)
}

export const createFileRequest = (body) => {
    
    const { orderType, expReason, paymentReason, orderId } = body

    if (!orderType) return toast.error('Please choose a valid order type')
    if (!orderId) return toast.error('Please choose a valid order id')
    if (!paymentReason) return toast.error('Please choose a valid double payment reason')
    if (!expReason) return toast.error('Please choose a valid execeptional reimbursement reason')

    const url = '/new-ops/create-file-request'
    const fullOrderId = orderType + orderId

    const requestBody = {
        title: `Reimbursement for ${fullOrderId}`,
        description: 'Please upload the prescription and invoice for your appointment below. Our team will review the documents and you will receive a unique link to claim your reimbursement once the documents are verified',
        open: true,
        destination: `/reimbursement/${fullOrderId}`,
        sourceId: fullOrderId,
        exceptionalReimbursementReason: expReason,
        doublePaymentReason: paymentReason
    }

    return baseRequest(url, 'POST', null, requestBody)
}