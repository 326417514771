import React, { Component } from 'react';
import styled from 'styled-components';
import { FaFilePrescription } from 'react-icons/fa';
import { fetchPrescriptionsForRequestId } from '../../services/api/offline-consult';
import LoadingComponent from '../common/LoadingComponent';

class Prescription extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      prescriptions: [],
    };
  }

  componentDidMount = () => {
    this.fetchPrescriptionForConsultation();
  };

  fetchPrescriptionForConsultation = () => {
    this.setState({
      loading: true,
    });
    const requestId = this.props.requestId;
    return fetchPrescriptionsForRequestId(requestId)
      .then((resp) => {
        console.log(resp, '[fetchPrescriptionsForRequestId]');
        const { prescriptions } = resp;
        this.setState({
          prescriptions,
        });
      })
      .catch((err) => {
        console.log(err, '[fetchPrescriptionsForRequestId]');
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { prescriptions } = this.state;
    return (
      <div className="container card">
        <div className="row mt-3">
          <div className="col">
            {this.state.loading ? (
              <LoadingComponent />
            ) : (
              <>
                {prescriptions.length > 0 ? (
                  <div>
                    <div className="row mt-3">
                      <div className="col-3">
                        <label>
                          <b>Prescription</b>
                        </label>
                      </div>
                      <div className="col-3">
                        <label>
                          <b>Status</b>
                        </label>
                      </div>
                      <div className="col-3">
                        <label>
                          <b>Request Date</b>
                        </label>
                      </div>
                      <div className="col-3">
                        <label>
                          <b>Reject Reason</b>
                        </label>
                      </div>
                    </div>

                    {prescriptions.map((p) => (
                      <div className="row mt-3">
                        <div className="col-3">
                          <a href={p.link}>
                            <FaFilePrescription
                              style={{ width: '30px', height: '30px' }}
                            />
                          </a>
                        </div>
                        <div className="col-3">
                          <span>{p.status}</span>
                        </div>
                        <div className="col-3">
                          <span>{p.requestTime}</span>
                        </div>
                        <div className="col-3">
                          <span>
                            {p.rejectReason
                              ? p.rejectReason
                              : 'Reason Not Mentioned'}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <label>
                      <b>No Prescription Available</b>
                    </label>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Prescription;
