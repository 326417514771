import baseRequest, {
  baseRequestWithHeaders,
  jsonPipeRequest,
} from './baseRequest';
export const getOrders = (filters, appliedOrderBy) => {
  if (filters.status && filters.status === 'all') {
    filters.status = null;
  }
  if (filters.receiptUrl && filters.receiptUrl === 'all') {
    filters.receiptUrl = null;
  }
  const urlQuery = new URLSearchParams(filters).toString();

  const url = `/new-ops/labs/orders/all?${urlQuery}`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, { orderBy: appliedOrderBy });
};

export const addLabPartner = (
  name,
  emails,
  type,
  opsName,
  partnerId,
  active,
  collectionCharge,
  clientCode,
  tds,
  credit,
  priority
) => {
  const url = `/new-ops/labs/partners/add`;
  const method = 'POST';
  const data = {
    name,
    emails,
    type,
    opsName,
    partnerId,
    active,
    collectionCharge,
    clientCode,
    tds,
    credit,
    priority
  };
  return baseRequestWithHeaders(url, method, null, data);
};

export const fetchAllPartners = () => {
  const url = `/new-ops/labs/partners/list`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method);
};

export const changePartnerActivity = (partnerId, active) => {
  const url = `/new-ops/labs/partners/${partnerId}/toggle`;
  const method = 'POST';
  const data = {
    partnerId,
    active,
  };
  return baseRequestWithHeaders(url, method, null, data);
};

export const removePartner = (partnerId) => {
  const url = `/new-ops/labs/partners/${partnerId}/delete`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, { partnerId });
};

export const getPartnerDetailsApi = (partnerId) => {
  const url = `/new-ops/labs/partners/fulfilment/${partnerId}`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method);
};

export const updateFulfilmentDetails = (
  body,
  onResponseReceived,
  onResponseComplete,
  onResponseError
) => {
  const url = `/new-ops/labs/partners/fulfilment`;
  const method = 'POST';
  return jsonPipeRequest(
    url,
    method,
    null,
    body,
    null,
    onResponseReceived,
    onResponseComplete,
    onResponseError
  );
};

export const fetchPartnerDetails = (partnerId) => {
  const url = `/new-ops/labs/partners/${partnerId}`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, null, null);
};

export const fetchPartnerTests = (partnerId) => {
  const url = `/new-ops/labs/partners/${partnerId}/tests`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, null, null);
};

export const searchTestsByNotAdded = (partnerId, search) => {
  const url = `/new-ops/labs/partners/${partnerId}/tests/searchByNotAdded`;
  const method = 'GET';
  const params = {
    search,
  };
  return baseRequestWithHeaders(url, method, params, null);
};

export const addLabTestForPartner = (partnerId, test, addToAllCenters) => {
  const url = `/new-ops/labs/partners/${partnerId}/tests/add`;
  const method = 'POST';
  const body = {
    test,
    addToAllCenters,
  };
  return baseRequestWithHeaders(url, method, null, body);
};

export const toggleActiveInactivePartnerTest = (
  partnerId,
  testMapId,
  active
) => {
  const url = `/new-ops/labs/partners/${partnerId}/tests/toggle`;
  const method = 'POST';
  const body = {
    active,
    testMapId,
  };
  return baseRequestWithHeaders(url, method, null, body);
};

export const fetchLabTestByTestMapId = (partnerId, testMapId) => {
  const url = `/new-ops/labs/partners/${partnerId}/tests/${testMapId}`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, null, null);
};

export const fetchPartnerCenters = (partnerId) => {
  const url = `/new-ops/labs/partners/${partnerId}/centers`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, null, null);
};

export const toggleActiveInactivePartnerCenter = (
  partnerId,
  centerId,
  active
) => {
  const url = `/new-ops/labs/partners/${partnerId}/centers/toggle`;
  const method = 'POST';
  const data = {
    active,
    centerId,
  };
  return baseRequestWithHeaders(url, method, null, data);
};

export const fetchLocationByLatLng = (lat, lng) => {
  const url = '/new-ops/labs/reverseGeoCode';
  const method = 'GET';
  const params = {
    lat,
    lng,
  };
  return baseRequestWithHeaders(url, method, params, null);
};

export const submitCenterDetailsForPartner = (partnerId, body) => {
  const url = `/new-ops/labs/partners/${partnerId}/centers/add`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, body);
};

export const fetchCenterDetailsByCenterId = (partnerId, centerId) => {
  const url = `/new-ops/labs/partners/${partnerId}/centers/${centerId}`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, null, null);
};

export const searchLabTestForCenterByNotAdded = (partnerId, centerId, val) => {
  const url = `/new-ops/labs/partners/${partnerId}/centers/${centerId}/tests/searchByNotAdded`;
  const method = 'GET';
  const params = {
    partnerId,
    centerId,
    val,
  };
  return baseRequestWithHeaders(url, method, params, null);
};

export const submitLabTestForTheCenter = (partnerId, centerId, testId) => {
  const url = `/new-ops/labs/partners/${partnerId}/centers/${centerId}/tests/add`;
  const method = 'POST';
  const data = {
    partnerId,
    centerId,
    testId,
  };
  return baseRequestWithHeaders(url, method, null, data);
};

export const fetchTestsForCenter = (partnerId, centerId) => {
  const url = `/new-ops/labs/partners/${partnerId}/centers/${centerId}/tests`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, null, null);
};

export const deleteTestForCenter = (partnerId, centerId, testId) => {
  const url = `/new-ops/labs/partners/${partnerId}/centers/${centerId}/tests/${testId}/delete`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, null);
};

export const addAllActiveTests = (partnerId, centerId) => {
  const url = `/new-ops/labs/partners/${partnerId}/centers/${centerId}/addAllActiveTests`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, null);
};

export const fetchPincodesForPartner = (partnerId) => {
  const url = `/new-ops/labs/partners/${partnerId}/pincodes`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, null, null);
};

export const deletePincode = (partnerId, pincode) => {
  const url = `/new-ops/labs/partners/${partnerId}/pincodes`;
  const method = 'DELETE';
  return baseRequestWithHeaders(url, method, null, { pincode });
};

export const updateCsv = (partnerId, file) => {
  const data = new FormData();
  data.append('file', file);
  console.log('\nupdateCsv: ', data);
  const url = `/new-ops/labs/partners/${partnerId}/pincodes`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, data);
};

export const uploadMultipleTestCsv = (partnerId, file) => {
  const data = new FormData();
  data.append('file', file);
  const url = `/new-ops/labs/partners/${partnerId}/multiple-test`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, data);
};

export const updateLogo = (partnerId, file) => {
  const data = new FormData();
  data.append('file', file);
  const url = `/new-ops/labs/partners/${partnerId}/logo`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, data);
};

export const fetchStates = () => {
  return baseRequest(`/new-ops/states`, 'GET', null, null);
};

export const fetchOverriddenStates = (partnerId, testMapId) => {
  return baseRequest(
    `/new-ops/labs/partners/${partnerId}/overriddenStates/${testMapId}`,
    'GET',
    null,
    null
  );
};

export const deleteOverriddenState = (testMapOverrideId) => {
  return baseRequest(
    `/new-ops/labs/partners/delete-overridden-state`,
    'POST',
    null,
    { testMapOverrideId }
  );
};

export const addOverriddenState = (
  partnerId,
  testMapId,
  stateId,
  mrp,
  cost
) => {
  return baseRequest(
    `/new-ops/labs/partners/add-overridden-state`,
    'POST',
    null,
    { partnerId, testMapId, stateId, mrp, cost }
  );
};

export const updateStatusToActive = (partnerId) => {
  const url = `/new-ops/labs/partners/${partnerId}/tests/updateStatus`;
  const method = 'POST';
  const params = {
    partnerId,
  };
  return baseRequest(url, method, params, null);
};
export const updateStatusToInactive = (partnerId) => {
  const url = `/new-ops/labs/partners/${partnerId}/tests/updateStatusToInactive`;
  const method = 'POST';
  const params = {
    partnerId,
  };
  return baseRequest(url, method, params, null);
};

export const addPincodeForPartner = (partnerId, body) => {
  const url = `/new-ops/labs/partners/${partnerId}/addPinCode`;
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const uploadFile = (file, partnerId) => {
  const url = `/new-ops/labs/partners/fulfilment/upload-logo`;
  const method = 'POST';
  const data = new FormData();
    data.append('file', file);
    data.append('partnerId', partnerId);
    
  return baseRequest(url, method, null, data);
};

export const setLabPriority = (partnerId, priority) => {
  const url = `/new-ops/labs/partners/prioritise`;
  const method = 'POST';
  const data = {
    partnerId,
    priority,
  };
  return baseRequest(url, method, null, data);
};

export const uploadRetailDiscountMargin = (partnerId, retailDiscountMargin) => {
  const url = `/new-ops/labs/partners/${partnerId}/tests/updateRetailDiscountForAll/`;
  const method = 'POST';
  const data = {
    retailDiscountMargin,
  };
  return baseRequest(url, method, null, data);
};

