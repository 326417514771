import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, Modal, ModalHeader, ModalBody, ModalFooter, Popover, PopoverBody} from 'reactstrap';
import {markLabRequestNotServiceable, convertToReimbursement} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';

import { MdInfo } from "react-icons/md";

class LabOrderConvertToReimbursement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            infoPopovers: {
                convertToReimbursement: false,
            },
        };

    }

    toggle = () => {

        this.setState({
            modal: !this.state.modal,
        });
    };

    save = () => {
        convertToReimbursement(this.props.orderId)
            .then((response) => {
                if (response.body.message === 'success') {
                    toast.success('Converted to Reimbursement');
                    this.toggle();
                } else {
                    toast.error(`Converted to Reimbursement`);
                }
            })
            .catch((err) => {
                console.error('Converted to Reimbursement', err);
                toast.error(`Converted to Reimbursement`);
            });
    };

    togglePopover = (popover) => {
        this.setState({
            infoPopovers: popover,
        });
    };


    render() {
        return (
            <div>
                <button className="btn btn-success ml-1" onClick={this.toggle}>Convert to Reimbursement<MdInfo id="convertToReimbursement"/></button>
                <Popover trigger="focus" placement="bottom" isOpen={this.state.infoPopovers.convertToReimbursement} target="convertToReimbursement" toggle={() => this.togglePopover({convertToReimbursement: !this.state.infoPopovers.convertToReimbursement})}>
                    <PopoverBody>This order will be cancelled and reimbursement request will be placed</PopoverBody>
                </Popover>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <h4 className="mt-1 mb-0 ml-2">Confirmation</h4>
                    <ModalBody>
                        <p>Are you sure you want to convert this reimbursement request?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>Save</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(LabOrderConvertToReimbursement);





