import baseRequest, { baseRequestWithHeaders } from './baseRequest';

export const getProducts = (params) => {
  const url = '/new-ops/redemptions/get-products';
  const method = 'get';
  // // console.log({ params }, 'getProducts');
  return baseRequest(url, method, params, null);
};

export const getLocations = () => {
  const url = '/new-ops/redemptions/get-locations';
  const method = 'get';
  return baseRequest(url, method, null, null);
};

export const addProduct = (data) => {
  const url = '/new-ops/redemptions/add-product';
  const method = 'POST';
  return baseRequest(url, method, null, data);
};

export const editProduct = (data) => {
  const url = '/new-ops/redemptions/edit-product';
  const method = 'POST';
  // console.log({ data }, 'editProduct');
  return baseRequest(url, method, null, data);
};
export const uploadProductImage = (data) => {
  const url = '/new-ops/redemptions/product-image';
  const method = 'POST';
  // console.log({ data }, 'uploadProductImage');
  return baseRequest(url, method, null, data);
};
export const deleteProductImage = (params) => {
  const url = '/new-ops/redemptions/product-image/delete';
  const method = 'POST';
  // console.log({ data }, 'uploadProductImage');
  return baseRequest(url, method, params, null);
};

export const deleteProduct = (params) => {
  const url = '/new-ops/redemptions/product/delete';
  const method = 'POST';
  // console.log({ data }, 'uploadProductImage');
  return baseRequest(url, method, params, null);
};

export const activeProduct = (body) => {
  const url = '/new-ops/redemptions/product/active';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const deletePool = (params) => {
  const url = '/new-ops/redemptions/pool/delete';
  const method = 'POST';
  // console.log({ data }, 'uploadProductImage');
  return baseRequest(url, method, params, null);
};
// Pools
export const getPools = (filters) => {
  const url = '/new-ops/redemptions/get-pools';
  const method = 'get';
  return baseRequest(url, method, filters, null);
};

export const replicatePool = (data) => {
  const url = '/new-ops/redemptions/replicate-pool';
  const method = 'POST';
  return baseRequest(url, method, null, data);
};

export const addPool = (data) => {
  const url = '/new-ops/redemptions/add-pool';
  const method = 'POST';
  // console.log({ data }, 'addPool');
  return baseRequest(url, method, null, data);
};

export const editPool = (data) => {
  const url = '/new-ops/redemptions/edit-pool';
  const method = 'POST';
  // console.log({ data }, 'editPool');
  return baseRequest(url, method, null, data);
};

export const getAllProducts = (params) => {
  const url = '/new-ops/redemptions/get-all-products';
  const method = 'get';
  return baseRequest(url, method, params, null);
};
export const getProductsPool = (params) => {
  const url = '/new-ops/redemptions/get-products-pool';
  const method = 'get';
  return baseRequest(url, method, params, null);
};
export const deleteProductFromPool = (productId,poolId) => {
  const url = '/new-ops/redemptions/delete-product-pool';
  const method = 'POST';
  const data = {
    productId,poolId
  }
  return baseRequest(url, method, null, data);
}
export const addProductPool = (data) => {
  const url = '/new-ops/redemptions/add-product-pool';
  const method = 'POST';
  // // console.log({ data }, 'addProductPool');
  return baseRequest(url, method, null, data);
};

export const editProductPool = (data) => {
  const url = '/new-ops/redemptions/edit-product-pool';
  const method = 'POST';
  // // console.log({ data }, 'addProductPool');
  return baseRequest(url, method, null, data);
};

export const getSponsors = () => {
  const url = '/new-ops/redemptions/get-sponsors';
  const method = 'get';
  return baseRequest(url, method, null, null);
};

export const getPoolSponsors = (poolId) => {
  const url = '/new-ops/redemptions/get-pool-sponsors';
  const method = 'get';
  const params = {
    poolId,
  };
  return baseRequest(url, method, params, null);
};

export const getProductsPoolSponsor = (params) => {
  const url = '/new-ops/redemptions/get-product-pool-sponsors';
  const method = 'get';
  return baseRequest(url, method, params, null);
};

export const addSponsorPool = (data) => {
  const url = '/new-ops/redemptions/add-sponsor-pool';
  const method = 'POST';
  // // console.log({ data }, 'addSponsorPool');
  return baseRequest(url, method, null, data);
};

export const fetchPoolCategories = (poolId) => {
  const url = '/new-ops/redemptions/pools/categories';
  const method = 'GET';
  return baseRequest(url, method, { poolId }, null);
};

export const addCategoryToPool = (details) => {
  const url = '/new-ops/redemptions/pools/categories/add';
  const method = 'POST';
  return baseRequest(url, method, null, details);
};

export const deletePoolCategory = (categoryId) => {
  const url = `/new-ops/redemptions/pools/categories/delete`
  const method = 'POST';
  return baseRequest(url, method, null, {categoryId})
}

export const fetchAllSponsor = () => {
  const url = '/new-ops/redemptions/get-all-sponsors';
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const fetchAllProducts = () => {
  const url = '/new-ops/redemptions/products';
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const submitChangeMySponsor = (sponsorId) => {
  const url = '/new-ops/redemptions/change-my-sponsor';
  const method = 'POST';
  return baseRequest(url, method, null, { sponsorId });
};

export const changeProductPoolActivity = (poolId, productId, isActive) => {
  const url = '/new-ops/redemptions/product-pool/active';
  const method = 'POST';
  const body = {
    poolId,
    productId,
    isActive,
  };
  return baseRequest(url, method, null, body);
};

export const uploadVoucherForProduct = (
  file,
  productId,
  couponType,
  expiryDate
) => {
  const data = new FormData();
  data.append('file', file);
  data.append('productId', productId);
  data.append('couponType', couponType);
  data.append('expiryDate', expiryDate);

  const url = `/new-ops/redemptions/products/upload-voucher`;
  const method = 'POST';
  return baseRequest(url, method, null, data);
};

export const fetchProductVoucherDetails = (productId) => {
  const url = `/new-ops/redemptions/products/voucher-details/${productId}`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const postInactiveProduct = (productId) => {
  const url = `/new-ops/redemptions/inactive-product`;
  const method = `POST`;
  const body = {
    productId,
  };
  return baseRequest(url, method, null, body);
};

export const deleteDupesProductCoupons = (randomString,productId) => {
  const url = `/new-ops/redemptions/products/delete-dups-vouchers`;
  const method = `POST`;
  const body = {
    randomString,
    productId
  }
  return baseRequest(url, method, null, body);
}