import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavBar from "../layout/NavBar";
import LabReimbursementCartsTable from './LabReimbursementCartsTable';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

class LabReimbursementCarts extends Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

 

  
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <NavBar />
                </div>
                <div className="row mt-5 pt-1">
                    <div className="col-4">
                        
                    </div>
                </div>
                <LabReimbursementCartsTable/>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withRouter(connect(
    mapStateToProps,
)(LabReimbursementCarts));