import { debounce } from '../../utils/debounce';
import React, { useMemo } from "react"
import { Button, Tooltip, UncontrolledTooltip } from "reactstrap"
import StatusFilter from './Filters/StatusFilter';
import { StatusCompleteContainer, StatusDangerContainer, StatusPendingContainer } from '../common/StatusContainer';
import { IoIosCheckmark, IoMdChatboxes, IoMdClose, IoMdCreate, IoMdPerson } from 'react-icons/io';
import moment from 'moment';
import DateFilter from '../cashless-vaccination/headerFilters/DateFilter';
import AppointmentTimeFilter from '../cashless-vaccination/headerFilters/AppointmentTimeFilter';
// import CallingButton from '../../offline-consultations/CallingButton';
// import AckCallFilter from '../headerFilters/AckCallFilter';
import ClaimedByFilter from '../offline-consultations/headerFilter/ClaimedByFilter'
import { FaArrowCircleUp } from 'react-icons/fa';
// import { showDentalRequestModal } from '../../actions';

const commonFields = (accessor, header, numeric = false) => ({
    Header: header,
    accessor: accessor,
    disablePadding: true,
    style: { whiteSpace: 'unset' },
    numeric: numeric
})


const useTableColumns = (functions, values, deps) => {
    const { 
        dispatch, showDentalRequestModal, setRequestIdFilter, setStatusFilter,
        confirmOpenModal, cancelOpenModal, setRequestDateFilter, setAppTimeFilter,
        setAppointmentDateFilter, setAckButtonConfirmModal, setAckFilter,
        openClaimRequestButton, openEditPaymentButton, openRequestCommentModal,
        openShiftRequestButton,setRequestCenterFilter,setRequestMdpAppointmentFilter,setRequestSponsorFilter
    } = functions
    const {
        statusFilter
    } = values
    const tableColumns = useMemo(() => [
        {
            Header: 'Cashless Dental Request Details',
            foldable: true,
            columns: [
                {
                    ...commonFields('requestId', 'RID', true),
                    Cell: (row) => {
                        const { requestId } = row.original
                        return <div>
                            <Button
                                type='submit'
                                size='sm'
                                color='primary'
                                onClick={() => dispatch(showDentalRequestModal(requestId))}
                            >
                                {requestId}
                            </Button>
                        </div>
                    },
                    Filter: () => (
                        <input 
                            onChange={(event) => {
                                debounce(setRequestIdFilter(event.target.value), 300)
                            }}
                        />
                    )
                },
                {
                    ...commonFields('mdpAppointmentId', 'MDP-AppointmentId'),
                    Cell: (row) => {
                        const { mdpAppointmentId } = row.original;
                        return <div>
                            {mdpAppointmentId}
                        </div>
                    },
                    Filter: () => (
                        <input 
                            onChange={(event) => {
                                debounce(setRequestMdpAppointmentFilter(event.target.value), 300)
                            }}
                        />
                    )
                },
                // {
                //     ...commonFields('opsUserName', 'Claimed By'),
                //     Filter: ({ filter, onChange }) => (
                //         <ClaimedByFilter 
                //             value={filter ? filter.value : ''}
                //             onChange={onChange}
                //         />
                //     )
                // },
                {
                    ...commonFields('requestStatus', 'Status'),
                    Filter: () => (
                        <StatusFilter 
                            onChange={setStatusFilter}
                            value={statusFilter}
                        />
                    ),
                    Cell: (row) => {
                        const { requestStatus, cancelledReason, prescriptionStatus, appointmentDate, requestId, appointmentConfirmedTime, procedureDetails, drPrescriptionStatus, prescriptionInvalid } = row.original
                        const todayDate = moment().format('YYYY-MM-DD');
                        const currentTime = moment().format("hh:mm:ss A");
                        const appointmentTime = moment(appointmentConfirmedTime, "hh:mm:ss A").add(60, 'minutes');
                        const addedTime = moment(appointmentTime).format("hh:mm:ss A");
                        // const isTimePassed = moment(currentTime, "hh:mm:ss A").isAfter(moment(addedTime, "hh:mm:ss A"));
                        const isTimePassed = moment().isAfter(moment(appointmentDate + ' ' + addedTime, 'YYYY-MM-DD hh:mm:ss A'));
                        const hasProcedureDetails = procedureDetails && procedureDetails.length > 0;
                        const anyProcedureSuccessNotNull = procedureDetails && procedureDetails.some(detail => detail.success !== null);
                        console.log(hasProcedureDetails, anyProcedureSuccessNotNull, requestId, '[check]')
                        
                        if ('pending' === (requestStatus)) {
                            return <StatusPendingContainer>{requestStatus}</StatusPendingContainer>
                        } else if ('cancelled' === requestStatus) {
                            return <><StatusDangerContainer>{requestStatus}</StatusDangerContainer><span>{`(${cancelledReason})`}</span></>
                        } else if (requestStatus === 'confirmed' && (moment(todayDate).isSameOrAfter(appointmentDate) && isTimePassed) && prescriptionStatus === 'upload-pending' ) {
                            return <StatusPendingContainer>Rx pending</StatusPendingContainer>
                        } else if (requestStatus === 'confirmed' && drPrescriptionStatus === 'pending' && prescriptionInvalid === 0 && prescriptionStatus === 'uploaded') {
                            return <StatusPendingContainer>Rx uploaded</StatusPendingContainer>
                        } else if (requestStatus === 'confirmed' && prescriptionInvalid === 0 && drPrescriptionStatus === 'complete' && prescriptionStatus === 'approved') {
                            return <StatusPendingContainer>Rx approved</StatusPendingContainer>
                        } else if (requestStatus === 'confirmed' && prescriptionInvalid === 1 && drPrescriptionStatus === 'complete' ) {
                            return <StatusPendingContainer>Rx rejected</StatusPendingContainer>
                        } else if (requestStatus === 'pre-auth-requested' && hasProcedureDetails && anyProcedureSuccessNotNull) {
                            return <StatusPendingContainer>Pre Auth Approved</StatusPendingContainer>
                        } 
                        else {
                            return <StatusCompleteContainer>{requestStatus}</StatusCompleteContainer>
                        }
                    }
                },
                {
                    ...commonFields('sponsorName', 'Sponsor'),
                    filterable:true,
                    Filter: () => (
                        <input 
                            onChange={(event) => {
                                debounce(setRequestSponsorFilter(event.target.value), 300)
                            }}
                        />
                    )
                },
                {
                    ...commonFields('requestDate', 'Req. Date'),
                    headerStyle: { overflow: 'visible' },
                    Cell: (row) => {
                        const { requestDate } = row.original
                        return moment(requestDate).format('DD MMM hh:mm A')
                    },
                    Filter: () => (
                        <DateFilter 
                            label='Request'
                            uniqueId='fsfdf343s'
                            onChooseDate={setRequestDateFilter}
                        />
                    )
                },
                {
                    ...commonFields('appointmentDate', 'Appt. Date'),
                    headerStyle: { overflow: 'visible' },
                    Filter: () => (
                        <DateFilter 
                            label='Appointment'
                            uniqueId='rew543fsd'
                            onChooseDate={setAppointmentDateFilter}
                        />
                    ),
                    Cell: (row) => {
                        const { appointmentDate } = row.original
                        const istOffsetMinutes = 330; // this is for indian timezone as moment .tz does not support timezone
                        return appointmentDate ? 
                        moment(appointmentDate).utcOffset(istOffsetMinutes).format('DD MMM YYYY') :
                        ''
                    }
                },
                {
                    ...commonFields('appointmentTime', 'Appt. Time'),
                    headerStyle: { overflow: 'visible' },
                    Cell: (row) => {
                        const { appointmentTime, appointmentConfirmedTime } = row.original
                        try {
                            if (appointmentConfirmedTime) {
                                return moment(appointmentConfirmedTime, "hh:mm:ss A").format("hh:mm A");
                            } else if (appointmentTime) {
                                return appointmentTime
                                    .split(' - ')
                                    .map(t => moment(t, 'hh:mm:ss A').format('hh:mm A'))
                                    .join(' - ');
                            } else {
                                return '';
                            }
                        } catch (err) {
                            return appointmentTime || ''
                        }
                    },
                    Filter: () => (
                        <AppointmentTimeFilter
                            handleChange={(timingId, slotId) => {
                                setAppTimeFilter([timingId, slotId])
                            }}
                        />
                    )
                },
                {
                    ...commonFields('cancelledAt', 'Cancelled At'),
                    Cell: (row) => {
                        const { cancelledAt } = row.original
                        return cancelledAt ? moment(cancelledAt).format('DD MMM hh:mm A') : ''
                    },
                    filterable: false,
                },
                {
                    ...commonFields('confirmedAt', 'Confirmed At'),
                    Cell: (row) => {
                        const { confirmedAt } = row.original
                        return confirmedAt ? moment(confirmedAt).format('DD MMM hh:mm A') : ''
                    },
                    filterable: false,
                },
                {
                    ...commonFields('patientName', 'Patient'),
                    filterable: false
                },
                {
                    ...commonFields('doctorName', 'Doctor'),
                    filterable: false
                },
                {
                    ...commonFields('centerLocation', 'Center'),
                    filterable: false
                },
                {
                    ... ('commentsCount', 'Comments'),
                    filterable: false,
                    sortable: false,
                    Cell: (row) => {
                        const { requestId, commentsCount } = row.original
                        return (
                            <React.Fragment>
                                <div>
                                <div onClick={() => openRequestCommentModal(requestId)}>
                                    <p>
                                        <span id={`commentTooltip-a-${requestId}`}>
                                        {commentsCount}
                                            <IoMdChatboxes
                                                className="icon-chat"
                                                style={{ cursor: 'pointer', width: '30px', height: '30px' }}
                                            />
                                        </span>
                                    </p>
                                    <UncontrolledTooltip target={`commentTooltip-a-${requestId}`}>
                                        View Comments
                                    </UncontrolledTooltip>
                                </div>
                                { row.original.requestStatus !== 'cancelled' ? 
                                <div onClick={() => cancelOpenModal(row.original.requestId, row.original.mdpAppointmentId)}>
                                        <IoMdClose
                                            className='icon-style'
                                            id={`cancelButton-${row.original.requestId}`}
                                        />
                                    <UncontrolledTooltip target={`cancelButton-${row.original.requestId}`}>
                                        Cancel Appointment
                                    </UncontrolledTooltip>
                                    </div>: ''}
                                    {row.original.requestStatus !== 'cancelled' && row.original.requestStatus !== 'pending' ? <div>
                                        <IoIosCheckmark
                                            className='icon-style'
                                            style={{ fontSize: '25px' }}
                                            id={`confirmButton-${row.original.requestId}`}
                                            onClick={() => confirmOpenModal(row.original.requestId)}
                                        />
                                        <UncontrolledTooltip target={`confirmButton-${row.original.requestId}`}>
                                            Mark Appointment Complete
                                        </UncontrolledTooltip>
                                    </div>: ''}
                                </div>
                            </React.Fragment>
                        )
                    }
                }
            ]
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ], deps);
    
    return tableColumns
}

export default useTableColumns