import React from 'react';

import { fetchPreviousPayments } from '../../../services/api/online-payment';

export default class PreviousPayments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalPaid: 0,
            fiscalYear: '',
            tdsDeducted: 0,
            payments: [],
        };
    }

    componentDidMount() {
        const { doctorId } = this.props;
        fetchPreviousPayments(doctorId)
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        totalPaid: response.data.totalPaid,
                        fiscalYear: response.data.fiscalYear,
                        tdsDeducted: response.data.tdsDeducted,
                        payments: response.data.payments,
                    });
                    return;
                }
                throw new Error('Error in endpoint');
            })
            .catch((err) => {
                console.error({ err, doctorId },
                    'Error while fetching previous payments');
                throw err
            });
    }

    render() {
        const { totalPaid, fiscalYear, tdsDeducted, payments } = this.state;
        return (
            <div>
                <div className="container-fluid">
                    <div className="row h4">
                        <div className="col">
                            <div className="row">
                                Total Paid
                            </div>
                            <div className="row">
                                <strong>{totalPaid}</strong>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                TDS
                            </div>
                            <div className="row">
                                <strong>{tdsDeducted}</strong>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{maxHeight: '400px', overflow: 'auto',}}>
                        <table className="table">
                            <thead>
                            <th>Amount</th>
                            <th>TDS</th>
                            <th>Month</th>
                            </thead>
                            <tbody>
                            {payments && payments.map((p) => {
                                return (
                                    <tr>
                                        <td>Rs. {p.amount}</td>
                                        <td>Rs. {p.tds}</td>
                                        <td>{p.monthYear}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}
