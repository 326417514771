import React, { Component } from "react";

class LegendContent extends Component {

    render() {
        return (
            <div>
                <p>It all starts with a consultation request from a user.</p>
                <p>An entry is created in this listing for every request</p>
                <p>The color coding is standard: </p>
                <ul>
                    <li><b>Red:</b> High Priority</li>
                    <li><b>Yellow:</b> Medium Priority</li>
                    <li><b>Green:</b> Low Priority</li>
                </ul>
                <p>The columns have the following meanings: </p>
                <ul>
                    <li>
                        <b>RID:</b> Request ID - It can be used to communicate with the technical team in case of any escalations. Upon clicking, detailed information about the consultation request is shown.
                    </li>
                    <br/>
                    <li>
                        <b>Sponsor:</b> Every user belongs to a certain organization specified by this column. The column is accompanied with a filter to segregate requests from various sponsors.
                    </li>
                    <br/>
                    <li>
                        <b>Appt. Date:</b> This column will show the tentative appointment date and time. For a pending appointment this will show the date time selected by the user. For a confirmed appointment, this will show the confirmed consultation date and time.
                    </li>
                    <br/>
                    <li>
                        <b>Req. Date:</b> The date and time at which the request was placed by the user.
                    </li>
                    <br/>
                    <li>
                        <b>Patient:</b> The person whom the consultation has been booked for. If a relation is specified next to the name of the patient, that means the request was placed by a user for his/her relative.
                    </li>
                    <br/>
                    <li>
                        <b>Ack. Call:</b> Shows if any acknowledgement call has been made to the user. There are three possible status:
                        <ol>
                            <li>Pending: No Call Made to the user yet</li>
                            <li>No Answer: An attempt was made but for some reason(ex. user did not pick the call) the call could not connect</li>
                            <li>Yes: Call was successfully made</li>
                        </ol>
                        When a successful call is made, the duration and the attempts are shown in this format - (2m, 4), where 2m says the total call duration has been 2 minutes in 4 attempts.
                    </li>
                    <br/>
                    <li>
                        <b>In House::</b> Every offline consultation has an in-house doctor associated with it. Doctors are supposed to resolve users' health concerns over call and give a resolution to the consultation. The column shows the initials of the in-house doctors. For eg. R.G stands for Renu Gupta. The Possible Statuses are:
                        <ol>
                            <li>
                                <b>Pending:</b> No call has been attempted by the doctor
                            </li>
                            <li>
                                <b>No Answer:</b> An attempt was made but for some reason(ex. user did not pick the call) the call could not connect. The number of attempts made are shown in the column.
                            </li>
                            <li>
                                <b>Completed: </b> The doctor did talk to the user but has not marked the consultation status as resolved.
                            </li>
                            <li>
                                <b>Offline Required, Not Required, Not Sure: </b> These are the possible resolution status provided by the in-house doctor.
                            </li>
                        </ol>
                    </li>
                    <br/>
                    <li>
                        <b>TAT(I.H):</b> Turn Around time of the In-House Doctors. This shows the time taken by the doctor to make the first calling attempt to the user from when the request was placed.
                    </li>
                    <br/>
                    <li>
                        <b>I.H Dur.</b> The duration of the total calls made to the user by the in-house doctor.
                    </li>
                    <br/>
                    <li>
                        <b>Status:</b> This tells the current status of the offline request. The possible statuses are:
                        <ol>
                            <li>
                                <b>Apt. Pending: </b> The appointment is being processed by all the parties involved.
                            </li>
                            <li>
                                <b>Payment Pending: </b> The appointment timings have been confirmed by operations' team and now the user needs to pay the amount required to mark it as a confirmed appointment.
                                Ex. B2C Users need to pay for every appointment. OPD Users need to pay if the charges of the doctors are more than 1000(covered amount).
                            </li>
                            <li>
                                <b>Confirmed: </b> The appointment has been confirmed by the operations' team and now the user is cleared to go to the clinic/hospital at the scheduled time.
                            </li>
                            <li>
                                <b>OnGoing: </b> An appointment is marked as ongoing within 3 hours of the scheduled time. For eg. if an appointment was scheduled for 2PM today, during 2-5PM the consultation will be shown as Ongoing.
                            </li>
                            <li>
                                <b>Rx. Pending: </b> The user has completed the appointment and is now required to upload the prescription provided by the offline doctor in the application which will be verified by the transcription team.
                            </li>
                            <li>
                                <b>Rx. Uploaded: </b> The user has uploaded the offline prescription and is cleared to book another consultation.
                            </li>
                            <li>
                                <b>Cancelled: </b> There are various cancelled statuses available. These are the requests which had been cancelled for the specied reasons.
                            </li>
                        </ol>
                    </li>
                    <br/>
                    <li>
                        <b>Actions: </b> There are 3 actions which can be taken against a consultation request:
                        <ol>
                            <li>
                                <b>Confirm Appointment:</b> This is to confirm the appointment on the timings requested by the user.
                            </li>
                            <li>
                                <b>Reschedule Appointment:</b> This is to confirm the appointment on the timings different from the ones requested by the user.
                            </li>
                            <li>
                                <b>Cancel Appointment:</b> This is to cancel the current request of the user. Upon clicking , the cancellation reason is to be specified.
                            </li>
                        </ol>
                    </li>
                    Reminder calls are to be made to the doctor and the user 2 hours prior to the scheduled appointment time.
                    <br/>
                    <li>
                        <b>Doc. Reminded:</b> This shows if a reminder call was made to the doctor. The calling button will place a call to the SPOC/doctor.
                    </li>
                    <br/>
                    <li>
                        <b>User Reminded:</b> This shows if a reminder call was made to the user. The calling button will place a call to the user.
                    </li>
                    <br/>
                    <li>
                        <b>Doc. Payment</b> This helps to raise a payment request to the finance department for the given consultation. Only after an approval the consultation is to be confirmed. The payment details from the on-boarding team are shown before sending the payment request.
                    </li>
                    <br/>
                    <li>
                        <b>Comments: </b> This section is to be used by the operations' team for internal commenting system. Operations' Agents can post important updates and keep others informed of the current status of the consultation. The number of comments are shown next to the icon and upon hovering it shows the last comment made for this request. Upon clicking the chat button, the comment history section is shown where the agent can post an update too.
                    </li>
                </ul>
            </div>
        );
    }
}

export default LegendContent;
