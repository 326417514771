import React from 'react';

import { fetchPoolCategories } from '../../services/api/redemptions';

import { toast } from 'react-toastify';

import AddPoolCategoryCard from "./AddPoolCategoryCard";


class AddPoolCategory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            categories: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const { poolId } = this.props;
        return fetchPoolCategories(poolId)
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        categories: response.data,
                    });
                    return;
                }
                throw new Error(response.errorMessage);
            })
            .catch((err) => {
                console.error(err, poolId);
                toast.error(err.message);
            });
    };

    render() {
        const { categories } = this.state;
        return (
            <div className="container">
                <div className="row">
                    {(categories) && categories.map((c) => (
                        <AddPoolCategoryCard onComplete={this.getData} details={c} poolId={this.props.poolId} />
                    ))}
                    <AddPoolCategoryCard onComplete={this.getData} poolId={this.props.poolId} />
                </div>
            </div>
        );
    }
}

export default AddPoolCategory;
