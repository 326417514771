import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Badge, Button, ButtonGroup, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { getVaccinationRequest, getVaccinationTransactionHistory, requestVaccinationPayment, resendCashlessLetter } from '../../../services/api/cashless-vaccination';
import ConfirmationModal from '../../common/ConfirmationModal';
import LoadingComponent from '../../common/LoadingComponent';
import StyledTappable from '../../common/StyledTappable';
import { BoldText, RegularText, Text } from '../../common/Text';
import PaymentButton from '../../offline-consultations/PaymentButton';
import OrderStatus from '../components/OrderStatus';
import RequestRow from './RequestRow';

const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;
`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;

const SpocDetailContainer = styled(SectionContainer)`
  background-color: #f8f9fa;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  padding: 0.625rem;
`;

const VaccinationRequestDetails = ({
    details,
    onClose,
    isOpen
}) => {

    const [vaccineInfo, setVaccineInfo] = useState({})
    const [activeInfo, setActiveInfo] = useState('basic')
    const [basicInfo, setBasicInfo] = useState({})
    const [payInfo, setPayInfo] = useState({})
    const [spocInfo, setSpocInfo] = useState({})
    const [reqPaymentLoading, setReqPaymentLoading] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [confirmMessage, setConfirmMessage] = useState('')
    const [transactions, setTransactions] = useState([])

    useEffect(() => {
        if (details.requestId) {
            findRequestInformation(details.requestId)
            findTransactionHistory(details.requestId)
        }
    }, [details.requestId])

    const findRequestInformation = (requestId) => {
        getVaccinationRequest(requestId)
        .then((response) => {

            setBasicInfo(response.basicInfo)
            setPayInfo(response.paymentInfo)
            setSpocInfo(response.centerSpoc)
            setVaccineInfo(response.vaccinationDetails)
        })
    }

    const findTransactionHistory = (requestId) => {
        getVaccinationTransactionHistory(requestId)
        .then((response) => {
            if (response && response.message === 'success') {
                setTransactions(response.transactions)
            }
        })
    }

    const resendVaccinationLetter = () => {
        const { requestId } = details
        return resendCashlessLetter(requestId)
        .then((response) => {
            if (response.message === 'success') {
                return toast.success('Cashless letter resent successully')
            }
            toast.error('Error while sending cashless letter')
        })
        .catch((err) => {
            console.log({err})
            toast.error('Error while sending cashless letter')
        })
    }

    const renderTimeline = () => (
        <DetailsContainer>
            <SectionContainer>
                <SectionHeader>Vaccination Booking Timeline</SectionHeader>
                <SectionBody>
                    <OrderStatus
                        requestId={details.requestId}
                    />
                </SectionBody>
            </SectionContainer>
        </DetailsContainer>
    )

    const renderBasicInfo = () => (
        <DetailsContainer>
            <SectionContainer>
                <SectionInnerContainer>
                    <SectionHeader>User Booking Details</SectionHeader>
                    <SectionBody>
                        <RequestRow name='User Id:' value={basicInfo.userId} />
                        <RequestRow name='Parent Order Id:' value={basicInfo.parentBookingId} />
                        <RequestRow name='Appoint. Date:' value={
                            basicInfo.appointmentDate ? moment(basicInfo.appointmentDate).format('DD MMM, YYYY') : ''
                        } />
                        <RequestRow name='Request Date' value={
                            basicInfo.requestDate ? moment(basicInfo.requestDate).format('DD MMM, YYYY hh:mm A') : ''
                        } />
                        <RequestRow name='Appoint. Slot:' value={basicInfo.appointmentTime} />
                        <RequestRow name='Patient Name:' value={basicInfo.patientName} />
                        <RequestRow name='Patient Relation:' value={basicInfo.patientRelation} />
                        <RequestRow name='Patient DOB:' value={basicInfo.patientDob} />
                        <RequestRow name='Patient Phone:' value={basicInfo.patientPhone} />
                        <RequestRow name='User Email:' value={basicInfo.userEmail} />
                        <RequestRow name='User Phone:' value={basicInfo.userPhone} />
                        <RequestRow name='Patient Age:' value={basicInfo.age} />
                    </SectionBody>
                </SectionInnerContainer>
            </SectionContainer>
            <SectionContainer>
                <SectionHeader>Vaccination Booking Details</SectionHeader>
                <SectionBody>
                    <RequestRow name='Center Name:' value={basicInfo.networkCenterName} />
                    <RequestRow name='Center Locality:' value={basicInfo.networkCenterLocality} />
                    <RequestRow name='Amount:' green value={vaccineInfo ? vaccineInfo.vaccinationCost : ''} />
                    <RequestRow name='Center Address:' value={basicInfo.networkCenterAddress} />
                    <RequestRow name='Sub Category Name:' value={basicInfo.subCategoryName} />
                    <RequestRow name='Center Id' value={basicInfo.centerId} />
                </SectionBody>
            </SectionContainer>
        </DetailsContainer>
    )

    const yesNo = value => value ? 'Yes' : 'No'

    const renderCenterInfo = () => (
        <DetailsContainer>
            <SectionContainer>
                <SectionInnerContainer>
                    <SectionHeader>Center Details:</SectionHeader>
                    <SectionBody>
                        <RequestRow name='Practice Center:' value={basicInfo.networkCenterName} />
                        <RequestRow name='Location:' value={basicInfo.networkCenterAddress} />
                        <RequestRow name='City:' value={basicInfo.networkCenterCity} />
                        <RequestRow name='Locality:' value={basicInfo.networkCenterLocality} />
                        <RequestRow name='Visit SPOC:' value={basicInfo.visitSpoc} />
                    </SectionBody>
                </SectionInnerContainer>
            </SectionContainer>

            <SectionContainer>
                <SectionInnerContainer>
                    <SectionHeader>SPOC Details</SectionHeader>
                    <SectionBody>
                        {Array.isArray(spocInfo) ? 
                            spocInfo.map(item => {
                                return <SpocDetailContainer>
                                    <RequestRow name='Name:' value={`${item.name} (${item.type})`} />
                                    <RequestRow name='Email:' value={item.email} />
                                    <RequestRow name='Phone:' value={item.phone} />
                                    <RequestRow name='Comm. Type:' value={item.commType} />
                                    <RequestRow name='Send WhatsApp:' value={yesNo(item.sendWhatsApp)} />
                                    <RequestRow name='Send Email:' value={yesNo(item.sendEmail)} />
                                    <RequestRow name='Send CC:' value={yesNo(item.sendCC)} />
                                    <RequestRow name='Send SMS:' value={yesNo(item.sendSMS)} />
                                    <RequestRow name='Send Call:' value={yesNo(item.sendCall)} />
                                </SpocDetailContainer>
                            })
                        : <></>}
                    </SectionBody>
                </SectionInnerContainer>
            </SectionContainer>
        </DetailsContainer>
    )

    const renderPaymentInfo = () => (
        <DetailsContainer>
            <SectionContainer>
                <SectionInnerContainer>
                    <SectionHeader>Charges</SectionHeader>
                    <SectionBody>
                        <RequestRow name='Amount:' value={vaccineInfo ? vaccineInfo.vaccinationCost : ''} />
                        <RequestRow name='Approved Amt.:' value={payInfo.approvedAmount} />
                        <RequestRow name='Cashless Letter' value={
                            basicInfo.cashlessLetterLink ? <>
                                <a href={basicInfo.cashlessLetterLink} target="_blank" rel="noopener noreferrer">Link</a>
                                <Button className='ml-4' color='primary' size='sm' onClick={resendVaccinationLetter}>Resend</Button>
                            </> : ''
                        } />
                        <RequestRow name='Payout Status:' value={payInfo.payoutStatus} jsx={
                            <Badge color='info' pill>{payInfo.payoutStatus}</Badge>
                        } />
                        {payInfo.showPayoutRequest ? <RequestRow 
                            name='Payment Req.:'
                            value={true}
                            jsx={<PaymentButton 
                                onSubmit={() => {
                                    setReqPaymentLoading(true)
                                    setShowConfirmModal(true)
                                    setConfirmMessage('Request Payout? Are you sure?')
                                }}
                            />}
                        /> : <></>}
                        <RequestRow name='Payout Receipt:' altText='no receipts uploaded' value={payInfo.payoutReceipt} jsx={
                            payInfo.payoutReceipt ? payInfo.payoutReceipt.split(',').map((ele, idx) => {
                                return ele ? (
                                  <a
                                    key={idx}
                                    href={ele}
                                    target="_blank"
                                    type="button"
                                    className="btn btn-link"
                                    role="button"
                                  >{`Receipt ${idx + 1}`}</a>
                                ) : <React.Fragment key={idx}></React.Fragment>;
                              }) : <></>
                        } />
                        <RequestRow name='Payout Invoice:' altText='no invoices uploaded' value={payInfo.payoutInvoice} jsx={
                            payInfo.payoutInvoice ? payInfo.payoutInvoice.split(',').map((ele, idx) => {
                                return (
                                  <a
                                    key={idx}
                                    href={ele}
                                    target="_blank"
                                    type="button"
                                    className="btn btn-link"
                                    role="button"
                                  >{`Receipt ${idx + 1}`}</a>
                                );
                              }) : <></>
                        } />
                    </SectionBody>
                </SectionInnerContainer>
                <SectionInnerContainer>
                    {(transactions && transactions.length) ? (
                        <div className="card">
                        <div className="card-header">
                            <div className="row no-gutters">
                            <div className="col">Transaction History</div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="container-fluid">
                            {(transactions.map((t) => {
                                return (
                                    <div className="row" key={`${t.id}-${t.type}`}>
                                        {/* {(t.amount > 0 || t.walletDeduction > 0) ? (
                                        <div className="col">{(t.amount > 0) ?
                                        <li>{(t.type === 'refund') ? 'Refund' : 'Copay'} of Rs. {t.amount} on {t.formattedDate} - {t.status}</li>
                                        : <li>{(t.type === 'refund') ? 'Refund' : 'Copay'} of Rs. {t.walletDeduction} deducted from Wallet on {t.formattedDate} - {t.status}</li>
                                        }
                                        </div>) : null} */}
                                        {t.type === 'refund' ? (
                                            <li>Refund of Rs. {t.amount > 0 ? t.amount : t.walletDeduction} on {t.formattedDate} - {t.status}</li>
                                            ) : 
                                             (
                                                <li>
                                                User paid copay of Rs. {t.amount} and wallet has been deducted with Rs. {t.walletDeduction} on {t.formattedDate} - {t.status}
                                                </li>
                                            )
                                        }
    
                                    </div>
                                )
                            }))}
                            </div>
                        </div>
                        </div>
                    ) : null}
                </SectionInnerContainer>
            </SectionContainer>
            <SectionContainer>
                <SectionInnerContainer>
                    <SectionHeader>Fulfilment Details</SectionHeader>
                    <SectionBody>
                        <SpocDetailContainer>
                            {payInfo.fulfillmentDetails ? <>
                                <RequestRow name='Fulfilment Id:' value={payInfo.fulfillmentDetails.fulfilmentId} />
                                <RequestRow name='Pref. Payment:' value={payInfo.fulfillmentDetails.preferredPayment} />
                                <RequestRow name='Payment Cycle:' value={payInfo.fulfillmentDetails.paymentCycle} />
                                <RequestRow name='Cancelled Cheque:' value={payInfo.fulfillmentDetails.cancelledChequeImg} jsx={
                                    <a
                                        href={payInfo.fulfillmentDetails.cancelledChequeImg}
                                        target="_blank"
                                    >
                                        {payInfo.fulfillmentDetails.cancelledChequeImg
                                        ? 'Click Here'
                                        : 'Unavailable'}
                                    </a>
                                } />
                                <RequestRow name='Payee Name:' value={payInfo.fulfillmentDetails.payeeName} />
                                <RequestRow name='Account Number:' value={`${payInfo.fulfillmentDetails.accountNumber} (${payInfo.fulfillmentDetails.accountType})`} />
                                <RequestRow name='IFSC Code:' value={payInfo.fulfillmentDetails.ifscCode} />
                                <RequestRow name='PAYTM:' value={payInfo.fulfillmentDetails.upiPhone} />
                                <RequestRow name='UPI:' value={payInfo.fulfillmentDetails.upiAddress} />
                                <RequestRow name='QR Code:' value={payInfo.fulfillmentDetails.qrCodeImg} jsx={
                                    <a
                                        href={payInfo.fulfillmentDetails.qrCodeImg}
                                        target="_blank"
                                    >
                                        {payInfo.fulfillmentDetails.qrCodeImg
                                        ? 'Click Here'
                                        : 'Unavailable'}
                                    </a>
                                } />
                                <RequestRow name='Remarks:' value={payInfo.fulfillmentDetails.remarks} />
                            </> : <></>}
                        </SpocDetailContainer>
                    </SectionBody>
                </SectionInnerContainer>
            </SectionContainer>
        </DetailsContainer>
    )

    const handleRequestSubmit = () => {
        const { requestId } = details
        return requestVaccinationPayment(requestId)
        .then((response) => {
            if (response.message === 'success') {
                return toast.success('Payout requested successully')
            }
            toast.error('Error while requesting payout')
        })
        .catch((err) => {
            toast.error('Error while requesting payout')
        })
        .finally(() => {
            setShowConfirmModal(false)
            setReqPaymentLoading(false)
        })
    }

    if (!isOpen) {
        return <></>
    }

    if (!basicInfo || !details.requestId) {
        return <LoadingComponent color="black" />;
    }

    if (basicInfo && details.requestId) {
        return <Modal
            isOpen={details.showRequestDetails}
            toggle={onClose}
            size='lg'
            className='rtsp-modal-big'
        >
            <ModalHeader
                className='bg-primary'
                toggle={onClose}
            >
                {`Request Details (${details.requestId})`}
            </ModalHeader>
            <ModalBody>
                <Container fluid>
                    <div className='row'>
                        <ButtonGroup>
                            <Button className='mr-2'
                                onClick={() => setActiveInfo('basic')}
                                active={activeInfo === 'basic'}
                            >
                                Basic Info
                            </Button>
                            <Button className='mr-2'
                                onClick={() => setActiveInfo('center')}
                                active={activeInfo === 'center'}
                            >
                                Center Info
                            </Button>
                            <Button className='mr-2'
                                onClick={() => setActiveInfo('payment')}
                                active={activeInfo === 'payment'}
                            >
                                Payment Info
                            </Button>
                            <Button className='mr-2'
                            onClick={() => {
                              navigator.clipboard.writeText(`
Patient name : ${basicInfo.patientName}
Patient phone : ${basicInfo.patientPhone}
Patient DOB : ${basicInfo.patientDob}
Patient Age : ${basicInfo.age}
Center name : ${basicInfo.networkCenterName}
Center address : ${basicInfo.networkCenterAddress}
Vaccine name : ${basicInfo.subCategoryName}
Appointment date : ${basicInfo.appointmentDate}
Appointment time slot : ${basicInfo.appointmentTime}
                              `
                              );
                            }}
                          >
                            Copy Details
                          </Button>
                        </ButtonGroup>
                    </div>
                    <div className='row'>
                        <div className='col-md-8'>
                            {activeInfo === 'basic' && renderBasicInfo()}
                            {activeInfo === 'center' && renderCenterInfo()}
                            {activeInfo === 'payment' && renderPaymentInfo()}
                        </div>
                        <div className='col-md-4'>
                            {renderTimeline()}
                        </div>
                    </div>
                    <ConfirmationModal 
                        isOpen={showConfirmModal}
                        confirmationMessage={confirmMessage}
                        callback={handleRequestSubmit}
                        toggle={() => setShowConfirmModal(prev => !prev)}
                    />
                </Container>
            </ModalBody>
        </Modal>
    } else {
        return <div />
    }
}

export default VaccinationRequestDetails