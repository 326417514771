import React from 'react';
import { Route, Switch } from 'react-router-dom';
import LabPartnerDetails from "./LabPartnerDetails";
import LabsTestOnboarding from "./LabsTestOnboarding";
import LabsCenterOnboarding from './LabsCenterOnboarding';
import NavBar from "../layout/NavBar";
import loginCheck from "../login/LoginCheck";
import LabsListing from "./LabsListing";
import LabsOnboardHome from "./LabsOnboardHome";
import LabsCentersTestDetails from './LabsCentersTestDetails';

import LabsPincodeOnboarding from './LabsPincodeOnboarding';

class LabsOnboardingNavigator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }

    render() {
        return (
            <React.Fragment>
                <NavBar />
                <div className="container-fluid mt-5">
                    <Switch>
                        <Route
                            exact
                            path="/lab-partner-details"
                            name="Labs | All Partners"
                            component={LabsListing}
                        />
                        <Route
                            exact
                            path="/lab-partner-details/new"
                            name="Labs | Add New Partner"
                            component={LabsOnboardHome}
                        />
                        <Route
                            exact
                            path="/lab-partner-details/edit/:partnerId"
                            name="Lab | Edit Partner Info"
                            component={LabsOnboardHome}
                        />
                        <Route
                            exact
                            path="/lab-partner-details/:partnerId"
                            name="Labs | New Partner"
                            component={LabPartnerDetails}
                        />
                        <Route
                            exact
                            path="/lab-partner-details/:partnerId/pincodes"
                            name="Labs | Pincodes"
                            component={LabsPincodeOnboarding}
                        />
                        <Route
                            exact
                            path="/lab-partner-details/:partnerId/tests"
                            name="Labs | Tests"
                            component={LabsTestOnboarding}
                        />
                        <Route
                            exact
                            path="/lab-partner-details/:partnerId/centers"
                            name="Labs | Tests"
                            component={LabsCenterOnboarding}
                        />
                        <Route
                            exact
                            path="/lab-partner-details/:partnerId/centers/:centerId/tests"
                            name="Labs | Tests"
                            component={LabsCentersTestDetails}
                        />
                    </Switch>
                </div>
            </React.Fragment>
        );
    }
}

export default loginCheck(LabsOnboardingNavigator);
