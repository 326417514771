import React from 'react';
import { toast } from 'react-toastify';

import Modal from '../common/Modal';
import LoadingComponent from "../common/LoadingComponent";

import { addEmployeeCodeForUser } from '../../services/api/customer-support';

class AddEmployeeCodeModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            employeeCode: '',
            loading: false,
        };
    }

    handleOnChangeEmployeeCode = (e) => {
        const employeeCode = e.target.value;
        this.setState({
            employeeCode: employeeCode.trim(),
        });
    };

    handleAddCodeButton = () => {
        const { userId } = this.props;
        const { employeeCode } = this.state;
        if (!userId) {
            toast.error('Somthing Went Wrong.');
            return;
        }
        if (!employeeCode) {
            toast.error('Please enter a employee code');
            return;
        }
        this.setState({
            loading: true,
        });
        return addEmployeeCodeForUser(userId, employeeCode)
            .then((response) => {
                console.log(response, 'Response from Server');
                if (response.message === 'success') {
                    toast.success(response.successMessage);
                    this.closeModal();
                    return;
                }
                throw new Error(response.errorMessage || 'Not able to add employee code for user');
            })
            .catch((err) => {
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            });
    };

    openModal = () => {
        this.setState({
            isOpen: true,
        })
    };

    closeModal = () => {
        this.setState({
            isOpen: false,
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.userId !== this.props.userId) {
            this.setState({
                employeeCode: '',
                loading: false,
                isOpen: false,
            });
        }
    }

    render() {
        const { userId } = this.props;
        const { employeeCode, loading } = this.state;
        return (
            <React.Fragment>
                <button className="btn btn-primary" onClick={this.openModal}>Add Code</button>
                <Modal
                    isOpen={this.state.isOpen}
                    onClose={() => (this.setState({ isOpen: false }))}
                    onOpen={() => (this.setState({ isOpen: true }))}
                    header={`Add Employee Code (${userId})`}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <input value={employeeCode} onChange={this.handleOnChangeEmployeeCode} />
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                {(loading) ? (
                                    <LoadingComponent />
                                ) : (
                                    <button className="btn btn-primary" onClick={this.handleAddCodeButton}>Add Code</button>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

export default AddEmployeeCodeModal;
