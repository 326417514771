import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from "../layout/NavBar";
import { addLabPartner, fetchPartnerDetails, updateLogo, setLabPriority } from '../../services/api/lab-partners';
import { toast } from 'react-toastify';

import { withRouter } from 'react-router-dom';
import LoadingComponent from "../common/LoadingComponent";

class LabsOnboardHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emails: '',
            name: '',
            opsName: '',
            type: 'pathology',
            processing: false,
            partnerId: null,
            logo: '',
            collectionCharge: 0,
        };
    }

    addNewLabPartner = () => {
        if (this.state.emails.trim().length === 0 ||
            this.state.name.trim().length === 0 ||
           this.state.opsName.trim().length === 0
        ) {
            toast.error('Enter all the details');
            return;
        }

        if (this.state.processing) {
            return;
        } else {
            this.setState({
                processing: true,
            });
        }
        const { partnerId } = this.state;
        addLabPartner(this.state.name, this.state.emails.trim(), this.state.type, this.state.opsName, partnerId, this.state.active, this.state.collectionCharge, this.state.clientCode, this.state.tds, this.state.credit)
            .then((response) => {
                if (response.body.message === 'success') {
                    setLabPriority(partnerId,this.state.priority)
                        .then((response) => {
                            if (response.message === 'success') {
                              if (partnerId) {
                                toast.success(
                                  'Successfully updated the partner info'
                                );
                                this.getPartnerBasicDetails();
                              } else {
                                toast.success('Successfully added new partner');
                                if (!isNaN(partnerId) && partnerId !== null) {
                                  this.setState(
                                    {
                                      partnerId,
                                    },
                                    this.getPartnerBasicDetails
                                  );
                                }
                              }
                            }
                        })
                    return;
                }
                throw new Error(response.body.errorMessage || 'Error while persisting partner details');
            })
            .catch((err) => {
                console.error('Error in adding new partner', err, partnerId);
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    processing: false,
                });
            });
    };

    getPartnerBasicDetails = () => {
        const { partnerId } = this.state;
        if (isNaN(partnerId) || partnerId === null) {
            toast.error('Error while fetching the basic details');
            return;
        }
        return fetchPartnerDetails(partnerId)
            .then((response) => {
                const body = response.body;
                const partnerDetails = body.partnerDetails;
                if (body.message === 'success') {
                    this.setState({
                        emails: partnerDetails.emails || '',
                        name: partnerDetails.partnerName || '',
                        opsName: partnerDetails.displayName || '',
                        type: partnerDetails.labType,
                        active: partnerDetails.active,
                        logo: partnerDetails.logo,
                        clientCode: partnerDetails.clientCode|| '',
                        collectionCharge: partnerDetails.collectionCharge || 0,
                        tds: partnerDetails.tds || 0,
                        credit: partnerDetails.credit || false,
                        priority: partnerDetails.priority
                     });
                    return;
                }
                throw new Error(body.errorMessage || 'Error while fetching the details of partner');
            })
            .catch((err) => {
                console.error(err, partnerId, 'Error while fetching the basic details for partner');
                toast.error(err.message);
            });
    };

    uploadLogo = (e) => {
        const file = e.target.files[0];
        const size = file.size;
        if (size/1024 > 20) {
            toast.error('Too big file for a simple logo');
            return;
        }
        if (file && this.state.partnerId) {
            return updateLogo(this.state.partnerId, file)
                .then((response) => {
                    const body = response.body;
                    if (body.message === 'success') {
                        toast.success('Successfully update partner logo');
                        this.getPartnerBasicDetails();
                        return;
                    }
                    throw new Error(response.body.errorMessage || 'Error while persisting partner details');
                })
                .catch((err) => {
                    console.error('Error in adding new partner', err, this.state.partnerId);
                    toast.error(err.message);
                });
        }
    };

    handleActiveInactiveChange = () => {
        this.setState({
            active: (!this.state.active),
        });
    };

    handleCreditChange = () => {
        this.setState({
            credit: (!this.state.credit),
        });
    };

    componentDidMount() {
        const partnerId = parseInt(this.props.match.params.partnerId);
        if (!isNaN(partnerId) && partnerId !== null) {
            this.setState({
                partnerId,
            }, this.getPartnerBasicDetails);
        }
    }

    render() {
        const { processing, partnerId } = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <NavBar />
                </div>
                <div className="row mt-5 pt-1">
                    <div className="col">
                        <h2 className="pl-1">{(partnerId) ? 'Edit' : 'Add a new'} Lab partner</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        <div className="form">
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Lab Name(As shown to user)</label>
                                <input type="text" className="form-control" id="formGroupExampleInput" value={this.state.name}
                                       placeholder="Name" onChange={(e) => this.setState({ name: e.target.value })}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput2">Lab Email(Comma separated)</label>
                                <input type="text" className="form-control" id="formGroupExampleInput2" value={this.state.emails}
                                       placeholder="xyz@abc.com" onChange={(e) => this.setState({ emails: e.target.value })}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Lab Name(As shown to OPS)</label>
                                <input type="text" className="form-control" id="formGroupExampleInput" value={this.state.opsName}
                                       placeholder="Name" onChange={(e) => this.setState({ opsName: e.target.value })}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Client Code</label>
                                <input type="text" className="form-control" id="formGroupExampleInput" value={this.state.clientCode}
                                       placeholder="client-code" onChange={(e) => this.setState({ clientCode: e.target.value })}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Collection Charges</label>
                                <input type="text" className="form-control" id="formGroupExampleInput" value={this.state.collectionCharge}
                                       placeholder="50" onChange={(e) => this.setState({ collectionCharge: e.target.value })}/>
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">TDS</label>
                                <input 
                                className="form-control" 
                                id="formGroupExampleInput" 
                                type="number"
                                min="0"
                                placeholder={this.state.tds}
                                value={this.state.tds}
                                onChange={(e) => this.setState({ tds: e.target.value })}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Priority</label>
                                <input 
                                type="number" 
                                max="1"
                                min="0"
                                className="form-control" 
                                id="formGroupExampleInput" 
                                value={this.state.priority}
                                placeholder={this.state.priority === null ? 'Unprioritized' : this.state.priority } 
                                onChange={(e) => this.setState({ priority: e.target.value })}/>
                            </div>
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitch2" checked={this.state.credit} onChange={this.handleCreditChange} />
                                <label className="custom-control-label" htmlFor="customSwitch2">
                                    Credit
                                </label>
                            </div>
                            <div className="form-row mb-1">
                                <button className={this.state.type==='pathology'?'mr-1 btn btn-sm btn-success': 'mr-1 btn btn-sm btn-secondary'} disabled={this.state.type==='pathology'} onClick={(e) => this.setState({type: 'pathology'})}>Pathology</button>
                                <button className={this.state.type==='radiology'?'mr-1 btn btn-sm btn-success': 'mr-1 btn btn-sm btn-secondary'} disabled={this.state.type==='radiology'}  onClick={(e) => this.setState({type: 'radiology'})}>Radiology</button>
                                <p className="text-capitalize">Selected - {this.state.type}</p>
                            </div>
                            {((!isNaN(partnerId) && partnerId !== null) ? (
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={this.state.active} onChange={this.handleActiveInactiveChange} />
                                        <label className="custom-control-label" htmlFor="customSwitch1">
                                            Active
                                        </label>
                                </div>
                            ) : (<React.Fragment />))}
                            {(processing) ? (
                                <LoadingComponent/>
                            ) : (
                                <button className="btn btn-success" onClick={this.addNewLabPartner}>{(!isNaN(partnerId) && partnerId !== null) ? 'Update' : 'Add'} Partner</button>
                            )}
                        </div>
                    </div>
                    <div className="col-3 offset-4 text-center">
                        <img src={this.state.logo} height={100} />
                        <br />
                        <br />
                        {(this.state.partnerId) ? (<input type="file" className="form-control" onChange={this.uploadLogo} accept="image/png" />) : (null)}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(withRouter(LabsOnboardHome));





