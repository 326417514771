import React, {Component} from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { editTestInOrder } from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
class LabOrderEditTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            mrp: 0,
            cost: 0,
            testName: null,
            testCode: null,
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
        if (this.state.modal === false) {
            this.props.close();
        }
    };


    editOrder = () => {
        console.log(this.props.selectedTestForEdit, this.state.mrp, this.state.cost);
        console.log(this.props.selectedTestForEdit, this.props.selectedTestForEdit.mrp, this.props.selectedTestForEdit.cost);

        let name = null, mrp, cost;
        if (this.state.testName === null) {
            name = this.props.selectedTestForEdit.labName;
        } else {
            name = this.state.testName;
        }
        if (this.state.mrp === 0) {
            mrp = this.props.selectedTestForEdit.mrp;
        } else {
            mrp = this.state.mrp;
        }
        if (this.state.cost === 0) {
            cost = this.props.selectedTestForEdit.cost;
        } else {
            cost = this.state.cost;
        }
        if (parseInt(mrp) === 0  || parseInt(cost) === 0) {
            toast.error('MRP and COST cannot be Zero');
            return;
        }
        editTestInOrder(this.props.orderId, this.props.selectedTestForEdit.labId, mrp, cost, name)
            .then((response) => {
                if (response.body.message === 'success') {
                    toast.success('Order updated!');
                    this.toggle();
                    this.props.loadCart();
                } else {
                    toast.error('Error in updating the test');
                }
            })
            .catch((err) => {
                console.log('Error in updating the test', err);
                toast.error('Error in updating the test');
            });

    };

    render() {
        return (
            <div>
                {/*<button onClick={this.toggle} className="btn btn-danger">Add New Test</button>*/}
                <Modal isOpen={this.props.open} toggle={this.toggle} size="lg">
                    <h4 className="mt-1 mb-0 ml-2">Edit Lab Test</h4>
                    <ModalBody>
                        <p>Fill the below details to edit a lab test</p>
                        {/*<Button color="success" onClick={this.toggleNested}>Add New Partner</Button>*/}
                        <div className="form">
                            <div className="form-row">
                                <div className="form-group col-3">
                                    <label htmlFor="inputName">Test Name</label>
                                    <input type="text" className="form-control" id="inputName" disabled={true} defaultValue={this.props.selectedTestForEdit.labName}/>
                                </div>

                                <div className="form-group col-3">
                                    <label htmlFor="inputMrp">MRP (Market price)</label>
                                    <input type="number" className="form-control" id="inputMrp" placeholder="Rs" onChange={(e) => this.setState({
                                        mrp: e.target.value,
                                    })} defaultValue={this.props.selectedTestForEdit.mrp}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-3">
                                    <label htmlFor="inputCost">Cost (Payeable to partner)</label>
                                    <input type="number" className="form-control" id="inputCost" placeholder="Rs" onChange={(e) => this.setState({
                                        cost: e.target.value,
                                    })} defaultValue={this.props.selectedTestForEdit.cost}/>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.editOrder()}>Edit Order</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

export default LabOrderEditTest;
