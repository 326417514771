import React from "react";
import Comment from "./comment";
import styled from 'styled-components';

const AllComments = styled.div`
    overflow: auto;
    max-height: 50vh;
`;

export default function CommentList(props) {
    const { comments } = props;
    return (
        <AllComments>
            <div className="commentList">
                <h5 className="text-muted mb-4 bold">
                    <span className="badge badge-success">{props.comments.length}</span>{" "}
                    Comment{props.comments.length > 0 ? "s" : ""}
                </h5>

                {props.comments.length === 0 && !props.loading ? (
                    <div className="alert text-center alert-info">
                        Comment History Empty
                    </div>
                ) : null}

                {comments && comments.length > 0 && comments.map((comment, index) => (
                    <Comment key={index} comment={comment} />
                ))}
            </div>
        </AllComments>
    );
}
