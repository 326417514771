import React, {Component} from 'react';

// Why does this component even exist?
// Blame the authors
// https://github.com/tannerlinsley/react-table/issues/1266#issuecomment-469303181
export default ({filter, onChange}) => {
    return (
        <input
            placeholder="Search..."
            value={filter ? filter.value : ''}
            onChange={event => onChange(event.target.value)}
        />
    );
};
