import styled from 'styled-components';

const Container = styled.div`
  ${
    '' /* padding: 3px;
    border-radius: 3px; */
  }
`;

export const StatusPendingContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #dc3545;
  border-radius: 25px;
  padding: 5px;
`;

export const StatusCompleteContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #00b479;
  border-radius: 25px;
  padding: 5px;
`;

export const StatusDangerContainer = styled(Container)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #fc6d3b;
  border-radius: 25px;
  padding: 5px;
`;

export const StatusUserCancelledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background-color: #ecb800;
  border-radius: 25px;
  padding: 5px;
`;

export const StatusCancelledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #dc3545;
  border-radius: 25px;
  padding: 5px;
`;
