import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input as AntInput,
  Row,
  Select
} from 'antd';

import { ModalHeader, ModalBody, Modal } from 'reactstrap';
import axios from 'axios';
import { addDataForNetworkCenter } from '../../services/api/offline-consult';
import { toast } from 'react-toastify';

const { Option } = Select;

const redirectForLogin = () => {
  window.location.href = '/';
 }
 
 axios.interceptors.response.use((response) => response,  (error) => {
   const statusCode = error.response.data.code;
   console.log(statusCode);
   return statusCode === 401 ?  redirectForLogin() : Promise.reject(error);
 });


const Text = styled.p`
  margin: 0;
  font-family: BrandonText-Medium, Lato, sans-serif;
`;

const SemiboldText = styled(Text)`
  font-family: ProximaNova-Semibold, Lato, sans-serif;
`;

const LabelText = styled(SemiboldText)`
  font-size: 12px;
  text-align: center;
  margin-bottom: 0.25rem;
  margin-top: 0.625rem;
  color: #7c7c7c;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;
  /* over */
`;

const AddSpocModal = (props) => {
  const { isModalOpen, closeSpocsModal,centerId, centerName } = props;
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [type, setType] = useState('');
  const [commsType, setCommsType] = useState('');
  const [sendWhatsApp, setSendWhatsApp] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [sendCC, setSendCC] = useState(false);
  const [sendSMS, setSendSMS] = useState(false);
  const [sendCall, setSendCall] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newSpoc = {
      name,
      phone,
      email,
      type,
      commsType,
      sendWhatsApp,
      sendEmail,
      sendCC,
      sendSMS,
      sendCall,
      requestType: 'Add_SPOC_Details',
      centerId: centerId,
      centerName: centerName
    };  

    if(!validateSpocsDetails(newSpoc)){
      return ;
    }
    const data = await addDataForNetworkCenter(newSpoc);
    console.log(data);
    if (data.message == 'success') {
      closeSpocsModal();
      toast.success('Zoho Requested Created Successfully');
    }
    if(data.message == 'serverError') {
      toast.error(data.errorMessage);
    }
  };

  const validateSpocsDetails = () => {
    let result = true;

    const regexPhone = /^[6-9]\d{9}$/;
    const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!name) {
      toast.error('Please provide SPOC Name');
      return false;
    }
    if(!phone && !email){
      toast.error('Please provide a phone number or email id. Both cannot be empty');
      return false;
    }
    if (phone && !regexPhone.test(phone)) {
      toast.error('Please provide a valid phone number');
      return false;
    }
    if (email && !regexEmail.test(email)) {
      toast.error('Please provide valid email address');
      return false;
    }
    if (!type) {
      toast.error('Please provide SPOC type.');
      return false; 
    }
    if (!commsType) {
      toast.error('SPOC Communication Type cannot be missing');
      return false; 
    }
    return result;
  };
  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader
        className="bg-primary"
        toggle={()=>closeSpocsModal()}
      >
        {`Add Spoc Details`}
      </ModalHeader>
      <ModalBody>
        <Card>
          <BodyContainer>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <LabelText>SPOC Name</LabelText>
                <AntInput
                  value={name}
                  placeholder="SPOC Name"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Col>

              <Col xs={24} md={12}>
                <LabelText>SPOC Phone</LabelText>
                <AntInput
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  placeholder="SPOC Phone"
                />
              </Col>

              <Col xs={24} md={12}>
                <LabelText>SPOC Email</LabelText>
                <AntInput
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  placeholder="SPOC Email"
                />
              </Col>

              <Col xs={24} md={12}>
                <LabelText>SPOC Type</LabelText>
                <Select
                  style={{ width: '100%' }}
                  value={type}
                  defaultValue="Bank Account"
                  onChange={(e) => setType(e)}
                >
                  <Option value="Appointment Desk">Appointment Desk</Option>
                  <Option value="Appointment Co-ordinator">
                    Appointment Co-ordinator
                  </Option>
                  <Option value="Central Board">Central Board</Option>
                  <Option value="Billing Desk">Billing Desk</Option>
                  <Option value="Reception">Reception</Option>
                  <Option value="Account Department">Account Department</Option>
                  <Option value="Doctor (Owner)">Doctor (Owner)</Option>
                  <Option value="Marketing Executive">
                    Marketing Executive
                  </Option>
                  <Option value="Marketing Head">Marketing Head</Option>
                </Select>
              </Col>
              <Col xs={24} md={12}>
                <LabelText>Communication Type</LabelText>
                <Select
                  style={{ width: '100%' }}
                  value={commsType}
                  defaultValue="all"
                  onChange={(e) => setCommsType(e)}
                >
                  <Option value="payment-confirmation">
                    Payment Confirmation
                  </Option>
                  <Option value="appointment-confirmation">
                    Appointment Confirmation
                  </Option>
                  <Option value="all">All</Option>
                </Select>
              </Col>
              <Col xs={24} md={24}>
                <Checkbox
                  style={{ marginTop: 10, marginBottom: 10 }}
                  checked={sendWhatsApp}
                  onChange={(e) => {
                    setSendWhatsApp(e.target.checked);
                  }}
                >
                  Send WhatsApp
                </Checkbox>
                <Checkbox
                  style={{ marginTop: 10, marginBottom: 10 }}
                  checked={sendEmail}
                  onChange={(e) => {
                    setSendEmail(e.target.checked);
                  }}
                >
                  Send Email
                </Checkbox>
                <Checkbox
                  style={{ marginTop: 10, marginBottom: 10 }}
                  checked={sendCC}
                  onChange={(e) => {
                    setSendCC(e.target.checked);
                  }}
                >
                  Send CC Email
                </Checkbox>
              </Col>
              <Col xs={24} md={12}>
              <Checkbox
                  style={{ marginTop: 10, marginBottom: 10 }}
                  checked={sendSMS}
                  onChange={(e) => {
                    setSendSMS(e.target.checked);
                  }}
                >
                  Send SMS
                </Checkbox>
                <Checkbox
                  style={{ marginTop: 10, marginBottom: 10 }}
                  checked={sendCall}
                  onChange={(e) => {
                    setSendCall(e.target.checked);
                  }}
                >
                  Send Call
                </Checkbox></Col>
            </Row>

            <Row gutter={24} style={{marginTop:20}}>
              <Col xs={24} md={12}>
                <Button
                  type="primary"
                  block
                  style={{ marginRight: 10  }}
                  onClick={(e)=>handleSubmit(e)}
                >
                  Send Request
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button
                  block
                  onClick={closeSpocsModal}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </BodyContainer>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default AddSpocModal;
