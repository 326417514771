import moment from 'moment';
import React, { Component } from 'react';
import { getAuthPlanDetails } from '../../services/api/authBenefits';


class AuthBenefitsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      relatives: [],
      benefits: [],
      planIds: [],
      showDetails:false,
      cardOpenRelativeIds: [],
    };
  }
  componentDidMount = () => {
    this.fetchPolicyDetails();
  };

  fetchPolicyDetails = () => {
    const params = {
      userId: this.props.userId,
    };
    return getAuthPlanDetails(params)
      .then((result) => {
        console.log({ result });
        this.setState({
          benefits: result.data,
        });
    });
  };

  showPoliciesDetails =   () => {
    const elements = [];
    this.state.benefits.forEach((element) => {
      elements.push(
        <div>
          <div
            className={`card mb-2 ${(moment(element.endDate) < moment()) ? ('text-white bg-danger') : ''}`}
          >
            <div className="card-header"
               onClick={() => {
                 this.handleTogglePolicyDetails(element.authPlanUserId);
               }}
            >
              <div className="row no-gutters">
                <span className="col-7">
                <label style={{ wordBreak: 'break-word' }}>
                  {element.planName}
                </label>
                </span>
                <span className="col-3">
                <label style={{ wordBreak: 'break-word' }}>
                  {element.userName}
                </label>
                </span>
              </div>
              <div className="row no-gutters">
                <span className="col-7">
                  <small style={{ wordBreak: 'break-word' }}>
                    {moment(element.startDate).format('DD MMM YY')} - {moment(element.endDate).format('DD MMM YY')}
                  </small>
                </span>
              </div>
            </div>
            {this.showMorePoliciesDetails(element)}
          </div>
        </div>
      );
    });
    if (elements.length) {
      return elements;
    } else {
      return (
        <div>
          <label>No policy present.</label>
        </div>
      );
    }
  };

  handleTogglePolicyDetails = (planId) => {
    if (this.state.planIds.includes(planId)) {
      const planIds = this.state.planIds.filter((id) => {
        return id !== planId;
      });
      this.setState({
        planIds,
      });
    } else {
      this.setState({
        planIds: [...this.state.planIds, planId],
      });
    }
    // this.setState({showDetails : true})
  };

  showMorePoliciesDetails = (details) => {
    if (this.state.planIds.includes(details.authPlanUserId)) {
      // if(this.state.showDetails){
      return (
        <div className=" card-body">
    
          <div className="row">
            <div className="col-5">
              <label>Phone:</label>
            </div>
            <div className="col-6">
              <label style={{ wordBreak: 'break-word' }}>
                {details.phone}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>Days:</label>
            </div>
            <div className="col-6">
              <label style={{ wordBreak: 'break-word' }}>
                {details.planDays}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>Email:</label>
            </div>
            <div className="col-6">{details.email}</div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>Gender:</label>
            </div>
            <div className="col-6">
              <label style={{ wordBreak: 'break-word' }}>
                {details.gender}
              </label>
            </div>
            
          </div>
          <div className="row">
            <div className="col-5">
              <label>Relation:</label>
            </div>
            <div className="col-6">
              <label style={{ wordBreak: 'break-word' }}>
                {details.relation || "SELF"}
              </label>
            </div>
            
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  showDependantDetails = (dependents) => {
    const elements = [];
    dependents.forEach((element) => {
      elements.push(
        <div className={`card mt-2 text-dark`}>
          <div className="card-body">
            <h5 className="card-title">{element.DependentName}&nbsp;&nbsp;
            <small>{moment(element.dob).format('DD MMM YY')}
            {/* <span className="text-danger">{(element.deonboardedOn === null ? '' : ` - Removed On ${element.deonboardedOn}` )}</span> */}
            </small>
            </h5>
            <div className="row">
              <div className="col-5">
                <label>Phone:</label>
              </div>
              <div className="col-6">
                <label style={{ wordBreak: 'break-word' }}>
                  {element.dependentPhone}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <label>Gender:</label>
              </div>
              <div className="col-6">
                <label style={{ wordBreak: 'break-word' }}>
                  {element.dependentGender}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <label>Relation:</label>
              </div>
              <div className="col-6">
                <label style={{ wordBreak: 'break-word' }}>
                  {element.relation}
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    });
    if (elements.length) {
      return elements;
    } else {
      return (
        <div>
          <label>No dependant added yet.</label>
        </div>
      );
    }
  };

  render() {
    const { userInfo } = this.state;
    const { showUserInfo = false, allowRelativeEdit = false } = this.props;
    return (
      <div className="container card">
        <div className="row mt-3">
          <div className="col-6">
            <label>
              <b>Policies</b>
            </label>
            {this.showPoliciesDetails()}
          </div>
        </div>
      </div>
    );
  }
}

export default AuthBenefitsDetails;
