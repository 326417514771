import styled from 'styled-components'

export const OuterComponent = styled.div`
    display: flex;
    flex-direction: column;
`

export const PaddedContainer = styled.div`
    padding: 5px;
`

export const Page = styled.div`
    display: flex;
    flex-direction: row;
    position: sticky;
    left: 0;
    justify-content: space-between;
    width: 100vw;
    padding: 5px;
`

export const ActionButton = styled.button`
    width: 40%;
    border: none;
    border-radius: 5px;
`

export const MainButton = styled.button`
    background: #8852cc;
    border: none;
    margin-bottom: 5px;
    color: #fff;
    font-size: 13px;
`

export const MainRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    padding: 5px;
    left: 0;
    position: sticky;
`