import styled from 'styled-components';
import { Input } from 'valuelink/lib/tags';
import { Row } from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

// Doctor On Call
export const OuterComponent = styled.div`
  /* background-color: rgba(136, 82, 204, 0.4); */
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const PaddedContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
`;
export const SubPaddedContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem;
  margin-left: 2rem;
`;

export const SearchBox = styled(Input)`
  margin: 0.5rem 50.5rem 0.5rem 1.5rem;
`;

// Consultation View Modal

export const CloseModal = styled(FaTimes)`
  position: absolute;
  top: 0rem;
  right: 0rem;
`;
export const PageTitle = styled.p`
  font-size: 1.25rem;
  padding: 0.05rem 0.5rem;
  font-family: serif;
  margin: 0;
  position: relative;
`;

// Doctor Info
export const DocInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex: 4;
  background-color: #8852cc;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
  padding: 0.5rem;
`;
export const WhiteText = styled.p`
  color: white;
  font-size: 0.9375rem;
  font-family: serif;
  padding: 0rem 0.5rem 0rem 0rem;
`;
// Left side - start
export const DocImg = styled.img`
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 3.75rem;
  margin: 1rem 10rem 1rem 5rem;
  justify-content: center;
  align-content: center;
`;
// Center content
export const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0rem 5rem 0rem 0rem;
  padding: 0rem;
  justify-content: center;
  align-content: center;
`;
// Body Container
export const BodyContainer = styled.div`
  margin: 0rem 0.5rem 0rem 0.5rem;
  padding: 0.5rem;
`;
export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

//Super Action
export const Icons = styled.div`
  font-size: 10px;
`;

// General Info
export const RowContainer = styled(Row)`
  color: black;
  font-size: 0.9375rem;
  font-family: serif;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: #cccccc;
`;
export const RowContainerEven = styled(Row)`
  color: black;
  font-size: 0.9375rem;
  font-family: serif;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  background-color: #f0f0f0;
`;
