import { Button, Input } from 'reactstrap';
import styled from 'styled-components';

export const OuterComponent = styled.div`
  /* background-color: rgba(136, 82, 204, 0.4); */
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
`;

export const PaddedContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* flex: 1; */
`;

export const BodyContainer = styled.div`
  padding: 1rem;
  margin-top: 1rem;
`;
