import React from 'react';

import Modal from '../common/Modal';

import {
    changeOrderStatus,
    fetchSlots, getOrders,
    getReasonsForRefund,
    getUserCartForOrder,
    refundLabsTestsForOrder,
    getReasons
} from '../../services/api/get-labs-orders';

import { toast } from 'react-toastify';
import LoadingComponent from '../common/LoadingComponent'
import {connect} from "react-redux";
import Select from 'react-select';
import {setCartInfo} from "../../actions";

const ActionTypes = {
    ORDER_CONFIRM: 'order-confirm',
    ORDER_RESCHEDULED: 'order-reschedule',
    SAMPLE_COLLECTED: 'sample-collected',
    ORDER_COMPLETED: 'order-completed',
    ORDER_CANCELLED: 'order-cancelled',
    ORDER_REFUNDED: 'order-refunded',
};

class OrderActionModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            collectionDate: null,
            slotId: null,
            reason: '',
            cancelReason: [],
            loading: false,
            collectionTime: '',
            transactionId: '',
            slots: [],
            refundRemarks: '',
            testCheckedStatus: [],
            allRefundReasons: [],
            refundAmount: [],
            handlingCharge: 0,
        };

        this.refundReasonRef = React.createRef();
    }

    refundReasonChangeHandler = (e) => {
        const { value } = e.target;
        this.setState({
            refundReason: value,
        });
    }

    refundRemarksChangeHandler = (e) => {
        const { value } = e.target;
        this.setState({
            refundRemarks: value,
        });
    }

    refundTestStatusChangeHandler = (e, index) => {
        console.log(e.target.value, index);
        let currTestCheckedStatus = this.state.testCheckedStatus;
        let currRefundAmount = this.state.refundAmount;
        currTestCheckedStatus[index] = !this.state.testCheckedStatus[index];
        if (currTestCheckedStatus[ index ]) {
            currRefundAmount[ index ] = '';
        }else{
            currRefundAmount[ index ] = 'NA';
        }
        this.setState({
            testCheckedStatus: currTestCheckedStatus,
            refundAmount: currRefundAmount,
        });
    }

    refundAmountChangeHandler = (e, index) => {
        if (!this.state.testCheckedStatus[ index ]) {
            toast.error('First Check the Item!');
            return;
        }
        const { value } = e.target;
        const currRefundAmounts = this.state.refundAmount;
        console.log('\n CurrRefundAmount: ', currRefundAmounts);
        currRefundAmounts[ index ] = value;
        this.setState({
            refundAmount: currRefundAmounts,
        })
    }

    renderCartDetails = () => {
        const { cartItemsResult } = this.state;
        if (cartItemsResult && cartItemsResult.itemsList ) {
            console.log('\n Cart Details renderCartDetails OrderActionModel', cartItemsResult);
            const cartItems = cartItemsResult.itemsList;
            return cartItems.map((test, index) => {
                console.log('\n Item: ',test);
                return (
                    <tr key={index}>
                        <td><input type="checkbox" checked={ this.state.testCheckedStatus[index] } onChange={(e)=>this.refundTestStatusChangeHandler(e, index)}/></td>
                        <td>Test</td>
                        <td>{test.labName}</td>
                        <td>{test.labId}</td>
                        <td>{test.postDiscountPrice}</td>
                        <td><input value={this.state.refundAmount[ index ]} onChange={(e) => this.refundAmountChangeHandler(e, index)} type="text" disabled={ this.state.testCheckedStatus[ index ] ? false : true }/></td>
                    </tr>
                );
            });
        }


    }

    getModalBody = () => {
        const { actionName, actionType } = this.props;
        const numOfTests = ((this.state.cartItemsResult || {}).itemsList || []).length;
        const { testCheckedStatus } = this.state;
        console.log('\n getModelBody testChecked', testCheckedStatus);
        console.log('\n getModelBody numoftest', numOfTests);
        const { collectionDate, reason, slotId, collectionTime, transactionId, slots } = this.state;
        const text = `Are you sure you want to ${actionName}?`;
        switch (actionType) {
            case ActionTypes.ORDER_CONFIRM: {
                return (
                    <div className="row">
                        <div className="col">
                            {text}
                        </div>
                    </div>
                );
            }
            case ActionTypes.ORDER_RESCHEDULED: {
                return (
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                {text}
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">Date</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <input type="date" value={collectionDate} onChange={(e) => (this.setState({ collectionDate: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        Slot
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <select value={slotId} onChange={(e) => (this.setState({ slotId: e.target.value }))}>
                                            {slots.map((s) => (
                                                <option key={s.slotId} value={s.slotId}>{s.text}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">Reason For Rescheduling</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <select value={reason} onChange={(e) => (this.setState({ reason: e.target.value }))}>
                                            <option value="">Select Reason</option>
                                            <option value="Lack of Phlebo/Partner Labs">Lack of Phlebo/Partner Labs</option>
                                            <option value="Slot Requested Unavailable">Slot Requested Unavailable</option>
                                            <option value="Pin Code not serviceable">Pin Code not serviceable</option>
                                            <option value="User did not take partner's call">User did not take partner's call</option>
                                            <option value="User requested">User requested</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            case ActionTypes.SAMPLE_COLLECTED: {
                return (
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                {text}
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">Date</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <input type="date" value={collectionDate} onChange={(e) => (this.setState({ collectionDate: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col">Time</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <input type="time" value={collectionTime} onChange={(e) => (this.setState({ collectionTime: e.target.value }))} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            case ActionTypes.ORDER_CANCELLED: {
                return (
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                {text}
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        Reason For Cancellation?
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <select onChange={(e) => (this.setState({ reason: e.target.value }))} value={reason}>
                                        {this.state.cancelReason.map((r) => (
                                            <option value={r.id}>{r.cancelReason}</option>
                                        ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            case ActionTypes.ORDER_REFUNDED: {
                return (
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                {text}
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                <div style={{paddingBottom: '20px'}}  className="row">
                                    <div className="col">
                                        <Select options={this.state.allRefundReasons}
                                                style={{width: '100%', padding: '5px'}}
                                                ref={this.refundReasonRef}
                                                isSearchable={false} />
                                    </div>
                                    <div className="col">
                                        <input style={{width: '100%', padding: '5px'}}  type="text" placeholder="Remarks" value={this.state.refundRemarks} onChange={this.refundRemarksChangeHandler}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <table className="table table-bordered thead-dark">
                                        <thead>
                                            <tr>
                                                <th scope="col">Refund</th>
                                                <th scope="col">Type</th>
                                                <th scope="col">Test Name</th>
                                                <th scope="col">Test Id</th>
                                                <th scope="col">Amount - Paid</th>
                                                <th scope="col">Amount - Refund</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderCartDetails()}
                                            <tr>
                                                <td><input type="checkbox" checked={ testCheckedStatus[ numOfTests ] } onChange={(e)=>this.refundTestStatusChangeHandler(e, numOfTests)}/></td>
                                                <td>HC</td>
                                                <td>Handling Charge</td>
                                                <td>{''}</td>
                                                <td>{this.state.handlingCharge}</td>
                                                <td><input value={this.state.refundAmount[ numOfTests ]} onChange={(e) => this.refundAmountChangeHandler(e, numOfTests)} type="text" disabled={ this.state.testCheckedStatus[ numOfTests ] ? false : true }/></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/*<div className="row">*/}
                                {/*    <div className="col">*/}
                                {/*        Transaction Id*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div className="col">*/}
                                {/*        <input value={transactionId} onChange={(e) => (this.setState({ transactionId: e.target.value }))}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div className="col">*/}
                                {/*        Remark*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row">*/}
                                {/*    <div className="col">*/}
                                {/*        <input value={reason} onChange={(e) => (this.setState({ reason: e.target.value }))}/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </React.Fragment>
                );
            }
            case ActionTypes.ORDER_COMPLETED: {
                return (
                    <React.Fragment>
                        <div className="row">
                            <div className="col">
                                {text}
                            </div>
                        </div>
                    </React.Fragment>
                )
            }
            default:
                return "Error";

        }
    };


    getSlots = () => {
        return fetchSlots()
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    this.setState({
                        slots: body.result,
                    });
                    return;
                }
                throw new Error(body.errorMessage || 'Error while fetching slots');
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err);
            });
    };

    getRefundReasonsForLabsOrders = () => {
        return getReasonsForRefund()
            .then(response => {
                const respBody = response.body;
                const reasons = respBody.result;
                let options = [];
                options = reasons.filter(r => (r.type === 'refund')).map(r => {
                    return {
                        value: r.id,
                        label: r.reason,
                    };
                })
                console.log('\n Reasons getReasonsForRefund', reasons);
                this.setState({
                    allRefundReasons: options
                })
            })
            .catch((err) => {
                console.log('Error in fetching reasons: ', err);
            });
    }

    getCartItems = () => {
        return getUserCartForOrder(this.props.orderId)
            .then((response) => {
                response.body.cartItemsResult.itemsList = response.body.cartItemsResult.itemsList.map(
                    (i) => {
                        i.label = i.labName;
                        i.value = i.labId;
                        return i;
                    }
                );
                const numTests = response.body.cartItemsResult.itemsList.length;
                this.setState({
                    centerDetails: response.body.centerDetails,
                    cartItemsResult: response.body.cartItemsResult,
                    testCheckedStatus: new Array(numTests + 1).fill(false),
                    refundAmount: new Array(numTests + 1).fill('NA'),
                });
            });
    }

    getOrderDetails = () => {
        const orderId = this.props.orderId;
        return getOrders({ orderId })
            .then((response) => {
                console.log('\n Order Details in getOrderDetails OrderActionModal: ', response.body.data.result[0]);
            this.setState({
                handlingCharge: response.body.data.result[0].collectionCharge,
            });
        });
    }

    getCancelReason = () => {
        return getReasons()
            .then((response) => {
                if(response.body.length) {
                    this.setState({
                        cancelReason: response.body,
                    }); 
                }
            })
    }
    componentDidMount() {
        const { collectionDate, slotId } = this.props;
        const numTests = ((this.state.cartItemsResult || {}).itemsList || []).length;
        this.setState({
            collectionDate,
            slotId,
            reason: '',
            loading: false,
            collectionTime: '',
            transactionId: '',
            refundRemarks: '',
            testCheckedStatus: new Array(numTests + 1).fill(false),
            refundAmount: new Array(numTests + 1).fill('NA'),
        }, this.getSlots);
        this.getCancelReason();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log(prevProps.collectionDate, this.props.collectionDate, prevProps.slotId, this.props.slotId, "Collection Date");
        if (prevProps.collectionDate !== this.props.collectionDate || prevProps.slotId !== this.props.slotId) {
            this.setState({
                collectionDate: this.props.collectionDate,
                slotId: this.props.slotId,
                reason: '',
                loading: false,
                collectionTime: '',
                transactionId: '',
            });
        }
    }

    handleSubmit = () => {
        const { orderId, actionType } = this.props;
        const { reason, collectionDate, slotId, collectionTime, transactionId } = this.state;
        console.log(reason, collectionDate, slotId, collectionTime, transactionId, '[handleSubmit]');
        if (!orderId) {
            toast.error('Couldn\'t fetch order');
            return;
        }
        this.setState({
            loading: true,
        });

        if (actionType === ActionTypes.ORDER_REFUNDED) {
            const { refundRemarks, testCheckedStatus, refundAmount } = this.state;
            const numOfTests = this.state.cartItemsResult.itemsList.length;
            const cartItems = this.state.cartItemsResult.itemsList;

            const currRefundReason = this.refundReasonRef.current.state.value ? this.refundReasonRef.current.state.value.value : '';
            console.log('\n Reason for Refund: ', currRefundReason);
            if (currRefundReason === '') {
                toast.error('Refund Reason missing!');
                this.setState({
                    loading: false,
                });
                return ;
            }
            let refundItems = [];
            refundItems = testCheckedStatus.map((status, index) => {
                let amount;
                if (status) {
                    // Checked
                    if (index === numOfTests) {
                        // Handling Charge Data
                        amount = parseInt(refundAmount[ index ]);
                        return {
                            type: "hc",
                            amount: amount,
                        }
                    }
                    // Test Data
                    amount = parseInt(refundAmount[ index ]);
                    if (cartItems[index].packageId) {
                      return {
                        type: 'package',
                        packageId: cartItems[index].packageId,
                        amount: amount,
                      };
                    } else {
                      return {
                        type: 'test',
                        testId: cartItems[index].labId,
                        amount: amount,
                      };
                    }
                }
            }).filter(ele=>ele);

            const refundComment = refundRemarks;
            const refundReasonId = currRefundReason;
            const payload = {
                orderId,
                refundReasonId,
                refundComment,
                refundItems
            };
            console.log('\nPayload to Refund:', payload);
            return refundLabsTestsForOrder(payload)
                .then((response) => {
                    const respBody = response.body;
                    console.log('\nresponse body refundLabsTestsForOrder:', respBody);
                    toast.success('Successfull Refund!');
                    this.props.onClose();
                })
                .catch((err) => {
                    console.log('Error refundLabsTestsForOrder', err);
                    toast.error('Error while making refund!');
                })
                .finally(() => {
                    this.handleCloseActionModal();
                    this.setState({
                        loading: false,
                    });
                })

        }
        return changeOrderStatus(orderId, { reason, collectionDate, slotId, collectionTime, transactionId, status: actionType })
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    toast.success('Changed the status successfully');
                    this.props.onClose();
                    return;
                }
                throw new Error(body.errorMessage || 'Couldn\'t change the status');
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, reason, collectionDate, slotId, collectionTime, transactionId);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleCloseActionModal = () => {
        this.setState({
            refundRemarks: '',
            testCheckedStatus: [],
            allRefundReasons: [],
            refundAmount: [],
        }, this.props.onClose);
    }

    onModalOpen = () => {
        const { actionType } = this.props;
        console.log('[onModalOpen]');
        if (actionType === ActionTypes.ORDER_REFUNDED) {
            this.getCartItems();
            this.getRefundReasonsForLabsOrders();
            this.getOrderDetails();
        }

    }

    render() {
        const { orderId, isOpen, actionName, actionType, status } = this.props;
        const currRefundReasons =  this.state.allRefundReasons;
        console.log('\nProps: ', this.props);
        const { loading } = this.state;
        return (
            <Modal
                isOpen={isOpen}
                header={`Order Id: ${orderId} | Status: ${status}`}
                onOpen={this.onModalOpen}
            >
                {this.getModalBody()}
                <br />
                <div className="row">
                    <div className="col">
                        {(loading) ? (
                            <LoadingComponent />
                        ) : (
                            <button className="btn btn-primary btn-block" onClick={this.handleSubmit}>{actionName}</button>
                        )}
                    </div>
                    <br />
                    <div className="col">
                        <button className="btn btn-danger btn-block"
                                onClick={()=>{
                                    this.handleCloseActionModal();
                                }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}


export default OrderActionModal;
