import React, { Component } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import ReactTable from 'react-table';
import { getAhcRequests } from '../../services/api/get-labs-orders';
import { debounce } from '../../utils/debounce';
import { toast } from 'react-toastify';

import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';

import LabsClickToCallButtonAHC from './LabsClickToCallButtonAHC';


import AhcRequestActions from "./AhcRequestActions";
import AhcActionModals from "./AhcActionModals";

import NavBar from "../layout/NavBar";
import {PageHeader} from "../common/PageHeader";
import { Button } from 'reactstrap';
import AhcRequestsTableSubComponents from "./AhcRequestsTableSubComponents";
import {Link} from "react-router-dom";
import CashgramModal from './CashgramModal';

class AhcRequests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pages: -1,
            filtered: [],
            sorted: [],
            pageSize: 50,
            page: 0,
            requests: [],
            cashgramModal: {
                isOpen: false,
            },
            actionModal: {
                userId: null,
                requestId: null,
                isOpen: false,
                actionName: null,
                actionType: null,
            }
        };


        this.fetchStrategy = this.fetchStrategy.bind(this);

        this.fetchData = this.fetchData.bind(this);
        this.fetchDataWithDebounce = debounce(this.fetchData, 300);
    }

    updateStateFilters = (id, value, id2, value2) => {
        console.log('Update all filters in state', {id, value, id2, value2});
        // console.log('New filter values', moment(value).format('DD-MM-YYYY'), moment(value2).format('DD-MM-YYYY'));
        const filter = this.state.filtered.find(f => f.id === id);
        const filter2 = this.state.filtered.find(f => f.id === id2);
        if(filter) {
            filter.value = value;
        } else {
            this.state.filtered.push({id, value});
        }
        if(filter2) {
            filter2.value = value2;
        } else {
            this.state.filtered.push({id: id2, value: value2});
        }

        this.setState({ filtered: JSON.parse(JSON.stringify(this.state.filtered))});
    };

    fetchStrategy = state => this.fetchDataWithDebounce(state);

    prepareApiFilters = () => {
        const appliedFilters = {};
        // let appliedOrderBy = {};
        // Convert this list of filterables into object properties
        this.state.filtered.forEach(
            filter => (appliedFilters[filter.id] = filter.value),
        );

        appliedFilters.rowsPerPage = this.state.pageSize;
        appliedFilters.page = this.state.page + 1;
        return appliedFilters;
    };

    fetchData = (state, instance) => {
        console.log(state, instance, '[fetchData]');
        // show the loading overlay
        this.setState({
            sorted: state.sorted,
        }, this.fetchOrders);
    };

    onFilteredChange = (e) => {
        this.setState({filtered: e});
    };

    fetchOrders = () => {
        this.setState({ loading: true });
        const appliedFilters = this.prepareApiFilters();
        console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>..',appliedFilters)
        // fetch your data
        getAhcRequests(appliedFilters, this.state.sorted)
            .then(response => {
                console.log('Api response', response.body);
                response.body.data.totalCount = response.body.data.totalCount || 100;
                const pages =
                    response.body.data.totalCount % appliedFilters.rowsPerPage === 0
                        ? parseInt(
                        response.body.data.totalCount / appliedFilters.rowsPerPage,
                        )
                        : parseInt(
                        response.body.data.totalCount / appliedFilters.rowsPerPage,
                    ) + 1;
                this.setState({
                    requests: response.body.data.result,
                    loading: false,
                    pages,
                });
            })
            .catch(err => {
                console.error(err, 'Error in get labs requests API');
                toast.error(`Error in fetching requests`);
            });
    };

    downloadAhcReport = () => {
        const appliedFilters = this.prepareApiFilters();
        appliedFilters.csv = true;
        console.log({ appliedFilters }, 'Applied filters');
        // fetch your data
        getAhcRequests(appliedFilters, this.state.sorted, true);
    };

    getColumns = () => [
        {
            Header: (
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-4">
              <Button
                outline
                color="primary"
                onClick={this.downloadAhcReport}
              >
                Download CSV
              </Button>
            </div>
          </div>
        </div>
      ),
            columns: [
                {
                    Header: 'AHC ID',
                    accessor: 'requestId',
                    filterable: true,
                    width: 70,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'Order ID',
                    accessor: 'orderId',
                    filterable: true,
                    width: 75,
                    Filter: ReactTableInputFilter,
                    Cell: row => {
                        const { orderId, cartId, userId } = row.original;
                        return (<Link title={row.original.orderId} to={`/new-lab-order-details?orderId=${orderId}&cartId=${cartId}&userId=${userId}`}>{orderId}</Link>);
                    },
                },
                {
                    Header: 'Requested On',
                    accessor: 'requestedOnEpoch',
                    width: 120,
                    Cell: row => (
                        <span>
                          {moment(row.original.requestedOnEpoch * 1000).format('Do MMM')}
                        </span>
                    ),
                },
                {
                    Header: 'Preferred Date',
                    accessor: 'preferredDate',
                    width: 120,
                    Cell: row => (
                        <span>
                            {moment(row.original.preferredDate * 1000).format('Do MMM')}
                        </span>
                    ),
                },
                {
                    Header: 'Raise Cashgram Link',
                    width: 155,
                    Cell: (row) => (<Button
                        outline
                        color="primary"
                        disabled={row.original.reimbursement_payment_status == "initiated" ||  row.original.reimbursement_payment_status == "settled" || row.original.reimbursement_payment_status == "link_sent"}
                        onClick={this.handleOpenCashgramModal}
                      >
                        {(row.original.reimbursement_payment_status == "initiated" ||  row.original.reimbursement_payment_status == "settled" || row.original.reimbursement_payment_status == "link_sent")? "Already Raised" : "Raise"}
                      </Button>),
                    style: {
                        
                        textAlign: 'center',
                       
                    },
                    
                },
                {
                    Header: 'Partner',
                    accessor: 'partnerName',
                    width: 100,
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'Center Address',
                    accessor: 'centerAddress',
                    width: 100,
                    Filter: ReactTableInputFilter,
                },
                {
                    expander: true,
                    Header: () => <strong>More</strong>,
                    width: 65,
                    Expander: ({ isExpanded, ...rest }) => (
                        <div>
                            {isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}
                        </div>
                    ),
                    style: {
                        cursor: 'pointer',
                        fontSize: 25,
                        padding: '0',
                        textAlign: 'center',
                        userSelect: 'none',
                    },
                },
                {
                    Header: 'Action',
                    width: 130,
                    Cell: (row) => (<AhcRequestActions
                        userId={row.original.userId}
                        disabled={row.original.requestStatus === 'cancelled'}
                        authToken={row.original.authToken}
                        requestId={row.original.requestId}
                        onClick={this.handleOpenActionModal}
                    />)
                },
            ],
        },
        {
            Header: 'User Details',
            columns: [
                {
                    Header: 'User Name',
                    accessor: 'userName',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'User phone',
                    accessor: 'userPhone',
                    width: 140,
                    filterable: true,
                    Filter: ReactTableInputFilter,
                    Cell: row => {
                        return (
                            <LabsClickToCallButtonAHC ahcRequestId={row.original.requestId} phone={row.original.userPhone}/>
                        )
                    },
                },
                {
                    Header: 'Sponsor Name',
                    accessor: 'sponsorName',
                    width: 100,
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
            ],
        },
        {
            Header: 'Patient Details',
            columns: [
                {
                    Header: 'Patient Name',
                    accessor: 'patientName',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'Patient Phone',
                    accessor: 'patientPhone',
                    Cell: row => {
                        return (
                            <LabsClickToCallButtonAHC ahcRequestId={row.original.requestId} phone={row.original.patientPhone}/>
                        )
                    },
                },
            ],
        },
        {
            Header: 'Status',
            columns: [
                {
                    Header: 'Request Status',
                    accessor: 'requestStatus',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },

            ],
        },
        {
            Header: 'requestType',
            columns: [
                {
                    Header: 'Request type',
                    accessor: 'requestType',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },

            ],
        },
        {
            Header: 'reimbursementInvoiceUrl',
            columns: [
                {
                    Header: 'invoice',
                    accessor: 'reimbursementInvoiceUrl',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                    Cell: row => (
                        <a href={row.original.reimbursementInvoiceUrl} rel="noopener noreferrer" target="_blank">
                            {row.original.reimbursementInvoiceUrl? 'LINK' : '' }
                        </a>
                    ),
                },

            ],
        },
    ];

    handleCloseAhcActionModals = () => {
        this.setState({
            actionModal: {
                requestId: null,
                isOpen: false,
                userId: null,
                actionName: null,
            }
        });
    };

    handleCloseAhcCashgramModals = (success=false) => {
        this.setState({
            cashgramModal: {
                isOpen: false,
            }
        });
        if(success){
            this.fetchStrategy(this.state);
        }
    };

    handleOpenActionModal = (requestId,  userId, authToken, actionName) => {
        console.log({requestId, actionName, authToken, userId}, '[handleOpenActionModal]');
        this.setState({
            actionModal: {
                userId,
                authToken,
                requestId,
                isOpen: true,
                actionName,
            }
        });
    };

    handleOpenCashgramModal = (ahcId) => {
        console.log({ahcId}, '[handleOpenCashgramModal]');
        this.setState({
            cashgramModal: {
                isOpen: true,
                ahcId
            }
        });
    };


    render() {
        const { actionModal, cashgramModal } = this.state;
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <NavBar />
                    </div>
                    <div className="row mt-5 pt-1">
                        <div className="col">
                            <PageHeader>AHC Requests</PageHeader>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <React.Fragment>
                                <ReactTable
                                    keyField="requestId"
                                    data={this.state.requests}
                                    columns={this.getColumns()}
                                    defaultPageSize={50}
                                    pages={this.state.pages}
                                    pageSize={this.state.pageSize}
                                    page={this.state.page}
                                    loading={this.state.loading}
                                    filtered={this.state.filtered}
                                    sorted={this.state.sorted}
                                    manual
                                    onFetchData={this.fetchStrategy}
                                    onFilteredChange={this.onFilteredChange}
                                    onPageSizeChange={pageSize => this.setState({ pageSize })}
                                    onPageChange={page => this.setState({ page })}
                                    onSortedChange={sorted => this.setState({ sorted })}
                                    className="-striped -highlight"
                                    showPaginationTop
                                    showPaginationBottom
                                    SubComponent={row => (
                                        <AhcRequestsTableSubComponents orderInfo={row.original} />
                                    )}

                                />
                                <AhcActionModals
                                    userId={actionModal.userId}
                                    authToken={actionModal.authToken}
                                    requestId={actionModal.requestId}
                                    isOpen={actionModal.isOpen}
                                    actionName={actionModal.actionName}
                                    onClose={this.handleCloseAhcActionModals}
                                />
                                <CashgramModal
                                    isOpen={cashgramModal.isOpen}
                                    ahcId={cashgramModal.requestId}
                                    onClose={this.handleCloseAhcCashgramModals}
                                />
                            </React.Fragment>
                        </div>
                    </div>


                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return { labPartnerId: state.user.labPartnerId };
}

export default connect(mapStateToProps)(AhcRequests);
