import React from 'react';

import { Container, ContainerItem } from './StyledComponents';

import PolicyDetails from '../../policy-details/PolicyDetails';



export default class SubComponent extends React.Component {
    render() {
        const { requestId, userId } = this.props;
        return (
            <Container>
                <ContainerItem style={{ width: "500px" }}>
                    <PolicyDetails userId={userId} />
                </ContainerItem>
            </Container>
        );
    }
}
