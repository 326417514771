export const reactSelectStyleMulti = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#48454E',
  }),
  menu: (provided) => ({
    ...provided,
    overflowY: 'scroll',
    marginTop: '12px',
    minHeight: '320px',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#E6DFF9',
    borderRadius: '8px',
    padding: '4px 8px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '0F0B28',
    fontSize: '14px',
    fontWeight: 500,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#0F0B28',
    ':hover': {
      backgroundColor: '#E6DFF9',
      color: '#0F0B28',
    },
  }),
};

export const reactSelectStyleSingle = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    height: '100px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#48454E',
  }),
  menu: (provided) => ({
    ...provided,
    minHeight: '320px',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#0F0B28',
    fontSize: ' 20px',
    fontWeight: 500,
  }),
};

export const reactTableHeader = {
  color: '#0f0b28',
  fontFamily: 'Montserrat',
  fontSize: '14px',
  fontWeight: 700,
  paddingLeft: '0px',
};

export const reactSelectStyle = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'white',
    height: '100px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#48454E',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '12px',
    // minHeight: '150px',
    overflowY: 'scroll',
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#E6DFF9',
    borderRadius: '8px',
    padding: '4px 12px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '0F0B28',
    fontSize: '14px',
    fontWeight: 500,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: '#0F0B28',
    ':hover': {
      backgroundColor: '#E6DFF9',
      color: '#0F0B28',
    },
  }),
};
