import React, { Component } from 'react';
import Select from 'react-select';
import { Async } from 'react-select';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import LoadingComponent from '../../components/common/LoadingComponent';

import {
  searchDoctors,
  fetchUsersRelative,
  getVerticals,
  shiftAppointment,
} from '../../services/api/offline-consult';

import moment from 'moment';
import { toast } from 'react-toastify';

class ShiftingDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      verticalsResult: [],
      verticalLoading: false,
      showFormWarning: false,
      alertMessage: 'None',
      loading: false,
      reason: '',
      remarks: '',
    };
    this.doctorSelectRef = React.createRef();
    this.verticalSelectRef = React.createRef();
    this.onAlertDismiss = this.onAlertDismiss.bind(this);
  }

  onAlertDismiss() {
    this.setState({ showFormWarning: false });
  }

  handleDoctorResultClick = doctorId => {
    this.setState({ doctorId });
  };

  componentWillMount() {
    this.fetchVerticals();
  }

  fetchVerticals = () => {
    this.setState({ verticalLoading: true });
    getVerticals()
      .then(response => {
        const arr = [];
        response.data.forEach(element => {
          arr.push({ value: element.verticalId, label: element.verticalName });
        });
        this.setState({ verticalsResult: arr });
      })
      .finally(() => {
        this.setState({ verticalLoading: false });
      });
  };

  shiftAppointment = () => {
    this.setState({ loading: true });
    const { requestId } = this.props.shiftingDetails;
    try {
      const doctorId =
        this.doctorSelectRef.current.select.state.value.value || false;
      const centerId =
        this.doctorSelectRef.current.select.state.value.centerId || false;
      const { reason, remarks } = this.state;
      if (!doctorId || !centerId || !reason || !remarks) {
        toast.error('Incorrect Selection');
        return;
      }
      return shiftAppointment(requestId, doctorId, centerId, reason, remarks)
        .then(res => {
          if (res.message === 'success') {
            toast.success(`Success`);
            // this.showSuccessToast('Successfully shifted.');
            this.props.fetchTableData();
            this.toggleParentModal();
          } else {
            const err = new Error('Invalid response');
            err.data = res;
            throw err;
          }
        })
        .catch(err => {
          console.log({ err, response: err.data });
          this.setState({ loading: false });
          toast.error(`Failure! ${err.data.errorMessage}`);
          // this.showFailureToast(err.data.errorMessage);
        });
    } catch (e) {
      toast.error(`Failure!`);
      this.setState({ loading: false });
    }
  };

  searchDoctors = val => {
    const verticalId = (this.verticalSelectRef.current.state.value || {}).value;
    return searchDoctors(val, verticalId)
      .then(response => {
        return response.data || [];
      })
      .then(result =>
        result.map(ele => {
          return {
            label: `${ele.doctorName} - ${ele.networkCenterTitle} - ${ele.centerCity} - ${ele.networkSourceName}`,
            value: ele.doctorId,
            centerId: ele.networkCenterId,
          };
        })
      )
      .catch(err => {
        console.log(err);
      });
  };

  toggleParentModal = () => {
    // reset the message checks
    this.setState({
      collapse: false,
      choice: 1,
      loading: false,
    });
    // call the parent method
    this.props.closeShiftingModal();
  };

  getShiftingReasons = () => {
    return [
      { value: "Doctor Unavailable", label: "Doctor Unavailable" },
      { value: "Doctor Couldn’t get onboarded", label: "Doctor Couldn’t get onboarded" },
    ]
  };

  getShiftingRemarks = () => {
    const { reason } = this.state;
    switch (reason) {
      case "Doctor Unavailable": {
        return [
          { value: "", label: "Select Remarks" },
          { value: "Profiling Error", label: "Profiling Error" },
          { value: "Doctor On Leave", label: "Doctor On Leave" },
          { value: "All slots booked", label: "All slots booked" }
        ];
      }
      case "Doctor Couldn’t get onboarded": {
        return [
          { value: "", label: "Select Remarks" },
          { value: "doctor didn’t respond to our calls", label: "doctor didn’t respond to our calls" },
          { value: "Denied 3rd party payment", label: "Denied 3rd party payment" },
        ];
      }
      default:
        return [];
    }
  };

  handleShiftingReasonChange = ({ value }) => {
    this.setState({
      reason: value,
      remarks: '',
    });
  };

  handleShiftingRemarksChange = ({ value }) => {
    this.setState({
      remarks: value,
    });
  };

  render() {
    const {
      relativeResult,
      relativeLoading,
      loading,
      verticalsResult,
      verticalLoading,
    } = this.state;
    console.log({ props: this.props }, 'asdf');
    const { requestId } = this.props.shiftingDetails;
    return (
      <Modal
        onOpened={this.onModalOpened}
        isOpen={this.props.isOpen}
        onCancel={this.toggleParentModal}
        toggle={this.toggleParentModal}
      >
        <ModalHeader className="bg-primary" toggle={this.toggleParentModal}>
          Shift Request ({requestId})
        </ModalHeader>
        <ModalBody>
          <form>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Select Vertical:</Label>
                </Col>
                <Col>
                  <Select
                    isSearchable={true}
                    ref={this.verticalSelectRef}
                    options={verticalsResult}
                    isLoading={verticalLoading}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Search Doctor:</Label>
                </Col>
                <Col>
                  <Async
                    loadOptions={this.searchDoctors}
                    ref={this.doctorSelectRef}
                  />
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Shifting Reason</Label>
                </Col>
                <Col>
                  <Select options={this.getShiftingReasons()} onChange={this.handleShiftingReasonChange}/>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Row>
                <Col className="col-3">
                  <Label>Remarks</Label>
                </Col>
                <Col>
                  <Select options={this.getShiftingRemarks()} onChange={this.handleShiftingRemarksChange}/>
                </Col>
              </Row>
            </FormGroup>
          </form>
          <Alert
            color="warning"
            isOpen={this.state.showFormWarning}
            toggle={this.onAlertDismiss}
          >
            {this.state.alertMessage}
          </Alert>
        </ModalBody>
        <ModalFooter>
          {(loading) ?
            (<LoadingComponent color={'black'}/>) : <></>
          }
          <Button
            color="primary"
            disabled={loading}
            onClick={this.shiftAppointment}
          >
            Shift Appointment
          </Button>
          <Button color="secondary" onClick={this.toggleParentModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ShiftingDetailsDialog;
