import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import SingleRequestDetail from './SingleRequestDetail';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

class SingleRequestDetailWrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        modalOpen: true,
        redirectToRoute: false,
        requestId: null,
      };
    }
    componentDidMount() {
      const { match } = this.props;
      const requestId = match.params.reqId;
  
      // Set requestId in the state
      this.setState({
        requestId: requestId,
      });
    }
  
  
    toggleModal = () => {
      this.setState(prevState => ({
        modalOpen: !prevState.modalOpen,
      }));
    };
    
    redirectToConsults = () => {
        this.setState({
          redirectToRoute: true,
        });
      };
    
    render() {
        if (this.state.redirectToRoute) {
            return <Redirect to="/offline-consults" />;
          }
          // const { match } = this.props;
          // const requestId = match.params.reqId;
      return (
        <div>
  
          <Modal 
            isOpen={this.state.modalOpen} 
            toggle={this.toggleModal}
            size="lg"
            className="rtsp-modal-big"
          >
            <ModalHeader
                className="bg-primary" 
                toggle={this.redirectToConsults}>Request Details ({this.state.requestId})
            </ModalHeader>
            <ModalBody>
              {/* Render your class-based component here */}
              <SingleRequestDetail requestId={this.state.requestId} />
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={this.redirectToConsults}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }

  export default withRouter(SingleRequestDetailWrapper);