import React, { useEffect, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import NavBar from '../layout/NavBar';
import ReactTable from 'react-table';
import { getAllOpsCredApprovals } from '../../services/api/makeCredential';
import ViewModal from './ViewModal';
import { Button } from 'reactstrap';
import styled from 'styled-components';

const ApproveCredentials = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [slectedRowData, setSelectedRowData] = useState(null);

  const history = useHistory();

  const PaddedContainer = styled.div`
    margin-top: 5rem;
    flex: 1;
    padding-left: 2rem;
    padding-right: 2rem;
  `;

  const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    flex: 1;
    padding: 0px;
    margin: 0px;
  `;

  const BodyComponent = styled.div`
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    border: 1px solid #d5d5d5;
    border-radius: 0.5rem;
    /* overflow: hidden; */
  `;
  const getOpsCredApprovals = () => {
    // Call the API to get the list of credentials that have been approved by the operations team
    getAllOpsCredApprovals().then((res) => {
      setDataSource(res.data);
      console.log(res);
    });
  };

  useEffect(() => {
    getOpsCredApprovals();
  }, []);

  const toggleViewModal = (row) => {
    console.log(row);
    setSelectedRowData(row);
    openViewModal();
  };

  const openViewModal = (row) => {
    setOpenModal(true);
  };

  const closeViewModal = () => {
    setOpenModal(false);
  };

  const tableColumns = [
    {
      accessor: 'name',
      filterable: true,
      sortable: false,
      Header: 'Name',
      width: 100,
    },
    {
      accessor: 'userName',
      filterable: true,
      sortable: false,
      Header: 'UserName',
      width: 100,
    },
    {
      accessor: 'phone',
      filterable: false,
      Header: 'Phone',
      width: 140,
    },
    {
      accessor: 'email',
      filterable: false,
      Header: 'Email',
      width: 250,
    },

    {
      accessor: 'password',
      filterable: false,
      Header: 'Password',
      width: 100,
    },
    {
      accessor: 'comments',
      filterable: false,
      Header: 'Comments',
      width: 300,
      justifyContent: 'center',
      Cell: ({ row }) => (
        <div style={{ wordBreak: 'break-all' }}>{row._original.comments}</div>
      ),
    },

    {
      accessor: 'status',
      filterable: false,
      Header: 'Status',
      filterable: true,
      width: 100,
    },
    {
      Header: 'Action',
      filterable: false,

      Cell: ({ row }) => (
        <button
          style={{
            backgroundColor: '#FFFFFF',
            color: '#712FFF',
            padding: '5px 10px',
            border: '1px solid #712FFF',
            borderRadius: '5px',
          }}
          onClick={() => toggleViewModal(row)}
        >
          View
        </button>
      ),
    },
  ];

  return (
    <Fragment>
      <NavBar />
      <PaddedContainer>
        <HeaderContainer>
          <h3 className="">Approve Credentials</h3>
          <Button
            color="primary"
            onClick={() => {
              history.push('/make-credentials');
            }}
          >
            Make Credentials
          </Button>
        </HeaderContainer>

        {/* Table */}
        <BodyComponent>
          <ReactTable
            filterable
            className="-striped -highlight"
            data={dataSource}
            collapseOnDataChange={false}
            columns={tableColumns}
            loading={loading}
            showPaginationTop
          />
        </BodyComponent>
      </PaddedContainer>

      {openModal && (
        <ViewModal
          getOpsCredApprovals={getOpsCredApprovals}
          centered
          isModalOpen={openViewModal}
          closeViewModal={closeViewModal}
          rowData={slectedRowData}
        />
      )}
    </Fragment>
  );
};

export default ApproveCredentials;
