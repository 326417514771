import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';

const TappableContainer = styled(Tappable)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: ${props => (props.disabled || props.loading ? '1' : '0.2')};
  }
`;

const StyledTappable = ({ children, className, onTap, loading, disabled }) => (
  <TappableContainer
    onTap={() => {
      !loading && !disabled && onTap && onTap();
    }}
    disabled={disabled || loading}
    className={className}
    stopPropagation
    moveThreshold={10}>
    {children}
  </TappableContainer>
);

StyledTappable.propTypes = {
  onTap: PropTypes.func,
};

export default StyledTappable;
