import React from 'react';
import ModalConfirm from '../common/ModalConfirm';

import { resendEmailVerification } from '../../services/api/customer-support';

import { toast } from 'react-toastify';

import { IoIosRepeat } from 'react-icons/io';

class EmailResendModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    onConfirm = () => {
        const { userId } = this.props;
        return resendEmailVerification(userId)
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        isOpen: false,
                    });
                    toast.success('Successfully Sent the Mailer');
                    return;
                }
                throw new Error(response.errorMessage || 'Error while sending verification mailer');
            })
            .catch((err) => {
                console.error(err);
                toast.error(err.message);
            });
    };

    openModal = () => (this.setState({ isOpen: true }));

    closeModal = () => (this.setState({ isOpen: false }));

    render() {
        return (
            <React.Fragment>
                <IoIosRepeat style={{ cursor: 'pointer' }} onClick={this.openModal} size={40} title={'Resend the verification mailer'} />
                <ModalConfirm
                    message={'Are you sure you want resend email?'}
                    onConfirm={this.onConfirm}
                    onClose={this.closeModal}
                    isOpen={this.state.isOpen}
                />
            </React.Fragment>
        );
    }
}

export default EmailResendModal;
