export const defaultState = {
    requestId: null,
    showRequestDetails: null,
};

export default function intimation(state = defaultState, action) {
    switch (action.type) {
        case "SET_INTIMATION_REQUEST_ID": {
            const requestId = action.payload.requestId;
            return Object.assign({}, state, {
                requestId: requestId
            });
        }
        case "UNSET_INTIMATION_REQUEST_ID": {
            return Object.assign({}, defaultState);
        }
        case "SHOW_INTIMATION_REQUEST_MODAL": {
            const showRequestDetails = action.payload.showRequestDetails;
            const requestId = action.payload.requestId;
            return Object.assign({}, state, {
                requestId,
                showRequestDetails
            })
        }
        case "HIDE_INTIMATION_REQUEST_MODAL": {
            return Object.assign({}, defaultState);
        }
        default:
            return state;
    }
}
