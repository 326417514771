import baseRequest, {baseRequestWithHeaders, baseRequestWithHeadersFileResponse} from './baseRequest';
export const getOrders = (filters, appliedOrderBy, csv) => {

    if (filters.status && filters.status === 'all') {
        filters.status = null;
    }
    if (filters.receiptUrl && filters.receiptUrl === 'all') {
        filters.receiptUrl = null;
    }
    filters.orderBy = appliedOrderBy;
    const urlQuery = new URLSearchParams(filters).toString();

    const url = `/new-ops/labs/orders/all?${urlQuery}`;
    const method = 'GET';
    if (!csv) {
        return baseRequestWithHeaders(url, method, null);
    }
    return baseRequestWithHeadersFileResponse(url, method, null);
};

export const getReasons = () => {
    const url = `/new-ops/labs/get-cancel-reason`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method, null);
}
export const getAhcRequests = (filters, appliedOrderBy, csv) => {

    if (filters.status && filters.status === 'all') {
        filters.status = null;
    }
    if (filters.receiptUrl && filters.receiptUrl === 'all') {
        filters.receiptUrl = null;
    }
    filters.orderBy = appliedOrderBy;
    const urlQuery = new URLSearchParams(filters).toString();

    const url = `/new-ops/ahc/requests/all?${urlQuery}`;
    const method = 'GET';
    if (!csv) {
        return baseRequestWithHeaders(url, method, null);
    }
    return baseRequestWithHeadersFileResponse(url, method, null);
};

export const saveRemark = (orderId, remark) => {
    const url = `/new-ops/labs/orders/remark`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, { orderId, remark });
};

export const getRequests = (filters, appliedOrderBy) => {

    if (filters.status && filters.status === 'all') {
        filters.status = null;
    }
    const urlQuery = new URLSearchParams(filters).toString();

    const url = `/new-ops/labs/requests/all?${urlQuery}`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, { orderBy: appliedOrderBy });
};

export const deleteTranscriptionRequest = (requestId) => {
    const url = `/new-ops/labs-carts/${requestId}/transcription-request`;
    const method = 'DELETE';
    return baseRequestWithHeaders(url, method, null, null);
}

export const uploadReceiptApi = (file, orderId, userId) => {

    const data = new FormData();
    data.append('file', file);
    data.append('orderId', orderId);
    data.append('userId', userId);

    const url = `/new-ops/labs/orders/receipt`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, data);
};

export const getTranscriptionUserDetails = (userId, requestId) => {
    const url = `/new-ops/labs-requests/details/${userId}/${requestId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const getOrderDetails = (orderId) => {
    const url = `/new-ops/labs-orders/details/${orderId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const getUserCart = (userId, cartId) => {
    const url = `/new-ops/labs-requests/cart/${cartId}?userId=${userId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const getUserCartForOrder = (orderId) => {
    const url = `/new-ops/labs-orders/cart/${orderId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const getReasonsForRefund = () => {
    const url = `/new-ops/get-refund-reasons`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
}

export const raiseCashgramReimbursementForAHC  = (data) => {
    const url = `/new-ops/raise-ahc-cashgram-request`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, data);
}

export const refundLabsTestsForOrder = (data) => {
    const url = `/new-ops/refund-order`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, data);
}

export const searchTests = (query, testType) => {
    const url = `/labs/dashboard/all?bookCode=true&s=${query}&labPartner=true&testsType=${testType}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const addTestToCart = (labId, cartId) => {
    const url = `/labs/ops/add-to-cart`;
    const method = 'POST';
    const body = { labId, cartId };
    return baseRequestWithHeaders(url, method, null, body);
};

export const removeTestFromCart = (labId, cartId, cartItemId) => {
    const url = `/labs/ops/remove-from-cart`;
    const method = 'POST';
    const body = { labId, cartId, cartItemId };
    return baseRequestWithHeaders(url, method, null, body);
};

export const getPastConsultations = (userId) => {
    const url = `/new-ops/labs-requests/past-appointments/${userId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const convertToOpdConsult = (cartId, consultationId, doctorId) => {
    const url = `/labs/ops/transcribe/give-opd-benefits`;
    const method = 'POST';
    const data = { cartId, consultationId, doctorId };
    return baseRequestWithHeaders(url, method, null, data);
};

export const convertToOpdConsultForOutOfNetworkConsult = (cartId, requestId, userId, date, doctorName, remarks, centerName, centerAddress) => {
    const url = `/new-ops/labs/lab-cart/give-opd-benefits`;
    const method = 'POST';
    const data = { cartId, requestId, userId, consultationDate: date, doctorName, remarks, centerName, centerAddress };
    return baseRequestWithHeaders(url, method, null, data);
};

export const markLabRequestNotServiceable = (requestId, remark) => {
    const url = `/labs/ops/transcribe/cancel-request`;
    const method = 'POST';
    const data = { requestId, remark };
    return baseRequestWithHeaders(url, method, null, data);
};

export const markCartReady = (requestId) => {
    const url = `/labs/ops/transcribe/save-cart`;
    const method = 'POST';
    const data = { requestId };
    return baseRequestWithHeaders(url, method, null, data);
};

export const getPartnersList = (type) => {
    const url = `/labs/list/partners?type=${type}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method, null);
};

export const addNewPartner = (partnerName, partnerEmails) => {
    const url = `/labs/add/new/partner`;
    const method = 'POST';
    const data = { partnerEmails, partnerName };
    return baseRequestWithHeaders(url, method, null, data);
};

export const addNewTest = (labMrp, labName, labCode, labCost, cartId, partnerId, type) => {
    const url = `/labs/lab-cart/save/new-lab-test`;
    const method = 'POST';
    const data = { labMrp, labName, labCode, labCost, cartId, partnerId, type };
    return baseRequestWithHeaders(url, method, null, data);
};

export const createCartForRadiology = (cartId, requestId) => {
    const url = `/new-ops/create/lab-cart/radiology`;
    const method = 'POST';
    const data = { cartId, requestId };
    return baseRequestWithHeaders(url, method, null, data);
};

export const convertCartToRadiology = (cartId, testsType) => {
    const url = `/labs/update/lab-cart/to-radiology`;
    const method = 'POST';
    const data = { cartId, testsType };
    return baseRequestWithHeaders(url, method, null, data);
};

export const getAvailablePartnersForCart = (orderId) => {
    const url = `/new-ops/labs/available-partners/${orderId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const getAvailablePartnersForCartByCartId = (cartId) => {
    const url = `/new-ops/labs/available-partners-for-cart/${cartId}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
};

export const saveCartRemark = (cartId, remark) => {
    const url = `/new-ops/labs/lab-cart/remark`;
    const method = 'POST';
    const data = { cartId, remark };
    return baseRequestWithHeaders(url, method, null, data);
};

export const createLabOrder = (cartId, userId, bookingEpoch, patient, address, collectionEpoch) => {
    const url = `/new-ops/labs/create-new-order`;
    const method = 'POST';
    const data = { cartId, userId, bookingEpoch, patient, address, collectionEpoch };
    return baseRequestWithHeaders(url, method, null, data);
};

export const editTestInOrder = (orderId, testId, mrp, cost, name) => {
    const url = `/new-ops/labs-orders/test-details/${orderId}`;
    const method = 'POST';
    const data = { testId, mrp, cost, name };
    return baseRequestWithHeaders(url, method, null, data);
};

export const addTestInOrder = (orderId, testId) => {
    const url = `/new-ops/labs-orders/add-test`;
    const method = 'POST';
    const data = { orderId, testId };
    return baseRequestWithHeaders(url, method, null, data);
};

export const cancelOrder = (orderId) => {
    const url = `/new-ops/labs-orders/cancel/${orderId}`;
    const method = 'POST';
    const data = null;
    return baseRequestWithHeaders(url, method, null, data);
};

export const uploadReport = (orderId, file, isPartial) => {
  const url = `/new-ops/labs-orders/report`;
  const data = new FormData();
  data.append('file', file);
  data.append('orderId', orderId);
  data.append('isPartial', isPartial);
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, data);
};

export const changePartnerForCart = (cartId, partnerId) => {
    const url = `/new-ops/labs-carts/change-partner`;
    const method = 'POST';
    const data = { cartId, partnerId };
    return baseRequestWithHeaders(url, method, null, data);
};

export const addExistingTestForPartner = (name, mrp, code, cost, partnerId, type) => {
    const url = `/new-ops/lab-cart/save/add-test-for-partner`;
    const method = 'POST';
    const data = { mrp, name, code, cost, partnerId, type };
    return baseRequestWithHeaders(url, method, null, data);
};

export const requestPayout = (orderId) => {
    const url = `/new-ops/labs-orders/request-single-payout`;
    const method = 'POST';
    const data = { orderId };
    return baseRequestWithHeaders(url, method, null, data);
};

export const getPayoutDetails = (orderId) => {
    const url = `/new-ops/labs-orders/request-single-payout-info`;
    const method = 'POST';
    const data = { orderId };
    return baseRequestWithHeaders(url, method, null, data);
};

export const markOrderCompleteSilently = (orderId) => {
    const url = `/new-ops/labs-orders/mark-complete`;
    const method = 'POST';
    const data = { orderId };
    return baseRequestWithHeaders(url, method, null, data);
};

export const convertToReimbursement = (orderId) => {
    const url = `/new-ops/labs-orders/convert-to-reimbursement`;
    const method = 'POST';
    const data = { orderId };
    return baseRequestWithHeaders(url, method, null, data);
};

export const clickToCallForOrder = (orderId, phone) => {
    const url = `/new-ops/dc-ops/labs-order-call-to-user`;
    const method = 'POST';
    const data = {orderId, phone};
    return baseRequestWithHeaders(url, method, null, data);
};

export const clickToCallForAHCRequests = (ahcRequestId, phone) => {
    const url = `/new-ops/dc-ops/labs-order-call-to-user`;
    const method = 'POST';
    const data = {ahcRequestId, phone};
    return baseRequestWithHeaders(url, method, null, data);
};

export const fetchSlots = () => {
    const url = '/new-ops/labs/slots';
    const method = 'GET';
    return baseRequestWithHeaders(url, method, null, null);
};

export const changeOrderStatus = (orderId, body) => {
    const url = `/new-ops/labs-orders/${orderId}/change-status`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, body);
};

export const updateVendorHc = (orderId, hc) => {
    const url = `/new-ops/dc-ops/update-handling-charge`;
    const method = 'POST';
    const body = {
        orderId, hc,
    };
    return baseRequestWithHeaders(url, method, null, body);
};

export const getTransactionHistory = (orderId) => {
    const url = `/new-ops/labs-orders/${orderId}/transaction-history`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const viewPayoutDetails = (orderId) => {
    const url = `/new-ops/labs-orders/${orderId}/payoutInfo`
    const method = 'GET';
    return baseRequest(url, method, null, null);
}

export const getAddressProof = (orderId) => {
    const url = `/labs-v2/address-proof-new/${orderId}`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
}

export const getTrudeskTickets = (orderId) => {
    const url = `/ticketing/get-tickets-for-group-and-sourceId?`;
    const method = 'GET';
    const params = {orderId, group: 'labs'}
    return baseRequest(url, method, params, null);
};

export const editAhcRequest = (requestId, patientId, addressId, preferredDate) => {
    const url = `/new-ops/ahc/requests/edit-request`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, { patientId, addressId, preferredDate, requestId});
};

export const cancelAhcRequest = (requestId) => {
    const url = '/new-ops/ahc/requests/cancel-request';
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, {requestId});
}

export function markSampleCollectedForBulkOrders(orderIds) {
    const url = '/new-ops/labs-orders/mark-bulk-sample-collection';
    const method = 'POST';
    return baseRequest(
      url,
      method,
      null,
      { orderIds }
    );
}

export const viewCommentRequest = (orderId) => {
    const url = `/new-ops/labs-orders/comments/${orderId}/requests`;
    const method = 'GET';
    const data = {};
    return baseRequest(url, method, null, data);
  };
  
  export const storeCommentRequest = (orderId, comment, type) => {
    const url = `/new-ops/labs-orders/comments/${orderId}/requests`;
    const method = 'POST';
    const data = {
      comment,
      type,
    };
    return baseRequest(url, method, null, data);
  };

export const getReimbursementCarts = (filters) => {
    if (filters.status && filters.status === 'all') {
        filters.status = null;
    }
    const urlQuery = new URLSearchParams(filters).toString();
    const url = `/new-ops/carts/all?${urlQuery}`;
    const method = 'GET';
    return baseRequestWithHeaders(url, method);
}

export const changeCartReimbursementStatus = (cartId, status) => {
    const url = '/new-ops/markCartForReimbursement';
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, {cartId, status}); 
}

export const renewCartAPI = (cartId) => {
    const url = '/new-ops/renewCart';
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, {cartId}); 
}
