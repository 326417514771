import React, { Component } from 'react';

import { fetchAllSponsors } from '../../../services/api/sponsors';

export default class SponsorFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: []
    };
  }

  handleChange = event => {
    const value = event.target.value;
    this.props.onChangeValue(value);
  };

  getSponsors = () => {
    return fetchAllSponsors()
        .then((response) => {
          console.log(response, 'Response from server');
          this.setState({
            sponsors: response.data,
          });
        })
        .catch(() => {
            this.setState({
                sponsors: [],
            });
        });
  };

  componentDidMount() {
    this.getSponsors();
  }

  render() {
    const { sponsors } = this.state;
    return (
      <div>
        <select
          onChange={this.handleChange}
          style={{
            width: '100%',
            height: '40px',
            fontSize: '12px',
          }}
          value={this.props.value}
        >
          <option value="">All</option>
          {sponsors && sponsors.map(s => (<option key={s.sponsorId} value={s.sponsorId}>{s.sponsorName}</option>))}
        </select>
      </div>
    );
  }
}
