import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../common/StyledTappable';
import { OuterComponent, customStyles } from '../common/modal/StyledComponent';

const CloseModal = styled(FaTimes)``;

const HeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
`;

class ProductImage extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <Modal isOpen toggle={this.props.closeModal} style={customStyles}>
        <OuterComponent>
          <HeaderComponent>
            <h3>Product Image</h3>
            <StyledTappable onTap={this.props.closeModal}>
              <CloseModal />
            </StyledTappable>
          </HeaderComponent>
          <ModalBody>
            <img src={this.props.fileUrl} style={{ width: 450, height: 300 }} />
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.props.closeModal}>Cancel</Button>
          </ModalFooter>
        </OuterComponent>
      </Modal>
    );
  }
}

export default ProductImage;
