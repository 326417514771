import { baseRequestWithHeaders } from './baseRequest';

export const storeCommentRequest = (requestId, comment, type) => {
    const url = `/new-ops/comments/${requestId}/requests`;
    const method = 'POST';
    const data = {
        comment,
        type,
    };
    return baseRequestWithHeaders(url, method, null, data);
};
