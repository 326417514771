import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
  Card,
  Row,
  Col,
  // Typography,
  // Checkbox,
  Badge,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { BoldText } from '../common/Text';
import { getSpecialistSuggestions } from '../../services/api/suggestions';
import { getVerticals } from '../../services/api/offline-consult';
// const { Text } = Typography;

class SuggestionsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      disableSubmit: false,
      selectedVertical: null,
      visitOnly: 1,
      latitude: null,
      longitude: null,
      radius: 5,
      collapse: false,
      suggestions: [],
      requestId: '',
      hits: 0,
      time: 0,
      limit: 10,
      verticals: [],
    };
  }

  // componentDidMount() {
  //   this.onModalOpened();
  //   console.log({ props: this.props });
  // }

  onModalOpened = () => {
    console.log({ props: this.props });
    if (this.props.suggestionsDetails) {
      this.setState({
        latitude: this.props.suggestionsDetails.latitude,
        longitude: this.props.suggestionsDetails.longitude,
      });
    }
    this.getVerticals();
  };

  getVerticals = () => {
    getVerticals()
      .then(response => {
        const arr = [];
        response.data.forEach(element => {
          arr.push({ value: element.verticalId, label: element.verticalName });
        });
        this.setState({ verticals: arr });
        if (
          this.props.suggestionsDetails &&
          this.props.suggestionsDetails.verticalId
        ) {
          this.setDefaultVertical(this.props.suggestionsDetails.verticalId);
        }
      })
      .catch(err => {
        console.log({ err });
      });
  };

  setDefaultVertical = verticalId => {
    console.log(this.props.suggestionsDetails, 'Details when mounting');
    const { verticals } = this.state;
    let defVer = null;
    verticals.forEach(v => {
      if (v.value === verticalId) {
        defVer = {
          ...v,
        };
      }
    });
    this.setState({
      selectedVertical: defVer,
    });
  };

  validateSuggestionsPayload = () => {
    let result = '';
    if (!this.state.latitude) {
      result += ' Latitude';
    }
    if (!this.state.longitude) {
      result += ' Longitude';
    }
    if (!this.state.selectedVertical) {
      result += ' Speciality';
    }
    if (result.length > 0) {
      return result;
    }
    return false;
  };

  showSuggestions = () => {
    console.log(this.state, 'Current State');
    // validate
    const result = this.validateSuggestionsPayload();
    if (result) {
      alert(`Complete all details: ${result}`);
      return;
    }
    // disable submit button
    this.setState({
      // disableSubmit: true,
      collapse: false,
    });
    let filters;
    // speciality clause
    filters = `v=${this.state.selectedVertical.value}&lat=${
      this.state.latitude
    }&lng=${this.state.longitude}&o=${this.state.visitOnly ? 1 : 0}&r=${
      this.state.radius
    }&l=${this.state.limit}`;
    getSpecialistSuggestions(filters)
      .then(data => {
        console.log(data, 'Suggestions');
        // checking for suggestions
        if (data.body.message === 'success') {
          // enabling submit button
          this.setState({
            collapse: true,
            disableSubmit: false,
            suggestions: data.body.results,
            hits: data.body.hits,
            time: data.body.time,
          });
          return;
        }
        alert('Something went wrong');
      })
      .catch(err => {
        console.log(err, 'Fetch Communications');
      });
  };

  toggleParentModal = () => {
    // reset the message checks
    this.setState({
      collapse: false,
      choice: 1,
    });
    // call the parent method
    this.props.closeSuggestions();
  };

  listAllVerticals = () => {
    console.log(this.state.verticals);
    const verticals = this.state.verticals;
    const options = [];
    if (verticals) {
      verticals.forEach(v => {
        options.push({
          value: v.id,
          label: v.name,
        });
      });
    }
    return options;
  };

  handleChange = selectedVertical => {
    this.setState({ selectedVertical });
    console.log(`Option selected:`, selectedVertical);
  };

  handleCheck = e => {
    this.setState({
      visitOnly: e.target.checked,
    });
  };

  handleLatitudeChange = e => {
    const { value } = e.target;
    this.setState({
      latitude: value,
    });
  };

  handleLongitudeChange = e => {
    const { value } = e.target;
    this.setState({
      longitude: value,
    });
  };

  handleChangeRadius = e => {
    const { value } = e.target;
    this.setState({
      radius: value,
    });
  };

  handleLimitChange = e => {
    const { value } = e.target;
    this.setState({
      limit: value,
    });
  };

  render() {
    return (
      <Modal
        onOpened={this.onModalOpened}
        isOpen={this.props.isOpen}
        onCancel={this.toggleParentModal}
        toggle={this.toggleParentModal}
      >
        <ModalHeader toggle={this.toggleParentModal}>
          <BoldText style={{ color: '#47464a' }}>Suggestions</BoldText>
        </ModalHeader>
        <ModalBody>
          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Label for="forLatitude">Latitude</Label>
              <Input
                type="text"
                name="latitude"
                id="forLatitude"
                value={this.state.latitude}
                placeholder="28.55667"
                onChange={this.handleLatitudeChange}
              />
            </Col>
            <Col span={6}>
              <Label for="forLongitude">Longitude</Label>
              <Input
                type="text"
                name="longitude"
                id="forLongitude"
                value={this.state.longitude}
                placeholder="78.33225"
                onChange={this.handleLongitudeChange}
              />
            </Col>
            <Col span={12}>
              <Label for="selectVertical">Speciality</Label>
              <Select
                value={this.state.selectedVertical}
                onChange={this.handleChange}
                options={this.state.verticals}
                id={'selectVertical'}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={6}>
              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Label for="checkbox">Visit Onboarded Only</Label>
              </Row>
              <Row style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Input
                  type="checkbox"
                  id="checkbox"
                  onChange={this.handleCheck}
                  checked={this.state.visitOnly}
                />
              </Row>
            </Col>
            <Col span={8}>
              <Label for="forLimit">No. of suggestions</Label>
              <Input
                type="text"
                name="limit"
                id="forLimit"
                value={this.state.limit}
                placeholder="10"
                onChange={this.handleLimitChange}
              />
            </Col>
            <Col span={8}>
              <Label for="forRadius">Radius(in km)</Label>
              <Input
                type="text"
                name="radius"
                id="forRadius"
                value={this.state.radius}
                onChange={this.handleChangeRadius}
              />
            </Col>
          </Row>

          <Row>
            <Button
              style={{ marginLeft: 20 }}
              type="primary"
              disabled={this.state.disableSubmit}
              onClick={this.showSuggestions}
            >
              Show Suggestions
            </Button>
          </Row>

          <br />

          <Row>
            <Col>
              {this.state.collapse && (
                <Col isOpen={this.state.collapse}>
                  {this.state.suggestions.length} results found in{' '}
                  {this.state.time} ms
                  <Col>
                    <ListGroup>
                      {this.state.suggestions.length === 0 ? (
                        <ListGroupItem>No Suggestions found</ListGroupItem>
                      ) : (
                        this.state.suggestions.map(m => {
                          return (
                            <ListGroupItem>
                              <Row>
                                <Col span={6}>
                                  <b>{`${m.doctorName}`}</b>
                                  <br />
                                  {m.verticalName}
                                  <br />
                                  {`${m.centerName}, ${m.centerAddress}`}
                                  <br />
                                </Col>
                                <Col span={6}>
                                  <Badge color="grey" pill>
                                    {m.source}
                                  </Badge>
                                  <br />
                                  <b>Charges:</b> Rs. {m.charges}
                                  <br />
                                  <b>Discount:</b> {m.discount}
                                  <br />
                                  <b>Experience:</b> {m.experience} years
                                  <br />
                                  <b>Distance: </b> {m.distance} km
                                  <br />
                                  <b>Onboarding Status: </b>{' '}
                                  {m.onboardingStatus}
                                  <br />
                                  <b>Spoc Phone: </b> {m.spocPhone}
                                </Col>
                              </Row>
                            </ListGroupItem>
                          );
                        })
                      )}
                    </ListGroup>
                  </Col>
                </Col>
              )}
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default withRouter(SuggestionsDialog);
