import React from 'react';

import Modal from '../common/Modal';
import Link from 'valuelink';
import { Input } from 'valuelink/tags';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { createNewSponsor } from '../../services/api/sponsor-management';
import { updateUserSponsor } from '../../services/api/sponsor-management';
import { fetchAllSponsors  } from '../../services/api/sponsors';

class SponsorManagementCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsorName: '',
      isOpen: false,
      loading: false,
      opdActiveCheck: null,
      gmcActiveCheck: null,
      phone: '',
      email: '',
      currentModal: '',
      searchData:null,
      filterSponsorData:[],
      sponsors: [],
      sponsorId:null
    };
  }
  onClose = () => {
    this.setState({
      isOpen: false,
      sponsorName: '',
    });
  };
  handleChange = (event) => {
    this.setState({ sponsorId: event.value, selectedOption: event });
  };

  handleCreateSponsor = () => {
    const { sponsorName } = this.state;
    if (!sponsorName) {
      return;
    }

    return createNewSponsor(sponsorName)
      .then((response) => {
        if (response.message === 'success') {
          toast.success('Created a sponsor');
          this.onClose();
          this.props.onComplete();
          return;
        }
        throw new Error(
          response.errorMessage || 'Error while creating a sponsor'
        );
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  handleOpenModal = () => {
    this.setState({
      isOpen: true,
      currentModal: 'create',
    });
  };
  handleOpenModalSecond = () => {
    this.setState({
      isOpen: true,
    currentModal: 'update',  
    });
    this.search()
  };

  onConfirm = () => {
    const opdIsActiveCheck = this.state.opdActiveCheck;
    const gmcIsActiveCheck = this.state.gmcActiveCheck;
    const phone = this.state.phone;
    const email = this.state.email;
    const sponsor= this.state.sponsorId;

    this.setState({
      loading: true,
    });
    return updateUserSponsor(phone, email, opdIsActiveCheck, gmcIsActiveCheck,sponsor)
      .then((response) => {
        console.log(response);
        if (response.message === 'success') {
          toast.success(`Details Updated`);
          this.onClose();
        } else {
          throw new Error(
            response.errorMessage || 'Not able to update relative phone number'
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      })
  };

  search(){
   // console.log(key)
    return fetchAllSponsors ()
    //fetch(`new-ops/sponsor-management/get-all-sponsor?sponsorName=${key}`)
    .then((response) => {
      // console.log("daaata--",response.data)
        const sponsors = response.data.map((sponsor) => {
          return {
            value:sponsor.sponsorId,
            label:sponsor.sponsorName
          }
        });
          this.setState({
              sponsors
          });
  })
  .catch((err) => {
    this.setState({
      sponsors: [],
    });
      console.error(err, 'Error while fetching sponsors');
      toast.error(err.message);
  })  
  
  }
   render() {
    const sponsorNameLink = Link.state(this, 'sponsorName');
    return (
      <React.Fragment>
        <button className="btn btn-primary" onClick={this.handleOpenModal}>
          Create Sponsor
        </button>
        &nbsp;
        &nbsp;
        &nbsp;
        <button
          className="btn btn-primary"
          onClick={this.handleOpenModalSecond}
        >
          Update Sponsor
        </button>
        {this.state.isOpen && this.state.currentModal === 'create' && (
          <Modal
            isOpen={this.state.isOpen}
            onClose={this.onClose}
            header={'Create a new Sponsor'}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <label>Sponsor Name</label>
                </div>
              </div>
              <div className="row mb-1">
                <div className="col">
                  <Input class="form-control" valueLink={sponsorNameLink} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <button
                    className="btn btn-success btn-block"
                    onClick={this.handleCreateSponsor}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {this.state.isOpen && this.state.currentModal === 'update' && (
          <Modal
            isOpen={this.state.isOpen}
            onClose={this.onClose}
            header={'Update Sponsor '}
          >
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <label>Phone Number</label>
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => {
                      this.setState({ phone: e.target.value });
                    }}
                    value={this.state.name}
                    placeholder="Phone"
                  ></input>
                  <br></br>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Email</label>
                </div>
                <div className="col">
                  <input
                    type="email"
                    id="email"
                    className="form-control"
                    name="email"
                    placeholder="Email (Optional)"
                    onChange={(e) => {
                      this.setState({ email: e.target.value });
                    }}
                    value={this.state.email}
                  ></input>
                  <br></br>
                </div>
              </div>
              <div className="row" >
                <div className="col">
                  <label>Sponsors</label>
                </div>
                <div className="col" style={{
              width: '20%',
              height: '40px',
              fontSize: '12px',

            }}>
                  <Select 
                     onChange={this.handleChange}
                    placeholder="Select sponsors"
                   options={this.state.sponsors}
                   value={this.state.selectedOption} />
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col">
                  <input
                    type="radio"
                    name="checkOpdActive"
                    onChange={(e) => {
                      this.setState({ opdActiveCheck: e.target.value });
                    }}
                    value={1}
                  ></input>
                  <label for="html">Active OPD </label>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      type="radio"
                      name="checkgmcActive"
                      onChange={(e) => {
                        this.setState({ gmcActiveCheck: e.target.value });
                      }}
                      value={1}
                    ></input>
                      <label for="html">Active GMC</label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <input
                    type="radio"
                    name="checkOpdActive"
                    onChange={(e) => {
                      this.setState({ opdActiveCheck: e.target.value });
                    }}
                    value={0}
                  ></input>
                    <label for="html">Inactive OPD </label>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      type="radio"
                      name="checkgmcActive"
                      onChange={(e) => {
                        this.setState({ gmcActiveCheck: e.target.value });
                      }}
                      value={0}
                    ></input>
                      <label for="html">Inactive GMC</label>
                  </div>
                </div>
              </div>
              <br></br>
              <div className="row justify-center">
                <div className="col-4">
                  <button
                    className="btn btn-block btn-success"
                    onClick={this.onConfirm}
                  >
                    Update
                  </button>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-block btn-danger"
                    onClick={this.onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

export default SponsorManagementCreate;
