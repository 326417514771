export const defaultState = {
  operatorId: -1,
  name: null,
  userName: null,
  operatorType: -1,
  token: null,
  userId: null,
  phone: null,
  showLabs: false,
  showPayments: false,
  showOps: false,
  showSupport: false,
  editUsersDetails: false,
  home: null,
  labPartnerId: -1,
  labPartnerInfo: {
    labType: null,
    partnerId: -1,
    partnerName: null,
    userType: null,
  },
};

export default function user(state = defaultState, action) {
  switch (action.type) {
    case 'SET_USER_INFO':
      return Object.assign({}, state, action.payload.userInfo);
    case 'UNSET_USER_INFO':
      return Object.assign({}, defaultState);
    case 'SET_LAB_PARTNER_INFO':
      return Object.assign({}, state, {
        labPartnerInfo: action.payload,
      });
    case 'SET_AUTH_TOKEN':
      return Object.assign({}, state, {
        token: action.payload,
      });
    case 'UNSET_AUTH_TOKEN':
      return Object.assign({}, state, {
        token: null,
      });
    default:
      return state;
  }
}
