import React from 'react';

import { Container, ContainerItem } from './StyledComponents';

import HistoryContainer from './HistoryContainer';
import PolicyDetails from '../../policy-details/PolicyDetails';
import Prescription from '../../prescription-details/Prescription';


export default class SubComponent extends React.Component {
    render() {
        const { orderId, userId } = this.props;
        return (
            <Container>
                <ContainerItem style={{ width: "300px" }}>
                    <HistoryContainer orderId={orderId} />
                </ContainerItem>
                {/* <ContainerItem style={{ width: "500px" }}>
                    <PolicyDetails userId={userId} />
                </ContainerItem>
                <ContainerItem>
                    <Prescription orderId={orderId} />
                </ContainerItem> */}
            </Container>
        );
    }
}
