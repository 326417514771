import React, {Component} from 'react';
import { FaRegPlusSquare, FaTrash } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { fetchAllPartners, changePartnerActivity, removePartner } from '../../services/api/lab-partners';
import { toast } from 'react-toastify';
import { addLab } from '../../services/api/pool-manager';
import moment from 'moment';
import { connect } from 'react-redux';

import { FaVial, FaPencilAlt, FaWarehouse, FaCentercode } from 'react-icons/fa';
import { PageHeader } from "../common/PageHeader";

import ModalConfirm from '../common/ModalConfirm';
import StyledModal from '../common/Modal';

import ReactTable from "react-table";
import { activeDropDownFilterMethod } from '../../utils/react-table';
import { setLabListingFilter } from '../../actions';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};
class LabsListing extends Component {

    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            loading: false,
            labName: '',
            commonName: '',
            active: '',
            fasting: '',
            type: null,
            testTypeDropDown: false,
            selectedType: 'Select Type',
            modalConfirm: {
                isOpen: false,
                message: '',
                onConfirm: () => {},
                onClose: () => {}
            },
            showModal: false,
        };
    }
    searchFilterMethod = (filter, row) => {
        const dispatch = this.props.dispatch;
        let filterChange = 0;
        const setFilter = this.props.labPartner.filter.map(element => {
         console.log({ element }, '[searchFilterMethod]');
         const filterData = Object.assign({}, element);
         if (filterData.id === filter.id && filterData.value !== filter.value ) {
            filterChange = 1;
            filterData.value = filter.value;
         }
         return filterData;
        });
        if (filterChange) {
            dispatch(setLabListingFilter(setFilter));
        }
        console.log({filter, row}, 'Inside Filter');
        if (!row[filter.id]) {
            return false;
        }
        return row[filter.id].toString().toLowerCase().startsWith(filter.value.toString().toLowerCase()) || row[filter.id].toString().toLowerCase().endsWith(filter.value.toString().toLowerCase());
    };
    getLabs = () => {
        this.setState({
            loading: true,
        }); 
        return fetchAllPartners()
            .then((response) => {
                if (response.body.message === 'success') {
                    this.setState({
                        partners: response.body.partners,
                    });
                } else {
                    toast.error(`Error in adding new partner`);
                }
            })
            .catch((err) => {
                toast.error(`Error in listing all partners`, err);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    componentDidMount() {
        this.getLabs();
    }

    handleActivityButtonClick = (partnerId, partnerName, newActive) => {
        this.setState({
            modalConfirm: {
                isOpen: true,
                message: `Are you sure you mark ${partnerName} as ${newActive ? 'active' : 'inactive'}?`,
                onConfirm: () => {
                    return changePartnerActivity(partnerId, newActive)
                        .then((response) => {
                            if (response.body.message === 'success') {
                                toast.success(`Successfully marked ${partnerName} as ${newActive ? 'active' : 'inactive'}`);
                                return this.getLabs();
                            } else {
                                throw new Error(response.body.errorMessage || 'Failed to update the partner');
                            }
                        })
                        .catch((err) => {
                            toast.error(err.message);
                            return Promise.reject(err);
                        });
                },
                onClose: () => {
                    this.setState({
                        modalConfirm: {
                            isOpen: false,
                            message: '',
                            onConfirm: () => {},
                            onClose: () => {}
                        }
                    });
                },
            }
        })
    };

    handleTrashButtonClick = (partnerId, partnerName)=>{
        this.setState({
            modalConfirm: {
                isOpen: true,
                message: `Are you sure you want to delete "${partnerName}" from lab partners}?`,
                onConfirm: () => {
                    return removePartner(partnerId)
                        .then((response) => {
                            if (response.body.message === 'success') {
                                toast.success(`Successfully deleted partner: ${partnerName}`);
                                return this.getLabs();
                            } else {
                                throw new Error(response.body.errorMessage || 'Failed to update the partner');
                            }
                        })
                        .catch((err) => {
                            toast.error(err.message);
                            return Promise.reject(err);
                        });
                },
                onClose: () => {
                    this.setState({
                        modalConfirm: {
                            isOpen: false,
                            message: '',
                            onConfirm: () => {},
                            onClose: () => {}
                        }
                    });
                },
            }
        })
    }

    getColumns = () => {
        return [{
            accessor: 'id',
            Header: 'Id',
            className: 'text-center',
            width: 50,
            Cell: (row) => (
                <div
                    className={(row.original.apiEnabled) ? null : "clickable"}
                    onClick={() => {
                        if (!row.original.apiEnabled) {
                            this.props.history.push(`/lab-partner-details/edit/${row.original.id}`)
                        }
                    }}
                >
                    {row.original.id}
                </div>
            )
        }, {
            accessor: 'name',
            Header: 'Name',
            filterable: true,
            filterMethod: this.searchFilterMethod,
        }, {
            accessor: 'opsDisplayName',
            Header: 'OPS Display Name',
            filterable: true,
            filterMethod: this.searchFilterMethod,
        }, {
            accessor: 'type',
            Header: 'Type',
            className: 'text-center',
            width: 100,
        }, {
            accessor: 'clientCode',
            Header: 'Client Code',
            className: 'text-center',
            width: 100,
        }, {
            accessor: 'tds',
            Header: 'TDS',
            className: 'text-center',
            width: 100,
        },{
            accessor: 'priority',
            Header: 'Priority',
            className: 'text-center',
            width: 100,
        },{
            Header: 'API Enabled',
            Cell: (row) => ((row.original.apiEnabled === 0) ? 'No' : 'Yes'),
            className: 'text-center',
            width: 70,
        }, {
            Header: 'Active',
            Cell: (row) => ((row.original.active === 0) ? 'No' : 'Yes'),
            className: 'text-center',
            accessor:'active',
            width: 70,
            filterable: true,
            filterMethod: activeDropDownFilterMethod,
            Filter: ({ filter, onChange }) => {
                        return (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : ''}
                            >
                                <option value="">Show All</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                            </select>
                        );
                    },
        }, {
            Header: 'Created On',
            className: 'text-center',
            Cell: (row) => (moment(row.original.createdAt).format('LLL')),
        }, {
            Header: 'Action',
            className: 'text-center',
            Cell: (row) => {
                const { id } = row.original;
                return (
                    (!row.original.apiEnabled) && (
                        <div className="container-fluid">
                          <div className="float-left">
                            <span title="Edit Fulfilment" className="mb-1">
                                <FaPencilAlt className="mt-2 mr-1 clickable" onClick={() => this.props.history.push(`/lab-partner-details/${id}`)} />
                            </span>
                            <span title="Tests" className="mb-1">
                                <FaVial className="mt-2 mr-1 clickable" onClick={() => this.props.history.push(`/lab-partner-details/${id}/tests`)} />
                            </span>
                            <span title="Centers" className="mb-1">
                                <FaWarehouse className="mt-2 mr-1 clickable" onClick={() => this.props.history.push(`/lab-partner-details/${id}/centers`)} />
                            </span>
                            <span title="Pincodes" className="mb-1">
                                <FaCentercode className="mt-2 mr-1 clickable" onClick={() => this.props.history.push(`/lab-partner-details/${id}/pincodes`)} />
                            </span>
                            <span title="delete partner" className="mb-1">
                                <FaTrash   className="mt-2 mr-1 clickable" onClick={() => this.handleTrashButtonClick(id,row.original.opsDisplayName)} />
                            </span>
                          </div>
                            <span className="mb-1 float-right">
                                 <button className={`btn btn-${(row.original.active) ? 'danger' : 'success'}`} onClick={() => (this.handleActivityButtonClick(id, row.original.opsDisplayName, !(row.original.active)))}>{(row.original.active) ? 'Inactive' : 'Active'}</button>
                            </span>
                        </div>
                    )
                );
            }
        }];
    };

     onSubmitHandler = (e) => {
        e.preventDefault();
        const { labName, commonName, active, fasting, type } = this.state;
        if (!type) {
            toast.error('Please select type');
        } else {
            addLab(labName, commonName, active, fasting, type)
            .then((res) => {
                console.log(res, '[onSubmitHandler][addLab][res]');
                if (res.message === 'success') {
                    this.setState({ showModal: false });
                    toast.success('Test added sucessfully');
                } else {
                    toast.error('Unable to add test');
                    toast.error(res.errorMessage);
                }
            })
            .catch((err) => {
                console.log(err, '[addLab][err]');
            });
        }
    }

    addLabTests = () => {
        this.setState({ showModal: true });
    }

    toggleTestTypeDropdown = () => {
        this.setState({ testTypeDropDown: !this.state.testTypeDropDown });
    }

    render() {
        const { partners, loading, showModal } = this.state;
        console.log({ssd:this.props.labPartner.filter});
        return (
            <React.Fragment>
                <div className="row pt-2">
                    <div className="col-2">
                        <PageHeader>All Partners</PageHeader>
                    </div>
                    <div className="col-2 pt-3 text-right">
                        <button className="btn btn-primary" onClick= {this.addLabTests}>Add Lab tests</button>
                    </div>
                    <div className="col pt-3 text-right">
                        <button className="btn btn-primary" onClick={() => this.props.history.push(`/lab-partner-details/new`)}><FaRegPlusSquare />&nbsp;Create Partner</button>
                    </div>
                </div>
                <div>
                    {showModal && (
                    <StyledModal
                        isOpen={true}
                        style={customStyles}
                        shouldCloseOnOverlayClick={true}
                        header={(<p>Add Test</p>)}
                        children={(
                            <form onSubmit={this.onSubmitHandler}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col">
                                            <p>Enter labName</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input
                                            className="form-control"
                                            onChange = {(e) => 
                                                this.setState({ labName: e.target.value })}
                                            value={this.state.labName}
                                            required
                                            />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col">
                                            <p>Enter commonName</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input
                                            className="form-control"
                                            onChange = {(e) => 
                                                this.setState({ commonName: e.target.value })}
                                            value={this.state.commonName}
                                            />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col">
                                            <p>Fasting</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <input
                                            className="form-control"
                                            onChange = {(e) => 
                                                this.setState({ fasting: e.target.value })}
                                            value={this.state.fasting}
                                            />
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col">
                                            <Dropdown isOpen={this.state.testTypeDropDown} toggle={this.toggleTestTypeDropdown}>
                                                <DropdownToggle caret>
                                                    {this.state.selectedType}
                                                </DropdownToggle>

                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => {this.setState({ type: 'pathology', selectedType: 'Pathology' })}}>Pathology</DropdownItem>
                                                    <DropdownItem onClick={() => {this.setState({ type: 'radiology', selectedType: 'Radiology' })}}>Radiology</DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div className="row">
                                        <div className="col">
                                        <button 
                                        className="col btn btn-success btn-block"
                                        >
                                            Add
                                        </button>
                                        </div>
                                        <div className="col">
                                        <button 
                                        className="col btn btn-danger btn-block"
                                        onClick={() => this.setState({ showModal: false })}
                                        >
                                            Cancel
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                     )}
                </div>
                <ReactTable
                    data={partners}
                    columns={this.getColumns()}
                    showPaginationTop={false}
                    filterable={false}
                    defaultPageSize={20}
                    loading={loading}
                    defaultFiltered={this.props.labPartner.filter}
                    // className="-striped -highlight"
                    sortable={false}
                />
                <ModalConfirm
                    message={this.state.modalConfirm.message}
                    isOpen={this.state.modalConfirm.isOpen}
                    onConfirm={this.state.modalConfirm.onConfirm}
                    onClose={this.state.modalConfirm.onClose}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    labPartner: state.labPartner,
});

export default withRouter(connect(mapStateToProps)(LabsListing));





