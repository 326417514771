import axios from 'axios';
import config from '../../../config.js';
import { store } from '../../../store/index.js';

const redirectForLogin = () => {
    window.location.href = '/';
   }
   
   axios.interceptors.response.use((response) => response,  (error) => {
     const statusCode = error.response.data.code;
     console.log(statusCode);
     return statusCode === 401 ?  redirectForLogin() : Promise.reject(error);
   });

export function uploadPrescriptionForMed(
    userId,
    requestData,
    onUploadProgress
) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }
    return axios
        .post(
            `${config.pharmacyService}/ops/digitisation/add-multiple-files?userId=${userId}`,
            requestData,
            {
                headers,
                onUploadProgress,
            }
        )
        .then((resp) => {
            const responseBody = resp.data;
            if (responseBody.message === 'success') {
                return responseBody;
            } else {
                const err = new Error('Invalid response');
                err.data = responseBody;
                throw err;
            }
        })
        .catch((err) => {
            throw err;
        });
}

export function removeFileFromPrescriptionMeds(
    fileId,
    prescriptionId,
) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }

    return axios
        .post(
            `${config.pharmacyService}/ops/digitisation/prescriptions/file/remove`,
            { prescriptionId, fileId },
            {
                headers,
            }
        )
        .then((res) => {
            const responseBody = res.data;
            if (responseBody.message === 'success') {
                return responseBody;
            } else {
                const err = new Error('Invalid response');
                err.data = responseBody;
                throw err;
            }
        })
        .catch((err) => {
            throw err;
        });
}

export function submitDigitsationRequestMeds(
    pharmacyPrescriptionId,
    addressId,
    patientId,
    userId
) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }
    return axios
        .post(
            `${config.pharmacyService}/ops/digitisation/submit-request?userId=${userId}`,
            {
                pharmacyPrescriptionId,
                addressId,
                patientId,
            },
            {
                headers,
            }
        )
        .then((resp) => {
            const responseBody = resp.data;
            if (responseBody.message === 'success') {
                return responseBody;
            } else {
                const err = new Error('Invalid response');
                err.data = responseBody;
                throw err;
            }
        })
        .catch((err) => {
            throw err;
        });
}

export function uploadPrescriptionForMedDashboard(userId) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }

    return axios
        .get(`${config.pharmacyService}/ops/digitisation/upload-prescription?userId=${userId}`, {
            headers,
        })
        .then((resp) => {
            const responseBody = resp.data;
            if (responseBody.message === 'success') {
                return responseBody;
            } else {
                const err = new Error('Invalid response');
                err.data = responseBody;
                throw err;
            }
        })
        .catch((err) => {
            throw err;
        });
}

export function getUserInfoByPhone(phone) { 
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }
    return axios
        .get(`${config.pharmacyService}/ops/userInfo/phone/${phone}`, {
            headers,
        })
        .then((resp) => {
            const responseBody = resp.data;
            return responseBody;
        })
        .catch((err) => {
            throw err;
        });
}
export function getDigitisationId(orderId) {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
        headers.authorization = state.user.token;
    }
    return axios
    .get(`${config.pharmacyService}/ops/digitisationId/${orderId}`, {
        headers,
    })
    .then((resp) => {
        const responseBody = resp.data;
        return responseBody;
    })
    .catch((err) => {
        throw err;
    });
}

export async function getCartsFromDigitisationId(digitisationId){
    try {
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
        const { data : responseBody } = await axios.get(`${config.pharmacyService}/selectPartner/digitisationId/${digitisationId}`, {headers})
        if (responseBody.message === 'success') {
            return responseBody;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
} 


export async function modifyCartData(cartId , cartData) {
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.post(`${config.pharmacyService}/cart/prescription/cartId/${cartId}/modify`, {items : cartData },{headers})
         if (responseBody.message === 'success') {
            return responseBody;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}

export async function getCartDetails(cartId){
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
         const { data : responseBody } = await axios.get(`${config.pharmacyService}/cart/prescription/cartId/${cartId}`, {headers})
         if (responseBody.message === 'success') {
            return responseBody;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}

export async function placeFailedOrderCart(failedOrderId, useCartId){
    try {
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
        const responseBody = await axios.post(`${config.pharmacyService}/ops/fix-failed-order`, {"useCartId" : useCartId , "failedOrderId" : failedOrderId}, { headers });
        return responseBody.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        } else if (err.request) {
            return { message: 'No response received from the server' };
        } else {
            return { message: 'Error during request setup' };
        }
    }
}

export async function placeOrderByCartId(cartId){
    try{
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
        const { data : responseBody }  = await axios.post(`${config.pharmacyService}/ops/place-order-by-cartId?cartId=${cartId}`, {},{headers});
         if (responseBody.message === 'success') {
            return responseBody;
        } else {
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
        }
    }catch(err){
        return err
    }
}
export async function cancelAndRefundOrder(orderId) {
    try {
        const headers = {};
        const state = store.getState();
        if (state.user.token) {
            headers.authorization = state.user.token;
        }
        const response = await axios.post(`${config.pharmacyService}/ops/cancel-and-refund-order?orderId=${orderId}`, {}, { headers });
        return response.data;
    } catch (err) {
        if (err.response) {
            return err.response.data;
        } else if (err.request) {
            return { success: false, message: 'No response received from the server' };
        } else {
            return { success: false, message: 'Error during request setup' };
        }
    }
}
