import baseRequest from './baseRequest';

export const sendCommunications = (data) => {
    const url = "/new-ops/communications";
    const method = "post";
    console.log('sendCommunications data: ',data);
    return baseRequest(url, method, null, data)
};

export const fetchCommunications = (requestId) => {
    const url = `/new-ops/communications/${requestId}`;
    const method = "GET";
    return baseRequest(url, method, null, null)
};

export const fetchPreviewMailer = (choice, requestId) => {
    const url = `/new-ops/communications/preview/${requestId}/${choice}`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
}
