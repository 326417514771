import React, { useRef, useState } from 'react';
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import ReactTable from 'react-table';
import { connect } from 'react-redux';
import { OuterComponent, PaddedContainer } from '../customer-support/StyledComponent';
import { SearchBox } from '../cashless-vaccination/styled-elements';
import NavBar from '../layout/NavBar';
import { ButtonGroup, Row } from 'reactstrap';
import { SearchBoxContent, SearchBoxInput } from '../cashless-vaccination/styled-elements';
import LoadingComponent from '../common/LoadingComponent';
import { FaSearch } from 'react-icons/fa';
import { cancelDentalModal, getCashlessDentalRequests, markAppointmentComplete } from '../../services/api/cashless-dentals';
import tableColumns from './DentalTable';
import DentalRequestDetails from './Modals/DentalRequestsDetails';
import { hideDentalRequestModal, showDentalRequestModal } from '../../actions';
import SubContainer from '../cashless-vaccination/components/SubContainer';
import useAfterInitialEffect from '../cashless-vaccination/hooks/useAfterInitialEffect';
import DentalCommentModal from './Modals/DentalCommentModal';
import moment from 'moment';
import DentalModalCancellation from './Modals/CancelModal';
import { toast } from 'react-toastify';
import DownloadCsv from '../common/DownloadCsv';
import MarkDentalAppointmentComplete from './Modals/ConfirmModal';

const FoldableTable = FoldableTableHOC(ReactTable);

const initialDates = {
    startDate: null,
    endDate: null,
}

const CashlessDental = ({
    dispatch,
    cashlessDentalDetails: stateDetails
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [ data, setData ] = useState([]);
    const [ makeSearch, setMakeSearch ] = useState('');
    const [ totalRecords, setTotalRecords ] = useState(0);
    const [ sponsorFilter, setRequestSponsorFilter ] = useState('');
    const [mdpAppointmentFilter, setRequestMdpAppointmentFilter] = useState('');
    const [ requestIdFilter, setRequestIdFilter ] = useState('');
    const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [ statusFilter, setStatusFilter ] = useState('');
    const [ appTimeFilter, setAppTimeFilter ] = useState('');
    const [appointmentDateFilter, setAppointmentDateFilter] = useState(initialDates)
    const [requestDateFilter, setRequestDateFilter] = useState(initialDates)
    const [ pages, setPages ] = useState(0);
    const [ pageSize, setPageSize ] = useState(20)
    const [ currentPage, setCurrentPage ] = useState(0);
    const [ showCommentModal, setShowCommentModal ] = useState(false);
    const [ commentModalRequestId, setCommentModalRequestId ] = useState(null);
    const [requestId, setRequestId] = useState(null);
    const [mdpRequestId, setMdpRequestId] = useState(null);

    const searchInputRef = useRef(null)

    const openRequestCommentModal = (requestId) => {
        setShowCommentModal(true)
        setCommentModalRequestId(requestId)
    }

    const cancelOpenModal = (requestId, mdpAppointmentId) => {
        console.log(requestId, mdpAppointmentId, '[requestId, mdpAppointmentId]');
        setCancelModalIsOpen(true)
        setRequestId(requestId)
        setMdpRequestId(mdpAppointmentId)
    }

    const confirmOpenModal = (requestId) => {
        console.log({ requestId }, '[confirmOpenModal]');
        setConfirmModalIsOpen(true)
        setRequestId(requestId)
    }

    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    }

    const cancelCloseModal = () => {
        setCancelModalIsOpen(false)
    }

    const submitCancelModal = (reason) => {
        console.log({ reason }, '[reason, remarks]');
        if (!mdpRequestId || !reason) {
            return toast.error('Please select a reason to continue')
        }
        return cancelDentalModal(mdpRequestId, reason)
        .then((response) => {
            console.log({response}, '[response]');
            if (response.message === 'success') {
                setMdpRequestId(null)
                setRequestId(null)
                toast.success('Request Canceled Successfully')
            } else {
                toast.error(`${response.message}`);
            }
        })
        .catch(() => toast.error('Cancel request failed, please try again'))
    }

    const submitCompletedAppointment = () => {
        console.log(requestId, '[requestId]');
        if(!requestId) {
            return toast.error('No requestId found');
        }
        return markAppointmentComplete(requestId).then((response) => {
            console.log({ response }, '[markAppointmentComplete][response]')
            setRequestId(null)
            if (response.message === 'success') {
                toast.success('Procedure Marked Completed');
            } else {
                toast.error(`${response.message}`);
            }
        }).catch((error) => toast.error(`Something went wrong - ${requestId} ${error}`))
    }

    const fetchTableData = (currPage, currentPageSize, orderBy, orderDir, claimFilter) => {
        const valid = value => typeof value === 'number';
        const dateCheck = (date) => {
            return !date ? date : moment(date).format('YYYY-MM-DD')
        }
        setIsLoading(true)
        const params = { 
            page: valid(currPage) ? currPage : currentPage,
            draw: valid(currentPageSize) ? currentPageSize : pageSize,
            requestId: requestIdFilter,
            appointmentTimeFilter: appTimeFilter,
            appTimeFilter,
            appointmentDateFilterEndDate: dateCheck(appointmentDateFilter.endDate),
            appointmentDateFilterStartDate: dateCheck(appointmentDateFilter.startDate),
            requestDateFilterStartDate: dateCheck(requestDateFilter.startDate),
            requestDateFilterEndDate: dateCheck(requestDateFilter.endDate),
            search: makeSearch,
            sponsorFilter: sponsorFilter,
            mdpAppointmentFilter: mdpAppointmentFilter,
            statusFilter: statusFilter,
        }
        if (orderBy && orderDir) {
            params.orderBy = orderBy
            params.orderDir = orderDir
        }
        if (claimFilter) {
            params.claimedByFilter = claimFilter
        }
        return getCashlessDentalRequests(params)
            .then((res) => {
                setIsLoading(false)
                const allData = res.data.rows
                setData(allData)
                setTotalRecords(res.data.count)
                setPages(Math.ceil(res.data.count / (valid(currentPageSize) ? currentPageSize : pageSize)))
            }).catch((err) => {
                console.log({err}, '[err]')
                setIsLoading(false)
            })
    }

    const onFetchData = (state) => {
        let orderBy, orderDir, claimFilter
        if (state.sorted.length) {
            orderBy = state.sorted[0].id
            orderDir = state.sorted[0].desc ? 'DESC' : 'ASC'
        }
        state.filtered.forEach((filter) => {
            if (filter.id === 'opsUserName') {
                claimFilter = filter.value
            }
        });

        fetchTableData(state.page, state.pageSize, orderBy, orderDir, claimFilter);
        setCurrentPage(state.page)
        setPageSize(state.pageSize)
    };

    const handleSearchBar = (event) => {
        const { value } = event.target
        if (event.key === 'Enter') {
            setMakeSearch(value)
        }
    }

    useAfterInitialEffect(() => {
        fetchTableData()
    }, [
        requestIdFilter,
        statusFilter,
        makeSearch,
        sponsorFilter,
        mdpAppointmentFilter,
        appointmentDateFilter,
        requestDateFilter,
        appTimeFilter,
    ])

    const cashlessDental = tableColumns({
        dispatch, showDentalRequestModal, setRequestIdFilter, setStatusFilter, setRequestSponsorFilter, openRequestCommentModal, setRequestMdpAppointmentFilter, setAppointmentDateFilter,setRequestDateFilter, setAppTimeFilter, cancelOpenModal, confirmOpenModal
    }, {
        statusFilter
    }, [statusFilter])

    const closeRequestCommentModal = () => {
        setShowCommentModal(false)
        setCommentModalRequestId(null)
    }

    return (
      <OuterComponent>
        <NavBar />
        <PaddedContainer className='pt-4 mt-5'>
            <Row>
                <div className='col'>
                    <div className='row'>
                        <div className='col-8'>
                            <SearchBox>
                                <SearchBoxInput
                                    ref={searchInputRef} 
                                    placeholder='Search...'
                                    type='text'
                                    onKeyDown={handleSearchBar}
                                />
                                <SearchBoxContent>{totalRecords} records</SearchBoxContent>
                            </SearchBox>
                        </div>
                        <div className="col-1">
                            {isLoading ? (
                                <LoadingComponent />
                            ) : (
                                <button
                                onClick={() => setMakeSearch(searchInputRef.current.value)}
                                className="btn btn-primary"
                                >
                                <FaSearch />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
                <div className='col-2'>
                    <ButtonGroup size='sm'>
                        <DownloadCsv
                            isCashlessDental
                            callback={() => getCashlessDentalRequests({
                                draw: totalRecords,
                                search: makeSearch
                            })}
                        />
                    </ButtonGroup>
                </div>
            </Row>
        </PaddedContainer>
        <FoldableTable
            data={data}
            columns={cashlessDental}
            loading={isLoading}
            filterable
            manual
            pages={pages}
            showPaginationTop
            className="-striped -highlight"
            onFetchData={onFetchData}
            pageSizeOptions={[10, 20, 50, 100]}
            defaultSorted={[
                {
                    id: 'requestId',
                    desc: true
                }
            ]}
            SubComponent={(row) => (
                <SubContainer 
                    requestId={row.original.requestId}
                    userId={row.original.userId}
                    type= {'dental'}
                />
            )}
        />

        <DentalRequestDetails 
            details={stateDetails}
            onClose={() => dispatch(hideDentalRequestModal())}
            isOpen={stateDetails && stateDetails.requestId}
            key={stateDetails?.requestId}
        />

        <DentalCommentModal
            requestId={commentModalRequestId}
            isOpen={showCommentModal}
            onClose={closeRequestCommentModal}
            onComplete={fetchTableData}
        />

        <DentalModalCancellation 
            modalIsOpen={cancelModalIsOpen}
            openModal={cancelOpenModal}
            closeModal={cancelCloseModal}
            submitModal={submitCancelModal}
            onComplete={fetchTableData}
            requestId={requestId}
        />

        <MarkDentalAppointmentComplete 
            modalIsOpen={confirmModalIsOpen}
            openModal={confirmOpenModal}
            closeModal={closeConfirmModal}
            onComplete={fetchTableData}
            submitModal={submitCompletedAppointment}
            requestId={requestId}
        />
        
        
    </OuterComponent>
  )
}

const matchStateToProps = (state) => ({
    cashlessDentalDetails: state.cashlessDental
})

export default connect(matchStateToProps)(CashlessDental)