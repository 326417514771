import React from 'react';
import Comment from './comment';
import styled from 'styled-components';
import Messages from '../../images/Messages.svg';

const AllComments = styled.div`
  overflow: auto;
  max-height: 50vh;
`;

export default function CommentList(props) {
  const { comments } = props;
  return (
    <AllComments>
      <div className="commentList">
        <h5
          className="mb-2 mt-2 "
          style={{
            color: '#714FFF',
            fontWeight: '600',
            fontFamily: 'Inter, sans-serif',
          }}
        >
          {/* <span className="badge badge-success">{props.comments.length}</span>{' '} */}
          <img src={Messages} alt="messages" /> Comment
          {props.comments.length > 0 ? 's' : ''}
        </h5>

        {props.comments.length === 0 && !props.loading ? (
          <div className="alert text-center alert-info">
            Comment History Empty
          </div>
        ) : null}

        {comments &&
          comments.length > 0 &&
          comments.map((comment, index) => (
            <Comment key={index} comment={comment} />
          ))}
      </div>
    </AllComments>
  );
}
