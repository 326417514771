import baseRequest, { checkSuccessfulResponse } from './baseRequest';

export const getNotificationPanelData = () => {
    const url = '/new-ops/notifications/comments';
    const method = 'GET';
    const params = {
        t: 'all',
    };
    return baseRequest(url, method, params, null)
        .then(checkSuccessfulResponse);
};
