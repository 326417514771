import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createLabOrder} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify/index';
import { withRouter } from 'react-router-dom';
import {DateRangePicker, SingleDatePicker} from 'react-dates';
import moment from 'moment';
import {listAddressesByOps, listPatientsByOps} from "../../services/api/rx-transcription";
class CreateLabOrderV2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            focused: false,
            collectionFocused: false,
            orderBookingDate: null,
            patients: [],
            addresses: [],
            selectedPatient: null,
            collectionDate: null,
            selectedAddress: null,
        };
    }

    toggle = () => {
        if (!this.state.modal) {
            this.getAddressList();
            this.getPatientList();
        }
        this.setState({
            modal: !this.state.modal,
        });
    };

    getAddressList = () => {
        listAddressesByOps(this.props.userId)
        .then((response) => {
            if(response.body.message === 'success') {
                this.setState({
                    addresses: response.body.result.map((a) => {a.selected=false; return a;}),
                });
            } else {
                toast.error('Error in listing addresses');
            }
        })
        .catch((err) => {
            toast.error('Error in listing addresses');
            console.error('Error', err);
        });
    };

    getPatientList = () => {
        listPatientsByOps(this.props.userId)
        .then((response) => {
            if(response.body.message === 'success') {
                this.setState({
                    patients: response.body.result.map((a) => {a.selected=false; return a;}),
                });
            } else {
                toast.error('Error in listing patients');
            }
        })
        .catch((err) => {
            toast.error('Error in listing patients');
            console.error('Error', err);
        });
    };

    selectAddress = (a) => {
        const addresses = this.state.addresses.map(o => {
            o.selected = o.addressId === a.addressId;
            return o
        });

        this.setState({ selectedAddress: a, addresses });
    };

    selectPatient = (p) => {
        const patients = this.state.patients.map(o => {
            o.selected = o.patientId === p.patientId;
            return o
        });

        this.setState({ selectedPatient: p, patients });
    };

    renderPatientList = () => {
        return this.state.patients.map((p) => {
            return (
                <tr key={p.patientId} onClick={() => this.selectPatient(p)} className={p.selected ? 'table-success' : ''}>
                    <th scope="row">{p.name}</th>
                    <td>{p.gender}</td>
                    <td>{p.phone}</td>
                    <td>{p.age}</td>
                </tr>
            )
        });
    };

    renderAddressList = () => {
        return this.state.addresses.map((a) => {
            return (
                <tr key={a.addressId} onClick={() => this.selectAddress(a)} className={a.selected ? 'table-success' : ''}>
                    <th scope="row">{a.address}</th>
                    <td>{a.locality}</td>
                    <td>{a.pincode}</td>
                </tr>
            )
        });
    };

    isOutSideRange = (date) => {
        const today = moment().endOf('day').unix();
        return date.unix() > today;
    };

    save = () => {
        if (this.state.orderBookingDate !== null && this.state.selectedPatient !== null) {
            // Make API call to create this order in DB
            createLabOrder(this.props.cartId, this.props.userId, this.state.orderBookingDate.unix(), this.state.selectedPatient, this.state.selectedAddress, this.state.collectionDate.unix())
                .then((response) => {
                    if (response.body.message === 'success') {
                        toast.success('Back dated order created. No notifications were sent.');
                        this.toggle();
                    } else {
                        toast.error('Error in creating new order');
                    }
                })
                .catch((err) => {
                    console.log('Error in creating order', err);
                    toast.error('Error in getting partners for this cart');
                });
        }
    };

    render() {
        return (
            <div>
                <button onClick={this.toggle} className="btn btn-info">Create Order</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                    <h4 className="mt-1 mb-0 ml-2">Confirmation</h4>
                    <ModalBody>
                        <p>Are you sure you want to create a back-dated order? No notifications will be sent to the user/partner/OPS team.</p>
                        <div className="row">
                            <div className="col">
                                <p>Order booking date</p>
                                <SingleDatePicker
                                    date={this.state.orderBookingDate} // momentPropTypes.momentObj or null
                                    onDateChange={date => this.setState({ orderBookingDate: date })} // PropTypes.func.isRequired
                                    focused={this.state.focused} // PropTypes.bool
                                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                                    id="your_unique_id" // PropTypes.string.isRequired,
                                    displayFormat={() => "DD/MM/YYYY"}
                                    isOutsideRange={this.isOutSideRange}
                                />
                            </div>

                            <div className="col">
                                <p>Tests done on Date</p>
                                <SingleDatePicker
                                    date={this.state.collectionDate} // momentPropTypes.momentObj or null
                                    onDateChange={date => this.setState({ collectionDate: date })} // PropTypes.func.isRequired
                                    focused={this.state.collectionFocused} // PropTypes.bool
                                    onFocusChange={({ focused }) => this.setState({ collectionFocused: focused })} // PropTypes.func.isRequired
                                    id="your_unique_id" // PropTypes.string.isRequired,
                                    displayFormat={() => "DD/MM/YYYY"}
                                    isOutsideRange={this.isOutSideRange}
                                />
                            </div>
                        </div>


                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Age</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderPatientList()}
                            </tbody>
                        </table>


                        <table className="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">Address</th>
                                <th scope="col">Locality</th>
                                <th scope="col">Pincode</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderAddressList()}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>Save</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }

}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(CreateLabOrderV2);





