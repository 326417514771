import React, { useEffect, useState } from 'react';
import {Row,Col } from 'reactstrap';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
const customStylesModal = {
    content: {
        left: '40%',
        overflow: 'auto',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        width: '95%',
        height: '70%',
        transform: 'translate(-40%, -10%)',
        position: "absolute",
        top: '30%',
        backgroundColor: '#F5F5F5'
    },
  };
  const CrossIcon = styled(FaTimes)`
  font-size: 1.5rem;
  font-weight: normal;
  color:#8752CB;
`;

export default  function ReimbursementPrePost (props){
    const {isOpen,prepostData,onClose}= props;
    return(
        <Modal
        isOpen={isOpen}
        style={customStylesModal}
        toggle={onClose}
        >
            <div className=''>
            <Row>
                <Col xs={12}md={12}>
                    <div className='float-right'>
                        <CrossIcon
                      onClick={onClose}
                        >

                        </CrossIcon>
                    </div>
                </Col>
            </Row>


            <Row className='m-2'>
               
            <Col xs={12}md={12}>
            <h3 className='m-3 text-center font-bold'>
                    Reimbursement Pre-Post Table
                </h3>
            <table>
                <tr>
                    <th>
                    Id
                    </th>
                    <th>
                    Amount
                    </th>
                    <th>
                    Reimbursement Type
                    </th>
                    <th>
                    Prescription Link
                    </th>
                    <th>
                    Lab Invoice Link
                    </th>
                    <th>
                    Medicine Invoice Link
                    </th>
                    <th>
                    Invoice Link
                    </th>
                    <th>
                    LabReport Link
                    </th>
                    <th>
                    MER Link
                    </th>
                    <th>
                        Time Type
                    </th>
                    <th>
                   Reason
                    </th>
                    <th>
                        Comment
                    </th>
                    <th>
                        Date
                    </th>
                    <th>
                        Discharge Date
                    </th>
                    <th>
                        Addmission Date
                    </th>
                    <th>
                        Request Date
                    </th>

                </tr>
                { prepostData.map((data)=>{
return (
  <tr style={{ background: 'white' }}>
    <td>{data.reimbursementPrePostReqId}</td>
    <td>{data.amount}</td>
    <td>{data.reimbursementType}</td>
    <td>
      {data.prescriptionLink ? (
        <a
          href={data.prescriptionLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Prescription Link
        </a>
      ) : (
        'N.A'
      )}
    </td>
    <td>
      {data.labInvoiceLink ? (
        <a href={data.labInvoiceLink} target="_blank" rel="noopener noreferrer">
          {' '}
          Lab Invoice Link
        </a>
      ) : (
        'N.A'
      )}
    </td>

    <td>
      {data.medicineInvoiceLink ? (
        <a
          href={data.medicineInvoiceLink}
          target="_blank"
          rel="noopener noreferrer"
        >
          {' '}
          Lab Invoice Link
        </a>
      ) : (
        'N.A'
      )}
    </td>
    <td>
      {data.invoiceLink ? (
        <a href={data.invoiceLink} target="_blank" rel="noopener noreferrer">
          {' '}
          Invoice Link
        </a>
      ) : (
        'N.A'
      )}
    </td>
    <td>
      {data.labReportLink ? (
        <a href={data.labReportLink} target="_blank" rel="noopener noreferrer">
          Lab Link
        </a>
      ) : (
        'N.A'
      )}
    </td>
    <td>
      {data.merLink ? (
        <a href={data.merLink} target="_blank" rel="noopener noreferrer">
          Mer Link
        </a>
      ) : (
        'N.A'
      )}
    </td>
    <td>{data.selectedTimeType}</td>

    <td>{data.reason ? data.reason : 'N.A'}</td>
    <td>{data.comments ? data.comments : 'N.A'}</td>
    <td>{data.date ? data.date : 'N.A'}</td>
    <td>{data.disChargeDate ? data.disChargeDate : 'N.A'}</td>
    <td>{data.addmissionDate ? data.addmissionDate : 'N.A'}</td>

    <td>{data.requestDate ? data.requestDate : 'N.A'}</td>
  </tr>
);

 })}
                
            </table>
                </Col>
                </Row>

            </div>

        </Modal>
    )

}