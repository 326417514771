// import React from 'react';

import styled from 'styled-components';

export const PageHeader = styled.p`
    font-size: 25px;
    font-weight: normal;
    padding-top: 20px;
`;

