import baseRequest from './baseRequest';

export const fetchWalletDetailsForUser = (userId, relativeId) => {
  const url = `/new-ops/wallet-details`;
  const params = {
    userId,
    relativeId,
  };
  const method = 'GET';
  return baseRequest(url, method, params);
};

export const walletTransactions = (userId, relativeId, primaryWalletId) => {
  const url = '/new-ops/wallet-transactions';
  const params = {
    userId,
    relativeId,
    primaryWalletId,
  };
  const method = 'GET';
  return baseRequest(url, method, params);
};
