import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Tooltip,
} from 'reactstrap';
import { fetchFollowUp,markFollowUpCompleted } from '../../services/api/offline-consult';
import moment from 'moment';

const FetchFollowUpModal = (props) => {
  const { isOpen, closeFetchFollowUpModal, fetchFollowUpDetails, onComplete } = props;
  const [requestId, setRequestId] = useState('');
  const [followUps, setFollowUps] = useState([]);
  const [loading, setLoading] = useState(false);
  const [completedFollowUps, setCompletedFollowUps] = useState({});
  const [activeFollowUps, setActiveFollowUps] = useState([]);
  const [confirmedFollowUps, setConfirmedFollowUps] = useState([]);

  const className = 'bg-info';

  useEffect(() => {
    // Perform API call when the component mounts
    if (fetchFollowUpDetails) {
      console.log(fetchFollowUpDetails);
      setRequestId(fetchFollowUpDetails.requestId);
      getFollowUps(fetchFollowUpDetails.requestId); // api call to get all followUps for requestId
    }
}, [fetchFollowUpDetails]); // Equivalent to componentDidMount in class components

  useEffect(()=>{
    const activeFollowUpsToSet = followUps.filter(followUp => followUp.completed === 0)
    setActiveFollowUps(activeFollowUpsToSet);
    
  },[followUps]);

  const getFollowUps = () => {
    setLoading(true);
    const { requestId } = fetchFollowUpDetails;
    fetchFollowUp(requestId)
      .then((response) => {
        setFollowUps(response.data);
      })
      .catch((err) => {
        console.log({ err });
      });

    setLoading(false);
  };

  const handleCheckboxChange = (followUpId) => {

    
    setCompletedFollowUps((prevState) => ({
        ...prevState,
        [followUpId]: !prevState[followUpId], // Toggle completion status
      }));
    setConfirmedFollowUps((prevFollowUps) => {
        if (prevFollowUps.includes(followUpId)) {
          return prevFollowUps.filter((id) => id !== followUpId);
        } else {
          return [...prevFollowUps, followUpId];
        }
      });
    
  };

  const confirmCompletedFollowUps = () => {
    //API call for confirmed follow-ups
    confirmedFollowUps.forEach((confirmedId) => {
        markFollowUpCompleted(requestId,confirmedId);
    });

    // After API calls, reset the list of confirmed follow-ups
    getFollowUps(fetchFollowUpDetails.requestId);
    setActiveFollowUps(followUps.filter(followUp => followUp.completed === 0))
    setConfirmedFollowUps([]);
    closeFetchFollowUpModal();
    onComplete();
  };

  return (
    <Modal
      isOpen={isOpen}
      onCancel={closeFetchFollowUpModal}
      toggle={closeFetchFollowUpModal}
    >
      <ModalHeader className={className} toggle={closeFetchFollowUpModal}>
        Follow Ups ({requestId})
      </ModalHeader>
      <ModalBody>
        <div>
          <ListGroup>
            {activeFollowUps.map((item) => (
              <ListGroupItem key={item.followUpId}>
                <strong>Required From:</strong> {item.follow_up_required_from}
                {' | '}
                <strong>Date and Time</strong>{' '}
                {moment(item.follow_up_date_time).format('YYYY-MM-DD hh:mm A')}
                {' | '}
                <input
                  type="checkbox"
                  id="completedCheckbox"
                  checked={completedFollowUps[item.followUpId] || false}
                  onChange={() => handleCheckboxChange(item.followUpId)}
                />
                <Tooltip placement="right" target="completedCheckbox">
                  {'Mark as Completed'}
                </Tooltip>
              </ListGroupItem>
            ))}
          </ListGroup>
        </div>
      </ModalBody>
      <ModalFooter>
      <Button
          color="primary"
          //disabled={}
          onClick={confirmCompletedFollowUps}
        >
          Confirm
        </Button>
        <Button color="secondary" onClick={() => closeFetchFollowUpModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FetchFollowUpModal;
