import React, { Component } from 'react';

import moment from 'moment';
import { BoldText, RegularText, Text } from '../common/Text';

import {
  Container,
  Button,
  ButtonGroup,
 
  Badge, 
} from 'reactstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';


import { FaPhone, FaSpinner, FaTimes, FaUndo } from 'react-icons/fa';
import LoadingComponent from '../common/LoadingComponent';
import ResultToast from './ResultToast';

// import CommentList from './comments/commentList';
// import CommentForm from './comments/commentForm';

// import { uploadImageAttachment } from '../../services/api/file-upload';

// import CustomCallingSection from './details/CustomCallingSection';

import {
  callUserRequest,
  callDoctorRequest,
  callCustomRequest,
  callPatientRequest,
} from '../../services/api/offline-consult';

import { unsetIntimationRequestId } from '../../actions/index';


import {
  getIntimationRequest,
} from '../../services/api/intimation-request';
import StyledTappable from '../common/StyledTappable';
import UploadingComponent from '../common/UploadingComponent';


const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;
`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled(Text)`
  font-size: 1rem;
  width: ${props => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;

const DetailText = styled(RegularText)`
  flex: 1;
  font-size: 1rem;
  color: ${props => props.color || '#787778'};
  margin-left: 0.25rem;
`;

const DetailTextDanger = styled(RegularText)`
  font-size: 1rem;
  color: #ff4d48;
`;

const CallUserText = styled(BoldText)`
  font-size: 1rem;
  margin-left: 0.4rem;
  color: #56c02c;
  cursor: pointer;
`;

const SelectElement = styled(Select)`
  /* border: 0.1rem solid #8852cc; */
  flex: 1 0;
  margin-bottom: 0.625rem;
`;

const UploadPrescriptionButton = styled(StyledTappable)`
  /* width: 14rem;
  height: 2rem;
  padding: 1.2rem;
  margin-left: 0.625rem;
  margin-top: 0.625rem;
  border: 1px solid #8852cc;
  border-radius: 0.2rem; */
  justify-content: flex-start;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`;

const UploadText = styled(BoldText)`
  font-size: 1.2rem;
  margin-left: 0.625rem;
  color: #8852cc;
  text-align: left;
`;

const SpocDetailContainer = styled(SectionContainer)`
  background-color: #f8f9fa;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  padding: 0.625rem;
`;

class RequestDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      intimationBasicInfo: {},
      intimationFulfillment: {},
      intimationPaymentHistory: [],
      intimationBookingInfo: [],
      callPatientLoading: false,
      callDoctorLoading: false,
      callUserLoading: false,
      showToast: false,
      callRequest: false,
      loading: false,
      details: {},
      actions: [],
      confirmationMessage: 'Are you sure you want to call',
      showConfirmationModal: false,
      phone: '',
      userType: '',
      activeInfo: 'basic',
      pacOrNoShow: '',
      transactions: null,
      refreshPayoutModalOpen: false
    };


    this.callStakeholdersOptions = [
      { value: 'User', label: 'User' },
      { value: 'Relative', label: 'Relative' },
      { value: 'Doctor', label: 'Doctor' },
      { value: 'Assistant', label: 'Assistant' },
      { value: 'Health Center', label: 'Health Center' },
    ];
  }

  componentDidMount() {
    console.log('RequestDetails componentDidMount', this.props);
    this.getIntimationDetails();
  }

  setPhone = event => {
    const value = event.target.value;
    this.setState({
      phone: value,
    });
  };

 

  getIntimationDetails = () => {
    const { requestId } = this.props.intimation;
    getIntimationRequest(requestId)
      .then(responseBody => {
        console.log(responseBody, 'getIntimationDetails');
      
        console.log(
          {
            ...responseBody.data
          },'getIntimationDetails')
          this.setState({
          intimationBasicInfo: responseBody.data.basic_details,
          intimationPaymentHistory: responseBody.data.payment_history,
          intimationBookingInfo: responseBody.data.booking_details,
          intimationFulfillment : responseBody.data.fulfillment,
          });
      }) 
      .catch(err => {
        console.log({ err });
      });
  }


  callPatient = () => {
    const { requestId } = this.props.intimation;
    this.setState({
      showConfirmationModal: false,
    });
    return callPatientRequest(requestId)
      .then(res => {
        this.showSuccessToast();
      })
      .catch(err => {
        console.log(err);
        this.showFailureToast();
      })
      .finally(() => {
        this.setState({
          callPatientLoading: false,
        });
      });
  };

  initiateCall = type => {
    console.log('inside initiate call', type);
    switch (type) {
      case 'USER': {
        this.setState({
          callUserLoading: true,
          showConfirmationModal: true,
          confirmationMessage: 'Are You sure You want to call the user ?',
        });
        break;
      }
      case 'PATIENT': {
        this.setState({
          callPatientLoading: true,
          showConfirmationModal: true,
          confirmationMessage: 'Are You sure You want to call the Patient ?',
        });
        break;
      }
      case 'CUSTOM': {
        this.setState({
          callCustomLoading: true,
          showConfirmationModal: true,
          confirmationMessage: 'Are You sure You want to make this call ?',
        });
        break;
      }
      case 'Doctor': {
        this.setState({
          callDoctorLoading: true,
          showConfirmationModal: true,
          confirmationMessage: 'Are You sure You want to call the user ?',
        });
        break;
      }

      default:
        break;
    }
  };

  openCancelTpaModal = () => {
    this.setState({
      tpaCancelLoading: true,
      showConfirmationModal: true,
      confirmationMessage: 'Are you sure you want to cancel the TPA Request?',
    });
  };

  toggleConfirmationModal = () => {
    this.setState({
      showConfirmationModal: !this.state.showConfirmationModal,
      callUserLoading: false,
      callPatientLoading: false,
      callDoctorLoading: false,
    });
  };

  callUser = () => {
    const { requestId } = this.props.intimation;
    this.setState({
      showConfirmationModal: false,
    });
    return callUserRequest(requestId)
      .then(res => {
        this.showSuccessToast();
      })
      .catch(err => {
        console.log(err);
        this.showFailureToast();
      })
      .finally(() => {
        this.setState({
          callUserLoading: false,
        });
      });
  };

  callDoctor = () => {
    const { requestId } = this.props.intimation;
    this.setState({
      showConfirmationModal: false,
    });
    return callDoctorRequest(requestId)
      .then(res => {
        this.showSuccessToast();
      })
      .catch(err => {
        console.log(err);
        this.showFailureToast();
      })
      .finally(() => {
        this.setState({
          callDoctorLoading: false,
        });
      });
  };

  callCustom = () => {
    const { requestId } = this.props.intimation;
    const { phone } = this.state;
    this.setState({
      showConfirmationModal: false,
    });
    return callCustomRequest(requestId, phone)
      .then(res => {
        this.showSuccessToast();
      })
      .catch(err => {
        console.log(err);
        this.showFailureToast();
      })
      .finally(() => {
        this.setState({
          callCustomLoading: false,
        });
      });
  };

  showSuccessToast(successToastMessage) {
    this.setState(
      {
        successToastMessage: successToastMessage,
        callRequest: true,
        showToast: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showToast: false,
            callRequest: false,
          });
        }, 3000);
      }
    );
  }

  showFailureToast(errorDesc) {
    this.setState(
      {
        failRequest: true,
        showToast: true,
        errorDesc,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showToast: false,
            failRequest: true,
            errorDesc: null,
          });
        }, 3000);
      }
    );
  }

  toggleToast = () => {
    this.setState({
      showToast: false,
    });
  };

  componentWillUnsetMount() {
    this.props.dispatch(unsetIntimationRequestId());
  }

  onOptionSelect = option => {
    console.log({ option });
    this.setState({
      userType: option.value,
    });
  };

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '0.1rem solid #8852cc',
      // // color: '#8852cc',
    }),
  };  

  render() {
    const {
      callUserLoading,
      activeInfo,
    } = this.state;
    const {
      intimationBasicInfo,
      intimationBookingInfo,
      intimationPaymentHistory,
      intimationFulfillment,
    } = this.state;
    const { requestId } = this.props.intimation;
    const opd_charges = intimationBookingInfo.reduce(
      (acc, curr) => acc + (parseInt(curr.total_amount) || 0),
      0
    );
    const co_pay = intimationBookingInfo.reduce(
      (acc, curr) => acc + (parseInt(curr.copay) || 0),
      0
    );
    const wallet_deduction = intimationBookingInfo.reduce(
      (acc, curr) => acc + (parseInt(curr.wallet_deduction) || 0),
      0
    );
    const paid_copay = intimationPaymentHistory.reduce(
      (acc, curr) => curr.status==1 ? acc + parseInt(curr.amount) : acc + 0,
      0
    );
    console.log(opd_charges, co_pay, wallet_deduction, paid_copay);
    const renderBasicInfo = () => (
      <DetailsContainer>
        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>User Booking Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>User Id:</DetailName>
                <DetailText>
                  {intimationBasicInfo.userId || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Appoint. Date:</DetailName>
                <DetailText>
                  {moment(intimationBasicInfo.appointment_date).format('DD-MM-YY hh:mm A') || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Request Date:</DetailName>
                <DetailText>
                  {moment(intimationBasicInfo.requested_on).format('DD-MM-YY hh:mm A') || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Patient Name:</DetailName>
                <DetailText>
                  {intimationBasicInfo.patient_name || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Patient Relation:</DetailName>
                <DetailText>
                  {intimationBasicInfo.relationship || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>User Email:</DetailName>
                <DetailText>
                  {intimationBasicInfo.userEmail || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>User Phone:</DetailName>
                {intimationBasicInfo.userPhone ? (
                  <CallUserText onClick={() => this.initiateCall('USER')}>
                    {callUserLoading ? (
                      <FaSpinner
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="icon-spin text-success"
                      />
                    ) : (
                      <FaPhone
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="text-success"
                      />
                    )}
                    {'  '}
                    {intimationBasicInfo.userPhone}
                  </CallUserText>
                ) : (
                  <DetailTextDanger>Unavailable</DetailTextDanger>
                )}
              </DetailRow>
            </SectionBody>
          </SectionInnerContainer>

          <SectionInnerContainer>
            <SectionHeader>Booking Info</SectionHeader>
            <SectionBody>
              {intimationBookingInfo.map(detail => (

                <DetailRow>
                  <DetailName>{intimationBasicInfo.category_name}</DetailName>
                  <DetailText>{`${detail.l_order_id ?detail.l_order_id : detail.c_request_id ?detail.c_request_id:detail.id }`}</DetailText>
                  <DetailText>{`Status: ${detail.status}`}</DetailText>
                </DetailRow>
              ))}
              {intimationBookingInfo.length === 0 && (
                <DetailText>
                  No Previous consultation found for this pair
                </DetailText>
              )}
            </SectionBody>
          </SectionInnerContainer>
        </SectionContainer>

      </DetailsContainer>
    );

    const renderCenterInfo = () => (
      <DetailsContainer>
        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>Center Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Practice Center:</DetailName>
                <DetailText>
                  {intimationBasicInfo.center_name || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Location:</DetailName>
                <DetailText>
                  {intimationBasicInfo.center_address || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Specialist name:</DetailName>
                <DetailText>
                  {intimationBasicInfo.specialist_name || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Specialist Phone:</DetailName>
                <DetailText>
                  {intimationBasicInfo.specialist_phone || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
            </SectionBody>
          </SectionInnerContainer>
        </SectionContainer>

        
      </DetailsContainer>
    );

    const renderPaymentInfo = () => (
      <DetailsContainer>
        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>Charges</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Charges:</DetailName>
                <DetailText color="28A745">
                  {intimationBookingInfo.length && opd_charges.toString() || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Wallet Deduction:</DetailName>
                <DetailText color="28A745">
                  {intimationBookingInfo.length && wallet_deduction.toString() || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Payout Status.:</DetailName>
                {intimationBasicInfo.payout_status ? (
                  <Badge color="info" pill>
                    {intimationBasicInfo.payout_status}
                  </Badge>
                ) : (
                  <DetailTextDanger>Unavailable</DetailTextDanger>
                )}
              </DetailRow>
              {intimationBookingInfo.length ? (
                <DetailRow>
                  <DetailName>Total Copay</DetailName>
                  <DetailText>{intimationBookingInfo.length && co_pay}</DetailText>
                </DetailRow>
              ) : null}
               {intimationBookingInfo.length  ? (
                <DetailRow>
                  <DetailName>paid Copay</DetailName>
                  <DetailText>{intimationBookingInfo.length && paid_copay}</DetailText>
                </DetailRow>
              ) : null}
                
            </SectionBody>
          {/* </SectionInnerContainer>
          {paymentInfo.fulfilment ? (
            <SectionInnerContainer>
              <SectionBody>
                <DetailRow>
                  <Input
                    type="checkbox"
                    id="checkbox"
                    onChange={this.changeInvoiceMandatory}
                    checked={paymentInfo.fulfilment.invoiceMandatory}
                  />
                  <DetailName fullWidth>Invoice Mandatory</DetailName>
                </DetailRow>
              </SectionBody>
            </SectionInnerContainer>
          ) : null}
          <SectionInnerContainer> */}
            {(intimationPaymentHistory && intimationPaymentHistory.length) ? (
                <div className="card">
                  <div className="card-header">
                    <div className="row no-gutters">
                      <div className="col">Transaction History</div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="container-fluid">
                      {intimationPaymentHistory.map((t) => (
                       <DetailRow>
                          <DetailName>{t.amount}</DetailName>
                          <DetailText>{`Status: ${t.status}`}</DetailText>
                     </DetailRow>
                      ))}
                    </div>
                  </div>
                </div>
            ) : null}
          </SectionInnerContainer>
        </SectionContainer>

        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>Fulfilment Details</SectionHeader>
            <SectionBody>
              {intimationFulfillment && intimationFulfillment.fulfillmentId ? (
                <SpocDetailContainer>
                  <DetailRow>
                    <DetailName>Fulfilment Id:</DetailName>
                    <DetailText>{`${intimationFulfillment.fulfillmentId}`}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Pref. Payment:</DetailName>
                    <DetailText>
                      {intimationFulfillment.preferredPayment}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Payee Name:</DetailName>
                    <DetailText>
                      {intimationFulfillment.payeeName || (
                        <DetailTextDanger>Unavailable</DetailTextDanger>
                      )}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Account Number:</DetailName>
                    <DetailText>
                      {`${intimationFulfillment.accountNumber}`}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Ifsc Code:</DetailName>
                    <DetailText>{intimationFulfillment.ifsc}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>upi No.:</DetailName>
                    <DetailText>{intimationFulfillment.upiPhone}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>UPI:</DetailName>
                    <DetailText>{intimationFulfillment.upiAddress}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>QR CODE:</DetailName>
                    <DetailText>
                      <a
                        href={intimationFulfillment.qrCodeImg}
                        target="_blank"
                      >
                        {intimationFulfillment.qrCodeImg
                          ? 'Click Here'
                          : 'Unavailable'}
                      </a>
                    </DetailText>
                  </DetailRow>
                </SpocDetailContainer>
              ): <p>Not Available</p>}
            </SectionBody>
          </SectionInnerContainer>
        </SectionContainer>
      </DetailsContainer>
    );

    

    if (!intimationBookingInfo || !requestId) {
      return <LoadingComponent color="black" />;
    }
    if (intimationBookingInfo || requestId) {
      return (
        <Container fluid={true}>
          {this.state.uploading && (
            <UploadingComponent
              uploadProgress={`${this.state.uploadProgress}%`}
              borderRadius={0}
            />
          )}
          <div className="row">
            <div className="col-12 text-center justify-content-center">
              <ResultToast
                callRequest={this.state.callRequest}
                failRequests={this.state.failRequest}
                successToastMessage={this.state.successToastMessage}
                errorDesc={this.state.errorDesc}
                show={this.state.showToast}
                onToggle={this.toggleToast}
              />
            </div>
          </div>
          <div className="row">
            <ButtonGroup>
              <Button
                onClick={() => this.setState({ activeInfo: 'basic' })}
                active={activeInfo === 'basic'}
              >
                Basic Info
              </Button>
              <Button
                onClick={() => this.setState({ activeInfo: 'center' })}
                active={activeInfo === 'center'}
              >
                Center Info
              </Button>
              <Button
                onClick={() => this.setState({ activeInfo: 'payment' })}
                active={activeInfo === 'payment'}
              >
                Payment Info
              </Button>
              {/* <Button
                onClick={() => this.setState({ activeInfo: 'misc' })}
                active={activeInfo === 'misc'}
              >
                Actions
              </Button> */}
              {/* <Button
                onClick={() => this.setState({ activeInfo: 'older' })}
                active={activeInfo === 'older'}
              >
                Old Info
              </Button> */}
            </ButtonGroup>
          </div>
          <div className="row">
            <div className="col-md-8">
              {activeInfo === 'basic' && renderBasicInfo()}
              {activeInfo === 'center' && renderCenterInfo()}
              {activeInfo === 'payment' && renderPaymentInfo()}
              {/* {activeInfo === 'misc' && renderMiscInfo()} */}
              {/* {activeInfo === 'older' && renderOlderInfo()} */}
            </div>
            {/* <div className="col-md-4">
              <CommentList loading={loading} comments={comments} />
              <div className="">
                <CommentForm
                  addComment={this.addComment}
                  requestId={requestId}
                  commentType={appConfig.REQUEST_CHAT.NETWORK}
                />
              </div>
            </div> */}
          </div>
         
          {/*Refresh Payout Modal*/}
         
          <input
            type="file"
            id="imageAttachment"
            accept="image/*,.pdf"
            style={{ display: 'none' }}
            onChange={this.uploadPrescription}
          />
        </Container>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = state => ({
  user: state.user,
  intimation: state.intimation,
});

export default connect(mapStateToProps)(RequestDetails);
