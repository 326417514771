import React, { Component } from 'react'
import {
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Row,
    Col,
    Input
  } from 'reactstrap';
import Select from 'react-select';
import { Async } from 'react-select';
import LoadingComponent from '../LoadingComponent';
import { toast } from 'react-toastify';
import {addDependentForUser, getReations} from '../../../services/api/online-consultation-booking'

class DependentModal extends Component {
    constructor(props){
        console.log(props,'[dependentModalProps]')
        super(props)
        this.state={
            number:'',
            age:'',
            dob:'',
            loading:false,
            relationLoading:false,
            relationResult:[],
            relationId:'',
            phone:'',
            gender:'',
            dependentName:''
        }
        this.relationRef = React.createRef();
    }

    componentDidMount(){
        this.getRelationsForUser();
        if (this.props.userPhone)
          this.setState({
            number: this.props.userPhone,
          });
    }

    getRelationsForUser = () => {
        this.setState({relationLoading:true})
        return getReations()
            .then((res)=>{
                const array = []
                res.data.forEach((ele)=>{
                    array.push({value:ele.relationId, label:`${ele.title} - ${ele.gender}`})
                })
                return this.setState({
                    relationResult:array
                })

            }).then(()=>{
                this.setState({relationLoading:false})
            }).catch((err)=>{
                console.error({err},'[err-relation]')
            })
    }

    addDependetData = () => {
        const userPhone = this.state.number
        const relationId = this.state.relationId
        const relativeName = this.state.dependentName
        const relativeAge = this.state.age
        const gender = this.state.gender
        const dob = this.state.dob
        const relativePhone = this.state.phone
        const body = {userPhone,relationId,relativeName,relativeAge,gender,dob,relativePhone}
        console.log(body,'[bodyModal]')
        if(body.userPhone){
            addDependentForUser(body)
                .then((response)=>{
                    console.log({response},'[response]')
                    if(response.message==='success'){
                        toast.success('Dependent Added Successfully')
                        this.props.closeModal()
                    }else {
                        toast.error(response.errorMessage);
                    }
                }).catch((err)=>{
                    console.log({err},'[response-err]')
                })
        }else{
            toast.error('User Phone is Required')
        }
    }

  render() {
    const option =  [{ value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },{ value: 'Others', label: 'Others' },]
    return (
        <>
        <Modal
        isOpen={this.props.modalIsOpen}
        toggle={()=>this.props.closeModal()}
        >
            <ModalHeader className="bg-primary" toggle={()=>this.props.closeModal()}>Add Dependent</ModalHeader>
            <ModalBody>
                <form>
                    <FormGroup>
                        <Row>
                            <Col className='col-3'>
                                <Label>User Mobile No.</Label>
                            </Col>
                            <Col>
                                <Input
                                type='number'
                                value={this.props.userPhone}
                                onChange={(event) => {
                                    this.setState({
                                    number: event.target.value,
                                    });
                                }}/>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col className='col-3'>
                                <Label>Relation</Label>
                            </Col>
                            <Col>
                                <Select
                                    isSearchable={true} ref={this.relationRef}
                                    defaultValue={this.state.relationResult.length>0&&this.state.relationResult[0]}
                                    options={this.state.relationResult}
                                    isLoading={this.state.relationLoading}
                                    onChange={(ele)=>{this.setState({relationId:ele?ele.value:''})}}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col className='col-3'>
                                <Label>Name</Label>
                            </Col>
                            <Col>
                                <Input type='text' onChange={(event) => {
                                    this.setState({
                                        dependentName: event.target.value,
                                    });
                                }}/>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col className='col-3'>
                                <Label>Age</Label>
                            </Col>
                            <Col>
                                <Input type='number' onChange={(event) => {
                                    this.setState({
                                    age: event.target.value,
                                    });
                                }}/>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col className='col-3'>
                                <Label>Gender</Label>
                            </Col>
                            <Col>
                                <Select
                                    options={option}
                                    onChange={(ele)=>{this.setState({gender:ele?ele.value:''})}}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col className='col-3'>
                                <Label>Date Of Birth</Label>
                            </Col>
                            <Col>
                                <Input placeholder='Date Of Birth' type='date' onChange={(ele)=>{this.setState({dob:ele.target.value})}}/>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col className='col-3'>
                                <Label>Dependent Phone No.</Label>
                            </Col>
                            <Col>
                                <Input placeholder='Dependent No.' type='Number' onChange={(ele)=>{this.setState({phone:ele.target.value})}}/>
                            </Col>
                        </Row>
                    </FormGroup>
                </form>
            </ModalBody>
            <ModalFooter>{this.state.loading ?(<LoadingComponent/>):(<Button color="primary"
        onClick={this.addDependetData}
        >
              Add Dependent
            </Button>)}
            <Button color="secondary"
            onClick={this.props.closeModal}>
            Close
          </Button></ModalFooter>
        </Modal>
      </>
    )
  }
}

export default DependentModal
