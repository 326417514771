import React from 'react'
import { useMemo } from "react"
import { ImNewTab } from 'react-icons/im'
import { UncontrolledTooltip } from 'reactstrap'
import { markInvalid, resendLink } from './api'
import { toast } from 'react-toastify'
import moment from 'moment'
import DateFilter from '../cashless-vaccination/headerFilters/DateFilter'

const commonFields = (accessor, header, width) => ({
    Header: header,
    accessor: accessor,
    disablePadding: true,
    style: { 
        whiteSpace: 'unset',
        minWidth: `${width}px`,
    },
    headerStyle: {
        minWidth: `${width}px`,
    }
})

export const useColumns = ({ 
    setUrlModalId, onComplete, currentAction, setCurrentAction, setCreationDate,
    setOfflineCashgramId, setLabCashgramId, showUploaded, setShowUploaded
}) => {

    return useMemo(() => [
        {
            ...commonFields('orderId', 'Order Id'),
            Cell: (row) => {
                const { destination } = row.original
                const destArr = (destination || '').split('/')
                return destArr[destArr.length - 1]
            }
        },
        {
            ...commonFields('sponsorName', 'Sponsor')
        },
        {
            ...commonFields('url', 'URL', 100),
            filterable: false,
            Cell: (row) => {
                const { url } = row.original
                return <a style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }} target="_blank" rel="noopener noreferrer" href={url}>
                    Upload Link
                    {/* <ImNewTab /> */}
                </a>
            }
        },
        {
            ...commonFields('created', 'Created On', 110),
            Cell: (row) => {
                return moment(row.original.created).format('DD-MMM-YYYY')
            },
            Filter: () => (
                <DateFilter 
                    label='Creation'
                    uniqueId='Exceptional_Reimbursement_Creation'
                    onChooseDate={setCreationDate}
                />
            ) 
        },
        {
            filterable: false,
            ...commonFields('amount', 'Amount')
        },
        {
            filterable: false,
            ...commonFields('status', 'Status')
        },
        {
            filterable: false,
            ...commonFields('expiry', 'Expiry', 110)
        },
        {
            ...commonFields('userUploaded', 'User Uploaded', 120),
            Cell: (row) => String(row.original.file_count !== 0),
        },
        {
            ...commonFields('utr', 'UTR', 150)
        },
        {
            ...commonFields('view_file', 'View File', 110),
            filterable: false,
            Cell: (row) => {

                const { file_count, destination } = row.original
                const destArr = (destination || '').split('/')
                const orderId = destArr[destArr.length - 1]

                return <button style={{
                    border: 'none',
                    color: !file_count ? '#000' : '#fff',
                    background: !file_count ? '#aaa' : '#0061fe'
                }} disabled={!file_count} onClick={() => {
                    setUrlModalId(orderId)
                }}>
                    View File(s)
                </button>
            }
        },
        {
            ...commonFields('raise_reimbursement_request', 'Raise Reim. Request', 140),
            filterable: false,
            Cell: (row) => {
                const { amount, destination, file_count, status, invalid, id } = row.original
                const destArr = (destination || '').split('/')
                const orderId = destArr[destArr.length - 1]
                const isDisabled = file_count === 0 || status !== 'Not Raised' || invalid === 1

                return <button style={{
                    border: 'none',
                    color: isDisabled ? '#000' : '#fff',
                    background: isDisabled ? '#aaa' : '#88cc52'
                }} disabled={isDisabled} onClick={() => {
                    if (orderId[0] === 'c') {
                        setOfflineCashgramId(orderId)
                        return
                    } else {
                        setLabCashgramId(orderId)
                        return
                    }
                }}>
                    Send Reim. Link
                </button>
            }
        },
        {
            ...commonFields('resend_link', 'Resend Link'),
            filterable: false,
            Cell: (row) => {
                const { destination, status, invalid, id } = row.original
                const destArr = (destination || '').split('/')
                const orderId = destArr[destArr.length - 1]
                const isDisabled = status  === "Not Raised" || invalid === 1

                return <button style={{
                    border: 'none',
                    color: isDisabled ? '#000' : '#fff',
                    background: isDisabled ? '#aaa' : '#8852cc'
                }} disabled={isDisabled} onClick={() => {
                    if (currentAction === `resend-${id}`) return
                    setCurrentAction(`resend-${id}`)
                    resendLink({ orderId })
                        .then((response) => {
                            if (response?.message === 'failure') {
                                toast.error(response.errorMessage)
                            } else {
                                onComplete()
                                toast.success('Link resent successfully')
                            }
                            
                        })
                        .catch(err => {
                            console.log({err})
                            toast.error(err?.response?.data?.errorMessage || 'Something went wrong, please try in a while')
                        })
                        .finally(() => {
                            setCurrentAction('')
                        })
                }}>
                    Resend
                </button>
            }
        },
        {
            ...commonFields('mark_invalid', 'Mark Invalid', 150),
            filterable: false,
            Cell: (row) => {
                const { destination, invalid, id } = row.original
                const destArr = (destination || '').split('/')
                const orderId = destArr[destArr.length - 1]

                return <button style={{
                    border: 'none',
                    color: invalid ? '#000' : '#fff',
                    background: invalid ? '#aaa' : '#f04'
                }} disabled={invalid} onClick={() => {
                    if (currentAction === `mark-${id}`) return
                    setCurrentAction(`mark-${id}`)
                    markInvalid({ orderId })
                        .then(() => {
                            onComplete()
                            toast.success('Marked as invalid')
                        })
                        .catch(err => {
                            toast.error(err?.response?.data?.errorMessage || 'Something went wrong, please try in a while')
                        })
                        .finally(() => {
                            setCurrentAction('')
                        })
                }}>
                    Mark Invalid
                </button>
            }
        },
        {
            ...commonFields('id', 'id', 200)
        },
        {
            ...commonFields('title', 'Title', 250)
        },
        {
            ...commonFields('destination', 'Destination', 210)
        },
        {
            filterable: false,
            ...commonFields('file_count', 'File Count', 10, 10)
        },
        {
            Filter: () => (
                <select value={showUploaded} onChange={e => setShowUploaded(e.target.value)}>
                    {console.log({showUploaded})}
                    <option value='all'>All</option>
                    <option value='yes'>Yes</option>
                    <option value='no'>No</option>
                </select>
            ),
            ...commonFields('is_open', 'Dropbox Upload Done?', 180),
            Cell: (row) => {
                return row.original.is_open ? 'Yes' : 'No';
            }
        },
        {
            ...commonFields('is_open', 'Is Open'),
            Cell: (row) => {
                return String(row.original.is_open)
            }
        },
        {
            ...commonFields('description', 'Description', 150),
            Cell: (row) => {
                const { description, id } = row.original
                const [first, second] = (description || '').split(' ')
                return description ? <>
                    <span id={`descriptionTooltip-${id}`}>
                        {first} {second} ...
                    </span>
                    <UncontrolledTooltip target={`descriptionTooltip-${id}`}>
                        {description}
                    </UncontrolledTooltip>
                </> : <></>
            }
        },
        {
            ...commonFields('invalid', 'Invalid')
        }
    ], [currentAction, showUploaded])
}

export const useData = (apiData) => {

    return useMemo(() => apiData, [apiData])
}