import React from 'react';

import Modal from "react-modal";

import { getPayoutLogic } from '../../services/api/online-payment';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

export default class PayoutLogicInfoModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            logic: [],
            verticalId: null,
        };
    }

    onOpen = () => {
        return getPayoutLogic()
            .then((response) => {
                if (response && response.message !== 'success') {
                    throw new Error('Endpoint response is not correct');
                }
                this.setState({
                    logic: response.data,
                    verticalId: response.data[0].verticalId,
                });
            })
            .catch((err) => {
                console.error({ err },
                    '[getPayoutLogic]');
                throw err;
            });
    };

    onClose = () => {
        this.setState({
            isOpen: false,
            logic: [],
        });
    };

    changeVerticalId = (e) => {
        this.setState({
            verticalId: parseInt(e.target.value),
        });
    };

    render() {
        const { isOpen, logic, verticalId } = this.state;
        const currentLogic = (logic && logic.find(l => (l.verticalId === verticalId)));
        return (
            <React.Fragment>
                <div className="btn btn-primary" onClick={() => (this.setState({ isOpen: true }))}>Logic Info</div>
                <Modal
                    isOpen={isOpen}
                    onAfterOpen={this.onOpen}
                    onRequestClose={this.onClose}
                    style={customStyles}
                >
                    <h4>Payout Logic Info</h4>
                    <p>Vertical Name: <select onChange={this.changeVerticalId}>
                        {logic && logic.length && logic.map(l => (
                            <option value={l.verticalId}>{l.verticalName}</option>
                        ))}
                    </select>
                    </p>
                    {currentLogic && (
                        <React.Fragment>
                            <p>Doctor Message: <strong>{currentLogic.doctorMessage}</strong></p>
                            <p>User Message: <strong>{currentLogic.userMessage}</strong></p>
                            <p>Call Duration: <strong>{currentLogic.lowCallDuration} mins - {currentLogic.higherCallDuration} mins</strong></p>
                            <p>Prescription: <strong>{currentLogic.prescriptionCount}</strong></p>
                            <p>Diet Plan: <strong>{currentLogic.dietPlanCount}</strong></p>
                        </React.Fragment>
                    )}
                </Modal>
            </React.Fragment>
        );
    }
}
