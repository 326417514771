import React from 'react';

import {
  getCovidPartners,
  updatePartnerForBulkOrder,
} from '../../services/api/lab-order-details';

import { toast } from 'react-toastify';
import LoadingComponent from '../common/LoadingComponent';
import { getReasonsForRefund } from '../../services/api/get-labs-orders';

export default class LabsOrderChangePartnerForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partners: [],
      fetchPartnersLoading: false,
      updatePartnerLoading: false,
      selectedPartner: {
        id: null,
      },
      reasons: [],
      reasonId: null,
    };
  }
  getPartners = () => {
    this.setState({
      fetchPartnersLoading: true,
    });
    return getCovidPartners()
      .then((response) => {
        if (response.message === 'success') {
          this.setState({
            partners: response.partners || [],
          });
        } else {
          throw new Error('Error while fetching partners order');
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      })
      .finally(() => {
        this.setState({
          fetchPartnersLoading: false,
        });
      });
  };

  getReasons = () => {
    return getReasonsForRefund()
      .then((response) => {
        const respBody = response.body;
        const reasons = respBody.result;
        let options = [];
        options = reasons
          .filter((r) => r.type === 'change-partner')
          .map((r) => {
            return {
              value: r.id,
              label: r.reason,
            };
          });
        options.unshift({
          value: null,
          label: 'Select Reason',
        });
        console.log('\n Reasons getReasonsForRefund', options, reasons);
        this.setState({
          reasons: options,
        });
      })
      .catch((err) => {
        console.log('Error in fetching reasons: ', err);
      });
  };

  componentDidMount() {
    this.getReasons();
  }

  selectPartner = (id) => {
    this.setState({
      selectedPartner: {
        id
      },
    });
  };

  updatePartner = () => {
    const { orderIds } = this.props;
    const { selectedPartner, reasonId } = this.state;
    if (!reasonId) {
      toast.error('Select a reason');
      return;
    }
    this.setState({
      updatePartnerLoading: true,
    });
    return updatePartnerForBulkOrder(
      orderIds,
      selectedPartner.id,
      reasonId
    )
      .then((response) => {
        if (response.message === 'success') {
          toast.success('Successfully updated the partner');
          this.props.onSubmit();
        } else {
          throw new Error(
            response.errorMessage ||
              'Something went wrong while updating the partner'
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
        console.error(err);
      })
      .finally(() => {
        this.setState({
          updatePartnerLoading: false,
        });
      });
  };

  render() {
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              {this.state.fetchPartnersLoading ? (
                <LoadingComponent />
              ) : (
                <button className="btn btn-primary" onClick={this.getPartners}>
                  Get Partners
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <strong>Partners</strong>
            </div>
          </div>
          {this.state.partners && this.state.partners.length ? (
            this.state.partners.map((p) => (
              <div
                className={
                  'row m-2 border rounded clickable' +
                  (this.state.selectedPartner.id === p.partnerId
                    ? ' border-primary'
                    : '')
                }
                key={p.partnerId}
                onClick={() => this.selectPartner(p.partnerId)}
              >
                <div className="col">
                  {p.partnerId}. {p.partnerName} {p.address ? ` - ${p.address}` : ''}
                </div>
              </div>
            ))
          ) : (
            <div className="row">
              <div className="col">No Partners Found</div>
            </div>
          )}
          <div className="row">
            <div className="col">
              Reason
              <select
                value={this.state.reasonId}
                onChange={(e) => this.setState({ reasonId: e.target.value })}
              >
                {this.state.reasons.map((r) => (
                  <option value={r.value}>{r.label}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {this.state.selectedPartner && this.state.selectedPartner.id ? (
                this.state.updatePartnerLoading ? (
                  <LoadingComponent />
                ) : (
                  <button
                    className="btn btn-primary"
                    onClick={this.updatePartner}
                  >
                    Update Partner
                  </button>
                )
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
