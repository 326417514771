import baseRequest, {baseRequestWithHeaders} from './baseRequest';

const baseUrl = '/new-ops/sponsor-management';

export const fetchSponsors = (filters) => (baseRequest(`${baseUrl}/get-all-sponsors`, 'GET', filters, null));

export const uploadLogo = (file, sponsorId) => {

    const data = new FormData();
    data.append('file', file);
    data.append('sponsorId', sponsorId);

    const url = `/new-ops/sponsor-management/upload-logo`;
    const method = 'POST';
    return baseRequestWithHeaders(url, method, null, data);
};

export const updateSponsorConfig = (sponsorConfig) => {
    const url = `/new-ops/sponsor-management/sponsor`;
    const method = 'POST';
    const body = sponsorConfig;
    return baseRequestWithHeaders(url, method, null, body);
};

export const createNewSponsor = (sponsorName) => {
    const url = `/new-ops/sponsor-management/create-sponsor`;
    const method = 'POST';
    const body = { sponsorName };
    return baseRequest(url, method, null, body);
};

export const createTestUser = (phone, sponsorId) => {
    const url = `${baseUrl}/create-test-user`;
    const method = 'POST';
    const data = {
        phone,
        sponsorId,
    };
    return baseRequest(url, method, null, data);
};


export const updateUserSponsor = (phone , email, opdIsActiveCheck,gmcIsActiveCheck,sponsor) => {
    const url = `/new-ops/sponsor-management/update-user-sponsor`;
    const method = 'POST';
    return baseRequest(url, method, null, {phone , email, opdIsActiveCheck,gmcIsActiveCheck,sponsor  })
  };