import React from 'react';

import styled from 'styled-components';

import Days from './Days';

import { FaWindowClose, FaPlusCircle } from 'react-icons/fa';
import { toast } from 'react-toastify';

import { removeFromRoster, addToRoster } from '../../services/api/pool-manager';

import LoadingComponent from '../common/LoadingComponent';

const OuterComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 7px;
  background-color: #fafafa;
  border: 0.1px #cecece solid;
  border-radius: 3px;
  padding: 10px;
  :hover {
    background-color: #f0f0f0;
  }
`;

const StyledDiv = styled.div`
  margin: 10px;
`;

class RosterDayTimePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      day: 1,
      startHour: 9,
      endHour: 23,
    };
  }

  isAdded = () => {
    const { dayTime } = this.props;
    return dayTime && dayTime.rosterId;
  };

  getSelectedDayName = () => {
    const { dayTime } = this.props;
    return Days.filter((d) => d.value === dayTime.day)[0].display;
  };

  handleDeleteFromRoster = () => {
    const { rosterId, day, startHour, endHour } = this.props.dayTime;
    const { doctorId, poolId } = this.props;


    if (!rosterId) {
      return;
    }
    this.setState({
      loading: true,
    });
    return removeFromRoster(rosterId, doctorId, poolId, {day, startHour, endHour})
      .then((response) => {
        if (response.message === 'success') {
          toast.success('Successfully removed from the roster');
          return this.props.onComplete();
        } else {
          throw new Error(
            response.errorMessage || 'Error while removing from the roster'
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleAddToRoster = () => {
    const { poolId, doctorId } = this.props;
    const { startHour, endHour } = this.state;
    let { day } = this.state;
    this.setState({
      loading: true,
    });
    let alldays = [];

    if (day == 8) {
      while (day > 1) {
        day--;
        alldays.push(
          addToRoster({ day, startHour, endHour }, doctorId, poolId)
        );
      }
    } else {
      alldays.push(addToRoster({ day, startHour, endHour }, doctorId, poolId));
    }

    return Promise.all(alldays)
      .then((response) => {
        if (response[response.length - 1].message === 'success') {
          toast.success('Successfully added to the roster');
          return this.props.onComplete();
        } else {
          throw new Error(
            response.errorMessage || 'Error while adding to the roster'
          );
        }
      })
      .catch((err) => {
        toast.error(err.message);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { dayTime, roster } = this.props;
    const { day, startHour, endHour, loading } = this.state;
        return (
      <OuterComponent>
        <StyledDiv>
          <div>Day:</div>
          <div>
            {this.isAdded() ? (
              this.getSelectedDayName()
            ) : (
              <select
                value={day}
                onChange={(e) => this.setState({ day: e.target.value })}
              >
                {Days.map((d) =>
                  // d.value == 8 && roster.length > 0 ? (
                  //   <></>
                  // ) : (
                   ( <option value={d.value}>{d.display}</option>)
                )}
              </select>
            )}
          </div>
        </StyledDiv>
        <StyledDiv>
          <div>Starts from (hour):</div>
          <div>
            {this.isAdded() ? (
              dayTime.startHour
            ) : (
              <input
                value={startHour}
                onChange={(e) => this.setState({ startHour: e.target.value })}
              />
            )}
          </div>
        </StyledDiv>
        <StyledDiv>
          <div>Ends at (hour):</div>
          <div>
            {this.isAdded() ? (
              dayTime.endHour
            ) : (
              <input
                value={endHour}
                onChange={(e) => this.setState({ endHour: e.target.value })}
              />
            )}
          </div>
        </StyledDiv>
        <StyledDiv>
          {loading ? (
            <LoadingComponent />
          ) : this.isAdded() ? (
            <FaWindowClose onClick={this.handleDeleteFromRoster} />
          ) : (
            <FaPlusCircle
              onClick={this.handleAddToRoster}
              // style={{
              //   pointerEvents: roster.length > 6 ? 'none' : 'unset',
              //   opacity: roster.length > 6 ? 0.5 : 1,
              // }}
            />
          )}
        </StyledDiv>
      </OuterComponent>
    );
  }
}

export default RosterDayTimePicker;
