import React from 'react';
import LoadingComponent from "../common/LoadingComponent";

import { addAllActiveTests } from '../../services/api/lab-partners';
import {toast} from "react-toastify";

class AddAllActiveTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleAddAllActiveTests = () => {
        const { centerId, partnerId } = this.props;
        this.setState({
            loading: true,
        });
        return addAllActiveTests(partnerId, centerId)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    toast.success('Successfully Added the tests');
                    this.props.onComplete();
                    return;
                }
                throw new Error(body.errorMessage || 'Error while adding active tests');
            })
            .catch((err) => {
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            });
    };

    render() {
        const { loading } = this.state;
        return (
            <React.Fragment>
                {(loading) ? (
                    <LoadingComponent />
                ) : (
                    <button onClick={this.handleAddAllActiveTests} className="btn btn-primary btn-block">Add All Active Tests</button>
                )}
            </React.Fragment>
        );
    }
}

export default AddAllActiveTest;
