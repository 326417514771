import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Toast,
  ToastBody,
  ToastHeader,
} from 'reactstrap';
import { abhiReimbursementStatus } from '../../services/api/customer-support';
import ValidationModal from '../opd-management/validationModal';
import csvFileValidator from 'csv-file-validator';
import moment from 'moment';

function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes(),
    second = d.getSeconds();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-') + ' ' + [hour, minute, second].join(':');
}

const requiredError = (headerName, rowNumber, columnNumber) => {
  return `${headerName} is required in the ${rowNumber} row ${columnNumber} column`;
};

const dateError = (DOB) => {
  const dob = moment(DOB, 'DD-MM-YYYY', true).isValid() ? true : false;
  // const dob = moment(DOB,'YYYY-MM-DD', true).isValid() ? true : false
  return dob;
};
const tpaClaimCheck = (data) => {
  const regex = /r-[0-9]+/;
  const result = regex.test(data);
  return result;
};

const checkData = (data) => {
  if (data) {
    const regex = /^([-0-9.><%,]|NULL)*$/;
    const result = regex.test(data);
    return result;
  }
};

const config = {
  headers: [
    {
      name: 'TPA CLAIM NO',
      inputName: 'tpaClaimNo',
      required: true,
      requiredError,
      validate: tpaClaimCheck,
    },
    {
      name: 'ABHI Claim No',
      inputName: 'abhiClaim',
      required: true,
      requiredError,
    },
    {
      name: 'Claims status',
      inputName: 'claimSta',
      required: true,
      requiredError,
    },
    {
      name: 'Recommend',
      inputName: 'recommend',
      required: true,
      requiredError,
    },
    {
      name: 'TDS',
      inputName: 'tds',
      required: true,
      requiredError,
    },
    {
      name: 'Payable Amt',
      inputName: 'paybleAmt',
      required: true,
      requiredError,
    },
    {
      name: 'Coinsurance',
      inputName: 'coinsurance',
      required: true,
      requiredError,
    },
    {
      name: 'Co-Ins %',
      inputName: 'coins',
      required: true,
      requiredError,
    },
    {
      name: 'Coin Res.',
      inputName: 'coinRes',
      // required:true,
      // validate:checkData
    },
    {
      name: 'Process Date',
      inputName: 'processDate',
      required: true,
      // requiredError,
      // validate: dateError
    },
    {
      name: 'Payment Date',
      inputName: 'paymentDate',
      required: true,
      requiredError,
      validate: dateError,
    },
    {
      name: 'UTR No.',
      inputName: 'utrNo',
      required: true,
      requiredError,
    },
    {
      name: 'Status',
      inputName: 'status',
      required: true,
      requiredError,
    },
  ],
};

const Element = (props) => {
  const [sheetAt, setSheetAt] = useState(() => new Date());

  const [uploading, setUploading] = useState(() => false);
  const [confirmUpload, setConfirmUpload] = useState(() => false);

  const [response, setResponse] = useState(() => {
    return { show: false, color: 'success', message: '' };
  });
  const [showValidationModal, setshowValidationModal] = useState(true);
  const [inValidData, setInValidData] = useState([]);

  const submitFormData = () => {
    const file = document.querySelector('#abhiRbsStatus').files[0];
    console.log(file, '[file]');
    const formData = new FormData();
    formData.append('sheetAt', formatDate(sheetAt));
    formData.append('file', file);

    console.log(formData, '[formData11]');
    csvFileValidator(file, config).then((csvData) => {
      console.log(csvData, '[csvData]');
      if (csvData.inValidData.length) {
        setshowValidationModal(true);
        setInValidData(csvData.inValidData);
      } else {
        setUploading(true);
        abhiReimbursementStatus(formData)
          .then((res) => {
            if (res.message === 'success') {
              setResponse(() => {
                return {
                  show: true,
                  message:
                    'Abhi Reimbursement Status File Succesfully Uploaded',
                  color: 'success',
                };
              });

              // props.getUpdatedDetails();
            } else {
              setResponse(() => {
                return {
                  show: true,
                  message:
                    res.errorMessage ||
                    'Failed to upload Abhi Reimbursement Status File',
                  color: 'danger',
                };
              });
            }
          })
          .catch((err) => {
            console.log(err, '');
            setResponse(() => {
              return {
                show: true,
                message:
                  err.errorMessage ||
                  'Failed to upload Abhi Reimbursement Status File',
                color: 'danger',
              };
            });
          })
          .finally(() => {
            setUploading(() => false);
            setConfirmUpload(() => false);
          });
      }
    });
  };

  return (
    <div>
      <div
        className={'row'}
        style={{ border: '1px solid rgb(230,230,230)', 'border-radius': '4px' }}
      >
        <div className={'col-md-12'}>
          <Toast isOpen={response.show}>
            <ToastHeader icon={response.color}>Response</ToastHeader>
            <ToastBody>{response.message}</ToastBody>
          </Toast>
          <Form>
            <FormGroup inline>
              <Label for="" sm={5}>
                <b>1) Sheet Gmail Thread Timing</b>
              </Label>
              <br />
              <div style={{ 'margin-left': '24px' }}>
                <DateTimePicker
                  format={'yyyy-MM-dd HH:mm:ss'}
                  onChange={setSheetAt}
                  value={sheetAt}
                />{' '}
                &nbsp;&nbsp;(YYYY-MM-DD)(HH:MM:SS) (24 Hr Clock) (Select Sheet
                Timings to Minute Precision)
              </div>
            </FormGroup>

            {/* <br /> */}
            <FormGroup inline>
              <Label for="abhiRbsStatus" sm={5}>
                <b>
                  2) ABHI Reimbursement Status File (Get Visit) (CSV Format)
                </b>
              </Label>
              <Col sm={7}>
                <Input
                  type="file"
                  accept="image/*,.pdf,.csv"
                  name="abhiRbsStatus"
                  id="abhiRbsStatus"
                />
              </Col>
            </FormGroup>
            <Button
              color="info"
              onClick={() => setConfirmUpload(() => true)}
              style={{ marginBottom: '1rem' }}
            >
              Upload
            </Button>
          </Form>
        </div>
      </div>
      <Modal isOpen={confirmUpload}>
        <ModalBody>Are you sure about uploading this file ?</ModalBody>
        <ModalFooter>
          <Button
            disabled={uploading}
            color="primary"
            onClick={() => submitFormData()}
          >
            {uploading ? 'Uploading' : 'Upload'}
          </Button>
          <Button
            color="secondary"
            disabled={uploading}
            onClick={() => {
              setConfirmUpload(() => false);
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {inValidData.length !== 0 && showValidationModal && (
        <ValidationModal
          closeStatusModal={() => setshowValidationModal(false)}
          errors={inValidData}
        />
      )}
    </div>
  );
};

export default Element;
