import React, { Component } from 'react'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getNotificationRemark } from '../../../services/api/reimburse-claims';

const Notification = styled.div`
    background: white;
    color: black;
    border-radius: 0.3rem;
    padding: 0.2rem;
    margin: 1rem 0.5rem;
    cursor: pointer;
`;

const Container = styled.div`
    position: absolute;
    max-width: 25rem;
    max-height: 30rem;
    min-width: 25rem;
    min-height: 30rem;
    background-color: #e6e6e6;
    color: black;
    border-radius: 0.3rem;
    padding: 1rem;
    right: 1.5rem;
`;

const TopContainer = styled.div`

`;

const UserName = styled.em`
    color: #3a3a3b;
    float: left;
`;

const CommentDateTime = styled.em`
    float: right;
    color: #3a3a3b;
`;

const CommentBody = styled.div`
    font-size: 0.9rem;
    text-align: justify;
    display: inline-block;
    width: 100%;
`;

const NotificationTop = styled.div`
    min-height: 1rem;
    font-size: .8rem;
`;

const BodyContainer = styled.div`
    overflow: auto;
    max-height: 26rem;
`;

class ReimbursementNotificationContainer extends Component {
    constructor(props){
        console.log(props,'[ReimbursementNotificationContainer]')
        super(props)
        this.state={
            reimburseNotification:[]
        }
        this.wrapperRef = React.createRef();
    }

    componentWillMount(){
        this.fetchNotification();
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }


    fetchNotification = () => {
       return getNotificationRemark()
       .then((response)=>{
        if (response && Array.isArray(response.data.results)) {
            this.setState({
                reimburseNotification: response.data.results,
            });
        }
       }).catch((err)=>{
            console.log(err,'[err]')
       })
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            this.props.close();
        }
    }

    handleNotificationClick = (requestId) =>{   
        console.log(requestId,'[requestId]')
        if(requestId){
            this.props.history.push(`/reimburse-claim/${requestId}`);
            return;
        }
    }


  render() {
    return (
        <Container ref={this.wrapperRef}>
            <TopContainer>
                Reimbursement Notifications: <strong>{this.state.reimburseNotification.length || 0}</strong>
            </TopContainer>
            <BodyContainer>
                {this.state.reimburseNotification && this.state.reimburseNotification.map(ele=>(
                    <Notification key={ele.commentId} onClick={() => (this.handleNotificationClick(ele.requestId))}>
                        <NotificationTop>
                            <UserName>{ele.opsUserName}({ele.requestId})</UserName>
                            <CommentDateTime>{ele.commentedTime}</CommentDateTime>
                        </NotificationTop>
                        <CommentBody>{ele.remarks}</CommentBody>
                    </Notification>
                ))}
            </BodyContainer>
        </Container>
    )
  }
}

export default connect()(withRouter(ReimbursementNotificationContainer))