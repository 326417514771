import styled from 'styled-components';
import { FaCircleNotch } from 'react-icons/fa';

export const StyledHistoryContiner = styled.div`
  background-color: '';
`;

export const Container = styled.div`
    display: flex;
`;

export const ContainerItem = styled.div`
    flex-grow: 1;
    margin: 7px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
`;

export const LoadingIcon = styled(FaCircleNotch)`
  color: white;
  margin-right: 0.5rem;
`;

export const HistoryItem = styled.div`
  border: 1px #0000003b solid;
  padding: 3px;
  padding-left: 5px;
  border-radius: 3px;
  border-top: 0;
`;

export const StyledHistoryContainer = styled.div`
    border: 1px #0000003b solid;
    padding: 3px;
    border-radius: 3px;
    max-height: 200px;
    overflow: auto;
`;

export const TimeContainer = styled.div`
  flex-grow: 1;
  font-size: x-small;
  float: right;
  padding-top: 5px;
  padding-right: 5px;
`;
