import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col } from 'reactstrap';
import UserDetail from './UserDetail';
import ActionDetail from './ActionDetail';
import UserActions from './UserActions';
import { connect } from 'react-redux';
import { setUserDetails } from '../../actions';
import UserBankDetails from './UserBankDetails';
import AbhiReimbursementStatus from './AbhiReimbursementStatus';
import LinkGmcAccount from './LinkGmcAccount';
import PolicyDetails from '../policy-details/PolicyDetails';
import AuthBenefitsDetails from '../authBenefits/authBenefits';
import OpdWallet from './opdWallet';
import EditUserDetails from './EditUserDetails';
import AkzoReimbursementStatus from './AkzoReimburse'
import ShlReimbursementStatus from './ShlReimburse'
import NewPolicyDetails from '../policy-details/NewPolicyDetails';

const StyledCol = styled(Col)`
  display: 'flex',
  flex-direction: 'column',
  align-items: 'stretch',
  flex: 1 0,
`;

class UserPortal extends Component {
  constructor(props) {
    super(props);
    // console.log(props);
    this.state = {
      actionRequest: 'policy-details',
      showActionDetail: true,
      showWalletDetails: false,
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevProps.userDetail.length !== this.props.userDetail.length ||
      (this.props.userDetail &&
        prevProps.userDetail[0].id !== this.props.userDetail[0].id)
    ) {
      this.setState({
        actionRequest: 'support-notes',
        showActionDetail: false,
      });
    }
  };
  stepsRequest = () => {
    this.setState({
      actionRequest: 'fit-data',
      showActionDetail: true,
    });
  };
  rewardsRequest = () => {
    this.setState({
      actionRequest: 'ft-tasks',
      showActionDetail: true,
    });
  };
  invitesRequest = () => {
    this.setState({
      actionRequest: 'ft-invites',
      showActionDetail: true,
    });
  };
  redemptionsRequest = () => {
    this.setState({
      actionRequest: 'ft-shop',
      showActionDetail: true,
    });
  };
  reimbursementClaims = () => {
    this.setState({
      actionRequest: 'reimburse-claims',
      showActionDetail: true,
    });
  };

  gmcPolicyDetails = () => {
    this.setState({
      actionRequest: 'gmc-policy-details',
      showActionDetail: true,
    });
  };
  shareRequest = () => {
    this.setState({
      actionRequest: 'ft-share',
      showActionDetail: true,
    });
  };
  walletBalance = () => {
    this.setState({
      actionRequest: 'ft-wallet',
      showActionDetail: true,
    });
  };
  offlineConsultations = () => {
    this.setState({
      actionRequest: 'consult',
      showActionDetail: true,
    });
  };
  supportNotes = () => {
    this.setState({
      actionRequest: 'support-notes',
      showActionDetail: true,
    });
  };
  userActions = () => {
    this.setState({
      actionRequest: 'user-actions',
      showActionDetail: true,
    });
  };
  fulfilmentDetails = () => {
    this.setState({
      actionRequest: 'user-fulfilments',
      showActionDetail: true,
    });
  };
  abhiReimbursementStatus = () => {
    this.setState({
      actionRequest: 'abhi-reimbursement-status',
      showActionDetail: true,
    });
  };
  updateUserDetails = () => {
    this.setState({
      actionRequest: 'user-updateDetails',
      showActionDetail: true,
    });
  };
  gmcLink = () => {
    this.setState({
      actionRequest: 'gmc-link',
      showActionDetail: true,
    });
  };
  policyDetails = () => {
    this.setState({
      actionRequest: 'policy-details',
      showActionDetail: true,
    });
  };
  walletDetails = () => {
    this.setState({
      actionRequest: 'wallet-transactions',
      showWalletDetails: true,
      showActionDetail: true,
    });
  };
  akzoReimbursementStatus = () => {
    this.setState({
      actionRequest:'akzo-reimbursement-status',
      showActionDetail:true,
    })
  }

  shlReimbursementStatus = () => {
    this.setState({
      actionRequest:'shl-reimbursement-status',
      showActionDetail:true,
    })
  }

  authBenefitsDetails = () => {
    this.setState({
      actionRequest:'auth-benefits',
      showActionDetail:true,
    })
  }

  setParentDetails = (element) => {
    this.props.dispatch(setUserDetails([element]));
  };

  render() {
    const renderDetailsPane = () => {
      switch (this.state.actionRequest) {
        case 'user-actions':
          return <UserActions userId={this.props.userDetail[0].id} />;
        case 'user-fulfilments':
          return (
            <UserBankDetails
              userId={this.props.userDetail[0].id}
              getUpdatedDetails={this.props.getUpdatedDetails}
            />
          );
        case 'abhi-reimbursement-status':
          return (
            <AbhiReimbursementStatus
              userId={this.props.userDetail[0].id}
              getUpdatedDetails={this.props.getUpdatedDetails}
            />
          );
        case 'gmc-link':
          return (
            <LinkGmcAccount
              userId={this.props.userDetail[0].id}
              getUpdatedDetails={this.props.getUpdatedDetails}
            />
          );
        case 'policy-details':
          return (
            <NewPolicyDetails
              userId={this.props.userDetail[0].id}
              getUpdatedDetails={this.props.getUpdatedDetails}
              allowRelativeEdit={true}
            />
          );
        case 'wallet-transactions':
          return (
            <OpdWallet
              userId={this.props.userDetail[0].id}
              actionRequest={this.state.actionRequest}
              showWalletDetails={this.state.showWalletDetails}
              showCSVDownload ={this.props.userInfo.downloadWalletCSV}
            />
          );
        case 'user-updateDetails':
          return (
            <EditUserDetails
              userId={this.props.userDetail[0].id}
              getUpdatedDetails={this.props.getUpdatedDetails}
            />
          );
        case 'akzo-reimbursement-status':
          return (
            <AkzoReimbursementStatus
              userId={this.props.userDetail[0].id}
              getUpdatedDetails={this.props.getUpdatedDetails}
            />
          )
          case 'shl-reimbursement-status':
            return (
              <ShlReimbursementStatus
                userId={this.props.userDetail[0].id}
                getUpdatedDetails={this.props.getUpdatedDetails}
              />
            )
            case 'auth-benefits':
              return (
                <AuthBenefitsDetails
                  userId={this.props.userDetail[0].id}
                  getUpdatedDetails={this.props.getUpdatedDetails}
                />
              )
        default:
          return (
            <ActionDetail
              actionRequest={this.state.actionRequest}
              // showWalletDetails={this.state.showWalletDetails}
              userId={this.props.userDetail[0].id}
            />
          );
      }
    };
    return (
      <div className="container-fluid">
        <Row>
          <Col md={{ size: 4 }}>
            <UserDetail
              userDetail={this.props.userDetail}
              setUserDetailsParent={this.setParentDetails}
              stepsRequest={this.stepsRequest}
              rewardsRequest={this.rewardsRequest}
              invitesRequest={this.invitesRequest}
              redemptionsRequest={this.redemptionsRequest}
              reimbursementClaims={this.reimbursementClaims}
              gmcPolicyDetails={this.gmcPolicyDetails}
              shareRequest={this.shareRequest}
              walletBalance={this.walletBalance}
              offlineConsultations={this.offlineConsultations}
              supportNotes={this.supportNotes}
              userActions={this.userActions}
              fulfilmentDetails={this.fulfilmentDetails}
              abhiReimbursementStatus={this.abhiReimbursementStatus}
              gmcLink={this.gmcLink}
              policyDetails={this.policyDetails}
              walletDetails={this.walletDetails}
              editUserDetails={this.updateUserDetails}
              akzoReimbursementStatus={this.akzoReimbursementStatus}
              shlReimbursementStatus={this.shlReimbursementStatus}
              authPlanDetails = {this.authBenefitsDetails}
              getUpdatedDetails={this.props.getUpdatedDetails}
            />
          </Col>
          <StyledCol md={{ size: 8 }}>
            {this.state.showActionDetail && renderDetailsPane()}
          </StyledCol>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.customerSupport.userDetail,
  showBody: state.customerSupport.showBody,
  userInfo : state.user,
});

export default connect(mapStateToProps)(UserPortal);
