import baseRequest from './baseRequest';

export const getPoolManagerHome = () => {
  const url = '/new-ops/pool-manager';
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const getAllVerticalsForPoolManager = () => {
  const url = '/new-ops/pool-manager/get-all-verticals';
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const getDoctorStats = (page, pageSize, sorted, filtered) => {
  const url = '/new-ops/pool-manager/doctor-live-stats';
  const method = 'GET';
  return baseRequest(
    url,
    method,
    {
      page,
      pageSize,
      sorted,
      filtered,
    },
    null
  );
};

export const getSponsorsAndPools = (page, pageSize, sorted, filtered) => {
  const url = '/new-ops/pool-manager/pool-sponsor';
  const method = 'GET';
  return baseRequest(
    url,
    method,
    {
      page,
      pageSize,
      sorted,
      filtered,
    },
    null
  );
};

export const getDoctorsForPool = (poolId) => {
  const url = `/new-ops/pool-manager/${poolId}/doctors`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const changeDoctorActivity = (poolId, doctorId, active) => {
  console.log({ poolId, doctorId, active });
  const url = `/new-ops/pool-manager/activity`;
  const method = 'POST';
  return baseRequest(url, method, null, {
    poolId,
    doctorId,
    active: active ? 1 : 0,
  });
};

export const removeDoctor = (poolId, doctorId) => {
  const url = `/new-ops/pool-manager/remove`;
  const method = 'DELETE';
  return baseRequest(url, method, null, { poolId, doctorId });
};

export const updatePriority = (
  poolId,
  doctorId,
  priority,
  specialists,
  type
) => {
  const url = '/new-ops/pool-manager/priority';
  const method = 'POST';
  return baseRequest(url, method, null, {
    poolId,
    doctorId,
    priority,
    specialists,
    type,
  });
};

export const addDoctor = (poolId, doctorId, practiceId) => {
  const url = `/new-ops/pool-manager/add`;
  const method = 'POST';
  return baseRequest(url, method, null, { poolId, doctorId, practiceId });
};

export const markDoctorActiveInAllPools = (doctorId) => {
  const url = `/new-ops/pool-manager/mark-doctor-active`;
  const method = 'POST';
  return baseRequest(url, method, null, { doctorId, source: 'DASHBOARD' });
};

export const markDoctorInactiveInAllPools = (doctorId) => {
  const url = `/new-ops/pool-manager/mark-doctor-inactive`;
  const method = 'POST';
  return baseRequest(url, method, null, { doctorId, source: 'DASHBOARD' });
};

export const searchDoctors = (q) => {
  const url = '/new-ops/pool-manager/search';
  const method = 'GET';
  return baseRequest(url, method, { q }, null);
};

export const fetchRosterForPoolAndDoctor = (doctorId, poolId) => {
  const url = '/new-ops/pool-manager/roster';
  const method = 'GET';
  return baseRequest(url, method, { doctorId, poolId }, null);
};

export const removeFromRoster = (rosterId, doctorId, poolId, roster) => {
  const url = '/new-ops/pool-manager/roster/remove';
  const method = 'POST';
  return baseRequest(url, method, null, { rosterId, doctorId, poolId, roster });
};

export const addToRoster = (roster, doctorId, poolId) => {
  const url = '/new-ops/pool-manager/roster/add';
  const method = 'POST';
  return baseRequest(url, method, null, { roster, doctorId, poolId });
};

export const addPool = (poolName, poolId) => {
  const url = '/new-ops/pool-manager/add-pool';
  const method = 'POST';
  return baseRequest(url, method, null, { poolName, poolId });
};

export const editPool = (poolId, sponsorId) => {
  const url = '/new-ops/pool-manager/update-pool';
  const method = 'PUT';
  return baseRequest(url, method, null, { poolId, sponsorId });
};

export const editPoolForSponsor = (poolId, sponsorId) => {
  const url = '/new-ops/pool-manager/update-pool-for-sponsor';
  const method = 'POST';
  return baseRequest(url, method, null, { poolId, sponsorId });
};

export const addLab = (labName, commonName, active, fasting, type) => {
  const url = '/new-ops/labs/addLab';
  const method = 'POST';
  return baseRequest(url, method, null, {
    labName,
    commonName,
    active,
    fasting,
    type,
  });
};
