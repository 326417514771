export const defaultState = {
    userId: null
};

export default function createRxRequest(state = defaultState, action) {
    switch (action.type) {
        case 'SET_CREATE_ORDER_DETAILS': {
            const userInfo = action.payload.userInfo;
            return Object.assign({}, state, {
                userInfo,
            });
        }

        case 'NEW_RX_SET_PRESCRIPTION_ID': {
            const newRequestPrescriptionId = action.payload.newRequestPrescriptionId;
            return Object.assign({}, state, {
                newRequestPrescriptionId,
            });
        }

        case 'NEW_RX_SET_PATIENT': {
            const patient = action.payload.patient;
            return Object.assign({}, state, {
                patient,
            });
        }

        case 'NEW_RX_SET_ADDRESS': {
            const address = action.payload.address;
            return Object.assign({}, state, {
                address,
            });
        }

        case 'NEW_RX_SET_COLLECTION_DETAILS': {
            const collectionDetails = action.payload.collectionDetails;
            return Object.assign({}, state, {
                collectionDetails,
            });
        }



        default:
            return state;
    }
}
