export const defaultState = {
    requestId: null,
    requestDetails: null
}

export default function CashlessDental(state = defaultState, action) {
    switch (action.type) {
        case "SHOW_DENTAL_REQUEST_MODAL": {
            const showRequestDetails = action.payload.showRequestDetails;
            const requestId = action.payload.requestId;
            return Object.assign({}, state, {
                requestId,
                showRequestDetails
            })
        }
        case "HIDE_DENTAL_REQUEST_MODAL": {
            const showRequestDetails = action.payload.showRequestDetails;
            const requestId = action.payload.requestId;
            return Object.assign({}, state, {
                requestId,
                showRequestDetails
            })
        }
        default:
            return state
    }
}