import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import StyledTappable from '../common/StyledTappable';
import {
  OuterComponent,
  PageTitle,
  customStyles,
  CloseModal,
} from '../common/modal/StyledComponent';
import { Row, Col } from 'reactstrap';
import {
  opdOnboarding,
  opdDeOnboarding,
  deleteOpdPreOnboarding,
} from '../../services/api/opd-management';
import ResultModal from '../common/modal/ResultModal';

class OpdConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
    };
  }

  confirmOpdOnboarding = () => {
    this.props.toggleLoading();
    // eslint-disable-next-line no-unused-expressions
    opdOnboarding(this.props.submitId)
      .then(resp => {
        console.log(resp);
        if(resp.message === 'success'){
          return opdDeOnboarding(this.props.submitId)
            .then((resp) => {
              console.log(resp, 'de-onboarding');
              this.props.closeModal();
              // this.props.onFetchData();
            });
        }
      })
      .catch(err => {
        console.log(err);
        let errorMessage = err.message;
        if (err.response !== undefined) {
          errorMessage = err.response.data.message;
        }
        console.log(errorMessage);
        this.setState({
          showModal: true,
          modalHeader: 'Parsing Failed',
          modalBody: `Error : "${errorMessage}". Check the csv file and try again.`,
          bgColor: 'bg-danger',
        });
      });
  };
  chanceOpdOnboarding = () => {
    this.props.toggleLoading();
    deleteOpdPreOnboarding(this.props.submitId)
      .then(resp => {
        console.log(resp);
        this.props.closeModalOnCancel();
      })
      .catch(err => {
        console.log(err);
        let errorMessage = err.message;
        if (err.response !== undefined) {
          errorMessage = err.response.data.errorDebug;
        }
        console.log(errorMessage);
        this.setState({
          showModal: true,
          modalHeader: 'Parsing Failed',
          modalBody: `Error : "${errorMessage}". Check the csv file and try again.`,
          bgColor: 'bg-danger',
        });
      });
  };
  showSummary = () => {
    const summary = this.props.summary;
    console.log(summary);
    const selections = [];
    let totalDependent = 0;
    summary.forEach(element => {
      console.log(element);
      if (element.RELATIONSHIP === 'SELF') {
        selections.push(
          <Row>
            <Col name={element.RELATIONSHIP}> {element.RELATIONSHIP}:</Col>
            <Col name={element.membersCount}>{element.membersCount}</Col>
          </Row>
        );
      } else {
        totalDependent += element.membersCount;
        selections.push(
          <Row>
            <Col name={element.RELATIONSHIP}> {element.RELATIONSHIP}:</Col>
            <Col name={element.membersCount}>{element.membersCount}</Col>
          </Row>
        );
      }
    });
    selections.push(
      <Row>
        <Col name="Dependents"> Total no. of Dependents:</Col>
        <Col name="totalDependent">{totalDependent}</Col>
      </Row>
    );
    return selections;
  };
  render() {
    return (
      <Modal isOpen toggle={this.chanceOpdOnboarding} style={customStyles}>
        <OuterComponent>
          <div>
            <p className="text-success">Success</p>
            <StyledTappable onTap={this.chanceOpdOnboarding}>
              <CloseModal />
            </StyledTappable>
          </div>
          <ModalBody>
            <u>Summary:</u>
            {this.showSummary()}
            <Row>
              <Col> Total Premium:</Col>
              <Col>{this.props.premium}</Col>
            </Row>
            Are you sure onboarding should be done now and the above details are
            correct ?
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.confirmOpdOnboarding}>Confirm</Button>
            <Button onClick={this.chanceOpdOnboarding}>Cancel</Button>
          </ModalFooter>
        </OuterComponent>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeStatusModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
      </Modal>
    );
  }
}

export default OpdConfirmModal;
