import React, { Component } from 'react';
import { connect } from 'react-redux';

import { storeCommentRequest } from '../../../services/api/reimburse-claims';

class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: '',

      comment: {
        name: '',
        message: '',
      },
    };

    // bind context to methods
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * Handle form input field changes & update the state
   */
  handleFieldChange = (event) => {
    const { value } = event.target;

    this.setState({
      comment: {
        name: this.props.user.name,
        message: value,
      },
    });
  };

  /**
   * Form submit handler
   */
  onSubmit(e) {
    // prevent default form submission
    e.preventDefault();

    if (!this.isFormValid()) {
      this.setState({ error: 'No Updates?' });
      return;
    }

    // loading status and clear error
    this.setState({ error: '', loading: true });

    // persist the comments on server
    let { comment } = this.state;
    storeCommentRequest(
      this.props.reimbursementId,
      comment.message,
      this.props.commentType
    )
      .then((res) => {
        // add time return from api and push comment to parent state
        comment.time = 'Just Now';
        this.props.addComment(comment);

        // clear the message box
        this.setState({
          loading: false,
          comment: {
            name: '',
            message: '',
          },
        });
      })
      .catch((err) => {
        console.log({ err });
        this.setState({
          error: 'Something went wrong.',
          loading: false,
        });
      });
  }

  /**
   * Simple validation
   */
  isFormValid() {
    return this.state.comment.name !== '' && this.state.comment.message !== '';
  }

  renderError() {
    return this.state.error ? (
      <div className="alert alert-danger">{this.state.error}</div>
    ) : null;
  }

  render() {
    return (
      <React.Fragment>
        <form method="post" onSubmit={this.onSubmit}>
          <div className="form-group mt-1">
            <textarea
              onChange={this.handleFieldChange}
              value={this.state.comment.message}
              className="form-control"
              placeholder="Post an important update..."
              name="message"
              rows="3"
            />
          </div>

          {this.renderError()}

          <div className="form-group">
            <button disabled={this.state.loading} className="btn btn-primary">
              Comment &#10148;
            </button>
          </div>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(CommentForm);
