import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';

export default class ConfirmModal extends React.Component {
  submitModal() {
    const { onSubmit } = this.props;
    this.setState({ modal: false }, onSubmit);
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedValue: '',
      colorGreading: false,
    };
  }

  render() {
    const { selectedValue, colorGreading } = this.state;
    const className = 'bg-info';
    const { modalHeader, children, onSubmit, isOpen, onClose, loading } =
      this.props;

    const selectStyles = {
      border: colorGreading ? '2px solid #17a2b8' : '2px solid #abe7f0',
      height: '40px',
      width: '100%',
      padding: '5px',
      borderRadius: '5px',
      marginBottom: '10px',
      // appearance: 'none', /* Remove default arrow */
      // backgroundImage: `url('data:image/svg+xml;utf8,<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="currentColor" d="M7.778 10.65l3.6 3.6a.5.5 0 00.707 0l3.6-3.6a.5.5 0 00-.707-.708L11 13.293V6.5a.5.5 0 00-1 0v6.793l-2.072-2.072a.5.5 0 00-.707.708z"/></svg>')`, /* Custom arrow */
      // backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right 20px top 50%',
      backgroundSize: '20px 20px',
    };

    const optionStyles = {
      cursor: 'pointer',
    };
    const headingStyles = {
      fontSize: '16px',
      fontFamily: 'Arial, sans-serif', // Specify font family here
      // fontWeight: 'bold', // Specify font weight here
      color: '#333', // Specify text color here
    };

    let dropdownSelectedValue = '';
    if (this.props.isCallModalOpen && this.props.selectedValue != null) {
      dropdownSelectedValue = this.props.selectedValue;
    }

    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader className={className} toggle={onClose}>
          {modalHeader}
        </ModalHeader>
        {this.props.isCallModalOpen ? (
          <ModalBody>
            <div>
              <h4 style={headingStyles}>Select call reason</h4>
            </div>
            <select
              value={dropdownSelectedValue}
              onChange={this.props.handleChange}
              style={selectStyles}
            >
              {
                <option value="" disabled hidden>
                  Select an option
                </option>
              }
              {this.props.callReasonOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </ModalBody>
        ) : (
          <ModalBody>{children}</ModalBody>
        )}
        <ModalFooter>
          <Button
            color="primary"
            onClick={onSubmit}
            disabled={!this.props.selectedValue || loading}
          >
            {loading ? 'Loading...' : 'Confirm'}
          </Button>
          {!loading && (
            <Button color="secondary" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
