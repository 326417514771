import React, { useState } from 'react';

const PayoutFilter = ({ onChangeValue }) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    const selectedValue = event.target.value === '' ? null : event.target.value;
    setValue(selectedValue);
    onChangeValue(selectedValue);
  };

  return (
    <select
      onChange={handleChange}
      style={{
        width: '100%',
        height: '40px',
        fontSize: '12px',
        borderRadius: '10px',
      }}
      value={value}
    >
      <option value="all">All</option>
      <option value="Pending">Pending</option>
      <option value="Approved">Approved</option>
      <option value="Declined">Declined</option>
      <option value="Processing">Processing</option>
      <option value="Not Raised">Not Raised</option>
    </select>
  );
};

export default PayoutFilter;
