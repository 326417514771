import React, { useEffect, useState } from 'react'
import { getSharableLinks } from '../api'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import LoadingComponent from '../../common/LoadingComponent'
import { FaCopy } from 'react-icons/fa'
import { toast } from 'react-toastify'

const FileLinks = ({
    isOpen,
    onClose = () => {},
    orderId,
    className = 'bg-info'
}) => {

    const [urls, setUrls] = useState([])

    useEffect(() => {

        if (!orderId) {
            return
        }
        getSharableLinks({ orderId })
            .then((response) => {
                setUrls(response.data.map(item => item.url))
            })
            .catch(err => console.log(err))

    }, [orderId])


    return <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader className={className} toggle={onClose}>
            View Uploaded Files
        </ModalHeader>
        <ModalBody>
            {urls.length ? <div>
                {urls.map(item => <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    background: '#ddd',
                    borderRadius: '5px',
                    marginBottom: '5px',
                    padding: '5px'
                }} key={item}>
                    <a href={item} target="_blank" rel="noopener noreferrer">{item.substring(0, 40)}...</a>
                    <div onClick={() => {
                        navigator.clipboard.writeText(item)
                            .then(() => toast.success('Copied to clipboard'))
                            .catch(() => toast.error('Failed to copy, please try in some time'))
                    }} style={{ cursor: 'pointer' }}>
                        <FaCopy />
                    </div>
                </div>)}
            </div> : <LoadingComponent />}
        </ModalBody>
    </Modal>
}

export default FileLinks