import React, { Component } from 'react';
import { connect } from 'react-redux';

// Layouts
import NavBar from '../layout/NavBar';
import loginCheck from '../login/LoginCheck';
import DoctorPoolBody from './DoctorPoolBody';

class DoctorPoolComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <NavBar />
        <DoctorPoolBody />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  offlineConsult: state.offlineConsult
});

export default connect(mapStateToProps)(loginCheck(DoctorPoolComponent));
