import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button, Card, Col, Row, Select } from 'antd';

import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  addDataForNetworkCenter,
  fetchCortisData,
} from '../../services/api/offline-consult';

const { Option } = Select;

const Text = styled.p`
  margin: 0;
  font-family: BrandonText-Medium, Lato, sans-serif;
`;

const SemiboldText = styled(Text)`
  font-family: ProximaNova-Semibold, Lato, sans-serif;
`;

const LabelText = styled(SemiboldText)`
  font-size: 12px;
  text-align: center;
  margin-bottom: 0.25rem;
  margin-top: 0.625rem;
  color: #7c7c7c;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;
  /* over */
`;

const AddZohoModal = (props) => {
  const { isModalOpen, closeZohoModal, centerId, centerName, fulfilmentId } =
    props;
  const [zohoId, setZohoId] = useState('');
  const [zohoData, setZohoData] = useState([]);

  useEffect(() => {
    fetchBillingEntities();
  }, []);

  const fetchBillingEntities = async () => {
    const data = await fetchCortisData();
    console.log(data);
    setZohoData(data.result);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedZohoCompany = zohoData.find(
      (item) => item.cortisBillingEntityId === zohoId
    );
    if (!selectedZohoCompany) {
      toast.error('Zoho Company not found');
      return;
    }

    const body = {
      zohoId,
      zohoCompanyName: selectedZohoCompany.zohoCompanyName,
      zohoDisplayName: selectedZohoCompany.zohoVendorName,
      requestType: 'Add_Zoho_Details',
      centerId: centerId,
      centerName: centerName,
      fulfilmentId: fulfilmentId,
    };

    if (!zohoId) {
      toast.error('Please fill all the required fields');
      return;
    }

    const data = await addDataForNetworkCenter(body);
    if (data.message == 'success') {
      closeZohoModal();
      toast.success('Zoho Requested Created Successfully');
    }
    if (data.message == 'serverError') {
      toast.error(data.errorMessage);
    }
    console.log(data);
  };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader
        className="revamp-modal-header"
        toggle={() => closeZohoModal()}
      >
        {`Map Zoho Id for ${centerName}`}
      </ModalHeader>
      <ModalBody>
        <Card>
          <BodyContainer>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <LabelText>Select Zoho Company:</LabelText>
                <Select
                  value={zohoId}
                  style={{ width: '100%' }}
                  onChange={(e) => setZohoId(e)}
                >
                  {zohoData.map((item, index) => (
                    <Option key={index} value={item.cortisBillingEntityId}>
                      {item.zohoCompanyName}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginTop: 20 }}>
              <Col xs={24} md={12}>
                <Button
                  type="primary"
                  block
                  style={{ marginRight: 10 }}
                  onClick={(e) => handleSubmit(e)}
                >
                  Send Request
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button block onClick={closeZohoModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </BodyContainer>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default AddZohoModal;
