import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { FaBell } from 'react-icons/fa';

import NotificationContainer from './notification/NotificationContainer';

// const NotificationContainer = styled.div`
//     &:after {
//         position:absolute;
//         right:10%;
//         top:8%;
//         content: attr(data-count);
//         font-size:40%;
//         padding:.2em;
//         border-radius:50%;
//         line-height:1em;
//         color: white;
//         background:rgba(255,0,0,.85);
//         text-align:center;
//         min-width: 1em;
//     }
// `;

const FaWhiteBell = styled(FaBell)`
    color: white;
    cursor: pointer;
`;

class NotificationPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    };

    toggle = () => {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    };

    close = () => {
        this.setState({ isOpen: false });
    };

    validate = () => {
        const { user } = this.props;
        return (user && user.token && user.showOps === 1);
    };

    render() {
        if (!this.validate()) {
            return <div />;
        }
        const { isOpen } = this.state;
        return (
            <div>
                <FaWhiteBell style={{color:'#3A2CA0'}} onClick={this.toggle} />
                {isOpen && <NotificationContainer close={this.close} />}
            </div>
        );
    }
};

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps)(NotificationPanel);
