import React from 'react';

const NetworkCenterIcon = () => (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.1436 12.1764H19.4529C19.6854 12.1764 19.8739 11.9879 19.8739 11.7554C19.8739 11.523 19.6854 11.3345 19.4529 11.3345H18.1436C17.9112 11.3345 17.7227 11.523 17.7227 11.7554C17.7227 11.9879 17.9112 12.1764 18.1436 12.1764Z" fill="black"/>
        <path d="M18.1436 14.4835H19.4529C19.6854 14.4835 19.8739 14.295 19.8739 14.0626C19.8739 13.8301 19.6854 13.6416 19.4529 13.6416H18.1436C17.9112 13.6416 17.7227 13.8301 17.7227 14.0626C17.7227 14.295 17.9112 14.4835 18.1436 14.4835Z" fill="black"/>
        <path d="M18.1436 16.8073H19.4529C19.6854 16.8073 19.8739 16.6188 19.8739 16.3863C19.8739 16.1538 19.6854 15.9653 19.4529 15.9653H18.1436C17.9112 15.9653 17.7227 16.1538 17.7227 16.3863C17.7227 16.6188 17.9112 16.8073 18.1436 16.8073Z" fill="black"/>
        <path d="M18.1436 19.1139H19.4529C19.6854 19.1139 19.8739 18.9254 19.8739 18.6929C19.8739 18.4605 19.6854 18.272 19.4529 18.272H18.1436C17.9112 18.272 17.7227 18.4605 17.7227 18.6929C17.7227 18.9254 17.9112 19.1139 18.1436 19.1139Z" fill="black"/>
        <path d="M18.1436 21.4376H19.4529C19.6854 21.4376 19.8739 21.2491 19.8739 21.0167C19.8739 20.7842 19.6854 20.5957 19.4529 20.5957H18.1436C17.9112 20.5957 17.7227 20.7842 17.7227 21.0167C17.7227 21.2491 17.9112 21.4376 18.1436 21.4376Z" fill="black"/>
        <path d="M18.1436 23.7448H19.4529C19.6854 23.7448 19.8739 23.5563 19.8739 23.3238C19.8739 23.0913 19.6854 22.9028 19.4529 22.9028H18.1436C17.9112 22.9028 17.7227 23.0913 17.7227 23.3238C17.7227 23.5563 17.9112 23.7448 18.1436 23.7448Z" fill="black"/>
        <path d="M3.73346 12.1764H4.92909C5.16155 12.1764 5.35005 11.9879 5.35005 11.7554C5.35005 11.523 5.16155 11.3345 4.92909 11.3345H3.73346C3.50099 11.3345 3.3125 11.523 3.3125 11.7554C3.3125 11.9879 3.50099 12.1764 3.73346 12.1764Z" fill="black"/>
        <path d="M3.73346 14.4835H4.92909C5.16155 14.4835 5.35005 14.295 5.35005 14.0626C5.35005 13.8301 5.16155 13.6416 4.92909 13.6416H3.73346C3.50099 13.6416 3.3125 13.8301 3.3125 14.0626C3.3125 14.295 3.50099 14.4835 3.73346 14.4835Z" fill="black"/>
        <path d="M3.73346 16.8073H4.92909C5.16155 16.8073 5.35005 16.6188 5.35005 16.3863C5.35005 16.1538 5.16155 15.9653 4.92909 15.9653H3.73346C3.50099 15.9653 3.3125 16.1538 3.3125 16.3863C3.3125 16.6188 3.50099 16.8073 3.73346 16.8073Z" fill="black"/>
        <path d="M3.73346 19.1139H4.92909C5.16155 19.1139 5.35005 18.9254 5.35005 18.6929C5.35005 18.4605 5.16155 18.272 4.92909 18.272H3.73346C3.50099 18.272 3.3125 18.4605 3.3125 18.6929C3.3125 18.9254 3.50099 19.1139 3.73346 19.1139Z" fill="black"/>
        <path d="M3.73346 21.4376H4.92909C5.16155 21.4376 5.35005 21.2491 5.35005 21.0167C5.35005 20.7842 5.16155 20.5957 4.92909 20.5957H3.73346C3.50099 20.5957 3.3125 20.7842 3.3125 21.0167C3.3125 21.2491 3.50099 21.4376 3.73346 21.4376Z" fill="black"/>
        <path d="M3.73346 23.7448H4.92909C5.16155 23.7448 5.35005 23.5563 5.35005 23.3238C5.35005 23.0913 5.16155 22.9028 4.92909 22.9028H3.73346C3.50099 22.9028 3.3125 23.0913 3.3125 23.3238C3.3125 23.5563 3.50099 23.7448 3.73346 23.7448Z" fill="black"/>
        <path d="M19.1417 1.0127C18.9092 1.0127 18.7207 1.20119 18.7207 1.43366C18.7207 1.66612 18.9092 1.85462 19.1417 1.85462C20.6906 1.86232 22.1735 2.48322 23.2661 3.58133C24.3587 4.67938 24.9719 6.16535 24.9719 7.71444C24.9719 7.82607 25.0163 7.93319 25.0952 8.01212C25.1742 8.09104 25.2813 8.1354 25.3929 8.1354C25.5045 8.1354 25.6117 8.09105 25.6906 8.01212C25.7695 7.93319 25.8139 7.82607 25.8139 7.71444C25.815 5.94169 25.1133 4.24096 23.8626 2.98481C22.6119 1.72852 20.9142 1.01943 19.1416 1.0127H19.1417Z" fill="black"/>
        <path d="M19.1417 2.76807C18.9092 2.76807 18.7207 2.95656 18.7207 3.18903C18.7207 3.30066 18.7651 3.40778 18.844 3.48671C18.9229 3.56564 19.03 3.60999 19.1417 3.60999C20.2258 3.61657 21.2634 4.052 22.0275 4.82101C22.7918 5.58999 23.2208 6.63018 23.2208 7.71438C23.2208 7.82601 23.2652 7.93313 23.3441 8.01206C23.4231 8.09099 23.5302 8.13534 23.6418 8.13534C23.7534 8.13534 23.8604 8.09099 23.9395 8.01206C24.0184 7.93313 24.0628 7.82601 24.0628 7.71438C24.0639 6.40656 23.5465 5.15157 22.6242 4.22454C21.7018 3.29732 20.4495 2.77355 19.1417 2.76811V2.76807Z" fill="black"/>
        <path d="M19.1417 4.52344C18.9092 4.52344 18.7207 4.71174 18.7207 4.9444C18.7207 5.05603 18.7651 5.16296 18.844 5.24208C18.9229 5.32101 19.03 5.36536 19.1417 5.36536C19.76 5.37194 20.3504 5.62245 20.7849 6.06237C21.2192 6.50231 21.4622 7.09596 21.4611 7.71427C21.4611 7.8259 21.5054 7.93302 21.5844 8.01195C21.6635 8.09088 21.7704 8.13523 21.882 8.13523C21.9937 8.13523 22.1008 8.09088 22.1797 8.01195C22.2587 7.93302 22.303 7.8259 22.303 7.71427C22.3053 6.87235 21.9739 6.06387 21.3814 5.46591C20.7889 4.8679 19.9836 4.52887 19.1417 4.52344Z" fill="black"/>
        <path d="M19.1296 9.11991C19.5059 9.11991 19.8667 8.97051 20.1328 8.7044C20.3987 8.43849 20.5483 8.07768 20.5483 7.70141C20.5483 7.32518 20.3987 6.96418 20.1328 6.69822C19.8667 6.43212 19.5059 6.28271 19.1296 6.28271C18.7534 6.28271 18.3926 6.43212 18.1264 6.69822C17.8603 6.96414 17.7109 7.32514 17.7109 7.70141C17.7121 8.07727 17.8618 8.43735 18.1278 8.70325C18.3935 8.96898 18.7538 9.11876 19.1296 9.11989L19.1296 9.11991Z" fill="black"/>
        <path d="M21.3729 9.61705H16.7674V5.45368C16.7674 5.34205 16.7231 5.23493 16.6442 5.156C16.5652 5.07707 16.4581 5.03271 16.3465 5.03271H6.84576C6.61311 5.03271 6.4248 5.22121 6.4248 5.45368V9.61705H1.8194C1.58693 9.61705 1.39844 9.80536 1.39844 10.038V25.0074C1.39844 25.119 1.44279 25.2261 1.52172 25.305C1.60065 25.384 1.70777 25.4283 1.8194 25.4283H21.3731C21.4848 25.4283 21.5919 25.384 21.6708 25.305C21.7497 25.2261 21.7941 25.119 21.7941 25.0074V10.038C21.7941 9.92638 21.7498 9.81926 21.6708 9.74033C21.5919 9.6614 21.4848 9.61705 21.3731 9.61705H21.3729ZM6.42466 24.5864H2.24021V10.459H6.42466V24.5864ZM9.79235 8.93533H11.1857V7.56291C11.1857 7.33044 11.3741 7.14194 11.6066 7.14194C11.8391 7.14194 12.0276 7.33044 12.0276 7.56291V8.93514H13.4209V8.93533C13.6535 8.93533 13.8418 9.12382 13.8418 9.35629C13.8418 9.58876 13.6535 9.77725 13.4209 9.77725H12.0191V11.1454C12.0191 11.3779 11.8306 11.5664 11.5981 11.5664C11.3657 11.5664 11.1772 11.3779 11.1772 11.1454V9.77725H9.78388C9.55141 9.77725 9.36292 9.58876 9.36292 9.35629C9.36292 9.12382 9.55141 8.93533 9.78388 8.93533L9.79235 8.93533ZM13.9809 24.5864H9.21556V18.7897H13.9809V24.5864ZM14.4018 17.1563H8.79464C8.56218 17.1563 8.37368 16.9678 8.37368 16.7354C8.37368 16.5029 8.56218 16.3144 8.79464 16.3144H14.4018C14.6343 16.3144 14.8228 16.5029 14.8228 16.7354C14.8228 16.9678 14.6343 17.1563 14.4018 17.1563ZM14.4018 15.3883H8.79464C8.56218 15.3883 8.37368 15.1998 8.37368 14.9673C8.37368 14.7349 8.56218 14.5464 8.79464 14.5464H14.4018C14.6343 14.5464 14.8228 14.7349 14.8228 14.9673C14.8228 15.1998 14.6343 15.3883 14.4018 15.3883ZM14.4018 13.6203H8.79464C8.56218 13.6203 8.37368 13.4318 8.37368 13.1993C8.37368 12.9668 8.56218 12.7783 8.79464 12.7783H14.4018C14.6343 12.7783 14.8228 12.9668 14.8228 13.1993C14.8228 13.4318 14.6343 13.6203 14.4018 13.6203ZM20.952 24.5864H16.7675V10.459H20.952V24.5864Z" fill="black"/>
        <rect x="8" y="6" width="7" height="12" fill="black"/>
        <path d="M8.75 10.5H14.25" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.5 13.25L11.5 7.75" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
)

export default NetworkCenterIcon