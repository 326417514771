import React from 'react';

import { getDetails, updateOrderDetails } from '../../services/api/redemption-ops';

import { toast } from 'react-toastify';

import Link from 'valuelink';
import { Input, Select } from 'valuelink/tags';

import Providers from './Providers.json';

import isFunction from '../../utils/isFunction';

export default class OrderDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            orderId: '',
            trackingLink: '',
            provider: '',
            placedOn: '',
            purchaseId: '',
        };
    }

    getRedemptionDetails = (redemptionId) => {
        if (redemptionId) {
            return getDetails(redemptionId)
                .then((response) => {
                    this.setState({
                        orderId: response.redemptionInfo.orderId || '',
                        trackingLink: response.redemptionInfo.trackingLink || '',
                        provider: response.redemptionInfo.provider || '',
                        placedOn: response.redemptionInfo.placedOn || '',
                        purchaseId: response.redemptionInfo.purchaseId || '',
                    });
                })
                .catch((err) => {
                    toast.error(err.errorMessage || 'Error while fetching redemption details');
                });
        }
    };

    submitOrderDetailsForm = () => {
        const { redemptionId } = this.props;
        const data = {
            orderId: this.state.orderId,
            trackingLink: this.state.trackingLink,
            provider: this.state.provider,
            purchaseId: this.state.purchaseId,
        };
        return updateOrderDetails(redemptionId, data)
            .then(() => {
                toast.success('Successfully updated order details');
                if (this.props.onFormClose && isFunction(this.props.onFormClose)) {
                    this.props.onFormClose();
                }
            })
            .catch((err) => {
                toast.error('Error while updating order details');
            });
    };

    componentDidMount() {
        const { redemptionId } = this.props;
        this.getRedemptionDetails(redemptionId);
    }

    render() {
        const orderIdLink = Link.state(this, 'orderId');
        const trackingLink = Link.state(this, 'trackingLink');
        const providerLink = Link.state(this, 'provider');
        const { placedOn } = this.state;
        return (
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col">
                        <strong>Order Id:</strong>
                    </div>
                    <div className="col">
                        <Input className="form-control" valueLink={orderIdLink} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <strong>Tracking Link:</strong>
                    </div>
                    <div className="col mt-2">
                        <Input className="form-control" valueLink={trackingLink} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <strong>Provider:</strong>
                    </div>
                    <div className="col">
                        <Select className="form-control" valueLink={providerLink}>
                            <option value="">Select Provider</option>
                            {Providers.map((p) => (<option key={p.value} value={p.value}>{p.name}</option>))}
                        </Select>
                    </div>
                </div>
                {(placedOn) ? (
                    <div className="row mt-2">
                        <div className="col">
                            <small>Placed on: {placedOn}</small>
                        </div>
                    </div>
                ) : null}
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-block btn-primary" onClick={this.submitOrderDetailsForm}>Submit</button>
                    </div>
                    <div className="col">
                        <button className="btn btn-block btn-secondary" onClick={this.props.onFormClose}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }
}
