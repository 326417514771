import baseRequest from './baseRequest';
const BASE_URL = '/new-ops/redemptions-ops';

export const getAllRedemptions = (data) => {
    const url = `${BASE_URL}`;
    const method = 'GET';
    return baseRequest(url, method, data, null);
}

export const getDetails = (redemptionId) => {
    const url = `${BASE_URL}/${redemptionId}/details`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const saveRemarks = (redemptionId, remarks) => {
    const url = `${BASE_URL}/${redemptionId}/remarks`;
    const method = 'POST';
    return baseRequest(url, method, null, { remarks });
};

export const sendConfirmationEmail = (redemptionId) => {
    const url = `${BASE_URL}/${redemptionId}/send-confirmation-email`;
    const method = 'POST';
    return baseRequest(url, method, null, null)
};

export const sendProcessedComms = (redemptionId, data) => {
    const url = `${BASE_URL}/${redemptionId}/send-amazon-gift-card-processed-mailer`;
    const method = 'POST';
    console.log(redemptionId, data);
    return baseRequest(url, method, null, data);
}

export const changeStatus = (redemptionId, status, visitCost) => {
    const url = `${BASE_URL}/${redemptionId}/status`;
    const method = 'POST';
    return baseRequest(url, method, null, { status, visitCost });
}

export const autoPayout = (redemptionId) => {
    const url = `${BASE_URL}/${redemptionId}/auto-payout`;
    const method = 'POST';
    return baseRequest(url, method, null, null);
}

export const updateOrderDetails = (redemptionId, details) => {
    const url = `${BASE_URL}/${redemptionId}/order-details`;
    const method = 'POST';
    return baseRequest(url, method, null, details);
};

export const updateDeliveryDetails = (redemptionId, details) => {
    const url = `${BASE_URL}/${redemptionId}/delivery-details`;
    const method = 'POST';
    return baseRequest(url, method, null, details);
};
