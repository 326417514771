import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
//import { editDoctorSlots, deleteDoctorSlots } from '../../service/api/doctors';
// import LoadingContainer from '../common/LoadingComponent';
import '../../styling/doctor.css';

import plusIcon from '../../images/plusIcon.svg';
import SolidButton from '../common/SolidButton';
import TimeSelector from './TimeSelector';

function DoctorSlot({
  prevSlots,
  isSlotTab,
  setIsSlotTab,
  setEditDoctorSlot,
  editDoctorSlot,
}) {
  const [loading, setloading] = useState(true);
  const [slotData, setSlotData] = useState([]);
  const [doctorSlots, setDoctorSlots] = useState([]);

  useEffect(() => {
    function updateSlotData() {
      setSlotData([{ startTime: '', endTime: '', weekDay: [] }]);

      setloading(false);
    }

    updateSlotData();
  }, []);

  const buttonStyle = {
    borderRadius: '100px',
    border: '2px solid #CFC8FF', // Initial border color
    backgroundColor: '#CFC8FF',
    color: 'white',
    height: '28px',
    width: '175px',
    transition: 'border-color 0.3s', // Add transition effect to border-color change
  };

  const HandleSave = async () => {
    setloading(true);
    let updateAndInsertSlot = [];

    for (let slots of slotData) {
      const startTime = slots.startTime;
      const endTime = slots.endTime;
      if (startTime && endTime) {
        for (let slot of slots.weekDay) {
          updateAndInsertSlot.push({
            //doctorId: doctorSlots.doctorId,
            //networkCenterId: doctorSlots.centerId,
            startTime: startTime,
            endTime: endTime,
            doctorSlotId: slot.slotId || null,
            dow: slot.value,
          });
        }
      }
    }
    if (Object.keys(doctorSlots).length == 0 && isSlotTab) {
      if (updateAndInsertSlot) {
        setEditDoctorSlot(updateAndInsertSlot);
        setIsSlotTab(true);
        setloading(false);
      }
    }
    toast.info('Slots have been added. Press Submit Button to add request.');
    //setIsSlotTab(true);

    //   try {

    //     // const [response] = await Promise.all([

    //     //   editDoctorSlots(updateAndInsertSlot, doctorSlots.doctorId),
    //     // ]);
    //     // if (response.body && response.body.message == 'success') {
    //     //     setIsSlotTab(false);
    //     // }
    //     setloading(false);
    //     toast.info('Slots Have Been Updated');
    //   } catch (err) {
    //     setloading(false);
    //     toast.error(err.message);
    //   }
  };

  function AddSlot() {
    setSlotData((prevSlotData) => [
      ...prevSlotData,
      { startTime: '', endTime: '', weekDay: [] },
    ]);
  }

  const CancelSlot = (index) => {
    setSlotData((prevSlotData) => {
      const filterArray = prevSlotData.filter(
        (_, slotIndex) => index !== slotIndex
      );
      return [...filterArray];
    });
  };

  return (
    <>
      {/* {loading ? (
        <LoadingContainer />
      ) : ( */}
      <div key={JSON.stringify(slotData)} style={{ marginBottom: '16px' }}>
        {slotData.map((slot, index) => (
          <TimeSelector
            key={index}
            slotData={slotData}
            setSlotData={setSlotData}
            index={index}
            CancelSlot={CancelSlot}
          />
        ))}
        <div className="doctorSlot-button">
          <SolidButton
            text="Add"
            style={{
              radius: '100px',
              border: `1px solid #714FFF`,
              background: '#CFC8FF',
              height: '48px',
              width: '100px',
              color: '#714FFF',
            }}
            onClick={AddSlot}
            children={<img src={plusIcon} style={{ marginRight: '8px' }} />}
          />

          <div style={{ marginLeft: '16px', marginRight: '32px' }}>
            <SolidButton
              text="Confirm Slots"
              style={{
                radius: '100px',
                border: `none`,
                background: '#CFC8FF',
                height: '48px',
                width: '175px',
                color: 'white',
              }}
              onClick={HandleSave}
            />
          </div>
        </div>
      </div>
      {/* ) */}
    </>
  );
}
export default DoctorSlot;
