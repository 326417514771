import { baseRequestWithHeaders } from './baseRequest';

export const fetchPaymentRequests = (
  next,
  rowsPerPage,
  searchNeedle,
  sd,
  ed,
  status,
  orderBy,
  order
) => {
  const url = `/new-ops/payment/all/requests`;
  const method = 'GET';
  const params = {
    rowsPerPage,
    next: next || undefined,
    search: searchNeedle || undefined,
    status: status || undefined,
    sd: sd || undefined,
    ed: ed || undefined,
    orderBy,
    order,
  };
  return baseRequestWithHeaders(url, method, params, {});
};

export const fetchPaymentRequestById = paymentRequestId => {
  const url = `/new-ops/payment/request/${paymentRequestId}`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method, null, {});
};
