import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { markLabRequestNotServiceable} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
class LabRxNotServiceable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            remark: '',
        };
    }

    toggle = () => {

        this.setState({
            modal: !this.state.modal,
            remark: '',
        });
    };

    save = () => {
        if (this.state.remark && this.state.remark.trim().length !== 0) {
            markLabRequestNotServiceable(this.props.requestId, this.state.remark.trim())
                .then((response) => {
                    console.log(response.body);
                    if (response.body.message === 'success') {
                        toast.success('Request Cancelled');
                        this.props.loadCart();
                        this.toggle();
                    } else {
                        toast.error('Unable to cancel request');
                    }
                })
                .catch((err) => {
                    toast.error('Unable to cancel request');
                    console.error('Error', err);
                });
        }
    };

    render() {
        return (
            <div>
                <button onClick={this.toggle} className="btn btn-danger">Not Serviceable</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <h4 className="mt-1 mb-0 ml-2">Confirmation</h4>
                    <ModalBody>
                        <p>Are you sure you want to mark this cart as non serviceable?</p>
                        <textarea className="form-control" rows="3" onChange={(e) => {console.log(e.target.value);this.setState({remark: e.target.value})}}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>Save</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(LabRxNotServiceable);





