import React from 'react';

import styled from "styled-components";

import { addCategoryToPool, deletePoolCategory } from '../../services/api/redemptions';

import { toast } from 'react-toastify';


const Card = styled.div`
    border: solid #bdbaba 1px;
    border-radius: 3px;
    padding: 15px;
    margin-bottom: 9px;
    border-radius: 7px;
`;

const CardRow = styled.div`
    margin: 15px;
`;

class AddPoolCategoryCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            categoryId: null,
            name: '',
            label: '',
            priority: 0,
            isActive: false,
        };
    }

    componentDidMount() {
        if (this.props.details) {
            this.setState({
                ...this.props.details
            });
        }
    }


    handleSubmit = () => {
        const { categoryId, name, label, priority, isActive } = this.state;
        const { poolId } = this.props;
        if (!name || !label || !priority) {
            return;
        }
        return addCategoryToPool({ categoryId, name, label, priority, isActive, poolId })
            .then((response) => {
                console.log(response);
                if (response.message === 'success') {
                    toast.success('Success');
                    if (!categoryId) {
                        this.setState({
                            name: '',
                            label: '',
                            priority: '',
                            isActive: false
                        }, this.props.onComplete);
                    } else {
                        this.props.onComplete();
                    }
                    return;
                }
                throw new Error(response.errorMessage);
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, categoryId, name, label, priority, isActive, 'Dont know what happened');
            })
    };

    handleNameChange = (e) => {
        this.setState({
            name: e.target.value,
        });
    };

    handleLabelChange = (e) => {
        this.setState({
            label: e.target.value,
        });
    };

    handlePriorityChange = (e) => {
        this.setState({
            priority: e.target.value
        });
    };

    handleActivityChange = (e) => {
        this.setState({
            isActive: !this.state.isActive,
        });
    };

    handleDelete = (categoryId) => {
        console.log(categoryId,'categoryId')
        return deletePoolCategory(categoryId)
            .then((response)=>{
                console.log(response,'[res]')
                if(response.message === 'success'){
                    toast.success('Success');
                    this.props.onComplete()
                }
            })
            .catch((err) => {
                toast.error(err.message);
            })
    }

    render() {
        const { categoryId, name, label, priority, isActive } = this.state;
        return (
            <React.Fragment>
                <Card className="container-fluid">
                    <CardRow className="row">
                        <div className="col">
                            <label>Name (For internal use)</label>
                        </div>
                        <div className="col">
                            <input value={name} onChange={this.handleNameChange} />
                        </div>
                    </CardRow>
                    <CardRow className="row">
                        <div className="col">
                            <label>Label</label>
                        </div>
                        <div className="col">
                            <input value={label} onChange={this.handleLabelChange} />
                        </div>
                    </CardRow>
                    <CardRow className="row">
                        <div className="col">
                            <label>Priority</label>
                        </div>
                        <div className="col">
                            <input value={priority} type="number" onChange={this.handlePriorityChange} />
                        </div>
                    </CardRow>
                    <CardRow className="row">
                        <div className="col">
                            <label>Active?</label>
                        </div>
                        <div className="col">
                            <input checked={isActive} type="checkbox" onChange={this.handleActivityChange} />
                        </div>
                    </CardRow>
                    <CardRow className="row">
                        <div className="col">
                            <button className="btn btn-primary btn-block" onClick={this.handleSubmit}>{(categoryId) ? 'Submit' : 'Add'}</button>
                        </div>
                    </CardRow>
                    {categoryId ?<CardRow className="row">
                        <div className="col">
                            <button className="btn btn-danger btn-block" onClick={(
                                )=>this.handleDelete(categoryId)}>Delete</button>
                        </div>
                    </CardRow>:''}
                </Card>
            </React.Fragment>
        );
    }
}

export default AddPoolCategoryCard;
