const config = {
  development: {
    // baseUrl: 'https://api.getvisitapp.xyz/beta/',
    baseUrl: 'http://localhost:5001',
    //baseUrl: 'https://api.samuraijack.xyz/latias',
    pharmacyService: 'http://localhost:2020',
    //pharmaServer: 'https://api.samuraijack.xyz/pharmacy',
    pubnub: {
      publishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
      subscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    },
    googleAuthConfig: {
      clientId:
        '1063005261362-ahma70ju5no6aksoc95p9kivj7s3uopp.apps.googleusercontent.com',
      scopes: ['email', 'profile', 'openid'],
    },
  },
  staging: {
    baseUrl: 'https://api.samuraijack.xyz/raichu',
    //pharmacyService: 'http://absol.getvisitapp.xyz',
    pharmacyService: 'http://localhost:2020',
    pubnub: {
      publishKey: 'pub-c-b8b14417-cff1-4e64-aa43-b41a777352cf',
      subscribeKey: 'sub-c-42db9ed2-315c-11e6-9327-02ee2ddab7fe',
    },
    googleAuthConfig: {
      clientId:
        '1063005261362-ahma70ju5no6aksoc95p9kivj7s3uopp.apps.googleusercontent.com',
      scopes: ['email', 'profile', 'openid'],
    },
  },
  production: {
    baseUrl: 'https://api.getvisitapp.com/v4',
    pharmacyService: 'https://api.getvisitapp.com/pharmacy',
    pubnub: {
      publishKey: 'pub-c-fb8ac7e3-75bf-4396-bf14-77215f8b9419',
      subscribeKey: 'sub-c-78deef00-680f-11e5-a57f-0619f8945a4f',
    },
    googleAuthConfig: {
      clientId:
        '587654000125-r26coedb1f9siqfgn1arj63k3dslrqqk.apps.googleusercontent.com',
      scopes: ['email', 'profile', 'openid'],
    },
  },
};

export default config[process.env.NODE_ENV];
