import React, { Component } from 'react';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';

const DATE = new Date();
const PREVIOUS_MONTH_DATE = new Date(DATE.setMonth(DATE.getMonth() - 1));

export default class DatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            month: PREVIOUS_MONTH_DATE.getMonth() + 1,
            year: PREVIOUS_MONTH_DATE.getFullYear(),
            setFocusedInput: null,
        };

    }

    handleOnDatesChange = () => {
        const { month, year } = this.state;
        if (this.props.onChange && month && year) {
            this.props.onChange(`${year}-${month}`);
        }
    };

    setFocusedInput = (value) => {
        this.setState({
            focusedInput: value,
        });
    };

    componentDidMount() {
        this.handleOnDatesChange();
        this.setState({
            month: (this.state.month.toString().length === 2)? (this.state.month.toString()):("0"+this.state.month.toString()),
            year: this.state.year.toString(),
        }, this.handleOnDatesChange);
    }

    handleMonthChange = (e) => {
        this.setState({
            month: e.target.value,
        }, this.handleOnDatesChange);
    };

    handleYearChange = (e) => {
        this.setState({
            year: e.target.value,
        }, this.handleOnDatesChange);
    };

    getYears = (startingYear)=>{
      let currentYear = Number((new Date()).getFullYear());
      let years = [];
      for(let i = startingYear; i <= currentYear; i++)
        years.push(i);
      return years;
    }



    render() {
        const { startDate, endDate, focusedInput } = this.state;
        return (
            <React.Fragment>
                <select onChange={this.handleMonthChange}>

                 {
                    [
                        {value:"01",label:"Jan"},
                        {value:"02",label:"Feb"},
                        {value:"03",label:"March"},
                        {value:"04",label:"Apr"},
                        {value:"05",label:"May"},
                        {value:"06",label:"June"},
                        {value:"07",label:"July"},
                        {value:"08",label:"Aug"},
                        {value:"09",label:"Sept"},
                        {value:"10",label:"Oct"},
                        {value:"11",label:"Nov"},
                        {value:"12",label:"Dec"},
                    ].map(
                        (month)=>
     {
         if(Number(month.value)===(PREVIOUS_MONTH_DATE.getMonth() + 1)){
             return (<option value={month.value.toString()} selected>{month.label.toString()}</option>)
         }
             else{
                 return (<option value={month.value.toString()}>{month.label.toString()}</option>)}
    }
                    )
                    }
                </select>
                <select onChange={this.handleYearChange}>
                    {
                    this.getYears(2019).map(
                        (year)=>
     {
         if(year===PREVIOUS_MONTH_DATE.getFullYear()){
             return (<option value={year.toString()} selected>{year.toString()}</option>)
         }
             else{
                 return (<option value={year.toString()}>{year.toString()}</option>)}
    }
                    )
                    }
                </select>
            </React.Fragment>
        );
    }
};
