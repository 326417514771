import React, { Component } from 'react';
import NavBar from '../layout/NavBar';
import {
  OuterComponent,
  PaddedContainer,
} from '../online-consultation-booking/StyledComponent';

export class PhysioProgramEnrolls extends Component {
  render() {
    return (
      <OuterComponent>
        <NavBar />
        <PaddedContainer>
          <iframe
            src="https://app.appsmith.com/physiotherapy-program-telehealth-list/login-page-624be5d451a8863d6c40195f"
            height="700"
            width="100%"
          ></iframe>
        </PaddedContainer>
      </OuterComponent>
    );
  }
}

export default PhysioProgramEnrolls;
