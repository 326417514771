import React from 'react';
import LabsDetailsHeader from "./LabsDetailsHeader";
import { fetchPincodesForPartner } from "../../services/api/lab-partners";
import {toast} from "react-toastify";
import LabsPincodeOnboardingTable from './LabsPincodeOnboardingTable';
import LabsPincodeUploadCsv from './LabsPincodeUploadCsv';



class LabsPincodeOnboarding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pincodes: [],
        };
    }

    getPartnerPincodes = () => {
        const partnerId = this.state.partnerId;
        if (isNaN(partnerId) || (partnerId === null)) {
            return;
        }
        this.setState({
            tableLoading: true,
        });
        fetchPincodesForPartner(partnerId)
            .then((response) => {
                const data = response.body;
                if (data.message === 'success') {
                    this.setState({
                        pincodes: data.pincodes,
                    });
                    return;
                }
                throw new Error('Error while fetching tests');
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, partnerId, 'Error while partner\'s tests');
            })
            .finally(() => {
                this.setState({
                    tableLoading: false,
                });
            });
    };

    componentDidMount() {
        const partnerId = parseInt(this.props.match.params.partnerId);
        this.setState({
            partnerId,
        }, this.getPartnerPincodes);
    }

    render() {
        const { tableLoading, pincodes, partnerId } = this.state;
        return (
            <React.Fragment>
                <LabsDetailsHeader partnerId={partnerId} type={"Pincodes"} />
                <LabsPincodeUploadCsv partnerId={partnerId} onComplete={this.getPartnerPincodes} />
                <LabsPincodeOnboardingTable
                    partnerId={partnerId}
                    pincodes={pincodes}
                    loading={tableLoading}
                    onComplete={this.getPartnerPincodes}
                />
            </React.Fragment>
        );
    }
}

export default LabsPincodeOnboarding;
