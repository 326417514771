import React from 'react';

import ReactTable from "react-table";
import ToggleActiveInactiveTestsModal from "./ToggleActiveInactiveTestsModal";

import EditLabTests from "./EditLabTests";

import { FaPencilAlt } from 'react-icons/fa';

import { searchFilterMethod } from '../../utils/react-table';

class LabsTestOnboardingTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeInactiveModalOpen: false,
            testMapId: null,
            testName: '',
            active: 0,
            editLabTestModal: false,
        };
    }

    getColumns = () => {
        return [{
            accessor: 'testName',
            filterable: true,
            Header: 'Name',
            filterMethod: searchFilterMethod,
        }, {
            accessor: 'partnerTestName',
            Header: 'Partner Test Name',
        }, {
            accessor: 'testMrp',
            Header: 'MRP',
            className: 'text-center',
        }, {
            accessor: 'testCost',
            Header: 'Cost',
            className: 'text-center',
        }, {
            accessor: 'retailDiscountMargin',
            Header: 'Retail Discount Margin',
            className: 'text-center',
        }, {
            accessor: 'testCode',
            Header: 'Test Code',
            className: 'text-center',
        }, {
            accessor: 'addedByName',
            Header: 'Added By',
        }, {
            Header: 'Action',
            className: 'text-center',
            Cell: row => {
                const { active, testMapId, testName } = row.original;
                return (
                    <div>
                        <button className={`btn btn-${(!active) ? ("success") : ("danger")}`} onClick={() => (this.openActiveInactiveModal(testMapId, !active))}>{(!active) ? ("Active") : ("Inactive")}</button>
                        &nbsp;
                        <div title="Edit Test">
                            <FaPencilAlt onClick={() => (this.openEditLabTestModal(testMapId, testName))} />
                        </div>
                    </div>
                );
            }
        }];
    };

    openActiveInactiveModal = (testMapId, active) => {
        this.setState({
            testMapId,
            active,
            activeInactiveModalOpen: true,
        });
    };

    closeActiveInactiveModal = () => {
        this.setState({
            activeInactiveModalOpen: false,
            testMapId: null,
            active: 0,
        }, this.props.onComplete);
    };

    openEditLabTestModal = (testMapId, testName) => {
        this.setState({
            editLabTestModal: true,
            testMapId,
            testName
        });
    };

    closeEditLabTestModal = () => {
        this.setState({
            editLabTestModal: false,
            testMapId: null,
        }, this.props.onComplete);
    };


    render () {
        const { tests, loading, partnerId } = this.props;
        const { activeInactiveModalOpen, active, testMapId, editLabTestModal, testName } = this.state;
        return (
            <React.Fragment>
                <ReactTable
                    data={tests}
                    loading={loading}
                    onFetchData={this.getPartnerTests}
                    showPaginationTop={false}
                    columns={this.getColumns()}
                    filterable={false}
                    defaultPageSize={20}
                    className="-striped -highlight"
                    sortable={false}
                />
                <ToggleActiveInactiveTestsModal testMapId={testMapId} active={active} partnerId={partnerId} isOpen={activeInactiveModalOpen} onClose={this.closeActiveInactiveModal} />
                <EditLabTests partnerId={partnerId} testMapId={testMapId} isOpen={editLabTestModal} testName={testName} onClose={this.closeEditLabTestModal} />
            </React.Fragment>
        );
    }
}

export default LabsTestOnboardingTable;
