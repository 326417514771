import baseRequest from './baseRequest';

const baseURL = `/new-ops/policy-details`;

export const getAuthPlanDetails = (params) => {
  const url = '/new-ops/get-user-auth-plans';
  const method = 'get';
  return baseRequest(url, method, params, null);
};

export const updateRelativeInfo = (userId, phone, relativeId, name, relationId,dob) => {
  const url = `${baseURL}/update-relative-info`;
  const method = 'POST';
  return baseRequest(url, method, null, { userId, phone, relativeId,name,relationId,dob })
};
