import { baseRequestWithHeaders } from './baseRequest';

export const uploadFiles = (
  type,
  paymentRequestId,
  payoutTransactionId,
  file,
  onUploadProgress
) => {
  const formData = new FormData();
  formData.append('file', file);
  if (payoutTransactionId) {
    formData.append('payoutTransactionId', payoutTransactionId);
  }
  const url = `/new-ops/payment/${paymentRequestId}/file/upload/${type}`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, formData, onUploadProgress);
};

export const takeActionOnPaymentRequest = (
  status,
  finalPayout,
  paymentType,
  remarks,
  altered,
  paymentRequestId,
  payoutMode,
  sendComms
) => {
  console.log('send comms flag',sendComms);
  const url = '/new-ops/payment/approve/request';
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, {
    status,
    finalPayout,
    paymentType,
    remarks,
    altered,
    paymentRequestId,
    payoutMode,
    sendComms
  });
};

export const autoPayoutRequest = (
  paymentRequestId,
  requestId,
  finalPayout,
  autoReceipt
) => {
  const url = `/new-ops/payment/${paymentRequestId}/autoPayout`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, {
    payoutAmount: finalPayout,
    requestId,
    autoReceipt,
  });
};

export const markAppointmentCreditReady = paymentRequestId => {
  const url = `/new-ops/payment/${paymentRequestId}/credit-ready`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, { paymentRequestId });
};
