import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useEffect, useState } from 'react';

const today = moment().format('YYYYMMDD')
const VaccinationModalConfirm = ({
    submitModal,
    onComplete,
    closeModal,
    modalIsOpen,
    className,
    requestId
}) => {

    const [value, setValue] = useState(today)
    const [slotValue, setSlotValue] = useState('1')

    const handleChange = (event) => {
        const date = moment(event.target.value, 'YYYY-MM-DD').format('YYYYMMDD')
        setValue(date)
    }

    const handleSlotChange = (event) => {
        setSlotValue(event.target.value)
    } 

    const handleSubmit = () => {
        submitModal(
            moment(value, 'YYYYMMDD').format('YYYY-MM-DD'), 
            slotValue
        )
        .then(() => {
            closeModal()
            onComplete()
        })
    }

    return <Modal
        isOpen={modalIsOpen}
        toggle={closeModal}
        className={className}
    >
        <ModalHeader className="bg-primary" toggle={closeModal}>
            Confirm Request (Request Id: {requestId})
        </ModalHeader>
        <ModalBody>
        <form>
            <label>Date:</label>
            <br />
            <input
                type="date"
                value={moment(value, 'YYYYMMDD').format('YYYY-MM-DD')}
                onChange={handleChange}
            />
            <br />
            <label>Select Slot:</label>
            <br />
            <select onChange={handleSlotChange}>
            <option value="13">06:00:00 AM - 06:30:00 AM</option>
            <option value="14">06:30:00 AM - 07:00:00 AM</option>
            <option value="15">07:00:00 AM - 07:30:00 AM</option>
            <option value="16">07:30:00 AM - 08:00:00 AM</option>
            <option value="17">08:00:00 AM - 08:30:00 AM</option>
            <option value="18">08:30:00 AM - 09:00:00 AM</option>
            <option value="19">09:00:00 AM - 09:30:00 AM</option>
            <option value="20">09:30:00 AM - 10:00:00 AM</option>
            <option value="21">10:00:00 AM - 10:30:00 AM</option>
            <option value="22">10:30:00 AM - 11:00:00 AM</option>
            <option value="23">11:00:00 AM - 11:30:00 AM</option>
            <option value="24">11:30:00 AM - 12:00:00 PM</option>
            <option value="25">12:00:00 PM - 12:30:00 PM</option>
            <option value="26">12:30:00 PM - 01:00:00 PM</option>
            <option value="27">01:00:00 PM - 01:30:00 PM</option>
            <option value="28">01:30:00 PM - 02:00:00 PM</option>
            <option value="29">02:00:00 PM - 02:30:00 PM</option>
            <option value="30">02:30:00 PM - 03:00:00 PM</option>
            <option value="31">03:00:00 PM - 03:30:00 PM</option>
            <option value="32">03:30:00 PM - 04:00:00 PM</option>
            <option value="33">04:00:00 PM - 04:30:00 PM</option>
            <option value="34">04:30:00 PM - 05:00:00 PM</option>
            <option value="35">05:00:00 PM - 05:30:00 PM</option>
            <option value="36">05:30:00 PM - 06:00:00 PM</option>
            <option value="37">06:00:00 PM - 06:30:00 PM</option>
            <option value="38">06:30:00 PM - 07:00:00 PM</option>
            <option value="39">07:00:00 PM - 07:30:00 PM</option>
            <option value="40">07:30:00 PM - 08:00:00 PM</option>
            <option value="41">08:00:00 PM - 08:30:00 PM</option>
            <option value="42">08:30:00 PM - 09:00:00 PM</option>
            <option value="43">09:00:00 PM - 09:30:00 PM</option>
            <option value="44">09:30:00 PM - 10:00:00 PM</option>
            <option value="45">10:00:00 PM - 10:30:00 PM</option>
            </select>
        </form>
        </ModalBody>
        <ModalFooter>
        <Button color="info" onClick={handleSubmit}>
            Confirm
        </Button>
        <Button color="secondary" onClick={closeModal}>
            Close
        </Button>
        </ModalFooter>
    </Modal>
}

export default VaccinationModalConfirm