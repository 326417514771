import { Button, Input } from 'reactstrap';
import styled from "styled-components";

export const OuterComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


export const PaddedContainer = styled.div`
  padding: 3rem 0.5rem 0.5rem 0.5rem;
  margin: 1rem 0.5rem 0.5rem 0.5rem;
`;

// Search Container
export const SearchContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
`;
export const SearchBox = styled(Input)`
    flex: 0.5;
    margin: 0.5rem;
`;

export const SearchIcon = styled(Button)`
    font-size: 1rem;
    padding: .375rem .75rem;
    height: 2.5rem;
    margin-top: 0.3rem;
`;

export const BodyContainer = styled.div``;

export const BodyContainerUser = styled.div`
    padding: 1rem;
    margin-top: 3rem;
`;

// User Portal
export const ActionButton = styled(Button)`
    font-size: 1rem;
    padding: .375rem .75rem;
    height: 2.5rem;
    margin: 0.5rem;
    width: 5rem;
`;

export const UserDetailContainer = styled.div`
    padding: 10px;
    background-color: #f9f9f9;
    color: black;
    border-radius: 5px;
`;

export const UserPersonalDetails = styled.div`
       
`;

export const UserAppDetails = styled.div`

`;

export const ActionDetailContainer = styled.div`
    background-color: #f9f9f9;
    color: black;
`;

export const OuterContainer = styled.div`
    padding: 7px;
    border: solid #e3e3e3 0.1px;
    overflow-x: scroll;
`;

export const WalletInfoContainer = styled.div`
    padding: 0.5rem;
    margin: 0.5rem 0.5rem 0.5rem 0rem;
    background-color: #e6e6e6;
    color: black;
`;
