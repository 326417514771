import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';
import { callUserVaccinationRequest } from '../../../services/api/cashless-vaccination';


const VaccinationCallModal = ({
    isOpen,
    header,
    body,
    requestId,
    onClose,
    className='bg-info',
    onComplete
}) => {

    const [isLoading, setIsLoading] = useState(false)
    const submitHandler = () => {
        if (!requestId) return

        setIsLoading(true)
        return callUserVaccinationRequest(requestId, 'userAcknowledge')
        .then(() => {
            onComplete()
            onClose()
        })
        .finally(() => {
            setIsLoading(false)
        })
    }

    return <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader className={className} toggle={onClose}>
            {header} (Request Id: {requestId})
        </ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={submitHandler} disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Confirm'}
            </Button>
            {!isLoading && (
                <Button color="secondary" onClick={onClose} disabled={isLoading}>
                    Cancel
                </Button>
            )}
        </ModalFooter>
    </Modal>
}

export default VaccinationCallModal