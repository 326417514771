import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Button } from 'reactstrap';

class UpdateOpdMembersTable extends Component {
  constructor(props) {
    super(props);
    this.tableColumns = [
      {
        accessor: 'MASTER_POLICY_NO',
        numeric: false,
        sortable: true,
        disablePadding: false,
        Header: 'MASTER_POLICY_NO',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'EMPLOYEE_ID',
        sortable: true,
        numeric: false,
        disablePadding: false,
        Header: 'EMPLOYEE_ID',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'NAME',
        numeric: true,
        disablePadding: false,
        sortable: true,
        Header: 'NAME',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'DOB',
        numeric: false,
        disablePadding: false,
        sortable: true,
        Header: 'DOB',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'OLD_VALUE',
        numeric: false,
        disablePadding: false,
        sortable: true,
        Header: 'OLD_VALUE',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'UPDATED_VALUE',
        numeric: false,
        disablePadding: false,
        sortable: true,
        Header: 'UPDATED_VALUE',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'Change Field Type',
        numeric: false,
        disablePadding: false,
        sortable: true,
        Header: 'Change Field Type',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'UPDATE',
        numeric: false,
        disablePadding: false,
        sortable: true,
        Header: 'UPDATE',
        style: { whiteSpace: 'unset' },
        Cell: row => {
          return (
            <div>
              <Button color="primary">UPDATE</Button>
            </div>
          );
        },
      },
    ];
  }
  render() {
    const { data, loading } = this.props;
    return (
      <ReactTable
        data={data}
        columns={this.tableColumns}
        loading={loading}
        // onFetchData={onFetchData}
        showPaginationTop={true}
        className="-striped -highlight"
      />
    );
  }
}

export default UpdateOpdMembersTable;
