import React from 'react';
import SearchIcon from '../../images/SearchIcon.svg';
import { Input } from 'antd';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';

export default ({ filter, onChange }) => {
  return (
    <Input
      style={{
        border: '1px solid rgba(113, 79, 255, 0.3)',
        borderRadius: '10px',
      }}
      placeholder="Search..."
      className="rid-search"
      prefix={<img src={SearchIcon} alt="Search Icon" />}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};
