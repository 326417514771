import React, { useState } from 'react';
import styled from 'styled-components';

import { Input as AntInput, Button, Card, Col, Row } from 'antd';

import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  addDataForNetworkCenter
} from '../../services/api/offline-consult';

const Text = styled.p`
  margin: 0;
  font-family: BrandonText-Medium, Lato, sans-serif;
`;

const SemiboldText = styled(Text)`
  font-family: ProximaNova-Semibold, Lato, sans-serif;
`;

const LabelText = styled(SemiboldText)`
  font-size: 12px;
  text-align: center;
  margin-bottom: 0.25rem;
  margin-top: 0.625rem;
  color: #7c7c7c;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;
  /* over */
`;

const AddPaymentModal = (props) => {
  const {
    isModalOpen,
    closePaymentModal,
    centerId,
    centerName,
    fulfilmentId,
    fulfilmentType,
    showBankDetails
  } = props;
  const [upiAddress, setUpiAddress] = useState('');
  const [upiPhone, setUpiPhone] = useState('');
  const [payeeName, setPayeeName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [bankAccountNumber, setBankAccountNumber] = useState('');
  const [ifscCode, setIfscCode] = useState('');


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!showBankDetails && !(upiAddress  && upiPhone)) {
      toast.error('Please fill all the required fields');
      return;
    }
    if(showBankDetails && !(payeeName && accountType && bankAccountNumber && ifscCode)){
      toast.error('Please fill all the required fields');
      return;
    }

    const body = {
      upiAddress: upiAddress,
      upiPhone: upiPhone,
      requestType: 'Add_Bank_Details',
      centerId: centerId,
      centerName: centerName,
      fulfilmentId: fulfilmentId,
      fulfilmentType: fulfilmentType,
      accountType : accountType,
      payeeName : payeeName,
      accountNumber : bankAccountNumber,
      ifscCode : ifscCode ? ifscCode.toUpperCase() : null,
    };

    const data = await addDataForNetworkCenter(body);
    if (data.message == 'success') {
      closePaymentModal();
      toast.success('Payment Request Created Successfully');
    }
    if(data.message == 'serverError') {
      toast.error(data.errorMessage);
    }
    console.log(data);
  };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader className="bg-primary" toggle={() => closePaymentModal()}>
        {`Add Payment Details for ${centerName}`}
      </ModalHeader>
      <ModalBody>
        <Card>
          <BodyContainer>
            <Row gutter={24}>
              {!showBankDetails && <Col xs={24} md={12}>
                <LabelText>Enter UPI Address:</LabelText>
                <AntInput
                  value={upiAddress}
                  placeholder="UPI Address"
                  onChange={(e) => {
                    setUpiAddress(e.target.value);
                  }}
                />
              </Col>}
              {!showBankDetails && <Col xs={24} md={12}>
                <LabelText>Enter UPI Phone Number:</LabelText>
                <AntInput
                  value={upiPhone}
                  placeholder="UPI Number"
                  onChange={(e) => {
                    setUpiPhone(e.target.value);
                  }}
                />
              </Col>}
              {showBankDetails && <Col xs={24} md={12}>
                <LabelText>Payee Name:</LabelText>
                <AntInput
                  value={payeeName}
                  placeholder="Payee Name"
                  onChange={(e) => {
                    setPayeeName  (e.target.value);
                  }}
                />
              </Col>}
              {showBankDetails && <Col xs={24} md={12}>
                <LabelText>Account Number:</LabelText>
                <AntInput
                  value={bankAccountNumber}
                  placeholder="Account Number"
                  onChange={(e) => {
                    setBankAccountNumber(e.target.value);
                  }}
                />
              </Col>}
              {showBankDetails && <Col xs={24} md={12}>
                <LabelText>Account Type:</LabelText>
                <AntInput
                  value={accountType}
                  placeholder="Account Type"
                  onChange={(e) => {
                    setAccountType(e.target.value);
                  }}
                />
              </Col>}
              {showBankDetails && <Col xs={24} md={12}>
                <LabelText>IFSC Code:</LabelText>
                <AntInput
                  value={ifscCode}
                  placeholder="IFSC Code"
                  onChange={(e) => {
                    setIfscCode(e.target.value);
                  }}
                />
              </Col>}
            </Row>

            <Row gutter={24} style={{ marginTop: 20 }}>
              <Col xs={24} md={12}>
                <Button
                  type="primary"
                  block
                  style={{ marginRight: 10 }}
                  onClick={(e) => handleSubmit(e)}
                >
                  Send Request
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button block onClick={closePaymentModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </BodyContainer>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default AddPaymentModal;
