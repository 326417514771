import React from 'react'
import styled from 'styled-components';


export const SearchBox = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
`;

export const SearchBoxInput = styled.input`
  border: 0;
  flex: 2 0 0;
  margin: 1px;
  &:focus {
    outline: none;
  }
`;

export const SearchBoxContent = styled.div`
  display: inline-block;
  flex: 1 0 0;
  margin: 1px;
  text-align: right;
`;

export const StyledHistoryContiner = styled.div`
  background-color: '';
`;

export const Container = styled.div`
  display: flex;
`;

export const ContainerItem = styled.div`
  flex-grow: 1;
  margin: 7px;
`;