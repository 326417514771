import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  requestPayout,
  getPayoutDetails,
} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { MdCreditCard } from 'react-icons/md';
class LabRequestPayoutConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      payoutDetails: {},
    };
  }

  toggle = () => {
    if (!this.state.modal) {
      // Fetch Payout details and eligibility here from API
      this.fetchPayoutDetails();
    }
    this.setState({
      modal: !this.state.modal,
    });
  };

  fetchPayoutDetails = () => {
    getPayoutDetails(this.props.orderId)
      .then((response) => {
        if (response.body.message === 'success') {
          console.log(response, '[payout]');
          this.setState({
            payoutDetails: response.body.body,
          });
        } else {
          toast.error(`Error - in fetching payout details`);
        }
      })
      .catch((err) => {
        console.error('Error in fetching payout details', err);
        toast.error(`Error - ${err.response.data.errorMessage}`);
      });
  };

  submit = () => {
    this.setState({
      loading: true,
    });
    requestPayout(this.props.orderId)
      .then((response) => {
        if (response.body.message === 'success') {
          toast.success(`Payout requested`);
          this.toggle();
        } else {
          console.error('Error in requesting Payout');
          toast.error(`Error - in requesting payout`);
        }
      })
      .catch((err) => {
        console.error('Error in requesting Payout', err);
        toast.error(`Error - ${err.response.data.errorMessage}`);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <button onClick={this.toggle} className="btn btn-sm btn-success">
          Request Payment
          <MdCreditCard />
        </button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <h4 className="mt-1 mb-0 ml-2">
            Confirmation of Payout to Provider: {this.state.payoutDetails.type}
          </h4>
          <ModalBody>
            <p>
              From Insurer Account:{' '}
              <strong>{this.state.payoutDetails.insurerPayout}</strong>
            </p>
            <p>
              From copay Account:{' '}
              <strong>{this.state.payoutDetails.copay}</strong>
            </p>
            <p>
              Total Payout to provider:{' '}
              <strong>{this.state.payoutDetails.orderCost}</strong>
            </p>
            {this.state.payoutDetails.vendorId && (
              <p>
                Vendor Id: <strong>{this.state.payoutDetails.vendorId}</strong>
              </p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={loading}
              onClick={() =>
                this.props.history.push(
                  `/lab-partner-details/${this.props.partnerId}`
                )
              }
            >
              Fulfilment Details
            </Button>{' '}
            <Button
              disabled={loading}
              color="primary"
              onClick={() => this.submit()}
            >
              {loading ? 'Loading' : 'Request Payout'}
            </Button>{' '}
            <Button disabled={loading} color="secondary" onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(
  connect(mapStateToProps)(LabRequestPayoutConfirmation)
);
