export const defaultState = {
  consultId: null
};

export default function docCall(state = defaultState, action) {
  switch (action.type) {
    case "SET_CONSULT_ID": {
      const consultId = action.payload.consultId;
      return Object.assign({}, state, {
        consultId: consultId
      });
    }
    case "UNSET_CONSULT_ID": {
      return Object.assign({}, defaultState);
    }
    default:
      return state;
  }
}
