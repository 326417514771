import React, { Component } from 'react';
import Select from 'react-select';
import { fetchAlldoctors } from '../../../services/api/doctor';
import { connect } from 'react-redux';
// import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { MultiSelect } from 'react-multi-select-component';

class MultiDoctorFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doctors: this.getInitialDoctorValue(),
      selected: this.getInitialDoctorValueSelected(),
      timeoutId: null,
    };
    this.selectRef = React.createRef();
  }

  handleChange = () => {
    let selectedDoctors = [];
    for (let i = 0; i < this.state.selected.length; i++) {
      if (this.state.selected[i].value !== 'all') {
        selectedDoctors.push(this.state.selected[i].value);
      }
    }
    // console.log(selectedDoctors, this.state.selected, 'handleChange');
    // added an extra flag to check whether all docs are selected
    this.props.onChangeValue(
      selectedDoctors,
      selectedDoctors.length === this.state.doctors.length
    );
    this.setState({ timeoutId: null });
  };

  getDoctors = () => {
    return fetchAlldoctors({ pathname:
      window.location.pathname === '/scheduled-consults' ? '/specialist-consults' : window.location.pathname })
      .then((response) => {
        console.log(response, 'getDoctors:fetchAlldoctors');
        this.setState({
          doctors: [
            ...this.state.doctors,
            ...response.results.map(ele => {
              if (!this.state.doctors.includes(ele)) {
                return {
                  label: ele.docName,
                  value: ele.docId,
                };
              }
            })
          ],
        });
      })
      .catch(() => {
        this.setState({
          doctor: [],
        });
      });
  };

  getInitialDoctorValue = () => {
    let arr = [];
    let prevValues;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        prevValues = this.props.doctorsDocOnCall;
        break;
      }
      case '/specialist-consults': {
        prevValues = this.props.doctorsSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        prevValues = this.props.doctorsOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        prevValues = this.props.doctorsScheduledConsults;
        break;
      }
      default:
        prevValues = undefined;
        break;
    }

    // console.log(prevValues, 'prevValues');

    fetchAlldoctors({ pathname: window.location.pathname })
      .then((response) => {
        if (prevValues && prevValues.selected) {
          console.log('fetchAlldoctors:prevValues', prevValues);
          if (prevValues.selected.length === 0) {
            // pass
          } else {
            response.results.forEach((element) => {
              if (prevValues.selected.includes(element.docId)
                // || !prevValues.all.includes(element.docId)
              ) {
                if (!arr.includes(element)) {
                  arr.push({
                    value: element.docId,
                    label: element.docName,
                  });
                }

              }
            });
          }
        } else {
          // arr.push({
          //   value: 'all',
          //   label: 'All',
          // });
        }



      });

    console.log('fetchAlldoctors:prevValues:arr', arr);
    return arr;
  };

  getInitialDoctorValueSelected = () => {
    let arr = [];
    let prevValues;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        prevValues = this.props.doctorsDocOnCall;
        break;
      }
      case '/specialist-consults': {
        prevValues = this.props.doctorsSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        prevValues = this.props.doctorsOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        prevValues = this.props.doctorsScheduledConsults;
        break;
      }
      default:
        prevValues = undefined;
        break;
    }

    // console.log(prevValues, 'prevValues');

    fetchAlldoctors({ pathname: window.location.pathname })
      .then((response) => {
        if (prevValues && prevValues.selected) {
          if (prevValues.selected.length === 0) {
            arr.push({
              value: 'all',
              label: 'Select...',
            });
            // set filter to null here
            this.props.setDoctorNull();
          } else {
            response.results.forEach((element) => {
              if (prevValues.selected.includes(element.docId)
                // || !prevValues.all.includes(element.docId)
              ) {
                arr.push({
                  value: element.docId,
                  label: element.docName,
                });
              }
            });
          }
        } else {
          // very initial page loading
          // arr.push({
          //   value: 'all',
          //   label: 'All items are selected.',
          // });
          response.results.forEach((element) => {
            arr.push({
              value: element.docId,
              label: element.docName,
            });
          });
        }


        // this.setState({doctors: arr});
        this.setState({ selected: arr });
      });

    return arr;
  };

  setSelected = (selectedItems) => {
    console.log(selectedItems, 'setSelected:doctors');

    if (selectedItems.length >= 1) {
      if (selectedItems[0].value === 'all') {
        this.setState({
          selected: selectedItems.slice(1, selectedItems.length),
        });
      } else {
        this.setState({ selected: selectedItems });
      }
    } else {
      this.setState({ selected: selectedItems });
    }

    if (!this.state.timeoutId) {
      this.setState({ timeoutId: setTimeout(this.handleChange, 1200) });
    }
  };

  componentDidMount() {
    this.getDoctors();
  }

  render() {
    return (
      <div>
        <MultiSelect
          hasSelectAll={true}
          options={this.state.doctors}
          value={this.state.selected}
          onChange={this.setSelected}
          labelledBy="Select Doctors"
          debounceDuration={300}
          styles={{
            option: (provided, state) => ({
              ...provided,
              background: 'white',
              color: 'black',
              cursor: 'pointer',
              '&:hover': {
                color: 'black',
                background: 'wheat',
              },
              '&:active': {
                color: 'white',
                background: 'rgb(2, 117, 216)',
              },
            }),
          }}
          ref={this.selectRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  doctorsDocOnCall: state.filterHeader.doctorsDocOnCall,
  doctorsSpecialistConsults: state.filterHeader.doctorsSpecialistConsults,
  doctorsOfflineSp: state.filterHeader.doctorsOfflineSp,
  doctorsScheduledConsults: state.filterHeader.doctorsScheduledConsults,
});

export default connect(mapStateToProps)(MultiDoctorFilter);
