import React, { Component } from 'react';
import { Badge, Button, Form, FormGroup, Label, Input, Collapse, Card, CardBody, ListGroup, ListGroupItem, Toast, ToastBody, ToastHeader, Modal, ModalHeader, ModalBody, ModalFooter, ButtonGroup } from 'reactstrap';
import { addUserTag, getUserTags, markResolved, removeTag } from '../../services/api/customer-support';

class UserActions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userTags: [],
            collapse: false,
            medium: false,
            collapseNotes: false,
            remarks: null,
            concern: false,
            detailedConcern: false,
            response: {
                show: false,
                message: '',
                color: 'success',
            },
            showRemoveTagModal: false,
            removeTagId: 0,
            concernType: 'enquiry', // 1 is enquiry, 2 is complaint
            options: [],
        };
    }

    getDetailedConcerns = (primaryConcern, concernType) => {
        console.log(primaryConcern, concernType, 'Fetching Options');
        switch (primaryConcern) {
            case 'Appointments': {
                if (concernType === 'enquiry') {
                    return [
                        'Prescription Blocker',
                        'Reschedule',
                        'Confirmation',
                        'Onboarding Request',
                        'Cancellations',
                        'Others',
                    ];
                }
                return [
                    'Confirmation',
                    'Wrong Vertical',
                    'Wrong Address',
                    'Hospital Denied Appointment',
                    'Doctor\'s Profile Dropped',
                    'Confirmed Wrong Slots',
                    'Prescription Blocker',
                    'Cancellations',
                    'Miscommunication',
                    'Tech Issue - Wrong Slots',
                    'Tech Issue - Others',
                    'Others',
                ];
            }
            case 'Online Consultations': {
                if (concernType === 'enquiry') {
                    return [
                        'Doctor not responding',
                        'Health Concern',
                        'Free Consultation',
                        'Others',
                    ];
                }
                return [
                    'Doctor Not Responding',
                    'Tech Issue',
                    'Others',
                ];
            }
            case 'Payments': {
                if (concernType === 'enquiry') {
                    return [
                        'Confirmation',
                        'Reimbursement',
                        'Invoice',
                        'Others',
                    ];
                }
                return [
                    'Hospital Denied Cashless',
                    'Hospital Denied Payment',
                    'Payment made to wrong account/number',
                    'Payment not made',
                    'Reimbursement',
                    'Wrong payment amount',
                    'Others',
                ];
            }
            case 'Labs': {
                if (concernType === 'enquiry') {
                    return [
                        'How to book tests',
                        'Confirmation',
                        'Cancellation',
                        'Others',
                    ];
                }
                return [
                    'Confirmation',
                    'Lab Tests not arranged',
                    'Partial reports received',
                    'Reports not received',
                    'Sample not collected',
                    'Reports not clear',
                    'Wrong tests',
                    'Tech Issue',
                    'Others',
                ];
            }
            case 'Redemption': {
                if (concernType === 'enquiry') {
                    return [
                        'Product details',
                        'Delivery Details',
                        'Others',
                    ];
                }
                return [
                    'Product not delivered',
                    'Defective product received',
                    'Expired Voucher',
                    'Others',
                ]
            }
            case 'Application': {
                if (concernType === 'enquiry') {
                    return [
                        'Email Verification',
                        'Update User Info',
                        'Synchronising steps',
                        'Invite Rewards',
                        'Others',
                    ];
                }
                return [
                    'Invites Not reflecting',
                    'Steps not counting',
                    'Not getting FitCoins',
                    'Not getting sponsored benefits',
                    'Others',
                ]
            }
            case 'GMC': {
                return [
                    'Endorsements Related',
                    'App Related',
                    'Claims Related'
                ];
            }
            default: {
                return [];
            }
        }
    };

    getConcernName(concern) {
        switch (concern) {
            case 'important': {
                return 'Priority Important';
            }
            case 'SOS': {
                return 'Priority SOS';
            }
            case 'spam': {
                return ' Priority Spam';
            }
            case 'pay-com': {
                return 'Payment - Complaint';
            }
            case 'pay-req': {
                return 'Payment - Request';
            }
            case 'con-com': {
                return 'Appointment - Complaint';
            }
            case 'con-req': {
                return 'Appointment - Request';
            }
            case 'labs-com': {
                return 'Labs - Complaint';
            }
            case 'labs-req': {
                return 'Labs - Request';
            }
            case 'red-com': {
                return 'Redemptions - Complaint';
            }
            case 'red-req': {
                return 'Redemptions - Request';
            }
            case 'app-com': {
                return 'Application - Complaint';
            }
            case 'app-req': {
                return 'Application - Request';
            }
            case 'con-online': {
                return 'Online Consultations';
            }
            case 'tech-com': {
                return 'Tech Issue';
            }
            case 'policy-cancel': {
                return 'Policy Cancellations';
            }
            case 'con-refund': {
                return 'Appointment Reimbursement';
            }
            case 'fit-refund': {
                return 'FitCoin Refund';
            }
            case 'wrong-inbound': {
                return 'Wrong Inbound';
            }
            case 'spam-inbound': {
                return 'Spam';
            }
            case 'policy-enquiry': {
                return 'OPD Policy Enquiry';
            }
            case 'fit-enquiry': {
                return 'FitCoins Enquiry';
            }
            case 'products-enquiry': {
                return 'Products Enquiry';
            }
            case 'change-details': {
                return 'Change Contact Details';
            }
            case 'doctor-recommend': {
                return 'Doctor Recommendation';
            }
            case 'con-cancel': {
                return 'Appointment - Cancellation';
            }
            default: {
                return concern;
            }
        }
    }

    handleUserTagSelect = (e) => {
        const { value } = e.target;
        console.log(value, 'Tag');
        // check for valid value
        if (value === 'none') {
            this.setState({
                response: {
                    show: true,
                    message: 'Please select a valid priority tag',
                    color: 'warning'
                },
            });
            return;
        }
        // make an API call
        addUserTag(value, 'priority', null, null, this.props.userId)
            .then((response) => {
                if (response.message === 'success') {
                    // real time update xD
                    this.handleAllTags();
                    this.setState({
                        response: {
                            show: true,
                            message: 'Priority Tag Added Successfully',
                            color: 'success'
                        },
                    });
                    return;
                }
                this.setState({
                    response: {
                        show: true,
                        message: response.message,
                        color: 'danger'
                    },
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    response: {
                        show: true,
                        message: error.messag,
                        color: 'danger'
                    },
                });
            });
    };

    handleUserMedium = (e) => {
        const { value } = e.target;
        console.log(value, 'Medium');
        // update the state
        this.setState({
            medium: value,
        });
    };

    handleAllTags = () => {
        console.log('Check All tags');
        getUserTags(this.props.userId)
            .then((response) => {
                if (response.message === 'success' && response.result && response.result.length) {
                    this.setState({
                        userTags: response.result,
                        collapse: true,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    response: {
                        show: true,
                        message: `Error in fetching tags ${err.message}`,
                        color: 'danger'
                    },
                });
            });
    };

    handleUserConcernSelect = (e) => {
        const { value } = e.target;
        // add concern to state
        if (value !== '') {
            this.setState({
                concern: value,
                options: this.getDetailedConcerns(value, this.state.concernType),
            });
        }
    };

    handleUserDetailedConcernSelect = (e) => {
        const { value } = e.target;
        // add concern to state
        if (value !== '') {
            this.setState({
                detailedConcern: value,
            });
        }
    };

    handleSubmitConcern = (e) => {
        // stop default action
        e.preventDefault();
        let { medium, concern, remarks, detailedConcern, concernType } = this.state;
        // check for medium and concern
        console.log({ concern, medium }, 'Concern');
        if (!medium || medium === 'none' || concern === 'none' || !concern || !concernType || detailedConcern === 'none' || !detailedConcern) {
            this.setState({
                response: {
                    show: true,
                    message: 'Please select medium, Concern Type, Primary Concern and Detailed Concern',
                    color: 'warning'
                },
            });
            return;
        }
        // // handle detailed concern
        // if (detailedConcern === 'none') {
        //     detailedConcern = null;
        // }
        // make an API call
        addUserTag(concern, concernType, medium, remarks, this.props.userId, detailedConcern)
            .then((response) => {
                if (response.message === 'success') {
                    // real time update xD
                    this.handleAllTags();
                    this.setState({
                        response: {
                            show: true,
                            message: 'Concern Added Successfully',
                            color: 'success'
                        },
                    });
                    return;
                }
                this.setState({
                    response: {
                        show: true,
                        message: `Error in adding concern ${response.message}`,
                        color: 'danger'
                    },
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    response: {
                        show: true,
                        message: `Error in adding concern ${error.message}`,
                        color: 'danger'
                    },
                });
            });
    };

    handleRemarks = (e) => {
        const { value } = e.target;
        // storing in state
        this.setState({
            remarks: value,
        });
    };

    handleResolved = (tagId) => {
        // calling the resolution API
        markResolved(tagId)
            .then((response) => {
                if (response.message === 'success') {
                    // real time update xD
                    this.handleAllTags();
                    this.setState({
                        response: {
                            show: true,
                            message: 'Concern Resolved Successfully',
                            color: 'success'
                        },
                    });
                    return;
                }
                this.setState({
                    response: {
                        show: true,
                        message: `Error in resolving concern ${response.message}`,
                        color: 'danger'
                    },
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    response: {
                        show: true,
                        message: `Error in resolving concern ${error.message}`,
                        color: 'danger'
                    },
                });
            });
    };

    openRemoveTagModal = (tagId) => {
        console.log('Remove clicked', tagId);
        // set remove tag Id
        this.setState({
            removeTagId: tagId,
            showRemoveTagModal: true,
        });
    };

    closeRemoveTagModal = () => {
        // unset remove tag id
        this.setState({
            removeTagId: 0,
            showRemoveTagModal: false,
        });
        console.log('Removing Dismissed', this.state.removeTagId);
    };

    handleRemoveTag = () => {
        console.log('Tag Removed', this.state.removeTagId);
        removeTag(this.state.removeTagId)
            .then((response) => {
                if (response.message === 'success') {
                    // real time update xD
                    this.handleAllTags();
                    this.setState({
                        showRemoveTagModal: false,
                        response: {
                            show: true,
                            message: 'Concern Removed Successfully',
                            color: 'success'
                        },
                    });
                    return;
                }
                this.setState({
                    showRemoveTagModal: false,
                    response: {
                        show: true,
                        message: `Error in removing concern ${response.message}`,
                        color: 'danger'
                    },
                });
            })
            .catch((error) => {
                console.log(error);
                this.setState({
                    showRemoveTagModal: false,
                    response: {
                        show: true,
                        message: `Error in removing concern ${error.message}`,
                        color: 'danger'
                    },
                });
            });
    };

    setConcernType = (selected) => {
        // set the concern in the state
        console.log('Concern Type Selected', selected);
        this.setState({
            concernType: selected,
            options: this.getDetailedConcerns(this.state.concern, selected),
        });
    };

    getDetailedOptions () {
        const selections = [];
        this.state.options.forEach((o) => {
            selections.push(<option value={o}>{o}</option>);
        });
        return selections;
    };

    render() {
        const { response } = this.state;
        return (
            <div>
                <div className={"row"}>
                    <div className={"col-md-4"}>
                        <Toast isOpen={response.show}>
                            <ToastHeader icon={response.color}>
                                Response
                            </ToastHeader>
                            <ToastBody>
                                {response.message}
                            </ToastBody>
                        </Toast>
                        <Form>
                            <FormGroup>
                                <Label for="userTagSelect">Select User Priority Tag</Label>
                                <Input type="select" name="userTag" id="userTagSelect" onChange={this.handleUserTagSelect}>
                                    <option value={"none"}>None</option>
                                    <option value={"important"}>Important</option>
                                    <option value={"spam"}>Spam</option>
                                    <option value={"SOS"}>SOS</option>
                                </Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className={"col-md-6"}>
                        <Form>
                            <FormGroup>
                                <Label for="userMediumSelect">Select Communication Medium</Label>
                                <Input type="select" name="userMedium" id="userMediumSelect" onChange={this.handleUserMedium}>
                                    <option value={"none"}>None</option>
                                    <option value={"call"}>Call</option>
                                    <option value={"email"}>Email</option>
                                    <option value={"freshchat"}>FreshChat</option>
                                    <option value={"other"}>Other</option>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for={"userConcernType"}>Select the concern type</Label>
                                <br/>
                                <ButtonGroup id={"userConcernType"}>
                                    <Button color="primary" size={"sm"} onClick={() => this.setConcernType('enquiry')} active={this.state.concernType === 'enquiry'}>Enquiry</Button>
                                    <Button color="primary" size={"sm"} onClick={() => this.setConcernType('concern')} active={this.state.concernType === 'concern'}>Complaint</Button>
                                </ButtonGroup>
                            </FormGroup>
                            <FormGroup>
                                <Label for="userConcernSelect">Select Primary Concern</Label>
                                <Input type="select" name="userConcern" id="userConcernSelect" onChange={this.handleUserConcernSelect}>
                                    <option value={"none"}>None</option>
                                    <option value={"Appointments"}>Appointments</option>
                                    <option value={"Online Consultations"}>Online Consultations</option>
                                    <option value={"Payments"}>Payments</option>
                                    <option value={"Labs"}>Labs</option>
                                    <option value={"Redemption"}>Redemption</option>
                                    <option value={"Application"}>Application</option>
                                    <option value={"GMC"}>GMC</option>
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="userDetailedConcernSelect">Select Detailed Concern</Label>
                                <Input type="select" name="userDetailedConcern" id="userDetailedConcernSelect" onChange={this.handleUserDetailedConcernSelect}>
                                    <option value={"none"}>None</option>
                                    {this.getDetailedOptions()}
                                </Input>
                            </FormGroup>
                            <FormGroup>
                                <Label for="remarksInput">Remarks</Label>
                                <Input type="text" maxLength={"255"} name="remarks" id="remarksInput" placeholder="add any remarks" onChange={this.handleRemarks} />
                            </FormGroup>
                            <Button color="info" onClick={this.handleSubmitConcern} style={{ marginBottom: '1rem' }}>Submit Concern</Button>
                        </Form>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col"}>
                        <Button color="primary" onClick={this.handleAllTags} style={{ marginBottom: '1rem' }}>Show Previous Tags</Button>
                        <Collapse isOpen={this.state.collapse}>
                            <Card>
                                <CardBody>
                                    <ListGroup>
                                        {this.state.userTags.length > 0 ? this.state.userTags.map((t) => (
                                            <ListGroupItem><b>
                                                <Button onClick={() => this.openRemoveTagModal(t.tagId)} close />
                                                {this.getConcernName(t.tag)}{t.subTag ? (': ' + t.subTag) : ''}</b> - Added by <b>{t.userName}</b> on {t.createdAt}
                                                {['concern','enquiry'].indexOf(t.type) > -1 ? (<><br/>
                                                    Remarks: <b>{t.remarks}</b>
                                                </>) : null}
                                                {['concern','enquiry'].indexOf(t.type) > -1 ? (t.resolvedAt ? <Badge style={{ float: 'right', padding: '5px' }} color="primary" pill>Resolved by {t.resolvedBy}</Badge> : <Button color="success" onClick={() => this.handleResolved(t.tagId)} style={{ float: 'right' }}>Mark Resolved</Button>) : null}
                                                {['concern','enquiry'].indexOf(t.type) > -1 ? (<div>
                                                    Communication - <b>{t.medium ? t.medium : 'None'}</b>
                                                </div>) : null}
                                                </ListGroupItem>
                                        )) : (
                                            'No tags found'
                                        )}
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>
                </div>
                <Modal isOpen={this.state.showRemoveTagModal} >
                    <ModalBody>
                        Do you want to remove this tag?
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.closeRemoveTagModal}>Cancel</Button>{' '}
                        <Button color="secondary" onClick={this.handleRemoveTag}>Remove</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default UserActions;
