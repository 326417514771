import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import axios from 'axios';
import config from '../../config.js';
import { store } from '../../store/index.js';

function AddApolloMedicineModal({ onClose }) {
  const [formData, setFormData] = useState({
    name: '',
    packSize: '',
    drugType: 'PHARMA',
    packUnit: 'LIQUID',
    partnerItemCode: '',
    mrp: ''
  });

  const [validationErrors, setValidationErrors] = useState(() => {
    const initialState = {};
    Object.keys(formData).forEach(key => {
      initialState[key] = '';
    });
    return initialState;
  });

  const drugTypes = ['PHARMA', 'FMCG', 'SURGICAL', 'AYURVEDIC', 'HNB', 'PRIVATE LABEL', 'BOTTLE'];

  const packUnits = ['LIQUID', 'TABLET', 'BABY CARE', 'OINTMENT', 'OTC', 'CAPSULE', 'DROPS', 'CONSUMABLES', 'POWDER', 'FOOD & BEVERAGES', 'PERSONAL CARE', 'BOTTLE', 'SURGICALS', 'RE-HABILITATION', 'INJECTION', 'SOAP', 'DEVICE', 'GEL', 'FACE WASH', 'CREAM', 'LOTION', 'SACHET', 'SUPPLEMENTS', 'AYURVEDIC', 'INHALER', 'SYRUP', 'ORAL SOLUTION', 'FLUIDS', 'MISCELLANEOUS', 'HOME NEEDS', 'SPRAY', 'SERUM', 'SOLUTION', 'PATCHES', 'SHAMPOO', 'PRIVATE LABEL', 'DROP', 'BODY WASH', 'HAIR COLOUR', 'EYE CREAM', 'WIPES', 'CLEANSER', 'TONIC', 'MOUTH WASH', 'OTHERS', 'TOOTHPASTE', 'PASTE', 'RESPULES', 'ORAL SUSPENSION', 'FACE MASK', 'TUBE', 'HAND WASH', 'TABLETS', 'VHMS', 'SUSPENSION', 'CONDOMS', 'EYE GEL', 'GENERAL ITEMS', 'NASAL SPRAY', 'VACCINE', 'HEALTH FOODS', 'GRANULES', 'EYE DROPS', 'EAR DROPS', 'DIAPER', 'READY TO EAT', 'SPORTS', 'ML', 'MILK', 'ROLL-ON', 'TOOTHBRUSH', 'OIL', 'PACK', 'BEVERAGES', 'CONDITIONER', 'FMCG', 'LOZENGE', 'PAD', 'BALM', 'Bathing Bar'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    const upperCaseValue = value.toUpperCase();
    setFormData({
      ...formData,
      [name]: upperCaseValue
    });
    validateField(name, upperCaseValue);
  };

  const validateField = (fieldName, value) => {
    let errorMessage = '';

    switch (fieldName) {
      case 'name':
        if (value.trim() === '') {
          errorMessage = 'Brand Name is required';
        }
        break;
      case 'packSize':
        if (value.trim() === '' || isNaN(value)) {
          errorMessage = 'Pack Size must be a number';
        }
        break;
      case 'partnerItemCode':
        const partnerItemCodeRegex = /^[a-zA-Z][a-zA-Z0-9]*[0-9]$/;
        if (!partnerItemCodeRegex.test(value)) {
          errorMessage = 'Partner Item Code must start with an alphabet, end with number';
        }
        break;
      case 'mrp':
        if (value.trim() === '' || isNaN(value) || parseFloat(value) <= 0) {
          errorMessage = 'MRP must be a number greater than 0';
        }
        break;
      default:
        break;
    }

    setValidationErrors({
      ...validationErrors,
      [fieldName]: errorMessage
    });
  };

  const addApolloMedicine = async (payload) => {
    try {
      const headers = {};
      const state = store.getState();
      if (state.user.token) {
        headers.authorization = state.user.token;
      }
      const { data: responseBody } = await axios.post(`${config.pharmacyService}/ops/add-apollo-drug`, payload, { headers });
      return responseBody.message;
    } catch (err) {
      if (err.response) {
        return err.response.data;
      } else if (err.request) {
        return { message: 'No response received from the server' };
      } else {
        return { message: 'Error during request setup' };
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const message = await addApolloMedicine(formData);
      alert(JSON.stringify(message))
      onClose();
    } else {
      alert('Please check the entered values and try again.');
    }
  };

  const validateForm = () => {
    for (const key in formData) {
      validateField(key, formData[key]);
      if (validationErrors[key] !== '') {
        return false;
      }
    }
    return true;
  };

  return (
    <Modal isOpen={true} style={{ maxWidth: '600px', width: '100%', borderRadius: '4px', backgroundColor: '#f8f9fa' }}>
      <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={onClose}>
        <h4 className="text-center" style={{ color: '#fff', fontWeight: 'bold' }}>Add Apollo Drug</h4>
      </ModalHeader>
      <ModalBody style={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '40px', paddingRight: '40px' }}>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Brand Name:</label>
            <input type="text" id="name" name="name" className="form-control" value={formData.name} onChange={handleChange} required />
            <span className="text-danger">{validationErrors.name}</span>
          </div>

          <div className="form-group">
            <label htmlFor="packSize">Pack Size:</label>
            <input type="number" id="packSize" name="packSize" className="form-control" value={formData.packSize} onChange={handleChange} required />
            <span className="text-danger">{validationErrors.packSize}</span>
          </div>

          <div className="form-group">
            <label htmlFor="drugType">Drug Type:</label>
            <select id="drugType" name="drugType" className="form-control" value={formData.drugType} onChange={handleChange} required>
              {drugTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
            <div className="form-group">
            <label htmlFor="drugType">Pack Unit:</label>
            <select id="drugType" name="drugType" className="form-control" value={formData.drugType} onChange={handleChange} required>
              {packUnits.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="partnerItemCode">Partner Item Code:</label>
            <input type="text" id="partnerItemCode" name="partnerItemCode" className="form-control" value={formData.partnerItemCode} onChange={handleChange} required />
            <span className="text-danger">{validationErrors.partnerItemCode}</span>
          </div>

          <div className="form-group">
            <label htmlFor="mrp">MRP:</label>
            <input type="number" id="mrp" name="mrp" className="form-control" value={formData.mrp} onChange={handleChange} required />
            <span className="text-danger">{validationErrors.mrp}</span>
          </div>

          <div className="text-center">
            <button type="submit" className="btn btn-primary">Add Drug</button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  user: state.user,
});

export default connect(mapStateToProps)(AddApolloMedicineModal);
