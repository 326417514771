import baseRequest from './baseRequest';

export const fetchAllSponsors = () => {
    const url = '/new-ops/sponsors';
    const mehod = 'GET';
    return baseRequest(url, mehod, null, null);
};

export const fetchAppointmentPreference = () => {
    const url = '/new-ops/appointment-preference-options';
    const mehod = 'GET';
    return baseRequest(url, mehod, null, null);
};


