import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getConflictingOrders } from "../../services/api/labs-reconcile-api";
import LabsConflictingTests from './LabsConflictingTests';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
class LabsConflictingOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            conflictingOrders: [],
        };
    }

    toggle = () => {
        if (!this.state.modal) {
            this.loadConflictingOrders();
        }
        this.setState({
            modal: !this.state.modal,
        });
    };

    loadConflictingOrders = () => {
        getConflictingOrders()
            .then((result) => {
                if(result.body.message === 'success') {
                    this.setState({
                        conflictingOrders: result.body.result,
                    })
                } else {
                    toast.error('Unable to load conflicting orders');
                }
            })
            .catch((err) => {
                toast.error('Unable to load conflicting orders');
                console.error('Error', err);
            });
    };

    renderConflictingOrders = () => {
        return this.state.conflictingOrders.map((o) => {
            const link = `${window.location.origin}/new-lab-order-details?orderId=${o.orderId}&cartId=${o.cartId}&userId=${o.userId}`;
            return (
                <tr key={o.orderId}>
                    <td><a href={link} target="_blank">{o.orderId}</a></td>
                    <td>{o.transactionReferenceId}</td>
                    <td>{o.partnerCount}</td>
                    <td>{o.visitCount}</td>
                    <td>{o.partnerCountdbCount}</td>
                    <td>
                        {o.sponsorName}
                    </td>
                    <td><LabsConflictingTests refresh={this.loadConflictingOrders} referenceId={o.transactionReferenceId}/></td>
                </tr>
            );
        });
    };

    render() {
        return (
            <div>
                <button onClick={this.toggle} className="btn btn-sm btn-primary">Show Summary</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" scrollable={true}>
                    <h4 className="mt-1 mb-0 ml-2">Summary of conflicting orders</h4>
                    <ModalBody>
                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th scope="col">OrderId</th>
                                <th scope="col">ReferenceId</th>
                                <th scope="col">PartnerCount</th>
                                <th scope="col">VisitCount</th>
                                <th scope="col">Difference</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderConflictingOrders()}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>Done</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(LabsConflictingOrders);





