import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import ReactTable from 'react-table';
import { Button, FormGroup, Label } from 'reactstrap';
import { debounce } from '../../utils/debounce';
import { toast } from 'react-toastify';
import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
  IoMdCall,
} from 'react-icons/io';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import ConfirmModal from '../offline-consultations/ConfirmModal';
import { getConsultationReimbursementRequests } from '../../services/api/reimburse-claims';
import {
  placeCall,
  updateConsultationReimbursementRequest,
} from '../../services/api/offline-consult';

const ApproveIcon = styled(IoMdCheckmarkCircleOutline)`
  font-size: 20px;
  color: #00b7b7;
  margin-right: 8px;
  cursor: pointer;
`;

const RejectIcon = styled(IoMdCloseCircleOutline)`
  font-size: 20px;
  color: #ff7448;
  cursor: pointer;
`;

class ConsultationReimburseRequestsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pages: -1,
      filtered: [
        {
          id: 'status',
          value: 'intimation-received',
        },
      ],
      sorted: [],
      pageSize: 20,
      page: 0,
      consultRequests: [],
      allStatus: ['Placed', 'Sample Collected', 'Completed', 'Cancelled'],
      rejectionDialogOpen: false,
      rejectedReimbursement: null,
      approvalDialogOpen: false,
      approvedReimbursement: null,
      dialogActionLoading: false,
      confirmModal: {
        isOpen: false,
        header: '',
        body: '',
        onSubmit: null,
        reimbursementRequestsId: null,
        onClose: null,
        buttonLoading: false,
      },
    };
    this.filtering = true;

    this.fetchData = this.fetchData.bind(this);
    this.fetchDataWithDebounce = debounce(this.fetchData, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('New props vs old props', prevProps, this.props);
    if (this.props.startDate === null || this.props.endDate === null) {
      return;
    }
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      let stDate = 0,
        enDate = 0;
      if (this.props.startDate !== null) {
        stDate = this.props.startDate.startOf('day').unix();
      }
      if (this.props.endDate !== null) {
        enDate = this.props.endDate.endOf('day').unix();
      }

      this.updateStateFilters('reqStartDate', stDate, 'reqEndDate', enDate);
    }
  }

  updateStateFilters = (id, value, id2, value2) => {
    console.log('Update all filters in state', { id, value, id2, value2 });
    // console.log('New filter values', moment(value).format('DD-MM-YYYY'), moment(value2).format('DD-MM-YYYY'));
    const filter = this.state.filtered.find((f) => f.id === id);
    const filter2 = this.state.filtered.find((f) => f.id === id2);
    if (filter) {
      filter.value = value;
    } else {
      this.state.filtered.push({ id, value });
    }
    if (filter2) {
      filter2.value = value2;
    } else {
      this.state.filtered.push({ id: id2, value: value2 });
    }

    this.setState({
      filtered: JSON.parse(JSON.stringify(this.state.filtered)),
    });
  };

  onFilteredChange = (e) => {
    this.setState({ filtered: e });
  };

  fetchStrategy = (state) => this.fetchDataWithDebounce(state);

  prepareApiFilters = () => {
    const appliedFilters = {};
    // let appliedOrderBy = {};
    // Convert this list of filterables into object properties
    if (this.state) {
      this.state.filtered.forEach((filter) => {
        appliedFilters[filter.id] = filter.value;
      });
    }
    // state.sorted.forEach(sort => appliedOrderBy[sort.id] = sort.desc?'desc':'asc');

    appliedFilters.size = this.state.pageSize;
    appliedFilters.page = this.state.page + 1;
    return appliedFilters;
  };

  fetchData = (state) => {
    const { pageSize } = this.state;
    // show the loading overlay
    this.setState({ loading: true });
    this.filtering = false;
    console.log(state);
    const appliedFilters = this.prepareApiFilters(state);

    // fetch your data
    getConsultationReimbursementRequests(appliedFilters)
      .then((response) => {
        console.log('Api response', response.body);
        if (response.body.message === 'noRecords') {
          this.setState({
            consultRequests: [],
            pages: 1,
          });
        }
        const totalRecords = response.body.totalRecords;
        // Compute pages
        this.setState({
          consultRequests: response.body.results,
          pages: Number.parseInt(totalRecords / pageSize),
        });
      })
      .catch((err) => {
        console.error(err, 'Error in get claims requests API');
        toast.error(`Error in fetching orders`);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getColumns = () => [
    {
      Header: 'Consultation Details',
      columns: [
        {
          Header: 'Request ID',
          accessor: 'reimbursementRequestsId',
          filterable: true,
          width: 50,
          Filter: ReactTableInputFilter,
        },
        {
          Header: 'User ID',
          accessor: 'userId',
          width: 75,
        },
        {
          Header: 'Patient Name',
          accessor: 'patientName',
          // width: 120,
        },
        {
          Header: 'Status',
          accessor: 'status',
          filterable: true,
          // width: 100,
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                // style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">Show All</option>
                <option value="intimation-received">Intimation Received</option>
                <option value="intimation-approved">Intimation Approved</option>
                <option value="intimation-rejected">Intimation Rejected</option>
                <option value="documents-uploaded">Documents Uploaded</option>
                <option value="documents-verified">Documents Verified</option>
                <option value="documents-deficient">Documents Deficient</option>
                <option value="rejected">Rejected</option>
                <option value="approved">Approved</option>
              </select>
            );
          },
        },
        {
          Header: 'Appointment Date',
          accessor: 'appointmentDate',
          // width: 100,
          Cell: (row) => (
            <span>{moment(row.original.appointmentDate).format('Do MMM')}</span>
          ),
        },
        {
          Header: 'Actions',
          // width: 50,
          Cell: (row) =>
            row.original.status === 'intimation-received' && (
              <span>
                <Button
                  color="link"
                  onClick={this.confirmApproveRequest(row.original)}
                >
                  <ApproveIcon />
                </Button>
                <Button
                  color="link"
                  onClick={this.confirmRejectRequest(row.original)}
                >
                  <RejectIcon />
                </Button>
                <Button
                  color="primary"
                  outline
                  onClick={this.requestCall(row.original)}
                >
                  <IoMdCall />
                </Button>
              </span>
            ),
        },
      ],
    },
  ];

  requestCall = (reimburseRequest) => () => {
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Call ${reimburseRequest.patientName}`,
        body: `Are you sure you want to place call to ${reimburseRequest.patientName} regarding reimbursement consultation with ${reimburseRequest.doctorName}`,
        onSubmit: this.placeCall(reimburseRequest.requestId),
        requestId: reimburseRequest.requestId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  placeCall = (reimbursementId) => () => {
    const { confirmModal } = this.state;
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    return placeCall(reimbursementId)
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Call placed successfully!');
        } else {
          toast.error('Error placing call');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error placing call');
      });
  };

  showRejectionModal = (reimbursementRequest) => () => {
    this.setState({
      rejectionDialogOpen: true,
      rejectedReimbursement: reimbursementRequest,
    });
  };

  showApprovalModal = (reimbursementRequest) => () => {
    this.setState({
      approvalDialogOpen: true,
      approvedReimbursement: reimbursementRequest,
    });
  };

  hideRejectionDialog = () => {
    this.setState({
      rejectionDialogOpen: false,
      rejectedReimbursement: null,
      approvalDialogOpen: false,
      approveReimbursement: null,
    });
  };

  confirmRejectRequest = (request) => () => {
    this.setState({
      confirmModal: {
        isOpen: true,
        header: 'Reject consultation request',
        body: (
          <div className="container">
            <div className="col-12">
              <FormGroup check>
                <Label for="rejectReason">
                  Specify a reason to reject this request
                </Label>
                <input type="text" name="rejectReason" id="rejectReason" />
              </FormGroup>
            </div>
          </div>
        ),
        onSubmit: this.updateRequest(request.reimbursementRequestsId, 0),
        reimbursementRequestsId: request.reimbursementRequestsId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  confirmApproveRequest = (request) => () => {
    console.log({ request: request.reimbursementRequestsId }, 'fddvf');
    this.setState({
      confirmModal: {
        isOpen: true,
        header: 'Approve consultation request',
        body: 'Are you sure you want to approve this consultation request?',
        onSubmit: this.updateRequest(request.reimbursementRequestsId, 1),
        reimbursementRequestsId: request.reimbursementRequestsId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  updateRequest = (reimbursementRequestsId, status) => () => {
    const { confirmModal } = this.state;
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    let reason;
    if (status === 0) {
      reason = document.querySelector('#rejectReason').value;
      if (!reason) {
        toast.error('Please specify a valid reason to reject this request');
        return Promise.resolve();
      }
    }
    return updateConsultationReimbursementRequest(
      reimbursementRequestsId,
      status,
      reason
    )
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Reimbursement request updated successfully!');
          this.fetchData();
        } else {
          toast.error('Failed to confirm reimbursement request');
        }
      })
      .catch((err) => {
        toast.error('Failed to confirm reimbursement request');
      });
  };

  onApprovalDialogEntered = () => {
    const { approvedReimbursement } = this.state;
    if (
      approvedReimbursement.reimbursementAmount &&
      document.querySelector('#requested-amount')
    ) {
      document.querySelector('#requested-amount').value =
        approvedReimbursement.reimbursementAmount;
    }
  };

  submitConfirmModalGen = () => {
    const { onSubmit, requestId } = this.state.confirmModal;
    return onSubmit(requestId).then(() => {
      this.setState({ confirmModal: { isOpen: false } });
    });
  };

  render() {
    const { confirmModal } = this.state;
    return (
      <>
        <ReactTable
          keyField="requestId"
          data={this.state.consultRequests}
          columns={this.getColumns()}
          defaultPageSize={20}
          pages={this.state.pages}
          pageSize={this.state.pageSize}
          page={this.state.page}
          loading={this.state.loading}
          filtered={this.state.filtered}
          sorted={this.state.sorted}
          manual
          onFetchData={this.fetchStrategy}
          onFilteredChange={this.onFilteredChange}
          onPageSizeChange={(pageSize) => this.setState({ pageSize })}
          onPageChange={(page) => this.setState({ page })}
          onSortedChange={(sorted) => this.setState({ sorted })}
          className="-striped -highlight"
          showPaginationTop
          showPaginationBottom
        />
        <ConfirmModal
          modalHeader={confirmModal.header}
          onClose={confirmModal.onClose}
          isOpen={confirmModal.isOpen}
          onSubmit={this.submitConfirmModalGen}
          loading={confirmModal.buttonLoading}
        >
          {confirmModal.body}
        </ConfirmModal>
      </>
    );
  }
}

export default ConsultationReimburseRequestsTable;
