import React, { Component } from 'react';
import moment from 'moment';
import ReactTable from 'react-table';
import { fetchRequestHistory } from '../../services/api/reimburse-claims';

class ReimbursementClaimsRequestsTableMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastWorkedDate: null,
    };
  }
  getColumns = () => [
    {
      Header: 'Payment Settlement Date',
      accessor: 'paymentSettlementDate',
      width: 200,
      Cell: (row) => (
        <span>
          {row.original.paymentSettlementDate
            ? moment(row.original.paymentSettlementDate).format(
                'DD/MM/YYYY HH:mm'
              )
            : null}
        </span>
      ),
    },
    {
      Header: 'Last worked date',
      accessor: 'lastWorkedDate',
      width: 200,
      filterable: false,
      Cell: (row) => (
        <span>
          {this.state.lastWorkedDate
            ? moment(this.state.lastWorkedDate).format('DD/MM/YYYY HH:mm')
            : null}
        </span>
      ),
    },
    {
      Header: 'Invoices',
      // width: 100,
      sortable: false,
      Cell: (row) =>
        row.original.invoiceLink &&
        row.original.invoiceLink.split(',').map((link, idx) => (
          <div>
            <a
              key={idx}
              rel="noopener noreferrer"
              target="_blank"
              href={link}
            >{`Invoice ${idx + 1}`}</a>
          </div>
        )),
    },
    {
      Header: 'Prescription/Proofs',
      // width: 100,
      sortable: false,
      Cell: (row) =>
        row.original.prescriptionLink &&
        row.original.prescriptionLink.split(',').map((link, idx) => (
          <div>
            <a
              key={idx}
              rel="noopener noreferrer"
              target="_blank"
              href={link}
            >{`Prescription ${idx + 1}`}</a>
          </div>
        )),
    },{
      Header:'Utr No.',
      accessor:`claimTransactionId`,
      sortable:false,
      disablePadding: false,
      width: 200,
      Cell:(row)=>{
        // return (<span>
          return row.original.claimTransactionId && (
            <div>{row.original.claimTransactionId}</div>
          )
        // </span>)
      }
    }
  ];
  getHistory = (requestId) => {
    return fetchRequestHistory(requestId)
      .then((response) => {
        if (response && response.message === 'success') {
          return response.data;
        }
        throw new Error('Response Message is not correct');
      })
      .catch((err) => {
        console.error(err, 'Error while fetching data');
      });
  };

  componentDidMount() {
    const { requestId } = this.props;

    this.getHistory(requestId)
      .then((data) => {
        console.log({ data, requestId }, 'History');
        this.setState({
          lastWorkedDate: data.history[0]
            ? data.history[0].eventDateTime
            : null,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }
  render() {
    console.log(this.props.details[0].claimTransactionId,'[mk]')
    return (
      <ReactTable
        data={this.props.details}
        columns={this.getColumns()}
        style={{ marginLeft: 10 }}
        defaultPageSize={1}
        showPaginationBottom={false}
      />
    );
  }
}
export default ReimbursementClaimsRequestsTableMore;
