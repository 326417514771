import React from 'react';

import { Container, ContainerItem } from '../styled-elements'
import HistoryContainer from './HistoryContainer';

const SubContainer = ({
    requestId, type
}) => {

    return <Container>
        <ContainerItem style={{ width: "300px" }}>
            <HistoryContainer requestId={requestId} type={type} />
        </ContainerItem>
        <ContainerItem />
        <ContainerItem />
    </Container>
}

export default SubContainer