import baseRequest, {
  baseRequestWithHeaders,
  baseRequestWithHeadersFileResponse,
  baseRequestMultiPart,
} from './baseRequest';
import config from '../../config';
import axios from 'axios';
import { store } from '../../store';

const redirectForLogin = () => {
  window.location.href = '/';
 }
 
 axios.interceptors.response.use((response) => response,  (error) => {
   const statusCode = error.response.data.code;
   console.log(statusCode);
   return statusCode === 401 ?  redirectForLogin() : Promise.reject(error);
 });

export const getOrders = (filters, appliedOrderBy, csv) => {
  if (filters.status && filters.status === 'all') {
    filters.status = null;
  }
  if (filters.receiptUrl && filters.receiptUrl === 'all') {
    filters.receiptUrl = null;
  }
  filters.orderBy = appliedOrderBy;
  console.log({ filters }, '[getOrders]');
  const urlQuery = new URLSearchParams(filters).toString();

  const url = `/new-ops/pharmacy/orders/all?${urlQuery}`;
  const method = 'GET';
  if (!csv) {
    return baseRequestWithHeaders(url, method, null);
  }
  return baseRequestWithHeadersFileResponse(url, method, null);
};

export const saveRemark = (orderId, remark) => {
  const url = `/new-ops/pharmacy/orders/remark`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null, { orderId, remark });
};
export const cancelOrder = (orderId) => {
  const url = `/new-ops/pharmacy/cancel-order?orderId=${orderId}&reasonId=6`;
  const method = 'POST';
  return baseRequestWithHeaders(url, method, null);
};
export const getOrderDetails = (orderId) => {
  const url = `/new-ops/pharmacy/orders/${orderId}`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method);
};

export const getWalletDetailsFromOrderId = (orderId) => {
  const url = `/new-ops/pharmacy/walletDetails/${orderId}`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method);
};

export const getReasonsForRefund = () => {
  const url = `/new-ops/pharmacy/get-refund-reasons`;
  const method = 'GET';
  return baseRequestWithHeaders(url, method);
};

export function changePrice(orderId, reasonId, priceChangeItems) {
  return baseRequest(`/new-ops/pharmacy/changeprice`, 'POST', null, {
    orderId,
    reasonId,
    priceChangeItems,
  });
}

export function cancelOrderNew(orderId, reasonId) {
  return baseRequest('/new-ops/pharmacy/cancel-order', 'POST', null, {
    orderId,
    reasonId,
  });
}

export function changePartner(orderId, partnerId) {
  return baseRequest('/new-ops/pharmacy/change-partner', 'POST', null, {
    orderId,
    newPartnerId: partnerId,
  });
}

export function getUserPresDetailsFromPhone(phone) {
  console.log(phone);
  return baseRequest(
    '/new-ops/pharmacy/get-user-pres-details-from-phone',
    'POST',
    null,
    { phone }
  );
}

export async function uploadPharmacyPrescription(file, phone,onUploadProgress, authToken) {
  console.log('data----', phone, file);
  const fileData = new FormData();

  fileData.append('file', file);
  //console.log("fileData",fileData)
  const body = {
    demo: phone,
  };

  const headers = {
    authorization: authToken,
  };

  return await axios
    .post(
      `${config.pharmacyService}/digitisation/upload-prescription-ops`,
      fileData,
      { headers,
        onUploadProgress }
    )
    .then((res) => {
      const responseBody = res.data;
      if (responseBody.message === 'success') {
        return responseBody.pharmacyPrescriptionId;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        throw err;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export async function bookUserPharmacyOrder(addressId, patientId, prescriptionId, phone){
  return baseRequest(
    '/new-ops/pharmacy/book-user-order',
    'POST',
    null,
    { phone, addressId, patientId, prescriptionId }
  );
}

export async function refundWalletAmount(orderId, serviceKey, relativeId, amount, userId){
  return baseRequest(
    '/new-ops/pharmacy/refund-order-amount',
    'POST',
    null,
    {orderId, serviceKey, relativeId, amount, userId}
  )
}

export const fetchRequestHistory = orderId => {
  const url = `/new-ops/pharmacy/orders/${orderId}/history`;
  const method = 'GET';
  const params = {
    orderId,
  };
  return baseRequest(url, method, params, null);
};

export const viewCommentRequest = (orderId) => {
  const url = `/new-ops/pharmacy/orders/${orderId}/remark`;
  const method = 'GET';
  const data = {};
  return baseRequest(url, method, null, data);
};

export const storeCommentRequest = (orderId, remark, type) => {
  const url = `/new-ops/pharmacy/orders/remark`;
  const method = 'POST';
  const data = {
    remark,
    type,
    orderId
  };
  return baseRequest(url, method, null, data);
};

export const getApprovalRequiredOrder = () => {
  const url = `/new-ops/pharmacy/approval-required-order`;
  const method = 'GET';
  const data = {};
  return baseRequest(url, method, null, data);
};


export const updateApprovalRequiredOrder = (cartId) => {
  const url = `/new-ops/pharmacy/approval-required-order/${cartId}`
  const method = 'PUT';
  const data = {
  };
  return baseRequest(url, method, null, data);
};

export const rejectApprovalRequiredOrder = (cartId) => {
  const url = `/new-ops/pharmacy/approval-required-order/reject/${cartId}`
  const method = 'PUT';
  const data = {
  };
  return baseRequest(url, method, null, data);
};
