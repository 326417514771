import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Collapse, Button, CardBody, Card } from 'reactstrap';

class UserDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userPhone: null,
        };
    }

    render() {
        return (
            <div className="row">
                <div className="container-fluid">
                    <div className="row">
                        <div className="form-inline form">
                            <div className="form-group mx-sm-3 mb-2">
                                <label htmlFor="inputPassword2" className="font-weight-bolder mr-2">Phone Number</label>
                                <input type="number" className="form-control" id="inputPassword2" placeholder="9879879871" onChange={(e) => this.setState({userPhone: e.target.value})}/>
                            </div>
                            <button type="submit" className="btn btn-primary mb-2" onClick={() => this.props.getUser(this.state.userPhone)}>Search User</button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            Name: <span className="font-weight-bold">{this.props.userInfo.userName}</span>
                        </div>
                        <div className="col">
                            Sponsor Name: {this.props.userInfo.sponsorName}
                        </div>
                        <div className="col">
                            Gender: {this.props.userInfo.userGender}
                        </div>
                        <div className="col">
                            Email: {this.props.userInfo.userEmail}
                        </div>


                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({ userInfo: state.createLabRxDetails.userInfo });

export default connect(
    mapStateToProps,
)(UserDetails);





