export const weekday = [
    { value: 1, label: 'Sunday' },
    { value: 2, label: 'Monday' },
    { value: 3, label: 'Tuesday' },
    { value: 4, label: 'Wednesday' },
    { value: 5, label: 'Thursday' },
    { value: 6, label: 'Friday' },
    { value: 7, label: 'Saturday' },
  ];
  
  export const time = [
    { value: '00:00:00', label: '12:00 AM' },
    { value: '00:10:00', label: '12:10 AM' },
    { value: '00:20:00', label: '12:20 AM' },
    { value: '00:30:00', label: '12:30 AM' },
    { value: '00:40:00', label: '12:40 AM' },
    { value: '00:50:00', label: '12:50 AM' },
    { value: '01:00:00', label: '1:00 AM' },
    { value: '01:10:00', label: '1:10 AM' },
    { value: '01:20:00', label: '1:20 AM' },
    { value: '01:30:00', label: '1:30 AM' },
    { value: '01:40:00', label: '1:40 AM' },
    { value: '01:50:00', label: '1:50 AM' },
    { value: '02:00:00', label: '2:00 AM' },
    { value: '02:10:00', label: '2:10 AM' },
    { value: '02:20:00', label: '2:20 AM' },
    { value: '02:30:00', label: '2:30 AM' },
    { value: '02:40:00', label: '2:40 AM' },
    { value: '02:50:00', label: '2:50 AM' },
    { value: '03:00:00', label: '3:00 AM' },
    { value: '03:10:00', label: '3:10 AM' },
    { value: '03:20:00', label: '3:20 AM' },
    { value: '03:30:00', label: '3:30 AM' },
    { value: '03:40:00', label: '3:40 AM' },
    { value: '03:50:00', label: '3:50 AM' },
    { value: '04:00:00', label: '4:00 AM' },
    { value: '04:10:00', label: '4:10 AM' },
    { value: '04:20:00', label: '4:20 AM' },
    { value: '04:30:00', label: '4:30 AM' },
    { value: '04:40:00', label: '4:40 AM' },
    { value: '04:50:00', label: '4:50 AM' },
    { value: '05:00:00', label: '5:00 AM' },
    { value: '05:10:00', label: '5:10 AM' },
    { value: '05:20:00', label: '5:20 AM' },
    { value: '05:30:00', label: '5:30 AM' },
    { value: '05:40:00', label: '5:40 AM' },
    { value: '05:50:00', label: '5:50 AM' },
    { value: '06:00:00', label: '6:00 AM' },
    { value: '06:10:00', label: '6:10 AM' },
    { value: '06:20:00', label: '6:20 AM' },
    { value: '06:30:00', label: '6:30 AM' },
    { value: '06:40:00', label: '6:40 AM' },
    { value: '06:50:00', label: '6:50 AM' },
    { value: '07:00:00', label: '7:00 AM' },
    { value: '07:10:00', label: '7:10 AM' },
    { value: '07:20:00', label: '7:20 AM' },
    { value: '07:30:00', label: '7:30 AM' },
    { value: '07:40:00', label: '7:40 AM' },
    { value: '07:50:00', label: '7:50 AM' },
    { value: '08:00:00', label: '8:00 AM' },
    { value: '08:10:00', label: '8:10 AM' },
    { value: '08:20:00', label: '8:20 AM' },
    { value: '08:30:00', label: '8:30 AM' },
    { value: '08:40:00', label: '8:40 AM' },
    { value: '08:50:00', label: '8:50 AM' },
    { value: '09:00:00', label: '9:00 AM' },
    { value: '09:10:00', label: '9:10 AM' },
    { value: '09:20:00', label: '9:20 AM' },
    { value: '09:30:00', label: '9:30 AM' },
    { value: '09:40:00', label: '9:40 AM' },
    { value: '09:50:00', label: '9:50 AM' },
    { value: '10:00:00', label: '10:00 AM' },
    { value: '10:10:00', label: '10:10 AM' },
    { value: '10:20:00', label: '10:20 AM' },
    { value: '10:30:00', label: '10:30AM' },
    { value: '10:40:00', label: '10:40 AM' },
    { value: '10:50:00', label: '10:50 AM' },
    { value: '11:00:00', label: '11:00 AM' },
    { value: '11:10:00', label: '11:10 AM' },
    { value: '11:20:00', label: '11:20 AM' },
    { value: '11:30:00', label: '11:30 AM' },
    { value: '11:40:00', label: '11:40 AM' },
    { value: '11:50:00', label: '11:50 AM' },
    { value: '12:00:00', label: '12:00 PM' },
    { value: '12:10:00', label: '12:10 PM' },
    { value: '12:20:00', label: '12:20 PM' },
    { value: '12:30:00', label: '12:30 PM' },
    { value: '12:40:00', label: '12:40 PM' },
    { value: '12:50:00', label: '12:50 PM' },
    { value: '13:00:00', label: '1:00 PM' },
    { value: '13:10:00', label: '1:10 PM' },
    { value: '13:20:00', label: '1:20 PM' },
    { value: '13:30:00', label: '1:30 PM' },
    { value: '13:40:00', label: '1:40 PM' },
    { value: '13:50:00', label: '1:50 PM' },
    { value: '14:00:00', label: '2:00 PM' },
    { value: '14:10:00', label: '2:10 PM' },
    { value: '14:20:00', label: '2:20 PM' },
    { value: '14:30:00', label: '2:30 PM' },
    { value: '14:40:00', label: '2:40 PM' },
    { value: '14:50:00', label: '2:50 PM' },
    { value: '15:00:00', label: '3:00 PM' },
    { value: '15:10:00', label: '3:10 PM' },
    { value: '15:20:00', label: '3:20 PM' },
    { value: '15:30:00', label: '3:30 PM' },
    { value: '15:40:00', label: '3:40 PM' },
    { value: '15:50:00', label: '3:50 PM' },
    { value: '16:00:00', label: '4:00 PM' },
    { value: '16:10:00', label: '4:10 PM' },
    { value: '16:20:00', label: '4:20 PM' },
    { value: '16:30:00', label: '4:30 PM' },
    { value: '16:40:00', label: '4:40 PM' },
    { value: '16:50:00', label: '4:50 PM' },
    { value: '17:00:00', label: '5:00 PM' },
    { value: '17:10:00', label: '5:10 PM' },
    { value: '17:20:00', label: '5:20 PM' },
    { value: '17:30:00', label: '5:30 PM' },
    { value: '17:40:00', label: '5:40 PM' },
    { value: '17:50:00', label: '5:50 PM' },
    { value: '18:00:00', label: '6:00 PM' },
    { value: '18:10:00', label: '6:10 PM' },
    { value: '18:20:00', label: '6:20 PM' },
    { value: '18:30:00', label: '6:30 PM' },
    { value: '18:40:00', label: '6:40 PM' },
    { value: '18:50:00', label: '6:50 PM' },
    { value: '19:00:00', label: '7:00 PM' },
    { value: '19:10:00', label: '7:10 PM' },
    { value: '19:20:00', label: '7:20 PM' },
    { value: '19:30:00', label: '7:30 PM' },
    { value: '19:40:00', label: '7:40 PM' },
    { value: '19:50:00', label: '7:50 PM' },
    { value: '20:00:00', label: '8:00 PM' },
    { value: '20:10:00', label: '8:10 PM' },
    { value: '20:20:00', label: '8:20 PM' },
    { value: '20:30:00', label: '8:30 PM' },
    { value: '20:40:00', label: '8:40 PM' },
    { value: '20:50:00', label: '8:50 PM' },
    { value: '21:00:00', label: '9:00 PM' },
    { value: '21:10:00', label: '9:10 PM' },
    { value: '21:20:00', label: '9:20 PM' },
    { value: '21:30:00', label: '9:30 PM' },
    { value: '21:40:00', label: '9:40 PM' },
    { value: '21:50:00', label: '9:50 PM' },
    { value: '22:00:00', label: '10:00 PM' },
    { value: '22:10:00', label: '10:10 PM' },
    { value: '22:20:00', label: '10:20 PM' },
    { value: '22:30:00', label: '10:30 PM' },
    { value: '22:40:00', label: '10:40 PM' },
    { value: '22:50:00', label: '10:50 PM' },
    { value: '23:00:00', label: '11:00 PM' },
    { value: '23:10:00', label: '11:10 PM' },
    { value: '23:20:00', label: '11:20 PM' },
    { value: '23:30:00', label: '11:30 PM' },
    { value: '23:40:00', label: '11:40 PM' },
    { value: '23:50:00', label: '11:50 PM' },
  ];
  
  export const mappingOfWeekday = {
    1: 'Sunday',
    2: 'Monday',
    3: 'Tuesday',
    4: 'Wednesday',
    5: 'Thursday',
    6: 'Friday',
    7: 'Saturday',
  };
  