import baseRequest from './baseRequest';

export const fetchAlldoctors = (params) => {
  const url = '/new-ops/doc-on-call/get-doctors';
  const mehod = 'GET';
  console.log('fetchAlldoctors:params', params);
  return baseRequest(url, mehod, params, null);
};

export const fetchDoctorVerticals = (params)=>{
  const url = '/new-ops/doc-on-call/fetch-all-verticals';
  const method = 'GET';
  console.log('fetchAllVerticals:params', params);
  return baseRequest(url,method,params,null);
}

export const fetchLanguages = ()=>{
  const url = '/new-ops/doctor/get-all-languages';
  const method = 'GET';
  //console.log('fetchAllLanguages:params', params);
  return baseRequest(url,method,null,null);
}

export const createDoctor = (postData)=>{
  const url = '/new-ops/doctor/add-doctor-profile';
  const method = 'post';
  //console.log('fetchAllLanguages:params', params);
  return baseRequest(url,method,null,postData);
}

export const fetchUserCenter = (centerRegExValue)=>{
  const url = '/new-ops/getNetworkCenters';
  const method = 'get';
  const params = {q:centerRegExValue};
  return baseRequest(url,method,params,null);
}

export const editDoctorSlots = (postData, doctorId)=>{
  const url = '/new-ops/practice/doctor-slots/:doctorId';
  const method = 'post';
  const params = {doctorId:doctorId};
  return baseRequest(url,method,params,postData);
}
