import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { loadCostUpdates, updateCostDiff } from "../../services/api/labs-reconcile-api";
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
class TestCostsUpdates extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            costUpdates: [],
            summary: {
                sum: 'NA',
            },
        };
    }

    toggle = () => {
        if (!this.state.modal) {
            this.getCostUpdates();
        }
        this.setState({
            modal: !this.state.modal,
        });
    };

    updateCosts = () => {
        updateCostDiff()
            .then((result) => {
                if(result.body.message === 'success') {
                   this.toggle();
                } else {
                    toast.error('Unable to update costs');
                }
            })
            .catch((err) => {
                toast.error('Unable to update costs');
                console.error('Error', err);
            });
    };

    getCostUpdates = () => {
        loadCostUpdates()
        .then((result) => {
            if(result.body.message === 'success') {
                this.setState({
                    costUpdates: result.body.result,
                    summary: {
                        sum: result.body.summary,
                    },
                })
            } else {
                toast.error('Unable to load updates');
            }
        })
        .catch((err) => {
            toast.error('Unable to load updates');
            console.error('Error', err);
        });
    };

    renderCostUpdates = () => {
        return this.state.costUpdates.map((o, index) => {
            return (
                <tr key={index}>
                    <td>{o.orderId}</td>
                    <td>{o.referenceId}</td>
                    <td>{o.visitTestId}</td>
                    <td>{o.partnerTestCode}</td>
                    <td>{o.dbMrp}</td>
                    <td>{o.partnerMrp}</td>
                    <td>{o.dbCost}</td>
                    <td>{o.partnerCost}</td>
                    <td>{o.diffCost}</td>
                </tr>
            );
        });
    };

    render() {
        return (
            <div>
                <button onClick={this.toggle} className="btn btn-sm btn-primary">Show Updates</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" scrollable={true}>
                    <h4 className="mt-1 mb-0 ml-2">Net difference in COST: {this.state.summary.sum}</h4>
                    <ModalBody>
                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th scope="col">OrderId</th>
                                <th scope="col">PartnerOrderId</th>
                                <th scope="col">Test ID</th>
                                <th scope="col">Partner Test Code</th>
                                <th scope="col">Visit MRP</th>
                                <th scope="col">Partner MRP</th>
                                <th scope="col">Visit Cost</th>
                                <th scope="col">Partner Cost</th>
                                <th scope="col">Cost Diff</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderCostUpdates()}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.updateCosts}>Update</Button>
                        <Button color="secondary" onClick={this.toggle}>Done</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(TestCostsUpdates);





