import React from 'react';
import {fetchPartnerDetails} from "../../services/api/lab-partners";
import { PageHeader } from "../common/PageHeader";

class LabsDetailsHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerName: '',
            partnerType: '',
        }
    }

    getPartnerDetails = () => {
        const partnerId = this.props.partnerId;
        if (isNaN(partnerId) || partnerId === null) {
            return;
        }
        return fetchPartnerDetails(partnerId)
            .then((response) => {
                const data = response.body;
                if (data.message === 'success') {
                    this.setState({
                        partnerName: data.partnerDetails.displayName,
                        partnerType: data.partnerDetails.labType,
                    }, this.getPartnerTests);
                }
            })
            .catch((err) => {
                console.error({ err, partnerId },
                    'Error while fetching partner details');
            })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.partnerId !== this.props.partnerId) {
            this.getPartnerDetails();
        }
    }

    componentDidMount() {
        this.getPartnerDetails();
    }

    render() {
        const { type } = this.props;
        const { partnerName, partnerType } = this.state;
        return (
            <PageHeader>
               {type} for {partnerName} (<small>{partnerType}</small>)
            </PageHeader>
        );
    }

}

export default LabsDetailsHeader;
