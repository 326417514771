import React from 'react';
import NavBar from '../layout/NavBar';
import PaymentRequestsList from './PaymentRequestsList';

const PaymentRequests = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <NavBar />
        </div>
        <div className="col-12">
          <PaymentRequestsList />
        </div>
      </div>
    </div>
  );
};

export default PaymentRequests;
