export const defaultState = {
  showSideBar: false
};

export default function app(state = defaultState, action) {
  switch (action.type) {
    case "TOGGLE_SIDEBAR": {
      let sideBarStatus = !state.showSideBar;
      if(action && action.payload === false){
        sideBarStatus = false;
      }
      return Object.assign({}, state, {
        showSideBar: sideBarStatus
      });
    }
    default:
      return state;
  }
}
