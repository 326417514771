import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getMissingTransactions } from "../../services/api/labs-reconcile-api";
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
class MissingLabTransactions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            tests: [],
        };
    }

    toggle = () => {
        if (!this.state.modal) {
            this.getCostUpdates();
        }
        this.setState({
            modal: !this.state.modal,
        });
    };


    getCostUpdates = () => {
        getMissingTransactions()
            .then((result) => {
                if(result.body.message === 'success') {
                    this.setState({
                        tests: result.body.result,
                    })
                } else {
                    toast.error('Unable to load updates');
                }
            })
            .catch((err) => {
                toast.error('Unable to load updates');
                console.error('Error', err);
            });
    };

    renderMissingTransactions = () => {
        return this.state.tests.map((o, index) => {
            return (
                <tr key={index}>
                    <td>{o.referenceId}</td>
                    <td>{o.name}</td>
                    <td>{o.testName}</td>
                    <td>{o.partnerTestCode}</td>
                    <td>{o.date}</td>
                    <td>{o.partnerMrp}</td>
                    <td>{o.partnerCost}</td>
                </tr>
            );
        });
    };

    render() {
        return (
            <div>
                <button onClick={this.toggle} className="btn btn-sm btn-primary">View All</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" scrollable={true}>
                    <h4 className="mt-1 mb-0 ml-2">Showing all missing tests/orders</h4>
                    <ModalBody>
                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th scope="col">Reference ID</th>
                                <th scope="col">User Details</th>
                                <th scope="col">Test Name</th>
                                <th scope="col">Partner Test Code</th>
                                <th scope="col">Date</th>
                                <th scope="col">MRP</th>
                                <th scope="col">Cost</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderMissingTransactions()}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.toggle}>Done</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(MissingLabTransactions);





