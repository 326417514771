import React from 'react';

const options = () => {
  return (
    <>
      <option value="">All</option>
      <option value="emergencyCalls">Emergency Calls</option>
      <option value="escalatedCases">Escalated Case</option>
      <option value="pendingFollowUps">Follow Up Pending</option>
    </>
  );
};
const TagsFilter = ({ value, onChangeTags }) => {
  const handleChage = (event) => {
    const value = event.target.value === '' ? null : event.target.value;
    onChangeTags(value);
  };

  return (
    <select
      onChange={handleChage}
      style={{
        width: '100%',
        fontSize: '12px',
        borderRadius: '10px',
        height: '42px',
      }}
      value={value}
    >
      {options()}
    </select>
  );
};

export default TagsFilter;
