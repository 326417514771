import React, { Component } from 'react';
import Modal from '../../common/Modal';
import { FadeInTransition } from './transitions';
import styled from 'styled-components';
import StyledTappable from './StyledTappable';
import uploadIcon from '../../../images/upload.svg';
import photoIcon from '../../../images/photo-icon.svg';
import fileIcon from '../../../images/file-icon.svg';

const ModalContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 12px 12px 0 0;
`;

const AttachOptionsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1.25rem;
  margin-top: 1rem;
`;

const AttachOption = styled(StyledTappable)`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  margin: 0 0.25rem;
`;

const AttachOptionInnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  overflow: hidden;
`;

const AttachOptionText = styled.div`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
`;

const AttachmentInput = styled.input`
  display: none;
`;

export class UploadModal extends Component {
  state = {
    showModal: true,
  };
  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      showModal: false,
    });
    setTimeout(this.props.hideModal, 200);
  };

  onAttachPhoto() {
    document.querySelector('#photoAttachment').click();
  }

  onAttachFile() {
    document.querySelector('#fileAttachment').click();
  }

  onAttachCapture() {
    document.querySelector('#captureAttachment').click();
  }

  render() {
    const { showModal } = this.state;
    return (
      <div>
        <AttachmentInput
          type="file"
          id="photoAttachment"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={() => this.props.handleAttachment('photo')}
        />
        <AttachmentInput
          type="file"
          id="fileAttachment"
          accept=".pdf"
          style={{ display: 'none' }}
          onChange={() => this.props.handleAttachment('file')}
        />
        <div
            ref={(node) => (this.node = node)}
          >
          <AttachOptionsContainer>
            <AttachOption onTap={() => this.onAttachPhoto()}>
              <AttachOptionInnerContainer>
                <img src={photoIcon} alt="upload-image" />
                <AttachOptionText>Image</AttachOptionText>
              </AttachOptionInnerContainer>
            </AttachOption>
            <AttachOption onTap={() => this.onAttachFile()}>
              <AttachOptionInnerContainer>
                <img src={fileIcon} alt="upload-pdf" />
                <AttachOptionText>PDF</AttachOptionText>
              </AttachOptionInnerContainer>
            </AttachOption>
            {/* <AttachOption onTap={() => this.onAttachFile()}> */}
            {/* <AttachOption onTap={() => this.onAttachCapture()}>
                    <AttachOptionInnerContainer>
                      <img src={cameraIcon} alt="click-pic" />
                      <AttachOptionText>Camera</AttachOptionText>
                    </AttachOptionInnerContainer>
                  </AttachOption> */}
          </AttachOptionsContainer>
          </div>
      </div>
    );
  }
}

export default UploadModal;
