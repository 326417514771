import React, { Component } from 'react'
import ReactTable from 'react-table';
import { FormGroup, Label, Tooltip, Badge, Button,ButtonGroup } from 'reactstrap';
import { Input } from 'valuelink/lib/tags';
import {Link as RouterLink } from 'react-router-dom';
import { debounce } from '../../utils/debounce';
import moment from 'moment';
import NavBar from '../layout/NavBar';
import { DateRangePicker } from 'react-dates';
import { getRemarkForDoc } from '../../services/api/reimburse-claims';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Link from 'valuelink';
import DownloadCsv from '../common/DownloadCsv';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';

class ReimbursementQcRemark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
      loading: true,
      searchInput: '',
      pages: -1,
      pageSize: 20,
      page: 0,
      data:[],
      filtered:[],
      totalRecords: null,
    };
    this.fetchData = this.getRemark.bind(this);
    this.tableRef = React.createRef();
    this.fetchDataWithDebounce = debounce(this.fetchData, 300);
    this.fetchSearchInputWithDebounce = debounce((ele) => {
      if (this.tableRef) {
        this.getRemark(this.tableRef.current.state, null, ele);
      }
    }, 300);
  }


  fetchStrategy = (state) => this.fetchDataWithDebounce(state);

  onDatesChanged = ({ startDate, endDate }) => {
    this.setState({ startDate, endDate }, () => {
      if (this.state.startDate && this.state.endDate) {
        this.getRemark();
      }
    });
  };

  getRemark() {
    const appliedFilters = {};
    const { startDate, endDate,searchInput } = this.state;
    if (this.state) {
        this.state.filtered.forEach((filter) => {
          if (filter.id === 'requestStatus') {
            appliedFilters.status = filter.value;
          } else {
            appliedFilters[filter.id] = filter.value;
          }
        });
      }
    appliedFilters.size = this.state.pageSize;
    appliedFilters.page = this.state.page + 1;
    appliedFilters.startDate = startDate && moment(startDate).unix();
    appliedFilters.endDate = endDate && moment(endDate).unix();
    getRemarkForDoc(appliedFilters,searchInput)
    .then((response)=>{
        console.log(response.body.data,'[response]')
        if(response.body.message === "success"){
          const totalRecords = response.body.totalRecords
            this.setState({
                data:response.body.data,
                totalRecords,
                loading: false,
                pages:Math.ceil(totalRecords / this.state.pageSize)
            })
        }
    }).catch((err)=>{
        console.log(err,'[getRemarkResponseError]')
        toast.error(`${err} || Something went wrong`)
    })
  }

  onFilteredChange = (e) => {
    this.setState({ filtered: e, page: 0, pages: 0 });
  };

  getColumns = () => [
    {
      Header: 'Reimbursement Remarks',
      columns: [
        {
          Header: 'Reimbursement ID',
          accessor: 'reimbursementId',
          // filterable: true,
          width: 50,
            Filter: ReactTableInputFilter,
          Cell: (row) => {
            const { reimbursementId } = row.original;
            return (
              <RouterLink
                title={reimbursementId}
                to={`/reimburse-claim/${reimbursementId}`}
              >
                {reimbursementId}
              </RouterLink>
            );
          },
        },
        {
          Header: 'Reimbursement Claimed by',
          accessor: 'intialDocName',
          filterable: true,
            Filter: ReactTableInputFilter,
        },
        {
          Header: 'Qc Claimed By',
          accessor: 'qcDocName',
          filterable: true,
          Filter: ReactTableInputFilter
        },
        {
            Header:'Issue',
            accessor:'declaredRemarks'
        },
        {
          Header: 'Comment',
          accessor: 'remark',
          // width: 100,
          Cell: (row) => (
            <span>{`${row.original.remark || 'NA'}`}</span>
          ),
        },
        {
          Header: 'QC Allotted on',
          accessor: 'qcAllotedTime',
          Cell: (row) => (
            <span>
              {row.original.qcAllotedTime
                ? moment(row.original.qcAllotedTime).format('DD/MM/YYYY HH:mm')
                : null}
            </span>
          ),
        },
        {
          Header:'Claim Request date',
          accessor:'createdAt',
          Cell:(row)=>(
            <span>
              {row.original.createdAt ? moment(row.original.createdAt).format('DD/MM/YYYY HH:mm'):null}
            </span>
          )
        },
        {
          Header: 'Status',
          accessor: 'requestStatus',
          // filterable: true,
          // width: 100,
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                // style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">Show All</option>
                <option value="documents-uploaded">Documents Uploaded</option>
                <option value="qc-check">Qc Check</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="documents-deficient">Documents Deficient</option>
                <option value="documents-verified">Documents Verified</option>
              </select>
            );
          },
          Cell: (row) => {
            if (row.original.deletionDate) {
              return (
                <h5>
                  <span className="badge badge-danger">DELETED</span>
                </h5>
              );
            }
            switch (row.original.requestStatus.toUpperCase()) {
              case 'DOCUMENTS-UPLOADED':
                return (
                  <h5>
                    <span className="badge badge-secondary">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                  </h5>
                );
              case 'DOCUMENTS-VERIFIED':
                return (
                  <h5>
                    <span className="badge badge-primary">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                  </h5>
                );
              case 'QC-CHECK':
                return (
                  <h5>
                    <span className="badge badge-secondary">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                  </h5>
                );
              case 'APPROVED':
                return (
                  <h5>
                    <span className="badge badge-success">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                  </h5>
                );
              case 'REJECTED':
                return (
                  <h5>
                    <span className="badge badge-danger">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                  </h5>
                );
              default:
                return (
                  <h5>
                    <span className="badge badge-secondary">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                  </h5>
                );
            }
          },
        },
      ],
    },
  ];

  render() {
    const searchInputLink = Link.state(this, 'searchInput').onChange((ele) => {
      this.fetchSearchInputWithDebounce(ele);
    });
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <NavBar />
          </div>
          <div className="row mt-5 pt-4 pb-3">
            <div className="col-8">
              <h2 className="pl-1">Reimbursement QC Remarks</h2>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              {/* <div className="col-6">
                <FormGroup>
                  <Label for="searchInput">
                    Search by user name/patient name/mobile number
                  </Label>
                  <Input className='ml-2'
                  type="text"
                  name="searchInput"
                  id="searchInput"
                  valueLink={searchInputLink}
                />
                </FormGroup>
              </div> */}
            </div>
            <div className="col">
              <DateRangePicker
                displayFormat={() => 'DD/MM/YYYY'}
                startDateId="startDate"
                endDateId="endDate"
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onDatesChange={this.onDatesChanged}
                isOutsideRange={() => false}
                minimumNights={0}
                focusedInput={this.state.focusedInput}
                onFocusChange={(focusedInput) =>
                  this.setState({ focusedInput })
                }
              />
              <Button className="ml-3" onClick={()=>{
                this.setState({
                    startDate:null,
                    endDate:null,
                    searchInput:'',
                    filtered:[]
                })
              }}>Clear Filters</Button>

              <ButtonGroup className='ml-2' size={'m'}>
                <DownloadCsv jsonArray={this.state.data}></DownloadCsv>
              </ButtonGroup>
            </div>
          </div>
          <div className="mt-3">
            <ReactTable
              keyField="orderId"
              ref={this.tableRef}
              data={this.state.data}
              columns={this.getColumns()}
              defaultPageSize={20}
              pages={this.state.pages}
              pageSize={this.state.pageSize}
              page={this.state.page}
              loading={this.state.loading}
              filtered={this.state.filtered}
              // sorted={this.state.sorted}
              manual
              onFetchData={this.fetchStrategy}
              onFilteredChange={this.onFilteredChange}
              onPageSizeChange={(pageSize) => this.setState({ pageSize })}
              onPageChange={(page) => this.setState({ page })}
              className="-striped -highlight"
              showPaginationTop
              showPaginationBottom
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
    user: state.user,
})

export default connect(mapStateToProps)(ReimbursementQcRemark)