import React from 'react';
import Modal from "../common/Modal";

import { getPartnersForOrder, updatePartnerForOrder, fetchAddressForOrder } from '../../services/api/lab-order-details';

import {toast} from 'react-toastify';
import LoadingComponent from "../common/LoadingComponent";
import {getReasonsForRefund} from "../../services/api/get-labs-orders";

 class LabsOrderChangePartnerForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partners: [],
            fetchPartnersLoading: false,
            updatePartnerLoading: false,
            selectedPartner: {
                id: null,
                meta: null,
            },
            addresses: [],
            selectedAddressId: null,
            reasons: [],
            reasonId: null,
            partnersDetailsModal: false,
            selectedPartnerIndex : 0,
            cartId:null,
        };
    }

    componentDidMount = () => {
        if (this.props.isRadiology) {
            console.log('radiology order',this.props.cartId);
            const cartId=this.props.cartId
           this.getPartners(cartId);
           this.getReasons();

        } else {
            this.setState({
                selectedAddress: this.props.addressId
            });
            this.getAddress();
            this.getReasons();
        }
    }

    getAddress = () => {
        const { orderId } = this.props;
        return fetchAddressForOrder(orderId)
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        addresses: response.addresses,
                    });
                } else {
                    throw new Error(response.errorMessage || 'Something went wrong while fetching addresses');
                }
            })
            .catch((err) => {
                console.error(err);
                toast.error(err.message);
            })
    };

     getPartners = (cartId) => {
        const { selectedAddress } = this.state;
        const { orderId } = this.props;
        // if (!selectedAddress) {
        //     toast.error('Please select a address');
        //     return;
        // }
        this.setState({
            fetchPartnersLoading: true,
            partnersDetailsModal: true,
        });
        return getPartnersForOrder(orderId, selectedAddress,cartId)
            .then((response) => {
                console.log(response, 'Partners Response');
                if (response.message === 'success') {
                    this.setState({
                        partners: response.partners || [],
                        selectedPartner: response.partners.length>0 ? response.partners[0] : {
                            id : null,
                            meta: null,
                        } ,
                    });
                } else {
                    throw new Error('Error while fetching partners order');
                }
            })
            .catch((err) => {
                console.error(err);
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    fetchPartnersLoading: false,
                });
            });
    };

    getReasons = () => {
        return getReasonsForRefund()
            .then(response => {
                const respBody = response.body;
                const reasons = respBody.result;
                let options = [];
                options = reasons.filter(r => (r.type === 'change-partner')).map(r => {
                    return {
                        value: r.id,
                        label: r.reason,
                    };
                })
                options.unshift({
                    value: null,
                    label: 'Select Reason',
                });
                console.log('\n Reasons getReasonsForRefund', options, reasons);
                this.setState({
                    reasons: options
                })
            })
            .catch((err) => {
                console.log('Error in fetching reasons: ', err);
            });
    }


    selectPartner = (id, meta) => {
        this.setState({
            selectedPartner: {
                id,
                meta,
            },
        });
    };

    updatePartner = () => {
        const { orderId } = this.props;
        const { selectedPartner, reasonId } = this.state;
        if (!reasonId) {
            toast.error('Select a reason');
            return;
        }
        this.setState({
            updatePartnerLoading: true,
        });
        return updatePartnerForOrder(orderId, selectedPartner.id, selectedPartner.meta, reasonId)
            .then((response) => {
                if (response.message === 'success') {
                    toast.success('Successfully updated the partner');
                    this.props.onSubmit();
                } else {
                    throw new Error(response.errorMessage || 'Something went wrong while updating the partner');
                }
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err);
            })
            .finally(() => {
                this.setState({
                    updatePartnerLoading: false,
                });
            });
    };

    closeModal = () => {
        this.setState({
            partnersDetailsModal: false,
        });
        this.props.onSubmit();

    }

    render() {
        const  { partnersDetailsModal } = this.state;
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <strong>Pincode</strong>
                        </div>
                    </div>
                    <div className="row">
                        {(this.state.addresses.length) ? (
                            this.state.addresses.map((a) => (
                                <div className={`col border p-2 m-1 clickable ${(a.addressId === this.state.selectedAddress) ? 'border-primary' : ''}`}
                                     onClick={() => (this.setState({ selectedAddress: a.addressId }))}
                                >
                                    {a.title} - {a.address} ({a.pincode})
                                </div>
                            ))
                        ): (null)}
                    </div>
                    <div className="row">
                        <div className="col">
                            {(this.state.fetchPartnersLoading) ? (
                                <LoadingComponent />
                            ) : (<button className="btn btn-primary" onClick={this.getPartners}>Get Partners</button>)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <strong>Partners</strong>
                        </div>
                    </div>
                    {partnersDetailsModal ? (
                    <Modal
                    header={'Partners Details'}
                    isOpen={partnersDetailsModal}
                    onClose={this.closeModal}
                    >
                    <div className="row">
                        <div className="col">
                            Partner
                            <select value={this.state.selectedPartnerIndex} onChange={(e) => (this.setState({ selectedPartnerIndex: e.target.value, selectedPartner: this.state.partners[e.target.value] }))}>
                                {(this.state.partners.map((p, index) => (
                                    <option value={index}>{p.name}</option>
                                )))}
                            </select>
                        </div>
                    </div>
                        {
                        (this.state.partners && this.state.partners.length) ? (
                            ( 
                                <div className="row m-2 border rounded clickable border-primary" >
                                    <div className="col">
                                        {this.state.partners[this.state.selectedPartnerIndex].id} <h5 className="text-danger">{this.state.partners[this.state.selectedPartnerIndex].name}</h5> {this.state.partners[this.state.selectedPartnerIndex].details.map((d) => (
                                        <div className="container">
                                            <div className="row">
                                                <div className="col red">
                                                    <p className="text-primary">TestName</p><p> {d.testName}</p>
                                                </div>
                                                <div className="col">
                                                    <p className="text-primary">MRP</p><p> {d.mrp}</p>
                                                </div>
                                                <div className="col">
                                                    <p className="text-primary">COST</p><p> {d.cost}</p>
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                        <br></br><h4>Final Price= {this.state.partners[this.state.selectedPartnerIndex].postDiscountPrice}</h4><br></br> {(this.state.partners[this.state.selectedPartnerIndex].address) ? (` - ${this.state.partners[this.state.selectedPartnerIndex].address}`) : ''}
                                    </div>
                                </div>
                            )
                        ) : (
                            <div className="row">
                                <div className="col">No Partners Found</div>
                            </div>
                        )}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    Reason
                                    <select value={this.state.reasonId} onChange={(e) => (this.setState({ reasonId: e.target.value }))}>
                                        {(this.state.reasons.map((r) => (
                                            <option value={r.value}>{r.label}</option>
                                        )))}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    {(this.state.selectedPartner && this.state.selectedPartner.id) ? (
                                            (this.state.updatePartnerLoading) ? (
                                                <LoadingComponent />
                                            ) : (<button className="btn btn-primary" onClick={this.updatePartner}>Update Partner</button>)
                                    ) : (null)}
                                </div>
                            </div>
                        </div>
                    </Modal>
                    ): <p></p>}
                </div>
            </React.Fragment>
        );
    }
}
export default LabsOrderChangePartnerForm;