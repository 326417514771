import React, { Component } from 'react';
import { OuterComponent } from '../common/modal/StyledComponent';
import { Button, Form, FormGroup, Label, Row, Col, Input } from 'reactstrap';
import { fetchAllSponsors } from '../../services/api/sponsors';
import LoadingComponent from '../common/LoadingComponent';
import Select from 'react-select';
import moment from 'moment';

export default class CreateUserForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // for create user form
      sponsors: [],
      sponsorsLoading: false,
      newUserForm: {
        sponsorId: null,
        name: '',
        email: '',
        dob: '',
        phone: '',
        age: '',
        gender: '',
      },
    };
    this.sponsorSelectRef = React.createRef();
  }

  componentDidMount() {
    this.getSponsors();
  }

  getSponsors = () => {
    this.setState({ sponsorsLoading: true });
    return fetchAllSponsors()
      .then((response) => {
        console.log(response, 'Response from server');
        const sponsors = response.data.map((sponsor) => {
          return {
            value: sponsor.sponsorId,
            label: `${sponsor.sponsorName} - ${sponsor.sponsorId}`,
          };
        });
        this.setState({
          sponsors,
          sponsorsLoading: false,
        });
      })
      .catch(() => {
        this.setState({
          sponsors: [],
          sponsorsLoading: false,
        });
      });
  };

  onSubmit = () => {
    console.log('[new user form in CreateUserForm component]', this.state.newUserForm);
    this.props.createNewUser(this.state.newUserForm);
  }

  render() {
    console.log(this.props, this.state, 'render');

    return (
        <OuterComponent>
            <Form>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="sponsor">Sponsor</Label>
                  </Col>
                  <Col>
                    <Select
                      name="sponsor"
                      id="sponsor"
                      isSearchable={true}
                      ref={this.sponsorSelectRef}
                      options={this.state.sponsors}
                      isLoading={this.state.sponsorsLoading}
                      onChange={(e) =>
                        this.setState({
                          newUserForm: {
                            ...this.state.newUserForm,
                            sponsorId: e.value,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="fullName">Full Name</Label>
                  </Col>
                  <Col>
                    <Input
                      name="fullName"
                      id="fullName"
                      required={true}
                      type="text"
                      onChange={(e) =>
                        this.setState({
                          newUserForm: {
                            ...this.state.newUserForm,
                            name: e.target.value,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup id="email">
                <Row>
                  <Col className="col-3">
                    <Label for="email">Email</Label>
                  </Col>
                  <Col>
                    <Input
                      name="email"
                      id="email"
                      required={true}
                      type="email"
                      onChange={(e) =>
                        this.setState({
                          newUserForm: {
                            ...this.state.newUserForm,
                            email: e.target.value,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="phoneNumber">Phone Number</Label>
                  </Col>
                  <Col>
                    <Input
                      name="phoneNumber"
                      id="phoneNumber"
                      required={true}
                      type="number"
                      onChange={(e) =>
                        this.setState({
                          newUserForm: {
                            ...this.state.newUserForm,
                            phone: e.target.value,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="selectGender">Gender</Label>
                  </Col>
                  <Col>
                    <Select
                      name="selectGender"
                      id="selectGender"
                      required={true}
                      isSearchable={false}
                      options={[
                        { value: 'male', label: 'Male' },
                        { value: 'female', label: 'Female' },
                        { value: 'other', label: 'Other' },
                      ]}
                      onChange={(e) =>
                        this.setState({
                          newUserForm: {
                            ...this.state.newUserForm,
                            gender: e.value,
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label for="dob">Date of Birth</Label>
                  </Col>
                  <Col>
                    <Input
                      name="dob"
                      id="dob"
                      required={true}
                      type="date"
                      onChange={(e) =>
                        this.setState({
                          newUserForm: {
                            ...this.state.newUserForm,
                            dob: e.target.value,
                            age: moment().diff(e.target.value, 'years')
                          },
                        })
                      }
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          <div className="flex flex-row justify-end">
            <div className="flex flex-col m-3">
              {this.props.loading ? (
                <LoadingComponent />
              ) : (
                <Button color="primary" onClick={this.onSubmit}>
                  Create User
                </Button>
              )}
            </div>
            <div className="flex flex-col m-3">
              <Button color="secondary" onClick={this.props.closeModal}>
                Close
              </Button>
            </div>
          </div>
        </OuterComponent>
    );
  }
}
