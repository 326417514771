import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { updateApprovalRequiredOrder, rejectApprovalRequiredOrder } from '../../services/api/get-pharmacy-orders';

export default function StarPharmacyOrder({
  isOpen,
  tableSource,
  getOrdersForApprovals,
  onClose,
}) {
  const onApprovedOrder = (cartId) => () => {
    updateApprovalRequiredOrder(cartId)
      .then((res) => {
        getOrdersForApprovals();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const onRejectOrder = (cartId) => () => {
    rejectApprovalRequiredOrder(cartId)
      .then((res) => {
        getOrdersForApprovals();
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const columns = [
    {
      Header: 'Cart Id ',
      accessor: 'cartId',
    },
    {
      Header: 'Partner ',
      accessor: 'partner',
    },
    {
      Header: 'Patient Name ',
      accessor: 'patientName',
    },
    {
      Header: 'Phone ',
      accessor: 'phoneNumber',
    },
    {
      Header: 'User  Name ',
      accessor: 'userName',
    },
    {
      Header: 'Sponsor ',
      accessor: 'sponsor',
    },
    {
      Header: ' Total Amount ',
      accessor: 'totalAmount',
    },
    {
      Header: ' Actions',

      Cell: (row) => {
        return (
          <button
            className="btn btn-success"
            onClick={onApprovedOrder(row.original.cartId)}
          >
            Approve
          </button>
        );
      },
    },
    {
      Header: ' Actions',

      Cell: (row) => {
        return (
          <button
            className="btn btn-danger"
            onClick={onRejectOrder(row.original.cartId)}
          >
            Reject
          </button>
        );
      },
    }
  ];

  return (
    <Modal
      isOpen={isOpen}
      style={{ maxWidth: '1200px', width: '100%', borderRadius: '4px' }}
    >
      <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={onClose}>
        <h3
          className="text-center"
          style={{ color: '#fff', fontWeight: 'bold' }}
        >
          Orders For Approval
        </h3>
      </ModalHeader>
      <ModalBody>
        <ReactTable data={tableSource} columns={columns} pageSize={20} />
      </ModalBody>
    </Modal>
  );
}
