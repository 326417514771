import React from 'react';

import ReactTable from "react-table";

import { fetchSponsors } from '../../services/api/sponsor-management';

import { toast } from 'react-toastify';

import moment from "moment";
import SponsorManagementCreate from "./SponsorManagementCreate";
import CreateTestUserModal from './CreateTestUserModal';

import { FaPeopleCarry } from 'react-icons/fa';

class SponsorManagementHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sponsors: [],
            loading: false,
            testUserModal: {
                isOpen: false,
                sponsorId: null,
            },
            filterSponsorData: [],
            searchValue: null,
        };
    }

    getSponsors = () => {
        this.setState({
            loading: true,
        });
        return fetchSponsors()
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        sponsors: response.data || [],
                        filterSponsorData: response.data || [],
                    });
                    return;
                }
                throw new Error(response.errorMessage || 'Error while fetching sponsors');
            })
            .catch((err) => {
                console.error(err, 'Error while fetching sponsors');
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleSponsorIdClick = (sponsorId) => {
        if (!sponsorId) {
            return;
        }
        this.props.history.push(`/sponsor-management/${sponsorId}`);
    };

    getColumns = () => {
        return [{
            accessor: 'sponsorId',
            filterable: false,
            Header: 'Sponsor Id',
            className: 'text-center',
            width: 75,
            Cell: (row) => (<button className="btn btn-sm btn-primary" onClick={() => (this.handleSponsorIdClick(row.original.sponsorId))}>{row.original.sponsorId}</button>)
        }, {
            accessor: 'sponsorName',
            filterable: false,
            width: 400,
            Header: 'Sponsor Name'
        }, {
            filterable: false,
            Header: 'Logo',
            className: 'text-center',
            width: 200,
            Cell: (row) => {
                const { logo, sponsorName } = row.original;
                return (<img src={logo} alt={`${sponsorName}'s Logo`} width={100} />);
            }
        }, {
            Header: 'Created On',
            filterable: false,
            width: 100,
            Cell: (row) => {
                const { createdTimestamp } = row.original;
                return moment.unix(createdTimestamp).format('LL');
            }
        }, {
            Header: 'Actions',
            filterable: false,
            width: 100,
            Cell: (row) => {
                const sponsorId = row.original.sponsorId;
                return <FaPeopleCarry onClick={() => (this.openTestUserModal(sponsorId))} />
            }
        },
        {
            accessor: 'active',
            filterable: false,
            width: 50,
            Header: 'Active'
        },
        {
            accessor: 'integrationType',
            filterable: false,
            width: 100,
            Header: 'Type'
        },
        {
            accessor: 'maxDependent',
            filterable: false,
            width: 100,
            Header: 'Max Dependent'
        }
    
    ];
    };

    componentDidMount() {
        this.getSponsors();
    }

    openTestUserModal = (sponsorId) => {
        this.setState({
            testUserModal: {
                isOpen: true,
                sponsorId,
            },
        })
    };

    handleTestUserModalClose = () => {
        this.setState({
            testUserModal: {
                isOpen: false,
                sponsorId: null,
            },
        });
    };

    handleSearchValueChange = (e) => {
        const  { value } = e.target;
        const { filterSponsorData, sponsors } = this.state;
        console.log('Search value: ', value);
        const pattern = new RegExp(value, 'gi');
        if (value && value !== '') {
            this.setState({
                filterSponsorData: sponsors.filter((sp) => sp.sponsorName.match(pattern))
            });
        } else {
            this.setState({
                filterSponsorData: sponsors
            })
        }

    }

    render() {
        const { sponsors, loading, searchValue, filterSponsorData } = this.state;
        return (
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="col">
                        <SponsorManagementCreate onComplete={this.getSponsors} />
                    </div>
                    <div className="col">
                        <input placeholder="Search for sponsors" value={searchValue} onChange={this.handleSearchValueChange}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ReactTable
                            filterable
                            style={{ flex: 1 }}
                            data={filterSponsorData}
                            collapseOnDataChange={false}
                            columns={this.getColumns()}
                            loading={loading}
                        />
                    </div>
                </div>
                <CreateTestUserModal isOpen={this.state.testUserModal.isOpen} sponsorId={this.state.testUserModal.sponsorId} onClose={this.handleTestUserModalClose} />
            </div>
        );
    }
}

export default SponsorManagementHome;
