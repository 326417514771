import React, { useEffect, useState, useRef } from 'react';
import { addCommentToRequest, getDentalRequests, getDentalRequestComments, getProcedures, addProcedureRequest, deductWalletBalance, callPatient, markProcedureRemoved, uploadPrescription, refundProcedureAmount } from '../../../services/api/cashless-dentals';
import RequestRow from '../../cashless-vaccination/modals/RequestRow';
import { Button, ButtonGroup, Container, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Tooltip } from 'reactstrap';
import Select from 'react-select';
import styled from 'styled-components';
import { BoldText, RegularText, Text } from '../../common/Text';
import CommentList from '../../offline-consultations/comments/commentList';
import CommentForm from '../../cashless-vaccination/components/CommentForm';
import { toast } from 'react-toastify';
import { FaArrowAltCircleUp, FaPhone, FaSpinner } from 'react-icons/fa';
import ConfirmationModal from '../../common/ConfirmationModal';
import Fuse from 'fuse.js';
import StyledTappable from '../../common/StyledTappable';
import LoadingComponent from '../../common/LoadingComponent';


const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  width: 100%;
`;

const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;


const UploadPrescriptionButton = styled(StyledTappable)`
  /* width: 14rem;
  height: 2rem;
  padding: 1.2rem;
  margin-left: 0.625rem;
  margin-top: 0.625rem;
  border: 1px solid #8852cc;
  border-radius: 0.2rem; */
  justify-content: flex-start;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`;

const UploadText = styled(BoldText)`
  font-size: 1.2rem;
  margin-left: 0.625rem;
  color: #8852cc;
  text-align: left;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
  width: 100%;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;

`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
  word-wrap: break-word;
`;

const SpocDetailContainer = styled(SectionContainer)`
  background-color: #f8f9fa;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  padding: 0.625rem;
  width: 100%;
`;

const DialogBox = styled.div`
    border: 1px solid #007bff;
    background-color: #FFFFFF;
    padding: 10px;
    color: white;
    border-radius: 10px;
    width: 100%;
`
const buttonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
`
const approveButton = styled.div`
    border: 2px solid green;
    background-color: #FFFFFF;
    width: 100%;
`
const CallUserText = styled(BoldText)`
  font-size: 1rem;
  margin-left: 0.4rem;
  color: #56c02c;
  cursor: pointer;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled(Text)`
  font-size: 1rem;
  width: ${props => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;

const DetailTextDanger = styled(RegularText)`
  font-size: 1rem;
  color: #ff4d48;
`;

const DentalRequestDetails = ({
    details, onClose, isOpen, key
}) => {
    const imageAttachmentRef = useRef(null);
    const [ dentalInfo, setDentalInfo ] = useState({});
    const [ basicInfo, setBasicInfo ] = useState({});
    const [ newCenterDetails, newCenterDetailsInfo ] = useState({});
    const [ policyDetails, setPolicyDetails ] = useState({});
    const [activeInfo, setActiveInfo] = useState('basic');
    const [loading, setLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [procedureDetails, setProcedureDetails] = useState(null);
    const [procedureAmount, setProcedureAmount] = useState('');
    const [comments, setComments] = useState([]);
    const [showProcedureDialog, setShowProcedureDialog] = useState([]);
    const [procedureList, setProcedureList] = useState([]);
    const [procedureAddRequestId, setProcedureAddRequestId] = useState(null);
    const [callPatientLoading, setCallPatientLoading] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationMessage, setConfirmationMessage] = useState('');
    const [procedureNotCovered, setProcedureNotCovered] = useState('');
    const [removeApproveButton, setRemoveApproveButton] = useState(false);
    const [deletePastProcedure, setDeletePastProcedure] = useState(null);
    const [deletePastProcedureAmount, setDeletePastProcedureAmount] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    // const [file, setFile] = useState(null);

    const handleComments = response => {
      console.log(response,'[responsecomment]')
      const comments = [];
      if (response.message === 'success' && response.data.length) {
        // build comments
        response.data.forEach(c => {
          comments.push({
            name: c.userName,
            message: c.comment,
            time: c.createdAt,
          });
        });
      }
      console.log(comments,'[returnCOmment]')
      return comments;
    };

    const findRequestInformation = (requestId) => {
      setLoading(true);
        getDentalRequests(requestId)
            .then((res) => {
              console.log('[basicInfo]', res)
                setBasicInfo(res.data[0])
                setPolicyDetails(res.data[0].policyDetails);
                newCenterDetailsInfo(res.data[0].newCenterDetails[0]);
                if (res.data[0].procedureRequests.length) {
                  setShowProcedureDialog(res.data[0].procedureRequests)
                }
            }).then(()=>{
              return getDentalRequestComments(requestId)
            }).then((res)=>{
              const commentData = handleComments(res)
              setComments(commentData)
            })
            .then(() => {
              return getProcedures(requestId); 
            })
            .then((procedureData) => {
              console.log(procedureData,'[procedureData]')
              if (procedureData.message === 'success' && procedureData.data.length === 1) {
                setProcedureDetails({value:procedureData.data[0].procedureId, label:procedureData.data[0].displayName});
                setProcedureAmount(Number(procedureData.data[0].procedureCost))
                setDeletePastProcedure({value:procedureData.data[0].procedureId, label:procedureData.data[0].displayName});
                setDeletePastProcedureAmount(Number(procedureData.data[0].procedureCost))
                setProcedureAddRequestId(procedureData.data[0].procedureRequestId)
                setRemoveApproveButton(true);
              }
              const transformedData = procedureData.data.map((item) => ({
                value: item.procedureId,
                label: item.displayName
              }));
              setProcedureList(transformedData)
              setLoading(false);
            })
            .catch((err) => {
                console.log(err)
                setLoading(false);
            })
    };
    const handleAddProcedureDetails = () => {
        setShowDialog(true);
    };
    const handleCancel = () => {
        setShowDialog(false);
        setRemoveApproveButton(false);
        setProcedureNotCovered('');
        setProcedureAmount('');
        setProcedureDetails(null);
    };

    useEffect(() => {
        if (details.requestId) {
            setShowProcedureDialog([])
            setComments([])
            setProcedureList([])
            findRequestInformation(details.requestId)
        }
    }, [details]);

    const addComment = (comments) => {
      console.log(comments, '[comment]')
        // const { requestId } = props;
        setComments(comments)
    }

    const initiateCall = (type) => {
      console.log('[inside initiate call]', type);
      switch (type) {
        case 'PATIENT': {
          const { patientPhone, userPhone } = basicInfo;
          console.log({ patientPhone, userPhone }, '[patientPhone]');
          if (patientPhone || userPhone) {
            setCallPatientLoading(true);
            setConfirmationMessage(`Are you sure you want to call ${patientPhone ? patientPhone : userPhone}?`);
            setShowConfirmationModal(true);
            
          }
        }
      }
    };

    const openAttachment = () => {
      if (imageAttachmentRef.current) {
          imageAttachmentRef.current.click();
      }
    };

    const handleFileChange = (event) => {
      if (event.target.files && event.target.files.length > 0) {
          const selectedFile = event.target.files[0];
          console.log('Selected file:', selectedFile);
          if (selectedFile) {
            setUploading(true);
            const { requestId, userId } = basicInfo;
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('userId', userId);
            formData.append('requestId', requestId);
            formData.append('preview', selectedFile);
            uploadPrescription(formData)
            .then((response)=> {
              if(response.message === 'success') {
                toast.success('File Uploaded Successfully')
                setUploading(false);
                findRequestInformation(requestId);
              }
            }).catch((err)=> {
              console.error({err}, '[err]');
            })
          } else {
            toast.message('File Not Selected')
          }
          
          
      }
  };

    const renderBasicInfo = () => (
        <DetailsContainer>
            <SectionContainer>
                <SectionInnerContainer>
                    <SectionHeader>User Booking Details</SectionHeader>
                    <SectionBody>
                        <RequestRow name='User Id:' value={basicInfo.userId} />
                        <RequestRow name='Appoint. Date:' value={basicInfo.appointmentDate} />
                        <RequestRow name='Appoint. Slot:' value={basicInfo.appointmentTime} />
                        <RequestRow name='Request Date' value={basicInfo.appointmentCreatedAt} />
                        <RequestRow name='Request Time' value={basicInfo.appointmentCreatedTime} />
                        <RequestRow name='Patient Name:' value={basicInfo.patientName} />
                        <RequestRow name='Patient Relation:' value={basicInfo.patientRelation} />
                        <RequestRow name='Patient DOB:' value={basicInfo.patientDob} />
                        <DetailRow>
                          <DetailName>Patient Phone:</DetailName> 
                          {basicInfo.patientPhone ? (
                            <CallUserText onClick={() => initiateCall('PATIENT')}>
                              {callPatientLoading ? (
                                <FaSpinner
                                  size={15}
                                  style={{ paddingBottom: 2 }}
                                  className="icon-spin text-success"
                                />
                              ) : (
                                <FaPhone
                                  size={15}
                                  style={{ paddingBottom: 2 }}
                                  className="text-success"
                                />
                              )}
                              {'  '}
                              {basicInfo.patientPhone}
                            </CallUserText>
                          ) : (
                            <DetailTextDanger>Unavailable</DetailTextDanger>
                          )}
                         </DetailRow>
                        <RequestRow name='User Email:' value={basicInfo.userEmail} />
                        <DetailRow>
                          <DetailName>User Phone:</DetailName> 
                          {basicInfo.userPhone ? (
                            <CallUserText onClick={() => initiateCall('PATIENT')}>
                              {callPatientLoading ? (
                                <FaSpinner
                                  size={15}
                                  style={{ paddingBottom: 2 }}
                                  className="icon-spin text-success"
                                />
                              ) : (
                                <FaPhone
                                  size={15}
                                  style={{ paddingBottom: 2 }}
                                  className="text-success"
                                />
                              )}
                              {'  '}
                              {basicInfo.userPhone}
                            </CallUserText>
                          ) : (
                            <DetailTextDanger>Unavailable</DetailTextDanger>
                          )}
                         </DetailRow>
                        <RequestRow name='Request Status'  value={basicInfo.requestStatus} />
                        <RequestRow name='Prescription Status'  value={basicInfo.prescriptionStatus} />
                    </SectionBody>
                </SectionInnerContainer>
            </SectionContainer>
            <SectionContainer>
              <SectionInnerContainer>
                <SectionHeader>Dental Booking Details</SectionHeader>
                <SectionBody>
                    <RequestRow name='Doctor Name:' value={basicInfo.doctorName} />
                    <RequestRow name='Clinic Name:' value={basicInfo.centerLocation} />
                    <RequestRow name='Center Address:' value={basicInfo.clinicAddress} />
                    <DetailRow>
                      <DetailName>Clinic Location:</DetailName>
                      {basicInfo.clinicLocation ? (
                        <a onClick={() =>
                          window.open(`${basicInfo.clinicLocation}`)
                        } style={{color:'blue'}}>Go to Location</a>
                      ): (
                        <DetailTextDanger>Unavailable</DetailTextDanger>
                      )}
                    </DetailRow>
                    <DetailRow>
                          <DetailName>Cashless letter:</DetailName> 
                          {basicInfo.cashlessLetter ? (
                             <a
                             onClick={() =>
                              window.open(`${basicInfo.cashlessLetter}`)
                            } style={{color:'blue'}}
                             >View</a>
                          ) : (
                            <DetailTextDanger>Unavailable</DetailTextDanger>
                          )}
                         </DetailRow>
                    <DetailRow>
                          <DetailName>Prescription Link:</DetailName> 
                          {basicInfo.prescriptionUrl ? (
                             <a
                             onClick={() =>
                              window.open(`${basicInfo.prescriptionUrl}`)
                            } style={{color:'blue'}}
                             >View</a>
                          ) : (
                            <DetailTextDanger>Unavailable</DetailTextDanger>
                          )}
                         </DetailRow>
                         <DetailRow>
                          <Button
                            onClick={() => {
                              navigator.clipboard.writeText(`
Doctor name : ${basicInfo.doctorName}
Patient name : ${basicInfo.patientName}
Patient phone : ${basicInfo.patientPhone}
Center name : ${basicInfo.centerLocation}
Center address : ${basicInfo.clinicAddress}
Appointment date : ${basicInfo.appointmentDate}
                              `
                              );
                            }}
                          >
                            Copy Details
                          </Button>
                          {basicInfo.requestStatus === 'confirmed' ? <div> <UploadPrescriptionButton 
                            onTap={openAttachment}
                          >
                            {uploading ?  <LoadingComponent/> : <UploadText>Upload Prescripiton</UploadText>}
                        </UploadPrescriptionButton>
                        <input
                            type="file"
                            id="imageAttachment"
                            ref={imageAttachmentRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        </div> : ''}
                         </DetailRow>
                </SectionBody>
                </SectionInnerContainer>
            </SectionContainer>
        </DetailsContainer>
    )
    
    const renderCenterInfo = () => (
      <DetailsContainer>
          <SectionContainer>
              <SectionInnerContainer>
                  <SectionHeader>Booked Center Details:</SectionHeader>
                  <SectionBody>
                      <RequestRow name='Doctor Name:' value={basicInfo.doctorName} />
                      <RequestRow name='Center:' value={basicInfo.centerLocation} />
                      <RequestRow name='Center Address:' value={basicInfo.clinicAddress} />
                      <DetailRow>
                      <DetailName>Clinic Location:</DetailName>
                      {basicInfo.clinicLocation ? (
                        <a onClick={() =>
                          window.open(`${basicInfo.clinicLocation}`)
                        } style={{color:'blue'}}>Go to Location</a>
                      ): (
                        <DetailTextDanger>Unavailable</DetailTextDanger>
                      )}
                    </DetailRow>
                  </SectionBody>
              </SectionInnerContainer>
          </SectionContainer>
          <SectionContainer>
            <SectionInnerContainer>
              <SectionHeader>New Center Details</SectionHeader>
              <SectionBody>
                      <RequestRow name='Doctor Name:' value={newCenterDetails.doctorName} />
                      <RequestRow name='Center:' value={newCenterDetails.clinicName} />
                      <RequestRow name='Center Address:' value={newCenterDetails.clinicAddress} />
                      <DetailRow>
                      <DetailName>Clinic Location:</DetailName>
                      {basicInfo.clinicLocation ? (
                        <a onClick={() =>
                          window.open(`${newCenterDetails.clinicLocation}`)
                        } style={{color:'blue'}}>Go to Location</a>
                      ): (
                        <DetailTextDanger>Unavailable</DetailTextDanger>
                      )}
                    </DetailRow>
                  </SectionBody>
            </SectionInnerContainer>
          </SectionContainer>
      </DetailsContainer>
  )

    const addProcedureRequests = () => {
      const { requestId } = details;
      console.log({procedureDetails, procedureAmount, requestId}, '[procedureDetails][data]')
        addProcedureRequest(requestId, procedureDetails?.value, procedureAmount)
          .then((res) => {
            console.log(res,'[res]')
            if (res.message === 'success') {
              setProcedureAddRequestId(res.data);
              toast.success('Procedure added successfully');
              setProcedureNotCovered('');
              findRequestInformation(requestId);
              setProcedureAddRequestId(null);
            } else {
              toast.error(res.errorMessage, {autoClose: 5000});
              setProcedureNotCovered(res.errorMessage);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    };

    const handleApproveProcedure = (procedureRequestsId, procedureAmount, procedureId) => {
      const { requestId } = details;
      setLoading(true)
      console.log({ procedureRequestsId, procedureAmount, requestId, procedureId }, '[handleApproveProcedure][data]')
        deductWalletBalance(requestId, procedureAmount, procedureRequestsId, procedureId)
          .then((res) => {
            console.log(res,'[res.message]')
            if (res.message === 'success'|| res.message === 'Procedure Is not Covered in Policy') {
              setLoading(false)
              toast.success('Amount deducted successfully');
              setShowDialog(false);
              findRequestInformation(requestId);
              setProcedureAmount('');
              setProcedureDetails(null);
              setDeletePastProcedure(null);
              setDeletePastProcedureAmount('');
              setRemoveApproveButton(false);
              setProcedureAddRequestId(null);
            } else {
              toast.error(res.message);
              findRequestInformation(requestId);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }

    const deductAmountForProcedure = () => {
      const { requestId } = details;
      console.log({ procedureAddRequestId, procedureAmount, requestId}, '[procedureDetails][data]')
        deductWalletBalance(requestId, procedureAmount, procedureAddRequestId)
          .then((res) => {
            console.log(res,'[res.message]')
            if (res.message === 'success') {
              toast.success('Amount deducted successfully');
              setShowDialog(false);
              findRequestInformation(requestId);
              setProcedureAmount('');
              setProcedureDetails(null);
              setDeletePastProcedure(null);
              setDeletePastProcedureAmount('');
              setRemoveApproveButton(false);
              setProcedureAddRequestId(null);
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }

    const handleRefundAmount = (requestId, requestProcedureId) => {
      console.log({ requestId, requestProcedureId }, '[handleRefundAmount]');
      setLoading(true)
      refundProcedureAmount(requestId, requestProcedureId)
        .then((response) => {
          console.log({response}, '[handleRefundAmount][refundProcedureAmount]');
          if (response.message === 'success') {
            setLoading(false)
            toast.success('Amount credited back successfully');
            setShowDialog(false);
            findRequestInformation(requestId);
            setProcedureAmount('');
            setProcedureDetails(null);
            setDeletePastProcedure(null);
            setDeletePastProcedureAmount('');
            setRemoveApproveButton(false);
            setProcedureAddRequestId(null);
          }
        }).catch((err) => {
          console.log({err}, '[handleRefundAmount][refundProcedureAmount]');
        })
    };

    const toggleConfirmationModal = () => {
      this.setState({
        showConfirmationModal: !this.state.showConfirmationModal,
        setCallPatientLoading: false,
      });
    };

    const getCallbackForTheConfirmation = () => {
      const { requestId } = basicInfo;
      console.log({ requestId }, '[patientPhone][getCallbackForTheConfirmation]');
      if(requestId) {
        setShowConfirmationModal(false);
        return callPatient(requestId)
        .then((res) => {
          console.log(res, '[response]')
          if (res.message === 'success') {
            toast.success('Called successfully');
            findRequestInformation(requestId);
          }
        })
      }
    };
    const markProcedureDelete = (procedureId) => {
      const { requestId } = details;
      console.log('value',requestId,procedureId, procedureDetails?.value)
      markProcedureRemoved(requestId, procedureId ? procedureId : procedureDetails?.value)
      .then((response) => {
        console.log(response, '[response]');
        if (response.message === 'success') {
          toast.success('Procedure Removed For Request')
          setShowDialog(false);
          findRequestInformation(requestId);
          setProcedureAmount('');
          setProcedureDetails(null);
          setDeletePastProcedure(null)
          setDeletePastProcedureAmount('')
          setRemoveApproveButton(false);
          setProcedureAddRequestId(null);
        }
      })

    }

    const transformInput = (inputValue, procedureList) => {
      if(!inputValue) {
        return true;
      }
      const options = {
        keys: ['label'], 
        threshold: 0.1,
      };
      const fuse = new Fuse([procedureList], options);

      const result = fuse.search(inputValue);
      
      console.log(result, '[transformInput]')
      if (result.length){
          return true;
      }
    };

    if (basicInfo && details.requestId) {
        console.log({procedureAmount, K:procedureDetails?.value, showProcedureDialog, procedureAddRequestId}, '[procedureDetails]')
        return (
          <>
          <Modal
            isOpen={details.showRequestDetails}
            toggle={onClose}
            size="lg"
            className="rtsp-modal-big"
          >
            <ModalHeader className="bg-primary" toggle={onClose}>
              {`Request Details (${details.requestId})`}
            </ModalHeader>
            <ModalBody>
              <Container fluid>
                <div className='row'>
                <ButtonGroup>
                  <Button
                    onClick={() => setActiveInfo('basic')}
                    active={activeInfo === 'basic'}
                  >
                    Basic Info
                  </Button>
                  {newCenterDetails && <Button
                    onClick={() => setActiveInfo('center')}
                    active={activeInfo === 'center'}
                  >
                    Center Info
                  </Button>}
                </ButtonGroup>
                </div>
                <div className="row">
                  <div className="col-md-8 overflow-hidden">
                    {activeInfo === 'basic' && renderBasicInfo()}
                    {activeInfo === 'center' && renderCenterInfo()}
                    {activeInfo === 'basic' && <div className="border-top">
                      {(basicInfo.prescriptionStatus === 'approved') ?
                       <div className='flex flex-row'>
                        <Button
                          className="bg-primary"
                          style={{ padding: '10px', margin: '20px' }}
                          onClick={handleAddProcedureDetails}
                        >
                          + Add Procedure Details
                        </Button>
                        {procedureNotCovered && (
                          <div style={{color:'red', fontSize:'20px' }}>
                            {procedureNotCovered}
                          </div>
                        )}
                      </div> : ''}
                      <div>
                      {showDialog && (                        
                          <DialogBox>
                            <div className="flex">
                              <div style={{ flex: '0.35' }}>
                              <FormGroup>
                                <Label
                                  className="text-black"
                                  for="procedureDetail"
                                >
                                  Procedure Details
                                </Label>
                                <Select 
                                  options={procedureList}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                  value={procedureDetails}
                                  cacheOptions={false}
                                  isClearable={true}
                                  isSearchable={true}
                                  filterOption={(option, rawInput)=>{
                                        return transformInput(rawInput, option);
                                  }}
                                  onChange={(selectedOption) => {
                                    console.log({selectedOption},'[selectedOption]')
                                    setProcedureDetails(selectedOption);
                                    setProcedureNotCovered('');
                                    setProcedureAmount('');
                                  }}
                                  styles={{
                                    option: (provided) => ({
                                      ...provided,
                                      color: 'black',
                                    }),
                                    singleValue: (provided) => ({
                                      ...provided,
                                      color: 'black',
                                    }),
                                  }}
                                   />
                              </FormGroup>
                              </div>
                              <div className='pl-2' style={{ flex: '0.35' }}>
                                <FormGroup>
                                  <Label className="text-black">
                                    Procedure Amount
                                  </Label>
                                  {console.log(procedureAmount,'[procedureAmount]')}
                                  <Input
                                    type="number"
                                    value={procedureAmount}
                                    placeholder="Input Field 2"
                                      onChange={(e) => {
                                      setProcedureAmount(e.target.value);
                                      }}
                                  />
                                </FormGroup>
                              </div>
                              <div className="flex" style={{ flex: '0.3', justifyContent: 'flex-end' }}>
                                {!removeApproveButton && <div style={{ marginRight: '0.5rem' }}>
                                  <Button className="bg-primary" style={{ width: '100%' }} onClick={()=>{addProcedureRequests()}} disabled = {!procedureAmount || !procedureDetails?.value}>
                                    Approve
                                  </Button>
                                </div>}
                                <div style={{ marginLeft: '0.5rem' }}>
                                  <Button className="bg-primary" onClick={handleCancel} style={{ width: '100%' }}>
                                      Cancel
                                  </Button>
                                  <div className='pt-2'>
                                  {/* {deletePastProcedure && deletePastProcedureAmount ? 
                                  <Button className='bg-danger' onClick={markProcedureDelete}>Delete Procedure</Button> 
                                   : ''} */}
                                  </div>
                                </div>
                              </div>
                            </div>
                            {console.log(procedureAddRequestId, procedureAmount,procedureDetails?.value,'[procedureAddRequestId]')}
                            <div style={{ width: '100%' }}>
                              <Button style={{ width: '100%' }} disabled={!procedureAmount || !procedureDetails?.value || !procedureAddRequestId} onClick={()=>{deductAmountForProcedure()}}>
                                Submit and deduct amount from user wallet
                              </Button>
                            </div>
                          </DialogBox>
                        
                          
                      )}

                      </div>
                      <div className='pt-2'>
                        {showProcedureDialog && (
                        <div className="flex border border-blue-500 flex-col p-2" style={{ backgroundColor: 'rgba(244, 241, 255)', borderRadius: '10px' }}>
                          <div className="flex justify-between">
                            <div>Available Primary Wallet amount Balance</div>
                            <div></div>
                            <div>₹{basicInfo.availablePrimaryWalletBalance ? (basicInfo.availablePrimaryWalletBalance) : 0}</div>
                          </div>
                          <div className="flex justify-between">
                            <div>Dental Wallet Balance before procedure</div>
                            <div></div>
                            <div>₹{basicInfo.previousAvailableWalletBalance ? (basicInfo.previousAvailableWalletBalance) : 0}</div>
                          </div>
                          <br/>
                          <table style={{ border: 'none' }}>
                            <thead>
                              <tr style={{ border: 'none' }}>
                                <th style={{ border: 'none' }}><strong>Detail</strong></th>
                                <th style={{ border: 'none' }}><strong>Cost</strong></th>
                                <th style={{ border: 'none' }}><strong>Final amount</strong></th>
                                <th style={{ border: 'none' }}><strong>Copay Amount</strong></th>
                                <th style={{ border: 'none' }}><strong>Action</strong></th>
                              </tr>
                            </thead>
                            <tbody>
                              {showProcedureDialog.map((procedure, index) => {
                                console.log({ procedure }, '[procedure]')
                                let cost = '', nameStyle = {}, walletDeduction = '';
                                if (procedure.success === -1) {
                                  cost = procedure.cost;
                                  nameStyle.color = 'red';
                                  walletDeduction = 'Insufficient balance';
                                } else if (procedure.success === -2) {
                                  cost = procedure.cost;
                                  nameStyle.color = 'red';
                                  walletDeduction = 'Not Covered In policy';
                                } else  {
                                  cost = procedure.cost;
                                  nameStyle.color = 'black';
                                  walletDeduction = procedure.walletDeduction === null ? 0 : parseFloat(procedure.walletDeduction).toFixed(0)
                                }
                                const copay = procedure.walletDeduction === null ? 0 : procedure.cost - procedure.walletDeduction  
                                return (
                                <tr key={index} style={{ border: 'none' }}>
                                  <td style={{ border: 'none', color: nameStyle.color }}>{procedure.procedureName} <span style={{ color: procedure.procedureRefundStatus ? 'red' : 'black' }}>{procedure.procedureRefundStatus ? '(Refunded)' : ''}</span></td>
                                  <td style={{ border: 'none', color: nameStyle.color }}>{cost}</td>
                                  <td style={{ border: 'none', color: nameStyle.color }}>₹{walletDeduction}</td>
                                  <td style={{ border: 'none', color: nameStyle.color }}>₹{copay}</td>
                                  <td style={{ border: 'none', color: nameStyle.color, display: 'flex' }}>{!procedure.procedureRefundStatus && <Button onClick={()=> markProcedureDelete(procedure.procedureRequestsId)}>Delete</Button>} 
                                  {procedure.walletDeduction === null && procedure.success === null ? (
                                    <Button style={{ marginLeft: '1px' }} onClick={() => handleApproveProcedure(procedure.procedureRequestsId, procedure.cost, procedure.procedureId)} disabled={loading}>
                                      Approve
                                    </Button>
                                  ) : ''}
                                   {procedure.walletDeduction !== null && procedure.success === 1 && !procedure.procedureRefundStatus ?( <Button style={{ marginLeft: '1px' }} onClick={()=> handleRefundAmount(procedure.requestId, procedure.procedureRequestsId)} disabled={loading}>Refund</Button>):''}
                                  </td>
                                </tr>
                              )})}
                            </tbody>
                          </table>
                          <div className="border-top flex justify-between">
                            <div>Current Dental Wallet Balance</div>
                            <div style={{ color: 'rgba(113, 79, 255)' }} className="font-bold">
                              ₹{policyDetails && policyDetails.availableSubWalletBalance ? parseFloat(policyDetails.availableSubWalletBalance) : 0}
                            </div>
                          </div>
                        </div>
                        )}
                      </div>
                    </div>}
                  </div>
                  <div className="col-md-4">
                        <CommentList 
                        loading={loading} 
                        comments={comments} 
                        />
                        <div className=''>
                            <CommentForm 
                            addComment={(c)=> addComment([...comments, c])}
                            requestId={details.requestId} 
                            type='dental'
                            />
                        </div>
                  </div>
                </div>
              </Container>
            </ModalBody>
          </Modal>
          <ConfirmationModal 
            confirmationMessage={confirmationMessage}
            isOpen={showConfirmationModal}
            toggle={() => toggleConfirmationModal()}
            callback={() => getCallbackForTheConfirmation()}
          />
          </>
        );
    } else {
        return <div />
    }
}

export default DentalRequestDetails;