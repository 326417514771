import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Row as RawRow, Col, ListGroup, ListGroupItem } from 'reactstrap'
import { getLabOrderItems, mapLabOrderItems, sendCashgramLink } from '../api'

const Row = ({children, ...props}) => <RawRow {...props} style={{ margin: 0, marginBottom: '20px', ...(props.style || {}) }}>
    {children}
</RawRow>

const TABLE_STYLES = { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }
const LabCashgramModal = ({
    isOpen = false,
    onClose = () => {},
    header = 'Add Lab Item Price',
    onComplete = () => {},
    orderId
}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [isMinorLoading, setIsMinorLoading] = useState(false)
    const [labTests, setLabTests] = useState([])
    const [cartId, setCartId] = useState('')
    const [currentTest, setCurrentTest] = useState(null)
    const [amount, setAmount] = useState('')

    useEffect(() => {
        if (isOpen && orderId) {
            getLabOrderItems({ orderId })
                .then(response => {
                    if (Array.isArray(response.data)) {
                        setCartId(response.data[0]?.cartId)
                        setLabTests(response.data)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }, [orderId, isOpen])

    const updateAmount = () => {
        if (!Number(amount)) return toast.error('Please choose a valid amount')
        setIsMinorLoading(true)

        mapLabOrderItems({
            price: Number(amount),
            cartId: currentTest.cartId,
            testId: currentTest.testId
        })
            .then((response) => {
                if (response.message === 'failure') {
                    toast.error(response.errorMessage)
                    return
                }
                getLabOrderItems({ orderId })
                    .then(response => {
                        if (Array.isArray(response.data)) {
                            setCartId(response.data[0]?.cartId)
                            setLabTests(response.data)
                        }
                    })
                    .finally(() => {
                        toast.success('Price updated successfully')
                        setAmount('')
                    })
            })
            .catch(err => {
                toast.error(err?.response?.data?.errorMessage || 'Something went wrong, please try in a while')
            })
            .finally(() => {
                setIsMinorLoading(false)
            })
    }

    const resetAmount = () => {
        setCurrentTest(null)
        setAmount('')
    }

    const submitHandler = () => {
        setIsLoading(true)

        sendCashgramLink({
            orderId, cartId
        })
            .then((response) => {
                if (response.message === 'failure') {
                    toast.error(response.errorMessage)
                    return
                }
                onComplete()
                setAmount('')
                setCurrentTest(null)
                toast.success('Cashgram link sent successfully')
            })
            .catch(err => {
                toast.error(err?.response?.data?.errorMessage || 'Something went wrong, please try in a while')
            })
            .finally(() => {
                setIsLoading(false)
            })
        
    }

    return <Modal toggle={onClose} isOpen={isOpen} className='custom-lab-cashgram-modal' contentClassName='custom-lab-cashgram-modal-content'>
        <ModalHeader className='bg-info' toggle={onClose}>{header}</ModalHeader>
        <ModalBody style={{ width: '90vw' }}>
            <Row>
                <Col>
                    <ListGroup>
                        <ListGroupItem style={{...TABLE_STYLES, fontWeight: 'bold', textTransform: 'uppercase'}}>
                            <div>Name</div>
                            <div>Reimbursement Price</div>
                        </ListGroupItem>
                        {labTests.length ? labTests.map((test, idx) => <ListGroupItem 
                            key={`${test.testId}_${idx}`}
                            action
                            style={TABLE_STYLES}
                            active={currentTest && currentTest.testId === test.testId && currentTest.idx === idx}
                            onClick={() => setCurrentTest({ ...test, idx })}
                        >
                            <div>{test.test_name}</div>
                            <div style={{ fontWeight: 'bold' }}>{test.reimbursementPrice || '-'}</div>
                        </ListGroupItem>) : <>No lab test available</>}
                    </ListGroup>
                </Col>
                <Col>
                    {currentTest ? <>
                        <Row>
                            <Col>
                                <RawRow style={{ color: '#555' }}>Test Name:</RawRow>
                                <RawRow>
                                    <input 
                                        value={currentTest.test_name}
                                        style={{ width: '100%', color: '#888' }}
                                        disabled
                                    />
                                </RawRow>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <RawRow style={{ color: '#555' }}>Amount:</RawRow>
                                <RawRow>
                                    <input 
                                        value={amount}
                                        placeholder='Amount e.g. 100'
                                        onChange={e => setAmount(e.target.value)}
                                        style={{ width: '100%' }}
                                    />
                                </RawRow>
                            </Col>
                        </Row>
                        <Row style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Button disabled={isMinorLoading} style={{ marginRight: '10px' }} onClick={updateAmount} color='primary'>
                                {isMinorLoading ? 'Loading...' : 'Update'}
                            </Button>
                            <Button color='secondary' disabled={isMinorLoading} onClick={resetAmount}>
                                Reset
                            </Button>
                        </Row>
                    </> : <>Please choose a test from the list to update</>}
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={submitHandler} disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Submit'}
            </Button>
            {!isLoading && (
                <Button color="secondary" onClick={onClose} disabled={isLoading}>
                    Cancel
                </Button>
            )}
        </ModalFooter>
    </Modal>
}

export default LabCashgramModal