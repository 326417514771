import { baseRequestWithHeaders } from './baseRequest';

export const sendLogin = (userName, password) => {
  const url = '/new-ops/login';
  const method = 'POST';
  const data = {
    userName,
    password,
  };
  return baseRequestWithHeaders(url, method, null, data);
};

export const sendGoogleLogin = (token) => {
  const url = '/new-ops/oauth/login';
  const method = 'POST';
  const data = {
    token,
  };
  return baseRequestWithHeaders(url,  method, null, data);
};