import React, { Component,useState, useEffect  } from 'react'
import {
    getClaimsRequests,
    placeCall,
    reopenClaim,
    processClaimRequest,
  } from '../../services/api/reimburse-claims';
import { FormGroup, Label, Tooltip, Badge, Button,ButtonGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import ReactTable from 'react-table';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import { FaTicketAlt } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import { debounce } from '../../utils/debounce';
import { Text } from '../common/Text';
import SponsorFilter from '../offline-consultations/headerFilter/SponsorFilter';
import {Link as RouterLink } from 'react-router-dom';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import DownloadCsv from '../common/DownloadCsv';


function ReimbursementType(props) {
    const { onChange, filter } = props;
    const [value, setValue] = useState(filter ? filter.value || '' : '');
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      onChange(value);
    }, [value]);
    /* eslint-enable */
    const handleChange = (event) => {
      setValue(event.target.value);
    };
    return (
      <div>
        <select
          onChange={handleChange}
          style={{
            width: '100%',
            height: '40px',
            fontSize: '12px',
          }}
          value={value}
        >
          <option value="">All</option>
          <option value="normal-spectacles">Normal Spectacles</option>
          <option value="bifocal-spectacles">Bifocal Spectacles</option>
          <option value="normal-lens">Normal Contact Lens</option>
          <option value="bifocal-lens">Bifocal Contact Lens</option>
          <option value="ivf">Infertility Treatment</option>
          <option value="tooth-ext">Simple Tooth Extraction</option>
          <option value="prebooked-consult">Prebook Consult</option>
          <option value="prebooked-medicine">
            Prebooked Prescribed Medicines
          </option>
          <option value="prebooked-lab">Prebooked Prescribed Diagnostics</option>
          <option value="fracture">Surgical Cast</option>
          <option value="vaccine">Vaccinations</option>
          <option value="emergency-consult">
            Urgent Consults, Medicines or Labs
          </option>
          <option value="surgical-tooth-ext">Surgical extraction of tooth</option>
          <option value="surgical-molar-ext">
            Surgical extraction of molar tooth
          </option>
          <option value="tooth-filling">Tooth Filling</option>
          <option value="tooth-scaling">Tooth Scaling</option>
          <option value="tooth-orthodontal">Orthodontal</option>
          <option value="denture-single">Simple Single Tooth Denture</option>
          <option value="denture-complex">
            Complex single/two teeth denture
          </option>
          <option value="denture-full">Full upper/lower teeth denture</option>
          <option value="denture-set">Full set of dentures</option>
          <option value="denture-capping">Tooth Capping</option>
          <option value="root-canal">Root canal treatment</option>
          <option value="dental-proflaxes">Dental Proflaxes</option>
        </select>
      </div>
    );
  }

class ReimbursementQcCLaimsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: true,
          pages: -1,
          lastWorkedDateFilter: null,
          invoiceDateFilter: null,
          filtered: [
            {
              id: 'requestStatus',
              value: 'qc-check',
            },
          ],
          sorted: [
            {
              id: 'reimbursementId',
              desc: true,
            },
          ],
          pageSize: 20,
          page: 0,
          orders: [],
          confirmModal: {
            isOpen: false,
            header: '',
            body: '',
            onSubmit: null,
            requestId: null,
            onClose: null,
            buttonLoading: false,
          },
          searchInput: '',
          processClaimTooltipOpen: -1,
          requestCallTooltipOpen: -1,
          requestReopenTooltipOpen: -1,
          viewClaimTooltipOpen: -1,
          claimRequestTooltipOpen: -1,
          totalRecords: null,
          deficientTab : false,
          totalApprovedAmount: null,
          totalRequestedAmount: null,
          startDate: null,
          endDate: null,
          focusedInput: null,
          pendingUtr:''
        }
        this.fetchData = this.fetchData.bind(this);
    this.tableRef = React.createRef();
    this.fetchDataWithDebounce = debounce(this.fetchData, 300);
    this.fetchSearchInputWithDebounce = debounce((ele, filtered) => {
      const filteredValue = filtered[0].value
      if (this.tableRef) {
        this.fetchData(this.tableRef.current.state, null, ele,filteredValue );
      }
    }, 300);
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.props.startDate === null || this.props.endDate === null) {
        return;
      }
      if (
        prevProps.startDate !== this.props.startDate ||
        prevProps.endDate !== this.props.endDate
      ) {
        let stDate = 0,
          enDate = 0;
        if (this.props.startDate !== null) {
          stDate = this.props.startDate.startOf('day').unix();
        }
        if (this.props.endDate !== null) {
          enDate = this.props.endDate.endOf('day').unix();
        }
  
        this.updateStateFilters('reqStartDate', stDate, 'reqEndDate', enDate);
      }
    }
  
    updateStateFilters = (id, value, id2, value2) => {
      const filter = this.state.filtered.find((f) => f.id === id);
      const filter2 = this.state.filtered.find((f) => f.id === id2);
      if (filter) {
        filter.value = value;
      } else {
        this.state.filtered.push({ id, value });
      }
      if (filter2) {
        filter2.value = value2;
      } else {
        this.state.filtered.push({ id: id2, value: value2 });
      }
  
      this.setState({
        filtered: JSON.parse(JSON.stringify(this.state.filtered)),
      });
    };
  
    onFilteredChange = (e) => {
      this.setState({ filtered: e, page: 0, pages: 0 });
    };

    fetchStrategy = (state) => this.fetchDataWithDebounce(state);

    prepareSorting = () => {
        const sortBy = [];
        const sortOrder = [];
        this.state.sorted.forEach((ele) => {
          sortBy.push(ele.id);
          if (ele.desc) {
            sortOrder.push('DESC');
          } else {
            sortOrder.push('ASC');
          }
        });
        return {
          sortBy,
          sortOrder,
        };
      };
    
      prepareApiFilters = () => {
        const appliedFilters = {};
        const {startDate,endDate} = this.state;
        const reimbursementIdFilter = this.state.filtered.find((ele) => {
          return ele.id === 'reimbursementId';
        });
        if (reimbursementIdFilter) {
          appliedFilters.reimbursementId = reimbursementIdFilter.value;
          return appliedFilters;
        }
        if (this.state) {
          this.state.filtered.forEach((filter) => {
            if (filter.id === 'requestStatus') {
              appliedFilters.status = filter.value;
            } else {
              appliedFilters[filter.id] = filter.value;
            }
          });
        }
        appliedFilters.pendingUtr = this.state.pendingUtr;
        appliedFilters.deficientTab = this.state.deficientTab;
        appliedFilters.startDate = startDate && moment(startDate).unix();
        appliedFilters.endDate = endDate && moment(endDate).unix()
        // state.sorted.forEach(sort => appliedOrderBy[sort.id] = sort.desc?'desc':'asc');
    
        appliedFilters.size = this.state.pageSize;
        appliedFilters.page = this.state.page + 1;
        return appliedFilters;
      };

      fetchData = (state, instance, searchInput,filteredValue) => {
        const { pageSize } = this.state;
        // show the loading overlay
        this.setState({ loading: true });
        this.filtering = false;
        const appliedFilters = this.prepareApiFilters(state);
        const appliedSorting = this.prepareSorting(state);
    
        // fetch your data
        getClaimsRequests(appliedFilters, appliedSorting, searchInput,filteredValue)
          .then((response) => {
            console.log('Api response', response.body);
            if (response.body.message === 'recordsFound') {
              const totalRecords = response.body.totalRecords;
              const totalApprovedAmount = response.body.totalApprovedAmount;
              const totalRequestedAmount = response.body.totalRequestedAmount;
              // Compute pages
              this.setState({
                orders: response.body.results,
                loading: false,
                totalRecords,
                totalApprovedAmount,
                totalRequestedAmount,
                pages: Math.ceil(totalRecords / pageSize),
              });
            } else if (response.body.message === 'noRecords') {
              this.setState({
                totalRecords: 0,
                orders: [],
                loading: false,
              });
            }
          })
          .catch((err) => {
            console.error(err, 'Error in get claims requests API');
            toast.error(`Error in fetching orders`);
          });
      };

    getColumns = () => [
        {
          Header: 'QC Details',
          columns: [
            {
              Header: 'Reimbursement ID',
              accessor: 'reimbursementId',
              filterable: true,
              width: 50,
              Filter: ReactTableInputFilter,
              Cell: (row) => {
                const { reimbursementId } = row.original;
                return (
                  <RouterLink
                    title={reimbursementId}
                    to={`/reimburse-claim/${reimbursementId}`}
                  >
                    {reimbursementId}
                  </RouterLink>
                );
              },
            },
            {
              Header: 'Claimed By',
              accessor: 'claimBy',
              filterable: true,
              Filter: ReactTableInputFilter,
            },
            {
              Header: 'Sponsor Name',
              accessor: 'sponsorName',
              filterable: true,
              Filter: ({ filter, onChange }) => (
                <SponsorFilter
                  onChangeValue={onChange}
                  value={filter ? filter.value : ''}
                />
              ),
            },
            {
              Header: 'User Name',
              accessor: 'userName',
            },
            {
              Header: 'User Phone',
              accessor: 'userPhone',
              width: 75,
              style: {
                wordBreak: 'break-all',
              },
            },
            {
              Header: 'User Email',
              accessor: 'userEmail',
              width: 100,
              style: {
                wordBreak: 'break-all',
              },
            },
            {
              Header: 'Patient Name',
              // width: 120,
              sortable: false,
              Cell: (row) => {
                return (
                  <span>{row.original.relative || row.original.userName}</span>
                );
              },
            },
            {
              Header: 'Reimbursement Type',
              accessor: 'reimbursementType',
              // width: 180,
              filterable: false,
              Filter: ReimbursementType,
            },
            {
              Header: 'Status',
              accessor: 'requestStatus',
              filterable: false,
              // width: 100,
              Filter: ({ filter, onChange }) => {
                return (
                  <select
                    onChange={(event) => onChange(event.target.value)}
                    // style={{ width: '100%' }}
                    value={filter ? filter.value : 'all'}
                  >
                    <option value="all">Show All</option>
                    <option value="documents-uploaded">Documents Uploaded</option>
                    <option value="qc-check">Qc Check</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="documents-deficient">Documents Deficient</option>
                    <option value="documents-verified">Documents Verified</option>
                  </select>
                );
              },
              Cell: (row) => {
                if (row.original.deletionDate) {
                  return (
                    <h5>
                      <span className="badge badge-danger">DELETED</span>
                    </h5>
                  );
                }
                switch (row.original.requestStatus.toUpperCase()) {
                  case 'DOCUMENTS-UPLOADED':
                    return (
                      <h5>
                        <span className="badge badge-secondary">
                          {row.original.requestStatus.toUpperCase()}
                        </span>
                      </h5>
                    );
                  case 'DOCUMENTS-VERIFIED':
                    return (
                      <h5>
                        <span className="badge badge-primary">
                          {row.original.requestStatus.toUpperCase()}
                        </span>
                      </h5>
                    );
                case 'QC-CHECK':
                    return(
                        <h5>
                        <span className="badge badge-secondary">
                          {row.original.requestStatus.toUpperCase()}
                        </span>
                      </h5>
                    )
                  case 'APPROVED':
                    return (
                      <h5>
                        <span className="badge badge-success">
                          {row.original.requestStatus.toUpperCase()}
                        </span>
                      </h5>
                    );
                  case 'REJECTED':
                    return (
                      <h5>
                        <span className="badge badge-danger">
                          {row.original.requestStatus.toUpperCase()}
                        </span>
                      </h5>
                    );
                  default:
                    return (
                      <h5>
                        <span className="badge badge-secondary">
                          {row.original.requestStatus.toUpperCase()}
                        </span>
                      </h5>
                    );
                }
              },
            },
            {
              Header: 'Insurer Status',
              accessor: 'claimStatus',
              filterable: false,
              // width: 100,
                Filter: ({ filter, onChange }) => {
                return (
                  <select
                    onChange={(event) => onChange(event.target.value)}
                    // style={{ width: '100%' }}
                    value={filter ? filter.value : 'all'}
                  >
                    <option value="all">Show All</option>
                    <option value="documents-uploaded">Documents Uploaded</option>
                    <option value="approved">Approved</option>
                    <option value="rejected">Rejected</option>
                    <option value="qc-check">Qc Check</option>
                    <option value="settled">Settled</option>
                    <option value="pending">Pending</option>
                  </select>
                );
              },
              Cell: (row) => {
                switch (row.original.claimStatus.toUpperCase()) {
                  case 'DOCUMENTS-UPLOADED':
                    return (
                      <h5>
                        <span className="badge badge-secondary p-0 pb-2">
                          <div className="bg-dark rounded-top mb-1">
                            <Text fontSize="10px" color="white">
                              Insurer
                            </Text>
                          </div>
                          <span className="ml-2 mr-2">
                            {row.original.claimStatus.toUpperCase()}
                          </span>
                        </span>
                      </h5>
                    );
                    case 'QC-CHECK':
                    return(
                        <h5>
                        <span className="badge badge-secondary">
                          {row.original.requestStatus.toUpperCase()}
                        </span>
                      </h5>
                    )
                  case 'APPROVED':
                  case 'SETTLED':
                    return (
                      <h5>
                        <span className="badge badge-success p-0 pb-2">
                          <div className="bg-dark rounded-top mb-1">
                            <Text fontSize="10px" color="white">
                              Insurer
                            </Text>
                          </div>
                          <span className="ml-2 mr-2">
                            {row.original.claimStatus.toUpperCase()}
                          </span>
                        </span>
                      </h5>
                    );
                  case 'REJECTED':
                  case 'FAILURE':
                  case 'REVERSED':
                    return (
                      <h5>
                        <span className="badge badge-danger p-0 pb-2">
                          <div className="bg-dark rounded-top mb-1">
                            <Text fontSize="10px" color="white">
                              Insurer
                            </Text>
                          </div>
                          <span className="ml-2 mr-2">
                            {row.original.claimStatus.toUpperCase()}
                          </span>
                        </span>
                      </h5>
                    );
                  case 'PENDING':
                    return (
                      <h5>
                        <span className="badge badge-danger p-0 pb-2">
                          <div className="bg-dark rounded-top mb-1">
                            <Text fontSize="10px" color="white">
                              Insurer
                            </Text>
                          </div>
                          <span className="ml-2 mr-2">
                            {row.original.claimStatus.toUpperCase()}
                          </span>
                        </span>
                      </h5>
                    )
                  default:
                    return (
                      <h5>
                        <span className="badge badge-secondary p-0 pb-2">
                          <div className="bg-dark rounded-top mb-1">
                            <Text fontSize="10px" color="white">
                              Insurer
                            </Text>
                          </div>
                          <span className="ml-2 mr-2">
                            {row.original.claimStatus.toUpperCase()}
                          </span>
                        </span>
                      </h5>
                    );
                }
              },
            },
            {
              Header: 'Claim Amount',
              accessor: 'reimbursementAmount',
              // width: 100,
              Cell: (row) => (
                <span>{`₹ ${row.original.reimbursementAmount || 'NA'}`}</span>
              ),
            },
            {
              Header: 'Approved Amount',
              accessor: 'approvedAmount',
              // width: 100,
              Cell: (row) => (
                <span>{`₹ ${row.original.approvedAmount || 'NA'}`}</span>
              ),
            },
            {
              Header: 'Request Date',
              accessor: 'requestDate',
              Cell: (row) => (
                <span>
                  {row.original.requestDate
                    ? moment(row.original.requestDate).format('DD/MM/YYYY HH:mm')
                    : null}
                </span>
              ),
            },{
              Header:'QC Allotted on',
              accessor:'qcAllotedTime',
              Cell: (row) => (
                <span>
                  {row.original.qcAllotedTime
                    ? moment(row.original.qcAllotedTime).format('DD/MM/YYYY HH:mm')
                    : null}
                </span>
              ),
            },
            {
              Header:'QC claimed by',
              accessor:'qcDoc',
            },
          ],
        },
      ];

  render() {
    const searchInputLink = Link.state(this, 'searchInput').onChange((ele) => {
        this.fetchSearchInputWithDebounce(ele, this.state.filtered);
      });
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <FormGroup>
                <Label for="searchInput">
                  Search by user name/patient name/mobile number
                </Label>
                <Input
                  type="text"
                  name="searchInput"
                  id="searchInput"
                  valueLink={searchInputLink}
                  className="ml-2"
                />
              </FormGroup>
            </div>
            <div className="col">
              <ButtonGroup lassName="ml-2" size={'m'}>
                <DownloadCsv jsonArray={this.state.orders}></DownloadCsv>
              </ButtonGroup>
            </div>
            <div className="col-4">
              <Button onClick={()=>{
                this.setState({
                  filtered: [
                    {
                      id: 'requestStatus',
                      value: 'qc-check',
                    },
                  ],
                })
              }}>
                Clear Filter
              </Button>
            </div>
          </div>
        </div>
        {/* <div className='col'> */}

        {/* </div> */}
        <div>
          <ReactTable
            keyField="orderId"
            ref={this.tableRef}
            data={this.state.orders}
            columns={this.getColumns()}
            defaultPageSize={20}
            pages={this.state.pages}
            pageSize={this.state.pageSize}
            page={this.state.page}
            loading={this.state.loading}
            filtered={this.state.filtered}
            sorted={this.state.sorted}
            manual
            onFetchData={this.fetchStrategy}
            onFilteredChange={this.onFilteredChange}
            onPageSizeChange={(pageSize) => this.setState({ pageSize })}
            onPageChange={(page) => this.setState({ page })}
            onSortedChange={(sorted) => this.setState({ sorted })}
            className="-striped -highlight"
            showPaginationTop
            showPaginationBottom
          />
        </div>
      </>
    );
  }
}

export default ReimbursementQcCLaimsTable