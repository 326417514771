import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import LoadingComponent from "./LoadingComponent";

class ConfirmationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            focusAfterClose: true,
            loading: false,
        };
    }

    triggerCallback = () => {
        if (this.state.loading) {
            return;
        }
        this.setState({
            loading: true,
        });
        this.setState({loading: false});        
        return this.props.callback()
        // .finally(() => {
        //         this.setState({
        //             loading: false,
        //         });
        //     });
    };

    render() {
        const { loading } = this.state;
        const selectStyles = {
            border: '2px solid #abe7f0', 
            height: '40px',
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            marginBottom: '10px',
            backgroundPosition: 'right 20px top 50%',
            backgroundSize: '20px 20px',
          };
      
          const optionStyles = {
            cursor: 'pointer',
          };

          const headingStyles = {
            fontSize: '16px',
            fontFamily: 'Arial, sans-serif', // Specify font family here
           // fontWeight: 'bold', // Specify font weight here
            color: '#333', // Specify text color here
          };

          let dropdownSelectedValue="";
          if(this.props.isCallModalOpen && this.props.selectedValue!=null)
          {
            dropdownSelectedValue=this.props.selectedValue;
          }
      
        return (
            <div>
                <Modal returnFocusAfterClose={this.state.focusAfterClose} isOpen={this.props.isOpen} toggle={this.props.toggle}>
                    {this.props.isCallModalOpen ? 
                    <ModalBody>
                        <div><h4 style={headingStyles}>Select call reason</h4></div>
                        <select value= {dropdownSelectedValue} onChange={this.props.handleChange} style={selectStyles}>
                        {<option value="" disabled hidden>Select an option</option>}
                        {this.props.callReasonOptions.map((option, index) => (
                            <option key={index} value={option}>{option}</option>
                        ))}
                        </select>
                    </ModalBody>
                    : 
                    <ModalBody>{this.props.confirmationMessage}</ModalBody>}
                    <ModalFooter>
                        { this.props.isCallModalOpen ?
                            <>{loading ? (
                                <LoadingComponent />
                            ) : (
                                <Button color="primary" onClick={this.triggerCallback} disabled={!this.props.selectedValue || loading} >Ok</Button>
                            )}
                            </>
                        :
                        <>{loading ? (
                            <LoadingComponent />
                            ) : (
                                <Button color="primary" onClick={this.triggerCallback}>Ok</Button>
                            )}
                            </>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default ConfirmationModal;
