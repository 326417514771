import React from 'react';

import ReactTable from 'react-table';

import { Input, Label } from 'reactstrap';



import { toast } from 'react-toastify';

import DownloadCsv from '../common/DownloadCsv';
import DetailsContainer from './DetailsContainer';
import EditDeliveryDetailsForm from './EditDeliveryDetailsForm';
import Modal from '../../components/common/Modal';
import ModalConfirm from '../../components/common/ModalConfirm';
import { FaWarehouse, FaInfo, FaRegEdit } from 'react-icons/fa';
import SponsorFilter from "../offline-consultations/headerFilter/SponsorFilter";

import OrderDetailsForm from './OrderDetailsForm';

import { getAllRedemptions, changeStatus, autoPayout } from '../../services/api/redemption-ops';

import DateFilter from "../offline-consultations/headerFilter/DateFilter";

import OrderStatus from './OrderStatus.json';

class RedemptionOpsHome extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            pageSize: 20,
            pages: 0,
            data: [],
            totalHits: 0,
            visitCost: null,
            detailsModal: {
                redemptionId: null,
                isOpen: false
            },
            editStatusModal: {
                redemptionId: null,
                isOpen: false,
                status: '',
            },
            sponsorId: null,
            orderStatus: '',
            productName: '',
            userPhone: '',
            orderId: '',
            redemptionDates: {
                startDate: null,
                endDate: null,
            },
            confirmModal: {
                message: '',
                isOpen: false,
                onSubmit: () => {},
            },
            orderDetailsModal: {
                redemptionId: null,
                isOpen: false,
            },
            editDeliveryDetailsModal: {
                isOpen: false,
                redemptionId: null,
            },
        };
    }

    onFetchData = (state) => {
        console.log(state, 'State');
        let sponsorId = '';
        let orderStatus = '';
        let productName = '';
        let userPhone = '';
        let email = '';
        let coupon='';
        let orderId = '';
        state.filtered.forEach((filter, index) => {
            console.log(filter, 'Filter', index);
            if (filter.id === 'sponsorName') {
                sponsorId = filter.value;
            }
            if (filter.id === 'orderStatus') {
                orderStatus = filter.value;
            }
            if (filter.id === 'productName') {
                productName = filter.value;
            }
            if (filter.id === 'userPhone') {
                userPhone = filter.value;
            }
            if (filter.id === 'email') {
                email = filter.value;
            }
            if (filter.id === 'orderId') {
                orderId = filter.value;
            }
            if (filter.id === 'coupon') {
                coupon = filter.value;
            }
        });
        this.setState({
            pageSize: state.pageSize,
            page: state.page,
            sponsorId,
            orderStatus,
            productName,
            userPhone,
            email,
            coupon,
            orderId,
        }, this.getRedemptions);
    }

    getRedemptions = () => {
        this.setState({
            loading: true,
        });
        const { pageSize, page } = this.state;
        const params = {
            pageSize,
            page,
            sponsorId: this.state.sponsorId || null,
            orderStatus: this.state.orderStatus || null,
            productName: this.state.productName || null,
            userPhone: this.state.userPhone || null,
            email: this.state.email || null,
            coupon: this.state.coupon || null,
            startDate: (this.state.redemptionDates.startDate) ? this.state.redemptionDates.startDate.format('YYYY-MM-DD') : null,
            endDate: (this.state.redemptionDates.endDate) ? this.state.redemptionDates.endDate.format('YYYY-MM-DD') : null,
            orderId: (this.state.orderId) ? (this.state.orderId) : null,
        };
        return getAllRedemptions(params)
            .then((responseBody) => {
                this.setState({
                    data: responseBody.redemptions,
                    pages: Math.ceil(responseBody.totalHits / this.state.pageSize),
                    totalHits: responseBody.totalHits || 0,
                })
            })
            .catch((err) => {
                console.error({ err, params },
                    'Error in [getRedemptions]');
                toast.error(err.errorMessage || 'Error while fetching redemptions');
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    closeDetailsModal =  () => {
        this.setState({
            detailsModal: {
                redemptionId: null,
                isOpen: false,
            }
        });
    }

    closeEditStatusModal = () => {
        this.setState({
            editStatusModal: {
                redemptionId: null,
                isOpen: false,
                status: '',
            },
            visitCost: null,
        }, this.getRedemptions);
    };

    openEditStatusModal = (redemptionId, status) => {
        this.setState({
            editStatusModal: {
                redemptionId,
                isOpen: true,
                status,
            }
        })
    };

    submitStatusChangeModal = () => {
        const { redemptionId, status } = this.state.editStatusModal;
        const { visitCost } = this.state;
        if (status === 'Delivered' && visitCost === null) {
            toast.error('Kindly provide a visit price.');
            return;
        }
        if (status !== 'Delivered' && visitCost) {
            toast.error('Visit Cost only applicable to Delivered status.');
            return;
        }
        return changeStatus(redemptionId, status, visitCost)
            .then((response) => {
                toast.success(`Successfully marked ${status}`);
                this.closeEditStatusModal();
                this.setState({
                    visitCost: null
                })
            })
            .catch((err) => {
                toast.error(`Error while marking ${status}`);
            });
    };

    handleStatusChangeButton = (event) => {
        const { value } = event.target;
        this.setState({
            editStatusModal: {
                ...this.state.editStatusModal,
                status: value,
            },
        });
    };

    handleOpenDetailsModal = (redemptionId) => {
        this.setState({
            detailsModal: {
                redemptionId,
                isOpen: true,
            },
            editDeliveryDetailsModal: {
                redemptionId,
            }
        });
    };

    changeRedemptionDateFilter = (startDate, endDate) => {
        console.log(startDate, endDate, '[changeRedemptionDateFilter]');
        this.setState({
            redemptionDates: {
                startDate: (startDate),
                endDate: (endDate),
            },
        }, this.getRedemptions);
    };

    openAutoPayoutConfirmationModal = (redemptionId) => {
        this.setState({
            confirmModal: {
                isOpen: true,
                message: 'Are you sure want to payout?',
                onConfirm: () => {
                    return autoPayout(redemptionId)
                        .then((response) => {
                            if (response.message === 'success') {
                                toast.success('Successfully paid out');
                            } else {
                                toast.error(response.data.errorMessage);
                            }
                        })
                        .catch((err) => {
                            toast.error('Error while paying out');
                        })
                        .finally(() => {
                            this.getRedemptions();
                        });
                },
            }
        })
    };

    closeOrderDetailsModal = () => {
        this.setState({
            orderDetailsModal: {
                redemptionId: null,
                isOpen: false,
            },
        }, this.getRedemptions);
    };

    closeEditDeliveryModal = () => {
        this.setState({
            editDeliveryDetailsModal: {
                isOpen: false,
                redemptionId: null,
            },
        });
    };

    openOrderDetailsModal = (redemptionId) => {
        this.setState({
            orderDetailsModal: {
                redemptionId,
                isOpen: true,
            },
        }, this.closeDetailsModal);
    };

    openEditDeliveryModal = (redemptionId) => {
        this.setState({
            editDeliveryDetailsModal: {
                redemptionId,
                isOpen: true,
            },
        }, this.closeDetailsModal);
    };

    visitPriceChangeHandler = (e) => {
        const updatedPrice = e.target.value.toString();
        console.log('Updated price: ', updatedPrice);
        this.setState({
            visitCost: updatedPrice
        });
    }

    getColumns = () => {
        return [ {
            accessor: 'redemptionId',
            numeric: true,
            disablePadding: false,
            Header: 'Redemption Id',
            style: { whiteSpace: 'unset' },
            className: 'text-center',
            width: 80,
            Cell: (row) => (
                <button className="btn btn-primary" onClick={() => (this.handleOpenDetailsModal(row.original.redemptionId))}>{row.original.redemptionId}</button>
            ),
        }, {
            accessor: 'productName',
            numeric: false,
            disablePadding: false,
            Header: 'Product',
            style: { whiteSpace: 'unset' },
            width: 200,
            filterable: true,
        }, {
            accessor: 'userName',
            numeric: false,
            disablePadding: false,
            Header: 'Name',
            style: { whiteSpace: 'unset' },
        }, {
            accessor: 'userPhone',
            numeric: false,
            disablePadding: false,
            Header: 'Phone',
            style: { whiteSpace: 'unset' },
            className: 'text-center',
            filterable: true,
        }, {
            accessor: 'email',
            numeric: false,
            disablePadding: false,
            Header: 'Email',
            style: { whiteSpace: 'unset' },
            className: 'text-center',
            filterable: true,
        },
          {
            accessor: 'coupon',
            numeric: false,
            disablePadding: false,
            Header: 'Coupon',
            style: { whiteSpace: 'unset' },
            className: 'text-center',
            filterable: true,
           },
         {
            accessor: 'sponsorName',
            numeric: false,
            disablePadding: false,
            Header: 'Sponsor',
            style: { whiteSpace: 'unset' },
            className: 'text-center',
            filterable: true,
            Filter: ({ filter, onChange }) => (<SponsorFilter onChangeValue={onChange} value={filter ? filter.value : ""} />),
        }, {
            accessor: 'orderId',
            numeric: false,
            disablePadding: false,
            Header: 'Order Id',
            style: { whiteSpace: 'unset' },
            className: 'text-center',
            filterable: true,
        }, {
            accessor: 'remarks',
            numeric: false,
            disablePadding: false,
            Header: 'Remarks',
            style: { whiteSpace: 'unset' },
            className: 'text-center',
            filterable: false,
        }, {
            accessor: 'orderStatus',
            numeric: false,
            disablePadding: false,
            Header: 'Status',
            style: { whiteSpace: 'unset' },
            width: 200,
            filterable: true,
            Filter: ({ filter, onChange }) => (
                <select onChange={e => onChange(e.target.value)} value={filter ? filter.value : ""}>
                    <option value="">All</option>
                    {OrderStatus.map(o => (<option key={o.value} value={o.value}>{o.name}</option>))}
                </select>
            ),
            Cell: (row) => (
                <div className="row">
                    <div className="col">
                        {row.original.orderStatus}
                    </div>
                    <div className="col">
                        <span onClick={() => (this.openEditStatusModal(row.original.redemptionId, row.original.orderStatus))}><FaRegEdit/></span>
                    </div>
                </div>
            ),
        },
            {
                accessor: 'visitCost',
                numeric: false,
                disablePadding: false,
                Header: 'Visit Cost',
                style: { whiteSpace: 'unset' },
                className: 'text-center',
                filterable: false,
            },
            {
            accessor: 'redemptionDate',
            numeric: false,
            disablePadding: false,
            Header: 'Redeemed On',
            style: { whiteSpace: 'unset' },
            filterable: true,
            Filter: (props) => (
                <DateFilter
                    label="Redemption"
                    value={this.state.redemptionDates}
                    {...props}
                    onChangeValue={this.changeRedemptionDateFilter}
                />
            )
        } ];
    };

    render() {
        const { loading, pages, data, detailsModal, editStatusModal } = this.state;
        return (
            <div className="container-fluid">
                <div className="row mb-3">
                    <div className="col">
                        <h3><FaWarehouse color={"#238ae6"} className="pb-1" /> Redemptions <DownloadCsv jsonArray={data} /></h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <ReactTable
                            filterable={false}
                            style={{ flex: 1 }}
                            pages={pages}
                            manual
                            data={data}
                            onFetchData={this.onFetchData}
                            className="-highlight"
                            columns={this.getColumns()}
                            loading={loading}
                            pageSizeOptions={[ 5, 10, 20, 25, 50, 100, 1000 ]}
                        />
                    </div>
                </div>
                <Modal
                    isOpen={detailsModal.isOpen}
                    onClose={() => (this.closeDetailsModal())}
                    header={(<React.Fragment><FaInfo color={"#238ae6"} className="pb-1" /> Redemption Details - {detailsModal.redemptionId}</React.Fragment>)}
                >
                    <DetailsContainer
                        redemptionId={detailsModal.redemptionId}
                        openPayoutModal={this.openAutoPayoutConfirmationModal}
                        openOrderDetailsModal={this.openOrderDetailsModal}
                        openEditDeliveryModal={this.openEditDeliveryModal}
                        closeModal = {() => (this.closeDetailsModal())}
                    />
                </Modal>
                <Modal
                    isOpen={editStatusModal.isOpen}
                    onClose={() => (this.closeEditStatusModal())}
                    header={(<React.Fragment>Change Status - {editStatusModal.redemptionId}</React.Fragment>)}
                >
                    <div className="row">
                        <div className="col">
                            <Label>Status:</Label>
                        </div>
                        <div className="col">
                            <select value={editStatusModal.status} onChange={this.handleStatusChangeButton}>
                                <option value="">All</option>
                                {OrderStatus.map(o => (<option key={o.value} value={o.value}>{o.name}</option>))}
                            </select>
                        </div>
                    </div>

                    <div style={{ marginTop: "5px", marginBottom: "5px" }} className="row">
                        <div className="col">
                            <Label>Visit Cost</Label>
                        </div>
                        <div className="col">
                            <Input type="text" placeholder="0" value = {this.state.visitCost} onChange = {this.visitPriceChangeHandler}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-primary btn-block" onClick={this.submitStatusChangeModal}>Change</button>
                        </div>
                        <div className="col">
                            <button  className="btn btn-secondary btn-block" onClick={this.closeEditStatusModal}>Cancel</button>
                        </div>
                    </div>
                </Modal>
                <ModalConfirm
                    message={this.state.confirmModal.message}
                    isOpen={this.state.confirmModal.isOpen}
                    onConfirm={this.state.confirmModal.onConfirm}
                    onClose={() => (this.setState({ confirmModal: { isOpen: false, onConfirm: () => {}, message: '', } } ))}
                />
                <Modal
                    isOpen={this.state.orderDetailsModal.isOpen}
                    onClose={this.closeOrderDetailsModal}
                    header={`Order Details - ${this.state.editDeliveryDetailsModal.redemptionId}`}
                >
                    <OrderDetailsForm
                        redemptionId={this.state.orderDetailsModal.redemptionId}
                        onFormClose={this.closeOrderDetailsModal}
                    />
                </Modal>
                <Modal
                    header={`Delivery/Service Details - ${this.state.editDeliveryDetailsModal.redemptionId}`}
                    isOpen={this.state.editDeliveryDetailsModal.isOpen}
                >
                    <EditDeliveryDetailsForm
                        redemptionId={this.state.editDeliveryDetailsModal.redemptionId}
                        onFormClose={this.closeEditDeliveryModal}
                    />
                </Modal>
            </div>
        );
    }
}

export default RedemptionOpsHome;
