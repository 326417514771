import React, { Component } from 'react';
import { toast } from 'react-toastify';
import NavBar from '../layout/NavBar';
import ResultModal from '../common/modal/ResultModal';
import {
  OuterComponent,
  HeadingContainer,
  PaddedContainer,
  BodyContainer,
} from './StyledComponent';
import ReactTable from 'react-table';
// import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import ProductsAddEdit from './ProductsAddEdit';
import { Button } from 'reactstrap';
import {
  getProducts,
  uploadVoucherForProduct,
  fetchProductVoucherDetails,
  postInactiveProduct,
  activeProduct
} from '../../services/api/redemptions';

import { IoMdCreate, IoIosCloudUpload, IoIosListBox } from 'react-icons/io';

import ProductVoucherDetailsModal from './ProductVoucherDetailsModal';
import ProductPoolSponsorTable from './ProductPoolSponsorTable';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import Modal from '../common/Modal';
import { Form, Input, ModalBody } from 'reactstrap';
import { searchFilterMethod } from '../../utils/react-table';

class Products extends Component {
  constructor(props) {
    super(props);
  
    this.state = {
      loading: false,
      showModal: false,
      showPoolsModal: false,
      editProduct: false,
      showProductAddEdit: false,
      modalHeader: '',
      modalBody: '',
      locationOptions: [],
      filtered: [],
      productId: '',
      bgColor: '',
      poolIds: [],
      editData: [],
      confirmIactive: false,
      confirmActive: false,
      activeStatus: '',
      uploadModal: {
        isOpen: false,
        productId: null,
        file: null,
        expiryDate: '',
        couponType: '',
      },
      voucherDetailsModal: {
        isOpen: false,
        productDetails: {
          productId: null,
          productName: null,
        },
      },
    };
  }
  tableColumns = [
    {
      accessor: 'id',
      numeric: false,
      sortable: true,
      disablePadding: false,
      Header: 'Id',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'title',
      sortable: true,
      numeric: false,
      disablePadding: false,
      Header: 'Title',
      style: { whiteSpace: 'unset' },
      filterable: true,
      filterMethod: searchFilterMethod,
    },
    {
      accessor: 'visit_cost',
      numeric: false,
      sortable: true,
      disablePadding: false,
      Header: 'Visit Cost',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'type',
      numeric: false,
      sortable: true,
      disablePadding: false,
      Header: 'Type',
      style: { whiteSpace: 'unset' },
      // filterable: true,
      // Filter: ReactTableInputFilter,
    },
    {
      accessor: 'status',
      numeric: false,
      sortable: true,
      disablePadding: false,
      Header: 'Status',
      style: { whiteSpace: 'unset' },
      filterable: true,
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        return row.status === filter.value;
      },
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="Live">Live</option>
            <option value="Inactivated">Inactivated</option>
            {/* <option value="Deleted">Deleted</option> */}
            <option value="all">All Products</option>
          </select>
        );
      },
    },
    {
      accessor: 'gender',
      numeric: false,
      sortable: true,
      disablePadding: false,
      Header: 'Gender-Constraint',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'edit',
      numeric: false,
      disablePadding: false,
      sortable: true,
      Header: 'Edit',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return (
          <div>
            <IoMdCreate
              className="icon-style text-primary"
              id={`editProductButton`}
              style={{ fontSize: 45 }}
              onClick={() => {
                this.handleEditProduct(row);
              }}
            />
            {row.original.type === 'voucher' && (
              <React.Fragment>
                <IoIosCloudUpload
                  className="text-primary"
                  onClick={() => this.handleUploadVoucherFile(row.original.id)}
                />
                <IoIosListBox
                  className="text-secondary"
                  onClick={() => this.handleVoucherDetailsClick(row.original)}
                />
              </React.Fragment>
            )}
          </div>
        );
      },
    },
    {
      accessor: 'action',
      numeric: false,
      sortable: true,
      disablePadding: false,
      Header: 'Actions',
      style: { whiteSpace: 'unset' },
      // id: row.original,
      Cell: (row) => {
        if(row.original.status === 'Live'){
          return (
            <div>
              <Button
                color="primary"
                onClick={() => this.confirmIactive(row.original.id)}
              >
                Inactive
              </Button>
            </div>
          );
        }else{
          return (
            <div>
              <Button
                color="primary"
                onClick={() => this.confirmActive(row.original)}
              >
                Active
              </Button>
            </div>
          );
        }
      },
    },
  ];
  componentDidMount = () => {
    this.fetchTableData();
  };

  confirmIactive = (id) => {
    console.log('id', id);
    this.setState({
      productId: id,
      confirmIactive: true,
    });
  };
  confirmActive = (rowOriginal) => {
    console.log('id', rowOriginal);
    this.setState({
      productId: rowOriginal.id,
      confirmActive: true,
      activeStatus: rowOriginal.status,
    });
  };

  handleVoucherDetailsClick = (productDetails) => {
    this.setState({
      voucherDetailsModal: {
        isOpen: true,
        productDetails: {
          productId: productDetails.id,
          productName: productDetails.title,
        },
      },
    });
  };
  handleVoucherDetailsClose = () => {
    this.setState({
      voucherDetailsModal: {
        isOpen: false,
        productDetails: {
          productId: null,
          productName: null,
        },
      },
    });
  };

  handleUploadVoucherFile = (productId) => {
    return fetchProductVoucherDetails(productId)
      .then((response) => {
        if (response.message === 'success') {
          if (response.data.length) {
            this.setState({
              uploadModal: {
                isOpen: true,
                productId,
                couponType: response.data[0].couponType,
              },
            });
          } else {
            this.setState({
              uploadModal: {
                isOpen: true,
                productId,
              },
            });
          }
        }
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  fetchTableData = () => {
    this.setState({
      loading: true,
    });

    return getProducts()
      .then((responseBody) => {
        this.setState({
          data: responseBody.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // handel changes
  handleAddProduct = () => {
    this.setState({
      showProductAddEdit: true,
      editData: [],
      editProduct: false,
    });
  };
  handleEditProduct = (row) => {
    console.log(row.original);
    const editData = row.original;
    this.setState({
      showProductAddEdit: true,
      editProduct: true,
      editData,
    });
  };
  handleBack = () => {
    this.setState(
      {
        showProductAddEdit: false,
      },
      this.fetchTableData
    );
  };

  closeUploadVoucherModal = () => {
    this.setState({
      uploadModal: {
        isOpen: false,
        productId: null,
        file: null,
        expiryDate: '',
        couponType: '',
      },
    });
  };

  handleModalFileChange = (e) => {
    console.log(e.target.files[0], 'Target Props');
    this.setState({
      uploadModal: {
        ...this.state.uploadModal,
        file: e.target.files[0],
      },
    });
  };

  handleModalExpiryDate = (e) => {
    console.log(e.target.value, 'Target Props');
    this.setState({
      uploadModal: {
        ...this.state.uploadModal,
        expiryDate: e.target.value,
      },
    });
  };

  handleModalVoucherType = (e) => {
    this.setState({
      uploadModal: {
        ...this.state.uploadModal,
        couponType: e.target.value,
      },
    });
  };

  handleModalVoucherUploadSubmit = () => {
    const { uploadModal } = this.state;
    const { file, productId, couponType, expiryDate } = uploadModal;
    if (!file || !productId || !couponType || !expiryDate) {
      toast.error('Please fill all the details');
      return;
    }
    return uploadVoucherForProduct(file, productId, couponType, expiryDate)
      .then((response) => {
        console.log(response, 'Response from server');
        if (response.message === 'success') {
          toast.success('Successfully uploaded the vouchers');
          this.fetchTableData();
          this.closeUploadVoucherModal();
          return;
        }
        throw new Error(
          response.errorMessage || 'Error while uploading the file'
        );
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  inactiveProductFromAllPolls = (productId) => {
    console.log('productId', productId);
    return postInactiveProduct(productId)
      .then((response) => {
        if (response.message === 'success') {
          this.setState({
            confirmIactive: false,
          });
          toast.success('Successfully inactived the product');
          this.fetchTableData();
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  activeProductFromAllPolls = (productId,isActive) => {
    console.log('productId', productId,isActive);
    const params = {
      productId,
      isActive:isActive
    };
    console.log('params', params);
    return activeProduct(params)
      .then((response) => {
        if (response.message === 'success') {
          this.setState({
            confirmActive: false,
            poolIds:response.data
          });
          toast.success('Successfully inactived the product');
          this.fetchTableData();
          if(response.data){
            this.setState({
              showPoolsModal: true,
              modalHeader: 'Product Got Activated in the following pools',
            })
          }
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.message);
      });
  };

  render() {
    // console.log('data', this.state.data, this.props);
    return (
      <OuterComponent>
        <NavBar />
        <HeadingContainer>
          <h4>Products</h4>
        </HeadingContainer>
        <PaddedContainer>
          {!this.state.showProductAddEdit && (
            <Button onClick={this.handleAddProduct}>Add Products</Button>
          )}
          {this.state.showProductAddEdit && (
            <Button onClick={this.handleBack}>Back</Button>
          )}
        </PaddedContainer>
        <BodyContainer>
          {!this.state.showProductAddEdit && (
            <ReactTable
              data={this.state.data}
              columns={this.tableColumns}
              loading={this.state.loading}
              onFilteredChange={this.onFilteredChange}
              className="-striped -highlight text-center"
              SubComponent={(row) => (
                <ProductPoolSponsorTable
                  productId={row.original.id}
                  fetchTableData={this.fetchTableData}
                />
              )}
            />
          )}
          {this.state.showProductAddEdit && (
            <ProductsAddEdit
              editProduct={this.state.editProduct}
              togglePage={() =>
                this.setState({
                  showProductAddEdit: !this.state.showProductAddEdit,
                })
              }
              fetchTableData={() => {
                this.fetchTableData();
              }}
              editData={this.state.editData}
            />
          )}
        </BodyContainer>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        {this.state.showPoolsModal && (
          <ResultModal 
            modalHeader={this.state.modalHeader}
            poolIds={this.state.poolIds}
            closeModal={() => this.setState({ showPoolsModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        <Modal
          isOpen={this.state.uploadModal.isOpen}
          onClose={this.closeUploadVoucherModal}
          header="Upload Voucher"
        >
          <div className="container">
            <div className="row mb-3">
              <div className="col">
                <input type="file" onChange={this.handleModalFileChange} />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>Expiry Date</label>
              </div>
              <div className="col">
                <input
                  type="date"
                  value={this.state.uploadModal.expiryDate}
                  onChange={this.handleModalExpiryDate}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <label>Voucher Type</label>
              </div>
              <div className="col">
                <input
                  placeholder="3-5 letter voucher type"
                  value={this.state.uploadModal.couponType}
                  onChange={this.handleModalVoucherType}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col">
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.handleModalVoucherUploadSubmit}
                >
                  Upload
                </button>
              </div>
              <div className="col">
                <button className="btn btn-danger btn-block" onClick={this.closeUploadVoucherModal}>Cancel</button>
              </div>
            </div>
          </div>
        </Modal>
        <Modal isOpen={this.state.confirmIactive}>
          <ModalBody>Do you want to inactive product?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              // disabled={saving}
              onClick={() =>
                this.inactiveProductFromAllPolls(this.state.productId)
              }
            >
              Yes
            </Button>
            <Button
              color="secondary"
              // disabled={saving}
              onClick={() => {
                this.setState({
                  confirmIactive: false,
                });
              }}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.confirmActive}>
          <ModalBody>Do you want to active product?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              // disabled={saving}
              onClick={() =>
                this.activeProductFromAllPolls(this.state.productId,this.state.activeStatus)
              }
            >
              Yes
            </Button>
            <Button
              color="secondary"
              // disabled={saving}
              onClick={() => {
                this.setState({
                  confirmActive: false,
                });
              }}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
        <ProductVoucherDetailsModal
          isOpen={this.state.voucherDetailsModal.isOpen}
          productDetails={this.state.voucherDetailsModal.productDetails}
          onClose={this.handleVoucherDetailsClose}
        />
      </OuterComponent>
    );
  }
}

export default Products;
