import React, { Component } from 'react';
import ReactTable from 'react-table';
import { Button } from 'reactstrap';

class DocOnCallTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.tableColumns = [
      {
        accessor: 'id',
        numeric: true,
        disablePadding: true,
        Header: 'Id',
        style: { whiteSpace: 'unset' },
        filterable: true,
        Cell: (row) => {
          const { id, userId } = row.original;
          return (
            <div style={{backgroundColor : row.original.isVipUser ? '#b78d78': 'transparent', display:'flex',alignItems:'center', justifyContent:'center', flexDirection:'column', padding:'0.5rem', borderRadius:'4px'}}>
              <Button
                type="submit"
                size="sm"
                className="bg-primary"
                onClick={() =>
                  this.props.showConsultationView(
                    id,
                    this.props.consultationType,
                    userId
                  )
                }
              >
                {id}
              </Button>
              &nbsp;&nbsp;{row.original.isVipUser ? 'VIP' : ''}
            </div>
          );
        },
        Filter: ({ filter, onChange }) => {
          console.log(filter, 'Consultation Id Filter');
          return (
            <input
              placeholder="Filter Ids"
              className="form-control"
              onChange={(e) => onChange(e.target.value)}
              value={filter ? filter.value : ''}
            />
          );
        },
      },
      // {
      //   width: 100,
      //   accessor: 'isBooked',
      //   numeric: true,
      //   disablePadding: false,
      //   Header: 'Booked',
      //   style: { whiteSpace: 'unset' },
      // },
      {
        width: 180,
        accessor: 'bookedConsultationId',
        numeric: true,
        disablePadding: false,
        Header: 'Booked Consultation Id',
        style: { whiteSpace: 'unset' },
        filterable: true,
        Cell: (row) => {
          const { bookedConsultationId, userId } = row.original;
          if (bookedConsultationId)
            return (
              <div>
                <Button
                  type="submit"
                  size="sm"
                  className="bg-primary"
                  onClick={() =>
                    this.props.showConsultationView(
                      bookedConsultationId,
                      'online-sp',
                      userId
                    )
                  }
                >
                  {bookedConsultationId}
                </Button>
              </div>
            );
          return '';
        },
        Filter: ({ filter, onChange }) => {
          return (
            <select
              onChange={(event) => onChange(event.target.value)}
              value={
                filter ? filter.value : this.props.internalFilter.cancelReason
              }
            >
              <option value="all">All</option>
              <option value="only-booked">Only Booked</option>
            </select>
          );
        },
      },
      {
        width: 260,
        accessor: 'scheduledDateTime',
        numeric: false,
        disablePadding: false,
        Header: 'Scheduled Date Time',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 260,
        accessor: 'timeCreated',
        numeric: false,
        disablePadding: false,
        Header: 'Consultation Date',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 200,
        accessor: 'doctorName',
        numeric: false,
        disablePadding: false,
        Header: 'Dr. Name',
        style: { whiteSpace: 'unset' },
        Cell: (row) => {
          const { doctorName, assistantName, consultationType } = row.original;
          if (this.props.consultationType === 'scheduled-consults') {
            return doctorName;
          }
          if (consultationType !== 'online-sp') {
            return assistantName;
          }
          let name = doctorName;
          if (assistantName) {
            name += `, ${assistantName}`;
          }
          return name;
        },
      },
      {
        accessor: 'doctorVertical',
        numeric: false,
        disablePadding: false,
        Header: 'Vertical',
        width: 150,
        style: { whiteSpace: 'unset' },
        Cell: (row) => {
          const { doctorVertical, assistantVertical, consultationType } =
            row.original;
          if (consultationType === 'doc-on-call') {
            return assistantVertical;
          }
          let name = doctorVertical;
          if (assistantVertical) {
            name += `, ${doctorVertical}`;
          }
          return name;
        },
      },

      {
        accessor: 'doctorId',
        numeric: false,
        sortable: false,
        disablePadding: false,
        Header: 'Dr. Id',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 200,
        accessor: 'userName',
        numeric: false,
        disablePadding: false,
        Header: 'User Name',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 200,
        accessor: 'patientName',
        numeric: false,
        disablePadding: false,
        Header: 'Patient Name',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'sponsorName',
        numeric: false,
        disablePadding: false,
        Header: 'Sponsor',
        style: { whiteSpace: 'unset', overflow: 'visible' },
        // Filter: ({ filter, onChange }) => (
        //   <MultiSponsorFilter
        //     onChangeValue={onChange}
        //     value={filter ? filter.value : ''}
        //   />
        // ),
        // filterable: true,
      },
      {
        accessor: 'sponsorId',
        numeric: false,
        disablePadding: false,
        Header: 'SponsorId',
        style: { whiteSpace: 'unset', overflow: 'visible' },
      },
      {
        width: 260,
        accessor: 'cancelledOn',
        numeric: false,
        disablePadding: false,
        Header: 'Cancellation Date',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 260,
        accessor: 'cancelledBy',
        numeric: true,
        disablePadding: false,
        Header: 'Cancelled By',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 260,
        accessor: 'cancelReason',
        numeric: false,
        disablePadding: false,
        Header: 'Cancel Reason',
        style: { whiteSpace: 'unset' },
        filterable: true,
        Filter: ({ filter, onChange }) => {
          return (
            <select
              onChange={(event) => onChange(event.target.value)}
              value={
                filter ? filter.value : this.props.internalFilter.cancelReason
              }
            >
              <option value="all">All</option>
              <option value="I want to consult a different doctor">
                I want to consult a different doctor
              </option>
              <option value="I want to change my time slot">
                I want to change my time slot
              </option>
              <option value="Consulted somewhere else">
                Consulted somewhere else
              </option>
              <option value="No longer needed">No longer needed</option>
              <option value="Booking attempt failed as Doctor is Unavailable">
                Booking attempt failed as Doctor is Unavailable
              </option>
              <option value="Night Booking is not allowed">
                Booking attempt failed as Night Booking is not allowed
              </option>
              <option value="Booking attempt failed as Active Consultations found">
                Booking attempt failed as Active Consultations found
              </option>
              <option value="Booking attempt failed as Payment not completed">
                Booking attempt failed as Payment not completed
              </option>
              <option value="Booking attempt failed as Telehealth Services are Off Today">
                Booking attempt failed as Telehealth Services are Off Today
              </option>
              <option value="Booking attempt failed, check error msg">
                Booking attempt failed, check error msg
              </option>
              <option value="Booking attempt failed due to an Unkonwn Error">
                Booking attempt failed due to an Unkonwn Error
              </option>
            </select>
          );
        },
      },
      {
        width: 260,
        accessor: 'errorMessage',
        numeric: false,
        disablePadding: false,
        Header: 'Error Message',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'requestedCallType',
        numeric: false,
        disablePadding: false,
        Header: 'Requested Call Type',
        style: { whiteSpace: 'unset', overflow: 'visible' },
        // Filter: ({ filter, onChange }) => (
        //   <MultiSponsorFilter
        //     onChangeValue={onChange}
        //     value={filter ? filter.value : ''}
        //   />
        // ),
        // filterable: true,
      },
      {
        accessor: 'callsCount',
        numeric: false,
        sortable: false,
        disablePadding: false,
        Header: 'Attempted Calls (Duration)',
        style: { whiteSpace: 'unset' },
        Cell: (row) => {
          const { callsDuration, callsCount } = row.original;
          return (
            <p>
              {callsCount} ({callsDuration})
            </p>
          );
        },
      },
      {
        accessor: 'videoCallCount',
        numeric: false,
        sortable: false,
        disablePadding: false,
        Header: 'Attempted Video Calls (Duration)',
        style: { whiteSpace: 'unset' },
        Cell: (row) => {
          const { videoCallDuration, videoCallCount } = row.original;
          return (
            <p>
              {videoCallCount} ({videoCallDuration})
            </p>
          );
        },
      },
      {
        width: 105,
        accessor: 'tat',
        numeric: false,
        disablePadding: false,
        sortable: false,
        Header: 'TAT',
        style: { whiteSpace: 'unset' },
        // filterable: true,
        // Filter: ({ filter, onChange }) => (
        //   <select
        //     onChange={(event) => onChange(event.target.value)}
        //     value={filter ? filter.value : ''}
        //   >
        //     <option value="">All</option>
        //     <option value="false">No Response</option>
        //     <option value="true">Response</option>
        //   </select>
        // ),
      },
      {
        width: 105,
        accessor: 'opsTat',
        numeric: false,
        disablePadding: false,
        sortable: false,
        Header: 'OpsTAT',
        style: {whiteSpace: 'unset'}
      },
      {
        accessor: 'prescriptions',
        numeric: true,
        sortable: false,
        disablePadding: false,
        Header: 'Rx / Diet Plans',
        filterable: true,
        style: { whiteSpace: 'unset' },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            value={
              filter ? filter.value : this.props.internalFilter.prescriptions
            }
          >
            <option value="all">All</option>
            <option value="sent">Sent</option>
            <option value="not-sent">Not Sent</option>
          </select>
        ),
      },
      {
        width: 160,
        accessor: 'rxTat',
        numeric: false,
        disablePadding: false,
        Header: 'RxTat(minutes)',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'doctorTTCount',
        numeric: false,
        sortable: false,
        disablePadding: false,
        Header: 'Dr. Msg',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'userTTCount',
        numeric: false,
        disablePadding: false,
        sortable: false,
        Header: 'User Msg',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'opsNote',
        numeric: false,
        sortable: false,
        disablePadding: false,
        Header: 'Notes/ Comments',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 160,
        accessor: 'lastCommentTime',
        numeric: false,
        disablePadding: false,
        Header: 'Last Comment Time',
        style: { whiteSpace: 'unset' },
      },
      {
        accessor: 'amount',
        numeric: true,
        disablePadding: false,
        Header: 'Amount',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 240,
        accessor: 'status',
        numeric: false,
        disablePadding: false,
        sortable: false,
        Header: 'Status',
        style: { whiteSpace: 'unset' },
        filterable: true,
        Filter: ({ filter, onChange }) => {
          return (
            <select
              onChange={(event) => onChange(event.target.value)}
              value={filter ? filter.value : this.props.internalFilter.status}
            >
              <option value="all">All</option>
              <option value="Pending - Doctor never connected">
                Pending - Doctor never connected
              </option>
              <option value="Pending - Rx not sent">
                Pending - Rx Not Sent
              </option>

              <option value="Pending - User not reachable">
                Pending - User not reachable
              </option>
              <option value="Cancelled - Spam">Cancelled - Spam</option>
              <option value="Cancelled - User did not want consultation">
                Cancelled - User did not want consultation
              </option>
              <option value="Cancelled - Medical Query">
                Cancelled - Medical Query
              </option>
              <option value="Cancelled - Non Medical Query">
                Cancelled - Non Medical Query
              </option>
              <option value="Cancelled - Proxy User">
                Cancelled - Proxy User
              </option>
              <option value="Cancelled - Demo Consultation">
                Cancelled - Demo Consultation
              </option>
              <option value="Cancelled - Ops(Demo Appointment)">
                Cancelled - Ops (Demo Appointment)
              </option>
              <option value="Cancelled - Ops(User Requested)">
                Cancelled - Ops(User Requested)
              </option>
              <option value="Cancelled - Consultation not completed">
                Cancelled - Consultation not completed
              </option>
              <option value="Completed">Completed</option>
              <option value="Closed">Closed</option>
            </select>
          );
        },
      },
      {
        width: 260,
        accessor: 'totalConnectionRequest',
        numeric: false,
        disablePadding: false,
        sortable: false,
        Header: 'Voice Callbacks',
        style: { whiteSpace: 'unset' },
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            value={
              filter
                ? filter.value
                : this.props.internalFilter.connectionRequest
            }
          >
            <option value="All">All</option>
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
          </select>
        ),
        Cell: (row) => {
          const {
            totalConnectionRequest,
            attemptedCount,
            completedConnectionRequest,
            lastConnectionRequest,
          } = row.original;
          if (totalConnectionRequest > completedConnectionRequest) {
            return (
              <div className="text-danger">
                {completedConnectionRequest}/{totalConnectionRequest} - (
                {lastConnectionRequest})
                <p><strong>Attempts:</strong> {attemptedCount}</p>
              </div>
            );
          } else if (totalConnectionRequest === 0) {
            return <div>None</div>;
          } else {
            return (
              <div className="text-success">
                {totalConnectionRequest} - ({lastConnectionRequest})
              </div>
            );
          }
        },
      },
      {
        width: 260,
        accessor: 'totalVideoConnectionRequest',
        numeric: false,
        disablePadding: false,
        sortable: false,
        Header: 'Video Callbacks',
        style: { whiteSpace: 'unset' },
        filterable: true,
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            value={
              filter
                ? filter.value
                : this.props.internalFilter.videoConnectionRequest
            }
          >
            <option value="All">All</option>
            <option value="pending">Pending</option>
            <option value="completed">Completed</option>
          </select>
        ),
        Cell: (row) => {
          const {
            totalVideoConnectionRequest,
            completedVideoConnectionRequest,
            lastVideoRequestTimestamp,
          } = row.original;
          if (totalVideoConnectionRequest > completedVideoConnectionRequest) {
            const date = new Date(lastVideoRequestTimestamp);
            const formatedDate =
              date.toDateString() + ', ' + date.toLocaleTimeString();
            return (
              <div className="text-danger">
                {completedVideoConnectionRequest}/{totalVideoConnectionRequest}{' '}
                - ({formatedDate})
              </div>
            );
          } else if (totalVideoConnectionRequest === 0) {
            return <div>None</div>;
          } else {
            return (
              <div className="text-success">
                {totalVideoConnectionRequest} - ({lastVideoRequestTimestamp})
              </div>
            );
          }
        },
      },
      {
        width: 160,
        accessor: 'userLastMonthConsultsCount',
        numeric: false,
        disablePadding: false,
        Header: 'User Last Month Consults',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 160,
        accessor: 'lastCallStatus',
        numeric: false,
        disablePadding: false,
        Header: 'Last Call Status',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 260,
        accessor: 'endedOn',
        numeric: true,
        disablePadding: false,
        Header: 'Ended On',
        style: { whiteSpace: 'unset' },
      },
      {
        width: 260,
        accessor: 'consultationLanguage',
        numeric: true,
        disablePadding: false,
        Header: 'Consultation Language',
        style: { whiteSpace: 'unset' },
      },

    ];

    this.getTrProps = (state, rowInfo, instance) => {
      if(rowInfo && rowInfo.original.hasScheduleConsultId) {
        return {
          style: {
            background: 
            rowInfo.original.hasScheduleConsultId === 1 ? '#f7c42b' : '',
          }
        }
      }
      if(rowInfo && rowInfo.original.hasScheduleConsultId === 0 && rowInfo.original.requestedCallType === 'video') {
        return {
          style: {
            background: 
            rowInfo.original.hasScheduleConsultId === 0 && rowInfo.original.requestedCallType === 'video' ? '#FFC0CB' : '',
          }
        }
      }
      if(rowInfo && rowInfo.original.platform === 'OPS-ABHI') {
        return {
          style: {
            background: rowInfo.original.platform === 'OPS-ABHI' ? '#FF4D4D' : '',
          }
        }
      }
      if (rowInfo) {
        return {
          style: {
            background:
              rowInfo.original.platform === 'HOTLINE' ? '#489652' : '',
            // color: 'white',
          },
        };
      }
      return {};
    };

    this.tableColumns = this.tableColumns.filter(x => this.props.allowedColumns.includes(x.accessor));
    if (
      this.props.consultationType &&
      this.props.consultationType === 'online-sp'
    ) {
      this.tableColumns.splice(18, 0, {
        accessor: 'scheduledAt',
        numeric: false,
        disablePadding: false,
        Header: 'Scheduled Consults',
        style: { whiteSpace: 'unset', overflow: 'visible' },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => {
              // console.log(filter, 'FILTER:scheduledAt');
              onChange(event.target.value);
            }}
            style={{ width: '100%' }}
            value={filter ? filter.value : this.props.internalFilter.schedule}
          >
            <option value="all">Show All</option>
            <option value="scheduled">Scheduled</option>
            <option value="not-scheduled">Not Scheduled</option>
          </select>
        ),
        filterable: true,
      });
      // this.tableColumns.splice(2, 0, {
      //   accessor: '',
      //   numeric: false,
      //   disablePadding: false,
      //   sortable: false,
      //   style: { whiteSpace: 'unset' },
      //   Header: 'Labels',
      //   Cell: (row) => {
      //     const { labels } = row.original;
      //
      //     return (
      //       <div>
      //         {labels.map((l) => (
      //           <span key={l.title} className="badge badge-success">
      //             {l.title}
      //           </span>
      //         ))}
      //       </div>
      //     );
      //   },
      // });
    }
  }

  componentDidMount() { }

  render() {
    const { loading, data, pages, pageIndex, pageSize, onFetchData } =
      this.props;
    return (
      <React.Fragment>
        <ReactTable
          data={data}
          columns={this.tableColumns}
          loading={loading}
          pages={pages}
          manual
          onFetchData={onFetchData}
          showPaginationTop={true}
          className="-striped -highlight"
          defaultPageSize={this.props.pageSize}
          page={this.props.pageIndex}
          getTrProps={this.getTrProps}
          pageSizeOptions={[5, 10, 20, 25, 50, 100, 300, 500, 800, 1000, 2000]}
          defaultSorted={[
            {
              id: 'id',
              desc: true,
            },
          ]}
          getTheadFilterThProps={(state, rowInfo, column) => {
            return {
              style: {
                overflow: 'visible',
              },
            };
          }}
        />
      </React.Fragment>
    );
  }
}

export default DocOnCallTable;
