import React from 'react';

export default class NetworkSourceFilter extends React.Component {
  onChange = e => {
    this.props.onChange(e.target.value);
  };

  render() {
    return (
      <select onChange={this.onChange} value={this.props.value}>
        <option value="">All</option>
        <option value="credit">Credit</option>
        <option value="non-credit">Non Credit</option>
        <option value="prepaid">Prepaid</option>
      </select>
    );
  }
}
