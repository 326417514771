import React, { useState } from 'react';

const NetworkSourceFilter = ({ value, onChange }) => {
  const handleOnChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <select
      style={{
        width: '100%',
        height: '40px',
        fontSize: '12px',
        borderRadius: '10px',
      }}
      onChange={handleOnChange}
      value={value}
    >
      <option value="">All</option>
      <option value="credit">Credit</option>
      <option value="non-credit">Non Credit</option>
      <option value="prepaid">Prepaid</option>
    </select>
  );
};

export default NetworkSourceFilter;
