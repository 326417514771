import React from 'react';
import Modal from 'react-modal';

import { getPricingForRequest, changePriceForRequest } from '../../services/api/offline-consult';

import LoadingComponent from '../../components/common/LoadingComponent';
import { toast } from 'react-toastify';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default class EditUserPaymentModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pricingData: null,
            loader: false,
        };
    }

    closeModal = () => {
        this.setState({
            pricingData: null,
            loader: false,
        });
        this.props.closeModal();
    };

    afterOpenModal = () => {
        const { requestId } = this.props;
        return getPricingForRequest(requestId)
            .then((response) => {
                console.log(response, 'Response from getPricingInfo api');
                if (response && response.message === 'success') {
                    this.setState({
                        pricingData: response.data,
                    });
                    return;
                }
                throw new Error('Invalid Response');
            })
            .catch((err) => {
                console.error(err, requestId);
                this.setState({
                    pricingData: null,
                });
                toast.error('Error while calculating the pricing');
            })
          
    };

    changePrice = () => {
        const { requestId } = this.props;
        this.setState({
          loader: true,
        });
        return changePriceForRequest(requestId, this.state.pricingData.amountRemaining)
            .then((response) => {
                if (response && response.message === 'success') {
                  this.state.loader = false;
                    toast.success('Successfully Updated the price');
                    return;
                }
                throw new Error('Invalid Response');
            })
            .catch((err) => {
                console.error(err, requestId);
                if(err.response.data.errorMessage){
                    toast.error(err.response.data.errorMessage);
                  }else{
                    toast.error('Error while updating the pricing');
                  }
               
            })
            .finally(() => {
              this.setState({
                loader: true,
              });
              this.closeModal();
              this.props.onComplete();
            });
    };

    render() {
        const { pricingData, loader } = this.state;
        const { requestId } = this.props;
        return (
            <Modal
                isOpen={this.props.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
            >
                {(!pricingData) ? (
                    <LoadingComponent color={'black'} />
                ) : (
                    <React.Fragment>
                        <div className="container-fluid">
                            <div className="row">
                                <h4>User Payments: ({requestId})</h4>
                            </div>
                            <div className="row">
                                <div className='col-6'>
                                    Amount Paid:<br />Rs. {pricingData.amountPaid}
                                </div>
                                <div className='col-6'>
                                    Doctor Charges:<br />Rs. {pricingData.opdCharges}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    Expected Co-Pay:<br />Rs. {pricingData.coPay}
                                </div>
                                <div className="col-6">
                                    Amount Pending:<br />Rs. {pricingData.amountRemaining}
                                </div>
                                <div className="col-6">
                                    Wallet Deducted:<br />Rs. {pricingData.walletDeduction}
                                </div>
                                <div className="col-6">
                                    Wallet to be deducted:<br />Rs. {pricingData.walletRemainingDeduction}
                                </div>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-6">
                                    <button className="btn btn-primary btn-block" onClick={this.changePrice} disabled={loader}>Recalculate</button>
                                </div>
                                <div className="col-6">
                                    <button className="btn btn-secondary btn-block" onClick={this.closeModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}

                {/* Loader for calculate API */}
                {(pricingData && loader) ?
                  (<LoadingComponent color={'black'} />) : <></>
                }
            </Modal>
        );
    }
}
