import React, { Component } from 'react';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import StyledTappable from '../common/StyledTappable';
import { OuterComponent, customStyles } from '../common/modal/StyledComponent';
import { Row, Col, Input, Label } from 'reactstrap';
import Select from 'react-select';
import ResultModal from '../common/modal/ResultModal';
import { updateMemberDetails } from '../../services/api/opd-management';

const CloseModal = styled(FaTimes)``;

const HeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
`;

class OpdConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      genderSelectedOption: props.details.gender,
      options: [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' },
      ],
      name: props.details.memberName,
      dob: props.details.dob || '',
      showModal: false,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
    };
  }
  // Update OPD Members
  updateOpdMembers = () => {
    const data = {
      opdHolderId: this.props.details.opdHolderId,
      dependentId: this.props.details.dependentId,
      name: this.state.name,
      dob: this.state.dob,
      gender: this.state.genderSelectedOption,
    };
    // console.log({ data }, 'updateOpdMembers');
    updateMemberDetails(data)
      .then((responseBody) => {
        this.props.closeModal();
        this.setState({
          showModal: true,
          modalHeader: 'Success',
          modalBody: `OPD Member Updated.`,
          bgColor: 'bg-success',
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };

  // Handle Change
  handleChangeName = (e) => {
    this.setState({ name: e.target.value });
  };
  handleChangeGender = (gender) => {
    this.setState({ genderSelectedOption: gender.value });
  };
  handleChangeDob = (e) => {
    this.setState({ dob: e.target.value });
  };
  render() {
    let genderSel = {};
    this.state.options.forEach((option) => {
      if (option.value === this.state.genderSelectedOption) {
        genderSel = option;
      }
    });
    return (
      <Modal isOpen toggle={this.props.closeModal} style={customStyles}>
        <OuterComponent>
          <HeaderComponent>
            <h3 className="text-primary">Update</h3>
            <StyledTappable onTap={this.props.closeModal}>
              <CloseModal />
            </StyledTappable>
          </HeaderComponent>
          <ModalBody>
            {this.props.details.opdHolderId && (
              <Row>
                <Col style={{ marginTop: '0.1rem' }}>
                  <Label> OPD Holder Id:</Label>
                </Col>
                <Col style={{ marginTop: '0.1rem' }}>
                  <Label> {this.props.details.opdHolderId}</Label>
                </Col>
              </Row>
            )}
            {this.props.details.dependentId && (
              <Row>
                <Col style={{ marginTop: '0.1rem' }}>
                  <Label> Dependent Id:</Label>
                </Col>
                <Col style={{ marginTop: '0.1rem' }}>
                  <Label> {this.props.details.dependentId}</Label>
                </Col>
              </Row>
            )}
            <Row>
              <Col style={{ marginTop: '0.1rem' }}>
                <Label> Name:</Label>
              </Col>
              <Col style={{ marginTop: '0.5rem' }}>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  onChange={this.handleChangeName}
                  defaultValue={this.state.name}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: '0.1rem' }}>
                <Label> Gender:</Label>
              </Col>
              <Col style={{ marginTop: '0.5rem' }}>
                <Select
                  defaultValue={genderSel}
                  onChange={this.handleChangeGender}
                  options={this.state.options}
                />
              </Col>
            </Row>
            <Row>
              <Col style={{ marginTop: '0.rem' }}>
                <Label> DOB:</Label>
              </Col>
              <Col style={{ marginTop: '0.5rem' }}>
                <Input
                  type="date"
                  name="dob"
                  value={this.state.dob}
                  onChange={this.handleChangeDob}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.updateOpdMembers}>Update</Button>
            <Button onClick={this.props.closeModal}>Cancel</Button>
          </ModalFooter>
        </OuterComponent>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
      </Modal>
    );
  }
}

export default OpdConfirmModal;
