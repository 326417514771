import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Col,
  Row,
} from 'reactstrap';
import { getCategory, getCenters, getPartner, getSubCategory, getVaccinationCost, getVaccinationRequest } from '../../../services/api/cashless-vaccination';

function RescheduleRequests({
  isOpen,
  onClose,
  requestId,
  onComplete,
  className = 'bg-primary',
}) {
  const [loading, setLoading] = useState(false);
  const [basicDetails, setBasicDetails] = useState(null);
  const [centerOptions, setCenterOptions] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState([]);
  const [vaccineOptions, setVaccineOptions] = useState([]);
  const [vaccineCategoryOptions, setVaccineCategoryOptions] = useState([]);
  const [vaccineCategory, setVaccineCategory] = useState(null);
  const [centerName, setCenterName] = useState(null);
  const [partnerName, setPartnerName] = useState(null);
  const [vaccine, setVaccine] = useState('');
  const [vaccinationCharges, setVaccinationCharges] = useState('');
  const [appointmentDate, setAppointmentDate] = useState('');
  const [appointmentTime, setAppointmentTime] = useState('');
  const [rescheduleReason, setRescheduleReason] = useState('');
  const [remarks, setRemarks] = useState('');
  const [isLoadingCenters, setIsLoadingCenters] = useState(false);
  const [centerChanged, setCenterChanged] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);

  const initialState = {
    partnerName: null,
    vaccineCategory: null,
    vaccine: '',
    vaccinationCharges: '',
    appointmentDate: '',
    appointmentTime: '',
    rescheduleReason: '',
    remarks: '',
  };

  function convertTo24Hour(timeStr) {
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes] = time.split(':');
  
    if (hours === '12') {
      hours = '00';
    }
  
    if (modifier === 'PM') {
      hours = parseInt(hours, 10) + 12;
    }
  
    return `${hours.padStart(2, '0')}:${minutes}`;
  }
  
  function extractStartTime(timeRange) {
    const [startTime] = timeRange.split(' - ');
    return convertTo24Hour(startTime);
  }

  useEffect(() => {
    console.log(partnerName, '[partnerName]');
    if (isOpen) {
        // Fetch vaccination request details
        getVaccinationRequest(requestId).then((data) => {
            console.log(data, '[data]');
            setBasicDetails(data.basicInfo);
            setCenterName({
            value: data.basicInfo.centerId,
            label: data.basicInfo.networkCenterName,
            });
            setPartnerName({
            value: data.basicInfo.partnerId,
            label: data.basicInfo.partnerName,
            });
            setVaccineCategory({
              value: data.basicInfo.categoryId,
              label: data.basicInfo.categoryName
            })
            setVaccine({
              value: data.basicInfo.subCategoryId,
              label: data.basicInfo.subCategoryName
            })
            setVaccinationCharges(data.basicInfo.vaccinationAmount);
            setAppointmentDate(data.basicInfo.appointmentDate);
            if (data.basicInfo && data.basicInfo.appointmentTime) {
              setAppointmentTime(extractStartTime(data.basicInfo.appointmentTime));
            }
            setIsInitializing(false);
        });

        getCenters().then((centers) => {
            console.log(centers, '[centers]');
            setCenterOptions(
                centers.data.map((center) => ({
                    value: center.centerId,
                    label: center.nameAndId,
                }))
            );
        });

        getPartner().then((partner) => {
            console.log('[getPartner]', partner);
            setPartnerOptions(
                partner.data.map((partner) => ({
                    value: partner.networkPartnerId,
                    label: partner.networkPartnerName,
                }))
            );
        })

        getCategory().then((category) => {
            console.log('[getCategory]', category);
            setVaccineCategoryOptions(
                category.data.map((vaccine) => ({
                    value: vaccine.id,
                    label: vaccine.name,
                }))
            );
        })
    }
  }, [isOpen, requestId]);

  useEffect(() => {
    console.log(vaccineCategory, '[vaccineCategory]');
    if (vaccineCategory) {
      getSubCategory(vaccineCategory.value).then((subCategory) => {
        setVaccineOptions(
          subCategory.data.map((vaccine) => ({
            value: vaccine.id,
            label: vaccine.name,
          }))
        );
      });
    }
  }, [vaccineCategory]);

  const handleCenterSearch = (inputValue) => {
    if (inputValue.length > 2) {
        getCenters(inputValue).then((centers) => {
            setIsLoadingCenters(true);
            setCenterOptions(
                centers.data.map((center) => ({
                    value: center.centerId,
                    label: center.nameAndId,
                }))
            );
            setIsLoadingCenters(false);
        });
    }
  };

  const handleCenterChange = (selectedOption) => {
    setCenterName(selectedOption);
    setCenterChanged(true);
  };

  useEffect(() => {
    if (!isInitializing && centerChanged) {
      setPartnerName(initialState.partnerName);
      setVaccineCategory(initialState.vaccineCategory);
      setVaccine(initialState.vaccine);
      setVaccinationCharges(initialState.vaccinationCharges);
      setAppointmentDate(initialState.appointmentDate);
      setAppointmentTime(initialState.appointmentTime);
      setRescheduleReason(initialState.rescheduleReason);
      setRemarks(initialState.remarks);
    }
  }, [centerName, centerChanged, isInitializing]);

  useEffect(() => {
    if (vaccine) {
      const body = {
        subVaccineId: vaccine.value,
        categoryId: vaccineCategory.value,
        centerId: centerName.value
      }
      getVaccinationCost(body).then((cost) => {
        if (cost && cost.data && cost.data[0]) {
          setVaccinationCharges(cost.data && cost.data[0].vaccineCost);
        }
      });
    }
  }, [vaccine])

const handleSubmit = () => {
  const formData = {
    centerName,
    partnerName,
    vaccineCategory,
    vaccine,
    vaccinationCharges,
    appointmentDate,
    appointmentTime,
    rescheduleReason,
    remarks
  };

  console.log(formData, '[formData]');
};

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader className={className} toggle={onClose}>
        Reschedule Request {requestId}
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
          <Label for='centerName'>Center Name</Label>
            <Select
                id='centerName'
                options={centerOptions}
                value={centerName}
                // onChange={(selectedOption) => setCenterName(selectedOption)}
                onChange={handleCenterChange}
                onInputChange={handleCenterSearch}
                isLoading={isLoadingCenters}
                placeholder="Search Centers"
            />
          </FormGroup>
          <FormGroup>
            <Label for='partnerName'>Partner Name</Label>
            <Select
              id='partnerName'
              options={partnerOptions}
              value={partnerName}
              onChange={(selectedOption) => setPartnerName(selectedOption)}
              placeholder="Search Partners"
            />
          </FormGroup>
          <FormGroup>
            <Label for='vaccineCategory'>Vaccine Category</Label>
            <Select 
                id='vaccineCategory'
                options={vaccineCategoryOptions}
                value={vaccineCategory}
                onChange={(selectedOption) => setVaccineCategory(selectedOption)}
                placeholder='Search Category'
            />
          </FormGroup>
          <FormGroup>
            <Label for='vaccine'>Vaccine Sub Category</Label>
            <Select
              id='vaccineSubCategory'
              options={vaccineOptions}
              value={vaccine}
              onChange={(selectedOption) => setVaccine(selectedOption)}
              placeholder="Search Vaccines"
            />
          </FormGroup>
          <FormGroup>
            <Label for='vaccinationCharges'>Vaccination Charges</Label>
            <Input
              type='text'
              name='vaccinationCharges'
              id='vaccinationCharges'
              value={vaccinationCharges}
              disabled={true}
            />
          </FormGroup>
          <Row form>
            <Col md={6}>
              <FormGroup>
                <Label for='appointmentDate'>Appointment Date</Label>
                <Input
                  type='date'
                  name='appointmentDate'
                  id='appointmentDate'
                  value={appointmentDate}
                  onChange={(e) => setAppointmentDate(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
              <Label for='appointmentTime'>Appointment Time</Label>
              <Input
                type='time'
                name='appointmentTime'
                id='appointmentTime'
                value={appointmentTime}
                onChange={(e) => setAppointmentTime(e.target.value)}
              />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for='rescheduleReason'>Reschedule Reason</Label>
            <Input
              type='select'
              name='rescheduleReason'
              id='rescheduleReason'
              value={rescheduleReason}
              onChange={(e) => setRescheduleReason(e.target.value)}
            >
              <option value=''>Select a reason</option>
              <option value='change_in_address'>Change in centre address</option>
              <option value='personal_reasons'>Personal reasons</option>
              <option value='other'>Other</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for='remarks'>Remarks</Label>
            <Input
              type='textarea'
              name='remarks'
              id='remarks'
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color='primary' onClick={handleSubmit} disabled={loading}>
          {loading ? 'Loading...' : 'Shift Appointment'}
        </Button>
        {!loading && (
          <Button color='secondary' onClick={onClose} disabled={loading}>
            Close
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}

export default RescheduleRequests;
