import React from 'react';

import { Async } from 'react-select';
import { toast } from 'react-toastify';
import { LoadingContainer } from "../offline-consultations/table-subcomponent/StyledComponents";

import { searchLabTestForCenterByNotAdded, submitLabTestForTheCenter } from '../../services/api/lab-partners';

class AddCenterTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
        this.labTestSelectRef = React.createRef();
    }

    onSubmit = () => {
        const { partnerId, centerId } = this.props;
        const testId = this.labTestSelectRef.current.select.state.value.testId;
        if (isNaN(testId) || isNaN(centerId) || isNaN(partnerId)) {
            toast.error('Error while updating the test for the center');
            return;
        }
        this.setState({
            loading: true,
        });
        return submitLabTestForTheCenter(partnerId, centerId, testId)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    toast.success('Successfully added the test');
                    this.props.onComplete();
                    return;
                }
                throw new Error('Couldn\'t persist details for the center');
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, partnerId, centerId, testId, 'Error while updating the test for center');
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    searchTests = (val) => {
        const { partnerId, centerId } = this.props;
        if (isNaN(partnerId) || isNaN(centerId)) {
            toast.error('Error while searching for tests');
            return;
        }
        return searchLabTestForCenterByNotAdded(partnerId, centerId, val)
            .then((response) => {
                const data = response.body;
                if (data.message === 'success') {
                    return data.tests.map((t) => {
                        return {
                            label: t.testName,
                            testId: t.testId,
                        };
                    });
                }
                throw new Error('Couldn\'t fetch Tests');
            })
            .catch((err) => {
                console.error(err, partnerId, centerId, val, 'SearchTests');
                toast.error(err.message);
            })
    };


    render() {
        const { loading } = this.state;
        return (
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col">
                        <Async
                            loadOptions={this.searchTests}
                            ref={this.labTestSelectRef}
                            name="Search To Add Test"
                            placeholder='Search To Add Test'
                            isClearable={true}
                        />
                    </div>
                    <div className="col">
                        {(loading) ? (
                            <LoadingContainer/>
                        ) : (
                            <button className="btn btn-primary btn-block" onClick={this.onSubmit}>Add Test</button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default AddCenterTest;
