import React from 'react';

import { getDetails, updateDeliveryDetails } from '../../services/api/redemption-ops';

import { toast } from 'react-toastify';

import Link from 'valuelink';
import { Input } from 'valuelink/tags';

import isFunction from '../../utils/isFunction';

export default class EditDeliveryDetailsForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            pincode: '',
            patientName: '',
            consultationDate: '',
        };
    }

    getRedemptionDetails = (redemptionId) => {
        if (redemptionId) {
            return getDetails(redemptionId)
                .then((response) => {
                    console.log(response,
                        'Response from Server');
                    this.setState({
                        address: response.redemptionInfo.address || '',
                        pincode: response.redemptionInfo.pincode || '',
                        patientName: response.redemptionInfo.patientName || '',
                        consultationDate: response.redemptionInfo.consultationDateStd || '',
                    });
                })
                .catch((err) => {
                    toast.error(err.errorMessage || 'Error while fetching redemption details');
                });
        }
    };

    submitOrderDetailsForm = () => {
        const { redemptionId } = this.props;
        const data = {
            address: this.state.address,
            pincode: this.state.pincode,
            patientName: this.state.patientName,
            consultationDate: this.state.consultationDate,
        };
        return updateDeliveryDetails(redemptionId, data)
            .then(() => {
                toast.success('Successfully updated order details');
                if (this.props.onFormClose && isFunction(this.props.onFormClose)) {
                    this.props.onFormClose();
                }
            })
            .catch((err) => {
                toast.error('Error while updating order details');
            });
    };

    componentDidMount() {
        const { redemptionId } = this.props;
        this.getRedemptionDetails(redemptionId);
    }

    render() {
        const addressLink = Link.state(this, 'address');
        const pincodeLink = Link.state(this, 'pincode');
        const patientNameLink = Link.state(this, 'patientName');
        const consultationDateLink = Link.state(this, 'consultationDate');
        return (
            <div className="container-fluid">
                <div className="row mt-2">
                    <div className="col">
                        <strong>Address:</strong>
                    </div>
                    <div className="col">
                        <Input className="form-control" valueLink={addressLink} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <strong>Pincode:</strong>
                    </div>
                    <div className="col mt-2">
                        <Input className="form-control" valueLink={pincodeLink} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <strong>Patient Name:</strong>
                    </div>
                    <div className="col">
                        <Input className="form-control" valueLink={patientNameLink} />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <strong>Consultation/Delivery Date:</strong>
                    </div>
                    <div className="col">
                        <Input valueLink={consultationDateLink} type="date" />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col">
                        <button className="btn btn-block btn-primary" onClick={this.submitOrderDetailsForm}>Submit</button>
                    </div>
                    <div className="col">
                        <button className="btn btn-block btn-secondary" onClick={this.props.onFormClose}>Cancel</button>
                    </div>
                </div>
            </div>
        );
    }
}
