import React, { Component } from 'react';
import Select from 'react-select';
import { Async } from 'react-select';
import {
  Alert,
  Button,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  searchLabCenters,
  searchLabs,
  searchTest
} from '../../services/api/intimation-request';

import moment from 'moment';
import LoadingComponent from '../common/LoadingComponent';



const timeSlots = [
  { value: 2, label: '08:00:00 AM - 09:00:00 AM' },
  { value: 3, label: '09:00:00 AM - 10:00:00 AM' },
  { value: 4, label: '10:00:00 AM - 11:00:00 AM' },
  { value: 5, label: '11:00:00 AM - 12:00:00 PM' },
  { value: 6, label: '12:00:00 PM - 01:00:00 PM' },
  { value: 7, label: '01:00:00 PM - 02:00:00 PM' },
  { value: 8, label: '02:00:00 PM - 03:00:00 PM' },
  { value: 9, label: '03:00:00 PM - 04:00:00 PM' },
  { value: 10, label: '04:00:00 PM - 05:00:00 PM' },
  { value: 11, label: '05:00:00 PM - 06:00:00 PM' },
  { value: 12, label: '06:00:00 PM - 07:00:00 PM' },
];

class LabRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      radiology_partner_id: null,
      pathology_partner_id: null,
      pathology_test_id : [],
      radiology_test_id : [],
      pathology_date : null,
      radiology_center_id : null,
      labCenters : [],
      pathology_slot : null,
      radiology_date : null,
      radiology_slot : null,
      showFormWarning : false,
      alertMessage : 'None',
      loading : false,
    };
    this.LabCenterSelectRef = React.createRef();
    this.radioLabSelectRef = React.createRef();
    this.pathSlotSelectRef = React.createRef();
    this.radioTestSelectRef = React.createRef();
    this.pathLabSelectRef = React.createRef();
    this.pathTestSelectRef = React.createRef();
    this.onAlertDismiss = this.onAlertDismiss.bind(this);
  }

  toggle = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  };

  onAlertDismiss() {
    this.setState({ showFormWarning: false });
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  handleRadioDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
    this.setState({ radiology_date: dateKey});
  };

  handlePathDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
    this.setState({ pathology_date:dateKey });
  };

  handleRadioSlotIdChange = (value) => {
    const slotId = value.value;
    console.log(slotId);
    if (!slotId) {
      return;
    }
    this.setState({ radiology_slot :slotId });
  };
  
  handlePathSlotIdChange = (value) => {
    const slotId = value.value;
    if (!slotId) {
      return;
    }
    this.setState({ pathology_slot:slotId });
  };



  placeRequest = () => {
    const {
      pathology_partner_id,
      pathology_test_id,
      pathology_date,
      pathology_slot,
      radiology_partner_id,
      radiology_test_id,
      radiology_date,
      radiology_slot,
      radiology_center_id,
    } = this.state

    if(pathology_test_id.length)
    {
      console.log(pathology_partner_id, pathology_test_id, pathology_date, pathology_slot, "data1");
      if(!pathology_partner_id || !pathology_date || !pathology_slot)
      {
        this.setState({showFormWarning: true, alertMessage: 'Please Fill Complete Pathology Data' });
        return;
      }
    }
    if(radiology_test_id.length)
    {
      if(!radiology_partner_id || !radiology_date || !radiology_slot || !radiology_center_id)
      {
        console.log(radiology_partner_id, radiology_test_id, radiology_date, radiology_slot,radiology_center_id, "data2");
        this.setState({showFormWarning: true, alertMessage: 'Please Fill Complete Radiology Data' });
        return;
      }
    }
    if(!pathology_test_id.length && !radiology_test_id.length)
    {
      this.setState({showFormWarning: true, alertMessage: 'Please Fill least one test' });
      return;
    }
    this.setState({
      loading: true,
    });
      this.props
      .submit(pathology_partner_id,
        pathology_test_id,
        pathology_date,
        pathology_slot,
        radiology_partner_id,
        radiology_test_id,
        radiology_date,
        radiology_slot,
        radiology_center_id)
      .then(() => true)
      .finally(() => {
        this.setState({ loading: false });
      });
  };


  //radiology

  fetchRadiologyLabs = (val) => {
    this.setState({ verticalLoading: true });
    return searchLabs(val,"radiology")
      .then((response) => {
        console.log("Labs response", response);
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value: element.id, label: element.opsDisplayName });
        });
        return arr;
      })
  };

  fetchLabCenter = (val) => {
    const { radiology_partner_id } = this.state;
    console.log("val========", radiology_partner_id);
    if(!radiology_partner_id)
    {
      return Promise.resolve([{value:null, label:"Please Select Radiology Lab"}]);
    }
    return searchLabCenters(val, radiology_partner_id)
      .then((response) => {
        console.log("Lab Center response", response);
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value: element.id, label: element.address });
        });
        this.setState({ labCenters: arr });
        return arr;
      })
  };

  handleLabCenterChange = (value) => {
    const centerId = value.value;
    if (!centerId) {
      return;
    }
    this.setState({ radiology_center_id : centerId });
  };

  handleRadiologyChange = (event) => {
    const radiology_partner_id = event.value;
    if (!radiology_partner_id) {
      return;
    }
    this.setState({ radiology_partner_id },()=> {
      this.fetchLabCenter();
    })
  };

  fetchRadiologyTest = (val) => {
    const { radiology_partner_id } = this.state;
    console.log("fetchRadiologyTest", val, radiology_partner_id);
    if(!radiology_partner_id){
      console.log("in if")
      return Promise.resolve( [{ value : null, label: "Please select partner" }])
    }
    console.log("afterRadio")
    return searchTest(radiology_partner_id, val)
      .then((response) => {
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value:{id: element.testId, label: element.testName}, label: element.testName });
        });
        console.log(arr)
        return arr;
      })
  };

  handleRadiologyTestChange = (event) => {
    if(!event.value)
    {
      return ;
    }
    const radiology_test_id = event.value.id;
    if (!radiology_test_id) {
      return Promise.resolve();
    }
    const radioTest =  this.state.radiology_test_id;
    radioTest.push({id: radiology_test_id ,label : event.value.label, type:"test"})
    this.setState({ radiology_test_id: radioTest });
  };
  
  removeRadiologyTest = (id) => {
    const { radiology_test_id } = this.state;
    const newRadiology_test_id = radiology_test_id.filter(
      (ele) => ele.id !== id
    );
    this.setState({ radiology_test_id: newRadiology_test_id });
  };

  //pathology

  fetchPathologyLabs = (val) => {
    this.setState({ verticalLoading: true });
    return searchLabs(val,"pathology")
      .then((response) => {
        console.log("Labs response", response);
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value: element.id, label: element.opsDisplayName });
        });
        return arr;
      })
  };

  handlePathologyChange = (event) => {
    const pathology_partner_id = event.value;
    if (!pathology_partner_id) {
      return;
    }
    this.setState({ pathology_partner_id });
  };

  fetchPathologyTest = (val) => {
    const { pathology_partner_id } = this.state;
    if(!pathology_partner_id){
      return Promise.resolve([{ value: null, label: "Please select partner" }]);
    }
    return searchTest(pathology_partner_id, val)
      .then((response) => {
        const arr = []; 
        response.data.forEach((element) => {
          arr.push({ value:{id: element.testId, label: element.testName}, label: element.testName });
        });
        return arr;
      })
  };

  handlePathologyTestChange = (event) => {
    if(!event.value){
      return;
    }
    const pathology_test_id = event.value.id;
    if (!pathology_test_id) {
      return;
    }
    const pathTest =  this.state.pathology_test_id;
    pathTest.push({id: pathology_test_id ,label : event.value.label, type:"test"})
    this.setState({ pathology_test_id : pathTest });
  };

  removePathologyTest = (id) => {
    const { pathology_test_id } = this.state;
    const newPathology_test_id = pathology_test_id.filter(
      (ele) => ele.id !== id
    );
    this.setState({ pathology_test_id: newPathology_test_id });
  };


  render() {
    const { loading } = this.state;
    return (
      <div>
        <form>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '450px' }}>
              <h4 style={{textAlign:"center"}}>Radiology Tests</h4>
              <br></br>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>Select Partner:</Label>
                  </Col>
                  <Col>
                    <Async
                      ref={this.radioLabSelectRef}
                      loadOptions={this.fetchRadiologyLabs}
                      onChange = {this.handleRadiologyChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>Search Partner Center :</Label>
                  </Col>
                  <Col>
                    <Async
                      defaultOptions = {this.state.labCenters}
                      loadOptions={this.fetchLabCenter}
                      ref={this.LabCenterSelectRef}
                      onChange = {this.handleLabCenterChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>App. Date:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="date"
                      onChange={this.handleRadioDateChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>App Time:</Label>
                  </Col>
                  <Col>
                    <Select
                      ref={this.RadioSlotSelectRef}
                      onChange={this.handleRadioSlotIdChange}
                      isSearchable={false}
                      options={timeSlots}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>Select Tests:</Label>
                  </Col>
                  <Col>
                    <Async
                      ref={this.radioTestSelectRef}
                      loadOptions={this.fetchRadiologyTest}
                      onChange = {this.handleRadiologyTestChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>Selected Tests:</Label>
                  </Col>
                  <Col>
                    <div>
                      {this.state.radiology_test_id.length?this.state.radiology_test_id.map((ele) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>{ele.label}</div>
                            <div>
                              <Button
                                style={{height:"7px"}}
                                color="danger"
                                onClick={() => this.removeRadiologyTest(ele.id)}
                              >
                                -
                              </Button>
                            </div>
                          </div>
                        );
                      }):<div>No Test Selected</div>}
                    </div>
                  </Col>
                </Row>
              </FormGroup>
            </div>
            <div style={{width:"500px"}}>
              <h4 style={{textAlign:"center"}}>Pathology Tests</h4>
              <br></br>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>Select Partner:</Label>
                  </Col>
                  <Col>
                    <Async
                      ref={this.radioLabSelectRef}
                      loadOptions={this.fetchPathologyLabs}
                      onChange = {this.handlePathologyChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>App. Date:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="date"
                      onChange={this.handlePathDateChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>App. Time:</Label>
                  </Col>
                  <Col>
                    <Select
                      ref={this.pathSlotSelectRef}
                      isSearchable={false}
                      options={timeSlots}
                      onChange={this.handlePathSlotIdChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>Select Tests:</Label>
                  </Col>
                  <Col>
                    <Async
                      ref={this.pathTestSelectRef}
                      loadOptions={this.fetchPathologyTest}
                      onChange = {this.handlePathologyTestChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-4">
                    <Label>Selected Tests:</Label>
                  </Col>
                  <Col>
                    <div>
                      {this.state.pathology_test_id.length ? this.state.pathology_test_id.map((ele) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div>{ele.label}</div>
                            <div>
                              <Button
                                style={{height:"7px"}}
                                color="danger"
                                onClick={() => this.removePathologyTest(ele.id)}
                              >
                                -
                              </Button>
                            </div>
                          </div>
                        );
                      }):<div>No Test Selected</div>}
                    </div>
                  </Col>
                </Row>
              </FormGroup>
            </div>
          </div>
        </form>
        <Alert
          color="warning"
          isOpen={this.state.showFormWarning}
          toggle={this.onAlertDismiss}
        >
          {this.state.alertMessage}
        </Alert>

        {loading ? (
          <LoadingComponent />
        ) : (
          <Button color="primary" onClick={this.placeRequest}>
            Place Request
          </Button>
        )}
        <Button color="secondary" onClick={this.props.closeModal}>
          Close
        </Button>
      </div>
    );
  }
}

export default LabRequest;
