import React, { Component } from 'react';
import { OuterComponent } from '../common/modal/StyledComponent';
import { Button, Form, FormGroup, Label, Row, Col, Input } from 'reactstrap';
import {
  getVerticals,
} from '../../services/api/offline-consult';
import {
  fetchLanguages,
} from '../../services/api/doctor';
import { getOnlineConsultDoc } from '../../services/api/online-consultation-booking';
import LoadingComponent from '../common/LoadingComponent';
import Select from 'react-select';
import { Async } from 'react-select';
import debounce from 'lodash.debounce';
import { toast } from 'react-toastify';

export default class PlaceConsultRequestForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verticalLoading: false,
      verticalsResult: [],
      languageLoading: false,
      languagesResult: [],
      doctorsLoading: false,
      doctorId: '',
      sendSms: 0,
      requestedCallType: 'audio',
    };
    this.verticalSelectRef = React.createRef();
    this.languageSelectRef = React.createRef();
    this.doctorSelectRef = React.createRef();
    this.relativeSelectRef = React.createRef();

    this.debouncedLoadOptions = debounce(this._searchDoctors, 500);
  }

  componentDidMount() {
    this.fetchVerticals();
    this.getLanguages();
  }

  fetchVerticals = () => {
    this.setState({ verticalLoading: true });
    getVerticals()
      .then((response) => {
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value: element.verticalId, label: element.verticalName });
        });
        this.setState({ verticalsResult: arr });
      })
      .finally(() => {
        this.setState({ verticalLoading: false });
      });
  };

  getLanguages = () => {
    this.setState({ languageLoading: true });
    const arr = [{value: null, label: 'Any'}];
    fetchLanguages()
      .then((response) => {
        response.data.forEach((element) => {
          arr.push({ value: element.value, label: element.label });
        });
      })
      .finally(() => {
        this.setState({ languageLoading: false, languagesResult: arr });
      });
  };

  _searchDoctors = (val) => {
    console.log(val, 'inside search doc function[val]');
    const verticalId = this.verticalSelectRef.current.state.value
      ? this.verticalSelectRef.current.state.value.value
      : null;
    const consultationLanguage = this.languageSelectRef.current.state.value
      ? this.languageSelectRef.current.state.value.value
      : null;
    if (!verticalId) {
      toast.error('Please select a vertical first');
      return [];
    }
    this.setState({ doctorsLoading :true});
    return getOnlineConsultDoc(val, verticalId, consultationLanguage)
      .then((response) => {
        return response.data || [];
      })
      .then((result) =>{
        return result.map((ele) => {
          return {
            label: `${ele.docName} - ${ele.docId}`,
            value: ele.docId,
          };
        });
      })
      .catch((err) => {
        console.log(err);
        return [];
      })
      .finally(() => {
        this.setState({doctorsLoading:false});
      })
  };

  onSubmit = () => {
    console.log(
      '[new user form in PlaceBookConsultRequest component]',
    );
    this.props.placeSpecialistConsultRequest({
      doctorId:this.state.doctorId,
      relativeId:this.relativeSelectRef.current.state.value.value,
      prescriptionSms: this.state.sendSms ? 1 : this.state.sendSms,
      requestedCallType: this.state.requestedCallType,
      consultationLanguage:  this.languageSelectRef.current.state.value.value || null
    });
  };

  handleRequestedCallType = (type) => {
    this.setState({
      requestedCallType: type
    });
  };


  render() {
    console.log(this.props, this.state, 'render');

    return (
      <OuterComponent>
        <Form>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label for="verticalSelect">Select Vertical</Label>
              </Col>
              <Col>
                <Select
                  name="verticalSelect"
                  id="verticalSelect"
                  isSearchable={true}
                  ref={this.verticalSelectRef}
                  options={this.state.verticalsResult}
                  isLoading={this.state.verticalLoading}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
          <Row>
              <Col className="col-3">
                <Label for="languageSelect">Select Language</Label>
              </Col>
              <Col>
                <Select
                  name="languageSelect"
                  id="languageSelect"
                  isSearchable={true}
                  ref={this.languageSelectRef}
                  options={this.state.languagesResult}
                  isLoading={this.state.languageLoading}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label for="doctorSearch">Search Doctor:</Label>
              </Col>
              <Col>
                <Async
                  name="doctorSearch"
                  id="doctorSearch"
                  loadOptions={this.debouncedLoadOptions}
                  ref={this.doctorSelectRef}
                  onChange={(e) => this.setState({ doctorId: e.value })}
                  isLoading={this.state.doctorsLoading}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label for="userPhoneNumber">Phone Number</Label>
              </Col>
              <Col>
                <Input
                  name="userPhoneNumber"
                  id="userPhoneNumber"
                  type="number"
                  value={this.props.phone ? this.props.phone : null}
                  onChange={(e) => this.props.getUserDetails(e.target.value)}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label for="userName">User Name</Label>
              </Col>
              <Col>
                <Input
                  name="userName"
                  id="userName"
                  type="text"
                  value={`${this.props.userId}-${this.props.userName}`}
                  readOnly
                  // disabled={true}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label for="patientSelect">Relative</Label>
              </Col>
              <Col>
                <Row className="my-2">
                  <Col>
                    <Select
                      name="patientSelect"
                      id="patientSelect"
                      style={{ width: `100%` }}
                      isSearchable={false}
                      ref={this.relativeSelectRef}
                      defaultValue={
                        this.props.relativeResult.length > 0 &&
                        this.props.relativeResult[0]
                      }
                      options={this.props.relativeResult}
                      isLoading={this.props.relativeLoading}
                    />
                  </Col>
                </Row>
                <Row className="my-2">
                  <Col>
                    <Button
                      disabled={
                        !(this.props.userId && this.props.userName) ||
                        !this.props.canAddDependents
                      }
                      onClick={() => this.props.addDependentModalOpen()}
                    >
                      Add Dependent
                    </Button>
                  </Col>
                </Row>
                <Row className="my-2 mx-2">
                  <div style={{display: 'flex'}}>
                    <Col>
                      <Input
                            name='callType'
                            id='callType' 
                            type='checkbox'
                            value={this.state.sendSms}
                            checked={this.state.requestedCallType === 'audio'}
                            onChange={() => this.handleRequestedCallType('audio')}
                          />
                      <Label>Audio</Label> 
                    </Col>
                    <Col>
                      <Input
                            name='callType'
                            id='callType' 
                            type='checkbox'
                            value={this.state.sendSms}
                            checked={this.state.requestedCallType === 'video'}
                            onChange={() => this.handleRequestedCallType('video')}
                          />
                      <Label>Video</Label> 
                    </Col>
                  </div>
                </Row>
                <Row className="my-2 mx-2">
                  <Col>
                    <Input
                          name='sms'
                          id='sms' 
                          type='checkbox'
                          value={this.state.sendSms}
                          onChange={() => this.setState({sendSms: !this.state.sendSms})}
                        />
                     <Label>Send prescription sms</Label> 
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>
        </Form>
        <div className="flex flex-row justify-end">
          <div className="flex flex-col m-3">
            {this.props.loading ? (
              <LoadingComponent />
            ) : (
              <Button color="primary" onClick={this.onSubmit}>
                Book Consultation
              </Button>
            )}
          </div>
          <div className="flex flex-col m-3">
            <Button color="secondary" onClick={this.props.closeModal}>
              Close
            </Button>
          </div>
        </div>
      </OuterComponent>
    );
  }
}
