import React, { Component } from "react";

class LegendContent extends Component {

    render() {
        return (
            <div>
                <h3>Appointments</h3>
                <p>The columns have the following meanings: </p>
                <ul>
                    <li>
                        <b>RID:</b> Request ID - It can be used to communicate with the technical team in case of any escalations.
                    </li>
                    <br/>
                    <li>
                        <b>Patient:</b> The person whom the consultation has been booked for. If a relation is specified next to the name of the patient, that means the request was placed by a user for his/her relative.
                    </li>
                    <br/>
                    <li>
                        <b>Doctor:</b> The doctor for whom the consultation was booked for.
                    </li>
                    <br/>
                    <li>
                        <b>Center:</b> The hospital/clinic where the appointment is going to take place.
                    </li>
                    <br/>
                    <li>
                        <b>Req. Time:</b> The date and time at which the request was placed by the user.
                    </li>
                    <br/>
                    <li>
                        <b>Appt. Time:</b> This column will show the tentative appointment date and time. For a pending appointment this will show the date time selected by the user. For a confirmed appointment, this will show the confirmed consultation date and time.
                    </li>
                    <br/>
                    <li>
                        <b>Appt. Status:</b> This tells the current status of the offline request. The possible statuses are:
                        <ol>
                            <li>
                                <b>Apt. Pending: </b> The appointment is being processed. Please wait as someone will call you back shortly.
                            </li>
                            <li>
                                <b>Payment Pending: </b> The appointment timings have been confirmed by operations' team. You will see the amount reflected on your app. You'll need to make the payment before you leave for your consultation. After you have made the payment, your appointment will get confirmed and you may go for your appointment at the scheduled time. (Appointment Time)
                            </li>
                            <li>
                                <b>Confirmed: </b> The appointment has been confirmed by the operations' team and you may go to your appointment at the scheduled Appointment Time as mentioned on the app.
                            </li>
                            <li>
                                <b>Rx. Pending: </b> The appointment is marked as over and you will now have to upload your prescription that you received from the consultation. You'll need to upload your prescription before booking the next appointment.
                            </li>
                            <li>
                                <b>Rx. Uploaded: </b> I can see that you have uploaded your prescription and that you can book another consultation.
                            </li>
                            <li>
                                <b>Cancelled: </b> There are various cancelled statuses available. These are the requests which had been cancelled for the specied reasons.
                            </li>
                        </ol>
                    </li>
                    <br/>
                    <li>
                        <b>Comments: </b> This section is to be used by the operations' team for internal commenting system. Operations' Agents can post important updates and keep others informed of the current status of the consultation. The number of comments are shown next to the icon. Upon clicking the chat button, the comment history section is shown where the agent can post an update too.
                    </li>
                </ul>
            </div>
        );
    }
}

export default LegendContent;
