import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from "../layout/NavBar";
import { getTranscriptionUserDetails, changePartnerForCart, getUserCart, getAvailablePartnersForCart, getAvailablePartnersForCartByCartId, saveCartRemark } from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import LabCart from './LabCart';
import LabRxPastConsults from './LabRxPastConsults';
import LabRxNotServiceable from './LabRxNotServiceable';
import LabRxReadyCart from './LabRxReadyCart';
import LabRxNewTest from './LabRxNewTest';
import { withRouter } from 'react-router-dom';
import LabRxConvertCartType from './LabRxConvertCartType';
import CreateLabOrderV2 from './CreateLabOrderV2';

import ConfirmAddingManualPartner from './ConfirmAddingManualPartner';
import moment from 'moment/moment';
import {setCartInfo, setSelectedPartnerForNewTest} from "../../actions";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class LabRxRequestDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userInfo: {},
            dependentDetails: {},
            cartDetails: {
                prescriptionFiles: [],
            },
            cartItemsResult: {},
            cartId: null,
            userId: null,
            requestId: null,
            openNewTestModal: false,
            labPartners: [],
            remarks: '',
            openConfirmManualPartner: 0,
            partnerListDropDown: false,
            totalObject: {},
        };
    }

    componentDidMount() {
        const params = new URLSearchParams(window.location.search);
        const userId = params.get('userId');
        const cartId = params.get('cartId');
        const requestId = params.get('requestId');
        this.setState({
            requestId,
            cartId,
            userId,
        }, this.loadCart);
    }

    makeDefaultState = () => ({
        cartItemsResult: {
            itemsList:[],
        },
    });

    loadCart = () => {
        return this.getUserDetails()
            .then(() => getAvailablePartnersForCartByCartId(this.state.cartId))
            .then((result) => {
                console.log(result, "Result from API");
                if (result.body.message === 'success') {
                    this.setState({
                        labPartners: result.body.partners,
                    });
                    return Promise.resolve();
                } else {
                    toast.error('Error in getting partners for this cart');
                    return Promise.reject();
                }
            })
            .then(() => getUserCart(this.state.userId, this.state.cartId))
            .then((response) => {
                const totalObject = {labName: "TOTAL", mrp:0, cost:0, postDiscountPrice:0};
                response.body.cartItemsResult.itemsList = response.body.cartItemsResult.itemsList.map((i) => {
                    i.label = i.labName;
                    i.value = i.labId;
                    totalObject.cost += parseInt(i.cost);
                    totalObject.mrp += parseInt(i.mrp); 
                    totalObject.postDiscountPrice += parseInt(i.postDiscountPrice);
                    return i;
                });

                this.setState({totalObject});
                // this.setState({
                //     cartItemsResult: response.body.cartItemsResult,
                // });
                this.props.dispatch(setCartInfo(response.body.cartItemsResult));
                // this.props.refresh();
            })
            .catch((err) => {
                if (err.response && err.response.data && err.response.data.errorMessage && err.response.data.errorMessage === 'No items in the cart') {
                    this.props.dispatch(setCartInfo(this.makeDefaultState().cartItemsResult));
                } else {
                    console.error('Error in fetching users cart', err);
                    toast.error('Error in cart or partner details');
                }
            });
    };

    getUserDetails = () => {
        return getTranscriptionUserDetails(this.state.userId, this.state.requestId)
            .then((response) => {
                console.log(response);
                const prescriptionFiles = (response.body.cartDetails.files) ? response.body.cartDetails.files.split(',') : [];
                const cartDetails = response.body.cartDetails;
                cartDetails.prescriptionFiles = prescriptionFiles;
                this.setState({
                    userInfo: response.body.userInfo,
                    cartDetails: cartDetails,
                    dependentDetails: response.body.dependentDetails || {},
                    remarks: cartDetails.remarks,
                });
                return Promise.resolve();
            })
            .catch((err) => {
                console.error('Error in loading user details', err);
                toast.error(`Unable to load User details`);
                return Promise.reject(err);
            });
    };

    openNewTestModal = () => {
        this.setState({
            openNewTestModal: true,
        });
    };

    saveRemarks = () => {
        saveCartRemark(this.state.cartId, this.state.remarks)
            .then((response) => {
                if (response.body.message === 'success') {
                    toast.success('Remark Saved');
                } else {
                    toast.error(`Unable to save remarks`);
                }
            })
            .catch((err) => {
                console.error('Error in saving remarks', err);
                toast.error(`Unable to save remarks`);
            });
    };

    togglePartnerListDropdown = () => {
        this.setState({ partnerListDropDown: !this.state.partnerListDropDown });
    };

    renderPartnerList = () => {
        if (this.state.labPartners.length === 0) {
            return '';
        }
        console.log('List of partners', this.state.labPartners);
        const availablePartners = this.state.labPartners.filter(p => p.available);
        let selected = this.state.cartDetails.partnerId;
        this.props.dispatch(setSelectedPartnerForNewTest(selected));
        console.log('Setting selected partner id as', selected);
        let selectedPartnerName = this.state.labPartners.find((o) => o.id === selected);
        if (!selectedPartnerName) {
            selectedPartnerName = 'Not Selected';
        } else {
            selectedPartnerName = selectedPartnerName.name;
        }
        return (
            <Dropdown isOpen={this.state.partnerListDropDown} toggle={this.togglePartnerListDropdown}>
                <DropdownToggle caret>
                    {selectedPartnerName}
                </DropdownToggle>
                <DropdownMenu>
                    {this.state.labPartners.map((p) => <DropdownItem active={selected === p.id} onClick={() => this.confirmAddingManualPartner(p)} disabled={!p.available} key={p.id}>{p.name}</DropdownItem>)}
                </DropdownMenu>
            </Dropdown>
        );
    };

    confirmAddingManualPartner = (p) => {
        // Show a modal to confirm that Manual partner needs to be shown to the user
        // This partner is not available to other users and will only be shown to this cart
        this.setState({
            openConfirmManualPartner: p.id,
        });
    };

    onPartnerChanged = (e) => {
        this.confirmAddingManualPartner(e);

        console.log(e.target.value, 'New partner selected');
        // Change the partner in the cart
        changePartnerForCart(this.state.cartId, e.target.value)
            .then((response) => {
                if (response.body.message === 'success') {
                    toast.success(`Partner Changed`);
                    this.loadCart();
                    this.props.dispatch(setSelectedPartnerForNewTest(e.target.value));
                } else {
                    toast.error(`Unable to update partner`);
                }
            })
            .catch((err) => {
                console.error('Error in changing partner', err);
                toast.error(`Unable to update partner`);
            });
    };


    renderPrescriptionFiles = () => this.state.cartDetails.prescriptionFiles.map((f, index) => <a className="m-1 p-2 badge badge-primary" key={index} href={f} target="_blank">{index+1}</a>);;

    render() {
        const oldDetailsLink = `${window.location.origin}/lab-order-details?cartId=${this.state.cartId}&requestId=${this.state.requestId}&userId=${this.state.userId}`;
        return (
            <div className="container-fluid">
                <div className="row">
                    <NavBar />
                </div>
                <div className="row mt-5 pt-1">
                    <div className="col">

                        <div>
                            <h2 className="pl-1">Rx Request - [{this.state.cartDetails.testsType}] - {this.state.cartDetails.status}</h2>
                        </div>

                        <div className="row d-flex justify-content-around p-1">
                            <LabRxPastConsults userId={this.state.userId} cartId={this.state.cartId} loadCart={this.loadCart} userInfo={this.state.userInfo} requestId={this.state.requestId}/>
                            <LabRxReadyCart loadCart={this.loadCart} requestId={this.state.requestId} disabled={this.state.cartDetails.billGeneratedOn !== null}/>
                            {/*<LabRxNotServiceable requestId={this.state.requestId} loadCart={this.loadCart}/>*/}
                            <CreateLabOrderV2 cartId={this.state.cartId} userId={this.state.userId}/>
                            {/*<button className="btn btn-info">Create New Order</button>*/}
                        </div>

                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th scope="col">User Name</th>
                                <th scope="col">Email</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Sponsor Name</th>
                                <th scope="col">Policy Holder Name</th>
                                <th scope="col">Dependent Names</th>
                                <th scope="col">Dependent Relations</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{this.state.userInfo.userName}</td>
                                <td>{this.state.userInfo.email}</td>
                                <td>{this.state.userInfo.phone}</td>
                                <td>{this.state.userInfo.sponsorName}</td>
                                <td>{this.state.dependentDetails.holderName}</td>
                                <td>{this.state.dependentDetails.dependentName}</td>
                                <td>{this.state.dependentDetails.relation}</td>
                            </tr>
                            </tbody>
                        </table>

                        <table className="table">
                            <thead className="thead-light">
                            <tr>
                                <th scope="col">Patient Name</th>
                                <th scope="col">Gender</th>
                                <th scope="col">Age</th>
                                <th scope="col">Phone</th>
                                <th scope="col">Address</th>
                                <th scope="col">Pickup Slot</th>
                                <th scope="col">Prescription File</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{this.state.cartDetails.name}</td>
                                <td>{this.state.cartDetails.gender}</td>
                                <td>{this.state.cartDetails.age}</td>
                                <td>{this.state.cartDetails.phone}</td>
                                <td>{this.state.cartDetails.address} | {this.state.cartDetails.locality} {this.state.cartDetails.pincode}</td>
                                <td>{this.state.cartDetails.slotTime} | {moment(this.state.cartDetails.collectionDate).format('Do MMM')}</td>
                                <td>{this.renderPrescriptionFiles()}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        <div className="form-group">
                            <label htmlFor="exampleFormControlTextarea1" className="font-weight-bolder">Remarks</label>
                            <textarea value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value})} className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>
                    <div className="col-5 d-flex align-items-center">
                        <button className="btn btn-success" onClick={this.saveRemarks}>Save Remarks</button>
                    </div>
                </div>
                <div className="row align-items-center">
                    <LabRxNewTest loadCart={this.loadCart} partners={this.state.labPartners} testType={this.state.cartDetails.testsType} userId={this.state.userId} cartId={this.state.cartId} open={this.state.openNewTestModal} close={() => this.setState({
                        openNewTestModal: false,
                    })}/>

                    <div className="col-auto">
                        <div className="form-inline">
                            <label className="mr-3 font-weight-bolder" htmlFor="inputPartner" style={{fontSize: "20px"}}>Select Partner</label>
                            {this.renderPartnerList()}
                            <LabRxConvertCartType disabled={this.state.cartDetails.testsType === 'radiology'} cartId={this.state.cartId} userId={this.state.userId} requestId={this.state.requestId} loadCart={this.loadCart}/>
                            <ConfirmAddingManualPartner loadCart={this.loadCart} cartId={this.state.cartId} partnerId={this.state.openConfirmManualPartner}/>
                            <button className="ml-1 btn btn-success" onClick={() => this.props.history.push('/lab-partner-details/new')}>Add New Lab</button>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <LabCart userId={this.state.userId} cartId={this.state.cartId} testType={this.state.cartDetails.testsType} openNewTestModal={this.openNewTestModal} loadCart={this.loadCart} add={true} edit={false} delete={true} manualPartnerId={this.state.cartDetails.manualPartnerId} totalObject={this.state.totalObject}/>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withRouter(connect(
    mapStateToProps,
)(LabRxRequestDetails));





