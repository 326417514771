import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from '../layout/NavBar';
import { FaSpinner, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import {
  getPartnerDetailsApi,
  updateFulfilmentDetails,
  uploadFile,
} from '../../services/api/lab-partners';
import { toast } from 'react-toastify';
import moment from 'moment';
import Link from 'valuelink';
import { Input, Select } from 'valuelink/lib/tags';
import Modal from '../common/Modal';

class LabPartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      name: '',
      opsDisplayName: '',
      type: 'pathology',
      partnerInfo: {
        ccEmail: [],
      },
      preferredPayment: 'Bank Account',
      payeeName: '',
      accountNumber: '',
      accountType: '',
      ifsc: '',
      upiPhone: '',
      upiAddress: '',
      paymentCycle: 'before',
      visitSpoc: '',
      spocName: '',
      spocEmail: '',
      spocPhone: '',
      remarks: '',
      imageUrl: '',
      amhi: {
        status: 0,
        errorMessage: '',
      },
      acko: {
        status: 0,
        errorMessage: '',
      },
      ackocopay: {
        status: 0,
        errorMessage: '',
      },
      suez: {
        status: 0,
        errorMessage: '',
      },
      suezcopay: {
        status: 0,
        errorMessage: '',
      },
      abhi: {
        status: 0,
        errorMessage: '',
      },
      abhicopay: {
        status: 0,
        errorMessage: '',
      },
      visitnonopd: {
        status: 0,
        errorMessage: '',
      },
      showLoadingModal: false,
      saved: false,
    };
  }

  componentDidMount = () => {
    const splitPathName = window.location.pathname.split('/');
    const partnerId = splitPathName[splitPathName.length - 1];
    console.log(window.location.pathname);
    this.getPartnerDetails(partnerId);
  };

  getPartnerDetails = (partnerId) => {
    getPartnerDetailsApi(partnerId)
      .then((response) => {
        if (response.body.message === 'success') {
          const details = response.body.partnerDetails;
          details.partnerInfo = JSON.parse(details.partnerInfo);
          console.log(details, 'partner details');
          this.setState({ imageUrl: details.imageUrl });
          if (details.available === false) {
            this.setState({
              id: details.id,
              name: details.name,
              opsDisplayName: details.opsDisplayName,
              type: details.type,
              partnerInfo: details.partnerInfo,
            });
          } else {
            this.setState(details);
          }
        } else {
          toast.error(`Error in fetching details of this partner`);
        }
      })
      .catch((err) => {
        toast.error(`Error in listing all partners`, err);
      });
  };

  renderFile = () => {
        console.log(this.state.imageUrl, 'imageUrl');
            return (
                    <img src={this.state.imageUrl} alt="Partner-logo" width={100} />
            );
    };

  onFileSelect = (e) => {
    const splitPathName = window.location.pathname.split('/');
    const partnerId = splitPathName[splitPathName.length - 1];
    console.log(e.target.files, 'File selected');
    
    uploadFile(e.target.files[0], partnerId)
      .then((response) => {
        console.log(response, 'Response from uploading file')
        if(response.message === 'success') {
            toast.success('File uploaded');
        } else {
            toast.error('Error in uploading file');
        }
      })
      .catch((err) => {
          toast.error('Error in uploading file');
          console.error('Error', err);
      });
  }

  validateIndiaPhone = (phone) => {
    const reg = /^[6789]\d{9}$/;
    return reg.test(phone);
  };

  validateName = (name) => {
    // https://stackoverflow.com/a/9436948/1482108
    if (!(typeof name === 'string' || name instanceof String)) {
      return false;
    }
    if (name.length <= 1) {
      return false;
    }
    return true;
  };

  submit = () => {
    console.log(this.state);
    const {
      id,
      preferredPayment,
      payeeName,
      accountNumber,
      accountType,
      ifsc,
      upiPhone,
      upiAddress,
      paymentCycle,
      visitSpoc,
      spocName,
      spocEmail,
      spocPhone,
      remarks,
    } = this.state;

    if (
      !(
        this.validateName(spocName) &&
        this.validateName(payeeName) &&
        this.validateIndiaPhone(spocPhone)
      )
    ) {
      toast.error('Please fill in all details');
      return;
    }
    this.setState({
      showLoadingModal: true,
    });
    updateFulfilmentDetails(
      {
        fulfilments: {
          upiPhone,
          spocPhone,
          spocName,
          spocEmail,
          accountNumber,
          ifsc,
          upiAddress,
          payeeName,
          paymentCycle,
          accountType,
        },
        partnerId: id,
        preferredPaymentMode: preferredPayment,
        remarks,
      },
      (res) => {
        console.log(res);
        switch (res.data.payoutAccount.toLowerCase()) {
          case 'amhi':
          case 'acko':
          case 'ackocopay':
          case 'suez':
          case 'suezcopay':
          case 'abhi':
          case 'abhicopay':
          case 'visitnonopd': {
            if (res.message === 'success') {
              this.setState({
                [res.data.payoutAccount.toLowerCase()]: {
                  status: 1,
                },
              });
            } else {
              this.setState({
                [res.data.payoutAccount.toLowerCase()]: {
                  status: -1,
                  errorMessage: res.data.errorMessage || 'Something went wrong',
                },
              });
            }
            break;
          }
          default:
            break;
        }
      },
      (status) => {
        console.log(status);
        this.setState({ saved: true });
        const {
          amhi,
          acko,
          ackocopay,
          suez,
          suezcopay,
          abhi,
          abhicopay,
          visitnonopd,
        } = this.state;
        if (
          amhi.status === 1 &&
          acko.status === 1 &&
          ackocopay.status === 1 &&
          suez.status === 1 &&
          suezcopay.status === 1 &&
          abhi.status === 1 &&
          abhicopay.status === 1 &&
          visitnonopd.status === 1
        ) {
          // All have saved successfully. Set timeout to close modal
          toast.success(`Fulfilment details saved successfully`);
          setTimeout(() => {
            this.closeSavingModal();
          }, 3000);
        }
      },
      (err) => {
        this.setState({ saved: true });
        toast.error(`Error in saving fulfilment details`);
        console.log(err);
      }
    );
  };

  closeSavingModal = () => {
    this.setState({
      showLoadingModal: false,
      amhi: {
        status: 0,
      },
      acko: {
        status: 0,
      },
      ackocopay: {
        status: 0,
      },
    });
  };

  render() {
    const {
      preferredPayment,
      paymentCycle,
      amhi,
      acko,
      ackocopay,
      suez,
      suezcopay,
      abhi,
      abhicopay,
      visitnonopd,
      showLoadingModal,
      saved,
    } = this.state;

    const nameInputLink = Link.state(this, 'name');
    const opsDisplayNameInputLink = Link.state(this, 'opsDisplayName');
    const typeSelectLink = Link.state(this, 'type');

    const spocNameInputLink = Link.state(this, 'spocName');
    const spocEmailInputLink = Link.state(this, 'spocEmail');
    const spocPhoneInputLink = Link.state(this, 'spocPhone');

    const preferredPaymentSelectLink = Link.state(this, 'preferredPayment');
    const paymentCycleSelectLink = Link.state(this, 'paymentCycle');
    const payeeNameInputLink = Link.state(this, 'payeeName');
    const accountNumberInputLink = Link.state(this, 'accountNumber');
    const accountTypeInputLink = Link.state(this, 'accountType');
    const ifscInputLink = Link.state(this, 'ifsc');
    const upiPhoneInputLink = Link.state(this, 'upiPhone');
    const upiAddressInputLink = Link.state(this, 'upiAddress');
    const remarksInputLink = Link.state(this, 'remarks');

    return (
      <div className="container-fluid">
        <div className="row mt-5 pt-1">
          <div className="col">
            <h2 className="pl-1">Lab Partner Profile Details</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="labName">Lab Name (Shown to user)</label>
              <Input
                type="text"
                className="form-control"
                id="labName"
                readOnly
                valueLink={nameInputLink}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="labName">Lab Name (Shown to OPS)</label>
              <Input
                type="text"
                className="form-control"
                id="labName"
                readOnly
                valueLink={opsDisplayNameInputLink}
              />
            </div>

            <div className="form-group">
              <label htmlFor="testTypeSelect">Tests Type</label>
              <Select
                className="form-control"
                id="testTypeSelect"
                readOnly
                valueLink={typeSelectLink}
              >
                <option value="pathology">Pathology</option>
                <option value="radiology">Radiology</option>
              </Select>
            </div>
          </div>
          <div className="col-4">
            <div className="form-group">
              {this.renderFile()}
            </div>
            <div className="form-group">
              <div className="custom-file">
                  <input type="file" id="validatedCustomFile" onChange={this.onFileSelect} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <h2>Fulfilment Details</h2>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label htmlFor="exampleInputEmail5">SPOC Name</label>
              <Input
                type="text"
                className="form-control"
                id="exampleInputEmail5"
                valueLink={spocNameInputLink}
                aria-describedby="emailHelp"
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleFormControlSelect1">
                Preferred Payment Mode
              </label>
              <Select
                className="form-control"
                id="exampleFormControlSelect1"
                valueLink={preferredPaymentSelectLink}
              >
                <option value="Bank Account">Bank Account</option>
                <option value="UPI">UPI</option>
              </Select>
            </div>

            {preferredPayment === 'Bank Account' ? (
              <div className="form-group">
                <label htmlFor="exampleInputEmail4">Account Number</label>
                <Input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail4"
                  valueLink={accountNumberInputLink}
                  onChange={(e) =>
                    this.setState({ accountNumber: e.target.value })
                  }
                  aria-describedby="emailHelp"
                />
              </div>
            ) : null}

            {preferredPayment === 'Bank Account' ? (
              <div className="form-group">
                <label htmlFor="exampleInputEmail5">IFSC Code</label>
                <Input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail5"
                  valueLink={ifscInputLink}
                  aria-describedby="emailHelp"
                />
              </div>
            ) : null}

            <div className="form-group">
              {/*enum('before consultation', 'after consultation', 'within 2', 'within 7', 'within 15', '30 Days')*/}
              <label htmlFor="exampleFormControlSelect3">Payment Cycle</label>
              <Select
                className="form-control"
                id="exampleFormControlSelect2"
                valueLink={paymentCycleSelectLink}
              >
                <option value="before">Before order</option>
                <option value="after">After order</option>
                <option value="within 2">within 2 days</option>
                <option value="within 7">within 7 days</option>
                <option value="within 15">within 15 days</option>
                <option value="within 30">within 30 days</option>
              </Select>
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail7">Remarks</label>
              <Input
                type="email"
                className="form-control"
                id="exampleInputEmail7"
                aria-describedby="emailHelp"
                valueLink={remarksInputLink}
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="exampleInputEmail5">SPOC Phone</label>
              <Input
                type="text"
                className="form-control"
                id="exampleInputEmail5"
                valueLink={spocPhoneInputLink}
                aria-describedby="emailHelp"
              />
            </div>

            <div className="form-group">
              <label htmlFor="exampleInputEmail5">SPOC Email</label>
              <Input
                type="email"
                className="form-control"
                id="exampleInputEmail5"
                valueLink={spocEmailInputLink}
                aria-describedby="emailHelp"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">Payee Name</label>
              <Input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                valueLink={payeeNameInputLink}
                aria-describedby="emailHelp"
              />
            </div>

            {preferredPayment === 'Bank Account' ? (
              <div className="form-group">
                <label htmlFor="exampleInputEmail2">Account Type</label>
                <Input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail2"
                  valueLink={accountTypeInputLink}
                  aria-describedby="emailHelp"
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="exampleInputEmail3">Payee Phone</label>
              <Input
                type="email"
                className="form-control"
                id="exampleInputEmail3"
                valueLink={upiPhoneInputLink}
                aria-describedby="emailHelp"
              />
            </div>

            {preferredPayment === 'UPI' ? (
              <div className="form-group">
                <label htmlFor="exampleInputEmail3">UPI Address</label>
                <Input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail3"
                  valueLink={upiAddressInputLink}
                  aria-describedby="emailHelp"
                />
              </div>
            ) : null}

            <div className="form-group">
              <label htmlFor="exampleFormControlSelect2">Visit SPOC</label>
              {/*<Select className="form-control" id="exampleFormControlSelect2" value>*/}
              {/*    <option>Shashvat</option>*/}
              {/*    <option>Smriti</option>*/}
              {/*    <option>Aniket</option>*/}
              {/*</Select>*/}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-8">
            <button
              onClick={this.submit}
              className="btn btn-primary"
              style={{ width: '100%' }}
            >
              Update Fulfilment Details
            </button>
          </div>
        </div>
        <Modal
          isOpen={showLoadingModal}
          header={
            <div className="row justify-content-between p-3 pt-0 pb-0">
              <h3>Saving fulfilment details</h3>
              {saved && (
                <button
                  className="btn btn-primary"
                  onClick={this.closeSavingModal}
                >
                  <FaTimesCircle />
                </button>
              )}
            </div>
          }
        >
          <div className="row">
            <div className="col-12">
              {amhi.status === 0 ? (
                <FaSpinner className="icon-spin" />
              ) : amhi.status === 1 ? (
                <FaCheckCircle className="text-success" />
              ) : (
                <FaTimesCircle className="text-danger" />
              )}
              <span className="ml-2">
                {amhi.status === 0
                  ? 'Saving fulfilment details for AMHI'
                  : amhi.status === 1
                  ? 'Fulfilment details for AMHI saved successfully'
                  : `Error saving fulfilment details for AHMI - ${amhi.errorMessage}`}
              </span>
            </div>
            <div className="col-12">
              {acko.status === 0 ? (
                <FaSpinner className="icon-spin" />
              ) : acko.status === 1 ? (
                <FaCheckCircle className="text-success" />
              ) : (
                <FaTimesCircle className="text-danger" />
              )}
              <span className="ml-2">
                {acko.status === 0
                  ? 'Saving fulfilment details for Acko'
                  : acko.status === 1
                  ? 'Fulfilment details for Acko saved successfully'
                  : `Error saving fulfilment details for Acko - ${acko.errorMessage}`}
              </span>
            </div>
            <div className="col-12">
              {ackocopay.status === 0 ? (
                <FaSpinner className="icon-spin" />
              ) : ackocopay.status === 1 ? (
                <FaCheckCircle className="text-success" />
              ) : (
                <FaTimesCircle className="text-danger" />
              )}
              <span className="ml-2">
                {ackocopay.status === 0
                  ? 'Saving fulfilment details for ackocopay'
                  : ackocopay.status === 1
                  ? 'Fulfilment details for Acko copay saved successfully'
                  : `Error saving fulfilment details for Acko copay - ${ackocopay.errorMessage}`}
              </span>
            </div>
            <div className="col-12">
              {suez.status === 0 ? (
                <FaSpinner className="icon-spin" />
              ) : suez.status === 1 ? (
                <FaCheckCircle className="text-success" />
              ) : (
                <FaTimesCircle className="text-danger" />
              )}
              <span className="ml-2">
                {suez.status === 0
                  ? 'Saving fulfilment details for Suez'
                  : suez.status === 1
                  ? 'Fulfilment details for Suez saved successfully'
                  : `Error saving fulfilment details for Suez - ${suez.errorMessage}`}
              </span>
            </div>
            <div className="col-12">
              {suezcopay.status === 0 ? (
                <FaSpinner className="icon-spin" />
              ) : suezcopay.status === 1 ? (
                <FaCheckCircle className="text-success" />
              ) : (
                <FaTimesCircle className="text-danger" />
              )}
              <span className="ml-2">
                {suezcopay.status === 0
                  ? 'Saving fulfilment details for Suez Copay'
                  : suezcopay.status === 1
                  ? 'Fulfilment details for Suez Copay saved successfully'
                  : `Error saving fulfilment details for Suez Copay - ${suezcopay.errorMessage}`}
              </span>
            </div>
            <div className="col-12">
              {abhi.status === 0 ? (
                <FaSpinner className="icon-spin" />
              ) : abhi.status === 1 ? (
                <FaCheckCircle className="text-success" />
              ) : (
                <FaTimesCircle className="text-danger" />
              )}
              <span className="ml-2">
                {abhi.status === 0
                  ? 'Saving fulfilment details for ABHI'
                  : abhi.status === 1
                  ? 'Fulfilment details for ABHI saved successfully'
                  : `Error saving fulfilment details for ABHI - ${abhi.errorMessage}`}
              </span>
            </div>
            <div className="col-12">
              {abhicopay.status === 0 ? (
                <FaSpinner className="icon-spin" />
              ) : abhicopay.status === 1 ? (
                <FaCheckCircle className="text-success" />
              ) : (
                <FaTimesCircle className="text-danger" />
              )}
              <span className="ml-2">
                {abhicopay.status === 0
                  ? 'Saving fulfilment details for ABHI copay'
                  : abhicopay.status === 1
                  ? 'Fulfilment details for ABHI copay saved successfully'
                  : `Error saving fulfilment details for ABHI copay - ${abhicopay.errorMessage}`}
              </span>
            </div>
            <div className="col-12">
              {visitnonopd.status === 0 ? (
                <FaSpinner className="icon-spin" />
              ) : visitnonopd.status === 1 ? (
                <FaCheckCircle className="text-success" />
              ) : (
                <FaTimesCircle className="text-danger" />
              )}
              <span className="ml-2">
                {visitnonopd.status === 0
                  ? 'Saving fulfilment details for Visit non OPD'
                  : visitnonopd.status === 1
                  ? 'Fulfilment details for Visit non OPD saved successfully'
                  : `Error saving fulfilment details for Visit non OPD - ${visitnonopd.errorMessage}`}
              </span>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default withRouter(connect(mapStateToProps)(LabPartnerDetails));
