import baseRequest from './baseRequest';

export const fetchUserDetail = (params) => {
  const url = '/support/search';
  const method = 'GET';
  return baseRequest(url, method, params, null);
};

export const fetchUserAction = (params, userId) => {
  const url = `/support/search/${userId}/user`;
  const method = 'GET';
  return baseRequest(url, method, params, null);
};

export const markUserAsVip = (params) => {
  const url = `/support/vip/mark`;
  const method = 'POST';
  return baseRequest(url, method, params, null);
};

export const removeUserAsVip = (params) => {
  const url = `/support/vip/undo-mark`;
  const method = 'POST';
  return baseRequest(url, method, params, null);
};

export const refundUserPurchase = (purchaseId) => {
  const url = `/support/redemptions/refund`;
  const method = 'POST';
  const data = {
    purchaseId,
  };
  return baseRequest(url, method, null, data);
};

export const getSupportNotes = (userId) => {
  const url = `/support/notes/${userId}`;
  const method = 'GET';
  return baseRequest(url, method);
};

export const addSupportNote = (userId, notes) => {
  const url = '/support/notes/add';
  const method = 'POST';
  const data = {
    userId,
    notes,
  };
  return baseRequest(url, method, null, data);
};

export const addUserTag = (
  tag,
  type,
  medium,
  remarks,
  userId,
  detailedConcern
) => {
  const url = '/support/tags';
  const method = 'POST';
  const data = {
    userId,
    tag,
    type,
    medium,
    remarks,
    detailedConcern,
  };
  return baseRequest(url, method, null, data);
};

export const getUserTags = (userId) => {
  const url = `/support/tags?userId=${userId}`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const markResolved = (tagId) => {
  const url = '/support/tags/resolve';
  const method = 'POST';
  const data = {
    tagId,
  };
  return baseRequest(url, method, null, data);
};

export const fetchReimbursementClaimsForUser = (userId) => {
  const url = `/support/reimbursements/claims/${userId}`;
  const method = 'GET';
  return baseRequest(url, method);
};

export const removeTag = (tagId) => {
  const url = '/support/tags';
  const method = 'DELETE';
  const data = {
    tagId,
  };
  return baseRequest(url, method, null, data);
};

export const changeUserEmail = (userId, email) => {
  const url = '/support/change-email';
  const method = 'POST';
  const data = {
    userId,
    email,
  };
  return baseRequest(url, method, null, data);
};

export const resendEmailVerification = (userId) => {
  const url = '/support/resend-verification-mailer';
  const method = 'POST';
  const data = {
    userId,
  };
  return baseRequest(url, method, null, data);
};

export const addEmployeeCodeForUser = (userId, employeeCode) => {
  const url = '/support/add-employee-code';
  const method = 'POST';
  const data = {
    userId,
    employeeCode,
  };
  return baseRequest(url, method, null, data);
};

export const saveFulfilmentDetails = (body) => {
  const url = '/claims-support/addBankDetails';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const abhiReimbursementStatus = (body) => {
  const url = '/claims-support/add-abhi-reimbursement-status-file';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const akzoReimbursementStatus = (body) => {
  const url = '/claims-support/akzo-reimbursement-status-file';
  const method = 'POST'
  return baseRequest(url,method,null,body);
}

export const shlReimbursementStatus = (body) => {
  const url = '/claims-support/shl-reimbursement-status-file';
  const method = 'POST'
  return baseRequest(url,method,null,body);
}


export const fetchRelevantGmcAccounts = (userId, searchText) => {
  const url = '/support/find-relevant-gmc-account';
  const method = 'GET';
  return baseRequest(url, method, {
    userId,
    searchText,
  });
};

export const linkGmcAccount = (userId, gmcUserId) => {
  const url = '/support/link-gmc-account';
  const method = 'POST';
  const body = {
    gmcUserId,
    userId,
  };
  return baseRequest(url, method, null, body);
};

export const applyZomatoBenefits = (userId, phone, feId) => {
  const url = '/new-ops/user/check-zomato-benefits';
  const method = 'POST';
  const body = {
    userId,
    phone,
    feId,
  };
  return baseRequest(url, method, null, body);
};

export const updateDetails = (userId, name, dob, gender) => {
  const url = '/support/updateUserDetails';
  const method = 'POST';
  const body = {
    userId,
    name,
    dob,
    gender,
  };
  return baseRequest(url, method, null, body);
};
export const updateUserSponsor = (userId,sponsorId) => {
  const url = '/support/updateUserSponsor';
  const method = 'POST';
  const body = {
    userId,
    sponsorId
  };
  console.log(body);
  return baseRequest(url,method, null, body);
};
