import React, { Fragment } from 'react';
import { toast } from 'react-toastify';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import styled from 'styled-components';
import { makeNewCrentenial } from '../../services/api/makeCredential';
import NavBar from '../layout/NavBar';
import hidePasswordIcon from '../../assets/img/brand/hidePassword.png';
import showPasswordIcon from '../../assets/img/brand/showPassword.png';
import ApproveCredentials from './ApproveCredentials';

const PaddedContainer = styled.div`
  margin-top: 5rem;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  flex: 1;
  padding: 0px;
  margin: 0px;
`;

class makeCredentials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      userName: '',
      password: '',
      phone: '',
      email: '',
      showPaymentRequest: 0,
      showSupport: 0,
      showSponsorManagement: 0,
      poolAdministration: 0,
      showRedemption: 0,
      showExceptionalReimbursement: 0,
      showOnlinePayments: 0,
      showLabs: 0,
      showClaims: 0,
      showReimbursement: 0,
      showReimbursementAdmin: 0,
      showPassword: false,
      reporting: 0,
      showAdmin: 0,
    };
  }

  handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      userName,
      password,
      phone,
      email,
      showClaims,
      showExceptionalReimbursement,
      showLabs,
      showOnlinePayments,
      showPaymentRequest,
      showRedemption,
      showSponsorManagement,
      showSupport,
      poolAdministration,
      showReimbursement,
      showReimbursementAdmin,
      reporting,
      showAdmin,
    } = this.state;

    if (name.length <= 0) {
      toast.error('name');
      return;
    }
    if (password.length <= 0) {
      toast.error('Password');
      return;
    }
    if (email.length <= 0) {
      toast.error('email');
      return;
    }
    if (userName.length <= 0) {
      toast.error('username');
      return;
    }
    if (phone.length <= 0) {
      toast.error('phone');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('userName', userName);
    formData.append('password', password);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('showClaims', showClaims);
    formData.append(
      'showExceptionalReimbursement',
      showExceptionalReimbursement
    );
    formData.append('showLabs', showLabs);
    formData.append('showOnlinePayments', showOnlinePayments);
    formData.append('showPaymentRequest', showPaymentRequest);
    formData.append('showRedemption', showRedemption);
    formData.append('showSponsorManagement', showSponsorManagement);
    formData.append('showSupport', showSupport);
    formData.append('poolAdministration', poolAdministration);
    formData.append('showReimbursement', showReimbursement);
    formData.append('showReimbursementAdmin', showReimbursementAdmin);
    formData.append('reporting', reporting);
    formData.append('showAdmin', showAdmin);

    makeNewCrentenial(this.state)
      .then((result) => {
        if (result.message === 'Success') {
          toast.success(
            `Cred Approval Request Created Successfully. Please Check Approvals Page`
          );
        } else {
          toast.error(
            result.errorMessage ||
              'Something went wrong. Please try again later'
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          err.errorMessage || 'Something went wrong. Please try again later'
        );
      })
      .finally(() => {
        this.setState({
          submitting: false,
        });
      });
  };
  render() {
    const {
      name,
      userName,
      password,
      phone,
      email,
      showExceptionalReimbursement,
      showPaymentRequest,
      showRedemption,
      showSponsorManagement,
      showSupport,
      poolAdministration,
      showOnlinePayments,
      showLabs,
      showClaims,
      showReimbursement,
      showReimbursementAdmin,
      showPassword,
      reporting,
      showAdmin,
    } = this.state;
    return (
      <Fragment>
        <NavBar />
        <PaddedContainer>
          <HeaderContainer>
            <h3 className="">Make Credentials</h3>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '10px',
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  this.props.history.push('/approve-credentials');
                }}
              >
                Approve Credentials
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  this.props.history.push('/manage-credentials');
                }}
              >
                Manage Credentials
              </Button>
            </div>
          </HeaderContainer>

          {/* Form */}
          <Form className="mt-4" onSubmit={this.onSubmit}>
            <FormGroup row>
              <Label for="name" md={2}>
                Name
              </Label>
              <Col md={10}>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter name"
                  md={10}
                  value={name}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="userName" md={2}>
                User Name
              </Label>
              <Col md={10}>
                <Input
                  type="text"
                  name="userName"
                  id="userName"
                  placeholder="Enter userName"
                  md={10}
                  value={userName}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="password" md={2}>
                Password
              </Label>
              <Col md={10} style={{ position: 'relative' }}>
                <Input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder="Enter password"
                  md={10}
                  value={password}
                  onChange={(event) => this.handleUserInput(event)}
                />
                <img
                  onClick={() =>
                    this.setState({
                      showPassword: !showPassword,
                    })
                  }
                  src={showPassword ? showPasswordIcon : hidePasswordIcon}
                  height="25px"
                  style={{ position: 'absolute', top: '6px', right: '30px' }}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="phone" md={2}>
                Phone
              </Label>
              <Col md={10}>
                <Input
                  type="number"
                  name="phone"
                  id="phone"
                  placeholder="Enter phone"
                  md={10}
                  value={phone}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label for="email" md={2}>
                Email
              </Label>
              <Col md={10}>
                <Input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="example@example.com"
                  md={10}
                  value={email}
                  onChange={(event) => this.handleUserInput(event)}
                />
              </Col>
            </FormGroup>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="showOnlinePayments">Show Online Payments</Label>
                  <Input
                    type="select"
                    name="showOnlinePayments"
                    id="showOnlinePayments"
                    value={showOnlinePayments}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="showLabs">Show Labs</Label>
                  <Input
                    type="select"
                    name="showLabs"
                    id="showLabs"
                    value={showLabs}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="showPaymentRequest">Show Payment Request</Label>
                  <Input
                    type="select"
                    name="showPaymentRequest"
                    id="showPaymentRequest"
                    value={showPaymentRequest}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="showSupport">Show Support</Label>
                  <Input
                    type="select"
                    name="showSupport"
                    id="showSupport"
                    value={showSupport}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="showSponsorManagement">
                    Show Sponsor Management
                  </Label>
                  <Input
                    type="select"
                    name="showSponsorManagement"
                    id="showSponsorManagement"
                    value={showSponsorManagement}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="poolAdministration">Pool Administration</Label>
                  <Input
                    type="select"
                    name="poolAdministration"
                    id="poolAdministration"
                    value={poolAdministration}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="showRedemption">Show Redemption</Label>
                  <Input
                    type="select"
                    name="showRedemption"
                    id="showRedemption"
                    value={showRedemption}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col md={4}>
                <FormGroup>
                  <Label for="showExceptionalReimbursement ">
                    Show Exceptional Reimbursement
                  </Label>
                  <Input
                    type="select"
                    name="showExceptionalReimbursement "
                    id="showExceptionalReimbursement "
                    value={showExceptionalReimbursement}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="showClaims">Show Claims</Label>
                  <Input
                    type="select"
                    name="showClaims"
                    id="showClaims"
                    value={showClaims}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="showClaims">Show Reimbursement Claim</Label>
                  <Input
                    type="select"
                    name="showReimbursement"
                    id="showReimbursement"
                    value={showReimbursement}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="showClaims">Show Reimbursement Admin</Label>
                  <Input
                    type="select"
                    name="showReimbursementAdmin"
                    id="showReimbursementAdmin"
                    value={showReimbursementAdmin}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="reporting">Reporting</Label>
                  <Input
                    type="select"
                    name="reporting"
                    id="reporting"
                    value={reporting}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="reporting">Show Admin</Label>
                  <Input
                    type="select"
                    name="showAdmin"
                    id="showAdmin"
                    value={showAdmin}
                    onChange={(event) => this.handleUserInput(event)}
                  >
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Button type="submit">Submit</Button>
          </Form>
        </PaddedContainer>
      </Fragment>
    );
  }
}

export default makeCredentials;
