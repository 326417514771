import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { getPastConsultations, convertToOpdConsult, convertToOpdConsultForOutOfNetworkConsult } from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import 'react-dates/initialize';
import {DateRangePicker, SingleDatePicker} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

class LabRxPastConsults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            allConsultations: [],
            selectedConsultation: null,
            outOfNetworkDate:null,
            focused: false,
            outOfNetworkRemarks: '',
            outOfNetworkDoctorName: '',

        };
    }

    toggle = () => {
        if (this.state.modal === false) {
            getPastConsultations(this.props.userId)
                .then((response) => {
                    if (response.body.message === 'success') {
                        this.setState({
                            allConsultations: response.body.allConsultations,
                            selectedConsultation: null,
                        });
                    }
                })
                .catch((err) => {
                    console.error('Error in getting past consultations', err);
                    toast.error('Error in getting past consultations');
                });
        }
        this.setState({
            modal: !this.state.modal,
        });
    };

    selectConsult = (consultation) => {
        console.log('Selected Consultation', consultation);
        // Make API call to give OPD benefits to the user

        const newState = Array.from(this.state.allConsultations);
        let currConsult = null;
        newState.forEach((c) => {
            if (c.uniqueId === consultation.uniqueId) {
                c.selected = true;
                currConsult = c;
            }  else {
                c.selected = false;
            }
        });

        // currConsult.selected = true;
        // Update that order
        this.setState({
            allConsultations: newState,
            selectedConsultation: currConsult,
        });
    };

    renderPastConsultations = () => {
        return this.state.allConsultations.map(c => {
           return (
               <tr key={c.uniqueId} onClick={() => this.selectConsult(c)} className={(c.selected) ? 'bg-primary' : ''}>
                   <th scope="row">{c.doctorName}</th>
                   <td>{c.date}</td>
                   <td>{c.relativeName}</td>
                   <td>{c.relation}</td>
                   <td>{c.consultType}</td>
                   <td>{c.consultationStatus}</td>
               </tr>
           );
        });
    };

    saveOutOfNetworkCart = () => {
        if (this.state.outOfNetworkDate &&
            this.state.outOfNetworkRemarks &&
            this.state.outOfNetworkRemarks.trim().length !== 0 &&
            this.state.outOfNetworkDoctorName &&
            this.state.outOfNetworkDoctorName.trim().length !== 0 &&
            this.state.outOfNetworkCenterName &&
            this.state.outOfNetworkCenterAddress.trim().length !== 0) {
            const date = this.state.outOfNetworkDate.format('YYYY-MM-DD');
            convertToOpdConsultForOutOfNetworkConsult(this.props.cartId, this.props.requestId, this.props.userId, date, this.state.outOfNetworkDoctorName, this.state.outOfNetworkRemarks, this.state.outOfNetworkCenterName, this.state.outOfNetworkCenterAddress)
            .then((response) => {
                console.log(response.body);
                if (response.body.message === 'success') {
                    toast.success('OPD benefits Applied');
                    this.props.loadCart();
                    this.toggle();
                } else {
                    toast.error('Unable to give OPD benefits to this cart');
                }
            })
            .catch((err) => {
                toast.error('Unable to give OPD benefits to this cart');
                console.error('Error', err);
            });
        }
    };

    save = () => {

        if (this.state.selectedConsultation) {
            convertToOpdConsult(this.props.cartId, this.state.selectedConsultation.consultationId, this.state.selectedConsultation.doctorId)
                .then((response) => {
                    console.log(response.body);
                    if (response.body.message === 'success') {
                        toast.success('OPD benefits Applied');
                        this.props.loadCart();
                        this.toggle();
                    } else {
                        toast.error('Unable to give OPD benefits to this cart');
                    }
                })
                .catch((err) => {
                    toast.error('Unable to give OPD benefits to this cart');
                    console.error('Error', err);
                });
        }
        // Close the dialog if successfull
    };

    renderOutOfNetworkForm = () => {
        console.log(this.props.userInfo, 'user info passed in Past Consults');
        if (this.props.userInfo.outOfNetworkDoctor) {
            return (
                <div className="form">
                    <p className="font-weight-bold">OR add an out of Network Doctor's Prescription Details</p>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Doctor Name</label>
                        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                               placeholder="Doctor Name" value={this.state.outOfNetworkDoctorName} onChange={(e) => this.setState({outOfNetworkDoctorName: e.target.value})}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Center Name</label>
                        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                               placeholder="Center Name" value={this.state.outOfNetworkCenterName} onChange={(e) => this.setState({outOfNetworkCenterName: e.target.value})}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Center Address</label>
                        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                               placeholder="Center Address" value={this.state.outOfNetworkCenterAddress} onChange={(e) => this.setState({outOfNetworkCenterAddress: e.target.value})}/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Date of Consultation</label>
                        <SingleDatePicker
                            date={this.state.outOfNetworkDate} // momentPropTypes.momentObj or null
                            onDateChange={outOfNetworkDate => this.setState({ outOfNetworkDate })} // PropTypes.func.isRequired
                            focused={this.state.focused} // PropTypes.bool
                            onFocusChange={({ focused }) => this.setState({ focused })}
                            id="outOfNetworkDate" // PropTypes.string.isRequired,
                            displayFormat={() => "DD/MM/YYYY"}
                            placeholder="Date of Consultation"
                            small={true}
                            numberOfMonths={1}
                            isOutsideRange={() => false}
                        />

                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">Remarks (optional)</label>
                        <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                               placeholder="Remarks" value={this.state.outOfNetworkRemarks} onChange={(e) => this.setState({outOfNetworkRemarks: e.target.value})}/>
                    </div>
                </div>
            );
        }

        return '';
    };

    render() {
        console.log('Cart items', this.props.cartItemsResult && this.props.cartItemsResult.discountType === 'opd');
        return (
            <div>
                <Button color="danger" disabled={this.props.cartItemsResult && this.props.cartItemsResult.discountType === 'opd'} onClick={this.toggle}>Give OPD Benefits</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" scrollable={true}>
                    <h4 className="mt-1 mb-0 ml-2">Showing online and offline consultations</h4>
                    <ModalBody>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">Doctor Name</th>
                                <th scope="col">Diagnosis</th>
                                <th scope="col">Relative Name</th>
                                <th scope="col">Relation</th>
                                <th scope="col">Type</th>
                                <th scope="col">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderPastConsultations()}
                            </tbody>
                        </table>

                        {this.renderOutOfNetworkForm()}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>Give OPD Benefits</Button>{' '}
                        <Button color="info" onClick={() => this.saveOutOfNetworkCart()}>Give out of Network benefits</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}


const mapStateToProps = state => ({ cartItemsResult: state.labsDashboard.cartItemsResult });

export default connect(
    mapStateToProps,
)(LabRxPastConsults);





