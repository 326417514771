import React from 'react';

import { withRouter } from 'react-router-dom';
import LabsDetailsHeader from "./LabsDetailsHeader";

import LabsCenterOnboardingTable from './LabsCenterOnboardingTable';

import { fetchPartnerCenters } from '../../services/api/lab-partners';

import { toast } from 'react-toastify';
import AddEditCenterModal from "./AddEditCenterModal";

class LabsCenterOnboarding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableLoading: false,
            partnerId: null,
            centers: [],
        };
    }

    getCentersForPartner = () => {
        const { partnerId } = this.state;
        if (isNaN(partnerId) || partnerId === null) {
            return;
        }
        this.setState({
            tableLoading: true,
        });
        return fetchPartnerCenters(partnerId)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    this.setState({
                        centers: body.centers,
                    });
                    return;
                }
                throw new Error(body.errorMessage || 'Couldn\'t find the centers');
            })
            .catch((err) => {
                console.error(err, 'getCenterForPartner', partnerId);
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    tableLoading: false,
                });
            });
    };

    onComplete = () => {
        this.getCentersForPartner();
    };

    componentDidMount() {
        const partnerId = parseInt(this.props.match.params.partnerId);
        this.setState({
            partnerId,
        }, this.getCentersForPartner);
    }

    render() {
        const { partnerId, tableLoading, centers } = this.state;
        return (
            <React.Fragment>
                <LabsDetailsHeader partnerId={partnerId} type={"Centers"} />
                <LabsCenterOnboardingTable centers={centers} partnerId={partnerId} loading={tableLoading} onComplete={this.onComplete} />
                <AddEditCenterModal />
            </React.Fragment>
        );
    }
}

export default withRouter(LabsCenterOnboarding);
