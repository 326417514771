import React from 'react';
import ReactTable from "react-table";

import ModalConfirm from "../common/ModalConfirm";

import { toggleActiveInactivePartnerCenter } from '../../services/api/lab-partners';

import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import AddEditCenterModal from "./AddEditCenterModal";

import { getGoogleMapLink } from '../../utils/maps';

import { FaLocationArrow, FaVial, FaPencilAlt } from 'react-icons/fa';

import { searchFilterMethod } from '../../utils/react-table';

class LabsCenterOnboardingTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: {
                message: '',
                onConfirm: null,
            },
            addEditCenterModal: {
                partnerId: null,
                centerId: null,
                editModal: false,
                isOpen: false,
            }
        };
    }

    getColumns = () => {
        return [{
            accessor: 'locality',
            Header: 'Locality',
            filterable: true,
            filterMethod: searchFilterMethod,
        }, {
            accessor: 'city',
            Header: 'City',
            className: 'text-center',
        }, {
            accessor: 'pincode',
            Header: 'Pincode',
            className: 'text-center',
            filterable: true,
            filterMethod: searchFilterMethod,
        }, {
            // accessor: 'latLng',
            Header: 'Lat-Lng',
            className: 'text-center',
            Cell: (row) => {
                const { lat, lng } = row.original;
                const link = getGoogleMapLink(lat, lng);
                return (
                    <React.Fragment>
                        {(lat) && (<a href={link} target="_blank"><FaLocationArrow /></a>)}
                        {lat}-{lng}
                    </React.Fragment>
                );
            }
        }, {
            Header: 'Home Collection',
            className: 'text-center',
            Cell: row => {
                const { collectionAvailable, collectionCharges, collectionRadius } = row.original;
                if (collectionAvailable) {
                    return (<p className="text-success">Yes - Rs. {collectionCharges} upto {collectionRadius} KM</p>)
                }
                return (<p className="text-danger">No</p>)
            }
        }, {
            accessor: 'addedByName',
            Header: 'Added By',
            className: 'text-center',
        }, {
            Header: 'Action',
            className: 'text-center',
            Cell: row => {
                const { active, centerId } = row.original;
                return (
                    <div>
                         <button className={`btn btn-${(!active) ? ("success") : ("danger")}`} onClick={() => (this.openActiveInactiveModal(centerId, !active))}>{(!active) ? ("Active") : ("Inactive")}</button>
                        &nbsp;
                        <span title="Edit">
                            <FaPencilAlt className="clickable" onClick={() => (this.openEditCenterModal(centerId))} />
                        </span>
                        &nbsp;
                        <Link title="Tests" to={`/lab-partner-details/${this.props.partnerId}/centers/${centerId}/tests`}>
                            <FaVial />
                        </Link>
                    </div>
                );
            }
        }];
    };

    openActiveInactiveModal = (centerId, active) => {
        const { partnerId } = this.props;
        this.setState({
            modal: {
                isOpen: true,
                message: `Are you sure mark it as ${(active) ? "Active" : "Inactive"}?`,
                onConfirm: () => {
                    return toggleActiveInactivePartnerCenter(partnerId, centerId, active)
                        .catch((err) => {
                            toast.error(err.message);
                            console.log(err, centerId, partnerId, active, 'Error while toggling the status');
                        });
                }
            },
        });
    };

    closeActiveInactiveModal = () => {
        this.setState({
            modal: {
                message: '',
                onConfirm: null,
                isOpen: false,
            }
        }, this.props.onComplete)
    };

    openAddCenterModal = () => {
        const partnerId = this.props.partnerId;
        this.setState({
            addEditCenterModal: {
                partnerId,
                editModal: false,
                centerId: null,
                isOpen: true,
            },
        })
    };

    openEditCenterModal = (centerId) => {
        const partnerId = this.props.partnerId;
        this.setState({
            addEditCenterModal: {
                partnerId,
                editModal: true,
                centerId,
                isOpen: true,
            }
        });
    };

    closeAddEditCenterModal = () => {
        this.setState({
            addEditCenterModal: {
                isOpen: false,
            },
        }, this.props.onComplete);
    };

    render() {
        const { centers, loading, partnerId } = this.props;
        const { modal } = this.state;
        const { addEditCenterModal } = this.state;
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-2">
                        <button className="btn btn-primary btn-block" onClick={this.openAddCenterModal}>Add Center</button>
                    </div>
                </div>
                <br />
                <ReactTable
                    data={centers}
                    loading={loading}
                    onFetchData={this.getPartnerTests}
                    showPaginationTop={false}
                    columns={this.getColumns()}
                    filterable={false}
                    collapseOnDataChange={false}
                    defaultPageSize={20}
                    className="-striped -highlight"
                    sortable={false}
                    // SubComponent={(row) => (<LabsCentersTestDetails partnerId={partnerId} centerId={row.original.centerId} refreshParent={this.props.onComplete} />)}
                />
                <ModalConfirm isOpen={modal.isOpen} onConfirm={modal.onConfirm} onClose={this.closeActiveInactiveModal} message={modal.message} />
                <AddEditCenterModal onClose={this.closeAddEditCenterModal} isOpen={addEditCenterModal.isOpen} partnerId={partnerId} editModal={addEditCenterModal.editModal} centerId={addEditCenterModal.centerId} />
            </React.Fragment>
        );
    }
}

export default LabsCenterOnboardingTable;
