import React, { Component } from 'react';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import { FaEdit, FaClipboardList } from 'react-icons/fa';
import { Button, Input, Tooltip } from 'reactstrap';
import {
  getPoolManagerHome,
  getDoctorsForPool,
  getSponsorsAndPools,
  editPoolForSponsor,
  changeDoctorActivity,
  removeDoctor,
  addDoctor,
  searchDoctors,
  addPool,
  editPool,
} from '../../services/api/pool-manager';
import { RegularText, BoldText } from '../common/Text';
import { toast } from 'react-toastify';
import StyledTappable from '../common/StyledTappable';

import Specialist from './Specialist';
import Modal from '../common/Modal';

const OuterComponent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin-top: 50px;
`;

const HeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  padding-top: 20px;
`;

const HeaderElement = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const BodyComponent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 0.5rem;
  /* overflow: hidden; */
`;

const SelectElement = styled(Select)`
  flex: 1;
  max-width: 30rem;
  margin-right: 10px;
  /* border: 0.1rem solid #8852cc; */
`;

const AsyncSelectComponent = styled(AsyncSelect)`
  /* flex: 1; */
  width: 30rem;
  margin-top: 10px;
  align-self: center;
  /* border: 0.1rem solid #8852cc; */
`;

const DoctorsContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin: 10px 0px;
  /* overflow-y: scroll; */
`;

// const Button = styled.button`
//   padding: 4px;
// `;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
};

class SponsorPoolsBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      sponsors: [],
      selectedPool: {
        poolId: null,
        poolName: null,
      },
      createPoolPoolName: '',
      editPoolPoolId: '',
      copyPoolPoolId: null,
      editPoolSponsorId: '',
      specialists: [],
      searchDoctors: [],
      showAddModal: false,
      showEditModal: false,
      pages: -1,
      page: 0,
      pageSize: 20,
      sorted: [],
      filtered: [],
    };
  }

  componentDidMount() {
    this.getPools();
  }

  onFetchData = (state) => {
    const { page, pageSize, sorted, filtered } = state;
    console.log(state);
    this.getSponsorsAndPools(page, pageSize, sorted, filtered);
  };

  getSponsorsAndPools = (page, pageSize, sorted, filtered) => {
    this.setState({
      loading: true,
    });
    if (page) {
      this.setState({
        page,
        pageSize,
        sorted,
        filtered,
      });
    }
    getSponsorsAndPools(page, pageSize, sorted, filtered)
      .then((res) => {
        console.log({ res });
        if (res.message === 'success') {
          const { sponsors, totalCount } = res;
          const pages = Math.ceil(totalCount / pageSize);
          this.setState({
            sponsors,
            pages,
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        toast.error(`Failure! ${err.errorMessage}`);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  getPools = () => {
    getPoolManagerHome()
      .then((res) => {
        console.log({ res });
        if (res.message === 'success') {
          const { pools } = res;
          this.setState({
            pools,
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        toast.error(`Failure! ${err.errorMessage}`);
      });
  };

  getDoctorsForPool = (poolId) => {
    return getDoctorsForPool(poolId)
      .then((res) => {
        console.log({ res });
        if (res.message === 'success') {
          const { specialists } = res;
          this.setState({
            specialists,
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        toast.error(`Failure! ${err.errorMessage}`);
      });
  };

  onPoolSelect = (option) => {
    console.log({ option }, '[option]');
    this.setState({
      selectedPool: option,
    });
    this.getDoctorsForPool(option.poolId);
  };

  onDoctorSelect = (option) => {
    console.log({ option }, 'onDoctorSelect after search');
    if (option) {
      const { selectedPool } = this.state;
      addDoctor(selectedPool.poolId, option.doctorId)
        .then((res) => {
          console.log({ res });
          if (res.message === 'success') {
            this.getDoctorsForPool(selectedPool.poolId);
          } else {
            toast.error(`Failure! ${res.errorMessage}`);
          }
        })
        .catch((err) => {
          console.log({ err });
          toast.error(`Failure! ${err.errorMessage}`);
        });
    }
  };

  getDoctors = (input) => {
    return searchDoctors(input.toLowerCase())
      .then((res) => {
        console.log({ res }, 'getDoctors result');
        const result = res.doctors;
        // result.forEach(doctor => {
        //   console.log({ doctor });
        //   doctor.label = doctor.brand_name;
        //   doctor.value = doctor.brand_name;
        // });
        this.setState({ searchDoctors: result });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  promiseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getDoctors(input));
      }, 500);
    });
  };

  addPool = (e) => {
    e.preventDefault();
    const poolName = this.state.createPoolPoolName;
    const copyPoolId = this.state.copyPoolPoolId;
    addPool(poolName, copyPoolId)
      .then((res) => {
        console.log({ res }, '[addPool]-[res]');
        if (res.message === 'success') {
          this.setState({ showAddModal: false });
          this.getPools();
          toast.success('Pool Added Sucessfully');
        } else {
          toast.error(res.errorMessage);
        }
      })
      .catch((err) => {
        console.log({ err }, '[addPool]-[err]');
        const errorMessage =
          (err.body || {}).errorMessage ||
          ((err.data || {}).body || {}).errorMessage;
        toast.error(
          errorMessage || 'Something went wrong. Please try again later'
        );
      });
  };

  editPoolForm = (e) => {
    e.preventDefault();
    const poolId = this.state.editPoolPoolId;
    const sponsorId = this.state.editPoolSponsorId;
    editPoolForSponsor(poolId, sponsorId)
      .then((res) => {
        console.log({ res }, '[editPool]-[res]');
        if (res.message === 'success') {
          this.setState({
            showEditModal: false,
            editPoolSponsorId: null,
            editPoolPoolId: null,
          });
          this.getSponsorsAndPools();
          toast.success('Pool Id updated sucessfully');
        } else {
          toast.error(res.errorMessage);
        }
      })
      .catch((err) => {
        console.log({ err }, '[editPool]-[err]');
        const errorMessage =
          (err.body || {}).errorMessage ||
          ((err.data || {}).body || {}).errorMessage;
        toast.error(
          errorMessage || 'Something went wrong. Please try again later'
        );
      });
  };

  showAddModal = () => {
    this.setState({
      showAddModal: true,
    });
  };

  showEditModal = (sponsorId) => {
    this.setState({
      showEditModal: true,
      editPoolSponsorId: sponsorId,
    });
  };

  viewPool = (poolId, poolName) => {
    const { history } = this.props;
    history.push(`/doctor-pool?poolId=${poolId}&poolName=${poolName}`);
  };

  tableColumns = [
    {
      accessor: 'sponsorId',
      filterable: false,
      Header: 'Sponsor ID',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'sponsorName',
      filterable: true,
      Header: 'Sponsor Name',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'poolName',
      filterable: true,
      Header: 'Pool Name',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'sponsorActiveConsultCount',
      filterable: false,
      Header: 'Active Consults in Sponsor',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'totalDoctors',
      filterable: false,
      Header: 'Total Doctors in Pool',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'totalActive',
      filterable: false,
      Header: 'Total Active Doctors in Pool',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'poolActiveConsultCount',
      filterable: false,
      Header: 'Active Consults in Pool',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'actions',
      numeric: true,
      filterable: false,
      disablePadding: false,
      Header: 'Actions',
      sortable: false,
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return (
          <div>
            <FaEdit
              className="icon-style"
              style={{ fontSize: 25 }}
              id={`confirmButton-${row.original.sponsorId}`}
              onClick={() => this.showEditModal(row.original.sponsorId)}
            />
            <Tooltip
              placement="top"
              target={`confirmButton-${row.original.sponsorId}`}
              trigger="hover"
              toggle={() => {
                const { confirmTooltipOpen } = this.state;
                this.setState({
                  confirmTooltipOpen:
                    row.original.sponsorId === confirmTooltipOpen
                      ? -1
                      : row.original.sponsorId,
                });
              }}
              isOpen={this.state.confirmTooltipOpen === row.original.sponsorId}
            >
              Change pool for sponsor
            </Tooltip>
            <FaClipboardList
              className="icon-style"
              style={{ fontSize: 25 }}
              id={`viewpool-${row.original.sponsorId}`}
              onClick={() =>
                this.viewPool(row.original.poolId, row.original.poolName)
              }
            />
            <Tooltip
              placement="top"
              target={`viewpool-${row.original.sponsorId}`}
              trigger="hover"
              toggle={() => {
                const { viewPoolTooltipOpen } = this.state;
                this.setState({
                  viewPoolTooltipOpen:
                    row.original.sponsorId === viewPoolTooltipOpen
                      ? -1
                      : row.original.sponsorId,
                });
              }}
              isOpen={this.state.viewPoolTooltipOpen === row.original.sponsorId}
            >
              View doctors in pool
            </Tooltip>
          </div>
        );
      },
    },
  ];

  render() {
    const {
      pools,
      pages,
      loading,
      sponsors,
      selectedPool,
      specialists,
      showAddModal,
      showEditModal,
    } = this.state;

    return (
      <React.Fragment>
        <OuterComponent>
          <HeaderComponent>
            <HeaderElement>
              <button
                className="btn btn-primary col-4"
                onClick={() => this.showAddModal()}
              >
                Add Pool
              </button>
            </HeaderElement>
          </HeaderComponent>
          <Modal
            isOpen={showAddModal}
            onClose={() => this.setState({ showAddModal: false })}
            header={'Add Pool'}
          >
            <form onSubmit={this.addPool}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <p>
                      Choose a pool name. Name should contain alphabets, numbers
                      or underscore only
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ createPoolPoolName: e.target.value })
                      }
                      value={this.state.createPoolPoolName}
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <p>Choose a pool to copy doctors from(Optional)</p>
                  </div>
                  <div className="col">
                    <SelectElement
                      options={pools}
                      placeholder="Choose a pool to copy doctors from(Optional)"
                      styles={{
                        menu: (base) => ({ ...base, position: 'relative' }),
                      }}
                      getOptionLabel={(option) => option.poolName}
                      getOptionValue={(option) => option.poolId}
                      onChange={(option) =>
                        this.setState({ copyPoolPoolId: option.poolId })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <button className="btn btn-primary btn-block">Add</button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-danger btn-block"
                      onClick={() => this.setState({ showAddModal: false })}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
          <Modal
            isOpen={showEditModal}
            onClose={() => this.setState({ showEditModal: false })}
            header={'Update Pool Id for a sponsor'}
          >
            <form onSubmit={this.editPoolForm}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <SelectElement
                      options={pools}
                      placeholder="Select Pool"
                      styles={{
                        menu: (base) => ({ ...base, position: 'relative' }),
                      }}
                      getOptionLabel={(option) => option.poolName}
                      getOptionValue={(option) => option.poolId}
                      onChange={(option) =>
                        this.setState({ editPoolPoolId: option.poolId })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <button className="btn btn-primary btn-block">Edit</button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-danger btn-block"
                      onClick={() => this.setState({ showEditModal: false })}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
          <BodyComponent>
            <ReactTable
              filterable
              sortable={false}
              style={{ flex: 1 }}
              data={sponsors}
              collapseOnDataChange={false}
              columns={this.tableColumns}
              loading={loading}
              pages={pages}
              manual
              onFetchData={this.onFetchData}
              showPaginationTop={true}
              className="-striped -highlight"
            />
          </BodyComponent>
        </OuterComponent>
      </React.Fragment>
    );
  }
}

export default withRouter(SponsorPoolsBody);
