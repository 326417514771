import React, { Component } from 'react'
import NavBar from '../layout/NavBar';
import ReimbursementQcCLaimsTable from './ReimbursementQcCLaimsTable';

class ReimbursementQcCheck extends Component {
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <NavBar />
        </div>
        <div className="row mt-5 pt-4 pb-3">
          <div className="col-8">
            <h2 className="pl-1">Reimbursement QC Claims</h2>
          </div>
        </div>
        <ReimbursementQcCLaimsTable/>
      </div>
    );
  }
}

export default ReimbursementQcCheck