import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTable from 'react-table';
import { getRequests, saveCartRemark, deleteTranscriptionRequest } from '../../services/api/get-labs-orders';
import { debounce } from '../../utils/debounce';
import { toast } from 'react-toastify';
import ReactTableInputFilter from './ReactTableInputFilter';
import FileSaver from "file-saver";
import jsonToCsv from '../../utils/jsonToCsv';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
} from 'reactstrap'
import { Link } from 'react-router-dom';

class LabsRxRequestsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pages: -1,
            requests: [],
            filtered: [],
            sorted: [],
            pageSize: 50,
            page: 0,
            delTrModalOpen: false,
            delTrRequestId: null,
        };


        this.onFilteredChange = this.onFilteredChange.bind(this);
        this.fetchStrategy = this.fetchStrategy.bind(this);

        this.fetchData = this.fetchData.bind(this);
        this.fetchDataWithDebounce = debounce(this.fetchData, 300);
        console.log('Lab PartnerId: ', this.props.labPartnerId);
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('New props vs old props', prevProps, this.props);
        if (prevProps.download !== this.props.download) {
            this.downloadMIS();
        }
        if (this.props.startDate === null || this.props.endDate === null) {
            return ;
        }
        if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
            let stDate = 0, enDate = 0;
            if (this.props.startDate !== null) {
                stDate = this.props.startDate.startOf('day').unix();
            }
            if (this.props.endDate !== null) {
                enDate = this.props.endDate.endOf('day').unix();
            }

            this.updateStateFilters('reqStartDate', stDate, 'reqEndDate', enDate);
        }
    }

    updateStateFilters = (id, value, id2, value2) => {
        console.log('Update all filters in state', {id, value, id2, value2});
        // console.log('New filter values', moment(value).format('DD-MM-YYYY'), moment(value2).format('DD-MM-YYYY'));
        const filter = this.state.filtered.find(f => f.id === id);
        const filter2 = this.state.filtered.find(f => f.id === id2);
        if(filter) {
            filter.value = value;
        } else {
            this.state.filtered.push({id, value});
        }
        if(filter2) {
            filter2.value = value2;
        } else {
            this.state.filtered.push({id: id2, value: value2});
        }

        this.setState({ filtered: JSON.parse(JSON.stringify(this.state.filtered))});
    };

    saveRemark = (row, remark) => {
        console.log(row, remark);
        saveCartRemark(row.original.cartId, remark)
            .then(result => {
                if (result.body.message === 'success') {
                    toast.success(`Comment Saved`);
                    const newState = Array.from(this.state.requests);
                    newState[row.index].remarks = remark;
                    // Update that order
                    this.setState({
                        requests: newState,
                    });
                } else {
                    toast.error(`Error in Saving comment`);
                }
            })
            .catch(err => {
                console.log('Error in API', err);
                toast.error(`Error in Saving comment`);
            });
    };

    onFilteredChange = (e) => {
        // console.log('Start filtering. After this fetch strategy should be called');
        this.setState({filtered: e});
    };

    fetchStrategy = state => this.fetchDataWithDebounce(state);

    prepareApiFilters = () => {
        const appliedFilters = {};
        // let appliedOrderBy = {};
        // Convert this list of filterables into object properties
        this.state.filtered.forEach(
            filter => (appliedFilters[filter.id] = filter.value),
        );
        // state.sorted.forEach(sort => appliedOrderBy[sort.id] = sort.desc?'desc':'asc');

        appliedFilters.rowsPerPage = this.state.pageSize;
        appliedFilters.page = this.state.page + 1;
        return appliedFilters;
    };

    downloadMIS = () => {
        const appliedFilters = this.prepareApiFilters();
        appliedFilters.rowsPerPage = 10000; //OOPS
        getRequests(appliedFilters, this.state.sorted)
            .then(response => {
                const data = response.body.data.result.map((d) => {
                    const item = {};
                    item.requestId = d.requestId;
                    item.orderId = d.orderId;
                    item.patientName = d.patientName;
                    item.userName = d.userName;
                    item.collectionDate = moment(d.collectionDate).format('DD-MM-YYYY');
                    item.rxSubmittedOn = moment(d.rxSubmittedOn).format('DD-MM-YYYY');
                    item.sponsorName = d.sponsorName;
                    item.userPhone = d.userPhone;
                    item.patientPohne = d.patientPhone;
                    item.link = d.link;
                    item.status = d.status;
                    item.remarks = d.remarks;
                    if (moment(d.cancelledOn).isValid()) {
                        item.cancelledOn = moment(d.cancelledOn).format('DD-MM-YYYY');
                    }
                    if (moment(d.billGeneratedOn).isValid()) {
                        item.billGeneratedOn = moment(d.billGeneratedOn).format('DD-MM-YYYY');
                    }
                    return item;
                });
                const csv = jsonToCsv(data);
                const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
                FileSaver.saveAs(blob, 'Transcription-Requests-MIS.csv' || 'exports.csv');
            })
            .catch(err => {
                console.error(err, 'Error in Dowloading MIS');
                toast.error(`Error in downloading MIS`);
            });
    };

    fetchData = (state, instance) => {
        // show the loading overlay
        this.setState({ loading: true });
        const appliedFilters = this.prepareApiFilters();

        console.log('fetchData with Applied filters', appliedFilters);
        // state.filtered =
        // fetch your data
        getRequests(appliedFilters, this.state.sorted)
        .then(response => {
            // console.log('Api response', response.body);
            const pages =
                response.body.data.totalCount % appliedFilters.rowsPerPage === 0
                    ? parseInt(
                    response.body.data.totalCount / appliedFilters.rowsPerPage,
                    )
                    : parseInt(
                    response.body.data.totalCount / appliedFilters.rowsPerPage,
                ) + 1;
            this.setState({
                requests: response.body.data.result,
                loading: false,
                pages,
            });
        })
        .catch(err => {
            console.error(err, 'Error in get labs requests API');
            toast.error(`Error in fetching requests`);
        });
    };
    updateTrDeleteRequestId = (requestId) => {
        const { delTrRequestId } = this.state;
        this.setState({
            delTrRequestId: requestId,
        });
    }

    openTrModal = () => {
        this.setState({
            delTrModalOpen: true,
        })
    }

    closeTrModal = () => {
        this.setState({
            delTrModalOpen: !this.state.delTrModalOpen,
        })
    }

    deleteTranscriptionRequest = () => {
        const currRequestId = this.state.delTrRequestId;
        console.log('\n Delete Prescription Request: ', currRequestId);
        return deleteTranscriptionRequest(currRequestId)
            .then((response) => {
                console.log('\n response deleteTranscriptionRequest', response);
                if (response.body.message === 'success') {
                    toast.success(`Deleted Transcription Request!`);
                } else {
                    toast.error('Failed to Delete Transcription!');
                }

            })
            .catch((err) => {
                console.log('Error in Deleting Transcription!', err);
                toast.error('Error in Deleting Transcription!');
            })
            .finally(() => {
                this.fetchData();
                this.closeTrModal();
            });
    }

    getColumns = () => [
        {
            Header: 'Order Details',
            columns: [
                {
                    Header: 'Request Id',
                    accessor: 'requestId',
                    filterable: true,
                    width: 75,
                    Filter: ReactTableInputFilter,
                    Cell: row => {
                        const link = `/lab-rx-details?cartId=${row.original.cartId}&requestId=${row.original.requestId}&userId=${row.original.userId}`;
                        return (
                            <Link to={link}>
                                {row.original.requestId}
                            </Link>
                        );
                    },
                },
                {
                    Header: 'Rx Submitted On',
                    accessor: 'rxSubmittedOnEpoch',
                    width: 120,
                    Cell: row => (
                        <span>
                          {moment(row.original.rxSubmittedOnEpoch * 1000).format('hh:mm A, Do MMM')}
                        </span>
                    ),
                },
                {
                    Header: 'Bill Generated On',
                    accessor: 'billGeneratedOn',
                    width: 120,
                    Cell: row => {
                        if (row.original.billGeneratedOn !== null) {
                            return (
                                <span>
                                     {moment(row.original.billGeneratedOn).format('hh:mm A, Do MMM')}
                                </span>
                            );
                        }
                        return (
                            <span>
                                  N/A
                            </span>
                        );

                    },
                },
                {
                    Header: 'Status',
                    accessor: 'status',
                    filterable: true,
                    width: 100,
                    Cell: row => {
                        if (row.original.status === 'Ordered') {
                            return (
                                <a href={row.original.orderLink}>{row.original.status}</a>
                            );
                        }
                        return (
                            <span>
                                {row.original.status}
                            </span>
                        );
                    },
                    filterMethod: (filter, row) => {
                        if (filter.value === 'all') {
                            return true;
                        }
                        return row.status === filter.value;
                    },
                    Filter: ({ filter, onChange }) => {
                        return (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : 'all'}
                            >
                                <option value="all">Show All</option>
                                <option value="rxSubmitted">Rx Submitted</option>
                                <option value="billGenerated">Bill generated</option>
                                <option value="orderPlaced">Order Placed</option>
                                <option value="cancelled">Cancelled</option>
                            </select>
                        );
                    },
                },
                {
                    Header: 'Priority',
                    accessor: 'priority',
                    filterable: true,
                    width: 100,
                    Filter: ({ filter, onChange }) => {
                        return (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : 'all'}
                            >
                                <option value="all">Show All</option>
                                <option value="urgent">Urgent</option>
                                <option value="normal">Normal</option>
                            </select>
                        );
                    },
                },
                {
                    expander: true,
                    Header: () => <strong>More</strong>,
                    width: 65,
                    Expander: ({ isExpanded, ...rest }) => (
                        <div>
                            {isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}
                        </div>
                    ),
                    style: {
                        cursor: 'pointer',
                        fontSize: 25,
                        padding: '0',
                        textAlign: 'center',
                        userSelect: 'none',
                    },
                },
            ],
        },
        {
            Header: 'User Details',
            columns: [
                {
                    Header: 'User Name',
                    accessor: 'userName',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'User phone',
                    accessor: 'userPhone',
                    width: 140,
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'Sponsor Name',
                    accessor: 'sponsorName',
                    width: 100,
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
            ],
        },
        {
            Header: 'Patient Details',
            columns: [
                {
                    Header: 'Patient Name',
                    accessor: 'patientName',
                    filterable: true,
                    Filter: ReactTableInputFilter,
                },
                {
                    Header: 'Patient Phone',
                    accessor: 'patientPhone',
                },
            ],
        },
    ];

    getSubComponent = row => {
        return (
            <div className="row">
                <div className="col-4">
                    <div className="input-group">
                        <div className="input-group-prepend">
                            <span className="input-group-text">Remarks</span>
                        </div>
                        <textarea
                            className="form-control"
                            aria-label="Remarks"
                            placeholder="Comment here..."
                            onChange={event => (row.updatedRemark = event.target.value)}
                            defaultValue={
                                row.original.remarks === null ? '' : row.original.remarks
                            }
                        />
                    </div>
                </div>
                <div className="col-1 d-flex">
                    <button
                        className="btn btn btn-success"
                        onClick={() => this.saveRemark(row, row.updatedRemark)}
                    >
                        Save
                    </button>
                </div>
                <div className="col-1 d-flex">
                    <button
                        className="btn btn btn-danger"
                        onClick={() => {
                            this.updateTrDeleteRequestId(row.original.requestId);
                            this.openTrModal();
                        }}
                    >
                        Delete
                    </button>
                </div>


            </div>
        );
    };

    render() {
        // console.log(this.props.startDate, this.props.endDate);
        return (
            <>
                <Modal
                    isOpen={this.state.delTrModalOpen}
                    toggle={this.closeTrModal}
                >
                    <ModalHeader className="bg-danger">{`Delete Transcription for Request Id ${this.state.delTrRequestId}`}</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete this transcription?
                    </ModalBody>
                    <ModalFooter>
                        <div className="btn btn-danger" onClick={this.deleteTranscriptionRequest}>
                            Delete
                        </div>{' '}
                        <div className="btn btn-secondary" onClick={this.closeTrModal}>
                            Cancel
                        </div>
                    </ModalFooter>
                </Modal>

            <ReactTable
                keyField="orderId"
                data={this.state.requests}
                columns={this.getColumns()}
                defaultPageSize={50}
                className="-striped -highlight"

                pages={this.state.pages}
                pageSize={this.state.pageSize}
                page={this.state.page}


                loading={this.state.loading}
                filtered={this.state.filtered}
                sorted={this.state.sorted}

                manual

                onFetchData={this.fetchStrategy}
                onFilteredChange={this.onFilteredChange}

                onPageSizeChange={pageSize => this.setState({ pageSize })}
                onPageChange={page => this.setState({ page })}
                onSortedChange={sorted => this.setState({ sorted })}

                showPaginationTop
                showPaginationBottom
                SubComponent={this.getSubComponent}
            />
            </>
        );
    }
}

function mapStateToProps(state) {
    return {  };
}

export default connect(mapStateToProps)(LabsRxRequestsTable);
