import React, {useState, useEffect} from 'react';
import { refundWalletAmount } from '../../services/api/get-pharmacy-orders';
import {toast} from "react-toastify";

function PharmacyRefundAmount({orderDetails, orderAmount, userId, serviceKey, orderId}) {
    const [refundAmount, setRefundAmount] = useState(0);
    const [refunded, setRefunded] = useState(false);

    useEffect(()=>{
        console.log(userId);
        const negativeAmount = orderAmount.totalAmount - (orderAmount.amount, orderAmount.walletDeduction);
        const data = negativeAmount < 0 ? Math.abs(negativeAmount) : 0;
        setRefundAmount(data);
    }, [orderAmount, orderDetails, orderId, serviceKey, userId]);


    const refundAmountButton = () => {
        refundWalletAmount(orderId, serviceKey, null, refundAmount, userId).then(response=>{
            if(response.message==='success'){
                toast.success('Successfully Refunded');
                setRefunded(true);
                setRefundAmount(0);
            }
        });
    }

  return (
    <div className='container-fluid pull-right'>{refundAmount > 0 && refunded === false ? <button type="button" class="btn btn-danger" onClick={refundAmountButton}>Refund Order Amount: {refundAmount}</button> : refundAmount <= 0 ? <>No refund needed</> : <>Already Refunded</>}
    <br />
    <br />
    </div>
  )
}

export default PharmacyRefundAmount