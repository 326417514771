import React, {useEffect, useState, useCallback} from 'react'
import { Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Col,
    Row,
    Button, } from 'reactstrap';
import Select from 'react-select';
import { bookNewVaccinationRequest, getCategory, getCenters, getPartner, getSubCategory, getVaccinationCost, getVaccinationRequestSlots, getWalletDeductionDetails } from '../../../services/api/cashless-vaccination';
import { debounce } from '../../../utils/debounce';
import { fetchUsersRelativeByphone } from '../../../services/api/offline-consult';
import { toast } from 'react-toastify';

function BookNewVaccination({ isOpen,
    onClose,
    onComplete,
    className = 'bg-primary',}) {
    
        const [loading, setLoading] = useState(false);
        const [userId, setUserId] = useState('');
        const [centerOptions, setCenterOptions] = useState([]);
        const [partnerOptions, setPartnerOptions] = useState([]);
        const [vaccineOptions, setVaccineOptions] = useState([]);
        const [vaccineCategoryOptions, setVaccineCategoryOptions] = useState([]);
        const [vaccineCategory, setVaccineCategory] = useState(null);
        const [centerName, setCenterName] = useState(null);
        const [partnerName, setPartnerName] = useState(null);
        const [vaccine, setVaccine] = useState('');
        const [userPhone, serUserPhone] = useState('');
        const [vaccinationCharges, setVaccinationCharges] = useState('');
        const [appointmentDate, setAppointmentDate] = useState('');
        const [appointmentTime, setAppointmentTime] = useState('');
        const [relatives, setRelatives] = useState([]);
        const [relativesSelected, setRelativesSelected] = useState([]);
        const [isLoadingCenters, setIsLoadingCenters] = useState(false);
        const [timeSlots, setTimeSlots] = useState([]);
        const [copay, setCopay] = useState('');
      
    useEffect(() => {
        // getPartner().then((partner) => {
        //     console.log('[getPartner]', partner);
        //     setPartnerOptions(
        //         partner.data.map((partner) => ({
        //             value: partner.networkPartnerId,
        //             label: partner.networkPartnerName,
        //         }))
        //     );
        // })

        // getCenters().then((centers) => {
        //     console.log(centers, '[centers]');
        //     setCenterOptions(
        //         centers.data.map((center) => ({
        //             value: center.centerId,
        //             label: center.nameAndId,
        //         }))
        //     );
        // });

        getVaccinationRequestSlots().then((slots) => {
            console.log('[getVaccinationRequestSlots]', slots);
            setTimeSlots(
                slots.data.map((slot) => ({
                    value: slot.id,
                    label: slot.start_title,
                }))
            );
        })

    }, []);

    useEffect(() => {
        if (userId) {
            getCategory(vaccineCategory ? vaccineCategory.value : null, userId).then((category) => {
                console.log('[getCategory]', category);
                setVaccineCategoryOptions(
                    category.data.map((vaccine) => ({
                        value: vaccine.id,
                        label: vaccine.name,
                    }))
                );
            });
        }
    }, [userId, vaccineCategory]);

    const handleCenterSearch = (inputValue) => {
    if (inputValue.length > 2) {
        getCenters(inputValue, userId, vaccineCategory.value).then((centers) => {
            setIsLoadingCenters(true);
            setCenterOptions(
                centers.data.map((center) => ({
                    value: center.centerId,
                    label: center.nameAndId,
                }))
            );
            setIsLoadingCenters(false);
        });
    }
  };

  useEffect(() => {
    if (userId && vaccineCategory && vaccineCategory.value) {
        getCenters(null, userId, vaccineCategory.value).then((centers) => {
            console.log(centers, '[centers]');
            setCenterOptions(
                centers.data.map((center) => ({
                    value: center.centerId,
                    label: center.nameAndId,
                }))
            );
        });
    }
}, [userId, vaccineCategory]);

  const fetchUserId = async (input) => {
    try {
        const response = await fetchUsersRelativeByphone(input)
        console.log(response.data, '[response]');
        if (response.userDetails && response.userDetails.userId) {
            setUserId(response.userDetails.userId);
            setRelatives(
                response.data.map((el) => ({
                    value: el.relativeId,
                    label: el.relativeName
                }))
            )
        }
    } catch (error) {
        console.error('Error fetching user ID:', error);
    }
};


const debouncedFetchUserId = useCallback(debounce(fetchUserId, 500), []);

useEffect(() => {
    if (userPhone) {
        debouncedFetchUserId(userPhone);
    }
}, [userPhone, debouncedFetchUserId]);

useEffect(() => {
    console.log(vaccineCategory, '[vaccineCategory]');
    if (vaccineCategory) {
      getSubCategory(vaccineCategory.value, userId, relativesSelected ? relativesSelected.value : null).then((subCategory) => {
        setVaccineOptions(
          subCategory.data.map((vaccine) => ({
            value: vaccine.id,
            label: vaccine.name,
          }))
        );
      });
    }
  }, [vaccineCategory]);

useEffect(() => {
    if (vaccine) {
      const body = {
        subVaccineId: vaccine.value,
        categoryId: vaccineCategory.value,
      }
      getVaccinationCost(body).then((cost) => {
        if (cost && cost.data && cost.data[0]) {
          setVaccinationCharges(cost.data && cost.data[0].vaccineCost);
        }
      });
    }
  }, [vaccine])

  const getCurrentDate = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');
    return `${yyyy}-${mm}-${dd}`;
    };

    const handleSubmit = () => {
        const formData = {
            centerId : centerName.value || null,
            // partnerId: partnerName.value || null,
            categoryId: vaccineCategory.value || null,
            subVaccineId: vaccine.value || null,
            vaccinationCharges,
            appointmentDate,
            appointmentTimeId: appointmentTime.value,
            relativeId: relativesSelected && relativesSelected.value || null,
            userId: userId,
        };
    
        // Check for missing mandatory fields
        if (!formData.centerId) {
            return toast.error('Center is required');
        }
        if (!formData.categoryId) {
            return toast.error('Vaccine category is required');
        }
        if (!formData.subVaccineId) {
            return toast.error('Vaccine is required');
        }
        if (!formData.vaccinationCharges) {
            return toast.error('Vaccination charges are required');
        }
        if (!formData.appointmentDate) {
            return toast.error('Appointment date is required');
        }
        if (!formData.appointmentTimeId) {
            return toast.error('Appointment time is required');
        }
        if (!formData.userId) {
            return toast.error('User ID is required');
        }
    
        console.log(formData, appointmentTime, '[formData]');
    
        setLoading(true);
    
        bookNewVaccinationRequest(formData)
            .then((response) => {
                console.log(response, '[response]');
                if (response.data.return) {
                    toast.success(`Vaccination booked successfully, Parent OrderId - ${response.data.vaccineDetails[0].orderID}, RequestId - ${response.data.vaccineDetails[0].requestID}`);
                    onClose();
                    onComplete();
                } else {
                    toast.error('Booking failed. Please try again.');
                }
            })
            .catch((error) => {
                toast.error('An error occurred during the booking process.');
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };
    

    useEffect(() => {
        console.log(relativesSelected?.value, '[relativesSelected]');
        if (centerName?.value && vaccineCategory?.value && vaccine?.value && userId) {
            const relativeId = relativesSelected?.value || null;

            getWalletDeductionDetails(centerName.value, vaccineCategory.value, vaccine.value, relativeId, userId)
                .then((response) => {
                    console.log(response, '[getWalletDeductionDetails]');
                    if (response && response.data.length) {
                        response.data.forEach((paymentData) => {
                            console.log(paymentData, '[paymentData]');
                            if (paymentData.label === 'Total Paid') {
                                setCopay(paymentData.value);
                            }
                        });
                    }
                })
                .catch((error) => {
                    console.error('Error fetching wallet deduction details:', error);
                });
        }
    }, [centerName, vaccineCategory, vaccine, userId, relativesSelected]);
    

    const handleChange = (selectedOption) => {
        console.log(selectedOption, '[selectedOption]');
        if (selectedOption === null) {
            setRelativesSelected(null);
            setVaccineCategory(null);
            setVaccine(null);
            setCenterName(null);
            setCopay('');
            setVaccinationCharges('');
            setAppointmentTime('');
            setAppointmentDate('');
        } else {
            setRelativesSelected(selectedOption);
        }
    };
    
  return (
    <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader>Book New Vaccination</ModalHeader>
        <ModalBody>
            <Form>
                <FormGroup>
                    <Label for='user'>User Phone/Email</Label>
                    <Input id='user'
                    onChange={(e) => serUserPhone(e.target.value)} 
                    placeholder='Enter User Phone/Email' />
                    
                </FormGroup>
                <FormGroup>
                        <Label for='userId'>User ID</Label>
                        <Input id='userId' value={userId} readOnly placeholder='User ID will be shown here' />
                </FormGroup>
                <FormGroup>
                    <Label for='relatives'>Relative</Label>
                    <Select 
                        id='relatives'
                        options={relatives}
                        onChange={handleChange}
                        placeholder='Relatives will be shown here'
                        isClearable={true}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for='vaccineCategory'>Vaccine Category</Label>
                    <Select 
                        id='vaccineCategory'
                        options={vaccineCategoryOptions}
                        value={vaccineCategory}
                        onChange={(selectedOption) => setVaccineCategory(selectedOption)}
                        placeholder='Search Category'
                    />
                </FormGroup>
                <FormGroup>
                    <Label for='vaccine'>Vaccine Sub Category</Label>
                    <Select
                        id='vaccineSubCategory'
                        options={vaccineOptions}
                        value={vaccine}
                        onChange={(selectedOption) => setVaccine(selectedOption)}
                        placeholder="Search Vaccines"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for='centerName'>Center Name</Label>
                    <Select
                        id='centerName'
                        options={centerOptions}
                        value={centerName}
                        onChange={(selectedOption) => setCenterName(selectedOption)}
                        onInputChange={handleCenterSearch}
                        isLoading={isLoadingCenters}
                        placeholder="Search Centers"
                    />
                </FormGroup>
                <FormGroup>
                    <Label for='walletDeduction'>Copay Available</Label>
                    <Input  
                    type='text'
                    name='walletDeduction'
                    id='walletDeduction'
                    value={copay}
                    disabled={true} />
                </FormGroup>
                <FormGroup>
                    <Label for='vaccinationCharges'>Vaccination Charges</Label>
                    <Input
                    type='text'
                    name='vaccinationCharges'
                    id='vaccinationCharges'
                    value={vaccinationCharges}
                    disabled={true}
                    />
                </FormGroup>
                <Row form>
                <Col md={6}>
                    <FormGroup>
                        <Label for='appointmentDate'>Appointment Date</Label>
                        <Input
                            type='date'
                            name='appointmentDate'
                            id='appointmentDate'
                            value={appointmentDate}
                            onChange={(e) => setAppointmentDate(e.target.value)}
                            min={getCurrentDate()}
                        />
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label for='appointmentTime'>Appointment Time</Label>
                        <Select
                            id='appointmentTime'
                            options={timeSlots}
                            value={appointmentTime}
                            onChange={(selectedOption) => setAppointmentTime(selectedOption)}
                            placeholder="Select Appointment Time"
                        />
                    </FormGroup>
                </Col>
                </Row>
            </Form>
        </ModalBody>
        <ModalFooter>
            <Button color='primary' onClick={handleSubmit} disabled={loading || copay !== 0}>
            {loading ? 'Loading...' : 'Book Appointment'}
            </Button>
            {!loading && (
            <Button color='secondary' onClick={onClose} disabled={loading}>
                Close
            </Button>
            )}
        </ModalFooter>
    </Modal>
  )
}

export default BookNewVaccination;