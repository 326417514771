import moment from "moment";
import React from "react";

export default function Comment(props) {
    const { name, message, time } = props.comment;
    console.log(message, '[message]');
    return (
        <div className="media mb-1">
            {/*<img*/}
            {/*    className="mr-3 bg-light rounded"*/}
            {/*    width="48"*/}
            {/*    height="48"*/}
            {/*    src={`https://api.adorable.io/avatars/48/${name.toLowerCase()}@adorable.io.png`}*/}
            {/*    alt={name}*/}
            {/*/>*/}
            <div className="media-body p-2 shadow-sm rounded bg-light border">
                <small className="float-right text-muted">{time==='Just Now' ? time : moment(time).format('YYYY-MM-DD HH:mm A')}</small>
                <h6 className="mt-0 mb-1 text-muted">{name}</h6>
                {message}
            </div>
        </div>
    );
}
