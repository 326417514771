import config  from '../../config';
import { store } from '../../store';
import generateGetAuth from "../../utils/generateGetAuth";

const backendBaseUrl = config.baseUrl;
const redirectBaseUrl = `${backendBaseUrl}/new-ops/ticketing`;

const getToken = () => {
    const state = store.getState();
    if (state.user.token) {
        return generateGetAuth(state.user.token);
    }
}

export const getMyTicketLink = () => {
    return `${redirectBaseUrl}/get-my-tickets-link?auth=${getToken()}`;
};

export const getUserLink = (userId) => {
    return `${redirectBaseUrl}/get-users-tickets?userId=${userId}&auth=${getToken()}`;
};

export const getRequestTicketsLink = (requestId) => {
    return `${redirectBaseUrl}/get-requests-tickets?requestId=${requestId}&auth=${getToken()}`;
};
