import React, { Component } from 'react';

import {
  Button,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from 'reactstrap';
import styled from 'styled-components';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';

const Container = styled.div`
  position: relative;
`;

const DateContainer = styled.div`
  position: absolute;
  padding: 10px;
  background: white;
  box-shadow: 0.5px 0.5px 0.5px rgba(0, 0, 0, 0.5);
`;

const LabelContainer = styled.div`
  text-decoration: underline;
  color: #474754;
  cursor: pointer;
`;

export default class DateFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      focusedInput: null,
    };
  }

  toggle = () => {
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  };

  onChangeValue = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      this.props.onChange(startDate, endDate);
    }
  };

  setFocusedInput = value => {
    this.setState({ focusedInput: value });
  };

  handleOnDatesChange = ({ startDate, endDate }) => {
    this.props.onChangeValue(startDate, endDate);
  };

  render() {
    const { label, value } = this.props;
    const { startDate, endDate } = value;
    const { showFilter, focusedInput } = this.state;
    return (
      <Container>
        <LabelContainer
          type="button"
          id={`PopoverLegacy_${label}`}
          // onClick={this.toggle}
        >
          {label}
        </LabelContainer>
        {/* {showFilter && ( */}
        <UncontrolledPopover
          trigger="legacy"
          placement="bottom"
          target={`PopoverLegacy_${label}`}
        >
          <PopoverHeader>{`${label} Filter`}</PopoverHeader>
          <PopoverBody>
            {/* <DateContainer> */}
            <DateRangePicker
              startDateId="startDate"
              endDateId="endDate"
              startDate={startDate}
              endDate={endDate}
              onDatesChange={this.handleOnDatesChange}
              focusedInput={focusedInput}
              onFocusChange={this.setFocusedInput}
              showClearDates={true}
              isOutsideRange={() => false}
              displayFormat={() => 'DD/MM/YYYY'}
              minimumNights={0}
            />
            {/* </DateContainer> */}
          </PopoverBody>
        </UncontrolledPopover>
        {/* )} */}
      </Container>
    );
  }
}
