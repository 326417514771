import React from 'react';
import Modal from '../common/Modal';

import { Input } from 'valuelink/tags';
import Link from 'valuelink';
import { toast } from 'react-toastify';

import { createTestUser } from '../../services/api/sponsor-management';

import LoadingComponent from "../common/LoadingComponent";

class CreateTestUserModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            isLoading: false,
        };
    }

    handleOnOpen = () => {
        this.setState({
            phone: '',
        });
    };

    handleSubmit = () => {
        const { sponsorId } = this.props;
        const { phone } = this.state;
        if (!phone || !sponsorId) {
            toast.error('Please enter valid phone number');
            return;
        }
        this.setState({
            isLoading: true,
        });
        return createTestUser(phone, sponsorId)
            .then((response) => {
                console.log()
                if (response.message === 'success') {
                    toast.success('Successfully Created Test User');
                    this.props.onClose();
                } else {
                    throw new Error(response.errorMessage);
                }
            })
            .catch((err) => {
                console.error(err, sponsorId, phone);
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    isLoading: false,
                });
            });
    };

    render() {
        const phoneLink = Link.state(this, 'phone');
        return (
            <Modal
                header="Create Test User"
                isOpen={this.props.isOpen}
                onOpen={this.handleOnOpen}
                onClose={this.props.onClose}
            >
                <div className="container-fluid">
                    <div className="row mb-1">
                        <div className="col">Enter Phone Number</div>
                    </div>
                    <div className="row mb-2">
                        <div className="col">
                            <Input className="form-control" valueLink={phoneLink} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {(this.state.isLoading) ? (
                                <LoadingComponent />
                            ) : (
                                <button className="btn btn-primary btn-block" onClick={this.handleSubmit}>Create</button>
                            )}
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default CreateTestUserModal;
