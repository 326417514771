import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import {
  OuterComponent,
  PageTitle,
  customStyles,
  CloseModal,
} from '../common/modal/StyledComponent';
import {
  onboardingStatus,
} from '../../services/api/opd-management';

class OpdStatusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        };
    }

    showStatus = () => {
        const selections = [];
        selections.push(
            <div>
                <Row>
                    <Col>Total Members Onboarded:</Col>
                    <Col>{this.props.onboardCount}</Col>
                </Row>
            </div>
        );
        return selections;
    }

    closeModal = () => {
        this.props.closeStatusModal();
    }

    render() {
        return (
        <Modal isOpen style={customStyles}>
            <OuterComponent>
                <div>
                    <p className="text-success">Success</p>
                    <ModalBody>
                        <u>OnBoarding Status</u>
                        {this.showStatus()}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={this.closeModal}>Close</Button>
                    </ModalFooter>
                </div>
            </OuterComponent>
        </Modal>
        );
    }
}

export default OpdStatusModal;