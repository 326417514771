import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Row as RawRow, Col } from 'reactstrap'
import { createFileRequest } from '../api'

const Row = ({children, ...props}) => <RawRow {...props} style={{ margin: 0, ...(props.style || {}) }}>
    {children}
</RawRow>

const DOUBLE_PAYMENT_REASONS = ['Single Payment', 'Issue At the Center', 'Payment Stuck', "Center didn't receive the payment", 'Tech Issue']
const SINGLE_PAYMENT_REASONS = [
    'MOU has expired',
    'Balance exhausted in cortis',
    'Centre did not acknowledege the cashless letter',
    'Payment stuck',
    'Centre denied tie-up',
    'Unable to verify the payment details', 
    'Spoc is unavailable', 
    'Center not ready to take online payment', 
    'Unable to pay due to internal issue', 
    'Unable to pay due Tech issue', 
    'Fee is not confirmed', 
    'Payment not allowed to this beneficiary'
];

const CreateReimbursementModal = ({
    isOpen,
    header,
    onClose = () => {},
    onComplete = () => {},
    className = 'bg-info'
}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [requestType, setRequestType] = useState('')
    const [orderId, setOrderId] = useState('')
    const [paymentReason, setPaymentReason] = useState('')
    const [expReason, setExpReason] = useState('')

    const submitHandler = async () => {

        setIsLoading(true)
        try {
            await createFileRequest({ orderType: requestType, expReason, paymentReason, orderId })
            onComplete()
            onClose()
        } catch (err) {
            console.log(err)
        }

        setIsLoading(false)
    }

    return <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader className={className} toggle={onClose}>
            {header}
        </ModalHeader>
        <ModalBody>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <Row style={{ color: '#555' }}>Select Type Of Request:</Row>
                    <Row>
                        <select style={{ width: '100%' }} onChange={e => setRequestType(e.target.value)}>
                            <option value=''>Select Option</option>
                            <option value='l-'>Lab Order</option>
                            <option value='c-'>Offline Consultation</option>
                        </select>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <Row style={{ color: '#555' }}>Enter ID:</Row>
                    <Row>
                        <input 
                            placeholder='Enter ID...' 
                            value={orderId}
                            onChange={(e) => setOrderId(e.target.value)}
                            style={{ width: '100%' }}
                        />
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <Row style={{ color: '#555' }}>Double Payment Reason:</Row>
                    <Row>
                        <ReasonSelect options={DOUBLE_PAYMENT_REASONS} value={paymentReason} onChange={setPaymentReason} />
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <Row style={{ color: '#555' }}>Exceptional Reimbursement Reason:</Row>
                    <Row>
                        <ReasonSelect options={SINGLE_PAYMENT_REASONS} value={expReason} onChange={setExpReason} />
                    </Row>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={submitHandler} disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Submit'}
            </Button>
            {!isLoading && (
                <Button color="secondary" onClick={onClose} disabled={isLoading}>
                    Cancel
                </Button>
            )}
        </ModalFooter>
    </Modal>
}

const ReasonSelect = ({ value, onChange, options }) => <select style={{ width: '100%' }} value={value} onChange={e => onChange(e.target.value)}>
    <option value=''>Select Option</option>
    {options.map(item => <option value={item} key={item}>{item}</option>)}
</select>

export default CreateReimbursementModal