import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loginCheck from "../login/LoginCheck";
import NavBar from "../layout/NavBar";

import SponsorManagementHome from './SponsorManagementHome';
import SponsorManagementDetails from './SponsorManagementDetails';

class SponsorManagementNavigator extends React.Component {

    render() {
        return (
            <React.Fragment>
                <NavBar />
                <div className="container-fluid" style={{ marginTop: '70px' }}>
                    <Switch>
                        <Route
                            exact
                            path="/sponsor-management/:sponsorId"
                            name="Sponsor Details"
                            component={SponsorManagementDetails}
                        />
                        <Route
                            exact
                            path="/sponsor-management"
                            name="Sponsor Management"
                            component={SponsorManagementHome}
                        />
                    </Switch>
                </div>
            </React.Fragment>
        );
    }
}

export default loginCheck(SponsorManagementNavigator);
