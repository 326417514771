import React from 'react';
import { fetchOpsUsers } from '../../services/api/offline-consult';
import { fetchClaimedOpsUsers } from '../../services/api/offline-consult';

export default class ClaimedByFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    fetchClaimedOpsUsers()
      .then((response) => {
        response.unshift({
          "opsUserId": -1,
          "opsUserName": "Unclaimed"
      });
        this.setState({
          users : response,
        });
      })
      .catch((err) => {
        console.error({ err }, 'Couldnt fetch OPS Users');
        throw err;
      });
  }

  onChange = (e) => {
    this.props.onChange(e.target.value);
  };

  render() {
    const { users } = this.state;
    console.log({ users }, 'State');
    return (
      <select
        style={{
          width: '100%',
          fontSize: '12px',
          borderRadius: '10px',
          height: '42px',
        }}
        onChange={this.onChange}
        value={this.props.value}
      >
        <option value="">All</option>
        {users &&
          users.map((u) => {
            return (
              <option key={u.opsUserId} value={u.opsUserId}>
                {u.opsUserName}
              </option>
            );
          })}
      </select>
    );
  }
}
