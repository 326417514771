import React, { useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import hidePasswordIcon from '../../assets/img/brand/hidePassword.png';
import showPasswordIcon from '../../assets/img/brand/showPassword.png';
import { updateCredentials } from '../../services/api/makeCredential';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import './EditCredentialModal.css';

export default function EditCredentialModal(props) {
  const [name, setName] = React.useState();
  const [userName, setUserName] = React.useState();
  const [password, setPassword] = React.useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showOnlinePayments, setShowOnlinePayments] = React.useState();
  const [showLabs, setShowLabs] = React.useState();
  const [showPaymentRequest, setShowPaymentRequest] = React.useState();
  const [showSupport, setShowSupport] = React.useState();
  const [showSponsorManagement, setShowSponsorManagement] = React.useState();
  const [poolAdministration, setPoolAdministration] = React.useState();
  const [showRedemption, setShowRedemption] = React.useState();
  const [showExceptionalReimbursement, setShowExceptionalReimbursement] =
    React.useState();
  const [showClaims, setShowClaims] = React.useState();
  const [showReimbursement, setShowReimbursement] = React.useState();
  const [showReimbursementAdmin, setShowReimbursementAdmin] = React.useState();
  const [id, setId] = React.useState();
  const [phone, setPhone] = React.useState();
  const [email, setEmail] = React.useState();
  const history = useHistory();

  const { showModal, toggle, selectedData = {} } = props;

  useEffect(() => {
    setName(selectedData.name);
    setUserName(selectedData.userName);
    setShowOnlinePayments(selectedData.showOnlinePayments);
    setShowLabs(selectedData.showLabs);
    setShowPaymentRequest(selectedData.showPayments);
    setShowSupport(selectedData.showSupport);
    setShowSponsorManagement(selectedData.showSponsorManagement);
    setPoolAdministration(selectedData.poolAdministration);
    setShowRedemption(selectedData.showRedemption);
    setShowExceptionalReimbursement(selectedData.showExceptionalReimbursement);
    setShowClaims(selectedData.showClaimsMgr);
    setShowReimbursement(selectedData.showReimbursement);
    setShowReimbursementAdmin(selectedData.reimbursementAdmin);
    setId(selectedData.id);
    setPhone(selectedData.phone);
    setEmail(selectedData.email);
  }, [selectedData]);

  const closeBtn = (
    <button className="close" onClick={toggle} type="button">
      &times;
    </button>
  );

  const updateCreds = async () => {
    const data = {
      id,
      name,
      phone,
      email,
      userName,
      password,
      showOnlinePayments,
      showLabs,
      showPaymentRequest,
      showSupport,
      showSponsorManagement,
      poolAdministration,
      showRedemption,
      showExceptionalReimbursement,
      showClaims,
      showReimbursement,
      showReimbursementAdmin,
    };
    try {
      const updatedData = await updateCredentials(data);
      console.log(updatedData, 'updated');
      if (updatedData.message === 'Success') {
        toast.success('Updation request created sucessfully');
      } else {
        const err = new Error('Invalid response');
        err.data = updatedData.err;
        throw err;
      }
    } catch (err) {
      console.log({ err, response: err.data });
      toast.error('failed to update credential');
    } finally {
      toggle();
      history.push('/make-credentials');
    }
  };

  return (
    <Modal isOpen={showModal} toggle={toggle}>
      <ModalHeader toggle={toggle} close={closeBtn}>
        Update Credential
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" for="name" md={6}>
              Name
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="text"
                name="name"
                id="name"
                placeholder="Enter name"
                md={10}
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" for="userName" md={6}>
              User Name
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="text"
                name="userName"
                id="userName"
                placeholder="Enter userName"
                md={10}
                value={userName}
                onChange={(event) => setUserName(event.target.value)}
              />
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" for="password" md={6}>
              New Password
            </Label>
            <Col md={6} style={{ position: 'relative' }}>
              <Input
                className="edit-modal-input"
                type={showPassword ? 'text' : 'password'}
                name="password"
                id="password"
                placeholder="Enter password"
                md={10}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <img
                onClick={() => setShowPassword(!showPassword)}
                src={showPassword ? showPasswordIcon : hidePasswordIcon}
                height="16px"
                style={{ position: 'absolute', top: '8px', right: '25px' }}
              />
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showOnlinePayments">
              Show Online Payments
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showOnlinePayments"
                id="showOnlinePayments"
                value={showOnlinePayments}
                onChange={(event) => setShowOnlinePayments(event.target.value)}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showLabs">
              Show Labs
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showLabs"
                id="showLabs"
                value={showLabs}
                onChange={(event) => setShowLabs(event.target.value)}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showPaymentRequest">
              Show Payment Request
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showPaymentRequest"
                id="showPaymentRequest"
                value={showPaymentRequest}
                onChange={(event) => setShowPaymentRequest(event.target.value)}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showSupport">
              Show Support
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showSupport"
                id="showSupport"
                value={showSupport}
                onChange={(event) => setShowSupport(event.target.value)}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label
              className="edit-modal-label"
              md={6}
              for="showSponsorManagement"
            >
              Show Sponsor Management
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showSponsorManagement"
                id="showSponsorManagement"
                value={showSponsorManagement}
                onChange={(event) =>
                  setShowSponsorManagement(event.target.value)
                }
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="poolAdministration">
              Pool Administration
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="poolAdministration"
                id="poolAdministration"
                value={poolAdministration}
                onChange={(event) => setPoolAdministration(event.target.value)}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showRedemption">
              Show Redemption
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showRedemption"
                id="showRedemption"
                value={showRedemption}
                onChange={(event) => setShowRedemption(event.target.value)}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label
              className="edit-modal-label"
              md={6}
              for="showExceptionalReimbursement"
            >
              Show Exceptional Reimbursement
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showExceptionalReimbursement "
                id="showExceptionalReimbursement "
                value={showExceptionalReimbursement}
                onChange={(event) =>
                  setShowExceptionalReimbursement(event.target.value)
                }
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showClaims">
              Show Claims
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showClaims"
                id="showClaims"
                value={showClaims}
                onChange={(event) => setShowClaims(event.target.value)}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label className="edit-modal-label" md={6} for="showReimbursement">
              Show Reimbursement Claim
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showReimbursement"
                id="showReimbursement"
                value={showReimbursement}
                onChange={(event) => setShowReimbursement(event.target.value)}
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>

          <FormGroup className="edit-row-modal" row>
            <Label
              className="edit-modal-label"
              md={6}
              for="showReimbursementAdmin"
            >
              Show Reimbursement Admin
            </Label>
            <Col md={6}>
              <Input
                className="edit-modal-input"
                type="select"
                name="showReimbursementAdmin"
                id="showReimbursementAdmin"
                value={showReimbursementAdmin}
                onChange={(event) =>
                  setShowReimbursementAdmin(event.target.value)
                }
              >
                <option value={0}>0</option>
                <option value={1}>1</option>
              </Input>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Discard
        </Button>{' '}
        <Button color="primary" onClick={() => updateCreds()}>
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
}
