import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import { Button, Input } from 'reactstrap';
import {
  getPoolManagerHome,
  getDoctorsForPool,
  changeDoctorActivity,
  removeDoctor,
  addDoctor,
  searchDoctors,
  addPool,
  editPool, getAllVerticalsForPoolManager,
} from '../../services/api/pool-manager';
import { RegularText, BoldText } from '../common/Text';
import { toast } from 'react-toastify';
import StyledTappable from '../common/StyledTappable';

import Specialist from './Specialist';
import Modal from '../common/Modal';

const OuterComponent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin-top: 50px;
`;

const HeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
`;

const HeaderElement = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const BodyComponent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 0.5rem;
  /* overflow: hidden; */
`;

const SelectElement = styled(Select)`
  flex: 1;
  max-width: 30rem;
  margin-right: 10px;
  /* border: 0.1rem solid #8852cc; */
`;

const AsyncSelectComponent = styled(AsyncSelect)`
  /* flex: 1; */
  width: 30rem;
  margin-top: 10px;
  align-self: center;
  /* border: 0.1rem solid #8852cc; */
`;

const DoctorsContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin: 10px 0px;
  /* overflow-y: scroll; */
`;

// const Button = styled.button`
//   padding: 4px;
// `;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 16,
    textAlign: 'left',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 12,
    textAlign: 'left',
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    // padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  noOptionsMessage: (provided, state) => ({
    ...provided,
    padding: 10,
    fontSize: 14,
    textAlign: 'left',
  }),
  // control: () => ({
  //   // none of react-select's styles are passed to <Control />
  //   width: 200,
  // }),
  // singleValue: (provided, state) => {
  //   const opacity = state.isDisabled ? 0.5 : 1;
  //   const transition = 'opacity 300ms';

  //   return { ...provided, opacity, transition };
  // }
};

class OnlinePaymentBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pools: [],
      verticals: [],
      sponsors: [],
      selectedPool: {
        poolId: null,
        poolName: null,
      },
      selectedVertical: {
        label: null,
        value: null,
      },
      createPoolPoolName: '',
      editPoolPoolId: '',
      editPoolSponsorId: '',
      specialists: [],
      filteredSpecialists: [],
      searchDoctors: [],
      showAddModal: false,
      showEditModal: false,
    };
    const { location } = props;
    if (location && location.search) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get('poolId')) {
        this.state.selectedPool.poolId = searchParams.get('poolId');
        this.state.selectedPool.poolName = searchParams.get('poolName');
        this.getDoctorsForPool(searchParams.get('poolId'));
      }
    }
  }

  componentDidMount() {
    this.getPools();
    this.getVerticals();
    this.onPoolSelect({ poolId: 24, poolName: "X" });
  }

  getVerticals = () => {
    getAllVerticalsForPoolManager()
        .then((resp) => {
          if (resp.message === 'success') {
            let { verticals } = resp;
            verticals = verticals.map((v) => {
              const obj = {
                label: v.verticalName,
                value: v.verticalId,
              }
              return obj;
            })
            this.setState({
              verticals
            })
          }
        })
  }

  getPools = () => {
    getPoolManagerHome()
      .then((res) => {
        console.log({ res });
        if (res.message === 'success') {
          const { pools, sponsors } = res;
          this.setState({
            pools,
            sponsors,
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        toast.error(`Failure! ${err.errorMessage}`);
      });
  };

  getDoctorsForPool = (poolId) => {
    return getDoctorsForPool(poolId)
      .then((res) => {
        console.log({ res });
        if (res.message === 'success') {
          const selectedVertical = this.state.selectedVertical;
          let { specialists } = res;
          let filteredSpecialists =  specialists;
          if (selectedVertical && selectedVertical.value) {
            filteredSpecialists = filteredSpecialists.filter((s) => (parseInt(s.verticalId) === parseInt(selectedVertical.value)));
          }
          this.setState({
            filteredSpecialists,
            specialists,
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        toast.error(`Failure! ${err.errorMessage}`);
      });
  };

  onPoolSelect = (option) => {
    console.log({ option }, '[option]');
    this.setState({
      selectedPool: option,
      selectedVertical: null,
    });
    this.getDoctorsForPool(option.poolId);
  };

  onVerticalSelect = (option) => {
    const allSpecialists = this.state.specialists;
    const verticalId = option.value;
    const filteredSpecialists = allSpecialists.filter((s) => (parseInt(s.verticalId) === parseInt(verticalId)));
    this.setState({
      filteredSpecialists,
      selectedVertical: option,
    });
  }

  onDoctorSelect = (option) => {
    console.log({ option }, 'onDoctorSelect after search');
    if (option) {
      const { selectedPool } = this.state;
      addDoctor(selectedPool.poolId, option.doctorId, option.practiceId)
        .then((res) => {
          console.log({ res });
          if (res.message === 'success') {
            toast.success(`Successfully Added ${option.doctorName}`);
            this.getDoctorsForPool(selectedPool.poolId);
          } else {
            toast.error(`Failure! ${res.errorMessage}`);
          }
        })
        .catch((err) => {
          console.log({ err });
          toast.error(`Failure! ${err.errorMessage}`);
        });
    }
  };

  getDoctors = (input) => {
    return searchDoctors(input.toLowerCase())
      .then((res) => {
        console.log({ res }, 'getDoctors result');
        const result = res.doctors;
        // result.forEach(doctor => {
        //   console.log({ doctor });
        //   doctor.label = doctor.brand_name;
        //   doctor.value = doctor.brand_name;
        // });
        this.setState({ searchDoctors: result });
        return result;
      })
      .catch((err) => {
        console.log({ err });
        return [];
      });
  };

  promiseOptions = (input) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getDoctors(input));
      }, 500);
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    const poolName = this.state.createPoolPoolName;
    addPool(poolName)
      .then((res) => {
        console.log({ res }, '[addPool]-[res]');
        if (res.message === 'success') {
          this.setState({ showAddModal: false });
          this.getPools();
          toast.success('Pool Added Sucessfully');
        }
      })
      .catch((err) => {
        console.log({ err }, '[addPool]-[err]');
      });
  };

  editPoolForm = (e) => {
    e.preventDefault();
    const poolId = this.state.editPoolPoolId;
    const sponsorId = this.state.editPoolSponsorId;
    editPool(poolId, sponsorId)
      .then((res) => {
        console.log({ res }, '[editPool]-[res]');
        if (res.message === 'success') {
          this.setState({ showEditModal: false });
          this.getPools();
          toast.success('Pool Id updated sucessfully');
        }
      })
      .catch((err) => {
        console.log({ err }, '[editPool]-[err]');
      });
  };

  showAddModal = () => {
    this.setState({
      showAddModal: true,
    });
  };

  showEditModal = () => {
    this.setState({
      showEditModal: true,
    });
  };

  render() {
    const {
      pools,
      sponsors,
      selectedPool,
      showAddModal,
      showEditModal,
        verticals,
      filteredSpecialists
    } = this.state;

    return (
      <React.Fragment>
        <OuterComponent>
          <HeaderComponent>
            <HeaderElement>
              <RegularText>Sponsors</RegularText>
              <SelectElement
                options={sponsors}
                placeholder="Select Sponsor"
                getOptionLabel={(option) =>
                  `${option.name} -- Pool(${option.poolName})`
                }
                getOptionValue={(option) => option.sponsorId}
                onChange={(option) => this.onPoolSelect(option)}
              />
            </HeaderElement>

            <HeaderElement>
              <RegularText>Pools</RegularText>
              <SelectElement
                options={pools}
                placeholder="Select Pool"
                defaultValue={{ poolId: 24, poolName: "X" }}
                getOptionLabel={(option) => option.poolName}
                getOptionValue={(option) => option.poolId}
                onChange={(option) => {
                console.log('doctor-pool:option',option);  
                  return this.onPoolSelect(option)
                }}
              />
            </HeaderElement>
            {this.state.specialists.length > 0 && (
                <HeaderElement>
                  <RegularText>Verticals</RegularText>
                  <SelectElement
                      options={verticals}
                      placeholder="Select Vertical"
                      value={this.state.selectedVertical}
                      onChange={(option) => this.onVerticalSelect(option)}
                  />
                </HeaderElement>
            )}
          </HeaderComponent>
          <Modal
            isOpen={showAddModal}
            onClose={() => this.setState({ showAddModal: false })}
            header={'Add Pool'}
          >
            <form onSubmit={this.submitHandler}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <p>Enter Pool Name</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ createPoolPoolName: e.target.value })
                      }
                      value={this.state.createPoolPoolName}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <button className="btn btn-primary btn-block">Add</button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-danger btn-block"
                      onClick={() => this.setState({ showAddModal: false })}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
          <Modal
            isOpen={showEditModal}
            onClose={() => this.setState({ showEditModal: false })}
            header={'Update Pool Id for a sponsor'}
          >
            <form onSubmit={this.editPoolForm}>
              <div className="container">
                <div className="row">
                  <div className="col">
                    <SelectElement
                      options={pools}
                      placeholder="Select Pool"
                      styles={{
                        menu: (base) => ({ ...base, position: 'relative' }),
                      }}
                      getOptionLabel={(option) => option.poolName}
                      getOptionValue={(option) => option.poolId}
                      onChange={(option) =>
                        this.setState({ editPoolPoolId: option.poolId })
                      }
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <p>Enter Sponsor Id</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <input
                      className="form-control"
                      onChange={(e) =>
                        this.setState({ editPoolSponsorId: e.target.value })
                      }
                      value={this.state.editPoolSponsorId}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <button className="btn btn-primary btn-block">Edit</button>
                  </div>
                  <div className="col">
                    <button
                      className="btn btn-danger btn-block"
                      onClick={() => this.setState({ showEditModal: false })}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
          <BodyComponent>
            {!selectedPool.poolId ? (
              <BoldText>Select Pool from above to manage doctors</BoldText>
            ) : null}
            {selectedPool.poolId ? (
              <BoldText>{`Doctors for Pool ${selectedPool.poolName}`}</BoldText>
            ) : null}
            {selectedPool.poolId ? (
              <AsyncSelectComponent
                ref={this.searchDoctorRef}
                styles={customStyles}
                placeholder="Add doctor to the Pool"
                cacheOptions
                getOptionLabel={(option) => `${option.doctorName}, ${option.centerName}`}
                getOptionValue={(option) => option.doctorId}
                isClearable={true}
                defaultOptions={this.state.searchDoctors}
                loadOptions={this.promiseOptions}
                onChange={(option) => this.onDoctorSelect(option)}
              />
            ) : null}

            <DoctorsContainer>
              {filteredSpecialists.map((ele) => (
                <Specialist
                  key={`${ele.doctorId}-${selectedPool.poolId}`}
                  selectedPool={selectedPool}
                  doctor={ele}
                  onComplete={() => this.getDoctorsForPool(selectedPool.poolId)}
                  specialists={filteredSpecialists}
                />
              ))}
            </DoctorsContainer>
          </BodyComponent>
        </OuterComponent>
      </React.Fragment>
    );
  }
}

export default withRouter(OnlinePaymentBody);
