import React, { Component } from 'react';
import { getPolicyDetails } from '../../services/api/policy-details';
import PolicyDetailsRelative from './PolicyDetailsRelative';

class PolicyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userInfo: {},
      relatives: [],
      policies: [],
      holderIds: [],
      cardOpenRelativeIds: [],
      starHealthPolicies: [],
      showMorePolicyDetailsOfStar: '',
    };
  }
  componentDidMount = () => {
    this.fetchPolicyDetails();
  };

  fetchPolicyDetails = () => {
    const params = {
      userId: this.props.userId,
    };
    return getPolicyDetails(params).then((result) => {
      console.log({ result });
      this.setState({
        userInfo: result.data.userInfo,
        relatives: result.data.relatives,
        starHealthPolicies: result.starHealthPolicies,
        policies: result.data.policies,
        cardOpenRelativeIds: result.data.relatives.length
          ? [result.data.relatives.relativeId]
          : [],
        holderIds: result.data.policies.length
          ? [result.data.policies.holderId]
          : [],
      });
    });
  };

  coiDetails = (coiUrl) => {
    console.log({ coiUrl });
    if (coiUrl) {
      return (
        <label>
          <a href={coiUrl} target="_blank">
            COI
          </a>
        </label>
      );
    } else {
      return <label>N/A</label>;
    }
  };

  showPoliciesDetails = () => {
    const elements = [];
    this.state.policies.forEach((element) => {
      console.log(element.name, 'element');
      elements.push(
        <div>
          <div
            className={`card mb-2 ${
              element.active !== 1 ? 'text-white bg-danger' : ''
            }`}
          >
            <div
              className="card-header"
              onClick={() => {
                this.handleTogglePolicyDetails(element.holderId);
              }}
            >
              <div className="row no-gutters">
                <span className="col-10">
                  <label style={{ wordBreak: 'break-word' }}>
                    {element.vendorName} ({element.policyName})
                  </label>
                </span>
                <span className="col-2">{this.coiDetails(element.coiUrl)}</span>
              </div>
              <div className="row no-gutters">
                <span className="col-7">
                  <small style={{ wordBreak: 'break-word' }}>
                    {element.startDate} - {element.endDate}
                  </small>
                </span>
                <span className="col-5 text-right">
                  {element.isCurrentUserDependent ? (
                    <span className="badge badge-primary">
                      <b>Dependent</b>
                    </span>
                  ) : null}
                </span>
              </div>
            </div>
            {this.showMorePoliciesDetails(element)}
          </div>
        </div>
      );
    });
    if (elements.length) {
      return elements;
    } else {
      return (
        <div>
          <label>No policy present.</label>
        </div>
      );
    }
  };

  handleTogglePolicyDetails = (holderId) => {
    if (this.state.holderIds.includes(holderId)) {
      const holderIds = this.state.holderIds.filter((id) => {
        return id !== holderId;
      });
      this.setState({
        holderIds,
      });
    } else {
      this.setState({
        holderIds: [...this.state.holderIds, holderId],
      });
    }
  };

  showMorePoliciesDetails = (policyDetail) => {
    if (this.state.holderIds.includes(policyDetail.holderId)) {
      return (
        <div className=" card-body">
          <div className="row">
            <div className="col-5">
              <label>Name:</label>
            </div>
            <div className="col-6">
              <label style={{ wordBreak: 'break-word' }}>
                {policyDetail.holderName}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>DOB:</label>
            </div>
            <div className="col-6">
              <label style={{ wordBreak: 'break-word' }}>
                {policyDetail.holderDob}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>Phone:</label>
            </div>
            <div className="col-6">
              <label style={{ wordBreak: 'break-word' }}>
                {policyDetail.holderPhone}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>Email:</label>
            </div>
            <div className="col-6">{policyDetail.holderEmail}</div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>Gender:</label>
            </div>
            <div className="col-6">
              <label style={{ wordBreak: 'break-word' }}>
                {policyDetail.holderGender}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>Certificate Number:</label>
            </div>
            <div className="col-6">
              <label style={{ wordBreak: 'break-word' }}>
                {policyDetail.certificateNumber}
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>CAP Consults:</label>
            </div>
            <div className="col-6">
              <label>Rs.{policyDetail.capConsults}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>Labs Cap Per Prescription:</label>
            </div>
            <div className="col-6">
              <label>Rs.{policyDetail.labsCapPerPrescription}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>Labs Cap Per Test:</label>
            </div>
            <div className="col-6">
              <label>Rs.{policyDetail.labsCapPerTest}</label>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
              <label>
                <b>Dependants:</b>
              </label>
            </div>
          </div>
          {this.showDependantDetails(policyDetail.dependants)}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  showDependantDetails = (dependants) => {
    const elements = [];
    dependants &&
      dependants.forEach((element) => {
        elements.push(
          <div className={`card mt-2 text-dark`}>
            <div className="card-body">
              <h5 className="card-title">
                {element.dependentName}&nbsp;&nbsp;
                <small>
                  {element.dependentDob}{' '}
                  <span className="text-danger">
                    {!element.deonboardedOn
                      ? ''
                      : ` - Removeds On ${element.deonboardedOn}`}
                  </span>
                </small>
              </h5>
              <div className="row">
                <div className="col-5">
                  <label>Phone:</label>
                </div>
                <div className="col-6">
                  <label style={{ wordBreak: 'break-word' }}>
                    {element.dependentPhone}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <label>Gender:</label>
                </div>
                <div className="col-6">
                  <label style={{ wordBreak: 'break-word' }}>
                    {element.dependentGender}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <label>Relation:</label>
                </div>
                <div className="col-6">
                  <label style={{ wordBreak: 'break-word' }}>
                    {element.dependentRelation}
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      });
    if (elements.length) {
      return elements;
    } else {
      return (
        <div>
          <label>No dependant added yet.</label>
        </div>
      );
    }
  };

  showStarPolicyDependentDetails = (dependents) => {
    return dependents.map((dep, index) => {
      return (
        <div className="mt-6px">
          <b>Dependent {index + 1}</b>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Name:</b> {dep.name}
            </div>
          </div>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Gender: </b> {dep.gender}{' '}
            </div>
          </div>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Patient-Id: </b> {dep.patientId}
            </div>
          </div>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Relative-Id:</b> {dep.relativeId}
            </div>
          </div>
          <div className="flex flex-row my-6px w-full">
            <div>
              <b>Health-Id:</b> {dep.healthId}
            </div>
          </div>
        </div>
      );
    });
  };

  showStarHealthPolicies = () => {
    const { starHealthPolicies } = this.state;

    return starHealthPolicies.map((policy, index) => {
      return (
        <div
          className="flex flex-row w-full"
          style={{
            border: '1px solid grey',
            borderRadius: '8px',
            padding: '6px',
            margin: '8px 0px',
            background: 'rgba(0,0,0,.03)',
          }}
        >
          <div className="flex flex-col w-full">
            <div className="flex flex-row my-6px w-full">
              <div>
                <b>Policy Name:</b> {policy.productName}
              </div>
            </div>
            <div className="flex flex-row my-6px w-full">
              <div>
                <b>Policy Number:</b> {policy.policyNumber}{' '}
              </div>
            </div>
            <div className="flex flex-row my-6px w-full">
              <div>
                <b>Policy Star Date:</b> {policy.policyStartDate}
              </div>
            </div>
            <div className="flex flex-row my-6px w-full">
              <div>
                <b>Policy End Date:</b> {policy.policyEndDate}
              </div>
            </div>
            <div
              className="w-full flex flex-row"
              style={{
                justifyContent: 'flex-end',
              }}
            >
              <div
                className="flex flex-row my-6px w-full items-center justify-center"
                style={{
                  background: '#17a2b8',
                  width: '100px',
                  color: 'white',
                  borderRadius: '8px',
                  padding: '4px',
                  cursor: 'pointer',
                  userSelect: 'none',
                }}
                onClick={() => {
                  let value = index;
                  if (this.state.showMorePolicyDetailsOfStar === index) {
                    value = null;
                  }
                  this.setState({
                    showMorePolicyDetailsOfStar: value,
                  });
                }}
              >
                More Details
              </div>
            </div>
            {this.state.showMorePolicyDetailsOfStar === index && (
              <div className="flex flex-col my-6px w-full">
                {this.showStarPolicyDependentDetails(policy.insuredDetails)}
              </div>
            )}
          </div>
        </div>
      );
    });
  };

  render() {
    const { userInfo } = this.state;
    const { showUserInfo = false, allowRelativeEdit = false } = this.props;
    return (
      <div className="container card">
        <div className="row mt-3">
          <div
            className="flex flex-col col-6"
            style={{
              zIndex: '10',
            }}
          >
            <div>
              {showUserInfo ? (
                <React.Fragment>
                  <label>
                    <b>User Info</b>
                  </label>
                  <div className="row">
                    <div className="col-3">
                      <label>User Name:</label>
                    </div>
                    <div className="col-6">
                      <label style={{ wordBreak: 'break-word' }}>
                        {userInfo.userName}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <label>DOB:</label>
                    </div>
                    <div className="col-6">
                      <label style={{ wordBreak: 'break-word' }}>
                        {userInfo.userDob}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <label>Phone:</label>
                    </div>
                    <div className="col-6">
                      <label style={{ wordBreak: 'break-word' }}>
                        {userInfo.userPhone}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <label>Email:</label>
                    </div>
                    <div className="col-6">
                      <label style={{ wordBreak: 'break-word' }}>
                        {userInfo.userEmail}
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <label>Gender:</label>
                    </div>
                    <div className="col-6">
                      <label style={{ wordBreak: 'break-word' }}>
                        {userInfo.userGender}
                      </label>
                    </div>
                  </div>
                </React.Fragment>
              ) : null}
              <label>
                <b>Relatives</b>
              </label>
              <PolicyDetailsRelative
                relatives={this.state.relatives}
                userId={(this.state.userInfo || {}).userId}
                onUpdateComplete={this.fetchPolicyDetails}
                allowEdit={allowRelativeEdit}
              />
            </div>

            <div style={{ width: '470px' }}>
              {this.state.starHealthPolicies && this.state.starHealthPolicies
                .length > 0 && (
                <>
                  <label>
                    <b>Star Health Policies</b>
                  </label>
                  <div className="w-full">{this.showStarHealthPolicies()}</div>
                </>
              )}
            </div>
          </div>
          <div className="col-6">
            <label>
              <b>Policies</b>
            </label>
            {this.showPoliciesDetails()}
          </div>
        </div>
      </div>
    );
  }
}

export default PolicyDetails;
