import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import axios from 'axios';
import config from '../../config.js';
import { store } from '../../store/index.js';

function WalletCoverageModal({ onClose , digitisationId }) {
  const [walletDetails, setWalletDetails] = useState({})

  
  useEffect(() => {

      const getWalletDetails = async () => {
        try {
          const headers = {};
          const state = store.getState();
          if (state.user.token) {
            headers.authorization = state.user.token;
          }
          const { data: responseBody } = await axios.get(
            `${config.pharmacyService}/ops/digitisation-wallet-detail?digitisationId=${digitisationId}`,
            { headers }
          );
          if(responseBody.success){
            setWalletDetails(responseBody);
          }else{
            onClose()
            alert(responseBody.message || "Failed to get wallet coverage details")
          }
        } catch (err) {
          onClose()
          let msg='Failed to get wallet coverage details';
          if(err.response && err.response.data && err.response.data.message){
            msg = err.response.data.message;
          }
          if(err.response && err.response.data && err.response.data && err.response.data.walletDetail && err.response.data.walletDetail.message){
            msg = `${msg}    ${err.response.data.walletDetail.message}`
          }
          alert(msg)
        }
      };
      if(digitisationId)
        getWalletDetails();
      else{
        alert("Please enter digitisation id")
      }
    }, []);

  return (
    <Modal
      isOpen={true}
      style={{
        maxWidth: '600px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: '#f8f9fa',
      }}
    >
      <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={onClose}>
        <h4
          className="text-center"
          style={{ color: '#fff', fontWeight: 'bold' }}
        >
          Wallet Coverage Details
        </h4>
      </ModalHeader>
      <ModalBody
        style={{
          paddingTop: '20px',
          paddingBottom: '20px',
          paddingLeft: '40px',
          paddingRight: '40px',
        }}
      >
        <div className="wallet-container">
          {walletDetails ? (
            <>
              {walletDetails.digitisationDetail && (
                <div className="section">
                  <h3>Digitisation Detail</h3>
                  <div style={{ paddingLeft: '16px' }}>
                    <p>
                      <strong>User ID:</strong>{' '}
                      {walletDetails.digitisationDetail.userId}
                    </p>
                    <p>
                      <strong>Relative ID:</strong>{' '}
                      {walletDetails.digitisationDetail.relativeId}
                    </p>
                    <p>
                      <strong>Is Critical:</strong>{' '}
                      {walletDetails.digitisationDetail.isCritical
                        ? 'Yes'
                        : 'No'}
                    </p>
                    <p>
                      <strong>Sponsor Name:</strong>{' '}
                      {walletDetails.digitisationDetail.sponsorName}
                    </p>
                    <p>
                      <strong>Patient Name:</strong>{' '}
                      {walletDetails.digitisationDetail.patientName}
                    </p>
                  </div>
                </div>
              )}
              {walletDetails.walletCoverage && (
                <div className="section">
                  <h3>Wallet Coverage</h3>
                  <div style={{ paddingLeft: '16px' }}>
                    <p>
                      <strong>Base Wallet Coverage:</strong>{' '}
                      {walletDetails.walletCoverage.baseWalletCoverage ? walletDetails.walletCoverage.baseWalletCoverage : 0}
                    </p>
                    <p>
                      <strong>Top-Up Wallet Coverage:</strong>{' '}
                      {walletDetails.walletCoverage.topUpWalletCoverage ? walletDetails.walletCoverage.topUpWalletCoverage : 0}
                    </p>
                  </div>
                </div>
              )}

            </>
          ) : (
            <>Wallet Details Not Found</>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  user: state.user,
});

export default connect(mapStateToProps)(WalletCoverageModal);
