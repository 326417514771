import React from 'react';
import {useState} from 'react';
import '../../styling/doctor.css';

function SolidButton({ text, style, children, onClick }) {
  const [isClicked, setIsClicked] = useState(false);
  const handleClick = () => {
    setIsClicked(true);
    onClick(); // Call the onClick function passed as props
    setTimeout(() => {
      setIsClicked(false);
    }, 1000);
  };
  return (
    <button
      style={{
        borderRadius: `${style.radius}`,
        //border: `${style.border}`,
        border: isClicked ? '2px solid #8C89AA' : 'none',
        background: `${style.background}`,
        height: `${style.height}`,
        width: `${style.width}`,
        color: `${style.color}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: `${style.margin}`,
        padding: '8px',
        transition: 'border-color 0.3s ease',
      }}
      onClick={handleClick}
    >
      {children}
      <p className="solid-button-text" style={{ color: `${style.textColor}` }}>
        {text}
      </p>
    </button>
  );
}

export default SolidButton;
