import React, { Component } from 'react';
import Select from 'react-select';
import { Async } from 'react-select';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  getVerticals,
} from '../../services/api/offline-consult';

import moment from 'moment';
import LoadingComponent from '../common/LoadingComponent';
import { getAllSponsor } from '../../services/api/coupon';

// import { useLink } from 'valueLink';
// import { Input } from 'linked-controls';
 const forOption = [
  { label : 'All', value : null },
  {label: "labs", value : "labs"},
  {label: "online", value : "con-online"},
  { label: "offline", value : "con-offline"},
]

const discountTypeOptions = [
  {label: "Percentage", value : "%"},
  {label: "Net Amount", value : "-"},
]

class BulkCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      searchQuery: '',
      userId: null,
      bulkCount: 0,
      dateKey: null,
      verticalsResult: [],
      verticalLoading: false,
      showFormWarning: false,
      alertMessage: 'None',
      max_count: 0,
      loading: false,
      couponName: '',
      discountValue: 0,
      valid_for: null,
      verticalId: null,
    };
    this.sponsor = React.createRef();
    this.verticalSelectRef = React.createRef();
    this.sponsorSelectRef = React.createRef();
    this.discountTypeRef = React.createRef();
    this.forSelectRef = React.createRef();
    this.onAlertDismiss = this.onAlertDismiss.bind(this);
  }

  toggle = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  };

  onAlertDismiss() {
    this.setState({ showFormWarning: false });
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  componentWillMount() {
    this.fetchVerticals();
    this.fetchSponsors()
  }


  fetchVerticals = () => {
    this.setState({ verticalLoading: true });
    getVerticals()
      .then((response) => {
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value: element.verticalId, label: element.verticalName });
        });
        arr.push({ value: null, label: 'all' });
        this.setState({ verticalsResult: arr });
      })
      .finally(() => {
        this.setState({ verticalLoading: false });
      });
  };

  fetchSponsors = () => {
    this.setState({ sponsorLoading: true });
    getAllSponsor()
      .then((response) => {
        const arr = [];
        response.forEach((element) => {
          arr.push({ value: element.sponsorId, label: element.sponsorName });
        });
        arr.push({ value: null, label: 'all' });
        this.setState({ sponsorResult: arr });
      })
      .finally(() => {
        this.setState({ sponsorLoading: false });
      });
  };

  handleDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    this.setState({ dateKey });
  };

  handleBulkCount = (event) => {
    const count = event.target.value;
    console.log("changing bulk Count to ", count);
    if (!count) {
      return;
    }
    this.setState({ bulkCount : count });
  };

  handleCouponNameChange = (event) => {
    const name = event.target.value;
    console.log("changing coupon name to ", name);
    if (!name) {
      return;
    }
    this.setState({ couponName : name });
  };

  handleMaxCountChange = (event) => {
    const count = event.target.value;
    console.log("changing max_count to ", count);
    if (!count) {
      return;
    }
    this.setState({ max_count : count });
  };

  handleApplicableForChange = (event) => {
    const valid_for = event.value;
    console.log("changing for to ", valid_for);
    if (!valid_for) {
      return;
    }
    this.setState({ valid_for : valid_for });
  }

  handleVerticalChange = (event) => {
    const verticalId = event.value;
    console.log("changing vertical to ", verticalId);
    if (!verticalId) {
      return;
    }
    this.setState({ verticalId : verticalId });
  }

  handleDiscountValueChange = (event) => {
    const value = event.target.value;
    console.log("changing discount value to ", value);
    if (!value) {
      return;
    }
    this.setState({ discountValue : value });
  }

  handleBadFormInput = (dateKey, discountValue, couponName, discount_type, applicableFor, bulkCount) => {
    let alertMessage = null;
    if (!dateKey) {
      alertMessage = 'You need to select a date';
    }
    if(!discountValue) {
      alertMessage = 'You need to enter a discount value';
    }
    if(!couponName) {
      alertMessage = 'You need to enter a coupon name';
    }
    if(!discount_type) {
      alertMessage = 'You need to select a discount type';
    }
    // if(!applicableFor) {
    //   alertMessage = 'You need to select a applicable for';
    // }
    if(!bulkCount){
        alertMessage = 'You need to enter a Iterations count';
    }

    if(discount_type === '%' && discountValue > 100) {
      alertMessage = 'Discount value cannot be greater than 100';
    }

    if(discountValue < 0) {
      alertMessage = 'Discount value cannot be negative';
    }
    // show alert
    this.setState({ showFormWarning: true, alertMessage });
    if(alertMessage) {
      return true;
    }
    return false;
  };

  placeRequest = () => {
    const { dateKey, userId, max_count, discountValue, couponName, valid_for, verticalId, bulkCount } = this.state;
    const sponsorId = this.sponsorSelectRef.current.select.state.selectValue[0] && this.sponsorSelectRef.current.select.state.selectValue[0].value ? this.sponsorSelectRef.current.select.state.selectValue[0].value : null;
    const applicableFor = valid_for;
    const discount_type = this.discountTypeRef.current.select.state.selectValue[0] && this.discountTypeRef.current.select.state.selectValue[0].value ? this.discountTypeRef.current.select.state.selectValue[0].value : null;
      // show alert
    const result = this.handleBadFormInput(dateKey, discountValue, couponName, discount_type, applicableFor, bulkCount);
    if(result) {
      return;
    }
    this.setState({
      loading: true,
    });
    console.log(dateKey, userId, verticalId, sponsorId, max_count, applicableFor, discountValue, couponName, discount_type, bulkCount);
    this.props
      .submit(dateKey, userId, verticalId, sponsorId, max_count, applicableFor, discountValue, couponName, discount_type, bulkCount)
      .then(() => true)
      .finally(() => {
        this.setState({ loading: false, modalVisible: false });
      });
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };


 
  render() {
    const {
      verticalsResult,
      verticalLoading,
      sponsorResult,
      sponsorLoading,
      modalVisible,
      loading,
    } = this.state;
    const { showModal } = this;
    return (
      <div>
          <Button type="button" className="btn btn-success" onClick={showModal}>
          Bulk Create
        </Button>
        <Modal
          isOpen={modalVisible}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader className="bg-primary" toggle={this.toggle}>
            Place Request
          </ModalHeader>
          <ModalBody>
            <form>
            <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Coupon Name:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="text"
                      onChange={this.handleCouponNameChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
            <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Applicable for:</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={true}
                      ref={this.forSelectRef}
                      options={forOption}
                      onChange={this.handleApplicableForChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Select Vertical:</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={true}
                      ref={this.verticalSelectRef}
                      isDisabled = {this.state.valid_for =='labs' || this.state.valid_for == null}
                      onChange={this.handleVerticalChange}
                      options={verticalsResult}
                      isLoading={verticalLoading}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Expiry Date:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="date"
                      onChange={this.handleDateChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              {/* <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>User Id:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="text"
                      onChange={this.handleUserIdChange}
                    />
                  </Col>
                </Row>
              </FormGroup> */}
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>No of Coupons</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="number"
                      placeholder='Number of times one Coupon can be used'
                      onChange={this.handleMaxCountChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Discount type:</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={true}
                      ref={this.discountTypeRef}
                      options={discountTypeOptions}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Discount Value:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="number"
                      min={0}
                      onChange={this.handleDiscountValueChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Select Sponsor:</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={true}
                      ref={this.sponsorSelectRef}
                    
                      options={sponsorResult}
                      isLoading={sponsorLoading}
                    />
                  </Col>
                </Row>
              </FormGroup>
               <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Iteration Count:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="text"
                      onChange={this.handleBulkCount}
                      placeholder='Total Number of Distinct Coupons to be created'
                    />
                  </Col>
                </Row>
              </FormGroup>
            </form>
            <Alert
              color="warning"
              isOpen={this.state.showFormWarning}
              toggle={this.onAlertDismiss}
            >
              {this.state.alertMessage}
            </Alert>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <LoadingComponent />
            ) : (
              <Button color="primary" onClick={this.placeRequest}>
                Place Request
              </Button>
            )}
            <Button color="secondary" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default BulkCreate;
