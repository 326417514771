import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from '../layout/NavBar';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import ConsultationReimburseRequestsTable from './ConsultationReimburseRequestsTable';

class ConsultationReimburseRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
    };
  }

  onDatesChanged = (startDate, endDate) => {
    console.log(startDate, endDate);
    this.setState({ startDate, endDate });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <NavBar />
        </div>
        <div className="row mt-5 pt-1">
          <div className="col">
            <h2 className="pl-1">Consultation Reimbursement Request</h2>
          </div>
        </div>
        <ConsultationReimburseRequestsTable />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect(mapStateToProps)(ConsultationReimburseRequests);
