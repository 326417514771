import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from "../../layout/NavBar";
import { findUser, createTranscriptionRequest } from '../../../services/api/rx-transcription';
import {Button, Collapse, Card, CardBody, Modal, ModalBody, ModalFooter} from 'reactstrap'
import UserDetails from './UserDetails';
import LabRxRequestCreator from './LabRxFileUploader';
import LabRxFileUploader from './LabRxFileUploader';
import LabRxPatientDetails from './LabRxPatientDetails'
import LabRxAddressDetails from './LabRxAddressDetails'
import LabRxCollectionDetails from './LabRxCollectionDetails';

import { toast } from 'react-toastify';
import { setUserInfoForRxCreate, setCreatingLabRxPrescriptionId, setSelectedAddressRxDetailsForTranscription, setSelectedPatientRxDetailsForTranscription, setSelectedCollectionDateRxDetailsForTranscription } from "../../../actions";

class CreateTranscriptionRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userInfo: {},
            modal: false,
        };

        this.resetFields();
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    searchUser = (phone) => {
        console.log(phone, 'user phone');
        findUser(phone)
            .then((response) => {
                console.log(response.body, 'Response from finding the user');
                this.setState({
                    userInfo: response.body,
                });
                this.resetFields();
                this.props.dispatch(setUserInfoForRxCreate(response.body));
            })
            .catch((err) => {
               toast.error('User not found!');
            });
    };

    resetFields = () => {
        this.props.dispatch(setUserInfoForRxCreate({}));
        this.props.dispatch(setCreatingLabRxPrescriptionId({}));
        this.props.dispatch(setSelectedAddressRxDetailsForTranscription({}));
        this.props.dispatch(setSelectedPatientRxDetailsForTranscription({}));
        this.props.dispatch(setSelectedCollectionDateRxDetailsForTranscription({}));
    };

    createTranscriptionRequest = () => {
        this.toggle();
        console.log('Create transcription request', this.props.prescriptionId);
        console.log(this.props.patient, this.props.address, this.props.collectionDetails);
        if (this.props.patient.patientId && this.props.address.addressId && this.props.collectionDetails.date
             && this.props.prescriptionId && this.state.userInfo.authToken) {

            createTranscriptionRequest(this.props.patient.patientId, this.props.address.addressId, this.props.collectionDetails.slot.slotId, this.props.collectionDetails.date, this.props.prescriptionId, this.state.userInfo.authToken)
                .then((response) => {
                    console.log(response.body, 'Response from creating transcription');
                    if (response.body.message === 'success') {
                        toast.success('Transcription request created');
                        this.resetFields();
                    } else {
                        toast.error('Error in creating a new transcription request');
                    }
                })
                .catch((err) => {
                    console.error('Error', err);
                    toast.error('Error in creating a new transcription request');
                });
        }
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <NavBar />
                </div>
                <div className="row mt-5 pt-4">
                    <div className="col">
                        <h4>Create New Transcription Request</h4>
                    </div>
                </div>
                <UserDetails getUser={this.searchUser} disabled={!(this.state.userInfo.userId > 0)}/>
                <LabRxFileUploader disabled={!(this.state.userInfo.userId > 0)} />
                <LabRxPatientDetails disabled={!(this.state.userInfo.userId > 0)} />
                <LabRxAddressDetails disabled={!(this.state.userInfo.userId > 0)} />
                <LabRxCollectionDetails disabled={!(this.state.userInfo.userId > 0)} />

                <div className="row">
                    <div className="col">
                        <button onClick={this.toggle} disabled={!(this.state.userInfo.userId > 0)} className="btn btn-success"  style={{ marginBottom: '1rem' }}>Create Transcription Request</button>
                    </div>
                </div>
                <div>
                    {/*<button onClick={this.toggle} className="btn btn-danger">Not Serviceable</button>*/}
                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                        <h4 className="mt-1 mb-0 ml-2">Confirmation</h4>
                        <ModalBody>
                            <p>Are you sure you want to create a new transcription request?</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.createTranscriptionRequest}>Yes</Button>{' '}
                            <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    patient: state.createLabRxDetails.patient,
    address: state.createLabRxDetails.address,
    collectionDetails: state.createLabRxDetails.collectionDetails,
    prescriptionId: state.createLabRxDetails.newRequestPrescriptionId
});

export default connect(
    mapStateToProps,
)(CreateTranscriptionRequest);





