import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { listSlots } from '../../../services/api/rx-transcription';
import { toast } from 'react-toastify';
import { setSelectedCollectionDateRxDetailsForTranscription } from "../../../actions";

class LabRxCollectionDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { collapse: false, days: [], selectedSlot: null };
    }

    toggle = () => {
        this.setState(state => ({ collapse: !state.collapse }));
        if (!this.collapse) {
            this.getAvailableSlots();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userInfo.userId !== this.props.userInfo.userId) {
            this.setState(state => ({ collapse: false }));
        }
    }

    getAvailableSlots = () => {
        listSlots(this.props.userInfo.authToken)
            .then((response) => {
                console.log(response);
                if(response.body.message === 'success') {
                    this.setState({
                        days: response.body.days,
                    });
                } else {
                    toast.error('Error in listing slots');
                }
            })
            .catch((err) => {
                toast.error('Error in listing slots');
                console.error('Error', err);
            });
    };

    batchSlots = (myArray) => {
        let index = 0;
        let arrayLength = myArray.length;
        let tempArray = [];
        let myChunk;
        const chunk_size = 2;
        for (index = 0; index < arrayLength; index += chunk_size) {
            myChunk = myArray.slice(index, index+chunk_size);
            // Do something if you want with the group
            tempArray.push(myChunk);
        }

        return tempArray;
    };

    selectSlot = (slot, day) => {
        console.log('selected slot', slot, day);
        const days = this.state.days;
        const currDay = days.find((d) => d.date === day.date);
        console.log(currDay, 'Current day');
        this.props.dispatch(setSelectedCollectionDateRxDetailsForTranscription({slot, date: day.date}));
        for (let i = 0; i < days.length; i++) {
            if (days[i].date === day.date) {
                for (let j = 0; j < days[i].timeCategory.length; j++) {
                    for (let k = 0; k < days[i].timeCategory[j].timeSlots.length; k++) {
                        if (days[i].timeCategory[j].timeSlots[k].slotId === slot.slotId) {
                            days[i].timeCategory[j].timeSlots[k].active = true;
                        } else {
                            days[i].timeCategory[j].timeSlots[k].active = false;
                        }
                    }
                }
            } else {
                for (let j = 0; j < days[i].timeCategory.length; j++) {
                    for (let k = 0; k < days[i].timeCategory[j].timeSlots.length; k++) {
                        days[i].timeCategory[j].timeSlots[k].active = false;
                    }
                }
            }
        }
        this.setState({
            days,
        });
    };

    renderDay = (day) => {
        const slots = [];
        day.timeCategory.forEach((cat) => {
            cat.timeSlots.forEach((s) => {
                slots.push(s);
            });
        });
        console.log('Slots', slots, day);
        const batch = this.batchSlots(slots);
        console.log('batched', this.batchSlots(slots));

        return batch.map((b, index) => {
            return (
                <tr key={index}>
                    {b.map((s) => {
                        return (
                            <td className={s.active ? 'bg-primary text-white':''} onClick={() => this.selectSlot(s, day)} key={s.slotId} scope="row">{s.label}</td>
                        );
                    })}
                </tr>
            );
        })

    };

    renderSlots = () => {

        return this.state.days.map((d, index) => {

            return (
                <div className="col" key={index}>
                    <h4>{d.date}</h4>
                    <table className="table">
                        <tbody>
                        {this.renderDay(d)}
                        </tbody>
                    </table>
                </div>
            );
        });
    };


    render() {
        return (
            <div className="row">
                <div className="col">
                    <div className="row">
                        <button disabled={this.props.disabled} className="btn btn-primary" onClick={this.toggle} style={{ marginBottom: '1rem' }}>Collection & Slot Details</button>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Collapse isOpen={this.state.collapse}>
                                <Card>
                                    <CardBody>
                                        <div className="row">
                                            {this.renderSlots()}

                                        </div>


                                    </CardBody>
                                </Card>
                            </Collapse>
                        </div>
                    </div>
                </div>



            </div>

        );
    }
}

const mapStateToProps = state => ({ userInfo: state.createLabRxDetails.userInfo });

export default connect(
    mapStateToProps,
)(LabRxCollectionDetails);





