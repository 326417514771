import baseRequest from './baseRequest';

const baseURL = `/new-ops/policy-details`;

export const getPolicyDetails = (params) => {
  const url = baseURL;
  const method = 'get';
  return baseRequest(url, method, params, null);
};

export const updateRelativeInfo = (userId, phone, relativeId, name, relationId,dob) => {
  const url = `${baseURL}/update-relative-info`;
  const method = 'POST';
  return baseRequest(url, method, null, { userId, phone, relativeId,name,relationId,dob })
};

export const getStarhealthWalletBalance = (params) => {
  const url = `/star-health/cashless-booking/get-eligibile-wallet-balance`;
  const method = 'get';
  return baseRequest(url, method, params, null);
};