import React, { Component } from 'react';
import { connect } from 'react-redux';

// Layouts
import NavBar from '../layout/NavBar';
import loginCheck from '../login/LoginCheck';
import DoctorLiveStatsBody from './DoctorLiveStatsBody';

class DoctorLiveStats extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <React.Fragment>
        <NavBar />
        <DoctorLiveStatsBody />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  offlineConsult: state.offlineConsult,
});

export default connect(mapStateToProps)(loginCheck(DoctorLiveStats));
