import React from 'react';

export default class OnboardingStatusFilter extends React.Component {
  onChange = e => {
    this.props.onChange(e.target.value);
  };

  render() {
    return (
      <select onChange={this.onChange} value={this.props.value}>
        <option value="">All</option>
        <option value="pending">Pending</option>
        <option value="live">Live</option>
        <option value="not-live">Not Live</option>
      </select>
    );
  }
}
