import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  Container,
  Label,
  Spinner
} from 'reactstrap';

import { toast } from 'react-toastify';

class OfflineModalCancel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      userComms:'',
      remarks: '',
      applyCancellation: false,
      subCancelReason:'',
      loader:false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.requestId !== this.props.requestId) {
      this.setState({
        value: '',
        userComms : '',
        remarks: '',
        applyCancellation: false,
      });
    }
  }



  handleChange = e => {
    const { value } = e.target;

    const { reason , userComms } = JSON.parse(value);

    this.setState({
      value : reason,
      userComms : userComms,
      subCancelReason:'',
      remarks: '',
    });
  };

  handleSubReasonChange = (e) => {
    this.setState({subCancelReason:e.target.value});
  }

  handleRemarksInputChange = e => {
    this.setState({ remarks: e.target.value });
  };

  handleSubmit = () => {
    const { value, remarks, applyCancellation, subCancelReason, userComms} = this.state;
    this.setState({
      loader:true
    })
    console.log({ value, remarks, applyCancellation,userComms },
        '[handleSubmit]');
    if(value==="User Requested" && subCancelReason === "Others" && !remarks){
      toast.error('Please enter remarks.');
      this.setState({
        loader:false
      })
      return;
    }
    if(value==="User Requested" && !subCancelReason){
      toast.error('Please select a reason to continue');
      this.setState({
        loader:false
      })
      return;
    }
    if(value==="User Requested" && subCancelReason != "Others"){
      return this.props.submitModal(value, remarks, applyCancellation, subCancelReason, userComms)
      .then(() => {
        this.setState({
          loader:false
        })
        this.props.closeModal();
        this.props.onComplete();
      });
    }
    if (!value || !remarks) {
      toast.error('No Value selected');
      this.setState({
        loader:false
      })
      return;
    }
    return this.props.submitModal(value, remarks, applyCancellation,null, userComms)
        .then(() => {
          this.setState({
            loader:false
          })
          this.props.closeModal();
          this.props.onComplete();
        });
  };

  handleApplyCancellation = () => {
    this.setState({
      applyCancellation: !this.state.applyCancellation,
    });
  };

  getRemarksSelectOptions = () => {
    const { value } = this.state;
    switch (value) {
      case "Doctor Unavailable": {
        return [ "",  "Profiling Error", "Doctor On Leave", "All slots booked", "Doctor went for emergency/IPD", "Corona", "Doctor Unavailable at requested location" ];
      }
      case "Doctor Couldn’t get onboarded": {
        return [ "", "doctor didn’t respond to our calls", "Denied 3rd party payment" ];
      }
      default:
        return [];
    }
  };

  render() {
    const { value, remarks,subCancelReason } = this.state;
    const remarksSelectOption = this.getRemarksSelectOptions();
    console.log(value,subCancelReason,"value")
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        toggle={() => this.props.closeModal()}
        className={this.props.className}
      >
        <ModalHeader className="bg-primary" toggle={this.props.closeModal}>
          Sure you want to cancel?
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <Row>
                  <label>Reason for cancellation:</label>
                </Row>
                <br />
                <Row>
                  <div>

              <select
              style={{
                width: '100%',
                height: '34px',
                fontSize: '16px',
              }}
                onChange={this.handleChange} value={value.reason} 
              >
                <option value="">Select Option</option>
                {this.props.cancelledReasons.map((data,index) =>
                   ( <option value={JSON.stringify(data)} >{data.reason}</option>)
                )}
              </select>

                  </div>
                </Row>
                <br />
                {value === 'User Requested' && (
                  <>
                    <Row>
                      <div>
                        <select
                          onChange={this.handleSubReasonChange}
                          value={subCancelReason}
                        >
                          <option value="">Select Reason</option>
                          <option value="User want to book for alternate center">
                            User want to book for alternate center
                          </option>
                          <option value="User will book for alternate doctor">
                            User will book for alternate doctor
                          </option>
                          <option value="User not ready to pay revised fee">
                            User not ready to pay revised fee
                          </option>
                          <option value="User don't want to pay registration fee">
                            User don't want to pay registration fee
                          </option>
                          <option value="Others">Others</option>
                        </select>
                      </div>
                    </Row>
                    <br />
                  </>
                )}
                {!(
                  value === 'User Requested' && subCancelReason != 'Others'
                ) && (
                  <>
                    <Row>
                      <div>
                        <React.Fragment>
                          {remarksSelectOption && remarksSelectOption.length ? (
                            <select
                              value={remarks}
                              onChange={this.handleRemarksInputChange}
                            >
                              {remarksSelectOption.map((option) => (
                                <option value={option}>
                                  {option || 'Select Remark'}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              onChange={this.handleRemarksInputChange}
                              value={remarks}
                              placeholder="Please enter remarks"
                            />
                          )}
                        </React.Fragment>
                      </div>
                    </Row>
                    <br />
                  </>
                )}
                <Row>
                  <Col md={{ size: 5 }}>
                    <Label check>
                      <Input
                        type="checkbox"
                        onClick={this.handleApplyCancellation}
                      />{' '}
                      Apply Cancellation Fee
                    </Label>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={this.handleSubmit}
            disabled={this.state.loader}
          >
            {this.state.loader && (
              <Spinner
                style={{ height: '16px', width: '16px', marginRight: '5px' }}
              />
            )}
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default OfflineModalCancel;
