import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from "../layout/NavBar";
import { searchTests, addTestInOrder, addTestToCart, removeTestFromCart } from '../../services/api/get-labs-orders';
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/lib/AsyncCreatable';
import { toast } from 'react-toastify';
import {setUserInfoForRxCreate} from "../../actions";
import AddExistingTestToPartner from './AddExistingTestToPartner';


class LabCart extends Component {

    constructor (props) {
        super(props);
        this.state = this.makeDefaultState();
    }

    makeDefaultState = () => ({
        searchResult: [],
        openAddExistingTestToPartner: 0,
        selectedTestName: null,
    });

    removeTest = (test) => {
        console.log('Test', test);
        removeTestFromCart(test.labId, this.props.cartId, test.cartItemId)
            .then((response) => {
                console.log(response.body, 'Response from removing a test');
                if (response.body.message === 'success') {
                    toast.success(`Removed ${test.labName ? test.labName : test.name} from the cart`);
                    this.props.loadCart();
                } else {
                    toast.error(`Error in removing the test ${test.labName ? test.labName : test.name}`);
                }
            })
            .catch((err) => {
                console.error('Error in removing the test', err);
                toast.error(`Error in removing the test ${test.labName ? test.labName : test.name}`);
            })
    };

    editTest = (test) => {
        console.log('Editing test', test);
        this.props.openEditTestModal(test);
    };


    renderCartItems = () => {
        console.log(this.state, 'State');
        const { cartItemsResult } = this.props;
        return (cartItemsResult && cartItemsResult.itemsList && cartItemsResult.itemsList.map((test, index) => {
            return (
                <tr key={index}>
                    <th scope="row">{index+1}</th>
                    <td>{test.labName ? test.labName : test.name}</td>
                    <td>{test.mrp}</td>
                    <td><span>{test.postDiscountPrice} + (<span style={{ color: 'green' }}>{test.pendingCopayAmount}</span> - <span style={{ color: 'red' }}>{test.pendingRefundAmount}</span>)</span></td>
                    <td>{test.cost}</td>
                    <td>{test.walletDeduction}</td>
                    <td>
                        {this.props.delete ? (<button className="btn btn-sm btn-danger" onClick={() => this.removeTest(test)}>Remove</button>) : ''}
                        {this.props.edit ? (<button className="ml-1 btn btn-sm btn-info" onClick={() => this.editTest(test)}>Edit</button>): ''}
                    </td>
                </tr>
            );
        }));
    };

    loadOptions = (input) => {
        return searchTests(input, this.props.testType)
            .then((response) => {
                console.log('Response from searching/loading test names', response);
                return response.body.labs.map(t => {
                    return {
                        label: t.name,
                        value: t.id,
                    };
                });
            })
            .catch((err) => {
                console.error('Error in loading test names', err);
                toast.error('Error in loading test names');
            });
    };

    promiseOptions = (input) => {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(this.loadOptions(input.toLowerCase()));
            }, 500);
        })
    };

    onAddingExistingTestForPartner = (testName) => {
        // addExistingTestForPartner(this.props.manualPartnerId)
        this.setState({
            openAddExistingTestToPartner: this.state.openAddExistingTestToPartner + 1,
            selectedTestName: testName,
        });
    };

    onTestSelectChange = (option, action) => {
        console.log(this.props.orderId, 'OrderID for this cart');
        console.log(option, action);
        if (action.action === 'select-option') {
            // Check if this lab is present cart
            // Add this option to the list
            const selectedItemInCart = this.props.cartItemsResult.itemsList.find(o => o.labId === option.labId);
            if (selectedItemInCart) {
                console.log('Test already present in the cart');
                return;
            } else {
                // Add this test to the cart and reload the cart
                if (this.props.orderId) {
                    console.log('Order already exisits, simply adding to the final order', this.props.orderId);
                    addTestInOrder(this.props.orderId, option.value)
                        .then((response) => {
                            if (response.body.message === 'success') {
                                // Reload the cart
                                this.props.loadCart();
                                toast.success('Test Added successfully!');
                            } else {
                                toast.error(`Error - ${response.body.errorMessage}`);
                            }
                        })
                        .catch((err) => {
                            console.error('Error in adding a test to the order', err);
                            toast.error(`Error - ${err.response.data.errorMessage}`);
                        });
                } else {
                    console.log(this.props.manualPartnerId, 'Manual partner id passing in LabCart');
                    if (this.props.manualPartnerId === -1) {
                        addTestToCart(option.value, this.props.cartId)
                        .then((response) => {
                            console.log('Response from adding a test to the cart', response.body);
                            if (response.body.message === 'success') {
                                // Reload the cart
                                this.props.loadCart();
                                toast.success('Test Added successfully!');
                            } else {
                                toast.error(`Error - ${response.body.errorMessage}`);
                            }
                        })
                        .catch((err) => {
                            console.error('Error in adding a test to the cart', err);
                            toast.error(`Error - ${err.response.data.errorMessage}`);
                        });
                    } else {
                        // This is a manual partner, so selecting an existing requires OPS team
                        // to add the cost details about this test
                        // Open up add existing test to this partner dialog with cost details
                        this.onAddingExistingTestForPartner(option.label);
                    }
                }
            }
        } else if (action.action === 'create-option') {
            // Open the new test dialog
            // console.log(this.props.openNewTestModal, 'Handler');
            if (this.props.orderId) {
                toast.error('You cannot add unknown tests to placed orders.');
                return;
            }
            this.props.openNewTestModal();
        }
    };

    renderAddItemsInput = () => {
        if (this.props.add) {
            return (
                <AsyncCreatableSelect
                    // options={dosageOptions}
                    // ref={this.medicineSelectRef}
                    placeholder='Search Tests'
                    isClearable={true}
                    defaultOptions={this.state.searchResult}
                    loadOptions={this.promiseOptions}
                    onChange={(options, action) => this.onTestSelectChange(options, action)}
                    formatCreateLabel={(input) => `Add Test: '${input}'`}

                />
            );
        }
        return '';
    };

    render() {
        const { cartItemsResult,totalObject } = this.props;

        return (
            <div className="container-fluid">
                <h4>User's Cart</h4>

                {this.renderAddItemsInput()}

                <table className="table table-bordered thead-dark">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Test Name</th>
                            <th scope="col">MRP</th>
                            <th scope="col">Final Price</th>
                            <th scope="col">Net Cost(Not for user)</th>
                            <th scope="col">Wallet Deduction</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                    {this.renderCartItems()}
                    <tr>
                        <td colspan="2" class ="text-center"><b>Total</b></td>
                        <td><b>{totalObject.mrp}</b></td>
                        <td><b>{totalObject.postDiscountPrice}</b></td>
                        <td><b>{totalObject.cost}</b></td>    
                        <td><b>{totalObject.walletDeduction}</b></td>

                    </tr>
                    <tr>
                        <td colSpan="3">OPD Benefits Applied</td>
                        <td>{(cartItemsResult && (cartItemsResult.discountType === 'opd') ? 'YES' : 'NO')}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Pickup Charge</td>
                        <td>{(cartItemsResult) && (cartItemsResult.deliveryCost)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">Payeable Amount</td>
                        <td>{(cartItemsResult) && (cartItemsResult.payableByUser)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">HC to Vendor</td>
                        <td>{(cartItemsResult) && (cartItemsResult.hcToVendor)}</td>
                    </tr>
                    <tr>
                        <td colSpan="3">wallet Deduction For HC</td>
                        <td>{(cartItemsResult) && (cartItemsResult.walletDeductionForHC)}</td>
                    </tr>
                    </tbody>
                </table>
                
                <AddExistingTestToPartner partnerId={this.props.manualPartnerId} open={this.state.openAddExistingTestToPartner} selectedTestName={this.state.selectedTestName} testType={this.props.testType} cartId={this.props.cartId} loadCart={this.props.loadCart}/>
            </div>

        );
    }
}

const mapStateToProps = state => ({ cartItemsResult: state.labsDashboard.cartItemsResult });

export default connect(
    mapStateToProps,
)(LabCart);





