import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import ReactTable from 'react-table';
import {
  getOrders,
  saveRemark,
  uploadReceiptApi,
  markSampleCollectedForBulkOrders,
} from '../../services/api/get-labs-orders';
import { debounce } from '../../utils/debounce';
import { toast } from 'react-toastify';
import {
  MdOpenInBrowser,
  MdCloudUpload,
  MdCloudDownload,
} from 'react-icons/md';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import LabRequestPayoutConfirmation from './LabRequestPayoutConfirmation';
import LabsClickToCallButton from './LabsClickToCallButton';
import LabOrderTableSubComponent from './LabOrderTableSubComponent';
import Truncate from './Truncate';
import OrderActionButton from './OrderActionButton';
import OrderActionModal from './OrderActionModal';
import { Button } from 'reactstrap';
import Modal from '../common/Modal';
import LabOrderCovidPartnerChangeForm from './LabOrderCovidPartnerChangeForm';
import { FaClosedCaptioning } from 'react-icons/fa';

class LabOrdersTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pages: -1,
      filtered: [],
      sorted: [],
      pageSize: 50,
      page: 0,
      orders: [],
      allStatus: [
        'Placed',
        'Sample Collected',
        'Completed',
        'Cancelled',
        'Delayed Sample Pickup',
        'Delayed Report',
        'Partial Report Generated',
      ],
      actionModal: {
        orderId: null,
        isOpen: false,
        actionName: null,
        status: null,
        actionType: null,
        collectionDate: null,
        slotId: null,
      },
      selectedOrderIds: [],
      showPartnerChangeModal: false,
      showConfirmationModal: false,
      totalCount: 0,
    };

    this.fetchStrategy = this.fetchStrategy.bind(this);

    this.fetchData = this.fetchData.bind(this);
    this.fetchDataWithDebounce = debounce(this.fetchData, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('New props vs old props', prevProps, this.props);
    if (this.props.startDate === null || this.props.endDate === null) {
      if (
        this.props.startDateCollection === null ||
        this.props.endDateCollection === null
      ) {
        return;
      }
    }
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      let stDate = 0,
        enDate = 0;
      if (this.props.startDate !== null) {
        stDate = this.props.startDate.startOf('day').unix();
      }
      if (this.props.endDate !== null) {
        enDate = this.props.endDate.endOf('day').unix();
      }

      this.updateStateFilters('reqStartDate', stDate, 'reqEndDate', enDate);
    }

    if (
      prevProps.startDateCollection !== this.props.startDateCollection ||
      prevProps.endDateCollection !== this.props.endDateCollection
    ) {
      let stDate = 0,
        enDate = 0;
      if (this.props.startDateCollection !== null) {
        stDate = this.props.startDateCollection.startOf('day').unix();
      }
      if (this.props.endDateCollection !== null) {
        enDate = this.props.endDateCollection.endOf('day').unix();
      }

      this.updateStateFilters(
        'collectionStartDate',
        stDate,
        'collectionEndDate',
        enDate
      );
    }
  }

  updateStateFilters = (id, value, id2, value2) => {
    console.log('Update all filters in state', { id, value, id2, value2 });
    // console.log('New filter values', moment(value).format('DD-MM-YYYY'), moment(value2).format('DD-MM-YYYY'));
    const filter = this.state.filtered.find((f) => f.id === id);
    const filter2 = this.state.filtered.find((f) => f.id === id2);
    if (filter) {
      filter.value = value;
    } else {
      this.state.filtered.push({ id, value });
    }
    if (filter2) {
      filter2.value = value2;
    } else {
      this.state.filtered.push({ id: id2, value: value2 });
    }

    this.setState({
      filtered: JSON.parse(JSON.stringify(this.state.filtered)),
    });
  };

  saveRemark = (order, remark) => {
    // console.log(order, remark);
    saveRemark(order.original.orderId, remark)
      .then((result) => {
        if (result.body.message === 'success') {
          toast.success(`Comment Saved`);
          const newState = Array.from(this.state.orders);
          newState[order.index].remarks = remark;
          // Update that order
          this.setState({
            orders: newState,
          });
        } else {
          toast.error(`Error in Saving comment`);
        }
      })
      .catch((err) => {
        console.log('Error in API', err);
        toast.error(`Error in Saving comment`);
      });
  };

  uploadReceipt = (row, file) => {
    uploadReceiptApi(file, row.original.orderId, row.original.userId)
      .then((result) => {
        console.log('Result from uploading the receipt', result);
        if (result.body.message === 'success') {
          toast.success(`Receipt Uploaded`);
          const newState = Array.from(this.state.orders);
          newState[row.index].receiptUrl = result.body.fileUrl;
          // Update that order
          this.setState({
            orders: newState,
          });
        } else {
          toast.error(`Error in Saving comment`);
        }
      })
      .catch((err) => {
        console.error('Error in uploading receipt', err);
        toast.error(`Error in uploading receipt`);
      });
  };

  onFileInputChanged = (row, event) => {
    console.log('File input state changed', row);
    console.log(event.target.files[0]);
    this.uploadReceipt(row, event.target.files[0]);
  };

  onFilteredChange = (e) => {
    this.setState({ filtered: e });
  };

  fetchStrategy = (state) => this.fetchDataWithDebounce(state);

  prepareApiFilters = () => {
    const appliedFilters = {};
    // let appliedOrderBy = {};
    // Convert this list of filterables into object properties
    this.state.filtered.forEach(
      (filter) => (appliedFilters[filter.id] = filter.value)
    );

    appliedFilters.rowsPerPage = this.state.pageSize;
    appliedFilters.page = this.state.page + 1;
    return appliedFilters;
  };

  fetchData = (state, instance) => {
    console.log(state, instance, '[fetchData]');
    // show the loading overlay
    this.setState(
      {
        sorted: state.sorted,
      },
      this.fetchOrders
    );
  };

  fetchOrders = () => {
    this.setState({ loading: true });
    const appliedFilters = this.prepareApiFilters();
    console.log(
      '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>..',
      appliedFilters
    );
    // fetch your data
    getOrders(appliedFilters, this.state.sorted)
      .then((response) => {
        // console.log('Api response', response.body);
        this.setState({ totalCount: response.body.data.totalCount });
        const pages =
          response.body.data.totalCount % appliedFilters.rowsPerPage === 0
            ? parseInt(
                response.body.data.totalCount / appliedFilters.rowsPerPage
              )
            : parseInt(
                response.body.data.totalCount / appliedFilters.rowsPerPage
              ) + 1;
        this.setState({
          orders: response.body.data.result,
          loading: false,
          pages,
        });
      })
      .catch((err) => {
        console.error(err, 'Error in get labs orders API');
        toast.error(`Error in fetching orders`);
      });
  };

  downloadTestWiseReport = () => {
    const appliedFilters = this.prepareApiFilters();
    appliedFilters.csv = 'testwise';
    console.log({ appliedFilters }, 'Applied filters');
    // fetch your data
    getOrders(appliedFilters, this.state.sorted, true);
  };
  downloadOrderWiseReport = () => {
    const appliedFilters = this.prepareApiFilters();
    appliedFilters.csv = 'orderwise';
    console.log({ appliedFilters }, 'Applied filters');
    // fetch your data
    getOrders(appliedFilters, this.state.sorted, true);
  };

  getColumns = () => [
    {
      Header: (
        <div className="container">
          <div className="row justify-content-start">
            <div className="col-4">Total Records: {this.state.totalCount}</div>
            <div className="col-4">
              <Button
                outline
                color="primary"
                onClick={this.downloadOrderWiseReport}
              >
                Order Wise
              </Button>
              <Button
                outline
                color="secondary"
                onClick={this.downloadTestWiseReport}
              >
                Test Wise
              </Button>
              {this.state.selectedOrderIds.length > 0 ? (
                <Button
                  outline
                  color="primary"
                  onClick={this.openPartnerChangeModal}
                >
                  Change Partner
                </Button>
              ) : (
                ''
              )}
              {this.state.selectedOrderIds.length > 0 ? (
                <Button
                  outline
                  color="primary"
                  onClick={this.openConfirmationModal}
                >
                  Mark Sample Picked Up
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ),
      columns: [
        {
          header: '',
          accessor: 'orderId',
          width: 40,
          Cell: (row) => {
            return row.original && row.original.highRiskAlert === 'yes' ? (
              <input
                type="checkbox"
                onChange={() => this.handleOrderSelect(row.original.orderId)}
                checked={this.state.selectedOrderIds.includes(
                  row.original.orderId
                )}
              />
            ) : (
              ''
            );
          },
        },
        {
          Header: 'Order ID',
          accessor: 'orderId',
          filterable: true,
          width: 75,
          Filter: ReactTableInputFilter,
          Cell: (row) => {
            const { orderId, apiError } = row.original;
            return (
              <Link
                title={orderId}
                target="_blank"
                to={`/lab-order-details-v2?orderId=${orderId}`}
                class={apiError === 'yes' ? 'text-danger' : ''}
              >
                {orderId}
              </Link>
            );
          },
        },
        {
          Header: 'Booking Date',
          accessor: 'bookingEpoch',
          width: 120,
          Cell: (row) => (
            <span>
              {moment(row.original.bookingEpoch * 1000).format('Do MMM')}
            </span>
          ),
        },
        {
          Header: 'Collection Date',
          accessor: 'collectionDate',
          width: 120,
          Cell: (row) => (
            <span>
              {moment(row.original.collectionDate, 'YYYY-MM-DD').format(
                'Do MMM'
              )}
            </span>
          ),
        },
        {
          Header: 'Slot',
          accessor: 'slot',
          width: 120,
          Cell: (row) => <span>{row.original.slotTime}</span>,
        },
        {
          Header: 'Test Type',
          accessor: 'testsType',
          filterable: true,
          width: 100,
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            }
            return row.status === filter.value;
          },
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">Show All</option>
                <option value="pathology">Pathology</option>
                <option value="radiology">Radiology</option>
                <option value="ahc">AHC</option>
              </select>
            );
          },
        },
        {
          Header: 'Partner',
          accessor: 'partnerName',
          width: 100,
          filterable: true,
          Filter: ReactTableInputFilter,
        },
        {
          Header: 'Status',
          accessor: 'status',
          filterable: true,
          width: 100,
          Cell: (row) => {
            if (row.original.status === 'Completed') {
              return (
                <a href={row.original.reportUrl} target="_blank">
                  <MdOpenInBrowser />
                  {row.original.status}
                </a>
              );
            }
            return <span>{row.original.status}</span>;
          },
          filterMethod: (filter, row) => {
            if (filter.value === 'all') {
              return true;
            }
            return row.status === filter.value;
          },
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">Show All</option>
                <option value="Completed">Completed</option>
                <option value="Placed">Placed</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Confirmed">Confirmed</option>
                <option value="Sample Collected">Sample Collected</option>
                <option value="Pending Copay">Pending Copay</option>
                <option value="Delayed Sample Pickup">
                  Delayed Sample Pickup
                </option>
                <option value="Delayed Report">Delayed Report</option>
                <option value="Partial Report Generated">
                  Partial Report Generated
                </option>
              </select>
            );
          },
        },
        {
          Header: 'Action',
          width: 130,
          Cell: (row) => (
            <OrderActionButton
              orderId={row.original.orderId}
              collectionDate={row.original.collectionDate}
              status={row.original.status}
              slotId={row.original.slotId}
              actions={row.original.actions}
              onClick={this.handleOpenActionModal}
              apiError={row.original.apiError}
            />
          ),
        },
        {
          Header: 'Assigned To',
          accessor: 'assignedTo',
          filterable: true,
          Filter: ReactTableInputFilter,
        },
        {
          Header: 'Priority',
          accessor: 'priority',
          filterable: true,
          width: 100,
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">Show All</option>
                <option value="urgent">Urgent</option>
                <option value="normal">Normal</option>
              </select>
            );
          },
        },
        {
          Header: 'Order Type',
          accessor: 'cartType',
          filterable: true,
          width: 70,
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">All</option>
                <option value="visit">OPD</option>
                <option value="offline">Rx Order</option>
                <option value="manual">CAMP</option>
                <option value="search">Search</option>
              </select>
            );
          },
        },
        {
          Header: 'Center Visit',
          accessor: 'isCenterVisit',
          width: 50,
          className: 'text-center',
          Cell: (row) => (row.original.isCenterVisit ? 'Yes' : 'No'),
        },
        {
          Header: 'Receipt Url',
          accessor: 'receiptUrl',
          width: 70,
          filterable: true,
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">All</option>
                <option value="i">Invoiced</option>
                <option value="n">Not Present</option>
              </select>
            );
          },
          Cell: (row) => {
            if (row.original.receiptUrl === null) {
              return (
                <div className="d-flex align-items-center justify-content-between">
                  <a
                    href={row.original.receiptUrl}
                    target="_blank"
                    style={{ visibility: 'hidden' }}
                  >
                    <MdCloudDownload style={{ visibility: 'hidden' }} />
                  </a>
                  <div
                    style={{
                      position: 'relative',
                      overflow: 'hidden',
                      cursor: 'pointer',
                    }}
                  >
                    <MdCloudUpload style={{ cursor: 'pointer' }} />
                    <input
                      type="file"
                      style={{
                        position: 'absolute',
                        opacity: 0,
                        right: 0,
                        top: 0,
                        cursor: 'pointer',
                      }}
                      onChange={(e) => this.onFileInputChanged(row, e)}
                      placeholder="Upload"
                    />
                  </div>
                </div>
              );
            }
            return (
              <div className="d-flex align-items-center justify-content-between">
                <a href={row.original.receiptUrl} target="_blank">
                  <MdCloudDownload />
                </a>
                <div
                  style={{
                    position: 'relative',
                    overflow: 'hidden',
                    cursor: 'pointer',
                  }}
                >
                  <MdCloudUpload style={{ cursor: 'pointer' }} />
                  <input
                    type="file"
                    style={{
                      position: 'absolute',
                      opacity: 0,
                      right: 0,
                      top: 0,
                      cursor: 'pointer',
                    }}
                    onChange={(e) => this.onFileInputChanged(row, e)}
                    placeholder="Upload"
                  />
                </div>
              </div>
            );
          },
        },
        {
          expander: true,
          Header: () => <strong>More</strong>,
          width: 65,
          Expander: ({ isExpanded, ...rest }) => (
            <div>
              {isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}
            </div>
          ),
          style: {
            cursor: 'pointer',
            fontSize: 25,
            padding: '0',
            textAlign: 'center',
            userSelect: 'none',
          },
        },
      ],
    },
    {
      Header: 'User Details',
      columns: [
        {
          Header: 'User Name',
          accessor: 'userName',
          filterable: true,
          Filter: ReactTableInputFilter,
        },
        {
          Header: 'User phone',
          accessor: 'userPhone',
          width: 140,
          filterable: true,
          Filter: ReactTableInputFilter,
          Cell: (row) => {
            return (
              <LabsClickToCallButton
                orderId={row.original.orderId}
                phone={row.original.userPhone}
              />
            );
          },
        },
        {
          Header: 'Sponsor Name',
          accessor: 'sponsorName',
          width: 100,
          filterable: true,
          Filter: ReactTableInputFilter,
        },
      ],
    },
    {
      Header: 'Patient Details',
      columns: [
        {
          Header: 'Patient Name',
          accessor: 'patientName',
          filterable: true,
          Filter: ReactTableInputFilter,
        },
        {
          Header: 'Patient Phone',
          accessor: 'patientPhone',
          Cell: (row) => {
            return (
              <LabsClickToCallButton
                orderId={row.original.orderId}
                phone={row.original.patientPhone}
              />
            );
          },
        },
      ],
    },
    {
      Header: 'High Risk Test',
      accessor: 'highRiskAlert',
      filterable: true,
      width: 100,
      Filter: ({ filter, onChange }) => {
        return (
          <select
            onChange={(event) => onChange(event.target.value)}
            style={{ width: '100%' }}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Show All</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        );
      },
    },
    {
      Header: 'Credit',
      accessor: 'isCredit',
      filterable: false,
      Filter: ReactTableInputFilter,
    },
    {
      Header: 'Partner Order Id',
      accessor: 'partnerGenId',
      Cell: (row) => {
        return <span>{row.original.partnerGenId}</span>;
      },
    },
  ];

  getSubComponent = (row) => {
    const subComponentClasses =
      this.props.labPartnerId === 0 ? 'row' : 'row d-none';
    return (
      <div className={subComponentClasses}>
        <div className="col-4">
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">Remarks</span>
            </div>
            <textarea
              className="form-control"
              aria-label="Remarks"
              placeholder="Comment here..."
              onChange={(event) => (row.updatedRemark = event.target.value)}
              defaultValue={
                row.original.remarks === null ? '' : row.original.remarks
              }
            />
          </div>
        </div>
        <div className="col-1 d-flex">
          <button
            className="btn btn btn-success"
            onClick={() => this.saveRemark(row, row.updatedRemark)}
          >
            Save
          </button>
          <LabsClickToCallButton orderId={row.original.orderId} />
        </div>
        <div className="col-1 d-flex">
          <LabRequestPayoutConfirmation
            orderId={row.original.orderId}
            partnerId={row.original.partnerId}
          />
        </div>
      </div>
    );
  };

  handleCloseOrderActionModal = () => {
    this.setState(
      {
        actionModal: {
          orderId: null,
          isOpen: false,
          actionName: null,
          status: null,
          actionType: null,
          collectionDate: null,
          slotId: null,
        },
      },
      this.fetchOrders
    );
  };

  handleOpenActionModal = (
    orderId,
    actionType,
    actionName,
    status,
    collectionDate,
    slotId
  ) => {
    console.log(orderId, actionType, status, collectionDate, slotId, 'Open');
    this.setState({
      actionModal: {
        orderId,
        isOpen: true,
        actionType,
        actionName,
        status,
        collectionDate,
        slotId,
      },
    });
  };

  handleOrderSelect = (orderId) => {
    if (this.state.selectedOrderIds.includes(orderId)) {
      this.setState({
        selectedOrderIds: this.state.selectedOrderIds.filter(
          (ele) => ele !== orderId
        ),
      });
    } else {
      this.setState({
        selectedOrderIds: [...this.state.selectedOrderIds, orderId],
      });
    }
  };

  openPartnerChangeModal = () => {
    this.setState({
      showPartnerChangeModal: true,
    });
  };

  hideModal = () => {
    this.setState({
      showPartnerChangeModal: false,
      showConfirmationModal: false,
    });
  };

  partnerChangeSuccess = () => {
    this.setState({
      showPartnerChangeModal: false,
      selectedOrderIds: [],
      showConfirmationModal: false,
    });
    this.fetchOrders();
  };

  openConfirmationModal = () => {
    this.setState({
      showConfirmationModal: true,
    });
  };

  markSamplePickedUp = () => {
    this.setState({
      pickupLoading: true,
    });
    markSampleCollectedForBulkOrders(this.state.selectedOrderIds)
      .then((response) => {
        if (response.message === 'success') {
          this.partnerChangeSuccess();
        } else {
          throw new Error('Error while fetching partners order');
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      })
      .finally(() => {
        this.setState({
          pickupLoading: false,
        });
      });
  };

  render() {
    const { actionModal } = this.state;
    return (
      <React.Fragment>
        <ReactTable
          keyField="orderId"
          data={this.state.orders}
          columns={this.getColumns()}
          defaultPageSize={50}
          pages={this.state.pages}
          pageSize={this.state.pageSize}
          page={this.state.page}
          loading={this.state.loading}
          filtered={this.state.filtered}
          sorted={this.state.sorted}
          manual
          onFetchData={this.fetchStrategy}
          onFilteredChange={this.onFilteredChange}
          onPageSizeChange={(pageSize) => this.setState({ pageSize })}
          onPageChange={(page) => this.setState({ page })}
          onSortedChange={(sorted) => this.setState({ sorted })}
          className="-striped -highlight"
          showPaginationTop
          showPaginationBottom
          SubComponent={(row) => (
            <LabOrderTableSubComponent orderInfo={row.original} />
          )}
        />
        <OrderActionModal
          orderId={actionModal.orderId}
          isOpen={actionModal.isOpen}
          actionName={actionModal.actionName}
          status={actionModal.status}
          actionType={actionModal.actionType}
          collectionDate={actionModal.collectionDate}
          slotId={actionModal.slotId}
          onClose={this.handleCloseOrderActionModal}
        />
        <Modal
          header={`Change Partner for selected Orders`}
          isOpen={this.state.showPartnerChangeModal}
          onClose={this.hideModal}
        >
          <LabOrderCovidPartnerChangeForm
            orderIds={this.state.selectedOrderIds}
            onSubmit={this.partnerChangeSuccess}
          />
        </Modal>
        <Modal
          header={`Mark Sample Picked Up for selected order`}
          isOpen={this.state.showConfirmationModal}
          onClose={this.hideModal}
        >
          <div>
            <Button
              color="primary"
              onClick={this.markSamplePickedUp}
              style={{ marginRight: '10px' }}
              disabled={this.state.pickupLoading}
            >
              Yes
            </Button>
            <Button
              color="danger"
              onClick={this.hideModal}
              disabled={this.state.pickupLoading}
            >
              No
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return { labPartnerId: state.user.labPartnerId };
}

export default connect(mapStateToProps)(LabOrdersTable);
