import React, { Component } from 'react';
import { debounce } from '../../utils/debounce';
import Select from 'react-select';
import { Async, components } from 'react-select';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import { toast } from 'react-toastify';

import {
  searchDoctors,
  fetchUsersRelative,
  getVerticals,
  fetchUserPolicies,
  fetchUsersRelativeByphone,
  fetchUserPoliciesByPhone,
} from '../../services/api/offline-consult';

import moment from 'moment';
import LoadingComponent from '../common/LoadingComponent';

// import { useLink } from 'valueLink';
// import { Input } from 'linked-controls';

const timeSlots = [
  { value: 13, label: '06:00:00 AM - 06:30:00 AM' },
  { value: 14, label: '06:30:00 AM - 07:00:00 AM' },
  { value: 15, label: '07:00:00 AM - 07:30:00 AM' },
  { value: 16, label: '07:30:00 AM - 08:00:00 AM' },
  { value: 17, label: '08:00:00 AM - 08:30:00 AM' },
  { value: 18, label: '08:30:00 AM - 09:00:00 AM' },
  { value: 19, label: '09:00:00 AM - 09:30:00 AM' },
  { value: 20, label: '09:30:00 AM - 10:00:00 AM' },
  { value: 21, label: '10:00:00 AM - 10:30:00 AM' },
  { value: 22, label: '10:30:00 AM - 11:00:00 AM' },
  { value: 23, label: '11:00:00 AM - 11:30:00 AM' },
  { value: 24, label: '11:30:00 AM - 12:00:00 PM' },
  { value: 25, label: '12:00:00 PM - 12:30:00 PM' },
  { value: 26, label: '12:30:00 PM - 01:00:00 PM' },
  { value: 27, label: '01:00:00 PM - 01:30:00 PM' },
  { value: 28, label: '01:30:00 PM - 02:00:00 PM' },
  { value: 29, label: '02:00:00 PM - 02:30:00 PM' },
  { value: 30, label: '02:30:00 PM - 03:00:00 PM' },
  { value: 31, label: '03:00:00 PM - 03:30:00 PM' },
  { value: 32, label: '03:30:00 PM - 04:00:00 PM' },
  { value: 33, label: '04:00:00 PM - 04:30:00 PM' },
  { value: 34, label: '04:30:00 PM - 05:00:00 PM' },
  { value: 35, label: '05:00:00 PM - 05:30:00 PM' },
  { value: 36, label: '05:30:00 PM - 06:00:00 PM' },
  { value: 37, label: '06:00:00 PM - 06:30:00 PM' },
  { value: 38, label: '06:30:00 PM - 07:00:00 PM' },
  { value: 39, label: '07:00:00 PM - 07:30:00 PM' },
  { value: 40, label: '07:30:00 PM - 08:00:00 PM' },
  { value: 41, label: '08:00:00 PM - 08:30:00 PM' },
  { value: 42, label: '08:30:00 PM - 09:00:00 PM' },
  { value: 43, label: '09:00:00 PM - 09:30:00 PM' },
  { value: 44, label: '09:30:00 PM - 10:00:00 PM' },
  { value: 45, label: '10:00:00 PM - 10:30:00 PM' },
  { value: 46, label: '10:30:00 PM - 11:00:00 PM' },
  { value: 47, label: '11:00:00 PM - 11:30:00 PM' },
];

class PlaceRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      searchQuery: '',
      userId: '',
      phone: '',
      dateKey: null,
      relativeResult: [],
      relativeLoading: false,
      verticalsResult: [],
      verticalLoading: false,
      showFormWarning: false,
      alertMessage: 'None',
      loading: false,
      starhealthUser: false,
      policyResult: [],
      policyNumber: null,
      dependantResult: [],
      userDetails: null
    };
    this.doctorSelectRef = React.createRef();
    this.relativeSelectRef = React.createRef();
    this.slotSelectRef = React.createRef();
    this.verticalSelectRef = React.createRef();
    this.policySelectRef = React.createRef();
    this.dependantSelectRef = React.createRef();
    this.onAlertDismiss = this.onAlertDismiss.bind(this);

    this.debounceFetchData = debounce(() => {
      this.onPhoneIdInputBlur(0);
    }, 800);
  }

  toggle = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  };

  onAlertDismiss() {
    this.setState({ showFormWarning: false });
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  handleDoctorResultClick = (doctorId) => {
    this.setState({ doctorId });
  };

  handleRelativeResultClick = (relativeId) => {
    this.setState({ relativeId });
  };

  componentWillMount() {
    this.fetchVerticals();
  }

  handleUserIdChange = () => {
    const { userId } = this.state;
    if (!userId) {
      return;
    }
    this.setState({ relativeLoading: true });
    fetchUsersRelative(userId)
      .then((response) => {
        if(response.isStarhealthUser) {
          this.setState({
            starhealthUser: true,
            policyResult: [],
            dependantResult: []
          })
          //show loader and msg that this is a starhealth user please wait while we fetch policies
          fetchUserPolicies(userId)
          .then((response) => {
            if(response && !response.errorMessage){
              console.log(response, 'Policies list');
              const policies = response.response.map(policy => {
                return {
                  label: `${policy.policyNumber}\n(${policy.productName})`,
                  value: policy.policyNumber,
                }
              });

              const dependants = {}
              response.response.map(policy => {
                dependants[policy.policyNumber] = policy.insuredDetails;
              })
              this.setState({
                policyResult : policies,
                dependantResult : dependants,
                loading: false
              })
            }
          });
        }
        else{
          this.setState({
            starhealthUser: false
          })
        }
        this.setState({
          userId,
          relativeResult: response.result || [],
        });
      })
      .finally(() => {
        this.setState({ relativeLoading: false, loading: false });
      });
  };

  fetchVerticals = () => {
    this.setState({ verticalLoading: true });
    getVerticals()
      .then((response) => {
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value: element.verticalId, label: element.verticalName });
        });
        this.setState({ verticalsResult: arr });
      })
      .finally(() => {
        this.setState({ verticalLoading: false });
      });
  };

  handleDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
    this.setState({ dateKey });
  };

  handleSlotIdChange = (event) => {
    const slotId = event.target.value;
    if (!slotId) {
      return;
    }
    this.setState({ slotId });
  };

  handleBadFormInput = (doctorId, dateKey, slot, userId, centerId, policyNumber, dependant) => {
    let alertMessage = 'Bad Input';
    if (!dateKey) {
      alertMessage = 'You need to select a date';
    }
    if (!slot) {
      alertMessage = 'You need to select a slot';
    }
    if (!userId) {
      alertMessage = 'You need to select a user or valid phone/email';
    }
    if (!doctorId) {
      alertMessage = 'You need to select a vertical and a doctor';
    }
    if (!centerId) {
      alertMessage = 'No Center Found for this doctor';
    }
    if (!policyNumber) {
      alertMessage = 'No Policies found for this user';
    }
    if (!dependant) {
      alertMessage = 'No Dependents found in this Policy';
    }
    // show alert
    this.setState({ showFormWarning: true, alertMessage });
  };

  placeRequest = () => {
    const { dateKey, userId } = this.state;
    console.log(
      { internalState: this.doctorSelectRef.current.select.state.value },
      '[placeRequest]'
    );
    const doctorId =
      this.doctorSelectRef.current.select.state.value.value || false;
      let relativeId;
      if(this.state.starhealthUser) {
        relativeId = -1;
      } else {
       relativeId = this.relativeSelectRef.current.state.value.value || -1;
      }
    const slotId = this.slotSelectRef.current.state.value
      ? this.slotSelectRef.current.state.value.value
      : false;

    const centerId =
      this.doctorSelectRef.current.select.state.value.centerId || false;

    if (relativeId === -1) {
      relativeId = null;
    }

    let policyNumber = this.policySelectRef.current && this.policySelectRef.current.state.value && this.policySelectRef.current.state.value.value || -1;
    let dependant = this.dependantSelectRef.current && this.dependantSelectRef.current.state.value && this.dependantSelectRef.current.state.value.value || -1;

    if (!doctorId || !dateKey || !slotId || !userId || !centerId) {
      // show alert
      this.handleBadFormInput(doctorId, dateKey, slotId, userId, centerId, policyNumber, dependant);
      return;
    }

    this.setState({
      loading: true,
      policyNumber : policyNumber
    });

    this.props
    .submit(doctorId, centerId, userId, relativeId, dateKey, slotId, policyNumber, dependant)
    .then((result) => {
        if (result) {
          this.setState({ modalVisible: false });
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onPolicyBlur = () => {
    let policyNumber = this.policySelectRef.current.state.value?.value || -1;
    if(this.dependantSelectRef.current.state.value != null && this.dependantSelectRef.current.state.value.hasOwnProperty('label')){
      this.dependantSelectRef.current.state.value.label = '';
    }
    this.dependantSelectRef.current.state.value = ''
    this.setState({
      policyNumber
    })
  }

  onUserIdInputBlur = (showToasty = 1) => {
    console.log(this.doctorSelectRef.current.state,'doctor selection value')
    const { userId } = this.state;
    this.setState({
      relativeLoading: true,
      loading: true,
      policyResult: [],
      dependantResult: []
    });
    fetchUsersRelative(userId)
      .then((response) => {
        if(response.isStarhealthUser) {
          showToasty && toast.info("Since its a star health user please wait while we are fetching its policies.");
          this.setState({
            starhealthUser: true,
            loading: true,
          })
          //show loader and msg that this is a starhealth user please wait while we fetch policies
          fetchUserPolicies(userId)
          .then((response) => {
            console.log( response, response.errorMessage, 'fetchUserPolicies/Response')
            if(response && !response.errorMessage) {
              const policies = response.response.map(policy => {
                return {
                  label: policy.policyNumber,
                  subLabel: policy.productName,
                  value: policy.policyNumber,
                }
              });

              const dependants = {}
              response.response.forEach(policy => {
                dependants[policy.policyNumber] = policy.insuredDetails;
              })
              this.setState({
                policyResult : policies,
                dependantResult : dependants,
                loading: false
              })
            }
            else {
              this.setState({
                loading: false
              })
              showToasty && toast.error("No Policies found for this user");
            }
          });
        } else {
          this.setState({
            starhealthUser: false,
            loading: false
          })
        }
        const relatives = [
          {
            value: -1,
            label: 'Self',
          },
        ].concat(
          response.data.map((ele) => ({
            label: `${ele.relativeName}(${ele.relationName})`,
            value: ele.relativeId,
          }))
        );
        this.setState({
          relativeResult: relatives,
          userDetails : response.userDetails
        });
      })
      .finally(() => {
        this.setState({
          relativeLoading: false,
          // loading: false
        });
      });
  };

  onPhoneIdInputBlur = (showToasty = 1) => {
    console.log(this.doctorSelectRef.current.state,'doctor selection value onPhoneIdBlur')
    const { phone } = this.state;
    this.setState({
      relativeLoading: true,
      loading: true,
      policyResult: [],
      dependantResult: []
    });
    fetchUsersRelativeByphone(phone)
      .then((response) => {
        if(response.isStarhealthUser) {
          showToasty && toast.info("Since its a star health user please wait while we are fetching its policies.");
          this.setState({
            starhealthUser: true,
            loading: true,
          })
          //show loader and msg that this is a starhealth user please wait while we fetch policies
          fetchUserPoliciesByPhone(phone)
          .then((response) => {
            console.log( response, response.errorMessage, 'fetchUserPolicies/Response')
            if(response && !response.errorMessage) {
              const policies = response.response.map(policy => {
                return {
                  label: policy.policyNumber,
                  subLabel: policy.productName,
                  value: policy.policyNumber,
                }
              });

              const dependants = {}
              response.response.forEach(policy => {
                dependants[policy.policyNumber] = policy.insuredDetails;
              })
              this.setState({
                policyResult : policies,
                dependantResult : dependants,
                loading: false
              })
            }
            else {
              this.setState({
                loading: false
              })
              showToasty && toast.error("No Policies found for this user");
            }
          });
        } else {
          this.setState({
            starhealthUser: false,
            loading: false
          })
        }
        const relatives = [
          {
            value: -1,
            label: 'Self',
          },
        ].concat(
          response.data.map((ele) => ({
            label: `${ele.relativeName}(${ele.relationName})`,
            value: ele.relativeId,
          }))
        );
        this.setState({
          relativeResult: relatives,
          userDetails : response.userDetails,
          userId : response.userDetails.userId
        });
      })
      .finally(() => {
        this.setState({
          relativeLoading: false,
          // loading: false
        });
      });
  };

  getNetworkName = (sourceId) => {
    switch (sourceId) {
      case 1: {
        return 'Practo';
      }
      case 2: {
        return 'Crediheatlh';
      }
      case 3: {
        return 'Medibuddy';
      }
      case 4: {
        return 'Visit Online';
      }
      case 5: {
        return 'Docprime';
      }
      case 6: {
        return 'Visit Onboarded';
      }
      default: {
        return 'Unknown';
      }
    }
  };

  searchDoctors = (val) => {
    const verticalId = this.verticalSelectRef.current.state.value.value;
    console.log(this.verticalSelectRef.current.state.value.value,'vertical value')
    if(!this.state.userId) {
      toast.error("Please fill user id before searching doctors");
      return;
    }
    return searchDoctors(val, verticalId, this.state.userId)
      .then((response) => {
        return response.data || [];
      })
      .then((result) =>
        result.map((ele) => {
          return {
            label: `${ele.doctorName} - ${ele.networkCenterTitle} - ${ele.centerCity} - ${ele.networkSourceName}`,
            value: ele.doctorId,
            centerId: ele.networkCenterId,
          };
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      relativeResult,
      relativeLoading,
      modalVisible,
      verticalsResult,
      verticalLoading,
      loading,
    } = this.state;
    const { showModal } = this;
    const userIdLink = Link.state(this, 'userId').onChange(() => {
      this.onUserIdInputBlur(0);
    });
    const phoneIdLink = Link.state(this, 'phone').onChange(() => {
      this.debounceFetchData();
    });
    const dependentOptions = this.state.policyNumber && this.state.dependantResult[this.state.policyNumber] ? this.state.dependantResult[this.state.policyNumber].map(dep=> ({label: dep.name, value: dep})) : [];

    const SingleValue = (props) => {
      const [{ label, subLabel }] = props.getValue();

      return (
        <components.SingleValue {...props}>
          <span>{label} ({subLabel})</span>
        </components.SingleValue>
      );
    };

    const GetOptionLabel = ({label, subLabel}) => (
      <>
        <div> {label} </div>
        <div> ({subLabel}) </div>
      </>
    );

    return (
      <div>
        {!this.props.providerClaims ? <Button type="button" className="btn btn-success" onClick={showModal}>
          Book Request
        </Button> : <></>}
        <Modal
          isOpen={modalVisible}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader className="bg-primary" toggle={this.toggle}>
            Place Request
          </ModalHeader>
          <ModalBody>
            <form>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Select Vertical:</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={true}
                      ref={this.verticalSelectRef}
                      defaultValue={
                        relativeResult.length > 0 && relativeResult[0]
                      }
                      options={verticalsResult}
                      isLoading={verticalLoading}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>User Id:</Label>
                  </Col>
                  <Col>
                    <Input
                      className="form-control"
                      valueLink={userIdLink}
                      type="number"
                      onBlur={this.onUserIdInputBlur}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Search Doctor:</Label>
                  </Col>
                  <Col>
                    <Async
                      loadOptions={this.searchDoctors}
                      ref={this.doctorSelectRef}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>phone/email:</Label>
                  </Col>
                  <Col>
                    <Input
                      className="form-control"
                      valueLink={phoneIdLink}
                    />
                  </Col>
                </Row>
              </FormGroup>
            {!this.state.starhealthUser &&
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Relative</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={false}
                      ref={this.relativeSelectRef}
                      defaultValue={
                        relativeResult.length > 0 && relativeResult[0]
                      }
                      options={relativeResult}
                      isLoading={relativeLoading}
                    />
                  </Col>
                </Row>
              </FormGroup>
            }
            {this.state.starhealthUser && (
              <>
                <FormGroup>
                  <Row>
                    <Col className="col-3">
                      <Label>Policy Number/Name</Label>
                    </Col>
                    <Col>
                      <Select
                        className="h-10"
                        isSearchable={false}
                        ref={this.policySelectRef}
                        options={this.state.policyResult}
                        isLoading={relativeLoading}
                        onBlur={this.onPolicyBlur}
                        components={{ SingleValue }}
                        getOptionLabel={GetOptionLabel}
                      />
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col className="col-3">
                      <Label>Dependents </Label>
                    </Col>
                    <Col>
                      <Select
                        isSearchable={false}
                        ref={this.dependantSelectRef}
                        options={dependentOptions}
                        isLoading={relativeLoading}
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </>
            )}
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Date:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="date"
                      onChange={this.handleDateChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Select Slot:</Label>
                  </Col>
                  <Col>
                    <Select
                      ref={this.slotSelectRef}
                      isSearchable={false}
                      options={timeSlots}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </form>
            <Alert
              color="warning"
              isOpen={this.state.showFormWarning}
              toggle={this.onAlertDismiss}
            >
              {this.state.alertMessage}
            </Alert>
            {this.state.userDetails &&
            <div>
            <h4>User Details</h4>
            <Row>
                  <Col className="col-3">
                    <Label>User Name : </Label>
                  </Col>
                  <Col>
                     <span>{this.state.userDetails.userName}</span>
                  </Col>
            </Row>
            <Row>
                  <Col className="col-3">
                    <Label>Sponsor : </Label>
                  </Col>
                  <Col>
                     <span>{this.state.userDetails.sponsorName}</span>
                  </Col>
            </Row>
             <Row>
                  <Col className="col-3">
                    <Label>Gender : </Label>
                  </Col>
                  <Col>
                     <span>{this.state.userDetails.gender}</span>
                  </Col>
            </Row>
            <Row>
                  <Col className="col-3">
                    <Label>Age : </Label>
                  </Col>
                  <Col>
                     <span>{this.state.userDetails.age}</span>
                  </Col>
            </Row>
            <Row>
                  <Col className="col-3">
                    <Label>policy : </Label>
                  </Col>
                  <Col>{  this.state.userDetails.policyDetails.length && this.state.userDetails.policyDetails.map((details)=>(
                     <span>{details.policyName}, </span>
                    ))
                  }
                  </Col>
            </Row>
                </div> }
          </ModalBody>
          <ModalFooter>
            {(loading) ? (
              <LoadingComponent />
            ) : (
              <Button color="primary" onClick={this.placeRequest}>
                Place Request
              </Button>
            )}
            <Button color="secondary" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default PlaceRequest;
