import React from 'react';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

export default function loginCheck(WrappedClass) {
  class ABC extends React.Component {
    componentWillMount() {
      const { user, history } = this.props;
      if (!user.token) {
        history.push('/');
      } else if (
        history.location.pathname === '/customer-support' &&
        user.showSupport === 0
      ) {
        history.push('/');
      } else if (
        (history.location.pathname === '/offline-consults' ||
          history.location.pathname === '/doc-on-call' ||
          history.location.pathname === '/specialist-consults' ||
          history.location.pathname === '/scheduled-consults') &&
        user.showOps === 0
      ) {
        history.push('/');
      } else if (
        history.location.pathname === '/payment-requests' &&
        user.showPayment === 0
      ) {
        history.push('/');
      } else if (
        history.location.pathname === '/online-payment' &&
        user.showOnlinePayments === 0
      ) {
        history.push('/');
      } else if (
        history.location.pathname === '/doctor-pool' &&
        user.showAdminActions === 0
      ) {
        history.push('/');
      } else if (
        history.location.pathname === '/labs-reconcile/upload-partner-tnx' &&
        user.showClaimsManagement === 0
      ) {
        history.push('/');
      } else if (
        history.location.pathname === '/labs-reconcile/reconciled-tnx' &&
        user.showClaimsManagement === 0
      ) {
        history.push('/');
      } else if (
        history.location.pathname === '/sponsor-management' &&
        user.showSponsorManagement === 0
      ) {
        history.push('/');
      }
    }

    render() {
      return <WrappedClass {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    user: state.user,
  });

  return connect(mapStateToProps)(withRouter(ABC));
}
