import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from '../layout/NavBar';
import {
  getOrders,
  getUserCartForOrder,
  getOrderDetails,
  getAvailablePartnersForCart,
  saveCartRemark,
  uploadReport,
  getTransactionHistory,
  getAddressProof,
  getTrudeskTickets,

} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import LabCart from '../lab-rx-requests/LabCart';
import LabOrderEditTest from './LabOrderEditTest';
import { setCartInfo } from '../../actions';
import LabOrderConvertToReimbursement from './LabOrderConvertToReimbursement';
import OrderActionModal from './OrderActionModal';
import OrderActionButton from './OrderActionButton';
import LabsClickToCallButton from './LabsClickToCallButton';
import LabOrderUpdateHandlingCharge from './LabOrderUpdateHandlingCharge';
import Modal from "../common/Modal";
import LabsOrderCopayForm from './LabsOrderCopayForm';
import LabsOrderChangePartnerForm from './LabsOrderChangePartnerForm';

class LabOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dependentDetails: {},
      cartDetails: {},
      cartItemsResult: {},
      orderId: null,
      openEditTestModal: false,
      labPartners: [],
      selectedPartnerId: null,
      remarks: '',
      selectedTestForEdit: {},
      copayBlockerModal: {
        isOpen: false,
      },
      changePartnerModal: {
        isOpen: false,
      },
      orderDetails: {
        actions: [],
      },
      totalObject: {},
      actionModal: {
        orderId: null,
        group: 'lab',
        isOpen: false,
        actionName: null,
        status: null,
        actionType: null,
        collectionDate: null,
        slotId: null,
        centerDetails: null,
      },
      
    };
  }

  makeDefaultState = () => ({
    cartItemsResult: {
      itemsList: [],
    },
  });

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get('orderId');
    const sourceId = params.get('sourceId');
    if (sourceId) {
      this.fetchOrderById(sourceId);
    } else {
      this.setState(
        {
          orderId,
        },
        this.loadCart
      );
    }
  }

  fetchOrderById = (sourceId) => {
    getUserCartForOrder(sourceId).then((response) => {
      if (response.body.message === 'success') {
        const { cartDetails } = response.body;
        this.setState(
          {
            orderId: cartDetails.orderId,
          },
          this.loadCart
        );
      } else {
        toast.error(`Unable to fetch details for the order.`);
      }
    });
  };

  loadCart = () => {
    this.getUserDetails();
    const { orderId } = this.state;
    if (!orderId) {
      return;
    }
    getAvailablePartnersForCart(orderId)
      .then((result) => {
        if (result.body.message === 'success') {
          this.setState({
            labPartners: result.body.partners,
          });
        } else {
          toast.error('Error in getting partners for this cart');
        }
      })
      .catch((err) => {
        console.error('Error in fetching partners', err);
        toast.error('Error in getting partners for this cart');
      });
    getOrders({ orderId }).then((response) => {
      this.setState({
        orderDetails: response.body.data.result[0],
      });
    });

    getTransactionHistory(orderId)
        .then((response) => {
          console.log(response, 'Response from api');
          if (response.message === 'success') {
            this.setState({
              transactions: response.transactions,
            });
          } else {
            throw new Error(response.errorMessage || 'Something went wrong while fetching transaction');
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
        });

    getAddressProof(orderId)
        .then((response) => {
          console.log(response, 'Response from api');
          if (response.message === 'success') {
            this.setState({
              addressProof: response.files,
              
            })
            console.log(this.state.addressProof);
          }
        });

    getTrudeskTickets(orderId)
        .then((response) => {
          console.log(response, 'Response from api');
          if (response.message === 'success') {
            this.setState({
              tickets: response.results,
            });
          } else {
            throw new Error(response.errorMessage || 'Something went wrong while fetching trudesk tickets');
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
        });

    getUserCartForOrder(this.state.orderId)
      .then((response) => {
        console.log(response.body);
        const totalObject = {labName: "TOTAL", mrp:0, cost:0, postDiscountPrice:0};
        response.body.cartItemsResult.itemsList = response.body.cartItemsResult.itemsList.map(
          (i) => {
            i.label = i.labName;
            i.value = i.labId;
            totalObject.cost += i.cost;
            totalObject.mrp += i.mrp; 
            totalObject.postDiscountPrice += i.postDiscountPrice;
            return i;
          }
        );

          this.setState({totalObject});
        // this.setState({
        //     cartItemsResult: response.body.cartItemsResult,
        // });
        this.setState({
          centerDetails: response.body.centerDetails,
        });
        this.props.dispatch(setCartInfo(response.body.cartItemsResult));
        // this.props.refresh();
      })
      .catch((err) => {
        if (err.response.data.errorMessage === 'No items in the cart') {
          this.props.dispatch(
            setCartInfo(this.makeDefaultState().cartItemsResult)
          );
        } else {
          console.error('Error in fetching users cart', err);
        }
      });
  };

  getUserDetails = () => {
    const { userId, orderId } = this.state;
    console.log(userId, orderId, '[getUserDetails]');
    if (!orderId) {
      return;
    }
    return getOrderDetails(orderId)
      .then((response) => {
        const cartDetails = response.body.cartDetails;
        this.setState({
          cartDetails: cartDetails,
          dependentDetails: response.body.dependentDetails || {},
          remarks: cartDetails.remarks || '',
        });
      })
      .catch((err) => {
        console.error('Error in loading user details', err);
        toast.error(`Unable to load User details`);
      });
  };

  openEditTestModal = (test) => {
    console.log('Opening edit test modal with the followng test', test);
    this.setState({
      openEditTestModal: true,
      selectedTestForEdit: test,
    });
  };

  saveRemarks = () => {
    const { cartId, remarks } = this.state;
    return saveCartRemark(cartId, remarks)
      .then((response) => {
        if (response.body.message === 'success') {
          toast.success('Remark Saved');
        } else {
          toast.error(`Unable to save remarks`);
        }
      })
      .catch((err) => {
        console.error('Error in saving remarks', err);
        toast.error(`Unable to save remarks`);
      });
  };

  onFileInputChanged = (event) => {
    console.log('File input state changed', event);
    console.log(event.target.files[0]);
    this.uploadLabReport(event.target.files[0]);
  };

  uploadLabReport = (file) => {
    uploadReport(this.state.orderId, file)
      .then((response) => {
        if (response.body.message === 'success') {
          toast.success('Report uploaded');
        } else {
          toast.error(`Unable to upload report`);
        }
      })
      .catch((err) => {
        console.error('Unable to upload report', err);
        toast.error(`Unable to upload report`);
      });
  };

  renderPartnerList = () => {
    const { labPartners } = this.state;
    if (labPartners && labPartners.length === 0) {
      return '';
    }
    return (
      <select
        id="inputPartner"
        className="form-control mr-3"
        onChange={this.onPartnerChanged}
      >
        {labPartners.map((p) => (
          <option disabled={!p.available} value={p.id} key={p.id}>
            {p.name}
          </option>
        ))}
      </select>
    );
  };

  onPartnerChanged = (e) => {
    console.log(e.target.value, 'New partner selected');
    this.setState({
      selectedPartnerId: parseInt(e.target.value),
    });
  };

  renderUploadedFiles = () => {
    const { cartDetails } = this.state;
    if (
      cartDetails &&
      cartDetails.uploadedFiles &&
      this.state.cartDetails.uploadedFiles !== null
    ) {
      return cartDetails.uploadedFiles.split(',').map((f, index) => (
        <a
          className="m-1 p-2 badge badge-primary"
          key={index}
          href={f}
          target="_blank"
        >
          {index + 1}
        </a>
      ));
    } else {
      return '';
    }
  };

  handleOpenActionModal = (
    orderId,
    actionType,
    actionName,
    status,
    collectionDate,
    slotId
  ) => {
    console.log(orderId, actionType, status, collectionDate, slotId, 'Open');
    this.setState({
      actionModal: {
        orderId,
        isOpen: true,
        actionType,
        actionName,
        status,
        collectionDate,
        slotId,
      },
    });
  };

  handleCloseOrderActionModal = () => {
    this.setState(
      {
        actionModal: {
          orderId: null,
          isOpen: false,
          actionName: null,
          status: null,
          actionType: null,
          collectionDate: null,
          slotId: null,
        },
      },
      this.loadCart
    );
  };

  openAddCopayModal = () => {
    this.setState({
      copayBlockerModal: {
        isOpen: true,
      },
    });
  };

  closeAddCopayModal = () => {
    this.setState({
      copayBlockerModal: {
        isOpen: false,
      },
    }, this.loadCart);
  };

  closeChangePartnerModal = () => {
    this.setState({
      changePartnerModal: {
        isOpen: false,
      },
    }, this.loadCart);
  };

  openChangePartnerModal = () => {
    this.setState({
      changePartnerModal: {
        isOpen: true,
      },
    });
  };

  showPartnerModal(){
    this.setState({
      changePartnerModal: {
        isOpen: true,
      },
    });
  }
  render() {
    const { actionModal, orderDetails } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <NavBar />
        </div>
        <div className="row mt-5 pt-1">
          <div className="col">
            <div>
              <h2 className="pl-1">
                Order #{this.state.orderId}{' '}
                <span className="badge badge-primary">
                  {this.state.orderDetails.status}
                </span>{' '}
                {this.state.orderDetails.partnerName} [
                {this.state.orderDetails.testsType}]
              </h2>
              <h5>
                Center Visit:{' '}
                {this.state.orderDetails.isCenterVisit ? 'Yes' : 'No'}
              </h5>
              <div className="d-flex justify-content-start align-items-center">
                <p>
                  Partner BookingId :
                  <span>
                    <strong>{this.state.cartDetails.partnerBookingId}</strong> ,
                  </span>
                  
                  Account:Id{' '}
                  <strong>{this.state.cartDetails.partnerAccountId}</strong>
                </p>
                <OrderActionButton
                  orderId={orderDetails.orderId}
                  collectionDate={orderDetails.collectionDate}
                  status={orderDetails.status}
                  slotId={orderDetails.slotId}
                  actions={orderDetails.actions}
                  onClick={this.handleOpenActionModal}
                />
              </div>
            </div>

            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">User Name</th>z
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Sponsor Name</th>
                  <th scope="col">Policy Holder Name</th>
                  <th scope="col">Dependent Names</th>
                  <th scope="col">Dependent Relations</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.orderDetails.userName}</td>
                  <td>{this.state.orderDetails.email}</td>
                  <td>
                    <LabsClickToCallButton
                      orderId={this.state.orderId}
                      phone={this.state.orderDetails.userPhone}
                    />{' '}
                  </td>
                  <td>{this.state.orderDetails.sponsorName}</td>
                  <td>{this.state.dependentDetails.holderName}</td>
                  <td>{this.state.dependentDetails.dependentName}</td>
                  <td>{this.state.dependentDetails.relation}</td>
                </tr>
              </tbody>
            </table>

            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Patient Name</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Age</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Address</th>
                  <th scope="col">Pickup Slot</th>
                  <th scope="col">Verified Prescription</th>
                  <th scope="col">Uploaded Files</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.orderDetails.patientName}</td>
                  <td>{this.state.orderDetails.patientGender}</td>
                  <td>{this.state.orderDetails.patientAge}</td>
                  <td>
                    <LabsClickToCallButton
                      orderId={this.state.orderId}
                      phone={this.state.orderDetails.patientPhone}
                    />
                  </td>
                  <td>
                    {this.state.cartDetails.address} |{' '}
                    {this.state.cartDetails.locality}{' '}
                    {this.state.cartDetails.pincode}
                  </td>
                  <td>
                    {this.state.orderDetails.slotTime} |{' '}
                    {this.state.orderDetails.collectionDate}
                  </td>
                  <td>
                    {this.state.cartDetails.verifiedPrescription !== null ? (
                      <a
                        target="_blank"
                        href={this.state.cartDetails.verifiedPrescription}
                      >
                        Open
                      </a>
                    ) : (
                      'NA'
                    )}
                  </td>
                  <td>{this.renderUploadedFiles()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="font-weight-bolder"
              >
                Remarks
              </label>
              <textarea
                value={this.state.remarks}
                onChange={(e) => this.setState({ remarks: e.target.value })}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div>
          </div>
          <div className="col-5 d-flex align-items-center">
            <button className="btn btn-success" onClick={this.saveRemarks}>
              Save Remarks
            </button>

            <div
              style={{
                cursor: 'pointer',
              }}
              className="ml-1"
            >
              <label className="btn btn-outline-primary">
                Upload Report <input type="file" hidden onChange={(e) => this.onFileInputChanged(e)}/>
              </label>
            </div>

            <LabOrderConvertToReimbursement orderId={this.state.orderId} />
            <button
                style={{marginLeft: "5px"}}
                className="btn btn-primary"
                onClick={this.openAddCopayModal}
            >Change Pricing</button>
            <button
                style={{marginLeft: "5px"}}
                className="btn btn-primary"
                onClick={this.demo}
            >Change Partner</button>
          </div>
        </div>
        <div className="row">

          {this.state.centerDetails && this.state.centerDetails.address ? (
            <div className="col-4">
              <div className="card">
                <div className="card-header">
                  <div className="row no-gutters">
                    <div className="col">Center Details</div>
                    <div className="col text-right">
                      {this.state.centerDetails.isCenterVisit ? (
                        <span className="badge badge-success">
                          <b>Center Visit</b>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-8">
                        {this.state.centerDetails.address},{' '}
                        {this.state.centerDetails.locality},{' '}
                        {this.state.centerDetails.city}
                      </div>
                      <div className="col text-right">
                        {this.state.centerDetails.pincode}
                      </div>
                    </div>
                    <br />
                    <div className="row border rounded border-dark">
                      <div className="col border text-center border-dark">
                        Booking {this.state.centerDetails.bookingMedium}
                      </div>
                      <div className="col border text-center border-dark">
                        {this.state.centerDetails.bookingMedium === 'phone'
                          ? this.state.centerDetails.bookingPhone
                          : this.state.centerDetails.bookingEmail}
                      </div>
                    </div>
                    <br />
                    <div className="row border rounded border-dark">
                      <div className="col border text-center border-dark">
                        Escalation {this.state.centerDetails.escalationMedium}
                      </div>
                      <div className="col border text-center border-dark">
                        {this.state.centerDetails.escalationMedium === 'phone'
                          ? this.state.centerDetails.escalationPhone
                          : this.state.centerDetails.escalationEmail}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-5">
            {(this.state.transactions && this.state.transactions.length) ? (
                <div className="card">
                  <div className="card-header">
                    <div className="row no-gutters">
                      <div className="col">Transaction History</div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="container-fluid">
                      {(this.state.transactions.map((t) => (
                          <div className="row" key={`${t.id}-${t.type}`}>
                            <div className="col">
                              <li>{(t.type === 'refund') ? 'Refund' : 'Copay'} of Rs. {t.amount} on {t.formattedDate} - {t.status}</li>
                            </div>
                          </div>
                      )))}
                    </div>
                  </div>
                </div>
            ) : null}
          </div>
          <div className="col-5">
            {(this.state.addressProof && this.state.addressProof.length) ? (
                <div className="card">
                  <div className="card-header">
                    <div className="row no-gutters">
                      <div className="col">Address Proofs</div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="container-fluid">
                      {(this.state.addressProof.map((p) => (
                          <div className="row" key={`${p.url}`}>
                            <div className="col">
                              
                              <li>URL: <a href = {p.fileUrl} target = "_blank"> {p.fileType} </a></li>
                            </div>
                          </div>
                      )))}
                    </div>
                  </div>
                </div>
            ) : null}
          </div>

          <div className="col-5">
            {(this.state.tickets && this.state.tickets.length) ? (
                <div className="card">
                  <div className="card-header">
                    <div className="row no-gutters">
                      <div className="col">Trudesk-Tickets</div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="container-fluid">
                      <table className="table table-bordered">
                                <thead>
                                  <td>ID</td>
                                  <td>Subject</td>
                                  <td>Status</td>
                                  <td>Date</td>
                                </thead>
                                <tbody>
                      {(this.state.tickets.map((t) => (
                        <tr>
                          <td><a href = {t.url} target = "_blank">{t.ticketId}</a></td>
                          <td>{t.subject}</td>
                          <td>{t.status}</td>
                          <td>{t.createdAt}</td>
                        </tr>
                          
                      )))}
                      </tbody>
                      </table>
                    </div>
                  </div>
                </div>
            ) : null}
          </div>
        </div>
        <br />

        <div className="row">
          <LabOrderEditTest
            loadCart={this.loadCart}
            orderId={this.state.orderId}
            cartId={this.state.cartId}
            open={this.state.openEditTestModal}
            close={() =>
              this.setState({
                openEditTestModal: false,
              })
            }
            selectedTestForEdit={this.state.selectedTestForEdit}
          />
        </div>
        <div className="row">
          <LabCart
            testType={this.state.orderDetails.testsType}
            openEditTestModal={this.openEditTestModal}
            loadCart={this.loadCart}
            add={true}
            edit={false}
            delete={false}
            orderId={this.state.orderId}
            totalObject={this.state.totalObject}
          />
        </div>
        <OrderActionModal
          orderId={actionModal.orderId}
          isOpen={actionModal.isOpen}
          actionName={actionModal.actionName}
          status={actionModal.status}
          actionType={actionModal.actionType}
          collectionDate={actionModal.collectionDate}
          slotId={actionModal.slotId}
          handlingCharge={this.state.orderDetails.collectionCharge}
          onClose={this.handleCloseOrderActionModal}
        />
        <Modal
            header={`Change Pricing`}
            isOpen={this.state.copayBlockerModal.isOpen}
            onClose={this.closeAddCopayModal}
        >
          <LabsOrderCopayForm
              orderId={this.state.orderId}
              cartItemsResult={this.props.cartItemsResult}
              onSubmit={this.closeAddCopayModal}
          />
        </Modal>
       { this.state.orderDetails.type? <Modal
            header={`Change Partner for Order`}
            isOpen={this.state.changePartnerModal.isOpen}
            onClose={this.closeChangePartnerModal}
        >
          <LabsOrderChangePartnerForm
              orderId={this.state.orderId}
              onSubmit={this.closeChangePartnerModal}
              addressId={this.state.orderDetails.addressId}
          />
        </Modal> : <div> {this.showPartnerModal()}</div>} 
        {/* <Modal
            header={'Partners Details'}
            isOpen={this.state.changePartnerModal.isOpen}
            onClose={this.closeChangePartnerModal}
        >
          <LabsOrderChangePartnerForm
              orderId={this.state.orderId}
              onSubmit={this.closeChangePartnerModal}
              addressId={this.state.orderDetails.addressId}
          />
        </Modal> */}
      </div>
    );
  }
}

function mapStateToProps(state) {

  return {
    cartItemsResult: state.labsDashboard.cartItemsResult,
  };
}

export default connect(mapStateToProps)(LabOrderDetails);
