import React, { Component } from 'react'
import {
    Alert,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    FormGroup,
    Label,
    Row,
    Col,
    Input
  } from 'reactstrap';
import Select from 'react-select';
import { Async } from 'react-select';
import LoadingComponent from '../LoadingComponent';
// import { Input } from 'valuelink/lib/tags';
import {getVerticals,fetchUsersRelative} from '../../../services/api/offline-consult'
import { getOnlineConsultDoc,getUserData } from '../../../services/api/online-consultation-booking'
import Link from 'valuelink';
import { toast } from 'react-toastify';

class ConsultOnlineModal extends Component {
    constructor(props){
        console.log(props,'[prop]')
        super(props)
        this.state={
            loading:false,
            verticalLoading:false,
            verticalsResult:[],
            relativeResult:[],
            relativeLoading:false,
            phone:'',
            userName:'',
            userId:''
        }
        // this.getUserDetails = this.getUserDetails.bind(this)
        // this.getUserDetails = debounce(this.getUserDetails\,200)
        this.verticalSelectRef = React.createRef();
        this.doctorSelectRef = React.createRef();
        this.relativeSelectRef = React.createRef();
    }
    componentDidMount(){
        this.fetcVerticals();
    }

    fetcVerticals = () => {
        this.setState({verticalLoading:true})
        getVerticals()
        .then((response) => {
            const arr = [];
            response.data.forEach((element) => {
              arr.push({ value: element.verticalId, label: element.verticalName });
            });
            this.setState({ verticalsResult: arr });
          })
          .finally(() => {
            this.setState({ verticalLoading: false });
          });
    }

    searchDoctors = (val)=>{
        console.log(val,'[val]')
        const verticalId = this.verticalSelectRef.current.state.value.value;
        return getOnlineConsultDoc(val,verticalId)
            .then((response)=>{
                return response.data || [];
            }).then((result) =>
            result.map((ele) => {
              console.log(ele, '[resele]');
              return {
                label: `${ele.docName} - ${ele.language} - ${ele.verticalName}`,
                value: ele.docId,
                // centerId: ele.networkCenterId,
              };
            })
          )
          .catch((err) => {
            console.log(err);
          });
    }

    onUserIdInputBlur = (val) => {
        // const { userId } = this.state;
        console.log(val,'[useId]')
        this.setState({
          relativeLoading: true,
        });
        if(this.state.userId === ''){
            console.log('id')
        }
        fetchUsersRelative(val)
          .then((response) => {
            const relatives = [
              {
                value: -1,
                label: 'Self',
              },
            ].concat(
              response.data.map((ele) => ({
                label: `${ele.relativeName}(${ele.relationName})`,
                value: ele.relativeId,
              }))
            );
            this.setState({
              relativeResult: relatives,
            });
          })
          .finally(() => {
            this.setState({ relativeLoading: false });
          });
      };

      getUserDetails = (value) => {
        this.setState({
            phone:value
        })
        if(value.length === 10){
            getUserData(value)
            .then((res)=>{
                console.log(res,'[res]')
                if(res.message==='success'){
                    this.setState({
                        userId:res.data[0].userId,
                      userName:res.data[0].userName  
                    })
                }else{
                    toast.error(res.errorMessage)
                }
            }).catch((err)=>{
                console.log(err,'[user-err]')
            })
        }
      }

  render() {
    const userIdLink = Link.state(this, 'userId').onChange(() => {
        this.onUserIdInputBlur();
      });
    return (
      <>
        <Modal
          isOpen={this.props.modalIsOpen}
          toggle={() => this.props.closeModal()}
        >
          <ModalHeader
            className="bg-primary"
            toggle={() => this.props.closeModal()}
          >
            Book Online Consult
          </ModalHeader>
          <ModalBody>
            <form>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Select Vertical</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={true}
                      ref={this.verticalSelectRef}
                      options={this.state.verticalsResult}
                      isLoading={this.state.verticalLoading}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Search Doctor:</Label>
                  </Col>
                  <Col>
                    <Async
                      loadOptions={this.searchDoctors}
                      ref={this.doctorSelectRef}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Phone Number</Label>
                  </Col>
                  <Col>
                    <Input
                      type="number"
                      onChange={(e) => this.getUserDetails(e.target.value)}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>User Name</Label>
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      value={`${this.state.userId}-${this.state.userName}`}
                      onChange={(e) => {
                        this.onUserIdInputBlur(this.state.userId);
                      }}
                    //   disabled
                    //   onBlur={this.onUserIdInputBlur}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Relative</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={false}
                      ref={this.relativeSelectRef}
                      defaultValue={
                        this.state.relativeResult.length > 0 &&
                        this.state.relativeResult[0]
                      }
                      options={this.state.relativeResult}
                      isLoading={this.state.relativeLoading}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </form>
          </ModalBody>
          <ModalFooter>
            {this.state.loading ? (
              <LoadingComponent />
            ) : (
              <Button
                color="primary"
                // onClick={this.placeOnlineRequest}
              >
                Place Request
              </Button>
            )}
            <Button color="secondary" onClick={this.props.closeModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default ConsultOnlineModal