import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, Row as RawRow, Col } from 'reactstrap'
import { sendCashgramLink } from '../api'

const Row = ({children, ...props}) => <RawRow {...props} style={{ margin: 0, ...(props.style || {}) }}>
    {children}
</RawRow>

const OfflineCashgramModal = ({
    isOpen,
    header = 'Enter Reimbursement Price',
    onClose = () => {},
    onComplete = () => {},
    orderId
}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [amount, setAmount] = useState('')

    const submitHandler = () => {
        if (!Number(amount)) return toast.error('Please choose a valid amount')
        setIsLoading(true)
        
        sendCashgramLink({
            orderId, amount: Number(amount)
        })
            .then((response) => {
                if (response.message === 'failure') {
                    toast.error(response.errorMessage)
                    return
                }
                onComplete()
                setAmount('')
                toast.success('Cashgram link sent successfully')
            })
            .catch((err) => {
                toast.error(err?.response?.data?.errorMessage || 'Something went wrong, please try in a while')
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    return <Modal toggle={onClose} isOpen={isOpen}>
        <ModalHeader className='bg-info' toggle={onClose}>{header}</ModalHeader>
        <ModalBody>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <Row style={{ color: '#555' }}>Order ID:</Row>
                    <Row>
                        <input 
                            value={orderId}
                            style={{ width: '100%', color: '#888' }}
                            disabled
                        />
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col>
                    <Row style={{ color: '#555' }}>Amount:</Row>
                    <Row>
                        <input 
                            value={amount}
                            placeholder='Amount e.g. 100'
                            style={{ width: '100%' }}
                            onChange={e => setAmount(e.target.value)}
                        />
                    </Row>
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={submitHandler} disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Submit'}
            </Button>
            {!isLoading && (
                <Button color="secondary" onClick={onClose} disabled={isLoading}>
                    Cancel
                </Button>
            )}
        </ModalFooter>
    </Modal>
}

export default OfflineCashgramModal