import React, { Component, useEffect, } from 'react';
import { useState } from "react";
import  { Fragment }  from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import StyledTappable from '../../components/common/StyledTappable';
import NavBar from '../layout/NavBar';
import ReactTable from 'react-table';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import selectTableHoc from 'react-table/lib/hoc/selectTable';
import { Async as AsyncSelect } from 'react-select';
import "react-table/react-table.css";  
import { Button } from 'reactstrap';
import { Link as RouterLink } from 'react-router-dom';
import {
  useFetchAllReimbursement,
  useSponsorList,
  useReimbursementReasonList,
  useReimbursementDocList,
  useTransferReimbursementCase,
  useAddClarificationSolution,
  useRemarkList,
} from '../../../src/queries/reimburseQueryAdmin';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { FaTimes, FaEdit } from 'react-icons/fa';
import moment from 'moment';
import {
  addClarificationSolution,
  downloadReimbursementCsvFile,
  downloadReimbursementDoctorsCountFile,
} from '../../../src/services/api/reimburse-claims';
const SelectTable = selectTableHoc(ReactTable);

const customStyles = {
  content: {
    top: '35%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '60%',
    height: '50%',
    transform: 'translate(-40%, -10%)',
  },
};
const customStylesForRemark = {
  content: {
    top: '35%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    width: '30%',
    height: '30%',
    transform: 'translate(-40%, -10%)',
  },
};

const Input = styled.input`
  padding: 5px;
  outline-color: #b3b3b3;
  outline-offset: -2px;
  outline-width: 5px;
  margin-left: 12px;
`;

const CrossIcon = styled(FaTimes)`
  font-size: 1.5rem;
  font-weight: normal;
`;
const CloseContainer = styled(StyledTappable)``;
const statusOption = [
  {
    value: 'documents-uploaded',
    label: 'Documents Uploaded',
  },
  {
    value: 'approved',
    label: 'Approved',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
  {
    value: 'deleted',
    label: 'Deleted',
  },
  {
    value: 'documents-verified',
    label: 'Documents Verified',
  },
  {
    value: 'documents-deficient',
    label: 'Documents Deficient',
  },
  {
    value: 'deficiency submitted',
    label: 'Deficiency Submitted',
  },
  {
    value: 'settled',
    label: 'Settled',
  },
  {
    value: 'pending',
    label: 'Settlement Pending',
  },
  {
    value: 'clarification-needed',
    label: 'Clarification Needed',
  },
  {
    value: 'clarification-resolved',
    label: 'Clarification Resolved',
  },
  {
    value: 'on-hold',
    label: 'On Hold',
  },

  // ,{
  //   value:'approved', label:'Sent to insurer'
  // }
];

const tatDaysOptions = new Array(16).fill(0).map((e, i) => {
  if (i < 15) {
    return {
      label: `${i + 1} Day`,
      value: i + 1,
    };
  }
  return {
    label: `15+ Day`,
    value: i + 1,
  };
});

const styleobj = {
  'font-size': '15px',
  overflow: 'hidden',
  'background-color': '#F1F1F1',
};
const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const Text = styled.p`
  margin: 0;
  font-family: Inter, Lato, sans-serif;
  color: ${(props) => props.color || 'black'};
  font-size: ${(props) => props.fontSize || '1.6rem'};
`;

export default function Admin() {
  const OnError = (error) => {
    toast.error('Error.');
  };
  const OnSucces = (success) => {
    toast.success(`${success}`);
    hideBulkAssignModal();
    refreshReimbursData();
  };
  const OnAddSuccess = (success) => {
    toast.success('Solution Added');
    hideRemarkModal();
  };
  const OnAddError = (error) => {
    toast.error('Error.');
  };

  let [pages, setCount] = useState(-1);
  const [page, setPage] = useState(0);
  const [sponsor, setSponsor] = useState('');
  const [sponsorValue, setSponsorValue] = useState([]);
  const [reasonValue, setReasonValue] = useState([]);
  const [statusValue, setStatusValue] = useState([]);
  const [doctorValue, setDoctorValue] = useState([]);
  const [searchStringValue, setSearchStringValue] = useState('');
  const [remarkModal, setRemarkModal] = useState(false);
  let reimbursementTableData = [];
  const [sortOrder, setSortOrder] = useState('');
  const [sortBy, setSortBy] = useState();
  const [searchString, setSearchString] = useState('');
  const [reason, setReason] = useState('');
  const [status, setstatus] = useState('');
  const [statusForCsv, setStatusForCsv] = useState('');
  const [deficientValue, setDeficientValue] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState({ sd: null, ed: null });
  const [focusedInput, setFocusedInput] = useState(null);
  const [claimedBy, setClaimedBy] = useState('');
  const [trnasferCasesDoctorId, setTrnasferCasesDoctorId] = useState(-1);
  const [remarkCasesSelected, setRemarkCasesSelected] = useState(new Set());
  const [pageSize, setPageSize] = useState(20);
  const [showBulkTransferModal, setShowBulkTransferModal] = useState(false);
  const [remarkData, setRemark] = useState(null);
  const [updateRemark, setUpdateRemark] = useState('');
  const [upatesolutionRId, setUpdateSolutionRId] = useState(null);
  const [rejectionRemark, setRejectionRemark] = useState('');
  const [rejectionRemarkList, setRejectionRemarkList] = useState([]);
  const [tatDay, setTatDay] = useState([]);

  const {
    isLoading,
    error,
    data: tableData,
    isFetching,
    refetch: refreshReimbursData,
  } = useFetchAllReimbursement(
    page,
    pageSize,
    sponsor,
    searchString,
    reason,
    status,
    claimedBy,
    selectedDate.sd && moment(selectedDate.sd).unix(),
    selectedDate.ed && moment(selectedDate.ed).unix(),
    sortBy,
    sortOrder,
    rejectionRemark,
    tatDay && tatDay.value ? tatDay.value : null
  );
  const { error: ErrorInDoctorList, data: opsDoctorList } =
    useReimbursementDocList();
  const { mutate: tranferCases } = useTransferReimbursementCase(
    OnSucces,
    OnError
  );
  const { data: sponsorData, error: errorInSponsor } = useSponsorList();
  pages = tableData && Math.ceil(tableData.count / pageSize);
  reimbursementTableData = tableData && tableData.result;
  const { data: reasonList, error: reasonError } = useReimbursementReasonList();
  const { data: rejectionReasonList, error: errorInRejectionReason } =
    useRemarkList();
  const {
    mutate: addClarificationSolution,
    error: errorInaddClarificationSolution,
  } = useAddClarificationSolution(OnAddSuccess, OnAddError);
  const handleChangeSponsor = (selectedValue) => {
    setSponsor(selectedValue.value);
    setSponsorValue({ value: selectedValue.value, label: selectedValue.label });
  };
  if (opsDoctorList) {
    opsDoctorList.push({
      value: 'unassigned',
      label: 'unassigned',
    });
  }
  const columns = [
    {
      Header: 'Reimbursement ID',
      accessor: 'reimbursementId',
      Cell: (row) => {
        const { reimbursementId } = row.original;
        return (
          <RouterLink
            title={reimbursementId}
            to={`/reimburse-claim/${reimbursementId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {reimbursementId}
          </RouterLink>
        );
      },
    },
    {
      Header: 'claimed By',
      accessor: 'claimBy',
    },
    {
      Header: 'sponsor Name ',
      accessor: 'sponsorName',
    },
    {
      Header: 'User Name',
      accessor: 'name',
    },
    {
      Header: 'User Phone',
      accessor: 'phone',
      dataIndex: 'phone',
    },
    {
      Header: 'User Email',
      accessor: 'email',
      dataIndex: 'email',
    },
    {
      Header: 'Patient Name',
      accessor: 'patientName',
      dataIndex: 'patientName',
    },
    {
      Header: 'Reimbursement Type',
      accessor: 'reimbursementType',
    },
    {
      Header: 'Status',
      accessor: 'requestStatus',
      Cell: (row) => {
        if (row.original.deletionDate) {
          return (
            <h5>
              <span className="badge badge-danger">DELETED</span>
            </h5>
          );
        }
        switch (row.original.requestStatus.toUpperCase()) {
          case 'DOCUMENTS-UPLOADED':
            return (
              <h5>
                <span className="badge badge-secondary">
                  {row.original.requestStatus.toUpperCase()}
                </span>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          case 'DOCUMENTS-VERIFIED':
            return (
              <h5>
                <span className="badge badge-primary">
                  {row.original.requestStatus.toUpperCase()}
                </span>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
          case 'APPROVED':
            return (
              <h5>
                <span className="badge badge-success">
                  {row.original.requestStatus.toUpperCase()}
                </span>
              </h5>
            );
          case 'REJECTED':
            return (
              <h5>
                <span className="badge badge-danger">
                  {row.original.requestStatus.toUpperCase()}
                </span>
              </h5>
            );
          default:
            return (
              <h5>
                <span className="badge badge-secondary">
                  {row.original.requestStatus.toUpperCase()}
                </span>
                {row.original.subStatus ? (
                  <span className="badge badge-secondary">
                    {row.original.subStatus.toUpperCase()}
                  </span>
                ) : null}
              </h5>
            );
        }
      },
    },

    {
      Header: 'Invoice Date',
      accessor: 'invoiceDate',
    },
    {
      Header: 'Approved Amount',
      accessor: 'approvedAmount',
    },

    {
      Header: 'Request Date',
      accessor: 'requestDate',
    },
    {
      Header: 'Document Deficient Marked Date',
      accessor: 'documentDefMarkedOn',
    },
    {
      Header: 'Document Deficient Updated on',
      accessor: 'documentDefUpdatedOn',
      Cell: (row) => (
        <p>
          {row.original.documentDefUpdatedOn
            ? moment(row.original.documentDefUpdatedOn).format('DD/MM/YYYY')
            : null}
        </p>
      ),
    },
    {
      Header: 'UTR No.',
      accessor: 'claimUtr',
    },
    {
      Header: 'Payout Date',
      accessor: 'paymentSettlementDate',
    },
    {
      Header: 'Payout Date',
      accessor: 'paymentSettlementDate',
    },
    {
      Header: 'Clarification requested date',
      accessor: 'clarificationReqOn',
    },
    {
      Header: 'Actions',
      Cell: (row) => {
        return row.original.subStatus ? (
          <button
            className="btn btn-success"
            onClick={showRemarkModal(row.original)}
          >
            Add Solution
          </button>
        ) : null;
      },
    },
  ];

  const handleChangeSearchInput = (input) => {
    setSearchString(input.target.value);
    setSearchStringValue(input.target.value);
  };
  const handleChangeReason = (selectedValue) => {
    setReason(selectedValue.label);
    setReasonValue({ value: selectedValue.value, label: selectedValue.label });
  };
  const handleChangeRejectionRemark = (selectedValue) => {
    setRejectionRemark(selectedValue.label);
    setRejectionRemarkList({
      value: selectedValue.value,
      label: selectedValue.label,
    });
  };
  const handleChangeStatus = (selectedValue) => {
    console.log(selectedValue, '[sele]');
    setstatus(selectedValue.value);
    setStatusForCsv(selectedValue.value);
    setStatusValue({ value: selectedValue.value, label: selectedValue.label });
    if (selectedValue.value === 'deficiency submitted') {
      setStatusForCsv('');
      setDeficientValue(true);
    }
  };
  const isRemarksCaseSelected = (ele) => {
    return remarkCasesSelected.has(ele);
  };
  const ClaimedByFilter = (v) => {
    setClaimedBy(v.value);
    setDoctorValue({ value: v.value, label: v.label });
  };
  const TatDayChanges = (v) => {
    setTatDay({ value: v.value, label: v.label });
  };
  const toggleRemarksCase = (ele, blah, obj) => {
    const updatedRemarkCasesSelected = new Set(remarkCasesSelected);
    if (isRemarksCaseSelected(obj.reimbursementId)) {
      updatedRemarkCasesSelected.delete(obj.reimbursementId);
      setRemarkCasesSelected(updatedRemarkCasesSelected);
    } else {
      updatedRemarkCasesSelected.add(obj.reimbursementId);
      setRemarkCasesSelected(updatedRemarkCasesSelected);
    }
  };
  const toggleAllRemarksCases = () => {
    if (remarkCasesSelected.size === reimbursementTableData.length) {
      setRemarkCasesSelected(new Set());
    } else {
      setRemarkCasesSelected(
        new Set(reimbursementTableData.map((ele) => ele.reimbursementId)),
        () => console.log(`Option selected:`, reimbursementTableData)
      );
    }
  };
  const bulkTransferCase = () => {
    const reimbursementIds = [];
    remarkCasesSelected.forEach((ele) => {
      const reimbursementData = reimbursementTableData.find(
        (cons) => ele === cons.reimbursementId
      );
      if (reimbursementData) {
        reimbursementIds.push(reimbursementData.reimbursementId);
      }
    });
    tranferCases({
      doctorId: trnasferCasesDoctorId,
      reimbursements: reimbursementIds,
    });
  };
  const showBulkAssignModal = () => {
    setShowBulkTransferModal(true);
  };
  const hideBulkAssignModal = () => {
    setShowBulkTransferModal(false);
  };
  const handleChangeTrnasferDoctorId = (v) => {
    setTrnasferCasesDoctorId(v.value);
  };
  const onDatesChanged = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setSelectedDate({ sd: startDate, ed: endDate });
    }
  };
  const prepareSorting = (state) => {
    const { sorted } = state;
    const reimbursementIdSort = Array.isArray(sorted)
      ? sorted.find((ele) => ele.id === 'reimbursementId')
        ? 'reimbursementId'
        : undefined
      : undefined;
    const reimbursementIdSortOrder = Array.isArray(sorted)
      ? sorted.find((ele) => ele.id === 'reimbursementId' || {}).desc
        ? 'desc'
        : 'asc'
      : undefined;
    setSortOrder(reimbursementIdSortOrder);
    setSortBy(reimbursementIdSort);
  };
  const showRemarkModal = (data) => () => {
    console.log('data' + data);
    setRemark(data.clarificationRemarks);
    setUpdateSolutionRId(data.reimbursementId);
    setRemarkModal(true);
  };

  const downloadCsvFile = () => {
    const params = {
      sponsorName: sponsor,
      claimBySearchId: claimedBy,
      size: 10000,
      page: 1,
      reimbursementType: reason,
      status: statusForCsv,
      startDate: selectedDate.sd && moment(selectedDate.sd).unix(),
      endDate: selectedDate.ed && moment(selectedDate.ed).unix(),
      deficientTab: deficientValue,
      remark: rejectionRemark,
    };
    if (tatDay && tatDay.value) {
      params.startDate = moment()
        .subtract(tatDay.value < 16 ? tatDay.value : 365, 'days')
        .startOf('day')
        .unix();
      params.endDate = moment()
        .subtract(tatDay.value, 'days')
        .endOf('day')
        .unix();
    }
    downloadReimbursementCsvFile(params);
  };

  const downloadDoctorsCount = () => {
    const params = {
      startDate:
        selectedDate.sd && moment(selectedDate.sd).format('YYYY-MM-DD'),
      endDate: selectedDate.ed && moment(selectedDate.ed).format('YYYY-MM-DD'),
    };
    downloadReimbursementDoctorsCountFile(params);
  };
  const resetFilter = () => {
    setSponsor('');
    setReason('');
    setstatus('');
    setClaimedBy('');
    setSearchString('');
    setSponsorValue([]);
    setReasonValue([]);
    setStatusValue([]);
    setDoctorValue([]);
    setSelectedDate({ sd: null, ed: null });
    setStartDate(null);
    setEndDate(null);
    setSearchString('');
    setSearchStringValue('');
    setRejectionRemark('');
    setRejectionRemarkList([]);
    setTatDay([]);
  };
  useEffect(() => {
    refreshReimbursData();
  }, [
    page,
    pageSize,
    sponsor,
    searchString,
    reason,
    status,
    claimedBy,
    selectedDate.sd && moment(selectedDate.ed).unix(),
    selectedDate.ed && moment(selectedDate.ed).unix(),
    sortBy,
    sortOrder,
    refreshReimbursData,
    rejectionRemark,
    tatDay,
  ]);

  const updateRemarkInput = (e) => {
    console.log('ss', e.target.value);
    setUpdateRemark(e.target.value);
  };
  const remarksSoliution = () => {
    const body = {
      reimbursementId: upatesolutionRId,
      solution: updateRemark,
    };
    addClarificationSolution(body);
  };
  const hideRemarkModal = () => {
    setRemarkModal(false);
  };

  return (
    <>
      <div className="container-fluid  overflow-auto ... w-full ... px-0">
        <NavBar></NavBar>
        <br></br>
        <br></br>
        <br></br>
        <div className="row">
          <div className="col">
            <Input
              value={searchStringValue}
              placeholder="Enter Name/Email/Phone/Id"
              onChange={handleChangeSearchInput}
            ></Input>
          </div>
          <div className="col">
            <Select
              value={sponsorValue}
              Clearable={true}
              placeholder="Select Spon.."
              onChange={handleChangeSponsor}
              options={sponsorData}
            />
          </div>
          <div className="col">
            <Select
              value={reasonValue}
              placeholder="Select Reimbursement Type"
              onChange={handleChangeReason}
              options={reasonList}
            />
          </div>
          <div className="col">
            <Select
              value={statusValue}
              placeholder="Select Status"
              onChange={handleChangeStatus}
              options={statusOption}
            />
          </div>
          <div className="col">
            <Select
              value={rejectionRemarkList}
              placeholder="Select DD/Rejection Reason"
              onChange={handleChangeRejectionRemark}
              options={rejectionReasonList}
            />
          </div>
          <div className="col">
            <Select
              value={doctorValue}
              placeholder="Select Doctor"
              onChange={ClaimedByFilter}
              options={opsDoctorList}
            />
          </div>
          <div className="col">
            <Select
              value={tatDay}
              placeholder="Select TAT Day"
              onChange={TatDayChanges}
              options={tatDaysOptions}
            />
          </div>
          <div className="col">
            <DateRangePicker
              displayFormat={() => 'DD/MM/YYYY'}
              startDateId="startDate"
              endDateId="endDate"
              startDate={startDate}
              endDate={endDate}
              isOutsideRange={() => false}
              minimumNights={0}
              onDatesChange={onDatesChanged}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
            />
          </div>
          <div className="col">
            <Link to="/reimbursement-doctor">
              <button className="btn btn-primary pr-2">Manage Doctors</button>
            </Link>
          </div>
          <div className="col ">
            <button
              className="btn btn-primary"
              onClick={showBulkAssignModal}
              disabled={remarkCasesSelected.size === 0}
            >
              Transfer Cases
            </button>
          </div>
          <div className="col">
            <Button outline color="primary" onClick={downloadCsvFile}>
              Download Csv
            </Button>
            <Button outline color="primary" onClick={downloadDoctorsCount}>
              Download Doctors Count
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <p>Total Count - {tableData && tableData.count}</p>
            <p>Selected Cases Count - {remarkCasesSelected.size}</p>
          </div>
          <div className="col-2">
            <Button outline color="success" onClick={resetFilter}>
              Reset Filter
            </Button>
          </div>
          <div className="col"></div>
        </div>

        <br></br>
        {error && error.data && toast.error(error.data.errorMessage)}
        {errorInSponsor &&
          errorInSponsor.data &&
          toast.error(errorInSponsor.data.errorMessage)}
        {ErrorInDoctorList &&
          ErrorInDoctorList.data &&
          toast.error(ErrorInDoctorList.data.errorMessage)}
        <SelectTable
          data={reimbursementTableData}
          keyField="reimbursementId"
          columns={columns}
          pages={pages}
          style={styleobj}
          showPaginationTop
          showPageJump={false}
          rowSelection={true}
          selectType="checkbox"
          manual
          defaultSorted={[
            {
              id: 'reimbursementId',
              desc: true,
            },
          ]}
          pageSizeOptions={[5, 10, 20, 25, 50, 100, 150]}
          onFetchData={(state, instance) => {
            prepareSorting(state);
            setPage(state.page);
            setPageSize(state.pageSize);
          }}
          loading={isLoading || isFetching}
          isSelected={isRemarksCaseSelected}
          toggleSelection={toggleRemarksCase}
          selectAll={remarkCasesSelected.size === reimbursementTableData}
          toggleAll={toggleAllRemarksCases}
        />
      </div>
      <Modal
        isOpen={showBulkTransferModal}
        style={customStyles}
        onRequestClose={hideBulkAssignModal}
      >
        <div className="float-right">
          <ButtonsComponent>
            <CloseContainer onTap={hideBulkAssignModal}>
              <CrossIcon />
            </CloseContainer>
          </ButtonsComponent>
        </div>
        <br></br>
        <div className="row">
          <div className="col-6">
            <Text>Please Select Doctor</Text>
            <br />
          </div>
          <div className="clo-6">
            <button
              className="btn btn-primary  pr-2 float-right "
              disabled={trnasferCasesDoctorId === -1}
              onClick={bulkTransferCase}
            >
              Transfer
            </button>
          </div>
          <div className="col-12">
            <Select
              placeholder="Select Doctor"
              onChange={handleChangeTrnasferDoctorId}
              options={opsDoctorList}
            />
          </div>
        </div>
      </Modal>
      <Modal isOpen={remarkModal} style={customStylesForRemark}>
        <div className="float-right m-1">
          <CloseContainer onTap={hideRemarkModal}>
            <CrossIcon />
          </CloseContainer>
        </div>
        <div className="flex">
          <label className="font-bold">Remark -</label>
          <h5 className="">{remarkData}</h5>
        </div>
        <label className="m-2 font-bold">Enter Solution</label>
        <br></br>
        <textarea
          className="m-1"
          type="text"
          height="50px"
          onChange={(e) => updateRemarkInput(e)}
          placeholder="Enter Solution"
        ></textarea>
        <br></br>
        <button className="btn btn-info m-1" onClick={remarksSoliution}>
          submit
        </button>
      </Modal>
    </>
  );
}

