import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from 'reactstrap';
import moment from 'moment';
import { TimePicker } from 'antd';
import 'antd/dist/antd.css';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { BoldText, RegularText} from '../common/Text';
import { FaEdit } from 'react-icons/fa';
import { getDoctorSlots } from '../../services/api/offline-consult';

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;
`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled.div`
  font-size: 1rem;
  width: ${(props) => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;
const Warning = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
  padding-top: 1rem;
  color: #FFAF3C;
`;

const DetailText = styled(RegularText)`
  flex: 1;
  font-size: 1rem;
  color: ${(props) => props.color || '#787778'};
  margin-left: 0.25rem;
`;
class SlotsOverlappingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
     
    };
  }

  formatTime = (time) => {
    const [hour, minute, second] = time.split(':');
    const hours = parseInt(hour, 10);
    const minutes = parseInt(minute, 10);
  
    // Check if it's AM or PM
    const ampm = hours >= 12 ? 'pm' : 'am';
  
    // Convert hours to 12-hour format
    const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
  
    // Add leading zeros to minutes if needed
    const formattedMinute = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${formattedHour}:${formattedMinute} ${ampm}`;
  };

  getDayName = (dow) => {
    const dayNames = ['', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return dayNames[dow];
  };

  renderTableRows = () => {
    if(this.props.warningData){
    return this.props.warningData.map((item, rowIndex) => (
      <tr key={rowIndex}>
        <td>{this.formatTime(item.startTime)}</td>
        <td>{this.formatTime(item.endTime)}</td>
        <td>{this.getDayName(item.dow)}</td>
      </tr>
    ));
    }
  };
  
  render() {
    console.log(this.state, 'a slot value', this.state.requestData);
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        toggle={() => this.props.closeModal()}
        // className={this.props.className}
      >
        <ModalHeader className="bg-primary" toggle={this.props.closeModal}>
          Attention !!
        </ModalHeader>
        <ModalBody>
        <div>
      <h3>Overlapping slots Details</h3>
      <table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '10px' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ccc', padding: '8px' }}>startTime</th>
            <th style={{ border: '1px solid #ccc', padding: '8px' }}>endTime</th>
            <th style={{ border: '1px solid #ccc', padding: '8px' }}>day</th>
          </tr>
        </thead>
        <tbody>{this.renderTableRows()}</tbody>
      </table>
    </div>
          
            </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={this.handleSubmit}
            disabled={this.state.loader}
          >
            {this.state.loader && (
              <Spinner
                style={{ height: '16px', width: '16px', marginRight: '5px' }}
              />
            )}
            Confirm
          </Button>
          <Button color="secondary" onClick={this.props.closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
};


export default SlotsOverlappingModal;

