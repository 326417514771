import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { addExistingTestForPartner, addTestToCart } from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
class AddExistingTestToPartner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            mrp: 0,
            cost: 0,
            testName: null,
            testCode: null,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('Confirm adding manual partner');
        console.log(prevProps, prevState);
        if (prevProps.open !== this.props.open) {
            this.toggle();
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    save = () => {
        console.log(this.state, 'Logging state before saving test');
        if (this.state.mrp > 0 && this.state.cost >0  && this.state.testCode && this.state.testCode.trim().length)
            addExistingTestForPartner(this.props.selectedTestName, this.state.mrp, this.state.testCode, this.state.cost, this.props.partnerId, this.props.testType)
            .then((response) => {
                if(response.body.message === 'success') {
                    return addTestToCart(response.body.testId, this.props.cartId)
                        .then((result) => {
                            if (result.body.message === 'success') {
                                this.toggle();
                                this.props.loadCart();
                                toast.success('Test Added');
                            } else {
                                toast.error('Error in Adding a test');
                            }
                        });
                } else {
                    toast.error('Error in Adding a test');
                }
            })
            .catch((err) => {
                console.error('Error', err);
                toast.error('Error in Adding a test');
            });
    };

    render() {
        console.log('Manual partner id in add existing test to partner', this.props.manualPartnerId);
        return (
            <div>
                {/*<button onClick={this.toggle} className="btn btn-danger">Add New Test</button>*/}
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg">
                    <h4 className="mt-1 mb-0 ml-2">Add Lab Test</h4>
                    <ModalBody>
                        <p>Fill the below details to add a lab test</p>
                        {/*<Button color="success" onClick={this.toggleNested}>Add New Partner</Button>*/}
                        <div className="form">
                            <div className="form-row">
                                <div className="form-group col-3">
                                    <label htmlFor="inputName">Test Name</label>
                                    <input type="text" disabled={true} className="form-control" id="inputName" defaultValue={this.props.selectedTestName} onChange={(e) => this.setState({
                                        testName: e.target.value,
                                    })}/>
                                </div>

                                <div className="form-group col-3">
                                    <label htmlFor="inputMrp">MRP (Market price)</label>
                                    <input type="number" className="form-control" id="inputMrp" placeholder="Rs" onChange={(e) => this.setState({
                                        mrp: e.target.value,
                                    })}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-3">
                                    <label htmlFor="inputCode">Test Code</label>
                                    <input type="text" className="form-control" id="inputCode" onChange={(e) => this.setState({
                                        testCode: e.target.value,
                                    })}/>
                                </div>

                                <div className="form-group col-3">
                                    <label htmlFor="inputCost">Cost (Payeable to partner)</label>
                                    <input type="number" className="form-control" id="inputCost" placeholder="Rs" onChange={(e) => this.setState({
                                        cost: e.target.value,
                                    })}/>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>Add Test</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(AddExistingTestToPartner);





