import React, { Component } from 'react';
import { connect } from 'react-redux';

class PharmacyCart extends Component {
  constructor(props) {
    super(props);
    this.state = this.makeDefaultState();
  }

  makeDefaultState = () => ({
    searchResult: [],
    openAddExistingTestToPartner: 0,
    selectedTestName: null,
  });



  renderCartItems = () => {
    const { orderDetails } = this.props;
    console.log({ orderDetails }, 'Props');
    return (
      orderDetails &&
      // orderDetails.itemsList &&
      orderDetails.map((medicine, index) => {
        return (
          <tr key={index}>
            <th scope="row">{index + 1}</th>
            <td>{medicine.drugName}</td>
            {/* <td>{medicine.MRP}</td> */}
            <td>{medicine.mrp}</td>
            <td>{medicine.quantity}</td>
            <td>{medicine.totalMrp}</td>
          </tr>
        );
      })
    );
  };

  render() {
    const { orderAmount } = this.props;
    return (
      <div className="container-fluid">
        <h4 className='font-weight-bold'>User's Cart Detail</h4>
        <table className="table table-bordered thead-dark">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Medicine Name</th>
              <th scope="col">MRP</th>
              {/* <th scope="col">Cost</th> */}
              <th scope="col">Quantity</th>
              <th scope="col">Total Cost</th>
            </tr>
          </thead>
          <tbody>
            {this.renderCartItems()}
          </tbody>
        </table>
        <br></br>
        <h4 className='font-weight-bold'>Payment Detail</h4>
        <table class="table table-bordered thead-dark">
  <thead>
    <tr>
      <th scope="col">User Paid</th>
      <th scope="col">Wallet deduction</th>
      <th scope="col">Total amount</th>
      <th scope="col">Order Type</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>{ orderAmount.amount}</td>
      <td>{ orderAmount.walletDeduction}</td>
      <td>{ orderAmount.totalAmount}</td>
      <td>{ orderAmount.orderType}</td>
    </tr>
  </tbody>
</table>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(PharmacyCart);
