import React, { Component } from 'react';
import Select from 'react-select';
import { Async } from 'react-select';
import {
  Alert,
  Button,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  searchDoctors,
  getVerticals,
} from '../../services/api/offline-consult';

import moment from 'moment';
import LoadingComponent from '../common/LoadingComponent';

// import { useLink } from 'valueLink';
// import { Input } from 'linked-controls';

const timeSlots = [
  { value: 17, label: '08:00:00 AM - 08:30:00 AM' },
  { value: 18, label: '08:30:00 AM - 09:00:00 AM' },
  { value: 19, label: '09:00:00 AM - 09:30:00 AM' },
  { value: 20, label: '09:30:00 AM - 10:00:00 AM' },
  { value: 21, label: '10:00:00 AM - 10:30:00 AM' },
  { value: 22, label: '10:30:00 AM - 11:00:00 AM' },
  { value: 23, label: '11:00:00 AM - 11:30:00 AM' },
  { value: 24, label: '11:30:00 AM - 12:00:00 PM' },
  { value: 25, label: '12:00:00 PM - 12:30:00 PM' },
  { value: 26, label: '12:30:00 PM - 01:00:00 PM' },
  { value: 27, label: '01:00:00 PM - 01:30:00 PM' },
  { value: 28, label: '01:30:00 PM - 02:00:00 PM' },
  { value: 29, label: '02:00:00 PM - 02:30:00 PM' },
  { value: 30, label: '02:30:00 PM - 03:00:00 PM' },
  { value: 31, label: '03:00:00 PM - 03:30:00 PM' },
  { value: 32, label: '03:30:00 PM - 04:00:00 PM' },
  { value: 33, label: '04:00:00 PM - 04:30:00 PM' },
  { value: 34, label: '04:30:00 PM - 05:00:00 PM' },
  { value: 35, label: '05:00:00 PM - 05:30:00 PM' },
  { value: 36, label: '05:30:00 PM - 06:00:00 PM' },
  { value: 37, label: '06:00:00 PM - 06:30:00 PM' },
  { value: 38, label: '06:30:00 PM - 07:00:00 PM' },
  { value: 39, label: '07:00:00 PM - 07:30:00 PM' },
  { value: 40, label: '07:30:00 PM - 08:00:00 PM' },
  { value: 41, label: '08:00:00 PM - 08:30:00 PM' },
  { value: 42, label: '08:30:00 PM - 09:00:00 PM' },
  { value: 43, label: '09:00:00 PM - 09:30:00 PM' },
  { value: 44, label: '09:30:00 PM - 10:00:00 PM' }
];

class PlaceRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      userId: null,
      dateKey: null,
      verticalsResult: [],
      verticalLoading: false,
      showFormWarning: false,
      alertMessage: 'None',
      loading: false,
    };
    this.doctorSelectRef = React.createRef();
    this.slotSelectRef = React.createRef();
    this.verticalSelectRef = React.createRef();
    this.onAlertDismiss = this.onAlertDismiss.bind(this);
  }

  toggle = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  };

  onAlertDismiss() {
    this.setState({ showFormWarning: false });
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  handleDoctorResultClick = (doctorId) => {
    this.setState({ doctorId });
  };

  componentWillMount() {
    this.fetchVerticals();
  }


  fetchVerticals = () => {
    this.setState({ verticalLoading: true });
    getVerticals()
      .then((response) => {
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value: element.verticalId, label: element.verticalName });
        });
        this.setState({ verticalsResult: arr });
      })
      .finally(() => {
        this.setState({ verticalLoading: false });
      });
  };

  handleDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYYMMDD');
    this.setState({ dateKey });
  };

  handleSlotIdChange = (event) => {
    const slotId = event.target.value;
    if (!slotId) {
      return;
    }
    this.setState({ slotId });
  };

  handleBadFormInput = (doctorId, dateKey, slot, userId, centerId) => {
    let alertMessage = 'Bad Input';
    if (!dateKey) {
      alertMessage = 'You need to select a date';
    }
    if (!slot) {
      alertMessage = 'You need to select a slot';
    }
    if (!doctorId) {
      alertMessage = 'You need to select a vertical and a doctor';
    }
    if (!centerId) {
      alertMessage = 'No Center Found for this doctor';
    }
    // show alert
    this.setState({ showFormWarning: true, alertMessage });
  };

  placeRequest = () => {
    const { dateKey, userId } = this.state;
    console.log(
      { internalState: this.doctorSelectRef.current.select.state.value },
      '[placeRequest]'
    );
    const doctorId =
      this.doctorSelectRef.current.select.state.value.value || false;
    let relativeId = null
    const slotId = this.slotSelectRef.current.state.value
      ? this.slotSelectRef.current.state.value.value
      : false;

    const centerId =
      this.doctorSelectRef.current.select.state.value.centerId || false;

    if (!doctorId || !dateKey || !slotId  || !centerId) {
      // show alert
      this.handleBadFormInput(doctorId, dateKey, slotId, userId, centerId);
      return;
    }
    this.setState({
      loading: true,
    });
    console.log(doctorId, centerId, userId, relativeId, dateKey, slotId);
    this.props
      .submit(doctorId, centerId, dateKey, slotId)
      .then(() => true)
      .finally(() => {
        this.setState({ loading: false });
      });
  };


  getNetworkName = (sourceId) => {
    switch (sourceId) {
      case 1: {
        return 'Practo';
      }
      case 2: {
        return 'Crediheatlh';
      }
      case 3: {
        return 'Medibuddy';
      }
      case 4: {
        return 'Visit Online';
      }
      case 5: {
        return 'Docprime';
      }
      case 6: {
        return 'Visit Onboarded';
      }
      default: {
        return 'Unknown';
      }
    }
  };

  searchDoctors = (val) => {
    const verticalId = this.verticalSelectRef.current.state.value.value;
    return searchDoctors(val, verticalId)
      .then((response) => {
        return response.data || [];
      })
      .then((result) =>
        result.map((ele) => {
          return {
            label: `${ele.doctorName} - ${ele.networkCenterTitle} - ${ele.centerCity} - ${ele.networkSourceName}`,
            value: ele.doctorId,
            centerId: ele.networkCenterId,
          };
        })
      )
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      verticalsResult,
      verticalLoading,
      loading,
    } = this.state;
    return (
      <div>
            <form>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Select Vertical:</Label>
                  </Col>
                  <Col>
                    <Select
                      isSearchable={true}
                      ref={this.verticalSelectRef}
                    
                      options={verticalsResult}
                      isLoading={verticalLoading}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Search Doctor:</Label>
                  </Col>
                  <Col>
                    <Async
                      loadOptions={this.searchDoctors}
                      ref={this.doctorSelectRef}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Date:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="date"
                      onChange={this.handleDateChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Select Slot:</Label>
                  </Col>
                  <Col>
                    <Select
                      ref={this.slotSelectRef}
                      isSearchable={false}
                      options={timeSlots}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </form>
            <Alert
              color="warning"
              isOpen={this.state.showFormWarning}
              toggle={this.onAlertDismiss}
            >
              {this.state.alertMessage}
            </Alert>
         
            {loading ? (
              <LoadingComponent />
            ) : (
              <Button color="primary" onClick={this.placeRequest}>
                Place Request
              </Button>
            )}
            <Button color="secondary" onClick={this.props.closeModal}>
              Close
            </Button>
      </div>
    );
  }
}

export default PlaceRequest;
