import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from '../layout/NavBar';
import {
  getOrders,
  getUserCartForOrder,
  getOrderDetails,
  getAvailablePartnersForCart,
  saveCartRemark,
  uploadReport,
  getTransactionHistory,
  getAddressProof,
  viewCommentRequest,
  viewPayoutDetails,
} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import LabCart from '../lab-rx-requests/LabCart';
import LabOrderEditTest from './LabOrderEditTest';
import { setCartInfo } from '../../actions';
import LabOrderConvertToReimbursement from './LabOrderConvertToReimbursement';
import OrderActionModal from './OrderActionModal';
import OrderActionButton from './OrderActionButton';
import LabsClickToCallButton from './LabsClickToCallButton';
import Modal from '../common/Modal';
import LabOrderActivities from './LabOrderActivities';

import appConfig from '../../config/app-config';
import CommentList from './comments/commentList';
import CommentForm from './comments/commentForm';
import LabsOrderCopayForm from './LabsOrderCopayForm';
import LabsOrderChangePartnerForm from './LabsOrderChangePartnerForm';
import LabsOrderChangeStatusForm from './LabsOrderChangeStatusForm';
import styled from 'styled-components';
import StarRatings from 'react-star-ratings';
import { object } from 'prop-types';
import { getLabOrdersTimeline } from '../../services/api/lab-order-details';
import OrderStatus from './OrderStatus';

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0rem;
`;
const DetailHeader = styled.strong`
  flex: 1;
  font-size: 1rem;
  color: ${(props) => props.color || '#4c4c4c'};
  margin-left: 0rem;
  margin-bottom: 0rem;
  max-width: 10rem;
`;
const DetailName = styled.p`
  font-size: 1rem;
  width: ${(props) => (props.fullWidth ? '20rem' : '9rem')};
  color: #4c4c4c;
  margin-bottom: 0rem;
`;

const DetailText = styled.strong`
  flex: 1;
  font-size: 1rem;
  color: ${(props) => props.color || '#4c4c4c'};
  margin-left: 0.1rem;
  margin-bottom: 0rem;
  max-width: 10rem;
`;

class LabOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dependentDetails: {},
      cartDetails: {},
      cartItemsResult: {},
      orderId: null,
      openEditTestModal: false,
      labPartners: [],
      selectedPartnerId: null,
      remarks: '',
      activeInfo: 'order-details',
      selectedTestForEdit: {},
      comments: [],
      loading: false,
      showTimeline: false,
      copayBlockerModal: {
        isOpen: false,
      },
      changePartnerModal: {
        isOpen: false,
      },
      changeStatusModal: {
        isOpen: false,
      },
      orderDetails: {
        actions: [],
      },
      totalObject: {},
      tickets: [],
      actionModal: {
        orderId: null,
        isOpen: false,
        actionName: null,
        status: null,
        group: 'lab',
        actionType: null,
        collectionDate: null,
        slotId: null,
        centerDetails: null,
        allowReport: false,
      },
      rate: 0,
      reason: [],
      userReview: '',
    };
    this.addComment = this.addComment.bind(this);
  }

  makeDefaultState = () => ({
    cartItemsResult: {
      itemsList: [],
    },
  });

  async fetchTimeline(orderId) {
    const data = await getLabOrdersTimeline(
      orderId,
      this.state.orderDetails.userId
    );
    console.log(data);
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get('orderId');
    const sourceId = params.get('sourceId');
    this.fetchCommentByOrderId(orderId);
    if (sourceId) {
      this.fetchOrderById(sourceId);
    } else {
      this.setState(
        {
          orderId,
        },
        this.loadCart
      );
    }
  }
  fetchCommentByOrderId = (orderId) => {
    viewCommentRequest(orderId, appConfig.REQUEST_CHAT.INTERNAL)
      .then((response) => this.handleComments(response))
      .then((comments) => {
        // setting the state
        this.setState({
          comments,
          requestChat: {
            orderId,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchOrderById = (sourceId) => {
    getUserCartForOrder(sourceId).then((response) => {
      if (response.body.message === 'success') {
        const { cartDetails } = response.body;
        console.log(response);
        this.setState(
          {
            orderId: cartDetails.orderId,
          },
          this.loadCart
        );
      } else {
        toast.error(`Unable to fetch details for the order.`);
      }
    });
  };

  loadCart = () => {
    this.getUserDetails();
    const { orderId } = this.state;
    if (!orderId) {
      return;
    }
    getAvailablePartnersForCart(orderId)
      .then((result) => {
        if (result.body.message === 'success') {
          this.setState({
            labPartners: result.body.partners,
          });
        } else {
          toast.error('Error in getting partners for this cart');
        }
      })
      .catch((err) => {
        console.error('Error in fetching partners', err);
        toast.error('Error in getting partners for this cart');
      });
    getOrders({ orderId }).then((response) => {
      this.setState({
        orderDetails: response.body.data.result[0],
        showTimeline: true,
      });
    });

    getTransactionHistory(orderId)
      .then((response) => {
        console.log(response, 'Response from api');
        if (response.message === 'success') {
          this.setState({
            transactions: response.transactions,
          });
        } else {
          throw new Error(
            response.errorMessage ||
              'Something went wrong while fetching transaction'
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });

    viewPayoutDetails(orderId).then((response) => {
      console.log(response, 'responseFromPayoutDetails');
      if (response.message === 'success') {
        this.setState({
          payouts: response.payouts,
        });
      } else {
        throw new Error(
          response.errorMessage ||
            'Something went wrong while fetching transaction'
        );
      }
    });

    getAddressProof(orderId).then((response) => {
      console.log(response, 'Response from api');
      if (response.message === 'success') {
        this.setState({
          addressProof: response.files,
        });
        console.log(this.state.addressProof);
      }
    });

    getUserCartForOrder(this.state.orderId)
      .then((response) => {
        console.log(response.body);
        const totalObject = {
          labName: 'TOTAL',
          mrp: 0,
          cost: 0,
          postDiscountPrice: 0,
          walletDeduction: 0,
        };
        response.body.cartItemsResult.itemsList =
          response.body.cartItemsResult.itemsList.map((i) => {
            i.label = i.labName;
            i.value = i.labId;
            totalObject.cost += i.cost;
            totalObject.mrp += i.mrp;
            totalObject.postDiscountPrice += i.postDiscountPrice;
            totalObject.walletDeduction += parseInt(i.walletDeduction);

            return i;
          });

        this.setState({ totalObject });
        // this.setState({
        //     cartItemsResult: response.body.cartItemsResult,
        // });
        this.setState({
          centerDetails: response.body.centerDetails,
        });
        this.props.dispatch(setCartInfo(response.body.cartItemsResult));
        // this.props.refresh();
      })
      .catch((err) => {
        if (err.response.data.errorMessage === 'No items in the cart') {
          this.props.dispatch(
            setCartInfo(this.makeDefaultState().cartItemsResult)
          );
        } else {
          console.error('Error in fetching users cart', err);
        }
      });
  };

  getUserDetails = () => {
    const { userId, orderId } = this.state;
    console.log(userId, orderId, '[getUserDetails]');
    if (!orderId) {
      return;
    }
    return getOrderDetails(orderId)
      .then((response) => {
        const cartDetails = response.body.cartDetails;
        const rating = response.body.labsFeedback
          ? parseInt(response.body.labsFeedback.rating)
          : 0;
        const reasons = response.body.labsFeedback
          ? response.body.labsFeedback.reasons
          : '[]';
        const review = response.body.labsFeedback
          ? response.body.labsFeedback.text
          : 'no review';
        let reason = JSON.parse(reasons);
        if (typeof reasons != 'object') {
          reason = [];
        }
        this.setState({
          cartDetails: cartDetails,
          dependentDetails: response.body.dependentDetails || {},
          remarks: cartDetails.remarks || '',
          rate: rating || 0,
          reason: reason || [],
          userReview: review || 'Not Available',
        });
      })
      .catch((err) => {
        console.error('Error in loading user details', err);
        toast.error(`Unable to load User details`);
      });
  };

  openEditTestModal = (test) => {
    console.log('Opening edit test modal with the followng test', test);
    this.setState({
      openEditTestModal: true,
      selectedTestForEdit: test,
    });
  };

  saveRemarks = () => {
    const { cartId, remarks } = this.state;
    return saveCartRemark(cartId, remarks)
      .then((response) => {
        if (response.body.message === 'success') {
          toast.success('Remark Saved');
        } else {
          toast.error(`Unable to save remarks`);
        }
      })
      .catch((err) => {
        console.error('Error in saving remarks', err);
        toast.error(`Unable to save remarks`);
      });
  };

  onFileInputChanged = (event, partialReport = false) => {
    console.log('File input state changed', event, partialReport);
    console.log(event.target.files[0]);
    this.uploadLabReport(event.target.files[0], partialReport);
  };

  uploadLabReport = (file, partialReport) => {
    uploadReport(this.state.orderId, file, partialReport)
      .then((response) => {
        if (response.body.message === 'success') {
          toast.success('Report uploaded');
        } else {
          toast.error(`Unable to upload report`);
        }
      })
      .catch((err) => {
        console.error('Unable to upload report', err);
        toast.error(`Unable to upload report`);
      })
      .finally(() => {
        this.closeAllowReport();
      });
  };

  renderPartnerList = () => {
    const { labPartners } = this.state;
    if (labPartners && labPartners.length === 0) {
      return '';
    }
    return (
      <select
        id="inputPartner"
        className="form-control mr-3"
        onChange={this.onPartnerChanged}
      >
        {labPartners.map((p) => (
          <option disabled={!p.available} value={p.id} key={p.id}>
            {p.name}
          </option>
        ))}
      </select>
    );
  };

  onPartnerChanged = (e) => {
    console.log(e.target.value, 'New partner selected');
    this.setState({
      selectedPartnerId: parseInt(e.target.value),
    });
  };

  renderUploadedFiles = () => {
    const { cartDetails } = this.state;
    if (
      cartDetails &&
      cartDetails.uploadedFiles &&
      this.state.cartDetails.uploadedFiles !== null
    ) {
      return cartDetails.uploadedFiles.split(',').map((f, index) => (
        <a
          className="m-1 p-2 badge badge-primary"
          key={index}
          href={f}
          target="_blank"
        >
          {index + 1}
        </a>
      ));
    } else {
      return '';
    }
  };

  handleOpenActionModal = (
    orderId,
    actionType,
    actionName,
    status,
    collectionDate,
    slotId
  ) => {
    console.log(orderId, actionType, status, collectionDate, slotId, 'Open');
    this.setState({
      actionModal: {
        orderId,
        isOpen: true,
        actionType,
        actionName,
        status,
        collectionDate,
        slotId,
      },
    });
  };
  handleCloseOrderActionModal = () => {
    this.setState(
      {
        actionModal: {
          orderId: null,
          isOpen: false,
          actionName: null,
          status: null,
          actionType: null,
          collectionDate: null,
          slotId: null,
        },
      },
      this.loadCart
    );
  };

  openAddCopayModal = () => {
    this.setState({
      copayBlockerModal: {
        isOpen: true,
      },
    });
  };

  closeAddCopayModal = () => {
    this.setState(
      {
        copayBlockerModal: {
          isOpen: false,
        },
      },
      this.loadCart
    );
  };

  closeChangePartnerModal = () => {
    this.setState({
      changePartnerModal: false,
    });
  };
  closeChangeStatusModal = () => {
    this.setState({
      changeStatusModal: {
        isOpen: false,
      },
    });
  };
  closeAllowReport = () => {
    this.setState({
      allowReport: false,
    });
  };
  openChangePartnerModal = () => {
    this.setState({
      changePartnerModal: {
        isOpen: true,
      },
    });
  };
  openChangeStatusModal = () => {
    this.setState({
      changeStatusModal: {
        isOpen: true,
      },
    });
  };
  handleClassNameNavTab = (currentActiveInfo) => {
    let className = 'btn nav-link text-light';
    const { activeInfo } = this.state;
    if (activeInfo === currentActiveInfo) {
      className = 'btn nav-link active';
    }

    return className;
  };
  handleComments = (response) => {
    const comments = [];
    if (response.message === 'success' && response.results.length) {
      // build comments
      response.results.forEach((c) => {
        comments.push({
          name: c.userName,
          message: c.text,
          time: c.createdAt,
        });
      });
    }
    return comments;
  };

  addComment(comment) {
    this.setState({
      loading: false,
      comments: [...this.state.comments, comment],
    });
  }

  render() {
    const {
      actionModal,
      orderDetails,
      activeInfo,
      cartDetails,
      orderId,
      comments,
      loading,
      centerDetails,
    } = this.state;

    const renderOrderDetails = () => (
      <div className="col">
        <div className="row mb-2">
          <SectionBody>
            <DetailRow>
              <DetailHeader>User Details:</DetailHeader>
            </DetailRow>
            <DetailRow>
              <DetailName>User Name:</DetailName>
              <DetailText>{orderDetails.userName}</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>User ID:</DetailName>
              <DetailText>{orderDetails.userId}</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Email:</DetailName>
              <DetailText>{orderDetails.email}</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Sponsor Name:</DetailName>
              <DetailText>{orderDetails.sponsorName}</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Phone:</DetailName>
              <LabsClickToCallButton
                orderId={orderId}
                phone={orderDetails.userPhone}
              />
            </DetailRow>
          </SectionBody>
          <SectionBody>
            <DetailRow>
              <DetailHeader>Patient Details:</DetailHeader>
            </DetailRow>
            <DetailRow>
              <DetailName>Patient Name:</DetailName>
              <DetailText>{orderDetails.patientName}</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Gender:</DetailName>
              <DetailText>{orderDetails.patientGender}</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Age:</DetailName>
              <DetailText>{orderDetails.patientAge}</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Phone:</DetailName>
              <LabsClickToCallButton
                orderId={orderId}
                phone={orderDetails.patientPhone}
              />
            </DetailRow>
          </SectionBody>
        </div>
        <div className="row">
          <SectionBody>
            <DetailRow>
              <DetailHeader>Order Details:</DetailHeader>
            </DetailRow>
            <DetailRow>
              <DetailName>Pickup Slot:</DetailName>
              <DetailText>
                {orderDetails.slotTime} |{orderDetails.collectionDate}
              </DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Verified Prescription:</DetailName>
              <DetailText>
                {cartDetails.verifiedPrescription !== null ? (
                  <a target="_blank" href={cartDetails.verifiedPrescription}>
                    Open
                  </a>
                ) : (
                  'NA'
                )}
              </DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Uploaded Files:</DetailName>
              <DetailText>{this.renderUploadedFiles()}</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Address:</DetailName>
              <DetailText>
                {cartDetails.address ||
                  (centerDetails ? centerDetails.address : null)}{' '}
                |{' '}
                {cartDetails.locality ||
                  (centerDetails ? centerDetails.locality : null)}
                {cartDetails.pincode ||
                  (centerDetails ? centerDetails.pincode : null)}
              </DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Transaction ID:</DetailName>
              <DetailText>{cartDetails.transactionId}</DetailText>
            </DetailRow>
            <DetailRow>
              <DetailName>Cart ID:</DetailName>
              <DetailText>{cartDetails.cartId}</DetailText>
            </DetailRow>
            <br></br>
            <h3
              className="text-black"
              style={{
                fontWeight: 'bold',
              }}
            >
              User Ratings
            </h3>
            <StarRatings
              rating={this.state.rate}
              starRatedColor="blue"
              numberOfStars={5}
              name="rating"
              starDimension="40px"
              starSpacing="15xpx"
            />
            <br></br>
            <h3
              style={{
                fontWeight: 'bold',
              }}
            >
              Reason
            </h3>
            <DetailText>
              <ul>
                {this.state.reason.map((i) => {
                  return <li>{i}</li>;
                })}
              </ul>
            </DetailText>
            <br></br>
            <h3
              style={{
                fontWeight: 'bold',
                // backgroundColor: 'yellow',
              }}
            >
              User Review
            </h3>
            <DetailText>{this.state.userReview}</DetailText>
          </SectionBody>
          <div className="col">
            <div className="row">
              <button
                style={{
                  cursor: 'pointer',
                }}
                className="ml-1 mb-2 btn btn-outline-primary"
                onClick={() => this.setState({ allowReport: true })}
                disabled={this.state.orderDetails.pauseUploadReport}
              >
                Upload Report
                {/* <input
                    type="file"
                    hidden
                    // onChange={(e) => this.onFileInputChanged(e)}
                    onClick={() => this.setState({ allowReport: true })}
                  /> */}
              </button>
              <LabOrderConvertToReimbursement orderId={orderId} />
            </div>
            <div className="row mt-2">
              <button
                style={{ marginLeft: '5px' }}
                className="btn btn-primary"
                onClick={this.openAddCopayModal}
              >
                Change Pricing
              </button>
              <button
                style={{ marginLeft: '5px' }}
                className="btn btn-primary"
                onClick={this.openChangePartnerModal}
              >
                Change Partner
              </button>
              <button
                style={{ marginLeft: '5px' }}
                className="btn btn-primary"
                onClick={this.openChangeStatusModal}
              >
                Change Status
              </button>
            </div>
            {cartDetails.partnerOrderId && (
              <div className="mt-4">
                <SectionBody>
                  <DetailRow className="mb-3">
                    <DetailHeader>Phlebo Details:</DetailHeader>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Name:</DetailName>
                    <DetailText>{cartDetails.phleboName}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Phone Number:</DetailName>
                    <DetailText>{cartDetails.phleboNumber}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Tracking Link:</DetailName>
                    <a href={cartDetails.phleboTrackingLink} target="_blank">
                      {cartDetails.phleboTrackingLink}
                    </a>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Partner Booking Id:</DetailName>
                    <DetailText>{cartDetails.partnerOrderId}</DetailText>
                  </DetailRow>
                </SectionBody>
              </div>
            )}
          </div>
        </div>
      </div>
    );
    const renderCartItems = () => (
      <div>
        <div className="row">
          <LabOrderEditTest
            loadCart={this.loadCart}
            orderId={this.state.orderId}
            cartId={this.state.cartId}
            open={this.state.openEditTestModal}
            close={() =>
              this.setState({
                openEditTestModal: false,
              })
            }
            selectedTestForEdit={this.state.selectedTestForEdit}
          />
        </div>
        <div className="row">
          <LabCart
            testType={this.state.orderDetails.testsType}
            openEditTestModal={this.openEditTestModal}
            loadCart={this.loadCart}
            add={true}
            edit={false}
            delete={false}
            orderId={this.state.orderId}
            totalObject={this.state.totalObject}
          />
          <button
            className="btn btn-primary ml-4"
            onClick={this.openAddCopayModal}
          >
            Change Pricing
          </button>
        </div>
      </div>
    );
    const renderPayinPayout = () => (
      <div className="row">
        <div className="col-5">
          {this.state.transactions && this.state.transactions.length ? (
            <div className="card">
              <div className="card-header">
                <div className="row no-gutters">
                  <div className="col">Transaction History</div>
                </div>
              </div>
              <div className="card-body">
                <div className="container-fluid">
                  {this.state.transactions.map((t) => (
                    <div className="row" key={`${t.id}-${t.type}`}>
                      <div className="col">
                        <li>
                          {t.type === 'refund' ? 'Refund' : 'Copay'} of Rs.{' '}
                          {t.amount} on {t.formattedDate} - {t.status}{' '}
                          {t.arn ? `(arn: ${t.arn})` : ''}
                        </li>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div className="col-5">
          {this.state.payouts && this.state.payouts.length ? (
            <div className="card">
              <div className="card-header">
                <div className="row no-gutters">
                  <div className="col">Payout History</div>
                </div>
              </div>
              <div className="card-body">
                <div className="container-fluid">
                  {this.state.payouts.map((p) => (
                    <div className="col">
                      <li>
                        Payout of amount: Rs {p.amount} via mode {p.payout_mode}{' '}
                        - {p.status} on {p.formattedDate} by {p.payoutBy}{' '}
                        {p.utr ? `(utr: ${p.utr})` : ''}
                      </li>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
    const renderTrudesk = () => (
      <div>
        {this.state.tickets && this.state.tickets.length ? (
          <table className="table table-bordered">
            <thead>
              <td>ID</td>
              <td>Subject</td>
              <td>Status</td>
              <td>Date</td>
            </thead>
            <tbody>
              {this.state.tickets.map((t) => (
                <tr>
                  <td>
                    <a href={t.url} target="_blank">
                      {t.ticketId}
                    </a>
                  </td>
                  <td>{t.subject}</td>

                  <td>{t.status}</td>
                  <td>{t.createdAt}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No trudesk ticket present</p>
        )}
      </div>
    );
    const renderActivity = () => <LabOrderActivities orderId={orderId} />;
    return (
      <div className="container-fluid">
        <div className="row">
          <NavBar />
        </div>
        <div className="row mt-5 pt-1">
          <div className="col-9 mt-3">
            <div className="row mb-3 ml-1">
              <div className="col ml-0 pl-0">
                <SectionBody>
                  <DetailRow>
                    <DetailName>Lab Order:</DetailName>
                    <DetailText>{orderId}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Partner Name:</DetailName>
                    <DetailText>
                      {orderDetails.partnerName}({orderDetails.partnerId})
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Status:</DetailName>
                    {orderDetails.status ? (
                      <DetailText className="m-1 p-2 mb-0 pb-0 mt-0 pb-0 badge badge-primary">
                        {orderDetails.status}
                      </DetailText>
                    ) : null}
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Center Visit:</DetailName>
                    <DetailText>
                      {orderDetails.isCenterVisit ? 'Yes' : 'No'}
                    </DetailText>
                  </DetailRow>
                </SectionBody>
              </div>
              <div className="col">
                <SectionBody>
                  <DetailRow>
                    <DetailName>Tests Type:</DetailName>
                    <DetailText>{orderDetails.testsType}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Partner BookingId:</DetailName>
                    {cartDetails.partnerBookingId ? (
                      <DetailText className="m-1 p-2 mb-0 pb-0 mt-0 pb-0 badge-primary">
                        {cartDetails.partnerBookingId}
                      </DetailText>
                    ) : null}
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Account Id:</DetailName>
                    {cartDetails.partnerAccountId ? (
                      <DetailText className="m-1 p-2 mb-0 pb-0 mt-0 pb-0 badge badge-primary">
                        {cartDetails.partnerAccountId}
                      </DetailText>
                    ) : null}
                  </DetailRow>
                </SectionBody>
              </div>
              <div className="col">
                <p className="mb-0 pb-0 mt-0 pb-0 mr-1">
                  <strong>Status Action:</strong>
                </p>
                <OrderActionButton
                  orderId={orderDetails.orderId}
                  collectionDate={orderDetails.collectionDate}
                  status={orderDetails.status}
                  slotId={orderDetails.slotId}
                  actions={orderDetails.actions}
                  onClick={this.handleOpenActionModal}
                />
              </div>
            </div>
            <ul className="nav nav-dark nav-pills nav-fill bg-dark ml-2">
              <li className="nav-item">
                <button
                  type="button"
                  className={this.handleClassNameNavTab('order-details')}
                  onClick={() => this.setState({ activeInfo: 'order-details' })}
                >
                  Order Details
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={this.handleClassNameNavTab('cart-items')}
                  onClick={() => this.setState({ activeInfo: 'cart-items' })}
                >
                  Cart Items
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={this.handleClassNameNavTab('payin-payout')}
                  onClick={() => this.setState({ activeInfo: 'payin-payout' })}
                >
                  Payin/Payout
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={this.handleClassNameNavTab('trudesk')}
                  onClick={() => this.setState({ activeInfo: 'trudesk' })}
                >
                  Trudesk
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={this.handleClassNameNavTab('activity')}
                  onClick={() => this.setState({ activeInfo: 'activity' })}
                >
                  Activity
                </button>
              </li>
            </ul>
            <div className="row">
              <div className="col mt-2">
                {activeInfo === 'order-details' && renderOrderDetails()}
                {activeInfo === 'cart-items' && renderCartItems()}
                {activeInfo === 'payin-payout' && renderPayinPayout()}
                {activeInfo === 'trudesk' && renderTrudesk()}
                {activeInfo === 'activity' && renderActivity()}
              </div>
            </div>
          </div>
          <div className="col mt-3">
            {this.state.showTimeline && (
              <div>
                <OrderStatus
                  orderId={this.state.orderId}
                  userId={this.state.orderDetails.userId}
                ></OrderStatus>
              </div>
            )}
            <CommentList loading={loading} comments={comments} />
            <div className="">
              <CommentForm
                addComment={this.addComment}
                orderId={orderId}
                commentType={appConfig.LABS.labsOrder}
              />
            </div>
          </div>
        </div>

        {/* <div className="row">
          {this.state.centerDetails && this.state.centerDetails.address ? (
            <div className="col-4">
              <div className="card">
                <div className="card-header">
                  <div className="row no-gutters">
                    <div className="col">Center Details</div>
                    <div className="col text-right">
                      {this.state.centerDetails.isCenterVisit ? (
                        <span className="badge badge-success">
                          <b>Center Visit</b>
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-8">
                        {this.state.centerDetails.address},{' '}
                        {this.state.centerDetails.locality},{' '}
                        {this.state.centerDetails.city}
                      </div>
                      <div className="col text-right">
                        {this.state.centerDetails.pincode}
                      </div>
                    </div>
                    <br />
                    <div className="row border rounded border-dark">
                      <div className="col border text-center border-dark">
                        Booking {this.state.centerDetails.bookingMedium}
                      </div>
                      <div className="col border text-center border-dark">
                        {this.state.centerDetails.bookingMedium === 'phone'
                          ? this.state.centerDetails.bookingPhone
                          : this.state.centerDetails.bookingEmail}
                      </div>
                    </div>
                    <br />
                    <div className="row border rounded border-dark">
                      <div className="col border text-center border-dark">
                        Escalation {this.state.centerDetails.escalationMedium}
                      </div>
                      <div className="col border text-center border-dark">
                        {this.state.centerDetails.escalationMedium === 'phone'
                          ? this.state.centerDetails.escalationPhone
                          : this.state.centerDetails.escalationEmail}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-5">
            {this.state.addressProof && this.state.addressProof.length ? (
              <div className="card">
                <div className="card-header">
                  <div className="row no-gutters">
                    <div className="col">Address Proofs</div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="container-fluid">
                    {this.state.addressProof.map((p) => (
                      <div className="row" key={`${p.url}`}>
                        <div className="col">
                          <li>
                            URL:{' '}
                            <a href={p.fileUrl} target="_blank">
                              {' '}
                              {p.fileType}{' '}
                            </a>
                          </li>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <br /> */}
        <Modal
          header={`Upload Prescription`}
          isOpen={this.state.allowReport}
          onClose={this.closeAllowReport}
        >
          <div>
            <div className="row">
              <div
                style={{
                  cursor: 'pointer',
                }}
                className="ml-1"
              >
                <label className="btn btn-outline-primary">
                  Partial Report
                  <input
                    type="file"
                    hidden
                    onChange={(e) => this.onFileInputChanged(e, true)}
                  />
                </label>
              </div>
              <div
                style={{
                  cursor: 'pointer',
                }}
                className="ml-1"
              >
                <label className="btn btn-outline-primary">
                  Complete Report
                  <input
                    type="file"
                    hidden
                    onChange={(e) => this.onFileInputChanged(e)}
                  />
                </label>
              </div>
            </div>
          </div>
        </Modal>
        <OrderActionModal
          orderId={actionModal.orderId}
          isOpen={actionModal.isOpen}
          actionName={actionModal.actionName}
          status={actionModal.status}
          actionType={actionModal.actionType}
          collectionDate={actionModal.collectionDate}
          slotId={actionModal.slotId}
          handlingCharge={this.state.orderDetails.collectionCharge}
          onClose={this.handleCloseOrderActionModal}
        />
        <Modal
          header={`Change Pricing`}
          isOpen={this.state.copayBlockerModal.isOpen}
          onClose={this.closeAddCopayModal}
        >
          <LabsOrderCopayForm
            orderId={this.state.orderId}
            cartItemsResult={this.props.cartItemsResult}
            onSubmit={this.closeAddCopayModal}
          />
        </Modal>
        <Modal
          header={`Change Partner for Order`}
          isOpen={this.state.changePartnerModal.isOpen}
          onClose={this.closeChangePartnerModal}
        >
          <LabsOrderChangePartnerForm
            orderId={this.state.orderId}
            onSubmit={this.closeChangePartnerModal}
            addressId={this.state.orderDetails.addressId}
            isRadiology={this.state.orderDetails.testsType === 'radiology'}
            cartId={this.state.cartDetails.cartId}
          />
        </Modal>
        <Modal
          header={`Change Status`}
          isOpen={this.state.changeStatusModal.isOpen}
          onClose={this.closeChangeStatusModal}
        >
          <LabsOrderChangeStatusForm
            orderId={this.state.orderId}
            onClose={this.closeChangeStatusModal}
          />
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cartItemsResult: state.labsDashboard.cartItemsResult,
  };
}

export default connect(mapStateToProps)(LabOrderDetails);
