import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { addPatientForUser, listPatients } from '../../../services/api/rx-transcription';
import { toast } from 'react-toastify';
import {
    setSelectedAddressRxDetailsForTranscription,
    setSelectedPatientRxDetailsForTranscription
} from "../../../actions";

class LabRxPatientDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { collapse: false, patients: [], name: '', age: '', gender: 'Male', phone: '', selectedPatient: null};
    }

    toggle = () => {
        this.setState(state => ({ collapse: !state.collapse }));
        if (!this.collapse) {
           this.getPatientList();
        }
    };


    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userInfo.userId !== this.props.userInfo.userId) {
            this.setState(state => ({ collapse: false }));
        }
    }


    getPatientList = () => {
        listPatients(this.props.userInfo.authToken)
            .then((response) => {
                if(response.body.message === 'success') {
                    this.setState({
                        patients: response.body.patients,
                    });
                } else {
                    toast.error('Error in listing patients');
                }
            })
            .catch((err) => {
                toast.error('Error in listing patients');
                console.error('Error', err);
            });
    };

    selectPatient = (patient) => {
        let selected = null;
        const patients = this.state.patients;
        patients.forEach((p) => {
           if (patient.patientId === p.patientId) {
               p.active = true;
               selected = p;
           } else {
               p.active = false;
           }
        });

        this.props.dispatch(setSelectedPatientRxDetailsForTranscription(selected));

        this.setState({selectedPatient: selected, patients});
    };

    renderPatients = () => {
        return this.state.patients.map((p) => {
            const containerClasses = 'list-group-item list-group-item-action ';
            return (
                <div onClick={() => this.selectPatient(p)} className={containerClasses + (p.active ? 'active': '')} key={p.patientId}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{p.name} | {p.phone}</h5>
                    </div>
                    <p className="mb-1">{p.gender} - {p.age} years old</p>
                </div>
            );
        });
    };

    addPatient = () => {
        if(this.state.name && this.state.age && this.state.gender && this.state.phone) {
            addPatientForUser(this.state.name, this.state.age, this.state.gender, this.state.phone, this.props.userInfo.authToken)
                .then((response) => {
                    if (response.body.message === 'success') {
                        this.getPatientList();
                        this.setState({
                            name: '',
                            age: '',
                            phone: '',
                        });
                    } else {
                        toast.error('Error in adding patient');
                    }
                })
                .catch((err) => {
                    toast.error('Error in adding patient');
                    console.error('Error', err);
                });
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col">
                    <div className="row">
                        <button disabled={this.props.disabled} className="btn btn-primary" onClick={this.toggle} style={{ marginBottom: '1rem' }}>Patient Details</button>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Collapse isOpen={this.state.collapse}>
                                <Card>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">Patient Name</label>
                                                            <input type="text" className="form-control" id="inputEmail4"
                                                                   placeholder="Name" value={this.state.name} onChange={(e) => this.setState({name: e.target.value})}/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputPassword4">Age</label>
                                                            <input type="number" className="form-control" id="inputPassword4"
                                                                   placeholder="Password" value={this.state.age} onChange={(e) => this.setState({age: e.target.value})}/>
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputCity">Phone</label>
                                                            <input type="number" className="form-control" value={this.state.phone} id="inputCity" onChange={(e) => this.setState({phone: e.target.value})}/>
                                                        </div>



                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio"
                                                                   name="inlineRadioOptions" id="inlineRadio1"
                                                                   value="Male" onChange={(e) => this.setState({gender: e.target.value})}/>
                                                                <label className="form-check-label"
                                                                       htmlFor="inlineRadio1">Male</label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input className="form-check-input" type="radio"
                                                                   name="inlineRadioOptions" id="inlineRadio2"
                                                                   value="Female"/>
                                                                <label className="form-check-label"
                                                                       htmlFor="inlineRadio2">Female</label>
                                                        </div>
                                                    </div>

                                                    <button className="btn btn-primary" onClick={this.addPatient}>Add Patient</button>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="list-group">
                                                    {this.renderPatients()}
                                                </div>
                                            </div>
                                        </div>


                                    </CardBody>
                                </Card>
                            </Collapse>
                        </div>
                    </div>
                </div>



            </div>

        );
    }
}

const mapStateToProps = state => ({ userInfo: state.createLabRxDetails.userInfo });

export default connect(
    mapStateToProps,
)(LabRxPatientDetails);





