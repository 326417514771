import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { OuterComponent } from './StyledComponent';
import { addSponsorPool } from '../../services/api/redemptions';
import Select from 'react-select';

class AddSponsorPool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sponsors: this.props.sponsorsData || [],
    };
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.poolId !== this.props.poolId) {
      this.setState({
        sponsors: this.props.sponsorsData,
      });
    }
  };

  addSponsorPool = () => {
    let sponsorsId = [];
    this.state.sponsors.forEach((element) => {
      sponsorsId.push(element.value);
    });
    const data = {
      productsPoolsId: this.props.poolId,
      sponsorsId,
    };
    console.log({ data }, 'addProduct');
    return addSponsorPool(data)
      .then((responseBody) => {
        this.setState({
          loading: false,
        });
        this.props.togglePage();
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };
  handleChangeSponsor = (sponsors) => {
    console.log({ sponsors });
    this.setState({ sponsors });
  };
  render() {
    return (
      <OuterComponent>
        <Card>
          <CardHeader>
            <strong>Add Sponsor Pool</strong>
          </CardHeader>
          <CardBody>
            <Form
              action=""
              method="post"
              encType="multipart/form-data"
              className="form-horizontal"
            >
              <FormGroup row>
                <Col md="3">
                  <Label htmlFor="text-input">Sponsor Name</Label>
                </Col>
                <Col xs="12" md="9">
                  <Select
                    isMulti
                    value={this.state.sponsors}
                    onChange={this.handleChangeSponsor}
                    options={this.props.sponsorOptions}
                  />
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter>
            <Button onClick={this.addSponsorPool}>Confirm</Button>
            <Button style={{ marginLeft: 15 }} onClick={this.props.togglePage}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
      </OuterComponent>
    );
  }
}

export default AddSponsorPool;
