import React from 'react';

import { Button } from 'reactstrap';

import { FaSpinner, FaPhone } from 'react-icons/fa';

export default class CallingButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    handleClick = (event) => {
        this.setState({ loading: true });
        this.props.onSubmit()
        // .finally(() => {

        // });
        this.setState({ loading: false });
    }

    render() {
        const { loading } = this.state;
        return (
            <div>
                <Button
                    color="link"
                    className="p-0 ml-1"
                >
                    {loading ? (
                        <FaSpinner className="icon-spin text-success" />
                    ) : (
                            <FaPhone className="text-success" onClick={this.handleClick} />
                        )}
                </Button>
            </div>
        );
    }
}
