import React from 'react';

import Link from 'valuelink';
import { Input } from 'valuelink/tags';

import { toast } from 'react-toastify';

import LoadingComponent from "../common/LoadingComponent";

import { updateAlternateNumber } from '../../services/api/doc-on-call';

export default class AddAlterNumberForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            phone: '',
        };
    }

    componentDidMount() {
        this.setState({
            phone: this.props.alternatePhone
        });
    }

    submit = () => {
        const { phone } = this.state;
        const { consultationId } = this.props;
        this.setState({
            loading: true,
        });
        return updateAlternateNumber(consultationId, phone)
            .then((response) => {
                console.log(response, 'Response from API');
                if (response.message === 'success') {
                    toast.success('Successfully updated alternate number');
                    this.props.onSubmit();
                } else {
                    throw new Error(response.errorMessage || 'Error while updating alternate number');
                }
            })
            .catch((err) => {
                console.error(err);
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const phoneLink = Link.state(this, 'phone');
        return (
            <div className='container'>
                <div className="row">
                    <div className='col'>
                        Phone Number
                    </div>
                    <div className='col'>
                        <Input type="number" className="form-control" valueLink={phoneLink} maxLength={10}/>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col">
                        {(this.state.loading) ? (
                            <LoadingComponent />
                        ) : (<div className="btn btn-primary" onClick={this.submit}>Update</div>)}
                    </div>
                    <div className="col">
                        <div className="btn btn-danger" onClick={this.props.onClose}>Cancel</div>
                    </div>
                </div>
            </div>
        );
    }
}
