import React, { Component } from 'react';
import ResultModal from '../common/modal/ResultModal';
import LoadingComponentAbsolute from '../common/LoadingComponentAbsolute';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import {
  addProductPool as addProductPoolAPI,
  editProductPool,
  getAllProducts,
  fetchPoolCategories,
} from '../../services/api/redemptions';

import { toast } from 'react-toastify';

class AddProductPool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showModal: false,
      showProductField: false,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      productOptions: [],
      productSelected: '',
      corporateCost: props.dataEditProductPool.corporate_cost || 0,
      nonServiceableMessage:
        props.dataEditProductPool.non_serviceable_message || '',
      amount: props.dataEditProductPool.amount || 0,
      priority: props.dataEditProductPool.priority || '',
      maxBuy: props.dataEditProductPool.max_buy || 0,
      maxUsersBuy: props.dataEditProductPool.max_users_buys || 0,
      layout: props.dataEditProductPool.layout || '',
      layoutOptions: [
        { value: 'grid', label: 'Grid' },
        { value: 'fullwidth', label: 'Full Width' },
      ],
      invites: props.dataEditProductPool.invites || '',
      web: props.dataEditProductPool.web || '',
      webOptions: [
        { value: 1, label: 'Yes' },
        { value: 0, label: 'No' },
      ],
      categories: [],
      categoryId: props.dataEditProductPool.categoryId,
    };
  }

  getAllCategories = () => {
    const { dataEditProductPool } = this.props;
    return fetchPoolCategories(
      dataEditProductPool.pool_id || this.props.poolId
    ).then((response) => {
      if (response.message === 'success') {
        response.data.forEach((d) => {
          d.value = d.categoryId;
        });
        this.setState({
          categories: response.data,
        });
      }
    });
  };

  componentDidUpdate = (prevProps) => {
    console.log(prevProps, this.props, 'AddProductPool - [componentDidUpdate]');
    if (
      prevProps.poolId !== this.props.poolId ||
      prevProps.editProductPool !== this.props.editProductPool ||
      prevProps.dataEditProductPool.id !== this.props.dataEditProductPool.id
    ) {
      this.getAllProduct();
      this.getAllCategories();
      if (this.props.editProductPool) {
        this.setState({
          corporateCost: this.props.dataEditProductPool.corporate_cost,
          nonServiceableMessage:
            this.props.dataEditProductPool.non_serviceable_message || '',
          amount: this.props.dataEditProductPool.amount,
          priority: this.props.dataEditProductPool.priority,
          maxBuy: this.props.dataEditProductPool.max_buy,
          maxUsersBuy: this.props.dataEditProductPool.max_users_buys,
          layout: this.props.dataEditProductPool.layout || '',
          invites: this.props.dataEditProductPool.invites || '',
          web: this.props.dataEditProductPool.web,
          categoryId: this.props.dataEditProductPool.categoryId || null,
        });
      } else {
        this.setState({
          corporateCost: '',
          nonServiceableMessage: '',
          amount: '',
          priority: '',
          maxBuy: '',
          maxUsersBuy: '',
          layout: '',
          invites: '',
          web: '',
          categoryId: null,
        });
      }
    }
  };

  componentDidMount = () => {
    this.getAllProduct();
    this.getAllCategories();
    if (this.props.editProductPool) {
      this.setState({
        corporateCost: this.props.dataEditProductPool.corporate_cost,
        nonServiceableMessage:
          this.props.dataEditProductPool.non_serviceable_message || '',
        amount: this.props.dataEditProductPool.amount,
        priority: this.props.dataEditProductPool.priority,
        maxBuy: this.props.dataEditProductPool.max_buy,
        maxUsersBuy: this.props.dataEditProductPool.max_users_buys,
        layout: this.props.dataEditProductPool.layout || '',
        invites: this.props.dataEditProductPool.invites || '',
        web: this.props.dataEditProductPool.web,
        categoryId: this.props.dataEditProductPool.categoryId || null,
      });
    } else {
      this.setState({
        corporateCost: '',
        nonServiceableMessage: '',
        amount: '',
        priority: '',
        maxBuy: '',
        maxUsersBuy: '',
        layout: '',
        invites: '',
        web: '',
        categoryId: null,
      });
    }
  };
  getAllProduct = () => {
    const params = {
      poolId: this.props.dataEditProductPool.pool_id || this.props.poolId,
    };
    return getAllProducts(params)
      .then((resp) => {
        // console.log(resp);
        this.setState({ productOptions: resp.data });
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };
  // };
  // Add Product
  addProductPool = (data) => {
    if (data.corporateCost !== '' && data.amount !== '') {
      if (this.props.editProductPool) {
        return editProductPool(data)
          .then((responseBody) => {
            toast.success('Successfully added the product');
            this.props.togglePage();
          })
          .catch((err) => {
            console.log(err);
            let errorMessage = err.message;
            toast.error(errorMessage);
          });
      } else {
        return addProductPoolAPI(data)
          .then((responseBody) => {
            toast.success('Successfully edited the product');
            this.props.togglePage();
          })
          .catch((err) => {
            console.log(err);
            let errorMessage = err.message;
            toast.error(errorMessage);
          });
      }
    } else {
      toast.error('Please enter complete details');
    }
  };
  //  Validations
  validationCheck = () => {
    console.log(
      'st',
      this.state.productSelected.value,
      this.state.corporateCost,
      this.props.dataEditProductPool.id
    );
    const data = {
      poolId: this.props.poolId || this.props.dataEditProductPool.pool_id,
      productId:
        this.state.productSelected.value || this.props.dataEditProductPool.id,
      corporateCost: this.state.corporateCost,
      nonServiceableMessage: this.state.nonServiceableMessage,
      amount: this.state.amount,
      priority: this.state.priority,
      maxBuy: this.state.maxBuy,
      maxUsersBuy: this.state.maxUsersBuy,
      layout: this.state.layout,
      invites: this.state.invites,
      web: this.state.web,
      categoryId: this.state.categoryId,
    };
    console.log('dat', data);
    if (data.nonServiceableMessage.length > 50) {
      toast.error(
        'Size of not serviceable message is more than the limit set.'
      );
    } else {
      console.log('el');
      this.addProductPool(data);
    }
  };
  // Handle Change
  handleChangeproduct = (productSelected) => {
    this.setState({ productSelected, showProductField: true });
  };
  handleChangeCorporateCost = (e) => {
    this.setState({ corporateCost: e.target.value });
  };
  handleChangeAmount = (e) => {
    this.setState({ amount: e.target.value });
  };
  handleChangeNonServiceableMessage = (e) => {
    this.setState({ nonServiceableMessage: e.target.value });
  };
  handleChangePriority = (e) => {
    this.setState({ priority: e.target.value });
  };
  handleChangeMaxBuy = (e) => {
    this.setState({ maxBuy: e.target.value });
  };
  handleChangeMaxUsersBuy = (e) => {
    this.setState({ maxUsersBuy: e.target.value });
  };
  handleChangeLayout = (e) => {
    // console.log(e.value);
    this.setState({ layout: e.value });
  };
  handleChangeInvites = (e) => {
    this.setState({ invites: e.target.value });
  };
  handleChangeWeb = (e) => {
    this.setState({ web: e.value });
  };
  handleChangeTat = (e) => {
    this.setState({ tat: e.target.value });
  };

  handleChangeCategoryId = (e) => {
    this.setState({
      categoryId: e.categoryId,
    });
  };
  render() {
    console.log(this.props.poolId);

    let layoutSel = [];
    let webSel = [];
    let categorySel = [];
    this.state.layoutOptions.forEach((option) => {
      if (option.value === this.state.layout) {
        layoutSel = option;
      }
    });
    this.state.categories.forEach((c) => {
      if (c.categoryId === this.state.categoryId) {
        categorySel = c;
      }
    });
    this.state.webOptions.forEach((option) => {
      if (option.value === this.state.web) {
        webSel = option;
      }
    });
    return (
      <div className="container-fluid">
        <Card>
          <CardHeader>
            {!this.props.editProductPool && (
              <strong>Add Product (Pool Id - {this.props.poolId})</strong>
            )}
            {this.props.editProductPool && (
              <strong>
                Edit Product (Pool Id - {this.props.dataEditProductPool.pool_id}
                )
              </strong>
            )}
          </CardHeader>
          <CardBody>
            <Form
              action=""
              method="post"
              encType="multipart/form-data"
              className="form-horizontal"
            >
              <FormGroup row>
                <Col md="3">
                  <Label htmlFor="product_name">Product Name</Label>
                </Col>
                <Col xs="12" md="9">
                  {!this.props.editProductPool && (
                    <Select
                      value={this.state.productSelected}
                      onChange={this.handleChangeproduct}
                      options={this.state.productOptions}
                    />
                  )}
                  {this.props.editProductPool && (
                    <Label className="text-primary">
                      {this.props.dataEditProductPool.title} (ProductId -{' '}
                      {this.props.dataEditProductPool.id})
                    </Label>
                  )}
                </Col>
              </FormGroup>
              {(this.state.showProductField || this.props.editProductPool) && (
                <div>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="corporate">Corporate Cost</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        type="number"
                        placeholder="Cost which is to be billed to the corporate"
                        value={this.state.corporateCost}
                        onChange={this.handleChangeCorporateCost}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="">Fit coin</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        type="number"
                        placeholder="Fit Coin Value to shown on App"
                        value={this.state.amount}
                        onChange={this.handleChangeAmount}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="">Not serviceable message</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        type="text"
                        rows="9"
                        value={this.state.nonServiceableMessage}
                        placeholder="Enter value only when the product is not available for this pool"
                        onChange={this.handleChangeNonServiceableMessage}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="">Priority</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        type="text"
                        rows="9"
                        value={this.state.priority}
                        placeholder="Priority of the product wrt to category it belongs to"
                        onChange={this.handleChangePriority}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="">Total Limit</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        type="number"
                        rows="9"
                        value={this.state.maxBuy}
                        placeholder="0, if max buys is not applicable"
                        onChange={this.handleChangeMaxBuy}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="">Per User Limit</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Input
                        type="number"
                        name=""
                        id=""
                        rows="9"
                        value={this.state.maxUsersBuy}
                        placeholder="0, if max users buys is not applicable"
                        onChange={this.handleChangeMaxUsersBuy}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="">Layout</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Select
                        value={layoutSel}
                        options={this.state.layoutOptions}
                        onChange={this.handleChangeLayout}
                      />
                    </Col>
                  </FormGroup>
                  {/* <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="">Web</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Select
                        value={webSel}
                        options={this.state.webOptions}
                        onChange={this.handleChangeWeb}
                      />
                    </Col>
                  </FormGroup> */}
                  <FormGroup row>
                    <Col md="3">
                      <Label htmlFor="">Category</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Select
                        value={categorySel}
                        options={this.state.categories}
                        onChange={this.handleChangeCategoryId}
                      />
                    </Col>
                  </FormGroup>
                </div>
              )}
            </Form>
          </CardBody>
          <CardFooter>
            <Button onClick={this.validationCheck}>Confirm</Button>
            <Button style={{ marginLeft: 15 }} onClick={this.props.togglePage}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        {this.state.loading ? <LoadingComponentAbsolute /> : null}
      </div>
    );
  }
}

export default AddProductPool;
