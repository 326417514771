import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import {
  getCartsFromDigitisationId,
  getDigitisationId,
  modifyCartData,
  placeFailedOrderCart,
  placeOrderByCartId
} from './UploadRxComponents/Services';

const buttonStyle = {
  padding: '8px 12px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  background: '#f0f0f0',
  color: '#333',
  fontSize: '16px',
  cursor: 'pointer',
  margin: '0 5px',
};

const quantityStyle = {
  padding: '8px 12px',
  border: '1px solid #ccc',
  borderRadius: '4px',
  background: '#fff',
  color: '#333',
  fontSize: '16px',
  margin: '0 5px',
};

const RetryOrder = ({ onClose, orderId = null, ...props }) => {
  const [digitisationId, setDigitisationId] = useState( props.digitisationId || null);
  const [cartData, setCartData] = useState([]);
  const [selectedCartIndex, setSelectedCartIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCart, setSelectedCart] = useState(null);
  const [partnerOptions, setPartnerOptions] = useState(['All']);
  const [selectedPartner, setSelecetdPartner] = useState('All');
  const [isCartUpdated, setIsCartUpdated] = useState(false);
  const [updatedCarts, setUpdatedCarts] = useState([]);
  const [retryOrderResponse, setRetryOrderResponse] = useState(null);

  useEffect(() => {
    async function getDigitisationIdOfOrderID() {
      const response = await getDigitisationId(orderId);
      if (response && response.status && response.status === 'success') {
        setDigitisationId(response.digitisationId);
        const cartResponse = await getCartsFromDigitisationId(
          response.digitisationId
        );
        if (cartResponse && cartResponse.data) {
          setCartData(cartResponse.data);
          setPartnerOptions((prevData) => [...new Set([
            ...prevData,
            ...cartResponse.data.map((cart) => {
              return cart.partnerName;
            }),
          ])]);
        } else {
          onClose();
        }
      }
    }
    if (orderId) {
      getDigitisationIdOfOrderID();
    }
  }, [orderId]);

  const handleCartSelection = (index, cartId) => {
    setSelectedCartIndex(index);
    setSelectedCart(cartId);
  };

  useEffect(()=>{
    async function setCart() {
        const cartResponse = await getCartsFromDigitisationId(digitisationId);
        if (cartResponse && cartResponse.data && cartResponse.data.length ) {
          setCartData(cartResponse.data);
          setPartnerOptions((prevData) => [...new Set([
            ...prevData,
            ...cartResponse.data.map((cart) => {
              return cart.partnerName;
            }),
          ])]);
        } else {
          alert(`No associated cart found with digitisation id - ${digitisationId}`)
          onClose();
        }
    }
    if (!orderId && digitisationId) {
      setCart();
    }
  },[digitisationId])

  const modifyAndGetUpdatedCarts = async () => {
    try {
      setIsLoading(true);
      const asyncCalls = updatedCarts.map((cartId) =>
        modifyCartData(
          cartId,
          cartData.find((cart) => cart.cartId === cartId).medicines || null
        )
      );
      await Promise.all(asyncCalls);
      const cartResponse = await getCartsFromDigitisationId(digitisationId);
      if (cartResponse && cartResponse.data) {
        setCartData(cartResponse.data);
        setPartnerOptions([
          'All',
          ...cartResponse.data.map((cart) => {
            return cart.partnerName;
          }),
        ]);
      }
      setIsCartUpdated(false);
      setIsLoading(false);
    } catch (error) {
      console.log('Error during async calls:', error);
    }
  };

  const decreaseQuantity = async (cartIndex, medIndex) => {
    const updatedCarts = [...cartData];
    if (updatedCarts[cartIndex].medicines[medIndex].quantity > 0) {
      setIsCartUpdated(true);
      setUpdatedCarts((prevState) => [
        ...prevState,
        updatedCarts[cartIndex].cartId,
      ]);
      updatedCarts[cartIndex].medicines[medIndex].quantity -= 1;
      setCartData(updatedCarts);
    }
  };

  const increaseQuantity = async (cartIndex, medIndex, maxQuant) => {
    const updatedCarts = [...cartData];
    if (updatedCarts[cartIndex].medicines[medIndex].quantity < maxQuant) {
      setIsCartUpdated(true);
      setUpdatedCarts((prevState) => [
        ...prevState,
        updatedCarts[cartIndex].cartId,
      ]);
      updatedCarts[cartIndex].medicines[medIndex].quantity += 1;
      setCartData(updatedCarts);
    }
  };

  const placeOrder = async (cartId) => {
    let res = orderId ?  await placeFailedOrderCart(orderId, cartId) : await placeOrderByCartId(cartId);
    setRetryOrderResponse(res);
  };

  return (
    <Modal
      isOpen={true}
      style={{
        maxWidth: '1200px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: 'yellow',
      }}
    >
      <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={onClose}>
        <h4
          className="text-center"
          style={{ color: '#fff', fontWeight: 'bold' }}
        >
          Retry Failed Order
        </h4>
      </ModalHeader>
      <ModalBody
        style={{
          paddingTop: '1%',
          paddingBottom: '5%',
          paddingInline: '5%',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            margin: '0px 16px',
          }}
        >
          {cartData && cartData.length
            ? partnerOptions.map((partner, index) => (
                <button
                  key={index}
                  className={`btn btn-${
                    selectedPartner === partner ? 'primary' : 'secondary'
                  }`}
                  onClick={() => setSelecetdPartner(partner)}
                >
                  {partner}
                </button>
              ))
            : null}
        </div>
        {cartData && cartData.length ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
              margin: 'auto',
              overflowX: 'auto',
            }}
          >
            <div
              style={{
                display: 'flex',
                overflowX: 'auto',
                paddingBottom: '8px',
              }}
            >
              {cartData
                .filter(
                  (cart) =>
                    selectedPartner === 'All' ||
                    cart.partnerName === selectedPartner
                )
                .map((cart, cartIndex) => (
                  <div
                    key={cart.cartId}
                    onClick={() => handleCartSelection(cartIndex, cart.cartId)}
                    className="card"
                    style={{
                      margin: '8px 16px',
                      cursor: 'pointer',
                      minWidth: '300px',
                      border:
                        selectedCartIndex === cartIndex
                          ? '1px solid rgb(135, 82, 204) !important'
                          : '1px solid rgba(0, 0, 0, .125) !important',
                    }}
                  >
                    {isLoading && updatedCarts.includes(cart.cartId) ? (
                      <>
                        <div
                          className="card-header"
                          style={{
                            background: '#f0f0f0',
                            borderRadius: '4px 4px 0 0',
                            height: '20px',
                            marginBottom: '16px',
                          }}
                        >
                          <div
                            style={{
                              width: '80%',
                              height: '100%',
                              margin: 'auto',
                            }}
                          ></div>
                        </div>
                        <div
                          className="card-body"
                          style={{
                            background: '#f0f0f0',
                            borderRadius: '0',
                            height: '180px',
                            marginBottom: '16px',
                          }}
                        >
                          <div
                            style={{
                              width: '90%',
                              height: '40px',
                              marginBottom: '16px',
                            }}
                          ></div>
                          <div
                            style={{
                              width: '90%',
                              height: '40px',
                              marginBottom: '16px',
                            }}
                          ></div>
                          <div
                            style={{
                              width: '90%',
                              height: '40px',
                              marginBottom: '16px',
                            }}
                          ></div>
                        </div>
                        <div
                          className="card-footer"
                          style={{
                            background: '#f0f0f0',
                            borderRadius: '0 0 4px 4px',
                            height: '50px',
                            marginBottom: '16px',
                          }}
                        >
                          <div
                            style={{
                              width: '80%',
                              height: '100%',
                              margin: 'auto',
                            }}
                          ></div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="card-header">
                          <div className="row no-gutters">
                            <div className="col">
                              <label
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    fontSize: '16px',
                                    lineHeight: '130%',
                                    letterSpacing: '0.25px',
                                    textAlign: 'left',
                                    textTransform: 'capitalize',
                                    margin: '0 16px',
                                  }}
                                >
                                  {cart.partnerName}
                                </div>
                                <input
                                  type="radio"
                                  name="partner"
                                  checked={selectedCartIndex === cartIndex}
                                  onChange={() =>
                                    handleCartSelection(cartIndex, cart.cartId)
                                  }
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <ul class="list-group list-group-flush">
                            {cart.medicines.map((med, medIndex) => (
                              <li
                                key={med.cartItemId}
                                class="list-group-item"
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    overflow: 'hidden',
                                    whiteSpace: 'nowrap',
                                    textOverflow: 'ellipsis',
                                    maxWidth: '200px',
                                    minWidth: '200px',
                                  }}
                                >
                                  {med.brandName}
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <button
                                    style={buttonStyle}
                                    onClick={() =>
                                      decreaseQuantity(cartIndex, medIndex)
                                    }
                                  >
                                    -
                                  </button>
                                  <span style={quantityStyle}>
                                    {med.quantity}
                                  </span>
                                  <button
                                    style={buttonStyle}
                                    onClick={() =>
                                      increaseQuantity(
                                        cartIndex,
                                        medIndex,
                                        med.maxQuantity
                                      )
                                    }
                                  >
                                    +
                                  </button>
                                </div>
                                <div>₹{med.mrp}</div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div class="card-footer">
                          <ul class="list-group list-group-flush">
                            <li
                              class="list-group-item"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>Subtotal Amount</div>
                              <div>₹{cart.orderSubTotal}</div>
                            </li>
                            <li
                              class="list-group-item"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>Visit Discount</div>
                              <div>- ₹{cart.orderDiscount}</div>
                            </li>
                            <li
                              class="list-group-item"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>Total Amount</div>
                              <div>₹{cart.totalAmount}</div>
                            </li>
                            <li
                              class="list-group-item"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>Order Type</div>
                              <div>{cart.orderType || 'code'}</div>
                            </li>
                            <li
                              class="list-group-item"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>User Payable</div>
                              <div>₹{cart.userPay}</div>
                            </li>
                            <li
                              class="list-group-item"
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div>Wallet Discount</div>
                              <div>₹{cart.walletDiscount|| 0}</div>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </div>
          </div>
        ) : null}
        {cartData && cartData.length ? (
          retryOrderResponse ? (
            <div style={{ fontFamily: 'Arial, sans-serif', margin: '20px' }}>
              {retryOrderResponse.message && <p>Message: {retryOrderResponse.message.toString()}</p>} 
              {retryOrderResponse.failedOrderDetail && <p>Failed Order Detail:</p>}
              {retryOrderResponse.failedOrderDetail && 
                <ul>
                  <li>Order ID: {retryOrderResponse.failedOrderDetail.orderId.toString()}</li>
                  <li>Replaced: {retryOrderResponse.failedOrderDetail.replaced.toString()}</li>
                  <li>Cancelled: {retryOrderResponse.failedOrderDetail.cancelled ? retryOrderResponse.failedOrderDetail.cancelled.toString() : 'NULL'}</li>
                  <li>Refund Required: { retryOrderResponse.failedOrderDetail.refundRequired ? retryOrderResponse.failedOrderDetail.refundRequired.toString() : 'NULL'}</li>
                  <li>Refunded: { retryOrderResponse.failedOrderDetail.refunded ? retryOrderResponse.failedOrderDetail.refunded.toString() : 'NULL'}</li>
                </ul>
              }
              <p>New Order Detail:</p>
              {retryOrderResponse.newOrderDetail &&
                <ul>
                  <li>Status: {retryOrderResponse.newOrderDetail.status.toString()}</li>
                  <li>Order ID: {retryOrderResponse.newOrderDetail.orderId.toString()}</li>
                  <li>Cancelled: {retryOrderResponse.newOrderDetail.cancelled ? retryOrderResponse.newOrderDetail.cancelled.toString() : 'NULL'}</li>
                  <li>Refund Required: { retryOrderResponse.newOrderDetail.refundRequired ? retryOrderResponse.newOrderDetail.refundRequired.toString() : 'NULL'}</li>
                  <li>Refunded: { retryOrderResponse.newOrderDetail.refunded ? retryOrderResponse.newOrderDetail.refunded.toString() : 'NULL'}</li>
                </ul>
              }
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <button
                className="btn btn-primary"
                disabled={isLoading || selectedCartIndex === null}
                onClick={() => isCartUpdated ? modifyAndGetUpdatedCarts() : placeOrder(selectedCart)}
              >
                {isCartUpdated ? 'Refresh Billing' : 'Place Order'}
              </button>
            </div>
          )
        ) : null}
      </ModalBody>
    </Modal>
  );
};

export default RetryOrder;
