import { createStore, compose, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import rootReducer from '../reducers/index';
import filterReducer from '../reducers/filterHeader';

const reducer = persistReducer(
  {
    key: 'visit-ops-dashboard-v2', // key is required
    storage, // storage is now required
    // whitelist: ['app', 'user'],
    blacklist: ['filterHeader'],
  },
  combineReducers({
    ...rootReducer,
    filterHeader: persistReducer(
      { key: 'filterHeaderSessionStorage', storage: storageSession },
      filterReducer
    ),
  })
);

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const store = createStore(reducer, initialState, composeEnhancer());

  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(require('../reducers/index').default);
    });
  }

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
