export function setUserInfo(userInfo) {
  return {
    type: 'SET_USER_INFO',
    payload: {
      userInfo,
    },
  };
}

export function setLabPartnerInfo(partnerInfo) {
  return {
    type: 'SET_LAB_PARTNER_INFO',
    payload: partnerInfo,
  };
}

export function setAuthToken(authToken) {
  return {
    type: 'SET_AUTH_TOKEN',
    payload: authToken,
  };
}

export function unsetUserInfo() {
  return {
    type: 'UNSET_USER_INFO',
  };
}

export function unsetAuthToken() {
  return {
    type: 'UNSET_AUTH_TOKEN',
    payload: null,
  };
}
export function toggleSidebar(data) {
  return {
    type: 'TOGGLE_SIDEBAR',
    payload: data,
  };
}

export function setOfflineConsultRequestId(requestId) {
  return {
    type: 'SET_REQUEST_ID',
    payload: {
      requestId,
    },
  };
}

export function showRequestModal(requestId, requestDetails) {
  return {
    type: 'SHOW_REQUEST_MODAL',
    payload: {
      requestId,
      showRequestDetails: true,
      requestData : requestDetails
    },
  };
}

export function updateRequestID(requestId) {
  return {
    type: 'UPDATE_REQUEST_ID',
    payload: {
      requestId,
    },
  };
}

export function requestDetails(requestId, requestData, priceApprovalStatus) {
  return {
    type: 'REQUEST_DETAILS',
    payload: {
      requestId,
      requestData,
      priceApprovalStatus,
    },
  };
}

export function showDoctorSlotsEditModal(doctorId) {
  return {
    type: 'SHOW_DOCTOR_SLOTS_EDIT_MODAL',
    payload: {
      doctorId,
      showEditDoctorSlots: true,
    },
  };
}

export function showOperationsConfirmModal(
  requestId,
  confirmModalOpen = false
) {
  return {
    type: 'SHOW_CONFIRM_SLOTS_MODAL',
    payload: {
      requestId,
      showConfirmModal: confirmModalOpen,
    },
  };
}

export function showIntimationRequestModal(requestId) {
  return {
    type: 'SHOW_INTIMATION_REQUEST_MODAL',
    payload: {
      requestId,
      showRequestDetails: true,
    },
  };
}

export function showInternalComments(requestId) {
  return {
    type: 'SHOW_INTERNAL_COMMENTS',
    payload: {
      requestId,
      showInternalComments: true,
    },
  };
}

export function showSupportComments(requestId) {
  return {
    type: 'SHOW_SUPPORT_COMMENTS',
    payload: {
      requestId,
      showInternalComments: true,
    },
  };
}

export function hideInternalComments(requestId) {
  return {
    type: 'HIDE_INTERNAL_COMMENTS',
  };
}

export function hideSupportComments(requestId) {
  return {
    type: 'HIDE_SUPPORT_COMMENTS',
  };
}

export function hideRequestModal() {
  return {
    type: 'HIDE_REQUEST_MODAL',
  };
}
export function hideDoctorSlotsEditModal() {
  return {
    type: 'HIDE_DOCTOR_SLOTS_EDIT_MODAL',
  };
}

export function hideIntimationRequestModal() {
  return {
    type: 'HIDE_INTIMATION_REQUEST_MODAL',
  };
}

export function unsetOfflineConsultRequestId() {
  return {
    type: 'UNSET_REQUEST_ID',
  };
}

export function unsetIntimationRequestId() {
  return {
    type: 'UNSET_INTIMATION_REQUEST_ID',
  };
}

export function updatePaymentDetails(paymentDetails) {
  return {
    type: 'UPDATE_PAYMENT_DETAILS',
    payload: {
      paymentDetails,
    },
  };
}
// Doctor on Call
export function setDocConsultConsultId(consultId) {
  // console.log(consultId, "setDocConsultId");
  return {
    type: 'SET_CONSULT_ID',
    payload: {
      consultId,
    },
  };
}

export function unsetDocConsultConsultId() {
  return {
    type: 'UNSET_CONSULT_ID',
  };
}

export function setUserDetails(userDetail) {
  return {
    type: 'SET_USER_DETAILS',
    payload: {
      userDetail,
    },
  };
}

export function showBody(showBody) {
  return {
    type: 'SHOW_BODY',
    payload: {
      showBody,
    },
  };
}

export function setCartInfo(cartItemsResult) {
  return {
    type: 'SET_CART_INFO',
    payload: {
      cartItemsResult,
    },
  };
}

export function setUserInfoForRxCreate(userInfo) {
  return {
    type: 'SET_CREATE_ORDER_DETAILS',
    payload: {
      userInfo,
    },
  };
}

export function setSelectedPartnerForNewTest(selectedPartnerId) {
  return {
    type: 'SET_SELECTED_PARTNER_NEW_TEST',
    payload: {
      selectedPartnerId,
    },
  };
}

export function setCreatingLabRxPrescriptionId(newRequestPrescriptionId) {
  return {
    type: 'NEW_RX_SET_PRESCRIPTION_ID',
    payload: {
      newRequestPrescriptionId,
    },
  };
}

export function setSelectedPatientRxDetailsForTranscription(patient) {
  return {
    type: 'NEW_RX_SET_PATIENT',
    payload: {
      patient,
    },
  };
}

export function setSelectedAddressRxDetailsForTranscription(address) {
  return {
    type: 'NEW_RX_SET_ADDRESS',
    payload: {
      address,
    },
  };
}

export function setSelectedCollectionDateRxDetailsForTranscription(
  collectionDetails
) {
  return {
    type: 'NEW_RX_SET_COLLECTION_DETAILS',
    payload: {
      collectionDetails,
    },
  };
}

export function setLabListingFilter(filter, row) {
  return {
    type: 'SET_LAB_LISTING_FILTER',
    payload: {
      filter,
      row,
    },
  };
}

export function setVerticalsInRedux(verticals, pathname) {
  return {
    type: `SET_VERTICALS_FILTER-${pathname}`,
    payload: verticals,
  };
}

export function setDoctorsInRedux(doctors, pathname) {
  return {
    type: `SET_DOCTORS_FILTER-${pathname}`,
    payload: doctors,
  };
}

export function setSponsorsInRedux(sponsors, pathname) {
  return {
    type: `SET_SPONSORS_FILTER-${pathname}`,
    payload: sponsors,
  };
}

export function setGroupOfInRedux(groupOf, pathname) {
  return {
    type: `SET_GROUP_OF_FILTER-${pathname}`,
    payload: groupOf,
  };
}

export function setStartDateInRedux(startDate, pathname) {
  return {
    type: `SET_START_DATE_FILTER-${pathname}`,
    payload: startDate,
  };
}

export function setEndDateInRedux(endDate, pathname) {
  return {
    type: `SET_END_DATE_FILTER-${pathname}`,
    payload: endDate,
  };
}

export function setInternalInRedux(internal, pathname) {
  return {
    type: `SET_INTERNAL_FILTER-${pathname}`,
    payload: internal,
  };
}

export function setPageIndexInRedux(payload, pathname) {
  return {
    type: `SET_PAGE_INDEX_FILTER-${pathname}`,
    payload: payload,
  };
}

export function setPageSizeInRedux(payload, pathname) {
  return {
    type: `SET_PAGE_SIZE_FILTER-${pathname}`,
    payload: payload,
  };
}

export function showVaccinationRequestModal(requestId) {
  return {
    type: 'SHOW_VACCINATION_REQUEST_MODAL',
    payload: {
      requestId,
      showRequestDetails: true,
    },
  };
}

export function hideVaccinationRequestModal() {
  return {
    type: 'HIDE_VACCINATION_REQUEST_MODAL',
    payload: {
      requestId: null,
      showRequestDetails: false,
    },
  };
}

export function hideDentalRequestModal() {
  return {
    type: 'HIDE_DENTAL_REQUEST_MODAL',
    payload: {
      requestId: null,
      showRequestDetails: false,
    },
  };
}

export function showDentalRequestModal(requestId) {
  return {
    type: 'SHOW_DENTAL_REQUEST_MODAL',
    payload: {
      requestId,
      showRequestDetails: true,
    },
  };
}
