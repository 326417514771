import React, { Component } from 'react';

export default class InHouseFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    onChange = (event) => {
        const value = event.target.value === "" ? null : event.target.value;
        this.setState({ value });
        this.props.onChangeValue(value);
    }


    render() {
        const { value } = this.state;
        return (
            <select
                onChange={this.onChange}
                style={{
                    width: "100%",
                    height: "40px",
                    fontSize: "12px"
                }}
                value={value}
            >
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="noAnswer">No Answer</option>
                <option value="onGoing">On Going</option>
                <option value="completed">Completed</option>
                <option value="required">Offline required</option>
                <option value="not-required">Offline Not Required</option>
                <option value="not-sure">Offline Not Sure</option>
            </select>
        )
    }
}
