import React from 'react';

import { toast } from 'react-toastify';

import AddCenterTest from './AddCenterTest';
import LabsCenterDetailsHeader from './LabsCenterDetailsHeader';
import ReactTable from "react-table";

import { fetchTestsForCenter, deleteTestForCenter } from '../../services/api/lab-partners';

import { FaTrashAlt } from 'react-icons/fa';

import ModalConfirm from "../common/ModalConfirm";

import AddAllActiveTest from './AddAllActiveTest';

class LabsCentersTestDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            tests: [],
            partnerId: null,
            centerId: null,
            modal: {
                isOpen: false,
                testId: null,
                centerId: null,
                partnerId: null,
                onConfirm: null,
            },
        }
    }

    componentDidMount() {
        const partnerId = parseInt(this.props.match.params.partnerId);
        const centerId = parseInt(this.props.match.params.centerId);
        console.log(partnerId, centerId, "Before Mounting Data");
        this.setState({
            partnerId,
            centerId,
        }, this.getTestsForCenter);
    }

    onComplete = () => {
        return this.getTestsForCenter();
            // .then(this.props.refreshParent)
    };

    getTestsForCenter = () => {
        const { partnerId, centerId } = this.state;
        if (isNaN(partnerId) || isNaN(centerId)) {
            toast.error('Error while fetching the tests for this center');
            return;
        }
        return fetchTestsForCenter(partnerId, centerId)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    this.setState({
                        tests: body.tests,
                    });
                    return;
                }
                throw new Error('Error while fetching tests for partner');
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, centerId, partnerId, 'Error while fetching tests for partner');
            });
    };

    getColumns = () => {
        return [{
            accessor: 'testName',
            Header: 'Test Name',
        }, {
            accessor: 'testCode',
            Header: 'Code',
            className: 'text-center',
        }, {
            accessor: 'mrp',
            Header: 'MRP',
            className: 'text-center',
        }, {
            accessor: 'cost',
            Header: 'Cost',
            className: 'text-center',
        }, {
            Header: 'Actions',
            className: 'text-center',
            Cell: row => (<FaTrashAlt onClick={() => (this.handleDelete(row.original.testId))} className="clickable" />),
        }];
    };

    handleDelete = (testId) => {
        const { centerId, partnerId } = this.state;
        if (isNaN(testId) || isNaN(centerId) || isNaN(partnerId)) {
            toast.error('Error while deleting the test');
            return;
        }
        this.setState({
            modal: {
                isOpen: true,
                message: 'Are you want to delete the test?',
                header: 'Delete test',
                testId,
                centerId,
                partnerId,
                onConfirm: () => {
                    const { testId } = this.state.modal;
                    const { partnerId, centerId } = this.state;
                    if (isNaN(testId) || isNaN(centerId) || isNaN(partnerId)) {
                        toast.error('Error while deleting the test');
                        return Promise.reject(new Error('Invalid partnerId, centerId or testId'));
                    }
                    return deleteTestForCenter(partnerId, centerId, testId)
                        .then((response) => {
                            const body = response.body;
                            if (body.message === 'success') {
                                this.handleCloseModal();
                                toast.success('Successfully deleted the test');
                                return;
                            }
                            throw new Error('Error while deleting the test');
                        })
                        .catch((err) => {
                            console.error(err, testId, partnerId, centerId, 'Error while deleting the test');
                            toast.error(err.message);
                        });
                },
            },
        });
    };

    handleCloseModal = () => {
        this.setState({
            modal: {
                isOpen: false,
                message: '',
                header: '',
                testId: null,
                onConfirm: null,
            },
        }, this.onComplete);
    };

    render() {
        const { partnerId, centerId } = this.state;
        const { loading, tests, modal } = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <LabsCenterDetailsHeader partnerId={partnerId} centerId={centerId} />
                    </div>
                </div>
                <div className="row mt-2 mb-2">
                    <div className="col-6">
                        <AddCenterTest partnerId={partnerId} centerId={centerId} onComplete={this.onComplete} />
                    </div>
                    <div className="col-3">
                        <AddAllActiveTest partnerId={partnerId} centerId={centerId} onComplete={this.onComplete} />
                    </div>
                </div>
                <ReactTable
                    data={tests}
                    loading={loading}
                    // onFetchData={this.getTestsForCenter}
                    showPaginationTop={false}
                    columns={this.getColumns()}
                    filterable={false}
                    defaultPageSize={20}
                    className="-striped -highlight"
                    sortable={false}
                />
                <ModalConfirm message={modal.message} isOpen={modal.isOpen} header={modal.header} onConfirm={modal.onConfirm} onClose={this.handleCloseModal} />
            </div>
        );
    }
}

export default LabsCentersTestDetails;
