import React, { Component } from 'react';
import Modal from "react-modal";

import styled from 'styled-components';

import { getPayoutInfo, processPayout } from '../../../services/api/online-payment';

import PreviousPayments from './PreviousPayments';
import {toast} from "react-toastify";

import LoadingComponent from "../../common/LoadingComponent";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const ModalHeader = styled.div`
    font-size: x-large;
    padding: 8px;
`;

const ModalBody = styled.div`
    margin: 25px;
`;

export default class PayoutModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tds: 0,
            doctorName: '',
            payoutAmount: 0,
            halfPayout: 0,
            fullPayout: 0,
            zeroPayout: 0,
            // numberOfconsultations: 0,
            remarks: '',
            totalPaidTillNow: 0,
            tdsTillNow: 0,
            loading: false,
            payoutFlag:false,
        };
    }

    onOpen = () => {
        const { doctorId, monthYear } = this.props;
        this.setState({
            tds: 0,
        });
        return getPayoutInfo(doctorId, monthYear)
            .then((response) => {
                if (response.message !== 'success') {
                    throw new Error('Error in endpoint');
                }
                console.log(response, 'Response from payout-info endpoint');
                this.setState({
                    doctorName: response.data.doctorName,
                    payoutAmount: (response.data.amountToBePaid - response.data.tds),
                    payoutAmountWithTDS: (response.data.amountToBePaid),
                    halfPayout: response.data.payoutStatus.halfPayout,
                    fullPayout: response.data.payoutStatus.fullPayout,
                    zeroPayout: response.data.payoutStatus.zeroPayout,
                    tds: response.data.tds,
                    totalPaidTillNow: response.data.totalPaidTillNow,
                    tdsTillNow: response.data.tdsTillNow,
                    // numberOfconsultations: response.data[0].numberOfconsultations,
                });
            })
            .catch((err) => {
                console.error(err, 'Error while getting payout info');
                throw err;
            });
    };

    onClose = () => {
        this.setState({
            tds: 0,
            payoutAmount: 0,
            remarks: '',
        }, this.props.onClose);
    };

    processPayoutButton = () => {
        const { doctorId, monthYear } = this.props;
        const { tds, remarks, payoutAmount, loading,payoutFlag } = this.state;
        if (loading) {
            return;
        }
        this.setState({
            loading: true,
        });
        return processPayout(doctorId, monthYear, tds, remarks, payoutAmount,payoutFlag)
            .then((response) => {
                if (response.message !== 'success') {
                    throw new Error('Error in enpoint');
                }
                toast.success('Successfully Paid to the doctor');
                this.onClose();
            })
            .catch((err) => {
                console.error(err, 'Error while fetching payout info');
                toast.error('Failed to pay to the doctor');
                throw err;
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    changeTdsAmount = (e) => {
        const value = e.target.value;
        this.setState({
            tds: value,
        });
    };

    changePayoutAmount = (e) => {
        this.setState({
            payoutAmount: e.target.value,
        });
    };

    changeRemarks = (e) => {
        this.setState({
            remarks: e.target.value,
        });
    };

    render() {
        const { doctorName, payoutAmount, halfPayout, fullPayout, zeroPayout, tds, remarks, loading,payoutFlag } = this.state;
        const { doctorId } = this.props;
        return (
            <React.Fragment>
                <Modal
                    isOpen={this.props.isOpen}
                    onAfterOpen={this.onOpen}
                    onRequestClose={this.onClose}
                    style={customStyles}
                >
                    <ModalHeader>
                        Payout Modal
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-4 border-right">
                                <div className="row">
                                    <div>Doctor Name: {doctorName}</div>
                                </div>
                                <div className="row">
                                    <div>Number of Consultations: {halfPayout + fullPayout + zeroPayout}</div>
                                </div>
                                <div className="row">
                                    <div>Half Payout: {halfPayout}</div>
                                </div>
                                <div className="row">
                                    <div>Full Payout: {fullPayout}</div>
                                </div>
                                <div className="row">
                                    <div>Zero Payout: {zeroPayout}</div>
                                 </div>
                                 <div className='row pt-2'>
                                <h5>
                                    Do You want to Execute Payout?
                                </h5>
                                 </div>
                                <div className="row">
                <div className="col">
                  <input
                    type="radio"
                    name="payoutFlag"
                    onChange={(e) => {
                      this.setState({ payoutFlag: false });
                    }}
                    value={false}
                  ></input>
                  	
                    <label for="no">No </label>
                  &nbsp; 	&nbsp;	&nbsp;
                  <input
                      type="radio"
                      name="payoutFlag"
                      onChange={(e) => {
                        this.setState({ payoutFlag: true });
                      }}
                      value={true}
                    ></input>
                      <label for="yes">Yes</label>
                </div>
              </div>
                            </div>
                            
                            <div className="col-4 border-right">
                                <div className="row mt-2">
                                    <div className="col">
                                        Payout Amount:
                                    </div>
                                    <div className="col">
                                        <input className="form-control" onChange={this.changePayoutAmount} value={payoutAmount} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        TDS:<br /><small>(will not be deducted from above amount)</small>
                                    </div>
                                    <div className="col">
                                        <input className="form-control" onChange={this.changeTdsAmount} value={tds} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        Any Remarks:
                                    </div>
                                    <div className="col">
                                        <input className="form-control" onChange={this.changeRemarks} value={remarks} />
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <div className="col">
                                        {loading ? (
                                            <LoadingComponent />
                                        ) : (
                                            <div>
                                            <button className="btn btn-primary btn-block" 
                                            onClick={this.processPayoutButton}
                                            disabled={payoutFlag === false}>Payout
                                            </button>
                                            </div>
                                            
                                        )}
                                    </div>
                                    <div className="col">
                                        {loading ? (
                                            <LoadingComponent />
                                        ) : (
                                            <div className="btn btn-primary btn-block" onClick={this.processPayoutButton}>Send Email</div>
                                            
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                <PreviousPayments doctorId={doctorId} />
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}
