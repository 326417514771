import React, { Component } from 'react';
import Modal from 'react-modal';
import C_Modal from '../common/Modal';
import SuperAction from '../common/modal/SuperAction';
import { getDocConsultData } from '../../services/api/doc-on-call';
import {
  OuterComponent,
  PageTitle,
  customStyles,
  CloseModal,
  DocInfo,
  BodyContainer,
} from '../common/modal/StyledComponent';
import StyledTappable from '../common/StyledTappable';
import LoadingComponent from '../../components/common/LoadingComponent';
import AddAlterNumberForm from './AddAlterNumberForm';
import styled from 'styled-components';
import { FaEdit } from 'react-icons/all';

const ItemContainer = styled.div`
  border: solid #00000030 1px;
  border-radius: 3px;
  width: 100%;
  padding: 3px;
  opacity: 1;
`;

export default class ConsultationViewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultationData: null,
      alternateNumberModalOpen: false,
    };
  }

  afterOpenModal = () => {
    const { consultationId } = this.props;
    return getDocConsultData({
      consultationId,
      isScheduledConsult : this.props.consultationType === 'scheduled-consults',
      userId: this.props.userId
    })
      .then((response) => {
        console.log(response, 'Response after modal open', consultationId);
        this.setState({
          consultationData: response.data,
        });
      })
      .catch((err) => {
        console.error(err, consultationId, '[getDocConsultData]');
      });
  };

  closeModal = () => {
    this.setState({
      consultationData: null,
    });
    this.props.closeModal();
  };

  openAddAlternateNumber = () => {
    this.setState({
      alternateNumberModalOpen: true,
    });
  };

  closeAddAlternateNumber = () => {
    this.setState(
      {
        alternateNumberModalOpen: false,
      },
      this.afterOpenModal
    );
  };

  render() {
    console.log(this.props, this.state, 'render');
    const { consultationData } = this.state;

    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
      >
        <OuterComponent>
          {!consultationData ? (
            <LoadingComponent color={'black'} />
          ) : (
            <React.Fragment>
              <PageTitle>
                Consultation Details ({consultationData.consultationId})
                <StyledTappable onTap={this.props.closeModal}>
                  <CloseModal />
                </StyledTappable>
              </PageTitle>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-3">
                    <div className="col-6 font-weight-bold">Doctor Name:</div>
                    <div className="col-6">
                      {consultationData.doctorInfo.primaryDoctorName} (
                      {consultationData.doctorInfo.primaryDoctorId})<br />
                      {consultationData.doctorInfo.primaryDoctorVerticalName} <br />
                      {consultationData.doctorInfo.primaryDoctorPhoneNumber}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="col-6 font-weight-bold">
                      Consultation Status:
                    </div>
                    <div className="col-6">
                      {consultationData.consultationInfo.consultationStatus}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="col-6 font-weight-bold">
                      Consultation Type:
                    </div>
                    <div className="col-6">
                      {consultationData.consultationInfo.consultationType}
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="col-6 font-weight-bold">
                      Consultation Date:
                    </div>
                    <div className="col-6">
                      {consultationData.consultationInfo.consultationDate}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-3">
                    <div className="col-6 font-weight-bold">Patient Name:</div>
                    <div className="col-6">
                      {consultationData.patientInfo.patientName} (
                      {consultationData.patientInfo.patientGender})
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="col-6 font-weight-bold">User Name:</div>
                    <div className="col-6">
                      {consultationData.patientInfo.userName} (
                      {consultationData.patientInfo.userId})
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="col-6 font-weight-bold">User Phone:</div>
                    <div className="col-6">
                      {consultationData.patientInfo.userPhone} (
                      {consultationData.patientInfo.userEmail})
                    </div>
                  </div>
                  {this.props.consultationType !== 'scheduled-consults' ? (
                    <React.Fragment>
                      <div className="col-2">
                        <div className="col-6 font-weight-bold">
                          Alternate Number:
                        </div>
                        <div className="col-6">
                          {consultationData.consultationInfo.alternatePhone ||
                            'N/A'}{' '}
                          <FaEdit
                            onClick={() => this.openAddAlternateNumber()}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <div className="col-6 font-weight-bold">
                          User Gender:
                        </div>
                        <div className="col-6">
                          {consultationData.patientInfo.userGender}
                        </div>
                      </div>
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <BodyContainer>
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="font-weight-bold">Calls</div>
                    </div>
                    <div className="row">
                      {consultationData.callInfo &&
                      consultationData.callInfo.length ? (
                        consultationData.callInfo.map((c) => {
                          return (
                            <ItemContainer>
                              <div className="row">
                                <div className="col">
                                  <span className="font-weight-bold">
                                    Call Date:
                                  </span>{' '}
                                  {c.callDate}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="font-weight-bold">Sid:</div>
                                  {c.sid}
                                </div>
                                <div className="col">
                                  <div className="font-weight-bold">
                                    Call Service:
                                  </div>
                                  {c.callService}
                                </div>
                                <div className="col">
                                  <div className="font-weight-bold">
                                    Status:
                                  </div>
                                  {c.status}
                                </div>
                                <div className="col">
                                  <div className="font-weight-bold">
                                    Duration:
                                  </div>
                                  {c.duration}
                                </div>
                                <div className="col">
                                  <div className="font-weight-bold">
                                    Phone 1:
                                  </div>
                                  <p>Status: {
                                    c.legs && c.legs.length ? (c.legs[0].Status ? c.legs[0].Status : 'none') : 'none'
                                    }</p>  
                                  <p>Duration: {
                                    c.legs && c.legs.length ? (c.legs[0].OnCallDuration ? `${Math.floor(c.legs[0].OnCallDuration/60)} mins ${c.legs[0].OnCallDuration%60} sec` : 'none') : 'none'
                                    }</p>
                                </div>
                                <div className="col">
                                  <div className="font-weight-bold">
                                    Phone 2:
                                  </div>
                                  <p>Status: {
                                    c.legs && c.legs.length ? (c.legs[1].Status ? c.legs[1].Status : 'none') : 'none'
                                    }</p> 
                                  <p>Duration: {
                                    c.legs && c.legs.length ? (c.legs[1].OnCallDuration ? `${Math.floor(c.legs[1].OnCallDuration/60)} mins ${c.legs[1].OnCallDuration%60} sec` : 'none') : 'none'
                                    }</p>
                                </div>
                              </div>
                            </ItemContainer>
                          );
                        })
                      ) : (
                        <div className="row">
                          <div className="col text-danger">No Calls</div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="font-weight-bold">Prescriptions</div>
                      {consultationData.prescriptionInfo &&
                      consultationData.prescriptionInfo.length ? (
                        consultationData.prescriptionInfo.map((p, index) => {
                          return (
                            <ItemContainer>
                              <div className="row">
                                <div className="col">
                                  <span className="font-weight-bold">
                                    Prescription Date:
                                  </span>{' '}
                                  {p.prescriptionCreationDate}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="font-weight-bold">Id:</div>
                                  {p.prescriptionId}
                                </div>
                                <div className="col">
                                  <div className="font-weight-bold">
                                    Prescription:
                                  </div>
                                  {/* <a href={p.prescriptionUrl} taget="_blank">
                                    Link
                                  </a> */}
                                  <p>{index+1}</p>
                                </div>
                                <div className="col">
                                  <div className="font-weight-bold">
                                    Sent Date:
                                  </div>
                                  {p.publishedDate || (
                                    <span className="text-danger">
                                      Not Sent
                                    </span>
                                  )}
                                </div>
                              </div>
                            </ItemContainer>
                          );
                        })
                      ) : (
                        <div className="row">
                          <div className="col text-danger">
                            No Prescriptions
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="font-weight-bold">Diet Plans</div>
                      {consultationData.dietPlans &&
                      consultationData.dietPlans.length ? (
                        consultationData.dietPlans.map((p) => {
                          return (
                            <ItemContainer>
                              <div className="row">
                                <div className="col">
                                  <span className="font-weight-bold">
                                    Upload Date:
                                  </span>{' '}
                                  {p.uploadDate}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <div className="font-weight-bold">Id:</div>
                                  {p.uploadId}
                                </div>
                                <div className="col">
                                  <div className="font-weight-bold">Name:</div>
                                  {p.fileName}
                                </div>
                                <div className="col">
                                  <div className="font-weight-bold">
                                    Sent Date:
                                  </div>
                                  {p.publishedDate || (
                                    <span className="text-danger">
                                      Not Sent
                                    </span>
                                  )}
                                </div>
                              </div>
                            </ItemContainer>
                          );
                        })
                      ) : (
                        <div className="row">
                          <div className="col text-danger">No Diet Plans</div>
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="font-weight-bold">Consultation Notes</div>
                    </div>
                    <div className="row">
                      {consultationData.consultationNotes &&
                      consultationData.consultationNotes.length ? (
                        consultationData.consultationNotes.map((c) => {
                          return (
                            <ItemContainer>
                              <div className="row">
                                <div className="col">
                                  <span className="font-weight-bold">
                                    Note:
                                  </span>{' '}
                                  {c.note}
                                </div>
                              </div>
                              <div className="row">
                                <div className="col">
                                  <span className="font-weight-bold">By:</span>{' '}
                                  {c.name}
                                </div>
                                <div className="col">
                                  <span className="font-weight-bold">On:</span>{' '}
                                  {c.createdAt}
                                </div>
                              </div>
                            </ItemContainer>
                          );
                        })
                      ) : (
                        <div className="row">
                          <div className="col text-danger">
                            No Consultation Notes
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                    <div className="col-6">
                      <SuperAction
                        consultationId={consultationData.consultationId}
                        consultationType={this.props.consultationType}
                        consultationNotesList= {consultationData.consultationNotesList}
                      />
                    </div>
                </div>
              </BodyContainer>
              <C_Modal
                header="Update Alternate Number"
                isOpen={this.state.alternateNumberModalOpen}
                onClose={this.closeAddAlternateNumber}
              >
                <AddAlterNumberForm
                  consultationId={
                    consultationData.consultationInfo.consultationId
                  }
                  alternatePhone={
                    consultationData.consultationInfo.alternatePhone
                  }
                  onSubmit={this.closeAddAlternateNumber}
                  onClose={this.closeAddAlternateNumber}
                />
              </C_Modal>
            </React.Fragment>
          )}
        </OuterComponent>
      </Modal>
    );
  }
}
