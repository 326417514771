import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Button,
  Card,
  Col,
  Icon,
  Input as AntInput,
  Row,
  Select,
  Upload,
} from 'antd';

import { UploadOutlined } from '@ant-design/icons';


import { ModalHeader, ModalBody, Modal } from 'reactstrap';
import axios from 'axios';
import { addDataForNetworkCenter, uploadMouDocument } from '../../services/api/offline-consult';
import { toast } from 'react-toastify';

const redirectForLogin = () => {
  window.location.href = '/';
 }
 
 axios.interceptors.response.use((response) => response,  (error) => {
   const statusCode = error.response.data.code;
   console.log(statusCode);
   return statusCode === 401 ?  redirectForLogin() : Promise.reject(error);
 });
 
const { Option } = Select;

const Text = styled.p`
  margin: 0;
  font-family: BrandonText-Medium, Lato, sans-serif;
`;

const SemiboldText = styled(Text)`
  font-family: ProximaNova-Semibold, Lato, sans-serif;
`;

const LabelText = styled(SemiboldText)`
  font-size: 12px;
  text-align: center;
  margin-bottom: 0.25rem;
  margin-top: 0.625rem;
  color: #7c7c7c;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 20px;
  /* over */
`;

const AddPanNumberModal = (props) => {
  const { isModalOpen, closePanNumberModal, centerId, centerName } = props;
  const [name, setName] = useState('');
  const [panNumber, setPanNumber] = useState('');
  const [mouStatus, setMouStatus] = useState('');
  const [fileList, setFileList] = useState([]);
  const [mouLogIds, setMouLogIds] = useState([]);

  const   handleChange = ({ fileList }) => {
    console.log(fileList, 'on change');
    setFileList(fileList.slice(-1));
  };

  const handleUpload = (response) => {
    let mouLogId,
      docUid = response.file.uid;
    console.log(centerId, 'HandleUpload');
    console.log(response.file.uid, 'HandleUpload Response UID Check');
    const formData = new FormData();
    formData.append('file', response.file);
    if (centerId) {
      formData.append('centerId', centerId);
    }
    return uploadMouDocument(
        formData
    )
      .then((resInfo) => {
        console.log(resInfo, 'uploadMouDocument response');
        mouLogId = resInfo.result.mouLogId;
        setMouLogIds([{ mouLogId, docUid }]);
        response.onSuccess(response, response.file);
      })
      .catch((err) => {
        response.onError(err);
      });
  };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const finalMouIds = mouLogIds.map((m) => {
        return m.mouLogId;
      });
      console.log(finalMouIds, 'finalMouIds');
      const panData = {
        panNumber,
        panName : name,
        mouStatus,
        finalMouIds:  finalMouIds[0],
        requestType: 'Add_PAN_Number',
        centerId: centerId,
        centerName: centerName,
        mouCategory:"6",
        documentType: "center-documents"
      };

      if(!(panNumber && name && finalMouIds.length>0)) {
        toast.error('Please fill all the required fields');
        return ;
      }

      const data = await addDataForNetworkCenter(panData);
      if(data.message == 'success') {
        closePanNumberModal();
        toast.success('PAN Number Requested Created Successfully');
      }
      if(data.message == 'serverError') {
        toast.error(data.errorMessage);
      }
      console.log(data);
    };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalHeader className="bg-primary" toggle={() => closePanNumberModal()}>
        {`Add Pan Number Request for ${centerName}`}
      </ModalHeader>
      <ModalBody>
        <Card>
          <BodyContainer>
            <Row gutter={24}>
              <Col xs={24} md={12}>
                <LabelText>Name on Pan Card</LabelText>
                <AntInput
                  value={name}
                  placeholder="Name on PAN Card"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Col>

              <Col xs={24} md={12}>
                <LabelText>Pan Number</LabelText>
                <AntInput
                  value={panNumber}
                  onChange={(e) => {
                    setPanNumber(e.target.value);
                  }}
                  placeholder="Pan Number"
                />
              </Col>
              {/* <Col xs={24} md={12}>
                <LabelText>Status:</LabelText>
                <Select
                  value={mouStatus}
                  style={{ width: '100%' }}
                  onChange={(e) => setMouStatus(e)}
                >
                  <Option value="1">Agreed and email confirmation</Option>
                  <Option value="2">
                    Agreed facilitation but no written confirmation
                  </Option>
                  <Option value="3">MoU pending</Option>
                  <Option value="4">Agreement signed at both ends</Option>
                  <Option value="5">Agreement Expired</Option>
                </Select>
              </Col> */}
              <Col xs={24} md={12}>
                <LabelText>PAN Documents:</LabelText>
                <Upload
                  accept="image/*,application/pdf"
                    fileList={fileList}
                    onChange={(e)=>handleChange(e)}
                    customRequest={(e)=>handleUpload(e)}
                >
                  <Button>
                  <UploadOutlined />
                    Upload
                  </Button>
                </Upload>
              </Col>
            </Row>

            <Row gutter={24} style={{ marginTop: 20 }}>
              <Col xs={24} md={12}>
                <Button
                  type="primary"
                  block
                  style={{ marginRight: 10 }}
                    onClick={(e)=>handleSubmit(e)}
                >
                  Send Request
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button block onClick={closePanNumberModal}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </BodyContainer>
        </Card>
      </ModalBody>
    </Modal>
  );
};

export default AddPanNumberModal;
