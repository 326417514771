import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

class ResultModal extends Component {
  render() {
    return (
      <Modal isOpen toggle={this.props.closeModal}>
        <ModalHeader
          className={this.props.bgColor}
          toggle={this.props.modalToast}
        >
          {this.props.modalHeader}
        </ModalHeader>
        <ModalBody>{this.props.poolIds && this.props.poolIds ? this.props.poolIds.map((e,i)=>{
          return <table key={i}>
            <thead>
            <tr>
              <th>Pool Id</th>
              <th>Product Id</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{e.poolId}</td>
              <td>{e.productId}</td>
            </tr>
            </tbody>
          </table>
        }) :  this.props.modalBody}</ModalBody>
      </Modal>
    );
  }
}

export default ResultModal;
