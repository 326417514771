export const defaultState = {
  showBody: false,
  userDetail: [],
  requestId: null,
  showInternalComments: null,
};

export default function customerSupport(state = defaultState, action) {
  switch (action.type) {
    case 'SET_USER_DETAILS': {
      return Object.assign({}, state, {
        userDetail: action.payload.userDetail,
      });
    }
    case 'SHOW_BODY': {
      return Object.assign({}, state, {
        showBody: action.payload.showBody,
      });
    }
    case 'SHOW_SUPPORT_COMMENTS':
      const requestId = action.payload.requestId;
      const showInternalComments = action.payload.showInternalComments;
      return Object.assign({}, state, {
        requestId,
        showInternalComments,
      });
    case 'HIDE_SUPPORT_COMMENTS': {
      return Object.assign({}, state, {
        showInternalComments: null,
        requestId: null,
      });
    }
    default:
      return state;
  }
}
