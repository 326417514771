import React, { Component } from 'react';
import Select  from 'react-select';
import loginCheck from '../login/LoginCheck';
import ReactTable from 'react-table';
import FoldableTableHOC from 'react-table/lib/hoc/foldableTable';
import 'react-table/react-table.css';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip,
} from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';

import SponsorFilter from './headerFilter/SponsorFilter';

import PlaceRequest from './PlaceRequest'

import SubComponent from './table-subcomponent/SubComponent';

import { toast } from 'react-toastify';

import {
  FaSearch,
  FaMoneyCheckAlt,
} from 'react-icons/fa';

//Axios
import 'bootstrap//dist/css/bootstrap.css';

import {
    IoIosCheckmark,
  IoMdCreate,
  IoMdClose,
} from 'react-icons/io';
import { MdTransform } from 'react-icons/md';
// Modal
import IntimationModalCancel from './IntimationModalCancel';
import ConfirmModal from './ConfirmModal';


import {
  showRequestModal,
  hideRequestModal,
  showIntimationRequestModal,
  hideIntimationRequestModal,
} from '../../actions/index';

import { debounce } from '../../utils/debounce';

import './modal.css';

import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';

import { Row, Col } from 'reactstrap';

import styled from 'styled-components';

import {
  StatusPendingContainer,
  StatusCompleteContainer,
  StatusDangerContainer,
} from '../common/StatusContainer';

import {

  cancelRequest,

} from '../../services/api/intimation-request';

import NavBar from '../layout/NavBar';



import { RegularText } from '../common/Text';
import StyledTappable from '../common/StyledTappable';
import LoadingComponent from '../common/LoadingComponent';
import { createBulkCoupon, createCoupon, downloadCouponData, getAllCouponCode } from '../../services/api/coupon';
import BulkCreate from './BulkCreate';
import DownloadCouponData from './downloadCsv';
import config from '../../config';

const FoldableTable = FoldableTableHOC(ReactTable);

const PaddedContainer = styled.div`
  padding: 5px;
`;

const OuterComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SearchBox = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
`;

const SerachBoxInput = styled(Input)`
  border: 0;
  flex: 2 0 0;
  margin: 1px;
  &:focus {
    outline: none;
  }
`;

const SearchBoxContent = styled.div`
  display: inline-block;
  flex: 1 0 0;
  margin: 1px;
  text-align: right;
`;

const ProcessingDateComponent = styled(StyledTappable)``;

const ProcessingDate = styled(RegularText)`
  display: flex;
  font-size: 16px;
  color: ${(props) => props.color || '#46471a'};
`;
const options =[
  { value: '1', label: '1' },
  { value: '2', label: '2' },
]
class Coupons extends Component {
  tableAllColumns = (status) => [
    {
      Header: 'Request Details',
      foldable: true,
      columns: [
        {
          accessor: 'id',
          numeric: true,
          disablePadding: true,
          // filterable: false,
          // Filter: (props) => (
          //   <input
          //     onChange={(event) =>
          //       debounce(this.setRequestIdFilter(event), 300)
          //     }
          //   />
          // ),
          filterable: false,
          Header: 'Id',
          style: { whiteSpace: 'unset' },
          // Cell: (row) => {
          //   const { requestId, appointmentDate } =
          //     row.original;
          //   let color = 'primary';
          //   return (
          //     <div>
          //       <Button
          //         type="submit"
          //         size="sm"
          //         color={color}
          //         onClick={() => this.showDetails(requestId)}
          //       >
          //         {requestId}
          //       </Button>
          //     </div>
          //   );
          // },
        },
        {
          accessor: 'coupon_name',
          numeric: false,
          filterable: false,
          disablePadding: false,
          Header: 'Coupon Name',
          style: { whiteSpace: 'unset' },
          sortable: false,
        },
        {
          accessor: 'coupon_code',
          numeric: false,
          filterable: false,
          disablePadding: false,
          Header: 'Coupon Code',
          style: { whiteSpace: 'unset' },
          sortable: false,
        },
        {
          accessor: 'active',
          numeric: false,
          disablePadding: false,
          filterable: false,
          Header: 'Active',
          style: { whiteSpace: 'unset' },
          sortable: false,
        },
        // {
        //   accessor: 'actions',
        //   numeric: true,
        //   filterable: false,
        //   disablePadding: false,
        //   Header: 'Actions',
        //   sortable: false,
        //   style: { whiteSpace: 'unset' },
        //   Cell: (row) => {
        //     return (
        //       <div>
        //         {row.original.active == '1' && (
        //           <>
        //             <IoIosCheckmark
        //               className="icon-style"
        //               style={{ fontSize: 25 }}
        //               id={`confirmButton-${row.original.id}`}
        //               onClick={() =>
        //                 this.confirmOpenModal(
        //                   row.original.id,
        //                   row.original.category_name
        //                 )
        //               }
        //             />
        //             <Tooltip
        //               placement="top"
        //               target={`confirmButton-${row.original.id}`}
        //               trigger="hover"
        //               toggle={() => {
        //                 const { confirmTooltipOpen } = this.state;
        //                 this.setState({
        //                   confirmTooltipOpen:
        //                     row.original.id === confirmTooltipOpen
        //                       ? -1
        //                       : row.original.id,
        //                 });
        //               }}
        //               isOpen={
        //                 this.state.confirmTooltipOpen === row.original.id
        //               }
        //             >
        //               Confirm Appointment
        //             </Tooltip>
        //           </>
        //         )}  
        //       </div>
        //     );
        //   },
        // },
        {
          accessor: 'expire_on',
          numeric: false,
          filterable: false,
          disablePadding: false,
          Header: 'Expired On',
          style: { whiteSpace: 'unset' },
          Cell : (row) => {
            return (
              <div>
                {moment(row.original.expired_on).format('DD-MM-YYYY hh:mm A')}
              </div>
            );
          }
        },
        {
          accessor: 'sponsorName',
          numeric: false,
          disablePadding: false,
          Header: 'Sponsor',
          style: { whiteSpace: 'unset' },
          Filter: ({ filter, onChange }) => (
            <SponsorFilter
              onChangeValue={this.setSponsorFilter}
              value={this.state.sponsorFilter}
            />
          ),
        },
        {
          accessor: 'userId',
          numeric: false,
          disablePadding: false,
          Header: 'User Id',
          style: { whiteSpace: 'unset' },
          filterable: false,
          headerStyle: { overflow: 'visible' },
        },
        {
          accessor: 'userName',
          numeric: false,
          disablePadding: false,
          Header: 'User Name',
          style: { whiteSpace: 'unset' },
          // filterable: false,
          headerStyle: { overflow: 'visible' },
          Filter: (props) => (
            <input
              type="text"
              onChange={(event) =>
                this.setUserNameFilter(event)
              }
            />
          ),
        },
        {
          accessor: 'valid_for',
          numeric: false,
          disablePadding: false,
          Header: 'Valid For',
          style: { whiteSpace: 'unset' },
          // filterable: false,
          headerStyle: { overflow: 'visible' },
          Filter: () => (
            <select
              onChange={(event) =>
                this.setValidForFilter(event)
              }
            >
              <option selected value=''>All</option>
              <option value="labs">Labs</option>
              <option value="con-online">Online</option>
              <option value="con-offline">Offline</option>
            </select>
          ),
        },
        {
          accessor: 'vertical_id',
          numeric: false,
          filterable: false,
          disablePadding: false,
          Header: 'Vertical_id',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'discount_type',
          numeric: false,
          filterable: false,
          disablePadding: false,
          Header: 'Discount Type',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'discount_value',
          numeric: false,
          filterable: false,
          disablePadding: false,
          Header: 'Discount Value',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'count',
          numeric: false,
          disablePadding: false,
          filterable:false,
          Header: 'Max Count',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'used',
          numeric: false,
          disablePadding: false,
          filterable:false,
          Header: 'Coupons Used',
          style: { whiteSpace: 'unset' },
        },
        // {
        //   accessor: 'min_order_value',
        //   numeric: false,
        //   disablePadding: false,
        //   Header: 'Min Order Value',
        //   style: { whiteSpace: 'unset' },
        // },
        {
          accessor: 'created_on',
          numeric: false,
          disablePadding: false,
          Header: 'Created On',
          style: { whiteSpace: 'unset' },
          Cell : (row) => {
            return moment(row.original.created_at).format('DD MM YYYY hh:mm A')}
        },
      ],
    }
  ];

  constructor(props) {
    super(props);

    this.debounceSearch = debounce((val) => this.fetchTableData(), 300);

    this.state = {
      loading: true,
      tableColumns: this.tableAllColumns(),
      data: [],
      type: null,
      totalHits: 0,
      confirmRequest: false,
      altRequest: false,
      cancelModalIsOpen: false,
      userNameFilter: '',
      detail: {},
      validForFilter: '',
      show: false,
      pages: 0,
      search: '',
      pageSize: 10,
      segregation: null,
      statusFilter: null,
      sponsorFilter: null,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      confirmModal: {
        isOpen: false,
        header: '',
        body: '',
        onSubmit: null,
        id: null,
        onClose: null,
        buttonLoading: false,
      },
      idFilter: null,
    };
  }


  componentDidMount() {
    const { location } = this.props;
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      if (searchParams.get('sourceId')) {
        this.showDetails(searchParams.get('sourceId'));
      }
    }
  }

  filterMethod = (filter, row) => {
    // Pivoted rows won't contain the column.
    //  If that's the case, we set the to true (allowing us to only filter on the current column)
    const rowValue = row[filter.id];
    if (!rowValue) {
      return true;
    }

    const filterValue = filter.value.filterValue || '';
    const filterType = filter.value.filterType;
    switch (filterType) {
      case 'contains':
        return rowValue.indexOf(filterValue) > -1;
      case 'starts-with':
        return rowValue.startsWith(filterValue);
      case 'ends-with':
        return rowValue.endsWith(filterValue);
      case 'matches':
        return rowValue === filterValue;
      case 'greater-than':
        return rowValue > filterValue;
      case 'less-than':
        return rowValue < filterValue;
      default:
        return true;
    }
  };


  setSponsorFilter = (sponsorId) => {
    this.setState(
      {
        sponsorFilter: sponsorId,
      },
      this.fetchTableData
    );
  };

  setUserNameFilter = (event) => {
    console.log(event.target.value);
    this.setState(
      {
        userNameFilter: event.target.value,
      },
      this.fetchTableData
    );
  };

  setValidForFilter = (event) => {
    console.log(event.target.value);
    this.setState({
      validForFilter: event.target.value,
    },
    this.fetchTableData
    )
    
  }

  // Cancel Modal
  cancelOpenModal = (requestId) => {
    this.setState({ cancelModalIsOpen: true, requestId });
  };

  cancelCloseModal = () => {
    this.setState({ cancelModalIsOpen: false });
  };

  submitCancelModal = (remarks) => {
    const  requestId  = this.state.id;
    if (!requestId || !remarks.length) {
      console.error({ requestId, remarks }, 'No request id present');
      toast.error('Select a reason to continue');
      return;
    }
    return cancelRequest(requestId, remarks)
      .then((response) => {
        // Handle success response
        toast.success('Cancel Request Success');
      })
      .catch((err) => {
        // Error Handle
        toast.error('Cancel Request fail Please try again');
      })
      .finally(() => {
        // Handle Final (mainly for spinners)
      });
  };

  onFetchData = (state, instance) => {
    let orderBy, orderDir;
    if (state.sorted.length > 0) {
      orderBy = state.sorted[0].id;
      orderDir = state.sorted[0].desc ? 'desc' : 'asc';
    } else {
      orderBy = 1;
      orderDir = 'desc';
    }
    console.log(state, 'State on fetch data');
    let claimedByFilter = '';
    state.filtered.forEach((f) => {
      if (f.id === 'claimedUserName') {
        claimedByFilter = f.value;
      }
    });
    this.setState(
      {
        pageSize: state.pageSize,
        page: state.page,
        orderBy,
        orderDir,
        claimedByFilter,
      },
      this.fetchTableData
    );
  };

  fetchTableData = () => {
    this.setState({
      loading: true,
    });
    const params = {
      draw: this.state.pageSize,
      page: this.state.page,
      orderBy: this.state.orderBy,
      orderDir: this.state.orderDir,
      statusFilter: this.state.statusFilter,
      userNameFilter: this.state.userNameFilter,
      validForFilter: this.state.validForFilter,
      search: this.state.search || null,
      sponsorFilter: this.state.sponsorFilter,
      claimedByFilter: this.state.claimedByFilter,
    };

    if (this.state.segregation !== null) {
      params.segregation = this.state.segregation;
    }

    if (this.state.idFilter) {
      params.id = this.state.id;
    }

    console.log('params', params);
    return getAllCouponCode(params)
      .then((responseBody) => {
        console.log('responseBody', responseBody);
        this.setState({
          data: responseBody.result,
          loading: false,
          pages: Math.ceil(responseBody.totalHits / this.state.pageSize),
          totalHits: responseBody.totalHits || 0,
        });
      })
      .catch((err) => {
        console.error({ err, params }, 'error========');
      });
  };


  setRequestIdFilter = (event) => {
    const { value } = event.target;
    this.setState(
      {
        idFilter: value,
      },
      this.fetchTableData
    );
  };


  onAction = () => {
    this.fetchTableData();
  };

  clearFilters = () => {
    this.setState(
      {
       
        statusFilter: null,
        ackFilter: null,
        inHouseFilter: null,
        sponsorFilter: null,
        idFilter: null,
        isRescheduledFilter: null,
        isReconfirmationRequiredFilter: null,
        isReconfirmationFilter: null,
        isPendingCallbackFilter: null,
      },
      this.fetchTableData
    );
  };


  onSearchClick = () => {
    const { search, loading } = this.state;
    if (loading) {
      return;
    }
    this.debounceSearch(search);
  };

  handleCreateCouponCode = (dateKey, userId, verticalId, sponsorId, max_count, applicableFor, discountValue, couponName, discount_type) => {
    return createCoupon(dateKey, userId, verticalId, sponsorId, max_count, applicableFor, discountValue, couponName, discount_type)
    .then((response) => {
      if (response.message === 'success') {
        toast.success('Request Placed Success');
        return this.fetchTableData().then(() => true);
      } else {
        toast.error(response.errorMessage);
        return false;
      }
    });
  };

  handleBulkCreateCouponCode = (dateKey, userId, verticalId, sponsorId, max_count, applicableFor, discountValue, couponName, discount_type, bulkCount) => {
    return createBulkCoupon(dateKey, userId, verticalId, sponsorId, max_count, applicableFor, discountValue, couponName, discount_type, bulkCount)
    .then((response) => {
      if (response.message === 'success') {
        toast.success('Request Placed Success');
        return this.fetchTableData().then(() => true);
      } else {
        toast.error(response.errorMessage);
        return false;
      }
    });
  };

  downloadCouponData = (couponName) => {
    window.open(`${config.baseUrl}/new-ops/coupons/download-coupon?couponName=${couponName}`);
    return Promise.resolve()
    .then((response) => {
        toast.success('Downloaded Placed Success');
        return this.fetchTableData().then(() => true);
    });
  }
  render() {
    const {
      loading,
      data,
      pages,
      confirmModal,
      totalHits,
      tableColumns,
      segregation,
    } = this.state;


    const searchLink = Link.state(this, 'search');

    return (
      <OuterComponent>
        <NavBar />
        <PaddedContainer className="pt-4 mt-5">
          <Row>
          <div className="col-1">
              <PlaceRequest
                submit={this.handleCreateCouponCode}
                onComplete={this.onAction}
              />
            </div>
            <div className="col-1">
              <BulkCreate
                submit={this.handleBulkCreateCouponCode}
                onComplete={this.onAction}
              />
            </div>
            <div className="col-1">
              <DownloadCouponData
                submit={this.downloadCouponData}
                onComplete={this.onAction}
              />
            </div>
            <div className="col">
              <div className="row">
                <div className="col-8">
                  <SearchBox>
                    <SerachBoxInput
                      valueLink={searchLink}
                      type="text"
                      placeholder="Search..."
                      onKeyDown={(e) =>
                        e.key === 'Enter' ? this.onSearchClick() : null
                      }
                    />
                    <SearchBoxContent>{totalHits} records</SearchBoxContent>
                  </SearchBox>
                </div>
                <div className="col-1">
                  {this.state.loading ? (
                    <LoadingComponent />
                  ) : (
                    <button
                      onClick={this.onSearchClick}
                      className="btn btn-primary"
                    >
                      <FaSearch />
                    </button>
                  )}
                </div>
                <div className="col-3"></div>
              </div>
            </div>
          </Row>
        </PaddedContainer>
        

        <FoldableTable
          filterable
          data={data}
          columns={tableColumns}
          loading={loading}
          pages={pages}
          manual
          onFetchData={this.onFetchData}
          showPaginationTop={true}
          className="-striped -highlight"
          SubComponent={(row) => (
            <SubComponent
              id={row.original.id}
              userId={row.original.userId}
            />
          )}
          pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          defaultSorted={[
            {
              id: 'id',
              desc: true,
            },
          ]}
        />
        <IntimationModalCancel
          modalIsOpen={this.state.cancelModalIsOpen}
          openModal={this.cancelOpenModal}
          closeModal={this.cancelCloseModal}
          submitModal={this.submitCancelModal}
          onComplete={this.onAction}
          id={this.state.id}
        />

       
        {/*  Request Payout Modal*/}
        <Modal
          isOpen={this.state.requestPayoutOpenModalOpen}
          toggle={this.togglePayoutRequestModal}
          style= {{ maxWidth : '1000px', width: '100%', zIndex: 10}}
        >
          <ModalHeader
            className="bg-primary"
            toggle={this.togglePayoutRequestModal}
          >
            {`Request Payout (Request Id: ${this.state.requestPayoutOpenReqId})`}
          </ModalHeader>
          <ModalBody>
            {/* {this.state.loading ? (
              <LoadingComponent />
            ) : (
              <Button
                type="submit"
                size="sm"
                onClick={() =>
                  this.requestPayout(
                    this.state.requestPayoutOpenReqId
                  )
                }
              >
                Confirm
              </Button>
            )} */}
          </ModalBody>
        </Modal>
        <ConfirmModal
          modalHeader={confirmModal.header}
          onClose={confirmModal.onClose}
          isOpen={confirmModal.isOpen}
          onSubmit={this.submitConfirmModalGen}
          loading={confirmModal.buttonLoading}
        >
          {confirmModal.body}
        </ConfirmModal>

       
      </OuterComponent>
    );
  }
}



export default (loginCheck(Coupons));
