import React, { useState } from 'react'
import ReactTable from 'react-table';
import { TableContainer } from '../redemptions/StyledComponent';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import { Button } from 'reactstrap';


function WalletTransactionDetailsTable(props) {
    console.log({props}, '[WalletTransactionDetailsTable][props]')
    const [loading, setLoading] = useState(false);

    const downloadArrayOfObjectsAsCSV = (data, filename) => {
      const headers = Object.keys(data[0]).join(',') + '\n';
      const rows = data.map((row) => Object.values(row).join(',')).join('\n');
      const csvData = headers + rows;
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      if (navigator.msSaveBlob) {
        // For IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      }
    }

    const walletTableColumns = [
        {
            Header: 'Wallet-Transactions',
            columns: [
                {
                  Header: 'Transaction ID',
                  accessor: 'id',
                  width: 150,
                },
                {
                  Header: 'Amount',
                  accessor: 'Amount',
                  width: 150,
                },
                {
                  Header: 'Type',
                  accessor: 'type',
                  filterable: true,
                  width: 150,
                  Filter: ReactTableInputFilter,
                },
                {
                  Header: 'Date',
                  accessor: 'formattedDate',
                  width: 150,
                  Filter: ReactTableInputFilter,
                },
                {
                  Header: 'Source ID',
                  accessor: 'sourceId',
                  width: 150,
                },
              ],
        }
    ]

  return (
    <div>
      <div><Button className='mb-2' onClick={()=>downloadArrayOfObjectsAsCSV(props.data, 'wallet_transaction')}>Download Csv</Button></div>
        <TableContainer>
            <ReactTable
                loading={loading}
                columns={walletTableColumns}
                data = {props.data}
             />
        </TableContainer>
    </div>
  )
}

export default WalletTransactionDetailsTable;