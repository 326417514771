import React from 'react';
import { toast } from 'react-toastify';
import {Input, Select} from "valuelink/lib/tags";
import LoadingComponent from "../common/LoadingComponent";
import Modal from "../common/Modal";
import Link from "valuelink";

import {
    fetchLabTestByTestMapId,
    addLabTestForPartner,
    fetchStates,
    fetchOverriddenStates,
    deleteOverriddenState,
    addOverriddenState
} from "../../services/api/lab-partners";

class EditLabTests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            testName: '',
            mrp: '',
            cost: '',
            testCode: '',
            states: [],
            overriddenStates: [],
            oMrp: '',
            oCost: '',
            oStateId: '',
            retailDiscountMargin: ''
        };
    }

    getOverriddenStates = () => {
        const { testMapId, partnerId } = this.props;
        return fetchOverriddenStates(partnerId, testMapId)
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        overriddenStates: response.overriddenStates,
                    });
                } else {
                    throw new Error(response.errorMessage || 'Something went wrong while getting overridden states');
                }
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err);
            });
    };

    validateNumber = (value) => {
        const reg = /^\d{1,5}$/;
        return reg.test(value);
    };

    validateAlphaNumeric = (value) => {
        const reg = /[a-z A-Z0-9\\_\\"]+$/;
        return reg.test(value);
    };

    getStates = () => {
        console.log('getStates');
        return fetchStates()
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        states: response.states || [],
                    });
                } else {
                    throw new Error(response.errorMessage || 'Something went wrong while fetching states');
                }
            })
            .catch((err) => {
                console.error(err);
                toast.error(err.message);
            });
    };


    deleteOverriddenState = (testMapOverrideId) => {
        return deleteOverriddenState(testMapOverrideId)
            .then((response) => {
                if (response.message === 'success') {
                    toast.success('Successfully deleted the state');
                } else {
                    throw new Error(response.errorMessage || 'Something went wrong while deleting');
                }
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err);
            });
    };

    addOverridenState = () => {
        const { testMapId, partnerId } = this.props;
        const { oMrp, oCost, oStateId } =  this.state;
        const mrp = oMrp;
        const cost = oCost;
        const stateId = oStateId;
        console.log(testMapId, partnerId, mrp, cost, stateId, 'Before insertion');
        return addOverriddenState(partnerId, testMapId, stateId, mrp, cost)
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        oMrp: '',
                        oCost: '',
                        oStateId: '',
                    }, this.onOpen);
                } else {
                    throw new Error(response.errorMessage || 'Something went wrong while adding state');
                }
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err);
            });
    };

    submitAddLabTest = () => {
        const { testMapId, partnerId } = this.props;

        const { mrp, cost, testName, testCode, retailDiscountMargin } = this.state;

        if (!testMapId || !this.validateNumber(mrp) || !this.validateNumber(cost) || !this.validateAlphaNumeric(testName) || !this.validateAlphaNumeric(testCode)) {
            toast.error('Please enter correct details');
            return;
        }

        const test = {
            testMapId,
            mrp,
            cost,
            testName,
            testCode,
            retailDiscountMargin
        };

        this.setState({
            loading: true,
        });
        return addLabTestForPartner(partnerId, test, false)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    toast.success('Successfully changed the test details');
                    this.props.onClose();
                    return;
                }
                throw new Error(body.errorMessage || 'Error while editing the details for the test');
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, testMapId, 'Error while editing the lab test');
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    getLabTest = () => {
        const { testMapId, partnerId } = this.props;
        fetchLabTestByTestMapId(partnerId, testMapId)
            .then((response) => {
                const body = response.body;
                const testDetails = body.testDetails;
                if (body.message === 'success' && testDetails) {
                    this.setState({
                        testName: testDetails.partnerTestName,
                        mrp: testDetails.mrp,
                        cost: testDetails.cost,
                        testCode: testDetails.partnerTestCode,
                        retailDiscountMargin: testDetails.retailDiscountMargin ? testDetails.retailDiscountMargin : 0
                    });
                    return;
                }
                throw new Error(body.errorMessage);
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, 'Error while getting lab test details');
            });
    };

    onOpen = () => {
        this.getStates()
            .then(() => {
                return this.getOverriddenStates();
            });
        this.getLabTest();
    };

    closeModal = () => {
        this.props.onClose();
    };

    render() {
        const { loading } = this.state;
        const { isOpen, testName } = this.props;
        const testNameLink = Link.state(this, "testName");
        const testCodeLink = Link.state(this, "testCode");
        const mrpLink = Link.state(this, "mrp");
        const costLink = Link.state(this, "cost");
        const rdmLink = Link.state(this, 'retailDiscountMargin');
        const oMrpLink = Link.state(this, 'oMrp');
        const oCostLink = Link.state(this, 'oCost');
        const oStateLink = Link.state(this, 'oStateId');

        return (
            <Modal
                onOpen={this.onOpen}
                isOpen={isOpen}
                onClose={this.closeModal}
                header={`Edit Test Modal (${testName})`}
            >
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    Partner Test Name
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={testNameLink} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    Partner Test Code
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={testCodeLink}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    MRP
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={mrpLink}/>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    Cost
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={costLink}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    Retail Discount Margin
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={rdmLink}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col">
                            {loading ? (
                                <LoadingComponent />
                            ) : (
                                <div className="btn btn-success btn-block" onClick={this.submitAddLabTest}>Add Test</div>
                            )}
                        </div>
                        <div className="col">
                            <div className="btn btn-danger btn-block" onClick={this.closeModal}>Cancel</div>
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-3 text-center font-weight-bold">
                                    State
                                </div>
                                <div className="col-3 text-center font-weight-bold">
                                    MRP
                                </div>
                                <div className="col-3 text-center font-weight-bold">
                                    Cost
                                </div>
                                <div className="col-3 text-center font-weight-bold">
                                    Action
                                </div>
                            </div>
                            {(this.state.overriddenStates && this.state.overriddenStates.length) ? this.state.overriddenStates.map((os) => (
                                <div className="row mt-1">
                                    <div className="col-3 text-center">
                                        {os.state}
                                    </div>
                                    <div className="col-3 text-center">
                                        {os.mrp}
                                    </div>
                                    <div className="col-3 text-center">
                                        {os.cost}
                                    </div>
                                    <div className="col-3 text-center">
                                        <button className="btn btn-primary" onClick={() => (this.deleteOverriddenState(os.testMapOverrideId))}>Delete</button>
                                    </div>
                                </div>
                            )) : (<React.Fragment />)}
                            <div className="row mt-1">
                                <div className="col-3">
                                    <Select valueLink={oStateLink} className='form-control'>
                                        <option>Select State</option>
                                        {(this.state.states && this.state.states.length) ? (this.state.states.map((s) => (
                                            <option value={s.stateId}>{s.state}</option>
                                        ))) : (<React.Fragment />)}
                                    </Select>
                                </div>
                                <div className="col-3">
                                    <Input
                                        className="form-control"
                                        valueLink={oMrpLink}
                                    />
                                </div>
                                <div className="col-3">
                                    <Input
                                        className="form-control"
                                        valueLink={oCostLink}
                                    />
                                </div>
                                <div className="col-3 text-center">
                                    <button
                                        className="btn btn-primary"
                                        onClick={this.addOverridenState}
                                    >Add</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default EditLabTests;
