import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class EditConsultation extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  handleSubmit = () => {
    this.props.closeModal();
    this.props.onComplete();
  };
  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        toggle={() => this.props.closeModal()}
        className={this.props.className}
      >
        <ModalHeader className="bg-primary" toggle={this.props.closeModal}>
          Edit Consultation
        </ModalHeader>
        <ModalBody>
          <form>
            <label>Payout to the doctor:</label>
            <br />
            <input type="number" />
            <br />
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="info" onClick={this.handleSubmit}>
            Submit
          </Button>
          <Button color="secondary" onClick={this.props.closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditConsultation;
