import React from 'react';

import Modal from 'react-modal';

import { changeUserEmail } from '../../services/api/customer-support';

import { toast } from 'react-toastify';

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

class EmailEditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            userId: null,
            email: '',
        };
    }

    processChangeEmail = () => {
        const { userId } = this.props;
        const { email } = this.state;
        if (!userId || !email) {
            toast.error('Email is invalid');
            return;
        }
        return changeUserEmail(userId, email)
            .then((response) => {
                console.log(response, 'Response from server');
                if (response.message !== 'success') {
                    toast.error(response.errorMessage);
                    // throw new Error(response.errorMessage);
                    return;
                }
                toast.success('Successfully added for changing');
                this.closeModal();
            })
            .catch((err) => {
                console.log(err, 'Error in endpoint');
            });

    };

    closeModal = () => {
        this.setState({
            userId: null,
            isOpen: false,
            email: '',
        });
    };

    changeEmail = (e) => {
        this.setState({
            email: e.target.value,
        });
    };

    openModal = () => {
        this.setState({
            isOpen: true,
        });
    };

    render() {
        return (
            <div>
                <button className="btn btn-primary" onClick={this.openModal}>Change Email</button>
                <Modal
                    isOpen={this.state.isOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Change Email"
                >
                    <h2>Change Email ({this.props.userId})</h2>
                    <input onChange={this.changeEmail}/>
                    <button className="btn btn-primary" onClick={this.processChangeEmail}>Change</button>
                </Modal>
            </div>
        )
    }
}

export default EmailEditModal;
