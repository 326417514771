import { jsonPipeRequest } from "./baseRequest";
import baseRequest from './baseRequest';

export const getAllCouponCode = params => {
    const url = '/new-ops/coupons/get-all-coupon';
    const method = 'GET';
    return baseRequest(url, method, params, null).then(response => response.data);
};

export const getAllSponsor = params => {
    const url = '/new-ops/sponsor-management/get-all-sponsors';
    const method = 'GET';
    return baseRequest(url, method, params, null).then(response => response.data);
}

// const expiredOn = moment(req.body.expiredOn).tz('Asia/Kolkata').format('YYYY-MM-DD HH:mm:ss');
// const couponName = req.body.couponName;
// const verticalId = req.body.verticalId || null;
// const validFor = req.body.validFor;
// const discountValue = req.body.discountValue;
// const minOrderValue = req.body.minOrderValue || 0;
// const userId  = req.body.userId || null;
// const couponCount   = req.body.couponCount || null;
// const discountType   = req.body.discountType || '-';

export const createCoupon = (dateKey, userId, verticalId, sponsorId, max_count, applicableFor, discountValue, couponName, discount_type) => {
    const body ={
        "expiredOn": dateKey,
        "userId": userId,
        "verticalId": verticalId,
        "sponsorId": sponsorId,
        "couponCount": max_count,
        "validFor": applicableFor,
        "discountValue": discountValue,
        "couponName": couponName,
        "discountType": discount_type
    }
    const url = '/new-ops/coupons/create-coupon';
    const method = 'POST';
    return baseRequest(url, method, null, body);
}


export const createBulkCoupon = (dateKey, userId, verticalId, sponsorId, max_count, applicableFor, discountValue, couponName, discount_type, bulkCount) => {
    const body ={
        "expiredOn": dateKey,
        "userId": userId,
        "verticalId": verticalId,
        "sponsorId": sponsorId,
        "couponCount": max_count,
        "validFor": applicableFor,
        "discountValue": discountValue,
        "couponName": couponName,
        "discountType": discount_type,
        "bulkCount": bulkCount,
    }
    const url = '/new-ops/coupons/create-bulk-coupon';
    const method = 'POST';
    return baseRequest(url, method, null, body);
}


export const downloadCouponData = (couponName) => {
    const params ={
        "couponName": couponName,
    }
    const url = '/new-ops/coupons/download-coupon';
    const method = 'get';
    return baseRequest(url, method, params, null);
}