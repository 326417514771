import baseRequest from './baseRequest';
import config from '../../config';

const baseUrl = config.baseUrl;

export const getUserAndPatientDocOnCallData = (params) => {
  const url = '/new-ops/doc-on-call/doc-on-call-table/user-and-patient';
  const method = 'POST';
  return baseRequest(url, method, {...params, filters: null}, { filters:params.filters });
};

export const getScheduledConsultationsData = (params) => {
  const url = '/new-ops/doc-on-call/scheduled-consultations-table';
  const method = 'POST';
  return baseRequest(url, method, {...params, filters: null}, { filters:params.filters });
}

export const getAllDocOnCallDataCount = (params) => {
  const url = '/new-ops/doc-on-call/get-total-count-for-filter';
  const method = 'GET';
  return baseRequest(url, method, params, null);
};
export const getUserAndPatientOnlineSpecialistData = (params) => {
  const url = '/new-ops/doc-on-call/specialist-consult-table/user-and-patient';
  const method = 'POST';
  return baseRequest(url, method, {...params, filters: null}, { filters:params.filters });
};
export const getUserAndPatientOfflineSpecialistData = (params) => {
  const url = '/new-ops/doc-on-call/offline-sp-table/user-and-patient';
  const method = 'POST';
  return baseRequest(url, method, {...params, filters: null}, { filters:params.filters });
};
export const getDocConsultData = (params) => {
  const url = '/new-ops/doc-on-call/consult-view';
  const method = 'GET';
  return baseRequest(url, method, params, null);
};

export const getUserImg = (params) => {
  if (params) {
    const url = `${baseUrl}/users/${params}/profilePicture`;
    // console.log(url);
    return url;
  }
};

export const initiateCarrierCall = (consultationId) => {
  const url = '/new-ops/consult/call/initiate';
  const method = 'post';
  const data = {
    consultationId: consultationId,
  };
  return baseRequest(url, method, null, data);
};

export const initiateOpsCallScheduleConsult = (scheduledConsultationId) => {
  const url = '/new-ops/consult/schedule/call/initiate-ops-call';
  const method = 'post';
  const data = {
    scheduledConsultationId: scheduledConsultationId,
  };
  console.log(scheduledConsultationId, 'scheduledConsultationId test');
  return baseRequest(url, method, null, data);
};

export const initiateOpsCall = (consultationId) => {
  const url = '/new-ops/consult/call/initiate-ops-call';
  const method = 'post';
  const data = {
    consultationId: consultationId,
  };
  return baseRequest(url, method, null, data);
};

export const shiftConsult = (consultationId, doctorId, shiftReason) => {
  const url = '/new-ops/consult/online/shift';
  const method = 'post';
  const data = {
    consultationId: consultationId,
    doctorId: doctorId,
    shiftReason
  };
  return baseRequest(url, method, null, data);
};

export const addNoteConsult = (consultId, addNote, isScheduledConsult = false) => {
  const url = '/new-ops/doc-on-call/save-consult';
  const method = 'post';
  const data = {
    consultId: consultId,
    addNote: addNote,
    isScheduledConsult
  };
  return baseRequest(url, method, null, data);
};

export const endConsult = (consultId) => {
  const url = '/new-ops/doc-on-call/end-consult';
  const method = 'post';
  const data = {
    consultId: consultId,
  };
  return baseRequest(url, method, null, data);
};

export const doctorPayoutEndpoint = (consultId, payout) => {
  const url = '/new-ops/doc-on-call/process-payout';
  const method = 'POST';
  const data = {
    consultationId: consultId,
    payout,
  };
  return baseRequest(url, method, null, data);
};

export const doctorPayoutInfo = (consultationId) => {
  const url = '/new-ops/doc-on-call/payout-info';
  const method = 'GET';
  const params = {
    consultationId,
  };
  return baseRequest(url, method, params, null);
};

export const cancelConsultation = (consultationId, cancelReason) => {
  const url = '/new-ops/doc-on-call/cancel-consultation';
  const method = 'POST';
  return baseRequest(url, method, null, { consultationId, cancelReason });
};

export const cancelScheduledConsultation = (scheduledConsultationId, cancelReason) => {
  const url = '/opd/consult/schedule-consult/cancel';
  const method = 'POST';
  return baseRequest(url, method, null, {
    scheduledConsultationId,
    cancelReason,
  });
};

export const updateAlternateNumber = (consultationId, phone) => {
  const url = '/new-ops/doc-on-call/alternate-number';
  const method = 'POST';
  return baseRequest(url, method, null, { consultationId, phone });
};

export const updateConsultationNotes = (data) => {
  const url = '/new-ops/doc-on-call/add-consultation-note-list';
  const method = 'POST';
  return baseRequest(url, method, null, { data });
};
