import React, { Component } from 'react';
import NavBar from '../layout/NavBar';
import ReimbursementClaimsRequestsTable from './ReimbursementClaimsRequestsTable';
import { Button } from 'reactstrap';
import CreateClaim from './CreateClaim';

class ReimbursementClaimsRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      focusedInput: null,
      createClaimModalVisible: false,
    };
  }

  onDatesChanged = (startDate, endDate) => {
    console.log(startDate, endDate);
    this.setState({ startDate, endDate });
  };

  createClaim = () => {
    this.setState({
      createClaimModalVisible: true,
    });
  };

  updateModalVisible = modalVisible => {
    this.setState({
      createClaimModalVisible: modalVisible,
    });
  };

  render() {
    const { createClaimModalVisible } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <NavBar />
        </div>
        <div className="row mt-5 pt-4 pb-3">
          <div className="col-8">
            <h2 className="pl-1">Reimbursement Claims</h2>
          </div>
          <div className="col-4 text-right">
            <Button color="primary" outline onClick={this.createClaim}>
              Add a claim
            </Button>
          </div>
        </div>
        <ReimbursementClaimsRequestsTable />
        <CreateClaim
          updateModalVisible={this.updateModalVisible}
          modalVisible={createClaimModalVisible}
        />
      </div>
    );
  }
}

export default ReimbursementClaimsRequests;
