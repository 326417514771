import React from 'react';
import { toast } from 'react-toastify';

import {
  changePrice,
  getReasonsForRefund,
} from '../../services/api/get-pharmacy-orders';

import LoadingComponent from '../common/LoadingComponent';

export default class PharmacyOrderCopayForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      computeLoading: false,
      computed: false,
      reasonId: null,
    };
  }

  componentDidMount() {
    const { cartItemsResult } = this.props;
    const finalItems = [];
    console.log({ cartItemsResult });
    cartItemsResult.forEach((i) => {
      finalItems.push({
        drugId: i.drugId,
        itemName: i.drugName,
        cost: i.cost || 0,
        MRP: i.MRP || 0,
        quantity: i.quantity || 0,
      });
    });
    this.setState({
      items: finalItems || [],
    });
    this.getReasons();
  }

  getReasons = () => {
    return getReasonsForRefund()
      .then((response) => {
        const respBody = response.body;
        const reasons = respBody.result;
        let options = [];
        options = reasons
          .filter((r) => r.type === 'change-pricing')
          .map((r) => {
            return {
              value: r.id,
              label: r.reason,
            };
          });
        options.unshift({
          value: null,
          label: 'Select Reason',
        });
        console.log('\n Reasons getReasonsForRefund', options, reasons);
        this.setState({
          reasons: options,
        });
      })
      .catch((err) => {
        console.log('Error in fetching reasons: ', err);
      });
  };

  handleCostChange = (value, index) => {
    const finalItems = this.state.items;
    finalItems[index].cost = value;
    this.setState({
      items: finalItems,
    });
  };
  handleQuantityChange = (value, index) => {
    const finalItems = this.state.items;
    finalItems[index].quantity = value;
    this.setState({
      items: finalItems,
    });
  };

  handleUpdateOrder = () => {
    const { orderId } = this.props;
    const { items, reasonId } = this.state;
    if (reasonId) {
      this.setState({
        computeLoading: true,
      });
      return changePrice(orderId, reasonId, items)
        .then((response) => {
          if (response.message === 'success') {
            toast.success('Successfully update order');
          } else {
            throw new Error(
              response.errorMessage || 'Error while adding copay to the order'
            );
          }
        })
        .catch((err) => {
          toast.error(err.message);
          console.error(err);
        })
        .finally(() => {
          this.setState({
            computeLoading: false,
          });
        });
    } else {
      toast.info('Please select the reason.');
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Item Name</th>
                  <th>MRP</th>
                  <th>Cost</th>
                  <th>Quantity</th>
                </tr>
              </thead>
              <tbody>
                {this.state.items.map((item, index) => (
                  <tr
                    key={item.drugId}
                    className={item.deleted ? 'text-secondary' : ''}
                  >
                    <td>{item.drugId}</td>
                    <td>{item.itemName}</td>
                    <td>
                      <input disabled={true} value={item.MRP} />
                    </td>
                    <td>
                      <input
                        disabled={item.deleted}
                        value={item.cost}
                        onChange={(e) =>
                          this.handleCostChange(e.target.value, index)
                        }
                      />
                    </td>
                    <td>
                      <input
                        disabled={item.deleted}
                        value={item.quantity}
                        onChange={(e) =>
                          this.handleQuantityChange(e.target.value, index)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col text-right">
              {this.state.computeLoading ? (
                <LoadingComponent />
              ) : (
                <button
                  className="btn btn-primary"
                  onClick={this.handleUpdateOrder}
                >
                  Update Order
                </button>
              )}
            </div>
            <div className="col text-right">
              Reason
              <select
                value={this.state.reasonId}
                onChange={(e) => this.setState({ reasonId: e.target.value })}
              >
                {this.state.reasons && this.state.reasons.length
                  ? this.state.reasons.map((r) => (
                      <option key={r.value} value={r.value}>
                        {r.label}
                      </option>
                    ))
                  : null}
              </select>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
