import React, { Component } from "react";
import {
    ModalHeader,
    ModalBody,
    Modal,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    ListGroup,
    ListGroupItem,
    Collapse,
    CardBody,
    Card,
    Row,
    Col
} from 'reactstrap';
import { sendCommunications, fetchCommunications, fetchPreviewMailer } from '../../../services/api/communications';
import {toast} from "react-toastify";
import Select from 'react-select';
import LoadingComponent from "../../common/LoadingComponent";
import moment from 'moment';

const timeSlots = [
    { value: 13, label: '06:00:00 AM - 06:30:00 AM' },
    { value: 14, label: '06:30:00 AM - 07:00:00 AM' },
    { value: 15, label: '07:00:00 AM - 07:30:00 AM' },
    { value: 16, label: '07:30:00 AM - 08:00:00 AM' },
    { value: 17, label: '08:00:00 AM - 08:30:00 AM' },
    { value: 18, label: '08:30:00 AM - 09:00:00 AM' },
    { value: 19, label: '09:00:00 AM - 09:30:00 AM' },
    { value: 20, label: '09:30:00 AM - 10:00:00 AM' },
    { value: 21, label: '10:00:00 AM - 10:30:00 AM' },
    { value: 22, label: '10:30:00 AM - 11:00:00 AM' },
    { value: 23, label: '11:00:00 AM - 11:30:00 AM' },
    { value: 24, label: '11:30:00 AM - 12:00:00 PM' },
    { value: 25, label: '12:00:00 PM - 12:30:00 PM' },
    { value: 26, label: '12:30:00 PM - 01:00:00 PM' },
    { value: 27, label: '01:00:00 PM - 01:30:00 PM' },
    { value: 28, label: '01:30:00 PM - 02:00:00 PM' },
    { value: 29, label: '02:00:00 PM - 02:30:00 PM' },
    { value: 30, label: '02:30:00 PM - 03:00:00 PM' },
    { value: 31, label: '03:00:00 PM - 03:30:00 PM' },
    { value: 32, label: '03:30:00 PM - 04:00:00 PM' },
    { value: 33, label: '04:00:00 PM - 04:30:00 PM' },
    { value: 34, label: '04:30:00 PM - 05:00:00 PM' },
    { value: 35, label: '05:00:00 PM - 05:30:00 PM' },
    { value: 36, label: '05:30:00 PM - 06:00:00 PM' },
    { value: 37, label: '06:00:00 PM - 06:30:00 PM' },
    { value: 38, label: '06:30:00 PM - 07:00:00 PM' },
    { value: 39, label: '07:00:00 PM - 07:30:00 PM' },
    { value: 40, label: '07:30:00 PM - 08:00:00 PM' },
    { value: 41, label: '08:00:00 PM - 08:30:00 PM' },
    { value: 42, label: '08:30:00 PM - 09:00:00 PM' },
    { value: 43, label: '09:00:00 PM - 09:30:00 PM' },
    { value: 44, label: '09:30:00 PM - 10:00:00 PM' },
    { value: 45, label: '10:00:00 PM - 10:30:00 PM' },
    { value: 46, label: '10:30:00 PM - 11:00:00 PM' },
    { value: 47, label: '11:00:00 PM - 11:30:00 PM' },
];

class CommunicationsSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tooltipOpen: false,
            collapse: false,
            choice: null,
            sentComms: [],
            disableSubmit: false,
            rescheduleDate: null,
            sameDayConfirmationDate: null,
            doctorNumber: null,
            previewMailer: false,
            previewMailerContent: null,
            loadingMailer: false,
            preview: false
        };
        this.toggle = this.toggle.bind(this);
        this.slotSelectRefReschedule = React.createRef();
        this.slotSelectRefSameDay = React.createRef();
    }

    checkSentStatus(choice) {
        if (this.state.sentComms.indexOf(choice) > -1) {
            return <span style={{ color: "red" }}>- Sent</span>;
        }
        return '';
    }

    toggleCommentTooltip(reqId) {
        this.setState({
            tooltipOpen: this.state.tooltipOpen === reqId ? false :reqId,
        });
    }

    componentDidMount() {
        fetchCommunications(this.props.commsData.requestId)
            .then((data) => {
                console.log(data, 'Comms Fetch Data');
                // checking for exiting comms
                if (data.message === 'success' && data.results.length) {
                    const sent = [];
                    data.results.forEach((r) => {
                         sent.push(r.choice);
                    });
                    this.setState({
                        sentComms: sent
                    });
                }
            })
            .catch((err) => {
                console.log(err, 'Fetch Communications');
            });
    }

    toggle() {
        this.setState(state => ({ collapse: !state.collapse }));
    }

    togglePreviewMailer = () => {
        this.setState({
            previewMailer: !this.state.previewMailer,
        })
    }

    submitCommunications = () => {
        // set the button state as disabled
        this.setState({
            disableSubmit: true,
        });
        // comms data
        const data = this.props.commsData;
        const choice = this.state.choice;
        if (!choice) {
            toast.error('Please select a communication category.');
            this.setState({
                disableSubmit: false,
            });
            return;
        }
      
        const rescheduleDate = this.state.rescheduleDate;
        const slotIdReschedule = this.slotSelectRefReschedule.current.state.value ? this.slotSelectRefReschedule.current.state.value.value : false;
        const sameDayConfirmationDate = this.state.sameDayConfirmationDate;
        const slotIdSameDay = this.slotSelectRefSameDay.current.state.value ? this.slotSelectRefSameDay.current.state.value.value : false;
        const doctorNumber = this.state.doctorNumber;

        let payload;
        payload = {
            choice: this.state.choice,
            requestId: data.requestId,
        }
        if (choice === '4') {
            if (!rescheduleDate || !slotIdReschedule) {
                toast.error('Date or Time empty!');
                return;
            }
            payload = {
                choice: this.state.choice,
                requestId: data.requestId,
                rescheduleDate: rescheduleDate,
                slotId: slotIdReschedule
            }

        }
        if (choice === '7') {
            if (!sameDayConfirmationDate || !slotIdSameDay) {
                toast.error('Date or Time empty!');
                return;
            }
            payload = {
                choice: this.state.choice,
                requestId: data.requestId,
                sameDayConfirmationDate: sameDayConfirmationDate,
                slotId: slotIdSameDay
            }
        }
        if (choice === '8') {
            if (!doctorNumber || doctorNumber === '') {
                toast.error('Doctor Number empty');
                return;
            }
            if (!(doctorNumber.length === 10)) {
                toast.error('Invalid Mobile Number. Please Check!');
                return;
            }
            payload = {
                choice: this.state.choice,
                requestId: data.requestId,
                doctorNumber: doctorNumber,
            };
        }
        console.log('payload: ',payload);
        // call the communications API
        sendCommunications(payload)
            .then((response) => {
                console.log(response, '[sendCommunications]');
                if (response.message === 'success') {
                    toast.success(`Communications Request Status - ${response.message}`);
                }else {
                    toast.error(response.message);
                }

                this.toggleParentModal();
            })
            .catch((err) => {
                console.log(err, 'Error in comms');
                toast.error('Error in sending comms');
            })
            .finally(() => {
                this.setState({
                    disableSubmit: false,
                });
            })
    };

    toggleParentModal = () => {
        // reset the message checks
        this.setState({
            collapse: false,
            choice: 1,
        });
        // call the parent method
        this.props.closeModal();
    };

    handleSelect = (e) => {
        const { value } = e.target;
        console.log(value, '[handleSelect]');
        this.setState({
            choice: value,
        });
    };

    handleRadio = (e) => {
        const { value } = e.target;
        console.log(value, '[handleRadio]');
        this.setState({
            choice: value,
        });
    };
    rescheduleDateChangeHandler = (e) => {
        const { value } = e.target;
        console.log('Rescheduling to date: ', value);
        this.setState({
            rescheduleDate: value,
        })
    }
    sameDayDateChangeHandler = (e) => {
        const { value } = e.target;
        console.log('Same day confirmation date ', value);
        this.setState({
            sameDayConfirmationDate: value,
        })
    }
    onDoctorNumberChangeHandler = (e) => {
        const { value } = e.target;
        console.log('Doctor Number: ', value);
        this.setState({
            doctorNumber: value,
        })
    }

    fetchPreviewMailerContent = () => {
        // Disable preview button
        // store it in a state
        this.setState({
            loadingMailer: true,
            preview: true,
        });
        console.log('Fetching Mailer content...');
        const choice = this.state.choice;
        const data = this.props.commsData;
        console.log('choice, data',choice, data);
        if (!choice) {
            toast.error('Please select a category to preview mailer.');
            this.setState({
                loadingMailer: false,
                preview: false,
                previewMailer: false,
            });
            return;
        }
        // if (parseInt(choice) === 1 || parseInt(choice) === 2 || parseInt(choice) === 3) {
        //     toast.error('Preview not set for this category');
        //     this.setState({
        //         loadingMailer: false,
        //         preview: false,
        //         previewMailer: false,
        //     });
        //     return;
        // }
        let rcFormatDate, rescheduleTime, sdFormatDate, sdTime;
        const rescheduleDate = this.state.rescheduleDate;
        const slotIdReschedule = this.slotSelectRefReschedule.current.state.value ? this.slotSelectRefReschedule.current.state.value.value : false;
        if (rescheduleDate && slotIdReschedule) {
            const date = new Date(rescheduleDate);
            rcFormatDate = moment(date).format('dddd D MMMM YYYY');
            rescheduleTime = timeSlots.filter(t => t.value === parseInt(slotIdReschedule))[0].label;
        }

        const sameDayConfirmationDate = this.state.sameDayConfirmationDate;
        const slotIdSameDay = this.slotSelectRefSameDay.current.state.value ? this.slotSelectRefSameDay.current.state.value.value : false;
        if (sameDayConfirmationDate && slotIdSameDay) {
            const sdDate = new Date(sameDayConfirmationDate);
            sdFormatDate = moment(sdDate).format('dddd, D MMMM YYYY,');
            sdTime = timeSlots.filter(t => t.value === parseInt(slotIdSameDay))[0].label;
        }
        const doctorNumber = this.state.doctorNumber;
        if (parseInt(choice) === 4 && (!rescheduleDate || !slotIdReschedule)) {
            // Input missing check
            toast.error('Please provide date & time');
            this.setState({
                loadingMailer: false,
                preview: false,
                previewMailer: false,
            });
            return;
        }
        if (parseInt(choice) === 7 && (!sameDayConfirmationDate || !slotIdSameDay)) {
            toast.error('Please provide date & time');
            this.setState({
                loadingMailer: false,
                preview: false,
                previewMailer: false,
            });
            return;
        }
        if (parseInt(choice) === 8 && (!doctorNumber || !(doctorNumber.length === 10))) {
            toast.error('Invalid doctor number');
            this.setState({
                loadingMailer: false,
                preview: false,
                previewMailer: false,
            });
            return;
        }

        const requestId = data.requestId;
        fetchPreviewMailer(choice, requestId)
            .then((resp) => {
                console.log('resp fetchPreview mailer', resp);
                let content = resp.data.content;
                content = content.replace('reschedule-date', `${rcFormatDate} ${rescheduleTime}`);
                content = content.replace('same-day-date', `${sdFormatDate} ${sdTime}`);
                content = content.replace('doctor-phone-number', `${doctorNumber}`);
                content = content.replace('support-number','011-40845566');

                
                this.setState({
                    previewMailerContent: content
                });
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                this.setState({
                    loadingMailer: false,
                    preview: false,
                })
            })


    }

    render() {
        const commsData = this.props.commsData;
        const mailerContent = this.state.previewMailerContent;
        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.toggleParentModal}
            >
                <ModalHeader
                    className="bg-primary"
                    toggle={this.toggleParentModal}
                >
                    {`Communications (Request Id: ${commsData.requestId})`}
                </ModalHeader>
                <ModalBody>
                    <div className={"row"}>
                        <div className={"col"}>
                            <Form>
                                {/*<FormGroup>*/}
                                {/*    <Label for="selectComms">Select Communications for when the User*/}
                                {/*        is not reachable</Label>*/}
                                {/*    <Input type="select" name="commsType" id="selectComms" onChange={this.handleSelect}>*/}
                                {/*        <option value={"1"}>*/}
                                {/*            1. Doctor Not Available at user timings {this.checkSentStatus(1)}*/}
                                {/*        </option>*/}
                                {/*        <option value={"2"}>*/}
                                {/*            2. Doctor Not Available at all {this.checkSentStatus(2)}*/}
                                {/*        </option>*/}
                                {/*        <option value={"3"}>*/}
                                {/*            3. User not available - Cancel Appointment {this.checkSentStatus(3)}*/}
                                {/*        </option>*/}
                                {/*    </Input>*/}
                                {/*</FormGroup>*/}
                                <FormGroup tag="fieldset">
                                    <h4>Options when User is not reachable</h4>
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="commsType" onClick={this.handleRadio} value={"1"} />{' '}
                                            1. Doctor Not Available at user timings  {this.checkSentStatus(1)}
                                        </Label>
                                    </FormGroup>
                                    <br />
                                 
                                    <FormGroup check>
                                        <Row>
                                            <Col>
                                                <Label check>
                                                    <Input type="radio" name="commsType" onClick={this.handleRadio} value={"4"} />{' '}
                                                    2. Appointment Rescheduled by the Hospital  {this.checkSentStatus(4)}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-5">
                                                <Input type="date" onChange={this.rescheduleDateChangeHandler} value={this.rescheduleDate}/>
                                            </Col>
                                            <Col>
                                                <Select
                                                    ref={this.slotSelectRefReschedule}
                                                    options={timeSlots}
                                                    isSearchable={false}
                                                />
                                            </Col>
                                        </Row>

                                    </FormGroup>
                                    <br /><FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="commsType" onClick={this.handleRadio} value={"5"} />{' '}
                                        3. Payment Issue{this.checkSentStatus(5)}
                                    </Label>
                                </FormGroup>
                                    <br /><FormGroup check>
                                    <Label check>
                                        <Input type="radio" name="commsType" onClick={this.handleRadio} value={"6"} />{' '}
                                        4. Tie Up Issue {this.checkSentStatus(6)}
                                    </Label>
                                </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Row>
                                            <Col>
                                                <Label check>
                                                    <Input type="radio" name="commsType" onClick={this.handleRadio} value={"7"} />{' '}
                                                    5. Same Day Confirmation  {this.checkSentStatus(7)}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="col-5">
                                                <Input type="date" onChange={this.sameDayDateChangeHandler} value={this.sameDayConfirmationDate}/>
                                            </Col>
                                            <Col>
                                                <Select
                                                    ref={this.slotSelectRefSameDay}
                                                    options={timeSlots}
                                                    isSearchable={false}
                                                />
                                            </Col>
                                        </Row>

                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Row>
                                            <Col>
                                                <Label check>
                                                    <Input type="radio" name="commsType" onClick={this.handleRadio} value={"8"} />{' '}
                                                    6. Pre-appointment checkup  {this.checkSentStatus(8)}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Input type="number" placeholder="Enter Doctor's Number" onChange={this.onDoctorNumberChangeHandler} value={this.doctorNumber}/>
                                            </Col>
                                        </Row>

                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="commsType" onClick={this.handleRadio} value={"9"} />{' '}
                                            7. Doctor not Reachable  {this.checkSentStatus(9)}
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="commsType" onClick={this.handleRadio} value={"10"} />{' '}
                                            8. Onboarding Pending  {this.checkSentStatus(10)}
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="commsType" onClick={this.handleRadio} value={"11"} />{' '}
                                            9. Doctor on emergency leave  {this.checkSentStatus(11)}
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="commsType" onClick={this.handleRadio} value={"12"} />{' '}
                                            10. Alternate doctor suggestion  {this.checkSentStatus(12)}
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="commsType" onClick={this.handleRadio} value={"13"} />{' '}
                                            11. Details shared with center  {this.checkSentStatus(13)}
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="commsType" onClick={this.handleRadio} value={"14"} />{' '}
                                            12. Walk in appointment  {this.checkSentStatus(14)}
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input type="radio" name="commsType" onClick={this.handleRadio} value={"15"} />{' '}
                                            13. Co-pay Refund  {this.checkSentStatus(15)}
                                        </Label>
                                    </FormGroup>
                                    <br />
                                </FormGroup>
                            </Form>
                        </div>
                    </div>
                    <br/>
                    <div className={"row"}>
                        <div className={"col-md-4"}>
                            <Button color="info" onClick={this.toggle}>Check Messages</Button>
                        </div>
                        <div className={"col-md-4"}>
                            <Button color="primary" disabled={this.state.disableSubmit} onClick={this.submitCommunications}>Send Message</Button>
                        </div>
                        <div className={"col-md-4"}>
                            <Button disabled={this.state.preview} color="info" onClick={() => {
                                this.togglePreviewMailer();
                                this.fetchPreviewMailerContent();
                            }}>Preview</Button>
                        </div>
                        <br />
                        <Collapse isOpen={this.state.collapse} style={{ paddingTop: '1rem' }}>
                            <Card>
                                <CardBody>
                                    <ListGroup>
                                        <ListGroupItem>1. Hi Aniket, this is an update on your appointment request
                                            for Dr. Mili Joshi on 29th December 6PM. Unfortunately, the doctor is
                                            not available at your preferred time slots. Our support tried contacting
                                            you to help you reschedule your appointment. Please get back to us on
                                            +91 9004900353 so that we can confirm on an available appointment
                                            time.</ListGroupItem>
                                        <ListGroupItem>2. Hi Annu, unfortunately, your appointment with Dr. Tumen
                                            cannot be fulfilled as the doctor is unavailable/unable to take
                                            appointments. We deeply regret the inconvenience caused. Our support
                                            team tried to reach out to you to help you book a consultation with an
                                            alternate doctor. Please reach out to us on +91 9004900353 so that we
                                            can assist you with your booking.</ListGroupItem>
                                        <ListGroupItem>3. Hi Yash, We tried reaching out to you multiple times to
                                            reschedule your consultation since your preferred time slots are
                                            unavailable. Since we were unable to get your appointment rescheduled,
                                            your request has been marked as cancelled. You can go ahead and place
                                            another consultation request from the Visit app.</ListGroupItem>
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Collapse>
                    </div>
                    <br/>
                    {this.state.previewMailer ? (
                        <div className={"row"}>
                            {this.state.loadingMailer ? <LoadingComponent/> : (
                                <div className={"col"}>
                                    <h5>Mailer Content</h5>
                                    <div dangerouslySetInnerHTML={{__html: mailerContent}}></div>
                                </div>
                            )}
                        </div>
                    ) : null}
                </ModalBody>
            </Modal>
        );
    };
}

export default CommunicationsSetup;
