import React, { useState } from 'react';
import Modal from 'react-modal'

import LoadingComponent from '../../common/LoadingComponent';

import { toast } from 'react-toastify';
import { changePriceForRequest, getPricingForRequest } from '../../../services/api/cashless-vaccination';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const EditUserPaymentModal = ({
    closeModal,
    requestId,
    onComplete,
    modalIsOpen
}) => {

    const [pricingData, setPricingData] = useState(null)

    const closeModalSelf = () => {
        setPricingData(null)
        closeModal()
    }

    const afterOpenModal = () => {
        return getPricingForRequest(requestId)
        .then((response) => {
            if (response && response.message === 'success') {
                setPricingData(response.data)
            }
        }).catch(() => {
            setPricingData(null)
        })
    }

    const changePrice = () => {
        return changePriceForRequest(requestId, pricingData)
        .then((response) => {
            if (response && response.message === 'success') {
                return toast.success('Price update successfully')
            }
        })
        .catch(() => {
            toast.error('Error while updating the price')
        })
        .finally(() => {
            closeModal()
            onComplete()
        })
    }

    return <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalSelf}
        style={customStyles}
    >
        {(!pricingData) ? (
            <LoadingComponent color={'black'} />
        ) : (
            <>
                <div className="container-fluid">
                    <div className="row">
                        <h4>User Payments: ({requestId})</h4>
                    </div>
                    <div className="row">
                        <div className='col-6'>
                            Amount Paid:<br />Rs. {pricingData.amountPaid}
                        </div>
                        <div className='col-6'>
                            Vaccine Charges:<br />Rs. {pricingData.vaccineAmount}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            Expected Co-Pay:<br />Rs. {pricingData.copay}
                        </div>
                        <div className="col-6">
                            Amount Pending:<br />Rs. {pricingData.copayRemaining}
                        </div>
                        <div className="col-6">
                            Wallet Deducted:<br />Rs. {pricingData.walletDeduction}
                        </div>
                        <div className="col-6">
                            Wallet to be deducted:<br />Rs. {pricingData.walletDeductionRemaining}
                        </div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-6">
                            <button className="btn btn-primary btn-block" onClick={changePrice}>Recalculate</button>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-secondary btn-block" onClick={closeModal}>Close</button>
                        </div>
                    </div>
                </div>
            </>
        )}
    </Modal>
}

export default EditUserPaymentModal