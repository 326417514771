export const defaultState = {
  requestId: null,
  doctorId: null,
  showEditDoctorSlots: null,
  showRequestDetails: null,
  showInternalComments: null,
  requestData: null
};

export default function offlineConsult(state = defaultState, action) {
  switch (action.type) {
    case 'SET_REQUEST_ID': {
      const requestId = action.payload.requestId;
      return Object.assign({}, state, {
        requestId: requestId,
      });
    }
    case 'UNSET_REQUEST_ID': {
      return Object.assign({}, defaultState);
    }
    case 'SHOW_REQUEST_MODAL': {
      const showRequestDetails = action.payload.showRequestDetails;
      const requestId = action.payload.requestId;
      const requestData = action.payload.requestData;
      return Object.assign({}, state, {
        requestId,
        showRequestDetails,
        requestData
      });
    }

    case 'UPDATE_REQUEST_ID': {
      const requestId = action.payload.requestId;
      return Object.assign({}, state, {
        requestId,
      });
    }

    case 'REQUEST_DETAILS': {
      const requestId = action.payload.requestId;
      const requestData = action.payload.requestData;
      const priceApprovalStatus = action.payload.priceApprovalStatus;
      return Object.assign({}, state, {
        requestId,
        requestData,
        priceApprovalStatus,
      });
    }

    case 'SHOW_DOCTOR_SLOTS_EDIT_MODAL': {
      const showEditDoctorSlots = action.payload.showEditDoctorSlots;
      const doctorId = action.payload.doctorId;
      return Object.assign({}, state, {
        doctorId,
        showEditDoctorSlots,
      });
    }
    case 'SHOW_CONFIRM_SLOTS_MODAL': {
      const requestId = action.payload.requestId;
      const showConfirmModal = action.payload.showConfirmModal;
      return Object.assign({}, state, {
        requestId,
        showConfirmModal,
      });
    }
    case 'SHOW_INTERNAL_COMMENTS':
      const requestId = action.payload.requestId;
      const showInternalComments = action.payload.showInternalComments;
      return Object.assign({}, state, {
        requestId,
        showInternalComments,
      });
    case 'HIDE_INTERNAL_COMMENTS':
      return Object.assign({}, defaultState);
    case 'HIDE_REQUEST_MODAL': {
      return Object.assign({}, defaultState);
    }
    case 'HIDE_DOCTOR_SLOTS_EDIT_MODAL': {
      return Object.assign({}, state, {
        doctorId: null,
        showEditDoctorSlots: null,
      });
    }
    default:
      return state;
  }
}
