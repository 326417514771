import React, { Component } from 'react';
import ResultModal from '../common/modal/ResultModal';
import LoadingComponentAbsolute from '../common/LoadingComponentAbsolute';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { OuterComponent } from './StyledComponent';
import { addPool, editPool } from '../../services/api/redemptions';

class PoolsAddEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showModal: false,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      poolName: props.editData.pool_name || '',
    };
  }
  componentDidUpdate = (prevProps) => {
    console.log(this.props.editData.name);

    if (prevProps.editData.pool_name !== this.props.editData.pool_name) {
      this.setState({
        poolName: this.props.editData.pool_name,
      });
    }
    if (
      prevProps.editPool !== this.props.editPool &&
      this.props.editPool === false
    ) {
      this.setState({
        poolName: '',
      });
    }
  };
  // Add Edit Pool
  addPool = () => {
    const data = {
      poolId: this.props.editData.id,
      poolName: this.state.poolName,
    };
    console.log({ data }, 'addPool');
    if (data.poolName) {
      if (this.props.editPool) {
        editPool(data)
          .then((responseBody) => {
            this.setState({
              showModal: true,
              modalHeader: 'Success',
              modalBody: `Pool Edit.`,
              bgColor: 'bg-success',
              loading: false,
            });
            this.props.togglePage();
          })
          .catch((err) => {
            console.log(err);
            let errorMessage = err.message;
            this.setState({
              showModal: true,
              modalHeader: 'Error',
              modalBody: `${errorMessage}.`,
              bgColor: 'bg-danger',
              loading: false,
            });
          });
      } else {
        addPool(data)
          .then((responseBody) => {
            this.setState({
              showModal: true,
              modalHeader: 'Success',
              modalBody: `Pool added.`,
              bgColor: 'bg-success',
              loading: false,
            });
            this.props.togglePage();
          })
          .catch((err) => {
            console.log(err);
            let errorMessage = err.message;
            this.setState({
              showModal: true,
              modalHeader: 'Error',
              modalBody: `${errorMessage}.`,
              bgColor: 'bg-danger',
              loading: false,
            });
          });
      }
    }
  };

  // Handle Change
  handleChangePoolName = (e) => {
    this.setState({ poolName: e.target.value });
  };

  render() {
    console.log(this.props.editData.pool_name, 'props');
    console.log(this.state.poolName, 'state');

    return (
      <OuterComponent>
        <Card>
          <CardHeader>
            {!this.props.editPool && <strong>Add Pool</strong>}
            {this.props.editPool && <strong>Edit Pool</strong>}
          </CardHeader>
          <CardBody>
            <Form
              action=""
              method="post"
              encType="multipart/form-data"
              className="form-horizontal"
            >
              <FormGroup row>
                <Col md="3">
                  <Label htmlFor="text-input">Pool Name</Label>
                </Col>
                <Col xs="12" md="9">
                  <Input
                    type="text"
                    id="text-input"
                    name="text-input"
                    placeholder=""
                    value={this.state.poolName}
                    onChange={this.handleChangePoolName}
                    required
                  />
                </Col>
              </FormGroup>
            </Form>
          </CardBody>
          <CardFooter>
            <Button onClick={this.addPool}>Confirm</Button>
            <Button style={{ marginLeft: 15 }} onClick={this.props.togglePage}>
              Cancel
            </Button>
          </CardFooter>
        </Card>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        {this.state.loading ? <LoadingComponentAbsolute /> : null}
      </OuterComponent>
    );
  }
}

export default PoolsAddEdit;
