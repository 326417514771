import React from 'react';

import { fetchRequestHistory } from '../../../services/api/get-pharmacy-orders';

import { LoadingContainer, LoadingIcon } from './StyledComponents';

import { HistoryItem, TimeContainer, StyledHistoryContainerForPharmacy } from '../../common/Activities';
import moment from 'moment';

export default class HistoryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            history: [],
        };
    }

    getHistory = (orderId) => {
        return fetchRequestHistory(orderId)
            .then((response) => {
                if (response && response.message === 'success') {
                    return response.data;
                }
                throw new Error('Response Message is not correct');
            })
            .catch((err) => {
                console.error(err, 'Error while fetching data');
            });
    };

    componentDidMount() {
        const { orderId } = this.props;

        this.getHistory(orderId)
            .then((data) => {
                console.log({ data, orderId },
                    'History');
                this.setState({
                    history: data,
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        const { history, loading } = this.state;
        return (
            (loading ? (
                <LoadingContainer>
                    <LoadingIcon></LoadingIcon>
                </LoadingContainer>
            ) : (
                    <StyledHistoryContainerForPharmacy>
                        {(history && history.length > 0) ? (history.map(item => (
                            <HistoryItem 
                                key={item.time}
                            >
                                {item.actionMsg}
                                <TimeContainer>
                                    {moment(item.time).format('YYYY-MM-DD hh:mm A')}
                                </TimeContainer>
                            </HistoryItem>
                        ))) : (<HistoryItem>No Activities Here</HistoryItem>)}
                    </StyledHistoryContainerForPharmacy>
                )
            )
        );
    }
}
