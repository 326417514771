import React from 'react';
import { Button } from 'reactstrap';
import FileSaver from 'file-saver';
import jsonToCsv from '../../utils/jsonToCsv';
import {getAllOfflineRequests} from '../../services/api/offline-consult'

export default class DownloadCsv extends React.Component {

    handleClick = () => {
        if(this.props.isCashless){
          this.props.callback().then(response => {
            const jsonArray = response.result
            const csv = jsonToCsv(jsonArray);

            // console.log(csv);
            // Download this csv somehow
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(blob, this.props.fileName || 'cashless_vaccination.csv');
          })
        } else if (this.props.isCashlessDental) {
          this.props.callback().then(response => {
            console.log({response}, '[callback]')
            const jsonArray = response.data.rows
            const csv = jsonToCsv(jsonArray);

            // console.log(csv);
            // Download this csv somehow
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(blob, this.props.fileName || 'cashless_dental.csv');
          })
        }
       else if(this.props.jsonArray){   
          const csv = jsonToCsv(this.props.jsonArray);    
          // console.log(csv);     // Download this csv somehow     
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' }); 
          FileSaver.saveAs(blob, this.props.fileName || 'exports.csv');    }    
        else{
          //const { jsonArray, fileName } = this.props;
          return getAllOfflineRequests(this.props.params).then((jsonArray) => {
            console.log(jsonArray, 'json response from backend');
            if (!jsonArray) {
              return;
            }
            const csv = jsonToCsv(jsonArray.result);

            // console.log(csv);
            // Download this csv somehow
            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
            FileSaver.saveAs(blob, 'offline request' || 'exports.csv');
          });
        }
    };

    render() {
        return (
            <Button onClick={this.handleClick}>
                CSV
            </Button>
        );
    }
}
