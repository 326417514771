import React from 'react';

import { fetchOpsUsers } from '../../../services/api/offline-consult';

export default class ClaimedByFilter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
        };
    }

    componentDidMount() {
        fetchOpsUsers()
            .then((response) => {
                this.setState({
                    users: response,
                });
            })
            .catch((err) => {
                console.error({ err },
                    'Couldnt fetch OPS Users');
                throw err;
            });
    }

    onChange = (e) => {
        this.props.onChange(e.target.value);
    };

    render() {
        const { users } = this.state;
        console.log({ users },
            'State');
        return (
            <select onChange={this.onChange} value={this.props.value}>
                <option value="">All</option>
                {users && users.map((u) => {
                    return (<option value={u.opsUserId}>{u.opsUserName}</option>);
                })}
            </select>
        );
    }
}
