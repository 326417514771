import React, { Component } from 'react';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import ReactTable from 'react-table';
import ResultModal from '../common/modal/ResultModal';
import {
  getOpdHolder,
  getMemberDetails,
  onBoardSpecificMember,
  getPolicy
} from '../../services/api/opd-management';
import { Button,ModalHeader,ModalBody } from 'reactstrap';
import UpdateOpdMembersModal from './UpdateOpdMembersModal';
import Modal from 'reactstrap/lib/Modal';
import styled from 'styled-components';
import LoadingComponent from '../common/LoadingComponent';
import { debounce } from '../../utils/debounce';
import {FaSearch} from 'react-icons/fa'
import Link from 'valuelink';
import DownloadCsv from '../common/DownloadCsv';
import {ButtonGroup} from 'reactstrap'
import Select from 'react-select';
import {getSponsorList} from '../../services/api/reimburse-claims'
import { DateRangePicker } from 'react-dates';


const Input = styled.input`
padding:5px;
outline-color: #B3B3B3;
outline-offset: -2px;
outline-width: 5px;
margin-left :12px;
`;

const SearchBox = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
`;

const SerachBoxInput = styled(Input)`
  border: 0;
  flex: 2 0 0;
  margin: 1px;
  &:focus {
    outline: none;
  }
`;

const SearchBoxContent = styled.div`
  display: inline-block;
  flex: 1 0 0;
  margin: 1px;
  text-align: right;
`;
class OpdHolderTable extends Component {
  constructor(props) {
    super(props);
    this.debounceSearch = debounce((val) => this.fetchTableData(), 300);
    this.state = {
      loading: false,
      showModal: false,
      showUpdateOpdModal: false,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      data: [],
      pageSize: 10,
      pages: 0,
      filtered: [],
      details: [],
      opsUserOnboardModal:false,
      opsUserOnboardModalFail:false,
      search: '',
      policy:[],
      totalHits: 0,
      totalData: [],
      totalDataFetched: false,
      policySelected:'',
      deonboarded:'',
      tableColumns: [
        {
          accessor: 'MASTER_POLICY_NO',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'MASTER_POLICY_NO',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'EMPLOYEE_ID',
          sortable: true,
          numeric: false,
          disablePadding: false,
          Header: 'EMPLOYEE_ID',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'CERTIFICATE_NUMBER',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'CERTIFICATE_NUMBER',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'opd_holder_id',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'OPD Holder Id',
          style: { whiteSpace: 'unset' },
          filterable: false,
          width: 50,
          Filter: ReactTableInputFilter,
        },
        {
          accessor: 'dependent_id',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'Dependent Id',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'NAME',
          numeric: true,
          disablePadding: false,
          sortable: true,
          Header: 'NAME',
          style: { whiteSpace: 'unset' },
          filterable: false,
          width: 50,
          Filter: ReactTableInputFilter,
        },
        {
          accessor: 'DOB',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'DOB',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'GENDER',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'GENDER',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'RELATIONSHIP',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'RELATIONSHIP',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'EMAIL',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'EMAIL',
          style: { whiteSpace: 'unset' },
          filterable: false,
          width: 50,
          Filter: ReactTableInputFilter,
        },
        {
          accessor: 'ADDRESS',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'ADDRESS',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'STATE',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'STATE',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'PHONE',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'PHONE',
          style: { whiteSpace: 'unset' },
          filterable: false,
          width: 50,
          Filter: ReactTableInputFilter,
        },
        {
          accessor: 'COVER_START_DATE',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'COVER_START_DATE',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'COVER_END_DATE',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'COVER_END_DATE',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'onboarded_on',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'Onboarded On',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'deboarded_on',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'De-onboarded On',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'error_message',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'Error Message',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'PREMIUM',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'PREMIUM',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'EMPLOYER',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'EMPLOYER',
          style: { whiteSpace: 'unset' },
          filterable: false,
          width: 50,
          Filter: ReactTableInputFilter,
        },
        {
          accessor: 'UPDATE',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'UPDATE',
          style: { whiteSpace: 'unset' },
          Cell: (row) => {
            // console.log(row.original.error_message);

            if (row.original.error_message) {
              return (
                <div>
                  <Button color="danger" disabled>
                    UPDATE
                  </Button>
                </div>
              );
            } else {
              return (
                <div>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.UpdateOpdMembers(row);
                    }}
                  >
                    UPDATE
                  </Button>
                </div>
              );
            }
          },
        },
        {
          accessor: 'Onboard User',
          numeric: false,
          disablePadding: false,
          sortable: true,
          Header: 'Onboard User',
          style: { whiteSpace: 'unset' },
          Cell: (row) => {
            if(row.original.onboarded_on === null){
              return(
                <div>
                  <Button
                    color="primary"
                    onClick={() => {
                      this.findUserByMemberID(row.original.id);
                    }}
                  >
                    Onboard User
                  </Button>
                </div>
              )
            }else{
               return <></>
            }
          }
        }
      ],
    };
  }

  componentDidMount(){
    this.getPolicies()
  }

  componentDidUpdate() {
    if (this.state.totalHits && !this.state.totalDataFetched) {
      this.setState({
        totalDataFetched: true
      }, this.allOpdData)
    }
  }

  findUserByMemberID = (id) => {
    // const userMemberId = this.state.memberId;
     if(id){
       return onBoardSpecificMember(id)
         .then((response) =>{
           console.log(response,'[response]')
           if(response.message==="success"){
             this.setState({
              opsUserOnboardModal:true
             })
           }else{
             this.setState({
              opsUserOnboardModalFail:true
             })
           }
         })
         .catch((err)=>{
           console.log(err);
           let errorMessage = err.message;
         })
     }
   }

   closeUserOnboardModal = () => {
    this.setState({
      opsUserOnboardModal:false
    })
  }

  UpdateOpdMembers = (row) => {
    // console.log({ row }, '[UpdateOpdMembers]');
    const params = {
      opdHolderId: row.original.opd_holder_id,
      dependentId: row.original.dependent_id,
    };
    getMemberDetails(params)
      .then((resps) => {
        console.log({ resps }, 'getMemberDetails');
        if (resps.memberDetails.length > 0) {
          this.setState({
            details: resps.memberDetails[0],
            showUpdateOpdModal: true,
          });
        } else {
          const err = new Error('Cannot find any member');
          throw err;
        }
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };
  // Fetch Table Data
  onFetchData = (state) => {
    let orderBy, orderDir;
    if (state.sorted.length > 0) {
      orderBy = state.sorted[0].id;
      orderDir = state.sorted[0].desc ? 'desc' : 'asc';
    } else {
      orderBy = 'created_at';
      orderDir = 'DESC';
    }
    this.setState(
      {
        pageSize: state.pageSize,
        page: state.page,
        orderBy,
        orderDir,
      },
      this.fetchTableData
    );
  };

  // getSponsors = () => {
  //   return getSponsorList()
  //     .then((response)=>{
  //       if(response.length){
  //         this.setState({
  //           sponsor:(response)
  //         })
  //       }
  //     }).catch((err)=>{
  //       console.log(err)
  //     })
  // }

  getPolicies = () => {
    return getPolicy()
      .then((response)=>{
        console.log(response.data,'[policy response]')
        if(response.data.length){
          this.setState({
            policy:response.data
          })
        }
      }).catch((err)=>{
          console.log(err)
        })
  }

  allOpdData = () =>{
    const params = {
      rowsPerPage: this.state.totalHits
    }

    getOpdHolder(params)
    .then((response) => {
      this.setState({
        totalData: response.data
      })
    })
  }

  fetchTableData = (e) => {
    console.log(e)
    this.setState({
      loading: true,
    });
    // console.log(this.state.filtered.length, this.state.filtered[0]);

    const params = {
      rowsPerPage: this.state.pageSize,
      page: this.state.page,
      orderBy: this.state.orderBy,
      orderDir: this.state.orderDir,
      search:this.state.search,
      // sponsorName:e,
      deonboarded:this.state.deonboarded,
      policy:e
    };
    if (this.state.filtered.length !== 0) {
      this.state.filtered.forEach((element) => {
        // console.log(element);
        if (element.id === 'NAME') params.name = element.value;
        if (element.id === 'PHONE') params.phone = element.value;
        if (element.id === 'EMPLOYER') params.sponsorName = element.value;
        if (element.id === 'opd_holder_id') params.opdHolderId = element.value;
        if (element.id === 'EMAIL') params.email = element.value;
      });
    }
    // console.log(params);

    getOpdHolder(params)
      .then((responseBody) => {
        console.log(responseBody.data,'[responseBody.data]')
        this.setState({
          data: responseBody.data,
          loading: false,
          pages: Math.ceil(responseBody.totalHits / this.state.pageSize),
          totalHits:responseBody.totalHits || 0,
        });
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };
  onFilteredChange = (e) => {
    // console.log(e);
    this.setState({ filtered: e });
  };

  closeModal = () => {
    this.setState({ showUpdateOpdModal: false });
    this.fetchTableData();
  };

  onSearchClick = () => {
    const { search, loading } = this.state;
    if (loading) {
      return;
    }
    this.debounceSearch(search);
  };

  clearFilters = () => {
    this.setState({
      search:'',
      policySelected:'',
      deonboarded:''
    },
    this.fetchTableData
    )
  }

  handleChangeSponsor = (e) => {
    console.log(e,'[e]')
    this.setState({
      policySelected:e.value
    })
    this.fetchTableData(e.value)
  }

  renderList = () => {
    return (this.state.policy.map((data,i) =>({label:`${data.name} ${
      data.master_policy_number ? `(${data.master_policy_number})` : ''
    }`,value:data.master_policy_number,})))
  }
  render() {
    const searchLink = Link.state(this, 'search');
    return (
      <div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-2">
            <SearchBox>
              <SerachBoxInput
                // valueLink={searchLink}
                valueLink={this.state.search}
                type="text"
                placeholder="Search..."
                onChange={(e) => {
                  this.setState({ search: e.target.value });
                }}
                onKeyDown={(e) => {
                  return e.key === 'Enter' ? this.onSearchClick() : null;
                }}
              />
              {/* <SearchBoxContent>{this.state.totalHits} records</SearchBoxContent> */}
            </SearchBox>
          </div>
          <div className="col-2">
            {this.state.loading ? (
              <LoadingComponent />
            ) : (
              <button onClick={this.onSearchClick} className="btn btn-primary">
                <FaSearch />
              </button>
            )}
          </div>
          <div className="col-2">
          <Select
            value={this.state.policySelected}
            Clearable={true}
            placeholder='Select Policies'
            onChange={this.handleChangeSponsor}
            options={this.renderList()}
          />
          </div>
          <div className='col-2'>
            <Button onClick={async()=>{
              await this.setState({deonboarded:'desc'})
              await this.fetchTableData()
              }}>De-Onboarded</Button>
          </div>
          <div className="col-2">
            <ButtonGroup size={'sm'}>
              <DownloadCsv jsonArray={this.state.totalData} />
            </ButtonGroup>
          </div>
          <div className='col-2'>
              <Button onClick={this.clearFilters} >Clear Filters</Button>
          </div>
        </div>
        <ReactTable
          data={this.state.data}
          columns={this.state.tableColumns}
          loading={this.state.loading}
          manual
          onFetchData={this.onFetchData}
          onFilteredChange={this.onFilteredChange}
          showPaginationTop={true}
          className="-striped -highlight"
          pages={this.state.pages}
        />
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        {this.state.showUpdateOpdModal && (
          <UpdateOpdMembersModal
            closeModal={this.closeModal}
            bgColor={this.state.bgColor}
            details={this.state.details}
          />
        )}
        {this.state.opsUserOnboardModal && (
          <Modal isOpen={this.state.opsUserOnboardModal}>
            <ModalHeader
              style={{ color: 'black' }}
              toggle={this.closeUserOnboardModal}
            ></ModalHeader>
            <ModalBody style={{ textAlign: 'center' }}>
              User Onboard Successfully
            </ModalBody>
          </Modal>
        )}
        {this.state.opsUserOnboardModalFail && (
          <Modal isOpen={this.state.opsUserOnboardModalFail}>
            {/* <ModalHeader style={{ color: "black" }} toggle={this.closeUserOnboardModal}></ModalHeader> */}
            <ModalBody style={{ textAlign: 'center' }}>
              Something Went wrong
            </ModalBody>
          </Modal>
        )}
      </div>
    );
  }
}

export default OpdHolderTable;
