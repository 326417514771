import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';

export default class ConfirmModal extends React.Component {
  submitModal() {
    const { onSubmit } = this.props;
    this.setState({ modal: false }, onSubmit);
  }

  render() {
    const className = 'bg-info';
    const {
      modalHeader,
      children,
      onSubmit,
      isOpen,
      onClose,
      loading,
    } = this.props;
    return (
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader className={className} toggle={onClose}>
          {modalHeader}
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit} disabled={loading}>
            {loading ? 'Loading...' : 'Confirm'}
          </Button>
          {!loading && (
            <Button color="secondary" onClick={onClose} disabled={loading}>
              Cancel
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
