import React, { useState } from 'react';

const AckFilter = ({ onChange }) => {
  const [value, setValue] = useState('');

  const handleChange = (event) => {
    const selectedValue = event.target.value === '' ? null : event.target.value;
    setValue(selectedValue);
    onChange(selectedValue);
  };

  return (
    <select
      onChange={handleChange}
      style={{
        width: '100%',
        height: '40px',
        fontSize: '12px',
        borderRadius: '10px',
      }}
      value={value}
    >
      <option value="">All</option>
      <option value="pending">Pending</option>
      <option value="noAnswer">No Answer</option>
      <option value="completed">Yes</option>
    </select>
  );
};

export default AckFilter;
