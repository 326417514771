import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from "../layout/NavBar";
import LabsReconcileTable from './LabsReconcileTable';
import LabsConflictingOrders from './LabsConflictingOrders';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { Collapse, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { MdInfo } from "react-icons/md";
import TestCostsUpdates from './TestCostsUpdates';
import MissingLabTransactions from './MissingLabTransactions';
class LabsReconcileHome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            startDate: moment().startOf('month'),
            endDate: moment().endOf('month'),
            focusedInput: null,
            summary: {},
            toggleSummarySection: false,
            infoPopovers: {
                reconcile: false,
                conflictingOrders: false,
                missingVisitTransactions: false,
            },
        };
    }

    onDatesChanged = (startDate, endDate) => {
        console.log(startDate, endDate);
        this.setState({ startDate, endDate });
    };

    setPartnerTransactionsSummary = (summary) => {
        this.setState({
            summary,
        });
    };

    togglePopover = (popover) => {
        this.setState({
            infoPopovers: popover,
        });
    };

    toggleSummarySection = () => {
        this.setState({
            toggleSummarySection: !this.state.toggleSummarySection,
        });
    };
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <NavBar />
                </div>
                <div className="row mt-5 pt-1">
                    <div className="col">
                        <h2 className="pl-1">Labs Reconcillation</h2>
                    </div>
                    <div className="col-4">
                        <DateRangePicker
                            displayFormat={() => "DD/MM/YYYY"}
                            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                            isOutsideRange={() => false}
                            onDatesChange={({ startDate, endDate }) => this.onDatesChanged(startDate, endDate)} // PropTypes.func.isRequired,
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <table className="table table-dark">
                            <thead>
                            <tr>
                                <th scope="col">Metric</th>
                                <th scope="col">Value</th>
                                <th scope="col">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>Orders given by Partner</td>
                                <td>{this.state.summary.totalOrders}</td>
                            </tr>
                            <tr>
                                <td>Orders found in Visit</td>
                                <td>{this.state.summary.totalOrdersInVisit}</td>
                                <td>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <MissingLabTransactions/>
                                        <MdInfo id="popoverMissingTransactions"/>
                                        <Popover trigger="focus" placement="bottom" isOpen={this.state.infoPopovers.missingVisitTransactions} target="popoverMissingTransactions" toggle={() => this.togglePopover({missingVisitTransactions: !this.state.infoPopovers.missingVisitTransactions})}>
                                            <PopoverBody>You can check what these transactions are and (a) Ask Labs team to create these back dated orders or (b) pay them under Visit Invoice for employee benefits or special client expenses</PopoverBody>
                                        </Popover>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Tests given by partner</td>
                                <td>{this.state.summary.totalTests}</td>
                            </tr>
                            <tr>
                                <td scope="row">Tests found in Visit</td>
                                <td>{this.state.summary.totalTestsInVisit}</td>
                                <td>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <TestCostsUpdates/>
                                        <MdInfo id="popoverReconcile"/>
                                        <Popover trigger="focus" placement="bottom" isOpen={this.state.infoPopovers.reconcile} target="popoverReconcile" toggle={() => this.togglePopover({reconcile: !this.state.infoPopovers.reconcile})}>
                                            <PopoverBody>This will check the difference for each test which is available in our system against the list that partner has sent and will update the cost of each test. MRP cannot be changed as COpay has already been decided when copay was collected</PopoverBody>
                                        </Popover>
                                    </div>

                                </td>
                            </tr>
                            <tr>
                                <td>Conflicting orders<MdInfo id="conflictingOrders"/><Popover trigger="focus" placement="bottom" isOpen={this.state.infoPopovers.conflictingOrders} target="conflictingOrders" toggle={() => this.togglePopover({conflictingOrders: !this.state.infoPopovers.conflictingOrders})}>
                                    <PopoverBody>Orders which have different number of tests compared to the partner ie an order which has atleast one test extra, or has atleast one test less</PopoverBody>
                                </Popover></td>
                                <td>N/A</td>
                                <td><LabsConflictingOrders/></td>
                            </tr>

                            <tr>
                                <td>Net difference in estimated cost</td>
                                <td>TBD</td>
                            </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
                <LabsReconcileTable setPartnerTransactionsSummary={this.setPartnerTransactionsSummary} startDate={this.state.startDate} endDate={this.state.endDate}/>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(LabsReconcileHome);





