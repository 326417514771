import React, { Component } from 'react';
import styled from 'styled-components';
import NavBar from '../layout/NavBar';
import { connect } from 'react-redux';
import { FaSistrix } from 'react-icons/fa';
import UserPortal from './UserPortal';
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import {
  OuterComponent,
  PaddedContainer,
  SearchBox,
  SearchIcon,
  SearchContainer,
  BodyContainer,
} from './StyledComponent';
import { fetchUserDetail } from '../../services/api/customer-support';
import { setUserDetails, showBody } from '../../actions';
import loginCheck from '../login/LoginCheck';
import LegendContent from './LegendContent';

const EmptyContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const EmptyText = styled.h2`
  font-size: 16;
  text-align: center;
`;

class CustomerSupport extends Component {
  constructor(props) {
    super(props);
    // console.log('pro', props);
    this.state = {
      showBody: false,
      searchBoxData: '',
      userDetail: [],
      emptyText: '',
      legendOpen: false,
    };

    this.toggleLegend = this.toggleLegend.bind(this);
    const { location } = props;
    console.log(location, '[Current location]');
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      console.log(searchParams, '[Search params]');
      if (searchParams.get('q')) {
        this.state.searchBoxData = searchParams.get('q');
      }
    }
  }

  componentDidMount() {
    const { searchBoxData } = this.state;
    if (searchBoxData) {
      this.searchUser();
    }
  }

  toggleLegend() {
    this.setState({
      legendOpen: !this.state.legendOpen,
    });
  }

  searchUser = (e) => {
    e && e.preventDefault();
    // checking for empty search
    if (this.state.searchBoxData === '') {
      console.log(this.state.searchBoxData, 'Empty Search');
      return;
    }
    const params = {
      q: this.state.searchBoxData,
    };
    return fetchUserDetail(params)
      .then((resp) => {
        console.log(resp.result, 'searchUser');
        if (resp.result) {
          this.props.dispatch(setUserDetails(resp.result));
          this.props.dispatch(showBody(true));
        } else {
          this.props.dispatch(showBody(false));
          this.setState({
            showBody: false,
            emptyText:
              'No records found for this search. Kindly search with different keywords.',
          });
        }
        // this.setState({ userDetail: resp.result, showBody: true });
      })
      .catch((err) => {
        console.log(err, '[searchUser]');
      });
  };

  getUpdatedDetails = () => {
    const { userDetail } = this.props;
    const params = {
      q: userDetail[0].phone,
    };
    return fetchUserDetail(params)
      .then((resp) => {
        if (resp.result) {
          this.props.dispatch(setUserDetails(resp.result));
          this.props.dispatch(showBody(true));
        } else {
          this.props.dispatch(showBody(false));
          this.setState({
            showBody: false,
            emptyText:
              'No records found for this search. Kindly search with different keywords.',
          });
        }
        // this.setState({ userDetail: resp.result, showBody: true });
      })
      .catch((err) => {
        console.log(err, '[searchUser]');
      });
  };

  HandleChangeSearchBox = (e) => {
    this.setState({ searchBoxData: e.target.value });
  };

  render() {
    return (
      <OuterComponent>
        <NavBar />
        <PaddedContainer>
          <Row>
            <SearchContainer>
              <Col md={{ size: 11 }}>
                <form onSubmit={this.searchUser}>
                  <Row>
                    <Col md={{ size: 5, offset: 3 }}>
                      <SearchBox
                        type="text"
                        placeholder="Search User by phone, email and name"
                        onChange={this.HandleChangeSearchBox}
                      />
                    </Col>
                    <Col md={{ size: 1 }}>
                      {/*<button type={"submit"} style={{ background: 'transparent', border: 0 }}>*/}
                      {/*  */}
                      {/*</button>*/}
                      <SearchIcon color="primary" type={'submit'}>
                        <FaSistrix />
                      </SearchIcon>
                    </Col>
                  </Row>
                </form>
              </Col>
            </SearchContainer>
            <Col md={{ size: 1 }}>
              <Modal
                isOpen={this.state.legendOpen}
                toggle={this.toggleLegend}
                size="lg"
                className="rtsp-modal"
              >
                <ModalHeader className="bg-primary" toggle={this.toggleLegend}>
                  {'Support Catalog'}
                </ModalHeader>
                <ModalBody>
                  <LegendContent />
                </ModalBody>
              </Modal>
              <div>
                <Button
                  id="offline-legend"
                  type="button"
                  onClick={this.toggleLegend}
                >
                  Legend
                </Button>
              </div>
            </Col>
          </Row>
        </PaddedContainer>
        <BodyContainer>
          {this.props.showBody ? (
            <UserPortal
              userDetail={this.props.userDetail}
              getUpdatedDetails={this.getUpdatedDetails}
            />
          ) : (
            <EmptyContainer>
              <EmptyText>{this.state.emptyText}</EmptyText>
            </EmptyContainer>
          )}
        </BodyContainer>
      </OuterComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.customerSupport.userDetail,
  showBody: state.customerSupport.showBody,
});

export default connect(mapStateToProps)(loginCheck(CustomerSupport));
