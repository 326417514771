import React, { useEffect, useState } from 'react';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Container,
} from 'reactstrap';
  
import { toast } from 'react-toastify';

const VaccinationModalCancel = ({
    requestId,
    submitModal,
    closeModal,
    onComplete,
    modalIsOpen,
    className
}) => {

    const [value, setValue] = useState('')
    const [remarks, setRemarks] = useState('')

    useEffect(() => {
        setValue('')
        setRemarks('')
    }, [requestId])

    const handleChange = (event) => {
        const { value } = event.target
        setValue(value)
        setRemarks('')
    }

    const handleRemarksInputChange = (event) => {
        setRemarks(event.target.value)
    }

    const handleSubmit = () => {
        if(!value || !remarks) {
            return toast.error('No value/remark selected')
        }

        return submitModal(value, remarks)
        .then(() => {
            closeModal()
            onComplete()
        })
    }

    const getRemarksSelectOptions = () => {
        switch (value) {
            case "Doctor Unavailable": {
                return [ "",  "Profiling Error", "Doctor On Leave", "All slots booked", "Doctor went for emergency/IPD", "Corona", "Doctor Unavailable at requested location" ];
            }
            case "Doctor Couldn’t get onboarded": {
                return [ "", "doctor didn’t respond to our calls", "Denied 3rd party payment" ];
            }
            default:
                return [];
        }
    }

    return <Modal
        isOpen={modalIsOpen}
        toggle={closeModal}
        className={className}
    >
        <ModalHeader className="bg-primary" toggle={closeModal}>
            Sure you want to cancel? (Request Id: {requestId})
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row>
                    <Col>
                        <Row>
                            <label>Reason for cancellation:</label>
                        </Row>
                        <br />
                        <Row>
                            <div>
                                <select onChange={handleChange} value={value}>
                                <option value="">Select Reason</option>
                                <option value="Stock unavailable">Stock unavailable</option>
                                <option value="Member already vaccinated">Member Already Vaccinated</option>
                                <option value="User Requested">User Requested</option>
                                <option value="User want to book for another date">User want to book for another date</option>
                                <option value="Location not serviceable">Location not serviceable</option>
                                <option value="User not comfortable with booked slot">User not comfortable with booked slot</option>
                                <option value="Appointment cancelled at hospital end">Appointment cancelled at hospital end</option>
                                <option value="User not available">User not available</option>
                                <option value="Not covered Under Policy">Not covered Under Policy</option>
                                <option value="Visit Demo">Visit Demo</option>
                                </select>
                            </div>
                        </Row>
                        <br />
                        <Row>
                            <div>
                                <React.Fragment>
                                {(getRemarksSelectOptions() && getRemarksSelectOptions().length) ? (
                                    <select value={remarks} onChange={handleRemarksInputChange}>
                                        {getRemarksSelectOptions().map((option) => (<option value={option}>{option || "Select Remark"}</option>))}
                                    </select>
                                ) : (
                                    <input onChange={handleRemarksInputChange} value={remarks} />
                                )}
                                </React.Fragment>
                            </div>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter>
        <Button color="info" onClick={handleSubmit}>
            Cancel
        </Button>
        </ModalFooter>
    </Modal>
}

export default VaccinationModalCancel