import React from 'react';
import styled from 'styled-components'
import { Text } from '../../common/Text';

const RegularText = styled.span`
    font-family: BrandonText-Regular, Lato, sans-serif;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled(Text)`
  font-size: 1rem;
  width: ${props => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;

const DetailText = styled(RegularText)`
  flex: 1;
  font-size: 1rem;
  color: ${props => props.color || '#787778'};
  margin-left: 0.25rem;
`;

const DetailTextDanger = styled(RegularText)`
  font-size: 1rem;
  color: #ff4d48;
`;

const RequestRow = ({
    name, value, jsx, altText = '',
    green = false
}) => {

    return <DetailRow>
        <DetailName>{name}</DetailName>
        <DetailText style={green ? { color: '#28A745' } : {}}>
        {value ? (jsx || value) : (
            <DetailTextDanger>{altText || 'Unavailable'}</DetailTextDanger>
        )}
        </DetailText>
    </DetailRow>
}

export default RequestRow