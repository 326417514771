import React from "react";

const allOptions = () => {
    return (
        <React.Fragment>
            <option value="">All</option>
            <option value="pending">Apt. Pending</option>
            <option value="paymentPending">Payment Pending</option>
            <option value="appointmentConfirmed">Confirmed</option>
            <option value="appointmentConfirmedByUser">Confirmed by User</option>
            <option value="prescriptionPending">Rx Pending</option>
            <option value="prescriptionUploaded">Rx Uploaded</option>
            <option value="reconfirmationAwaited">Reconfirmation Awaited</option>
            <option value="unclaimed">Unclaimed</option>
            <option value="prescriptionRejected">Rx Rejected</option>
            <option value="prescriptionApproved">Rx Approved</option>
            <option value="userCanceled">User Canceled</option>
            <option value="noShow">No Show</option>
            <option value="pac">PAC</option>
            <option value="canceled">Cancelled</option>
            <option value="rescheduled_call_user">Call User</option>
            <option value="rescheduled_app">Appt. Rescheduled</option>
            <option value="rescheduled_escalated">User Escalated</option>
            <option value="rescheduled_confirm_escalated">Confirmed and Escalated</option>
            <option value="rescheduled_cancel_escalated">Cancelled and Escalated</option>
        </React.Fragment>
    );
};

const pendingOptions = () => {
    return (
        <React.Fragment>
            <option value="">All Pending</option>
            <option value="pending">Apt. Pending</option>
            <option value="paymentPending">Payment Pending</option>
            <option value="noShow">No Show</option>
            <option value="pac">PAC</option>
            <option value="unclaimed">Unclaimed</option>
            <option value="rescheduled_call_user">Call User</option>
            <option value="rescheduled_app">Appt. Rescheduled</option>
        </React.Fragment>
    );
};

const confirmedOptions = () => {
    return (
        <React.Fragment>
            <option value="">All Confirmed</option>
            <option value="appointmentConfirmed">Confirmed</option>
            <option value="appointmentConfirmedByUser">Confirmed by User</option>
            <option value="prescriptionPending">Rx Pending</option>
            <option value="prescriptionUploaded">Rx Uploaded</option>
            <option value="reconfirmationAwaited">Reconfirmation Awaited</option>
            <option value="unclaimed">Unclaimed</option>
            <option value="prescriptionRejected">Rx Rejected</option>
            <option value="prescriptionApproved">Rx Approved</option>
            <option value="noShow">No Show</option>
            <option value="pac">PAC</option>
            <option value="rescheduled_confirm_escalated">Confirmed and Escalated</option>
        </React.Fragment>
    );
};

const cancelledOptions = () => {
    return (
        <React.Fragment>
            <option value="">All Cancelled</option>
            <option value="userCanceled">User Canceled</option>
            <option value="canceled">Cancelled</option>
            <option value="noShow">No Show</option>
            <option value="pac">PAC</option>
            <option value="rescheduled_cancel_escalated">Cancelled and Escalated</option>
        </React.Fragment>
    );
};

export default class StatusFilter extends React.Component {

    changeFilterType = (event) => {
        const value = event.target.value === "" ? null : event.target.value;
        console.log({ value }, 'Filter Inner Component');
        this.props.onChangeValue(value);
    };

    getOptions = (seg) => {
        console.log({ seg }, 'Getting options for Header Status Filter');
        if (seg === 0) {
            return pendingOptions();
        } else if (seg === 1) {
            return confirmedOptions();
        } else if (seg === -1) {
            return cancelledOptions();
        }
        return allOptions();
    };

    render() {
        const { value, segregation } = this.props;
        return (
            <select
                onChange={this.changeFilterType}
                style={{
                    width: "100%",
                    height: "40px",
                    fontSize: "12px"
                }}
                value={value || ''}
            >
                {this.getOptions(segregation)}
            </select>
        );
    }
}
