import React, { useState } from 'react';

const AppointmentTimeFilter = ({
    handleChange
}) => {

    const [value, setValue] = useState('')

    const onChange = ({ target }) => {
        const { value } = target
        if (!value) {
            return handleChange('', '')
        }
        const [ timingId, slots ] = value.split('_')
        const slotId = slots.split('.')
        setValue(target.value)
        handleChange(timingId, slotId)
    }

    return <select 
        onChange={onChange}
        value={value}
        style={{
            width: "100%",
            height: "40px",
            fontSize: "12px"
        }}
    >
        <option value="">All</option>
        <option value='1_17.18.19.20.21.22'>08:00 AM - 11:00 AM</option>
        <option value='2_23.24.25.26.27.28'>11:00 AM - 02:00 PM</option>
        <option value='3_29.30.31.32.33.34'>02:00 PM - 05:00 PM</option>
        <option value='4_35.36.37.38'>05:00 PM - 07:00 PM</option>
        <option value='5_39.40.41.42.43.44'>07:00 PM - 10:00 PM</option>
    </select>
}

export default AppointmentTimeFilter