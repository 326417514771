import { jsonPipeRequest } from "./baseRequest";
import baseRequest from './baseRequest';

export const getAllIntimationRequests = params => {
    const url = '/intimation/new-ops//all-intimation-requests';
    const method = 'GET';
    return baseRequest(url, method, params, null).then(response => response.data);
};

export const cancelRequest = (requestId,remarks) => {
  const url = `/intimation/new-ops/change-intimation-status`;
  const method = 'POST';
  const data = {
    requestId,
    reason:remarks,
    status: "request-cancelled",
  };
  return baseRequest(url, method, null, data);
};

export const placeAppointmentRequest = (
appointmentData
) => {
  const url = '/intimation/new-ops/book-intimation-appointment';
  const method = 'POST';
  const data = appointmentData;
  return baseRequest(url, method, null, data);
};

export const shiftIntimationToReimbursement = (requestId) => {
  const url = `/intimation/new-ops/change-intimation-status`;
  const method = 'POST';
  const data = {
    requestId,
    mode:"reimbursement",
    status: "request-processed"
  };
  return baseRequest(url, method, null, data);
}

export const requestPayout = (requestId) => {
  const url = `/intimation/new-ops/request-payout`;
  const method = 'POST';
  const data = {
    intimationId : requestId,
  };
  return baseRequest(url, method, null, data);
}

export const searchLabs = (value,type) => {
  const url = '/intimation/new-ops/get-all-lab-partners';
  const method = 'GET';
  const query = {
    name : value,
    type
  }
  return baseRequest(url, method, query, null);
};

export const searchLabCenters = (value, partnerId) => {
  const url = '/intimation/new-ops/get-lab-centers';
  const method = 'GET';
  const query = {
    address : value,
    partnerId
  }
  return baseRequest(url, method, query, null);
};

export const searchTest = (partnerId,search) => {
  const url = '/intimation/new-ops/get-lab-test';
  const method = 'GET';
  const query = {
    partnerId : partnerId,
    search : search
  }
  return baseRequest(url, method, query, null);
};

export const getIntimationRequest = requestId => {
  const url = `/intimation/new-ops/${requestId}/details`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};

export const callUserRequest = (requestId, regarding) => {
  const url = `/intimation/new-ops/request/${requestId}/call/user`;
  const method = 'POST';
  const data = {
    regarding,
  };
  return baseRequest(url, method, null, data);
};


export const updateFulfilmentDetails = (
  body,
  onResponseReceived,
  onResponseComplete,
  onResponseError
) => {
  console.log("body", body);
  const url = `/intimation/new-ops/request/update-fulfillments`;
  const method = 'POST';
  return jsonPipeRequest(
    url,
    method,
    null,
    body,
    null,
    onResponseReceived,
    onResponseComplete,
    onResponseError
  );
};

export const getFulfillmentDetails = (requestId) => {
  const url = `/intimation/new-ops/fulfillment/${requestId}`;
  const method = 'GET';
  return baseRequest(url, method, null, null);
};