import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  FormGroup,
  Row,
  Col,
  Label,
} from 'reactstrap';

import { markEscallation } from '../../services/api/offline-consult';
import { toast } from 'react-toastify';

export default class MarkEscallationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      editComment: '',
    };
  }

  onSubmit = () => {
    const { onComplete, requestId, closeEscallationModal } = this.props;
    const { editComment } = this.state;
    this.setState({
      loading: false,
    });
    return markEscallation(requestId, editComment)
      .then((response) => {
        if (response.message === 'success') {
          toast.success('Successfully Marked Escalation');
          return;
        }
        throw new Error('Error in endpoint');
      })
      .catch((err) => {
        console.error(err, 'Error while marking escalation');
        toast.error('Error while marking escalation');
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
        closeEscallationModal();
        onComplete();
      });
  };

  render() {
    const className = 'bg-info';
    const { isOpen, requestId, onComplete, closeEscallationModal } = this.props;
    const { loading, editComment } = this.state;
    return (
      <Modal centered isOpen={isOpen} toggle={closeEscallationModal}>
        <ModalHeader
          className="revamp-modal-header"
          toggle={closeEscallationModal}
        >
          Mark Escalated ({requestId})
        </ModalHeader>
        <ModalBody>
          Are you sure want to mark escalated on this request?
        </ModalBody>

        <FormGroup style={{ padding: '1rem' }}>
          {/* <Row>
                    <p style="color: red;">Entering remarks is mandatory</p>
                    </Row> */}
          <Row>
            <Col className="col-2">
              <Label>Remarks</Label>
            </Col>
            <Col>
              <textarea
                className="form-control"
                value={editComment}
                rows={1}
                onChange={(e) => this.setState({ editComment: e.target.value })}
              />
            </Col>
          </Row>
        </FormGroup>
        <ModalFooter
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            style={{
              backgroundColor: 'rgb(113, 79, 255)',
              width: '40%',
              borderColor: 'rgb(113, 79, 255)',
              height: '44px',
            }}
            onClick={this.onSubmit}
            disabled={loading || editComment.length <= 0}
          >
            {loading ? 'Loading...' : 'Mark Escalated'}
          </Button>
          {!loading && (
            <Button
              style={{
                backgroundColor: 'rgb(255, 255, 255)',
                width: '40%',
                borderColor: 'rgb(113, 79, 255)',
                color: 'rgb(113, 79, 255)',
                height: '44px',
              }}
              onClick={closeEscallationModal}
              disabled={loading}
            >
              Cancel
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}
