import baseRequest from './baseRequest';

export const opdPreOnboarding = (columnsName, records) => {
  const url = '/new-ops/opd-management/opd-pre-onboarding';
  const method = 'POST';
  const data = {
    columnsName,
    records,
  };
  console.log({ data }, 'opdPreOnboarding');
  return baseRequest(url, method, null, data);
};

export const opdOnboarding = (submitId) => {
  const url = '/new-ops/opd-management/opd-onboarding';
  const method = 'POST';
  const data = { submitId };
  console.log({ data }, '[opdOnboarding');
  return baseRequest(url, method, null, data);
};

export const opdDeOnboarding = (submitId) => {
  const url = '/new-ops/opd-management/opd-de-onboarding';
  const method = 'POST';
  const data = { submitId };
  console.log({ data }, '[opdDeOnboarding');
  return baseRequest(url, method, null, data);
};

export const deleteOpdPreOnboarding = (submitId) => {
  const url = '/new-ops/opd-management/delete-opd-pre-onboarding';
  const method = 'POST';
  const data = { submitId };
  console.log({ data }, '[deleteOpdPreOnboarding');
  return baseRequest(url, method, null, data);
};

export const getOpdHolder = (params) => {
  console.log(params,'[para]')
  const url = '/new-ops/opd-management/get-opd-holder';
  const method = 'get';
  return baseRequest(url, method, params, null);
};

export const getMemberDetails = (params) => {
  const url = '/new-ops/opd-management/get-member-details';
  const method = 'get';
  // console.log({ params }, 'getMemberDetails');
  return baseRequest(url, method, params, null);
};

export const updateMemberDetails = (data) => {
  const url = '/new-ops/opd-management/update-member-details';
  const method = 'POST';
  console.log({ data }, 'updateMembersDetails');
  return baseRequest(url, method, null, data);
};

export const onboardingStatus = (submitId) => {
  const url = '/new-ops/opd-management/status';
  const method = 'GET';
  const params = {
    submitId
  };
  return baseRequest(url, method, params, null);
}

export const onBoardSpecificMember = (id) => {
  const url = '/new-ops/opd-management/onboard-user'
  const method = 'POST';
  const data = {id}
  return baseRequest(url,method,null,data)
}

export const getPolicy = () => {
  const url = '/new-ops/opd-management/get-policy'
  const method = 'GET'
  return baseRequest(url,method,null,null)
}