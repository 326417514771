import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Row,
  Col,
  Container,
  Label,
  Spinner
} from 'reactstrap';

import { toast } from 'react-toastify';
import uploadDocIcon from '../../images/uploadDocument.svg'

class OfflineModalNonPayable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      remarks: '',
      nonPayableClaimsDoc: {},
      loader:false
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.requestId !== this.props.requestId) {
      this.setState({
        value: '',
        remarks: '',
      });
    }
  }



  handleChange = e => {
    const { value } = e.target;

    this.setState({
      value : value,
      remarks: '',
    });
  };

  uploadRemarkDoc = () => {
    const file = document.querySelector('#imageAttachment').files[0];
    if (file) {
      document.querySelector('#imageAttachment').value = null;
      this.setState({nonPayableClaimsDoc : file});
    }
  };

  handleRemarksInputChange = e => {
    this.setState({ remarks: e.target.value });
  };

  openAttachment = () => {
    document.querySelector('#imageAttachment').click();
  };

  handleSubmit = () => {
    const { value, remarks, nonPayableClaimsDoc} = this.state;
    this.setState({
      loader:true
    })
    if (!value || !remarks || !nonPayableClaimsDoc) {
      toast.error('No Value selected');
      this.setState({
        loader:false
      })
      return;
    }
    return this.props.submitModal(value, remarks, nonPayableClaimsDoc, 'non-payable')
        .then(() => {
          this.setState({
            loader:false
          })
          this.props.closeModal();
          this.props.onComplete();
        });
  };

  render() {
    const { value, remarks } = this.state;
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        toggle={() => this.props.closeModal()}
        className={this.props.className}
      >
        <ModalHeader className="bg-primary" toggle={this.props.closeModal}>
        Sure you want to mark this as Non Payable Claim?
        </ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col>
                <Row>
                  <label>Reason for marking as Non Payable:</label>
                </Row>
                <br />
                <Row>
                  <div>

              <select
              style={{
                width: '100%',
                height: '34px',
                fontSize: '16px',
              }}
                onChange={this.handleChange} value={value.reason} 
              >
                <option value="">Select Option</option>
                {this.props.nonPayableClaimsReasons.map((data,index) =>
                   ( <option value={data} >{data}</option>)
                )}
              </select>

                  </div>
                </Row>
                <br />
                  <>
                    <Row>
                      <div>
                        <React.Fragment>
                            <input
                              onChange={this.handleRemarksInputChange}
                              value={remarks}
                              placeholder="Please enter remarks"
                            />
                            <img src={uploadDocIcon}onClick={this.openAttachment}></img>
                        </React.Fragment>
                      </div>
                    </Row>
                    <br />
                  </>
              </Col>
            </Row>
            <input
                            type="file"
                            id="imageAttachment"
                            accept="image/*,.pdf"
                            style={{ display: 'none' }}
                            onChange={this.uploadRemarkDoc}
                          />
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={this.handleSubmit}
            disabled={this.state.loader}
          >
            {this.state.loader && (
              <Spinner
                style={{ height: '16px', width: '16px', marginRight: '5px' }}
              />
            )}
            Update Status
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default OfflineModalNonPayable;
