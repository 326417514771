import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { createCartForRadiology, convertCartToRadiology } from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
class LabRxConvertCartType extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            selected: 'both',
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };


    save = () => {
        if (this.state.selected === 'both') {
            createCartForRadiology(this.props.cartId, this.props.requestId)
                .then((response) => {
                    if (response.body.message === 'success') {
                        this.props.loadCart();
                        this.toggle();
                    } else {
                        toast.error('Error in processing request');
                    }
                })
                .catch((err) => {
                    console.error('Error', err);
                    toast.error('Error in processing request');
                });
        } else {
            convertCartToRadiology(this.props.cartId, 'radiology')
                .then((response) => {
                    if (response.body.message === 'cart changed to radiology successfully') {
                        this.props.loadCart();
                        this.toggle();
                    } else {
                        toast.error('Error in processing request');
                    }
                })
                .catch((err) => {
                    console.error('Error', err);
                    toast.error('Error in processing request');
                });
        }
    };

    render() {
        return (
            <div>
                <button className="ml-1 btn btn-primary" onClick={this.toggle} disabled={this.props.disabled}>This Prescription contains Radiology Tests</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <h4 className="mt-1 mb-0 ml-2">Please choose an appropriate action</h4>
                    <ModalBody>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1"
                                   value="both" defaultChecked onChange={(e) => this.setState({selected: 'both'})}/>
                                <label className="form-check-label" htmlFor="exampleRadios1">
                                    Contains Both Pathology and Radiology Tests
                                </label>
                                <p>[A new Cart will be create for radiology but for pathology tests - use this very cart]</p>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2"
                                   value="radiology" onChange={(e) => this.setState({selected: 'radiology'})}/>
                                <label className="form-check-label" htmlFor="exampleRadios2">
                                    Contains Only Radiology Tests
                                </label>
                                <p>[This cart will be converted into Radiology Cart]</p>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>Save</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(LabRxConvertCartType);





