import React, { useState , useEffect} from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import axios from 'axios';
import config from '../../config.js';
import { store } from '../../store/index.js';

const getRefundReasons = async () => {
  try {
    const headers = {};
    const state = store.getState();
    if (state.user.token) {
      headers.authorization = state.user.token;
    }
    const { data: responseBody } = await axios.get(`${config.pharmacyService}/ops/refund-reasons`,  { headers });
    return responseBody;
  } catch (err) {
    return {...err , success: false}
  }
}

function RefundOrderModal({ onClose }) {
  const [formData, setFormData] = useState({
    orderId: '',
    refundAmount: '',
    refundReason : ''
  });
  const [refundReasons, setRefundReasons] = useState([]);
  useEffect(() => {
    const fetchRefundReasons = async () => {
      try {
        const reasonsResp = await getRefundReasons();
        if(reasonsResp && reasonsResp.success) {
          setRefundReasons(reasonsResp.data);
        }
      } catch (error) {
        console.error("Error fetching refund reasons:", error);
      }
    };

    fetchRefundReasons();
  }, []);
 


  const [validationErrors, setValidationErrors] = useState(() => {
    const initialState = {};
    Object.keys(formData).forEach(key => {
      initialState[key] = '';
    });
    return initialState;
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const upperCaseValue = value.toUpperCase();
    setFormData({
      ...formData,
      [name]: upperCaseValue
    });
    validateField(name, upperCaseValue);
  };

  const validateField = (fieldName, value) => {
    let errorMessage = '';

    switch (fieldName) {
      case 'orderId':
        if (value.trim() === '' || isNaN(value)) {
          errorMessage = 'Order Id must be a number';
        }
        break;
    case 'refundAmount':
            if (value.trim() === '' || isNaN(value)) {
              errorMessage = 'refund amount must be a number';
            }
            break;
    case 'refundReason' :
        if (value.trim() === '' ) {
            errorMessage = 'Refund Reason Is Required ';
          }
          break;

        if (value.trim() === '' || isNaN(value) || parseFloat(value) <= 0) {
          errorMessage = 'MRP must be a number greater than 0';
        }
        break;
      default:
        break;
    }

    setValidationErrors({
      ...validationErrors,
      [fieldName]: errorMessage
    });
  };

  const refundAmountToUser = async (payload) => {
    try {
      const headers = {};
      const state = store.getState();
      if (state.user.token) {
        headers.authorization = state.user.token;
      }
      const { data: responseBody } = await axios.post(`${config.pharmacyService}/ops/refund-user`, payload, { headers });
      return responseBody;
    } catch (err) {
      if (err.response) {
        return err.response.data;
      } else if (err.request) {
        return { message: 'No response received from the server' };
      } else {
        return { message: 'Error during request setup' };
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const {message , success} = await refundAmountToUser(formData);
      alert(JSON.stringify(message))
      if(success){
        onClose();
      }
    } else {
      alert('Please check the entered values and try again.');
    }
  };

  const validateForm = () => {
    for (const key in formData) {
      validateField(key, formData[key]);
      if (validationErrors[key] !== '') {
        return false;
      }
    }
    return true;
  };

  return (
    <Modal isOpen={true} style={{ maxWidth: '600px', width: '100%', borderRadius: '4px', backgroundColor: '#f8f9fa' }}>
      <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={onClose}>
        <h4 className="text-center" style={{ color: '#fff', fontWeight: 'bold' }}>Refund amount</h4>
      </ModalHeader>
      <ModalBody style={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '40px', paddingRight: '40px' }}>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="orderId">Order ID:</label>
            <input type="number"  name="orderId" className="form-control" value={formData.orderId} onChange={handleChange} required />
            <span className="text-danger">{validationErrors.orderId}</span>
          </div>

          <div className="form-group">
            <label htmlFor="refundAmount">Refund amount:</label>
            <input type="number" id="refundAmount" name="refundAmount" className="form-control" value={formData.refundAmount} onChange={handleChange} required />
            <span className="text-danger">{validationErrors.refundAmount}</span>
          </div>

          <div className="form-group">
            <label htmlFor="refundReason">Refund reason:</label>
           {refundReasons && <select
              id="refundReason"
              name="refundReason"
              className="form-control"
              value={formData.refundReason}
              onChange={handleChange}
              required
            >
              <option value="" disabled>Select a reason</option>
              {refundReasons.map((reason, index) => (
                <option key={index} value={reason.id}>
                  {reason.reason}
                </option>
              ))}
            </select>}
            <span className="text-danger">{validationErrors.refundReason}</span>
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-primary">Refund Amount</button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  user: state.user,
});

export default connect(mapStateToProps)(RefundOrderModal);
