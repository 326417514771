import React, { Component } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Toast,
  ToastBody,
  ToastHeader,
} from 'reactstrap';
import { saveFulfilmentDetails } from '../../services/api/customer-support';

class UserBankDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmSave: false,
      saving: false,
      response: {
        show: false,
        message: '',
        color: 'success',
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { response } = this.state;
    if (response.show && !prevState.response.show) {
      this.setTimeoutForResponsePopup();
    }
  }

  setTimeoutForResponsePopup() {
    this.clearTimeout = setTimeout(() => {
      this.setState({
        response: {
          show: false,
        },
      });
      this.clearTimeout = null;
    }, 3000);
  }

  saveFulfilmentDetails = () => {
    const bankAccount = document.querySelector('#bankAccount').value;
    const ifscCode = document.querySelector('#ifscCode').value;
    const file = document.querySelector('#documentProof').files[0];
    if (!bankAccount || !ifscCode) {
      this.setState({
        response: {
          show: true,
          message: 'Please enter all fields before saving',
          color: 'danger',
        },
      });
      return;
    }
    this.setState({
      response: {
        show: false,
      },
      saving: true,
    });
    const formData = new FormData();
    formData.append('userId', this.props.userId);
    formData.append('accountNumber', bankAccount);
    formData.append('ifsc', ifscCode);
    formData.append('file', file);
    saveFulfilmentDetails(formData)
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            response: {
              show: true,
              message: 'Account details saved successfully',
              color: 'success',
            },
          });
          this.props.getUpdatedDetails();
        } else {
          this.setState({
            response: {
              show: true,
              message: res.errorMessage || 'Failed to save account details',
              color: 'danger',
            },
          });
        }
      })
      .catch((err) => {
        console.log(err, '');
        this.setState({
          response: {
            show: true,
            message: err.errorMessage || 'Failed to save account details',
            color: 'danger',
          },
        });
      })
      .finally(() => {
        this.setState({
          saving: false,
          confirmSave: false,
        });
      });
  };

  confirmSave = () => {
    this.setState({
      confirmSave: true,
    });
  };

  render() {
    const { response, saving } = this.state;
    return (
      <div>
        <div className={'row'}>
          <div className={'col-md-12'}>
            <Toast isOpen={response.show}>
              <ToastHeader icon={response.color}>Response</ToastHeader>
              <ToastBody>{response.message}</ToastBody>
            </Toast>
            <Form>
              <FormGroup inline>
                <Label for="bankAccount" sm={5}>
                  Bank Account
                </Label>
                <Col sm={7}>
                  <Input type="text" name="bankAccount" id="bankAccount" />
                </Col>
              </FormGroup>
              <FormGroup inline>
                <Label for="ifscCode" sm={5}>
                  IFSC
                </Label>
                <Col sm={7}>
                  <Input type="text" name="ifscCode" id="ifscCode" />
                </Col>
              </FormGroup>
              <FormGroup inline>
                <Label for="documentProof" sm={5}>
                  Document Proof
                </Label>
                <Col sm={7}>
                  <Input
                    type="file"
                    accept="image/*,.pdf"
                    name="documentProof"
                    id="documentProof"
                  />
                </Col>
              </FormGroup>
              <Button
                color="info"
                onClick={this.confirmSave}
                style={{ marginBottom: '1rem' }}
              >
                Save
              </Button>
            </Form>
          </div>
        </div>
        <Modal isOpen={this.state.confirmSave}>
          <ModalBody>Do you want to Add this account?</ModalBody>
          <ModalFooter>
            <Button
              disabled={saving}
              color="primary"
              onClick={this.saveFulfilmentDetails}
            >
              {saving ? 'Saving' : 'Save'}
            </Button>
            <Button
              color="secondary"
              disabled={saving}
              onClick={() => {
                this.setState({
                  confirmSave: false,
                });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default UserBankDetails;
