import React, { Component } from 'react';
import { toast } from 'react-toastify';
import ResultModal from '../common/modal/ResultModal';
import LoadingComponentAbsolute from '../common/LoadingComponentAbsolute';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import ModalConfirm from '../common/ModalConfirm';
import { OuterComponent } from './StyledComponent';
import Select from 'react-select';
import {
  addProduct,
  editProduct,
  uploadProductImage,
  deleteProductImage,
  deleteProduct,
  activeProduct,
} from '../../services/api/redemptions';
import { IoMdAdd } from 'react-icons/io';
import { MdDelete } from 'react-icons/md';
import ProductImage from './ProductImage';
import styled, { css } from 'styled-components';

const ImageContainer = styled.div`
  margin: 9px;
  display: flex;
  justify-content: space-around;
  ${(props) =>
    props.saved
      ? css`
          border: solid #00dd2d 1px;
        `
      : css`
          border: solid #e8e8e8 1px;
        `};
  border-radius: 5px;
  padding: 9px;
  align-items: center;
`;

class ProductsAddEdit extends Component {
  constructor(props) {
    console.log(props, 'pro');
    super(props);

    this.state = {
      loading: false,
      showModal: false,
      showImageType: false,
      modalHeader: '',
      expireDate: props.editData.expiresIn || null,
      modalBody: '',
      bgColor: '',
      title: props.editData.title || '',
      files: [],
      productImageData: props.editData.productImages || [],
      name: props.editData.name || '',
      description: props.editData.description || '',
      visitCost: props.editData.visit_cost || 0,
      offerDetails: props.editData.offer_details || '',
      redeemDetails: props.editData.redeem_details || '',
      knowMoreText: props.editData.know_more_text || '',
      knowMoreLink: props.editData.know_more_link || '',
      locations: props.editData.locations || '',
      enterPhoneCaption: props.editData.enter_phone_caption || '',
      collectInfo: props.editData.collect_info || '',
      genderInfo: props.editData.gender || '',
      collectInfoOptions: [
        { value: '', label: 'None' },
        { value: 'patient', label: 'Patient' },
      ],
      genderConstraint: [
        { value: 'Unisex', label: 'Unisex' },
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
      ],
      showProductImageModal: false,
      ccMail: props.editData.cc_mail || '',
      toEmail: props.editData.to_mail || '',
      emailSubject: props.editData.email_subject || '',
      tat: props.editData.tat || '',
      type: props.editData.type || '',
      multiRedeemDetails: [],
      fileUrl: '',
      multiOfferDetails: [],
      confirmModal: {
        isOpen: false,
        message: '',
        onConfirm: () => {},
        onClose: () => {},
      },
      typeOptions: [
        { value: 'product', label: 'product' },
        { value: 'voucher', label: 'voucher' },
        { value: 'donate', label: 'donation' },
      ],
      imageTypeOptions: [
        { value: 'inside', label: 'inside' },
        { value: 'outside', label: 'outside' },
        { value: 'comms', label: 'comms' },
      ],
      imageType: [],
      imageTypes: [],
      imageLink: props.imageLink || '',
    };
  }
  // Add Edit Product
  addProduct = (data) => {
    // console.log({ data }, 'addProduct');
    if (data.title && data.description && data.offerDetails && data.type) {
      if (this.props.editProduct) {
        return editProduct(data)
          .then((responseBody) => {
            const productId = this.props.editData.id;
            if (!productId || !this.state.files.length) {
              return;
            }
            const data = new FormData();
            for (let i = 0; i < this.state.files.length; i++) {
              data.append('file', this.state.files[i]);
            }
            data.append('productId', productId);
            data.append('imageType', this.state.imageTypes);
            return uploadProductImage(data);
          })
          .then((responseBody) => {
            this.setState({
              showModal: true,
              modalHeader: 'Success',
              modalBody: `Product Edit.`,
              bgColor: 'bg-success',
              loading: false,
            });
            this.props.togglePage();
            document.querySelector('#productImage').value = null;
          })
          .catch((err) => {
            console.log(err);
            let errorMessage = err.message;
            this.setState({
              showModal: true,
              modalHeader: 'Error',
              modalBody: `${errorMessage}.`,
              bgColor: 'bg-danger',
              loading: false,
            });
          })
          .finally(() => {
            this.props.fetchTableData();
          });
      } else {
        return addProduct(data)
          .then((responseBody) => {
            const productId = responseBody.productId;
            console.log(responseBody, 'Response after adding product');
            const data = new FormData();
            if (!productId || !this.state.files.length) {
              return;
            }
            for (let i = 0; i < this.state.files.length; i++) {
              data.append('file', this.state.files[i]);
            }

            data.append('productId', productId);
            data.append('imageType', this.state.imageTypes);
            return uploadProductImage(data);
          })
          .then((res) => {
            this.setState({
              showModal: true,
              modalHeader: 'Success',
              modalBody: `Product added.`,
              bgColor: 'bg-success',
              loading: false,
              uploading: false,
              uploadProgress: 0,
            });
            this.props.togglePage();
            document.querySelector('#productImage').value = null;
          })
          .catch((err) => {
            console.log(err);
            let errorMessage = err.message;
            this.setState({
              showModal: true,
              modalHeader: 'Error',
              modalBody: `${errorMessage}.`,
              bgColor: 'bg-danger',
              loading: false,
            });
          });
      }
    }
  };
  //  Validations
  validationCheck = () => {
    const data = {
      id: this.props.editData.id,
      title: this.state.title,
      name: this.state.name,
      description: this.state.description,
      visitCost: this.state.visitCost,
      offerDetails: this.state.offerDetails,
      redeemDetails: this.state.redeemDetails,
      knowMoreLink: this.state.knowMoreLink,
      knowMoreText: this.state.knowMoreText,
      locations: this.state.locations,
      enterPhoneCaption: this.state.enterPhoneCaption,
      collectInfo: this.state.collectInfo,
      genderInfo: this.state.genderInfo,
      emailSubject: this.state.emailSubject,
      tat: this.state.tat,
      type: this.state.type,
      ccMail: this.state.ccMail,
      toEmail: this.state.toEmail,
      imageLink: this.state.imageLink,
      expireDate: this.state.expireDate,
    };

    if (data.description.length > 500) {
      this.setState({
        showModal: true,
        modalHeader: 'Validation error',
        modalBody: 'Size of description is more than the limit set',
        bgColor: 'bg-danger',
        loading: false,
      });
    } else if (data.enterPhoneCaption.length > 100) {
      this.setState({
        showModal: true,
        modalHeader: 'Validation error',
        modalBody: 'Size of phone caption is more than the limit set',
        bgColor: 'bg-danger',
        loading: false,
      });
    } else if (data.emailSubject.length > 100) {
      this.setState({
        showModal: true,
        modalHeader: 'Validation error',
        modalBody: 'Size of email subject is more than the limit set',
        bgColor: 'bg-danger',
        loading: false,
      });
    } else {
      this.addProduct(data);
    }
  };

  // Handle Change
  handleChangeTitle = (e) => {
    this.setState({ title: e.target.value });
  };
  handleChangeName = (e) => {
    this.setState({ name: e.target.value });
  };
  handleChangeVisitCost = (e) => {
    this.setState({ visitCost: e.target.value });
  };
  handleChangeDescription = (e) => {
    this.setState({ description: e.target.value });
  };
  handleChangeOfferDetails = (e) => {
    this.setState({ offerDetails: e.target.value });
  };
  handleChangeRedeemDetails = (e) => {
    this.setState({ redeemDetails: e.target.value });
  };
  handleChangeKnowMoreLink = (e) => {
    this.setState({ knowMoreLink: e.target.value });
  };
  handleChangeKnowMoreText = (e) => {
    this.setState({ knowMoreText: e.target.value });
  };
  handleChangeLocation = (e) => {
    this.setState({ locations: e.target.value });
  };
  handleChangeEnterPhoneCaption = (e) => {
    this.setState({ enterPhoneCaption: e.target.value });
  };
  handleChangeCollectInfo = (e) => {
    this.setState({ collectInfo: e.value });
  };
  handleChangeGenderInfo = (e) => {
    this.setState({ genderInfo: e.value });
  };
  handleChangeEmailSubject = (e) => {
    this.setState({ emailSubject: e.target.value });
  };
  handleChangeCcMail = (e) => {
    this.setState({ ccMail: e.target.value });
  };
  handleChangeToEmail = (e) => {
    this.setState({ toEmail: e.target.value });
  };
  handleChangeTat = (e) => {
    this.setState({ tat: e.target.value });
  };
  handleChangeType = (e) => {
    this.setState({ type: e.value });
  };
  handleProductImageAttachment = () => {
    const file = document.querySelector('#productImage').files[0];

    if (file) {
      this.setState({
        uploading: true,
        showImageType: true,
        imageType: [],
        files: [...this.state.files, file],
      });
    }
  };

  handleChangeImageType = (e) => {
    this.setState({
      imageType: e,
    });
  };
  handleAddMoreImage = (e) => {
    this.setState({
      showImageType: false,
      imageTypes: [...this.state.imageTypes, this.state.imageType.value],
    });
    document.querySelector('#productImage').value = null;
  };

  showAddedImageUrl = () => {
    return this.state.productImageData.map((element) => (
      <ImageContainer saved={true}>
        <img
          src={element.imageLink}
          width={100}
          className="text-primary"
          onClick={() => {
            this.showImage(element.imageLink);
          }}
        />
        <Label
          className="text-primary"
          style={{ marginLeft: 20, marginRight: 20 }}
        >
          {element.type}
        </Label>
        <MdDelete
          className="icon-style text-danger"
          id={`editProductButton`}
          style={{ fontSize: 45 }}
          onClick={() => {
            this.handleDeleteImageById(element.imageId);
          }}
        />
      </ImageContainer>
    ));
  };
  showAddedImage = () => {
    return this.state.imageTypes.map((imType, i) => (
      <ImageContainer>
        <img
          src={URL.createObjectURL(this.state.files[i])}
          width={100}
          className="text-primary"
          onClick={() => {
            this.showImage(URL.createObjectURL(this.state.files[i]));
          }}
        />
        <Label
          className="text-primary"
          style={{ marginLeft: 20, marginRight: 20 }}
        >
          {imType}
        </Label>
        <MdDelete
          className="icon-style text-danger"
          id={`editProductButton`}
          style={{ fontSize: 45 }}
          onClick={() => {
            this.handleDeleteImage(i);
          }}
        />
      </ImageContainer>
    ));
  };
  showImage = (fileUrl) => {
    this.setState({
      fileUrl,
      showProductImageModal: true,
    });
  };
  handleDeleteImageById = (id) => {
    const params = {
      imageId: id,
    };
    deleteProductImage(params)
      .then((resp) => {
        let data = [];
        this.state.productImageData.forEach((element) => {
          if (element.id !== id) {
            data.push(element);
          }
        });
        this.setState({
          productImageData: data,
        });
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };
  handleDeleteImage = (i) => {
    // console.log({ i });
    let files = this.state.files;
    let imageTypes = this.state.imageTypes;
    if (i === 0) {
      files.splice(0, 1);
      imageTypes.splice(0, 1);
    } else {
      files.splice(i, i);
      imageTypes.splice(i, i);
    }
    this.setState({
      files,
      imageTypes,
    });
  };
  // Handle add more
  handleAddMoreRedeemDetails = () => {
    let uni = 1;
    this.state.multiRedeemDetails.forEach((element) => {
      if (
        this.state.redeemDetails === element ||
        this.state.redeemDetails === null
      ) {
        uni = 0;
      }
    });
    if (uni) {
      this.setState({
        multiRedeemDetails: [
          ...this.state.multiRedeemDetails,
          this.state.redeemDetails,
        ],
      });
    }
  };
  showMultiRedeemDetails = () => {
    const elements = [];
    this.state.multiRedeemDetails.forEach((element) => {
      if (element.length !== 0) {
        elements.push(
          <FormGroup row>
            <Col md="3"></Col>
            <Col xs="12" md="9">
              <Label className="text-primary">{element}</Label>
            </Col>
          </FormGroup>
        );
      }
    });
    return elements;
  };
  handleAddMoreOfferDetails = () => {
    let uni = 1;
    this.state.multiOfferDetails.forEach((element) => {
      if (
        this.state.offerDetails === element ||
        this.state.offerDetails === null
      ) {
        uni = 0;
      }
    });
    if (uni) {
      this.setState({
        multiOfferDetails: [
          ...this.state.multiOfferDetails,
          this.state.offerDetails,
        ],
      });
    }
  };
  showMultiOfferDetails = () => {
    const elements = [];
    this.state.multiOfferDetails.forEach((element) => {
      if (element.length !== 0) {
        elements.push(
          <FormGroup row>
            <Col md="3"></Col>
            <Col xs="12" md="9">
              <Label className="text-primary">{element}</Label>
            </Col>
          </FormGroup>
        );
      }
    });
    return elements;
  };
  handleDeleteProduct = () => {
    const params = {
      productId: this.props.editData.id,
    };
    this.setState({
      confirmModal: {
        isOpen: true,
        message: `Are you sure you want to delete ${this.state.name} product?`,
        onConfirm: () => {
          return deleteProduct(params)
            .then((response) => {
              console.log(response, 'Response from server');
              if (response.message === 'success') {
                toast.success(
                  `Successfully deleted ${this.state.name} product`
                );
                this.props.togglePage();
                return this.props.fetchTableData();
              }
              throw new Error(
                response.errorMessage || 'Error while uploading the file'
              );
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.message);
            });
        },
        onClose: () => {
          this.setState({
            confirmModal: {
              isOpen: false,
              onConfirm: () => {},
              onClose: () => {},
            },
          });
        },
      },
    });
  };
  handleInactiveProduct = () => {
    const isActive = this.props.editData.active;
    const params = {
      productId: this.props.editData.id,
      isActive: !isActive,
    };
    this.setState({
      confirmModal: {
        isOpen: true,
        message: `Are you sure you want to ${
          isActive ? 'inactive' : 'active'
        } ${this.state.name} product?`,
        onConfirm: () => {
          return activeProduct(params)
            .then((response) => {
              console.log(response, 'Response from server');
              if (response.message === 'success') {
                toast.success(
                  `Successfully ${isActive ? 'inactive' : 'active'} ${
                    this.state.name
                  } product`
                );
                this.props.togglePage();
                return this.props.fetchTableData();
              }
              throw new Error(
                response.errorMessage || 'Error while uploading the file'
              );
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.message);
            });
        },
        onClose: () => {
          this.setState({
            confirmModal: {
              isOpen: false,
              onConfirm: () => {},
              onClose: () => {},
            },
          });
        },
      },
    });
  };
  render() {
    let typeSel = null;
    let collectInfoSel = [];
    let genderValue = [];
    this.state.typeOptions.forEach((option) => {
      if (option.value === this.state.type) {
        typeSel = option;
      }
    });
    this.state.collectInfoOptions.forEach((option) => {
      if (option.value === this.state.collectInfo) {
        collectInfoSel = option;
      }
    });
    this.state.genderConstraint.forEach((option) => {
      if (option.value === this.state.genderInfo) {
        genderValue = option;
      }
    });
    return (
      <OuterComponent>
        <Col xs="12" md="6">
          <Card>
            <CardHeader>
              {!this.props.editProduct && <strong>Add Product</strong>}
              {this.props.editProduct && <strong>Edit Product</strong>}
            </CardHeader>
            <CardBody>
              <Form
                action=""
                method="post"
                encType="multipart/form-data"
                className="form-horizontal"
              >
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Title *</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      id="text-input"
                      name="text-input"
                      placeholder="Name for internal user only"
                      defaultValue={this.state.title}
                      onChange={this.handleChangeTitle}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Product Display Name *</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      id="text-input"
                      name="text-input"
                      placeholder="This will be displayed as text of input"
                      defaultValue={this.state.name}
                      onChange={this.handleChangeName}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Visit Cost *</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="number"
                      id="visit-cost"
                      name="visit-cost"
                      placeholder="The price which visit bears to get the product"
                      defaultValue={this.state.visitCost}
                      onChange={this.handleChangeVisitCost}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="textarea-input">Description *</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="textarea"
                      name="textarea-input"
                      id="textarea-input"
                      rows="9"
                      defaultValue={this.state.description}
                      placeholder="It will be simple description of the product"
                      onChange={this.handleChangeDescription}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="textarea-input">Offer Details *</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="textarea"
                      name="textarea-input"
                      id="textarea-input"
                      rows="9"
                      defaultValue={this.state.offerDetails}
                      placeholder="It will be displayed as numbered list. Enter your offer details separated by ';'"
                      onChange={this.handleChangeOfferDetails}
                      required
                    />
                  </Col>
                </FormGroup>
                {this.showMultiOfferDetails()}
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="textarea-input">Redeem Details</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="textarea"
                      name="textarea-input"
                      id="redeem"
                      rows="9"
                      defaultValue={this.state.redeemDetails}
                      placeholder="It will be displayed as numbered list. Enter your offer details separated by ';'"
                      onChange={this.handleChangeRedeemDetails}
                    />
                  </Col>
                </FormGroup>
                {this.showMultiRedeemDetails()}
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="textarea-input">Know more text</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      name="text-input"
                      id="text-input"
                      rows="9"
                      defaultValue={this.state.knowMoreText}
                      placeholder="Label for know more link"
                      onChange={this.handleChangeKnowMoreText}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="textarea-input">Know more link</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      name="text-input"
                      id="text-input"
                      rows="9"
                      defaultValue={this.state.knowMoreLink}
                      placeholder="A link where user can see the detailed info about offer"
                      onChange={this.handleChangeKnowMoreLink}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="textarea-input">Location</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      name="text-input"
                      id="text-input"
                      rows="9"
                      defaultValue={this.state.locations}
                      placeholder="Blank if you want to be displayed everywhere, else tag cities with diff names"
                      onChange={this.handleChangeLocation}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="textarea-input">Phone caption *</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      name="text-input"
                      id="text-input"
                      rows="9"
                      defaultValue={this.state.enterPhoneCaption}
                      placeholder="Label for asking the users's phone number"
                      onChange={this.handleChangeEnterPhoneCaption}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Email subject</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      name="text-input"
                      id="text-input"
                      rows="9"
                      defaultValue={this.state.emailSubject}
                      placeholder="Subject of the email sent to user after redeeming the offer"
                      onChange={this.handleChangeEmailSubject}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Turn around time</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      name="text-input"
                      id="text-input"
                      rows="9"
                      defaultValue={this.state.tat}
                      placeholder="TAT to be sent in email which is sent to user"
                      onChange={this.handleChangeTat}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="textarea-input">Type *</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Select
                      onChange={this.handleChangeType}
                      options={this.state.typeOptions}
                      defaultValue={typeSel}
                      required
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Collect info</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Select
                      onChange={this.handleChangeCollectInfo}
                      options={this.state.collectInfoOptions}
                      defaultValue={collectInfoSel}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Gender-Constraint</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Select
                      onChange={this.handleChangeGenderInfo}
                      options={this.state.genderConstraint}
                      defaultValue={genderValue}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">To email</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      name="text-input"
                      id="text-input"
                      rows="9"
                      defaultValue={this.state.toEmail}
                      placeholder="Emails of the partner where the details of user has to be sent"
                      onChange={this.handleChangeToEmail}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">CC mail</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="text"
                      name="text-input"
                      id="text-input"
                      rows="9"
                      defaultValue={this.state.ccMail}
                      placeholder="Emails of the Visit SPOC/OPS who will be CCed while sending above email"
                      onChange={this.handleChangeCcMail}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="text-input">Expires In:</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <input
                      type="date"
                      value={this.state.expireDate}
                      onChange={(event) =>
                        this.setState({ expireDate: event.target.value })
                      }
                    />
                  </Col>
                </FormGroup>
                {this.props.editProduct && (
                  <FormGroup row>
                    <Col md="3" style={{ marginTop: 10 }}>
                      <Label htmlFor="text-input">Delete Product</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <MdDelete
                        className="icon-style text-danger"
                        id={`editProductButton`}
                        style={{ fontSize: 45 }}
                        onClick={() => {
                          this.handleDeleteProduct();
                        }}
                      />
                    </Col>
                  </FormGroup>
                )}
                {this.props.editProduct && (
                  <FormGroup row>
                    <Col md="3" style={{ marginTop: 10 }}>
                      <Label htmlFor="text-input">Product Activity</Label>
                    </Col>
                    <Col xs="12" md="9">
                      <Button
                        color="primary"
                        onClick={() => {
                          return this.handleInactiveProduct();
                        }}
                      >
                        {this.props.editData.active ? 'Inactive' : 'Active'}
                      </Button>
                    </Col>
                  </FormGroup>
                )}
                <FormGroup row>
                  <Col md="3">
                    <Label htmlFor="textarea-input">Image</Label>
                  </Col>
                  <Col xs="12" md="9">
                    <Input
                      type="file"
                      id="productImage"
                      accept=".png,.jpeg"
                      onChange={this.handleProductImageAttachment}
                    />
                  </Col>
                </FormGroup>
                {this.state.showImageType && (
                  <div>
                    <FormGroup row>
                      <Col md="3">
                        <Label htmlFor="textarea-input">Image Type</Label>
                      </Col>
                      <Col xs="12" md="8">
                        <Select
                          onChange={this.handleChangeImageType}
                          options={this.state.imageTypeOptions}
                          value={this.state.imageType}
                        />
                      </Col>
                      <Col md="1">
                        <IoMdAdd
                          className="icon-style text-primary"
                          id={`editProductButton`}
                          style={{ fontSize: 45 }}
                          onClick={() => {
                            this.handleAddMoreImage();
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                )}
                {this.showAddedImage()}
                {this.showAddedImageUrl()}
              </Form>
            </CardBody>
            <CardFooter>
              <Button
                type="submit"
                size="sm"
                color="secondary"
                onClick={this.validationCheck}
                disabled={!this.state.expireDate}
              >
                Confirm
              </Button>
            </CardFooter>
          </Card>
        </Col>
        {this.state.showProductImageModal && (
          <ProductImage
            fileUrl={this.state.fileUrl}
            closeModal={() => this.setState({ showProductImageModal: false })}
          />
        )}
        <ModalConfirm
          message={this.state.confirmModal.message}
          onConfirm={this.state.confirmModal.onConfirm}
          onClose={this.state.confirmModal.onClose}
          isOpen={this.state.confirmModal.isOpen}
        />
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        {this.state.loading ? <LoadingComponentAbsolute /> : null}
      </OuterComponent>
    );
  }
}

export default ProductsAddEdit;
