import React from 'react';

const AckCallFilter = ({
    onChange
}) => {

    const handleChange = (event) => {
        onChange(event.target.value)
    }

    return <select
        onChange={handleChange}
        style={{
            width: "100%",
            height: "40px",
            fontSize: "12px"
        }}
    >
        <option value=''>All</option>
        <option value='completed'>Completed</option>
        <option value='no-answer'>No Answer</option>
    </select>
}

export default AckCallFilter