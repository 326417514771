import { useEffect, useState } from "react"

const useAfterInitialEffect = (callback, deps) => {

    const [count, setCount] = useState(0)

    useEffect(() => {
        setCount(prevCount => prevCount + 1)
        if (count) {
            callback()
        }
    }, deps)

}

export default useAfterInitialEffect