import React from 'react';

import { fetchRequestHistory } from '../../services/api/reimburse-claims';

import {
  HistoryItem,
  TimeContainer,
  StyledHistoryContainer,
} from '../common/Activities';
import styled from 'styled-components';
import { FaCircleNotch } from 'react-icons/fa';

const LoadingContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
`;

const LoadingIcon = styled(FaCircleNotch)`
  color: white;
  margin-right: 0.5rem;
`;

export default class HistoryContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      history: [],
    };
  }

  getHistory = (requestId) => {
    return fetchRequestHistory(requestId)
      .then((response) => {
        if (response && response.message === 'success') {
          return response.data;
        }
        throw new Error('Response Message is not correct');
      })
      .catch((err) => {
        console.error(err, 'Error while fetching data');
      });
  };

  componentDidMount() {
    const { requestId } = this.props;

    this.getHistory(requestId)
      .then((data) => {
        console.log({ data, requestId }, 'History');
        this.setState({
          history: data.history,
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  render() {
    const { history, loading } = this.state;
    return loading ? (
      <LoadingContainer>
        <LoadingIcon></LoadingIcon>
      </LoadingContainer>
    ) : (
      <StyledHistoryContainer style={{ width: '500px' }}>
        {history && history.length > 0 ? (
          history.map((item) => (
            <HistoryItem key={item.activityId}>
              {item.description} by {item.userName}
              {item.meta && `(${item.meta})`}
              {item.audioFileLink && (
                <a href={item.audioFileLink} target="_blank">
                  Link
                </a>
              )}
              <TimeContainer>{item.eventTime}</TimeContainer>
            </HistoryItem>
          ))
        ) : (
          <HistoryItem>No Activities Here</HistoryItem>
        )}
      </StyledHistoryContainer>
    );
  }
}
