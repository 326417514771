import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { getOpsUsers } from '../../services/api/makeCredential';
import NavBar from '../layout/NavBar';
import ReactTable from 'react-table';
import EditCredentialModal from './EditCredentialModal';

const PaddedContainer = styled.div`
  margin-top: 5rem;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  flex: 1;
  padding: 0px;
  margin: 0px;
`;

const BodyComponent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 0.5rem;
  /* overflow: hidden; */
`;

class manageCredentials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      opsUsers: [],
      showEditModal: false,
      selectedId: null,
      selectedData: {},
    };
  }

  componentDidMount = () => {
    this.getOpsUsers();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.selectedId !== this.state.selectedId) {
      const selectedData = this.state.opsUsers.find(
        (user) => user.id === this.state.selectedId
      );
      this.setState({ selectedData });
    }
  };

  getOpsUsers = () => {
    this.setState({ loading: true });
    getOpsUsers()
      .then((res) => {
        console.log(res);
        if (res.message === 'Success') {
          this.setState({ opsUsers: res.data });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  tableColumns = [
    {
      accessor: 'name',
      filterable: true,
      sortable: false,
      Header: 'Name',
    },
    {
      accessor: 'userName',
      filterable: true,
      sortable: false,
      Header: 'UserName',
    },
    {
      accessor: 'phone',
      filterable: false,
      Header: 'Phone',
    },
    {
      accessor: 'email',
      filterable: false,
      Header: 'Email',
    },
    {
      accessor: 'password',
      filterable: false,
      Header: 'Password',
    },
    {
      accessor: 'updated_by',
      filterable: false,
      Header: 'Last Updated',
      Cell: (row) => {
        const inputDate = new Date(row.original.updatedAt);
        const formattedDate = inputDate.toLocaleString();

        return (
          <div>
            <span>
              {row.original.updatedBy ? row.original.updatedBy : ''}{' '}
              {formattedDate}
            </span>
          </div>
        );
      },
    },
    {
      accessor: 'action',
      filterable: false,
      Header: 'Action',
      Cell: (row) => {
        return (
          <div>
            <Button
              onClick={() => {
                this.setState({
                  showEditModal: true,
                  selectedId: row.original.id,
                });
              }}
            >
              Edit
            </Button>
          </div>
        );
      },
    },
  ];

  render() {
    const { loading, opsUsers } = this.state;
    console.log(this.state.selectedData, 'selectedData');

    return (
      <Fragment>
        <NavBar />
        <PaddedContainer>
          <HeaderContainer>
            <h3 className="">Manage Credentials</h3>
            <Button
              color="primary"
              onClick={() => {
                this.props.history.push('/make-credentials');
              }}
            >
              Make Credentials
            </Button>
          </HeaderContainer>

          {/* Table */}
          <BodyComponent>
            <ReactTable
              filterable
              className="-striped -highlight"
              minRows={1}
              data={opsUsers}
              collapseOnDataChange={false}
              columns={this.tableColumns}
              loading={loading}
              showPaginationTop
            />
          </BodyComponent>
        </PaddedContainer>
        {this.state.showEditModal && (
          <EditCredentialModal
            showModal={this.state.showEditModal}
            toggle={() => {
              this.setState({ showEditModal: false });
            }}
            selectedData={this.state.selectedData}
            getOpsUsers={this.getOpsUsers}
          />
        )}
      </Fragment>
    );
  }
}

export default manageCredentials;
