import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from 'reactstrap/lib/Button';
import FormGroup from 'reactstrap/lib/FormGroup';
import Label from 'reactstrap/lib/Label';
import Modal from 'reactstrap/lib/Modal';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { Form, Input, ModalBody } from 'reactstrap';
import { updateDetails } from '../../services/api/customer-support';
import{updateUserSponsor} from '../../services/api/customer-support';
import moment from 'moment';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {fetchAllSponsors} from '../../services/api/sponsors'

class EditUserDetails extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      confirmSave: false,
      saving: false,
      DOB: moment(
        this.props.customerSupport.userDetail[0].dob,
        'DD MMM YYYY'
      ).format('YYYY-MM-DD'),
      name: this.props.customerSupport.userDetail[0].name,
      gender: this.props.customerSupport.userDetail[0].gender,
      response: {
        show: false,
        message: '',
        color: 'success',
      },
      sponsors: [],
      sponsorId:null
    };
  }

  updateUserDetails = (e) => {
    e.preventDefault();
    let { name, DOB, gender } = this.state;
    console.log('state', name, DOB, gender);
    if (!name || !gender) {
      this.setState({
        response: {
          show: true,
          message: 'Please fill all fields',
          color: 'warning',
        },
      });
      return;
    }
    updateDetails(this.props.userId, name, DOB, gender)
      .then((response) => {
        if (response.message === 'success') {
          this.setState({
            response: {
              show: true,
              message: 'Updated Successfully',
              color: 'success',
            },
            confirmSave: false,
          });
          this.props.getUpdatedDetails();
        } else {
          this.setState({
            response: {
              show: true,
              message: `Error in Updating Details ${response.message}`,
              color: 'danger',
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          response: {
            show: true,
            message: `Error in Update ${error.message}`,
            color: 'danger',
          },
        });
      });
  };

  handleName = (e) => {
    const { value } = e.target;
    this.setState({
      name: value,
    });
  };

  handleDOB = (e) => {
    const { value } = e.target;
    this.setState({
      DOB: value,
    });
  };

  handleGender = (e) => {
    const { value } = e.target;
    this.setState({
      gender: value,
    });
  };
  handleOpenModalSecond = () => {
    this.setState({
      isOpen: true,
    currentModal: 'update',  
    });
   this.getSponsor()
  };
  handleChange = (event) => {
    this.setState({ sponsorId: event.value, selectedOption: event });
  };
  confirmSave = () => {
    this.setState({
      confirmSave: true,
    });
  };

  onClose = () => {
    this.setState({
      isOpen: false,
      // sponsorName: '',
    });
  };

  getSponsor(){
     return fetchAllSponsors ()
     .then((response) => {
       // console.log("daaata--",response.data)
         const sponsors = response.data.map((sponsor) => {
           return {
             value:sponsor.sponsorId,
             label:sponsor.sponsorName
           }
         });
           this.setState({
               sponsors
           });
   })
   .catch((err) => {
     this.setState({
       sponsors: [],
     });
       console.error(err, 'Error while fetching sponsors');
       toast.error(err.message);
   })  
   
   }
    onConfirm = () => {
    const userId = this.props.userId;
    const sponsorId= this.state.sponsorId;
    this.setState({
      loading: true,
    });
    if(sponsorId){
    return updateUserSponsor(userId,sponsorId)
      .then((response) => {
        console.log(response);
        if (response.message === 'success') {
          toast.success(`Sponsor Updated`);
          this.onClose();
        } else {
          throw new Error(
            response.errorMessage || 'Not able to update sponsor'
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      })
    }
    else{
      toast.warn(`Please select sponsor`);
    }
  };

  render() {
    const { saving } = this.state;
    return (
      <div>
        <div className={'row'}>
          <div className={'col-md-8'}>
            <Form>
              <FormGroup>
                <Label for="UpdateDetails">Update User Details</Label>
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;
                &nbsp;

                <Button color="primary"
                onClick={this.handleOpenModalSecond}
                >Update Sponsor</Button>

              </FormGroup>
              <FormGroup>
                <Label for="Name">Name</Label>
                <Input
                  type="text"
                  maxLength={'255'}
                  value={this.state.name}
                  name="name"
                  id="nameInput"
                  placeholder="name"
                  onChange={this.handleName}
                />
              </FormGroup>
              <FormGroup>
                <Label for="dob">DOB</Label>
                <Input
                  type="date"
                  maxLength={'255'}
                  name="dob"
                  id="dobInput"
                  value={this.state.DOB}
                  format="DD-MM-YYYY"
                  onChange={this.handleDOB}
                />
              </FormGroup>
              <FormGroup>
                <Label for="gender">Gender</Label>
                <Input
                  type="select"
                  maxLength={'255'}
                  name="gender"
                  id="genderInput"
                  value={this.state.gender}
                  onChange={this.handleGender}
                >
                  <option>Select</option>
                  <option
                    selected={this.state.gender === 'Male' ? true : false}
                    value={'Male'}
                  >
                    Male
                  </option>
                  <option
                    selected={this.state.gender === 'Female' ? true : false}
                    value={'Female'}
                  >
                    Female
                  </option>
                </Input>
              </FormGroup>
              <Button
                color="info"
                style={{ marginBottom: '1rem' }}
                onClick={this.confirmSave}
              >
                Update Details
              </Button>
            </Form>
          </div>
        </div>
        <Modal isOpen={this.state.confirmSave}>
          <ModalBody>Do you want to update details?</ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={saving}
              onClick={this.updateUserDetails}
            >
              {saving ? 'Saving' : 'Save'}
            </Button>
            <Button
              color="secondary"
              disabled={saving}
              onClick={() => {
                this.setState({
                  confirmSave: false,
                });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        {this.state.isOpen && this.state.currentModal === 'update' && (
          <Modal
            isOpen={this.state.isOpen}
            onClose={this.onClose}
            header={'Update Sponsor'}
          >
              <div className="row" style={{
            //  width: '20%',
            //   height: '70px',
            //    fontSize: '15px', 

            }}>
              <div className="col">
                  <Button color='success' onClick={this.onConfirm}>Update</Button>
                </div>
                <div className="col">
               
                   <Select 
                     onChange={this.handleChange}
                    placeholder="Select sponsors"
                   options={this.state.sponsors}
                   value={this.state.selectedOption} />
                </div>
              </div>   
          </Modal>
        )}
      </div>
      
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.customerSupport.userDetail,
  logedUser: state.user,
  customerSupport: state.customerSupport,
});
export default connect(mapStateToProps)(EditUserDetails);
