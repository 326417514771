import React from 'react';
import styled from 'styled-components';
import { FaCircleNotch } from 'react-icons/fa';

const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(30, 30, 30, 0.6);
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;
`;

const LoadingIcon = styled(FaCircleNotch)`
  font-size: 2rem;
  align-self: center;
  z-index: 999;
  margin-top: 100px;
  color: ${props => (props.color ? props.color : '#ccc')};
`;

export default ({ color }) => (
  <LoadingContainer>
    <Container>
      <LoadingIcon className="icon-spin" color={color} />
    </Container>
  </LoadingContainer>
);
