import React from 'react';
import { withRouter } from 'react-router-dom';

import LabsTestOnboardingTable from "./LabsTestOnboardingTable";
import AddLabsTests from './AddLabsTests';
import LabsDetailsHeader from './LabsDetailsHeader';

import { fetchPartnerTests } from '../../services/api/lab-partners';

import { toast } from 'react-toastify';

class LabsTestOnboarding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            partnerName: '',
            partnerType: '',
            partnerId: null,
            tableLoading: false,
            tests: [],
        };
    }

    getPartnerTests = () => {
        const partnerId = this.state.partnerId;
        if (isNaN(partnerId) || (partnerId === null)) {
            return;
        }
        this.setState({
            tableLoading: true,
        });
        fetchPartnerTests(partnerId)
            .then((response) => {
                const data = response.body;
                if (data.message === 'success') {
                    this.setState({
                        tests: data.partnerTests,
                    });
                    return;
                }
                throw new Error('Error while fetching tests');
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, partnerId, 'Error while partner\'s tests');
            })
            .finally(() => {
                this.setState({
                    tableLoading: false,
                });
            });
    };

    componentDidMount() {
        const partnerId = parseInt(this.props.match.params.partnerId);
        this.setState({
            partnerId,
        }, this.getPartnerTests);
    }

    render() {
        const { tableLoading, tests, partnerId } = this.state;
        return (
            <React.Fragment>
                <LabsDetailsHeader partnerId={partnerId} type={"Tests"} />
                <AddLabsTests partnerId={partnerId} onComplete={this.getPartnerTests} />
                <LabsTestOnboardingTable partnerId={partnerId} tests={tests} loading={tableLoading} onComplete={this.getPartnerTests} />
            </React.Fragment>
        );
    }
}

export default withRouter(LabsTestOnboarding);
