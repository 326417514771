import React, { memo, useEffect, useState } from 'react';

import {
    UncontrolledPopover,
    PopoverHeader,
    PopoverBody,
} from 'reactstrap';
import styled from 'styled-components';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { DateRangePicker } from 'react-dates';

const Container = styled.div`
    position: relative;
`;

const LabelContainer = styled.div`
    text-decoration: underline;
    color: #474754;
    cursor: pointer;
`;

const DateFilter = ({
    label,
    uniqueId,
    onChooseDate
}) => {

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const handleDateChange = ({startDate, endDate}) => {
        setStartDate(startDate)
        setEndDate(endDate)
        
    }

    useEffect(() => {
        if (startDate && endDate) {
            onChooseDate({startDate, endDate})
        } else if (!startDate && !endDate) {
            onChooseDate({startDate, endDate})
        }
    }, [startDate, endDate])

    const [isFocused, setIsFocused] = useState(null)
    return <Container>
        <LabelContainer
            type='button'
            id={`PopoverLegacy_${label}_${uniqueId}`}
        >
            {label}
        </LabelContainer>
        <UncontrolledPopover
            trigger='legacy'
            placement='bottom'
            target={`PopoverLegacy_${label}_${uniqueId}`}
        >
            <PopoverHeader>{`${label} filter`}</PopoverHeader>
            <PopoverBody>
                <DateRangePicker 
                    startDateId={`startDate_${uniqueId}`}
                    endDateId={`endDate_${uniqueId}`}
                    startDate={startDate}
                    endDate={endDate}
                    onDatesChange={handleDateChange}
                    minimumNights={0}
                    displayFormat={() => 'DD/MM/YYYY'}
                    showClearDates
                    isOutsideRange={() => false}
                    onFocusChange={setIsFocused}
                    focusedInput={isFocused}
                />
            </PopoverBody>
        </UncontrolledPopover>
    </Container>
}

export default memo(DateFilter)