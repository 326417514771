import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavBar from "../layout/NavBar";
import LabRxRequestsTable from './LabRxRequestsTable';
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

class LabRxRequestsHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            focusedInput: null,
            download: 1,
        };
    }

    onDatesChanged = (startDate, endDate) => {
        console.log(startDate, endDate);
        this.setState({ startDate, endDate });
    };

    download = () => {
        console.log(this.state.download, 'Downloading');
        this.setState({download: this.state.download + 1});
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <NavBar />
                </div>
                <div className="row mt-5 pt-1">
                    <div className="col">
                        <h2 className="pl-1">Transcription Requests</h2>
                    </div>
                    <div className="col">
                        <button className="btn btn-primary mt-1" onClick={() => this.props.history.push('/lab-create-rx')}>Create new transcription</button>
                    </div>
                    <div className="col">
                        <button className="btn btn-primary mt-1" onClick={this.download}>Download MIS</button>
                    </div>
                     <div className="col">
                        <button className="btn btn-primary mt-1" onClick={() => this.props.history.push('/labs-reimbursements')}>Carts For Reimbursement</button>
                    </div>
                    <div className="col-4">
                        <DateRangePicker
                            displayFormat={() => "DD/MM/YYYY"}
                            startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                            startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                            endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                            endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                            isOutsideRange={() => false}
                            onDatesChange={({ startDate, endDate }) => this.onDatesChanged(startDate, endDate)} // PropTypes.func.isRequired,
                            focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                            onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                        />
                    </div>
                </div>
                <LabRxRequestsTable startDate={this.state.startDate} endDate={this.state.endDate} download={this.state.download}/>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withRouter(connect(
    mapStateToProps,
)(LabRxRequestsHome));





