export const defaultState = {
  filter: [
    {
      id: 'name',
      value: '',
    },
    {
      id: 'opsDisplayName',
      value: '',
    },
  ],
};

export default function labPartner(state = defaultState, action) {
  switch (action.type) {
    case 'SET_LAB_LISTING_FILTER': {
      const filter = action.payload.filter;
      return Object.assign({}, state, {
        filter,
      });
    }
    default:
      return state;
  }
}
