import React from 'react';
import { useState } from 'react';
import { PrimaryButton } from '../common/Buttons';
import { ButtonText } from '../common/Text';
import { submitOrderStatus } from '../../services/api/lab-order-details';
import infoIcon from '../../assets/img/info.svg';

const LabsOrderChangeStatusForm = ({ orderId, onClose }) => {
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');
  const [otherReason, setOtherReason] = useState({
    showOtherReason: false,
    otherReasonText: '',
  });
  const [confirm, setConfirm] = useState(false);

  const submitStatus = () => {
    const body = {
      orderId,
      status,
      reason,
    };
    submitOrderStatus(body)
      .then((res) => {
        if (res.data.message === 'success') {
          onClose();
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="mt-8">
      <div>
        <p
          style={{ marginBottom: '8px' }}
          className="text-title-m text-[#3A3A4A] font-semibold"
        >
          Select Status
        </p>
        <select
          onChange={(e) => setStatus(e.target.value)}
          style={{
            width: '500px',
            outline: 'none',
            padding: '0.75rem 1rem',
            border: '1px solid #E4E4E4',
            borderRadius: '6px',
          }}
        >
          <option
            selected
            disabled
            hidden
            className="text-body-s text-txtsecondary-shades8 font-semibold"
          >
            Select Status
          </option>
          <option value="sample-collected">Sample Collected</option>
          <option value="order-confirm">Partner Confirmed</option>
          <option value="place-order">Order Placed</option>
        </select>
        <p
          style={{ margin: '8px 0px' }}
          className="text-title-m text-[#3A3A4A] font-semibold"
        >
          Select Reason
        </p>
        <select
          onChange={(e) => {
            setReason(e.target.value);
            if (e.target.value === 'other')
              setOtherReason((prev) => {
                return { ...prev, showOtherReason: true };
              });
            else
              setOtherReason((prev) => {
                return { ...prev, showOtherReason: false };
              });
          }}
          style={{
            width: '500px',
            outline: 'none',
            padding: '0.75rem 1rem',
            border: '1px solid #E4E4E4',
            borderRadius: '6px',
          }}
        >
          <option
            selected
            disabled
            hidden
            className="text-body-s text-txtsecondary-shades8 font-semibold"
          >
            Select Reason
          </option>
          <option value="User requested">Asked By User</option>
          <option value="Marked by mistake">Marked by mistake</option>
          <option value="Wrong reports uploaded">Wrong reports uploaded</option>
          <option value="other">Other</option>
        </select>
        {otherReason.showOtherReason && (
          <>
            <input
              type="textarea"
              style={{
                marginTop: '8px',
                display: 'block',
                width: '500px',
                outline: 'none',
                padding: '0.75rem 1rem',
                border: '1px solid #EBEAF5',
                borderRadius: '6px',
              }}
              onChange={(e) => {
                e.persist();
                setOtherReason((prev) => {
                  return {
                    ...prev,
                    otherReasonText: e.target.value,
                  };
                });
              }}
            />
            <div className="flex items-center mt-8px">
              <img src={infoIcon} alt="info" />
              <p
                style={{
                  marginLeft: '8px',
                  marginBottom: '0',
                  color: '#3F8CFF',
                }}
                className="text-chip text-secondary-shades6 font-medium ml-6px"
              >
                This is mandetory
              </p>
            </div>
          </>
        )}
        <div className="flex items-center" style={{ marginTop: '24px' }}>
          <input
            type="checkbox"
            onChange={(e) => {
              e.persist();
              setConfirm(e.target.checked);
            }}
          />
          <p
            className="text-body-l font-medium"
            style={{ color: '#585969', marginBottom: '0', marginLeft: '6px' }}
          >
            Yes, change the order status to the previous status.
          </p>
        </div>
        <PrimaryButton
          className="mt-3"
          disabled={
            !(
              status &&
              reason &&
              confirm &&
              (!otherReason.showOtherReason ||
                (otherReason.showOtherReason && otherReason.otherReasonText))
            )
          }
          onTap={() => submitStatus()}
        >
          <ButtonText>Update Status</ButtonText>
        </PrimaryButton>
      </div>
    </div>
  );
};

export default LabsOrderChangeStatusForm;
