import React, { Component } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import ReactTable from 'react-table';
import StyledTappable from '../common/StyledTappable';
import {
  OuterComponent,
  customStyles,
  CloseModal,
} from '../common/modal/StyledComponent';
import ResultModal from '../common/modal/ResultModal';

class OnlineConsultConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
    };
  }
  tableColumns = [
    {
      accessor: 'phone',
      sortable: false,
      Header: 'MOBILE_NO',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'empName',
      sortable: false,
      Header: 'EMP_NAME',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'dob',
      filterable: false,
      Header: 'DOB',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'errorMessage',
      filterable: false,
      Header: 'Error Message',
      style: { whiteSpace: 'unset' },
    },
  ];
  render() {
    return (
      <Modal isOpen toggle={this.props.closeModal} style={customStyles}>
        <OuterComponent>
          <div style={{ margin: 10 }}>
            <p className="text-success">Success</p>
            <StyledTappable onTap={this.props.closeModal}>
              <CloseModal />
            </StyledTappable>
          </div>
          <ModalBody>
            {this.props.invalidRecord && this.props.invalidRecord.length > 0 ? (
              <ReactTable
                data={this.props.invalidRecord}
                columns={this.tableColumns}
                defaultPageSize={5}
                className="-striped -highlight"
              />
            ) : (
              <p>All consultations booked successfully</p>
            )}
          </ModalBody>
        </OuterComponent>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
      </Modal>
    );
  }
}

export default OnlineConsultConfirmModal;
