import React, { Component } from 'react';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import Select from 'react-select';
import { Label, Button } from 'reactstrap';
import {
  fetchWalletDetailsForUser,
  walletTransactions,
} from '../../services/api/opd-wallet-details';
import { fetchUsersRelative } from '../../services/api/offline-consult';
import ReactTable from 'react-table';
import jsonToCsv from '../../utils/jsonToCsv';
import FileSaver from 'file-saver';

class OpdWallet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      showTable: true,
      walletData: [],
      tableColumns: [],
      relativeResult: [],
      selectedOption: null,
      showWalletDetails: false,
    };
  }

  checkActionRequest = (actionRequest) => {
    switch (actionRequest) {
      case 'wallet-transactions': {
        this.setState({
          tableColumns: [
            {
              Header: 'Wallet-transactions',
              columns: [
                {
                  Header: 'Transaction ID',
                  accessor: 'id',
                  width: 150,
                },
                {
                  Header: 'Amount',
                  accessor: 'Amount',
                  width: 150,
                  Filter: ReactTableInputFilter,
                },
                {
                  Header: 'Type',
                  accessor: 'type',
                  width: 150,
                },
                {
                  Header: 'Date',
                  accessor: 'formattedDate',
                  width: 150,
                  Filter: ReactTableInputFilter,
                },
                {
                  Header: 'Source ID',
                  accessor: 'sourceId',
                  width: 150,
                },
              ],
            },
          ],
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  componentDidMount() {
    const userId = this.props.userId;
    this.usersRelative(userId);
    this.fetchWalletDetails(userId);
    this.checkActionRequest(this.props.actionRequest);
  }

  componentDidUpdate = (prevProps, prevState) => {
    console.log({ prevProps, prevState, props: this.props });
    if (prevProps.actionRequest !== this.props.actionRequest) {
      this.checkActionRequest(this.props.actionRequest);
      this.fetchTableData();
    }
    if (prevProps.userId !== this.props.userId) {
      this.setState({ data: [] });
    }
    if (this.state.selectedOption !== prevState.selectedOption) {
      console.log(this.state.selectedOption);
      const userId = this.props.userId;
      const relativeId = this.state.selectedOption
        ? this.state.selectedOption.value
        : null;
      this.fetchWalletDetails(userId, relativeId);
      this.fetchWalletTransactions(userId, relativeId).then(() => {
        this.render();
      });
    }
  };

  usersRelative = (userId) => {
    fetchUsersRelative(userId).then((response) => {
      const relatives = [
        {
          value: null,
          label: 'Self',
        },
      ].concat(
        response.data.map((ele) => ({
          label: `${ele.relativeName}(${ele.relationName})`,
          value: ele.relativeId,
        }))
      );
      this.setState({
        relativeResult: relatives,
      });
    });
  };

  relativeSelect = (selectedOption) => {
    console.log(selectedOption, 'selected');
    this.setState({
      selectedOption,
    });
  };

  fetchWalletDetails = (userId, relativeId) => {
    fetchWalletDetailsForUser(userId, relativeId)
      .then((response) => {
        console.log(response, 'fetchWalletDetailsForUser');
        if (!response) {
          throw new Error('Something went wrong');
        }
        if (response.message === 'success') {
          this.setState({
            showWalletDetails: true,
            hasList: response.hasList,
            walletData: response.response,
          });
          if (
            !response.hasList &&
            response.response &&
            response.response[0] &&
            response.response[0].response
          ) {
            this.setState({
              walletData: response.response[0].response,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  fetchWalletTransactions = (userId, relativeId) => {
    return walletTransactions(userId, relativeId)
      .then((response) => {
        console.log(response, '[transactions]');
        if (response.message === 'success') {
          this.setState({
            data: response.response,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.log(err, '[transactions]');
      });
  };

  fetchTableData = () => {
    this.setState({
      loading: true,
    });

    const userId = this.props.userId;
    const relativeId = this.state.selectedOption
      ? this.state.selectedOption.value
      : null;

    walletTransactions(userId, relativeId)
      .then((responseBody) => {
        if (responseBody.message === 'warning') {
          this.setState({
            data: [],
            showTable: false,
          });
        }
        console.log(
          responseBody,
          '[fetchTableData][walletTransactions][response]'
        );
        if (responseBody.message === 'success') {
          this.setState({
            loading: false,
            data: responseBody.response,
            showTable: true,
          });
        }
      })
      .catch((err) => {
        console.log({ err }, '[fetchTableData][walletTransactions][err]');
      });
  };

  onFetchData = (state) => {
    console.log(state, 'state after render');
    this.fetchTableData();
  };

  showTable = () => {
    if (this.state.showTable) {
      return (
        <ReactTable
          data={this.state.data}
          columns={this.state.tableColumns}
          loading={this.state.loading}
          onFetchData={this.onFetchData}
          className="-striped -highlight"
          defaultPageSize={10}
        />
      );
    } else {
      return <h1> No Records </h1>;
    }
  };

  downloadCsv = () => {
    console.log(this.state.data, this.state.showTable, 'downloadCsv');
    if(this.state.showTable && this.state.data.length > 0) {
      const data = this.state.data;
      const csv = jsonToCsv(data);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, this.props.fileName || 'exports.csv');
    }
  };

  render() {
    const { walletData, relativeResult, showWalletDetails, hasList } =
      this.state;
    return (
      <div className="bg-white">
        {showWalletDetails &&
        this.props.actionRequest === 'wallet-transactions' ? (
          <div className="container-fluid">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <Label>Relative ID</Label>
                  <Select
                    isSearchable={false}
                    defaultValue={
                      relativeResult.length > 0 && relativeResult[0]
                    }
                    value={this.state.selectedOption}
                    options={relativeResult}
                    onChange={this.relativeSelect}
                  />
                </div>
              </div>
            </div>
            <br></br>
            {hasList ? (
              walletData.map((w) => {
                return (
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col">
                          <h5 className="font-weight-bold">{w.name}</h5>
                          <h6>
                            {w.response.length > 0
                              ? `${w.response[0].primaryAvailableBalance}/${w.response[0].primaryTotalBalance}`
                              : null}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {w.response.map((ele) => (
                          <div
                            className="col"
                            key={`${ele.id}-${ele.serviceKey}`}
                          >
                            <h5 className="font-weight-bold">
                              {ele.serviceKeyArray.join(', ')}
                            </h5>
                            <h6>
                              {ele.availableBalance}/{ele.totalBalance}
                            </h6>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="card">
                <div className="card-header">
                  <div className="row">
                    <div className="col">
                      <h5 className="font-weight-bold">Primary Wallet</h5>
                      <h6>
                        {walletData.length > 0
                          ? `${walletData[0].primaryAvailableBalance}/${walletData[0].primaryTotalBalance}`
                          : null}
                      </h6>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    {walletData.map((ele) => (
                      <div className="col" key={`${ele.id}-${ele.serviceKey}`}>
                        <h5 className="font-weight-bold">
                          {ele.serviceKeyArray?.join(', ')}
                        </h5>
                        <h6>
                          {ele.availableBalance}/{ele.totalBalance}
                        </h6>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : null}
        <br></br>
        {this.state.showTable === true && this.props.showCSVDownload === 1 && (
          <>
            <Button onClick={this.downloadCsv}>CSV</Button>
            <br></br>
          </>
        )}
        {this.showTable()}
      </div>
    );
  }
}

export default OpdWallet;
