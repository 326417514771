import React from 'react';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter } from 'reactstrap';

import { claimRequest } from '../../services/api/offline-consult';
import { toast } from 'react-toastify';

export default class ClaimRequestModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };

    }

    onSubmit = () => {
        const { requestId, onClose } = this.props;
        this.setState({
            loading: false,
        });
        return claimRequest(requestId)
            .then((response) => {
                if (response.message === 'success') {
                    toast.success('Successfully Claimed Request');
                    return;
                }
                throw new Error('Error in endpoint');
            })
            .catch((err) => {
                console.error(err, 'Error while claiming request');
                toast.error('Error while claiming the request');
            })
            .finally(() => {
                this.setState({
                    loading: false,
                }, onClose);
            });
    };

    render() {
        const className = 'bg-info';
        const { isOpen, requestId, onClose } = this.props;
        const { loading } = this.state;
        return (
            <Modal isOpen={isOpen} toggle={onClose}>
                <ModalHeader className={className} toggle={onClose}>
                    Claim Request ({requestId})
                </ModalHeader>
                <ModalBody>Are you sure want to claim the request?</ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.onSubmit} disabled={loading}>
                        {loading ? 'Loading...' : 'Claim'}
                    </Button>
                    {!loading && (
                        <Button color="secondary" onClick={onClose} disabled={loading}>
                            Cancel
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}
