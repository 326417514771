import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { Table , Divider,DatePicker, Space } from 'antd';
import moment from 'moment';
import { FaTimes } from 'react-icons/fa';
const { RangePicker } = DatePicker;
const customStylesModal = {
    content: {
      left: '40%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      width: '80%',
      height: '50%',
      transform: 'translate(-40%, -10%)',
      position: 'absolute',
      top: '30%',
      backgroundColor: 'white',
      padding: '10px',
    },
  };
  const CrossIcon = styled(FaTimes)`
  font-size: 1.5rem;
  color: white;
`;

const pastClaimcolumns = [
    {
      title: 'RID',
      dataIndex: 'rId',
      render:(value,row,index)=>{
        return(
          <a href= {`/reimburse-claim/${value}`}
          target='_blank'
          >
            {value}
          </a>
        )
      }
    },
    {
      title: 'Request Date',
      dataIndex: 'requestDate',
      render:(value,row,index)=>{
        return(
        <p>
          {value?moment(value,'YYYY-MM-DD').format('YYYY-MM-DD'):'N.A'}
        </p>
        )
      }
    },
    {
      title: 'Reimbursement Type',
      dataIndex: 'reimbursementType',
      render: (value, row, index) => {
        return(
          <p>
            {value?value:'N.A'}
          </p>
        )
      }
    },
    {
      title: 'Chief complaint/Probale diagnosis',
      dataIndex: 'probableDiagnosis',
      render: (value, row, index) => {
        return(
          <p>
            {value?value:'N.A'}
          </p>
        )
      }
    },
    {
      title: 'Invoice date',
      dataIndex: 'invoiceDate',
      render: (value, row, index) => {
        return(
          <p>
            {value? moment(value,'YYYY-MM-DD').format('YYYY-MM-DD'):'N.A'}
            
          </p>
        )
      }
    },
    {
      title: 'Claim status',
      dataIndex: 'claimStatus',
      render: (value, row, index) => {
        return(
          <p>
            {value?value:'N.A'}
          </p>
        )
      }
  
    },
    {
      title: 'Rejection reason(if any)',
      dataIndex: 'rejectionReason',
      render: (value, row, index) => {
        return(
          <p>
            {value?value:'N.A'}
          </p>
        )
      }
    },
  ];

  

export default function PastClaims ({isOpen,dataSource,onClosePastClaimsModal,selectedDate}){

  
    return(
        <Modal isOpen={isOpen}
        style={customStylesModal}
        onRequestClose={onClosePastClaimsModal}
        >
          <div className='row'
          >
            
           <div className='col-5 col-md-5 col-lg-5'>
            <h3 style={{fontWeight:'bold'}}>Past Claims</h3>
            </div> 
            <div className='col-5'>
            <div className='flex'>
              <p className='mr-1'>Date range- </p>
            <Space direction="vertical" size={12}>
    <RangePicker
    onChange={selectedDate}
    />
    </Space>
    </div>
            </div>
            <div className='col-2 m-0'>
            <button  className='float-right'
            style={{borderRadius:'50%',backgroundColor:'grey',marginTop:'0px'}}
            onClick={onClosePastClaimsModal}
            >
                    <CrossIcon />
                  </button>
            </div>
            </div>
      <div className='mt-2'>
      <Table columns={pastClaimcolumns} dataSource={dataSource} size="middle" />

      </div>

        </Modal> 
    )
}