import React from 'react';

import { Container, ContainerItem } from './StyledComponents';

import LabOrderPartnerComms from "./LabOrderPartnerComms";
import LabRequestPayoutConfirmation from "./LabRequestPayoutConfirmation";

import LabOrderActivities from './LabOrderActivities';
import PolicyDetails from "../policy-details/PolicyDetails";


export default class AhcRequestsTableSubComponents extends React.Component {
    render() {
        const { orderInfo } = this.props;
        return (
            <Container>
                <ContainerItem style={{ flex: 2 }}>
                    <LabRequestPayoutConfirmation orderId={orderInfo.orderId} partnerId={orderInfo.partnerId}/>
                    <br />
                    <LabOrderPartnerComms orderId={orderInfo.orderId} />
                </ContainerItem>
            </Container>
        );
    }
}
