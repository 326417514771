  import React from 'react';
  import Link from 'valuelink';
  import { Input } from 'valuelink/tags';
  import Modal from '../common/Modal';
  import LoadingComponent from '../common/LoadingComponent';
  import { getPolicyDetails } from '../../services/api/policy-details';
  import { FaEdit } from 'react-icons/fa';
  import { updateRelativeInfo } from '../../services/api/policy-details';
  import { fetchWalletDetailsForUser } from '../../services/api/opd-wallet-details';
  import { toast } from 'react-toastify';
  import moment from 'moment';
  import Select from 'react-select';
  class PolicyDetailsRelative extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        editing: false,
        loading: false,
        cardOpenRelativeIds: [],
        relativesWalletDetails: [],
        showWalletModal: false,
        phone: '',
        gender:'',
        realtionArray:[],
        relationId:null,
        dob:moment(
          `${this.props.relatives[0]? this.props.relatives[0].dob : ''}`,'DD MMM YYYY')
          .format('YYYY-DD-MM'),
        updateInfoModal: {
          isOpen: false,
          name: '',
          userId: null,
          relativeId: null,
        },
      };
    }
    fetchWalletDetails = (userId, relativeId) => {
      return fetchWalletDetailsForUser(userId, relativeId)
        .then((response) => {
          console.log(response.response, 'fetchWalletDetailsForUser');
          if (!response) {
            throw new Error('Something went wrong');
          }
          if (response.message === 'success') {
            this.setState({
              relativesWalletDetails: response.response,
            });
          }
          if (response.message === 'warning') {
            this.setState({
              relativesWalletDetails: [],
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    showRelativeWalletDetails = (element) => {
      const { relativesWalletDetails } = this.state;
      return (
        <div>
          <Modal
            header={
              <div className="font-weight-bold">
                Wallet Details For {element.name}
              </div>
            }
            isOpen={this.state.showWalletModal}
          >
            {relativesWalletDetails.length > 0 ? (
              <div className="container-fluid">
                {relativesWalletDetails.map((wallet,index) => {
                  return (
                    <div className="row">
                      {wallet.response.length > 0 && 
                      <>
                        <div className="col-md-9 col-md-push-3">
                          <h5 className="font-weight-bold">Primary Wallet:</h5>
                        </div>
                        <div className="col-md-3 col-md-pull-9">
                          <h6>
                            {`${wallet.response[0].primaryAvailableBalance}/${wallet.response[0].primaryTotalBalance}`}
                          </h6>
                        </div>
                      </>}
                      {wallet.response.length>0 && wallet.response.map((ele)=>{
                        return ( 
                          <>
                            <div className="col-md-9 col-md-push-3">
                              <h5 className="font-weight-bold">
                                {ele.serviceKeyArray.join(', ')}:
                              </h5>
                            </div>
                            <div className="col-md-3 col-md-pull-9">
                              <h6>
                                {`${ele.availableBalance}/${ele.totalBalance}`}
                              </h6>
                            </div>
                          </>
                        )
                      })}
                    </div>
                    )                  
                })}
                <div className="row">
                  <div className="col">
                    <button
                      className="btn btn-block btn-danger"
                      onClick={this.closeWalletModal}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  <p>No Wallet Found</p>
                </div>
                <div className="row">
                  <button
                    className="btn btn-block btn-danger"
                    onClick={this.closeWalletModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
          </Modal>
        </div>
      );
    };

    handleToggleRelativeDetails = (relativeId) => {
      if (relativeId) {
        this.fetchWalletDetails(this.props.userId, relativeId);
      }
      if (this.state.cardOpenRelativeIds.includes(relativeId)) {
        const cardOpenRelativeIds = this.state.cardOpenRelativeIds.filter(
          (id) => {
            return id !== relativeId;
          }
        );
        this.setState({
          cardOpenRelativeIds,
        });
      } else {
        this.setState({
          cardOpenRelativeIds: [...this.state.cardOpenRelativeIds, relativeId],
        });
      }
    };

    showMoreRelativesDetails = (element) => {
      if (this.state.cardOpenRelativeIds.includes(element.relativeId)) {
        return (
          <div className="card-body">
            <div className="row">
              <div className="col-5">
                <label>DOB:</label>
              </div>
              <div className="col-6">
                <label style={{ wordBreak: 'break-word' }}>{element.dob}</label>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <label>Phone:</label>
              </div>
              <div className="col-6">
                <label style={{ wordBreak: 'break-word' }}>{element.phone}</label>
              </div>
            </div>
            <div className="row">
              <div className="col-5">
                <label>Relation:</label>
              </div>
              <div className="col-6">
                <label style={{ wordBreak: 'break-word' }}>
                  {element.relation}
                </label>
              </div>
            </div>
            {this.showRelativeWalletDetails(element)}
          </div>
        );
      } else {
        return <React.Fragment />;
      }
    };
    fetchRelation(){
      const params={
        userId:this.props.userId
      }
      return getPolicyDetails (params)
      .then((response)=>{
        const realtionArray= response.relationInfo.map((r)=>{
          return{
            value:r.relationId,
            label:r.title
          }
        });
        this.setState({
          realtionArray
        })
      })
      .catch((err) => {
        this.setState({
          realtionArray: [],
        });
          console.error(err, 'Error while fetching sponsors');
          toast.error(err.message);
      }) 
    }


    onOpen = (relative, userId) => {
      console.log('in open',relative,'userId',userId);
      this.setState({
        updateInfoModal: {
          isOpen: true,
          relativeId: relative.relativeId,
          userId:userId,
          name: relative.name,
        
        },
        phone: relative.phone,
        dob:relative.dob
      });
      this.fetchRelation()
    };
    
    onConfirm = () => {
      console.log(
        'confirm modal called',
        this.state.updateInfoModal,
      );
      const { userId } = this.props;
      const relativeId = this.state.updateInfoModal.relativeId;
      const phone = this.state.phone;
      const name = this.state.updateInfoModal.name;
      const dob= this.state.dob;
      const relationId= this.state.relationId
      this.setState({
        loading: true,
      });
    return  updateRelativeInfo(userId, phone, relativeId, name, relationId,dob)
        .then((response) => {
          console.log(response);
          if (response.message === 'success') {
            toast.success(`Updated ${name}'s Details`);
            this.onClose();
          } else {
            throw new Error(
              response.errorMessage || 'Not able to update relative phone number'
            );
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error(err.message);
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    };
    onClose = () => {
      this.setState(
        {
          phone: '',
          gender: '',
          dob: null,
          updateInfoModal: {
            isOpen: false,
            name: '',
            userId: null,
            relativeId: null,
          },
        },
        this.props.onUpdateComplete
      );
    };
    openWalletModal = () => {
      this.setState({
        showWalletModal: true,
      });
    };
    closeWalletModal = () => {
      this.setState({
        showWalletModal: false,
      });
    };
    handleChange = (event) => {
      this.setState({ relationId: event.value, selectedOption: event });
    };

    render() {
      const elements = [];
      const newPhoneLink = Link.state(this, 'phone');
      const newNameLink = Link.state(this, 'updateInfoModal').at('name');
      const newDobLink= moment(this.state.dob).format('YYYY-MM-DD')
      this.props.relatives.forEach((element) => {
        elements.push(
          <div>
            <div
              className={`card mb-2 ${
                element.registered ? 'text-white bg-success' : ''
              }`}
            >
              <div
                className="card-header"
                onClick={() => {
                  this.handleToggleRelativeDetails(element.relativeId);
                }}
              >
                <div className="row no-gutters">
                  <div className="col">
                    <label style={{ wordBreak: 'break-word' }}>
                      {element.name}
                    </label>
                  </div>
                  <div className="col text-right">
                    <button
                      className="btn btn-info btn-block"
                      onClick={this.openWalletModal}
                    >
                      Wallet
                    </button>
                  </div>
                  <div className="col text-right">
                    {/* //{element.allowPhoneUpdate && this.props.allowEdit  ( */}
                      <FaEdit
                        onClick={() => this.onOpen(element, this.props.userId)}
                      />
                    {/* )} */}
                    &nbsp;&nbsp;&nbsp;
                    {element.opdCovered ? (
                      <span className="badge badge-success">
                        <b>OPD</b>
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
              {this.showMoreRelativesDetails(element)}
            </div>
          </div>
        );
      });
      if (elements.length) {
        return (
          <React.Fragment>
            {elements}
            <Modal
              header={`Update phone number for ${this.state.updateInfoModal.name}`}
              isOpen={this.state.updateInfoModal.isOpen}
            >
              <div className="row">
                <div className="col">
                  <label>Phone number</label>
                </div>
                <div className="col">
                  <Input className="form-control" valueLink={newPhoneLink} />
                </div>
              </div>
              <br></br>
              <div className="row">
                <div className="col">
                  <label>Name</label>
                </div>
                <div className="col">
                  <Input className="form-control" valueLink={newNameLink} />
                </div>
              </div>
              <br></br>
              <div className='row'>
              <div className="col">
                  <label>Relation</label>
                </div>
                <div className='col' style={{
              width: '20%',
              height: '40px',
              fontSize: '12px',
            }}>
                <Select 
                     onChange={this.handleChange}
                    placeholder="Select relation"
                   options={this.state.realtionArray}
                  value={this.state.selectedOption}
                   />
                </div>
              </div>
              <br></br>
              <div className='row'>
              <div className='col'>
              <label>DOB</label>
              </div>
            <div className='col'>  
    <input type='date' className='form-control'
                  maxLength={'255'}
                  name="dob"
                  value={newDobLink}
                  onChange={(e)=>{this.setState({dob:e.target.value})}}/>
            </div>
              </div>
              <br />
              <div className="row justify-content-end">
                <div className="col-4">
                  {this.state.loading ? (
                    <LoadingComponent />
                  ) : (
                    <button
                      className="btn btn-block btn-success"
                      onClick={this.onConfirm}
                    >
                      Update
                    </button>
                  )}
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-block btn-danger"
                    onClick={this.onClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Modal>
          </React.Fragment>
        );
      } else {
        return (
          <div>
            <label>No relative added yet.</label>
          </div>
        );
      }
    }
  }

  export default PolicyDetailsRelative;
