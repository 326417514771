import React from 'react';
import {
  MdCheckCircle,
  MdCancel,
  MdMoneyOff,
  MdStars,
  MdDateRange,
  MdHealing,
} from 'react-icons/md';
import { Tooltip } from 'reactstrap';
import { debounce } from '../../utils/debounce';
const getAllStatus = () => ({
  ORDER_PLACED: 'place-order',
  ORDER_CONFIRM: 'order-confirm',
  ORDER_RESCHEDULED: 'order-reschedule',
  SAMPLE_COLLECTED: 'sample-collected',
  ORDER_COMPLETED: 'order-completed',
  ORDER_CANCELLED: 'order-cancelled',
  ORDER_REFUNDED: 'order-refunded',
});

class OrderActionButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cancelTooltipOpen: false };
  }

  handleOnClick = (actionType, actionName, enabled) => {
    if (!enabled) {
      return;
    }
    const { orderId, status, collectionDate, slotId } = this.props;
    this.props.onClick(
      orderId,
      actionType,
      actionName,
      status,
      collectionDate,
      slotId
    );
  };

  getDisabledStyle = (enabled) => {
    return !enabled ? { opacity: '0.3' } : '';
  };

  renderToolTip = (enabled, id, actionItem) => {
    if (!enabled) return '';
    return (
      <Tooltip
        placement="top"
        target={id}
        trigger="hover"
        toggle={() => {
          const { cancelTooltipOpen } = this.state;
          this.setState({
            cancelTooltipOpen: id === cancelTooltipOpen ? false : id,
          });
        }}
        isOpen={this.state.cancelTooltipOpen === id}
      >
        {actionItem.name}
      </Tooltip>
    );
  };

  renderActionItem = (orderId, type, actionItem) => {
    const id = `action-btn-${orderId}-${type}`;
    const enabled = !!actionItem;

    switch (type) {
      case getAllStatus().ORDER_CONFIRM:
        return (
          <span>
            <MdCheckCircle
              style={this.getDisabledStyle(enabled)}
              className="mr-1"
              size={30}
              id={id}
              onClick={() =>
                this.handleOnClick(
                  type,
                  enabled ? actionItem.name : null,
                  enabled
                )
              }
            />
            {this.renderToolTip(enabled, id, actionItem)}
          </span>
        );
      case getAllStatus().ORDER_CANCELLED:
        return (
          <span>
            <MdCancel
              style={this.getDisabledStyle(enabled)}
              className="mr-1"
              size={30}
              id={id}
              onClick={() =>
                this.handleOnClick(
                  type,
                  enabled ? actionItem.name : null,
                  enabled
                )
              }
            />
            {this.renderToolTip(enabled, id, actionItem)}
          </span>
        );
      case getAllStatus().ORDER_REFUNDED:
        return (
          <span>
            <MdMoneyOff
              style={this.getDisabledStyle(enabled)}
              className="mr-1"
              size={30}
              id={id}
              onClick={() =>
                this.handleOnClick(
                  type,
                  enabled ? actionItem.name : null,
                  enabled
                )
              }
            />
            {this.renderToolTip(enabled, id, actionItem)}
          </span>
        );
      case getAllStatus().ORDER_COMPLETED:
        return (
          <span>
            <MdStars
              style={this.getDisabledStyle(enabled)}
              className="mr-1"
              size={30}
              id={id}
              onClick={() =>
                this.handleOnClick(
                  type,
                  enabled ? actionItem.name : null,
                  enabled
                )
              }
            />
            {this.renderToolTip(enabled, id, actionItem)}
          </span>
        );
      case getAllStatus().ORDER_RESCHEDULED:
        return (
          <span>
            <MdDateRange
              style={this.getDisabledStyle(enabled)}
              className="mr-1"
              size={30}
              id={id}
              onClick={() =>
                this.handleOnClick(
                  type,
                  enabled ? actionItem.name : null,
                  enabled
                )
              }
            />
            {this.renderToolTip(enabled, id, actionItem)}
          </span>
        );
      case getAllStatus().SAMPLE_COLLECTED:
        return (
          <span>
            <MdHealing
              style={this.getDisabledStyle(enabled)}
              className="mr-1"
              size={30}
              id={id}
              onClick={() =>
                this.handleOnClick(
                  type,
                  enabled ? actionItem.name : null,
                  enabled
                )
              }
            />
            {this.renderToolTip(enabled, id, actionItem)}
          </span>
        );
      default:
        return '';
    }
  };

  getActionFromOrder = (type) => {
    const { actions } = this.props;
    const found = actions.find((o) => o.actionType === type);
    return !found ? false : found;
  };

  renderAllActions = () => {
    const { orderId } = this.props;
    return (
      <div className="ml-1 flex flex-wrap">
        <div className="flex my-1">
          {this.renderActionItem(
            orderId,
            getAllStatus().ORDER_CONFIRM,
            this.getActionFromOrder(getAllStatus().ORDER_CONFIRM)
          )}
          {this.renderActionItem(
            orderId,
            getAllStatus().ORDER_RESCHEDULED,
            this.getActionFromOrder(getAllStatus().ORDER_RESCHEDULED)
          )}
          {this.renderActionItem(
            orderId,
            getAllStatus().SAMPLE_COLLECTED,
            this.getActionFromOrder(getAllStatus().SAMPLE_COLLECTED)
          )}
        </div>
        <div className="flex my-1">
          {this.renderActionItem(
            orderId,
            getAllStatus().ORDER_CANCELLED,
            this.getActionFromOrder(getAllStatus().ORDER_CANCELLED)
          )}
          {this.renderActionItem(
            orderId,
            getAllStatus().ORDER_COMPLETED,
            this.getActionFromOrder(getAllStatus().ORDER_COMPLETED)
          )}
          {this.renderActionItem(
            orderId,
            getAllStatus().ORDER_REFUNDED,
            this.getActionFromOrder(getAllStatus().ORDER_REFUNDED)
          )}
        </div>
      </div>
    );
  };

  render() {
    const { actions, orderId } = this.props;
    return (
      <React.Fragment>
        <div className="container-fluid">
          <div className="row">{this.renderAllActions()}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default OrderActionButton;
