import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Row,
    Col,
    Container,
} from 'reactstrap';

const DentalModalCancellation = ({
    requestId,
    submitModal,
    closeModal,
    onComplete,
    modalIsOpen,
    className,
    mdpAppointmentId
}) => {

    const [value, setValue] = useState('');

    useEffect(() => {
        setValue('')
    }, [requestId])

    const handleChange = (event) => {
        const { value } = event.target
        setValue(value)
    }

    // const handleRemarksInputChange = (event) => {
    //     setRemarks(event.target.value)
    // }

    console.log(mdpAppointmentId, '[mdpAppointmentId]')

    const handleSubmit = () => {
        if(!value) {
            return toast.error('No value/remark selected')
        }

        return submitModal(value)
        .then(() => {
            closeModal()
            onComplete()
        })
    }


    return <Modal
        isOpen={modalIsOpen}
        toggle={closeModal}
        className={className}>
            <ModalHeader className="bg-primary" toggle={closeModal}>
            Sure you want to cancel? (Request Id: {requestId})
        </ModalHeader>
        <ModalBody>
            <Container>
                <Row>
                    <Col>
                        <Row>
                            <label>Reason for cancellation:</label>
                        </Row>
                        <br />
                        <Row>
                            <div>
                                <select onChange={handleChange} value={value}>
                                <option value="">Select Reason</option>
                                <option value="User Requested">User Requested</option>
                                <option value="User want to book for another date">User want to book for another date</option>
                                <option value="Location not serviceable">Location not serviceable</option>
                                <option value="User not comfortable with booked slot">User not comfortable with booked slot</option>
                                <option value="User not available">User not available</option>
                                <option value="Visit Demo">Visit Demo</option>
                                </select>
                            </div>
                        </Row>
                        <br />
                        {/* <Row>
                            <div>
                                <React.Fragment>
                                {(getRemarksSelectOptions() && getRemarksSelectOptions().length) ? (
                                    <select value={remarks} onChange={handleRemarksInputChange}>
                                        {getRemarksSelectOptions().map((option) => (<option value={option}>{option || "Select Remark"}</option>))}
                                    </select>
                                ) : (
                                    <input onChange={handleRemarksInputChange} value={remarks} />
                                )}
                                </React.Fragment>
                            </div>
                        </Row> */}
                    </Col>
                </Row>
            </Container>
        </ModalBody>
        <ModalFooter>
            <Button color="info" onClick={handleSubmit}>
                Cancel
            </Button>
        </ModalFooter>
        </Modal>

};

export default DentalModalCancellation;