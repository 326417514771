import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { FaTimes } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import StyledTappable from '../../components/common/StyledTappable';
import { RegularText } from '../../components/common/Text';
import { getLabOrdersTimeline } from '../../services/api/lab-order-details';
// import {
//   getLabOrdersTimeline,
//   intitiateEmergencyCall,
//   payLabCopay,
//   postSamplePickup,
//   submitFeedback,
// } from '../../services';
// import initializeFreshDesk from '../../utils/freshchat';
// import ErrorModal from '../common/ErrorModal';

const ScreenIcon = styled.img`
  filter: ${(props) =>
    props.type === 'digitisationDelayed' ||
    props.type === 'digitisationRejected' ||
    props.type === 'delayed'
      ? 'brightness(0) saturate(100%) invert(54%) sepia(42%) saturate(985%) hue-rotate(324deg) brightness(102%) contrast(100%)'
      : 'none'};
`;
const EllipseIcon = styled.img`
  filter: ${(props) =>
    props.type === 'digitisationDelayed' ||
    props.type === 'digitisationRejected' ||
    props.type === 'delayed'
      ? 'brightness(0) saturate(100%) invert(54%) sepia(42%) saturate(985%) hue-rotate(324deg) brightness(102%) contrast(100%)'
      : 'none'};
`;

const IconContainer = styled.div`
  padding: 7px 12px;
  align-self: start;
  border: 1px solid
    ${(props) =>
      props.type === 'digitisationRejected' ? '#FF754C' : '#E3E3E3'};
  border-radius: 12px;
  margin-right: 12px;
  text-align: center;
`;

const ModalContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh !important;
  width: 100vw;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  max-width: 400px;
  padding: 1.125rem 1.25rem 1.875rem 1.25rem;
  border-radius: 0.5rem;
  margin: 0 1rem;
  text-align: center;
`;
const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
`;

const ModalIcon = styled.img`
  width: 5.7rem;
`;

const ModalText = styled(RegularText)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin: 0.625rem 0rem;
`;

const OrderStatus = ({ orderId, userId }) => {
  const { authToken } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);

  const [modalDetails, setModalDetails] = useState({
    showModal: false,
    modalText: '',
  });
  const [disclaimer, setDisclaimer] = useState('');

  const hideModal = () => {
    setModalDetails({ showModal: false, modalText: '' });
  };

  const getOrders = async () => {
    const result = await getLabOrdersTimeline(orderId, userId);

    let lastCheck = false;
    setDisclaimer(result.disclaimer);
    setOrders(
      result.timeline.map((data, i) => {
        if (!lastCheck && !data.checked && i)
          return {
            ...data,
            disabled: true,
          };
        else {
          lastCheck = data.checked;
          return {
            ...data,
            disabled: false,
          };
        }
      })
    );
  };

  useEffect(() => {
    getOrders();
  }, []);

  return (
    <div className="w-full overflow-auto bg-textbox">
      <div className="w-full flex justify-between items-end p-16px border-b border-txtsecondary-shades4">
        <div className="flex justify-center text-title-m font-semibold">
          <p className="ml-4px">Order Status</p>
        </div>
      </div>
      <div className="flex flex-col w-full p-20px">
        <div className="flex text-body-l font-semibold justify-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.2"
              d="M16.6756 2H7.33333C3.92889 2 2 3.92889 2 7.33333V16.6667C2 20.0711 3.92889 22 7.33333 22H16.6756C20.08 22 22 20.0711 22 16.6667V7.33333C22 3.92889 20.08 2 16.6756 2Z"
              fill="#714FFF"
            />
            <path
              d="M7.36866 9.36914C6.91533 9.36914 6.54199 9.74247 6.54199 10.2047V17.0758C6.54199 17.5291 6.91533 17.9025 7.36866 17.9025C7.83088 17.9025 8.20421 17.5291 8.20421 17.0758V10.2047C8.20421 9.74247 7.83088 9.36914 7.36866 9.36914Z"
              fill="#714FFF"
            />
            <path
              d="M12.0357 6.08887C11.5823 6.08887 11.209 6.4622 11.209 6.92442V17.0755C11.209 17.5289 11.5823 17.9022 12.0357 17.9022C12.4979 17.9022 12.8712 17.5289 12.8712 17.0755V6.92442C12.8712 6.4622 12.4979 6.08887 12.0357 6.08887Z"
              fill="#714FFF"
            />
            <path
              d="M16.6402 12.9961C16.178 12.9961 15.8047 13.3694 15.8047 13.8316V17.0761C15.8047 17.5294 16.178 17.9028 16.6314 17.9028C17.0936 17.9028 17.4669 17.5294 17.4669 17.0761V13.8316C17.4669 13.3694 17.0936 12.9961 16.6402 12.9961Z"
              fill="#714FFF"
            />
          </svg>
          <p className="ml-10px">Status Timeline</p>
        </div>
        {!!disclaimer && (
          <div className="text-card-body m-20px px-10px py-8px bg-disclaimer rounded-12">
            {disclaimer}
          </div>
        )}
        <div className="mt-20px">
          {orders.map((order, i) => {
            const cloneOrder = {
              ...order,
            };
            return (
              <Section
                last={i + 1 === orders.length}
                setModalDetails={setModalDetails}
                getOrder={getOrders}
                {...cloneOrder}
              >
                {order.title}
              </Section>
            );
          })}
        </div>
      </div>
      {/* {modalDetails.showModal && (
        <ErrorModal>
          <ModalContainer onClick={hideModal}>
            <ModalBody onTap={hideModal}>
              <CloseContainer onTap={hideModal}>
                <FaTimes />
              </CloseContainer>
              <ModalText>{modalDetails.modalText}</ModalText>
            </ModalBody>
          </ModalContainer>
        </ErrorModal>
      )} */}
    </div>
  );
};

const Section = (data) => {
  const { children, checked, disabled, last, showDetails, cardType } = data;

  return (
    <div className="flex w-full mt-8px">
      <div className="flex flex-col justify-start items-center mr-10px">
        <div>
          {checked && cardType !== 'cancelled' && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1Z"
                fill="#714FFF"
              />
              <path
                d="M10.416 13.399L8.34108 11.4138L7.2002 12.5053L9.93833 15.1251L9.94147 15.1221L10.4413 15.6004L16.8002 9.51319L15.6372 8.40039L10.416 13.399Z"
                fill="white"
              />
            </svg>
          )}
          {!checked && !disabled && cardType !== 'cancelled' && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5Z"
                fill="white"
                stroke="#714FFF"
                stroke-width="3"
              />
            </svg>
          )}
          {disabled && cardType !== 'cancelled' && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2.5C17.2467 2.5 21.5 6.75329 21.5 12C21.5 17.2467 17.2467 21.5 12 21.5C6.75329 21.5 2.5 17.2467 2.5 12C2.5 6.75329 6.75329 2.5 12 2.5Z"
                stroke="#EBEAF5"
                stroke-width="3"
              />
            </svg>
          )}
          {cardType === 'cancelled' && (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1Z"
                fill="#FF754C"
              />
              <path
                d="M15.0573 16L16 15.0573L12.9427 12L16 8.9427L15.0573 8L12 11.0573L8.9427 8L8 8.9427L11.0573 12L8 15.0573L8.9427 16L12 12.9427L15.0573 16Z"
                fill="white"
              />
            </svg>
          )}
        </div>
        {!last && (
          <div className="mt-8px flex h-full">
            {!disabled && checked && (
              <div
                className="border border-primary-shades1 h-full"
                style={{ minHeight: '5vh' }}
              ></div>
            )}
            {!disabled && !checked && (
              <div
                className="border border-primary-shades1 h-full"
                style={{ minHeight: '5vh' }}
              ></div>
            )}
            {disabled && (
              <div
                className="border border-boxCard h-full"
                style={{ minHeight: '5vh' }}
              ></div>
            )}
          </div>
        )}
      </div>
      {(!showDetails || disabled) && (
        <div className="w-full items-end flex flex-col">
          <div
            className={
              disabled
                ? 'py-12px px-16px bg-primary-light w-full text-body-s font-semibold rounded-12 text-sub'
                : 'py-12px px-16px bg-primary-light w-full text-body-s font-semibold rounded-12'
            }
          >
            {children}
          </div>
          <div className="text-card-body text-sub mt-8px">{data.dateTime}</div>
        </div>
      )}
      {showDetails && <Card {...data} children={null}></Card>}
    </div>
  );
};

const Card = (data) => {
  const { cardType } = data;
  if (
    cardType === 'partnerConfirmed' &&
    data.newPartnerName &&
    data.slotDateTime
  )
    return <LabCardUpdate {...data}></LabCardUpdate>;
  else if (cardType === 'partnerConfirmed' && data.newPartnerName)
    return <LabCardUpdatePartner {...data}></LabCardUpdatePartner>;
  else if (cardType === 'partnerConfirmed' && data.slotDateTime)
    return <LabCardUpdateTime {...data}></LabCardUpdateTime>;
  else if (cardType === 'partnerConfirmed')
    return <PartnerConfirmedCard {...data}></PartnerConfirmedCard>;
  else if (cardType === 'samplePickUp')
    return <SamplePickupCard {...data}></SamplePickupCard>;
  else if (cardType === 'sampleProcessing')
    return <SampleProcessingCard {...data}></SampleProcessingCard>;
  else if (cardType === 'cartCreated')
    return <CartCreated {...data}></CartCreated>;
  else if (cardType === 'partialReportGenerated')
    return <PartialReportCard {...data}></PartialReportCard>;
  else if (cardType === 'delivered')
    return <FeedbackCard {...data}></FeedbackCard>;
  else if (cardType === 'copay') return <CoPayCard {...data}></CoPayCard>;
  else if (cardType === 'prescriptionRejected')
    return <PrescriptionRejected {...data}></PrescriptionRejected>;
  else if (cardType === 'cancelled')
    return <CancelledCard {...data}></CancelledCard>;
  else if (cardType === 'prescriptionUploaded')
    return <PrescriptionUpload {...data}></PrescriptionUpload>;
  else if (cardType === 'digitisationCompleted')
    return <DigitisationComplete {...data}></DigitisationComplete>;
  else if (cardType === 'orderPlaced')
    return <OrderPlaced {...data}></OrderPlaced>;
};

const OrderPlaced = (data) => {
  return (
    <CardContainer
      {...data}
      noCta
      borderColor="transparent"
      bg="#F5F4FF"
      pb="10px"
    ></CardContainer>
  );
};

const DigitisationComplete = (data) => {
  return (
    <CardContainer
      {...data}
      noCta
      borderColor="transparent"
      bg="#F5F4FF"
    ></CardContainer>
  );
};

const PrescriptionUpload = (data) => {
  return (
    <CardContainer
      {...data}
      noCta
      pb="10px"
      bg="#F5F4FF"
      borderColor="transparent"
    ></CardContainer>
  );
};

const CancelledCard = (data) => {
  return (
    <CardContainer
      {...data}
      noCta
      bg="rgba(255, 134, 92, 0.1)"
      borderColor="transparent"
      children={null}
      pb="10px"
    ></CardContainer>
  );
};

const PrescriptionRejected = (data) => {
  return (
    <CardContainer
      {...data}
      borderColor="#FF754C"
      cta="Re-Upload Prescription"
    ></CardContainer>
  );
};

const LabCardUpdate = (data) => {
  return (
    <CardContainer
      {...data}
      customCta={
        <>
          <div className="py-12px px-16px flex justify-between items-center text-body-s">
            <div className="flex justify-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.00065 2.66732C5.05513 2.66732 2.66732 5.05513 2.66732 8.00065C2.66732 10.9462 5.05513 13.334 8.00065 13.334C10.9462 13.334 13.334 10.9462 13.334 8.00065C13.334 5.05513 10.9462 2.66732 8.00065 2.66732ZM1.33398 8.00065C1.33398 4.31875 4.31875 1.33398 8.00065 1.33398C11.6825 1.33398 14.6673 4.31875 14.6673 8.00065C14.6673 11.6825 11.6825 14.6673 8.00065 14.6673C4.31875 14.6673 1.33398 11.6825 1.33398 8.00065Z"
                  fill="#714FFF"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.4951 8.10522C11.4951 8.47341 11.1966 8.77189 10.8284 8.77189L7.72386 8.77189C7.35567 8.77189 7.05719 8.47341 7.05719 8.10522L7.05719 5.55294C7.05719 5.18475 7.35567 4.88627 7.72386 4.88627C8.09205 4.88627 8.39052 5.18475 8.39052 5.55294L8.39052 7.43855L10.8284 7.43855C11.1966 7.43855 11.4951 7.73703 11.4951 8.10522Z"
                  fill="#714FFF"
                />
              </svg>

              <p className="ml-8px">{data.slotDateTime}</p>
            </div>
            <div className="flex justify-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.99967 2.66732C5.05416 2.66732 2.66634 5.05513 2.66634 8.00065C2.66634 10.9462 5.05416 13.334 7.99967 13.334C10.9452 13.334 13.333 10.9462 13.333 8.00065C13.333 5.05513 10.9452 2.66732 7.99967 2.66732ZM1.33301 8.00065C1.33301 4.31875 4.31778 1.33398 7.99967 1.33398C11.6816 1.33398 14.6663 4.31875 14.6663 8.00065C14.6663 11.6825 11.6816 14.6673 7.99967 14.6673C4.31778 14.6673 1.33301 11.6825 1.33301 8.00065Z"
                  fill="#A5A6BB"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.4951 8.10522C11.4951 8.47341 11.1966 8.77189 10.8284 8.77189L7.72386 8.77189C7.35567 8.77189 7.05719 8.47341 7.05719 8.10522L7.05719 5.55294C7.05719 5.18475 7.35567 4.88627 7.72386 4.88627C8.09205 4.88627 8.39052 5.18475 8.39052 5.55294L8.39052 7.43855L10.8284 7.43855C11.1966 7.43855 11.4951 7.73703 11.4951 8.10522Z"
                  fill="#A5A6BB"
                />
              </svg>
              <p className="ml-8px text-txtsecondary-shades8 decoration-dashed">
                <s>{data.oldTime}</s>
              </p>
            </div>
          </div>
          <div className="flex px-16px justify-between item-center text-body-s border-t border-boxCard py-12px">
            <div className="flex justify-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.66602 5.33333H10.3327"
                  stroke="#714FFF"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.66602 11.9993H11.3327"
                  stroke="#714FFF"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.66644 5.33398V9.33398L13.1164 13.5803C13.4704 14.0159 13.1604 14.6673 12.599 14.6673H3.40035C2.83899 14.6673 2.52895 14.0159 2.88294 13.5803L6.3331 9.33398V5.33398"
                  stroke="#714FFF"
                  stroke-width="0.8"
                />
                <ellipse
                  cx="8.99935"
                  cy="3.66732"
                  rx="0.333333"
                  ry="0.333333"
                  fill="#714FFF"
                />
                <ellipse
                  cx="6.99935"
                  cy="2.33333"
                  rx="0.333333"
                  ry="0.333333"
                  fill="#714FFF"
                />
                <ellipse
                  cx="7.33268"
                  cy="4.00065"
                  rx="0.666667"
                  ry="0.666667"
                  fill="#714FFF"
                />
              </svg>
              <p className="ml-8px">{data.newPartnerName}</p>
            </div>
            <div className="flex justify-center">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.66602 5.33333H10.3327"
                  stroke="#A5A6BB"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4.66602 11.9993H11.3327"
                  stroke="#A5A6BB"
                  stroke-width="0.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.66644 5.33398V9.33398L13.1164 13.5803C13.4704 14.0159 13.1604 14.6673 12.599 14.6673H3.40035C2.83899 14.6673 2.52895 14.0159 2.88294 13.5803L6.3331 9.33398V5.33398"
                  stroke="#A5A6BB"
                  stroke-width="0.8"
                />
                <ellipse
                  cx="8.99935"
                  cy="3.66732"
                  rx="0.333333"
                  ry="0.333333"
                  fill="#A5A6BB"
                />
                <ellipse
                  cx="6.99935"
                  cy="2.33333"
                  rx="0.333333"
                  ry="0.333333"
                  fill="#A5A6BB"
                />
                <ellipse
                  cx="7.33268"
                  cy="4.00065"
                  rx="0.666667"
                  ry="0.666667"
                  fill="#A5A6BB"
                />
              </svg>
              <p className="ml-8px text-txtsecondary-shades8 decoration-dashed">
                <s>{data.oldPartnerName}</s>
              </p>
            </div>
          </div>
        </>
      }
    ></CardContainer>
  );
};

const LabCardUpdateTime = (data) => {
  return (
    <CardContainer
      {...data}
      sub={data.reason}
      customCta={
        <div className="py-12px px-16px flex justify-between items-center text-body-s">
          <div className="flex justify-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.00065 2.66732C5.05513 2.66732 2.66732 5.05513 2.66732 8.00065C2.66732 10.9462 5.05513 13.334 8.00065 13.334C10.9462 13.334 13.334 10.9462 13.334 8.00065C13.334 5.05513 10.9462 2.66732 8.00065 2.66732ZM1.33398 8.00065C1.33398 4.31875 4.31875 1.33398 8.00065 1.33398C11.6825 1.33398 14.6673 4.31875 14.6673 8.00065C14.6673 11.6825 11.6825 14.6673 8.00065 14.6673C4.31875 14.6673 1.33398 11.6825 1.33398 8.00065Z"
                fill="#714FFF"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4951 8.10522C11.4951 8.47341 11.1966 8.77189 10.8284 8.77189L7.72386 8.77189C7.35567 8.77189 7.05719 8.47341 7.05719 8.10522L7.05719 5.55294C7.05719 5.18475 7.35567 4.88627 7.72386 4.88627C8.09205 4.88627 8.39052 5.18475 8.39052 5.55294L8.39052 7.43855L10.8284 7.43855C11.1966 7.43855 11.4951 7.73703 11.4951 8.10522Z"
                fill="#714FFF"
              />
            </svg>

            <p className="ml-8px">{data.slotDateTime}</p>
          </div>
          <div className="flex justify-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.99967 2.66732C5.05416 2.66732 2.66634 5.05513 2.66634 8.00065C2.66634 10.9462 5.05416 13.334 7.99967 13.334C10.9452 13.334 13.333 10.9462 13.333 8.00065C13.333 5.05513 10.9452 2.66732 7.99967 2.66732ZM1.33301 8.00065C1.33301 4.31875 4.31778 1.33398 7.99967 1.33398C11.6816 1.33398 14.6663 4.31875 14.6663 8.00065C14.6663 11.6825 11.6816 14.6673 7.99967 14.6673C4.31778 14.6673 1.33301 11.6825 1.33301 8.00065Z"
                fill="#A5A6BB"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4951 8.10522C11.4951 8.47341 11.1966 8.77189 10.8284 8.77189L7.72386 8.77189C7.35567 8.77189 7.05719 8.47341 7.05719 8.10522L7.05719 5.55294C7.05719 5.18475 7.35567 4.88627 7.72386 4.88627C8.09205 4.88627 8.39052 5.18475 8.39052 5.55294L8.39052 7.43855L10.8284 7.43855C11.1966 7.43855 11.4951 7.73703 11.4951 8.10522Z"
                fill="#A5A6BB"
              />
            </svg>
            <p className="ml-8px text-txtsecondary-shades8 decoration-dashed">
              <s>{data.oldTime}</s>
            </p>
          </div>
        </div>
      }
      borderColor="#714FFF"
    ></CardContainer>
  );
};

const LabCardUpdatePartner = (data) => {
  return (
    <CardContainer
      {...data}
      sub={data.reason}
      borderColor="#714FFF"
      customCta={
        <div className="py-12px px-16px flex justify-between items-center text-body-s">
          <div className="flex justify-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.66602 5.33333H10.3327"
                stroke="#714FFF"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.66602 11.9993H11.3327"
                stroke="#714FFF"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.66644 5.33398V9.33398L13.1164 13.5803C13.4704 14.0159 13.1604 14.6673 12.599 14.6673H3.40035C2.83899 14.6673 2.52895 14.0159 2.88294 13.5803L6.3331 9.33398V5.33398"
                stroke="#714FFF"
                stroke-width="0.8"
              />
              <ellipse
                cx="8.99935"
                cy="3.66732"
                rx="0.333333"
                ry="0.333333"
                fill="#714FFF"
              />
              <ellipse
                cx="6.99935"
                cy="2.33333"
                rx="0.333333"
                ry="0.333333"
                fill="#714FFF"
              />
              <ellipse
                cx="7.33268"
                cy="4.00065"
                rx="0.666667"
                ry="0.666667"
                fill="#714FFF"
              />
            </svg>
            <p className="ml-8px">{data.newPartnerName}</p>
          </div>
          <div className="flex justify-center">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.66602 5.33333H10.3327"
                stroke="#A5A6BB"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M4.66602 11.9993H11.3327"
                stroke="#A5A6BB"
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.66644 5.33398V9.33398L13.1164 13.5803C13.4704 14.0159 13.1604 14.6673 12.599 14.6673H3.40035C2.83899 14.6673 2.52895 14.0159 2.88294 13.5803L6.3331 9.33398V5.33398"
                stroke="#A5A6BB"
                stroke-width="0.8"
              />
              <ellipse
                cx="8.99935"
                cy="3.66732"
                rx="0.333333"
                ry="0.333333"
                fill="#A5A6BB"
              />
              <ellipse
                cx="6.99935"
                cy="2.33333"
                rx="0.333333"
                ry="0.333333"
                fill="#A5A6BB"
              />
              <ellipse
                cx="7.33268"
                cy="4.00065"
                rx="0.666667"
                ry="0.666667"
                fill="#A5A6BB"
              />
            </svg>
            <p className="ml-8px text-txtsecondary-shades8 decoration-dashed">
              <s>{data.oldPartnerName}</s>
            </p>
          </div>
        </div>
      }
    ></CardContainer>
  );
};

const CoPayCard = (data) => {
  const [open, setOpen] = useState(false);

  const { authToken } = useSelector((state) => state.user);

  return (
    <CardContainer
      {...data}
      cta="Make Payment"
      customSection={
        <div className="w-full flex justify-between py-12px px-16px pb-0 text-body-s font-semibold">
          <div className="flex">
            <p>Copay Payable</p>
            <button
              onFocus={() => setOpen(true)}
              onBlur={() => setOpen(false)}
              className="p-4px px-8px rounded-4 ml-10px bg-primary-light relative"
              disabled={true}
            >
              <svg
                width="2"
                height="10"
                viewBox="0 0 2 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.75129 8.24357V4.05297C1.75129 3.62437 1.40386 3.27694 0.975256 3.27694C0.54665 3.27694 0.199219 3.62437 0.199219 4.05297V8.24357C0.199219 8.67218 0.54665 9.01961 0.975256 9.01961C1.40386 9.01961 1.75129 8.67218 1.75129 8.24357ZM0.975256 0.599609C0.54665 0.599609 0.199219 0.947041 0.199219 1.37565C0.199219 1.80425 0.54665 2.15168 0.975256 2.15168C1.40386 2.15168 1.75129 1.80425 1.75129 1.37565C1.75129 0.947041 1.40386 0.599609 0.975256 0.599609Z"
                  fill="#6544C6"
                />
              </svg>
              {open && (
                <div className="absolute w-52 bg-primary-light px-10px py-8px text-left rounded-12 bottom-6 left-4">
                  Copay is applicable when your wallet balance isn’t sufficient
                  to cover the appointment charges{' '}
                </div>
              )}
            </button>
          </div>
          <p>₹ {data.pendingCopayAmount}</p>
        </div>
      }
      borderColor="#714FFF"
      children={null}
    ></CardContainer>
  );
};

const FeedbackCard = (data) => {
  const [stars, setStars] = useState([false, false, false, false, false]);
  const [reasons, setReasons] = useState(
    data.feedbackReasons.map((reason) => ({
      ...reason,
      selected: false,
    }))
  );
  const [submit, setSubmit] = useState(false);

  const { authToken } = useSelector((state) => state.user);
  const ref = useRef();

  const getRating = () => {
    let rating = 0;
    stars.forEach((star, i) => (star ? (rating = i + 1) : false));
    return rating;
  };

  const getReasons = () => {
    const selectedReasons = [];
    reasons.forEach((reason) =>
      reason.selected ? selectedReasons.push(reason.reason) : false
    );
    return selectedReasons;
  };

  return (
    <CardContainer
      {...data}
      infoText={null}
      customSection={
        <div className="py-12px px-16px pb-0 flex flex-col w-full justify-between">
          {!submit && data.askFeedback && (
            <div>
              <div className="w-full justify-between flex border-b pb-16px border-txtsecondary-shades4">
                {stars.map((star, i) => (
                  <button disabled={true}>
                    {star && (
                      <svg
                        width="31"
                        height="28"
                        viewBox="0 0 31 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M29.9432 10.603C29.7884 10.1345 29.3709 9.79806 28.828 9.70117L20.3796 8.20942C20.3567 8.19983 20.3153 8.17215 20.2957 8.15085L16.2241 0.760249C15.9636 0.28323 15.5068 0 15.0032 0C14.4984 0 14.0428 0.284295 13.7822 0.759185L9.71608 8.14553C9.69864 8.16576 9.65177 8.19983 9.66158 8.19983L1.17828 9.70117C0.636489 9.79806 0.220063 10.1335 0.0641765 10.5998C-0.0928006 11.0662 0.0423741 11.5784 0.423916 11.9713L6.39667 18.098L5.23788 26.4437C5.16484 26.9782 5.36215 27.4691 5.76877 27.7566C5.99551 27.9184 6.26477 27.9993 6.54275 27.9993C6.76404 27.9993 6.99297 27.9482 7.21426 27.8439L14.9465 24.2023C14.9737 24.1959 15.0326 24.1959 15.0271 24.1896L22.7953 27.8449C23.2924 28.0781 23.832 28.0451 24.2376 27.7566C24.6431 27.4691 24.8426 26.9782 24.7684 26.4426L23.6129 18.1321C23.614 18.1076 23.6304 18.0554 23.6249 18.0554L23.6238 18.0565L29.5813 11.9713C29.9639 11.5816 30.0991 11.0694 29.9432 10.603Z"
                          fill="#FFA800"
                        />
                      </svg>
                    )}
                    {!star && (
                      <svg
                        width="31"
                        height="28"
                        viewBox="0 0 31 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M30.6893 10.603C30.5345 10.1345 30.117 9.79806 29.5741 9.70117L21.1257 8.20942C21.1028 8.19983 21.0614 8.17215 21.0418 8.15085L16.9702 0.760249C16.7096 0.28323 16.2529 0 15.7493 0C15.2445 0 14.7889 0.284295 14.5283 0.759185L10.4622 8.14553C10.4447 8.16576 10.3979 8.19983 10.4077 8.19983L1.92437 9.70117C1.38258 9.79806 0.966157 10.1335 0.81027 10.5998C0.653293 11.0662 0.788468 11.5784 1.17001 11.9713L7.14277 18.098L5.98397 26.4437C5.91093 26.9782 6.10825 27.4691 6.51486 27.7566C6.7416 27.9184 7.01086 27.9993 7.28884 27.9993C7.51014 27.9993 7.73906 27.9482 7.96036 27.8439L15.6926 24.2023C15.7198 24.1959 15.7787 24.1959 15.7732 24.1896L23.5414 27.8449C24.0385 28.0781 24.5781 28.0451 24.9836 27.7566C25.3892 27.4691 25.5887 26.9782 25.5145 26.4426L24.359 18.1321C24.3601 18.1076 24.3765 18.0554 24.371 18.0554L24.3699 18.0565L30.3274 11.9713C30.71 11.5816 30.8452 11.0694 30.6893 10.603Z"
                          fill="#F0F3F6"
                        />
                      </svg>
                    )}
                  </button>
                ))}
              </div>
              <div className="mt-12px">
                <p className="text-body-s font-semibold">Help us know more</p>
                <div className="flex flex-wrap my-12px">
                  {reasons.map(({ reason, selected }, i) => (
                    <button
                      className={
                        !selected
                          ? 'w-36 py-12px px-16px border m-4px rounded-12 text-body-s font-semibold flex text-left border-boxCard'
                          : 'w-36 py-12px px-16px border m-4px rounded-12 text-body-s font-semibold flex text-left border-primary'
                      }
                      disabled={true}
                    >
                      {selected && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 9C3 5.68629 5.68629 3 9 3H15C18.3137 3 21 5.68629 21 9V15C21 18.3137 18.3137 21 15 21H9C5.68629 21 3 18.3137 3 15V9Z"
                            fill="#714FFF"
                          />
                          <path
                            d="M10.5841 13.1655L8.91678 11.5111L8 12.4208L10.2003 14.604L10.2028 14.6014L10.6045 15L15.7143 9.92734L14.7797 9L10.5841 13.1655Z"
                            fill="white"
                          />
                        </svg>
                      )}
                      <p className={selected ? 'ml-8px' : ''}>{reason}</p>
                    </button>
                  ))}
                </div>
              </div>
              <div>
                <textarea
                  placeholder="Anything else you want to say..."
                  className="w-full bg-txtsecondary-shades4 px-16px py-12px text-body-s rounded-12 my-10px"
                  ref={ref}
                ></textarea>
              </div>
              <div className="w-full my-8px border-b border-txtsecondary-shades4 pb-16px">
                <button
                  className="flex justify-center w-full border border-primary rounded-12 py-12px text-body-s font-semibold"
                  disabled={true}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
          <div className="my-10px">
            <p className="text-body-s font-semibold">Report Generated</p>
            <p className="text-chip text-sub mt-5px">{data.infoText}</p>
            <div className="my-12px border-b border-txtsecondary-shades4 pb-16px">
              <button
                className="py-12px bg-primary w-full flex justify-center rounded-12 text-textbox font-semibold text-body-s"
                disabled={true}
              >
                Download Report
              </button>
            </div>
          </div>
          <div className="text-body-s text-sub mb-12px text-center">
            {data.description}
          </div>
        </div>
      }
      children={null}
      borderColor="#714FFF"
      cta="Consult Doctor"
    ></CardContainer>
  );
};

const PartialReportCard = (data) => {
  return (
    <CardContainer
      {...data}
      borderColor="#714FFF"
      noCta={true}
      customCta={
        <div className="w-full py-12px px-16px">
          <button
            className="w-full flex bg-primary justify-center py-12px rounded-12 text-body-s font-semibold text-textbox"
            disabled={true}
          >
            Download Report
          </button>
        </div>
      }
    ></CardContainer>
  );
};

const CartCreated = (data) => {
  console.log(data);
  return (
    <CardContainer {...data} borderColor="#714FFF" cta="Place Order">
      <div className="mt-10px">
        {data.tests.map((test, i) => (
          <div
            className="border-t border-boxCard py-12px px-16px  text-body-s flex"
            style={{ paddingBottom: i + 1 === data.tests.length ? 0 : '' }}
          >
            <p className="ml-8px">{test}</p>
          </div>
        ))}
      </div>
    </CardContainer>
  );
};

const SampleProcessingCard = (data) => {
  return (
    <CardContainer
      {...data}
      borderColor={data.isDelayed ? '#FF754C' : 'transparent'}
      cta={data.isDelayed ? 'Request Callback' : null}
      noCta={!data.isDelayed}
      pb={data.isDelayed ? '' : '10px'}
      bg={data.isDelayed ? null : '#F5F4FF'}
    ></CardContainer>
  );
};

const PartnerConfirmedCard = (data) => {
  return (
    <CardContainer
      {...data}
      cta={data.showEmergencyButtonCall ? 'Request Callback' : false}
      borderColor={!data.isDelayed ? 'transparent' : '#FF754C'}
      noCta={!data.isDelayed}
      bg={data.isDelayed ? null : '#F5F4FF'}
    ></CardContainer>
  );
};

const SamplePickupCard = (data) => {
  const { authToken } = useSelector((state) => state.user);

  const user = useSelector((state) => state.user);

  return (
    <CardContainer
      {...data}
      cta={data.showEmergencyButtonCall ? 'Contact Support' : null}
      borderColor={!data.isDelayed ? '#714FFF' : '#FF754C'}
      customCta={
        !data.showEmergencyButtonCall ? (
          <div className="px-16px py-12px flex w-full justify-between">
            <button
              className="flex justify-center w-full text-body-s font-semibold text-primary"
              disabled={true}
            >
              Yes, on time!
            </button>
            <svg
              width="2"
              height="18"
              viewBox="0 0 2 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 0V18" stroke="#F0F3F6" />
            </svg>
            <button
              className="flex justify-center w-full text-body-s font-semibold text-redText"
              disabled={true}
            >
              No, not yet!
            </button>
          </div>
        ) : (
          false
        )
      }
      customSection={
        data.questionToAsk ? (
          <div className="py-12px px-16px text-body-s font-semibold">
            {data.questionToAsk}
          </div>
        ) : null
      }
    ></CardContainer>
  );
};

const CardContainer = ({
  children,
  title,
  cta,
  noCta,
  infoText,
  borderColor,
  customCta,
  customSection,
  sub,
  bg,
  pb,
  dateTime,
}) => {
  return (
    <div
      className="w-full flex flex-col items-end"
      style={{ marginBottom: '20px' }}
    >
      <div
        className="ml-10px border rounded-12 w-full"
        style={{ borderColor, background: bg, paddingBottom: pb }}
      >
        <div className="mt-12px mx-16px text-body-s font-semibold">{title}</div>
        {infoText && (
          <div
            className=" mt-12px mx-16px text-chip text-sub mt-5px"
            style={{
              marginBottom: noCta || !cta ? '12px' : '0',
            }}
          >
            {infoText}
            {!!sub && <p className="text-black">{sub}</p>}
          </div>
        )}
        {customSection && (
          <div className="mt-12px border-t border-txtsecondary-shades4">
            {customSection}
          </div>
        )}
        {!infoText && <div>{children}</div>}
        {customCta && (
          <div
            className="border-t border-txtsecondary-shades4"
            style={{
              marginTop: customSection ? '0' : '20px',
            }}
          >
            {customCta}
          </div>
        )}
        {!noCta && !customCta && (
          <button
            className="mt-10px py-12px text-primary text-body-s font-semibold flex justify-center items-center w-full border-t border-txtsecondary-shades4"
            disabled={true}
          >
            {cta}
          </button>
        )}
      </div>
      <div className="text-card-body text-sub mt-8px">{dateTime}</div>
    </div>
  );
};

export default OrderStatus;
