import React, { Component } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  Toast,
  ToastBody,
  ToastHeader,
} from 'reactstrap';
import ReactTable from 'react-table';
import { Text } from '../common/Text';
import {
  saveFulfilmentDetails,
  fetchRelevantGmcAccounts,
  linkGmcAccount,
} from '../../services/api/customer-support';

class UserBankDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmSave: false,
      gmcUserIdToLink: -1,
      saving: false,
      searchList: [],
      searching: false,
      response: {
        show: false,
        message: '',
        color: 'success',
      },
    };
  }

  componentDidMount() {
    this.fetchRelevantGmcAccounts();
  }

  componentDidUpdate(prevProps, prevState) {
    const { response } = this.state;
    if (response.show && !prevState.response.show) {
      this.setTimeoutForResponsePopup();
    }
  }

  fetchRelevantGmcAccounts() {
    const { userId } = this.props;
    const searchText = document.querySelector('#searchText').value;
    this.setState({
      searching: true,
      searchList: [],
    });
    fetchRelevantGmcAccounts(userId, searchText)
      .then((res) => {
        this.setState({
          searchList: res.result,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({
          searching: false,
        });
      });
  }

  setTimeoutForResponsePopup() {
    this.clearTimeout = setTimeout(() => {
      this.setState({
        response: {
          show: false,
        },
      });
      this.clearTimeout = null;
    }, 3000);
  }

  linkGmcUserToUserId = () => {
    const { userId } = this.props;
    const { gmcUserIdToLink } = this.state;
    this.setState({
      saving: true,
    });
    linkGmcAccount(userId, gmcUserIdToLink)
      .then((res) => {
        if (res.message === 'success') {
          this.setState({
            response: {
              show: true,
              message: 'Account linked successfully',
              color: 'success',
            },
          });
          this.fetchRelevantGmcAccounts();
          this.props.getUpdatedDetails();
        } else {
          this.setState({
            response: {
              show: true,
              message: res.errorMessage || 'Failed to link GMC account',
              color: 'danger',
            },
          });
        }
      })
      .catch((err) => {
        this.setState({
          response: {
            show: true,
            message: err.errorMessage || 'Failed to link GMC account',
            color: 'danger',
          },
        });
      })
      .finally(() => {
        this.setState({
          saving: false,
          confirmSave: false,
        });
      });
  };

  confirmLinkGmcUser = (gmcUserId) => () => {
    this.setState({
      confirmSave: true,
      gmcUserIdToLink: gmcUserId,
    });
  };

  render() {
    const { response, saving, searching, searchList } = this.state;
    return (
      <div>
        <div className={'row'}>
          <div className={'col-md-12'}>
            <Toast isOpen={response.show}>
              <ToastHeader icon={response.color}>Response</ToastHeader>
              <ToastBody>{response.message}</ToastBody>
            </Toast>
            <Text className="h4">Choose a GMC account to link</Text>
            <Form>
              <FormGroup inline>
                <Label for="bankAccount" sm={5}>
                  Search by UHID or Employee ID
                </Label>
                <Col sm={7}>
                  <Input type="text" name="searchText" id="searchText" />
                </Col>
              </FormGroup>
              <Button
                color="info"
                onClick={() => {
                  this.fetchRelevantGmcAccounts();
                }}
                style={{ marginBottom: '1rem' }}
              >
                Search
              </Button>
            </Form>
          </div>
          <div className="col-12">
            <ReactTable
              data={searchList}
              columns={[
                {
                  accessor: 'gmcUserId',
                  Header: 'GMC User Id',
                },
                {
                  accessor: 'uhid',
                  Header: 'UHID',
                },
                {
                  accessor: 'ecode',
                  Header: 'Employee ID',
                },
                {
                  accessor: 'name',
                  Header: 'Name',
                },
                {
                  accessor: 'phone',
                  Header: 'GMC Phone',
                },
                {
                  accessor: 'email',
                  Header: 'GMC Email',
                },
                {
                  accessor: 'sponsorName',
                  Header: 'GMC Sponsor Name',
                },
                {
                  Header: 'Actions',
                  Cell: (row) => {
                    if (row.original.linkedUserId) {
                      return (
                        <button className="btn btn-disabled">
                          GMC account already linked
                        </button>
                      );
                    } else {
                      return (
                        <button
                          className="btn btn-primary"
                          onClick={this.confirmLinkGmcUser(
                            row.original.gmcUserId
                          )}
                        >
                          Link GMC Account
                        </button>
                      );
                    }
                  },
                },
              ]}
              loading={searching}
              className="-striped -highlight"
            />
          </div>
        </div>
        <Modal isOpen={this.state.confirmSave}>
          <ModalBody>
            Are you sure you want to link to this GMC account?
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={saving}
              color="primary"
              onClick={this.linkGmcUserToUserId}
            >
              {saving ? 'Saving' : 'Save'}
            </Button>
            <Button
              color="secondary"
              disabled={saving}
              onClick={() => {
                this.setState({
                  confirmSave: false,
                  gmcUserIdToLink: -1,
                });
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default UserBankDetails;
