import React from 'react';
import {toast} from 'react-toastify';

import { addCopay, computeFinalCopay,  } from '../../services/api/lab-order-details';

import LoadingComponent from "../common/LoadingComponent";
import {getReasonsForRefund} from "../../services/api/get-labs-orders";

export default class LabsOrderCopayForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            computeLoading: false,
            computed: false,
            reasonId: '',
        };
    }

    componentDidMount() {
        const { cartItemsResult } = this.props;
        const finalItems = [];
        cartItemsResult.itemsList.forEach((i) => {
            finalItems.push({
                testId: i.labId,
                packageId: i.packageId,
                itemName: i.labName,
                cost: i.cost || 0,
                mrp: i.mrp || 0,
                userPaid: i.postDiscountPrice || 0,
                type: 'test',
                extraCopay: 0,
                delete: false,
                walletDeduction: i.walletDeduction,
            });
        });
        finalItems.push({
            testId: null,
            itemName: 'Handling Charges',
            mrp: cartItemsResult.hcToVendor || 0,
            userPaid: cartItemsResult.hcToVendor || 0,
            cost: cartItemsResult.hcToVendor || 0,
            type: 'hc',
            extraCopay: 0,
            delete: false,
            walletDeduction: cartItemsResult.walletDeductionForHC,
        });
        this.setState({
            items: finalItems || [],
        }, console.log(finalItems, 'itemshiitems'), this.handleCompute);
        this.getReasons();
    }

    getReasons = () => {
        return getReasonsForRefund()
            .then(response => {
                const respBody = response.body;
                const reasons = respBody.result;
                let options = [];
                options = reasons.filter(r => (r.type === 'change-pricing')).map(r => {
                    return {
                        value: r.id,
                        label: r.reason,
                    };
                })
                options.unshift({
                    value: null,
                    label: 'Select Reason',
                });
                console.log('\n Reasons getReasonsForRefund', options, reasons);
                this.setState({
                    reasons: options
                })
            })
            .catch((err) => {
                console.log('Error in fetching reasons: ', err);
            });
    }

    handleMrpChange = (value, index) => {
        const finalItems = this.state.items;
        finalItems[index].mrp = value;
        this.setState({
            items: finalItems,
        });
    };

    handleCostChange = (value, index) => {
        const finalItems = this.state.items;
        finalItems[index].cost = value;
        this.setState({
            items: finalItems,
        });
    };

    handleSubmitAddCopay = () => {
        const { orderId } = this.props;
        const { reasonId } = this.state;
        if (!reasonId) {
            toast.error('Select a reason');
            return;
        }
        return addCopay(orderId, this.state.items, reasonId)
            .then((response) => {
                if (response.message === 'success') {
                    toast.success('Successfully added for copay');
                    this.props.onSubmit();
                } else {
                    throw new Error(response.errorMessage || 'Error while adding copay to the order');
                }
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err);
            });
    };

    handleCompute = () => {
        const { orderId } = this.props;
        const { items } = this.state;
        this.setState({
            computeLoading: true,
        });
        return computeFinalCopay(orderId, items)
            .then((response) => {
                if (response.message === 'success') {
                    this.setState({
                        items: response.items.map((i) => {
                            i.deleted = false;
                            return i;
                        }),
                        computed: true,
                    });
                } else {
                    throw new Error(response.errorMessage || 'Error while adding copay to the order');
                }
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err);
            })
            .finally(() => {
                this.setState({
                    computeLoading: false,
                });
            });
    };

    toggleItemDeletion = (index) => {
        const finalItems = this.state.items;
        finalItems[index].deleted = !(finalItems[index].deleted);
        this.setState({
            items: finalItems,
        });
    };

    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>Test Id</th>
                                <th>Item Name</th>
                                <th>MRP</th>
                                <th>Cost</th>
                                <th>Wallet Deduction</th>
                                <th>Already Paid</th>
                                <th>Final User Payable</th>
                                <th>deduction in wallet amount</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {(this.state.items.map((item, index) => (
                                <tr key={item.testId} className={(item.deleted) ? 'text-secondary' : ''}>
                                    <td>{item.testId}</td>
                                    <td>{item.itemName}</td>
                                    <td>
                                        <input
                                            disabled={item.deleted}
                                            value={item.mrp}
                                            onChange={(e) => (this.handleMrpChange(e.target.value, index))}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            disabled={item.deleted}
                                            value={item.cost}
                                            onChange={(e) => (this.handleCostChange(e.target.value, index))}
                                        />
                                    </td>
                                    <td>{item.walletDeduction}</td>
                                    <td>{item.existingPaidCopay}</td>
                                    <td className={(item.deleted) ? 'text-secondary' : (item.computedFinalPrice < 0) ? 'text-danger' : 'text-primary'}>{(isNaN(item.computedFinalPrice)) ? 'Not Computed' : item.computedFinalPrice}</td>
                                    <td className={(item.deleted) ? 'text-secondary' : (item.computedDeltaWallet < 0) ? 'text-danger' : 'text-primary'}>{(isNaN(item.computedDeltaWallet)) ? 'Not Computed' : item.computedDeltaWallet}</td>
                                    <td>
                                        {(item.type === 'hc') ? (null): (<button className="btn btn-danger" onClick={() => (this.toggleItemDeletion(index))}>Delete</button>)}
                                    </td>
                                </tr>
                            )))}
                            <tr>
                                    <td colSpan="2" text-align="right"><b>TOTAL</b></td>
                                    <td>{this.state.items.reduce((acc, item)=> item.deleted? acc : acc+(parseInt(item.mrp)||0),0)}</td>
                                    <td>{this.state.items.reduce((acc, item)=> item.deleted? acc : acc+(parseInt(item.cost)||0),0)}</td>
                                    <td>{this.state.items.reduce((acc, item)=> item.deleted? acc : acc+(parseInt(item.walletDeduction)||0),0)}</td>
                                    <td>{this.state.items.reduce((acc, item)=> item.deleted? acc : acc+(parseInt(item.existingPaidCopay)||0),0)}</td>
                                    {/* <td className={(item.deleted) ? 'text-secondary' : (item.computedFinalPrice < 0) ? 'text-danger' : 'text-primary'}>{(isNaN(item.computedFinalPrice)) ? 'Not Computed' : item.computedFinalPrice}</td> */}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col text-right">
                            {(this.state.computeLoading) ? (
                                <LoadingComponent />
                            ) : (
                                <button
                                    className="btn btn-primary"
                                    onClick={this.handleCompute}
                                >Compute</button>
                            )}
                        </div>
                        <div className="col text-right">
                            Reason
                            <select value={this.state.reasonId} onChange={(e) => (this.setState({ reasonId: e.target.value }))}>
                                {(this.state.reasons && this.state.reasons.length) ? (
                                    this.state.reasons.map((r) => (
                                        <option key={r.value} value={r.value}>{r.label}</option>
                                    ))
                                ) : (null)}
                            </select>
                        </div>
                        <div className="col text-right">
                            {(this.state.computed) ? (
                                <button
                                    className="btn btn-primary"
                                    onClick={this.handleSubmitAddCopay}
                                >Edit Pricing</button>
                            ) : (null)}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
