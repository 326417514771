import React, { Component } from 'react';

export default class PayoutFilter  extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }

    onChange = (event) => {
        const value = event.target.value === "" ? null : event.target.value;
        this.setState({ value });
        this.props.onChangeValue(value);
    }


    render() {
        const { value } = this.state;
        return (
            <select
                onChange={this.onChange}
                style={{
                    width: "100%",
                    height: "40px",
                    fontSize: "12px"
                }}
                value={value}
            >
                 <option value="all">All</option>
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Declined">Declined</option>
                <option value="Processing">Processing</option>
                <option value="Not Raised">Not Raised</option>
            </select>
        )
    }
}
