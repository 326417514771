import baseRequest from './baseRequest';

const baseUrl = '/new-ops/online-payment';

export const fetchDoctorConsultations = (doctorId, monthYear) => {
    const url = `${baseUrl}/doctors/${doctorId}/consultations`;
    const method = 'GET';
    const params = {
        monthYear
    };
    return baseRequest(url, method, params, null);
};

export const fetchDoctorByFilters = (query) => {
    const url = `${baseUrl}/doctors`;
    const method = 'GET';
    return baseRequest(url, method, query, null);
};

export const overridePaymentStatus = (consultationId, doctorId, paymentStatus) => {
    const url = `${baseUrl}/doctors/${doctorId}/consultations/${consultationId}/override`;
    const method = 'POST';
    const params = {
        override: paymentStatus,
    };
    return baseRequest(url, method, null, params);
};

export const processPayout = (doctorId, monthYear, tds, remarks, payoutAmount,payoutFlag) => {
    const url = `${baseUrl}/doctors/${doctorId}/consultations/payout`;
    const method = 'POST';
    const params = {
        monthYear
    };
    const body = {
        tds,
        remarks,
        payoutAmount,
        payoutFlag
    };
    return baseRequest(url, method, params, body);
};

export const requestPaymentFromInsurer = (payload) => {
    const doctorId = payload.doctorId;
    const url = `${baseUrl}/doctors/${doctorId}/consultations/request-payment`;
    const method = 'POST';
    return baseRequest(url,method,null, payload);
}

export const getPayoutInfo = (doctorId, monthYear) => {
    const url = `${baseUrl}/doctors/${doctorId}/payout-info`;
    const method = 'GET';
    const params = {
        monthYear,
        doctorId,
    };
    return baseRequest(url, method, params, null);
};

export const getPayoutLogic = () => {
    const url = `${baseUrl}/doctors/current-logic`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const fetchPreviousPayments = (doctorId) => {
    const url = `${baseUrl}/doctors/${doctorId}/previous-payments`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};
