import React, { Component } from 'react';
import appConfig from '../../config/app-config';
import {
  Container,
  Button,
  ButtonGroup,
  Row,
  Col,
  Input,
  Badge,
  ModalHeader,
  ModalBody,
  Modal,
} from 'reactstrap';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Select from 'react-select';

import { toast } from 'react-toastify';

import { FaPhone, FaSpinner, FaTimes, FaUndo, FaEdit } from 'react-icons/fa';
import LoadingComponent from '../common/LoadingComponent';
import ResultToast from './ResultToast';

import CommentList from './comments/commentList';
import CommentForm from './comments/commentForm';

import { uploadImageAttachment } from '../../services/api/file-upload';

import CustomCallingSection from './details/CustomCallingSection';

import {
  callUserRequest,
  callDoctorRequest,
  callCustomRequest,
  callPatientRequest,
  tpaCancelTpaRequest,
  reopentNoShow,
  fetchTransactionHistory,
  requestReconfirmationForOfflineConsult,
  refreshPayoutRequest,
  fetchDoctorPriceChangeHistory,
  resendReconfirmationCommsToSpocs,
  resendReconfirmationCommsToUser,
} from '../../services/api/offline-consult';

import { unsetOfflineConsultRequestId } from '../../actions/index';

import {
  getOfflineRequest,
  updateCashlessLetterStatus,
  getDoctorSlots,
  waiveOffCancellationFees as waiveOffCancellationFeesService,
  requestPayment,
  resendCashlessLetter,
  pacOrNoShow,
  changeInvoiceMandatory,
  getPayoutStatus,
  getCenterInfoOfflineRequest,
  getPaymentInfoOfflineRequest,
  getActionsInfoOfflineRequest,
} from '../../services/api/offline-consult';
import { viewCommentRequest } from '../../services/api/view-comments';
import ConfirmationModal from '../common/ConfirmationModal';
import { BoldText, RegularText, Text } from '../common/Text';
import StyledTappable from '../common/StyledTappable';
import UploadingComponent from '../common/UploadingComponent';
import PaymentButton from './PaymentButton';
import AddSpocModal from './AddSpocModal';
import AddPanNumberModal from './AddPanNumberModal';
import AddZohoModal from './AddZohoModal';
import AddPaymentModal from './AddPaymentModal';

const DetailsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

const SectionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const SectionInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
`;

const SectionHeader = styled(BoldText)`
  font-size: 1.2rem;
  color: #4b4f5b;
`;

const SectionBody = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.625rem;
`;

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-bottom: 0.25rem;
`;

const DetailName = styled(Text)`
  font-size: 1rem;
  width: ${(props) => (props.fullWidth ? '20rem' : '8rem')};
  color: #4c4c4c;
`;

const DoctorSlotBox = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

const DetailName2 = styled(Text)`
  font-size: 1rem;
  width: 12rem;
  color: #4c4c4c;
`;
const DetailText = styled(RegularText)`
  flex: 1;
  font-size: 1rem;
  color: ${(props) => props.color || '#787778'};
  margin-left: 0.25rem;
`;

const DetailTextDanger = styled(RegularText)`
  font-size: 1rem;
  color: #ff4d48;
`;

const CallUserText = styled(BoldText)`
  font-size: 1rem;
  margin-left: 0.4rem;
  color: #56c02c;
  cursor: pointer;
`;

const SelectElement = styled(Select)`
  /* border: 0.1rem solid #8852cc; */
  flex: 1 0;
  margin-bottom: 0.625rem;
`;

const UploadPrescriptionButton = styled(StyledTappable)`
  /* width: 14rem;
  height: 2rem;
  padding: 1.2rem;
  margin-left: 0.625rem;
  margin-top: 0.625rem;
  border: 1px solid #8852cc;
  border-radius: 0.2rem; */
  justify-content: flex-start;
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
`;

const UploadText = styled(BoldText)`
  font-size: 1.2rem;
  margin-left: 0.625rem;
  color: #8852cc;
  text-align: left;
`;

const SpocDetailContainer = styled(SectionContainer)`
  background-color: #f8f9fa;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  padding: 0.625rem;
`;

class RequestDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      callPatientLoading: false,
      callDoctorLoading: false,
      callUserLoading: false,
      callCommentsLoading: false,
      tpaCancelLoading: false,
      showToast: false,
      callRequest: false,
      loading: false,
      details: {},
      basicInfo: {},
      paymentInfo: {},
      centerInfo: {},
      totalConsultations: '',
      actions: [],
      lastConsultationDetails: [],
      comments: [],
      confirmationMessage: 'Are you sure you want to call',
      showConfirmationModal: false,
      phone: '',
      userType: '',
      activeInfo: 'basic',
      doctorSlots: null,
      pacOrNoShow: '',
      transactions: null,
      refreshPayoutModalOpen: false,
      rescheduleInfo: [],
      doctorPriceChanges: [],
      userRequestedInfo:{},
      emergencyCalls: [],
      spocCommsStatus: 'Not yet conveyed.',
      userCommsStatus: 'Not yet conveyed.',
      addSpocModalOpen: false,
      addPanNumberModalOpen: false,
      addZohoModalOpen: false,
      addPaymentModalOpen: false,
      showBankDetails: false,
      payoutStatus : null,
      showDoctorNewSlots: false,
      doctorNewSlot: {
        modal: false,
        data: [],
        date: ''
      },
      payoutStatus: null,
      callReasonOptions: [
        'a pending action on the rescheduled request from you',
        'a change in the price',
        'the required reconfirmation from you',
        'the doctor’s unavailability and available alternatives',
        'the slot’s unavailability and available alternatives',
        'a pending confirmation from the centre',
        "an update",
        'a pending prescription upload',
      ],
      isCallModalOpen: false,
      selectedCallReason: null,
    };

    this.addComment = this.addComment.bind(this);

    this.callStakeholdersOptions = [
      { value: 'User', label: 'User' },
      { value: 'Relative', label: 'Relative' },
      { value: 'Doctor', label: 'Doctor' },
      { value: 'Assistant', label: 'Assistant' },
      { value: 'Health Center', label: 'Health Center' },
    ];
    this.PACOptions = [
      {
        value: `Doctor unavailable during user's visit.`,
        label: `Doctor unavailable during user's visit.`,
      },
      {
        value: `User unavailable due to personal reasons.`,
        label: `User unavailable due to personal reasons.`,
      },
      {
        value: `Centre didn't acknowledge the payment`,
        label: `Centre didn't acknowledge the payment`,
      },
      {
        value: `Centre didn't agree to the credit appointment`,
        label: `Centre didn't agree to the credit appointment`,
      },
      {
        value: `Center denied partnership with Visit.`,
        label: `Center denied partnership with Visit.`,
      },
      {
        value: `Center denied cashless appointment.`,
        label: `Center denied cashless appointment.`,
      },
      {
        value: `VIP reason (for special cases or priority appointments).`,
        label: `VIP reason (for special cases or priority appointments).`,
      },
    ];
  }

  componentDidMount() {
    this.getOfflineRequest();
    this.getTransactionHistory();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.basicInfo.centerId !== this.state.basicInfo.centerId) {
      this.getDoctorPriceChangeHistory();
    }
    if (
      prevState.basicInfo.commsSendToSPOC !==
      this.state.basicInfo.commsSendToSPOC
    ) {
      if (
        this.state.basicInfo.commsSendToSPOC &&
        !this.state.basicInfo.reconfirmedByHospital
      ) {
        this.setState({
          spocCommsStatus: 'Reconfirmation Awaited',
        });
      }
      if (this.state.basicInfo.reconfirmedByHospital) {
        this.setState({
          spocCommsStatus: 'Yes',
        });
      }
    }
    if (
      prevState.basicInfo.commsSendToUser !==
      this.state.basicInfo.commsSendToUser
    ) {
      if (
        this.state.basicInfo.commsSendToUser &&
        !this.state.basicInfo.reconfirmedByUser
      ) {
        this.setState({
          userCommsStatus: 'Reconfirmation Awaited',
        });
      }
      if (this.state.basicInfo.reconfirmedByUser) {
        this.setState({
          userCommsStatus: 'Yes',
        });
      }
    }
    if (
      prevState.basicInfo.appointmentDate !==
      this.state.basicInfo.appointmentDate
    ) {
      const targetDate = new Date(this.state.basicInfo.appointmentDate);
      const currentDate = new Date();

      // Calculate the difference in milliseconds between the two dates
      const timeDifference = targetDate - currentDate;

      // Convert milliseconds to hours
      const hoursDifference = timeDifference / (1000 * 60 * 60);

      if (hoursDifference <= 3) {
        if (!this.state.basicInfo.reconfirmedByUser) {
          this.setState({
            userCommsStatus: 'Reconfirmation required',
          });
        }
        if (!this.state.basicInfo.reconfirmedByHospital) {
          this.setState({
            spocCommsStatus: 'Reconfirmation required',
          });
        }
      }
    }

    // check for request modal tabs
    if (prevState.activeInfo !== this.state.activeInfo) {
      switch (this.state.activeInfo) {
        case 'payment':
          if (Object.keys(this.state.paymentInfo).length === 0)
            this.getPaymentInfoForOfflineConsultations();
          break;
        case 'misc':
          if (Object.keys(this.state.actions).length === 0)
            this.getActionsInfoForOfflineConsultations();
          break;
        case 'center':
          if (Object.keys(this.state.centerInfo).length === 0)
            this.getCenterInfoForOfflineConsultations();
          break;
      }
    }
  };

  setPhone = (event) => {
    const value = event.target.value;
    this.setState({
      phone: value,
    });
  };

  getDoctorSlots = () => {
    const { requestId } = this.props.offlineConsult;
    getDoctorSlots({ requestId })
      .then((res) => {
        console.log({ res }, 'getDoctorSlots');
        this.setState({
          doctorSlots: res.data.slots,
          showDoctorNewSlots: res.data.showSlot
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  getOfflineRequest = () => {
    const { requestId } = this.props.offlineConsult;
    let resBody = {};
    getOfflineRequest(requestId)
      .then((responseBody) => {
        resBody.details = responseBody;
        const {
          basicInfo,
          totalConsultations,
          lastConsultationDetails,
          rescheduleInfo,
          emergencyCalls,
          userRequestedInfo
        } = responseBody;
        console.log(
          {
            basicInfo,
            totalConsultations,
            lastConsultationDetails,
            emergencyCalls,
          },
          'get offline request response'
        );
        this.setState({
          basicInfo,
          totalConsultations,
          lastConsultationDetails,
          cashlessLetterChecked: basicInfo.cashlessLetterSentStatus,
          rescheduleInfo,
          emergencyCalls,
          userRequestedInfo
        });
      })
      .then(() => viewCommentRequest(requestId, appConfig.REQUEST_CHAT.NETWORK))
      .then(this.handleComments)
      .then((comments) => {
        // setting the state
        console.log(comments);
        this.setState({
          details: resBody.details,
          comments,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getTransactionHistory = (orderId) => {
    const { requestId } = this.props.offlineConsult;
    fetchTransactionHistory(requestId)
      .then((response) => {
        console.log(response, 'Response from transaction api');
        if (response.message === 'success') {
          this.setState({
            transactions: response.transactions,
          });
        } else {
          throw new Error(
            response.errorMessage ||
              'Something went wrong while fetching transaction'
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  };

  handleZohoModalOpen = () => {
    this.setState({
      addZohoModalOpen: true,
    });
  };
  
  getDoctorPriceChangeHistory = () => {
    console.log(this.state.basicInfo, 'basicInfo123');
    const { doctorId, centerId } = this.state.basicInfo;
    const params = {
      doctorId,
      centerId,
    };
    fetchDoctorPriceChangeHistory(params)
      .then((response) => {
        console.log(response, 'Response from doctor price change api');
        if (response.message === 'success') {
          this.setState({
            doctorPriceChanges: response.data,
          });
        } else {
          throw new Error(
            response.errorMessage ||
              'Something went wrong while fetching transaction'
          );
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  };

  handleComments = (response) => {
    const comments = [];
    if (response.body.message === 'success' && response.body.results.length) {
      // build comments
      response.body.results.forEach((c) => {
        comments.push({
          name: c.userName,
          message: c.content,
          time: c.createdAt,
        });
      });
    }
    return comments;
  };

  addComment(comment) {
    this.setState({
      loading: false,
      comments: [...this.state.comments, comment],
    });
  }

  callPatient = () => {
    const { requestId } = this.props.offlineConsult;
    this.setState({
      showConfirmationModal: false,
    });
    return callPatientRequest(
      requestId,
      'userAcknowledge',
      this.state.selectedCallReason
    )
      .then((res) => {
        this.showSuccessToast();
      })
      .catch((err) => {
        console.log(err);
        this.showFailureToast();
      })
      .finally(() => {
        this.setState({
          callPatientLoading: false,
        });
      });
  };

  initiateCall = (type) => {
    console.log('inside initiate call', type);
    switch (type) {
      case 'USER': {
        this.setState({
          callUserLoading: true,
          showConfirmationModal: true,
          confirmationMessage: 'Are You sure You want to call the user ?',
          isCallModalOpen: true,
        });
        break;
      }
      case 'PATIENT': {
        this.setState({
          callPatientLoading: true,
          showConfirmationModal: true,
          confirmationMessage: 'Are You sure You want to call the Patient ?',
          isCallModalOpen: true,
        });
        break;
      }
      case 'CUSTOM': {
        this.setState({
          callCustomLoading: true,
          showConfirmationModal: true,
          confirmationMessage: 'Are You sure You want to make this call ?',
        });
        break;
      }
      case 'Doctor': {
        this.setState({
          callDoctorLoading: true,
          showConfirmationModal: true,
          confirmationMessage: 'Are You sure You want to call the user ?',
        });
        break;
      }

      default:
        break;
    }
  };

  openCancelTpaModal = () => {
    this.setState({
      tpaCancelLoading: true,
      showConfirmationModal: true,
      confirmationMessage: 'Are you sure you want to cancel the TPA Request?',
    });
  };

  toggleConfirmationModal = () => {
    this.setState({
      showConfirmationModal: !this.state.showConfirmationModal,
      callUserLoading: false,
      callPatientLoading: false,
      callDoctorLoading: false,
    });
  };

  callUser = () => {
    const { requestId } = this.props.offlineConsult;
    this.setState({
      showConfirmationModal: false,
    });
    return callUserRequest(
      requestId,
      'userAcknowledge',
      this.state.selectedCallReason
    )
      .then((res) => {
        this.showSuccessToast();
      })
      .catch((err) => {
        console.log(err);
        this.showFailureToast();
      })
      .finally(() => {
        this.setState({
          callUserLoading: false,
        });
      });
  };

  callDoctor = () => {
    const { requestId } = this.props.offlineConsult;
    this.setState({
      showConfirmationModal: false,
    });
    return callDoctorRequest(requestId)
      .then((res) => {
        this.showSuccessToast();
      })
      .catch((err) => {
        console.log(err);
        this.showFailureToast();
      })
      .finally(() => {
        this.setState({
          callDoctorLoading: false,
        });
      });
  };

  callCustom = () => {
    const { requestId } = this.props.offlineConsult;
    const { phone } = this.state;
    this.setState({
      showConfirmationModal: false,
    });
    return callCustomRequest(requestId, phone)
      .then((res) => {
        this.showSuccessToast();
      })
      .catch((err) => {
        console.log(err);
        this.showFailureToast();
      })
      .finally(() => {
        this.setState({
          callCustomLoading: false,
        });
      });
  };

  showSuccessToast(successToastMessage) {
    this.setState(
      {
        successToastMessage: successToastMessage,
        callRequest: true,
        showToast: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showToast: false,
            callRequest: false,
          });
        }, 3000);
      }
    );
  }

  showFailureToast(errorDesc) {
    this.setState(
      {
        failRequest: true,
        showToast: true,
        errorDesc,
      },
      () => {
        setTimeout(() => {
          this.setState({
            showToast: false,
            failRequest: true,
            errorDesc: null,
          });
        }, 3000);
      }
    );
  }

  toggleToast = () => {
    this.setState({
      showToast: false,
    });
  };

  submitCancelTpaRequest = () => {
    const { requestId } = this.props.offlineConsult;
    return tpaCancelTpaRequest(requestId)
      .then(() => {
        console.log('Reached on Success');
        toast.success('Successfully Cancelled TPA Request');
        this.getOfflineRequest();
      })
      .catch((err) => {
        console.error(err, 'Failed to cancel TPA Request');
        toast.error('Failed to cancel TPA Request');
      })
      .finally(() => {
        this.setState({
          tpaCancelLoading: false,
          showConfirmationModal: false,
          confirmationMessage: '',
        });
      });
  };

  refreshPayoutRequest = () => {
    this.setState({
      loading: true,
    });
    const { requestId } = this.props.offlineConsult;
    return refreshPayoutRequest(requestId)
      .then((resp) => {
        if (resp.message === 'success') {
          toast.success('Refresh Payout Success');
        } else {
          toast.error('Something went wrong');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong');
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  handleAddSpocModal = () => {
    console.log('closing modal');
    this.setState({
      addSpocModalOpen: false,
    });
  };

  handlePanNumberModal = () => {
    console.log('closing modal');
    this.setState({
      addPanNumberModalOpen: false,
    });
  };

  handleZohoModal = () => {
    console.log('closing modal');
    this.setState({
      addZohoModalOpen: false,
    });
  };

  getCallbackForTheConfirmation = () => {
    const {
      callUserLoading,
      callPatientLoading,
      callCustomLoading,
      callDoctorLoading,
      tpaCancelLoading,
      waiveOffCancellationFeesLoading,
      requestPaymentLoading,
    } = this.state;
    if (callUserLoading) {
      return this.callUser;
    }
    if (callPatientLoading) {
      return this.callPatient;
    }
    if (callCustomLoading) {
      return this.callCustom;
    }
    if (callDoctorLoading) {
      return this.callDoctor;
    }
    if (tpaCancelLoading) {
      return this.submitCancelTpaRequest;
    }
    if (waiveOffCancellationFeesLoading) {
      return this.waiveOffCancellationFees;
    }
    if (requestPaymentLoading) {
      return this.requestPayment;
    }
    return Promise.resolve(() => {});
  };

  componentWillUnsetMount() {
    this.props.dispatch(unsetOfflineConsultRequestId());
  }

  waiveOffCancellationFees = () => {
    const { requestId } = this.props.offlineConsult;
    return waiveOffCancellationFeesService(requestId)
      .then((res) => {
        this.getOfflineRequest();
      })
      .catch((err) => {
        console.log(err);
        this.showFailureToast(err.errorMessage);
      })
      .finally(() => {
        this.setState({
          waiveOffCancellationFeesLoading: false,
          showConfirmationModal: false,
        });
      });
  };

  resendCashlessLetter = () => {
    const { requestId } = this.props.offlineConsult;
    console.log(requestId, 'resend cashless');
    return resendCashlessLetter(requestId)
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Resent Cashless Letter');
        } else {
          const err = new Error('Invalid response');
          err.data = res;
          throw err;
        }
      })
      .catch((err) => {
        console.log({ err, response: err.data });
        toast.error('failed to resend cashless letter ');
      });
  };

  resendReconfirmationCommsSpocs = () => {
    const { requestId } = this.props.offlineConsult;
    console.log(requestId, 'resend cashless');
    return resendReconfirmationCommsToSpocs(requestId)
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Reconfirmation Comms resent to SPOCS');
        } else {
          const err = new Error('Invalid response');
          err.data = res;
          throw err;
        }
      })
      .catch((err) => {
        console.log({ err, response: err.data });
        toast.error('failed to resend cashless letter ');
      });
  };

  resendReconfirmationCommsUser = () => {
    const { requestId } = this.props.offlineConsult;
    console.log(requestId, 'resend cashless');
    return resendReconfirmationCommsToUser(requestId)
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Reconfirmation Comms resent to user');
        } else {
          const err = new Error('Invalid response');
          err.data = res;
          throw err;
        }
      })
      .catch((err) => {
        console.log({ err, response: err.data });
        toast.error('failed to resend cashless letter ');
      });
  };

  handleButtonForAddingSpocs = () => {
    this.setState({
      addSpocModalOpen: true,
    });
  };

  handleButtonForAddingPan = () => {
    this.setState({
      addPanNumberModalOpen: true,
    });
  };

  requestPayment = () => {
    const { requestId } = this.props.offlineConsult;
    return requestPayment(requestId)
      .then((res) => {
        console.log(res, 'request payment');
        if (res.message === 'success') {
          this.showSuccessToast('Successfully requested Payout.');
          if (res.showMissingFieldWarning) {
            toast.warn(
              `There are missing details of the partner. Missing fields : ${res.missingFields.join(
                ', '
              )}. Please get the details filled in by the network team to avoid blocking the request payout.`
            );
          }
          this.getOfflineRequest();
        } else {
          const err = new Error('Invalid response');
          err.data = res;
          throw err;
        }
      })
      .catch((err) => {
        console.log({ err, response: err.data });
        this.showFailureToast(err.data.errorMessage);
      })
      .finally(() => {
        this.setState({
          requestPaymentLoading: false,
          showConfirmationModal: false,
        });
      });
  };

  onOptionSelect = (option) => {
    console.log({ option });
    this.setState({
      userType: option.value,
    });
  };

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: '0.1rem solid #8852cc',
      // // color: '#8852cc',
    }),
  };

  uploadPrescription = () => {
    const authToken = this.props.user.token;
    const { requestId } = this.props.offlineConsult;
    const { consultationId, userId, doctorId } = this.state.basicInfo;
    const file = document.querySelector('#imageAttachment').files[0];
    if (file) {
      this.setState({
        uploading: true,
      });
      document.querySelector('#imageAttachment').value = null;
      const onUploadProgress = (progressEvent) => {
        if (progressEvent.lengthComputable) {
          const { loaded, total } = progressEvent;
          this.setState({
            uploadProgress: (loaded / total) * 100,
          });
        } else {
          this.setState({
            uploadProgress: 100,
          });
          toast.success('File uploaded');
        }
      };
      uploadImageAttachment(
        file,
        doctorId,
        consultationId,
        userId,
        requestId,
        onUploadProgress,
        authToken
      )
        .then((res) => {
          this.setState({
            uploading: false,
            uploadProgress: 0,
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error('Something went wrong. Couldnot upload the prescription');
        });
    }
  };

  openAttachment = () => {
    document.querySelector('#imageAttachment').click();
  };

  pacOrNoShowText = (e) => {
    console.log(e, 'target value for pac');
    this.setState({ pacOrNoShow: e.value });
  };

  callPacOrNoShow = (type) => {
    const { requestId } = this.props.offlineConsult;
    const body = { requestId };
    if (type === 'pac') {
      body.pac = this.state.pacOrNoShow;
    } else {
      body.noShow = this.state.pacOrNoShow;
    }

    pacOrNoShow(body, requestId)
      .then((res) => {
        console.log({ res });
        if (res.message === 'success') {
          this.setState({
            pacOrNoShow: '',
          });
          toast.success('Success!!!');
          this.getOfflineRequest();
        } else if (res.message === 'serverError') {
          toast.error(res.errorMessage);
        } else {
          toast.error('Something went wrong!');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong!');
      });
  };

  changeInvoiceMandatory = (e) => {
    const { requestId } = this.props.offlineConsult;
    changeInvoiceMandatory(e.target.checked, requestId)
      .then((res) => {
        if (res.message === 'success') {
          this.showSuccessToast('Successfully marked mandatory.');
          this.getOfflineRequest();
        } else {
          const err = new Error('Invalid response');
          err.data = res;
          throw err;
        }
      })
      .catch((err) => {
        console.log({ err, response: err.data });
        this.showFailureToast(err.data.errorMessage);
      });
  };

  handleReopenNoShow = () => {
    const { requestId } = this.props.offlineConsult;
    if (!requestId) {
      return;
    }
    return reopentNoShow(requestId)
      .then((res) => {
        console.log({ res });
        if (res.message === 'success') {
          toast.success('Success!!!');
          this.getOfflineRequest();
        } else if (res.message === 'serverError') {
          toast.error(res.errorMessage);
        } else {
          toast.error('Something went wrong!');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Something went wrong!');
      });
  };

  toggleRadioInt = (value) => {
    const { requestId } = this.props.offlineConsult;
    console.log(value, 'Checked value toggle');
    this.setState({
      cashlessLetterChecked: value ? 1 : 0,
    });
    // Mark cashless as sent api
    const status = value ? 1 : 0;
    updateCashlessLetterStatus(requestId, { status })
      .then((resp) => {
        if (resp.message === 'success') {
          toast.success('Success');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed');
      });
  };

  refreshPayoutStatus = () => {
    const { requestId } = this.props.offlineConsult;
    return getPayoutStatus(requestId).then((resp) => {
      if (resp.payoutStatus === 'success') {
        this.setState({ payoutStatus: 'Approved' });
      }
    });
  };

  toggleRefreshPayoutModal = () => {
    const { refreshPayoutModalOpen } = this.state;
    this.setState({
      refreshPayoutModalOpen: !refreshPayoutModalOpen,
    });
  };

  getCenterInfoForOfflineConsultations = () => {
    const { requestId } = this.props.offlineConsult;
    getCenterInfoOfflineRequest(requestId)
      .then((responseBody) => {
        const { centerInfo } = responseBody;
        console.log(
          {
            centerInfo,
          },
          'get center info offline request response'
        );
        this.setState({
          centerInfo,
        });
      })
      .then(() => viewCommentRequest(requestId, appConfig.REQUEST_CHAT.NETWORK))
      .then(this.handleComments)
      .then((comments) => {
        // setting the state
        console.log(comments);
        this.setState({
          comments,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getPaymentInfoForOfflineConsultations = () => {
    const { requestId } = this.props.offlineConsult;
    getPaymentInfoOfflineRequest(requestId)
      .then((responseBody) => {
        const { paymentInfo } = responseBody;
        console.log(
          {
            paymentInfo,
          },
          'get payment info offline request response'
        );
        this.setState({
          paymentInfo,
          payoutStatus: paymentInfo.payoutStatus,
        });
      })
      .then(() => viewCommentRequest(requestId, appConfig.REQUEST_CHAT.NETWORK))
      .then(this.handleComments)
      .then((comments) => {
        // setting the state
        console.log(comments);
        this.setState({
          comments,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChangeForCallReason = (event) => {
    const { value } = event.target;
    this.setState({ selectedCallReason: value, colorGreading: true });
  };

  getActionsInfoForOfflineConsultations = () => {
    const { requestId } = this.props.offlineConsult;
    getActionsInfoOfflineRequest(requestId)
      .then((responseBody) => {
        const { actions } = responseBody;
        console.log(
          {
            actions,
            responseBody,
          },
          'get actions info offline request response'
        );
        this.setState({
          actions,
        });
      })
      .then(() => viewCommentRequest(requestId, appConfig.REQUEST_CHAT.NETWORK))
      .then(this.handleComments)
      .then((comments) => {
        // setting the state
        console.log(comments);
        this.setState({
          comments,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const {
      callPatientLoading,
      callDoctorLoading,
      callUserLoading,
      comments,
      loading,
      confirmationMessage,
      showConfirmationModal,
      activeInfo,
    } = this.state;
    const {
      details,
      basicInfo,
      paymentInfo,
      centerInfo,
      totalConsultations,
      actions,
      lastConsultationDetails,
      rescheduleInfo,
      emergencyCalls,
      payoutStatus,
      userRequestedInfo
    } = this.state;
    const { requestId } = this.props.offlineConsult;

    if (!showConfirmationModal) {
      this.state.selectedCallReason = null;
      this.state.isCallModalOpen = false;
    }

    const renderReschduleInfo = () => {
      if (rescheduleInfo && rescheduleInfo.length) {
        return rescheduleInfo.map((resInfo, index) => (
          <SectionInnerContainer>
            <SectionHeader>Reschedule({index + 1}) Info:</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>New Appoint. DateTime:</DetailName>
                <DetailText>
                  {resInfo.newApptDateTime || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Doctor Name:</DetailName>
                <DetailText>
                  {resInfo.doctorName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Vertical:</DetailName>
                <DetailText>
                  {resInfo.doctorVertical || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Center Name:</DetailName>
                <DetailText>
                  {resInfo.centerName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Slot Type:</DetailName>
                <DetailText>{resInfo.slotType}</DetailText>
              </DetailRow>
              {resInfo.slotType == 'walk-in' && (
                <DetailRow>
                  <DetailName>Slot Range:</DetailName>
                  <DetailText>{resInfo.slotTimeRange}</DetailText>
                </DetailRow>
              )}
              <DetailRow>
                <DetailName>Center/Ops User:</DetailName>
                <DetailText>
                  {resInfo.networkCenterUsersName || resInfo.opsName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
            </SectionBody>
          </SectionInnerContainer>
        ));
      } else {
        return <div>No Reschedule Info Found</div>;
      }
    };

    const renderUserRequestedInfo = () => {
      if (userRequestedInfo) {
        return <div>
          <SectionInnerContainer>
            <SectionHeader>User Requested Info:</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Appoint. Date:</DetailName>
                
                <DetailText>
                  {basicInfo.userRequestedSlotTiming &&
                  basicInfo.userRequestedDateKey ? (
                    <>
                      {basicInfo.userRequestedDateKey} -{' '}
                      {basicInfo.userRequestedSlotTiming}
                    </>
                  ) : (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Doctor Name:</DetailName>
                <DetailText>
                  {userRequestedInfo.userRequestedDoctorName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Vertical:</DetailName>
                <DetailText>
                  {userRequestedInfo.userRequestedDoctorVertical || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Center Name:</DetailName>
                <DetailText>
                  {userRequestedInfo.userRequestedCenterName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Center Address:</DetailName>
                <DetailText>
                {userRequestedInfo.userRequestedCenterAddress || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Center Id:</DetailName>
                <DetailText>
                {userRequestedInfo.userRequestedCenterId || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
             
              
            </SectionBody>
          </SectionInnerContainer>
          </div>
        
      } else {
        return <div>No User Requested Info Found</div>;
      }
    };

    const renderBasicInfo = () => (
      <DetailsContainer>
        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>User Booking Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>User Id:</DetailName>
                <DetailText>
                  {basicInfo.userId || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Appointment Id:</DetailName>
                <DetailText>
                  {basicInfo.appointmentId || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Appoint. Date:</DetailName>
                <DetailText>
                  {basicInfo.appointmentDate || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>User Requested Slot:</DetailName>
                <DetailText>
                  {basicInfo.userRequestedSlotTiming &&
                  basicInfo.userRequestedDateKey ? (
                    <>
                      {basicInfo.userRequestedDateKey} -{' '}
                      {basicInfo.userRequestedSlotTiming}
                    </>
                  ) : (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Request Date:</DetailName>
                <DetailText>
                  {basicInfo.requestDate || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Patient Name:</DetailName>
                <DetailText>
                  {basicInfo.patientName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Patient Relation:</DetailName>
                <DetailText>
                  {basicInfo.patientRelation || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Patient Dob:</DetailName>
                <DetailText>
                  {basicInfo.patientDob || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Patient Phone:</DetailName>
                {basicInfo.patientPhone ? (
                  <CallUserText onClick={() => this.initiateCall('PATIENT')}>
                    {callPatientLoading ? (
                      <FaSpinner
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="icon-spin text-success"
                      />
                    ) : (
                      <FaPhone
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="text-success"
                      />
                    )}
                    {'  '}
                    {basicInfo.patientPhone}
                  </CallUserText>
                ) : (
                  <DetailTextDanger>Unavailable</DetailTextDanger>
                )}
              </DetailRow>
              <DetailRow>
                <DetailName>User Location:</DetailName>
                <DetailText>
                  {basicInfo.userLocation || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>User Email:</DetailName>
                <DetailText>
                  {basicInfo.userEmail || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>User Phone:</DetailName>
                {basicInfo.userPhone ? (
                  <CallUserText onClick={() => this.initiateCall('USER')}>
                    {callUserLoading ? (
                      <FaSpinner
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="icon-spin text-success"
                      />
                    ) : (
                      <FaPhone
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="text-success"
                      />
                    )}
                    {'  '}
                    {basicInfo.userPhone}
                  </CallUserText>
                ) : (
                  <DetailTextDanger>Unavailable</DetailTextDanger>
                )}
              </DetailRow>
              <DetailRow>
                <DetailName>Escalation Remarks:</DetailName>
                <DetailText>
                  {basicInfo.escalationRemarks || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
            </SectionBody>
          </SectionInnerContainer>

          <SectionInnerContainer>
            <SectionHeader>Last Consultation Info</SectionHeader>
            <SectionBody>
              {lastConsultationDetails.map((detail) => (
                <DetailRow>
                  <DetailName>{detail.requestId}</DetailName>
                  <DetailText>{`${detail.appointmentDate}`}</DetailText>
                  <DetailText>{`Status: ${detail.status}`}</DetailText>
                </DetailRow>
              ))}
              {lastConsultationDetails.length === 0 && (
                <DetailText>
                  No Previous consultation found for this pair
                </DetailText>
              )}
            </SectionBody>
          </SectionInnerContainer>
          {basicInfo.cancelReason && (
            <SectionInnerContainer>
              <SectionHeader>Cancellation Details</SectionHeader>
              <SectionBody>
                <DetailRow>
                  <DetailName2>Cancellation Reason :</DetailName2>
                  <DetailText>{basicInfo.cancelReason}</DetailText>
                </DetailRow>
                {basicInfo.subCancelReason && (
                  <DetailRow>
                    <DetailName2>Sub Cancellation Reason :</DetailName2>
                    <DetailText>{basicInfo.subCancelReason}</DetailText>
                  </DetailRow>
                )}
                {basicInfo.referenceNumber && (
                  <DetailRow>
                    <DetailName2>Refund ARN :</DetailName2>
                    <DetailText>{basicInfo.referenceNumber}</DetailText>
                  </DetailRow>
                )}
              </SectionBody>
            </SectionInnerContainer>
          )}

          {basicInfo.opsUser && (
            <SectionInnerContainer>
              <SectionHeader>Assigne Details</SectionHeader>
              <SectionBody>
                <DetailRow>
                  <DetailName2>Assigne Ops User Name :</DetailName2>
                  <DetailText>{basicInfo.opsUser}</DetailText>
                </DetailRow>
                {basicInfo.addAssigneTime && (
                  <DetailRow>
                    <DetailName2>Assigned Date :</DetailName2>
                    <DetailText>{basicInfo.addAssigneTime}</DetailText>
                  </DetailRow>
                )}
              </SectionBody>
            </SectionInnerContainer>
          )}

          <SectionInnerContainer>
            <SectionHeader>Emergency Calls Info</SectionHeader>
            <SectionBody>
              {emergencyCalls && emergencyCalls.length > 0 ? (
                emergencyCalls.map((detail) => (
                  <React.Fragment>
                    <DetailRow>
                      <DetailName>Call Status:</DetailName>
                      <DetailText>{detail.callStatus}</DetailText>
                    </DetailRow>
                    <DetailRow>
                      <DetailName>Call Duration:</DetailName>
                      <DetailText>{detail.callDuration}</DetailText>
                    </DetailRow>
                    <DetailRow>
                      <DetailName>Ops User:</DetailName>
                      <DetailText>{detail.opsName}</DetailText>
                    </DetailRow>
                    <br></br>
                  </React.Fragment>
                ))
              ) : (
                <DetailText>
                  No Emergency Calls found for this request
                </DetailText>
              )}
            </SectionBody>
          </SectionInnerContainer>
        </SectionContainer>

        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>Doctor Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Name:</DetailName>
                <DetailText>
                  {basicInfo.doctorName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Vertical:</DetailName>
                <DetailText>
                  {basicInfo.vertical || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Opd Charges:</DetailName>
                <DetailText color="#28A745">
                  {basicInfo.opdCharges || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>OnBoard Status:</DetailName>
                <DetailText>
                  {basicInfo.oStatus || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Tpa Name:</DetailName>
                <DetailText>
                  {basicInfo.tpaName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                  &nbsp;
                  {basicInfo.tpaName &&
                    basicInfo.showTpaCancel &&
                    (!basicInfo.tpaCancelledAt ? (
                      <FaTimes
                        style={{ cursor: 'pointer' }}
                        onClick={this.openCancelTpaModal}
                      ></FaTimes>
                    ) : (
                      `- (Cancelled)`
                    ))}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Center Name:</DetailName>
                <DetailText>
                  {basicInfo.centerName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Center Address:</DetailName>
                <DetailText>
                  {basicInfo.centerAddress || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Center Id</DetailName>
                <DetailText>
                  {basicInfo.centerId || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              {/* <DetailRow>
                <DetailName>Phone:</DetailName>
                <DetailText>
                  {details.doctorPhone ? (
                    <CallUserText onClick={() => this.initiateCall('DOCTOR')}>
                      {callDoctorLoading ? (
                        <FaSpinner
                          size={15}
                          style={{ paddingBottom: 2 }}
                          className="icon-spin text-success"
                        />
                      ) : (
                        <FaPhone
                          size={15}
                          style={{ paddingBottom: 2 }}
                          className="text-success"
                        />
                      )}
                      {'  '}
                      {details.doctorPhone}
                    </CallUserText>
                  ) : (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow> */}
            </SectionBody>
          </SectionInnerContainer>
          {this.state.doctorSlots ? (
            <SectionInnerContainer>
              <SectionHeader>Doctor Slots &nbsp;
               {!this.state.showDoctorNewSlots && <FaEdit className='button' onClick={()=> this.props.showEditDoctorSlotsModal(this.state.basicInfo.doctorId)}/> }
              </SectionHeader>
              <SectionBody>

                {!this.state.showDoctorNewSlots && this.state.doctorSlots.map((slot) => (
                  <DetailRow>
                    <DetailName>{`${slot.day}:`}</DetailName>
                    <DetailText>
                      {slot.values
                        .map((value) => `${value.startTime} - ${value.endTime}`)
                        .join(', ')}
                    </DetailText>
                  </DetailRow>
                ))}
                {this.state.showDoctorNewSlots && this.state.doctorSlots.map((slot) => (
                  <DetailRow key={slot.dateKey}>
                    <DetailName>{`${slot.formattedDateKey}:`}</DetailName>
                    <DetailText>
                      {slot.slots.slice(0,4)
                        .map((value) => `${value.timings}`)
                        .join(', ')} { slot.slots.length > 4 && <span style={{ color: 'green', cursor: 'pointer', fontWeight: 700}} onClick={() => this.setState({doctorNewSlot: {modal: true, data: slot.slots, date: slot.formattedDateKey}})}>...more</span>}
                    </DetailText>
                  </DetailRow>
                ))}
              </SectionBody>
            </SectionInnerContainer>
          ) : (
            <Button color="link" onClick={this.getDoctorSlots}>
              Show Doctor Slots
            </Button>
          )}
          <Modal
  isOpen={this.state.doctorNewSlot.modal}
  onCancel={() => this.setState({doctorNewSlot: { modal: false, data: [] }})}
>
  <ModalHeader
    className="bg-primary"
    toggle={() => this.setState({doctorNewSlot: { modal: false, data: [] }})}
  >
    Doctor Slots
  </ModalHeader>
  <ModalBody>
    <DetailRow>
      <DetailName>{`${this.state.doctorNewSlot.date}:`}</DetailName>
      <DoctorSlotBox>
        {this.state.doctorNewSlot.data.map((value) => (
          <p
            style={{
              padding: "6px",
              backgroundColor: "skyblue",
              borderRadius: "8px",
              margin: "4px",
            }}
          >
            {value.timings}
          </p>
        ))}
      </DoctorSlotBox>
    </DetailRow>
  </ModalBody>
</Modal>
        </SectionContainer>
      </DetailsContainer>
    );

    const renderOlderInfo = () => (
      <DetailsContainer>
        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>User Booking Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>User:</DetailName>
                <DetailText>
                  {details.userName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
                <DetailText>{` (${details.userId})`}</DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Phone:</DetailName>
                {details.userPhone ? (
                  <CallUserText onClick={() => this.initiateCall('USER')}>
                    {callUserLoading ? (
                      <FaSpinner
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="icon-spin text-success"
                      />
                    ) : (
                      <FaPhone
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="text-success"
                      />
                    )}
                    {'  '}
                    {details.userPhone}
                  </CallUserText>
                ) : (
                  <DetailTextDanger>Unavailable</DetailTextDanger>
                )}
              </DetailRow>
              <DetailRow>
                <DetailName>Email:</DetailName>
                <DetailText>
                  {details.userEmail || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Sponsor:</DetailName>
                <DetailText>
                  {details.sponsorName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
            </SectionBody>
          </SectionInnerContainer>

          <SectionInnerContainer>
            <SectionHeader>Patient Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Patient:</DetailName>
                <DetailText>
                  {details.patientName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Phone:</DetailName>
                {details.patientPhone ? (
                  <CallUserText onClick={() => this.initiateCall('PATIENT')}>
                    {callPatientLoading ? (
                      <FaSpinner
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="icon-spin text-success"
                      />
                    ) : (
                      <FaPhone
                        size={15}
                        style={{ paddingBottom: 2 }}
                        className="text-success"
                      />
                    )}
                    {'  '}
                    {details.patientPhone}
                  </CallUserText>
                ) : (
                  <DetailTextDanger>Unavailable</DetailTextDanger>
                )}
              </DetailRow>
              <DetailRow>
                <DetailName>Gender:</DetailName>
                <DetailText>
                  {details.patientGender || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>DOB:</DetailName>
                <DetailText>
                  {details.patientDob || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Location:</DetailName>
                <DetailText>
                  {details.location || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
            </SectionBody>
          </SectionInnerContainer>

          <SectionInnerContainer>
            <SectionHeader>Appointment Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Appoint. Id:</DetailName>
                <DetailText>
                  {details.appointmentId || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Appoint. Date:</DetailName>
                <DetailText>
                  {details.appointmentDate || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Doctor Fee:</DetailName>
                <DetailText>
                  {details.uCharges || details.doctorCharges || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>User Paid:</DetailName>
                <DetailText>{details.userPaid || 0}</DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Status:</DetailName>
                <DetailText>
                  {details.status || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Request Date:</DetailName>
                <DetailText>
                  {details.requestDate || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>User Slots:</DetailName>
                <DetailText>
                  {details.userSelectedSlots || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              {details.cancellationFees && (
                <>
                  <DetailRow>
                    <DetailName>Cancel Charges:</DetailName>
                    <DetailTextDanger>
                      {details.cancellationFees}
                    </DetailTextDanger>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Cancel Status:</DetailName>
                    <DetailText>
                      {details.cancellationStatus ? 'Paid' : 'Unpaid'}
                    </DetailText>
                    {!details.cancellationStatus && (
                      <button
                        className="btn btn-primary btn-sm"
                        style={{ marginLeft: 10 }}
                        onClick={() =>
                          this.waiveOffCancellationFees(basicInfo.requestId)
                        }
                      >
                        Waive off
                      </button>
                    )}
                  </DetailRow>
                </>
              )}
            </SectionBody>
          </SectionInnerContainer>

          <SectionInnerContainer>
            <SectionHeader>Call Stakeholders</SectionHeader>
            <CustomCallingSection />
          </SectionInnerContainer>
        </SectionContainer>

        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>Doctor Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Name:</DetailName>
                <DetailText>
                  {details.doctorName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Vertical:</DetailName>
                <DetailText>
                  {details.verticalName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Phone:</DetailName>
                <DetailText>
                  {details.doctorPhone ? (
                    <CallUserText onClick={() => this.initiateCall('DOCTOR')}>
                      {callDoctorLoading ? (
                        <FaSpinner
                          size={15}
                          style={{ paddingBottom: 2 }}
                          className="icon-spin text-success"
                        />
                      ) : (
                        <FaPhone
                          size={15}
                          style={{ paddingBottom: 2 }}
                          className="text-success"
                        />
                      )}
                      {'  '}
                      {details.doctorPhone}
                    </CallUserText>
                  ) : (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Email:</DetailName>
                <DetailText>
                  {details.doctorEmail || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
            </SectionBody>
          </SectionInnerContainer>

          <SectionInnerContainer>
            <SectionHeader>Center Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Practice Center:</DetailName>
                <DetailText>
                  {details.networkCenterName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Location:</DetailName>
                <DetailText>
                  {details.networkCenterLocation || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>SPOC Name:</DetailName>
                <DetailText>
                  {details.spocName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>SPOC Phone:</DetailName>
                <DetailText>
                  {details.spocPhone || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>TPA Name:</DetailName>
                <DetailText>
                  {details.tpaName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                  &nbsp;
                  {details.tpaName &&
                    details.showTpaCancel &&
                    (!details.tpaCancelledAt ? (
                      <FaTimes
                        style={{ cursor: 'pointer' }}
                        onClick={this.openCancelTpaModal}
                      ></FaTimes>
                    ) : (
                      `- (Cancelled)`
                    ))}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Assistant Name:</DetailName>
                <DetailText>
                  {details.assistantName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Assistant Phone:</DetailName>
                <DetailText>
                  {details.assistantNumber || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Onboard Status:</DetailName>
                <DetailText>
                  {details.doctorOnBoardingStatus || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Visit Spoc:</DetailName>
                <DetailText>
                  {details.visitSpoc || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Claimed by:</DetailName>
                <DetailText>
                  {details.onboardingUser || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Remarks:</DetailName>
                <DetailText>
                  {details.remarks || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
            </SectionBody>
          </SectionInnerContainer>
          <SectionInnerContainer>
            <SectionHeader>Misc Options</SectionHeader>
            <UploadPrescriptionButton onTap={this.openAttachment}>
              <UploadText>Upload Prescripiton</UploadText>
            </UploadPrescriptionButton>
          </SectionInnerContainer>
        </SectionContainer>
      </DetailsContainer>
    );

    const renderCenterInfo = () => (
      <DetailsContainer>
        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>Center Details</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Practice Center:</DetailName>
                <DetailText>
                  {centerInfo.title || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Location:</DetailName>
                <DetailText>
                  {centerInfo.address || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>City:</DetailName>
                <DetailText>
                  {centerInfo.city || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Locality:</DetailName>
                <DetailText>
                  {centerInfo.locality || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Visit SPOC:</DetailName>
                <DetailText>
                  {centerInfo.visitSpoc || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Claimed By:</DetailName>
                <DetailText>
                  {centerInfo.claimedBy || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              {/* <DetailRow>
                <DetailName>TPA Name:</DetailName>
                <DetailText>
                  {details.tpaName || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                  &nbsp;
                  {details.tpaName &&
                    details.showTpaCancel &&
                    (!details.tpaCancelledAt ? (
                      <FaTimes
                        style={{ cursor: 'pointer' }}
                        onClick={this.openCancelTpaModal}
                      ></FaTimes>
                    ) : (
                      `- (Cancelled)`
                    ))}
                </DetailText>
              </DetailRow> */}
              <DetailRow>
                <DetailName>Remarks:</DetailName>
                <DetailText>
                  {centerInfo.remarks || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Source:</DetailName>
                <DetailText>
                  {centerInfo.source || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Total Consultations:</DetailName>
                <DetailText>
                  {totalConsultations || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              {/* <DetailRow>
                <DetailName>Onboard Status:</DetailName>
                <DetailText>
                  {details.doctorOnBoardingStatus || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow> */}
            </SectionBody>
          </SectionInnerContainer>
        </SectionContainer>

        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>
              SPOC Details{' '}
              <Button
                onClick={this.handleButtonForAddingSpocs}
                style={{ marginLeft: '20px', fontSize: '12px' }}
              >
                Send Request for Adding SPOC
              </Button>
            </SectionHeader>
            <SectionBody>
              {centerInfo.spocs &&
                centerInfo.spocs.map((spoc) => {
                  return (
                    <SpocDetailContainer>
                      <DetailRow>
                        <DetailName>Name:</DetailName>
                        <DetailText>{`${spoc.name} (${spoc.type})`}</DetailText>
                      </DetailRow>
                      <DetailRow>
                        <DetailName>Email:</DetailName>
                        <DetailText>{spoc.email}</DetailText>
                      </DetailRow>
                      <DetailRow>
                        <DetailName>Phone:</DetailName>
                        <DetailText>{spoc.phone}</DetailText>
                      </DetailRow>
                      <DetailRow>
                        <DetailName>Comm. Type:</DetailName>
                        <DetailText>
                          {spoc.commType || 'Unavailable'}
                        </DetailText>
                      </DetailRow>
                      <DetailRow>
                        <DetailName>Send Whatsapp:</DetailName>
                        <DetailText>
                          {spoc.sendWhatsApp ? 'Yes' : 'No'}
                        </DetailText>
                      </DetailRow>
                      <DetailRow>
                        <DetailName>Send Email:</DetailName>
                        <DetailText>{spoc.sendEmail ? 'Yes' : 'No'}</DetailText>
                      </DetailRow>
                      <DetailRow>
                        <DetailName>Send CC:</DetailName>
                        <DetailText>{spoc.sendCC ? 'Yes' : 'No'}</DetailText>
                      </DetailRow>
                      <DetailRow>
                        <DetailName>Send SMS:</DetailName>
                        <DetailText>{spoc.sendSMS ? 'Yes' : 'No'}</DetailText>
                      </DetailRow>
                      <DetailRow>
                        <DetailName>Send Call:</DetailName>
                        <DetailText>{spoc.sendCall ? 'Yes' : 'No'}</DetailText>
                      </DetailRow>
                    </SpocDetailContainer>
                  );
                })}
            </SectionBody>
          </SectionInnerContainer>
        </SectionContainer>
      </DetailsContainer>
    );

    const renderPaymentInfo = () => (
      <DetailsContainer>
        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>Charges</SectionHeader>
            <SectionBody>
              <DetailRow>
                <DetailName>Opd Charges:</DetailName>
                <DetailText color="28A745">
                  {paymentInfo.opdCharges || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Offline Discount:</DetailName>
                <DetailText>
                  {paymentInfo.offlineDiscount || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Followup Disc.:</DetailName>
                <DetailText>
                  {paymentInfo.fDiscount ? (
                    `${paymentInfo.fDiscount} within ${paymentInfo.fDays} days`
                  ) : (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              {paymentInfo.fulfilment && (
                <DetailRow>
                  <DetailName>TDS percentage:</DetailName>
                  <DetailText>
                    {paymentInfo.fulfilment.tds ? (
                      `${paymentInfo.fulfilment.tds} %`
                    ) : (
                      <DetailTextDanger>Unavailable</DetailTextDanger>
                    )}
                  </DetailText>
                </DetailRow>
              )}
              <DetailRow>
                <DetailName>Approved Amt:</DetailName>
                <DetailText>
                  {paymentInfo.approvedAmount || (
                    <DetailTextDanger>Unavailable</DetailTextDanger>
                  )}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Payout Status.:</DetailName>
                {paymentInfo.payoutStatus ? (
                  <Badge color="info" pill>
                    {payoutStatus}
                  </Badge>
                ) : (
                  <DetailTextDanger>Unavailable</DetailTextDanger>
                )}
                <div style={{ marginLeft: '15px' }}>
                  <FaUndo onClick={this.refreshPayoutStatus} size={12} />
                </div>
              </DetailRow>
              <DetailRow>
                <DetailName>Payout Receipt:</DetailName>
                <DetailText>
                  {paymentInfo.payoutReceipt
                    ? paymentInfo.payoutReceipt.split(',').map((ele, idx) => {
                        return (
                          <a
                            key={idx}
                            href={ele}
                            target="_blank"
                            type="button"
                            className="btn btn-link"
                            role="button"
                          >{`Receipt ${idx + 1}`}</a>
                        );
                      })
                    : 'No receipts uploaded'}
                </DetailText>
              </DetailRow>
              <DetailRow>
                <DetailName>Payout Invoice:</DetailName>
                <DetailText>
                  {paymentInfo.payoutInvoice
                    ? paymentInfo.payoutInvoice.split(',').map((ele, idx) => {
                        return (
                          <a
                            key={idx}
                            href={ele}
                            target="_blank"
                            type="button"
                            className="btn btn-link"
                            role="button"
                          >{`Invoice ${idx + 1}`}</a>
                        );
                      })
                    : 'No invoices uploaded'}
                </DetailText>
              </DetailRow>
              {paymentInfo?.fulfilment &&
                paymentInfo.fulfilment.paymentCycle ==
                  'before consultation' && (
                  <DetailRow>
                    <DetailName>Reconfiirmed by user :</DetailName>
                    <DetailText>{this.state.userCommsStatus}</DetailText>
                  </DetailRow>
                )}
              {paymentInfo?.fulfilment &&
                paymentInfo.fulfilment.paymentCycle ==
                  'before consultation' && (
                  <DetailRow>
                    <DetailName>Reconfirmed by SPOC :</DetailName>
                    <DetailText>{this.state.spocCommsStatus}</DetailText>
                  </DetailRow>
                )}
              {paymentInfo?.fulfilment &&
              paymentInfo.fulfilment.paymentCycle == 'before consultation' &&
              basicInfo.requestStatus ? (
                <DetailRow>
                  <DetailName>Reconfirmation Comms (SPOCS):</DetailName>
                  <Button
                    onClick={this.resendReconfirmationCommsSpocs}
                    disabled={
                      basicInfo.reconfirmedByHospital ||
                      basicInfo.requestStatus < 0
                    }
                  >
                    Send
                  </Button>
                </DetailRow>
              ) : (
                <></>
              )}
              {paymentInfo?.fulfilment &&
              paymentInfo.fulfilment.paymentCycle == 'before consultation' &&
              basicInfo.requestStatus ? (
                <DetailRow>
                  <DetailName>Reconfirmation Comms (USER):</DetailName>
                  <Button
                    onClick={this.resendReconfirmationCommsUser}
                    disabled={
                      basicInfo.reconfirmedByUser || basicInfo.requestStatus < 0
                    }
                  >
                    Send
                  </Button>
                </DetailRow>
              ) : (
                <></>
              )}
              {paymentInfo.showPayoutRequest && basicInfo.requestStatus > 0 ? (
                <DetailRow>
                  <DetailName>Payment Req:</DetailName>
                  <PaymentButton
                    onSubmit={() =>
                      this.setState({
                        requestPaymentLoading: true,
                        showConfirmationModal: true,
                        confirmationMessage: 'Request Payout? Are you sure?',
                      })
                    }
                  />
                  <div style={{ marginLeft: '15px' }}>
                    <FaUndo onClick={this.toggleRefreshPayoutModal} size={12} />
                  </div>
                </DetailRow>
              ) : (
                <DetailRow>
                  <DetailName>Resend Cashless Letter : </DetailName>
                  <Button onClick={this.resendCashlessLetter}>Send</Button>
                </DetailRow>
              )}

              {paymentInfo.pac || paymentInfo.noShow ? (
                <DetailRow>
                  <DetailName>
                    {paymentInfo.pac ? 'PAC: ' : 'No Show'}
                  </DetailName>
                  <DetailText>
                    {paymentInfo.pac || paymentInfo.noShow}
                  </DetailText>
                </DetailRow>
              ) : null}
              {paymentInfo.coPay ? (
                <DetailRow>
                  <DetailName>Total Copay</DetailName>
                  <DetailText>{paymentInfo.coPay.totalCoPay}</DetailText>
                </DetailRow>
              ) : null}
              {paymentInfo.coPay ? (
                <DetailRow>
                  <DetailName>Paid Copay</DetailName>
                  <DetailText>{paymentInfo.coPay.paidCoPay}</DetailText>
                </DetailRow>
              ) : null}
            </SectionBody>
          </SectionInnerContainer>
          {paymentInfo.fulfilment ? (
            <SectionInnerContainer>
              <SectionBody>
                <DetailRow>
                  <Input
                    type="checkbox"
                    id="checkbox"
                    onChange={this.changeInvoiceMandatory}
                    checked={paymentInfo.fulfilment.invoiceMandatory}
                  />
                  <DetailName fullWidth>Invoice Mandatory</DetailName>
                </DetailRow>
              </SectionBody>
            </SectionInnerContainer>
          ) : null}
          <SectionInnerContainer>
            {this.state.transactions && this.state.transactions.length ? (
              <div className="card">
                <div className="card-header">
                  <div className="row no-gutters">
                    <div className="col">Transaction History</div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="container-fluid">
                    {this.state.transactions
                      .filter((t) => t.type !== 'walletRefund')
                      .map((t) => (
                        <div className="row" key={`${t.id}-${t.type}`}>
                          {t.amount > 0 || t.walletDeduction > 0 ? (
                            <div className="col">
                              {t.amount > 0 ? (
                                <li>
                                  {t.type === 'refund' ? 'Refund' : 'Copay'} of
                                  Rs. {t.amount} on {t.formattedDate} -{' '}
                                  {t.status}
                                </li>
                              ) : (
                                <li>
                                  {t.type === 'refund' ? 'Refund' : 'Copay'} of
                                  Rs. {t.walletDeduction} deducted from Wallet
                                  on {t.formattedDate} - {t.status}
                                </li>
                              )}
                            </div>
                          ) : null}
                        </div>
                      ))}
                    {this.state.transactions
                      .filter((t) => t.type === 'walletRefund')
                      .map((t) => (
                        <div className="row" key={`${t.id}-${t.type}`}>
                          {t.amount > 0 || t.walletDeduction > 0 ? (
                            <div className="col">
                              <li>
                                Wallet Refund of Rs. {t.amount} on{' '}
                                {t.formattedDate} - {t.status}
                              </li>
                            </div>
                          ) : null}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ) : null}
          </SectionInnerContainer>
          <SectionInnerContainer>
            {this.state.doctorPriceChanges &&
            this.state.doctorPriceChanges.length ? (
              <div className="card">
                <div className="card-header">
                  <div className="row no-gutters">
                    <div className="col">Doctor Price Change History</div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="container-fluid">
                    {this.state.doctorPriceChanges.map((t) => (
                      <div className="row" key={`${t.id}-${t.type}`}>
                        {t.oldPrice > 0 && t.newPrice > 0 ? (
                          <div className="col">
                            {
                              <>
                                <li>
                                  Doctor Price have been changed from Rs.{' '}
                                  {t.oldPrice} to Rs. {t.newPrice} on{' '}
                                  {t.updatedAt}
                                </li>
                                {/* <li>Reason for Price Change - {t.reason}</li> */}
                              </>
                            }
                          </div>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </SectionInnerContainer>
        </SectionContainer>

        <SectionContainer>
          <SectionInnerContainer>
            <SectionHeader>Fulfilment Details</SectionHeader>
            <SectionBody>
              {paymentInfo.fulfilment && (
                <SpocDetailContainer>
                  <DetailRow>
                    <DetailName>Fulfilment Id:</DetailName>
                    <DetailText>{`${paymentInfo.fulfilment.fulfilmentId}`}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Pref. Payment:</DetailName>
                    <DetailText>
                      {paymentInfo.fulfilment.preferredPayment}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Payment Cycle:</DetailName>
                    <DetailText>
                      {paymentInfo.fulfilment.paymentCycle}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Cancel'd Cheque:</DetailName>
                    <DetailText>
                      <a
                        href={paymentInfo.fulfilment.cancelledChequeImg}
                        target="_blank"
                      >
                        {paymentInfo.fulfilment.cancelledChequeImg
                          ? 'Click Here'
                          : 'NA'}
                      </a>
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Payee Name:</DetailName>
                    <DetailText
                      style={{
                        display: 'flex',
                      }}
                    >
                      {paymentInfo.fulfilment.payeeName ?? (
                        <>
                          <p
                            style={{ marginRight: '16px', marginBottom: '0px' }}
                          >
                            <DetailTextDanger>Unavailable</DetailTextDanger>
                          </p>
                          {paymentInfo.fulfilment.preferredPayment ==
                            'Bank Account' && (
                            <Button
                              style={{
                                height: '28px',
                                fontSize: '10px',
                                margin: 'unset',
                              }}
                              onClick={() =>
                                this.setState({
                                  addPaymentModalOpen: true,
                                  showBankDetails: true,
                                })
                              }
                            >
                              <span
                                style={{
                                  textAlign: 'center',
                                  display: 'block',
                                }}
                              >
                                Add Bank Account
                              </span>
                            </Button>
                          )}
                        </>
                      )}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Account Number:</DetailName>
                    <DetailText>
                      {paymentInfo.fulfilment.accountNumber &&
                        `xxxx-xxxx-xxxx-${paymentInfo.fulfilment.accountNumber.slice(
                          -4
                        )} (${paymentInfo.fulfilment.accountType})`}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Ifsc Code:</DetailName>
                    <DetailText>{paymentInfo.fulfilment.ifscCode}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Paytm:</DetailName>
                    <DetailText>{paymentInfo.fulfilment.upiPhone}</DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Pan Number:</DetailName>
                    <DetailText
                      style={{
                        display: 'flex',
                      }}
                    >
                      {centerInfo.panNumber ?? (
                        <>
                          <p
                            style={{ marginRight: '16px', marginBottom: '0px' }}
                          >
                            <DetailTextDanger>Unavailable</DetailTextDanger>
                          </p>
                          <Button
                            style={{
                              height: '28px',
                              fontSize: '10px',
                              margin: 'unset',
                            }}
                            onClick={this.handleButtonForAddingPan}
                          >
                            <span
                              style={{
                                textAlign: 'center',
                                display: 'block',
                              }}
                            >
                              Add PAN
                            </span>
                          </Button>
                        </>
                      )}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Name on PAN Card:</DetailName>
                    <DetailText
                      style={{
                        display: 'flex',
                      }}
                    >
                      {centerInfo.nameOnPan}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Zoho Id:</DetailName>
                    <DetailText
                      style={{
                        display: 'flex',
                      }}
                    >
                      {centerInfo.zohoVendorId ??
                        (paymentInfo.fulfilment &&
                        paymentInfo.fulfilment.fulfilmentType == 'center' ? (
                          <>
                            <p
                              style={{
                                marginRight: '16px',
                                marginBottom: '0px',
                              }}
                            >
                              <DetailTextDanger>Unavailable</DetailTextDanger>
                            </p>
                            <Button
                              style={{
                                height: '28px',
                                fontSize: '10px',
                                margin: 'unset',
                              }}
                              onClick={this.handleZohoModalOpen}
                            >
                              <span
                                style={{
                                  textAlign: 'center',
                                  display: 'block',
                                }}
                              >
                                Add Cortis Entity
                              </span>
                            </Button>
                          </>
                        ) : (
                          <></>
                        ))}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Zoho Company :</DetailName>
                    <DetailText>
                      {centerInfo.zohoCompanyName ?? (
                        <DetailTextDanger>Unavailable</DetailTextDanger>
                      )}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Zoho Vendor :</DetailName>
                    <DetailText>
                      {centerInfo.zohoVendorName ?? (
                        <DetailTextDanger>Unavailable</DetailTextDanger>
                      )}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>UPI:</DetailName>
                    <DetailText
                      style={{
                        display: 'flex',
                      }}
                    >
                      {paymentInfo.fulfilment.upiAddress ?? (
                        <>
                          <p
                            style={{ marginRight: '16px', marginBottom: '0px' }}
                          >
                            <DetailTextDanger>Unavailable</DetailTextDanger>
                          </p>
                          {paymentInfo.fulfilment.preferredPayment !=
                            'Bank Account' && (
                            <Button
                              style={{
                                height: '28px',
                                fontSize: '10px',
                                margin: 'unset',
                              }}
                              onClick={() => {
                                this.setState({
                                  addPaymentModalOpen: true,
                                });
                              }}
                            >
                              <span
                                style={{
                                  textAlign: 'center',
                                  display: 'block',
                                }}
                              >
                                Add UPI
                              </span>
                            </Button>
                          )}
                        </>
                      )}
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>QR CODE:</DetailName>
                    <DetailText>
                      <a
                        href={paymentInfo.fulfilment.qrCodeImg}
                        target="_blank"
                      >
                        {paymentInfo.fulfilment.qrCodeImg
                          ? 'Click Here'
                          : 'Unavailable'}
                      </a>
                    </DetailText>
                  </DetailRow>
                  <DetailRow>
                    <DetailName>Remarks:</DetailName>
                    <DetailText>{paymentInfo.fulfilment.remarks}</DetailText>
                  </DetailRow>
                </SpocDetailContainer>
              )}
            </SectionBody>
          </SectionInnerContainer>
        </SectionContainer>
      </DetailsContainer>
    );

    const renderMiscInfo = () => (
      <DetailsContainer>
        <SectionContainer>
          {actions.map((action) => {
            switch (action) {
              case 'calling':
                return (
                  <SpocDetailContainer>
                    <SectionHeader>Call Stakeholders</SectionHeader>
                    <CustomCallingSection />
                  </SpocDetailContainer>
                );
              default:
                return null;
            }
          })}
          <SpocDetailContainer>
            <SectionHeader>Other Options</SectionHeader>
            {actions.map((action) => {
              switch (action) {
                case 'prescription-upload':
                  return (
                    <UploadPrescriptionButton onTap={this.openAttachment}>
                      <UploadText>Upload Prescripiton</UploadText>
                    </UploadPrescriptionButton>
                  );
                case 'waive-cancellation':
                  return (
                    <UploadPrescriptionButton
                      onTap={() =>
                        this.setState({
                          waiveOffCancellationFeesLoading: true,
                          showConfirmationModal: true,
                          confirmationMessage:
                            'Are You sure You want to waive off cancellation fees ?',
                        })
                      }
                    >
                      <UploadText>Waive Cancellation Fees</UploadText>
                    </UploadPrescriptionButton>
                  );
                case 'cancel-tpa':
                  return (
                    <UploadPrescriptionButton onTap={this.openCancelTpaModal}>
                      <UploadText>Cancel Tpa</UploadText>
                    </UploadPrescriptionButton>
                  );
                default:
                  return null;
              }
            })}
          </SpocDetailContainer>
          {actions.map((action) => {
            switch (action) {
              case 'show-pac-no-show':
                return (
                  <SpocDetailContainer>
                    <SectionHeader>PAC or No Show</SectionHeader>
                    <Select
                      id="pacOrNoShow"
                      placeholder="Pac or No Show"
                      onChange={this.pacOrNoShowText}
                      options={this.PACOptions}
                    />
                    <div style={{ marginTop: 10 }}>
                      <Button
                        color="warning"
                        onClick={() => this.callPacOrNoShow('pac')}
                      >
                        PAC
                      </Button>{' '}
                      <Button
                        color="info"
                        onClick={() => this.callPacOrNoShow('noShow')}
                      >
                        No Show
                      </Button>
                    </div>
                  </SpocDetailContainer>
                );
              case 'reopen-no-show':
                return (
                  <SpocDetailContainer>
                    <Button color="info" onClick={this.handleReopenNoShow}>
                      Reopen No Show
                    </Button>
                  </SpocDetailContainer>
                );
              default:
                return null;
            }
          })}
           <SpocDetailContainer>{renderUserRequestedInfo()}</SpocDetailContainer>
          <SpocDetailContainer>{renderReschduleInfo()}</SpocDetailContainer>
        </SectionContainer>

        <SectionContainer>
          <SpocDetailContainer>
            <SectionHeader>Cashless Letter</SectionHeader>
            {this.state.basicInfo.cashlessLetterLink ? (
              <>
                <Button
                  color="info"
                  onClick={() =>
                    window.open(`${this.state.basicInfo.cashlessLetterLink}`)
                  }
                >
                  Preview Cashless Letter
                </Button>
                <div style={{ flexDirection: 'row', marginTop: '10px' }}>
                  <input
                    type="checkbox"
                    id={'cashless-' + requestId}
                    onChange={(e) => this.toggleRadioInt(e.target.checked)}
                    checked={this.state.cashlessLetterChecked}
                  />
                  <label style={{ marginLeft: '10px' }}>
                    Cashless Sent Status
                  </label>
                </div>
              </>
            ) : (
              <div>No cashless Letter Found</div>
            )}
          </SpocDetailContainer>
        </SectionContainer>
      </DetailsContainer>
    );

    if (!basicInfo || !requestId) {
      return <LoadingComponent color="black" />;
    }
    if (basicInfo || requestId) {
      return (
        <Container fluid={true}>
          {this.state.uploading && (
            <UploadingComponent
              uploadProgress={`${this.state.uploadProgress}%`}
              borderRadius={0}
            />
          )}
          <div className="row">
            <div className="col-12 text-center justify-content-center">
              <ResultToast
                callRequest={this.state.callRequest}
                failRequests={this.state.failRequest}
                successToastMessage={this.state.successToastMessage}
                errorDesc={this.state.errorDesc}
                show={this.state.showToast}
                onToggle={this.toggleToast}
              />
            </div>
          </div>
          <div className="row">
            <ButtonGroup>
              <Button
                onClick={() => this.setState({ activeInfo: 'basic' })}
                active={activeInfo === 'basic'}
              >
                Basic Info
              </Button>
              <Button
                onClick={() => this.setState({ activeInfo: 'center' })}
                active={activeInfo === 'center'}
              >
                Center Info
              </Button>
              <Button
                onClick={() => this.setState({ activeInfo: 'payment' })}
                active={activeInfo === 'payment'}
              >
                Payment Info
              </Button>
              <Button
                onClick={() => this.setState({ activeInfo: 'misc' })}
                active={activeInfo === 'misc'}
              >
                Actions
              </Button>

              <Button
                onClick={() => {
                  navigator.clipboard.writeText(
                    `
Patient name : ${basicInfo.patientName}
Patient phone : ${basicInfo.patientPhone}
Doctor : ${basicInfo.doctorName},${basicInfo.vertical}
Center name : ${basicInfo.centerName}
Center address : ${basicInfo.centerAddress}
Appointment date : ${basicInfo.appointmentDate}
`
                  );
                }}
              >
                Copy Details
              </Button>
              {/* <Button
                onClick={() => this.setState({ activeInfo: 'older' })}
                active={activeInfo === 'older'}
              >
                Old Info
              </Button> */}
            </ButtonGroup>
          </div>
          <div className="row">
            <div className="col-md-8">
              {activeInfo === 'basic' && renderBasicInfo()}
              {activeInfo === 'center' && renderCenterInfo()}
              {activeInfo === 'payment' && renderPaymentInfo()}
              {activeInfo === 'misc' && renderMiscInfo()}
              {activeInfo === 'older' && renderOlderInfo()}
            </div>
            <div className="col-md-4">
              <CommentList loading={loading} comments={comments} />
              <div className="">
                <CommentForm
                  addComment={this.addComment}
                  requestId={requestId}
                  commentType={appConfig.REQUEST_CHAT.NETWORK}
                />
              </div>
            </div>
          </div>
          <ConfirmationModal
            isOpen={showConfirmationModal}
            confirmationMessage={confirmationMessage}
            callback={this.getCallbackForTheConfirmation()}
            callReasonOptions={this.state.callReasonOptions}
            isCallModalOpen={this.state.isCallModalOpen}
            handleChange={this.handleChangeForCallReason}
            selectedValue={this.state.selectedCallReason}
            toggle={this.toggleConfirmationModal}
          />
          {this.state.addSpocModalOpen && (
            <AddSpocModal
              isModalOpen={this.state.addSpocModalOpen}
              closeSpocsModal={this.handleAddSpocModal}
              centerId={centerInfo.centerId}
              centerName={centerInfo.title}
            />
          )}
          {this.state.addPanNumberModalOpen && (
            <AddPanNumberModal
              isModalOpen={this.state.addPanNumberModalOpen}
              closePanNumberModal={this.handlePanNumberModal}
              centerId={centerInfo.centerId}
              centerName={centerInfo.title}
            />
          )}
          {this.state.addZohoModalOpen && (
            <AddZohoModal
              isModalOpen={this.state.addZohoModalOpen}
              closeZohoModal={this.handleZohoModal}
              centerId={centerInfo.centerId}
              centerName={centerInfo.title}
              fulfilmentId={
                paymentInfo.fulfilment
                  ? paymentInfo.fulfilment.fulfilmentId
                  : null
              }
            />
          )}
          {this.state.addPaymentModalOpen && (
            <AddPaymentModal
              isModalOpen={this.state.addPaymentModalOpen}
              closePaymentModal={() =>
                this.setState({ addPaymentModalOpen: false })
              }
              showBankDetails={this.state.showBankDetails}
              centerId={centerInfo.centerId}
              centerName={centerInfo.title}
              fulfilmentId={
                paymentInfo.fulfilment
                  ? paymentInfo.fulfilment.fulfilmentId
                  : null
              }
              fulfilmentType={
                paymentInfo.fulfilment
                  ? paymentInfo.fulfilment.fulfilmentType
                  : null
              }
            />
          )}
          {/*Refresh Payout Modal*/}
          <Modal
            isOpen={this.state.refreshPayoutModalOpen}
            toggle={this.toggleRefreshPayoutModal}
          >
            <ModalHeader
              className="bg-primary"
              toggle={this.toggleRefreshPayoutModal}
            >
              {`Refresh Payout (Request Id: ${requestId})`}
            </ModalHeader>
            <ModalBody>
              {this.state.loading ? (
                <LoadingComponent />
              ) : (
                <Button
                  type="submit"
                  size="sm"
                  onClick={() => this.refreshPayoutRequest(requestId)}
                >
                  Confirm
                </Button>
              )}
            </ModalBody>
          </Modal>
          <input
            type="file"
            id="imageAttachment"
            accept="image/*,.pdf"
            style={{ display: 'none' }}
            onChange={this.uploadPrescription}
          />
        </Container>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  offlineConsult: state.offlineConsult,
});

export default connect(mapStateToProps)(RequestDetails);
