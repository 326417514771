import React, { Component } from "react";
import { connect } from "react-redux";

import appConfig from '../../../config/app-config';
import CommentList from "../comments/commentList";
import CommentForm from "../comments/commentForm";

import { hideInternalComments } from '../../../actions/index';

import { viewCommentRequest } from "../../../services/api/view-comments";

class SupportComments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            callCommentsLoading: false,
            showToast: false,
            callRequest: false,
            comments: this.props.comments,
            loading: false,
        };

        this.addComment = this.addComment.bind(this);
    }

    componentDidMount() {
        const { requestId } = this.props.customerSupport;
        viewCommentRequest(requestId, appConfig.REQUEST_CHAT.INTERNAL)
            .then((response) => this.handleComments(response))
            .then((comments) => {
                console.log({ requestId, commen: comments.length }, 'Modal Open');
                // setting the state
                this.setState({
                    comments,
                    requestChat: {
                        requestId,
                    },
                    requestChatModal: true,
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentWillUnmount() {
        this.props.dispatch(hideInternalComments());
    }

    handleComments = response => {
        const comments = [];
        if (response.body.message === "success" && response.body.results.length) {
            // build comments
            response.body.results.forEach(c => {
                comments.push({
                    name: c.userName,
                    message: c.content,
                    time: c.createdAt
                });
            });
        }
        return comments;
    };

    addComment(comment) {
        this.setState({
            loading: false,
            comments: [...this.state.comments, comment]
        });
    }

    showFailureToast() {
        this.setState(
            {
                failRequest: true,
                showToast: true
            },
            () => {
                setTimeout(() => {
                    this.setState({
                        showToast: false
                    });
                }, 3000);
            }
        );
    }

    toggleToast = () => {
        this.setState({
            showToast: false
        });
    };

    render() {
        const { requestId } = this.props.customerSupport;
        return (
            <div className="row">
                <div className="col">
                    <CommentList
                        loading={this.state.loading}
                        comments={this.state.comments}
                    />
                    <div className="">
                        <CommentForm addComment={this.addComment} requestId={requestId} commentType={appConfig.REQUEST_CHAT.INTERNAL} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    customerSupport: state.customerSupport,
});

export default connect(mapStateToProps)(SupportComments);
