import React from 'react';
import styled from 'styled-components';
import { FaSpinner } from 'react-icons/fa';

const ALink = styled.span`
  cursor: pointer;
  color: green;
`;

export default class PaymentButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleClick = e => {
    const { onSubmit } = this.props;
    console.log({ onSubmit }, 'clicked payment button');
    this.setState({ loading: true });
    onSubmit();
    this.setState({ loading: false });
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        {loading ? (
          <FaSpinner className="icon-spin text-success" />
        ) : (
          <ALink onClick={this.handleClick}>Request Payout</ALink>
        )}
      </div>
    );
  }
}
