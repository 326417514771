import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getDentalRequestComments } from '../../../services/api/cashless-dentals';
import LoadingComponent from '../../common/LoadingComponent';
import CommentList from '../../offline-consultations/comments/commentList';
import CommentForm from '../../cashless-vaccination/components/CommentForm';

const DentalCommentModal = ({
    isOpen,
    onClose,
    requestId,
    onComplete,
    className = 'bg-info'
}) => {

    const [comments, setComments] = useState([])

    useEffect(() => {
        if (!requestId) {
            return
        }
        getDentalRequestComments(requestId)
        .then((response) => {
            if (response && response.data) {
                setComments(response.data.map(c => ({
                    name: c.userName,
                    message: c.comment,
                    time: c.createdAt
                })))
            }
        }).catch((err) => {
            setComments([])
        })
    }, [requestId])

    const addNewComment = (newComment) => {
        setComments(c => ([ ...c, newComment ]))
        onComplete()
    }

    return <Modal isOpen={isOpen} toggle={onClose}>
        {!comments ? 
            <LoadingComponent color={'black'} /> : 
            <>
                <ModalHeader className={className} toggle={onClose}>
                    Comments (Request Id: {requestId})
                </ModalHeader>
                <ModalBody>
                    <CommentList 
                        loading={!comments || !comments.length}
                        comments={comments}
                    />
                    <div>
                        <CommentForm
                            addComment={addNewComment}
                            requestId={requestId}
                            type='dental'
                        />
                    </div>
                </ModalBody>
            </>
        }
    </Modal>
}

export default DentalCommentModal