import React from 'react';
import {PageHeader} from "../common/PageHeader";
import { toast } from 'react-toastify';

import { fetchCenterDetailsByCenterId } from "../../services/api/lab-partners";

class LabsCenterDetailsHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            locality: '',
            partnerName: '',
            partnerType: '',
        };
    }

    getCenterDetails = () => {
        const { partnerId, centerId } = this.props;
        console.log(partnerId, centerId, 'Fetching center details');
        if (isNaN(partnerId) || isNaN(centerId) || partnerId === null || centerId === null) {
            return;
        }
        return fetchCenterDetailsByCenterId(partnerId, centerId)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    const result = body.result;
                    console.log(body, result, "result from endpoint");
                    this.setState({
                        locality: result.locality,
                        partnerName: result.partnerName,
                        partnerType: result.partnerType,
                    });
                    return;
                }
                throw new Error(body.errorMessage || 'Error while fetching the details for the center');
            })
            .catch((err) => {
                toast.error(err.message);
            })
    };

    componentDidMount() {
        this.getCenterDetails();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.centerId !== this.props.centerId || prevProps.partnerId !== this.props.partnerId) {
            this.getCenterDetails();
        }
    }

    render() {
        const { locality, partnerName, partnerType } = this.state;
        return (
            <React.Fragment>
                <PageHeader>{locality} - {partnerName} ({partnerType})</PageHeader>
            </React.Fragment>
        );
    }
}

export default LabsCenterDetailsHeader;
