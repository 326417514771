import React, { Component } from 'react';
import ReactTable from 'react-table';

import ChangePayoutStatusButton from './ChangePayoutStatusButton';
import DownloadCsv from '../../common/DownloadCsv';

import { fetchDoctorConsultations, overridePaymentStatus } from '../../../services/api/online-payment';

class DoctorConsultationTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            consultations: [],
            loading: false,
        };
    }

    fetchDoctorsConsultations = () => {
        const { doctorId, monthYear } = this.props;
        console.log({ doctorId, monthYear },
            '[fetchDoctorsConsultations]');
        this.setState({ loading: true });
        fetchDoctorConsultations(doctorId, monthYear)
            .then((response) => {
                if (response.message !== 'success') {
                    throw new Error('Error in endpoint');
                }
                console.log(response, 'Response from backend');
                this.setState({ consultations: response.data });
            })
            .catch((err) => {
                console.error(err, 'Error while fetching doctors consultations');
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    overrideConsultationStatus = (consultationId, doctorId, paymentStatus) => {
        return overridePaymentStatus(consultationId, doctorId, paymentStatus)
            .then(() => {
                return this.onFetchData();
            })
            .then(() => {
                return this.props.refreshParent();
            })
            .catch((err) => {
                console.error({ err },
                    '[overridePaymentStatus] endpoint error');
            });
    };

    onFetchData = () => {
        this.fetchDoctorsConsultations();
    };

    componentDidMount() {
        this.fetchDoctorsConsultations();
    };

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.monthYear !== prevProps.monthYear) {
            this.onFetchData();
        }
    };

    doctorConsultationColumns = [
        {
            accessor: "consultationId",
            filterable: false,
            Header: "Consultation Id",
            style: { 'whiteSpace': 'unset' },
        },
        {
            accessor: 'patientName',
            filterable: false,
            Header: 'Name',
            style: { 'whiteSpace': 'unset' },
        },
        {
            accessor: 'consultationDate',
            filterable: false,
            Header: 'Date',
            style: { 'whiteSpace': 'unset' },
        },
        {
            accessor: 'doctorMessagesCount',
            filterable: false,
            Header: 'Doc. Messages',
            style: { 'whiteSpace': 'unset' },
        },
        {
            accessor: 'userMessagesCount',
            filterable: false,
            Header: 'User Messages',
            style: { 'whiteSpace': 'unset' },
        },
        {
            accessor: 'prescriptions',
            filterable: false,
            Header: 'Prescription/Diet Plan',
            style: { 'whiteSpace': 'unset' },
        },
        {
            accessor: 'callsCount',
            filterable: false,
            Header: 'Call Attempts',
            style: { 'whiteSpace': 'unset' },
        },
        {
            accessor: 'callsDuration',
            filterable: false,
            Header: 'Call Duration',
            style: { 'whiteSpace': 'unset' },
            Cell: row => {
                return (row.original.callsDuration || 0);
            }
        },
        {
            accessor: 'videoCallDuration',
            filterable: false,
            Header: 'Video Call Duration',
            style: { 'whiteSpace': 'unset' },
            Cell: row => {
                return (row.original.videoCallDuration || 0);
            }
        },
        {
            accessor: 'videoCallAttempts',
            filterable: false,
            Header: 'Video Call Counts',
            style: { 'whiteSpace': 'unset' },
            Cell: row => {
                return (row.original.videoCallAttempts || 0);
            }
        },
        {
            accessor: '',
            filterable: false,
            Header: 'Payout Amount',
            style: { 'whiteSpace': 'unset' },
            Cell: row => {
                return (row.original.amountToBePaid || 0);
            },
        },
        {
            accessor: '',
            filterable: false,
            Header: 'Payout Status',
            style: { 'whiteSpace': 'unset' },
            Cell: row => {
                const { consultationId, doctorId, paid } = row.original;
                if (paid) {
                    return (<div className="text-success">Paid {paid}</div>);
                }
                return (<ChangePayoutStatusButton value={row.original.payoutStatus || 0} onChange={(e) => (this.overrideConsultationStatus(consultationId, doctorId, e.target.value))} />);
            },
        },
    ];

    render() {
        const { consultations, loading } = this.state;
        const { doctorId, monthYear } = this.props;
        return (
            <React.Fragment>
                <DownloadCsv jsonArray={consultations} fileName={`${doctorId}-${monthYear}-${new Date().toString()}.csv`} />
                <ReactTable
                    filterable
                    columns={this.doctorConsultationColumns}
                    data={consultations}
                    loading={loading}
                    pageSizeOptions={[100]}
                    defaultPageSize={100}
                    className="-striped -highlight"
                />
            </React.Fragment>
        );
    }
}

export default DoctorConsultationTable;
