import FileSaver from "file-saver"
import moment from "moment"
import jsonToCsv from "../../utils/jsonToCsv"
import { fetchCSVFileRequests, fetchFileRequests } from "./api"

export const customSort = (data, key, desc) => {
    return typeof (data[0] || {})[key] === 'number' ? numericSort(data, key, desc) : alphaSort(data, key, desc)
}

export const numericSort = (data, key, desc) => {

    data.sort((a, b) => {
        return !desc ? a[key] - b[key] : b[key] - a[key]
    })

    return data

}

export const alphaSort = (data, key, desc) => {
    
    console.log({key})
    data.sort((a, b) => {

        if (key === 'raise_reimbursement_request') {
            if (!(a.file_count === 0 || (a.status !="Not Raised" && a.status !="expired") || a.invalid == 1)) {
                return -1
            } else return 1
        }

        return !desc ?
        (a[key] || '').toString().toUpperCase().localeCompare((b[key] || '').toString().toUpperCase()) :
        (b[key] || '').toString().toUpperCase().localeCompare((a[key] || '').toString().toUpperCase())
    })

    return data
}

export const dataFilter = (data, filterArray, cb = () => true) => {

    return data.filter(item => {

        const conditionMap = filterArray.map(filterItem => {

            if (!item.hasOwnProperty(filterItem.id)) {
                return true
            }

            return String(item[filterItem.id]).toLowerCase().includes(filterItem.value.toLowerCase())

        })

        return conditionMap.indexOf(false) === -1 && !!cb(item)

    })
}

const formatCSVData = (data) => data.map(item => ({
    url: item.url,
    orderId: (item.destination || '').split('/')[(item.destination || '').split('/').length - 1],
    createdAt: item.created,
    fileCount: item.file_count,
    utr: item.utr,
    status: item.status,
    amount: item.amount,
    expiry: item.expiry,
    invalid: item.invalid,
    sponsorId: item.sponsorId,
    sponsorName: item.sponsorName,
    reimbursementReason: item.reimbursementReason,
    doublePaymentReason: item.doublePaymentReason,
}))

export const downloadCSV = async (incomingData) => {
    let csv
    if (incomingData) {
        csv = jsonToCsv(formatCSVData(incomingData))
    } else {
        const { file_requests: data } = await fetchCSVFileRequests();
        csv = jsonToCsv(formatCSVData(data))
    }
    
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' })

    FileSaver.saveAs(blob, 'exceptional-reimbursement.csv')
}

const pendingFilterCallback = (item) => {
    const { file_count, status, invalid } = item
    const isDisabled = file_count === 0 || (status !== "Not Raised" && status !== "expired") || invalid === 1
    return file_count !== 0 && !isDisabled
}

const completedFilterCallback = (item) => {
    const { file_count, status, invalid } = item
    const isDisabled = file_count === 0 || (status !== "Not Raised" && status !== "expired") || invalid === 1
    return file_count !== 0 && isDisabled
}

export const decideFilterCallback = (showPending, showCompleted) => {

    if (!showPending && !showCompleted) return () => true
    if (showPending) return pendingFilterCallback
    else return completedFilterCallback

}

export const creationFilterCallback = (item, creationDate) => {
    let { startDate, endDate } = creationDate
    startDate = startDate.format('DD-MMM-YYYY')
    endDate = endDate.format('DD-MMM-YYYY')
    const created = moment(item.created).format('DD-MMM-YYYY')
    console.log({startDate, endDate, created })
    return moment(startDate) <= moment(created) && moment(endDate) >= moment(created)
}