import React, { Component, useState, useEffect } from 'react';
import { withRouter} from 'react-router-dom';
import moment from 'moment';
import ReactTable from 'react-table';
import { FormGroup, Label, Tooltip, Badge, Button,ButtonGroup } from 'reactstrap';
import { toast } from 'react-toastify';
import Link from 'valuelink';
 import {Link as RouterLink } from 'react-router-dom';
 import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import { Input } from 'valuelink/lib/tags';
import DownloadCsv from '../common/DownloadCsv';
import { Text } from '../common/Text';
import { debounce } from '../../utils/debounce';
import ConfirmModal from '../offline-consultations/ConfirmModal';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import {
  getClaimsRequests,
  placeCall,
  reopenClaim,
  processClaimRequest,
} from '../../services/api/reimburse-claims';
import {
  IoIosMailOpen,
  IoMdPerson,
  IoMdClipboard,
  IoMdContacts,
  IoIosCall,
} from 'react-icons/io';
import HistoryContainer from './HistoryContainer';
import SponsorFilter from '../offline-consultations/headerFilter/SponsorFilter';
import { FaTicketAlt } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa';
import ReimbursementClaimsRequestsTableMore from './ReimbursementClaimsRequestsTableMore';
import { getRequestTicketsLink } from '../../services/api/tickets';

function ReimbursementType(props) {
  const { onChange, filter } = props;
  const [value, setValue] = useState(filter ? filter.value || '' : '');
  console.log('calling');
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onChange(value);
  }, [value]);
  /* eslint-enable */
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div>
      <select
        onChange={handleChange}
        style={{
          width: '100%',
          height: '40px',
          fontSize: '12px',
        }}
        value={value}
      >
        <option value="">All</option>
        <option value="normal-spectacles">Normal Spectacles</option>
        <option value="bifocal-spectacles">Bifocal Spectacles</option>
        <option value="normal-lens">Normal Contact Lens</option>
        <option value="bifocal-lens">Bifocal Contact Lens</option>
        <option value="ivf">Infertility Treatment</option>
        <option value="tooth-ext">Simple Tooth Extraction</option>
        <option value="prebooked-consult">Prebook Consult</option>
        <option value="prebooked-medicine">
          Prebooked Prescribed Medicines
        </option>
        <option value="prebooked-lab">Prebooked Prescribed Diagnostics</option>
        <option value="fracture">Surgical Cast</option>
        <option value="vaccine">Vaccinations</option>
        <option value="emergency-consult">
          Urgent Consults, Medicines or Labs
        </option>
        <option value="surgical-tooth-ext">Surgical extraction of tooth</option>
        <option value="surgical-molar-ext">
          Surgical extraction of molar tooth
        </option>
        <option value="tooth-filling">Tooth Filling</option>
        <option value="tooth-scaling">Tooth Scaling</option>
        <option value="tooth-orthodontal">Orthodontal</option>
        <option value="denture-single">Simple Single Tooth Denture</option>
        <option value="denture-complex">
          Complex single/two teeth denture
        </option>
        <option value="denture-full">Full upper/lower teeth denture</option>
        <option value="denture-set">Full set of dentures</option>
        <option value="denture-capping">Tooth Capping</option>
        <option value="root-canal">Root canal treatment</option>
        <option value="dental-proflaxes">Dental Proflaxes</option>
      </select>
    </div>
  );
}

class ReimbursementClaimsRequestsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      pages: -1,
      lastWorkedDateFilter: null,
      invoiceDateFilter: null,
      filtered: [
        {
          id: 'requestStatus',
          value: 'documents-uploaded',
        },
      ],
      sorted: [
        {
          id: 'reimbursementId',
          desc: true,
        },
      ],
      pageSize: 20,
      page: 0,
      orders: [],
      confirmModal: {
        isOpen: false,
        header: '',
        body: '',
        onSubmit: null,
        requestId: null,
        onClose: null,
        buttonLoading: false,
      },
      searchInput: '',
      processClaimTooltipOpen: -1,
      requestCallTooltipOpen: -1,
      requestReopenTooltipOpen: -1,
      viewClaimTooltipOpen: -1,
      claimRequestTooltipOpen: -1,
      totalRecords: null,
      deficientTab : false,
      totalApprovedAmount: null,
      totalRequestedAmount: null,
      startDate: null,
      endDate: null,
      focusedInput: null,
      pendingUtr:''
    };
    this.filtering = true;

    this.fetchData = this.fetchData.bind(this);
    this.tableRef = React.createRef();
    this.fetchDataWithDebounce = debounce(this.fetchData, 300);
    this.fetchSearchInputWithDebounce = debounce((ele) => {
      if (this.tableRef) {
        console.log(this.tableRef);
        this.fetchData(this.tableRef.current.state, null, ele);
      }
    }, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.startDate === null || this.props.endDate === null) {
      return;
    }
    if (
      prevProps.startDate !== this.props.startDate ||
      prevProps.endDate !== this.props.endDate
    ) {
      let stDate = 0,
        enDate = 0;
      if (this.props.startDate !== null) {
        stDate = this.props.startDate.startOf('day').unix();
      }
      if (this.props.endDate !== null) {
        enDate = this.props.endDate.endOf('day').unix();
      }

      this.updateStateFilters('reqStartDate', stDate, 'reqEndDate', enDate);
    }
  }

  updateStateFilters = (id, value, id2, value2) => {
    console.log('Update all filters in state', { id, value, id2, value2 });
    // console.log('New filter values', moment(value).format('DD-MM-YYYY'), moment(value2).format('DD-MM-YYYY'));
    const filter = this.state.filtered.find((f) => f.id === id);
    const filter2 = this.state.filtered.find((f) => f.id === id2);
    if (filter) {
      filter.value = value;
    } else {
      this.state.filtered.push({ id, value });
    }
    if (filter2) {
      filter2.value = value2;
    } else {
      this.state.filtered.push({ id: id2, value: value2 });
    }

    this.setState({
      filtered: JSON.parse(JSON.stringify(this.state.filtered)),
    });
  };

  onFilteredChange = (e) => {
    this.setState({ filtered: e, page: 0, pages: 0 });
  };

  fetchStrategy = (state) => this.fetchDataWithDebounce(state);

  prepareSorting = () => {
    const sortBy = [];
    const sortOrder = [];
    this.state.sorted.forEach((ele) => {
      sortBy.push(ele.id);
      if (ele.desc) {
        sortOrder.push('DESC');
      } else {
        sortOrder.push('ASC');
      }
    });
    return {
      sortBy,
      sortOrder,
    };
  };

  prepareApiFilters = () => {
    const appliedFilters = {};
    // let appliedOrderBy = {};
    const {startDate,endDate} = this.state;
    // Convert this list of filterables into object properties
    const reimbursementIdFilter = this.state.filtered.find((ele) => {
      return ele.id === 'reimbursementId';
    });
    if (reimbursementIdFilter) {
      // If searching on reimbursment ID, only add that filter
      appliedFilters.reimbursementId = reimbursementIdFilter.value;
      return appliedFilters;
    }
    if (this.state) {
      this.state.filtered.forEach((filter) => {
        if (filter.id === 'requestStatus') {
          appliedFilters.status = filter.value;
        } else {
          appliedFilters[filter.id] = filter.value;
        }
      });
    }
    appliedFilters.pendingUtr = this.state.pendingUtr;
    appliedFilters.deficientTab = this.state.deficientTab;
    appliedFilters.startDate = startDate && moment(startDate).unix();
    appliedFilters.endDate = endDate && moment(endDate).unix()
    // state.sorted.forEach(sort => appliedOrderBy[sort.id] = sort.desc?'desc':'asc');

    appliedFilters.size = this.state.pageSize;
    appliedFilters.page = this.state.page + 1;
    return appliedFilters;
  };

  fetchData = (state, instance, searchInput) => {
    const { pageSize } = this.state;
    // show the loading overlay
    this.setState({ loading: true });
    this.filtering = false;
    const appliedFilters = this.prepareApiFilters(state);
    const appliedSorting = this.prepareSorting(state);

    // fetch your data
    getClaimsRequests(
      appliedFilters,
      appliedSorting,
      searchInput || this.state.searchInput
    )
      .then((response) => {
        console.log('Api response', response.body);
        if (response.body.message === 'recordsFound') {
          const totalRecords = response.body.totalRecords;
          const totalApprovedAmount = response.body.totalApprovedAmount;
          const totalRequestedAmount = response.body.totalRequestedAmount;
          // Compute pages
          this.setState({
            orders: response.body.results,
            loading: false,
            totalRecords,
            totalApprovedAmount,
            totalRequestedAmount,
            pages: Math.ceil(totalRecords / pageSize),
          });
        } else if (response.body.message === 'noRecords') {
          this.setState({
            totalRecords: 0,
            orders: [],
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.error(err, 'Error in get claims requests API');
        toast.error(`Error in fetching orders`);
      });
  };

  getColumns = () => [
    {
      Header: 'Order Details',
      columns: [
        {
          Header: 'Reimbursement ID',
          accessor: 'reimbursementId',
          filterable: true,
          width: 50,
          Filter: ReactTableInputFilter,
          Cell: (row) => {
            const { reimbursementId } = row.original;
            return (
              <RouterLink
                title={reimbursementId}
                to={`/reimburse-claim/${reimbursementId}`}
              >
                {reimbursementId}
              </RouterLink>
            );
          },
        },
        {
          Header: 'Claimed By',
          accessor: 'claimBy',
          filterable: true,
          Filter: ReactTableInputFilter,
        },
        {
          Header: 'Sponsor Name',
          accessor: 'sponsorName',
          filterable: true,
          Filter: ({ filter, onChange }) => (
            <SponsorFilter
              onChangeValue={onChange}
              value={filter ? filter.value : ''}
            />
          ),
        },
        {
          Header: 'User Name',
          accessor: 'userName',
        },
        {
          Header: 'User Phone',
          accessor: 'userPhone',
          width: 75,
          style: {
            wordBreak: 'break-all',
          },
        },
        {
          Header: 'User Email',
          accessor: 'userEmail',
          width: 100,
          style: {
            wordBreak: 'break-all',
          },
        },
        {
          Header: 'Patient Name',
          // width: 120,
          sortable: false,
          Cell: (row) => {
            return (
              <span>{row.original.relative || row.original.userName}</span>
            );
          },
        },
        {
          Header: 'Reimbursement Type',
          accessor: 'reimbursementType',
          // width: 180,
          filterable: true,
          Filter: ReimbursementType,
        },
        {
          Header: 'Status',
          accessor: 'requestStatus',
          filterable: true,
          // width: 100,
          Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                // style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">Show All</option>
                <option value="documents-uploaded">Documents Uploaded</option>
                <option value="qc-check">Qc Check</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="documents-deficient">Documents Deficient</option>
                <option value="documents-verified">Documents Verified</option>
                <option value="clarification-needed">
                  Clarification Needed
                </option>
                <option value="clarification-resolved">
                  Clarification Resolved
                </option>
                <option value="On-hold">
                  On Hold
                </option>
              </select>
            );
          },
          Cell: (row) => {
            if (row.original.deletionDate) {
              return (
                <h5>
                  <span className="badge badge-danger">DELETED</span>
                </h5>
              );
            }
            switch (row.original.requestStatus.toUpperCase()) {
              case 'DOCUMENTS-UPLOADED':
                return (
                  <h5>
                    <span className="badge badge-secondary">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                    {row.original.subStatus ? (
                      <span className="badge badge-secondary">
                        {row.original.subStatus.toUpperCase()}
                      </span>
                    ) : null}
                  </h5>
                );
              case 'DOCUMENTS-VERIFIED':
                return (
                  <h5>
                    <span className="badge badge-primary">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                    {row.original.subStatus ? (
                      <span className="badge badge-secondary">
                        {row.original.subStatus.toUpperCase()}
                      </span>
                    ) : null}
                  </h5>
                );
              case 'APPROVED':
                return (
                  <h5>
                    <span className="badge badge-success">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                    {row.original.subStatus ? (
                      <span className="badge badge-secondary">
                        {row.original.subStatus.toUpperCase()}
                      </span>
                    ) : null}
                  </h5>
                );
              case 'REJECTED':
                return (
                  <h5>
                    <span className="badge badge-danger">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                    {row.original.subStatus ? (
                      <span className="badge badge-secondary">
                        {row.original.subStatus.toUpperCase()}
                      </span>
                    ) : null}
                  </h5>
                );
              default:
                return (
                  <h5>
                    <span className="badge badge-secondary">
                      {row.original.requestStatus.toUpperCase()}
                    </span>
                    {row.original.subStatus ? (
                      <span className="badge badge-secondary">
                        {row.original.subStatus.toUpperCase()}
                      </span>
                    ) : null}
                  </h5>
                );
            }
          },
        },
        {
          Header: 'Insurer Status',
          accessor: 'claimStatus',
          filterable: true,
          // width: 100,
            Filter: ({ filter, onChange }) => {
            return (
              <select
                onChange={(event) => onChange(event.target.value)}
                // style={{ width: '100%' }}
                value={filter ? filter.value : 'all'}
              >
                <option value="all">Show All</option>
                <option value="documents-uploaded">Documents Uploaded</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
                <option value="settled">Settled</option>
                <option value="pending">Pending</option>
              </select>
            );
          },
          Cell: (row) => {
            switch (row.original.claimStatus.toUpperCase()) {
              case 'DOCUMENTS-UPLOADED':
                return (
                  <h5>
                    <span className="badge badge-secondary p-0 pb-2">
                      <div className="bg-dark rounded-top mb-1">
                        <Text fontSize="10px" color="white">
                          Insurer
                        </Text>
                      </div>
                      <span className="ml-2 mr-2">
                        {row.original.claimStatus.toUpperCase()}
                      </span>
                    </span>
                  </h5>
                );
              case 'APPROVED':
              case 'SETTLED':
                return (
                  <h5>
                    <span className="badge badge-success p-0 pb-2">
                      <div className="bg-dark rounded-top mb-1">
                        <Text fontSize="10px" color="white">
                          Insurer
                        </Text>
                      </div>
                      <span className="ml-2 mr-2">
                        {row.original.claimStatus.toUpperCase()}
                      </span>
                    </span>
                  </h5>
                );
              case 'REJECTED':
              case 'FAILURE':
              case 'REVERSED':
                return (
                  <h5>
                    <span className="badge badge-danger p-0 pb-2">
                      <div className="bg-dark rounded-top mb-1">
                        <Text fontSize="10px" color="white">
                          Insurer
                        </Text>
                      </div>
                      <span className="ml-2 mr-2">
                        {row.original.claimStatus.toUpperCase()}
                      </span>
                    </span>
                  </h5>
                );
              case 'PENDING':
                return (
                  <h5>
                    <span className="badge badge-danger p-0 pb-2">
                      <div className="bg-dark rounded-top mb-1">
                        <Text fontSize="10px" color="white">
                          Insurer
                        </Text>
                      </div>
                      <span className="ml-2 mr-2">
                        {row.original.claimStatus.toUpperCase()}
                      </span>
                    </span>
                  </h5>
                )
              default:
                return (
                  <h5>
                    <span className="badge badge-secondary p-0 pb-2">
                      <div className="bg-dark rounded-top mb-1">
                        <Text fontSize="10px" color="white">
                          Insurer
                        </Text>
                      </div>
                      <span className="ml-2 mr-2">
                        {row.original.claimStatus.toUpperCase()}
                      </span>
                    </span>
                  </h5>
                );
            }
          },
        },
        // {
        //   Header: 'Invoice Date',
        //   accessor: 'invoiceDate',
        //   // width: 100,
        //   Cell: (row) => (
        //     <span>
        //       {row.original.invoiceDate
        //         ? moment(row.original.invoiceDate).format('Do MMM')
        //         : null}
        //     </span>
        //   ),
        //   filterable: true,
        //   Filter: ({ filter, onChange }) => {
        //     return (
        //       <input
        //         type="datetime-local"
        //         onChange={(event) => {
        //           onChange(event.target.value);
        //           this.setState({ invoiceDateFilter: event.target.value });
        //         }}
        //         value={this.state.invoiceDateFilter}
        //         style={{ width: 100 }}
        //       ></input>
        //     );
        //   },
        // },
        {
          Header: 'Claim Amount',
          accessor: 'reimbursementAmount',
          // width: 100,
          Cell: (row) => (
            <span>{`₹ ${row.original.reimbursementAmount || 'NA'}`}</span>
          ),
        },
        {
          Header: 'Approved Amount',
          accessor: 'approvedAmount',
          // width: 100,
          Cell: (row) => (
            <span>{`₹ ${row.original.approvedAmount || 'NA'}`}</span>
          ),
        },
        // {
        //   Header: 'Invoices',
        //   // width: 100,
        //   sortable: false,
        //   Cell: (row) =>
        //     row.original.invoiceLink &&
        //     row.original.invoiceLink.split(',').map((link, idx) => (
        //       <div>
        //         <a
        //           key={idx}
        //           rel="noopener noreferrer"
        //           target="_blank"
        //           href={link}
        //         >{`Invoice ${idx + 1}`}</a>
        //       </div>
        //     )),
        // },
        // {
        //   Header: 'Prescription/Proofs',
        //   // width: 100,
        //   sortable: false,
        //   Cell: (row) =>
        //     row.original.prescriptionLink &&
        //     row.original.prescriptionLink.split(',').map((link, idx) => (
        //       <div>
        //         <a
        //           key={idx}
        //           rel="noopener noreferrer"
        //           target="_blank"
        //           href={link}
        //         >{`Prescription ${idx + 1}`}</a>
        //       </div>
        //     )),
        // },
        {
          Header: 'Request Date',
          accessor: 'requestDate',
          Cell: (row) => (
            <span>
              {row.original.requestDate
                ? moment(row.original.requestDate).format('DD/MM/YYYY HH:mm')
                : null}
            </span>
          ),
        },
        // {
        //   Header: 'Payment Settlement Date',
        //   accessor: 'paymentSettlementDate',
        //   Cell: (row) => (
        //     <span>
        //       {moment(row.original.paymentSettlementDate).format(
        //         'DD/MM/YYYY HH:mm'
        //       )}
        //     </span>
        //   ),
        // },
        // {
        //   Header: 'Last worked date',
        //   accessor: 'lastWorkedDate',
        //   filterable: true,
        //   Filter: ({ filter, onChange }) => {
        //     return (
        //       <input
        //         type="datetime-local"
        //         onChange={(event) => {
        //           onChange(event.target.value);
        //           this.setState({ lastWorkedDateFilter: event.target.value });
        //         }}
        //         value={this.state.lastWorkedDateFilter}
        //         style={{ width: 100 }}
        //       ></input>
        //     );
        //   },
        //   Cell: (row) => (
        //     <span>
        //       {moment(row.original.lastWorkedDate).format('DD/MM/YYYY HH:mm')}
        //     </span>
        //   ),
        // },
        {
          Header:'QC Allotted on',
          accessor:'qcAllotedTime',
          Cell: (row) => (
            <span>
              {row.original.qcAllotedTime
                ? moment(row.original.qcAllotedTime).format('DD/MM/YYYY HH:mm')
                : null}
            </span>
          ),
        },
        {
          Header:'QC claimed by',
          accessor:'qcDoc',
        },
        {
          Header:'Clarification requested date',
          accessor:'clarificationReqOn',
        },
        {
          Header: 'Actions',
          sortable: false,
          // width: 50,
          Cell: (row) => {
            // console.log(row,'[row]')
            return (
              <>
              {row.original.qcCheckValue ? '' : 
              <div>
                {row.original.requestStatus === 'documents-uploaded' ||
                row.original.requestStatus === 'documents-deficient' ? (
                  <Button
                    variant="outline"
                    color="primary"
                    style={{ marginBottom: 5 }}
                    onClick={this.processClaim(row.original)}
                  >
                    Process Claim
                  </Button>
                ) : null}
                {(row.original.claimStatus === 'approved' ||
                  row.original.claimStatus === 'settled') &&
                row.original.claimStatus !== 'reopened' ? (
                  <Button
                    variant="outline"
                    color="primary"
                    style={{ marginBottom: 5 }}
                    onClick={this.requestReopen(row.original)}
                  >
                    Reopen Claim
                  </Button>
                ) : null}
                {row.original.requestStatus === 'rejected' ||
                row.original.requestStatus === 'documents-verified' ? (
                  <Button
                    variant="outline"
                    color="primary"
                    style={{ marginBottom: 5 }}
                    onClick={this.processClaim(row.original)}
                  >
                    View Claim
                  </Button>
                ) : null}
                <Button
                  variant="outline"
                  style={{ marginBottom: 5 }}
                  color="primary"
                  onClick={() => this.claimRequest(row.original)}
                >
                  {!row.original.claimBy ? 'Claim Request' : 'Re-claim Request'}
                </Button>
                {row.original.requestStatus === 'documents-uploaded' ? (
                  <Button
                    color="primary"
                    outline
                    onClick={this.requestCall(row.original)}
                  >
                    <FaPhone />
                  </Button>
                ) : null}
                <a
                  href={getRequestTicketsLink(row.original.requestId)}
                  target="_blank"
                >
                  <FaTicketAlt className="icon-style" />
                </a>
              </div>
            }
            </>
            );
          },
        },
      ],
    },
  ];

  claimRequest = (reimburseRequest) => {
    processClaimRequest(reimburseRequest.reimbursementId)
      .then((response) => {
        console.log('Api response', response);
        if (response.message === 'success') {
          this.fetchData();
          toast.success(`Request is claimed successfully.`);
        } else {
          toast.error(`Error in claim request`);
        }
      })
      .catch((err) => {
        console.error(err, 'Error in get claims requests API');
        toast.error(`Error in claim request`);
      });
  };
  processClaim = (reimburseRequest) => () => {
    console.log(reimburseRequest, 'opening claim page');
    const anchorElement = document.createElement('a');
    anchorElement.setAttribute(
      'href',
      `/reimburse-claim/${reimburseRequest.reimbursementId}`
    );
    anchorElement.setAttribute('target', '_blank');
    anchorElement.click();
    // const { history } = this.props;
    // history.push(`/reimburse-claim/${reimburseRequest.reimbursementId}`, {
    //   reimburseRequest,
    // });
  };

  reopenClaim = (reimbursementId) => () => {
    const { confirmModal } = this.state;
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    const reason = document.querySelector('#reopenReason').value;
    if (!reason) {
      toast.error('Please specify a valid reason to reopen this claim');
      this.setState({
        confirmModal: {
          isOpen: false,
        },
      });
      return;
    }
    return reopenClaim(reimbursementId, reason)
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Claim reopened!');
          this.processClaim({ reimbursementId })();
          this.fetchSearchInputWithDebounce(null);
        } else {
          toast.error(res.errorMessage);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error reopening claim');
      })
      .finally(() => {
        this.setState({
          confirmModal: {
            isOpen: false,
          },
        });
      });
  };

  requestReopen = (reimburseRequest) => () => {
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Reopen claim ${reimburseRequest.reimbursementId}`,
        body: (
          <div className="container">
            <div className="col-12">
              <FormGroup check>
                <Label for="deleteReason">
                  Specify a reason to reopen this claim
                </Label>
                <input type="text" name="reopenReason" id="reopenReason" />
              </FormGroup>
            </div>
          </div>
        ),
        onSubmit: this.reopenClaim(reimburseRequest.reimbursementId),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  requestCall = (reimburseRequest) => () => {
    this.setState({
      confirmModal: {
        isOpen: true,
        header: `Call ${reimburseRequest.userName}`,
        body: `Are you sure you want to place call to ${
          reimburseRequest.userName
        } regarding claim made for ${
          reimburseRequest.relative || reimburseRequest.userName
        } ${
          reimburseRequest.relative ? '(' + reimburseRequest.relation + ')' : ''
        }`,
        onSubmit: this.placeCall(reimburseRequest.reimbursementId),
        requestId: reimburseRequest.reimbursementId,
        onClose: () => {
          this.setState({ confirmModal: { isOpen: false } });
        },
        buttonLoading: false,
      },
    });
  };

  onDatesChanged = ({ startDate, endDate }) => {
    this.setState({ startDate,
           endDate },
           ()=>{
            if(this.state.startDate && this.state.endDate){
              this.fetchData();
            }

           });
   
  };
  placeCall = (reimbursementId) => () => {
    const { confirmModal } = this.state;
    this.setState({
      confirmModal: Object.assign({}, confirmModal, {
        buttonLoading: true,
      }),
    });
    return placeCall(reimbursementId)
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Call placed successfully!');
        } else {
          toast.error('Error placing call');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Error placing call');
      })
      .finally(() => {
        this.setState({
          confirmModal: {
            isOpen: false,
          },
        });
      });
  };

  render() {
    const { confirmModal,startDate,endDate,focusedInput } = this.state;
    const searchInputLink = Link.state(this, 'searchInput').onChange((ele) => {
      this.fetchSearchInputWithDebounce(ele);
    });
    return (
      <>
        <div className="container-fluid">
          <div className="row">
            <div className="col-6">
              <FormGroup>
                <Label for="searchInput">
                  Search by user name/patient name/mobile number
                </Label>
                <Input
                  type="text"
                  name="searchInput"
                  id="searchInput"
                  valueLink={searchInputLink}
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {this.state.totalRecords ? (
            <div style={{ flex: 1, margin: 10 }}>
              <Badge color="primary" style={{ padding: 10 }}>
                Total Count: {this.state.totalRecords}
              </Badge>
            </div>
          ) : null}
          {this.state.totalRequestedAmount ? (
            <div style={{ flex: 1, margin: 10 }}>
              <Badge color="primary" style={{ padding: 10 }}>
                Total Requested Amount: {this.state.totalRequestedAmount}
              </Badge>
            </div>
          ) : null}
          {this.state.totalApprovedAmount ? (
            <div style={{ margin: 10 }}>
              <Badge color="primary" style={{ padding: 10 }}>
                Total Approved Amount: {this.state.totalApprovedAmount}
              </Badge>
            </div>
          ) : null}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: "center", justifyContent: 'center', marginBottom: '1px' }}>
            <div>
              <div color="primary" style={{ padding: 10, border: 'solid 1px', marginRight: '5px', borderRadius : '8px', backgroundColor: this.state.deficientTab ? 'white' : '#007bff', color: this.state.deficientTab ? 'black' : 'white', cursor : 'pointer'}} onClick = {() => {
                this.setState({
                  deficientTab : false,
                }, () => {
                  this.fetchData();
                });
              }} >
                All Reimbursements
              </div>
            </div>
            <div>
              <div color="primary" style={{ padding: 10, border: 'solid 1px', borderRadius : '8px', backgroundColor: !this.state.deficientTab ? 'white' : '#007bff', color: !this.state.deficientTab ? 'black' : 'white', cursor : 'pointer' }} onClick ={() => {
                this.setState({
                  deficientTab : true,
                }, () => {
                  this.fetchData();
                });
              }}>
              Deficient updated reimbursement 
              </div>
            </div>
            <div className="col">
    <DateRangePicker
              displayFormat={() => 'DD/MM/YYYY'}
              startDateId="startDate"
              endDateId="endDate"
              startDate={startDate}
              endDate={endDate}
              isOutsideRange={() => false}
              minimumNights={0}
            onDatesChange={this.onDatesChanged}
             focusedInput={focusedInput}
             onFocusChange={(focusedInput) => this.setState({ focusedInput })}
            />

    </div>
    {/* {this.state.orders.requestDate < moment(this.state.orders.requestDate).add(10,'days')} */}
    <div className='col'>
       <Button color='primary' onClick={()=>{
        this.setState({
          pendingUtr:'desc',
        filtered: [{
          id: 'status',
          value: 'approved'
        }, {
          id: 'claimStatus',
          value: 'pending'
        }]
       })
        this.fetchData()}}>Pending Utr Data</Button>
      <div>
        <Button onClick={()=>{this.setState({
          pendingUtr:'',
          filtered: [{
            id: 'requestStatus',
            value: 'documents-uploaded',
          }]
        })}}>Clear Filter</Button>
      </div>
    </div>
    <div className='col'>
      <ButtonGroup size={'sm'}>
          <DownloadCsv jsonArray={this.state.orders} />
      </ButtonGroup>
    </div>
        </div>
        <br>
    </br>
    <br>
    </br>
        <ReactTable
          keyField="orderId"
          ref={this.tableRef}
          data={this.state.orders}
          columns={this.getColumns()}
          defaultPageSize={20}
          pages={this.state.pages}
          pageSize={this.state.pageSize}
          page={this.state.page}
          loading={this.state.loading}
          filtered={this.state.filtered}
          sorted={this.state.sorted}
          manual
          onFetchData={this.fetchStrategy}
          onFilteredChange={this.onFilteredChange}
          onPageSizeChange={(pageSize) => this.setState({ pageSize })}
          onPageChange={(page) => this.setState({ page })}
          onSortedChange={(sorted) => this.setState({ sorted })}
          className="-striped -highlight"
          showPaginationTop
          showPaginationBottom
          SubComponent={(row) => (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <HistoryContainer requestId={row.original.reimbursementId} />
              <ReimbursementClaimsRequestsTableMore
                details={[row.original]}
                requestId={row.original.reimbursementId}
              />
            </div>
          )}
        />
        <ConfirmModal
          modalHeader={confirmModal.header}
          onClose={confirmModal.onClose}
          isOpen={confirmModal.isOpen}
          onSubmit={confirmModal.onSubmit}
          loading={confirmModal.buttonLoading}
        >
          {confirmModal.body}
        </ConfirmModal>
      </>
    );
  }
}

export default withRouter(ReimbursementClaimsRequestsTable);
