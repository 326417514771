import { Select } from 'antd';
import React, { Component } from 'react'
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, Button, ModalFooter, FormGroup, Label } from 'reactstrap';
import { addRemarkForDoc } from '../../services/api/reimburse-claims';

class RemarksConfirmModal extends Component {
    constructor(props){
        super(props)
        console.log(props,'[qxremark]')
        this.state={
            remark:'',
            selectedReason:''
        }
    }

    // getRemark(){
      
      // console.log([reimbursementId])
      // getRemarkForDoc(reimburseRequest.reimbursementId)
      // .then((response)=>{
      //   console.log(response.data,'[rmarkResponse]')
      //   if(response){
      //     this.setState({
      //       remarkData:response.data
      //     })
      //   }
      // }).catch((err)=>{
      //   console.log(err,'[remarkErrr]')
      //   toast.error('Error fetching claim Remarks');
      // })
    // }

    addRemark = () => {
      const opsUserId = this.props.opsUserId
      const {remark,selectedReason} = this.state
      const reimburseRequest = this.props.reimbursementDetails
      console.log(opsUserId,remark,reimburseRequest.userId,selectedReason,'[opsuserid]')
      if(opsUserId,reimburseRequest.userId,remark,selectedReason){
        addRemarkForDoc(opsUserId,remark,reimburseRequest.userId,reimburseRequest.reimbursementId,selectedReason)
          .then((response)=>{
            console.log({response},'[responsesss]')
            if(response.message ==='success'){
              toast.success('Remark Added Successfully')
              this.props.onSubmit()
              this.props.onClose()
            }
          }).catch((err)=>{
            console.log({err},'[err]')
            toast.error('Remarks Not added');
          })
      }
    }

    handleChange=(e)=>{
      console.log(e,'[eee]')
      this.setState({
        selectedReason:e
      })
    }
    render() {
      const className = 'bg-info';
      return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
        <ModalHeader className={className}>
          Remark For Initial Doctor for claim no-{this.props.reimbursementDetails.reimbursementId}
        </ModalHeader>
        <ModalBody>
          <div className="container">
            <div className="col">
              <FormGroup check>
                <Label className="col-12 mr-2">Select Reason:</Label>

                <Select className='col-12'
                  onChange={this.handleChange}
                  placeholder="Select Remark"
                >
                  <Select.Option value='NA'>NA</Select.Option>
                  <Select.Option value="digitisation-prescription-not-submitted">
                    Prescription not submitted for digitisation
                  </Select.Option>
                  <Select.Option value="Incorrect-remark-document-deficient">
                    Incorrect remark of Document deficient selected
                  </Select.Option>
                  <Select.Option value="claims-not-covered">
                    BHMS/BAMS/BPT/IPD claims are not covered
                  </Select.Option>
                  <Select.Option value="checkup-not-covered">
                    Cosmetic/Dental/Vision/Physiotherapy are not covered
                  </Select.Option>
                  <Select.Option value="scan-not-covered">
                    CT scan, MRI, PET Scan, Biopsy, Bone Densitometry are not
                    covered
                  </Select.Option>
                  <Select.Option value="wrong-bill">
                    Wrong bill number updated
                  </Select.Option>
                  <Select.Option value="incorrect-merchant">
                    Incorrect merchant name updated
                  </Select.Option>
                  <Select.Option value="wrong-date">
                    Date selected is wrong
                  </Select.Option>
                  <Select.Option value="partial-transcription">
                    Partial transcription is done
                  </Select.Option>
                  <Select.Option value="internal-comment-history">
                    No update in internal comment history
                  </Select.Option>
                  <Select.Option value="incorrect-rejection">
                    Incorrect rejection reason
                  </Select.Option>
                  <Select.Option value="medicine-vaccines-together">
                    Medicines and vaccines approved together
                  </Select.Option>
                  <Select.Option value="reimbursement-type-wrong">
                    Reimbursement type selected wrong
                  </Select.Option>
                  <Select.Option value="procedure-not-covered">
                    Procedures are not covered
                  </Select.Option>
                  <Select.Option value="incorrect-amount">
                    Incorrect amount is approved
                  </Select.Option>
                  <Select.Option value="invoice-date-prescription">
                    Invoice date before prescription date is not valid
                  </Select.Option>
                  <Select.Option value="apr-with-proper-document">
                    Approved without proper documents
                  </Select.Option>
                  <Select.Option value="critical-illness">
                    Claim had to be submitted under Critical illness
                  </Select.Option>
                  <Select.Option value="diagnosis-chief-complaint">
                    Approved without any diagnosis/chief complaints
                  </Select.Option>
                  <Select.Option value="one-invoice-multiple">
                    Only one invoice approved out of multiple
                  </Select.Option>
                  <Select.Option value="cap-charge-approved">
                    Capping items/charges are approved
                  </Select.Option>
                  <Select.Option value="invalid-prescription-invoice">
                    Invalid prescription/invoice is approved
                  </Select.Option>
                  <Select.Option value= 'claim-rejected-already-submitted-another-rid'>
                  Claim is to be rejected as already submitted in another RID
                  </Select.Option>
                <Select.Option value='not-a-ci-case'>
                Not a CI case
                </Select.Option>
                <Select.Option value='unnecessarily-clicked-on-ci-labs'>
                Unnecessarily clicked on CI (for labs/consultation claim)
                </Select.Option>
                </Select>
                {/* </select> */}
              </FormGroup>
              <FormGroup check>
                <Label>Remark Comments:</Label>
                <textarea
                  onChange={(e) => this.setState({ remark: e.target.value })}
                  placeholder="Add remark..."
                  className="form-control"
                />
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.addRemark}>
            {this.props.loading ? 'Loading...' : 'Confirm'}
          </Button>
          {!this.props.loading && (
            <Button
              color="secondary"
              onClick={this.props.onClose}
              disabled={this.props.loading}
            >
              Cancel
            </Button>
          )}
        </ModalFooter>
      </Modal>
    );
  }
}


export default RemarksConfirmModal