import baseRequest from './baseRequest';

export const makeNewCrentenial = (body) => {
  const url = '/new-ops/credentials/create';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const getOpsUsers = () => {
  const url = '/new-ops/credentials/fetch-credentials';
  const method = 'GET';
  return baseRequest(url, method, null);
};

export const updateCredentials = (body) => {
  const url = '/new-ops/credentials/update-credentials';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const getAllOpsCredApprovals = (body) => {
  const url = '/new-ops/credentials/fetch-approvals';
  const method = 'GET';
  return baseRequest(url, method, null, body);
};

export const approveCredentials = (body) => {
  console.log(body, 'body');
  const url = '/new-ops/credentials/approve-request';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};

export const reasonCredentials = (body) => {
  console.log(body, 'body');
  const url = '/new-ops/credentials/decline-request';
  const method = 'POST';
  return baseRequest(url, method, null, body);
};
