import { Button, Input } from 'reactstrap';
import styled from 'styled-components';
import { MdEdit } from 'react-icons/md';

export const OuterComponent = styled.div`
  /* background-color: rgba(136, 82, 204, 0.4); */
  display: flex;
  flex-direction: column;
  flex: 1;
`;
export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

export const PaddedContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  /* flex: 1; */
`;

export const BodyContainer = styled.div`
  padding: 1rem;
  margin-top: 1rem;
`;
export const HeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
`;

export const PoolContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  /* flex: 1; */
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: row;
  /* flex: 1; */
`;

export const Container = styled.div`
  display: flex;
`;

export const ContainerItem = styled.div`
  flex-grow: 1;
  margin: 7px;
`;
