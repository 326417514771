import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import PlaceRequest from "./PlaceRequest";
import { placeAppointmentRequest } from "../../services/api/intimation-request";
import { toast } from 'react-toastify';
import LabRequest from "./LabsRequest";
class IntimationModalConfirm extends Component {
  constructor(props) {
    super(props);

    this.state = { slotvalue: null, value: null, amount: 0 };
  }

  handleIntimationPlaceRequestSubmit = (
    doctorId,
    centerId,
    dateKey,
    slotId
  ) => {
    return placeAppointmentRequest(
      {doctorId,
      centerId,
      dateKey,
      slotId,
      intimationId : this.props.requestId}
    ).then((response) => {
      if (response.message === 'success') {
        toast.success('Request Placed Success');
        this.props.onComplete()
        this.props.closeModal()
      } else {
        toast.error(response.errorMessage);
        return false;
      }
    });
  };

  handleLabsPlaceRequestSubmit = (
    pathology_partner_id,
        pathology_test_id,
        pathology_date,
        pathology_slot,
        radiology_partner_id,
        radiology_test_id,
        radiology_date,
        radiology_slot,
        radiology_center_id
  ) => {
    return placeAppointmentRequest(
      {pathology_partner_id,
      pathology_test_id,
      pathology_date,
      pathology_slot,
      radiology_partner_id,
      radiology_test_id,
      radiology_date,
      radiology_slot,
      radiology_center_id,
      intimationId : this.props.requestId}
    ).then((response) => {
      if (response.message === 'success') {
        toast.success('Request Placed Success');
        this.props.onComplete()
        this.props.closeModal()
      } else {
        toast.error(response.errorMessage);
        return false;
      }
    });
  };

  handleChange = (e) => {
    let date = moment(e.target.value, 'YYYY-MM-DD').format('YYYYMMDD');

    this.setState({ value: date });
  };

  slothandleChange = (e) => {
    this.setState({ slotvalue: e.target.value });
  };

  handleAmountChange = (e) => {
    this.setState({ amount: e.target.value });
  };

  handleSubmit = () => {
    console.log(this.state.value, this.state.slotvalue, this.state.amount);
    if(!this.state.amount || !this.state.value || !this.state.slotvalue) {
      toast.error('Fill All Details!');
      return false;
    }

    return placeAppointmentRequest(
      { 
        intimationId: this.props.requestId,
        appointmentDate:this.state.value,
        appointmentTime: this.state.slotvalue,
        amount: this.state.amount
      })
      .then((response) => {
        if (response.message === 'success') {
          toast.success('Request Placed Success');
          this.props.onComplete()
          this.props.closeModal()
        } else {
          toast.error(response.errorMessage);
          return false;
        }
      });

  }

  render() {
    const { type } = this.props;
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        toggle={() => this.props.closeModal()}
        className={this.props.className}
        style= {{ maxWidth : type=="Lab Tests"?'1000px':'500px', width: '100%'}}
      >
        <ModalHeader className="bg-primary" toggle={this.props.closeModal}>
          Confirm Request
        </ModalHeader>
        <ModalBody> 
          {type === 'In-clinic Consultations (OPD)' && (
            <PlaceRequest
              submit={this.handleIntimationPlaceRequestSubmit}
              closeModal={this.props.closeModal}
            />
          )}
           {type === 'Lab Tests' && (
            <LabRequest
              submit={this.handleLabsPlaceRequestSubmit}
              closeModal={this.props.closeModal}
            />
          )}
          {type != 'In-clinic Consultations (OPD)' && type != 'Lab Tests' && (
            <form>
              <label>Appointment Date:</label>
              <br />
              <input
                type="date"
                value={this.state.datevalue}
                onChange={this.handleChange}
              />
              <br />
              <label>Appointment Time:</label>
              <br />
              <select onChange={this.slothandleChange}>
                <option value="08:00:00">08:00:00 AM - 08:30:00 AM</option>
                <option value="08:30:00">08:30:00 AM - 09:00:00 AM</option>
                <option value="09:00:00">09:00:00 AM - 09:30:00 AM</option>
                <option value="09:30:00">09:30:00 AM - 10:00:00 AM</option>
                <option value="10:00:00">10:00:00 AM - 10:30:00 AM</option>
                <option value="10:30:00">10:30:00 AM - 11:00:00 AM</option>
                <option value="11:00:00">11:00:00 AM - 11:30:00 AM</option>
                <option value="11:30:00">11:30:00 AM - 12:00:00 PM</option>
                <option value="12:00:00">12:00:00 PM - 12:30:00 PM</option>
                <option value="12:30:00">12:30:00 PM - 01:00:00 PM</option>
                <option value="13:00:00">01:00:00 PM - 01:30:00 PM</option>
                <option value="13:30:00">01:30:00 PM - 02:00:00 PM</option>
                <option value="14:00:00">02:00:00 PM - 02:30:00 PM</option>
                <option value="14:30:00">02:30:00 PM - 03:00:00 PM</option>
                <option value="15:00:00">03:00:00 PM - 03:30:00 PM</option>
                <option value="15:30:00">03:30:00 PM - 04:00:00 PM</option>
                <option value="16:00:00">04:00:00 PM - 04:30:00 PM</option>
                <option value="16:30:00">04:30:00 PM - 05:00:00 PM</option>
                <option value="17:00:00">05:00:00 PM - 05:30:00 PM</option>
                <option value="17:30:00">05:30:00 PM - 06:00:00 PM</option>
                <option value="18:00:00">06:00:00 PM - 06:30:00 PM</option>
                <option value="18:30:00">06:30:00 PM - 07:00:00 PM</option>
                <option value="19:00:00">07:00:00 PM - 07:30:00 PM</option>
                <option value="19:30:00">07:30:00 PM - 08:00:00 PM</option>
                <option value="20:00:00">08:00:00 PM - 08:30:00 PM</option>
                <option value="20:30:00">08:30:00 PM - 09:00:00 PM</option>
                <option value="21:00:00">09:00:00 PM - 09:30:00 PM</option>
                <option value="21:30:00">09:30:00 PM - 10:00:00 PM</option>
              </select>
              <br />
              <label>Enter Amount:</label>
              <br />
              <input type={"number"} placeholder={"Enter Appointment Amount"} name={"amount"} onChange={this.handleAmountChange}></input>
              <br/>
              <br/>
              <Button color="info" onClick={this.handleSubmit}>
              Confirm
              </Button>
              <Button color="secondary" onClick={this.props.closeModal}>
                Close
              </Button>
            </form>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default IntimationModalConfirm;
