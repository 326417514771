import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { ActionDetailContainer } from './StyledComponent';
import ReactTable from 'react-table';
import {
  fetchUserAction,
  refundUserPurchase,
  fetchReimbursementClaimsForUser,
} from '../../services/api/customer-support';
import { toast } from 'react-toastify';
import { hideSupportComments, showSupportComments } from '../../actions/index';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import { IoMdChatboxes } from 'react-icons/io';
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Input,
} from 'reactstrap';
import RequestChatModal from './comments/modal';

class ActionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
      pages: 0,
      userId: '',
      page: 0,
      tableColumns: [],
      showTable: true,
      requestChat: {},
      comments: [],
      frequency: 'daily',
      requestChatModal: false,
      refundModal: {
        showModal: false,
        purchaseId: null,
      },
      fitnessOptions: false,
      tasksSearch: false,
      tasksSearchKey: false,
    };
  }

  openRequestChatModal = requestId => {
    if (!requestId) {
      return;
    }
    this.props.dispatch(showSupportComments(requestId));
  };

  checkActionRequest = actionRequest => {
    switch (actionRequest) {
      case 'fit-data': {
        this.setState({
          fitnessOptions: true,
          tasksSearch: false,
          tableColumns: [
            {
              Header: 'Fitness Data',
              columns: [
                {
                  accessor: 'dataDate',
                  numeric: true,
                  disablePadding: true,
                  filterable: false,
                  sortable: false,
                  Header: 'Data Date',
                  style: { whiteSpace: 'unset' },
                },
                {
                  accessor: 'syncTime',
                  numeric: false,
                  disablePadding: false,
                  sortable: false,
                  Header: 'Sync Time',
                  disable: true,
                  style: { whiteSpace: 'unset' },
                },
                {
                  accessor: 'steps',
                  numeric: false,
                  disablePadding: false,
                  sortable: false,
                  Header: 'Steps',
                  style: { whiteSpace: 'unset' },
                  filterable: false,
                },
                {
                  accessor: 'distance',
                  numeric: false,
                  disablePadding: false,
                  Header: 'Distance',
                  sortable: false,
                  style: { whiteSpace: 'unset' },
                  filterable: false,
                },
                {
                  accessor: 'calories',
                  numeric: false,
                  disablePadding: false,
                  Header: 'Calories',
                  sortable: false,
                  style: { whiteSpace: 'unset' },
                  filterable: false,
                },
                {
                  accessor: 'platform',
                  numeric: false,
                  disablePadding: false,
                  Header: 'Platform',
                  sortable: false,
                  style: { whiteSpace: 'unset' },
                  filterable: false,
                },
                {
                  accessor: 'userGoal',
                  numeric: false,
                  disablePadding: false,
                  Header: 'Goal',
                  sortable: false,
                  style: { whiteSpace: 'unset' },
                  filterable: false,
                  getProps: (state, rowInfo, column) => {
                    console.log(rowInfo);
                    if (rowInfo) {
                      return {
                        style: {
                          background:
                            parseInt(rowInfo.row.steps) >=
                            parseInt(rowInfo.row.userGoal)
                              ? '#447d44'
                              : '#ff6565',
                          color: 'white',
                        },
                      };
                    }
                    return {};
                  },
                },
              ],
            },
          ],
        });
        break;
      }
      case 'ft-tasks': {
        this.setState({
          fitnessOptions: false,
          tasksSearch: true,
          tableColumns: [
            {
              accessor: 'taskName',
              numeric: true,
              disablePadding: true,
              filterable: false,
              sortable: false,
              Header: 'Task Name',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'rewardFrequency',
              numeric: false,
              disablePadding: false,
              sortable: false,
              Header: 'Reward Frequency',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'reward',
              numeric: false,
              disablePadding: false,
              sortable: false,
              Header: 'Reward',
              style: { whiteSpace: 'unset' },
              filterable: false,
            },
            {
              accessor: 'rewardDate',
              numeric: false,
              disablePadding: false,
              Header: 'Reward Date',
              sortable: false,
              style: { whiteSpace: 'unset' },
              filterable: false,
            },
          ],
        });
        break;
      }
      case 'ft-share': {
        this.setState({
          fitnessOptions: false,
          tasksSearch: false,
          tableColumns: [
            {
              accessor: 'tokens',
              numeric: true,
              disablePadding: true,
              filterable: false,
              sortable: false,
              Header: 'Tokens',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'referredUserName',
              numeric: false,
              disablePadding: false,
              sortable: false,
              Header: 'Referred User Name',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'rewardDate',
              numeric: false,
              disablePadding: false,
              sortable: false,
              Header: 'Reward Date',
              style: { whiteSpace: 'unset' },
              filterable: false,
              Cell: row => {
                if (row.row.reward === 1) {
                  return 'Yes';
                } else return 'no';
              },
            },
            {
              accessor: 'rewardDate',
              numeric: false,
              disablePadding: false,
              sortable: false,
              Header: 'Reward Date',
              style: { whiteSpace: 'unset' },
              filterable: false,
            },
          ],
        });
        break;
      }
      case 'ft-invites': {
        this.setState({
          fitnessOptions: false,
          tasksSearch: false,
          tableColumns: [
            {
              accessor: 'userId',
              numeric: false,
              disablePadding: false,
              sortable: false,
              Header: 'User Id',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'userName',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'User Name',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'userEmail',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'User Email',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'platform',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Platform',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'created_at',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Join On',
              style: { whiteSpace: 'unset' },
            },
          ],
        });
        break;
      }
      case 'ft-shop': {
        this.setState({
          fitnessOptions: false,
          tasksSearch: false,
          tableColumns: [
            {
              accessor: 'title',
              numeric: true,
              disablePadding: true,
              sortable: false,
              filterable: false,
              Header: 'Title',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'amount',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Amount',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'invites',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Invites',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'purchaseDate',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Purchase Date',
              style: { whiteSpace: 'unset' },
              filterable: false,
            },
            {
              accessor: 'refundDate',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Refund',
              style: { whiteSpace: 'unset' },
              filterable: false,
              Cell: row => {
                const { refundDate, purchaseId } = row.original;
                if (refundDate) {
                  return refundDate;
                }
                return (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={e => this.openRefundModal(purchaseId)}
                  >
                    Refund
                  </div>
                );
              },
            },
          ],
        });
        break;
      }
      case 'ft-wallet': {
        this.setState({
          fitnessOptions: false,
          tasksSearch: false,
          tableColumns: [
            {
              accessor: 'currentBalance',
              numeric: true,
              disablePadding: true,
              sortable: false,
              filterable: false,
              Header: 'Current Balance',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'taskRewards',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Task Rewards',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'shareRewards',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Share Rewards',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'purchases',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Purchases',
              style: { whiteSpace: 'unset' },
              filterable: false,
            },
          ],
        });
        // console.log(this.state.data);
        break;
      }
      case 'consult': {
        this.setState({
          fitnessOptions: false,
          tasksSearch: false,
          tableColumns: [
            {
              accessor: 'requestId',
              numeric: true,
              disablePadding: true,
              sortable: false,
              filterable: false,
              Header: 'RID',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'patientName',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Patient',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'doctorName',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Doctor',
              style: { whiteSpace: 'unset' },
            },
            {
              accessor: 'healthCenter',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Center',
              style: { whiteSpace: 'unset' },
              filterable: false,
            },
            {
              accessor: 'bookingTime',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Req. Time',
              style: { whiteSpace: 'unset' },
              filterable: false,
            },
            {
              accessor: 'appointmentTime',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Appt. Time',
              style: { whiteSpace: 'unset' },
              filterable: false,
            },
            {
              accessor: 'appointmentStatus',
              numeric: false,
              sortable: false,
              disablePadding: false,
              Header: 'Appt. Status',
              style: { whiteSpace: 'unset' },
              filterable: false,
            },
            {
              accessor: 'requestComments',
              disablePadding: true,
              filterable: false,
              Header: 'Comments',
              sortable: false,
              style: { whiteSpace: 'unset' },
              Cell: row => {
                const { requestId } = row.original;
                return (
                  <div onClick={() => this.openRequestChatModal(requestId)}>
                    {row.original.countComments}
                    <IoMdChatboxes
                      className="icon-chat"
                      style={{
                        cursor: 'pointer',
                        width: '30px',
                        height: '30px',
                      }}
                    />
                  </div>
                );
              },
            },
          ],
        });
        // console.log(this.state.data);
        break;
      }
      case 'reimburse-claims': {
        this.setState({
          fitnessOptions: false,
          tasksSearch: false,
          tableColumns: [
            {
              Header: 'Order Details',
              columns: [
                {
                  Header: 'Reimbursement ID',
                  accessor: 'reimbursementId',
                  filterable: true,
                  width: 75,
                  Filter: ReactTableInputFilter,
                },
                {
                  Header: 'Patient Name',
                  width: 120,
                  Cell: (row) => {
                    return <span>{row.original.patientName}</span>;
                  },
                },
                {
                  Header: 'Reimbursement Type',
                  accessor: 'reimbursementType',
                  width: 100,
                  filterable: true,
                  Filter: ReactTableInputFilter,
                },
                {
                  Header: 'Request Date',
                  accessor: 'requestDate',
                  width: 100,
                  filterable: true,
                  Cell: (row) =>
                    moment(row.original.requestDate).format('DD-MMM-YYYY'),
                },
                {
                  Header: 'Status',
                  accessor: 'requestStatus',
                  filterable: true,
                  width: 100,
                  Filter: ({ filter, onChange }) => {
                    return (
                      <select
                        onChange={(event) => onChange(event.target.value)}
                        style={{ width: '100%' }}
                        value={filter ? filter.value : 'all'}
                      >
                        <option value="all">Show All</option>
                        <option value="pending">Document-Uploaded</option>
                        <option value="approved">Approved</option>
                        <option value="rejected">Rejected</option>
                      </select>
                    );
                  },
                  // Cell: (row) => (
                  //   <span>
                  //     {row.original.deletedRequest
                  //       ? 'DELETED'
                  //       : row.original.requestStatus &&
                  //         row.original.requestStatus.toUpperCase()}
                  //   </span>
                  // ),
                  Cell: (row) => {
                    const { insurerStatus, claimSettledDate, debitNoteSentDate, claimStatus } = row.original;
                    if (claimSettledDate) {
                      return (
                        <div
                          className=" flex flex-column mb-2"
                        >
                          Claim Settled
                          </div>
                      )
                    }
                    if (debitNoteSentDate) {
                      return (
                        <div className='flex flex-column mb-2'>
                          Debit Note Sent
                        </div>
                      )
                    }
                    if(claimStatus === 'rejected') {
                      return (
                        <div className='flex flex-column mb-2'>
                          Rejected
                        </div>
                      )
                    }
                    switch (insurerStatus) {
                      case 'approved':
                      return (
                        <div
                            className=" flex flex-column mb-2"
                          >
                            Approved By Insurer
                        </div>
                      )

                      case 'rejected':
                      return (
                        <div className='flex flex-column mb-2'
                          >
                            Rejected by Insurer
                        </div>
                      )
                      default: {
                        return (
                          <div className='flex flex-column mb-2'>
                          Pending
                        </div>
                        )
                      }
                      
                    }
                  }
                },
                {
                  Header: 'Debit Note Sent Date',
                  accessor: 'debitNoteSentDate',
                  width: 100,
                  Cell: (row) => (
                    <span>{row.original.debitNoteSentDate ? moment(row.original.debitNoteSentDate).format('Do MMM'): ''}</span>
                  )
                },
                {
                  Header: 'Claim UTR',
                  accessor: 'claimUtr',
                  width: 100,
                  Cell: (row) => (
                    <span>{row.original.claimUtr}</span>
                  )
                 },
                 {
                  Header: 'Claim Settled Date',
                  accessor: 'claimSettledDate',
                  width: 100,
                  Cell: (row) => (
                    <span>{row.original.claimSettledDate ? moment(row.original.claimSettledDate).format('Do MMM'): ''}</span>
                  )
                 },
                {
                  Header: 'Reason',
                  accessor: 'reimbursementReason',
                  width: 100,
                  Cell: (row) => (
                    <span>{row.original.reimbursementReason}</span>
                  )
                },
                {
                  Header: 'Invoice Date',
                  accessor: 'invoiceDate',
                  width: 100,
                  Cell: (row) => (
                    <span>
                      {moment(row.original.invoiceDate).format('Do MMM')}
                    </span>
                  ),
                },
                {
                  Header: 'Requested Amount',
                  accessor: 'reimbursementAmount',
                  width: 70,
                  Cell: (row) => (
                    <span>{`₹ ${
                      row.original.reimbursementAmount || 'NA'
                    }`}</span>
                  ),
                },
                {
                  Header: 'Approved Amount',
                  accessor: 'approvedAmount',
                  width: 70,
                  Cell: (row) => (
                    <span>{`₹ ${row.original.approvedAmount || 'NA'}`}</span>
                  ),
                },
                {
                  Header: 'Invoices',
                  width: 100,
                  Cell: (row) =>
                    row.original.invoiceLink
                      ? row.original.invoiceLink.split(',').map((link, idx) => (
                          <div>
                            <a
                              key={idx}
                              rel="noopener noreferrer"
                              target="_blank"
                              href={link}
                            >{`Invoice ${idx + 1}`}</a>
                          </div>
                        ))
                      : null,
                },
                {
                  Header: 'Prescription/Proofs',
                  width: 100,
                  Cell: (row) =>
                    row.original.prescriptionLink
                      ? row.original.prescriptionLink
                          .split(',')
                          .map((link, idx) => (
                            <div>
                              <a
                                key={idx}
                                rel="noopener noreferrer"
                                target="_blank"
                                href={link}
                              >{`Prescription ${idx + 1}`}</a>
                            </div>
                          ))
                      : null,
                },
                {
                  Header: 'Lab Reports',
                  width: 100,
                  Cell: (row) =>
                    row.original.labsReportLink
                      ? row.original.labsReportLink
                          .split(',')
                          .map((link, idx) => (
                            <div>
                              <a
                                key={idx}
                                rel="noopener noreferrer"
                                target="_blank"
                                href={link}
                              >{`Labs Report ${idx + 1}`}</a>
                            </div>
                          ))
                      : null,
                },
              ],
            },
          ],
        });
        break;
      }
      case 'gmc-policy-details': {
        this.setState({
          fitnessOptions: false,
          tasksSearch: false,
          tableColumns: [ {
            Header: 'Name',
            accessor: 'name',
            filter: true,
          }, {
            Header: 'Type',
            accessor: 'userType',
          }, {
            Header: 'Phone',
            accessor: 'phone',
          }, {
            Header: 'Status',
            Cell: (row) => {
              if (row.original.active) {
                return (<span className="text-success">Active</span>);
              }
              return (<span className="text-danger">Inactive</span>);
            }
          }, {
            Header: 'E-Card',
            accessor: 'ecard',
            Cell: (row) => {
              return (<a href={row.original.ecard} target='_blank'>{row.original.ecard}</a>);
            }
          } ]
        });
        break;
      }
      default:
        break;
    }
  };
  onFetchData = state => {
    this.setState(
      {
        pageSize: state.pageSize,
        page: state.page,
      },
      this.fetchTableData
    );
  };

  fetchTableData = () => {
    this.setState({
      loading: true,
    });

    const userId = this.props.userId;
    if (this.props.actionRequest === 'reimburse-claims') {
      fetchReimbursementClaimsForUser(userId)
        .then(responseBody => {
          if (responseBody.message === 'success') {
            this.setState({
              data: responseBody.result,
              loading: false,
              pages: null,
            });
          } else {
            this.setState({
              showTable: false,
              loading: false,
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
      return;
    }
    const params = {
      page: this.state.page,
      t: this.props.actionRequest,
      pageSize: this.state.pageSize,
      f: this.state.frequency,
    };
    if (this.state.tasksSearchKey) {
      params.q = this.state.tasksSearchKey;
    }
    fetchUserAction(params, userId)
      .then(responseBody => {
        this.setState({
          data: responseBody.result[0],
          loading: false,
          pages: Math.ceil(
            responseBody.result[1][0]['totalHit'] / this.state.pageSize
          ),
        });
      })
      .then(() => {
        if (this.state.data.length === 0) {
          this.setState({ showTable: false });
        } else {
          this.setState({ showTable: true });
        }
      })
      .catch(err => {
        console.error({ err, params });
      });
  };

  componentDidMount = () => {
    this.checkActionRequest(this.props.actionRequest);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.actionRequest !== this.props.actionRequest) {
      this.setState({ page: 0 });
      this.checkActionRequest(this.props.actionRequest);
      this.fetchTableData();
    }
    if (
      prevProps.userDetail.length !== this.props.userDetail.length ||
      (this.props.userDetail &&
        prevProps.userDetail[0].id !== this.props.userDetail[0].id)
    ) {
      this.setState({ data: [] });
    }
  };

  showTable = () => {
    if (this.state.showTable) {
      return (
        <ReactTable
          data={this.state.data}
          columns={this.state.tableColumns}
          loading={this.state.loading}
          page={this.state.page}
          onPageChange={this.onFetchData}
          pages={this.state.pages}
          manual
          onFetchData={this.onFetchData}
          className="-striped -highlight"
          defaultPageSize={10}
        />
      );
    } else {
      return <h1> No Records </h1>;
    }
  };

  closeRequestChatModal = () => {
    this.props.dispatch(hideSupportComments());
  };

  openRefundModal = purchaseId => {
    console.log({ purchaseId }, '[openRefundModal]');
    this.setState({
      refundModal: {
        showModal: true,
        purchaseId,
      },
    });
  };

  closeRefundModal = () => {
    this.setState(
      {
        refundModal: {
          showModal: false,
          purchaseId: null,
        },
      },
      this.fetchTableData
    );
  };

  refundPurchase = () => {
    const purchaseId = this.state.refundModal.purchaseId;
    if (!purchaseId) {
      return;
    }
    refundUserPurchase(purchaseId)
      .then(() => {
        toast.info('Successfully Refunded');
        this.closeRefundModal();
      })
      .catch(err => {
        console.error(err, 'Couldnt Refund');
        toast.warn('Couldnt Refund');
      })
      .finally(() => {
        this.closeRefundModal();
      });
  };

  handleDailyClick = () => {
    this.setState(
      {
        frequency: 'daily',
      },
      () => this.fetchTableData()
    );
  };

  handleWeeklyClick = () => {
    this.setState(
      {
        frequency: 'weekly',
      },
      () => this.fetchTableData()
    );
  };

  handleTasksSearch = e => {
    const { value } = e.target;
    console.log(value, 'Tasks Search');
    this.setState(
      {
        tasksSearchKey: value,
      },
      () => this.fetchTableData()
    );
  };

  render() {
    return (
      <ActionDetailContainer>
        <div className={'row'} hidden={!this.state.fitnessOptions}>
          <div className={'col'} style={{ 'text-align': 'center' }}>
            <Button color="primary" onClick={this.handleDailyClick} size="sm">
              Daily Data
            </Button>{' '}
          </div>
          <div className={'col'} style={{ 'text-align': 'center' }}>
            <Button
              color="secondary"
              onClick={this.handleWeeklyClick}
              size="sm"
            >
              Weekly Data
            </Button>
          </div>
        </div>
        <div className={'row'} hidden={!this.state.tasksSearch}>
          <div className={'col'} style={{ 'text-align': 'center' }}>
            <Input
              type={'text'}
              onChange={this.handleTasksSearch}
              placeholder={'Search Task Name Here'}
            />
          </div>
        </div>
        {this.showTable()}
        <Modal
          isOpen={this.props.customerSupport.showInternalComments}
          toggle={this.closeRequestChatModal}
        >
          <ModalHeader className="bg-primary">
            {`Internal Comments`}
          </ModalHeader>
          <ModalBody>
            <RequestChatModal
              comments={this.state.comments}
              requestId={this.props.customerSupport.requestId}
              closeModal={() => this.setState({ requestChatModal: false })}
            />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.refundModal.showModal}
          toggle={this.closeRefundModal}
        >
          <ModalHeader className="bg-primary">{`Refund Modal`}</ModalHeader>
          <ModalBody>
            Are you sure you want to cancel and refund this purchase?
          </ModalBody>
          <ModalFooter>
            <div class="btn btn-primary" onClick={this.refundPurchase}>
              Refund
            </div>{' '}
            <div class="btn btn-secondary" onClick={this.closeRefundModal}>
              Cancel
            </div>
          </ModalFooter>
        </Modal>
      </ActionDetailContainer>
    );
  }
}

const mapStateToProps = state => ({
  userDetail: state.customerSupport.userDetail,
  customerSupport: state.customerSupport,
});

export default connect(mapStateToProps)(ActionDetail);
