import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { addAddressForUser, listAddresses } from "../../../services/api/rx-transcription";
import {toast} from "react-toastify";
import { setSelectedAddressRxDetailsForTranscription } from "../../../actions";

class LabRxAddressDetails extends Component {

    constructor(props) {
        super(props);
        this.state = { collapse: false, addresses: [], selectedAddress: null, address: '', pincode: '', locality: '' };
    }

    toggle = () => {
        this.setState(state => ({ collapse: !state.collapse }));
        if (!this.collapse) {
            this.getAddressList();
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userInfo.userId !== this.props.userInfo.userId) {
            this.setState(state => ({ collapse: false }));
        }
    }

    getAddressList = () => {
        listAddresses(this.props.userInfo.authToken)
        .then((response) => {
            if(response.body.message === 'success') {
                this.setState({
                    addresses: response.body.addresses,
                });
            } else {
                toast.error('Error in listing addresses');
            }
        })
        .catch((err) => {
            toast.error('Error in listing addresses');
            console.error('Error', err);
        });
    };

    selectAddress = (address) => {
        let selected = null;
        const addresses = this.state.addresses;
        addresses.forEach((a) => {
            if (address.addressId === a.addressId) {
                a.active = true;
                selected = a;
            } else {
                a.active = false;
            }
        });

        this.props.dispatch(setSelectedAddressRxDetailsForTranscription(selected));

        this.setState({selectedAddress: selected, addresses});
    };

    renderAddresses = () => {
        return this.state.addresses.map((a) => {
            const containerClasses = 'list-group-item list-group-item-action ';
            return (
                <div onClick={() => this.selectAddress(a)} className={containerClasses + (a.active ? 'active': '')} key={a.addressId}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{a.address} | {a.locality} | {a.pincode}</h5>
                    </div>
                    <p className="mb-1">{a.title}</p>
                </div>
            );
        });
    };

    addAddress = () => {
        if(this.state.address && this.state.pincode && this.state.locality) {
            addAddressForUser(this.state.address, this.state.pincode, this.state.locality, 'Other', this.props.userInfo.authToken)
            .then((response) => {
                if (response.body.message === 'success') {
                    this.getAddressList();
                    this.setState({
                        address: '',
                        pincode: '',
                        locality: '',
                    });
                } else {
                    toast.error('Error in adding addresses');
                }
            })
            .catch((err) => {
                toast.error('Error in adding addresses');
                console.error('Error', err);
            });
        }
    };

    render() {
        return (
            <div className="row">
                <div className="col">
                    <div className="row">
                        <button disabled={this.props.disabled} className="btn btn-primary" onClick={this.toggle} style={{ marginBottom: '1rem' }}>Address Details</button>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Collapse isOpen={this.state.collapse}>
                                <Card>
                                    <CardBody>
                                        <div className="row">
                                            <div className="col">
                                                <div className="form">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail4">Complete Address*</label>
                                                            <input type="text" className="form-control" id="inputEmail4"
                                                                   placeholder="Address" value={this.state.address} onChange={(e) => this.setState({address: e.target.value})}/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputPassword4">Pincode*</label>
                                                            <input type="number" className="form-control" id="inputPassword4"
                                                                   placeholder="Pincode" value={this.state.pincode} onChange={(e) => this.setState({pincode: e.target.value})}/>
                                                        </div>
                                                    </div>

                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputCity">Locality*</label>
                                                            <input type="text" className="form-control" value={this.state.locality} id="inputCity" onChange={(e) => this.setState({locality: e.target.value})}/>
                                                        </div>


                                                    </div>

                                                    <button className="btn btn-primary" onClick={this.addAddress}>Add address</button>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <div className="list-group">
                                                    {this.renderAddresses()}
                                                </div>
                                            </div>
                                        </div>


                                    </CardBody>
                                </Card>
                            </Collapse>
                        </div>
                    </div>
                </div>



            </div>

        );
    }
}

const mapStateToProps = state => ({ userInfo: state.createLabRxDetails.userInfo });

export default connect(
    mapStateToProps,
)(LabRxAddressDetails);





