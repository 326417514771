import React, { Component } from 'react';
// import Select from 'react-select';
import { fetchAllSponsors } from '../../../services/api/sponsors';
import { connect } from 'react-redux';
// import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { MultiSelect } from 'react-multi-select-component';

class SponsorFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsors: this.getInitialSponsorValue(),
      selected: this.getInitialSponsorValueSelected(),
      timeoutId: null,
    };
    this.selectRef = React.createRef();
    // this.selectRef = React.forwardRef(); // parent to child component
  }

  handleChange = () => {
    let selectedSponsors = [];
    for (let i = 0; i < this.state.selected.length; i++) {
      if (this.state.selected[i].value !== 'all') {
        selectedSponsors.push(this.state.selected[i].value);
      }
    }
    // console.log(selectedSponsors, this.state.selected, 'handleChange');
    this.props.onChangeValue(
      selectedSponsors,
      selectedSponsors.length === this.state.sponsors.length
    );
    this.setState({ timeoutId: null });
  };

  getSponsors = () => {
    return fetchAllSponsors()
      .then((response) => {
        console.log(response, 'fetchAllSponsors');
        this.setState({
          sponsors: [
            ...this.state.sponsors,
            ...response.data.map((ele) => ({
              label: ele.sponsorName,
              value: ele.sponsorId,
            })),
          ],
        });
      })
      .catch(() => {
        this.setState({
          sponsors: [],
        });
      });
  };

  getInitialSponsorValue = () => {
    let arr = [];
    let prevValues;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        prevValues = this.props.sponsorsDocOnCall;
        break;
      }
      case '/specialist-consults': {
        prevValues = this.props.sponsorsSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        prevValues = this.props.sponsorsOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        prevValues = this.props.sponsorsScheduledConsults;
        break;
      }
      default:
        prevValues = undefined;
        break;
    }
    console.log(prevValues, 'prevValues:sponsors');

    fetchAllSponsors().then((response) => {
      if (prevValues) {
        if (
          prevValues.length >= response.data.length ||
          prevValues === undefined
        ) {
          // arr.push({
          //   value: 'all',
          //   label: 'All items are selected.',
          // });
        } else if (prevValues.length === 0) {
          // pass
        } else {
          response.data.forEach((element) => {
            if (prevValues.includes(element.sponsorId)) {
              arr.push({
                value: element.sponsorId,
                label: element.sponsorName,
              });
            }
          });
        }
      } else {
        // arr.push({
        //   value: 'all',
        //   label: 'All',
        // });
      }
    });

    return arr;
  };

  getInitialSponsorValueSelected = () => {
    let arr = [];
    let prevValues;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        prevValues = this.props.sponsorsDocOnCall;
        break;
      }
      case '/specialist-consults': {
        prevValues = this.props.sponsorsSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        prevValues = this.props.sponsorsOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        prevValues = this.props.sponsorsScheduledConsults;
        break;
      }
      default:
        prevValues = undefined;
        break;
    }
    // console.log(prevValues, 'prevValues:sponsors');

    fetchAllSponsors().then((response) => {
      // console.log(prevValues.length,response.data.length, 'prevValues:sponsors2');
      if (prevValues) {
        if (prevValues.length === 0) {
          arr.push({
            value: 'all',
            label: 'Select...',
          });
          // set filter to null here
          this.props.setSponsorNull();
        } else {
          response.data.forEach((element) => {
            if (prevValues.includes(element.sponsorId)) {
              arr.push({
                value: element.sponsorId,
                label: element.sponsorName,
              });
            }
          });
        }
      } else {
        // very initial page loading
        // arr.push({
        //   value: 'all',
        //   label: 'All items are selected.',
        // });
          response.data.forEach((element) => {
            arr.push({
              value: element.sponsorId,
              label: element.sponsorName,
            });
          });
      }

      this.setState({selected: arr});
    });

    return arr;
  };

  setSelected = (selectedItems) => {
    console.log(selectedItems, 'setSelected');

    if (selectedItems.length >= 1) {
      if (selectedItems[0].value === 'all') {
        this.setState({
          selected: selectedItems.slice(1, selectedItems.length),
        });
      } else {
        this.setState({ selected: selectedItems });
      }
    } else {
      this.setState({ selected: selectedItems });
    }

    if (!this.state.timeoutId) {
      this.setState({ timeoutId: setTimeout(this.handleChange, 1200) });
    }
  };

  componentDidMount() {
    this.getSponsors();
  }

  render() {
    return (
      <div>
        <MultiSelect
          hasSelectAll={true}
          options={this.state.sponsors}
          value={this.state.selected}
          onChange={this.setSelected}
          labelledBy="Select Sponsors"
          debounceDuration={300}
          styles={{
            option: (provided, state) => ({
              ...provided,
              background: 'white',
              color: 'black',
              cursor: 'pointer',
              '&:hover': {
                color: 'black',
                background: 'wheat',
              },
              '&:active': {
                color: 'white',
                background: 'rgb(2, 117, 216)',
              },
            }),
          }}
          ref={this.selectRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  sponsorsDocOnCall: state.filterHeader.sponsorsDocOnCall,
  sponsorsSpecialistConsults: state.filterHeader.sponsorsSpecialistConsults,
  sponsorsOfflineSp: state.filterHeader.sponsorsOfflineSp,
  sponsorsScheduledConsults: state.filterHeader.sponsorsScheduledConsult
});

export default connect(mapStateToProps)(SponsorFilter);
