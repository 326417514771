import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import 'react-table/react-table.css';
import UploadFile from './UploadRxComponents/UploadFile.js';

function UploadRxModal({
  onClose,
}) {

  return (
    
    <Modal
      isOpen={true}
      style={{ maxWidth: '1200px', width: '100%', borderRadius: '4px', backgroundColor: 'yellow' }}
    >
      <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={onClose}>
        <h4
          className="text-center"
          style={{ color: '#fff', fontWeight: 'bold' }}
        >
         Upload Prescriptions
        </h4>
      </ModalHeader>
      <ModalBody style={{paddingTop:'1%', paddingBottom:'5%', paddingInline:'5%'}}>
        <UploadFile onClose={onClose}></UploadFile>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  user: state.user,
});

export default connect(mapStateToProps)(UploadRxModal);

