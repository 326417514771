import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { markLabRequestNotServiceable} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { getConflictingItems, reconcileOrder } from "../../services/api/labs-reconcile-api";
import LabsConflictingUpdatePartnerOrderId from './LabsConflictingUpdatePartnerOrderId';
class LabsConflictingTests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            add: [],
            remove: [],
        };
    }

    toggle = () => {
        if (!this.state.modal) {
            this.loadConflictingItems();
        }
        this.setState({
            modal: !this.state.modal,
        });
    };

    loadConflictingItems = () => {
        getConflictingItems(this.props.referenceId)
        .then((result) => {
            if(result.body.message === 'success') {
                this.setState({
                    add: result.body.toAdd,
                    remove: result.body.toRemove,
                });
            } else {
                toast.error('Unable to load conflicting orders');
            }
        })
        .catch((err) => {
            toast.error('Unable to load conflicting orders');
            console.error('Error', err);
        });
    };

    reconcile = () => {
        reconcileOrder(this.props.referenceId)
        .then((result) => {
            if(result.body.message === 'success') {
                this.toggle();
                this.props.refresh();
            } else {
                toast.error('Unable to load conflicting orders');
            }
        })
        .catch((err) => {
            toast.error('Unable to load conflicting orders');
            console.error('Error', err);
        });
    };

    renderItems = () => {
        const allItems = [];
        this.state.add.forEach((i) => {
            i.action = 'ADD';
            allItems.push(i);
        });
        this.state.remove.forEach((i) => {
            allItems.push({pTestName: i.vTestName, pTestCode: i.vTestCode, pMrp: i.vMrp, pCost: i.vCost, action: 'REMOVE'});
        });

        return allItems.map((i, index) => {
            return (
                <tr key={index}>
                    <td>
                        {i.pTestName}
                    </td>
                    <td>
                        {i.pTestCode}
                    </td>
                    <td>
                        {i.pMrp}
                    </td>
                    <td>
                        {i.pCost}
                    </td>
                    <td>
                        {i.action}
                    </td>
                    <td>
                        <LabsConflictingUpdatePartnerOrderId orderId={this.props.referenceId} partnerTestCode={i.pTestCode} refresh={this.props.refresh}/>
                    </td>
                </tr>
            );
        })
    };

    render() {
        return (
            <div>
                <button onClick={this.toggle} className="btn btn-sm btn-outline-primary">Reconcile</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <h4 className="mt-1 mb-0 ml-2">Showing details of reconciliation: {this.props.referenceId}</h4>
                    <ModalBody>
                        <table className="table">
                            <thead className="thead-dark">
                            <tr>
                                <th scope="col">Test Name</th>
                                <th scope="col">Test Code</th>
                                <th scope="col">MRP</th>
                                <th scope="col">COST</th>
                                <th scope="col">Action</th>
                                <th scope="col">Update Incorrect Visit Order ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderItems()}
                            </tbody>
                        </table>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.reconcile}>Reconcile</Button>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(LabsConflictingTests);





