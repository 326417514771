import React from 'react';

import {
  fetchSponsors,
  uploadLogo,
  updateSponsorConfig,
} from '../../services/api/sponsor-management';
import Link from 'valuelink';
import { Input, TextArea, Radio } from 'valuelink/tags';
import { ButtonGroup, Button, Tooltip } from 'reactstrap';
import { toast } from 'react-toastify';
import { MdInfo } from 'react-icons/md';

class SponsorManagementDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sponsorId: null,
      logoFile: '',
      verticals: [],
      updatedVerticalsInfo: [],
    };
  }

  getSponsorDetails = () => {
    const { sponsorId } = this.state;
    if (!sponsorId) {
      toast.error('Invalid Sponsor');
      return;
    }
    return fetchSponsors({ sponsorId })
      .then((response) => {
        if (response.message === 'success') {
          const updatedVerticalsInfo = response.verticals.map((v) => {
            const verticalObjIndex = response.data[0].verticalsInfo.findIndex(
              (v2) => v2.id === v.verticalId
            );
            const foundVertical =
              response.data[0].verticalsInfo[verticalObjIndex];
            if (foundVertical) {
              foundVertical.visible = true;
              if (foundVertical.offlineOff === undefined) {
                foundVertical.offlineOff = foundVertical.off;
              }
              foundVertical.rank = verticalObjIndex + 1;
              return foundVertical;
            }
            return {
              id: v.verticalId,
              off: 0,
              offlineOff: 0,
              visible: false,
              rank: 1000,
              online: v.online,
              offline: v.offline,
            };
          });

          this.setState({
            ...response.data[0],
            opsName: response.data[0].opsName || '',
            updatedVerticalsInfo,
            verticals: response.verticals,
          });

          return;
        }

        throw new Error(
          response.errorMessage || 'Error while fetching sponsor details'
        );
      })
      .catch((err) => {
        console.error(err, 'Error while fetching sponsor details');
        toast.error(err.message);
      });
  };

  componentDidMount() {
    const sponsorId = parseInt(this.props.match.params.sponsorId);
    this.setState(
      {
        sponsorId,
      },
      this.getSponsorDetails
    );
  }

  toggleRadioInt = (propName, value) => {
    console.log(propName, value, this.state[propName]);
    this.setState({ [propName]: value ? 1 : 0 });
  };

  getToggleIntSwitch = (propName) => {
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={'sw-' + propName}
          onChange={(e) => this.toggleRadioInt(`${propName}`, e.target.checked)}
          checked={this.state[propName] === 1}
        />
        <label
          className="custom-control-label"
          htmlFor={'sw-' + propName}
        ></label>
      </div>
    );
  };

  uploadSponsorLogo = (file) => {
    uploadLogo(file, this.state.sponsorId)
      .then((result) => {
        console.log('Result from uploading the logo', result);
        if (result.body.message === 'success') {
          toast.success(`Logo Updated`);
        } else {
          toast.error(`Error in uploading logo`);
        }
      })
      .catch((err) => {
        console.error('Error in uploading logo', err);
        toast.error(`Error in uploading logo`);
      });
  };

  updateSponsorInfo = () => {
    // Update verticalsInfo
    const verticalsInfo = this.state.updatedVerticalsInfo
      .filter((v) => v.visible === true)
      .sort((a, b) => a.rank - b.rank)
      .map((v) => {
        return {
          id: v.id,
          off: v.off,
          offlineOff: v.offlineOff,
          online: v.online ? 1 : 0,
          offline: v.offline ? 1 : 0,
        };
      });
    const newSponsorConfig = { ...this.state, verticalsInfo };
    return updateSponsorConfig(newSponsorConfig)
      .then((result) => {
        if (result.body.message === 'success') {
          toast.success(`Sponsor Updated`);
          return this.getSponsorDetails();
        } else {
          toast.error(`Error in updating SponsorConfig`);
        }
      })
      .catch((err) => {
        console.error('Error in updating SponsorConfig', err);
        toast.error(`Error in updating SponsorConfig`);
      });
  };

  onFileInputChanged = (event) => {
    console.log('File input state changed', event.target.files[0]);
    this.uploadSponsorLogo(event.target.files[0]);
  };

  renderToolTip = (target, text) => {
    return (
      <Tooltip
        placement="top"
        target={target}
        trigger="hover"
        toggle={() => {
          const { cancelTooltipOpen } = this.state;
          this.setState({
            cancelTooltipOpen: target === cancelTooltipOpen ? false : target,
          });
        }}
        isOpen={this.state.cancelTooltipOpen === target}
      >
        {text}
      </Tooltip>
    );
  };

  updateVerticalsInfo = (verticalId, changedObj) => {
    const updatedVerticalsInfo = [...this.state.updatedVerticalsInfo];
    let verticalIndex = updatedVerticalsInfo.findIndex(
      (v) => v.id === verticalId
    );
    if (verticalIndex >= 0) {
      updatedVerticalsInfo[verticalIndex] = {
        ...updatedVerticalsInfo[verticalIndex],
        ...changedObj,
      };
      this.setState({ updatedVerticalsInfo });
    }
  };

  renderToggleForVerticalInfo = (vertical) => {
    const { id, visible } = vertical;

    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={'sw-v-' + id}
          onChange={(e) =>
            this.updateVerticalsInfo(id, { visible: e.target.checked })
          }
          checked={visible}
        />
        <label className="custom-control-label" htmlFor={'sw-v-' + id}></label>
      </div>
    );
  };

  renderToggleVerticalInfoOnline = (vertical) => {
    const { id, online } = vertical;
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={'sw-v-o-' + id}
          onChange={(e) =>
            this.updateVerticalsInfo(id, { online: e.target.checked })
          }
          checked={online}
        />
        <label
          className="custom-control-label"
          htmlFor={'sw-v-o-' + id}
        ></label>
      </div>
    );
  };

  renderToggleVerticalInfoOffline = (vertical) => {
    const { id, offline } = vertical;
    return (
      <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id={'sw-v-of-' + id}
          onChange={(e) =>
            this.updateVerticalsInfo(id, { offline: e.target.checked })
          }
          checked={offline}
        />
        <label
          className="custom-control-label"
          htmlFor={'sw-v-of-' + id}
        ></label>
      </div>
    );
  };

  renderVerticalsInfoTable = () => {
    console.log(this.state.updatedVerticalsInfo, 'All available verticals');
    const { updatedVerticalsInfo } = this.state;
    return updatedVerticalsInfo.map((v) => {
      const verticalName = this.state.verticals.find(
        (i) => i.verticalId === v.id
      ).verticalName;
      return (
        <tr key={v.id}>
          <td scope="row">{v.id}</td>
          <td>
            <input
              type="number"
              className="form-control"
              id="formGroupExampleInput"
              value={v.rank}
              placeholder="Rank"
              onChange={(e) =>
                this.updateVerticalsInfo(v.id, {
                  rank: parseInt(e.target.value),
                })
              }
            />
          </td>
          <td>{verticalName}</td>
          <td>{this.renderToggleForVerticalInfo(v)}</td>
          <td width={200}>
            <div className="row">
              <div class="col-md-6">
                {this.renderToggleVerticalInfoOnline(v)}
              </div>
              <div class="col-md-6">
                <input
                  type="number"
                  className="form-control"
                  id="formGroupExampleInput"
                  value={v.off}
                  placeholder="Online Discount %"
                  onChange={(e) =>
                    this.updateVerticalsInfo(v.id, {
                      off: parseInt(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          </td>
          <td width={200}>
            <div className="row">
              <div className="col-md-6">
                {this.renderToggleVerticalInfoOffline(v)}
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  className="form-control"
                  id="formGroupExampleInput"
                  value={v.offlineOff}
                  placeholder="Online Discount %"
                  onChange={(e) =>
                    this.updateVerticalsInfo(v.id, {
                      offlineOff: parseInt(e.target.value),
                    })
                  }
                />
              </div>
            </div>
          </td>
        </tr>
      );
    });
  };

  render() {
    const sponsorNameLink = Link.state(this, 'sponsorName');
    const descriptionLink = Link.state(this, 'description');
    const sponsorTextLink = Link.state(this, 'sponsorText');
    const domainsLink = Link.state(this, 'domains');
    const commonNameLink = Link.state(this, 'commonName');
    const opsNameLink = Link.state(this, 'opsName');
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <h3 className="ml-1">{this.state.sponsorName}</h3>
          </div>
          <div className="col">
            <button
              className="btn btn-success"
              onClick={() => this.updateSponsorInfo()}
            >
              Update Sponsor Configuration
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="row mb-1">
              <div className="col-3">
                <label>
                  Name <MdInfo id="nameTooltip" />{' '}
                  {this.renderToolTip('nameTooltip', 'Name tooltip text')}
                </label>
              </div>
              <div className="col-6">
                <Input className="form-control" valueLink={sponsorNameLink} />
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-3">
                <label>Description</label>
              </div>
              <div className="col-6">
                <TextArea
                  className="form-control"
                  valueLink={descriptionLink}
                  rows={7}
                  style={{ resize: 'none' }}
                />
              </div>
            </div>

            <div className="row mb-1">
              <div className="col-3">
                <label>Logo</label>
              </div>
              <div className="col-3">
                <div>
                  <input
                    type="file"
                    id="validatedCustomFile"
                    onChange={(e) => this.onFileInputChanged(e)}
                  />
                </div>
              </div>
              <div className="col-3">
                <img src={this.state.logo} width={100} />
              </div>
            </div>

            <div className="row mb-1">
              <div className="col-3">
                <label>Sponsor Text</label>
              </div>
              <div className="col-6">
                <Input
                  type="text"
                  className="form-control"
                  valueLink={sponsorTextLink}
                />
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-3">
                <label>Domains</label>
              </div>
              <div className="col-6">
                <Input
                  type="text"
                  className="form-control"
                  valueLink={domainsLink}
                />
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-3">
                <label>Common Name</label>
              </div>
              <div className="col-6">
                <Input
                  type="text"
                  className="form-control"
                  valueLink={commonNameLink}
                />
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-3">
                <label>OPS Name</label>
              </div>
              <div className="col-6">
                <Input
                  type="text"
                  className="form-control"
                  valueLink={opsNameLink}
                />
              </div>
            </div>

            <div className="row mb-1">
              <div className="col">
                <h3 className="mt-1">Feature Matrix</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Feed</th>
                      <td>{this.getToggleIntSwitch('feedAvailable')}</td>
                    </tr>

                    <tr>
                      <th scope="row">
                        Show Products Market Place(ftAvailable){' '}
                        <MdInfo id="ftAvailableInfo" />{' '}
                        {this.renderToolTip(
                          'ftAvailableInfo',
                          'Show Products Market Place'
                        )}
                      </th>
                      <td>{this.getToggleIntSwitch('ftAvailable')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show HRA Card</th>
                      <td>{this.getToggleIntSwitch('showHra')}</td>
                    </tr>

                    <tr>
                      <th scope="row">
                        Credit Fitcoins to users?(ftCredit){' '}
                        <MdInfo id="ftCreditInfo" />{' '}
                        {this.renderToolTip(
                          'ftCreditInfo',
                          'Fitcoins will be credited to the user for specific tasks'
                        )}
                      </th>
                      <td>{this.getToggleIntSwitch('ftCredit')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show Reimbursement Flow?</th>
                      <td>{this.getToggleIntSwitch('reimbursement')}</td>
                    </tr>

                    <tr>
                      <th scope="row">
                        Lab Tests Search (showLabSearch){' '}
                        <MdInfo id="showLabSearchInfo" />{' '}
                        {this.renderToolTip(
                          'showLabSearchInfo',
                          'Enable Lab test search in labs section'
                        )}
                      </th>
                      <td>{this.getToggleIntSwitch('showLabSearch')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show Rx Upload in Labs</th>
                      <td>{this.getToggleIntSwitch('showLabUpload')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show Labs Card in Home and Care Tab</th>
                      <td>{this.getToggleIntSwitch('showLabs')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show Rx Upload in Medicine Delivery</th>
                      <td>{this.getToggleIntSwitch('showMedUploadRx')}</td>
                    </tr>

                    <tr>
                      <th scope="row">
                        Show Medicine Delivery in Home and Care Tab
                      </th>
                      <td>{this.getToggleIntSwitch('showMeds')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show Offline</th>
                      <td>{this.getToggleIntSwitch('showOffline')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show OPD Buying Page</th>
                      <td>{this.getToggleIntSwitch('showOpd')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show Subscription Buying Page</th>
                      <td>{this.getToggleIntSwitch('showSubscription')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show Quick Actions</th>
                      <td>{this.getToggleIntSwitch('showQuickActions')}</td>
                    </tr>

                    <tr>
                      <th scope="row">Show Symptom Checker</th>
                      <td>{this.getToggleIntSwitch('showSymChecker')}</td>
                    </tr>
                    <tr>
                      <th scope="row">Water Tracking</th>
                      <td>{this.getToggleIntSwitch('waterTracking')}</td>
                    </tr>
                    <tr>
                      <th scope="row">Ticketing Enabled</th>
                      <td>{this.getToggleIntSwitch('ticketingEnabled')}</td>
                    </tr>
                    <tr>
                      <th scope="row">Dependents get sponsor benefits</th>
                      <td>
                        {this.getToggleIntSwitch(
                          'dependentsGetSponsorBenefits'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Show Prebook</th>
                      <td>{this.getToggleIntSwitch('showPrebook')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mb-1">
              <div className="col">
                <h3 className="mt-1">Verticals Info</h3>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">ID</th>
                      <th scope="col">Rank</th>
                      <th scope="col">Vertical Name</th>
                      <th scope="col">Shown to user</th>
                      <th scope="col" width={200}>
                        Online Discount %
                      </th>
                      <th scope="col" width={200}>
                        Offline Discount %
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {/*<button onClick={() => console.log('updated verticals info', {updated: this.state.updatedVerticalsInfo}, {old: this.state.verticalsInfo})}>Log updated info state</button>*/}
                    {this.renderVerticalsInfoTable()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SponsorManagementDetails;
