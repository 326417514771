import styled from 'styled-components';

const Container = styled.div`
  ${
    '' /* padding: 3px;
    border-radius: 3px; */
  }
`;

export const StatusPendingContainer = styled(Container)`
  color: #9c9c00;
`;

export const StatusCompleteContainer = styled(Container)`
  color: #018e01;
`;

export const StatusDangerContainer = styled(Container)`
  color: #ff0000;
`;
