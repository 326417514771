import React, { Component } from 'react';
import NavBar from '../layout/NavBar';
import { withRouter } from 'react-router-dom';
import ActionDetail from './ActionDetail';
import { Row, Col } from 'reactstrap';
import {
  OuterComponent,
  BodyContainerUser,
  ActionButton,
  UserDetailContainer,
} from './StyledComponent';
class CustomerSupportUser extends Component {
  constructor(props) {
    super(props);
    console.log('UserProp', props);
    this.state = {
      showBody: true,
      searchBoxData: '',
      showActionDetail: false,
      userDetail: [],
    };
    console.log(props, 'props in customersupportuser');
    const { element } = props.history.location.state;
    if (element) {
      this.state.userDetail.push(element);
    }
  }

  stepsRequest = () => {
    this.setState({
      actionRequest: 'fit-data',
      showActionDetail: true,
    });
  };
  rewardsRequest = () => {
    this.setState({
      actionRequest: 'ft-tasks',
      showActionDetail: true,
    });
  };
  invitesRequest = () => {
    this.setState({
      actionRequest: 'ft-invites',
      showActionDetail: true,
    });
  };
  redemptionsRequest = () => {
    this.setState({
      actionRequest: 'ft-shop',
      showActionDetail: true,
    });
  };
  shareRequest = () => {
    this.setState({
      actionRequest: 'ft-share',
      showActionDetail: true,
    });
  };
  walletBalance = () => {
    this.setState({
      actionRequest: 'ft-wallet',
      showActionDetail: true,
    });
  };
  render() {
    const { history } = this.props;
    return (
      <OuterComponent>
        <NavBar />
        <BodyContainerUser>
          <ActionButton
            color="danger"
            onClick={() => history.push('/customer-support')}
          >
            Previous Page
          </ActionButton>
          <Row>
            <Col xs="4">
              <UserDetailContainer>
                <Row>
                  <Col xs="6">
                    <label>User Id:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].id}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Name:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].name}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Date of birth:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].dob}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Gender:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].gender}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Phone Number:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].phone}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Email:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].email}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>OTP Verified:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].otpVerified}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Email Verified:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].emailVerified}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Sponsor:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].sponsorName}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Platform:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].platform}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Blocked:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].blocked}</label>
                  </Col>
                </Row>
                <Row>
                  <Col xs="6">
                    <label>Join On:</label>
                  </Col>
                  <Col xs="6">
                    <label>{this.state.userDetail[0].joinOn}</label>
                  </Col>
                </Row>
                <div>
                  <ActionButton color="info" onClick={this.stepsRequest}>
                    Steps
                  </ActionButton>
                  <ActionButton color="info" onClick={this.rewardsRequest}>
                    Rewards
                  </ActionButton>
                  <ActionButton color="info" onClick={this.invitesRequest}>
                    Invites
                  </ActionButton>
                  <ActionButton color="info" onClick={this.redemptionsRequest}>
                    Redemptions
                  </ActionButton>
                  <ActionButton color="info" onClick={this.shareRequest}>
                    Share
                  </ActionButton>
                  <ActionButton color="info" onClick={this.walletBalance}>
                    Wallet Balance
                  </ActionButton>
                </div>
              </UserDetailContainer>
            </Col>
            <Col xs="8">
              {this.state.showActionDetail && (
                <ActionDetail
                  actionRequest={this.state.actionRequest}
                  userId={this.state.userDetail[0].id}
                />
              )}
            </Col>
          </Row>
        </BodyContainerUser>
      </OuterComponent>
    );
  }
}

export default withRouter(CustomerSupportUser);
