import React, {Component} from 'react';
import {connect} from 'react-redux';
import { updateVendorHc } from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import Modal from '../common/Modal';
import { MdCall } from "react-icons/md";
import {Tooltip} from "reactstrap";
import Truncate from "./Truncate";
import LoadingComponent from "../common/LoadingComponent";

class LabOrderUpdateHandlingCharge extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen:false,
            hc: 0,
            processing: false,
        };

    }

    update = () => {
        console.log(this.state.hc, 'Hc');
        if(this.state.processing) {
            return;
        }
        this.setState({ processing: true });
        console.log('trigger call');

        updateVendorHc(this.props.orderId, this.state.hc)
            .then((response) => {
                this.setState({ processing: false });
                if (response.body.message === 'success') {
                    toast.success('Vendor HC Updated');
                    this.setState({ isOpen: false });
                    this.props.loadCart();
                } else {
                    toast.error(`Unable to update Vendor HC`);
                }
            })
            .catch((err) => {
                this.setState({ processing: false });
                console.error('`Unable to update Vendor HC', err);
                toast.error(`Unable to update Vendor HC`);
            });
    };

    getModalBody = () => {
        return (
            <div className="row">
                <div className="col">
                    <p>Enter the handling charge</p>
                    <input type="number" defaultValue={this.props.hc} onChange={(e) => this.setState({hc: e.target.value})}/>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div>
                <button className="btn btn-sm btn-outline-secondary ml-1" onClick={() => this.setState({isOpen: true, hc: this.props.hc})}>Edit HC: ({this.props.hc})</button>
                <Modal
                    isOpen={this.state.isOpen}
                    header="Handling Charge"
                >
                    {this.getModalBody()}
                    <br />
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-danger btn-block" onClick={this.update}>Update</button>
                        </div>
                        <br />
                        <div className="col">
                            <button className="btn btn-danger btn-block" onClick={() => this.setState({isOpen: false})}>Close</button>
                        </div>
                    </div>
                </Modal>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(LabOrderUpdateHandlingCharge);





