import React, { Component } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';

import DoctorConsultationTable from './DoctorConsultationTable';
import DatePicker from './DatePicker';

import PayoutModal from './PayoutModal';

import PayoutLogicInfoModal from '../PayoutLogicInfoModal';
import { ButtonGroup } from 'reactstrap';

import { fetchDoctorByFilters } from '../../../services/api/online-payment';
import DownloadCsv from '../../common/DownloadCsv';
import { Input } from 'valuelink/lib/tags';
import { debounce } from '../../../utils/debounce';
import Link from 'valuelink';

const OuterComponent = styled.div`
  padding: 5px;
  padding-top: 4px;
  margin-top: 70px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const DatePickerComponent = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 15px;
`;
const SearchBox = styled.div`
  border: 1px solid;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const SerachBoxInput = styled(Input)`
  border: 0;
  flex: 2 0 0;
  margin: 1px;
  &:focus {
    outline: none;
  }
`;

const SearchBoxContent = styled.div`
  display: inline-block;
  flex: 1 0 0;
  margin: 1px;
  text-align: right;
`;

class OnlinePaymentTable extends Component {

  constructor(props) {

    super(props);

    this.debounceSearch = debounce((val) => this.fetchTableData(), 300);

    this.state = {
      page:0,
      pageSize: 25,
      pages:0,
      totalHits: 0,
      loading: false,
      doctors: [],
      // totalHits: 0,
      monthYear: '',
      doctorIdFilter: null,
      doctorNameFilter: '',
      payoutModal: {
        doctorId: null,
        isOpen: false,
      },
      requestPaymentModal: {
        doctorId: null,
        isOpen: false,
      },
    };
  }

  fetchTableData = () => {
    if(this.state.loading){
      return;
    }
    let { monthYear, page, pageSize, doctorIdFilter, doctorNameFilter } = this.state;
    const parts = monthYear.split('-');
    const year = parts[0];
    const month = parts[1].padStart(2, '0');
    monthYear = `${year}-${month}`;
    console.log(monthYear, 'monthYear asdasd');
    this.setState({ loading: true });
    const query = {
        monthYear,
        page,
        draw: pageSize,
        doctorId: doctorIdFilter,
        doctorName: doctorNameFilter === '' ? null: doctorNameFilter,
    };
    return fetchDoctorByFilters(query)
      .then((response) => {
        console.log(response.data, 'response data');
        if (response.message !== 'success') {
          throw new Error('Error in endpoint');
        }
        this.setState({
          doctors: response.data,
          pages: Math.ceil(response.totalHits / this.state.pageSize),
          totalHits: response.totalHits || 0,
        });
      })
      .catch((err) => {
        console.error(err, 'Error while fetching the data');
        throw err;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onFetchData = (state, instance) => {
    const {page, pageSize} = state;
    if(page !== null && page !== undefined && pageSize){
      this.setState(
      {
        pageSize,
        page,
      },
      this.fetchTableData);
    }
  };

  // componentDidMount() {
  //     this.onFetchData();
  // }
  setDoctorIdFilter = (event) => {
    const { value } = event.target;
    this.setState(
      {
        doctorIdFilter: value,
      },
      this.fetchTableData
    );
  }

  onSearchClick = () => {
    const { doctorNameFilter, loading } = this.state;
    if (loading) {
      return;
    }
    this.debounceSearch(doctorNameFilter);
  };
  changeDateFilter = (monthYear) => {
    if (monthYear) {
      this.setState(
        {
          monthYear,
        },
        this.fetchTableData
      );
    }
    return;
  };

  tableColumns = [
    {
      accessor: 'doctorId',
      // filterable: false,
      Header: 'Doctor Id',
      style: { whiteSpace: 'unset' },
      Filter: (props) => (
            <input
              onChange={(event) =>
                debounce(this.setDoctorIdFilter(event), 300)
              }
            />
          ),
    },
    {
      accessor: 'doctorName',
      filterable: false,
      Header: 'Name',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'verticalName',
      filterable: false,
      Header: 'Vertical',
      style: { whiteSpace: 'unset' },
    },
    {
      filterable: false,
      Header: 'Zero Payout',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return row.original.payoutStatus.zeroPayout;
      },
    },
    {
      filterable: false,
      Header: 'Half Payout',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return row.original.payoutStatus.halfPayout;
      },
    },
    {
      filterable: false,
      Header: 'Full Payout',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return row.original.payoutStatus.fullPayout;
      },
    },
    {
      filterable: false,
      Header: 'Paid',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return row.original.payoutStatus.paid;
      },
    },
    {
      filterable: false,
      Header: 'Number Of Consultations',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return row.original.numberOfConsultations;
      },
    },
    {
      filterable: false,
      Header: 'Doctor Price',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return row.original.visitPrice;
      },
    },
    {
      filterable: false,
      Header: 'Amount',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return row.original.amountToBePaid;
      },
    },
    {
      filterable: false,
      Header: 'Payment',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        const { doctorId } = row.original;
        if (row.original.isPaidForMonth) {
          return (
            <div className="text-success">Rs. {row.original.amountPaid}</div>
          );
        }
        return (
          <div
            className="btn btn-primary"
            onClick={() => this.openPayoutModal(doctorId)}
          >
            Payout
          </div>
        );
      },
    },
  ];


  openPayoutModal = (doctorId) => {
    this.setState({
      payoutModal: {
        doctorId,
        isOpen: true,
      },
    });
  };

  closePayoutModal = () => {
    this.setState(
      {
        payoutModal: {
          doctorId: null,
          isOpen: false,
        },
      },
      this.fetchTableData
    );
  };

  render() {
    const { loading, doctors, pages, totalHits} = this.state;
    const { monthYear } = this.state;
    const { payoutModal } = this.state;
    const searchLink = Link.state(this, 'doctorNameFilter');
    return (
      <React.Fragment>
        <OuterComponent>
          <DatePickerComponent>
            <DatePicker onChange={this.changeDateFilter} />
            <PayoutLogicInfoModal />
          </DatePickerComponent>

          <SearchBox>
            <SerachBoxInput
              valueLink={searchLink}
              type="text"
              placeholder="Search with Doctor Name and hit enter..."
              onKeyDown={(e) =>
                e.key === 'Enter' ? this.onSearchClick() : null
              }
            />
            <SearchBoxContent>{totalHits} records</SearchBoxContent>
          </SearchBox>
          <ButtonGroup size={'sm'}>
            <DownloadCsv
              jsonArray={doctors.map((x) => {
                const { payoutStatus } = x;
                // delete x['payoutStatus'];

                return Object.assign(
                  {},
                  {
                    doctorName: x.doctorName,
                    verticalName: x.verticalName,
                    doctorId: x.doctorId,
                    visitPrice: x.visitPrice,
                    networkCenterId: x.networkCenterId,
                    numberOfConsultations: x.numberOfConsultations,
                    amountToBePaid: x.amountToBePaid,
                  },
                  {
                    fullPayout: payoutStatus.fullPayout,
                    halfPayout: payoutStatus.halfPayout,
                    zeroPayout: payoutStatus.zeroPayout,
                    paid: payoutStatus.paid,
                  }
                );
              })}
            />
          </ButtonGroup>

          <ReactTable
            filterable
            style={{ flex: 1 }}
            data={doctors}
            collapseOnDataChange={false}
            pageSizeOptions={[25, 50, 75, 100, 150, 200, 250, 300, 500, 1000, 1500]}
            defaultPageSize={25}
            columns={this.tableColumns}
            loading={loading}
            pages={pages}
            manual
            onFetchData={this.onFetchData}
            showPaginationTop={true}
            className="-striped -highlight"
            SubComponent={(row) => (
              <DoctorConsultationTable
                doctorId={row.original.doctorId}
                monthYear={monthYear}
                refreshParent={this.onFetchData}
              />
            )}
          />
        </OuterComponent>
        <PayoutModal
          isOpen={payoutModal.isOpen}
          doctorId={payoutModal.doctorId}
          monthYear={monthYear}
          onClose={this.closePayoutModal}
        />
        {/*<RequestPaymentModal isOpen={requestPaymentModal.isOpen} doctorId={requestPaymentModal.doctorId} monthYear={monthYear} onClose={this.closeRequestPaymentModal}/>*/}
      </React.Fragment>
    );
  }
}

export default OnlinePaymentTable;
