import axios from 'axios';
import { store } from '../../store';
import config from '../../config';


const baseUrl = config.baseUrl;

const redirectForLogin = () => {
  window.location.href = '/';
 }
 
 axios.interceptors.response.use((response) => response,  (error) => {
   const statusCode = error.response.data.code;
   console.log(statusCode);
   return statusCode === 401 ?  redirectForLogin() : Promise.reject(error);
 });

export const uploadCSVApproveDocument = (file) => {
  const headers={};
  const state = store.getState();
  if (state.user.token) {
    headers.authorization = state.user.token;
  }
  const formData = new FormData();
  formData.append('file', file);

  return axios
    .post(
      `${baseUrl}/new-ops/payment/uploadCsvFile/doctorApproval`,
      formData,
      {
        headers,
      }
    )
    .then((resp) => {
      const responseBody = resp;
      if (responseBody.data.message) {
        return responseBody.data;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        // showError(err);
        throw err;
      }
    })
    .catch((err) => {
      //   showError(err);
      throw err;
    });
};