import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {changePartnerForCart, markCartReady} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import {setSelectedPartnerForNewTest} from "../../actions";
class ConfirmAddingManualPartner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('Confirm adding manual partner');
        console.log(prevProps, prevState);
        if (prevProps.partnerId !== this.props.partnerId) {
            this.toggle();
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    save = () => {
        console.log(this.props.cartId, this.props.partnerId, 'New partner selected');
        // Change the partner in the cart
        changePartnerForCart(this.props.cartId, this.props.partnerId)
        .then((response) => {
            if (response.body.message === 'success') {
                toast.success(`Partner Changed`);
                this.props.loadCart();
                this.props.dispatch(setSelectedPartnerForNewTest(this.props.partnerId));
                this.toggle();
            } else {
                toast.error(`Unable to update partner`);
            }
        })
        .catch((err) => {
            console.error('Error in changing partner', err);
            toast.error(`Unable to update partner`);
        });
    };
    render() {
        return (
            <div>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <h4 className="mt-1 mb-0 ml-2">Confirmation</h4>
                    <ModalBody>
                        <p>Are you sure you want to change to a manual partner? Since this is a manual order, you will need to add MRP/cost of each test in this cart and this partner will ONLY be show to this user's cart. It will not show to other users</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>Save</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = state => ({ cartItemsResult: state.labsDashboard.cartItemsResult });

export default connect(
    mapStateToProps,
)(ConfirmAddingManualPartner);





