import React from 'react';
import Modal from '../common/Modal';
import { Link } from 'valuelink';
import { toast } from 'react-toastify';

import { Input, Select } from 'valuelink/lib/tags';
import {LoadingContainer} from "../offline-consultations/table-subcomponent/StyledComponents";

import { fetchLocationByLatLng, submitCenterDetailsForPartner, fetchCenterDetailsByCenterId } from '../../services/api/lab-partners';

class AddEditCenterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lat: '',
            lng: '',
            locality: '',
            city: '',
            state: '',
            pincode: '',
            address: '',
            loading: false,
            fetchLocationLoading: false,
            collectionAvailable: false,
            collectionCharges: 0.0,
            collectionRadius: 1,
            bookingMedium: 'email',
            bookingEmail: '',
            bookingPhone: '',
            escalationMedium: 'email',
            escalationEmail: '',
            escalationPhone: '',
        };
    }

    getLocationByLatLng = () => {
        const { lat, lng } = this.state;
        if (this.getFetchLocationDisabled()) {
            return;
        }
        this.setState({
            fetchLocationByLatLng: true,
        });
        return fetchLocationByLatLng(lat, lng)
            .then((response) => {
                const body = response.body;
                console.log(body, 'Response from reverse geo code');
                const result = body.result;
                if (body.message === 'success') {
                    this.setState({
                        locality: result.locality,
                        city: result.city,
                        state: result.state,
                        pincode: result.pincode,
                        address: result.formatted_address,
                    });
                }
            })
            .catch((err) => {
                console.error(err, lat, lng, 'Error while fetching location by lat lng');
                toast.error('Error while fetching location');
            })
            .finally(() => {
                this.setState({
                    fetchLocationByLatLng: false,
                });
            });
    };

    onClose = () => {
        this.props.onClose();
    };

    onOpen = () => {
        const { editModal, partnerId, centerId } = this.props;
        this.setState({
            lat: '',
            lng: '',
            locality: '',
            city: '',
            state: '',
            pincode: '',
            address: '',
            collectionAvailable: false,
            collectionCharges: 0.0,
            collectionRadius: 0.0,
            bookingMedium: 'email',
            bookingEmail: '',
            bookingPhone: '',
            escalationMedium: 'email',
            escalationEmail: '',
            escalationPhone: '',
        });
        if (!editModal) {
            return;
        }
        if (isNaN(partnerId) || partnerId === null || !centerId) {
            toast.error('Couldn\'t find the center details');
            return;
        }
        return fetchCenterDetailsByCenterId(partnerId, centerId)
            .then((response) => {
                const body = response.body;
                const result = body.result;
                if (body.message === 'success') {
                    this.setState({
                        lat: result.lat,
                        lng: result.lng,
                        locality: result.locality,
                        city: result.city,
                        state: result.state,
                        pincode: result.pincode,
                        address: result.address,
                        collectionAvailable: result.collectionAvailable,
                        collectionCharges: result.collectionCharges,
                        collectionRadius: result.collectionRadius,
                        bookingMedium: result.bookingMedium || 'email',
                        bookingEmail: result.bookingEmail || '',
                        bookingPhone: result.bookingPhone || '',
                        escalationMedium: result.escalationMedium || 'email',
                        escalationEmail: result.escalationEmail || '',
                        escalationPhone: result.escalationPhone || '',
                    });
                    return;
                }
                throw new Error('Couldn\'t find the center details');
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, editModal, partnerId, centerId, "Error while fetching the details for the center");
            });

    };

    onSubmit = () => {
        const { lat, lng, locality, city, state, pincode, address, collectionAvailable, collectionCharges, collectionRadius, bookingMedium, bookingEmail, bookingPhone, escalationMedium, escalationEmail, escalationPhone } = this.state;
        const { centerId, editModal, partnerId } = this.props;
        if (editModal && (!centerId)) {
            toast.error('Error while editing the details of center');
            return;
        }

        if (!lat || !lng || !locality || !city || !state || !pincode || !address || !bookingMedium || !escalationMedium) {
            toast.error('Please fill up of all the details');
            return;
        }

        if (collectionAvailable && (!collectionCharges || !collectionRadius)) {
            toast.error('Please enter collection details');
            return;
        }

        if (this.getIsBookingMediumEmail()) {
            if (!bookingEmail) {
                toast.error('Booking email is required');
                return;
            }
        } else {
            if (!bookingPhone) {
                toast.error('Booking phone is required');
                return;
            }
        }

        if (this.getIsEscalationMediumEmail()) {
            if (!escalationEmail) {
                toast.error('Escalation email is required');
                return;
            }
        } else {
            if (!escalationPhone) {
                toast.error('Escalation Phone is required');
            }
        }

        const body = {
            lat,
            lng,
            locality,
            city,
            state,
            pincode,
            address,
            centerId,
            collectionAvailable,
            collectionCharges: (collectionAvailable) ? (collectionCharges) : 0,
            collectionRadius: (collectionAvailable) ? (collectionRadius) : 0,
            bookingMedium,
            bookingEmail: (this.getIsBookingMediumEmail()) ? (bookingEmail) : null,
            bookingPhone: (this.getIsBookingMediumEmail()) ? null : bookingPhone,
            escalationMedium,
            escalationEmail: (this.getIsEscalationMediumEmail()) ? (escalationEmail) : null,
            escalationPhone: (this.getIsEscalationMediumEmail()) ? null : escalationPhone,
        };

        this.setState({
            loading: false,
        });
        return submitCenterDetailsForPartner(partnerId, body)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    toast.success(`Successfully ${(editModal) ? 'updated' : 'added'} the center.`);
                    this.onClose();
                    return;
                }
                throw new Error(body.errorMessage || 'Couldn\'t persist the details');
            })
            .catch((err) => {
                console.error(err, partnerId, body, 'Error while submitting details');
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    getFetchLocationDisabled = () => {
        const { lat, lng } = this.state;
        return !(lat && lng);
    };

    getShowCollectionAvailableOptions = () => {
        return this.state.collectionAvailable;
    };

    handleCollectionAvailableOptionsChange = () => {
        this.setState({
            collectionAvailable: !(this.state.collectionAvailable),
        });
    };

    getIsBookingMediumEmail = () => {
        return (this.state.bookingMedium === 'email');
    };

    getIsEscalationMediumEmail = () => {
        return (this.state.escalationMedium === 'email');
    };


    render() {
        const { editModal, isOpen } = this.props;
        const latLink = Link.state(this, 'lat');
        const longLink = Link.state(this, 'lng');
        const cityLink = Link.state(this, 'city');
        const stateLink = Link.state(this, 'state');
        const localityLink = Link.state(this, 'locality');
        const pincodeLink = Link.state(this, 'pincode');
        const addressLink = Link.state(this, 'address');
        const collectionChargesLink = Link.state(this, 'collectionCharges');
        const collectionRadiusLink = Link.state(this, 'collectionRadius');
        const { loading, fetchLocationLoading } = this.state;

        const bookingMediumLink = Link.state(this, 'bookingMedium');
        const bookingEmailLink = Link.state(this, 'bookingEmail');
        const bookingPhoneLink = Link.state(this, 'bookingPhone');
        const isBookingMediumEmail = this.getIsBookingMediumEmail();

        const escalationMediumLink = Link.state(this, 'escalationMedium');
        const escalationEmailLink = Link.state(this, 'escalationEmail');
        const escalationPhoneLink = Link.state(this, 'escalationPhone');
        const isEscalationMediumEmail = this.getIsEscalationMediumEmail();

        const isDisabled = this.getFetchLocationDisabled();
        const collectionAvailable = this.getShowCollectionAvailableOptions();

        return (
            <Modal
                header={(editModal) ? 'Edit Center' : 'Add Center'}
                isOpen={isOpen}
                onOpen={this.onOpen}
                onClose={this.onClose}
            >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    Latitude
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={latLink} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    Longitude
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={longLink} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 mt-1 mb-1">
                            {(fetchLocationLoading) ? (
                                <LoadingContainer/>
                            ) : (
                                <button className="btn btn-primary btn-block" onClick={this.getLocationByLatLng} disabled={isDisabled}>Fetch Location</button>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">Locality</div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={localityLink} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">City</div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={cityLink} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">State</div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={stateLink} />
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="row">
                                <div className="col">Pincode</div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" valueLink={pincodeLink} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">Address (To be shown to user)</div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Input className="form-control" maxlength={100} valueLink={addressLink} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">Booking Medium</div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Select className="form-control" valueLink={bookingMediumLink}>
                                        <option value="email">Email</option>
                                        <option value="phone">Phone</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {(isBookingMediumEmail) ? (
                            <div className="col">
                                <div className="row">
                                    <div className="col">SPOC Booking Email</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={bookingEmailLink} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col">
                                <div className="row">
                                    <div className="col">SPOC Booking Phone</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={bookingPhoneLink} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">Escalation Medium</div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <Select className="form-control" valueLink={escalationMediumLink}>
                                        <option value="email">Email</option>
                                        <option value="phone">Phone</option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                        {(isEscalationMediumEmail) ? (
                            <div className="col">
                                <div className="row">
                                    <div className="col">Partner Escalation Email</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={escalationEmailLink} />
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col">
                                <div className="row">
                                    <div className="col">Partner Escalation Phone</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={escalationPhoneLink} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col">
                                    <div className="form-check">
                                        <input type="checkbox" className="form-check-input" checked={collectionAvailable} onChange={this.handleCollectionAvailableOptionsChange} />
                                        <label className="form-check-label">Is home collection available?</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(collectionAvailable) && (
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">Collection Charges</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={collectionChargesLink} />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col">Collection Radius (in Km)</div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={collectionRadiusLink} type="number" min={1} max={100} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <br />
                    <div className="row">
                        <div className="col">
                            {(loading) ? (
                                <LoadingContainer />
                            ) : (
                                <button className="btn btn-success btn-block" onClick={this.onSubmit}>Submit</button>
                            )}
                        </div>
                        <div className="col">
                            <button className="btn btn-danger btn-block" onClick={this.onClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default AddEditCenterModal;
