import React, { Component } from 'react';
import ReactTable from 'react-table';
import Select from 'react-select';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { toast } from 'react-toastify';
import { UploadOutlined } from '@ant-design/icons';
import {  Col, Button as AntButton } from 'antd';
import {
  Button,
  Card,
  CardTitle,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
  Table,
  CardHeader,
  CardBody,
  Input as StrapInput
} from 'reactstrap';
import Link from 'valuelink';
import { Input } from 'valuelink/lib/tags';
import { FaUpload } from 'react-icons/fa';

import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import {
  fetchPaymentRequestById,
  fetchPaymentRequests,
} from '../../services/api/fetchPaymentRequest';
import {
  autoPayoutRequest,
  uploadFiles,
  markAppointmentCreditReady,
  takeActionOnPaymentRequest,
} from '../../services/api/paymentApproval';
import { debounce } from '../../utils/debounce';
import { uploadCSVApproveDocument } from '../../services/api/doctorApprovalCsvUpload';

const paymentTypeOptions = [
  { value: 'normal', label: 'Normal' },
  { value: 'credit-ch', label: 'Credit - Credihealth' },
  { value: 'credit-mb', label: 'Credit - Medibuddy' },
  {
    value: 'credit-visit',
    label: 'Credit - Visit Onboarded',
  },
];

const payoutModeOptions = [
  { value: 'auto', label: 'Auto payout' },
  { value: 'hdfc-upi', label: 'HDC UPI' },
  { value: 'hdfc-nb', label: 'HDFC Netbanking' },
  { value: 'hdfc-debit', label: 'HDFC Debit Card' },
  { value: 'aniket-paytm', label: 'Aniket Paytm' },
  { value: 'manual-cashfree', label: 'Manual Cashfree' },
  { value: 'cortis', label: 'Cortis' },
];

const getLabelForPaymentType = paymentType => {
  let paymentTypeString = paymentType;
  paymentTypeOptions.forEach(obj => {
    if (obj.value === paymentType) {
      paymentTypeString = obj.label;
    }
  });
  return paymentTypeString;
};

const getLabelForPayoutMode = payoutMode => {
  let payoutModeString = payoutMode;
  payoutModeOptions.forEach(obj => {
    if (obj.value === payoutMode) {
      payoutModeString = obj.label;
    }
  });
  return payoutModeString;
};

export default class PaymentRequestsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      requestSearchInput: '',
      finalPayoutInput: '',
      remarksInput: '',
      paymentTypeSelect: '',
      payoutModeSelect: '',
      sendComms:true,
      startDateFilter: null,
      endDateFilter: null,
      approvalStatusFilter: [],
      paymentRequests: [],
      paymentRequestsTablePages: -1,
      paymentRequestsLoading: true,
      paymentRequestsError: null,
      paginationToken: null,
      isDetailsModalOpen: false,
      datePickerFocusInput: null,
      paymentModalDetails: null,
      paymentModalActiveTab: '',
      showConfirmPayoutModal: false,
      approvingRequest: false,
      showApproveRequestModal: false,
      showDeclineRequestModal: false,
      showCreditReadyModal: false,
      payoutProcessing: false,
      openReceipt: -1,
      uploading: false,
      uploadProgress: 0,
      bulkApproveCSVFile:null,
      csvFileErrorModalOpen:false,
      csvFileErrors:null,
    };
    this.paymentRequestsTableRef = React.createRef();
    this.debounceFetchData = debounce(() => {
      this.fetchStrategy(
        (this.paymentRequestsTableRef.current || {}).state || {}
      );
    }, 800);
  }

  tableColumns = [
    {
      Header: 'Appointment Details',
      columns: [
        {
          Header: 'Request Id',
          // accessor: 'requestId',
          sortable: false,
          // filterable: true,
          minWidth: 100,
          // Filter: ReactTableInputFilter,
          Cell: row => {
            return (
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.viewPaymentRequestDetails(row.original)}
              >
                {row.original.requestId}
              </button>
            );
          },
        },
        {
          Header: 'Appointment Date',
          id: 'appointmentDate',
          accessor: row =>
            moment(row.appointmentDate).format('DD-MM-YYYY HH:mm'),
          minWidth: 125,
        },
        {
          Header: 'Patient Name',
          accessor: 'patientName',
          sortable: false,
          minWidth: 175,
        },
        {
          Header: 'Sponsor',
          accessor: 'sponsorName',
          sortable: false,
          minWidth: 125,
        },
        {
          Header: 'Doctor Name',
          accessor: 'doctorName',
          sortable: false,
          minWidth: 175,
        },
        {
          Header: 'Center Name',
          accessor: 'centerName',
          sortable: false,
          minWidth: 175,
        },
      ],
    },
    {
      Header: 'Payment Details',
      columns: [
        {
          Header: 'Request By',
          accessor: 'opsUserName',
          sortable: false,
          minWidth: 100,
        },
        {
          Header: 'Final Charge',
          accessor: 'finalCharge',
          sortable: false,
          minWidth: 75,
        },
        {
          Header: 'Payment Mode',
          accessor: 'paymentMode',
          sortable: false,
          minWidth: 75,
        },
        {
          Header: 'Approval Status',
          sortable: false,
          // accessor: 'paymentMode',
          id: 'requestStatus',
          minWidth: 125,
          Cell: row => {
            if (row.original.requestStatus === 'Processing') {
              return (
                <h4>
                  <span className="badge badge-warning">Processing</span>
                </h4>
              );
            }
            switch (row.original.requestStatus) {
              case 'Pending':
                return (
                  <h4>
                    <span className="badge badge-secondary">Pending</span>
                  </h4>
                );
              case 'Approved':
                return (
                  <h4>
                    <span className="badge badge-success">Approved</span>
                  </h4>
                );
              case 'Declined':
                return (
                  <h4>
                    <span className="badge badge-danger">Declined</span>
                  </h4>
                );
              default:
                return (
                  <h4>
                    <span className="badge badge-secondary">Pending</span>
                  </h4>
                );
            }
          },
        },
        // {
        //   Header: 'Actions',
        //   sortable: false,
        //   minWidth: 100,
        //   Cell: row => {
        //     return (
        //       <button
        //         type="button"
        //         className="btn btn-primary"
        //         onClick={this.viewPaymentRequestDetails(row.original)}
        //       >
        //         View Details
        //       </button>
        //     );
        //   },
        // },
      ],
    },
  ];

  viewPaymentRequestDetails = payoutRequest => () => {
    console.log(payoutRequest);
    this.setState(
      {
        isDetailsModalOpen: true,
        paymentModalDetails: payoutRequest,
        paymentModalActiveTab: 'basicDetail',
        finalPayoutInput: payoutRequest.finalCharge || '',
        remarksInput: payoutRequest.remarks || '',
        paymentTypeSelect: payoutRequest.paymentType,
        payoutModeSelect: payoutRequest.overallPayoutMode,
      },
      () => {
        this.fetchPaymentRequestDetails();
      }
    );
  };

  handleChange = (e)=>{
    this.setState({
      bulkApproveCSVFile:e.target.files[0]
    })
  }

  handleUploadCsvFile=()=>{

      const { bulkApproveCSVFile } = this.state;
      return uploadCSVApproveDocument(bulkApproveCSVFile)
        .then((resInfo) => {
          if(resInfo.message=="success"){
            this.setState({
              bulkApproveCSVFile:null
            })
            toast.success('Approved and Mark Credited Successfully!', {
              autoClose: 3000,
            });
          }
          if(resInfo.message=="Request Payout not done for these users."){
            this.setState({
              csvFileErrorModalOpen:true,
              csvFileErrors:resInfo
            })
          }
          if(resInfo.message=="Payment Cycle is not 30 days for these users."){
            this.setState({
              csvFileErrorModalOpen:true,
              csvFileErrors:resInfo
            })
          }
          
        })
        .catch((err) => {
          // response.onError(err);
        });
  }

  fetchStrategy = state => {
    const { pageSize, sorted, filtered } = state;
    console.log(pageSize, sorted, filtered);
    // Get the filter value for payout status
    const approvalStatusSelected = Array.isArray(filtered)
      ? (filtered.find(ele => ele.id === 'requestStatus') || {}).value
      : undefined;
    const dateSort = Array.isArray(sorted)
      ? sorted.find(ele => ele.id === 'appointmentDate')
        ? 'requestDate'
        : undefined
      : undefined;
    const dateSortOrder = Array.isArray(sorted)
      ? sorted.find(ele => ele.id === 'appointmentDate' || {}).desc
        ? 'desc'
        : 'asc'
      : undefined;
    const {
      paginationToken,
      requestSearchInput,
      startDateFilter,
      endDateFilter,
    } = this.state;
    this.setState({
      paymentRequests: [],
      paymentRequestsLoading: true,
    });
    fetchPaymentRequests(
      paginationToken,
      pageSize,
      requestSearchInput,
      startDateFilter && startDateFilter.format('YYYY-MM-DD'),
      endDateFilter && endDateFilter.format('YYYY-MM-DD'),
      approvalStatusSelected === 'all' ? undefined : approvalStatusSelected,
      dateSort,
      dateSortOrder
    )
      .then(response => {
        console.log('all payment requests', response);
        if (response && response.body.message === 'success') {
          const responseData = response.body.data;
          this.setState({
            paymentRequests: responseData.result,
            paginationToken: responseData.pageInfo.pageInfo,
            paymentRequestsTablePages:
              Number.parseInt(responseData.totalRows / pageSize) +
              (responseData.totalRows % pageSize ? 1 : 0),
          });
        } else {
          console.log(response, 'Error fetching all payment requests');
          throw new Error('Error fetching all payment requests');
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({
          paymentRequestsError:
            err.errorMessage || 'Something went wrong. Please try again later',
        });
      })
      .finally(() => {
        this.setState({ paymentRequestsLoading: false });
      });
  };

  onDateFilterChanged = ({ startDate, endDate }) => {
    console.log(startDate, endDate);
    this.setState(
      {
        startDateFilter: startDate,
        endDateFilter: endDate,
        paginationToken: null,
      },
      () => {
        this.fetchStrategy(
          (this.paymentRequestsTableRef.current || {}).state || {}
        );
      }
    );
  };

  fetchPaymentRequestDetails = () => {
    const { paymentModalDetails } = this.state;
    fetchPaymentRequestById(paymentModalDetails.paymentRequestId)
      .then(res => {
        if (res.body.message === 'success') {
          this.setState({
            paymentModalDetails: res.body.data.paymentRequest,
            finalPayoutInput: res.body.data.paymentRequest.finalCharge || '',
            remarksInput: res.body.data.paymentRequest.remarks || '',
            paymentTypeSelect: res.body.data.paymentRequest.paymentType,
            payoutModeSelect: res.body.data.paymentRequest.overallPayoutMode,
          });
        }
      })
      .catch(err => {
        console.log(err, 'Error in fetching payment by request id');
      });
  };

  autoPayout = () => {
    const { finalPayoutInput, paymentModalDetails } = this.state;
    if (paymentModalDetails.requestId && !paymentModalDetails.payoutDate) {
      this.setState({
        payoutProcessing: true,
      });
      autoPayoutRequest(
        paymentModalDetails.paymentRequestId,
        paymentModalDetails.requestId,
        finalPayoutInput,
        false
      )
        .then(response => {
          if (response.body.message === 'success') {
            if (response.body.data.pending) {
              // Transaction is processing
              toast.warning(
                'Payout under PROCESS! The request will be updated once the payout is completed',
                {
                  autoClose: 3000,
                }
              );
            } else {
              toast.success('Payout successful!', {
                autoClose: 3000,
              });
            }
          } else {
            toast.error(
              response.body.data.errorMessage || 'Oh oh! Something went wrong.',
              {
                autoClose: 3000,
              }
            );
          }
        })
        .catch(err => {
          console.log(err);
          toast.error(
            err.response.data.errorMessage || 'Oh oh! Something went wrong.',
            {
              autoClose: 3000,
            }
          );
        })
        .finally(() => {
          this.fetchPaymentRequestDetails();
          this.setState({
            showConfirmPayoutModal: false,
            payoutProcessing: false,
          });
        });
    }
  };

  uploadInvoice = payoutTransactionId => event => {
    const { paymentModalDetails } = this.state;
    event.stopPropagation();
    event.preventDefault();
    if (paymentModalDetails.paymentRequestId && event.target.files[0]) {
      this.setState({ uploading: true });
      return uploadFiles(
        'invoice',
        paymentModalDetails.paymentRequestId,
        null,
        event.target.files[0],
        progress => {
          if (progress.loaded) {
            if (progress.total) {
              this.setState({
                uploadProgress: (progress.loaded / progress.total) * 100,
              });
            } else {
              this.setState({
                uploadProgress: 100,
              });
            }
          }
        }
      )
        .then(response => {
          console.log(response.fileurl);
          this.fetchPaymentRequestDetails();
        })
        .catch(err => {
          console.log(err);
          toast.error(
            err.errorMessage || 'Something went wrong. Please try again later'
          );
        })
        .finally(() => {
          this.setState({
            uploading: false,
          });
        });
    }
  };

  uploadReceipt = payoutTransactionId => event => {
    const { paymentModalDetails } = this.state;
    event.stopPropagation();
    event.preventDefault();
    if (paymentModalDetails.paymentRequestId && event.target.files[0]) {
      this.setState({ uploading: true });
      return uploadFiles(
        'receipt',
        paymentModalDetails.paymentRequestId,
        payoutTransactionId,
        event.target.files[0],
        progress => {
          if (progress.loaded) {
            if (progress.total) {
              this.setState({
                uploadProgress: (progress.loaded / progress.total) * 100,
              });
            } else {
              this.setState({
                uploadProgress: 100,
              });
            }
          }
        }
      )
        .then(response => {
          console.log(response.fileurl);
          this.fetchPaymentRequestDetails();
        })
        .catch(err => {
          console.log(err);
          toast.error(err.errorMessage || 'Something went wrong');
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    }
  };

  approveRequest = () => {
    const {
      finalPayoutInput,
      remarksInput,
      paymentTypeSelect,
      payoutModeSelect,
      paymentModalDetails,
      sendComms
    } = this.state;
    console.log('send comms',sendComms,paymentTypeSelect);
    this.setState({ approvingRequest: true });
    takeActionOnPaymentRequest(
      'Approved',
      finalPayoutInput,
      paymentTypeSelect,
      remarksInput,
      false,
      paymentModalDetails.paymentRequestId,
      payoutModeSelect,
      sendComms
    )
      .then(response => {
        toast.success('Request approved successfully!');
      })
      .catch(err => {
        console.log(err);
        toast.error(
          `Error approving payment request- ${err.errorMessage ||
            'Something went wrong'}`
        );
      })
      .finally(() => {
        this.setState({
          approvingRequest: false,
          showApproveRequestModal: false,
        });
        this.fetchPaymentRequestDetails();
      });
  };

  declineRequest = () => {
    const {
      finalPayoutInput,
      remarksInput,
      paymentTypeSelect,
      payoutModeSelect,
      paymentModalDetails,
    } = this.state;
    this.setState({ approvingRequest: true });
    takeActionOnPaymentRequest(
      'Declined',
      finalPayoutInput,
      paymentTypeSelect,
      remarksInput,
      false,
      paymentModalDetails.paymentRequestId,
      payoutModeSelect
    )
      .then(response => {
        toast.success('Request declined successfully!');
      })
      .catch(err => {
        console.log(err);
        toast.error(
          `Error declining payment request- ${err.errorMessage ||
            'Something went wrong'}`
        );
      })
      .finally(() => {
        this.setState({
          approvingRequest: false,
          showDeclineRequestModal: false,
        });
        this.fetchPaymentRequestDetails();
      });
  };

  markCreditReady = () => {
    const { paymentModalDetails } = this.state;
    if (paymentModalDetails && paymentModalDetails.paymentRequestId) {
      this.setState({ approvingRequest: true });
      markAppointmentCreditReady(paymentModalDetails.paymentRequestId)
        .then(response => {
          if (response.body.message === 'success') {
            toast.success('Request marked credit ready!');
          }
        })
        .catch(err => {
          console.log(err);
          toast.error(
            `Error marking credit ready- ${err.errorMessage ||
              'Something went wrong'}`
          );
        })
        .finally(() => {
          this.setState({
            approvingRequest: false,
            showCreditReadyModal: false,
          });
          this.fetchPaymentRequestDetails();
        });
    }
  };

  render() {
    const {
      requestSearchInput,
      startDateFilter,
      endDateFilter,
      finalPayoutInput,
      sendComms,
      approvalStatusFilter,
      paymentRequests,
      paymentRequestsTablePages,
      paymentRequestsLoading,
      paymentRequestsError,
      datePickerFocusInput,
      isDetailsModalOpen,
      csvFileErrorModalOpen,
      paymentModalActiveTab,
      paymentModalDetails,
      payoutProcessing,
      paymentTypeSelect,
      payoutModeSelect,
      approvingRequest,
      showConfirmPayoutModal,
      showApproveRequestModal,
      showCreditReadyModal,
      showDeclineRequestModal,
      openReceipt,
      uploading,
      uploadProgress,
      csvFileErrors
    } = this.state;
    const requestSearchInputLink = Link.state(
      this,
      'requestSearchInput'
    ).onChange(() => {
      this.debounceFetchData();
    });
    const finalPayoutInputLink = Link.state(this, 'finalPayoutInput');
    const remarksInputLink = Link.state(this, 'remarksInput');
    const prettyPrintPayoutStatus = (status, requestStatus) => {
      if (requestStatus === 'Processing') {
        return (
          <h4>
            <span className="badge badge-warning">Processing</span>
          </h4>
        );
      }
      switch (status) {
        case 0:
          return (
            <h4>
              <span className="badge badge-secondary">Pending</span>
            </h4>
          );
        case 1:
          return (
            <h4>
              <span className="badge badge-success">Completed</span>
            </h4>
          );
        case -1:
          return (
            <h4>
              <span className="badge badge-danger">Failed</span>
            </h4>
          );
        default:
          return (
            <h4>
              <span className="badge badge-secondary">Pending</span>
            </h4>
          );
      }
    };
    const renderPaymentDetails = () => {
      if (!paymentModalDetails) {
        return 'No payment details found';
      }
      return (
        <>
          <dl className="row mt-3">
            <dt className="col-6 col-md-3">Payee Name</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.payeeName}`}</dd>
            <dt className="col-6 col-md-3">Account Number</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.accountNumber ||
              'NA'}`}</dd>
            <dt className="col-6 col-md-3">Account Type</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.accountType ||
              'NA'}`}</dd>
            <dt className="col-6 col-md-3">IFSC Code</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.ifscCode ||
              'NA'}`}</dd>
            <dt className="col-6 col-md-3">Paytm Phone</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.upiPhone ||
              'NA'}`}</dd>
            <dt className="col-6 col-md-3">UPI</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.upiAddress ||
              'NA'}`}</dd>
            <dt className="col-6 col-md-3">Preferred Payout Mode</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.paymentMode}`}</dd>
            <dt className="col-6 col-md-3">Payment Cycle</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.paymentCycle}`}</dd>
            <dt className="col-6 col-md-3">Doctor Charges</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.doctorCharge}`}</dd>
            <dt className="col-6 col-md-3">Expected Final Payout</dt>
            <dd className="col-6 col-md-3">{`${paymentModalDetails.finalCharge}`}</dd>
            <dt className="col-6 col-md-3">Payout Status</dt>
            <dd className="col-6 col-md-3">
              {prettyPrintPayoutStatus(
                paymentModalDetails.overallPayoutStatus,
                paymentModalDetails.requestStatus
              )}
            </dd>
          </dl>
          <div className="row mt-3">
            <div className="col-xs-12 col-md-6">
              <div className="form-group row align-items-center">
                <label
                  htmlFor="finalPayout"
                  className="col-sm-4 col-form-label"
                >
                  Final Payout
                </label>
                <div className="col-sm-8">
                  <Input
                    style={{ width: '100%' }}
                    className="form-control"
                    id="finalPayout"
                    placeholder="Final Payout"
                    valueLink={finalPayoutInputLink}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="form-group row align-items-center">
                <label htmlFor="remarks" className="col-sm-4 col-form-label">
                  Remarks
                </label>
                <div className="col-sm-8">
                  <Input
                    style={{ width: '100%' }}
                    className="form-control"
                    id="remarks"
                    placeholder="Remarks"
                    valueLink={remarksInputLink}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="form-group row align-items-center">
                <label htmlFor="dateFilter" className="col-sm-4 col-form-label">
                  Payment Type
                </label>
                <div className="col-sm-8">
                  <Select
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        width: '100%',
                        'z-index': 1000,
                      }),
                    }}
                    placeholder="Payment Type"
                    value={{
                      value: paymentTypeSelect,
                      label: getLabelForPaymentType(paymentTypeSelect),
                    }}
                    onChange={(ele, action) => {
                      if (
                        action.action === 'select-option' ||
                        action.action === 'set-value'
                      ) {
                        this.setState({
                          paymentTypeSelect: ele.value,
                        });
                      }
                    }}
                    options={paymentTypeOptions}
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="form-group row align-items-center">
                <label htmlFor="dateFilter" className="col-sm-4 col-form-label">
                  Payout Mode
                </label>
                <div className="col-sm-8">
                  <Select
                    styles={{
                      container: (provided, state) => ({
                        ...provided,
                        width: '100%',
                        'z-index': 1000,
                      }),
                    }}
                    placeholder="Payout Mode"
                    value={{
                      value: payoutModeSelect,
                      label: getLabelForPayoutMode(payoutModeSelect),
                    }}
                    onChange={(ele, action) => {
                      if (
                        action.action === 'select-option' ||
                        action.action === 'set-value'
                      ) {
                        this.setState({
                          payoutModeSelect: ele.value,
                        });
                      }
                    }}
                    options={payoutModeOptions}
                  />
                </div>
              </div>
            </div>   
            <div className="col-xs-12 col-md-8">
              <div className="form-group row align-items-center">
             
                <label htmlFor="dateFilter" className="col-sm-4 col-form-label">
                 Send Communication
                </label>
                <div className="col-sm-8">
              
                <StrapInput
                    type="checkbox"
                    id="checkbox"
                    onChange={(e)=>{
                      console.log(e.target.checked,'checkbox response for comms')
                      this.setState({
                        sendComms: e.target.checked,
                      });
                    }}
                    checked={sendComms}
                  />
                </div>
              </div>
            </div>         
          </div>
        </>
      );
    };
    const renderPayoutHistory = () => {
      if (!paymentModalDetails.payoutDate) {
        return (
          <div className="container">
            <div className="row text-center">
              <div className="col">No payout made yet</div>
            </div>
          </div>
        );
      }
      console.log(paymentModalDetails.payoutDate.split(';;'));
      return (
        <Table striped responsive>
          <thead>
            <tr>
              <th>Payout by</th>
              <th>Payout mode</th>
              <th>Payout date</th>
              <th>Payout amount</th>
              <th>UTR</th>
              <th>Acknowledged</th>
              <th>Payout status</th>
            </tr>
          </thead>
          <tbody>
            {paymentModalDetails.payoutDate.split(';;').map((ele, idx) => {
              const transferResponse = paymentModalDetails.transferResponse.split(
                ';;'
              )[idx];
              let utr = 'NA';
              let acknowledged = 'NA';
              try {
                const parsedResponse = JSON.parse(transferResponse);
                utr = parsedResponse.data.utr;
                acknowledged = parsedResponse.data.acknowledged;
              } catch (err) {
                console.log(err);
              }
              return (
                <tr>
                  <td>{paymentModalDetails.payoutBy.split(';;')[idx]}</td>
                  <td>{paymentModalDetails.payoutMode.split(';;')[idx]}</td>
                  <td>{ele}</td>
                  <td>{paymentModalDetails.payoutAmount.split(';;')[idx]}</td>
                  <td>{utr}</td>
                  <td>{acknowledged}</td>
                  <td>{paymentModalDetails.payoutStatus.split(';;')[idx]}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      );
    };
    const renderReceiptsAndInvoices = () => {
      return (
        <div className="row">
          <div className="col-12">
            {uploading && (
              <Progress
                className="mt-2 mb-2"
                animated
                striped
                value={uploadProgress}
              />
            )}
            <Card>
              <CardHeader>
                <p className="h4 m-0">Invoices</p>
              </CardHeader>
              <CardBody>
                <p className="text-secondary m-0">
                  Invoices are marked against a payment request. They are not
                  marked against a transaction
                </p>
                {paymentModalDetails.invoice ? (
                  <div className="row align-items-center">
                    {paymentModalDetails.invoice.split(',').map((ele, idx) => {
                      return (
                        <a
                          key={idx}
                          href={ele}
                          target="_blank"
                          type="button"
                          className="btn btn-link"
                          role="button"
                        >{`Invoice ${idx + 1}`}</a>
                      );
                    })}
                    <button
                      type="button"
                      className="btn btn-link"
                      role="button"
                      onClick={() => {
                        document.querySelector('#invoice-file').click();
                      }}
                    >
                      <input
                        accept="image/*,pdf"
                        className="d-none"
                        id="invoice-file"
                        onChange={this.uploadInvoice()}
                        type="file"
                      />
                      <FaUpload />
                    </button>
                  </div>
                ) : (
                  <>
                    <span className="font-weight-light">
                      No invoices uploaded yet
                    </span>
                    <button
                      type="button"
                      className="btn btn-link"
                      role="button"
                      onClick={() => {
                        document.querySelector('#invoice-file').click();
                      }}
                    >
                      <input
                        accept="image/*,pdf"
                        className="d-none"
                        id="invoice-file"
                        onChange={this.uploadInvoice()}
                        type="file"
                      />
                      <FaUpload />
                    </button>
                  </>
                )}
              </CardBody>
            </Card>
          </div>
          <div className="col-12 mt-3">
            <p className="h4">Payment Receipts</p>
            {paymentModalDetails.payoutDate ? (
              paymentModalDetails.payoutDate.split(';;').map((ele, idx) => {
                const isShown = idx === openReceipt;
                return (
                  <Card className="h-auto" key={idx}>
                    <button
                      type="button"
                      className="btn btn-link p-0 text-left"
                      onClick={() => {
                        this.setState({
                          openReceipt: idx,
                        });
                      }}
                    >
                      <CardHeader>
                        {`₹${
                          paymentModalDetails.payoutAmount.split(';;')[idx]
                        } by ${paymentModalDetails.payoutBy.split(';;')[idx]}`}
                      </CardHeader>
                    </button>
                    <Collapse isOpen={isShown}>
                      <CardBody>
                        {paymentModalDetails.receipt &&
                        paymentModalDetails.receipt.split(';;')[idx] ? (
                          <div className="row align-items-center">
                            {paymentModalDetails.receipt
                              .split(';;')
                              [idx].split(',')
                              .map((ele, index) => {
                                return (
                                  <a
                                    key={index}
                                    href={ele}
                                    target="_blank"
                                    type="button"
                                    className="btn btn-link"
                                    role="button"
                                  >{`Receipt ${index + 1}`}</a>
                                );
                              })}
                            <button
                              type="button"
                              className="btn btn-link"
                              role="button"
                              onClick={() => {
                                document
                                  .querySelector(`#receipt-file-${idx}`)
                                  .click();
                              }}
                            >
                              <input
                                accept="image/*,pdf"
                                className="d-none"
                                id={`receipt-file-${idx}`}
                                onChange={this.uploadReceipt(
                                  paymentModalDetails.payoutTransactionId.split(
                                    ';;'
                                  )[idx]
                                )}
                                type="file"
                              />
                              <FaUpload />
                            </button>
                          </div>
                        ) : (
                          <>
                            <span className="font-weight-light">
                              No receipts uploaded yet
                            </span>
                            <button
                              type="button"
                              className="btn btn-link"
                              role="button"
                              onClick={() => {
                                document
                                  .querySelector(`#receipt-file-${idx}`)
                                  .click();
                              }}
                            >
                              <input
                                accept="image/*,pdf"
                                className="d-none"
                                id={`receipt-file-${idx}`}
                                onChange={this.uploadReceipt(
                                  paymentModalDetails.payoutTransactionId.split(
                                    ';;'
                                  )[idx]
                                )}
                                type="file"
                              />
                              <FaUpload />
                            </button>
                          </>
                        )}
                      </CardBody>
                    </Collapse>
                  </Card>
                );
              })
            ) : (
              <span className="font-weight-light text-center">
                No successful payouts made yet.
              </span>
            )}
          </div>
        </div>
      );
    };
    const renderPaymentDetailActiveTab = () => {
      switch (paymentModalActiveTab) {
        case 'basicDetail':
          return renderPaymentDetails();
        case 'payoutHistory':
          return renderPayoutHistory();
        case 'receipt':
          return renderReceiptsAndInvoices();
        default:
          return renderPaymentDetails();
      }
    };
    const renderConfirmPayoutModal = () => {
      return (
        <Modal isOpen={showConfirmPayoutModal} size="sm">
          <ModalHeader className="text-body">Confirm Payout</ModalHeader>
          <ModalBody>
            {`Are you sure you want to make a payout of ${finalPayoutInput} to ${paymentModalDetails.payeeName}?`}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={payoutProcessing}
              onClick={this.autoPayout}
            >
              Yes
            </Button>
            <Button
              color="danger"
              onClick={() => {
                this.setState({
                  showConfirmPayoutModal: false,
                });
              }}
              disabled={payoutProcessing}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      );
    };
    const renderApproveRequestModal = () => {
      return (
        <Modal isOpen={showApproveRequestModal} size="sm">
          <ModalHeader className="text-body">Approve Request</ModalHeader>
          <ModalBody>
            {`Are you sure you want to approve this request?`}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={approvingRequest}
              onClick={this.approveRequest}
            >
              Yes
            </Button>
            <Button
              color="danger"
              onClick={() => {
                this.setState({
                  showApproveRequestModal: false,
                });
              }}
              disabled={approvingRequest}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      );
    };
    const renderDeclineRequestModal = () => {
      return (
        <Modal isOpen={showDeclineRequestModal} size="sm">
          <ModalHeader className="text-body">Decline Request</ModalHeader>
          <ModalBody>
            {`Are you sure you want to decline this request?`}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={approvingRequest}
              onClick={this.declineRequest}
            >
              Yes
            </Button>
            <Button
              color="danger"
              onClick={() => {
                this.setState({
                  showDeclineRequestModal: false,
                });
              }}
              disabled={approvingRequest}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      );
    };
    const renderMarkCreditReadyModal = () => {
      return (
        <Modal isOpen={showCreditReadyModal} size="sm">
          <ModalHeader className="text-body">Mark Credit Ready</ModalHeader>
          <ModalBody>
            {`Are you sure you want to mark this request as credit ready?`}
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={approvingRequest}
              onClick={this.markCreditReady}
            >
              Yes
            </Button>
            <Button
              color="danger"
              onClick={() => {
                this.setState({
                  showCreditReadyModal: false,
                });
              }}
              disabled={approvingRequest}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      );
    };
    return (
      <div className="container-fluid" style={{ marginTop: '60px' }}>
        <div className="row align-items-center">
          <div className="col-12 mt-2 mb-2">
            <h3>Payment Requests</h3>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="form-group row align-items-center">
              <label
                htmlFor="requestSearch"
                className="col-sm-4 col-form-label"
              >
                Search by doctor/user/request ID
              </label>
              <div className="col-sm-8">
                <Input
                  style={{ width: '90%' }}
                  className="form-control"
                  id="requestSearch"
                  placeholder="Type something to search"
                  valueLink={requestSearchInputLink}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="form-group row align-items-center">
              <label htmlFor="dateFilter" className="col-sm-3 col-form-label">
                Filter by date
              </label>
              <div className="col-sm-9">
                <DateRangePicker
                  startDate={startDateFilter}
                  startDateId="startDateFilter"
                  endDate={endDateFilter}
                  endDateId="endDateFilter"
                  onDatesChange={this.onDateFilterChanged}
                  small={true}
                  focusedInput={datePickerFocusInput}
                  onFocusChange={(focusedInput) =>
                    this.setState({
                      datePickerFocusInput: focusedInput,
                    })
                  }
                  isOutsideRange={(date) => {
                    if (moment().isBefore(date)) {
                      return true;
                    }
                    return false;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="form-group row align-items-center">
              <label htmlFor="dateFilter" className="col-sm-3 col-form-label">
                Filter by approval status
              </label>
              <div className="col-sm-9">
                <Select
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      width: '100%',
                      'z-index': 1000,
                    }),
                  }}
                  placeholder="Filter Approval Status"
                  defaultValue={{
                    value: 'pending',
                    label: 'Pending',
                  }}
                  onChange={(ele, action) => {
                    if (
                      action.action === 'select-option' ||
                      action.action === 'set-value'
                    ) {
                      this.setState({
                        paginationToken: null,
                        approvalStatusFilter: [
                          {
                            id: 'requestStatus',
                            value: ele.value,
                          },
                        ],
                      });
                    }
                  }}
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'pending', label: 'Pending' },
                    { value: 'processing', label: 'Processing' },
                    { value: 'approved', label: 'Approved' },
                    { value: 'declined', label: 'Declined' },
                  ]}
                />
              </div>
            </div>
            <div>
              Upload doctors list (only csv files)
              <div style={{ display: 'flex' }}>
                <input
                  type="file"
                  placeholder="Upload Bulk Approve CSV File"
                  md={4}
                  accept=".csv"
                  onChange={(event) => this.handleChange(event)}
                />
                <AntButton onClick={this.handleUploadCsvFile}>
                  <UploadOutlined />
                  Upload
                </AntButton>
              </div>
            </div>
          </div>
          <div className="col-12 mt-2">
            <ReactTable
              ref={this.paymentRequestsTableRef}
              keyField="paymentRequestId"
              data={paymentRequests}
              columns={this.tableColumns}
              defaultPageSize={20}
              pages={paymentRequestsTablePages}
              pageSize={20}
              defaultFiltered={[
                {
                  id: 'payoutStatus',
                  value: 'pending',
                },
              ]}
              filtered={approvalStatusFilter}
              defaultSorted={[
                {
                  id: 'appointmentDate',
                  desc: true,
                },
              ]}
              // page={this.state.page}
              loading={paymentRequestsLoading}
              noDataText={paymentRequestsError || 'No data found'}
              // filtered={this.state.filtered}
              // sorted={this.state.sorted}
              manual
              onFetchData={this.fetchStrategy}
              getTheadFilterThProps={() => ({
                style: {
                  overflow: 'visible',
                },
              })}
              // onFilteredChange={this.onFilteredChange}
              // onPageSizeChange={pageSize => this.setState({ pageSize })}
              // onPageChange={page => this.setState({ page })}
              // onSortedChange={sorted => this.setState({ sorted })}
              className="-striped -highlight text-center"
              showPaginationTop
              showPaginationBottom
            />
          </div>
        </div>
        <Modal isOpen={isDetailsModalOpen} size="lg">
          <ModalHeader className="text-body">
            {`Payout Details for request ID ${
              paymentModalDetails ? paymentModalDetails.requestId : ''
            }(${paymentModalDetails ? paymentModalDetails.requestStatus : ''})`}
          </ModalHeader>
          <ModalBody style={{ overflow: 'scroll' }}>
            <ul className="nav nav-pills">
              <li className="nav-item">
                <button
                  className={`btn btn-link nav-link ${
                    paymentModalActiveTab === 'basicDetail' ||
                    paymentModalActiveTab === ''
                      ? 'active'
                      : ''
                  }`}
                  type="button"
                  onClick={() => {
                    this.setState({
                      paymentModalActiveTab: 'basicDetail',
                    });
                  }}
                >
                  Details
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`btn btn-link nav-link ${
                    paymentModalActiveTab === 'payoutHistory' ? 'active' : ''
                  }`}
                  type="button"
                  id="receiptTab"
                  onClick={() => {
                    this.setState({
                      paymentModalActiveTab: 'payoutHistory',
                    });
                  }}
                >
                  Payout History
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={`btn btn-link nav-link ${
                    paymentModalActiveTab === 'receipt' ? 'active' : ''
                  }`}
                  type="button"
                  onClick={() => {
                    this.setState({
                      paymentModalActiveTab: 'receipt',
                    });
                  }}
                >
                  Receipts and Invoices
                </button>
              </li>
            </ul>
            {renderPaymentDetailActiveTab()}
          </ModalBody>
          <ModalFooter>
            <button
              onClick={() => {
                this.setState({
                  isDetailsModalOpen: false,
                  openReceipt: -1,
                });
              }}
              type="button"
              className="btn btn-link"
            >
              Close
            </button>
            {paymentModalDetails &&
              paymentModalDetails.overallPayoutStatus !== 1 &&
              paymentModalDetails.requestStatus === 'Pending' &&
              paymentModalDetails.allowPayout && (
                <button
                  type="button"
                  className="btn"
                  onClick={() => {
                    this.setState({
                      showConfirmPayoutModal: true,
                    });
                  }}
                  style={{
                    background: 'linear-gradient(45deg, #4ff44f, #4ff4a2)',
                  }}
                >
                  Payout
                </button>
              )}
            {paymentModalDetails &&
              paymentModalDetails.requestStatus === 'Approved' &&
              !paymentModalDetails.creditReadyBy && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.setState({
                      showCreditReadyModal: true,
                    });
                  }}
                >
                  Mark Credit Ready
                </button>
              )}
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                this.setState({
                  showApproveRequestModal: true,
                });
              }}
            >
              Approve
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                this.setState({
                  showDeclineRequestModal: true,
                });
              }}
            >
              Decline
            </button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={csvFileErrorModalOpen} size="lg">
          <ModalHeader className="text-body">
            Error while Uploading CSV
          </ModalHeader>
          <ModalBody style={{ overflow: 'scroll' }}>
            <div style={{marginBottom:"20px"}}>{csvFileErrors && csvFileErrors.message} Please Check and try again for remaining users.</div>
            <ol className="nav" type="1" style={{display:"block"}} >
              {csvFileErrors &&
                csvFileErrors.ids.length > 0 &&
                csvFileErrors.ids.map((item, index) => <li key={index+item.id}>Request Id : {item.id}</li>)}
            </ol>
          </ModalBody>
          <ModalFooter>
            <button
              onClick={() => {
                this.setState({
                  csvFileErrorModalOpen: false,
                });
              }}
              type="button"
              className="btn btn-link"
            >
              Close
            </button>
          </ModalFooter>
        </Modal>
        {showConfirmPayoutModal && renderConfirmPayoutModal()}
        {showApproveRequestModal && renderApproveRequestModal()}
        {showDeclineRequestModal && renderDeclineRequestModal()}
        {showCreditReadyModal && renderMarkCreditReadyModal()}
      </div>
    );
  }
}
