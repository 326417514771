import React, {Component} from 'react';
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import NavBar from "../layout/NavBar";
import 'react-dates/initialize';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { uploadPartnerTnx } from "../../services/api/labs-reconcile-api";
import {toast} from "react-toastify";

class LabsUploadPartnerTnx extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tableName: '',
        };
    }

    onFileInputChanged = (event) => {
        console.log(event.target.files[0]);
        this.uploadFile(event.target.files[0]);
    };

    uploadFile = (file) => {
        uploadPartnerTnx(file, this.state.tableName, '1')
            .then((result) => {
                console.log('Result from uploading the receipt', result);
                if (result.body.message === 'success') {
                    toast.success(`Partner Tnx uploaded`);
                } else {
                    toast.error(`Error in Saving comment`);
                }
            })
            .catch((err) => {
                console.error('Error in uploading receipt', err);
                toast.error(`Error in uploading receipt`);
            });
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <NavBar />
                </div>
                <div className="row mt-5 pt-1">
                    <div className="col">
                        <h2 className="pl-1">Upload Partner Transactions CSV File</h2>
                    </div>
                </div>
                <div className="row">
                    <button className="btn btn-primary" onClick={() => {this.props.history.push('/labs-reconcile/reconciled-tnx')}}>Goto Reconciliation</button>
                    <div className="col">
                        <input type="file" className="form-control-file" onChange={this.onFileInputChanged} placeholder="Upload"/>
                    </div>
                </div>

            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default withRouter(connect(
    mapStateToProps,
)(LabsUploadPartnerTnx));





