import styled from 'styled-components';

export const HistoryItem = styled.div`
  border: 1px #0000003b solid;
  padding: 3px;
  padding-left: 5px;
  border-radius: 3px;
  border-top: 0;
  overflow: auto;
  :hover {
    background-color: #f7f7f7;
    font-size: 17px;
  }
`;

export const StyledHistoryContainer = styled.div`
    border: 1px #0000003b solid;
    padding: 3px;
    border-radius: 3px;
    min-height: 200px;
    overflow: auto;
    width: 100%;
`;

export const StyledHistoryContainerForPharmacy = styled.div`
    border: 1px #0000003b solid;
    padding: 3px;
    border-radius: 3px;
    min-height: 200px;
    overflow: auto;
    width: 50%;
`;

export const TimeContainer = styled.div`
  flex-grow: 1;
  font-size: x-small;
  float: right;
  padding-top: 5px;
  padding-right: 5px;
`;
