import React from 'react';
import { toast } from 'react-toastify';

import Modal from '../common/Modal';

import LoadingComponent from "../common/LoadingComponent";

import { toggleActiveInactivePartnerTest } from '../../services/api/lab-partners';

class ToggleActiveInactiveTestsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    handleConfirm = () => {
        const { partnerId, active, testMapId } = this.props;
        if (isNaN(partnerId) || (partnerId === null) || isNaN(testMapId) || (!testMapId)) {
            console.log(partnerId, testMapId, active, 'While submitting toggle');
            return;
        }
        this.setState({
            loading: true,
        });
        return toggleActiveInactivePartnerTest(partnerId, testMapId, active)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    toast.success('Successfully changed the status');
                    this.props.onClose();
                    return;
                }
                throw new Error(body.errorMessage || 'Couldn\'t change the Active/Inactive');
            })
            .catch((err) => {
                toast.error(err.message);
                console.error(err, 'Error toggling the status');
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        const { isOpen, onClose, active } = this.props;
        const { loading } = this.state;
        return (
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                header={`Are you sure you want to mark test as ${(active) ? ('Active') : ('Inactive')}?`}
            >
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {(loading) ? (
                                <LoadingComponent/>
                            ) : (
                                <button className="btn btn-success btn-block" onClick={this.handleConfirm}>Yes</button>
                            )}
                        </div>
                        <div className="col">
                            <button className="btn btn-danger btn-block" onClick={onClose}>No</button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ToggleActiveInactiveTestsModal;
