import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { claimVaccinationRequest } from '../../../services/api/cashless-vaccination';


const VaccinationClaimModal = ({
    isOpen,
    onClose,
    requestId,
    onComplete,
    className = 'bg-info',
}) => {

    const [loading, setLoading] = useState(false)

    const onSubmit = () => {
        setLoading(true)
        claimVaccinationRequest(requestId)
        .then((response) => {
            if (response.message === 'success') {
                toast.success('Successfully claimed request')
                return
            }
            throw new Error('Error while claiming request')
        })
        .catch((err) => {
            toast.error('Error while claiming request')
        })
        .finally(() => {
            setLoading(false)
            onClose()
            onComplete()
        })
    }

    return <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader className={className} toggle={onClose}>
            Claim Request ({requestId})
        </ModalHeader>
        <ModalBody>Are you sure want to claim the request?</ModalBody>
        <ModalFooter>
            <Button color="primary" onClick={onSubmit} disabled={loading}>
                {loading ? 'Loading...' : 'Claim'}
            </Button>
            {!loading && (
                <Button color="secondary" onClick={onClose} disabled={loading}>
                    Cancel
                </Button>
            )}
        </ModalFooter>
    </Modal>
}

export default VaccinationClaimModal