import React, { Component } from 'react';
import {
  UserDetailContainer,
  UserPersonalDetails,
  UserAppDetails,
  ActionButton,
  OuterContainer,
} from './StyledComponent';
import { Row, Col, ListGroup, ListGroupItem, Badge } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { FaEdit } from 'react-icons/fa';
import EmailEditModal from './EmailEditModal';
import EmailResendModal from './EmailResendModal';
import AddEmployeeCodeModal from './AddEmployeeCodeModal';
import Modal from '../common/Modal';

import { getUserLink } from '../../services/api/tickets';
import { markUserAsVip, removeUserAsVip } from '../../services/api/customer-support';
import { applyZomatoBenefits } from '../../services/api/customer-support';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

class UserDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showActionButton: props.userDetail.length === 1 ? true : false,
      showZomatoModal: false,
      loadingZomatoBenefits: true,
      zomatoBenefitsApplied: false,
      zomatoBenefitsFailed: false,
      zomatoBenefitsFailedError: '',
      showFeIdInput: false,
      searchUsingFeId: false,
      zomatoFeIdInput: '',
    };
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.userDetail.length !== this.props.userDetail.length) {
      this.setState({
        showActionButton: this.props.userDetail.length === 1 ? true : false,
      });
    }
  };

  componentDidMount() {
    // console.log('map', this.props.logedUser);
  }

  coiDetails = () => {
    if (this.props.userDetail[0].coiUrl === 'Not Generated') {
      return <label>Not Generated</label>;
    } else {
      return (
        <label>
          <a href={this.props.userDetail[0].coiUrl} target="_blank">
            Link
          </a>
        </label>
      );
    }
  };

  getTagColor(tag) {
    switch (tag) {
      case 'important': {
        return 'warning';
      }
      case 'SOS': {
        return 'danger';
      }
      case 'spam': {
        return 'dark';
      }
      default: {
        return 'info';
      }
    }
  }

  getConcernName(concern) {
    switch (concern) {
      case 'pay-com': {
        return 'Payment - Complaint';
      }
      case 'pay-req': {
        return 'Payment - Request';
      }
      case 'con-com': {
        return 'Appointment - Complaint';
      }
      case 'con-req': {
        return 'Appointment - Request';
      }
      case 'labs-com': {
        return 'Labs - Complaint';
      }
      case 'labs-req': {
        return 'Labs - Request';
      }
      case 'red-com': {
        return 'Redemptions - Complaint';
      }
      case 'red-req': {
        return 'Redemptions - Request';
      }
      case 'app-com': {
        return 'Application - Complaint';
      }
      case 'app-req': {
        return 'Application - Request';
      }
      default: {
        return 'Other';
      }
    }
  }

  checkUserDetail = () => {
    if (!this.props.userDetail) {
      return;
    } else if (this.props.userDetail.length === 1) {
      return (
        <div className="container">
          <Row style={{ borderBottom: 'solid #d0d0d0 1px' }}>
            <Col md={{ size: 4 }}>
              <h4>
                <Badge color={'dark'} pill>
                  {this.props.userDetail[0].id}
                </Badge>
              </h4>
            </Col>
            <Col md={{ size: 6 }}>
              {this.props.userDetail[0].tag ? (
                <h5>
                  <Badge
                    onClick={this.props.userActions}
                    color={this.getTagColor(this.props.userDetail[0].tag)}
                  >
                    {this.props.userDetail[0].tag}
                  </Badge>
                </h5>
              ) : (
                ''
              )}
              {this.props.userDetail[0].concern ? (
                <h5>
                  <Badge
                    onClick={this.props.userActions}
                    color={this.getTagColor(this.props.userDetail[0].concern)}
                  >
                    {this.getConcernName(this.props.userDetail[0].concern)}
                  </Badge>
                </h5>
              ) : (
                ''
              )}
            </Col>
            <Col md={{ size: 2 }}>
              <FaEdit
                onClick={this.props.userActions}
                style={{ cursor: 'pointer' }}
              />
              {this.props.userDetail[0].emailVerified && (
                <EmailResendModal userId={this.props.userDetail[0].id} />
              )}
            </Col>
          </Row>
          <label>
            <b>Personal Details</b>
          </label>
          <div className="container">
            <Row>
              <Col xs="4">
                <label>Name:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].name}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>DOB:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].dob}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Gender:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].gender}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Phone:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].phone}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Email:</label>
              </Col>
              <Col xs="4">
                <label>{this.props.userDetail[0].email}</label>
                {this.props.userDetail[0].showChangeEmail && (
                  <EmailEditModal userId={this.props.userDetail[0].id} />
                )}
              </Col>
            </Row>
            {this.props.userDetail[0].addEmployeeCode ? (
              <Row>
                <Col xs="4">
                  <label>Employee Code:</label>
                </Col>
                <Col xs="8">
                  <AddEmployeeCodeModal userId={this.props.userDetail[0].id} />
                </Col>
              </Row>
            ) : (
              <React.Fragment />
            )}
          </div>
          <label>
            <b>Application Details</b>
          </label>
          <div className="container">
            <Row>
              <Col xs="4">
                <label>VIP User:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].isVipUser}
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ marginLeft: '1rem'}}
                  onClick={this.markOrRemoveUserAsVIP}>
                  {this.props.userDetail[0].isVipUser === 'Yes' ? 'Remove VIP': 'Mark VIP' }
                </button>
                </label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>OTP Verified:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].otpVerified}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Email Verified:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].emailVerified}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Sponsor:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].sponsorName}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Platform:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].platform}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Blocked:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].blocked}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Join On:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].joinOn}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Step Goal:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].stepGoal}</label>
              </Col>
            </Row>
            {this.props.userDetail[0].isIbmExit === 1 && (
              <Row>
                <Col xs="4">
                  <label>IBM Exit:</label>
                </Col>
                <Col xs="8">
                  <label>Yes</label>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs="4">
                <label>GMC IPD Policy:</label>
              </Col>
              <Col xs="8">
                <label>{this.props.userDetail[0].gmcActivated}</label>
              </Col>
            </Row>
          </div>

          <label>
            <b>OPD Details</b>
          </label>
          <div className="container">
            <Row>
              <Col xs="4">
                <label>Bank Details Available</label>
              </Col>
              <Col xs="8">
                {this.props.userDetail[0].userBankAccount ? (
                  <span className="badge badge-success">Yes</span>
                ) : (
                  <span className="badge badge-danger">No</span>
                )}
              </Col>
            </Row>
            {this.props.userDetail[0].userBankAccount ? (
              <>
                <Row>
                  <Col xs="4">
                    <label>Bank Account Number(Last 4 digits)</label>
                  </Col>
                  <Col xs="8">{this.props.userDetail[0].userBankAccount}</Col>
                </Row>
                <Row>
                  <Col xs="4">
                    <label>IFSC Code</label>
                  </Col>
                  <Col xs="8">{this.props.userDetail[0].userIfscCode}</Col>
                </Row>
              </>
            ) : (
              false
            )}
            {this.props.userDetail[0].addedBy ? (
              <>
              <Row>
                <Col xs="4">
                  <label>Account Details Added By</label>
                </Col>
                <Col xs="8">{this.props.userDetail[0].addedBy}</Col>
              </Row>
              </>
            ):false}
            {this.props.userDetail[0].detailsUpdatedAt ? (
              <>
              <Row>
                <Col xs="4">
                  <label>Account Details Added By</label>
                </Col>
                <Col xs="8">{moment(this.props.userDetail[0].detailsUpdatedAt).format('DD/MM/YYYY HH:mm')}</Col>
              </Row>
              </>
            ):false}
          </div>
        </div>
      );
    } else {
      // console.log(this.props.userDetail.length);3
      const elements = [];
      this.props.userDetail.forEach((element) => {
        // console.log(element, 'element');
        elements.push(
          <UserDetailContainer>
            <Row>
              <Col xs="4">
                <label>Name:</label>
              </Col>
              <Col xs="8">
                <label>{element.name}</label>
              </Col>
            </Row>
            <Row>
              <Col xs="4">
                <label>Phone Number:</label>
              </Col>
              <Col xs="8">
                <label>{element.phone}</label>
              </Col>
            </Row>
            <ActionButton
              color="primary"
              onClick={() => this.showDetails(element)}
            >
              Details
            </ActionButton>
          </UserDetailContainer>
        );
      });
      return elements;
    }
  };

  showDetails = (element) => {
    this.props.setUserDetailsParent(element);
    // this.props.dispatch(setUserDetails(resp.result));
    // this.props.history.push('/customer-support-user', { element });
  };

  markOrRemoveUserAsVIP = () => {
    const isVipUser = (this.props.userDetail[0].isVipUser === 'Yes');
    console.log(isVipUser ? 'Removing' : 'Marking', 'User as VIP');

    const modifyUserVipStatusPromise = isVipUser ?
      removeUserAsVip({ userId: this.props.userDetail[0].id }) :
      markUserAsVip({ userId: this.props.userDetail[0].id});

    return modifyUserVipStatusPromise
      .then((response) => {
        if(response.message === 'success'){
          toast.success(`User successfully ${isVipUser ? 'removed' : 'marked'} as VIP`);
        } else {
          toast.error(`Action Failed`);
        }
        this.props.getUpdatedDetails();
    })
  }

  checkForZomatoBenefits = () => {
    const { zomatoFeIdInput } = this.state;
    if (!this.props.userDetail) {
      return;
    } else if (this.props.userDetail.length === 1) {
      const userId = this.props.userDetail[0].id;
      const phone = this.props.userDetail[0].phone;
      this.setState({
        showZomatoModal: true,
        loadingZomatoBenefits: true,
        zomatoBenefitsFailed: false,
      });
      if (zomatoFeIdInput) {
        this.setState({
          searchUsingFeId: true,
        });
      }
      applyZomatoBenefits(userId, phone, zomatoFeIdInput)
        .then((res) => {
          if (res.message === 'success') {
            // Benefits applied successfully
            this.setState(
              {
                zomatoBenefitsApplied: true,
              },
              () => {
                setTimeout(() => {
                  this.onZomatoModalClosed();
                }, 2500);
              }
            );
          } else {
            // Benefits could not be applied
            if (res.policyNotFound && zomatoFeIdInput.trim() === '') {
              // Attempt to ask the user to input the FE ID
              this.setState({
                showFeIdInput: true,
                loadingZomatoBenefits: false,
              });
              return;
            }
            // There was a fatal error, show an error message
            this.setState({
              zomatoBenefitsFailed: true,
              zomatoBenefitsFailedError: res.errorMessage,
            });
          }
        })
        .catch((err) => {
          this.setState({
            zomatoBenefitsFailed: true,
            zomatoBenefitsFailedError: err.errorMessage,
          });
        })
        .finally(() => {
          this.setState({
            loadingZomatoBenefits: false,
          });
        });
    }
  };

  onZomatoModalClosed = () => {
    this.setState({
      showZomatoModal: false,
      loadingZomatoBenefits: true,
      zomatoBenefitsApplied: false,
      zomatoBenefitsFailed: false,
      zomatoBenefitsFailedError: '',
      showFeIdInput: false,
      zomatoFeIdInput: '',
      searchUsingFeId: false,
    });
  };

  render() {
    const {
      showZomatoModal,
      zomatoBenefitsApplied,
      loadingZomatoBenefits,
      showFeIdInput,
      zomatoFeIdInput,
      zomatoBenefitsFailed,
      zomatoBenefitsFailedError,
      searchUsingFeId,
    } = this.state;
    return (
      <OuterContainer>
        {this.checkUserDetail()}
        {this.state.showActionButton && (
          <div className="container">
            <div className="row">
              <div className="col-12 mt-2 mb-2">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.checkForZomatoBenefits}
                >
                  Check for Zomato benefits
                </button>
              </div>
              <div className="col-6">
                <ListGroup>
                  <ListGroupItem tag="button" onClick={this.props.stepsRequest}>
                    Steps
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.rewardsRequest}
                  >
                    Rewards
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.invitesRequest}
                  >
                    Invites
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.redemptionsRequest}
                  >
                    Redemptions
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.reimbursementClaims}
                  >
                    Reimbursement Claims
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.fulfilmentDetails}
                  >
                    Edit Bank Details
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.abhiReimbursementStatus}
                  >
                    ABHI RBS Status
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.policyDetails}
                  >
                    OPD Policy Details
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.authPlanDetails}
                  >
                    Auth Plan Details
                  </ListGroupItem>
                  {/* <ListGroupItem
                    tag="button"
                    onClick={this.props.walletDetails}
                  >
                    OPD Wallet Details
                  </ListGroupItem> */}
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.akzoReimbursementStatus}
                  >
                    Akzo RBS Status
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.shlReimbursementStatus}
                  >
                    SHL RBS Status
                  </ListGroupItem>
                </ListGroup>
              </div>
              <div className="col-6">
                <ListGroup>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.offlineConsultations}
                  >
                    Appointments
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.walletBalance}
                  >
                    Wallet Balance
                  </ListGroupItem>
                  <ListGroupItem tag="button" onClick={this.props.shareRequest}>
                    Share Rewards
                  </ListGroupItem>
                  <ListGroupItem tag="button" onClick={this.props.supportNotes}>
                    Support Notes
                  </ListGroupItem>
                  <ListGroupItem
                    tag="button"
                    onClick={this.props.gmcPolicyDetails}
                  >
                    GMC Policies Details
                  </ListGroupItem>
                  <ListGroupItem tag="button" onClick={this.props.gmcLink}>
                    Link GMC Account
                  </ListGroupItem>
                  <a
                    href={getUserLink(this.props.userDetail[0].id)}
                    target="_blank"
                  >
                    <ListGroupItem
                      className="w-100"
                      tag="button"
                      onClick={this.props.gmcLink}
                    >
                      Tickets
                    </ListGroupItem>
                  </a>
                  {this.props.logedUser.editUsersDetails === 1 ? (
                    <ListGroupItem
                      tag="button"
                      onClick={this.props.editUserDetails}
                    >
                      Edit User Details
                    </ListGroupItem>
                  ) : (
                    ''
                  )}
                </ListGroup>
              </div>
            </div>
          </div>
        )}
        <Modal
          isOpen={showZomatoModal}
          onClose={this.onZomatoModalClosed}
          header={
            <div className="row justify-content-between p-3 pt-0 pb-0">
              <h3>Apply Zomato Benefits</h3>
            </div>
          }
        >
          <div className="row">
            <div className="col-12">
              {loadingZomatoBenefits && (
                <div className="alert alert-primary" role="alert">
                  Checking for zomato benefits
                </div>
              )}
              {zomatoBenefitsApplied && (
                <div className="alert alert-success" role="alert">
                  Zomato Benefits Applied Successfully
                </div>
              )}
              {zomatoBenefitsFailed && (
                <div className="alert alert-danger" role="alert">
                  Failed to apply benefits - {zomatoBenefitsFailedError}{' '}
                  {searchUsingFeId
                    ? 'We searched by both phone and FE ID and are unable to find a valid policy'
                    : ''}
                </div>
              )}
            </div>
            <div className="col-12">
              {showFeIdInput && (
                <div className="form-group">
                  <label for="zomato-fe-id">
                    Unable to find zomato policy on phone number. Enter Zomato
                    FE ID to search again
                  </label>
                  <input
                    type="email"
                    className="form-control mb-3"
                    id="zomato-fe-id"
                    placeholder="FE007"
                    value={zomatoFeIdInput}
                    onChange={(ev) => {
                      this.setState({
                        zomatoFeIdInput: ev.target.value,
                      });
                    }}
                  ></input>
                  <button
                    className="btn btn-primary"
                    disabled={
                      loadingZomatoBenefits ||
                      zomatoFeIdInput.trim().length === 0 ||
                      zomatoBenefitsApplied
                    }
                    onClick={this.checkForZomatoBenefits}
                  >
                    Check for benefits
                  </button>
                </div>
              )}
            </div>

            <div className="col-12">
              <button
                className="btn btn-secondary"
                disabled={loadingZomatoBenefits}
                onClick={this.onZomatoModalClosed}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  userDetail: state.customerSupport.userDetail,
  logedUser: state.user,
  customerSupport: state.customerSupport,
});

export default connect(mapStateToProps, withRouter)(UserDetail);
