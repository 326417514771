import React, { Component } from 'react';
import NavBar from '../layout/NavBar';
import ResultModal from '../common/modal/ResultModal';
import {
  OuterComponent,
  HeadingContainer
} from './StyledComponent';
import ReactTable from 'react-table';
import PoolsAddEdit from './PoolsAddEdit';
import { Button } from 'reactstrap';
import {
  getPools,
  getSponsors,
  getPoolSponsors,
  fetchAllSponsor,
  submitChangeMySponsor,
  changeProductPoolActivity,
  fetchAllProducts,
  deletePool, replicatePool
} from '../../services/api/redemptions';
import AddProductPool from './AddProductPool';
import AddSponsorPool from './AddSponsorPool';
import AddPoolCategory from './AddPoolCategory';
import { MdDelete } from 'react-icons/md';
import { IoMdCreate, IoMdAddCircle, IoIosListBox } from 'react-icons/io';
import { MdGroupAdd } from 'react-icons/md';
import ProductPoolTable from './ProductPoolTable';

import Modal from '../common/Modal';
import ModalConfirm from "../common/ModalConfirm";

import { toast } from 'react-toastify';

import Select from 'react-select';
import { searchFilterMethod } from '../../utils/react-table';

class Pools extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editPool: true,
      showModal: false,
      showPoolAddEdit: false,
      showAddProductPool: false,
      showAddSponsorPool: false,
      editProductPool: false,
      showAddPoolCategory: false,
      productFilterOptions:[],
      sponsorFilterOptions:[],
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      data: [],
      editData: [],
      dataEditProductPool: [],
      sponsorOptions: [],
      productOptions: [],
      sponsors: [],
      sponsorsData: [],
      poolId: '',
      changeMySponsorModal: {
        isOpen: false,
        sponsorId: null,
      },
      allSponsor: [],
      confirmModal: {
        isOpen: false,
        message: '',
        onConfirm: () => {},
        onClose: () => {},
      },
      products: [],
      productFilter: '',
      sponsorFilter: '',
      replicatePoolModalOpen: false,
      newPoolName: '',
      poolReplicateOptions: [],
      poolReplicateSelect: null,
    };
  }
  tableColumns = [
    {
      accessor: 'id',
      numeric: false,
      sortable: true,
      disablePadding: false,
      Header: 'Id',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'pool_name',
      sortable: true,
      numeric: false,
      disablePadding: false,
      Header: 'Pool Name',
      style: { whiteSpace: 'unset' },
      filterable: true,
      filterMethod: searchFilterMethod,
    },
    {
      accessor: 'edit',
      numeric: false,
      disablePadding: false,
      sortable: true,
      Header: 'Edit',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        return (
          <div>
            <IoMdCreate
              className="icon-style text-primary"
              id={`editProductButton`}
              style={{ fontSize: 45 }}
              onClick={() => {
                this.handleEditPool(row);
              }}
            />
            <IoIosListBox className="icon-style text-primary" style={{ fontSize: 45 }} onClick={() => (this.handleEditCategories(row))} />
            <MdDelete
              className="icon-style text-danger"
              id={`editProductButton`}
              style={{ fontSize: 45 }}
              onClick={() => {
                this.handleDeleteProduct(row);
              }}
            />
          </div>
        );
      },
    },
    {
      accessor: 'add sponsor',
      numeric: false,
      disablePadding: false,
      sortable: true,
      Header: 'Total Sponsor',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        const totalSponsor = row.original.total_sponsor;
        return (
          <div>
            {totalSponsor}
            <MdGroupAdd
              className="icon-style text-primary"
              id={`editProductButton`}
              style={{ fontSize: 45, marginLeft: 10 }}
              onClick={() => {
                this.handleAddSponsorPool(row);
              }}
            />
          </div>
        );
      },
    },
    {
      accessor: 'Total Product',
      numeric: false,
      disablePadding: false,
      sortable: true,
      Header: 'Total Product',
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        const totalProduct = row.original.total_products;
        return (
          <div>
            {totalProduct}
            <IoMdAddCircle
              className="icon-style text-primary"
              id={`editProductButton`}
              style={{ fontSize: 45, marginLeft: 10 }}
              onClick={() => {
                this.handleAddProductPool(row);
              }}
            />
          </div>
        );
      },
    },
  ];
  componentDidMount = () => {
    this.getAllSponsor();
    this.fetchTableData();
    this.getAllProducts();
    getSponsors()
      .then((resp) => {
        // console.log(resp);
        const sponsorFilterOptions = [{value: '', label: 'All Sponsors'}];
        this.setState({ sponsorOptions: resp.data, sponsorFilterOptions: sponsorFilterOptions.concat(resp.data) });
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };

  fetchTableData = () => {
    let { poolReplicateOptions } = this.state;
    this.setState({
      loading: true,
    });
    const filters = {
      sponsorFilter: this.state.sponsorFilter,
      productFilter: this.state.productFilter,
    };
    return getPools(filters)
      .then((responseBody) => {
        poolReplicateOptions = responseBody.data.map((r) => {
          return {
            label: r.pool_name,
            value: r.id,
          }
        })
        this.setState({
          data: responseBody.data,
          poolReplicateOptions,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };
  // handel changes
  handleAddPool = () => {
    this.setState({
      showAddProductPool: false,
      showAddSponsorPool: false,
      showPoolAddEdit: true,
      editData: [],
      editPool: false,
      showAddPoolCategory: false,
    });
  };
  handleEditPool = (row) => {
    console.log(row.original);
    const editData = row.original;
    this.setState({
      showPoolAddEdit: true,
      showAddProductPool: false,
      showAddSponsorPool: false,
      editPool: true,
      editData: editData,
      showAddPoolCategory: false,
    });
  };
  handleAddSponsorPool = (row) => {
    console.log(row.original);
    const poolId = row.original.id;
    getPoolSponsors(poolId)
      .then((resp) => {
        console.log({ resp });
        this.setState({
          showAddProductPool: false,
          showAddSponsorPool: true,
          showPoolAddEdit: false,
          showAddPoolCategory: false,
          poolId,
          sponsorsData: resp.data,
        });
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };
  handleAddProductPool = (row) => {
    console.log(row.original);
    const poolId = row.original.id;
    this.setState({
      showAddProductPool: true,
      showAddSponsorPool: false,
      showPoolAddEdit: false,
      editProductPool: false,
      showAddPoolCategory: false,
      poolId,
    });
  };
  handleEditProductPool = (data) => {
    console.log(data, '[handleEditProductPool]');
    const dataEditProductPool = data.original;
    this.setState({
      showAddProductPool: true,
      showAddSponsorPool: false,
      showPoolAddEdit: false,
      editProductPool: true,
      showAddPoolCategory: false,
      dataEditProductPool,
    });
  };

  toggleProductActiveInactive = (row) => {
    const isActive = row.original.isActive;
    this.setState({
      confirmModal: {
        isOpen: true,
        message: `Are you sure you want to mark this ${(isActive) ? 'inactive' : 'active'}?`,
        onConfirm: () => {
          const { id, pool_id } = row.original;
          return changeProductPoolActivity(pool_id, id, !isActive)
              .then(() => {
                toast.success(`Successfully marked ${(isActive) ? 'inactive' : 'active'}`);
                return this.fetchTableData();
              });
        },
        onClose: () => {
          this.setState({
            confirmModal: {
              isOpen: false,
              onConfirm: () => {},
              onClose: () => {},
            },
          });
        },
      },
    });
  };

  handleEditCategories = (data) => {
    console.log(data);
    const poolId = data.original.id;
    this.setState({
      showAddProductPool: false,
      showAddSponsorPool: false,
      showPoolAddEdit: false,
      editProductPool: false,
      showAddPoolCategory: true,
      poolId,
    });
  };

  handleChangeMySponsor = () => {
    this.setState({
      changeMySponsorModal: {
        isOpen: true,
        sponsorId: null,
      },
    });
  };

  toggleReplicatePoolModal = () => {
    const { replicatePoolModalOpen } = this.state;
    this.setState({
      replicatePoolModalOpen: !replicatePoolModalOpen
    })
  }

  handleNewPoolNameChange = (e) => {
    const val = e.target.value;
    this.setState({
      newPoolName: val,
    })
  }

  getAllSponsor = () => {
    return fetchAllSponsor()
        .then((response) => {
          if (response.message === 'success') {
            this.setState({
              allSponsor: response.data || [],
            });
          }
        })
        .catch((err) => {
          console.error(err, 'Error while fetching all sponsors');
        })
  };

  getAllProducts = () => {
    return fetchAllProducts()
        .then((response) => {
          if (response.message === 'success') {
            const productFilterOptions = [{value: '', label: 'All Products'}];
            this.setState({
              productOptions: response.data,
              productFilterOptions: productFilterOptions.concat(response.data)
            });
          }
        });
  };

  handleSponsorChangeSelect = (e) => {
    this.setState({
      changeMySponsorModal: {
        isOpen: true,
        sponsorId: e.value,
        sponsorSelected: e
      },
    });
  };

  handleChangeMySponsorSubmit = () => {
    const { sponsorId } = this.state.changeMySponsorModal;
    return submitChangeMySponsor(sponsorId)
        .then((response) => {
          if (response.message === 'success') {
            toast.success('Successfully changed the sponsor');
            return this.handleCloseChangeMySponsorModal();
          }
          throw new Error(response.errorMessage);
        })
        .catch((err) => {
          toast.error(err.message);
        });
  };

  handleCloseChangeMySponsorModal = () => {
    this.setState({
      changeMySponsorModal: {
        isOpen: false,
        sponsorId: null,
      },
    });
  };

  handleApplyProductFilter = (e) => {
    if (this.state.productFilter === e.value) {
      return;
    }
    this.setState({
      productFilter: e.value,
      productFilterSelected: e
    }, this.fetchTableData);
  };

  handlePoolReplicateSelectChange = (option) => {
    // const { poolReplicateId } = this.state.poolReplicateId;
    this.setState({
      poolReplicateSelect: option,
    });
  }

  handlePoolReplication = () => {
    console.log(this.state.newPoolName, 'New Pool Name');
    console.log(this.state.poolReplicateSelect, 'Pool Id');
    const newPoolName = this.state.newPoolName;
    const replicatePoolId = this.state.poolReplicateSelect ? this.state.poolReplicateSelect.value : null;
    if (!newPoolName || newPoolName === '') {
      toast.error('Invalid Pool Name');
      return;
    }
    if (!replicatePoolId) {
      toast.error('Invalid Pool Id');
      return;
    }

    const payload = {
      newPoolName,
      replicatePoolId,
    }
    this.setState({
      loading: true,
    });

    return replicatePool(payload)
        .then((resp) => {
          console.log(resp, 'Replicate Pool response');
          if (resp.message === 'success') {
            toast.success(`Successfully created new pool ${newPoolName}`);
          }
        })
        .catch((err) => {
          console.log(err, payload, 'Error in replicating Pool');
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
          this.toggleReplicatePoolModal();
          this.fetchTableData();
        })
  }

  handleApplySponsorFilter = (e) => {
    if (this.state.sponsorFilter === e.value) {
      return;
    }
    this.setState({
      sponsorFilter: e.value,
      sponsorFilterSelected: e
    }, this.fetchTableData);
  };
  handleDeleteProduct = (row) => {
    const data = row.original;
    this.setState({
      confirmModal: {
        isOpen: true,
        message: `Are you sure you want to delete ${data.pool_name} pool?`,
        onConfirm: () => {
          const params = {
            poolId: row.original.id
          };
          return deletePool(params)
            .then((response) => {
              console.log(response, 'Response from server');
              if (response.message === 'success') {
                toast.success(`Successfully deleted ${data.pool_name} pool`);
                return this.fetchTableData();
              }
              throw new Error(
                response.errorMessage || 'Error while uploading the file'
              );
            })
            .catch((err) => {
              console.log(err);
              toast.error(err.message);
            });
        },
        onClose: () => {
          this.setState({
            confirmModal: {
              isOpen: false,
              onConfirm: () => {},
              onClose: () => {},
            },
          });
        },
      },
    });
  }
  render() {
    return (
      <OuterComponent>
        <NavBar />
        <HeadingContainer>
          <div className="container-fluid">
            <div className="row">
              <div className="col-1">
                <h4>Pools</h4>
              </div>
              <div className="col-2">
                {this.state.editPool && (
                    <Button style={{ marginLeft: 15 }} onClick={this.handleAddPool}>
                      Add Pools
                    </Button>
                )}
              </div>
              <div className="col-2">
                <Button className="btn" onClick={this.handleChangeMySponsor}>Change My Sponsor</Button>
              </div>
              {/*Replicate Pool*/}
              <div className="col-1">
                <Button className="btn" onClick={this.toggleReplicatePoolModal}>Replicate Pool</Button>
              </div>
              <div className="col-3">
                <div>Product</div>
                <Select
                  value={this.state.productFilterSelected}
                  onChange={this.handleApplyProductFilter}
                  options={this.state.productFilterOptions}
                />
              </div>
              <div className="col-3">
                <div>Sponsor</div>
                <Select
                    value={this.state.sponsorFilterSelected}
                    onChange={this.handleApplySponsorFilter}
                    options={this.state.sponsorFilterOptions}
                  />
              </div>
            </div>
          </div>
          {/*Replicate Modal*/}
          <Modal header='Replicate a Pool' isOpen={this.state.replicatePoolModalOpen} onClose={this.toggleReplicatePoolModal} >
            <div className="container">
              <div className="row">
                <div className="col" style={{ width: '300px', height: '300px' }}>
                  <input style={{ marginBottom: '20px' }} type="text" placeholder="New pool name..." value={this.state.newPoolName} onChange={this.handleNewPoolNameChange}/>
                  <Select
                      value={this.state.poolReplicateSelect}
                      onChange={this.handlePoolReplicateSelectChange}
                      style={{ width: '100%' }}
                      options={this.state.poolReplicateOptions}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <button className="btn btn-primary btn-block" onClick={this.handlePoolReplication}>Confirm</button>
              </div>
            </div>
          </Modal>
          <Modal header='Select a Sponsor' isOpen={this.state.changeMySponsorModal.isOpen} onClose={this.handleCloseChangeMySponsorModal} >
            <div className="container">
              <div className="row">
                <div className="col" style={{ width: '300px', height: '60px' }}>
                <Select
                    value={this.state.sponsorSelected}
                    onChange={this.handleSponsorChangeSelect}
                    style={{ width: '100%' }}
                    options={this.state.sponsorOptions}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                  <button className="btn btn-primary btn-block" onClick={this.handleChangeMySponsorSubmit}>Confirm</button>
              </div>
            </div>
          </Modal>
        </HeadingContainer>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <ReactTable
                  data={this.state.data}
                  columns={this.tableColumns}
                  loading={this.state.loading}
                  showPaginationTop={false}
                  className="-striped -highlight text-center"
                  SubComponent={(row) => (
                      <ProductPoolTable
                          poolId={row.original.id}
                          handleEditProductPool={(data) => {
                            this.handleEditProductPool(data);
                          }}
                          fetchPools={this.fetchTableData}
                          toggleProductActiveInactive={this.toggleProductActiveInactive}
                      />
                  )}
              />
            </div>
            <div className="col">
              {this.state.showPoolAddEdit && (
                  <PoolsAddEdit
                      editPool={this.state.editPool}
                      togglePage={() => {
                        this.fetchTableData();
                        this.setState({
                          showPoolAddEdit: !this.state.showPoolAddEdit,
                          editPool: true,
                        });
                      }}
                      editData={this.state.editData}
                      locationOptions={this.state.locationOptions}
                  />
              )}
              {this.state.showAddProductPool && (
                  <AddProductPool
                      poolId={this.state.poolId}
                      productOptions={this.state.productOptions}
                      editProductPool={this.state.editProductPool}
                      dataEditProductPool={this.state.dataEditProductPool}
                      togglePage={() => {
                        this.fetchTableData();
                        this.setState({
                          showAddProductPool: false,
                          editProductPool: false,
                        });
                      }}
                  />
              )}
              {this.state.showAddSponsorPool && (
                  <AddSponsorPool
                      togglePage={() => {
                        this.fetchTableData();
                        this.setState({
                          showAddSponsorPool: !this.state.showAddSponsorPool,
                        });
                      }}
                      sponsorOptions={this.state.sponsorOptions}
                      poolId={this.state.poolId}
                      sponsorsData={this.state.sponsorsData}
                  />
              )}
              {this.state.showAddPoolCategory && (
                  <AddPoolCategory
                      togglePage={() => {
                        this.fetchTableData();
                        this.setState({
                          showAddPoolCategory: !this.state.showAddPoolCategory,
                        });
                      }}
                      poolId={this.state.poolId}
                  />
              )}
            </div>
          </div>
        </div>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        <ModalConfirm
            message={this.state.confirmModal.message}
            onConfirm={this.state.confirmModal.onConfirm}
            onClose={this.state.confirmModal.onClose}
            isOpen={this.state.confirmModal.isOpen}
        />
      </OuterComponent>
    );
  }
}

export default Pools;
