import React from 'react';
import { toast } from 'react-toastify';

import { getDetails, saveRemarks, sendConfirmationEmail, sendProcessedComms } from '../../services/api/redemption-ops';
import LoadingComponent from "../common/LoadingComponent";

import {
    FaUserAlt,
    FaPlaystation,
    FaFirstOrder,
    FaTruckPickup,
    FaStickyNote,
    FaMoneyBill,
    FaAmazon,
    FaPencilAlt
} from 'react-icons/fa';

import Link from 'valuelink';
import { TextArea } from 'valuelink/tags';

class DetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redemptionInfo: {},
            remarks: '',
            loading: false,
            confirmationButtonLoading: false,
            showRedemptionMailer: false,
            showRemarks: false,
            couponCode: '',
            couponValidity: '',
            sendCouponButtonLoading: false,
        };
    }

    getDetailsForRedemption = (redemptionId) => {
        if (redemptionId) {
            return getDetails(redemptionId)
                .then((response) => {
                    this.setState({
                        redemptionInfo: response.redemptionInfo || {},
                        remarks: response.redemptionInfo.remarks,
                    });
                })
                .catch((err) => {
                    console.error({ err, redemptionId },
                        '[getDetailsForRedemption]');
                    toast.error(err.errorMessage || 'Error while fetching redemption details');
                });
        }
    };

    saveRemarks = () => {
        const { remarks } = this.state;
        const { redemptionId } = this.props;
        this.setState({
            loading: true,
        });
        return saveRemarks(redemptionId, remarks)
            .then(() => {
                toast.success('Updated Remarks');
                return this.getDetailsForRedemption(redemptionId);
            })
            .catch((err) => {
                toast.error('Error while saving remarks');
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };

    handleSendConfirmationMail = () => {
        const { redemptionId } = this.props;
        this.setState({
            confirmationButtonLoading: true,
        });
        return sendConfirmationEmail(redemptionId)
            .then(() => {
                toast.success('Successfully sent confirmation mailer');
            })
            .catch((err) => {
                toast.error('Error while sending confirmation mailer');
            })
            .finally(() => {
                this.setState({
                    confirmationButtonLoading: false,
                });
            });
    };

    componentDidMount() {
        if (this.props.redemptionId) {
            this.getDetailsForRedemption(this.props.redemptionId);
        }
    }

    toggleConfirmationMail = () => {
        this.setState((currentState) => ({
            showRedemptionMailer: !(currentState.showRedemptionMailer),
        }));
    }

    toggleRemarks = () => {
        this.setState((currentState) => ({
            showRemarks: !(currentState.showRemarks),
        }));
    };
    onChangeCouponCode = (e) => {
        this.setState({ couponCode: e.target.value });
    }
    onChangeCouponValidity = (e) => {
        this.setState({ couponValidity: e.target.value });
    }
    sendRedemptionProcessedComms = () => {
        this.setState({
            sendCouponButtonLoading: true,
        })
        let { couponCode, couponValidity } = this.state;
        console.log(couponCode, couponValidity);
        if (couponCode === '' || !couponValidity) {
            toast.error('Invalid Code or Validity');
            this.setState({
                sendCouponButtonLoading: false,
            })
            return ;
        }
        couponCode = couponCode.trim();
        couponValidity = couponValidity.trim();
        const { redemptionId } = this.props;
        const payload = { couponCode, couponValidity };
        return sendProcessedComms(redemptionId, payload)
            .then((response) => {
                toast.success('Successfully Processed Redemption!');
            })
            .catch((err) => {
                const message = err.response.data.errorMessage;
                console.log('error sendProcessedComms', err);
                if (message) {
                    toast.error(message);
                } else {
                    toast.error('Error in processing gift card!');
                }


            })
            .finally(() => {
                this.setState({ couponCode: '', couponValidity: '', sendCouponButtonLoading: false });
                this.props.closeModal();
            })

    }

    render() {
        const { redemptionInfo, loading, confirmationButtonLoading, sendCouponButtonLoading } = this.state;
        const remarksLink = Link.state(this, 'remarks');
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col m-2">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="m-1"><FaPlaystation color={"#238ae6"} className="pb-1" /> Product Info</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Product:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.productName || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Type:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.productType || 'N/A'}
                                        </div>
                                    </div>
                                    {(redemptionInfo.showPayout) ? (
                                        <div className="row">
                                            <div className="col">
                                                <strong>Paytm Payout:</strong>
                                            </div>
                                            <div className="col">
                                                <FaMoneyBill color={"#238ae6"} style={{ fontSize: "22px" }} onClick={() => (this.props.openPayoutModal(this.props.redemptionId))} />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col m-2">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="m-1"><FaUserAlt color={"#238ae6"} className="pb-1" /> User Info</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Name:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.userName || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Phone:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.userPhone || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Email:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.userEmail || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Sponsor:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.sponsorName || 'N/A'}
                                        </div>
                                    </div>
                                </div>
                                {(redemptionInfo.productType === 'gift-card' && !this.state.showRedemptionMailer) ? (
                                    <div className="col m-2">
                                        <div className="row">
                                            <div className="col">
                                                <h5 className="m-1"><FaAmazon color={"#000000"} className="pb-1" /> Amazon Coupon Info:</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <strong>Code:</strong>
                                            </div>
                                            <div className="col">
                                                <input type="text" value={this.state.couponCode} onChange={this.onChangeCouponCode}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <strong>Validity:</strong>
                                            </div>
                                            <div className="col">
                                                <input type="date" value={this.state.couponValidity} onChange={this.onChangeCouponValidity}/>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                {(sendCouponButtonLoading) ? (<LoadingComponent/>) : ((<button style={{marginTop: "5px"}} className="btn btn-primary btn-sm" onClick={this.sendRedemptionProcessedComms}>Send</button>))}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div className="row">
                                <div className="col m-2">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="m-1"><FaFirstOrder color={"#238ae6"} className="pb-1" /> Redemption Info</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Status:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.orderStatus || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Redeemed On:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.redemptionDate || 'N/A'}
                                        </div>
                                    </div>
                                    {(redemptionInfo.couponCode) ? (
                                        <div className="row">
                                            <div className="col">
                                                <strong>Coupon:</strong>
                                            </div>
                                            <div className="col">
                                                {redemptionInfo.couponCode}
                                            </div>
                                        </div>
                                    ) : null}
                                    {(redemptionInfo.orderId) ? (
                                        <React.Fragment>
                                            <div className="row">
                                                <div className="col">
                                                    <strong>Order Id:</strong>
                                                </div>
                                                <div className="col">
                                                    {redemptionInfo.orderId}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <strong>Tracking Link:</strong>
                                                </div>
                                                <div className="col">
                                                    {redemptionInfo.trackingLink}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <strong>Provider:</strong>
                                                </div>
                                                <div className="col">
                                                    {redemptionInfo.provider}
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <strong>Placed On:</strong>
                                                </div>
                                                <div className="col">
                                                    {redemptionInfo.placedOn}
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    ): null}
                                </div>
                                <div className="col m-2">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="m-1"><FaTruckPickup color={"#238ae6"} className="pb-1" /> Delivery Info {(redemptionInfo.showDeliveryDetailsEditModal) ? (<FaPencilAlt onClick={() => (this.props.openEditDeliveryModal(this.props.redemptionId))} />) : (null)}</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Address:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.address || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Pincode:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.pincode || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Patient Name:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.patientName || 'N/A'}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <strong>Consultation Date:</strong>
                                        </div>
                                        <div className="col">
                                            {redemptionInfo.consultationDate || 'N/A'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <a style={{ color: "#238ae6", cursor: "pointer" }} onClick={this.toggleRemarks}>Remarks</a>
                                </div>
                                <div className="col">
                                    {(this.state.redemptionInfo.addOrderDetails) ? (
                                        <React.Fragment>
                                            <a style={{ color: "#238ae6", cursor: "pointer" }} onClick={() => (this.props.openOrderDetailsModal(this.props.redemptionId))}>Order Details</a>
                                        </React.Fragment>
                                    ) : null}
                                </div>
                                <div className="col text-right">
                                    <a style={{ color: "#238ae6", cursor: "pointer" }} onClick={this.toggleConfirmationMail}>Confirmation Mailer</a>
                                </div>
                            </div>
                            {(this.state.showRemarks) ? (
                                <div className="row">
                                    <div className="col">
                                        <div className="m-1"><FaStickyNote color={"#238ae6"} className="pb-1" /> Remarks</div>
                                        <TextArea className="form-control" style={{ resize: "none" }} valueLink={remarksLink} rows={3} cols={50} />
                                        <div className="text-right mt-2">
                                            {(loading) ? (<LoadingComponent />) : (<button className="btn btn-primary" onClick={this.saveRemarks}>Save Remarks</button>)}
                                        </div>
                                    </div>
                                </div>
                            ): null}
                        </div>
                        {(this.state.showRedemptionMailer) ? (
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <h5>Confirmation Email</h5>
                                        <div dangerouslySetInnerHTML={{__html: redemptionInfo.confirmationMailerContent}}></div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <div className="text-right mt-2">
                                            {(confirmationButtonLoading) ? (<LoadingComponent />) : ((<button className="btn btn-primary" onClick={this.handleSendConfirmationMail}>Send Mailer</button>))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default DetailsContainer;
