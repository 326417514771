import React from 'react';
import styled from 'styled-components';
import { FaCircleNotch } from 'react-icons/fa';
import loaderIcon from '../../../images/loader.svg';


const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 100%;
  width: 100%;
  background: #ffffff;
  border-radius: 12px 12px 0 0;
`;

const LoadingIcon = styled(FaCircleNotch)`
  font-size: ${() => `${2 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: ${(props) => (props.color ? props.color : 'black')};
`;

export default ({ color, borderRadius }) => (
  <LoadingContainer style={{ borderRadius }}>
    <img src={loaderIcon} style={{ height: '48px', width: '48px' }} />
  </LoadingContainer>
);
