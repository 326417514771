import React from 'react';
import { MdModeEdit, MdAdd, MdCancel } from "react-icons/md";
import {Tooltip} from "reactstrap";
import {debounce} from "../../utils/debounce";

const CREATE_ORDER = 'create-order';
const EDIT_REQUEST = 'edit-request';
const CANCEL_REQUEST = 'cancel-request';

class AhcRequestActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = { cancelTooltipOpen: false };
    }

    handleOnClick = (requestId, userId, authToken, actionName, disabled) => {
        if (disabled) {
            return;
        }
        // console.log({actionType, actionName}, '[handleOnClick]');
        // const { requestId, status, collectionDate, slotId } = this.props;
        this.props.onClick(requestId, userId, authToken, actionName);
    };

    renderActionItem = (requestId, userId, authToken, type, disabled) => {
        // console.log({ requestId, userId, authToken, type }, '[renderActionItem]');
        const id = `action-btn-${requestId}-${type}`;
        const opacity = disabled ? {opacity:0.2} : "";
        switch (type) {
            case CREATE_ORDER:
                return (
                    <span>
                        <MdAdd className="mr-3" style={opacity} id={id} onClick={() => (this.handleOnClick(requestId,  userId, authToken, CREATE_ORDER, disabled))}/>
                        <Tooltip
                            placement="top"
                            target={id}
                            trigger="hover"
                            toggle={() => {
                                const { cancelTooltipOpen } = this.state;
                                this.setState({
                                    cancelTooltipOpen: (id === cancelTooltipOpen) ? false : id
                                });
                            }}
                            isOpen={
                                this.state.cancelTooltipOpen === id
                            }>
                            Create Order
                        </Tooltip>
                    </span>

                );
            case EDIT_REQUEST:
                return (
                    <span>
                        <MdModeEdit style={opacity} className="mr-3" id={id} onClick={() => (this.handleOnClick(requestId, userId, authToken, EDIT_REQUEST, disabled))}/>
                        <Tooltip
                            placement="top"
                            target={id}
                            trigger="hover"
                            toggle={() => {
                                const { cancelTooltipOpen } = this.state;
                                this.setState({
                                    cancelTooltipOpen: (id === cancelTooltipOpen) ? false : id
                                });
                            }}
                            isOpen={
                                this.state.cancelTooltipOpen === id
                            }>
                            Edit Request
                        </Tooltip>
                    </span>
                );
            case CANCEL_REQUEST:
                return (
                    <span>
                        <MdCancel style={opacity} className="mr-3" id={id} onClick={() => (this.handleOnClick(requestId, userId, authToken, CANCEL_REQUEST, disabled))}/>
                        <Tooltip
                            placement="top"
                            target={id}
                            trigger="hover"
                            toggle={() => {
                                const { cancelTooltipOpen } = this.state;
                                this.setState({
                                    cancelTooltipOpen: (id === cancelTooltipOpen) ? false : id
                                });
                            }}
                            isOpen={
                                this.state.cancelTooltipOpen === id
                            }>
                            Cancel Request
                        </Tooltip>
                    </span>
                );
            default:
                return '';
        }
    };

    getActionFromOrder = (type) => {
        console.log(type, '[getActionFromOrder]');
        const { actions } = this.props;
        const found = actions.find(o => o.actionType === type);
        return !found ? false : found;
    };

    renderAllActions = () => {
        const { requestId, authToken, userId, disabled } = this.props;
        return (
            <div>
                {this.renderActionItem(requestId, userId, authToken, CREATE_ORDER, disabled)}
                {this.renderActionItem(requestId, userId, authToken, EDIT_REQUEST, disabled)}
                {this.renderActionItem(requestId, userId, authToken, CANCEL_REQUEST, disabled)}
            </div>
        );
    };

    render() {
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        {this.renderAllActions()}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AhcRequestActions;
