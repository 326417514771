import React, { Component } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Badge,
  Label,
} from 'reactstrap';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { BoldText } from '../common/Text';

import { toast } from 'react-toastify';

import { changeProcessingDate } from '../../services/api/offline-consult';
import moment from 'moment';

class SuggestionsDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      date: null,
      time: null,
      reason: 'Doctor Unvailable',
    };
  }

  updateProcessingDate = () => {
    const body = {
      requestId: this.props.requestId,
      processingDate: moment(
        `${this.state.date} ${this.state.time}`,
        'YYYY-MM-DD HH:mm'
      ).format('YYYY-MM-DD HH:mm:ss'),
      reason: this.state.reason,
    };
    changeProcessingDate(body)
      .then(res => {
        if (res.message !== 'success') {
          throw new Error(res.errorMessage);
        }
        console.log({ res });
        toast.success('Changed Processing Date');
        this.toggleParentModal();
      })
      .catch(err => {
        toast.error(err.message);
        console.log({ err }, 'error changing processing date');
      });
  };

  toggleParentModal = () => {
    // reset the message checks
    this.setState({
      collapse: false,
      choice: 1,
    });
    // call the parent method
    this.props.closeProcessingDateModal();
  };

  handleChange = selectedVertical => {
    this.setState({ selectedVertical });
    console.log(`Option selected:`, selectedVertical);
  };

  onModalOpened = () => {
    const { processingDate } = this.props;
    this.setState({
      date: moment(processingDate * 1000).format('YYYY-MM-DD'),
      time: moment(processingDate * 1000).format('HH:mm'),
    });
  };

  onDateChange = e => {
    console.log({ date: e.target.value }, 'date');
    this.setState({
      date: e.target.value,
    });
  };

  onTimeChange = e => {
    console.log({ time: e.target.value }, 'Time');
    this.setState({
      time: e.target.value,
    });
  };

  onReasonChange = e => {
    console.log({ reason: e.target.value }, 'reason');
    this.setState({
      reason: e.target.value,
    });
  };

  render() {
    return (
      <Modal
        onOpened={this.onModalOpened}
        isOpen={this.props.isOpen}
        onCancel={this.toggleParentModal}
        toggle={this.toggleParentModal}
      >
        <ModalHeader toggle={this.toggleParentModal}>
          <BoldText style={{ color: '#47464a' }}>
            Change Processing Date
          </BoldText>
        </ModalHeader>
        <ModalBody>
          <Row gutter={[16, 16]} style={{ margin: 10 }}>
            {`Date and time: ${this.state.date} | ${this.state.time}`}
          </Row>
          <input
            id="appt-date"
            style={{ margin: 10 }}
            type="date"
            name="appt-date"
            value={this.state.date}
            onChange={this.onDateChange}
          ></input>
          <input
            id="appt-time"
            style={{ margin: 10 }}
            type="time"
            name="appt-time"
            value={this.state.time}
            onChange={this.onTimeChange}
          ></input>
          <Row gutter={[16, 16]} style={{ margin: 10 }}>
            <Input
              type="select"
              name="select"
              id="exampleSelect"
              onChange={this.onReasonChange}
              value={this.state.reason}
            >
              <option>Doctor Unvailable</option>
              <option>Same day confirmation</option>
            </Input>
          </Row>
          <Row gutter={[16, 16]} style={{ margin: 10 }}>
            <Button color="success" onClick={this.updateProcessingDate}>
              Change
            </Button>
          </Row>
        </ModalBody>
      </Modal>
    );
  }
}

export default withRouter(SuggestionsDialog);
