import React from 'react';

import Modal from '../common/Modal';

import {
    editAhcRequest, cancelAhcRequest
} from '../../services/api/get-labs-orders';

import { toast } from 'react-toastify';
import LoadingComponent from '../common/LoadingComponent'

import {listPartnersForAhc, addAddressForUser, createOrderForAhc, listAddressesByOps, listPatientsByOps} from "../../services/api/rx-transcription";
import moment from 'moment';

const ActionTypes = {
    ORDER_CONFIRM: 'order-confirm',
    ORDER_RESCHEDULED: 'order-reschedule',
    SAMPLE_COLLECTED: 'sample-collected',
    ORDER_COMPLETED: 'order-completed',
    ORDER_CANCELLED: 'order-cancelled',
    ORDER_REFUNDED: 'order-refunded',
};

const CREATE_ORDER = 'create-order';
const EDIT_REQUEST = 'edit-request';
const CANCEL_REQUEST = 'cancel-request';
class AhcActionModals extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            patients: [],
            addresses: [],
            selectedPatient: null,
            selectedAddress: null,
            preferredDate: '',
            partners: [],
            selectedPartner: null,
            selectedCenter: null,
            selectedTest: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.isOpen && this.props.isOpen) {
            if (this.props.actionName === EDIT_REQUEST) {
                this.getPatientList();
                this.getAddressList();
            }
            if (this.props.actionName === CREATE_ORDER) {
                this.getPartnerListForAhc();
            }
        }
    }

    selectPatient = (patient) => {
        let selected = null;
        const patients = this.state.patients;
        patients.forEach((p) => {
            if (patient.patientId === p.patientId) {
                p.active = true;
                selected = p;
            } else {
                p.active = false;
            }
        });

        this.setState({selectedPatient: selected, patients});
    };

    selectAddress = (address) => {
        let selected = null;
        const addresses = this.state.addresses;
        addresses.forEach((a) => {
            if (address.addressId === a.addressId) {
                a.active = true;
                selected = a;
            } else {
                a.active = false;
            }
        });

        this.setState({selectedAddress: selected, addresses});
    };

    deSelectArr = (arr) => {
        arr.forEach((t) => {
            t.active=0;
        });
    }

    selectPartner = (partner) => {
        let selected = null;
        const partners = this.state.partners;
        partners.forEach((a) => {
            if (partner.id === a.id) {
                a.active = true;
                selected = a;
            } else {
                a.active = false;
            }
            this.deSelectArr(a.tests);

        });

        this.setState({selectedPartner: selected, selectedTest: null, selectedCenter: null, partners});
    };


    selectTest = (test) => {
        const partners = this.state.partners;
        partners.forEach((a) => {
            if (this.state.selectedPartner.id === a.id) {
                a.tests.forEach((t) => {
                   if (t.testId === test.testId) {
                       t.active = true;
                   } else {
                       t.active =  false;
                   }
                });
            } else {
                this.deSelectArr(a.tests);
            }
        });

        this.setState({selectedTest: test, selectedCenter: null, partners});
    };

    selectCenter = (center) => {
        const partners = this.state.partners;
        partners.forEach((a) => {
            if (this.state.selectedPartner.id === a.id) {
                a.tests.forEach((b) => {
                    if (b.testId === this.state.selectedTest.testId) {
                        b.centers.forEach((c) => {
                            if (c.centerId === center.centerId) {
                                c.active = true;
                            } else {
                                c.active = false;
                            }
                        });
                    }
                });
            }
        });

        this.setState({selectedCenter: center, partners});
    };

    renderAddresses = () => {
        return this.state.addresses.map((a) => {
            const containerClasses = 'list-group-item list-group-item-action ';
            return (
                <div onClick={() => this.selectAddress(a)} className={containerClasses + (a.active ? 'active': '')} key={a.addressId}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{a.address} | {a.locality} | {a.pincode}</h5>
                    </div>
                    <p className="mb-1">{a.title}</p>
                </div>
            );
        });
    };

    renderPatients = () => {
        return this.state.patients.map((p) => {
            const containerClasses = 'list-group-item list-group-item-action ';
            return (
                <div key={p.patientId} className={containerClasses + (p.active ? 'active': '')} onClick={() => this.selectPatient(p)}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{p.name} | {p.phone}</h5>
                    </div>
                    <p className="mb-1">{p.gender} - {p.age} years old</p>
                </div>
            );
        });
    };

    renderPartners = () => {
        return this.state.partners.map((p) => {
            const containerClasses = 'list-group-item list-group-item-action ';
            return (
                <div key={p.id} className={containerClasses + (p.active ? 'active': '')} onClick={() => this.selectPartner(p)}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{p.partnerName}</h5>
                    </div>
                </div>
            );
        });
    };

    renderTests = () => {
        if (this.state.selectedPartner === null) return '';
        return this.state.selectedPartner.tests.map((p) => {
            const containerClasses = 'list-group-item list-group-item-action ';
            return (
                <div key={p.testId} className={containerClasses + (p.active ? 'active': '')} onClick={() => this.selectTest(p)}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{p.testName}</h5>
                    </div>
                </div>
            );
        });
    };

    renderCenters = () => {
        if (this.state.selectedTest === null) return '';
        return this.state.selectedTest.centers.map((p) => {
            const containerClasses = 'list-group-item list-group-item-action ';
            return (
                <div key={p.centerId} className={containerClasses + (p.active ? 'active': '')} onClick={() => this.selectCenter(p)}>
                    <div className="d-flex w-100 justify-content-between">
                        <h5 className="mb-1">{p.centerName}</h5>
                    </div>
                </div>
            );
        });
    };


    getAddressList = () => {
        listAddressesByOps(this.props.userId)
            .then((response) => {
                console.log(response, 'list Address Response');
                if(response.body.message === 'success') {
                    this.setState({
                        addresses: response.body.result,
                    });
                } else {
                    toast.error('Error in listing addresses');
                }
            })
            .catch((err) => {
                toast.error('Error in listing addresses');
                console.error('Error', err);
            });
    };

    getPatientList = () => {
        listPatientsByOps(this.props.userId)
            .then((response) => {
                if(response.body.message === 'success') {
                    this.setState({
                        patients: response.body.result,
                    });
                } else {
                    toast.error('Error in listing patients');
                }
            })
            .catch((err) => {
                toast.error('Error in listing patients');
                console.error('Error', err);
            });
    };

    getModalBody = () => {
        const { actionName, userId, authToken } = this.props;
        const userInfo = { userId, authToken };
        // console.log({actionName, userInfo}, '[getModalBodyAhcActionModals]');
        const text = `Are you sure you want to ${actionName}?`;
        switch (actionName) {
            case CREATE_ORDER: {
                return (
                    <div className="row">
                        <div className="col">
                            <div className="list-group">
                                {this.renderPartners()}
                            </div>
                        </div>
                        <div className="col">
                            <div className="list-group">
                                {this.renderTests()}
                            </div>
                        </div>
                        <div className="col">
                            <div className="list-group">
                                {this.renderCenters()}
                            </div>
                        </div>
                    </div>
                );
            }
            case EDIT_REQUEST: {
                return (
                    <div>
                        <div className="row">
                            <div className="col">
                                <div className="list-group">
                                    {this.renderPatients()}
                                </div>
                            </div>
                            <div className="col">
                                <div className="list-group">
                                    {this.renderAddresses()}
                                </div>
                            </div>

                            <div className="col">
                                <input type="date" value={this.state.preferredDate} onChange={(e) => (this.setState({ preferredDate: e.target.value }))}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {text}
                            </div>
                        </div>
                    </div>

                );
            }
            case CANCEL_REQUEST: {
                return (
                    <div>
                        <div className="row">
                            <div className="col">
                                {text}
                            </div>
                        </div>
                    </div>
                )
            }
            default:
                return "Error";

        }
    };

    getPartnerListForAhc = () => {
        listPartnersForAhc(this.props.requestId)
            .then((response) => {
                if(response.body.message === 'success') {
                    this.setState({
                        partners: response.body.partnerList,
                    });
                } else {
                    toast.error('Error in listing partners');
                }
            })
            .catch((err) => {
                toast.error('Error in listing partners');
                console.error('Error', err);
            });
    };

    handleSubmit = () => {
        console.log({props: this.props, state: this.state}, 'submitting');

        if (this.props.actionName === EDIT_REQUEST) {
            if (this.state.selectedPatient === null) {
                toast.error('Please select a patient');
                return;
            }

            if (this.state.preferredDate === null || this.state.preferredDate === '') {
                toast.error('Please select a preferred date');
                return;
            }
            // if (this.state.selectedAddress === null) {
            //     toast.error('Please select an address');
            //     return;
            // }
            const preferedDateEpoch = moment(this.state.preferredDate, 'YYYY-MM-DD').unix()
            console.log({ preferedDateEpoch }, '[df]');

            editAhcRequest(this.props.requestId, this.state.selectedPatient.patientId, this.state.selectedAddress? this.state.selectedAddress.addressId: null,  preferedDateEpoch)
                .then((response) => {
                    if (!response.body.message === 'success') {
                        toast.error(`Error in submitting edit ahc request: ${response.body.errorMessage}`);
                    } else {
                        toast.success('Request edit complete!');
                        this.handleCloseActionModal();
                    }
                })
                .catch((err) => {
                    console.log(err.response.data, 'errpr');
                    toast.error(`Error in submitting edit ahc request: ${err.response.data.errorMessage}`);
                    console.error('[handleSubmit/AhActionModals]', err);
                });
        } else if(this.props.actionName === CREATE_ORDER) {

            if (this.state.selectedPartner === null) {
                toast.error('Please select a partner');
                return;
            }
            if (this.state.selectedTest === null) {
                toast.error('Please select a test');
                return;
            }
            if (this.state.selectedCenter === null) {
                toast.error('Please select a Center');
                return;
            }

            createOrderForAhc(this.props.requestId, this.state.selectedPartner.id, this.state.selectedTest.testId, this.state.selectedCenter.centerId)
                .then((response) => {
                    if (!response.body.message === 'success') {
                        toast.error('Error in creating order for AHC');
                    } else {
                        toast.success('Order Created Successfully');
                        this.handleCloseActionModal();
                    }
                })
                .catch((err) => {
                    toast.error(`Error in placing order: ${err.response.data.errorMessage}`);
                    console.error('[handleSubmit/AhActionModals]', err);
                });
        } else if(this.props.actionName === CANCEL_REQUEST) {
            cancelAhcRequest(this.props.requestId)
                .then((response) => {
                        if (!response.body.message === 'success') {
                            toast.error('Error in cancelling AHC request');
                        } else {
                            toast.success('request cancelled Successfully');
                            this.handleCloseActionModal();
                        }
                })
                .catch((err) => {
                    toast.error(`Error in cancelling order: ${err.response.data.errorMessage}`);
                    console.error('[handleSubmit/AhActionModals]', err);
                });
        }
    };

    handleCloseActionModal = () => {
        this.setState({}, this.props.onClose);
    }

    onModalOpen = () => {
        const { actionType } = this.props;
        console.log('[onModalOpen]');
        if (actionType === ActionTypes.ORDER_REFUNDED) {
            this.getCartItems();
            this.getRefundReasonsForLabsOrders();
            this.getOrderDetails();
        }

    }

    render() {
        const { requestId, userId, isOpen, actionName } = this.props;

        // console.log({requestId, userId, isOpen, actionName}, '[render][props][AhcActionModals]');
        const { loading } = this.state;
        return (
            <Modal
                isOpen={isOpen}
                header={`Request Id: ${requestId} | Action : ${actionName}`}
                onOpen={this.onModalOpen}
            >
                {this.getModalBody()}
                <br />
                <div className="row">
                    <div className="col">
                        {(loading) ? (
                            <LoadingComponent />
                        ) : (
                            <button className="btn btn-primary btn-block" onClick={this.handleSubmit}>{actionName}</button>
                        )}
                    </div>
                    <br />
                    <div className="col">
                        <button className="btn btn-danger btn-block"
                                onClick={()=>{
                                    this.handleCloseActionModal();
                                }}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </Modal>
        );
    }
}


export default AhcActionModals;
