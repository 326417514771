import React, { Component } from 'react';
import ReactTable from 'react-table';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';
import { FaToggleOn, FaToggleOff } from 'react-icons/fa';
import { Tooltip } from 'reactstrap';
import {
  editPoolForSponsor,
  addDoctor,
  addPool,
  getDoctorStats,
  markDoctorActiveInAllPools,
  markDoctorInactiveInAllPools,
} from '../../services/api/pool-manager';
import { toast } from 'react-toastify';
import DownloadCsv from '../common/DownloadCsv';

const OuterComponent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin-top: 50px;
`;

const HeaderComponent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px;
  padding-top: 20px;
`;

const HeaderElement = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
`;

const BodyComponent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 0.5rem;
  /* overflow: hidden; */
`;

// const Button = styled.button`
//   padding: 4px;
// `;

class DoctorLiveStatsBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      doctors: [],
      pages: -1,
      page: 0,
      pageSize: 20,
      sorted: [],
      filtered: [],
      dataCsv: [],
    };
  }

  onFetchData = (state) => {
    const { page, pageSize, sorted, filtered } = state;
    console.log(state);
    this.getDoctorLiveStats(page, pageSize, sorted, filtered);
  };

  getDoctorLiveStats = (page, pageSize, sorted, filtered) => {
    this.setState({
      loading: true,
    });
    if (page) {
      this.setState({
        page,
        pageSize,
        sorted,
        filtered,
      });
    }
    let pageParam = page || this.state.page;
    let pageSizeParam = pageSize || this.state.pageSize;
    let sortedParam = sorted || this.state.sorted;
    let filteredParam = filtered || this.state.filtered;
    getDoctorStats(pageParam, pageSizeParam, sortedParam, filteredParam)
      .then((res) => {
        console.log({ res });
        if (res.message === 'success') {
          const { result, totalCount } = res;
          const csvList = this.getCsvList(res.result);
          const pages = Math.ceil(totalCount / pageSize);
          this.setState({
            doctors: result,
            pages,
            dataCsv: csvList,
          });
        }
      })
      .catch((err) => {
        console.log({ err });
        toast.error(`Failure! ${err.errorMessage}`);
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  };

  onPoolSelect = (option) => {
    console.log({ option }, '[option]');
    this.setState({
      selectedPool: option,
    });
    this.getDoctorsForPool(option.poolId);
  };

  onDoctorSelect = (option) => {
    console.log({ option }, 'onDoctorSelect after search');
    if (option) {
      const { selectedPool } = this.state;
      addDoctor(selectedPool.poolId, option.doctorId)
        .then((res) => {
          console.log({ res });
          if (res.message === 'success') {
            this.getDoctorsForPool(selectedPool.poolId);
          } else {
            toast.error(`Failure! ${res.errorMessage}`);
          }
        })
        .catch((err) => {
          console.log({ err });
          toast.error(`Failure! ${err.errorMessage}`);
        });
    }
  };

  addPool = (e) => {
    e.preventDefault();
    const poolName = this.state.createPoolPoolName;
    const copyPoolId = this.state.copyPoolPoolId;
    addPool(poolName, copyPoolId)
      .then((res) => {
        console.log({ res }, '[addPool]-[res]');
        if (res.message === 'success') {
          this.setState({ showAddModal: false });
          this.getDoctors();
          toast.success('Pool Added Sucessfully');
        } else {
          toast.error(res.errorMessage);
        }
      })
      .catch((err) => {
        console.log({ err }, '[addPool]-[err]');
        const errorMessage =
          (err.body || {}).errorMessage ||
          ((err.data || {}).body || {}).errorMessage;
        toast.error(
          errorMessage || 'Something went wrong. Please try again later'
        );
      });
  };

  editPoolForm = (e) => {
    e.preventDefault();
    const poolId = this.state.editPoolPoolId;
    const sponsorId = this.state.editPoolSponsorId;
    editPoolForSponsor(poolId, sponsorId)
      .then((res) => {
        console.log({ res }, '[editPool]-[res]');
        if (res.message === 'success') {
          this.setState({
            showEditModal: false,
            editPoolSponsorId: null,
            editPoolPoolId: null,
          });
          this.getDoctorLiveStats();
          toast.success('Pool Id updated sucessfully');
        } else {
          toast.error(res.errorMessage);
        }
      })
      .catch((err) => {
        console.log({ err }, '[editPool]-[err]');
        const errorMessage =
          (err.body || {}).errorMessage ||
          ((err.data || {}).body || {}).errorMessage;
        toast.error(
          errorMessage || 'Something went wrong. Please try again later'
        );
      });
  };

  showAddModal = () => {
    this.setState({
      showAddModal: true,
    });
  };

  getCsvList = (responseBody) => {
    const csvList = [];

    responseBody.forEach((val,idx) => {
      let tmpObject = {};
      for(const [keyInner, valInner] of Object.entries(val)) {
        tmpObject[keyInner] = valInner;
      }
      if(!tmpObject.activeInactiveInfo) tmpObject.activeInactiveInfo = 'None';
      csvList.push(tmpObject);
    });
    return csvList;
  };

  showEditModal = (sponsorId) => {
    this.setState({
      showEditModal: true,
      editPoolSponsorId: sponsorId,
    });
  };

  viewPool = (poolId, poolName) => {
    const { history } = this.props;
    history.push(`/doctor-pool?poolId=${poolId}&poolName=${poolName}`);
  };

  tableColumns = [
    {
      accessor: 'doctorId',
      filterable: true,
      sortable: false,
      Header: 'Doctor ID',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'doctorName',
      filterable: true,
      sortable: false,
      Header: 'Doctor Name',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'unattendedConsults',
      filterable: false,
      Header: 'Unattended Consults',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'callbackPendingConsults',
      filterable: false,
      Header: 'Callback Pending',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'tatExceededConsults',
      filterable: false,
      Header: 'TAT Exceeded Consults',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'activePoolCount',
      filterable: false,
      sortable: false,
      Header: 'Pools Active In',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'inactivePoolCount',
      filterable: false,
      sortable: false,
      Header: 'Pools Inactive In',
      style: { whiteSpace: 'unset' },
    },
    {
      accessor: 'actions',
      numeric: true,
      filterable: false,
      disablePadding: false,
      Header: 'Actions',
      sortable: false,
      style: { whiteSpace: 'unset' },
      Cell: (row) => {
        const showActiveButton = row.original.activePoolCount > 0;
        if (showActiveButton) {
          return (
            <div>
              <FaToggleOn
                className="icon-style ml-2 text-success"
                style={{ fontSize: 25 }}
                id={`viewpool-${row.original.doctorId}`}
                onClick={() => this.markDoctorInactive(row.original.doctorId)}
              />
              {/*<Tooltip*/}
              {/*  placement="top"*/}
              {/*  target={`viewpool-${row.original.doctorId}`}*/}
              {/*  trigger="hover"*/}
              {/*  toggle={() => {*/}
              {/*    const { viewPoolTooltipOpen } = this.state;*/}
              {/*    this.setState({*/}
              {/*      viewPoolTooltipOpen:*/}
              {/*        row.original.doctorId === viewPoolTooltipOpen*/}
              {/*          ? -1*/}
              {/*          : row.original.doctorId,*/}
              {/*    });*/}
              {/*  }}*/}
              {/*  isOpen={*/}
              {/*    this.state.viewPoolTooltipOpen === row.original.doctorId*/}
              {/*  }*/}
              {/*>*/}
              {/*  Mark Doctor Inactive*/}
              {/*</Tooltip>*/}
            </div>
          );
        }
        return (
          <div>
            <FaToggleOff
              className="icon-style text-danger"
              style={{ fontSize: 25 }}
              id={`confirmButton-${row.original.doctorId}`}
              onClick={() => this.markDoctorActive(row.original.doctorId)}
            />
            {/*<Tooltip*/}
            {/*  placement="top"*/}
            {/*  target={`confirmButton-${row.original.doctorId}`}*/}
            {/*  trigger="hover"*/}
            {/*  toggle={() => {*/}
            {/*    const { confirmTooltipOpen } = this.state;*/}
            {/*    this.setState({*/}
            {/*      confirmTooltipOpen:*/}
            {/*        row.original.doctorId === confirmTooltipOpen*/}
            {/*          ? -1*/}
            {/*          : row.original.doctorId,*/}
            {/*    });*/}
            {/*  }}*/}
            {/*  isOpen={this.state.confirmTooltipOpen === row.original.doctorId}*/}
            {/*>*/}
            {/*  Mark Doctor Active*/}
            {/*</Tooltip>*/}
          </div>
        );
      },
    },
  ];

  markDoctorActive = (doctorId) => {
    markDoctorActiveInAllPools(doctorId)
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Successfully activated doctor');
        } else {
          toast.failure(`Failure! ${res.errorMessage}`);
        }
      })
      .catch((err) => {
        toast.error(`Failure! ${err.errorMessage}`);
      })
      .finally(() => {
        this.getDoctorLiveStats();
      });
  };

  markDoctorInactive = (doctorId) => {
    markDoctorInactiveInAllPools(doctorId)
      .then((res) => {
        if (res.message === 'success') {
          toast.success('Successfully deactivated doctor');
        } else {
          toast.failure(`Failure! ${res.errorMessage}`);
        }
      })
      .catch((err) => {
        toast.error(`Failure! ${err.errorMessage}`);
      })
      .finally(() => {
        this.getDoctorLiveStats();
      });
  };

  render() {
    const { pages, loading, doctors } = this.state;

    return (
      <React.Fragment>
        <OuterComponent>
          <HeaderComponent>
            <HeaderElement>
              <h3>Doctor Live Stats</h3>
            </HeaderElement>
          </HeaderComponent>
          <div className="text-right mr-4">
            <DownloadCsv
              jsonArray={this.state.dataCsv}
              fileName={`doctor-stats`}
            />
          </div>
          <BodyComponent>
            <ReactTable
              filterable
              // sortable={false}
              style={{ flex: 1 }}
              data={doctors}
              collapseOnDataChange={false}
              columns={this.tableColumns}
              loading={loading}
              pages={pages}
              pageSizeOptions={[5, 10, 20, 25, 50, 100, 500]}
              manual
              onFetchData={this.onFetchData}
              showPaginationTop={true}
              className="-striped -highlight"
            />
          </BodyComponent>
        </OuterComponent>
      </React.Fragment>
    );
  }
}

export default withRouter(DoctorLiveStatsBody);
