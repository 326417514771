import React, { Component } from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Card, Col, Row, Spin, Typography, Select as AntSelect, Modal, Checkbox, Switch } from 'antd';
import Select, { createFilter } from 'react-select';

import {
  fetchDoctorSlots,
  fetchDoctorPracticeCenters,
  getDoctorSlots,
  deleteDoctorSlot,
  persistDoctorSlots,
  updateOnCallBasis,
  updatePracticeCenterActiveStatus,
  updateAppointmentType,
  updateShowSameDaySlotsStatus,
  updateTimeRange
} from '../../services/api/offline-consult';
import SlotsOverlappingModal from './SlotsOVerlappingModal'
import { hideDoctorSlotsEditModal } from '../../actions/index';

const { Title } = Typography;
const { Option } = AntSelect;

const dayOptions = [
  {
    value: '1',
    label: 'Sunday',
  },
  {
    value: '2',
    label: 'Monday',
  },
  {
    value: '3',
    label: 'Tuesday',
  },
  {
    value: '4',
    label: 'Wednesday',
  },
  {
    value: '5',
    label: 'Thursday',
  },
  {
    value: '6',
    label: 'Friday',
  },
  {
    value: '7',
    label: 'Saturday',
  },
];

const timeSlots = [
  { value: '06:00:00', label: '06:00 AM' },
  { value: '06:05:00', label: '06:05 AM' },
  { value: '06:10:00', label: '06:10 AM' },
  { value: '06:15:00', label: '06:15 AM' },
  { value: '06:20:00', label: '06:20 AM' },
  { value: '06:25:00', label: '06:25 AM' },
  { value: '06:30:00', label: '06:30 AM' },
  { value: '06:35:00', label: '06:35 AM' },
  { value: '06:40:00', label: '06:40 AM' },
  { value: '06:45:00', label: '06:45 AM' },
  { value: '06:50:00', label: '06:50 AM' },
  { value: '06:55:00', label: '06:55 AM' },
  { value: '07:00:00', label: '07:00 AM' },
  { value: '07:05:00', label: '07:05 AM' },
  { value: '07:10:00', label: '07:10 AM' },
  { value: '07:15:00', label: '07:15 AM' },
  { value: '07:20:00', label: '07:20 AM' },
  { value: '07:25:00', label: '07:25 AM' },
  { value: '07:30:00', label: '07:30 AM' },
  { value: '07:35:00', label: '07:35 AM' },
  { value: '07:40:00', label: '07:40 AM' },
  { value: '07:45:00', label: '07:45 AM' },
  { value: '07:50:00', label: '07:50 AM' },
  { value: '07:55:00', label: '07:55 AM' },
  { value: '08:00:00', label: '08:00 AM' },
  { value: '08:05:00', label: '08:05 AM' },
  { value: '08:10:00', label: '08:10 AM' },
  { value: '08:15:00', label: '08:15 AM' },
  { value: '08:20:00', label: '08:20 AM' },
  { value: '08:25:00', label: '08:25 AM' },
  { value: '08:30:00', label: '08:30 AM' },
  { value: '08:35:00', label: '08:35 AM' },
  { value: '08:40:00', label: '08:40 AM' },
  { value: '08:45:00', label: '08:45 AM' },
  { value: '08:50:00', label: '08:50 AM' },
  { value: '08:55:00', label: '08:55 AM' },
  { value: '09:00:00', label: '09:00 AM' },
  { value: '09:05:00', label: '09:05 AM' },
  { value: '09:10:00', label: '09:10 AM' },
  { value: '09:15:00', label: '09:15 AM' },
  { value: '09:20:00', label: '09:20 AM' },
  { value: '09:25:00', label: '09:25 AM' },
  { value: '09:30:00', label: '09:30 AM' },
  { value: '09:35:00', label: '09:35 AM' },
  { value: '09:40:00', label: '09:40 AM' },
  { value: '09:45:00', label: '09:45 AM' },
  { value: '09:50:00', label: '09:50 AM' },
  { value: '09:55:00', label: '09:55 AM' },
  { value: '10:00:00', label: '10:00 AM' },
  { value: '10:05:00', label: '10:05 AM' },
  { value: '10:10:00', label: '10:10 AM' },
  { value: '10:15:00', label: '10:15 AM' },
  { value: '10:20:00', label: '10:20 AM' },
  { value: '10:25:00', label: '10:25 AM' },
  { value: '10:30:00', label: '10:30 AM' },
  { value: '10:35:00', label: '10:35 AM' },
  { value: '10:40:00', label: '10:40 AM' },
  { value: '10:45:00', label: '10:45 AM' },
  { value: '10:50:00', label: '10:50 AM' },
  { value: '10:55:00', label: '10:55 AM' },
  { value: '11:00:00', label: '11:00 AM' },
  { value: '11:05:00', label: '11:05 AM' },
  { value: '11:10:00', label: '11:10 AM' },
  { value: '11:15:00', label: '11:15 AM' },
  { value: '11:20:00', label: '11:20 AM' },
  { value: '11:25:00', label: '11:25 AM' },
  { value: '11:30:00', label: '11:30 AM' },
  { value: '11:35:00', label: '11:35 AM' },
  { value: '11:40:00', label: '11:40 AM' },
  { value: '11:45:00', label: '11:45 AM' },
  { value: '11:50:00', label: '11:50 AM' },
  { value: '11:55:00', label: '11:55 AM' },
  { value: '12:00:00', label: '12:00 PM' },
  { value: '12:05:00', label: '12:05 PM' },
  { value: '12:10:00', label: '12:10 PM' },
  { value: '12:15:00', label: '12:15 PM' },
  { value: '12:20:00', label: '12:20 PM' },
  { value: '12:25:00', label: '12:25 PM' },
  { value: '12:30:00', label: '12:30 PM' },
  { value: '12:35:00', label: '12:35 PM' },
  { value: '12:40:00', label: '12:40 PM' },
  { value: '12:45:00', label: '12:45 PM' },
  { value: '12:50:00', label: '12:50 PM' },
  { value: '12:55:00', label: '12:55 PM' },
  { value: '13:00:00', label: '01:00 PM' },
  { value: '13:05:00', label: '01:05 PM' },
  { value: '13:10:00', label: '01:10 PM' },
  { value: '13:15:00', label: '01:15 PM' },
  { value: '13:20:00', label: '01:20 PM' },
  { value: '13:25:00', label: '01:25 PM' },
  { value: '13:30:00', label: '01:30 PM' },
  { value: '13:35:00', label: '01:35 PM' },
  { value: '13:40:00', label: '01:40 PM' },
  { value: '13:45:00', label: '01:45 PM' },
  { value: '13:50:00', label: '01:50 PM' },
  { value: '13:55:00', label: '01:55 PM' },
  { value: '14:00:00', label: '02:00 PM' },
  { value: '14:05:00', label: '02:05 PM' },
  { value: '14:10:00', label: '02:10 PM' },
  { value: '14:15:00', label: '02:15 PM' },
  { value: '14:20:00', label: '02:20 PM' },
  { value: '14:25:00', label: '02:25 PM' },
  { value: '14:30:00', label: '02:30 PM' },
  { value: '14:35:00', label: '02:35 PM' },
  { value: '14:40:00', label: '02:40 PM' },
  { value: '14:45:00', label: '02:45 PM' },
  { value: '14:50:00', label: '02:50 PM' },
  { value: '14:55:00', label: '02:55 PM' },
  { value: '15:00:00', label: '03:00 PM' },
  { value: '15:05:00', label: '03:05 PM' },
  { value: '15:10:00', label: '03:10 PM' },
  { value: '15:15:00', label: '03:15 PM' },
  { value: '15:20:00', label: '03:20 PM' },
  { value: '15:25:00', label: '03:25 PM' },
  { value: '15:30:00', label: '03:30 PM' },
  { value: '15:35:00', label: '03:35 PM' },
  { value: '15:40:00', label: '03:40 PM' },
  { value: '15:45:00', label: '03:45 PM' },
  { value: '15:50:00', label: '03:50 PM' },
  { value: '15:55:00', label: '03:55 PM' },
  { value: '16:00:00', label: '04:00 PM' },
  { value: '16:05:00', label: '04:05 PM' },
  { value: '16:10:00', label: '04:10 PM' },
  { value: '16:15:00', label: '04:15 PM' },
  { value: '16:20:00', label: '04:20 PM' },
  { value: '16:25:00', label: '04:25 PM' },
  { value: '16:30:00', label: '04:30 PM' },
  { value: '16:35:00', label: '04:35 PM' },
  { value: '16:40:00', label: '04:40 PM' },
  { value: '16:45:00', label: '04:45 PM' },
  { value: '16:50:00', label: '04:50 PM' },
  { value: '16:55:00', label: '04:55 PM' },
  { value: '17:00:00', label: '05:00 PM' },
  { value: '17:05:00', label: '05:05 PM' },
  { value: '17:10:00', label: '05:10 PM' },
  { value: '17:15:00', label: '05:15 PM' },
  { value: '17:20:00', label: '05:20 PM' },
  { value: '17:25:00', label: '05:25 PM' },
  { value: '17:30:00', label: '05:30 PM' },
  { value: '17:35:00', label: '05:35 PM' },
  { value: '17:40:00', label: '05:40 PM' },
  { value: '17:45:00', label: '05:45 PM' },
  { value: '17:50:00', label: '05:50 PM' },
  { value: '17:55:00', label: '05:55 PM' },
  { value: '18:00:00', label: '06:00 PM' },
  { value: '18:05:00', label: '06:05 PM' },
  { value: '18:10:00', label: '06:10 PM' },
  { value: '18:15:00', label: '06:15 PM' },
  { value: '18:20:00', label: '06:20 PM' },
  { value: '18:25:00', label: '06:25 PM' },
  { value: '18:30:00', label: '06:30 PM' },
  { value: '18:35:00', label: '06:35 PM' },
  { value: '18:40:00', label: '06:40 PM' },
  { value: '18:45:00', label: '06:45 PM' },
  { value: '18:50:00', label: '06:50 PM' },
  { value: '18:55:00', label: '06:55 PM' },
  { value: '19:00:00', label: '07:00 PM' },
  { value: '19:05:00', label: '07:05 PM' },
  { value: '19:10:00', label: '07:10 PM' },
  { value: '19:15:00', label: '07:15 PM' },
  { value: '19:20:00', label: '07:20 PM' },
  { value: '19:25:00', label: '07:25 PM' },
  { value: '19:30:00', label: '07:30 PM' },
  { value: '19:35:00', label: '07:35 PM' },
  { value: '19:40:00', label: '07:40 PM' },
  { value: '19:45:00', label: '07:45 PM' },
  { value: '19:50:00', label: '07:50 PM' },
  { value: '19:55:00', label: '07:55 PM' },
  { value: '20:00:00', label: '08:00 PM' },
  { value: '20:05:00', label: '08:05 PM' },
  { value: '20:10:00', label: '08:10 PM' },
  { value: '20:15:00', label: '08:15 PM' },
  { value: '20:20:00', label: '08:20 PM' },
  { value: '20:25:00', label: '08:25 PM' },
  { value: '20:30:00', label: '08:30 PM' },
  { value: '20:35:00', label: '08:35 PM' },
  { value: '20:40:00', label: '08:40 PM' },
  { value: '20:45:00', label: '08:45 PM' },
  { value: '20:50:00', label: '08:50 PM' },
  { value: '20:55:00', label: '08:55 PM' },
  { value: '21:00:00', label: '09:00 PM' },
  { value: '21:05:00', label: '09:05 PM' },
  { value: '21:10:00', label: '09:10 PM' },
  { value: '21:15:00', label: '09:15 PM' },
  { value: '21:20:00', label: '09:20 PM' },
  { value: '21:25:00', label: '09:25 PM' },
  { value: '21:30:00', label: '09:30 PM' },
  { value: '21:35:00', label: '09:35 PM' },
  { value: '21:40:00', label: '09:40 PM' },
  { value: '21:45:00', label: '09:45 PM' },
  { value: '21:50:00', label: '09:50 PM' },
  { value: '21:55:00', label: '09:55 PM' },
  { value: '22:00:00', label: '10:00 PM' },
  { value: '22:05:00', label: '10:05 PM' },
  { value: '22:10:00', label: '10:10 PM' },
  { value: '22:15:00', label: '10:15 PM' },
  { value: '22:20:00', label: '10:20 PM' },
  { value: '22:25:00', label: '10:25 PM' },
  { value: '22:30:00', label: '10:30 PM' },
  { value: '22:35:00', label: '10:35 PM' },
  { value: '22:40:00', label: '10:40 PM' },
  { value: '22:45:00', label: '10:45 PM' },
  { value: '22:50:00', label: '10:50 PM' },
  { value: '22:55:00', label: '10:55 PM' },
  { value: '23:00:00', label: '11:00 PM' },
  { value: '23:05:00', label: '11:05 PM' },
  { value: '23:10:00', label: '11:10 PM' },
  { value: '23:15:00', label: '11:15 PM' },
  { value: '23:20:00', label: '11:20 PM' },
  { value: '23:25:00', label: '11:25 PM' },
  { value: '23:30:00', label: '11:30 PM' },
  { value: '23:35:00', label: '11:35 PM' },
  { value: '23:40:00', label: '11:40 PM' },
  { value: '23:45:00', label: '11:45 PM' },
  { value: '23:50:00', label: '11:50 PM' },
  { value: '23:55:00', label: '11:55 PM' },
];

// const LabelText = styled(SemiboldText)`
//   font-size: 12px;
//   text-align: center;
//   margin-bottom: 0.25rem;
//   margin-top: 0.625rem;
//   color: #7c7c7c;
//   display: flex;
//   flex-shrink: 0;
//   flex-direction: row;
// `;

class EditDoctorSlots extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      slots: [],
      slotCenters: [],
      doctorPracticeId: null,
      networkCenterId: null,
      doctorPracticeActive: '',
      networkCenterTitle: '',
      networkCenterAddress: '',
      networkCenterLocality: '',
      emergencyNumber: '',
      description: '',
      opdCharges: '',
      latitude: '',
      longitude: '',
      formEditing: false,
      isOverlappingModalVisible: false,
      overlappingWarningData : ''
    };
    console.log({ props });

    this.doctorId = this.props.offlineConsult.doctorId;
    this.requestId = this.props.offlineConsult.requestId;
  }

  componentDidMount() {
    this.fetchSlots();
  }

  componentDidUpdate = (prevProps, prevState) => {
    // if (prevState.basicInfo.centerId !== this.state.basicInfo.centerId)
    //   this.getDoctorPriceChangeHistory();
  };

  getDoctorSlots = () => {
    const { requestId } = this.props.offlineConsult;
    getDoctorSlots({ requestId })
      .then((res) => {
        console.log({ res }, 'getDoctorSlots');
        this.setState({
          doctorSlots: res.data.slots,
        });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  fetchSlots = () => {
    this.setState({loading:true});
    Promise.all([
      fetchDoctorSlots(this.doctorId),
      fetchDoctorPracticeCenters(this.doctorId),
    ])
      .then(([res, centersRes]) => {
        const slots = res.data;
        // this.props.dispatch(setDoctorSlots({ slots }));
        this.setState({
          slots,
          slotCenters: centersRes.practices,
        });
      })
      .finally(() => this.setState({ loading: false }));
  };
  cancelEditing = () => {
    this.setState({
      formEditing: false,
      doctorPracticeId: null,
      networkCenterId: null,
      doctorPracticeActive: '',
      networkCenterTitle: '',
      networkCenterAddress: '',
      networkCenterLocality: '',
      emergencyNumber: '',
      description: '',
      opdCharges: '',
      latitude: '',
      longitude: '',
    });
  };

  onFromToDateChange = (date, dateString) => {
    console.log({ date, dateString });
    this.setState({
      fromDate: dateString[0],
      toDate: dateString[1],
    });
  };

  onPracticeActiveChange = e => {
    console.log(`checked = ${e.target.checked}`);
    this.setState({
      doctorPracticeActive: e.target.checked,
    });
    updatePracticeCenterActiveStatus(
      this.doctorId,
      this.state.doctorPracticeId,
      e.target.checked
    )
      .then(res => {
        console.log({ res });
        this.fetchPractice();
      })
      .catch(err => {
        console.log({ err });
      });
  };

  getStartAndEndTimeFromKey(key) {
    const slotTimes = key.match(/^\d+-([\d:]+)-([\d:]+)$/);
    const startTime = moment(slotTimes[1], 'HH:mm:ss');
    const endTime = moment(slotTimes[2], 'HH:mm:ss');
    return [startTime, endTime];
  }

  onDaysChanged = (key, centerName, centerId) => (value, action) => {
    console.log(key, value, action);
    const { slots } = this.state;
    const { doctorId } = this;
    const slotTimes = this.getStartAndEndTimeFromKey(key);
    const updatedTimingsArray = Array.from(slots);
    switch (action.action) {
      case 'pop-value':
      case 'remove-value':
        // Find the exact value and remove it
        this.setState({ saving: true });
        const slotIndex = slots.findIndex(ele => {
          console.log(
            ele.startTime,
            slotTimes[0].format('HH:mm:ss'),
            ele.endTime,
            slotTimes[1].format('HH:mm:ss'),
            ele.networkCenterId,
            centerId,
            ele.dow,
            action.removedValue.value
          );
          return (
            ele.startTime === slotTimes[0].format('HH:mm:ss') &&
            ele.endTime === slotTimes[1].format('HH:mm:ss') &&
            ele.networkCenterId === centerId &&
            ele.dow.toString() === action.removedValue.value
          );
        });
        if (slotIndex === -1) {
          return;
        }
        if (updatedTimingsArray[slotIndex].doctorSlotId) {
          deleteDoctorSlot(
            updatedTimingsArray[slotIndex].doctorSlotId,
            centerId,
            doctorId
          ).finally(() => {
            this.setState({ saving: false });
          });
        } else {
          this.setState({ saving: false });
        }
        updatedTimingsArray.splice(slotIndex, 1);
        this.setState({
          slots: updatedTimingsArray,
        });
        break;
      case 'select-option':
        updatedTimingsArray.push({
          doctorId,
          startTime: slotTimes[0].format('HH:mm:ss'),
          endTime: slotTimes[1].format('HH:mm:ss'),
          dow: Number.parseInt(action.option.value),
          networkCenterId: centerId,
          centerName,
        });
        this.setState({
          slots: updatedTimingsArray,
        });
        break;
      default:
        break;
    }
  };

  onSlotTypeChanged = (key, startTime, centerName, centerId) => (
    value,
    action
  ) => {
    console.log(key, startTime, value, action);
    const { slots } = this.state;
    const slotTimes = this.getStartAndEndTimeFromKey(key);
    switch (action.action) {
      case 'select-option':
        // Find all slots with these start and end time slots and modify them
        const updatedTimingsArray = slots.map(ele => {
          if (
            ele.startTime === startTime &&
            ele.endTime === slotTimes[1].format('HH:mm:ss') &&
            ele.networkCenterId === centerId
          ) {
            return Object.assign({}, ele, {
              slotType: value.value,
            });
          }
          return ele;
        });
        this.setState({ slots: updatedTimingsArray });
        break;
      default:
        break;
    }
  };

  onStartSlotChanged = (key, oldValue, centerName, centerId) => (
    value,
    action
  ) => {
    console.log(key, oldValue, value, action);
    const { slots } = this.state;
    const slotTimes = this.getStartAndEndTimeFromKey(key);
    switch (action.action) {
      case 'select-option':
        // Find all slots with these start and end time slots and modify them
        const updatedTimingsArray = slots.map(ele => {
          if (
            ele.startTime === oldValue &&
            ele.endTime === slotTimes[1].format('HH:mm:ss') &&
            ele.networkCenterId === centerId
          ) {
            return Object.assign({}, ele, {
              startTime: value.value,
            });
          }
          return ele;
        });
        this.setState({ slots: updatedTimingsArray });
        break;
      default:
        break;
    }
  };

  onEndSlotChanged = (key, oldValue, centerName, centerId) => (
    value,
    action
  ) => {
    console.log(key, oldValue, value, action);
    const { slots } = this.state;
    const slotTimes = this.getStartAndEndTimeFromKey(key);
    switch (action.action) {
      case 'select-option':
        // Find all slots with these start and end time slots and modify them
        const updatedTimingsArray = slots.map(ele => {
          if (
            ele.startTime === slotTimes[0].format('HH:mm:ss') &&
            ele.endTime === oldValue &&
            ele.networkCenterId === centerId
          ) {
            return Object.assign({}, ele, {
              endTime: value.value,
            });
          }
          return ele;
        });
        this.setState({ slots: updatedTimingsArray });
        break;
      default:
        break;
    }
  };

  addTimeSlot = (centerId, centerName) => () => {
    const { doctorId } = this;
    const { slots } = this.state;
    const updatedSlots = Array.from(slots);
    // Check if an existing first slot time exists
    let found = false;
    let startTimeSlot = '00:00:00';
    while (!found) {
      if (slots.findIndex(ele => ele.startTime === startTimeSlot) === -1) {
        found = true;
      } else {
        startTimeSlot = moment(startTimeSlot, 'HH:mm:ss')
          .add(5, 'minutes')
          .format('HH:mm:ss');
      }
    }
    updatedSlots.push({
      doctorId,
      startTime: startTimeSlot,
      endTime: moment(startTimeSlot, 'HH:mm:ss')
        .add(5, 'minutes')
        .format('HH:mm:ss'),
      dow: 2,
      networkCenterId: centerId,
      centerName,
    });
    this.setState({
      slots: updatedSlots,
    });
  };

  appointmentTypeHandler = (e, centerId) => {
    console.log(`appointmentTypeHandler: ${e}, ${centerId}`);
    const appointmentType = e.toString();
    const appointmentPayload = { appointmentType, centerId };
    console.log('\nappointment payload', appointmentPayload);

    // API to change appointment type
    updateAppointmentType(appointmentPayload)
        .then((resp) => {
          this.fetchSlots();
          const title = 'Successfully updated appointment type!';
          this.handleModalSuccess(title);
        })
        .catch((err) => {
          console.log(err);
        })
  }
  handleModalSuccess = (title) => {
    const seconds = 5;
    const modal = Modal.success({
      title: title,
    });

    setTimeout(() => {
      modal.destroy();
    }, seconds*1000);
  }
  handleSameDaySlotsChange = (e, centerId, previousToggleValue) => {
    const body = {
      "showSameDaySlot": e?1:0,
      "centerId": centerId,
      "doctorId": this.doctorId,
      "previousToggleValue": previousToggleValue
    }
    updateShowSameDaySlotsStatus(body)
      .then(() => {
        this.fetchSlots();
        const title = `Successfully updated showSameDaySlots Status!`;
        this.handleModalSuccess(title);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleTimeRangeChange = (e, centerId, previousTimeRangeValue) => {
    const body = {
      "leadTime": e,
      "centerId": centerId,
      "doctorId": this.doctorId,
      "previousTimeRangeValue": previousTimeRangeValue
    }
    updateTimeRange(body)
      .then(() => {
        this.fetchSlots();
        const title = `Successfully updated lead time range!`;
        this.handleModalSuccess(title);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  onCallToggleHandler = (value, centerId) => {
    const appointmentPayload = { value, centerId };
    console.log('\nappointment payload', appointmentPayload);
    updateOnCallBasis(appointmentPayload)
        .then(resp => {
          this.fetchSlots();
          const title = `Successfully updated on-call status`
          this.handleModalSuccess(title);
        })
        .catch((err) => {
          console.log(err);
        })

  }
  formatTime = (time) => {
    const [hour, minute, second] = time.split(':');
    const hours = parseInt(hour, 10);
    const minutes = parseInt(minute, 10);
  
    // Check if it's AM or PM
    const ampm = hours >= 12 ? 'pm' : 'am';
  
    // Convert hours to 12-hour format
    const formattedHour = hours % 12 === 0 ? 12 : hours % 12;
  
    // Add leading zeros to minutes if needed
    const formattedMinute = minutes < 10 ? `0${minutes}` : minutes;
  
    return `${formattedHour}:${formattedMinute} ${ampm}`;
  };

  getDayName = (dow) => {
    const dayNames = ['', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return dayNames[dow];
  };

  DynamicTable = ({ warningData }) => {
    // Function to render table rows dynamically based on data
    const renderTableRows = () => {
      return warningData.map((item, rowIndex) => (
        <tr key={rowIndex}>
          <td>{this.formatTime(item.startTime)}</td>
          <td>{this.formatTime(item.endTime)}</td>
          <td>{this.getDayName(item.dow)}</td>
        </tr>
      ));
    };
  
    return (
      <div>
        <h3>Overlapping slots Details</h3>
        <table style={{ width: '100%', borderCollapse: 'collapse', borderSpacing: '10px' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ccc', padding: '8px' }}>startTime</th>
              <th style={{ border: '1px solid #ccc', padding: '8px' }}>endTime</th>
              <th style={{ border: '1px solid #ccc', padding: '8px' }}>day</th>
            </tr>
          </thead>
          <tbody>{renderTableRows()}</tbody>
        </table>
      </div>
    );
  };

  //  showSlotOverlappingWarning = (warningData) => {
  //   console.log('inside showSlotOverlappingWarning');
  //   let errorTitle = 'Attention !!';
  //   let errorDesc = <div>
  //     <h3>The below slots were found overlapping and hence not added</h3>
  //     <div>{this.DynamicTable({ warningData })}</div>
  
  //   </div>;
  //   console.log(`error desc ${errorDesc}`);
  //   // store.dispatch({
  //   //   type: 'SHOW_ERROR',
  //   //   payload: {
  //   //     errorTitle: errorTitle,
  //   //     errorDesc: errorDesc,
  //   //   },
  //   // });
  //   Modal.success({
  //     title: errorTitle,
  //     content: errorDesc,
  //     // icon: (
  //     //   <Icon type="exclamation-circle" theme="twoTone" twoToneColor="#ff856a" />
  //     // ),
  //     keyboard: true,
  //     onOk: () => Promise.resolve(true),
  //   });
  // };

  

  submit = () => {
    const { slots } = this.state;
    console.log({ slots }, 'submit2');
    this.setState({ saving: true });
    persistDoctorSlots(this.doctorId, slots).then(async (resp) => {
      const responseMessage = resp.message;
      const responseData = resp.warningData;
    //   this.setState({ overlappingWarningData: responseData });
    //   console.log(`responseBody ${responseMessage}`);
    //   console.log(`responseData ${responseData}`);
    //   if (responseMessage === 'warning') {
    //     // return 'some slots are overlapping';
    //     console.log('inside warning');
    //     //this.showSlotOverlappingWarning(responseData);
    //     // <SlotsOverlappingModal warningData={responseData} />
    //    this.setState({ isOverlappingModalVisible: true });
    //     return;
    //   }
    return new Promise((resolve) => {
      this.setState({ overlappingWarningData: responseData }, () => {
        resolve(); // Resolve the Promise when the state update is complete
      });
    }).then(() => {
      // Any code that depends on the state update can be placed here
      console.log(`responseBody ${responseMessage}`);
        console.log(`responseData ${responseData}`);
        if (responseMessage === 'warning') {
          // return 'some slots are overlapping';
          console.log('inside warning');
          //this.showSlotOverlappingWarning(responseData);
          // <SlotsOverlappingModal warningData={responseData} />
         this.setState({ isOverlappingModalVisible: true });
          return;
        }
        this.props.dispatch(hideDoctorSlotsEditModal());
    });
    }).finally(() => {
      this.setState({ saving: false });
      // this.fetchSlots();
    });
  };

  componentWillUnsetMount() {
    // this.props.dispatch(unsetOfflineConsultRequestId());
  }

  render() {
    const { loading, slotCenters, slots, saving } = this.state;
    const appointmentTypeHandler = this.appointmentTypeHandler;
    const onCallToggleHandler = this.onCallToggleHandler;
    const uniqueCenterTimeSlots = {};
    const slotTypes = {};
    console.log(saving);
    slots.forEach(slot => {
      const key = `${slot.networkCenterId}-${slot.startTime}-${slot.endTime}`;
      slotTypes[key] = slot.slotType;
      if (uniqueCenterTimeSlots[key]) {
        uniqueCenterTimeSlots[key].push(slot.dow);
      } else {
        uniqueCenterTimeSlots[key] = [slot.dow];
      }
    });
    const renderCenterSlot = (centerName, centerId) => slotKey => {
      const selectedDaysForTimeSlot = [];
      uniqueCenterTimeSlots[slotKey].forEach(val =>
        selectedDaysForTimeSlot.push(dayOptions[val - 1])
      );
      const slotType = slotTypes[slotKey];
      const startTimeSlots = Array.from(timeSlots);
      const endTimeSlots = Array.from(timeSlots);

      slots.forEach(slot => {
        // Remove the already used startTime from the list of available start time slots
        const startSlotIndex = startTimeSlots.findIndex(
          ele =>
            ele.value === slot.startTime &&
            centerId.toString() === slot.networkCenterId.toString()
        );
        const endSlotIndex = endTimeSlots.findIndex(
          ele =>
            ele.value === slot.endTime &&
            centerId.toString() === slot.networkCenterId.toString()
        );
        if (startSlotIndex !== -1) {
          startTimeSlots.splice(startSlotIndex, 1);
        }
        if (endSlotIndex !== -1) {
          endTimeSlots.splice(endSlotIndex, 1);
        }
      });
      const slotTimes = this.getStartAndEndTimeFromKey(slotKey);
      const startTime = slotTimes[0];
      const endTime = slotTimes[1];
      return (
        <Row key={slotKey}>
          <Col xs={24} md={6}>
            <Select
              value={selectedDaysForTimeSlot}
              options={dayOptions}
              isMulti
              styles={{
                container: provided => ({
                  ...provided,
                  flex: 2,
                  margin: '0 5px',
                }),
              }}
              onChange={this.onDaysChanged(slotKey, centerName, centerId)}
            />
          </Col>
          <Col xs={24} md={6}>
          <Select
              value={{value: slotType, label: slotType === 'fixed'? 'Fixed' : 'Walk-in'}}
              options={[{ value:'fixed', label:'Fixed'}, {value: 'walk-in', label:'Walk-in'}]}
              styles={{
                container: provided => ({
                  ...provided,
                  flex: 2,
                  margin: '0 5px',
                }),
              }}
              onChange={this.onSlotTypeChanged(
                slotKey,
                startTime.format('HH:mm:ss'),
                centerName,
                centerId
              )}
            /></Col>
          <Col xs={24} md={6}>
            <Select
              value={{
                value: startTime.format('HH:mm:ss'),
                label: startTime.format('hh:mm A'),
              }}
              options={startTimeSlots}
              filterOption={createFilter({
                matchFrom: 'start',
              })}
              styles={{
                container: provided => ({
                  ...provided,
                  flex: 1,
                  margin: '0 5px',
                }),
              }}
              onChange={this.onStartSlotChanged(
                slotKey,
                startTime.format('HH:mm:ss'),
                centerName,
                centerId
              )}
            />
          </Col>
          <Col xs={24} md={6}>
            <Select
              value={{
                value: endTime.format('HH:mm:ss'),
                label: endTime.format('hh:mm A'),
              }}
              options={endTimeSlots}
              filterOption={createFilter({
                matchFrom: 'start',
              })}
              styles={{
                container: provided => ({
                  ...provided,
                  flex: 1,
                  margin: '0 5px',
                }),
              }}
              onChange={this.onEndSlotChanged(
                slotKey,
                endTime.format('HH:mm:ss'),
                centerName,
                centerId
              )}
              isOptionDisabled={option =>
                moment(option.value, 'HH:mm:ss').isSameOrBefore(startTime)
              }
            />
          </Col>
        </Row>
      );
    };
    const renderCenter = center => {
      let onCallStatus = false;
      if (center.onCall === '1') {
        onCallStatus = true;
      }
      console.log('\nonCallStatus', onCallStatus);
      // console.log('\nrender center', center);
      return (
        <Card style={{ overflow: 'visible' }} key={center.centerId}>
          <Row gutter={[32, 12]}>
            <Col span={24}>
              <Col span={24}>
                <Title level={4} style={{ margin: 0 }}>
                  {center.centerName}
                </Title>
                {center.centerAddress}
              </Col>
              <Row gutter={[28, 12]}>
                    <Col span={12}>
                      <Row gutter={[32,32]}>
                        <Col span={4}><label>Appointment Type</label></Col>
                        <Col span={6}>
                          <AntSelect value={ center.appointmentType } style={{ width: '100%' }} onChange={function(e) {
                            appointmentTypeHandler(e, center.centerId);
                          }}>
                            <Option value = "walk_in">Walk In</Option>
                            <Option value = "prior_appointment">Prior Appointment</Option>
                          </AntSelect>
                        </Col>
                        </Row>
                    </Col>
                    <Col span={3}>
                      <Checkbox checked={onCallStatus} onChange={function (e) {
                        const checked = e.target.checked;
                        const value = checked ? "1" : "0";
                        onCallToggleHandler(value, center.centerId);
                        }}>
                        On Call
                      </Checkbox>
                  </Col>
                  
              </Row>
              <Row>
              <Col span={2.5}> <label style = {{display:"inline-block", margin:"10px",fontWeight : 400, color: "black" }}>Same Day Slots</label></Col>

              <Col span={1} style ={{margin:"10px",marginLeft: "10px"}}>
                        <Switch checked={center.showSameDaySlots}
                        onChange={(e) => {
                          this.handleSameDaySlotsChange(e, center.centerId, center.showSameDaySlots);
                        }
                        }
                        />
                </Col>

              <Col span={2} style ={{margin:"10px"}}>
              <AntSelect value={center.showSameDaySlots ? center.leadTime + "Hours": "Select range"} style={{width: '100%' }} onChange={(e) => {
                            this.handleTimeRangeChange(e, center.centerId, center.leadTime);
                          }}
                          disabled={!center.showSameDaySlots}>
                            <Option value = "2">2 Hours</Option>
                            <Option value = "4">4 Hours</Option>
                            <Option value = "6">6 Hours</Option>
                          </AntSelect>
              </Col>

                {/* <Col span={12}>
                      <Row gutter={[32,32]}>
                        <Col span={4}><label>Time Range</label></Col>
                        <Col span={6}>
                          <AntSelect value={ center.leadTime + "Hours" } style={{ width: '100%' }} onChange={(e) => {
                            this.handleTimeRangeChange(e, center.centerId);
                          }}>
                            <Option value = "2">2 Hours</Option>
                            <Option value = "4">4 Hours</Option>
                            <Option value = "6">6 Hours</Option>
                          </AntSelect>
                        </Col>
                        </Row>
                    </Col> */}
              </Row>
              </Col>
          </Row>

          {Object.keys(uniqueCenterTimeSlots)
            .filter(ele => ele.startsWith(`${center.centerId}-`))
            .map(renderCenterSlot(center.centerName, center.centerId))}
          <Button
            type="primary"
            block
            size="large"
            style={{ marginTop: '10px' }}
            onClick={this.addTimeSlot(center.centerId, center.centerName)}
          >
            Add Slot
            </Button>
        </Card>
      );
    };
    return (
      <div>
        <div>{this.state.isOverlappingModalVisible && (
          <SlotsOverlappingModal warningData={this.state.overlappingWarningData} modalIsOpen = {this.state.isOverlappingModalVisible} closeModal = {()=>{this.setState({isOverlappingModalVisible: false})}} />
        )}</div>
      <Row gutter={[24, 24]} className='doctor-slots'>
        <Spin spinning={loading || saving}>
          {slotCenters.length > 0 && slotCenters.map(renderCenter)}
          <Col span={1}>
            <Button type="primary" onClick={this.submit}>
              Save
            </Button>
          </Col>
        </Spin>
      </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  offlineConsult: state.offlineConsult,
});

export default connect(mapStateToProps)(EditDoctorSlots);
