import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table } from 'reactstrap';
import { debounce } from '../../utils/debounce';
import { toast } from 'react-toastify';
import { MdOpenInBrowser, MdCloudUpload, MdCloudDownload } from "react-icons/md";
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import { getOrders } from "../../services/api/labs-reconcile-api";
import ReactTable from 'react-table';
import selectTableHOC from "react-table/lib/hoc/selectTable";
const SelectTreeTable = selectTableHOC((ReactTable));


class LabsReconcileTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            pages: -1,
            filtered: [],
            sorted: [],
            pageSize: 50,
            page: 0,
            orders: [],
            selectAll: false,
            selectedRows: [],
        };
        this.filtering = true;


        this.fetchStrategy = this.fetchStrategy.bind(this);

        this.fetchData = this.fetchData.bind(this);
        this.fetchDataWithDebounce = debounce(this.fetchData, 300);
        console.log('Lab PartnerId: ', this.props.labPartnerId);
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('New props vs old props', prevProps, this.props);
        if (this.props.startDate === null || this.props.endDate === null) {
            return ;
        }
        if (prevProps.startDate !== this.props.startDate || prevProps.endDate !== this.props.endDate) {
            let stDate = 0, enDate = 0;
            if (this.props.startDate !== null) {
                stDate = this.props.startDate.startOf('day').unix();
            }
            if (this.props.endDate !== null) {
                enDate = this.props.endDate.endOf('day').unix();
            }

            this.updateStateFilters('st', stDate, 'et', enDate);
        }
    }

    updateStateFilters = (id, value, id2, value2) => {
        console.log('Update all filters in state', {id, value, id2, value2});
        // console.log('New filter values', moment(value).format('DD-MM-YYYY'), moment(value2).format('DD-MM-YYYY'));
        const filter = this.state.filtered.find(f => f.id === id);
        const filter2 = this.state.filtered.find(f => f.id === id2);
        if(filter) {
            filter.value = value;
        } else {
            this.state.filtered.push({id, value});
        }
        if(filter2) {
            filter2.value = value2;
        } else {
            this.state.filtered.push({id: id2, value: value2});
        }

        this.setState({ filtered: JSON.parse(JSON.stringify(this.state.filtered))});
    };

    onFilteredChange = (e) => {
        this.setState({filtered: e});
    };

    fetchStrategy = state => this.fetchDataWithDebounce(state);

    prepareApiFilters = () => {
        const appliedFilters = {};
        // let appliedOrderBy = {};
        // Convert this list of filterables into object properties
        this.state.filtered.forEach(
            filter => (appliedFilters[filter.id] = filter.value),
        );
        // state.sorted.forEach(sort => appliedOrderBy[sort.id] = sort.desc?'desc':'asc');

        appliedFilters.rowsPerPage = this.state.pageSize;
        appliedFilters.page = this.state.page + 1;
        return appliedFilters;
    };

    fetchData = (state, instance) => {
        // show the loading overlay
        this.setState({ loading: true });
        this.filtering = false;


        const appliedFilters = this.prepareApiFilters();

        // fetch your data
        getOrders(appliedFilters, state.sorted)
        .then(response => {
            console.log('Api response', response.body);
            const pages =
                response.body.totalCount % appliedFilters.rowsPerPage === 0
                    ? parseInt(
                    response.body.totalCount / appliedFilters.rowsPerPage,
                    )
                    : parseInt(
                    response.body.totalCount / appliedFilters.rowsPerPage,
                ) + 1;
            this.setState({
                orders: response.body.orders,
                loading: false,
                pages,
                selectedRows: [],
            });
            this.props.setPartnerTransactionsSummary(response.body.summary);
        })
        .catch(err => {
            console.error(err, 'Error in get labs orders API');
            toast.error(`Error in fetching orders`);
        });
    };


    getColumns = () => [
        {
            Header: 'Visit Order Details',
            columns: [
                {
                    Header: 'Visit ReferenceId',
                    accessor: 'ourReference',
                    width: 75,
                },
                {
                    Header: 'Order ID',
                    accessor: 'orderId',
                    width: 100,
                },
                {
                    Header: 'Date',
                    accessor: 'date',
                    width: 100,
                },
                {
                    Header: 'Test Name',
                    accessor: 'testName',
                    width: 100,
                },
                {
                    Header: 'Test Code',
                    accessor: 'testCode',
                    width: 75,
                },
                {
                    Header: 'Visit MRP',
                    accessor: 'visitMrp',
                    width: 50,
                },
                {
                    Header: 'Visit Cost',
                    accessor: 'visitCost',
                    width: 50,
                },

            ],
        },
        {
            Header: 'Partner Order Details',
            columns: [
                {
                    Header: 'Partner Test Code',
                    accessor: 'partnerTestCode',
                    width: 75,
                },
                {
                    Header: 'Partner MRP',
                    accessor: 'partnerMrp',
                    width: 120,
                },
                {
                    Header: 'Partner Cost',
                    accessor: 'partnerCost',
                    width: 100,
                },
            ],
        },
        {
            Header: 'Difference[Visit-Partner]',
            columns: [
                {
                    Header: 'MRP Diff',
                    accessor: 'diffMrp',
                    width: 75,
                },
                {
                    Header: 'Cost Diff',
                    accessor: 'diffCost',
                    width: 75,
                },
            ],
        },
        {
            Header: 'Actions',
            columns: [
                {
                    Header: 'Category',
                    accessor: 'category',
                    filterable: true,
                    width: 75,
                    filterMethod: (filter, row) => {
                        if (filter.value === 'all') {
                            return true;
                        }
                        return row.status === filter.value;
                    },
                    Filter: ({ filter, onChange }) => {
                        return (
                            <select
                                onChange={event => onChange(event.target.value)}
                                style={{ width: '100%' }}
                                value={filter ? filter.value : 'all'}
                            >
                                <option value="all">Show All</option>
                                <option value="IncorrectCost">IncorrectCost</option>
                                <option value="VisitMissingTransaction">VisitMissingTransaction</option>
                                <option value="PartnerMissingTransaction">PartnerMissingTransaction</option>
                            </select>
                        );
                    },
                },
            ],
        },
    ];

    selectAllRows = () => {

    };

    toggleRowSelection = (id) => {
        // console.log(id, 'select this');
        // start off with the existing state
        let selection = [...this.state.selectedRows];
        const keyIndex = selection.indexOf(id);

        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(id);
        }
        // update the state
        this.setState({ selectedRows: selection });
    };

    render() {
        return (
            <SelectTreeTable
                keyField="id"
                data={this.state.orders}
                columns={this.getColumns()}
                defaultPageSize={50}

                pages={this.state.pages}
                pageSize={this.state.pageSize}
                page={this.state.page}

                loading={this.state.loading}
                filtered={this.state.filtered}
                sorted={this.state.sorted}

                manual

                onFetchData={this.fetchStrategy}
                onFilteredChange={this.onFilteredChange}

                onPageSizeChange={pageSize => this.setState({ pageSize })}
                onPageChange={page => this.setState({ page })}
                onSortedChange={sorted => this.setState({ sorted })}

                className="-striped -highlight"


                showPaginationTop
                showPaginationBottom
                SubComponent={this.getSubComponent}

                isSelected={(key)=>{return this.state.selectedRows.includes(`select-${key}`);}}
                selectAll={this.state.selectAll}
                toggleAll={this.selectAllRows}
                toggleSelection={this.toggleRowSelection}
                selectType={'checkbox'}
            />
        );
    }
}

function mapStateToProps(state) {
    return {  };
}

export default connect(mapStateToProps)(LabsReconcileTable);
