import { store } from '../../store';
import baseRequest, { baseRequestWithAxiosResponse } from './baseRequest';

export function fetchUserCart(cartId, isOPSTeam) {
  const query = isOPSTeam ? '?IS_OPS=true' : '';
  return baseRequestWithAxiosResponse(
    `/labs/order/summary/${cartId}${query}`,
    'GET'
  )
    .then((response) => {
      console.log('response cart', response);
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function labTestSearch(needle, testsType) {
  return baseRequestWithAxiosResponse(
    `/labs/dashboard/all?bookCode=true&s=${needle}&labPartner=true&testsType=${testsType}`,
    'GET'
  )
    .then((response) => {
      console.log('labTestSearch response', response);
      if (response.data.message === 'success') return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function markCartReady(cartId, userId) {
  return baseRequestWithAxiosResponse(
    `/labs/ops/transcribe/save-cart?userId=${userId}`,
    'POST',
    null,
    {
      requestId: cartId,
    }
  )
    .then((response) => {
      console.log('markCartReady response', response);
      if (response.data.message === 'success') {
        return response.data;
      } else {
        return response;
      }
    })
    .catch((err) => {
      throw err;
    });
}

export function markCartAsNonServiceable(cartId, remark, userId) {
  return baseRequestWithAxiosResponse(
    `/labs/ops/transcribe/cancel-request?userId=${userId}`,
    'POST',
    null,
    {
      requestId: cartId,
      remark,
    }
  )
    .then((response) => {
      console.log('markCartAsNonServiceable response', response);
      if (response.data.message === 'success') return response.data;
      return Promise.reject('Unable to mark cart as non serviceable');
    })
    .catch((err) => {
      throw err;
    });
}

export function addLabTestsToCart(data) {
  return baseRequestWithAxiosResponse(
    `/labs/ops/add-to-cart`,
    'POST',
    null,
    data
  )
    .then((response) => {
      console.log('addLabTestsToCart response', response);
      if (response.data.message === 'success') return response.data;
      return Promise.reject('Unable to add lab test to cart');
    })
    .catch((err) => {
      throw err;
    });
}

export function fetchOrderActivities(orderId) {
  return baseRequestWithAxiosResponse(
    `/new-ops/labs-orders/${orderId}/activities`
  ).then((response) => response.data);
}

export function removeLabTestsFromCart(data) {
  return baseRequestWithAxiosResponse(
    '/labs/ops/remove-from-cart',
    'POST',
    null,
    data
  )
    .then((response) => {
      console.log('removeLabTestsToCart response', response);
      if (response.data.message === 'success') return response.data;
      return Promise.reject('Unable to remove lab test from cart');
    })
    .catch((err) => {
      throw err;
    });
}

export function labOrderSummary(cartId, isOPSTeam, userId) {
  const query = isOPSTeam
    ? `?IS_OPS=true&userId=${userId}`
    : `?userId=${userId}`;
  return baseRequestWithAxiosResponse(
    `/labs/order/summary/${cartId}${query}`,
    'GET'
  )
    .then((response) => {
      console.log('labOrderSummary response', response);
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function fetchUserInfo(cartId, userId) {
  return baseRequestWithAxiosResponse(
    `/labs/ops/transcribe/userInfo/${cartId}?userId=${userId}`,
    'GET'
  )
    .then((response) => {
      console.log('fetchUserInfo response', response);
      if (response.data.message === 'success') return response.data;
      return Promise.reject('Unable to fetch user info');
    })
    .catch((err) => {
      throw err;
    });
}

export function fetchPastOfflineConsultations(userId) {
  return baseRequestWithAxiosResponse(
    `/labs/ops/transcribe/past-appointments?userId=${userId}`,
    'GET'
  )
    .then((response) => {
      console.log('fetchPastOfflineConsultations response', response);
      if (response.data.message === 'success') return response.data;
      return Promise.reject('Unable to fetch past offline consultations');
    })
    .catch((err) => {
      throw err;
    });
}

export function giveOpdBenefits(cartId, consultationId, doctorId, diagnosis) {
  return baseRequestWithAxiosResponse(
    '/labs/ops/transcribe/give-opd-benefits',
    'POST',
    null,
    {
      cartId,
      consultationId,
      doctorId,
      diagnosis,
    }
  )
    .then((response) => {
      console.log('giveOpdBenefits response', response);
      if (response.data.message === 'success') return response.data;
      return Promise.reject('Unable to provide OPD benefits');
    })
    .catch((err) => {
      throw err;
    });
}

export function fetchTransactionDetails(cartId) {
  return baseRequestWithAxiosResponse(
    `/labs/ops/transcribe/check/lab-transaction/${cartId}`,
    'GET'
  )
    .then((response) => {
      console.log('fetchTransactionDetails response', response);
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}
export function insertTransaction(amount, cartId) {
  return baseRequestWithAxiosResponse(
    `/labs/transact?cartId=${cartId}&amount=${amount}`,
    'GET'
  )
    .then((response) => {
      console.log('insertTransaction response', response);
      if (response.data.message === 'success') return response.data;
      return Promise.reject('Unable to insert transaction details');
    })
    .catch((err) => {
      throw err;
    });
}

export function markOrderIdSuccess(orderId) {
  return baseRequestWithAxiosResponse('/labs/buyLabsCallback', 'POST', null, {
    orderId,
    status: 'SUCCESS',
  })
    .then((response) => {
      console.log('markOrderIdSuccess response', response);
      if (response.data.message === 'success') return response.data;
      return Promise.reject('Unable to mark orderId successful');
    })
    .catch((err) => {
      throw err;
    });
}

export function checkCartStatusForVendor(cartId, userId) {
  return baseRequestWithAxiosResponse(
    `/labs/transcription/status/check/${cartId}?userId=${userId}`,
    'GET'
  )
    .then((response) => {
      console.log('checkCartStatusForVendor response', response);
      if (response.data.message === 'success') return response.data.status;
      return Promise.reject(response.data.message);
    })
    .catch((err) => {
      console.log('checkCartStatusForVendor', err);
      return Promise.reject(err);
    });
}

export function saveRemarkForOps(cartId, remark, remarkFrom) {
  return baseRequestWithAxiosResponse(
    '/labs/lab-cart/save/remark',
    'POST',
    null,
    {
      cartId,
      remark,
      remarkFrom,
    }
  )
    .then((response) => {
      console.log('saveRemarkForOps response', response);
      if (response.data.message === 'success') return response.data.message;
      return Promise.resolve(response.data.message);
    })
    .catch((err) => {
      throw err;
    });
}

export function insertNewLabTest(
  labMrp,
  labName,
  labCode,
  labCost,
  cartId,
  partnerId,
  type
) {
  return baseRequestWithAxiosResponse(
    '/labs/lab-cart/save/new-lab-test',
    'POST',
    null,
    {
      labMrp,
      labName,
      labCode,
      labCost,
      cartId,
      partnerId,
      type,
    }
  )
    .then((response) => {
      console.log('insertNewLabTest response', response);
      if (response.data.message === 'success') return response.data.cartItem;
      return Promise.reject(response.data.message);
    })
    .catch((err) => {
      throw err;
    });
}

export function createNewCartForRadiologyTests(
  cartId,
  prescriptionFileUrl,
  userId
) {
  return baseRequestWithAxiosResponse(
    `/labs/create/lab-cart/radiology?userId=${userId}`,
    'POST',
    null,
    {
      cartId,
      prescriptionFileUrl,
    }
  )
    .then((response) => {
      console.log('createNewCartForRadiologyTests response', response);
      if (response.data.message === 'success') return response.data.message;
      return Promise.reject(response.data.message);
    })
    .catch((err) => {
      throw err;
    });
}

export function fetchAllLabPartners(type) {
  return baseRequestWithAxiosResponse(`/labs/list/partners?type=${type}`, 'GET')
    .then((response) => {
      console.log('fetchAllLabPartners response', response);
      if (response.data.message === 'success') return response.data;
      return response.data.message;
    })
    .catch((err) => {
      throw err;
    });
}

export function insertNewLabPartner(labPartnerName, labPartnerEmails) {
  return baseRequestWithAxiosResponse('/labs/add/new/partner', 'POST', null, {
    partnerName: labPartnerName,
    partnerEmails: labPartnerEmails,
  })
    .then((response) => {
      console.log('insertNewLabPartner response', response);
      if (response.data.message === 'success') return response.data;
      return Promise.reject(response.data.message);
    })
    .catch((err) => {
      throw err;
    });
}

export function changeCartsTestsType(cartId, testsType) {
  return baseRequestWithAxiosResponse(
    '/labs/update/lab-cart/to-radiology',
    'POST',
    null,
    {
      cartId,
      testsType,
    }
  )
    .then((response) => {
      console.log('changeCartsTestsType response', response);
      return response.data.message;
    })
    .catch((err) => {
      throw err;
    });
}

export function selectPartner(partnerId, cartId) {
  return baseRequestWithAxiosResponse(
    '/labs/ops/select/partner',
    'POST',
    null,
    {
      cartId,
      partnerId,
    }
  )
    .then((response) => {
      console.log('changeCartsTestsType response', response);
      return response.data.message;
    })
    .catch((err) => {
      throw err;
    });
}

export function uploadLabReport(file, cartId, type) {
  const formData = new FormData();
  console.log('before api call checking file', file, cartId, type);
  formData.append('file', file);
  formData.append('cartId', cartId);
  formData.append('type', type);

  return baseRequestWithAxiosResponse(
    '/labs/upload/lab/report',
    'POST',
    null,
    formData
  );
}

export function markSamplePicked(cartId, isSamplePicked) {
  return baseRequestWithAxiosResponse(
    '/labs/ops/update/sample-picked',
    'POST',
    null,
    {
      cartId,
      isSamplePicked,
    }
  )
    .then((response) => {
      console.log('markSamplePicked response', response);
      return response.data.message;
    })
    .catch((err) => {
      throw err;
    });
}

export function getLabsCartStatus(cartId, userId) {
  return baseRequestWithAxiosResponse(
    `/labs/ops/lab-order/status?cartId=${cartId}?userId=${userId}`,
    'GET'
  )
    .then((response) => {
      console.log('getLabsCartStatus response', response);
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function getPostTransactionCart(cartId, userId) {
  return baseRequestWithAxiosResponse(
    `/labs/ops/lab-order/post-transaction/summary/${cartId}?userId=${userId}`,
    'GET'
  )
    .then((response) => {
      console.log('getPOstTransactionCart response', response);
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function sendPartnerComms(orderId, type, meta) {
  return baseRequestWithAxiosResponse(
    `/new-ops/labs-orders/${orderId}/send-partner-communication`,
    'POST',
    null,
    { ...meta, type }
  )
    .then((response) => {
      console.log('sendPartnerComms response', response);
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
}

export function addCopay(orderId, items, reasonId) {
  return baseRequest(
    `/new-ops/labs-orders/${orderId}/update-pricing`,
    'POST',
    null,
    { items, reasonId }
  );
}

export function computeFinalCopay(orderId, items) {
  return baseRequest(`/new-ops/labs-orders/compute-pricing`, 'POST', null, {
    orderId,
    items,
  });
}

export function getPartnersForOrder(orderId, addressId, cartId) {
  return baseRequest(
    `/new-ops/labs-orders/${orderId}/partners`,
    'GET',
    { addressId, cartId },
    null
  );
}

export function updatePartnerForOrder(orderId, partnerId, meta, reasonId) {
  return baseRequest(
    `/new-ops/labs-orders/${orderId}/change-partner`,
    'POST',
    null,
    { partnerId, meta, reasonId }
  );
}

export function fetchAddressForOrder(orderId) {
  return baseRequest(
    `/new-ops/labs-orders/${orderId}/addresses`,
    'GET',
    null,
    null
  );
}

export function getCovidPartners() {
  return baseRequest(`/new-ops/labs-orders/covid-partners`, 'GET', null, null);
}

export function updatePartnerForBulkOrder(orderIds, partnerId, reasonId) {
  return baseRequest(
    `/new-ops/labs-orders/change-bulk-partners`,
    'POST',
    null,
    { orderIds, partnerId, reasonId }
  );
}

export const getLabOrdersTimeline = (orderId, userId) => {
  const state = store.getState();

  return baseRequest(
    `/new-ops/dashboard-timeline-ops?orderId=${
      orderId ? orderId : 0
    }&userId=${userId}`
  );
};

export const submitOrderStatus = (body) => {
  return baseRequestWithAxiosResponse(
    '/new-ops/labs-orders/change-status-to-prev-status',
    'POST',
    null,
    body
  );
};
