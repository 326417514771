import React, { useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import crossIcon from '../../images/crossIcon.svg';
import '../../styling/doctor.css';
import {
  reactSelectStyle,
  reactSelectStyleSingle,
} from '../common/CustomStyles';
import { weekday, time } from '../../constant';

function TimeSelector({ slotData, setSlotData, index, CancelSlot }) {
  const handleWeekdayChange = (selectedOptions) => {
    setSlotData((prevSlotData) =>
      prevSlotData.map((slot, i) =>
        i === index ? { ...slot, weekDay: selectedOptions } : slot
      )
    );
  };

  const handleStartTimeChange = (selectedOption) => {
    setSlotData((prevSlotData) =>
      prevSlotData.map((slot, i) =>
        i === index ? { ...slot, startTime: selectedOption.value } : slot
      )
    );
  };

  const handleEndTimeChange = (selectedOption) => {
    setSlotData((prevSlotData) =>
      prevSlotData.map((slot, i) =>
        i === index ? { ...slot, endTime: selectedOption.value } : slot
      )
    );
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div className="doctorSlot-main">
        <ReactSelect
          isMulti
          options={weekday}
          className="doctorSlot-weekday"
          defaultValue={slotData[index].weekDay}
          onChange={handleWeekdayChange}
          styles={{
            ...reactSelectStyle,
            }}
        />
        <ReactSelect
          options={time}
          className="doctorSlot-time"
          defaultValue={time.find(
            (option) => option.value === slotData[index].startTime
          )}
          onChange={handleStartTimeChange}
          styles={{
            ...reactSelectStyleSingle,
            height: '320px',
            maxheight: '320px',
          }}
        />
        <ReactSelect
          options={time.filter((e) => e.value > slotData[index].startTime)}
          className="doctorSlot-time"
          defaultValue={time.find(
            (option) => option.value === slotData[index].endTime
          )}
          onChange={handleEndTimeChange}
          styles={{
            ...reactSelectStyleSingle,
            }}
        />
        <div className="doctorSlot-cross">
          <img
            src={crossIcon}
            onClick={() => {
              CancelSlot(index);
            }}
          />
        </div>
      </div>
    </div>
  );
}
export default TimeSelector;
