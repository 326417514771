import React, { Component } from 'react';

import { overridePaymentStatus } from '../../../services/api/online-payment';

class ChangePayoutStatusButton extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { value, onChange } = this.props;
        return (
            <React.Fragment>
                <select value={value} onChange={onChange}>
                    <option value={0.5}>Half</option>
                    <option value={1}>Full</option>
                    <option value={0}>No Payout</option>
                </select>
            </React.Fragment>
        );
    }
}

export default ChangePayoutStatusButton;
