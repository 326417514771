import React, { Component } from "react";
import OnlinePaymentTable from './table/OnlinePaymentTable';

export default class OnlinePaymentBody extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentDidMount() {

    }

    handleDateChange = () => {

    }

    render() {
        return (
            <React.Fragment>
                <OnlinePaymentTable />
            </React.Fragment>
        );
    }
};
