import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { markLabRequestNotServiceable} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { updatePartnerOrderId } from "../../services/api/labs-reconcile-api";
class LabsConflictingUpdatePartnerOrderId extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            partnerOrderId: null,
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    };

    update = () => {
        if (this.state.partnerOrderId === null || this.state.partnerOrderId.trim().length === 0) {
            return;
        }
        updatePartnerOrderId(this.props.orderId, this.props.partnerTestCode, this.state.partnerOrderId)
            .then((result) => {
                if(result.body.message === 'success') {
                    this.toggle();
                    this.props.refresh();
                } else {
                    toast.error('Unable to update partner order id');
                }
            })
            .catch((err) => {
                toast.error('Unable to update partner order id');
                console.error('Error', err);
            });
    };



    render() {
        return (
            <div>
                <button onClick={this.toggle} className="btn btn-sm btn-outline-danger">Update</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <h4 className="mt-1 mb-0 ml-2">Enter the partner's order ID under which this test was booked</h4>
                    <ModalBody>
                        <input onChange={(e) => this.setState({partnerOrderId: e.target.value})}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.update}>Update</Button>
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(LabsConflictingUpdatePartnerOrderId);





