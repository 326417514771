import baseRequest from "./baseRequest"

export const getCashlessDentalRequests = (params = {}) => {
    const url = '/cashless-dental/mdp/new-ops/allRequests'
    const method = 'GET'
    return baseRequest(url, method, params, null)
}

export const getDentalRequests = (requestId) => {
    const url = `/cashless-dental/mdp/new-ops/${requestId}/details`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const getDentalRequestComments = (requestId) => {
    console.log(requestId,'[getDentalRequestComments]')
    const url = `/cashless-dental/mdp/new-ops/get-comments/${requestId}`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const addCommentToRequest = (requestId, comment) => {
    const url = `/cashless-dental/mdp/new-ops/comments/${requestId}`;
    const method = 'POST';
    const data = {comment}
    return baseRequest(url, method, null, data);
};

export const getProcedures = (requestId) => {
    const url = `/cashless-dental/mdp/new-ops/get-procedures/${requestId}`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const addProcedureRequest = (requestId, procedureId, procedureFee) => {
    const url = `/cashless-dental/mdp/new-ops/add-procedure-request`;
    const method = 'POST';
    const data = {requestId, procedureId, procedureFee}
    return baseRequest(url, method, null, data);
};

export const deductWalletBalance = (requestId, procedureAmount, requestProcedureId, procedureId) => {
    const url = `/cashless-dental/mdp/new-ops/wallet-deduction`;
    const method = 'POST';
    const data = {requestId, procedureAmount, requestProcedureId, procedureId}
    return baseRequest(url, method, null, data);
};

export const callPatient = (requestId) => {
    const url = `/cashless-dental/mdp/new-ops/${requestId}/call/user`;
    const method = 'POST';
    return baseRequest(url, method, null, null);
};

export const markProcedureRemoved = (requestId, requestProcedureId) => {
    const url = `/cashless-dental/mdp/new-ops/mark-procedure-removed`;
    const method = 'POST';
    const data =  {requestId, requestProcedureId};
    return baseRequest(url, method, null, data);
}

export const uploadPrescription = (formData) => {
    const url = `/cashless-dental/mdp/new-ops/users/upload-prescription`;
    const method = 'POST';
    const data = formData;
    return baseRequest(url, method, null, data);
};

export const getDentalHistory = (requestId) => {
    const url = `/cashless-dental/mdp/new-ops/request-history/${requestId}`;
    const method = 'GET';
    return baseRequest(url, method, null, null);
};

export const cancelDentalModal = (appointmentId, reason, ) => {
    const url = `/cashless-dental/mdp/new-ops/cancel-appointment`;
    const method = 'POST';
    const data = {
        reason,
        appointmentId
    }
    return baseRequest(url, method, null, data);
};

export const refundProcedureAmount = (requestId, procedureRequestsId) => {
    const url = `/cashless-dental/mdp/new-ops/refund-procedure`;
    const method = 'POST';
    const data = {
        requestId, procedureRequestsId
    }
    return baseRequest(url, method, null, data);
};

export const markAppointmentComplete = (requestId) => {
    const url = `/cashless-dental/mdp/new-ops/mark-appointment-complete`;
    const method = 'POST';
    const data = {
        requestId
    }
    return baseRequest(url, method, null, data);
};