import React from 'react'

const AllOptions = () => (
    <React.Fragment>
        <option value=''>All</option>
        <option value='request-received'>Request Received</option>
        <option value='request-confirmed'>Request Confirmed</option>
        <option value='request-cancelled'>Request Cancelled</option>
        <option value='payout-completed'>Payout Completed</option>
    </React.Fragment>
)

const StatusFilter = ({ value, onChange }) => {

    const changeFilterType = (event) => {
        onChange(event.target.value === '' ? null : event.target.value)
    }

    return <select
        onChange={changeFilterType}
        style={{
            width: "100%",
            height: "40px",
            fontSize: "12px"
        }}
        value={value || ''}
    >
        <AllOptions />
    </select>
}

export default StatusFilter