import React, { Component } from 'react';

import { Toast, ToastBody, ToastHeader } from 'reactstrap';
class ResultToast extends Component {
  toggle = () => {
    const { onToggle } = this.props;
    onToggle && onToggle();
  };

  toast = () => {
    if (this.props.confirmRequests) {
      return (
        <div className="p-3 my-2 rounded">
          <Toast isOpen={this.props.show}>
            <ToastHeader toggle={this.toggle}>Confirm Requests</ToastHeader>
            <ToastBody>Confirm requests is send.</ToastBody>
          </Toast>
        </div>
      );
    } else if (this.props.altRequests) {
      return (
        <div className="p-3 my-2 rounded">
          <Toast isOpen={this.props.show}>
            <ToastHeader toggle={this.toggle}>
              Confirm Alternate Request
            </ToastHeader>
            <ToastBody>Confirm Alternate Request is send.</ToastBody>
          </Toast>
        </div>
      );
    } else if (this.props.cancelRequests) {
      return (
        <div className="p-3 my-2 rounded">
          <Toast isOpen={this.props.show}>
            <ToastHeader toggle={this.toggle}>Cancel request</ToastHeader>
            <ToastBody>Cancel request is done.</ToastBody>
          </Toast>
        </div>
      );
    } else if (this.props.failRequests) {
      return (
        <div className="p-3 my-2 rounded">
          <Toast isOpen={this.props.show}>
            <ToastHeader toggle={this.toggle}>Failed!</ToastHeader>
            <ToastBody>
              {this.props.errorDesc ||
                'Failed on sending request.Please try again'}
            </ToastBody>
          </Toast>
        </div>
      );
    } else if (this.props.callRequest) {
      return (
        <div className="p-3 my-2 rounded">
          <Toast isOpen={this.props.show}>
            <ToastHeader toggle={this.toggle}>Success</ToastHeader>
            <ToastBody>
              {this.props.successToastMessage || 'Call placed successfully!'}
            </ToastBody>
          </Toast>
        </div>
      );
    } else {
      return <div />;
    }
  };
  render() {
    return <div>{this.toast()}</div>;
  }
}

export default ResultToast;
