import React, { Component } from 'react';
import ResultModal from '../common/modal/ResultModal';
import { TableContainer } from './StyledComponent';
import ReactTable from 'react-table';
import {
  getProductsPool,
  getPoolSponsors,
  deleteProductFromPool,
} from '../../services/api/redemptions';
import { IoMdCreate } from 'react-icons/io';
import ReactTableInputFilter from '../lab-rx-requests/ReactTableInputFilter';
import { MdDelete } from 'react-icons/md';
import { toast } from 'react-toastify';

class ProductPoolTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showModal: false,
      showProductField: false,
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      productOptions: [],
      productSelected: '',
      corporateCost: '',
      nonServiceableMessage: '',
      amount: '',
      priority: '',
      maxBuy: '',
      maxUsersBuy: '',
      locations: '',
      layout: '',
      layoutOptions: [
        { value: 'grid', label: 'Grid' },
        { value: 'fullwidth', label: 'Full Width' },
      ],
      invites: '',
      web: '',
      webOptions: [
        { value: '1', label: 'Yes' },
        { value: '0', label: 'No' },
      ],
      productData: [],
      sponsorsData: [],
    };
  }

  handleDeleteProduct = (data) => {
    return deleteProductFromPool(data.original.id, data.original.pool_id)
      .then((res) => {
        if (res.message === 'success') {
          this.props.fetchPools();
          toast.success('Product deleted successfully');
        }
      }).catch((err) => {
        toast.error('Something went wrong');
        console.log(err);
      })
  }

  productTableColumns = [
    {
      Header: 'Products',
      columns: [
        {
          accessor: 'id',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'Id',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'title',
          sortable: true,
          numeric: false,
          disablePadding: false,
          width: 200,
          Header: 'Title',
          style: { whiteSpace: 'unset' },
          filterable: true,
          Filter: ReactTableInputFilter,
          filterMethod: (filter, row, column) => {
            const id = filter.pivotId || filter.id;
            return row[id] !== undefined
              ? String(row[id].toLowerCase()).startsWith(
                  filter.value.toLowerCase()
                )
              : true;
          },
        },
        {
          accessor: 'visit_cost',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'Visit Cost',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'amount',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'Fit Coin',
          style: { whiteSpace: 'unset' },
        },
        {
          accessor: 'type',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'Type',
        },
        {
          accessor: 'priority',
          numeric: false,
          sortable: true,
          disablePadding: false,
          Header: 'Priority',
        },
        {
          disablePadding: false,
          sortable: true,
          Header: 'Action',
          style: { whiteSpace: 'unset' },
          Cell: (row) => {
            const { isActive } = row.original;
            return (
              <div>
                <IoMdCreate
                  className="icon-style text-primary"
                  id={`editProductPoolButton`}
                  style={{ fontSize: 45 }}
                  onClick={() => {
                    this.props.handleEditProductPool(row);
                  }}
                />
                <MdDelete
                  className="icon-style text-danger"
                  id={`editProductButton`}
                  style={{ fontSize: 45 }}
                  onClick={() => {
                  this.handleDeleteProduct(row);
                }}
            />
                <button
                  className={`btn ${isActive ? 'btn-danger' : 'btn-primary'}`}
                  onClick={() => {
                    return this.props.toggleProductActiveInactive(row);
                  }}
                >
                  {isActive ? 'Mark-Inactive' : 'Mark-Active'}
                </button>
              </div>
            );
          },
        },
      ],
    },
  ];

  componentDidMount() {
    this.fetchProductTableData();
  }

  // Fetch Table Data
  fetchProductTableData = () => {
    this.setState({
      loading: true,
    });

    const params = {
      poolId: this.props.poolId,
    };

    if (!params.poolId) {
      return;
    }

    return getProductsPool(params)
      .then((responseBody) => {
        this.setState({
          productData: responseBody.data || [],
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        let errorMessage = err.message;
        this.setState({
          showModal: true,
          modalHeader: 'Error',
          modalBody: `${errorMessage}.`,
          bgColor: 'bg-danger',
          loading: false,
        });
      });
  };

  render() {
    return (
      <TableContainer>
        <ReactTable
          data={this.state.productData}
          columns={this.productTableColumns}
          loading={this.state.loading}
          showPaginationTop={true}
          showPaginationBottom={false}
          collapseOnDataChange={false}
          className="-striped -highlight text-center"
        />
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
      </TableContainer>
    );
  }
}

export default ProductPoolTable;
