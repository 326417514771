import styled from 'styled-components';
import { FaCircleNotch } from 'react-icons/fa';

export const StyledHistoryContiner = styled.div`
  background-color: '';
`;

export const Container = styled.div`
    display: flex;
`;

export const ContainerItem = styled.div`
    flex-grow: 1;
    margin: 7px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: center;
  justify-content: center;
`;

export const LoadingIcon = styled(FaCircleNotch)`
  color: white;
  margin-right: 0.5rem;
`;
