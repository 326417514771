import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import { MdOpenInBrowser, MdCloudUpload, MdCloudDownload } from "react-icons/md";
import { removeFileFromPrescription, getUploadedPrescriptions, uploadFileForTranscription } from '../../../services/api/rx-transcription';
import { toast } from 'react-toastify';
import { setCreatingLabRxPrescriptionId } from "../../../actions";

class LabRxFileUploader extends Component {

    constructor(props) {
        super(props);
        this.state = { collapse: false, files: [] } ;
    }

    toggle = () => {
        this.setState(state => ({ collapse: !state.collapse }));
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userInfo.userId !== this.props.userInfo.userId) {
            this.setState(state => ({ collapse: false }));
        }
    }

    removeFile = (file) => {
        removeFileFromPrescription(file.prescriptionId, file.fileId, this.props.userInfo.authToken)
            .then((response) => {
                if (response.body.message === 'success') {
                    const files = this.state.files;
                    const index = files.indexOf(file);
                    console.log(index, 'INdex of removed file');
                    files.splice(index, 1);
                    this.setState({files});
                    toast.success('File Removed');
                } else{
                    toast.error('Error in removing file');
                }
            })
            .catch((err) => {
                toast.error('Error in removing file');
                console.error('Error', err);
            });
    };

    renderFiles = () => {
        console.log(this.state.files);
        return this.state.files.map((f, index) => {
            return (
                <tr key={f.fileId}>
                    <th scope="row">{index+1}</th>
                    <td><a target="_blank" href={f.fileUrl}>{f.fileName}</a></td>
                    <td><button className="btn btn-danger" onClick={() => this.removeFile(f)}>Remove</button></td>
                </tr>
            );
        });
    };

    onFileSelect = (e) => {
        console.log('File selected', e.target.files);
        uploadFileForTranscription(e.target.files, this.props.prescriptionId, this.props.userInfo.authToken)
            .then((response) => {
                if(response.body.message === 'success') {
                    const files = this.state.files;
                    response.body.filesInfo.forEach((f) => {
                        files.push(f);
                    });
                    console.log(files, 'In state');
                    this.setState({
                        files,
                    });

                    this.props.dispatch(setCreatingLabRxPrescriptionId(response.body.filesInfo[0].prescriptionId));
                    toast.success('File uploaded');
                } else {
                    toast.error('Error in uploading file');
                }
            })
            .catch((err) => {
                toast.error('Error in uploading file');
                console.error('Error', err);
            });
    };

    render() {
        return (
            <div className="row">
                <div className="col">
                    <div className="row">
                        <button className="btn btn-primary" onClick={this.toggle} style={{ marginBottom: '1rem' }} disabled={this.props.disabled}>Prescription Files</button>
                    </div>
                    <div className="row">
                        <div className="col">
                            <Collapse isOpen={this.state.collapse}>
                                <Card>
                                    <CardBody>
                                        <table className="table table-striped">
                                            <thead className="thead-dark">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Link</th>
                                                <th scope="col">Remove</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.renderFiles()}
                                            </tbody>
                                        </table>
                                        <div className="custom-file">
                                            <input multiple="multiple" type="file" className="custom-file-input" id="customFile" onChange={this.onFileSelect}/>
                                            <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Collapse>
                        </div>

                    </div>
                </div>

            </div>

        );
    }
}

const mapStateToProps = state => ({ userInfo: state.createLabRxDetails.userInfo, newRequestPrescriptionId: state.createLabRxDetails.newRequestPrescriptionId });

export default connect(
    mapStateToProps,
)(LabRxFileUploader);





