import React, {Component} from 'react';
import {connect} from 'react-redux';
import NavBar from "../layout/NavBar";
import LabsOrdersTable from './LabOrdersTable';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import {PageHeader} from "../common/PageHeader";

class LabOrdersHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            collectionStartDate: null,
            collectionEndDate: null,
            focusedInput: null,
            focusedInputCollection: null,
        };
    }

    onDatesChanged = ({ startDate, endDate }) => {
        this.setState({ startDate, endDate });
    };

    onDatesChangedCollection = ({ startDate, endDate }) => {
        this.setState({
            collectionStartDate: startDate,
            collectionEndDate: endDate,
        });
    }

    render() {
        const { startDate, endDate, collectionStartDate, collectionEndDate, focusedInput, focusedInputCollection } = this.state;
        return (
            <div className="container-fluid">
                <div className="row">
                    <NavBar />
                </div>
                <div className="row mt-5 pt-1">
                    <div className="col">
                        <PageHeader>Lab Orders</PageHeader>
                    </div>
                    <div className="col-4 text-right pt-3">
                        <label style={{ marginRight: '10px'}}>Booking Date:</label>
                        <DateRangePicker
                            displayFormat={() => "DD/MM/YYYY"}
                            startDateId="startDate"
                            endDateId="endDate"
                            startDate={startDate}
                            endDate={endDate}
                            isOutsideRange={() => false}
                            minimumNights={0}
                            onDatesChange={this.onDatesChanged}
                            focusedInput={focusedInput}
                            onFocusChange={focusedInput => this.setState({ focusedInput })}
                        />
                    </div>
                    <div className="col-4 text-right pt-3">
                        <label style={{ marginRight: '10px'}}>Collection Date:</label>
                        <DateRangePicker
                            displayFormat={() => "DD/MM/YYYY"}
                            startDateId="collectionStartDate"
                            endDateId="collectionEndDate"
                            startDate={collectionStartDate}
                            endDate={collectionEndDate}
                            isOutsideRange={() => false}
                            minimumNights={0}
                            onDatesChange={this.onDatesChangedCollection}
                            focusedInput={focusedInputCollection}
                            onFocusChange={focusedInputCollection => this.setState({ focusedInputCollection })}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <LabsOrdersTable startDate={startDate} endDate={endDate} startDateCollection={collectionStartDate} endDateCollection={collectionEndDate}/>
                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {};
}

export default connect(
    mapStateToProps,
)(LabOrdersHome);





