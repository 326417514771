import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { addNewTest, getPartnersList, addNewPartner} from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
class LabRxNewTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            nestedModal: false,
            closeAll: false,
            partners: [],
            newPartnerEmail: null,
            newPartnerName: null,
            mrp: 0,
            cost: 0,
            testName: null,
            testCode: null,
        };
    }

    // listAllPartners = () => {
    //     getPartnersList(this.props.testType)
    //         .then((response) => {
    //             if(response.body.message === 'success') {
    //                 this.setState({
    //                     partners: response.body.partners,
    //                 });
    //             } else {
    //                 toast.error('Error in listing partners');
    //             }
    //         })
    //         .catch((err) => {
    //             console.error('Error in listing partners', err);
    //             toast.error('Error in listing partners');
    //         });
    // };

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
        if (this.state.modal === false) {
            this.props.close();
        }
    };

    saveNewPartner = () => {
        if (this.state.newPartnerName && this.state.newPartnerEmail && this.state.newPartnerName.trim().length > 0 && this.state.newPartnerEmail.trim().length)
        addNewPartner(this.state.newPartnerName.trim(), this.state.newPartnerEmail.trim())
            .then((response) => {
                if(response.body.message === 'success') {
                    const partnerId = response.body.partnerId;
                    this.setState({
                        nestedModal: !this.state.nestedModal,
                        closeAll: false
                    });
                } else {
                    toast.error('Error in Adding a partner');
                }
            })
            .catch((err) => {
                console.error('Error', err);
                toast.error('Error in Adding a partner');
            });
    };

    toggleNested = () => {
        this.setState({
            nestedModal: !this.state.nestedModal,
            closeAll: false
        });
    };

    // toggleAll = () => {
    //     this.setState({
    //         nestedModal: !this.state.nestedModal,
    //         closeAll: true
    //     });
    // };

    save = () => {
        console.log(this.state, 'Logging state before saving test');
        if (this.state.mrp > 0 && this.state.cost >0 && this.state.testName && this.state.testCode && this.state.testName.trim().length > 0 && this.state.testCode.trim().length)
            addNewTest(this.state.mrp, this.state.testName, this.state.testCode, this.state.cost, parseInt(this.props.cartId), this.props.selectedPartnerId, this.props.testType)
                .then((response) => {
                    if(response.body.message === 'success') {
                        const partnerId = response.body.partnerId;
                        this.toggle();
                        this.props.loadCart();
                        toast.success('Test Added');
                    } else {
                        toast.error('Error in Adding a test');
                    }
                })
                .catch((err) => {
                    console.error('Error', err);
                    toast.error('Error in Adding a test');
                });
    };

    render() {
        return (
            <div>
                {/*<button onClick={this.toggle} className="btn btn-danger">Add New Test</button>*/}
                <Modal isOpen={this.props.open} toggle={this.toggle} size="lg">
                    <h4 className="mt-1 mb-0 ml-2">Add Lab Test</h4>
                    <ModalBody>
                        <p>Fill the below details to add a lab test</p>
                        {/*<Button color="success" onClick={this.toggleNested}>Add New Partner</Button>*/}
                        <div className="form">
                            <div className="form-row">
                                <div className="form-group col-3">
                                    <label htmlFor="inputName">Test Name</label>
                                    <input type="text" className="form-control" id="inputName" onChange={(e) => this.setState({
                                        testName: e.target.value,
                                    })}/>
                                </div>

                                <div className="form-group col-3">
                                    <label htmlFor="inputMrp">MRP (Market price)</label>
                                    <input type="number" className="form-control" id="inputMrp" placeholder="Rs" onChange={(e) => this.setState({
                                        mrp: e.target.value,
                                    })}/>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-3">
                                    <label htmlFor="inputCode">Test Code</label>
                                    <input type="text" className="form-control" id="inputCode" onChange={(e) => this.setState({
                                        testCode: e.target.value,
                                    })}/>
                                </div>

                                <div className="form-group col-3">
                                    <label htmlFor="inputCost">Cost (Payeable to partner)</label>
                                    <input type="number" className="form-control" id="inputCost" placeholder="Rs" onChange={(e) => this.setState({
                                        cost: e.target.value,
                                    })}/>
                                </div>
                            </div>
                        </div>
                        <Modal isOpen={this.state.nestedModal} toggle={this.toggleNested} onClosed={this.state.closeAll ? this.toggle : undefined}>
                            {/*<h4>Add Partner</h4>*/}
                            <ModalBody>
                                <div className="form">
                                    <div className="form-group">
                                        <label htmlFor="partnerName">Partner Name</label>
                                        <input type="text" className="form-control" id="partnerName" onChange={(e) => this.setState({
                                            newPartnerName: e.target.value,
                                        })}/>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="partnerEmail">Partner Email</label>
                                        <input type="text" className="form-control" id="partnerEmail" onChange={(e) => this.setState({
                                            newPartnerEmail: e.target.value,
                                        })}/>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={this.saveNewPartner}>Add Partner</Button>{' '}
                                <Button color="secondary" onClick={this.toggleNested}>Cancel</Button>
                            </ModalFooter>
                        </Modal>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>Add Test</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = state => {
    console.log(state.labsDashboard.selectedPartnerId, 'Selected partner Id');
    return { selectedPartnerId: state.labsDashboard.selectedPartnerId };
};

export default connect(
    mapStateToProps,
)(LabRxNewTest);





