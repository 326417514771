import React from 'react';

import { FaUpload, FaFileCsv } from 'react-icons/fa';

import { toast } from 'react-toastify';

import { updateCsv } from '../../services/api/lab-partners';

import LoadingComponent from "../common/LoadingComponent";

class LabsPincodeUploadCsv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            file: null,
            loading: false,
        };
    }

    onFileSelect = (e) => {
        console.log(e.target.files[0]);
        this.setState({
            file: e.target.files[0] || null,
        });
    }

    uploadCsv = () => {
        if (!this.state.file) {
            toast.error('Select a file first');
            return;
        }
        const { partnerId } = this.props;
        this.setState({
            loading: true,
        });
        return updateCsv(partnerId, this.state.file)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    toast.success('Succesfully updated pincodes');
                    return this.props.onComplete();
                } else {
                    throw new Error(body.errorMessage || 'Error while updating the pincodes');
                }
            })
            .catch((err) => {
                console.error(err);
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    render() {
        return (
            <div className="row mb-2">
                <div className="col-3">
                    <input type="file" className="form-control" accept=".csv" onChange={this.onFileSelect}/>
                </div>
                <div className="col-1">
                    {(this.state.loading) ? (
                        <LoadingComponent />
                    ) : (
                        <button className="btn btn-primary" onClick={this.uploadCsv}><FaUpload /> Upload</button>
                    )}
                </div>
                <div className="col">
                    <a href="https://docs.google.com/spreadsheets/d/1yISm2_BshuQ1oxiALSogE_G56tJZgZ_LitQw97s2y4E/edit?usp=sharing" target="_blank"><FaFileCsv className="text-success" size={35} /> Template</a>
                </div>
            </div>
        )
    }
}

export default LabsPincodeUploadCsv;
