export const defaultState = {
    fulfilments: {
        assistant_name: null,
        assistant_number: null,
        payeeName: null,
        accountNumber: null,
        accountType: null,
        ifsc: null,
        upiPhone: null,
        upiAddress: null,
        offline_discount: null,
        online_discount: null,
        qr_code_img: null,
        spoc_name: null,
        spoc_phone: null,
        spoc_email: null,
        followup_discount: null,
        followup_duration: null,
        paymentCycle: null,
        preferredPaymentMode: null,
    },

};

export default function PaymentDetails(state = defaultState, action) {
    switch (action.type) {
        case "UPDATE_PAYMENT_DETAILS": {
            const paymentDetails = action.payload.paymentDetails;
            console.log('setting new state for payment details', action.payload)
            return Object.assign({}, state, {
                fulfilments: paymentDetails,
            });
        }
        case 'INIT_STATE_PAYMENT_DETAILS': {
            return Object.assign({}, state, {
                sendingRequest: action.payload.sendingRequest
            })
        }

        case 'PAYMENT_REQUEST_SENT': {
            return Object.assign({}, state, {
                sendingRequest: action.payload.sendingRequest
            })
        }
        default:
            return state;
    }
}
