import React from 'react';
import { Async } from "react-select";
import Link from "valuelink";
import { Input } from "valuelink/lib/tags";

import { toast } from 'react-toastify';

import Modal from "../common/Modal";

import LoadingComponent from "../common/LoadingComponent";

import { searchTestsByNotAdded, addLabTestForPartner, uploadMultipleTestCsv, updateStatusToActive, updateStatusToInactive, uploadRetailDiscountMargin } from '../../services/api/lab-partners';
import {FaFileCsv, FaUpload} from "react-icons/fa";

class AddLabsTests extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            option: null,
            loading: false,
            testName: '',
            mrp: '',
            cost: '',
            testCode: '',
            addToAllCenters: false,
            file: null,
            showActiveModal: false,
            rdm:''
        };
        this.labTestSelectRef = React.createRef();
    }

    searchTests = (val) => {
        // if (val.length <= 3) {
        //     return;
        // }
        const partnerId = this.props.partnerId;
        return searchTestsByNotAdded(partnerId, val)
            .then((response) => {
                const data = response.body;
                if (data.message === 'success') {
                    return data.tests.map((t) => {
                        return {
                            label: t.testName,
                            testId: t.testId,
                        };
                    });
                }
                throw new Error('Couldn\'t fetch Tests');
            })
            .catch((err) => {
                console.error(err, 'Error while fetching tests');
            })
    };

    openModal = (option) => {
        this.setState({
            isOpen: true,
            option,
            testName: option && option.label,
            mrp: '',
            cost: '',
            testCode: '',
            addToAllCenters: '',
        });
    };

    closeModal = () => {
        this.setState({
            isOpen: false,
            option: null,
        });
    };

    onTestSelectChange = (options, action) => {
        if (!options || !options.testId) {
            return;
        }
        this.openModal(options);
    };

    validateNumber = (value) => {
        const reg = /^\d{1,5}$/;
        return reg.test(value);
    };

    validateAlphaNumeric = (value) => {
        const reg = /[a-z A-Z0-9\\_\\"]+$/;
        return reg.test(value);
    };

    submitAddLabTest = () => {
        const { testName, mrp, cost, testCode, option, addToAllCenters } = this.state;
        const partnerId = this.props.partnerId;

        if (!this.validateNumber(mrp) || !this.validateNumber(cost) || !this.validateAlphaNumeric(testName) || !this.validateAlphaNumeric(testCode) || !option || !option.testId) {
            toast.error('Please enter correct details');
            return;
        }
        this.setState({
            loading: true,
        });
        const test = {
            testName,
            mrp,
            cost,
            testCode,
            testId: option.testId,
        };
        return addLabTestForPartner(partnerId, test, addToAllCenters)
            .then((response) => {
                const data = response.body;
                if (data.message === 'success') {
                    toast.success('Lab Test Added');
                    this.props.onComplete();
                    this.closeModal();
                    return;
                }
                throw new Error(data.errorMessage || 'Error while creating the test');
            })
            .catch((err) => {
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            })
    };

    onFileSelectHandler = (e) => {
        console.log(e.target.files[0]);
        this.setState({
            file: e.target.files[0] || null,
        });
    }
    uploadCsvTests = () => {
        if (!this.state.file) {
            toast.error('Select a file first');
            return;
        }
        const { partnerId } = this.props;
        this.setState({
            loading: true,
        });
        return uploadMultipleTestCsv(partnerId, this.state.file)
            .then((response) => {
                const body = response.body;
                if (body.message === 'success') {
                    toast.success('Succesfully added tests');
                    return this.props.onComplete();
                } else {
                    throw new Error(body.errorMessage || 'Error while adding tests');
                }
            })
            .catch((err) => {
                console.error(err);
                toast.error(err.message);
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    }

    updateStatus = () => {
        const partnerId = this.props.partnerId;
        return updateStatusToActive(partnerId)
            .then((response) => {
                console.log(response);
                if (response.message === 'success') {
                    toast.success('Successfully changed the status');
                    this.setState({ showActiveModal: false });
                    return this.props.onComplete();
                } else {
                    this.setState({ showActiveModal: false });
                    throw new Error(response.errorMessage || 'Error while updating status');
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    updateStatusToInactive = () => {
        const partnerId = this.props.partnerId;
        return updateStatusToInactive(partnerId)
            .then((response) => {
                console.log(response);
                if (response.message === 'success') {
                    toast.success('Successfully changed the status');
                    this.setState({ showInactiveModal: false });
                    return this.props.onComplete();
                } else {
                    this.setState({ showInactiveModal: false });
                    throw new Error(response.errorMessage || 'Error while updating status');
                }
            })
            .catch((err) => {
                toast.error(err.message);
            });
    }

    showActiveModal = () => {
        this.setState({
            showActiveModal: true,
        });
    };
    showInactiveModal = () => {
        this.setState({
            showInactiveModal: true,
        });
    };

    validateNumber = (value) => {
        const reg = /^\d{1,5}$/;
        return reg.test(value);
    };

    updateRDM = () => {
        const {partnerId} = this.props;
        const { rdm } = this.state;

        if (!rdm) {
          toast.error('Please enter Retail Discount Margin');
          return;
        }

        uploadRetailDiscountMargin(partnerId,rdm)
            .then((result) => {
                toast.success('Successfully updated Retail Discount Margin for all tests');
            })
    }

    render() {
        const { isOpen, option, loading, showActiveModal, showInactiveModal } = this.state;
        const testNameLink = Link.state(this, "testName");
        const testCodeLink = Link.state(this, "testCode");
        const mrpLink = Link.state(this, "mrp");
        const costLink = Link.state(this, "cost");
        const addToAllCenterLink = Link.state(this, 'addToAllCenters');
        return (
            <React.Fragment>
                <Modal
                    // onOpen
                    isOpen={isOpen}
                    onRequestClose={this.closeModal}
                    header={`Add Test Modal (${option && option.label})`}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        Partner Test Name
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={testNameLink} />
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        Partner Test Code
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={testCodeLink}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        MRP
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={mrpLink}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        Cost
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <Input className="form-control" valueLink={costLink}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="row">
                                    <div className="col">
                                        <Input type="checkbox" valueLink={addToAllCenterLink} />&nbsp;Add this test to all active centers?
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row">
                            <div className="col">
                                {(loading) ? (
                                    <LoadingComponent />
                                ) : (
                                    <div className="btn btn-success btn-block" onClick={this.submitAddLabTest}>Add Test</div>
                                )}
                            </div>
                            <div className="col">
                                <div className="btn btn-danger btn-block" onClick={this.closeModal}>Cancel</div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={showActiveModal}
                    onClose={() => this.setState({ showActiveModal: false })}
                    header={'Are you sure you want to mark all tests as Active'}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-success btn-block" onClick={this.updateStatus}>Yes</button>
                            </div>
                            <div className="col">
                                <button className="btn btn-danger btn-block" onClick={() => this.setState({ showActiveModal: false })}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    isOpen={showInactiveModal}
                    onClose={() => this.setState({ showInactiveModal: false })}
                    header={'Are you sure you want to mark all tests as inActive'}
                >
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <button className="btn btn-success btn-block" onClick={this.updateStatusToInactive}>Yes</button>
                            </div>
                            <div className="col">
                                <button className="btn btn-danger btn-block" onClick={() => this.setState({ showInactiveModal: false })}>No</button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="container-fluid mb-2 mt-1">
                    <div className="row">
                        <div className='col'>
                            <Async
                                loadOptions={this.searchTests}
                                ref={this.labTestSelectRef}
                                name="Search To Add Test"
                                placeholder='Search To Add Test'
                                onChange={(options, action) => this.onTestSelectChange(options, action)}
                                isClearable={true}
                            />
                            <div className='row' style={{paddingLeft:'15px',marginTop:'10px'}}>
                            <input 
                            className="form-control"
                            style={{width:'50%'}}
                            type="number"
                            min="0"
                            placeholder='Enter RDM'
                            value={this.state.rdm}
                            onChange={(e) => this.setState({rdm:e.target.value})}
                            /> 
                            <button className="btn btn-primary" style={{marginLeft:'20px'}} onClick={this.updateRDM}>Update RDM</button>
                            </div>
                        </div>
                        <div className="col-3">
                            <input type="file" className="form-control" accept=".csv" onChange={this.onFileSelectHandler}/>
                        </div>
                        <div className="col-1">
                            {(this.state.loading) ? (
                                <LoadingComponent />
                            ) : (
                                <button className="btn btn-primary" onClick={this.uploadCsvTests}><FaUpload /> Upload</button>
                            )}
                        </div>
                        <div className="col-2">
                            <a href="https://docs.google.com/spreadsheets/d/1EEcimEPNDANIzp7lA-ogV3dGI3Bdf5dyIJ7Vok3rMxA/edit?usp=sharing" target="_blank"><FaFileCsv className="text-success" size={35} />Template</a>
                        </div>
                        <div className="col-2">
                        <button className="btn btn-primary" onClick={this.showActiveModal}>Mark all Active</button>
                        <button className="btn btn-primary" onClick={this.showInactiveModal}>Mark all Inactive</button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AddLabsTests;
