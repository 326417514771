import React, { Component } from 'react';
import Select from 'react-select';
import { Async } from 'react-select';
import {
  Alert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';
import {
  getVerticals,
} from '../../services/api/offline-consult';

import moment from 'moment';
import LoadingComponent from '../common/LoadingComponent';
import { getAllSponsor } from '../../services/api/coupon';

// import { useLink } from 'valueLink';
// import { Input } from 'linked-controls';
 const forOption = [
  {label: "labs", value : "labs"},
  {label: "online", value : "con-online"},
  { label: "offline", value : "con-offline"},
]

const discountTypeOptions = [
  {label: "Percentage", value : "%"},
  {label: "Net Amount", value : "-"},
]

class DownloadCouponData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      showFormWarning: false,
      alertMessage: 'None',
      loading: false,
      couponName: '',
    };
    this.onAlertDismiss = this.onAlertDismiss.bind(this);
  }

  toggle = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
    });
  };

  onAlertDismiss() {
    this.setState({ showFormWarning: false });
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  };

  componentWillMount() {
    this.fetchVerticals();
    this.fetchSponsors()
  }


  fetchVerticals = () => {
    this.setState({ verticalLoading: true });
    getVerticals()
      .then((response) => {
        const arr = [];
        response.data.forEach((element) => {
          arr.push({ value: element.verticalId, label: element.verticalName });
        });
        arr.push({ value: null, label: 'all' });
        this.setState({ verticalsResult: arr });
      })
      .finally(() => {
        this.setState({ verticalLoading: false });
      });
  };

  fetchSponsors = () => {
    this.setState({ sponsorLoading: true });
    getAllSponsor()
      .then((response) => {
        const arr = [];
        response.forEach((element) => {
          arr.push({ value: element.sponsorId, label: element.sponsorName });
        });
        arr.push({ value: null, label: 'all' });
        this.setState({ sponsorResult: arr });
      })
      .finally(() => {
        this.setState({ sponsorLoading: false });
      });
  };

  handleDateChange = (event) => {
    const date = event.target.value;
    if (!date) {
      return;
    }
    const dateKey = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    this.setState({ dateKey });
  };

  handleBulkCount = (event) => {
    const count = event.target.value;
    console.log("changing bulk Count to ", count);
    if (!count) {
      return;
    }
    this.setState({ bulkCount : count });
  };

  handleCouponNameChange = (event) => {
    const name = event.target.value;
    console.log("changing coupon name to ", name);
    if (!name) {
      return;
    }
    this.setState({ couponName : name });
  };

  handleMaxCountChange = (event) => {
    const count = event.target.value;
    console.log("changing max_count to ", count);
    if (!count) {
      return;
    }
    this.setState({ max_count : count });
  };

  handleApplicableForChange = (event) => {
    const valid_for = event.value;
    console.log("changing for to ", valid_for);
    if (!valid_for) {
      return;
    }
    this.setState({ valid_for : valid_for });
  }

  handleVerticalChange = (event) => {
    const verticalId = event.value;
    console.log("changing vertical to ", verticalId);
    if (!verticalId) {
      return;
    }
    this.setState({ verticalId : verticalId });
  }

  handleDiscountValueChange = (event) => {
    const value = event.target.value;
    console.log("changing discount value to ", value);
    if (!value) {
      return;
    }
    this.setState({ discountValue : value });
  }

  handleBadFormInput = (couponName) => {
    let alertMessage = null;
    if(!couponName) {
      alertMessage = 'You need to enter a coupon name';
    }
    // show alert
    this.setState({ showFormWarning: true, alertMessage });
    if(alertMessage) {
      return true;
    }
    return false;
  };

  placeRequest = () => {
    const {couponName } = this.state;
    const result = this.handleBadFormInput(couponName);
    if(result){
        return;
    }
    this.setState({
      loading: true,
    });
    console.log(couponName);
    this.props
      .submit(couponName)
      .then(() => true)
      .finally(() => {
        this.setState({ loading: false, modalVisible: false });
      });
  };

  showModal = () => {
    this.setState({ modalVisible: true });
  };


 
  render() {
    const {
      verticalsResult,
      verticalLoading,
      sponsorResult,
      sponsorLoading,
      modalVisible,
      loading,
    } = this.state;
    const { showModal } = this;
    return (
      <div>
          <Button type="button" className="btn btn-success" onClick={showModal}>
          Download Coupon Data
        </Button>
        <Modal
          isOpen={modalVisible}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader className="bg-primary" toggle={this.toggle}>
            Enter Coupon Name
          </ModalHeader>
          <ModalBody>
            <form>
            <FormGroup>
                <Row>
                  <Col className="col-3">
                    <Label>Coupon Name:</Label>
                  </Col>
                  <Col>
                    <input
                      className="form-control"
                      type="text"
                      onChange={this.handleCouponNameChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </form>
            <Alert
              color="warning"
              isOpen={this.state.showFormWarning}
              toggle={this.onAlertDismiss}
            >
              {this.state.alertMessage}
            </Alert>
          </ModalBody>
          <ModalFooter>
            {loading ? (
              <LoadingComponent />
            ) : (
              <Button color="primary" onClick={this.placeRequest}>
                Place Request
              </Button>
            )}
            <Button color="secondary" onClick={this.toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default DownloadCouponData;
