import React from 'react';
import {toast} from "react-toastify";

import { fetchRosterForPoolAndDoctor } from '../../services/api/pool-manager';
import RosterDayTimePicker from "./RosterDayTimePicker";
import LoadingComponent from "../common/LoadingComponent";

class RosterComponent extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            roster: [],
            loading: false,
        };
    }

    getRoster = () => {
        const { poolId, doctorId } = this.props;
        this.setState({
            loading: true,
        });
        return fetchRosterForPoolAndDoctor(doctorId, poolId)
            .then((body) => {
                if (body.message === 'success') {
                    this.setState({
                        roster: body.roster,
                    });
                } else {
                    throw new Error(body.errorMessage || 'Error while fetching the roster');
                }
            })
            .catch((err) => {
                toast.error(err.message);
                this.setState({
                    roster: [],
                });
            })
            .finally(() => {
                this.setState({
                    loading: false,
                })
            });
    };

    componentDidMount() {
        this.getRoster();
    }

    render() {
        const { roster, loading } = this.state;
        const { poolId, doctorId } = this.props;
        return (
            <React.Fragment>
                {(loading) ? (
                    <LoadingComponent />
                ) : (
                    roster.map((r) => {
                        return (<RosterDayTimePicker key={r.rosterId} dayTime={r} poolId={poolId} doctorId={doctorId} onComplete={this.getRoster} roster = {roster}/>);
                    })
                )}
                <RosterDayTimePicker dayTime={null} poolId={poolId} doctorId={doctorId} onComplete={this.getRoster} roster = {roster}/>
            </React.Fragment>
        );
    }
}

export default RosterComponent;
