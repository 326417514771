import React from 'react';

import ReactTable from 'react-table';
import ToggleActiveInactiveTestsModal from './ToggleActiveInactiveTestsModal';
import ModalConfirm from '../common/ModalConfirm';

import EditLabTests from './EditLabTests';

import {
  FaPencilAlt,
  FaTimes,
  FaCheck,
  FaTrashAlt,
  FaPlusCircle,
} from 'react-icons/fa';

import { searchFilterMethod } from '../../utils/react-table';

import {
  deletePincode,
  addPincodeForPartner,
} from '../../services/api/lab-partners';

import { toast } from 'react-toastify';

class LabsPincodeOnboardingTable extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();

    this.state = {
      activeInactiveModalOpen: false,
      testMapId: null,
      active: 0,
      editLabTestModal: false,
      deleteModal: {
        isOpen: false,
        pincode: '',
        partnerId: null,
      },
      newRow: {
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        friday: 1,
        thursday: 1,
        saturday: 1,
        sunday: 1,
        pincode: '',
        newRow: true,
      },
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.newRow &&
      prevState.newRow &&
      this.state.newRow.pincode !== prevState.newRow.pincode
    ) {
      this.textInput.current.focus();
    }
  }

  removeDayFromNewRow = (day) => {
    const newRow = { ...this.state.newRow };
    newRow[day] = 0;
    this.setState({
      newRow,
    });
  };
  addDayToNewRow = (day) => {
    const newRow = { ...this.state.newRow };
    newRow[day] = 1;
    this.setState({
      newRow,
    });
  };
  formatDayCell = (day) =>
    day ? <FaCheck class="text-success" /> : <FaTimes class="text-danger" />;

  formatNewDayCell = (day, dayName) =>
    day ? (
      <FaCheck
        class="text-success"
        onClick={() => this.removeDayFromNewRow(dayName)}
      />
    ) : (
      <FaTimes
        class="text-danger"
        onClick={() => this.addDayToNewRow(dayName)}
      />
    );

  getColumns = () => {
    return [
      {
        accessor: 'pincode',
        filterable: true,
        Header: 'Pincode',
        className: 'text-center',
        filterMethod: searchFilterMethod,
        Cell: (row) =>
          row.original.newRow ? (
            <input
              type="number"
              key="1"
              className="form-control"
              placeholder="Enter Pincode"
              value={row.original.pincode}
              ref={this.textInput}
              onChange={(e) => {
                console.log(row.original);
                e.preventDefault();
                e.stopPropagation();
                console.log(e.target.value);
                const newRow = { ...this.state.newRow };
                newRow['pincode'] = e.target.value;
                this.setState({
                  newRow,
                });
              }}
            />
          ) : (
            <p>{row.original.pincode}</p>
          ),
      },
      {
        accessor: 'monday',
        Header: 'Monday',
        className: 'text-center',
        Cell: (row) =>
          row.original.newRow
            ? this.formatNewDayCell(row.original.monday, 'monday')
            : this.formatDayCell(row.original.monday),
      },
      {
        accessor: 'tuesday',
        Header: 'Tuesday',
        className: 'text-center',
        Cell: (row) =>
          row.original.newRow
            ? this.formatNewDayCell(row.original.tuesday, 'tuesday')
            : this.formatDayCell(row.original.tuesday),
      },
      {
        accessor: 'wednesday',
        Header: 'Wednesday',
        className: 'text-center',
        Cell: (row) =>
          row.original.newRow
            ? this.formatNewDayCell(row.original.wednesday, 'wednesday')
            : this.formatDayCell(row.original.wednesday),
      },
      {
        accessor: 'thursday',
        Header: 'Thursday',
        className: 'text-center',
        Cell: (row) =>
          row.original.newRow
            ? this.formatNewDayCell(row.original.thursday, 'thursday')
            : this.formatDayCell(row.original.thursday),
      },
      {
        accessor: 'friday',
        Header: 'Friday',
        className: 'text-center',
        Cell: (row) =>
          row.original.newRow
            ? this.formatNewDayCell(row.original.friday, 'friday')
            : this.formatDayCell(row.original.friday),
      },
      {
        accessor: 'saturday',
        Header: 'Saturday',
        className: 'text-center',
        Cell: (row) =>
          row.original.newRow
            ? this.formatNewDayCell(row.original.saturday, 'saturday')
            : this.formatDayCell(row.original.saturday),
      },
      {
        accessor: 'sunday',
        Header: 'Sunday',
        className: 'text-center',
        Cell: (row) =>
          row.original.newRow
            ? this.formatNewDayCell(row.original.sunday, 'sunday')
            : this.formatDayCell(row.original.sunday),
      },
      {
        Header: 'Action',
        className: 'text-center',
        Cell: (row) =>
          row.original.newRow ? (
            <FaPlusCircle
              className="clickable"
              onClick={this.addPincodeForPartner}
            />
          ) : (
            <FaTrashAlt
              onClick={() =>
                this.openDeleteModal(
                  row.original.partnerId,
                  row.original.pincode
                )
              }
              className="clickable text-danger"
            />
          ),
      },
    ];
  };

  openDeleteModal = (partnerId, pincode) => {
    this.setState({
      deleteModal: {
        partnerId,
        pincode,
        isOpen: true,
      },
    });
  };

  delete = () => {
    const { partnerId, pincode } = this.state.deleteModal;
    return deletePincode(partnerId, pincode)
      .then((response) => {
        const data = response.body;
        if (data.message === 'success') {
          toast.success('Successfully deleted the pincode');
          return this.props.onComplete();
        } else {
          throw new Error(data.errorMessage || "Couldn't delete the pincode");
        }
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message);
      });
  };

  addPincodeForPartner = () => {
    if (
      this.state.newRow &&
      this.state.newRow.pincode &&
      this.state.newRow.pincode !== '' &&
      String(this.state.newRow.pincode).length > 4
    ) {
      const { partnerId } = this.props;

      const body = {
        pincode: this.state.newRow.pincode,
        weekdays: {
          monday: this.state.newRow['monday'],
          tuesday: this.state.newRow['tuesday'],
          wednesday: this.state.newRow['wednesday'],
          thursday: this.state.newRow['thursday'],
          friday: this.state.newRow['friday'],
          saturday: this.state.newRow['saturday'],
          sunday: this.state.newRow['sunday'],
        },
      };
      addPincodeForPartner(partnerId, body)
        .then((response) => {
          const data = response.body || response;
          console.log(data);
          if (data.message === 'success') {
            this.setState({
              newRow: {
                monday: 1,
                tuesday: 1,
                wednesday: 1,
                friday: 1,
                thursday: 1,
                saturday: 1,
                sunday: 1,
                pincode: '',
                newRow: true,
              },
            });
            toast.success('Successfully Added the pincode');
            return this.props.onComplete();
          } else {
            throw new Error(data.errorMessage || "Couldn't Add the pincode");
          }
        })
        .catch((err) => {
          console.error(err, err.response);
          toast.error(err.message);
        });
    } else {
      toast.error('Invalid Pincode');
    }
  };

  closeModal = () => {
    this.setState({
      deleteModal: {
        partnerId: null,
        pincode: '',
        isOpen: false,
      },
    });
  };

  render() {
    const { pincodes, loading } = this.props;
    const { deleteModal, newRow } = this.state;
    // const { activeInactiveModalOpen, active, testMapId, editLabTestModal } = this.state;
    return (
      <React.Fragment>
        <ReactTable
          data={[newRow, ...pincodes]}
          loading={loading}
          showPaginationTop={false}
          columns={this.getColumns()}
          filterable={false}
          defaultPageSize={50}
          className="-striped -highlight"
          sortable={true}
        />
        <ModalConfirm
          message={`Are you sure you want delete ${deleteModal.pincode}?`}
          onConfirm={this.delete}
          onClose={this.closeModal}
          isOpen={deleteModal.isOpen}
        />
      </React.Fragment>
    );
  }
}

export default LabsPincodeOnboardingTable;
