import React, { Component } from 'react';
import Select from 'react-select';
import { getVerticals } from '../../../services/api/offline-consult';
import { connect } from 'react-redux';
// import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { MultiSelect } from 'react-multi-select-component';

class MultiVerticalFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // verticals: [{value:'apple',label:'Apple'},{value:'banana',label:'Banana'}],
      verticals: this.getInitialVerticalValue(),
      // selected: [{value:'apple',label:'Apple'},{value:'banana',label:'Banana'}],
      selected: this.getInitialVerticalValueSelected(),
      timeoutId: null,
    };
    this.selectRef = React.createRef();
  }

  handleChange = () => {
    let selectedvertical = [];
    for (let i = 0; i < this.state.selected.length; i++) {
      if (this.state.selected[i].value !== 'all') {
        selectedvertical.push(this.state.selected[i].value);
      }
    }
    // console.log(selectedvertical, this.state.selected, 'handleChange');
    this.props.onChangeValue(selectedvertical);
    this.setState({ timeoutId: null });
  };

  getVerticals = () => {
    getVerticals()
      .then((response) => {
        const arr = [];

        response.data.forEach((element) => {
          arr.push({
            value: element.verticalId,
            label: element.verticalName,
          });
        });

        this.setState({ verticals: arr });
        return response;
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  getInitialVerticalValue = () => {
    let arr = [];
    let prevValues;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        prevValues = this.props.verticalsDocOnCall;
        break;
      }
      case '/specialist-consults': {
        prevValues = this.props.verticalsSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        prevValues = this.props.verticalsOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        prevValues = this.props.verticalsScheduledConsults;
        break;
      }
      default:
        prevValues = undefined;
        break;
    }

    console.log(prevValues, 'verticals:prevValues');

    getVerticals().then((response) => {
      if (prevValues) {
        if (
          prevValues.length >= response.data.length ||
          prevValues === undefined
        ) {
          // arr.push({
          //   value: 'all',
          //   label: 'All items are selected.',
          // });
        } else if (prevValues.length === 0) {
          // pass
        } else {
          response.data.forEach((element) => {
            if (prevValues.includes(element.verticalId)) {
              arr.push({
                value: element.verticalId,
                label: element.verticalName,
              });
            }
          });
        }
      } else {
        // arr.push({
        //   value: 'all',
        //   label: 'All',
        // });
      }
    });

    return arr;
  };

  getInitialVerticalValueSelected = () => {
    let arr = [];
    let prevValues;

    switch (window.location.pathname) {
      case '/doc-on-call': {
        prevValues = this.props.verticalsDocOnCall;
        break;
      }
      case '/specialist-consults': {
        prevValues = this.props.verticalsSpecialistConsults;
        break;
      }
      case '/offline-sp': {
        prevValues = this.props.verticalsOfflineSp;
        break;
      }
      case '/scheduled-consults': {
        prevValues = this.props.verticalsScheduledConsults;
        break;
      }
      default:
        prevValues = undefined;
        break;
    }

    console.log(prevValues, 'verticals:prevValues');

    getVerticals().then((response) => {
      if (prevValues) {
        if (
          // prevValues.length >= response.data.length ||
          prevValues === undefined
        ) {
          // unreached conditional
          arr.push({
            value: 'all',
            label: 'All items are selected.',
          });
        } else if (prevValues.length === 0) {
          // nothing selected
          arr.push({
            value: 'all',
            label: 'Select...',
          });
          // set filter to null here
          this.props.setVerticalNull();
        } else {
          response.data.forEach((element) => {
            if (prevValues.includes(element.verticalId)) {
              arr.push({
                value: element.verticalId,
                label: element.verticalName,
              });
            }
          });
        }
      } else {
        // arr.push({
        //   value: 'all',
        //   label: 'All items are selected.',
        // });
        response.data.forEach((element) => {
            arr.push({
              value: element.verticalId,
              label: element.verticalName,
            });
          });
      }

      // this.setState({verticals: arr});
      this.setState({selected: arr});
    });


    return arr;
  };

  setSelected = (selectedItems) => {
    // console.log(selectedItems[0], 'setSelected0');
    // console.log(selectedItems, selectedItems.length, 'setSelectedAll');

    if (selectedItems.length >= 1) {
      if (selectedItems[0].value === 'all') {
        this.setState({
          selected: selectedItems.slice(1, selectedItems.length),
        });
      } else {
        this.setState({ selected: selectedItems });
      }
    } else {
      this.setState({ selected: selectedItems });
    }

    if (!this.state.timeoutId) {
      this.setState({ timeoutId: setTimeout(this.handleChange, 1200) });
    }
  };

  componentDidMount() {
    this.getVerticals();
    // this.getInitialVerticalValueSelected();
    // this.setState({selected: [{value:'apple2',label:'Apple2'},{value:'banana2',label:'Banana2'}]});
    // this.setState({selected: this.getInitialVerticalValueSelected()});
  }

  render() {
    return (
      <div>
        <MultiSelect
          hasSelectAll={true}
          options={this.state.verticals}
          value={this.state.selected}
          onChange={this.setSelected}
          debounceDuration={300}
          ref={this.selectRef}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  verticalsDocOnCall: state.filterHeader.verticalsDocOnCall,
  verticalsSpecialistConsults: state.filterHeader.verticalsSpecialistConsults,
  verticalsOfflineSp: state.filterHeader.verticalsOfflineSp,
});

export default connect(mapStateToProps)(MultiVerticalFilter);
