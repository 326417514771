import React, { Component } from 'react';
import NavBar from '../layout/NavBar';
import csvParse from 'csv-parse/lib/sync';
import ResultModal from '../common/modal/ResultModal';
import {
  OuterComponent,
  HeadingContainer,
  PaddedContainer,
  BodyContainer,
} from './StyledComponent';
import { Button, ModalBody, ModalHeader } from 'reactstrap';
import { opdPreOnboarding, onboardingStatus, onBoardSpecificMember } from '../../services/api/opd-management';
import OpdConfirmModal from './OpdConfirmModal';
import OpdStatusModal from './OpdStatusModal';
import LoadingComponentAbsolute from '../common/LoadingComponentAbsolute';
import OpdHolderTable from './OpdHolderTable';
import UpdateOpdMembersTable from './UpdateOpdMembersTable';
import Modal from 'reactstrap/lib/Modal';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import csvFileValidator from 'csv-file-validator'
import moment from 'moment'
import ValidationModal from './validationModal';

const newMembersCols = [
  'ENDORSEMENT_TYPE',
  'MASTER_POLICY_NO',
  'EMPLOYEE_ID',
  'CERTIFICATE_NUMBER',
  'NAME',
  'DOB',
  'GENDER',
  'RELATIONSHIP',
  'EMAIL',
  'ADDRESS',
  'STATE',
  'PHONE',
  'COVER_START_DATE',
  'COVER_END_DATE',
  'PREMIUM',
  'PREMIUM_INCL_GST',
  'EMPLOYER',
  'account_number',
  'ifsc'
];
const updateMembersCols = [
  'MASTER_POLICY_NO',
  'EMPLOYEE_ID',
  'NAME',
  'DOB',
  'OLD_VALUE',
  'UPDATED_VALUE',
  'Change Field Type',
];

const requiredError = (headerName, rowNumber, columnNumber) => {
	return `${headerName} is required in the ${rowNumber} row ${columnNumber} column`
}

const validateError = (headerName, rowNumber, columnNumber) => {
	return `${headerName} is not valid in the ${rowNumber} row ${columnNumber} column`
}

const uniqueError = (headerName, rowNumber) => {
	return `${headerName} is not unique at the ${rowNumber} row`
}

const isEmailValid = function (email) {
  if (email) {
    const reqExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return reqExp.test(email);
  }
  // If no email is present, allow the file
  return true;
}

const dateError = (DOB) => {
  const dob = moment(DOB,'YYYY-MM-DD', true).isValid() ? true : false
  return dob;
}

const whiteSpaceCheck = (data) => {
  const regex = /([A-Z])\w+/;
  const result = regex.test(data)
  return result;
}

const nameCheck = (data) => {
  const regex = /([A-Za-z\s])/;
  const result = regex.test(data)
  return result;
}

const phoneCheck = (data) =>{
  if(data){
    const regex = /^[6789]\d{9}$/
    const result = regex.test(data)
    return result;
  }
  return true;
}
const genderCHeck = (data) => {
  const regex = /^Male$|^Female$|^male$|^female$/
  const result = regex.test(data)
  return result;
}

const relationshipValidationCheck = (data) => {
  const regex = /^SELF$|^SPOUSE$|^SON$|^DAUGHTER$|^MOTHER$|^FATHER$|^FATHER-IN-LAW$|^OTHERS$|^MOTHER-IN-LAW$/i;
  const result = regex.test(data)
  return result;
}

// const dependsOnSomeDataInRow = (data,row) => {
//   console.log(data,row,'[dependent]')
//   // if(data){}
//   // return isEmailDependsOnSomeDataInRow(email,row)
// }


const config = {
  headers:[
    {
      name:'ENDORSEMENT_TYPE',
      inputName:'ENDORSEMENT_TYPE',
      required:true,
      requiredError,
      validateError:whiteSpaceCheck
    },
    {
      name:'MASTER_POLICY_NO',
      inputName:'MASTER_POLICY_NO',
      required:true,
      requiredError,
      validateError:whiteSpaceCheck
    },
    {
      name:'EMPLOYEE_ID',
      inputName:'EMPLOYEE_ID',
      required:true,
      requiredError,
      validateError:whiteSpaceCheck
    },
    {
      name:'CERTIFICATE_NUMBER',
      inputName:'CERTIFICATE_NUMBER',
      required:true,
      requiredError,
      validate:whiteSpaceCheck
    },
    {
      name:'NAME',
      inputName:'NAME',
      required:true,
      requiredError,
      validate:nameCheck
      // validate:dependsOnSomeDataInRow
    },
    {
      name:'DOB',
      inputName:'DOB',
      required:true,
      requiredError,
      validate: dateError
    },
    {
      name:'GENDER',
      inputName:'GENDER',
      required:true,
      requiredError,
      validate:genderCHeck
    },
    {
      name:'RELATIONSHIP',
      inputName:'RELATIONSHIP',
      required:true,
      requiredError,
      validate:relationshipValidationCheck
    },
    {
      name:'EMAIL',
      inputName:'EMAIL',
      required:false,
      requiredError,
      // unique: true,
      // uniqueError,
      validate: isEmailValid,
      validateError,
      // dependentValidate:dependsOnSomeDataInRow
      // dependentValidate:((email, row) => {
      //   return isEmailDependsOnSomeDataInRow(email, row)})
    },
    {
      name:'ADDRESS',
      inputName:'ADDRESS',
      required:false,
      requiredError
    },
    {
      name:'STATE',
      inputName:'STATE',
      required:false,
      requiredError
    },
    {
      name:'PHONE',
      inputName:'PHONE',
      required:false,
      requiredError,
      validate:phoneCheck
      // dependentValidate:dependsOnSomeDataInRow
    },
    {
      name:'COVER_START_DATE',
      inputName:'COVER_START_DATE',
      required:true,
      requiredError,
      validate: dateError
    },
    {
      name:'COVER_END_DATE',
      inputName:'COVER_END_DATE',
      required:true,
      requiredError,
      validate: dateError
    },
    {
      name:'PREMIUM',
      inputName:'PREMIUM',
      required:false,
      requiredError
    },
    {
      name:'PREMIUM_INCL_GST',
      inputName:'PREMIUM_INCL_GST',
      required:false,
      requiredError
    },
    {
      name:'EMPLOYER',
      inputName:'EMPLOYER',
      required:true,
      requiredError
    },
    {
      name:'account_number',
      inputName:'account_number',
      required:false,
      requiredError
    },
    {
      name:'ifsc',
      inputName:'ifsc',
      required:false,
      requiredError
    },
  ]
}

const sampleFileUrl = 'https://visit-public.s3.ap-south-1.amazonaws.com/operations-dashboard/add-opd-members-sample.csv';
class OpdManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      showModal: false,
      showOpdModal: false,
      summary: [],
      premium: '',
      modalHeader: '',
      modalBody: '',
      bgColor: '',
      data: [],
      tableData: [],
      submitId: '',
      showUpdateTable: false,
      showOpdTable: true,
      columnsNames: [],
      records: [],
      search: '',
      pageSize: 10,
      pages: 0,
      filtered: [],
      showStatusModal: false,
      onboardCount: '',
      // deOnboardCount: '',
      details: '',
      memberId:'',
      opsModal:false,
      showValidationModal:false,
      inValidData:[]
    };
  }

  showStatus = () => {
    onboardingStatus(this.state.submitId)
      .then((response) => {
        this.setState({
          onboardCount: response.onBoardCount,
          // deOnboardCount: response.deOnboardCount,
          details: response.failedResult,
        });
      })
      .then(() => {
        this.setState({ showStatusModal: true });
      })
  }

  // Check the data parse
  // Onboard New Members
  checkOnboardData = (data) => {
    // console.log(data.length, '[checkOnboardData]');
    const columnsNames = data.shift();
    // console.log(columnsNames, '[columnNames]');
    const records = data;
    if (data.length <= 1000) {
      if (columnsNames.length === newMembersCols.length) {
        // console.log(records, 'records');
        opdPreOnboarding(columnsNames, records)
          .then((resp) => {
            // console.log(resp.summary, resp.summary, resp);
            this.setState({
              showOpdModal: true,
              modalHeader: 'Success',
              summary: resp.summary,
              premium: resp.premium,
              submitId: resp.submitId,
            });
          })
          .catch((err) => {
            let errorMessage = err.message;
            if (err.response !== undefined) {
              errorMessage = err.response.data.message;
            }
            // console.log(errorMessage);
            this.setState({
              showModal: true,
              modalHeader: 'Parsing Failed',
              modalBody: `Error : "${errorMessage}". Check the csv file and try again.`,
              bgColor: 'bg-danger',
            });
          });
      } else {
        this.setState({
          showModal: true,
          modalHeader: 'Parsing Failed',
          modalBody:
            'Error : columns length of csv file is not equal to the required format. Check the csv format and try again',
          bgColor: 'bg-danger',
        });
      }
    } else {
      this.setState({
        showModal: true,
        modalHeader: 'Parsing Failed',
        modalBody:
          'Error : Size of the file is too large. Decrease the size of csv file and try again',
        bgColor: 'bg-danger',
      });
    }
  };
  // // Update Member Details
  // checkUpdateData = (data, tableData) => {
  //   console.log(data.length, '[checkUpdateData]');
  //   const columnsNames = data.shift();
  //   const records = data;
  //   if (data.length <= 290) {
  //     if (columnsNames.length === updateMembersCols.length) {
  //       // console.log(records, 'records');
  //       for (let i = 0; i < columnsNames.length; i++) {
  //         if (updateMembersCols.includes(columnsNames[i])) {
  //           // console.log('Success');
  //           if (i === updateMembersCols.length - 1) {
  //             // console.log(updateMembersCols.length, 'Success');
  //             getOldValues()
  //               .then((resp) => {
  //                 console.log(resp);
  //                 this.setState({
  //                   showUpdateTable: true,
  //                   showOpdTable: false,
  //                   columnsNames: columnsNames,
  //                   records: records,
  //                   tableData: tableData,
  //                 });
  //               })
  //               .catch((err) => {
  //                 let errorMessage = err.message;
  //                 if (err.response !== undefined) {
  //                   errorMessage = err.response.data.errorDebug;
  //                 }
  //                 console.log(errorMessage);
  //                 this.setState({
  //                   showModal: true,
  //                   modalHeader: 'Parsing Failed',
  //                   modalBody: `Error : "${errorMessage}". Check the csv file and try again.`,
  //                   bgColor: 'bg-danger',
  //                 });
  //               });
  //           }
  //         } else {
  //           this.setState({
  //             showModal: true,
  //             modalHeader: 'Parsing Failed',
  //             modalBody: `Error : ${columnsNames[i]}Unknown columns name found. Check the csv format and try again`,
  //             bgColor: 'bg-danger',
  //           });
  //         }
  //       }
  //     } else {
  //       this.setState({
  //         showModal: true,
  //         modalHeader: 'Parsing Failed',
  //         modalBody:
  //           'Error : columns length of csv file is not equal to the required format. Check the csv format and try again',
  //         bgColor: 'bg-danger',
  //       });
  //     }
  //   } else {
  //     this.setState({
  //       showModal: true,
  //       modalHeader: 'Parsing Failed',
  //       modalBody:
  //         'Error : Size of the file is too large. Decrease the size of csv file and try again',
  //       bgColor: 'bg-danger',
  //     });
  //   }
  // };
  // // handle changes
  // Onboard New Members
  handleOnboard = () => {
    document.querySelector('#csvOnboardingAttachment').click();
  };
  handleOnboardAttachment = () => {
    const file = document.querySelector('#csvOnboardingAttachment').files[0];
    const reader = new FileReader();
    return csvFileValidator(file,config)
      .then(csvData=>{
        console.log(csvData,'[csvdata]')
        if(csvData.inValidData.length){
          this.setState({
            showValidationModal:true,
            inValidData:csvData.inValidData
          })
        }else{
           reader.onload = () => {
            const res = reader.result;
            // console.log({ res });

            try {
              const data = csvParse(res, {
                // columns: true,
                skip_empty_lines: true,
                quote: '"',
                ltrim: true,
                rtrim: true,
                delimiter: ',',
              });
              // console.log({ data });
              this.setState({ data: data });
              // console.log(this.state.data, 'dataaas');
              this.checkOnboardData(data);
            } catch (err) {
              // console.log(err.message, 'err in parse');
              this.setState({
                showModal: true,
                modalHeader: 'Failed',
                modalBody: err.message,
                bgColor: 'bg-danger',
              });
            }
          };
          reader.readAsText(file);
          document.querySelector('#csvOnboardingAttachment').value = null;
        }
      })
  };
  // Update Member Details
  handleUpdate = () => {
    document.querySelector('#csvUpdateAttachment').click();
  };
  handleUpdateAttachment = () => {
    const file = document.querySelector('#csvUpdateAttachment').files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const res = reader.result;
      // console.log({ res });

      try {
        const data = csvParse(res, {
          // columns: true,
          skip_empty_lines: true,
          quote: '"',
          ltrim: true,
          rtrim: true,
          delimiter: ',',
        });
        const tableData = csvParse(res, {
          columns: true,
          skip_empty_lines: true,
          quote: '"',
          ltrim: true,
          rtrim: true,
          delimiter: ',',
        });
        // console.log({ data, tableData });
        this.setState({ data: data });
        // console.log(this.state.data, 'dataaas');
        this.checkUpdateData(data, tableData);
      } catch (err) {
        // console.log(err.message, 'err in parse');
        this.setState({
          showModal: true,
          modalHeader: 'Failed',
          modalBody: err.message,
          bgColor: 'bg-danger',
        });
      }
    };
    reader.readAsText(file);
    document.querySelector('#csvUpdateAttachment').value = null;
  };

  // handleMemberId = (e) => {
  //   this.setState({
  //     memberId:e.target.value
  //   })
  // }

  // findUserByMemberID = () => {
  //  const userMemberId = this.state.memberId;
  //   if(userMemberId){
  //     return onBoardSpecificMember(userMemberId)
  //       .then((response) =>{
  //         console.log(response,'[response]')
  //         if(response.message==="success"){
  //           this.setState({
  //             opsModal:true
  //           })
  //         }
  //       })
  //       .catch((err)=>{
  //         console.log(err);
  //         let errorMessage = err.message;
  //       })
  //   }
  // }

  // closeModal = () => {
  //   this.setState({
  //     opsModal:false
  //   })
  // }

  render() {
    return (
      <OuterComponent>
        <NavBar />
        <HeadingContainer>
          <h1 className="col-6">
            <u>OPD Member Onboarding</u>
          </h1>
        </HeadingContainer>
        <PaddedContainer>
          <div className="col-3">
            <input
              type="file"
              id="csvOnboardingAttachment"
              accept=".csv"
              style={{ display: 'none' }}
              onChange={this.handleOnboardAttachment}
            />
            <Button onClick={this.handleOnboard}>Onboard New Members</Button>
          </div>
          {/* <div className='col-3'>
            <div >
              <input type="text" placeholder='member-id' defaultValue={this.state.memberId} onChange={this.handleMemberId} />
              <Button className="m-3" type="submit" color="secondary" onClick={this.findUserByMemberID}>Onboard User</Button>
            </div>
          </div> */}
          <div className="col-3">
            <button className="btn btn-primary" onClick={() => window.open(sampleFileUrl, "_blank")}>Download Onboarding Sample</button>
          </div>
          {/* <div className="col-3">
            <input
              type="file"
              id="csvUpdateAttachment"
              accept=".csv"
              style={{ display: 'none' }}
              onChange={this.handleUpdateAttachment}
            />
            <Button onClick={this.handleUpdate}>Update Member Details</Button>
          </div> */}
          {/* <div className="col-3">
            <Button>Remove Members</Button>
          </div> */}
        </PaddedContainer>
        <BodyContainer>
          {this.state.showOpdTable && <OpdHolderTable />}
          {this.state.showUpdateTable && (
            <UpdateOpdMembersTable
              loading={this.state.loading}
              data={this.state.tableData}
              columnsNames={this.state.columnsNames}
              records={this.state.records}
            />
          )}
        </BodyContainer>
        {this.state.showModal && (
          <ResultModal
            modalHeader={this.state.modalHeader}
            modalBody={this.state.modalBody}
            closeModal={() => this.setState({ showModal: false })}
            bgColor={this.state.bgColor}
          />
        )}
        {this.state.showOpdModal && (
          <OpdConfirmModal
            modalHeader={this.state.modalHeader}
            closeModal={() =>
              this.setState({ showOpdModal: false, loading: false }, () => this.showStatus())
            }
            closeModalOnCancel={() => this.setState({ showOpdModal: false, loading: false })}
            summary={this.state.summary}
            toggleLoading={() =>
              this.setState({ loading: !this.state.loading })
            }
            onFetchData={this.onFetchData}
            premium={this.state.premium}
            submitId={this.state.submitId}
          />
        )}
        {this.state.showStatusModal && (
          <OpdStatusModal
            closeStatusModal={() => this.setState({ showStatusModal: false })}
            submitId={this.state.submitId}
            onboardCount={this.state.onboardCount}
            // deOnboardCount={this.state.deOnboardCount}
            details={this.state.details}
          />
        )}
        {this.state.loading ? <LoadingComponentAbsolute /> : null}
        {/* <Modal isOpen={this.state.opsModal}>
          <ModalHeader style={{ color: "black" }} toggle={this.closeModal}></ModalHeader>
          <ModalBody style={{textAlign:'center'}}>User Onboard Successfully</ModalBody>
        </Modal> */}
        {
          this.state.showValidationModal && (
          <ValidationModal closeStatusModal={() => this.setState({ showValidationModal: false })} errors={this.state.inValidData} />
        )}
      </OuterComponent>
    );
  }
}

export default OpdManagement;
