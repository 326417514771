import React from 'react';
import Modal from "react-modal";
import styled from "styled-components";

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)'
    }
};

const ModalHeader = styled.div`
    font-size: x-large;
    border-bottom: 0.5px #d7d7d7 solid;
`;

const ModalBody = styled.div`
    margin: 10px;
    max-height: 500px;
`;

class StyledModal extends React.Component {
    componentWillMount() {
        Modal.setAppElement('body');
    }

    render() {
        const { header, children } = this.props;
        return (
            <Modal
                isOpen={this.props.isOpen}
                onAfterOpen={this.props.onOpen}
                onRequestClose={this.props.onClose}
                style={customStyles}
                shouldCloseOnOverlayClick={true}
            >
                <ModalHeader>
                    {header}
                </ModalHeader>
                <ModalBody children={children}/>
            </Modal>
        );
    }
}

export default StyledModal;
