import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { markCartReady } from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
class LabRxReadyCart extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        };
    }

    toggle = () => {

        this.setState({
            modal: !this.state.modal,
        });
    };

    save = () => {
        markCartReady(parseInt(this.props.requestId))
            .then((response) => {
                console.log(response.body);
                if (response.body.message === 'success') {
                    toast.success('Cart sent to the user');
                    this.props.loadCart();
                    this.toggle();
                } else {
                    toast.error('Unable to mark cart as ready');
                }
            })
            .catch((err) => {
                toast.error('Unable to mark cart as ready');
                console.error('Error', err);
            });
    };

    render() {
        return (
            <div>
                <button disabled={this.props.disabled} onClick={this.toggle} className="btn btn-success">Ready Cart</button>
                <Modal isOpen={this.state.modal} toggle={this.toggle}>
                    <h4 className="mt-1 mb-0 ml-2">Confirmation</h4>
                    <ModalBody>
                        <p>Are you sure you want to send this cart to the user?</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={() => this.save()}>Save</Button>{' '}
                        <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>

        );
    }
}

const mapStateToProps = state => ({ cartItemsResult: state.labsDashboard.cartItemsResult });

export default connect(
    mapStateToProps,
)(LabRxReadyCart);





