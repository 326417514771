import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import styled from 'styled-components';
import { getAddedDoctorRequestList } from '../../services/api/baseRequest';

import NavBar from '../layout/NavBar';
import ReactTable from 'react-table';

const PaddedContainer = styled.div`
  margin-top: 5rem;
  flex: 1;
  padding-left: 2rem;
  padding-right: 2rem;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  flex: 1;
  padding: 0px;
  margin: 0px;
`;

const BodyComponent = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  border: 1px solid #d5d5d5;
  border-radius: 0.5rem;
  /* overflow: hidden; */
`;

class AddedDoctorRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      opsAddedDoctorRequestList: [],
      showEditModal: false,
      selectedId: null,
      selectedData: {},
    };
  }

  componentDidMount = () => {
    this.getAddedDoctorRequestList();
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   if (prevState.selectedId !== this.state.selectedId) {
  //     const selectedData = this.state.opsAddedDoctorRequestList.find(
  //       (user) => user.id === this.state.selectedId
  //     );
  //     this.setState({ selectedData });
  //   }
  // };

  customFilterMethod(filter, row) {
    // Convert both the filter value and the cell value to lowercase for case-insensitive matching
    const filterText = filter.value.toLowerCase();
    const cellValue = String(row[filter.id]).toLowerCase();
    
    // Check if the cell value includes the filter text
    return cellValue.includes(filterText);
  }

  getAddedDoctorRequestList = () => {
    this.setState({ loading: true });
    getAddedDoctorRequestList()
      .then((res) => {
        if (res.message === 'Success') {
          this.setState({ opsAddedDoctorRequestList: res.data });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  tableColumns = [
    {
      accessor: 'centerId',
      filterable: true,
      sortable: false,
      Header: 'Center Id',
      filterMethod: this.customFilterMethod
    },
    {
      accessor: 'centerName',
      filterable: true,
      sortable: false,
      Header: 'Center Name',
      filterMethod: this.customFilterMethod
    },
    {
      accessor: 'doctorId',
      filterable: true,
      Header: 'Doctor Id',
      filterMethod: this.customFilterMethod
    },
    {
      accessor: 'doctorName',
      filterable: true,
      Header: 'Doctor Name',
      filterMethod: this.customFilterMethod
    },
    {
      accessor: 'opdCharges',
      filterable: true,
      Header: 'Fee',
      //filterMethod: this.customFilterMethod
    },
    {
      accessor: 'status',
      filterable: false,
      Header: 'Status',
      //filterMethod: this.customFilterMethod
    },

  ];

  render() {
    const { loading, opsAddedDoctorRequestList } = this.state;
    return (
      <Fragment>
        <NavBar />
        <PaddedContainer>
          <HeaderContainer>
            <h3 className="">Added Doctor Request List</h3>
          </HeaderContainer>

          {/* Table */}
          <BodyComponent>
            <ReactTable
              filterable
              className="-striped -highlight"
              minRows={1}
              data={opsAddedDoctorRequestList}
              collapseOnDataChange={false}
              columns={this.tableColumns}
              loading={loading}
              showPaginationTop
            />
          </BodyComponent>
        </PaddedContainer>
    
      </Fragment>
    );
  }
}

export default AddedDoctorRequestList;
